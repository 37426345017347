import { SET_LOADER, DELETE_LOADER, PROGRESS_LOADER } from 'actionTypes';

const loadersReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SET_LOADER: {
            const {
                meta: { loader }
            } = action;
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [loader]: 0
                }
            };
        }

        case DELETE_LOADER: {
            const {
                meta: { loader }
            } = action;
            const filteredLoaders = Object.entries(state.loaders).filter(
                ([key]) => key === !loader
            );
            const loaders = Object.fromEntries(filteredLoaders);

            return {
                ...state,
                loaders
            };
        }

        case PROGRESS_LOADER: {
            const {
                payload: { progress },
                meta: { loader }
            } = action;
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [loader]: progress
                }
            };
        }

        default: {
            return state;
        }
    }
};

export { loadersReducer as default, loadersReducer };
