import React from 'react';
import { withStyles } from '@material-ui/core';
import { composeClsx } from '@libs/materialUI';

const styles = ({ spacing, typography }) => ({
    badge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5,
        borderRadius: '50%',
        backgroundColor: '#0F70C3',
        color: '#fff',
        fontSize: spacing.unit * 1.5,
        fontFamily: typography.fontFamily,
        fontWeight: 500
    }
});

const Badge = withStyles(styles)(({ count, styles, classes }) => {
    if (!count) return null;
    const c = composeClsx({ classes, styles });
    return <span className={c.badge}>{count}</span>;
});

export { Badge as default, Badge };
