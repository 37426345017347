import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import { Button } from '@libComponents/Button';

const styles = ({ spacing }) => ({
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: '100%',
        margin: spacing.unit * 2,
        marginTop: 0
    }
});

const ModalFooter = withStyles(styles)(({ classes: c, onSave, disabled }) => (
    <div className={c.container}>
        <Button
            variant='contained'
            color='primary'
            onClick={onSave}
            className={c.button}
            disabled={disabled}
        >
            Save
        </Button>
    </div>
));

ModalFooter.propTypes = {
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default ModalFooter;
