import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Grid } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem } from '@formComponents';

import OriginalFabricImage from './OriginalFabricImage';
import SelectFabricType from '../../SelectFabircType';
import SelectComposition from '../../SelectComposition';
import SelectGSM from '../../SelectGSM';

const propTypes = {
    formName: PropTypes.string.isRequired,
    shouldLocked: PropTypes.bool.isRequired
};

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    gridItem: {
        paddingLeft: 0
    }
});

const OriginalFabricContainer = withStyles(styles)(
    ({ classes, shouldLocked, formName }) => {
        return (
            <ContentContainer
                title='Original fabric details'
                AdornmentComponent={<Fragment />}
                styles={{ container: classes.container }}
            >
                <GridContainer>
                    <GridItem md={8} className={classes.gridItem}>
                        <Grid container>
                            <GridItem md={6}>
                                <SelectFabricType
                                    name='originalFabric.fabricType'
                                    disabled={shouldLocked}
                                    required
                                />
                            </GridItem>
                            <GridItem md={6}>
                                <SelectComposition
                                    name='originalFabric.composition'
                                    disabled={shouldLocked}
                                    required
                                />
                            </GridItem>
                            <GridItem md={6}>
                                <SelectGSM
                                    name='originalFabric.gsm'
                                    disabled={shouldLocked}
                                    required
                                />
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem md={4} className={classes.gridItem}>
                        <OriginalFabricImage
                            shouldLocked={shouldLocked}
                            formName={formName}
                        />
                    </GridItem>
                </GridContainer>
            </ContentContainer>
        );
    }
);

OriginalFabricContainer.propTypes = propTypes;

export default OriginalFabricContainer;
