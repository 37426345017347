import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import styles from './styles';

function _Badge(props) {
    const { content, children, color, invisible } = props;

    return (
        <Badge badgeContent={content} color={color} invisible={invisible}>
            {children}
        </Badge>
    );
}

_Badge.defaultProps = {
    color: 'secondary',
    content: '',
    invisible: false
};

_Badge.propTypes = {
    classes: PropTypes.object,
    color: PropTypes.string,
    content: PropTypes.node,
    invisible: PropTypes.bool
};

const __Badge = withStyles(styles)(_Badge);

export { __Badge as default, __Badge as Badge };
