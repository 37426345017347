import React, { Component, Fragment } from 'react';

// Material
import { withStyles, Typography } from '@material-ui/core';

// Local
import styles from './styles';
import { Editor } from 'components';

class SamplingEditor extends Component {

    render() {
        const { state, props } = this;

        const { classes: c, label, ...restProps } = props;

        return (
            <Fragment>
                <label>
                    <Typography component='div' className={c.label}>
                        {label}
                    </Typography>
                </label>
                <Editor
                    {...restProps}
                />
            </Fragment>
        );
    }
}

const _SamplingEditor = withStyles(styles)(SamplingEditor);

export {
    _SamplingEditor as default,
    _SamplingEditor as SamplingEditor
};
