import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, withStyles } from '@material-ui/core';

// Local
import {
    DETAIL_TYPES,
    valuePropType,
    labelPropType,
    typePropType
} from './common';
import { Checkbox } from '../Checkbox';

const propTypes = {
    value: valuePropType,
    label: labelPropType,
    type: typePropType
};

const defaultProps = { type: DETAIL_TYPES.string };

const styles = ({ palette, spacing, typography }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: spacing.unit * 3.5,
        paddingLeft: spacing.unit * 1.5,
        paddingRight: spacing.unit * 1.5,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        fontWeight: typography.fontWeightBold
    },
    string: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'end',
        width: '50%',
        color: palette.secondary.light
    },
    boolean: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: spacing.unit * 3.25, // Accounts for vertical padding
        width: '50%',
        paddingRight: 0,
        pointerEvents: 'none'
    }
});

const Row = withStyles(styles)(({ classes: c, type, value, label }) => (
    <div className={c.root}>
        {type === DETAIL_TYPES.custom ? (
            value
        ) : (
            <Fragment>
                <Typography className={c.label}>{label}</Typography>
                {type === DETAIL_TYPES.string ? (
                    <Typography className={c.string}>{value}</Typography>
                ) : (
                    <Checkbox checked={value} className={c.boolean} />
                )}
            </Fragment>
        )}
    </div>
));

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
