export const FETCH_BACKNECK_DETAILS_REQUEST = 'get_backneck_details';
export const FETCH_BACKNECK_DETAILS_SUCCESS = 'get_backneck_details_success';
export const FETCH_BACKNECK_DETAILS_FAILURE = 'get_backneck_details_failure';

export const POST_BACKNECK_DATA_REQUEST = 'post_backneck_data';
export const POST_BACKNECK_DATA_SUCCESS = 'post_backneck_data_success';

export const UPDATE_BACKNECK_DETAILS_REQUEST = 'put_backneck_details';
export const UPDATE_BACKNECK_DETAILS_SUCCESS = 'put_backneck_details_success';

export const DELETE_BACKNECK_DETAILS_REQUEST = 'delete_backneck_details';
export const DELETE_BACKNECK_DETAILS_SUCCESS =
	'delete_backneck_details_success';

export const RESET_BACKNECK_DATA_REQUEST = 'reset_backneck_data';
export const BACKNECK_IMG_UPLOAD_LOADING = 'backneck_img_upload_loader';

export const LOAD_BACKNECK_DATA = 'load_backneck_details';
