import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PDFViewer as ReactPDFViewer } from '@react-pdf/renderer';

const propTypes = {
    children: PropTypes.element.isRequired
};

const PDFViewer = ({ children, ...props }) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let readyTimeout;
        readyTimeout = setTimeout(() => setReady(true), 1);
        return () => clearTimeout(readyTimeout);
    }, []);

    return ready && <ReactPDFViewer {...props}>{children}</ReactPDFViewer>;
};

PDFViewer.propTypes = propTypes;

export { PDFViewer as default, PDFViewer };
