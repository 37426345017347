import { startSubmit } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

import { FORM_NAME } from '../../Fabric/Sustainability/common';
import { sustainabilitySchema } from './schemas';
import { DOMAIN_NAME } from 'setup/common';
import { FABRIC_SUSTAINABILITY } from 'setup/redux.datasets';
import { COMPOSITIONS } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

// Saga that handles adding or updating fabric sustainability
export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        // Start the submit process for the form
        yield put(startSubmit(FORM_NAME));

        // Compose the payload to be sent to the API
        const publishData = composeApiPayload(payload, sustainabilitySchema);

        // Call the API to add or update the fabric sustainability
        const { data } = yield call(apiRequest, {
            url: 'FabricSustainability',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        // Update the fabric sustainability dataset
        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: FABRIC_SUSTAINABILITY,
            isNew: id === 0,
            data
        });

        // If updating an existing sustainability, update the dependent dataset
        if (id > 0) {
            const dependentDataset = yield select(
                entitySelector,
                SAMPLING_DOMAIN_NAME,
                COMPOSITIONS
            );
            // Update the compositions dataset with the updated sustainability data
            const dependentDatasetAfterEdit = dependentDataset.map(
                ({ sustainabilityId, ...prevData }) => ({
                    ...(sustainabilityId === id
                        ? { ...prevData, sustainability: data.sustainability }
                        : prevData),
                    sustainabilityId
                })
            );
            yield put(
                setDataset({
                    entity: COMPOSITIONS,
                    domain: SAMPLING_DOMAIN_NAME,
                    dataset: dependentDatasetAfterEdit
                })
            );
        }

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Sustainability',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
