export default ({ palette, spacing, shape }) => ({
    root: {
        paddingLeft: spacing.unit * 0.75,
    },
    icon: {
        color: palette.grey[400],
        borderRadius: shape.borderRadius,
        '&:hover': {
            color: palette.grey[700],
            cursor: 'pointer',
        },
    },
    iconHighlight: {
        backgroundColor: palette.primary.light,
        color: palette.primary.contrastText,
        '&:hover': {
            color: palette.primary.contrastText,
            cursor: 'pointer',
            backgroundColor: palette.primary.dark,
        },
    },
});
