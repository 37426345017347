import React, { useCallback } from 'react';
import { compose } from 'redux';
import clsx from 'classnames';
import PropTypes from 'prop-types';

// Material
import { withStyles, FormControl } from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';

import { Error } from 'components/Message';

// Local
import withDateProvider from './withDateProvider';
import { denormalizeDate } from './normalizeDate';
import { composeClsx } from '@libs/materialUI';
import Label from '../Label';
import Adornment from './Adornment';

const propTypes = {
    label: PropTypes.node
};

const defaultProps = {
    //minDate: new Date(new Date().getFullYear() - 1 + '-01-01'), // limited to previous 1 year
    maxDate: new Date('2100-01-01')
};

const styles = ({ spacing, palette, shape, typography }) => ({
    container: {
        width: '100%',
        height: '100%'
    },
    root: {
        width: '100%',
        height: '100%',
        color: palette.grey[700],
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit,
        '&:hover': {
            borderColor: palette.grey[400]
        },
        // InputBase
        '& > div': {
            height: 'inherit',
            color: 'inherit'
        }
    },
    input: {
        padding: 0,
        fontSize: typography.caption.fontSize,
        '&[disabled]': {
            color: palette.grey[800]
        }
    },
    disabled: {
        backgroundColor: palette.background.light,
        color: palette.grey[500],
        '&:hover': {
            borderColor: palette.grey[300]
        }
    },
    success: {
        // borderColor: `${palette.success.primary} !important`, // Focus override
        // '&:hover': {
        //     borderColor: `${palette.success.hover} !important` // Focus override
        // },
        borderColor: `#212121 !important`, // Focus override
        '&:hover': {
            borderColor: `#18191a !important` // Focus override
        }
    },
    error: {
        borderColor: `${palette.error.light} !important`, // Focus override
        '&:hover': {
            borderColor: `${palette.error.dark} !important` // Focus override
        }
    }
});

const DateBase = compose(
    withDateProvider,
    withStyles(styles)
)(
    ({
        classes,
        styles,
        minDate,
        maxDate,
        input: { value, ...input },
        meta: { form, touched, error },
        fieldName,
        label,
        required,
        disabled: rootDisabled,
        loading,
        isCancellable,
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });
        const id = `${form}-${fieldName}`;
        const disabled = rootDisabled || loading;
        const success = !disabled && Boolean(value);
        const hasError = touched && error;

        const datePickerProps = {
            className: clsx(
                c.root,
                success && c.success,
                disabled && c.disabled,
                hasError && c.error
            ),
            disabled,
            id,
            minDate,
            maxDate,
            format: 'dd-MM-yyyy',
            value: denormalizeDate(value ? value : null),
            clearable: !!value || false,
            InputProps: {
                disabled: true,
                disableUnderline: true,
                inputProps: { className: c.input },
                endAdornment: (
                    <Adornment
                        disabled={disabled}
                        isCancellable={!!value && isCancellable}
                        handleClearValue={input.onChange}
                    />
                )
            },
            ...input,
            ...restProps
        };

        return (
            <FormControl className={c.container}>
                {label && (
                    <Label
                        label={label}
                        disabled={disabled}
                        required={required}
                        success={success}
                        htmlFor={id}
                    />
                )}
                <DatePicker {...datePickerProps} />
                {hasError && <Error message={error} />}
            </FormControl>
        );
    }
);

DateBase.propTypes = propTypes;
DateBase.defaultProps = defaultProps;

export default DateBase;
