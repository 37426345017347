/***********************
 * LIST OF PRIMARY KEYS:
 * ======================
 * bacnkNeck
 * bought
 * fabric
 * inks
 * sample
 * trims
 * *********************/

import sampleKeys from './_key_samples';
import backneckKeys from './_key_backneck';
import boughtKeys from './_key_bought';
import fabricKeys from './_key_fabric';
import inksKeys from './_key_inks';
import trimsKeys from './_key_trims';

const keys = {
	sample: sampleKeys,
	backNeck: backneckKeys,
	bought: boughtKeys,
	fabric: fabricKeys,
	inks: inksKeys,
	trims: trimsKeys,
};

/****************
 *
 * data: redux store data,
 * primaryKey: main key,
 * secondaryKey: property key,
 * cardId: card id of items(ex: fabric, trim, backneck etc.)
 *
 ****************/

function isAmendColor(data, primaryKey, secondaryKey, cardId = null) {
	// console.log('get key', data.hasOwnProperty(primaryKey), data[primaryKey]);
	let final = false;
	if (
		data &&
		keys.hasOwnProperty(primaryKey) &&
		data.hasOwnProperty(primaryKey)
	) {
		const mainKey = keys[primaryKey];
		const dataKey = data[primaryKey];
		let propertyKey = null;

		//search, match and find redux key counterpart of api key
		if (Array.isArray(mainKey)) {
			// console.log('yes mainKey array');
		} else if (mainKey.hasOwnProperty(secondaryKey)) {
			// console.log(
			// 	'no array',
			// 	mainKey.hasOwnProperty(secondaryKey),
			// 	mainKey[secondaryKey]
			// );
			propertyKey = mainKey[secondaryKey];
		}

		//get color info by key
		if (Array.isArray(dataKey)) {
			// console.log('yes dataKey array');
			if (propertyKey && dataKey.length > 0 && !cardId) {
				//no loop; get first value
				// console.log(
				// 	'no loop',
				// 	propertyKey,
				// 	dataKey[0][propertyKey],
				// 	dataKey[0].hasOwnProperty(propertyKey)
				// );
				if (dataKey[0].hasOwnProperty(propertyKey))
					final = dataKey[0][propertyKey];
			} else if (propertyKey && dataKey.length > 0 && cardId) {
				//loop and find item by cardId
				// console.log('yes loop');
			}
		}
	}

	return final;
}

export default isAmendColor;
