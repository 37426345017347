import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';

import { userIdSelector } from 'auth/redux.selectors';

import {
    userFormSelector,
    userPermissionsDataSelector
} from '../../../redux.selectors';

export default function*() {
    const [
        {
            permissions: { menus: selectedMenus = [], ...permissions } = {},
            ...values
        },
        userId
    ] = yield all([
        select(
            userFormSelector,
            'name',
            'email',
            'role',
            'reportTo',
            'active',
            'cadDesigner',
            'shapeDesigner',
            'permissions',
            'id'
        ),
        select(userIdSelector)
    ]);

    const { menus: menusData } = yield select(userPermissionsDataSelector);

    const menus = menusData.filter(({ menuId }) =>
        selectedMenus.includes(menuId)
    );

    return renameProperties(
        { ...values, userId, ...permissions, menus },
        {
            role: 'roleId',
            reportTo: 'reportToId',
            active: 'isActive',
            cadDesigner: 'isCadDesigner',
            shapeDesigner: 'isShapeDesigner'
        }
    );
}
