import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { string, array, object, number, oneOfType } from 'prop-types';

import { Field, Title, Comments, TabularView } from 'components/PDF';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    othersContainer: {
        marginTop: 5
    }
});

const propTypes = {
    additionalComments: string,
    attachments: array,
    boughtDetails: object,
    numberOfColours: oneOfType([string, number]),
    techPack: oneOfType([string, object]),
    artworkLinks: string
};

const Others = ({
    additionalComments,
    attachments = [],
    boughtDetails = {},
    numberOfColours,
    techPack = {},
    artworkLinks
}) => {
    const { count, data } = boughtDetails;
    return (
        <View>
            <Title text={'Other Information'} />
            <View style={styles.container}>
                <Field
                    label={'Number of colours'}
                    value={numberOfColours}
                    style={{ width: '38%' }}
                />
                <Field
                    label={'Artwork Link'}
                    value={!!artworkLinks ? artworkLinks : null}
                    style={{ width: '58%' }}
                    required={false}
                />
                {/* <Field
                    label={'Tech Pack'}
                    value={
                        techPack instanceof Object
                            ? techPack.filename
                            : techPack
                    }
                    style={{ width: '58%' }}
                /> */}
            </View>
            {/* {attachments.length > 0 && (
                <View
                    style={{ ...styles.container, ...styles.othersContainer }}
                >
                    <AttachmentDetails
                        attachments={attachments}
                        style={{ width: '100%' }}
                    />
                </View>
            )} */}
            {!!additionalComments && (
                <View
                    style={{ ...styles.container, ...styles.othersContainer }}
                >
                    <Comments
                        comments={additionalComments}
                        style={{ width: '100%' }}
                    />
                </View>
            )}
            {/* {!!artworkLinks && (
                <Field
                    label={'Artwork Link'}
                    value={artworkLinks}
                    style={{ width: '100%' }}
                    required={false}
                />
            )} */}
            {count > 0 && <TabularView data={data} title={'Bought Details'} />}
        </View>
    );
};

Others.propTypes = propTypes;

export default Others;
