import React from 'react';
import { withStyles, Popover, Grid } from '@material-ui/core';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';

import Button from '@libComponents/Button';
import { GridItem } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware';

import { isGridFilterItemSelectedSelector } from 'gridView/redux.selectors';
import {
    SelectTypes,
    SelectDesigners,
    SelectLicensors
} from 'cadUpload/components';
import { SelectRetailers, SelectDepartments } from 'sampling/components';
import { FILTERS_FORM_NAME as FORM_NAME } from 'common/common';
import {
    SelectCategories,
    SelectStyles,
    SelectGenders,
    SelectAges
} from 'common/components';
import {
    setGridFilterRetailerChildren,
    setGridFilterPropertyValue
} from 'gridView/redux.actions';

const INITIAL_VALUES = {
    cadType: '',
    licensor: '',
    property: '',
    designer: '',
    retailer: '',
    genderId: '',
    ageId: '',
    categoryId: '',
    department: '',
    styleId: ''
};

const styles = ({ spacing, typography }) => ({
    container: {
        position: 'absolute',
        height: `unset !important`,
        zIndex: 2,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 2
    },
    button: {
        height: spacing.unit * 4.5,
        width: '100%',
        fontSize: typography.subtitle1.fontSize
    }
});

const propTypes = {
    handleFilters: func.isRequired,
    hideDialog: func.isRequired,
    anchor: object.isRequired
};

const mapStateToProps = (state, { appliedFilters }) => ({
    initialValues: appliedFilters,
    isFilterItemSelected: isGridFilterItemSelectedSelector(state)
});

const mapDispatch = dispatch => ({
    handleResetValue: () => dispatch(initialize(FORM_NAME, INITIAL_VALUES))
});

const mapInputs = {
    retailer: setGridFilterRetailerChildren,
    licensor: setGridFilterPropertyValue
};

const Filters = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { handleFilters, hideDialog }) => {
            handleFilters({ type: 'set-filters', payload: values });
            hideDialog();
        }
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        anchor,
        hideDialog,
        handleSubmit,
        classes: c,
        isFilterItemSelected,
        handleResetValue
    }) => {
        return (
            <Popover
                open={!!anchor}
                onClose={hideDialog}
                anchorEl={anchor}
                PaperProps={{
                    style: { width: 580, height: 320 }
                }}
            >
                <Grid container className={c.container}>
                    <GridItem md={6}>
                        <SelectTypes label='' name='cadType' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectLicensors label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectDesigners label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectRetailers label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectGenders label='' name='genderId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectAges label='' name='ageId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectCategories label='' name='categoryId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectDepartments label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectStyles label='' name='styleId' />
                    </GridItem>
                    <Grid container>
                        <GridItem md={3}>
                            <Button className={c.button} onClick={handleSubmit}>
                                Apply
                            </Button>
                        </GridItem>
                        <GridItem md={3}>
                            <Button
                                className={c.button}
                                onClick={handleResetValue}
                                color='warning'
                                disabled={!isFilterItemSelected}
                            >
                                Reset
                            </Button>
                        </GridItem>
                    </Grid>
                </Grid>
            </Popover>
        );
    }
);

Filters.propTypes = propTypes;

export default Filters;
