import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

import { withStyles, Divider } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Input, Checkbox } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

import { SelectRetailers } from 'sampling/components';

import { FORM_NAME, LABELS } from '../../common';
import { useSampleSendState } from '../../useSampleSend';
import HeaderAdornment from '../HeaderAdornment';
import SelectDispatchCountry from '../SelectDispatchCountry';
import asyncValidate from './asyncValidate';

const styles = ({ spacing }) => ({
    container: {
        paddingBottom: spacing.unit * 6
    },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    }
});

const mapInputs = {
    hasManualReference: () => {
        return change(FORM_NAME, 'manualReference', '');
    },
    manualReference: ({ payload }) => {
        if (payload) return change(FORM_NAME, 'manualReferenceAsync', '');
    }
};

const mapState = state => ({
    hasManualReference: formValueSelector(FORM_NAME)(
        state,
        'hasManualReference'
    )
});

const Setup = compose(
    connect(
        mapState,
        null
    ),
    withStyles(styles),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['manualReference']
    }),
    formListener(mapInputs)
)(({ classes: c, hasManualReference }) => {
    const { setupLocked } = useSampleSendState();

    return (
        <ContentContainer
            title={LABELS.setup}
            AdornmentComponent={<HeaderAdornment />}
        >
            <GridContainer className={c.container}>
                <GridItem md={4}>
                    <SelectRetailers required disabled={setupLocked} />
                </GridItem>
                <GridItem md={4}>
                    <SelectDispatchCountry
                        name='originCountry'
                        label='Source Country'
                        placeholder='Select source country'
                        disabled={setupLocked}
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectDispatchCountry
                        name='destinationCountry'
                        label='Destination Country'
                        placeholder='Select destination country'
                        disabled={setupLocked}
                    />
                </GridItem>
                <Divider className={c.divider} />
                <GridItem md={6}>
                    <Checkbox
                        name='hasManualReference'
                        label='I want to give this a manual reference'
                        styles={{
                            label: c.checkboxLabel
                        }}
                        disabled={setupLocked}
                    />
                </GridItem>
                <GridItem md={3} />
                <GridItem md={3} />
                {hasManualReference && (
                    <GridItem md={6}>
                        <Input
                            name='manualReference'
                            placeholder='Enter manual reference'
                            required
                            disabled={!!setupLocked}
                        />
                    </GridItem>
                )}
            </GridContainer>
        </ContentContainer>
    );
});

export default Setup;
