import createCachedSelector from 're-reselect';

// Local
import { selectUtils } from './common';

export const selectAmendedField = createCachedSelector(
    selectUtils,
    (state, fieldName) => fieldName,
    ({ amends }, fieldName) => amends.includes(fieldName)
)((state, fieldName) => fieldName || 'empty');
