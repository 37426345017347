import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { DeleteIcon } from '@assets/Icons';
import { Button, Typography, withStyles } from '@material-ui/core';

const propTypes = {
    onClick: PropTypes.func.isRequired
};

const styles = ({ spacing, shape, typography }) => ({
    root: {
        display: 'flex',
        textTransform: 'none',
        padding: spacing.unit * 0.5,

        // Targets iconContainer with own hover state
        '& div': {
            opacity: 0.5
        },

        '&:hover': {
            '& div': {
                opacity: 1
            }
        }
    },
    iconContainer: {
        position: 'relative',
        width: spacing.unit * 1.5,
        height: spacing.unit * 1.875,
        marginRight: spacing.unit * 0.5,
        color: 'inherit'
    },
    icon: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 'auto',
        borderRadius: shape.borderRadius * 0.5
    },
    label: {
        fontWeight: typography.fontWeightSemi
    }
});

const DeleteAll = withStyles(styles)(({ classes: c, onClick }) => {

    return (
        <Button className={c.root} onClick={onClick}>
            <div className={c.iconContainer}>
                <DeleteIcon className={c.icon} />
            </div>
            <Typography className={c.label}>Delete all</Typography>
        </Button>
    );
});

DeleteAll.propTypes = propTypes;

export default DeleteAll;
