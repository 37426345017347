import { SET_REPORTS_NUM_PAGES, SET_REPORTS_PAGE } from 'store/actionTypes';

export const setReportsNumPages = payload => ({
    type: SET_REPORTS_NUM_PAGES,
    payload,
});

export const setReportsPage = payload => ({
    type: SET_REPORTS_PAGE,
    payload,
});
