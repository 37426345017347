import reduceReducer from 'reduce-reducers';

// Local
import initialState from './initialState';

// Reducers
import artworkReducer from './artwork';
import backnecksReducer from './backnecks';
import cadReducer from './cad';
import commonReducer from './common';
import fabricsReducer from './fabrics';
import inksReducer from './inks';
import otherReducer from './other';
import referenceReducer from './reference';
import requestReducer from './request';
import specReducer from './spec';
import trimsReducer from './trims';

// Action Types
import { RESET_SAMPLING_DATA, SIGN_OUT } from 'actionTypes';

const stemReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SIGN_OUT:
        case RESET_SAMPLING_DATA:
            return initialState;

        default: {
            return state;
        }
    }
};

const samplingPrintReducer = reduceReducer(
    initialState,
    artworkReducer,
    backnecksReducer,
    cadReducer,
    commonReducer,
    fabricsReducer,
    inksReducer,
    otherReducer,
    referenceReducer,
    requestReducer,
    specReducer,
    stemReducer,
    trimsReducer
);

export { samplingPrintReducer as default, samplingPrintReducer };
