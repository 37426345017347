import React from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    Dialog,
    DialogContent,
    Typography,
    DialogActions
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutlineRounded';

import { Button } from '@libComponents/Button';

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    promptText: PropTypes.string
};

const styles = ({ palette, spacing, typography }) => ({
    container: {
        width: spacing.unit * 53,
        height: spacing.unit * 42
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: `${spacing.unit * 6}px !important`,
        paddingBottom: 0
    },
    warningIcon: {
        width: spacing.unit * 10,
        height: spacing.unit * 10,
        color: palette.error.main
    },
    warningTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    promptText: {
        fontSize: '0.95rem',
        marginTop: spacing.unit
    },
    actions: {
        justifyContent: 'center',
        margin: spacing.unit * 5
    },
    button: {
        width: spacing.unit * 19,
        height: spacing.unit * 5,
        fontSize: typography.subtitle1.fontSize
    }
});

const CancelDialog = withStyles(styles)(
    ({
        classes: c,
        open,
        onClose,
        onConfirm,
        promptText = 'Do you really want to cancel all entries?'
    }) => {
        return (
            <Dialog
                classes={{ paper: c.container }}
                open={open}
                onClose={onClose}
            >
                <DialogContent className={c.content}>
                    <WarningIcon className={c.warningIcon} />
                    <div className={c.warningTextContainer}>
                        <Typography variant='h4'>Are you sure?</Typography>
                        <Typography className={c.promptText}>
                            {promptText}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions className={c.actions}>
                    <Button
                        className={c.button}
                        color='error'
                        onClick={onClose}
                    >
                        No
                    </Button>
                    <Button className={c.button} onClick={onConfirm}>
                        Yes, do it!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

CancelDialog.propTypes = propTypes;

export { CancelDialog as default, CancelDialog };
