import React from 'react';
import uuid from 'uuid/v1';

import { addListeners, removeListeners } from './index';

const formListener = mapFormToActions => Component => {
    class WrappedComponent extends React.Component {
        state = {
            listenerIDs: []
        };

        componentDidMount() {
            const { form, mode } = this.props;
            const mapFormToListeners = Object.entries(mapFormToActions).reduce(
                (map, [field, action]) => [
                    ...map,
                    { form, field, action, mode, id: uuid() }
                ],
                []
            );

            this.setState({
                listenerIDs: mapFormToListeners.map(({ id }) => id)
            });

            addListeners(mapFormToListeners);
        }

        componentWillUnmount() {
            removeListeners(this.state.listenerIDs);
        }

        render() {
            const { props } = this;
            return <Component {...props} />;
        }
    }

    return props => <WrappedComponent {...props} />;
};

export { formListener as default, formListener };
