import React from 'react';

import { withStyles, FormControlLabel } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';
import CheckboxBase from '@baseComponents/Checkbox';

const styles = ({ spacing, palette }) => ({
    root: { width: '100%' },
    container: {
        paddingLeft: spacing.unit * 2,
        '&:hover': {
            backgroundColor: palette.grey[300],
            borderRadius: 'none'
        }
    }
});

const Checkbox = withStyles(styles)(
    ({
        value,
        label,
        styles,
        classes: { container, ...classes },
        onClick = f => f,
        disabled,
        ...rest
    }) => {
        return (
            <div className={container} {...rest}>
                <FormControlLabel
                    control={
                        <CheckboxBase
                            checked={!!value}
                            styles={styles}
                            onClick={onClick}
                            disabled={disabled}
                        />
                    }
                    label={label}
                    classes={composeClsx({ classes, styles })}
                />
            </div>
        );
    }
);

export default Checkbox;
