import React from 'react';
import PropTypes from 'prop-types';

import InputGroupSelect from '@baseComponents/InputGroupSelect';
import { number, mustBeGreaterThanZero } from '@libs/validationRules';
import { customRequired } from './EchoPrice';

import { currencyData } from '../../../../../../customData';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'fobPrice',
    label: 'FOB Price in PC',
    placeholder: 'FOB Price',
    required: true,
    validate: [customRequired, number, mustBeGreaterThanZero],
    inputProps: { type: 'number' }
};

const FOBPrice = props => (
    <InputGroupSelect {...props} selectData={currencyData} />
);

FOBPrice.propTypes = propTypes;
FOBPrice.defaultProps = defaultProps;

export default FOBPrice;
