import { Dashboard } from 'views';
import { Dashboard as DashboardIcon } from 'assets/Containers';

export default {
	'1': {
		component: Dashboard,
		url: '/home',
		icon: DashboardIcon,
		depth: 1,
		exact: true
	}
};
