import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Local
import MultiSelectModel from '@formComponents/MultiSelectModel';
import { DOMAIN_NAME } from 'orders/common';

import { gridDatasetSelector } from '@libs/datasets';

import { CP_GRID_DATA } from 'orders/redux.datasets';

const dataSelector = createCachedSelector(gridDatasetSelector, ({ data }) =>
    data
        .filter(({ orderStatus }) => orderStatus === 'ONTIME')
        .map(({ id, factoryRef, poNo, packId }) => ({
            label: packId
                ? `${factoryRef}__${poNo}__${packId}`
                : `${factoryRef}__${poNo}`,
            value: id
        }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'rowIds',
    label: '',
    domain: DOMAIN_NAME,
    entity: CP_GRID_DATA,
    disabled: false,
    required: true
};

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity)
});

const SelectMultipleRows = connect(mapState)(props => {
    return <MultiSelectModel {...props} />;
});

SelectMultipleRows.propTypes = propTypes;
SelectMultipleRows.defaultProps = defaultProps;

export default SelectMultipleRows;
