import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectModel from '@formComponents/SelectModel';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

import { DOMAIN_NAME } from '../common';
import { TYPES } from '../redux.datasets';
import { cadTypesSelector } from '../redux.selectors';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'type',
    label: 'Type',
    placeholder: 'Select type',
    disabled: false,
    required: false
};

const mapStateToProps = state => ({
    data: cadTypesSelector(state),
    loading: datasetLoadingSelector(state, DOMAIN_NAME, TYPES)
});

const mapDispatchToProps = dispatch => ({
    fetchDataset: () => dispatch(fetchDatasets(TYPES))
});

const SelectTypes = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectTypes.propTypes = propTypes;
SelectTypes.defaultProps = defaultProps;

export { SelectTypes as default, SelectTypes };
