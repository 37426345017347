import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import ContentWrapper from './_ContentWrapper';

const _SnackbarBase = memo(function _SnackbarBase(props) {
	const { open, variant, message, onClose } = props;
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			open={open}
			autoHideDuration={4000}
			onClose={onClose}
		>
			<ContentWrapper
				onClose={onClose}
				variant={variant}
				message={message}
			/>
		</Snackbar>
	);
});

_SnackbarBase.defaultProps = {
	message: '',
	variant: '',
	onClose: () => {}
};

_SnackbarBase.propTypes = {
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

export { _SnackbarBase as default, _SnackbarBase as SnackbarBase };
