import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import classNames from 'classnames';
import { useTable, useRowSelect } from 'react-table';

// Material
import { withStyles, Paper } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../MultipleSelectDialogContext';
import Header from './Header';
import MenuItem from './MenuItem';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * DialogContent
 */
const DialogContent = ({
    classes: c,
    dialogState: { data = [], checkedItems = [], allChecked } = {},
    dialogDispatch
}) => {
    const [shouldScroll, setScroll] = useState(false);
    const contentRef = useRef(null);
    const menusRef = useRef(null);

    const handleCheckboxClick = useCallback(
        value => {
            dialogDispatch({ type: 'set-checked-items', payload: value });
        },
        [dialogDispatch]
    );

    useEffect(() => {
        if (menusRef.current && contentRef.current) {
            const contentNode = ReactDOM.findDOMNode(contentRef.current);
            const menusNode = ReactDOM.findDOMNode(menusRef.current);

            const menusHeight = menusNode.offsetHeight;
            const contentHeight = contentNode.offsetHeight + 1; // Border + header

            setScroll(menusHeight > contentHeight);
        }
    }, [data]);

    return (
        <Paper ref={contentRef} elevation={0} className={c.root}>
            {data.length !== 0 && (
                <Header
                    onClick={() => handleCheckboxClick(0)}
                    value={allChecked}
                />
            )}

            <Paper
                className={classNames(
                    c.root,
                    c.menus,
                    shouldScroll && c.scroll
                )}
            >
                <div ref={menusRef}>
                    {data.map(({ label, value }, idx) => {
                        return (
                            <MenuItem
                                label={label}
                                value={checkedItems.includes(value)}
                                key={idx}
                                onClick={() => handleCheckboxClick(value)}
                            />
                        );
                    })}
                </div>
            </Paper>
        </Paper>
    );
};

const _DialogContent = compose(
    withDialogContext,
    withStyles(styles)
)(DialogContent);

export { _DialogContent as default, _DialogContent as DialogContent };
