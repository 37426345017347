import makeContext from '@libs/makeContext';
import isBlobUrl from '@libs/isBlobUrl';

const initialState = {
    value: '',
    filename: '',
    isDragging: false,
    excessUploadLimit: false,
    isValidType: true
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'setFilename': {
            return {
                ...state,
                filename: payload,
                excessUploadLimit: false,
                isDragging: false,
                isValidType: true
            };
        }
        case 'setExcessUplodLimit': {
            return {
                ...state,
                excessUploadLimit: true,
                isDragging: false,
                isValidType: true
            };
        }
        case 'setValidType': {
            return {
                ...state,
                excessUploadLimit: false,
                isDragging: false,
                isValidType: false
            };
        }
        case 'setDragging': {
            return {
                ...state,
                isDragging: payload
            };
        }

        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addFile': {
            const { value, uploadConstraints: { accept } = {} } = state;
            const { payload = {} } = action;
            const payloadSize = parseInt(
                (payload.size / (1024 * 1024)).toFixed(1),
                10
            );
            // If file type is not desired
            if (!payload.type.includes(accept)) {
                return { type: 'setValidType' };
            }
            // if file size more than 15MB then system will restrict user to upload file
            if (payloadSize > 15) {
                return { type: 'setExcessUplodLimit' };
            }
            // Remove if blob already exists
            if (isBlobUrl(value)) URL.revokeObjectURL(value);
            // From FileList to Array
            const blobUrl = URL.createObjectURL(payload);
            reduxFormOnChange({ url: blobUrl, filename: payload.name });
            // return {};
            return { type: 'setFilename', payload: payload.name };
        }

        case 'removeFile': {
            const { value } = state;
            // Cleanup if blob
            if (isBlobUrl(value)) URL.revokeObjectURL(value);

            reduxFormOnChange('');
            return {};
        }

        default:
            return action;
    }
};

const [
    UploadProvider,
    useUploadState,
    useUploadDispatch,
    useUpload
] = makeContext(reducer, initialState, {
    name: 'Upload',
    middleware
});

export {
    useUpload as default,
    UploadProvider,
    useUploadState,
    useUploadDispatch
};
