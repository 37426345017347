import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@formComponents';
import { required, number, minValue, maxValue } from '@libs/validationRules';

import { MAX_PACKS } from '../../common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'noOfPacks',
    label: 'Number of Packs',
    type: 'number',
    required: true,
    disabled: false,
    validate: [required, number, minValue(1), maxValue(MAX_PACKS)]
};

const NumberOfPacks = props => <Input {...props} />;

NumberOfPacks.propTypes = propTypes;
NumberOfPacks.defaultProps = defaultProps;

export { NumberOfPacks as default, NumberOfPacks };
