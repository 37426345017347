import { useEffect, useState, useCallback } from 'react';

import isBlobUrl from '@libs/isBlobUrl';
import axios from 'axios';

export const useDownloadAll = attachments => {
    const canDownload = !!attachments.length;

    const [blobUrls, setBlobUrls] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDownloadAll = useCallback(() => {
        if (!!blobUrls.length) {
            blobUrls.forEach(({ url, filename }) => {
                const anchor = document.createElement('a');

                anchor.href = url;
                anchor.download = filename;

                anchor.click();
            });
        }
    }, [canDownload, blobUrls, loading]);

    useEffect(() => {
        let subscribed = true;
        if (canDownload) {
            (async () => {
                setLoading(true);
                const data = await Promise.all(
                    attachments.map(async ({ url, filename }) => {
                        let attachmentBlob = '';
                        let isServerImage = true;
                        if (!isBlobUrl(url)) {
                            const { data } = await axios.get(url, {
                                responseType: 'blob',
                                headers: {
                                    Authorization: ''
                                }
                            });
                            attachmentBlob = URL.createObjectURL(data);
                        } else {
                            attachmentBlob = url;
                            isServerImage = false;
                        }

                        return { url: attachmentBlob, filename, isServerImage };
                    })
                );
                if (subscribed) {
                    setBlobUrls(data);
                    setLoading(false);
                }
            })();
        }
        return () => {
            subscribed = false;
        };
    }, [canDownload, attachments]);

    useEffect(() => {
        return () => {
            blobUrls.forEach(({ url, isServerImage }) => {
                if (isServerImage) {
                    URL.revokeObjectURL(url);
                }
            });
        };
    }, [blobUrls]);

    return { blobUrls, loading, canDownload, handleDownloadAll };
};
