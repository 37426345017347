import { userIdSelector } from 'auth/redux.selectors';

export const FABRIC_SUSTAINABILITY = '@fabric-sustainability';
export const FABRIC_GROUP = '@fabric-group';
export const RETAILER_BASE = '@retailer-base';
export const PO_SIZE_SET = '@po-size-set';
export const TRIM_LABEL_TYPE = '@trim-label-type';
export const TRIM_LABEL = '@trim-label';
export const COLOURS = '@colours';

export default {
    [FABRIC_SUSTAINABILITY]: {
        url: 'FabricSustainability'
    },
    [FABRIC_GROUP]: {
        url: 'FabricGroup'
    },
    [RETAILER_BASE]: {
        url: 'Retailer'
    },
    [PO_SIZE_SET]: {
        url: 'PoSizes',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [TRIM_LABEL_TYPE]: {
        url: 'OrderBasicTrimTypes'
    },
    [TRIM_LABEL]: {
        url: 'OrderBasicTrims',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [COLOURS]: {
        url: 'Colours'
    }
};
