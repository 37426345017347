import { arrowGenerator } from 'helpers';

export default ({ palette, spacing }) => ({
    button: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: spacing.unit * 15,
        height: spacing.unit * 6,
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        display: 'block',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    },
});
