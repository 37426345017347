import React from 'react';

import { Button, withStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const propTypes = {
    handleClick: PropTypes.func.isRequired
};
const styles = ({ palette, spacing, shadows, typography }) => ({
    root: {
        width: '100%',
        minHeight: 'var(--headerFooterHeight)',
        display: 'flex',
        backgroundColor: palette.common.white,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: palette.background.dark
    },
    text: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: spacing.unit * 4,
        color: palette.background.dark,
        fontSize: '0.95rem',
        borderRadius: 0,
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: palette.common.transparent
        }
    },
    button: {
        minWidth: spacing.unit * 16.5,
        height: '100%',
        color: palette.common.white,
        fontSize: '0.95rem',
        fontWeight: typography.fontWeightSemi,
        borderRadius: 0,
        textTransform: 'none',
        boxShadow: shadows[0],
        background: `linear-gradient(to bottom, ${palette.success.main}, ${palette.success.dark})`,

        '&:hover': {
            background: `linear-gradient(to bottom, ${palette.success.dark}, ${palette.success.dark})`
        }
    }
});

const UploadHandleBar = withStyles(styles, { withTheme: true })(
    ({ classes: c, handleClick }) => (
        <div className={c.root}>
            <Button className={c.text} variant='text' onClick={handleClick}>
                Upload CAD versions
            </Button>
            <Button className={c.button} variant='text' onClick={handleClick}>
                Add version
            </Button>
        </div>
    )
);

UploadHandleBar.propTypes = propTypes;

export default UploadHandleBar;
