import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing, palette, typography }) => ({
    error: {
        width: '100%',
        marginTop: spacing.unit * 0.5,
        marginBottom: spacing.unit * 0.5,
        color: palette.error.main,
        fontSize: typography.caption.fontSize,
        lineHeight: 1.2
    }
});

const propTypes = {
    message: PropTypes.string.isRequired
};

const defaultProps = {
    message: 'Required!'
};

const _Error = withStyles(styles)(({ classes: c, message, styles }) => {
    return (
        <Typography className={c.error} style={styles}>
            {message}
        </Typography>
    );
});

_Error.propTypes = propTypes;
_Error.defaultProps = defaultProps;

export { _Error as default, _Error as Error };
