import artworkState from './artwork';
import cadState from './cad';
import fabricsState from './fabrics';
import inksState from './inks';
import referenceState from './reference';
import requestState from './request';
import specState from './spec';
import trimsState from './trims';

export default {
    ...artworkState,
    ...cadState,
    ...fabricsState,
    ...inksState,
    ...referenceState,
    ...requestState,
    ...specState,
    ...trimsState
};
