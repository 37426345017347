import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

const styles = {
    frameWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flex: 1
    },
    iframe: {
        flex: 1
    }
};

const Iframe = ({ classes, src, title }) => (
    <div className={classes.frameWrapper}>
        <iframe src={src} className={classes.iframe} title={title}>
            Your browser does not support inline frames.
        </iframe>
    </div>
);

Iframe.propTypes = {
    src: PropTypes.string,
    title: PropTypes.string
};

Iframe.defaultProps = {
    title: 'Embedded Content'
};

const withStylesIframe = withStyles(styles)(Iframe);

export { withStylesIframe as default, withStylesIframe as Iframe };
