import React from 'react';
import PropTypes from 'prop-types';

// Material
import { CircularProgress, withStyles } from '@material-ui/core';

const propTypes = {
    size: PropTypes.number
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        backgroundColor: palette.background.light
    },
    loader: {
        width: spacing.unit * 7.5,
        height: spacing.unit * 7.5
    }
});

const LoaderCard = withStyles(styles, { withTheme: true })(
    ({ classes: c, size, theme: { spacing } }) => (
        <div className={c.root}>
            <CircularProgress size={size || spacing.unit * 7.5} />
        </div>
    )
);

LoaderCard.propTypes = propTypes;
LoaderCard.defaultProps = defaultProps;

export default LoaderCard;
