import React from 'react';

const Pdf = ({ color: fill }) => (
    <svg
        id='pdf'
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='17'
        viewBox='0 0 17 17'
    >
        <path
            id='Path_155'
            d='M14.7,17H.5a.472.472,0,0,1-.5-.5v-8A.536.536,0,0,1,.5,8a.536.536,0,0,1,.5.5V16H14.2V14.9a.5.5,0,0,1,1,0v1.6a.472.472,0,0,1-.5.5Z'
            fill={fill ? fill : '#234c72'}
        />
        <g id='Group_39' transform='translate(0.504 0.494)'>
            <path
                id='Path_156'
                d='M0,5.4V5.2L5.3,0V5.4Z'
                fill={fill ? fill : '#f40f02'}
            />
        </g>
        <path
            id='Path_157'
            d='M16.5,6.8H15.2V.5a.472.472,0,0,0-.5-.5H5.2L.1,5.4h0v.3a.472.472,0,0,0,.5.5H5.9a.472.472,0,0,0,.5-.5V1h7.9V6.8H6.5a.472.472,0,0,0-.5.5v5.4a.472.472,0,0,0,.5.5h10a.472.472,0,0,0,.5-.5V7.3A.472.472,0,0,0,16.5,6.8ZM1.6,5.4,5.3,1.7V5.4ZM16,12.2H7V7.8h9Z'
            fill={fill ? fill : '#234c72'}
        />
        <rect
            id='Rectangle_29'
            width='10'
            height='5.4'
            transform='translate(6.504 7.294)'
            fill={fill ? fill : '#f40f02'}
        />
    </svg>
);

export { Pdf as default, Pdf };
