import {
    FETCH_GRID_DATA,
    SET_GRID_DATA,
    SET_GRID_LOADING,
    GRID_DATA_UPDATE,
    GRID_ROW_UPDATE_LOADING,
    GRID_EXCEL_EXPORTING,
    GRID_PDF_PAGE_EXPORTING,
    SET_GRID_REPORT_LOADING,
    FETCH_ACCUMULATED_DATA,
    SET_ACCUMULATED_DATA,
    SET_GRID_FILTER_RETAILER_CHILDREN,
    SET_GRID_FILTER_PROPERTY_VALUE,
    FETCH_PREVIEW_DATA,
    SET_PREVIEW_DATA,
    SET_PREVIEW_LOADING,
    GRID_ALL_COLUMNS_EXCEL_EXPORTING,
    GRID_EDIT_HANDLE,
    GRID_ALL_ROWS_EXCEL_EXPORTING
} from './redux.actionTypes';

export const fetchGridData = ({ entity, endpoint, accuShow, moduleName }) => ({
    pageNumber = 1,
    pageSize = 10,
    ...rest
}) => ({
    type: FETCH_GRID_DATA,
    meta: { entity, endpoint, accuShow, moduleName },
    payload: {
        pageNumber,
        pageSize,
        ...rest
    }
});

export const setGridData = ({
    entity,
    totalCount,
    pageSize,
    currentPage,
    totalPages,
    nextPage,
    previousPage,
    dataset
}) => ({
    type: SET_GRID_DATA,
    meta: { entity },
    payload: {
        totalCount,
        pageSize,
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        dataset
    }
});

export const setGridLoading = ({ entity, loading }) => ({
    type: SET_GRID_LOADING,
    meta: { entity },
    payload: loading
});

export const gridUpdate = ({
    entity,
    endpoint,
    accuShow,
    moduleName
}) => payload => ({
    type: GRID_DATA_UPDATE,
    meta: { endpoint, accuShow, moduleName, entity },
    payload
});

export const setGridRowLoading = ({ rowId, value }) => ({
    type: GRID_ROW_UPDATE_LOADING,
    payload: { rowId, value }
});

export const gridExcelExporting = ({ fileName, data, reportType }) => ({
    type: GRID_EXCEL_EXPORTING,
    meta: { reportType },
    payload: { fileName, data }
});

export const gridPdfPageExporting = ({
    fileName,
    data,
    module,
    reportType
}) => ({
    type: GRID_PDF_PAGE_EXPORTING,
    meta: { module, reportType },
    payload: { fileName, data }
});

export const setReportLoading = payload => ({
    type: SET_GRID_REPORT_LOADING,
    payload
});

export const fetchAccumulatedData = moduleName => ({
    type: FETCH_ACCUMULATED_DATA,
    payload: moduleName
});

export const setAccumulatedData = ({ moduleName, accumulation }) => ({
    type: SET_ACCUMULATED_DATA,
    meta: { moduleName },
    payload: accumulation
});

// filter form in gridView
export const setGridFilterRetailerChildren = () => ({
    type: SET_GRID_FILTER_RETAILER_CHILDREN
});

export const setGridFilterPropertyValue = () => ({
    type: SET_GRID_FILTER_PROPERTY_VALUE
});

export const fetchPreviewData = ({ entity, endpoint, payload }) => ({
    type: FETCH_PREVIEW_DATA,
    meta: { entity, endpoint },
    payload
});

export const setPreviewData = ({ entity, dataset }) => ({
    type: SET_PREVIEW_DATA,
    meta: { entity },
    payload: dataset
});

export const setPreviewLoading = ({ entity, loading }) => ({
    type: SET_PREVIEW_LOADING,
    meta: { entity },
    payload: loading
});

export const gridAllColumnsExcelExporting = ({
    fileName,
    rowIds,
    moduleName,
    retailerId
}) => ({
    type: GRID_ALL_COLUMNS_EXCEL_EXPORTING,
    meta: { fileName, reportType: 'allColumnsXlsx' },
    payload: { rowIds, moduleName, retailerId }
});

export const gridAllRowsExcelExporting = ({ fileName, ...payload }) => ({
    type: GRID_ALL_ROWS_EXCEL_EXPORTING,
    meta: { fileName, reportType: 'allRowsXlsx' },
    payload
});

export const gridEditHandle = payload => ({
    type: GRID_EDIT_HANDLE,
    payload
});
