import { useState, useEffect, useCallback } from 'react';

export default (columns, storedDataGridSettings, setDataGridSettings) => {
    const frozenColumnsSettingsKey = `frozenColumns`;

    const [frozenColumns, setFrozenColumns] = useState(() => {
        const storedFrozenColumns =
            storedDataGridSettings[frozenColumnsSettingsKey];
        if (storedFrozenColumns) {
            const columnNames = columns.map(column => column.name);
            // Filter the stored frozen columns to keep only those that exist in the current 'columns' array.
            const columnsAfterDelete = storedFrozenColumns.filter(column =>
                columnNames.includes(column)
            );
            // Return the initial state for frozen columns
            return columnsAfterDelete;
        }
        // If there are no stored frozen columns, use the following logic:
        // - Filter the 'columns' array to include only columns with 'defaultFreezable' set to true.
        // - Extract the names of these columns and return them as the initial state for 'frozenColumns'.
        return columns
            .filter(column => column.defaultFreezable === true)
            .map(column => column.name);
    });

    const freezeColumn = useCallback(
        columnName => {
            if (!frozenColumns.includes(columnName)) {
                setFrozenColumns(prevFrozenColumns => [
                    ...prevFrozenColumns,
                    columnName
                ]);
            }
        },
        [frozenColumns]
    );

    const unfreezeColumn = useCallback(
        columnName => {
            setFrozenColumns(prevFrozenColumns =>
                prevFrozenColumns.filter(column => column !== columnName)
            );
        },
        [frozenColumns]
    );

    const isColumnFrozen = useCallback(
        columnName => {
            return frozenColumns.includes(columnName);
        },
        [frozenColumns]
    );

    const clearColumnFreezeSettings = useCallback(() => {
        const defaultColumnsFreezableList = columns
            .filter(column => column.defaultFreezable === true)
            .map(column => column.name);
        setFrozenColumns(defaultColumnsFreezableList);
    }, [columns]);

    useEffect(() => {
        setDataGridSettings({ [frozenColumnsSettingsKey]: frozenColumns });
    }, [frozenColumns, setDataGridSettings]);

    return {
        frozenColumns,
        freezeColumn,
        unfreezeColumn,
        isColumnFrozen,
        clearColumnFreezeSettings
    };
};
