import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_NORMAL, FONT_FAMILY_BOLD } from 'common/constants';

import generatePieChartImage from './generatePieChartImage';
import useDynamicColors from 'sampling/SamplingReport/hooks';
import useDynamicPieChartStyles from './hooks';

const styles = ({ spacing, palette }) => ({
    container: {
        border: 1,
        borderColor: palette.grey[300],
        margin: spacing.unit * 0.5
    },
    chartTitle: {
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.5,
        padding: spacing.unit * 1,
        backgroundColor: palette.background.light
    },
    pieChartContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: spacing.unit
    },
    imageContainer: {
        marginRight: spacing.unit
    },
    image: {
        width: '100%',
        height: '100%'
    },
    legendContainer: {},
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: spacing.unit * 0.5,
        marginRight: spacing.unit
    },
    colorBox: {
        width: spacing.unit * 1.5,
        height: spacing.unit * 1.5,
        marginRight: spacing.unit * 0.5
    },
    legendText: {
        fontFamily: FONT_FAMILY_NORMAL,
        fontSize: spacing.unit * 1.25,
        marginRight: spacing.unit * 0.5,
        lineHeight: 1.4
    }
});

const Chart = withPDFStyles(styles, { withTheme: true })(
    ({
        theme: { spacing },
        classes: c,
        chartTitle,
        data,
        view,
        cellSettings
    }) => {
        const [base64Image, setBase64Image] = useState('');
        const visibleCells = useMemo(
            () => data.filter(cell => cellSettings[cell.name] === undefined),
            [data]
        );
        const colors = useDynamicColors(visibleCells);
        const {
            pieChartWidth,
            imageContainerSize,
            legendItemWidth
        } = useDynamicPieChartStyles(view, spacing);

        useEffect(() => {
            const image = generatePieChartImage({
                data: visibleCells,
                colors,
                fontSize: view === '1x1' ? 12 : 8,
                ...(view === '1x1'
                    ? { width: 400, height: 400, innerRadius: 50 }
                    : { width: 200, height: 200, innerRadius: 30 })
            });
            setBase64Image(image);
        }, [visibleCells, colors]);

        if (!base64Image) {
            return null;
        }

        return (
            <View style={{ ...c.container, width: pieChartWidth }} wrap={false}>
                <Text style={c.chartTitle}>{chartTitle}</Text>
                <View style={c.pieChartContainer}>
                    <View
                        style={{
                            ...c.imageContainer,
                            width: imageContainerSize,
                            height: imageContainerSize
                        }}
                    >
                        <Image style={c.image} src={base64Image} />
                    </View>
                    <View style={c.legendContainer}>
                        {visibleCells.map((item, index) => (
                            <View
                                key={index}
                                style={{
                                    ...c.legendItem,
                                    width: legendItemWidth
                                }}
                            >
                                <View
                                    style={{
                                        ...c.colorBox,
                                        backgroundColor: colors[item.name]
                                    }}
                                />
                                <Text style={c.legendText}>{item.value}</Text>
                                <Text style={c.legendText}>{item.name}</Text>
                            </View>
                        ))}
                    </View>
                </View>
            </View>
        );
    }
);

export default Chart;
