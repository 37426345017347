import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Libs
import { valueSelector } from '@libs/datasets';
import BaseCard from '@baseComponents/Card';

// Local
import { DOMAIN_NAME } from '../../../common';
import {
    FABRIC_FORS,
    FABRIC_TYPES,
    COMPOSITIONS,
    DYE_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from '../../../redux.datasets';
import Title from './Title';
import ActionsAdornment from './ActionsAdornment';
import { useFabricDetails } from './hooks';

const propTypes = {
    fabricFor: PropTypes.number,
    fabricType: PropTypes.number.isRequired,
    composition: PropTypes.number.isRequired,
    finishedGsm: PropTypes.number.isRequired,
    dyeType: PropTypes.number,
    specialFabricRef: PropTypes.string,
    specialFinish: PropTypes.number,
    fabricColour: PropTypes.string,
    eld: PropTypes.string,
    isDischargeable: PropTypes.bool,
    isYds: PropTypes.bool,
    isEngineeredStripe: PropTypes.bool,
    fabFrom: PropTypes.string,
    isTc: PropTypes.bool,
    tcText: PropTypes.string,

    includeTc: PropTypes.bool.isRequired,
    handleDuplicate: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
};

const mapState = (
    state,
    { fabricFor, fabricType, composition, finishedGsm, dyeType, specialFinish }
) => ({
    fabricFor: valueSelector(state, DOMAIN_NAME, FABRIC_FORS, fabricFor),
    fabricType: valueSelector(state, DOMAIN_NAME, FABRIC_TYPES, fabricType),
    composition: valueSelector(state, DOMAIN_NAME, COMPOSITIONS, composition),
    finishedGsm: valueSelector(state, DOMAIN_NAME, FINISHED_GSMS, finishedGsm),
    dyeType: valueSelector(state, DOMAIN_NAME, DYE_TYPES, dyeType),
    specialFinish: valueSelector(
        state,
        DOMAIN_NAME,
        SPECIAL_FINISHES,
        specialFinish
    )
});

// Normalize fabrics into strings from values
const Card = connect(mapState)(
    ({ fabricFor, handleDuplicate,handleEdit, handleDelete, ...props }) => {
        const details = useFabricDetails(props);

        return (
            <BaseCard
                title={<Title value={fabricFor} />}
                ActionsAdornment={
                    <ActionsAdornment
                        handleDuplicate={handleDuplicate}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                }
                details={details}
                minAmountShown={10}
            />
        );
    }
);

Card.propTypes = propTypes;

export { Card as default, Card };
