import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    isSubmitting,
    initialize as formInitialize,
    destroy as formDestroy
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';
import { useValidate, useNext } from '@libHooks/useValidate';
import { formErrorsSelector } from 'common/redux.selectors';

import useSchema from './content/useSchema';
import { UserProvider } from './useUser';
import { useHandles, useContentFields } from './hooks';
import { FORM_NAME, INITIAL_VALUES } from './common';
import { FETCH_USER_PERMISSIONS_DATA } from '../redux.actionTypes';
import { userPublish } from '../redux.actions';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => ({
    formValues: getFormValues(FORM_NAME)(state),
    formErrors: formErrorsSelector(state, FORM_NAME),
    formSubmitting: isSubmitting(FORM_NAME)(state)
});

const mapDispatch = {
    publish: userPublish,
    formInitialize,
    formDestroy,
    fetchUserPermissionsData: () => ({ type: FETCH_USER_PERMISSIONS_DATA })
};

const SampleDispatchContainer = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        publish,
        formInitialize,
        formDestroy,
        fetchUserPermissionsData,
        mode,
        handleReduxFormSubmit
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const formFields = useContentFields(activeStep);
        const { role, reportTo, hasUserData } = formValues;

        const [userFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            formFields,
            FORM_NAME
        );

        const handleDispatchFormSubmit = useCallback(() => {
            handleReduxFormSubmit();
            handleScroll();
        }, [handleReduxFormSubmit, handleScroll]);

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );

        const schema = useSchema(mode, formValues);
        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);
        const canResetForm = useMemo(() => {
            return !!Object.keys(formValues).length;
        }, [formValues]);
        const shouldFetchData = mode !== 'new' && activeStep === 0;
        /**
         *  On amend mode, initially user setup step is locked until don't fetch the user data from server.
         *  This prevents user to input data without fetching specfic user data. We change hasUserData flag to true
         *  When we successfully fetch data from server when select a user from dropdown. Now user can amend data.
         */
        const setupLocked = mode === 'amend' && !hasUserData;

        const [handleNext, handlePublish, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish
        });

        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleDispatchFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep]);

        const initialize = useCallback(
            state => ({
                ...state,
                mode,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                handlePublish,
                reset,
                canResetForm,
                shouldFetchData,
                setupLocked
            }),
            [
                mode,
                areValid.saveDraft,
                areValid.publish,
                handlePublish,
                reset,
                canResetForm,
                shouldFetchData,
                setupLocked
            ]
        );

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => formDestroy(FORM_NAME);
        }, [mode]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        useEffect(() => {
            if (role) {
                fetchUserPermissionsData();
            }
        }, [role, JSON.stringify(reportTo)]);

        return (
            <Paper className={c.container}>
                <UserProvider initialize={initialize}>
                    <div ref={userFormRef}>{children}</div>
                </UserProvider>
                {!complete && (
                    <Button
                        className={c.button}
                        onClick={handleNextWithSubmit}
                        loading={formSubmitting}
                    >
                        {proceedText}
                    </Button>
                )}
            </Paper>
        );
    }
);

export default SampleDispatchContainer;
