import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from 'auth/redux.actionTypes';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
