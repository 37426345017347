import {
    GET_COPY_FABRIC_DATA,
    FABRIC_DEVELOPMENT_REFERENCE_GENERATE,
    SET_FABRIC_DEVELOPMENT_REFERENCE_GENERATE_LOADING,
    GET_FABRIC_DEVELOPMENT_REFERENCE_DATA,
    FABRIC_DEVELOPMENT_PUBLISH,
    ROUTE_TO_FABRIC_DEVELOPMENT,
    DESTROY_FABRIC_DEVELOPMENT_FORM,
    FABRIC_REPORT_MAIL_SEND,
    SET_FABRIC_EXPORT_XL_DATA_LOADING
} from './redux.actionTypes';

export const getCopyFabricData = fabricReference => ({
    type: GET_COPY_FABRIC_DATA,
    payload: fabricReference
});
export const fabricDevelopmentReferenceGenerate = () => ({
    type: FABRIC_DEVELOPMENT_REFERENCE_GENERATE
});
export const setFabricDevelopmentReferenceLoading = loading => ({
    type: SET_FABRIC_DEVELOPMENT_REFERENCE_GENERATE_LOADING,
    payload: loading
});
export const getFabricDevelopmentReferenceData = reference => ({
    type: GET_FABRIC_DEVELOPMENT_REFERENCE_DATA,
    payload: reference
});
export const fabricDevelopmentPublish = ({
    handleComplete,
    closeModals,
    ...payload
}) => ({
    type: FABRIC_DEVELOPMENT_PUBLISH,
    meta: { handleComplete, closeModals },
    payload
});

export const fabricDevelopmentSaveDraft = ({ mode }) => ({
    type: FABRIC_DEVELOPMENT_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});

export const routeToFabricDevelopment = mode => ({
    type: ROUTE_TO_FABRIC_DEVELOPMENT,
    meta: { mode }
});

export const destroyFabricDevelopmentForm = () => ({
    type: DESTROY_FABRIC_DEVELOPMENT_FORM
});

export const fabricReportMailSend = ({
    fabricRef,
    versionNo,
    closeModals
}) => ({
    type: FABRIC_REPORT_MAIL_SEND,
    meta: { closeModals },
    payload: { fabricRef, versionNo }
});

export const setFabricExportXLLoading = loading => ({
    type: SET_FABRIC_EXPORT_XL_DATA_LOADING,
    payload: loading
});
