import {
    SET_REPORTS_NUM_PAGES,
    SET_REPORTS_PAGE,
    SET_REPORTS_VIEW_MODE
} from 'actionTypes';

const paginationReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SET_REPORTS_NUM_PAGES: {
            const { numPages } = payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    numPages
                }
            };
        }

        case SET_REPORTS_PAGE: {
            const { pageNumber } = payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageNumber
                }
            };
        }

        case SET_REPORTS_VIEW_MODE: {
            const { viewMode } = payload;
            return {
                ...state,
                viewMode,
                pagination: {
                    ...state.pagination,
                    pageNumber: 1
                }
            };
        }

        default: {
            return state;
        }
    }
};

export { paginationReducer as default, paginationReducer };
