import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import Images from './Images';
import DataColumns from './DataColumns';

const propTypes = {
    images: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    isLast: PropTypes.bool.isRequired
};

const styles = ({ spacing, palette }) => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderBottomWidth: 1,
        paddingBottom: spacing.unit * 4,
        marginBottom: spacing.unit * 4,
        height: 'auto'
    }
});

const Row = withPDFStyles(styles)(({ images, columns, isLast, classes: c }) => {
    return (
        <View
            style={{
                ...c.row,
                ...(isLast
                    ? {
                          borderBottomWidth: 0,
                          marginBottom: 0,
                          paddingBottom: 0
                      }
                    : {})
            }}
        >
            <Images images={images} />
            <DataColumns columns={columns} />
        </View>
    );
});

Row.propTypes = propTypes;

export default Row;
