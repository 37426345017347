import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectMenus } from '../../store/selectors/auth';

const mapState = state => ({ menus: selectMenus(state) });

const Navigation = compose(connect(mapState))(({ menus }) => {
    console.log('menus', menus);
    return <div />;
});

export { Navigation };
