import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing, palette, shape }) => ({
    imageInfo: {
        position: 'absolute',
        bottom: spacing.unit * 2.5,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: palette.grey[200],
        padding: spacing.unit * 1,
        display: 'flex',
        alignItems: 'center',
        borderRadius: shape.borderRadius
    },
    imageName: {
        maxWidth: spacing.unit * 37.5,
        lineHeight: 'initial',
        fontSize: spacing.unit * 1.75,
        color: palette.grey[600],
        marginRight: spacing.unit * 4
    },
    imageNumber: {
        fontSize: spacing.unit * 2,
        color: palette.grey[600],
        fontWeight: 'bold'
    }
});

const ImageInfo = ({ imageName, currentIndex, totalImages, classes }) => (
    <div className={classes.imageInfo}>
        <span className={classes.imageName}>{imageName}</span>
        <span className={classes.imageNumber}>
            {currentIndex + 1} / {totalImages}
        </span>
    </div>
);

export default withStyles(styles)(ImageInfo);
