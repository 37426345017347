import createCachedSelector from 're-reselect';
// Local
import { mapToSelect, mapFromLabel } from 'helpers';
import { selectSamplingPrint } from '../common';

// Action Types
import { POST_NEW_TRIM_STEP_FIRST } from 'store/actionTypes';

// Selectors
import {
    selectCurrenciesData,
    selectTrimSourcesData,
    selectTrimSuppliersData,
    selectTrimTypesData
} from '../../datasets/sampling/trims';
import { selectRetailers } from '../newRequest';
import { selectRetailerId } from '../sampleDetails';

// Currencies
export const selectCurrencies = createCachedSelector(
    selectCurrenciesData,
    currencies =>
        mapToSelect(
            currencies.map(({ currencyId, currency, currencySymbol }) => ({
                currencyId,
                currency: `${currencySymbol} ${currency}`
            }))
        )
)(() => 'placeholder');

// Trim Sources
export const selectTrimSources = createCachedSelector(
    selectTrimSourcesData,
    mapToSelect
)(() => 'placeholder');

// Trim Suppliers
export const selectTrimSuppliers = createCachedSelector(
    selectTrimSuppliersData,
    mapToSelect
)(() => 'placeholder');

// Trim Types
export const selectTrimTypes = createCachedSelector(
    selectTrimTypesData,
    mapToSelect
)(() => 'placeholder');

// Dirty
// save new trim card data selector
const saveTrimPost = createCachedSelector(
    selectRetailerId,
    selectRetailers,
    ({
        initSamplingPrint: {
            selectedRetailer,
            trimType,
            trimFrom,
            trimCardCopyRef,
            trimManualRefChecked,
            trimManualRefTxt,
            trimDescription,
            trimSize,
            trimComposition,
            trimComments,
            trimUnit,
            trimSupplier,
            trimSupplierRef,
            trimCost,
            trimCadImg,
            trimCurrency,
            trimAttachments
        },
        auth: { userId, userName }
    }) => ({
        trimTypeId: trimType.value || null,
        trimTypeText: trimType.label || '',
        // trimTypeCode: trimType ? trimType.fullData.trimTypeCode : null,
        trimFrom: trimFrom.value || null,
        trimCopyFrom: trimCardCopyRef.value.value || '',
        trimManualRef: trimManualRefTxt,
        isManual: trimManualRefChecked,
        IsDiscontinued: true,
        TrimDes: trimDescription,
        TrimSize: trimSize,
        TrimComp: trimComposition,
        TrimSuppRef: trimSupplierRef,
        TrimRemarks: trimComments,
        TrimCardUnits: trimUnit,
        TrimSuppId: trimSupplier.value || null,
        TrimSuppText: trimSupplier.label || null,
        TrimCost: Number(trimCost),
        cadFile: trimCadImg,
        TrimCostCurrId: trimCurrency.value || null,
        TrimCostCurrText: trimCurrency.label || null,
        // TrimCostCurrSym: trimCurrency ? trimCurrency.fullData.symbol : null,
        // trimAttachments,
        userId,
        userName
    }),
    (trimDevForId, retailers, trimInfo) => ({
        trimDevForId,
        trimDevForText: mapFromLabel(retailers, trimDevForId),
        ...trimInfo
    })
)(() => POST_NEW_TRIM_STEP_FIRST);

// Trims
const selectTrimReferences = createCachedSelector(
    selectSamplingPrint,
    data => data.trimsReferences
)(() => 'placeholder');

export const selectTrimsList = createCachedSelector(
    selectTrimReferences,
    trimsInfo => {
        if (!trimsInfo || (Array.isArray(trimsInfo) && !trimsInfo.length)) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }

        const trimList = trimsInfo.data || [];
        let value;
        let mainInfo = trimList.map(function(trim) {
            value = { value: trim.trimCardRef, fullData: trim };
            return [
                { title: trim.trimCardRef, value: value },
                { title: trim.trimDescription, value: value },
                { title: trim.trimSupplier, value: value },
                { title: trim.trimSupplierRef, value: value },
                { title: trim.retailer, value: value }
            ];
        });
        return {
            rowInColumnWidth: [20, 20, 20, 20, 20],
            paginationData: trimsInfo.paginationMetadata,
            columnHeader: [
                'Trim card ref.',
                'Trim description',
                'Trim supplier',
                'Trim supp ref.',
                'Retailer'
            ],
            columnInfo: mainInfo
        };
    }
)(() => 'placeholder');

export { saveTrimPost };
