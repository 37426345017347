import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets
} from '@libs/datasets';
import { Input } from '@formComponents';

import { DOMAIN_NAME } from 'sampling/common';
import { COUNTRIES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'country',
    label: 'Country',
    placeholder: ' ',
    disabled: true,
    required: true
};

const mapState = (state, { form }) => {
    const country = formValueSelector(form)(state, 'country');
    return {
        staticValue: valueSelector(state, DOMAIN_NAME, COUNTRIES, country),
        loading: datasetLoadingSelector(state, DOMAIN_NAME, COUNTRIES)
    };
};

const mapDispatch = {
    fetchDatasets
};

const Country = connect(
    mapState,
    mapDispatch
)(({ fetchDatasets, ...props }) => {
    useEffect(() => {
        fetchDatasets(COUNTRIES);
    }, []);

    return <Input {...props} />;
});

Country.propTypes = propTypes;
Country.defaultProps = defaultProps;

export { Country as default, Country };
