import createCachedSelector from 're-reselect';

// Local
import { mapToSelect } from 'helpers';
import { selectSamplingPrint } from '../common';

// Selectors
import {
    selectCollectionsData,
    selectLicensorsData
} from '../../datasets/sampling/cad';

// Collections
export const selectCollections = createCachedSelector(
    selectCollectionsData,
    mapToSelect
)(() => 'placeholder');

// Licensors
export const selectLicensors = createCachedSelector(
    selectLicensorsData,
    mapToSelect
)(() => 'placeholder');

// Dirty
// Graphic ref - Incomplete
const selectIncompleteGraphicReferences = createCachedSelector(
    selectSamplingPrint,
    ({ incompleteGraphicReferences }) => incompleteGraphicReferences
)(() => 'placeholder');

export const selectIncompleteGraphicRefList = createCachedSelector(
    selectIncompleteGraphicReferences,
    data => {
        if (!data) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }

        const refs = data.data || [];
        let value = null;
        let mainInfo = refs.map(function(ref) {
            value = { value: ref.cadRef, fullData: ref };
            return [
                { title: ref.cadRef, value: value },
                { title: ref.description, value: value },
                { title: ref.designerName, value: value }
            ];
        });

        return {
            rowInColumnWidth: [25, 50, 25],
            paginationData: data.paginationMetadata,
            columnHeader: ['CAD REF', 'CAD DESCRIPTION', 'DESIGNER'],
            columnInfo: mainInfo
        };
    }
)(() => 'placeholder');

// Graphic ref - Amend
const selectAmendGraphicReferences = createCachedSelector(
    selectSamplingPrint,
    ({ amendGraphicReferences }) => amendGraphicReferences
)(() => 'placeholder');

export const selectAmendGraphicRefList = createCachedSelector(
    selectAmendGraphicReferences,
    data => {
        if (!data) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }

        const refs = data.data || [];
        let value = null;
        let mainInfo = refs.map(function(ref) {
            value = { value: ref.cadRef, fullData: ref };
            return [
                { title: ref.cadRef, value: value },
                { title: ref.description, value: value },
                { title: ref.designerName, value: value }
            ];
        });

        return {
            rowInColumnWidth: [25, 50, 25],
            paginationData: data.paginationMetadata,
            columnHeader: ['CAD REF', 'CAD DESCRIPTION', 'DESIGNER'],
            columnInfo: mainInfo
        };
    }
)(() => 'placeholder');
