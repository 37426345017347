export function reorderColumnsWithFrozen(orderedColumns, frozenColumns) {
    if (frozenColumns && frozenColumns.length > 0) {
        const output = [];

        // Add frozenColumns to the output in their original order
        for (const frozenColumn of frozenColumns) {
            const column = orderedColumns.find(
                item => item.name === frozenColumn
            );
            if (column) {
                output.push({
                    fieldName: column.name,
                    headerName: column.header
                });
            }
        }

        // Add orderedColumns that are not in frozenColumns
        for (const orderedColumn of orderedColumns) {
            if (!frozenColumns.includes(orderedColumn.name)) {
                output.push({
                    fieldName: orderedColumn.name,
                    headerName: orderedColumn.header
                });
            }
        }

        return output;
    } else {
        return orderedColumns.map(column => ({
            fieldName: column.name,
            headerName: column.header
        })); // If frozenColumns is undefined or empty, return orderedColumns as is
    }
}
