import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    LinearProgress,
    Fade,
    Typography,
    withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import useTransitionToggle from '@libHooks/useTransitionToggle';

import { loadingProgressSelector, loadingSelector } from '../redux.selectors';
import { resetLoading } from '../redux.actions';

const styles = ({ spacing, palette, typography, zIndex }) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: fade(palette.secondary.dark, 0.8),
        zIndex: zIndex.tooltip
    },
    loadingText: {
        marginLeft: spacing.unit,
        color: palette.success.main,
        fontWeight: typography.fontWeightLight
    },
    progressWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    progressContainer: {
        backgroundColor: palette.primary.dark,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.success.main,
        borderRadius: spacing.unit * 0.625,
        padding: 1
    },
    root: {
        width: spacing.unit * 33,
        height: spacing.unit * 0.625,
        borderRadius: 4
    },
    colorPrimary: {
        backgroundColor: palette.primary.dark
    },
    barColorPrimary: {
        background: `repeating-linear-gradient(
                    -60deg, 
                    ${palette.success.main}, 
                    ${palette.success.main} ${spacing.unit * 0.25}px,
                    transparent ${spacing.unit * 0.5}px)`,
        borderRadius: spacing.unit * 0.625
    }
});

const mapState = state => ({
    loading: loadingSelector(state),
    loadingProgress: loadingProgressSelector(state)
});

const Loader = compose(
    connect(
        mapState,
        { resetLoading }
    ),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: { container, loadingText, progressWrapper, progressContainer, ...c },
        theme: { transitions },
        loading,
        loadingProgress,
        resetLoading
    }) => {
        const isLoading = useMemo(() => !!loading || !!loadingProgress, [
            loading,
            loadingProgress
        ]);

        const shouldRender = useTransitionToggle(
            isLoading,
            transitions.duration.complex
        );

        const variant = useMemo(
            () => (!!loadingProgress ? 'determinate' : 'indeterminate'),
            [loadingProgress]
        );

        useEffect(() => {
            let progressTimeout = null;

            // if (loadingProgress >= 100) {
            //     progressTimeout = setTimeout(
            //         () => resetLoading(),
            //         transitions.duration.complex
            //     );
            // }

            return () => clearTimeout(progressTimeout);
        }, [loadingProgress]);

        const progress = useMemo(() => {
            const normalizedProgress =
                loadingProgress >= 100 ? 100 : loadingProgress;
            return `${Math.round(normalizedProgress)}%`;
        }, [loadingProgress]);

        return (
            shouldRender && (
                <Fade in={isLoading} timeout={transitions.duration.complex}>
                    <div className={container}>
                        <div className={progressWrapper}>
                            <div className={progressContainer}>
                                <LinearProgress
                                    classes={c}
                                    variant={variant}
                                    value={loadingProgress}
                                />
                            </div>
                            {!!loadingProgress && (
                                <Typography variant='caption' className={loadingText}>
                                    {progress}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Fade>
            )
        );
    }
);

export default Loader;
