import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { destroy } from 'redux-form';

// Local
import SamplingPrint from 'views/Sampling/Container/homePage';
import { selectPageType } from 'selectors';

// Actions
import { resetSamplingData, setSamplingPageType } from 'actions';

class SamplingPrintIncomplete extends PureComponent {
    componentDidMount() {
        this.props.setSamplingPageType('incomplete');
    }

    componentWillUnmount() {
        const { destroy, resetSamplingData } = this.props;
        destroy('SamplingPrintForm');
        resetSamplingData();
    }

    render() {
        return (
            this.props.isIncompletePage && (
                <SamplingPrint
                    incompletePage
                    title='Print Sample Request'
                    parent='print'
                />
            )
        );
    }
}

const mapState = state => ({
    isIncompletePage: selectPageType(state, 'incomplete')
});

const mapDispatch = { destroy, resetSamplingData, setSamplingPageType };

const _SamplingPrintIncomplete = compose(
    connect(
        mapState,
        mapDispatch
    )
)(SamplingPrintIncomplete);

export {
    _SamplingPrintIncomplete as default,
    _SamplingPrintIncomplete as SamplingPrintIncomplete
};
