import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@formComponents';
import { required, number, minValue } from '@libs/validationRules';

const ratioMustBeLessThanQuantity = (fieldValue, { orderQuantity }) => {
    return fieldValue && Number(fieldValue) > orderQuantity
        ? 'The quantity must not be lower than the ratio.'
        : null;
};
const validatePackQuantity = (fieldValue, { orderQuantity }) => {
    const result = orderQuantity / Number(fieldValue);
    if (result % 1 !== 0) {
        return 'The pack quantity must be an integer. E.g. Total Quantity/Total Ratio = a whole number';
    }
    return null;
};

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'totalRatio',
    label: 'Total Ratio',
    type: 'number',
    required: true,
    disabled: false,
    validate: [
        required,
        number,
        minValue(1),
        ratioMustBeLessThanQuantity,
        validatePackQuantity
    ]
};

const TotalRatio = props => <Input {...props} />;

TotalRatio.propTypes = propTypes;
TotalRatio.defaultProps = defaultProps;

export { TotalRatio as default, TotalRatio };
