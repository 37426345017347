import React from 'react';

import Item from './Item';
import { Xlsx as XlsxIcon } from '@assets/Icons';

const CustomXlsx = ({ exportToExcel, shouldDisable, title }) => (
    <Item
        icon={XlsxIcon}
        title={title}
        shouldDisable={shouldDisable}
        onClick={exportToExcel}
    />
);

export default CustomXlsx;
