export const licensorSchema = {
    id: 0,
    licensor: 'string',
    code: 'string',
    isActive: true
};

export const licensorPropertySchema = {
    id: 0,
    property: 'string',
    licensorId: 0,
    isActive: true
};

export const cadTypeSchema = {
    id: 0,
    type: 'string',
    isActive: true
};

export const artworkSourceSchema = {
    id: 0,
    artworkSource: 'string',
    typeShort: 'string'
};
