import React from 'react';
import { string, number, bool } from 'prop-types';
import { View } from '@react-pdf/renderer';

import { Field, Title, Checkbox } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    title: string.isRequired,
    fabricType: string.isRequired,
    composition: string.isRequired,
    gsm: number.isRequired,
    hasWashColor: bool.isRequired,
    hasMatchWashAndFinish: bool,
    hasMatchColor: bool,
    specialFinish: string,
    color: string,
    colorReference: string
};

const defaultProps = {
    hasWashColor: false
};

const styles = ({ spacing }) => ({
    title: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row'
    },
    checkboxes: {
        flexDirection: 'row',
        marginTop: spacing.unit * 0.25
    }
});

const FabricDetailsPDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        title,
        fabricType,
        composition,
        gsm,
        hasWashColor,
        hasMatchWashAndFinish,
        hasMatchColor,
        specialFinish,
        color,
        colorReference
    }) => {
        return (
            <View>
                <Title text={title} style={c.title} />
                <View style={c.container}>
                    <Field
                        label='Fabric Type'
                        value={fabricType}
                        style={{ width: '30%' }}
                    />
                    <Field
                        label='Composition'
                        value={composition}
                        style={{ width: '58%' }}
                    />
                    <Field label='GSM' value={gsm} style={{ width: '8%' }} />
                </View>
                {hasWashColor && (
                    <View>
                        <View style={c.checkboxes}>
                            <Checkbox
                                label='Match Wash'
                                checked={hasMatchWashAndFinish}
                                style={{ width: '20%' }}
                            />
                            <Checkbox
                                label='Match Color'
                                checked={hasMatchColor}
                                style={{ width: '20%' }}
                            />
                        </View>
                        <View style={c.container}>
                            <Field
                                label='Wash & Finish'
                                value={specialFinish}
                                required={!hasMatchWashAndFinish}
                            />
                            <Field
                                label='Color Name'
                                value={color}
                                required={!hasMatchColor}
                            />
                            <Field
                                label='Color Reference'
                                value={colorReference}
                                required={!hasMatchColor}
                            />
                        </View>
                    </View>
                )}
            </View>
        );
    }
);

FabricDetailsPDFContent.propTypes = propTypes;
FabricDetailsPDFContent.defaultProps = defaultProps;

export {
    FabricDetailsPDFContent as default,
    propTypes as FabricDetailsPDFContentPropTypes
};
