import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import { withStyles, Grid } from '@material-ui/core';

import { GridItem, ImageDnD, Editor } from '@formComponents';
import { validateImageSizeAndExtension } from '@libs/validationRules';

import PreviewImage from './PreviewImage';

const propTypes = {
    formName: PropTypes.string.isRequired,
    shouldLocked: PropTypes.bool.isRequired
};

const mapState = (state, { formName }) => ({
    washedImage: formValueSelector(formName)(state, 'washedImage')
});

const styles = ({ spacing }) => ({
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    imageWrapper: {
        marginTop: spacing.unit * 3.5
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 40
        }
    }
});

const FabricWashedImageAndCommentsContainer = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, shouldLocked, washedImage }) => {
    return (
        <Grid container className={c.container}>
            <GridItem md={5}>
                {shouldLocked ? (
                    <PreviewImage
                        label='Image'
                        alt='Wash & Finish image'
                        {...washedImage}
                    />
                ) : (
                    <div className={c.imageWrapper}>
                        <ImageDnD
                            name='washedImage'
                            label='Image'
                            validate={validateImageSizeAndExtension}
                        />
                    </div>
                )}
            </GridItem>
            <GridItem md={7}>
                <Editor
                    name='comments'
                    label='Comments'
                    placeholder='Write here..'
                    styles={{ editor: c.editor }}
                    disabled={shouldLocked}
                />
            </GridItem>
        </Grid>
    );
});

FabricWashedImageAndCommentsContainer.propTypes = propTypes;

export default FabricWashedImageAndCommentsContainer;
