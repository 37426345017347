import { useMemo } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useSwitchColor = ({ palette }, switchColor) =>
    useMemo(() => {
        const color = palette[switchColor];
        return {
            '--disabledColor': color.light,
            '--color': color.main,
            '--hoverColor': color.dark,
            '--outlinedDisabledColor': fade(color.light, 0.1),
            '--outlinedHoverColor': fade(color.dark, 0.1)
        };
    }, [switchColor]);

export { useSwitchColor };
