import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';

import { FORM_NAME } from '../../common';
import { useFabricDevelopmentState } from '../../useFabricDevelopment';
import HeaderAdornment from '../HeaderAdornment';
import CriticalPathContainer from './CriticalPathContainer';
import DevelopedFabricDetails from './DevelopedFabricDetails';
import PhysicalTestResultContainer from './PhysicalTestResultContainer';
import ApprovalStatusContainer from './ApprovalStatusContainer';

const styles = ({ spacing }) => ({
    container: {
        marginBottom: spacing.unit * 6
    }
});

const Others = compose(
    withStyles(styles),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    })
)(({ classes }) => {
    const { shouldLocked, mode } = useFabricDevelopmentState();

    return (
        <ContentContainer
            title='Critical Path'
            AdornmentComponent={<HeaderAdornment />}
            className={classes.container}
        >
            <CriticalPathContainer shouldLocked={shouldLocked} />
            {mode === 'developedFabric' && (
                <>
                    <DevelopedFabricDetails />
                    <PhysicalTestResultContainer />
                    <ApprovalStatusContainer />
                </>
            )}
        </ContentContainer>
    );
});

export default Others;
