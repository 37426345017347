// Entities
import { MENU_LIST, MENU_GROUPS, DEVELOPMENT_TYPES } from 'libs/datasets';

// Selectors
import { selectMenusData, selectMenuGroupsData, selectDevelopmentTypesData } from 'selectors';

// Normalizers
import { normalizeMenusData, normalizeDevelopmentTypes } from 'normalizers';

// References
export default [
	{
		entity: MENU_LIST,
		url: '/api/Menus',
		selector: selectMenusData,
		normalizer: normalizeMenusData
	},
	{
		entity: MENU_GROUPS,
		url: '/api/Logins/GetMenuGroups',
		selector: selectMenuGroupsData,
	},
	{
		entity: DEVELOPMENT_TYPES,
		url: '/api/DevelopmentTypes',
		selector: selectDevelopmentTypesData,
		normalizer: normalizeDevelopmentTypes
	}
];
