import { isFalsyButNotZero } from './number';

export const required = fieldValue => {
    if (Array.isArray(fieldValue)) {
        return fieldValue.length === 0 ? 'Required!' : null;
    }
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    return isFalsyButNotZero(formattedValue) ? 'Required!' : null;
};

export const maxLength = max => value =>
    value && value.length > max
        ? `Input must be ${max} characters or less`
        : null;

export const minLength = min => value =>
    value && value.length < min
        ? `Input must be at least ${min} characters or more`
        : null;

export const maxDigits = max => fieldValue => {
    const formattedValue =
        typeof fieldValue === 'object' ? fieldValue.inputValue : fieldValue;
    return formattedValue && formattedValue.toString().length > max
        ? `Input must not exceed ${max} digits.`
        : null;
};
export const minDigits = min => fieldValue => {
    const formattedValue =
        typeof fieldValue === 'object' ? fieldValue.inputValue : fieldValue;
    return formattedValue && formattedValue.toString().length < min
        ? `Input must have a minimum of ${min} digits.`
        : null;
};

export const number = fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    return formattedValue && isNaN(Number(formattedValue))
        ? 'Input must be a number'
        : null;
};

export const absoluteNumber = fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    return Math.sign(formattedValue) === -1
        ? 'Input must be a non negative.'
        : null;
};

export const minValue = min => fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    const parseValue = Number(formattedValue);
    return formattedValue === '' || parseValue < min
        ? `Input must be greater than or equal to ${min}.`
        : null;
};

export const maxValue = max => fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    if (!formattedValue) return null;
    return Number(formattedValue) > max
        ? `Input must be less than or equal to ${max}`
        : null;
};

export const mustBeGreaterThanZero = fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    if (isFalsyButNotZero(formattedValue)) return null;
    if (formattedValue <= 0) {
        return `Input must be greater than 0.`;
    }
    return null;
};

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : null;

export const alphanumeric = fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (!formattedValue || alphanumericRegex.test(formattedValue)) return null;
    const nonAlphanumericChars = formattedValue.match(/[^a-zA-Z0-9\s]/g);
    return `Input contains non-alphanumeric characters: ${nonAlphanumericChars}`;
};
export const fraction = fieldValue => {
    const formattedValue =
        fieldValue && typeof fieldValue === 'object'
            ? fieldValue.inputValue
            : fieldValue;
    if (formattedValue && formattedValue.toString().includes('.')) {
        return 'Fraction values are not allowed.';
    }
    return null;
};

export const validateSpecialCharacters = str => {
    //These are the characters considered as special characters
    /* ! @ # $ % ^ & * ( ) + = [ ] { } ; ' : " \ | , . < > / ? */
    const specialCharsPattern = /[!@#$%^&*()+=[\]{};':"\\|,.<>/?]/g;

    // Find all matches for the special characters pattern in the string
    const matches = str && str.toString().match(specialCharsPattern);

    // If matches are found, create an error message
    if (matches && matches.length > 0) {
        const specialCharsList = matches.join(', ');
        const errorMessage = `Input contains special characters. Special chars: ${specialCharsList}`;
        return errorMessage;
    }

    // No special characters found, return null (indicating no error)
    return null;
};

export const validateImageSizeAndExtension = value => {
    const { filename, size } = value || {};
    if (!filename || !size) return null;

    const MAX_SIZE = 10 * 1024 * 1024; // 10 MB
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];

    if (size > MAX_SIZE) {
        return `File size should not exceed 10 MB.`;
    }

    const fileExtension = filename
        .toLowerCase()
        .slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

    if (!allowedExtensions.includes('.' + fileExtension)) {
        const supportedExtensions = allowedExtensions.join(', ');
        return `This type of image/file is not allowed. Supported image extensions are: ${supportedExtensions}`;
    }

    return null;
};

export const validateImageDnDRequired = value => {
    if (!value || !value.filename) {
        return 'Required!';
    }
    return null;
};

export const validateCharsAndSpaces = value => {
    let invalidCharacters = value.match(/[^a-zA-Z ]/g);

    if (invalidCharacters && invalidCharacters.length > 0) {
        invalidCharacters = [...new Set(invalidCharacters)].join(', ');

        return `Input contains invalid characters: ${invalidCharacters}`;
    }
    return null;
};

export const validateEmailForm = values => {
    const errors = {};
    const { receivers, subject, body, cc } = values;

    if (!receivers) {
        errors.receivers = 'Required!';
    }
    if (!subject) {
        errors.subject = 'Required!';
    }
    if (!body) {
        errors.body = 'Required!';
    }

    const MAX_ALLOWED_RECIPIENTS = 50;
    const totalRecipients = (receivers && receivers.split(',').length) || 0;
    const totalCC = (cc && cc.split(',').length) || 0;
    const totalNumber = totalRecipients + totalCC;

    if (totalNumber > MAX_ALLOWED_RECIPIENTS) {
        const excessRecipients = totalNumber - MAX_ALLOWED_RECIPIENTS;
        const message = `You can only add up to ${MAX_ALLOWED_RECIPIENTS} recipients in the To and CC fields together. Right now, you have a total of ${totalNumber} recipients. Please remove ${excessRecipients} recipients, including any with a trailing comma.`;
        errors.receivers = message;
        if (totalCC) {
            errors.cc = message;
        }
    }

    return errors;
};

export const validateNoEmptySpace = inputString => {
    if (inputString.includes(' ')) {
        return 'No spaces are allowed';
    }
    return null;
};

export const validateStringWithSpaces = inputString => {
    if (inputString.trim() !== inputString) {
        return 'No leading or trailing spaces allowed';
    }
    return null;
};
