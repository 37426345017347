import {
    SET_SAMPLING_PAGE_TYPE
} from 'actionTypes';

const commonReducer = (state, { type, payload, ...action }) => {
    switch (type) {

        case SET_SAMPLING_PAGE_TYPE: {
            const { pageType } = payload;
            return { ...state, pageType };
        }

        default: {
            return state;
        }
    }
};

export { commonReducer as default, commonReducer };
