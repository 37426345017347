// Returns the id next after current Id if mode === amend
export default (developmentTypes, developmentType, mode) => {
    const [developmentTypeId] = developmentTypes.reduce(
        ([developmentTypeId, shouldNext], { id }) => {
            if (shouldNext) return [id, false];

            if (id === developmentType) {
                if (mode === 'amend') return [developmentTypeId, true];
                else return [id, false];
            } else return [developmentTypeId, false];
        },
        ['', false]
    );
    return developmentTypeId;
};
