export const normalizeDevelopmentTypes = ({ data: developmentTypes }) =>
    developmentTypes.map(({ devTypeId, devType }) => ({
        developmentTypeId: devTypeId,
        developmentType: devType,
    }));

export const normalizeMenusData = ({ data: menuList }) =>
    menuList
        .filter(({ wbMenuId }) => wbMenuId !== 125)
        .map(({ slno, wbMenuId, wbMenuName = '', wbParentId, groupId }) => ({
            menuId: wbMenuId,
            parentId: wbParentId,
            menuName: wbMenuName
                .split(' ')
                .map(
                    (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                )
                .join(' '),
            serial: slno,
            menuGroupId: groupId
        }));

export const normalizePaginatedData = ({
    data = [],
    paginationMetadata: { nextPage = 'No' } = {},
}) => ({
    data,
    nextPage: nextPage === 'Yes',
});
