import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = ({ palette, spacing }) => ({
    emptyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.unit * 10
    },
    emptyContent: {
        color: palette.secondary.light
    }
});

const NoDataFound = withStyles(styles)(
    ({ gridLoading, classes: c }) =>
        !gridLoading && (
            <div className={c.emptyContainer}>
                <Typography className={c.emptyContent}>
                    No data found!
                </Typography>
            </div>
        )
);

export default NoDataFound;
