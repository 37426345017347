import { formValueSelector } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { userNameSelector } from 'auth/redux.selectors';
import { getFileNameFromURL } from '@libs/filenames';
import { syncReportToDatabase, emailSend } from 'common/redux.sagas';
import { MAIL_FORM_NAME } from 'common/common';
import isBlobUrl from '@libs/isBlobUrl';
import { setLoading } from 'utilities/redux.actions';
import { setSnack } from 'utilities/redux.actions';

export default function*(action) {
    const {
        payload: { sampleReference, developmentNumber, developmentName },
        meta: { closeModals }
    } = action;

    try {
        yield put(setLoading(true));
        const userName = yield select(userNameSelector);
        const {
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body,
            report
        } = yield select(
            formValueSelector(MAIL_FORM_NAME),
            'receivers',
            'cc',
            'subject',
            'body',
            'report'
        );

        let absoluteUrl = report.url;
        if (isBlobUrl(absoluteUrl)) {
            const [reportBlobs, reportSizePaths] = yield all([
                call(getAssetBlobsWorker, {
                    assets: report,
                    mode: 'new',
                    handleLoading: f => f
                }),
                select(sizePathsSelector, 'reportPath')
            ]);

            const [reportPayload] = yield call(uploadAssetsWorker, {
                assetBlobs: reportBlobs,
                folderPath: `${reportSizePaths.sizeOriginal}/${sampleReference}`,
                reference: sampleReference,
                sizePaths: reportSizePaths,
                handleLoading: f => f,
                developmentName
            });

            const reportPDFPath = reportPayload
                ? reportPayload.sizeOriginal
                : '';
            absoluteUrl = yield call(syncReportToDatabase, {
                reportReference: sampleReference,
                reportUrl: reportPDFPath,
                referenceDevNo: developmentNumber
            });
        }

        const fileName = yield call(getFileNameFromURL, absoluteUrl);

        let composeBody = `
          ${body}
          <a style="color: #234c72;margin-right: 10px;font-size: 16px;" href="${absoluteUrl}">${fileName}</a>`;

        const emailPayload = {
            userName,
            toAddress: toAddress.replace(/\s/g, ''),
            ccAddress,
            subject,
            body: composeBody,
            sampleRef: sampleReference
        };
        yield call(emailSend, emailPayload);
        closeModals && closeModals();
        yield put(setLoading(false));
        yield put(
            setSnack({
                message: 'Email request received. It will be sent soon.',
                type: 'success'
            })
        );
    } catch (error) {
        yield put(setLoading(false));
        yield put(
            setSnack({
                message: 'Failed to send.',
                type: 'error',
                duration: 8000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
