import React from 'react';
// Others domain
import {
    ALL_DESIGNERS,
    ARTWORK_SOURCES,
    TYPES
} from 'cadUpload/redux.datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import {
    STYLES,
    CATEGORIES,
    GENDERS,
    AGES,
    DESIGN_DEPARTMENT
} from 'common/redux.datasets';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';

import {
    RETAILERS,
    DEPARTMENTS,
    FIT_OPTIONS,
    REQUEST_TYPES,
    SAMPLE_STATUSES,
    SAMPLE_ACTIONS,
    SAMPLE_FACTORY,
    SEASONS,
    CURRIER,
    GARMENTS_TYPE,
    SAMPLE_ORDER_STATUS,
    COUNTRIES
} from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import { ReferenceLinkModal } from './components';

export const DATASETS = [
    RETAILERS,
    DEPARTMENTS,
    FIT_OPTIONS,
    ALL_DESIGNERS,
    ARTWORK_SOURCES,
    REQUEST_TYPES,
    TYPES,
    SAMPLE_STATUSES,
    SAMPLE_ACTIONS,
    SAMPLE_FACTORY,
    SEASONS,
    STYLES,
    CATEGORIES,
    GENDERS,
    AGES,
    CURRIER,
    GARMENTS_TYPE,
    SAMPLE_ORDER_STATUS,
    COUNTRIES,
    DESIGN_DEPARTMENT
];

export const PAGE_SIZES = [10, 25, 50, 100, 250];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            type: 'number',
            visible: false
        },
        {
            name: 'sampleRef',
            header: 'Sample Ref',
            type: 'text',
            filterable: true,
            render: ({ column, row }) => (
                <ReferenceLinkModal sampleRef={row[column.name]} />
            )
        },
        {
            name: 'sampleType',
            header: 'Sample Type',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: GARMENTS_TYPE
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: RETAILERS
        },
        {
            name: 'departmentId',
            header: 'Department',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: DEPARTMENTS
        },
        {
            name: 'countryId',
            header: 'Country',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'frontImage',
            header: 'Front Image',
            type: 'image'
        },
        {
            name: 'backImage',
            header: 'Back Image',
            type: 'image'
        },
        {
            name: 'fitOptionId',
            header: 'Fit Option',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: FIT_OPTIONS
        },
        {
            name: 'graphicDescription',
            header: 'Graphic Description',
            type: 'text',
            filterable: true
        },
        {
            name: 'styleDescription',
            header: 'Style Description',
            type: 'text',
            filterable: true,
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'garmentDescription',
            header: 'Spec Desc',
            type: 'text',
            filterable: true
        },
        {
            name: 'fabrication',
            header: 'Fabrication',
            type: 'text',
            filterable: true,
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'specialFabricRef',
            header: 'Fabric Ref',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'designerId',
            header: 'Designer',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: ALL_DESIGNERS
        },
        {
            name: 'designFromId',
            header: 'Dsg From',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: ARTWORK_SOURCES
        },
        {
            name: 'validation',
            header: 'Validation',
            type: 'text',
            filterable: true,
            visible: false
        },
        {
            name: 'orderStatus',
            header: 'Order Status',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: SAMPLE_ORDER_STATUS
        },
        {
            name: 'designerRef',
            header: 'Designer Ref',
            type: 'text',
            visible: false
        },
        {
            name: 'devNo',
            header: 'Dev No',
            type: 'text',
            visible: false
        },
        {
            name: 'dateRequested',
            header: '1st Request Date',
            type: 'date',
            filterable: true
        },
        {
            name: 'dateModified',
            header: 'Modified Date',
            type: 'date',
            filterable: true,
            visible: false
        },
        {
            name: 'fabricEta',
            header: 'Fabric Eta',
            type: 'date',
            visible: false
        },
        {
            name: 'dateSampleRequired',
            header: 'Target Sample Del Date',
            type: 'date',
            filterable: true,
            visible: false
        },
        {
            name: 'targetSentDate',
            header: 'Target Snt Dt',
            type: 'date',
            filterable: true,
            visible: false
        },
        {
            name: 'sampleEta',
            header: 'Sample ETA',
            type: 'date',
            visible: false
        },
        {
            name: 'actualSendDate',
            header: 'Act Snt Dt',
            type: 'date',
            filterable: true
        },
        {
            name: 'requestTypeId',
            header: 'Sample Request Type',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: REQUEST_TYPES
        },
        {
            name: 'dateApproveBy',
            header: 'Apr By Dt',
            type: 'date',
            visible: false
        },
        {
            name: 'dateRcvdUk',
            header: 'Rcvd UK',
            type: 'date',
            filterable: true,
            editable: true
        },
        {
            name: 'sampleStatusId',
            header: 'Sample Status',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: SAMPLE_STATUSES
        },
        {
            name: 'sampleActionId',
            header: 'Sample Action',
            type: 'select',
            filterable: true,
            editable: true,
            domain: DOMAIN_NAME,
            entity: SAMPLE_ACTIONS
        },
        {
            name: 'factoryId',
            header: 'Factory',
            type: 'select',
            filterable: true,
            editable: true,
            domain: DOMAIN_NAME,
            entity: SAMPLE_FACTORY
        },
        {
            name: 'sealBy',
            header: 'Seal By Dt',
            type: 'date',
            filterable: true
        },
        {
            name: 'weight',
            header: 'Weight/dzn',
            type: 'text',
            visible: false
        },
        {
            name: 'seasonId',
            header: 'Season',
            type: 'select',
            filterable: true,
            editable: true,
            domain: DOMAIN_NAME,
            entity: SEASONS
        },
        {
            name: 'fabricColour',
            header: 'Fabric Colour',
            type: 'text',
            visible: false
        },
        {
            name: 'specRef',
            header: 'Spec',
            type: 'text'
        },
        {
            name: 'categoryId',
            header: 'Category',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: CATEGORIES
        },
        {
            name: 'styleId',
            header: 'Style',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: STYLES
        },
        {
            name: 'genderId',
            header: 'Gender',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: GENDERS
        },
        {
            name: 'ageId',
            header: 'age',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: AGES
        },
        {
            name: 'designDepartmentId',
            header: 'Design Department',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: DESIGN_DEPARTMENT,
            visible: false
        },
        {
            name: 'fabBookDate',
            header: 'Fabric Booking Date',
            type: 'date',
            filterable: true,
            editable: true,
            visible: false
        },
        {
            name: 'fabRcvDate',
            header: 'Fabric Received Date',
            type: 'date',
            filterable: true,
            editable: true,
            visible: false
        },
        {
            name: 'currierId',
            header: 'Courier',
            type: 'select',
            filterable: true,
            domain: DOMAIN_NAME,
            entity: CURRIER
        },
        {
            name: 'trackingRef',
            header: 'Tracking Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'noOfInk',
            header: 'No. Ink',
            type: 'text',
            visible: false
        },
        {
            name: 'cadTypeId',
            header: 'Cad Type',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: TYPES,
            visible: false
        },
        {
            name: 'graphicRef',
            header: 'Design Ref',
            type: 'text',
            filterable: true,
            visible: false
        }
    ];
};
