import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import {
    COUNTRIES,
    DEPARTMENTS,
    DESIGN_TYPES,
    FACTORIES,
    JOINED_RETAILER_FACTORIES,
    PRODUCT_TYPES,
    RETAILERS,
    COPY_SAMPLE_REFERENCE,
} from 'libs';

export const selectCountriesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[COUNTRIES]
)(() => 'placeholder');

export const selectDepartmentsData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[DEPARTMENTS]
)(() => 'placeholder');

export const selectDesignTypesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[DESIGN_TYPES]
)(() => 'placeholder');

export const selectFactoriesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[FACTORIES]
)(() => 'placeholder');

export const selectJoinedRetailerFactoriesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[JOINED_RETAILER_FACTORIES]
)(() => 'placeholder');

export const selectProductTypesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[PRODUCT_TYPES]
)(() => 'placeholder');

export const selectRetailersData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[RETAILERS]
)(() => 'placeholder');

export const selectCopySampleReferencesData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[COPY_SAMPLE_REFERENCE]
)(() => 'placeholder');

export const selectCopyReferenceData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets['copyReferences']
)(() => 'placeholder');
