import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { string, number, bool } from 'prop-types';

import { Field, Checkbox, Title } from 'components/PDF';
import { useCustomDate } from 'sampling/hooks';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    sampleReference: string,
    developmentName: string,
    buyer: string,
    dateRequested: string,
    dateRequiredBy: string,
    requestType: string,
    fitOption: string,
    sendToEcho: string,
    sendToBuyer: string,
    photoApproval: bool
};

const defaultProps = {
    photoApproval: false
};

const Details = ({
    sampleReference,
    developmentName,
    buyer,
    dateRequested,
    dateRequiredBy,
    requestType,
    fitOption,
    sendToEcho,
    sendToBuyer,
    sendToLicensor,
    photoApproval
}) => {
    return (
        <View style={styles.container}>
            <Title text={'Sample Reference'} />
            <Field
                label={'Sample Reference'}
                value={sampleReference}
                required={false}
            />
            <Field
                label={'Development No'}
                value={developmentName}
                required={false}
            />
            <Field label={'Request type'} value={requestType} />
            <Field label={'Fit option'} value={fitOption} required={false} />
            <Field
                label={'Date Requested'}
                value={useCustomDate(dateRequested)}
            />
            <Field
                label={'Sample Required by'}
                value={useCustomDate(dateRequiredBy)}
            />
            <Field label={'Sent to UK'} value={sendToEcho} />
            <Field label={'Sent to Buyer'} value={sendToBuyer} />
            {!!sendToLicensor && (
                <Field label={'Sent to Licensor'} value={sendToLicensor} />
            )}
            <Field label={'Buyer Name'} value={buyer} required={false} />
            <Checkbox
                label={'Request For Photo Approval'}
                checked={!!photoApproval}
            />
        </View>
    );
};

Details.propTypes = propTypes;
Details.defaultProps = defaultProps;

export default Details;
