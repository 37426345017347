export default ({ palette, spacing }) => ({
	success: {
		backgroundColor: palette.green[600]
	},
	error: {
		backgroundColor: palette.error.dark
	},
	info: {
		backgroundColor: palette.primary.main
	},
	warning: {
		backgroundColor: palette.amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: spacing.unit
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	}
});
