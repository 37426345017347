import makeContext from '@libs/makeContext';

const initialState = {
    brands: []
};

const reducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'removeBrand': {
            const { brands } = state;
            const { payload } = action;

            const brandsAfterRemoval = brands.filter(
                (brand, index) => index !== payload
            );

            reduxFormOnChange(brandsAfterRemoval);

            return action;
        }

        default:
            return action;
    }
};

const [
    BrandingDetailsProvider,
    useBrandingDetailsState,
    useBrandingDetailsDispatch,
    useBrandingDetails
] = makeContext(reducer, initialState, {
    name: 'BrandingDetails',
    middleware
});

export {
    useBrandingDetails as default,
    BrandingDetailsProvider,
    useBrandingDetailsState,
    useBrandingDetailsDispatch
};
