// Pristine
export const SET_SAMPLING_PAGE_TYPE = 'set_sampling_page_type';


// Check
export const CHANGE_STEP_DATA = 'change_step_data';
export const CHANGE_NEW_CAD_DATA = 'change_new_cad_data';
export const CHANGE_NEW_CAD_DATA_SUCCESS = 'change_new_cad_data_success';

export const PUBLISH_TO_DATABASE = 'publish_to_database';
export const PUBLISH_TO_DATABASE_SUCCESS = 'publish_to_database_success';
export const PUBLISH_TO_DATABASE_FAILURE = 'ppublish_to_database_failure';

export const PUBLISH_TO_DATABASE_HANDOVER = 'publish_to_database_handover';
export const PUBLISH_TO_DATABASE_HANDOVER_SUCCESS =
	'publish_to_database_handover_success';
export const PUBLISH_TO_DATABASE_HANDOVER_FAILURE =
	'publish_to_database_handover_failure';

export const PUBLISH_TO_DATABASE_DRAFT = 'publish_to_database_draft';
export const PUBLISH_TO_DATABASE_DRAFT_SUCCESS =
	'publish_to_database_draft_success';
export const PUBLISH_TO_DATABASE_DRAFT_FAILURE =
	'publish_to_database_draft_failure';

export const RESET_FABRIC_DATA = 'reset_fabric_data';
export const RESET_SAMPLING_DATA = 'reset_sampling_data';
export const RESET_NEW_CAD_MODAL = 'reset_new_cad_modal';
