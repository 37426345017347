import React from 'react';
import { string, oneOfType, number } from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

const propTypes = {
    value: oneOfType([string, number])
};

const styles = ({ spacing }) => ({
    tableCell: {
        width: '100%',
        padding: `${spacing.unit * 0.5}px ${spacing.unit * 2}px`,
        fontSize: spacing.unit * 1.75,
        borderRight: '1px solid #c8ced4',
        borderBottom: '1px solid #c8ced4',

        overflow: 'hidden',
        overflowWrap: 'break-word'
    }
});

const Cell = withStyles(styles)(({ classes: { tableCell }, value }) => {
    return <Typography className={tableCell}>{value}</Typography>;
});

Cell.propTypes = propTypes;

export default Cell;
