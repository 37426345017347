import React from 'react';

import {
    RETAILER_ROUTE_ID,
    DEPARTMENT_ROUTE_ID,
    STYLE_ROUTE_ID,
    GENDER_ROUTE_ID,
    CATEGORY_ROUTE_ID,
    COUNTRY_ROUTE_ID,
    AGE_ROUTE_ID,
    DESIGN_DEPARTMENT_ROUTE_ID
} from './common';
import Retailer from './Retailer';
import Department from './Department';
import Style from './Style';
import Gender from './Gender';
import Category from './Category';
import Country from './Country';
import Age from './Age';
import DesignDepartment from './DesignDepartment';

export default {
    [RETAILER_ROUTE_ID]: () => <Retailer />,
    [DEPARTMENT_ROUTE_ID]: () => <Department />,
    [STYLE_ROUTE_ID]: () => <Style />,
    [GENDER_ROUTE_ID]: () => <Gender />,
    [CATEGORY_ROUTE_ID]: () => <Category />,
    [COUNTRY_ROUTE_ID]: () => <Country />,
    [AGE_ROUTE_ID]: () => <Age />,
    [DESIGN_DEPARTMENT_ROUTE_ID]: () => <DesignDepartment />
};
