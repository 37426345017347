import { put, call } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { splice } from '@libs/object';
import { normalizeAttachments } from 'common/redux.normalizers';

import { normalizeFile } from 'sampling/redux.normalizers';

import { INITIAL_VALUES, FORM_NAME } from '../../SealSample/common';
import { normalizeDate } from '@baseComponents/Date';

export default function*(qcRef, isCopy = false) {
    try {
        const {
            data: {
                data: [
                    {
                        qcDate,
                        sealByDate,
                        attachments,
                        frontImage,
                        backImage,
                        ...data
                    } = {}
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'QcPreSealSamples/GetQcPreSealSampleDetails',
            params: {
                qcRef
            }
        });

        const nestedNormalizedData = {
            ...data,
            qcDate: normalizeDate(qcDate) || '',
            sealDate: normalizeDate(sealByDate) || '',
            attachments: normalizeAttachments(attachments, isCopy),
            ...(!!frontImage ? { frontImage: normalizeFile(frontImage) } : {}),
            ...(!!backImage ? { backImage: normalizeFile(backImage) } : {})
        };

        const nonCopyDataMapping = {
            id: 'id',
            qcRef: 'qcReference',
            jwpNo: 'jwpNo',
            styleRef: 'qcStyle',
            colorId: 'qcColor',
            qcVersion: 'qcVersion',
            qcDate: 'qcDate',
            sealDate: 'sealDate',
            orderFactory: 'factory',
            totalQty: 'quantity',
            styleDescription: 'styleDesc'
        };

        const values = splice(nestedNormalizedData, {
            ...(isCopy ? {} : nonCopyDataMapping),
            finishWashId: 'finishWash',
            fabricBatch: 'fabricBatch',
            coveringLot: 'coveringLot',
            ftyPrdUnit: 'prodUnit',
            prewashPanel: 'panelStatus',
            bulkProductionUnit: 'bulkProductionUnit',
            bodyFabric: 'bodyFabricStatus',
            sampleColorStatusId: 'sampleColorStatus',
            trimFabric: 'trimFabricStatus',
            accTrim: 'accAndTrimStatus',
            missingTrims: 'missingTrims',
            emblishment: 'embellishmentStatus',
            colorShade: 'washStatus',
            styling: 'stichingStatus',
            washShrinkage: 'washShrinkage',
            printShrinkage: 'printShrinkage',
            spiralityPercent: 'spirality',
            foundGsm: 'foundGSM',
            pattern: 'patternStatus',
            fitStatus: 'fitStatus',
            typesOfSample: 'typeOfSample',
            frontImage: 'frontImage',
            backImage: 'backImage',
            fabrics: 'fabrics',
            clarificationFromBuyer: 'commentsFromBuyer',
            generalInstruction: 'generalInstructions',
            sealVerification: 'sealOrTrimCardVerification',
            patternFit: 'patternFitComments',
            specialComments: 'specialInstructions',
            workmanshipComments: 'workmanshipComments',
            measurementComments: 'measurementComments',
            preventiveAction: 'preventiveCorrective',
            fabricCTQ: 'fabricCTQ',
            emblishmentCTQ: 'embellishmentCTQ',
            accTrimCTQ: 'accTrimsCTQ',
            cprtwashCTQ: 'CPRTWashCTQ',
            cuttingCTQ: 'cuttingCTQ',
            finishingPackingCTQ: 'finishingPackingCTQ',
            sewingCTQ: 'sewingCTQ',
            bulkProductionComplicacy: 'bulkProductionComplicacy',
            attachments: 'attachments',
            sampleQty: 'sampleQTY',
            sampleReviewStatus: 'sampleReviewStatus',
            rejectQty: 'rejectQTY',
            dhu: 'dhu',
            thDefectRate: 'tdr',
            remarks: 'remarks'
        });

        yield put(
            initialize(FORM_NAME, { ...INITIAL_VALUES, ...values }, isCopy, {
                updateUnregisteredFields: true,
                keepDirty: isCopy
            })
        );
    } catch (error) {
        console.log(error);
    }
}
