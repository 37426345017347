import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

// Local
import DateBase from '@baseComponents/Date';

// Selectors
import { composeClsx } from '../@libs/materialUI';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    staticValue: PropTypes.string
};

const defaultProps = {
    styles: {}
};

const styles = ({ palette, spacing }) => ({
    root: {
        height: spacing.unitHeight
    },
    container: {
        paddingBottom: spacing.unitPadding
    },
    amendRoot: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: palette.amend.dark
    }
});

const mapState = (state, { name }) => ({
    // amended: selectAmendedField(state, name)
});

const Date = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes, placeholder, amended, styles, ...restProps }) => {
    const { root, amendRoot, disabled, amendDisabled, ...c } = composeClsx({
        classes,
        styles
    });

    const props = {
        placeholder: placeholder || restProps.label || '',
        styles: {
            ...c,
            root: clsx(root, amended && amendRoot),
            disabled: clsx(disabled, amended && amendDisabled)
        },
        ...restProps
    };
    return <DateBase {...props} />;
});

Date.propTypes = propTypes;
Date.defaultProps = defaultProps;

export { Date as default, Date };
