const OK_CODE = 200; //* The request succeeded
const CREATED_CODE = 201; //* The request succeeded, and a new resource was created as a result.
const ACCEPTED_CODE = 202; //* The request has been received but yet not acted upon. Ex: Queue based process.
const MOVED_PERMANENTLY_CODE = 301; //* The URL of the requested resource has been changed permanently. The new URL is given in the response.
const BAD_REQUEST_CODE = 400; //* Malformed request syntax.
const UNAUTHENTICATED_CODE = 401; //* This response means "unauthenticated"
const FORBIDDEN_CODE = 403; //* The client does not have access rights to the content.
const NOT_FOUND_CODE = 404; //* The server cannot find the requested resource.
const METHOD_NOT_ALLOWED = 405; //* The request method is known by the server but is not supported by the target resource
const INTERNAL_SERVER_ERROR = 500; //* The server has encountered a situation it does not know how to handle.

const CUSTOM_HTTP_MESSAGES = {
    [OK_CODE]: 'Success',
    [CREATED_CODE]: 'Created',
    [ACCEPTED_CODE]: 'Accepted',
    [MOVED_PERMANENTLY_CODE]: 'Moved',
    [BAD_REQUEST_CODE]: 'Bad Request',
    [UNAUTHENTICATED_CODE]: 'UNAUTHENTICATED',
    [FORBIDDEN_CODE]: 'Not allowed',
    [NOT_FOUND_CODE]: 'Not found',
    [METHOD_NOT_ALLOWED]: 'Method not allowed',
    [INTERNAL_SERVER_ERROR]: 'Error.',
};

export {
    CUSTOM_HTTP_MESSAGES as default,
    OK_CODE,
    CREATED_CODE,
    ACCEPTED_CODE,
    MOVED_PERMANENTLY_CODE,
    BAD_REQUEST_CODE,
    UNAUTHENTICATED_CODE,
    FORBIDDEN_CODE,
    NOT_FOUND_CODE,
    METHOD_NOT_ALLOWED,
    INTERNAL_SERVER_ERROR,
};
