import { FETCH_BOUGHT_DETAILS_TYPES_SUCCESS } from 'actionTypes';

const otherReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_BOUGHT_DETAILS_TYPES_SUCCESS: {
            return {...state, boughtReferences: payload}
        }

        default: {
            return state;
        }
    }
};

export { otherReducer as default, otherReducer };
