import React from 'react';
import classNames from 'classnames';

// Material
import { withStyles, Paper } from '@material-ui/core';

// Local
import styles from './styles';
import Spotlight from './PaginatedDialogToolbarSpotlight';
import CategorySelect from './PaginatedDialogToolbarCategories';
import ClearButton from './PaginatedDialogToolbarClear';

/*
 * Domain: --
 * Page: PaginatedSelect
 * Component: PaginatedDialog
 * Type: Icon
 * PaginatedDialogToolbar
 */
const PaginatedDialogToolbar = ({ classes }) => (
    <Paper classes={classes} elevation={0}>
        <Spotlight />
        <CategorySelect />
        <ClearButton />
    </Paper>
);

const _PaginatedDialogToolbar = withStyles(styles)(PaginatedDialogToolbar);

export {
    _PaginatedDialogToolbar as default,
    _PaginatedDialogToolbar as PaginatedDialogToolbar,
};
