export const RESET_SAMPLES_REFS = 'orders@reset-samples-refs';
export const RESET_TYPE_CHILDREN = 'orders@reset-type-children';
export const RESET_RETAILER_CHILDREN = 'orders@reset-reatailer-children';
export const ORDER_PUBLISH = 'orders@order-publish';
export const SET_CLIENT_PO_TBC_CHILDREN = 'orders@set-client-po-tbc-children';
export const SET_CLIENT_PO_TBC_LOADING = 'orders@set-client-po-tbc-loading';
export const SET_CLIENT_PO_NO_CHECK_LOADING =
    'orders@set-client-po-no-check-loading';
export const FETCH_PRODUCT_DETAILS_BY_REFERENCE =
    'orders@fetch-product-details-by-reference';
export const SET_PRODUCTS_LOADING = 'orders@set-products-loading';
export const FETCH_SAMPLE_REF_CHANGE_DATA =
    'orders@fetch-sample-ref-change-data';
export const SET_SAMPLE_REF_CHANGE_DATA_LOADING =
    'orders@set-sample-ref-change-data-loading';
export const ORDER_REFERENCE_GENERATE = 'orders@order-reference-generate';
export const SET_ORDER_REFERENCE_LOADING = 'orders@set-order-reference-loading';
export const SET_FACTORY_CHILDREN = 'orders@set-factory-children';
export const SET_FACTORY_REF_LOADING = 'orders@set-factory-ref-loading';
export const SET_PO_LAB_DIP_COLOR_CHILDREN =
    'orders@set-po-lab-dip-color-children';
export const SET_PO_LAB_DIP_COLOR_LOADING =
    'orders@set-po-lab-dip-color-loading';
export const SET_PO_TBC_CHILDREN = 'orders@set-po-tbc-children';
export const SET_PO_TBC_LOADING = 'orders@set-po-tbc-loading';
export const SET_PO_OR_PACK_CHECK_LOADING =
    'orders@set-po-or-pack-check-loading';
export const SET_PO_NO_CHILDREN = 'orders@set-po-no-children';
export const SET_PO_PACK_TBC_CHILDREN = 'orders@set-po-pack-tbc-children';
export const SET_PO_PACK_TBC_LOADING = 'orders@set-po-pack-tbc-loading';
export const SET_PO_SHIP_MODE_CHILDREN = 'orders@set-po-ship-mode-children';
export const SET_PO_PAYMENT_TERMS_AND_SETTLEMENT_DISCOUNT_DEFAULT_VALUE_LOADING =
    'orders@set-po-payment-terms-and-settlement-discount-loading';
export const SET_PO_SIZE_SET_CHILDREN = 'orders@set-po-size-set-children';
export const SET_PO_SIZE_SET_LOADING = 'orders@set-po-size-set-loading';
export const SET_PO_EX_FTY_DATE = 'orders@set-po-ex-fty-date';
export const GENERATE_PO_SIZE_SKU = 'orders@po-size-sku';
export const WATCH_PO_REMOVED = 'orders@watch-po-removed';
export const SET_PACK_TYPE_CHILDREN = 'orders@set-pack-type-children';
export const RESET_SIZE_DETAILS_ON_QUANITY_OR_RATIO_CHANGE =
    'orders@reset-size-details-on-quantity-or-ratio-change';
export const SET_PO_SIZE_TABLE_QUANTITY_CHILDREN =
    'orders@set-po-size-table-quantity-children';
export const SET_PO_SIZE_TABLE_RATIO_CHILDREN =
    'orders@set-po-size-table-ratio-children';
export const SET_ORDER_BASIC_TRIMS_CHILDREN =
    'orders@set-order-basic-trims-children';
export const ORDER_TRIMS_LOADING = 'orders@order-trims-loading';
export const SET_ORDER_NOTE_CHILDREN = 'orders@set-order-note-children';
export const ROUTE_TO_ORDER = 'orders@route-to-order';
export const DESTROY_ORDER_FORM = 'orders@destroy-order-form';
export const RESET_ORDER_FORM = 'orders@reset-order-form';
export const SET_SAME_STYLE_IN_ALL_PACKS = 'orders@set-same-style-in-all-packs';
export const SET_SAMPLE_REFS_AMEND = 'orders@set-sample-refs-amend';

export const GET_INCOMPLETE_ORDER_DATA = 'orders@get-incomplete-order-data';
export const GET_AMEND_ORDER_DATA = 'orders@get-amend-order-data';
export const ROUTE_TO_AMEND_ORDER = 'orders@route-to-amend-order';

export const SET_ORDER_EMAIL_RECEIVERS = 'orders@set-order-email-receivers';
export const SET_ORDER_EMAIL_BODY = 'orders@set-order-email-body';
export const ORDER_REPORT_MAIL_SEND = 'orders@order-report-mail-send';

/* Order Data Grid */

export const ORDER_CP_EXPORT_XL_DATA = 'orders@cp-export-xl-data';
export const SET_CP_EXPORT_EXCEL_DATA_LOADING =
    'orders@set-cp-export-excel-data-loading';
