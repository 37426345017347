import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, SnackbarContent } from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon
} from '@material-ui/icons';

import styles from './styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const _ContentWrapper = memo(function _ContentWrapper(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
    const snackId = `${variant}-snackbar`;

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby={message}
            message={
                <span id={snackId} className={classes.message}>
                    <Icon
                        className={classNames(
                            classes.icon,
                            classes.iconVariant
                        )}
                    />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key='close'
                    aria-label='Close'
                    color='inherit'
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
});

_ContentWrapper.defaultProps = {
    message: '',
    onClose: () => {}
};

_ContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
        .isRequired,
    onClose: PropTypes.func
};

const __ContentWrapper = withStyles(styles)(_ContentWrapper);

export { __ContentWrapper as default, __ContentWrapper as ContentWrapper };
