import React from 'react';
import { string, number, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';

import { useFabricDetails } from './hooks';
import Row from './Row';

const styles = ({ palette, spacing }) => ({
    container: {
        width: `100%`,
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gridTemplateRows: 'repeat(4,auto)',
        gridAutoFlow: 'column',

        border: '1px solid',
        borderColor: palette.grey[300],
        borderBottom: 'none',
        borderRight: 'none',
        '&:not(:last-child)': {
            marginBottom: spacing.unit * 1.25
        }
    }
});

const propTypes = {
    fabricFor: string,
    fabricType: string,
    composition: string,
    gsm: number,
    dyeType: string,
    specialFabricRef: string,
    specialFinish: string,
    fabricColour: string,
    eld: string,
    isYds: bool,
    istc: bool,
    fabFrom: string
};

const FabricTable = withStyles(styles)(({ classes: c, ...fabrics }) => {
    const rows = useFabricDetails(fabrics);
    return (
        <div className={c.container}>
            {rows.map((row, idx) => (
                <Row {...row} key={`fabirc-table-row-${idx}`} />
            ))}
        </div>
    );
});

FabricTable.propTypes = propTypes;

export default FabricTable;
