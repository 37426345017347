import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const SealSampleReducer = state => state;

const [
    SealSampleProvider,
    useSealSampleState,
    useSealSampleDispatch,
    useSealSample
] = makeContext(SealSampleReducer, initialState, { name: 'SealSample' });

export {
    useSealSample as default,
    SealSampleProvider,
    useSealSampleState,
    useSealSampleDispatch
};
