import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';

import { imgTypes, fileSize } from 'helpers';
import styles from './styles';

import {
    GridContainer,
    GridItem,
    TextInput,
    SelectFields,
    EditIcon,
    Tooltip,
    ModalHeader,
    ModalContent,
    ModalFooter
} from 'components';

import { selectInkTypes, selectInkImagesList } from 'selectors';

import {
    setInkDetails,
    editInkDetail,
    deleteInkImg
} from 'actions';

function mapState(state) {
    return {
        inkTypes: selectInkTypes(state),
        inkImageList: selectInkImagesList(state)
    };
}

const mapDispatch = function(dispatch) {
    return bindActionCreators(
        {
            postInks: setInkDetails,
            updateInk: editInkDetail,
            deleteInkImg
        },
        dispatch
    );
};

class AddInksModal extends Component {
    componentDidMount() {
        const { props, initializeInkArray, setEditMode } = this;
        const {
            inkDataForEdit,
            inkIndexForEdit,
            inkModalType,
        } = props;
        if (inkModalType === 'ink') initializeInkArray();
        if (inkDataForEdit) setEditMode(inkDataForEdit, inkIndexForEdit);
    }

    state = {
        arrowRef: null,
        noOfInk: 0,
        editParent: false,
        editable: false,
        editId: null,
        editColor: '',
        editColorRef: '',
        editInkType: null,
        inkTypes: [],
        inkImages: [],
        uploadError: ''
    };

    resetState = () => {
        this.setState({
            noOfInk: 0,
            editParent: false,
            editable: false,
            editId: null,
            editColor: null,
            editColorRef: null,
            editInkType: null,
            inkTypes: [],
            inkImages: [],
            uploadError: ''
        });
    };

    initializeInkArray = () => {
        let singleInkFormat = {
            colour: '',
            colourRef: '',
            InkType: '',
            InkTitle: null
        };

        const size = this.state.noOfInk;
        let arr = [];

        for (let i = 0; i < size; i++) {
            arr.push(singleInkFormat);
        }

        this.setState({ inkTypes: arr });
    };

    handleNoOfInk = e => {
        const no = e.target.value;
        this.setState({ noOfInk: no ? no : 0 }, () => {
            if (!no || no <= 0) {
                this.resetState();
            } else if (no > 0) {
                this.initializeInkArray();
            }
        });
    };

    saveToState = (key, e) => this.setState({ [key]: e.target.value });

    // image handle
    handleInkImages = event => {
        const THIS = this;
        const { inkImages } = THIS.state;
        const { postInks } = THIS.props;
        THIS.setState({ uploadError: '' });

        const files = event.target.files;
        if (files.length > 0) {
            const images = [...inkImages];
            Object.keys(files).forEach(function(key) {
                if (files[key].size <= fileSize.bytes) {
                    images.push({ id: 0, file: files[key] });
                } else {
                    THIS.showUploadError('File size limit exceeded.');
                }
            });
            postInks({
                inkImages: images
            });
        }
    };

    // image handle error
    showUploadError = msg => {
        this.setState({ uploadError: msg });
        const THIS = this;
        setTimeout(function() {
            THIS.setState({ uploadError: '' });
        }, 3000);
    };

    // publish validator
    validateInkImageSave = () => {
        const { props, state } = this;
        const { inkTypes } = state;
        const { inkModalType, inkImageList } = props;
        return !!(
            (inkModalType === 'ink' &&
                inkTypes.filter(item => item.InkTitle !== null).length > 0) ||
            (inkModalType === 'img' && inkImageList.length > 0)
        );
    };

    // post
    saveInkImages = () => {
        const { props, state } = this;
        const { postInks, onClose } = props;
        const { inkTypes } = state;
        postInks({
            inks: inkTypes
        });
        onClose();
    };

    // edit - local
    editInkType = index => {
        const value = this.state.inkTypes[index];
        this.setState({
            editable: true,
            editId: index,
            editInkType: { label: value.InkTitle, value: value.InkType },
            editColor: value.colour,
            editColorRef: value.colourRef
        });
    };

    // edit - parent
    updateInkType = () => {
        const { updateInk, onClose } = this.props;
        const { editId, editInkType, editColor, editColorRef } = this.state;
        updateInk({
            inkIndex: editId,
            InkTitle: editInkType.label,
            InkType: editInkType.value,
            colour: editColor,
            colourRef: editColorRef
        });
        onClose();
    };

    // delete - local
    deleteInkType = idx => {
        const { inkTypes, noOfInk } = this.state;
        let types = inkTypes;
        let inkCount = noOfInk;
        let indexFound = types[idx] ? true : false;
        if (indexFound) {
            types.splice(idx, 1);
            inkCount -= 1;
        }
        this.setState({ inkTypes: types, noOfInk: inkCount });
    };

    // ink type dropdown
    setInkType = param => {
        const { editable, inkTypes } = this.state;
        if (editable) {
            const newObj = {
                value: param.value ? param.value.value : null,
                label: param.value ? param.value.label : null
            };
            this.setState({ editInkType: newObj });
        } else {
            for (let i = 0; i < inkTypes.length; i++) {
                inkTypes[i].InkType = param.value ? param.value.value : null;
                inkTypes[i].InkTitle = param.value ? param.value.label : null;
            }
            this.setState({ inkTypes: inkTypes });
        }
    };

    getSelectedInkType = () => {
        const { editable, editInkType, inkTypes } = this.state;
        let inkType = null;
        if (editable && editInkType) {
            inkType = {
                value: editInkType.value,
                label: editInkType.label
            };
        } else if (inkTypes.length > 0) {
            inkType = {
                value: inkTypes[0].InkType,
                label: inkTypes[0].InkTitle
            };
        }
        return inkType;
    };

    // Edit mode
    setEditMode = (value, idx) => {
        this.setState({
            editParent: true,
            editable: true,
            editId: idx,
            editColor: value.colour,
            editColorRef: value.colourRef,
            editInkType: { label: value.InkTitle, value: value.InkType }
        });
    };

    cancelEdit = () => {
        if (this.state.editParent) {
            this.resetState();
        } else {
            this.setState({
                editable: false,
                editId: null,
                editInkType: null,
                editColor: null,
                editColorRef: null
            });
        }
    };

    updateInkImage = () => {
        const {
            editId,
            editColor,
            editColorRef,
            editInkType,
            inkTypes
        } = this.state;
        const allInks = inkTypes.map(i => ({ ...i }));
        if (editColor) allInks[editId].colour = editColor;
        if (editColorRef) allInks[editId].colourRef = editColorRef;
        if (editInkType) {
            allInks[editId].InkType = editInkType.value;
            allInks[editId].InkTitle = editInkType.label;
        }

        this.setState({
            inkTypes: allInks,
            editable: false,
            editId: null,
            editInkType: null,
            editColor: null,
            editColorRef: null
        });
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    render() {
        const { props, state, saveToState } = this;
        const {
            classes,
            inkDataForEdit,
            inkModalType,
            inkTypes: inkTypesList,
            inkImageList,
            deleteInkImg,
            onClose
        } = props;
        const {
            editable,
            editId,
            noOfInk,
            editParent,
            editColor,
            editColorRef,
            editInkType,
            editFile,
            inkTypes,
            uploadError
        } = state;

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {editable ? 'Edit ink information' : 'Add ink information'}
                </ModalHeader>

                <ModalContent>
                    <GridContainer
                        spacing={40}
                        className={classes.gridContainer}
                    >
                        <GridItem xs={12} sm={6} md={6}>
                            {inkModalType === 'ink' ? ( // ink types
                                <Fragment>
                                    {editable ? (
                                        <Fragment>
                                            <TextInput
                                                required={true}
                                                labelText='Color: '
                                                value={editColor}
                                                handleText={e =>
                                                    saveToState('editColor', e)
                                                }
                                                isAmend={false}
                                            />
                                            <TextInput
                                                required={true}
                                                labelText='Color ref: '
                                                value={editColorRef}
                                                handleText={e =>
                                                    saveToState(
                                                        'editColorRef',
                                                        e
                                                    )
                                                }
                                                isAmend={false}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <TextInput
                                                required={true}
                                                labelText='No of Ink: '
                                                inputType='number'
                                                value={noOfInk}
                                                handleText={e =>
                                                    this.handleNoOfInk(e)
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    <SelectFields
                                        label='Select ink type: '
                                        data={inkTypesList}
                                        value={this.getSelectedInkType()}
                                        required={true}
                                        action={this.setInkType}
                                        isAmend={false}
                                    />
                                </Fragment>
                            ) : (
                                //ink image
                                <Fragment>
                                    <TextField
                                        fullWidth
                                        label='Upload ink images: '
                                        InputProps={{
                                            disableUnderline: true,
                                            onChange: e =>
                                                this.handleInkImages(e),
                                            inputProps: {
                                                type: 'file',
                                                multiple: editable
                                                    ? false
                                                    : true,
                                                accept: imgTypes.join(', '),
                                                size: fileSize.bytes,
                                                id: 'ink-upload-input'
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                root: classes.inputLabelRoot,
                                                focused:
                                                    classes.inputLabelFocused,
                                                shrink: classNames(
                                                    classes.inputLabelShrank,
                                                    classes.labelFileField
                                                )
                                            }
                                        }}
                                        className={classes.textField}
                                    />
                                    <label
                                        htmlFor='ink-upload-input'
                                        className={classNames(
                                            classes.browseButtonRoot,
                                            classes.inkUploadButton
                                        )}
                                    >
                                        <Typography
                                            className={classes.fileListLabel}
                                        >
                                            {uploadError.length > 0 ? (
                                                <span style={{ color: 'red' }}>
                                                    {uploadError}
                                                </span>
                                            ) : editable ? (
                                                editFile ? (
                                                    editFile.name
                                                ) : null
                                            ) : (
                                                inkTypes.map((ink, index) =>
                                                    ink.file
                                                        ? ink.file.name + ', '
                                                        : null
                                                )
                                            )}
                                        </Typography>
                                        <Button
                                            component='span'
                                            className={classes.browseButton}
                                            disableRipple
                                        >
                                            Browse
                                        </Button>
                                    </label>
                                    <Typography
                                        style={{
                                            color: '#aeaeae',
                                            marginTop: 10
                                        }}
                                    >
                                        * Allowed file extensions:{' '}
                                        {imgTypes.join(', ')}
                                        <br />* Maximum file size:{' '}
                                        {fileSize.megabytes} MB
                                    </Typography>
                                </Fragment>
                            )}
                        </GridItem>

                        {editParent ? null : (
                            <GridItem xs={12} sm={6} md={6}>
                                {/* Inks */}

                                {inkModalType === 'ink' && !inkDataForEdit ? (
                                    <Table className={classes.inkTable}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Added Ink{' '}
                                                    {noOfInk > 0 && !editable
                                                        ? '(' +
                                                          noOfInk +
                                                          ' items)'
                                                        : null}
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        {noOfInk > 0 ? (
                                            <TableBody>
                                                {Object.entries(inkTypes).map(
                                                    ([key, value]) => (
                                                        <TableRow
                                                            key={key}
                                                            className={
                                                                editable
                                                                    ? editId ===
                                                                      key
                                                                        ? null
                                                                        : classes.hideThisRow
                                                                    : null
                                                            }
                                                        >
                                                            <TableCell>
                                                                <Typography
                                                                    className={classNames(
                                                                        classes.inkTableText,
                                                                        classes.nowrapText
                                                                    )}
                                                                >
                                                                    <span>
                                                                        Color:{' '}
                                                                        {editable
                                                                            ? editColor
                                                                            : inkTypes[
                                                                                  key
                                                                              ]
                                                                                  .colour}
                                                                    </span>
                                                                    <span>
                                                                        Color
                                                                        ref:{' '}
                                                                        {editable
                                                                            ? editColorRef
                                                                            : inkTypes[
                                                                                  key
                                                                              ]
                                                                                  .colourRef}
                                                                    </span>
                                                                    <span>
                                                                        Ink
                                                                        type:{' '}
                                                                        {editable
                                                                            ? editInkType.label
                                                                            : inkTypes[
                                                                                  key
                                                                              ]
                                                                                  .InkTitle}
                                                                    </span>
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {editable ? null : (
                                                                    <Fragment>
                                                                        <Tooltip
                                                                            onClick={() =>
                                                                                this.editInkType(
                                                                                    key
                                                                                )
                                                                            }
                                                                            title='Edit it'
                                                                        >
                                                                            <EditIcon
                                                                                width='24px'
                                                                                height='24px'
                                                                                className={classNames(
                                                                                    classes.tooltipTriggerIcon,
                                                                                    classes.customSvgIcon
                                                                                )}
                                                                            />
                                                                        </Tooltip>

                                                                        <Tooltip
                                                                            onClick={() =>
                                                                                deleteInkImg(
                                                                                    {
                                                                                        id: key
                                                                                    }
                                                                                )
                                                                            }
                                                                            title='Delete it'
                                                                        >
                                                                            <CloseIcon
                                                                                className={classNames(
                                                                                    classes.tooltipTriggerIcon
                                                                                )}
                                                                            />
                                                                        </Tooltip>
                                                                    </Fragment>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={3}
                                                        style={{
                                                            textAlign: 'center',
                                                            color: '#aeaeae'
                                                        }}
                                                    >
                                                        Add ink
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                ) : null}

                                {/* Ink Images */}
                                {inkModalType === 'img' ? (
                                    <Table className={classes.inkTable}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Uploaded Images
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        {inkImageList.length > 0 ? (
                                            <TableBody>
                                                {inkImageList.map(
                                                    ({ file }, index) =>
                                                        typeof img ===
                                                            'object' &&
                                                        file.name !== void 0 ? (
                                                            <TableRow
                                                                key={index}
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        <img
                                                                            src={URL.createObjectURL(
                                                                                file
                                                                            )}
                                                                            alt='ink'
                                                                        />
                                                                    }
                                                                    <Typography
                                                                        className={
                                                                            classes.inkTableText
                                                                        }
                                                                    >
                                                                        <span>
                                                                            File:{' '}
                                                                            {
                                                                                file.name
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            Size:{' '}
                                                                            {(
                                                                                file.size /
                                                                                1000
                                                                            ).toFixed(
                                                                                2
                                                                            ) +
                                                                                ' KB'}
                                                                        </span>
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align='right'>
                                                                    <Tooltip
                                                                        onClick={() =>
                                                                            deleteInkImg(
                                                                                {
                                                                                    id: index
                                                                                }
                                                                            )
                                                                        }
                                                                        title='Delete it'
                                                                    >
                                                                        <CloseIcon
                                                                            className={classNames(
                                                                                classes.tooltipTriggerIcon
                                                                            )}
                                                                        />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : null
                                                )}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={3}
                                                        style={{
                                                            textAlign: 'center',
                                                            color: '#aeaeae'
                                                        }}
                                                    >
                                                        Add images
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                ) : null}
                            </GridItem>
                        )}
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer spacing={0} className={classes.footer}>
                        {editParent ? null : <GridItem xs={12} sm={6} md={6} />}
                        <GridItem xs={12} sm={6} md={6}>
                            <Fragment>
                                <Button
                                    onClick={
                                        editable
                                            ? editParent
                                                ? this.updateInkType
                                                : this.updateInkImage
                                            : this.saveInkImages
                                    }
                                    variant='contained'
                                    disabled={
                                        editable
                                            ? false
                                            : this.validateInkImageSave()
                                            ? false
                                            : true
                                    }
                                    classes={{
                                        root: classes.saveButton,
                                        disabled: classes.saveButtonDisabled
                                    }}
                                >
                                    {editable ? 'Update' : 'Save'}
                                </Button>
                                {editable ? (
                                    <Button
                                        onClick={this.cancelEdit}
                                        variant='contained'
                                        classes={{
                                            root: classes.cancelButton
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                            </Fragment>
                        </GridItem>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

AddInksModal.propTypes = {
    classes: PropTypes.object
};

const _AddInksModal = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(AddInksModal);

export { _AddInksModal as default, _AddInksModal as AddInksModal };
