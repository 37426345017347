import React, { Fragment } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Material
import { Grid, withStyles, Typography } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import { GridItem, Input } from '@formComponents';

// Local
import { addSetupItem } from '../../redux.actions';
import { ADD_ORDER_STATUS } from '../../redux.actionTypes';
import { FORM_NAME } from './common';
import { validationRules } from '../common';

const styles = ({ breakpoints, spacing, palette, typography }) => ({
    content: { width: breakpoints.values.sm },
    button: {
        width: '100%',
        margin: spacing.unit * 2
    },
    error: {
        color: palette.error.main,
        padding: spacing.unit * 1.5,
        textAlign: 'center'
    }
});

const Modal = compose(
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { onClose }) => {
            dispatch(
                addSetupItem({
                    ...values,
                    type: ADD_ORDER_STATUS,
                    handleCloseModal: onClose
                })
            );
        }
    }),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleSubmit,
        initialized,
        invalid,
        submitting,
        error
    }) => {
        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {`${initialized ? 'Update' : 'Add'} Status`}
                </ModalHeader>
                <ModalContent className={c.content}>
                    {error && (
                        <Typography className={c.error}>{error}</Typography>
                    )}
                    <Grid container>
                        <GridItem md={12}>
                            <Input
                                name='orderStatus'
                                label='Status'
                                validate={validationRules}
                                required
                            />
                        </GridItem>
                    </Grid>
                </ModalContent>
                <ModalFooter>
                    <Button
                        className={c.button}
                        onClick={handleSubmit}
                        loading={submitting}
                        disabled={invalid}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Fragment>
        );
    }
);

export { Modal as default, Modal };
