import { createSelector } from 'reselect';
import { sizePathsDatasetSelector } from './redux.selectors';

export const DEVELOPMENT_TYPES = '@developmentTypes';
export const SIZE_PATHS = '@sizePaths';
export const SHAPE_DESIGNERS = '@shapeDesigners';
export const GENDERS = '@genders';
export const AGES = '@ages';
export const CATEGORIES = '@categories';
export const STYLES = '@styles';
export const DESIGN_DEPARTMENT = '@design-department';

export default {
    [DEVELOPMENT_TYPES]: {
        url: 'DevType'
    },
    [SIZE_PATHS]: {
        url: 'SizePath/GetSizePaths',
        selector: createSelector(
            sizePathsDatasetSelector,
            sizePaths => !Array.isArray(sizePaths)
        )
    },
    [SHAPE_DESIGNERS]: {
        url: 'User/GetShapeDesigners'
    },
    [GENDERS]: {
        url: 'Genders'
    },
    [AGES]: {
        url: 'Age'
    },
    [CATEGORIES]: {
        url: 'Category'
    },
    [STYLES]: {
        url: 'Styles'
    },
    [DESIGN_DEPARTMENT]: {
        url: 'DesignDepartment'
    }
};
