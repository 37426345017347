import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { SAMPLE_FACTORY } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: SAMPLE_FACTORY,
    name: 'factory',
    label: '',
    placeholder: 'Select Factory',
    disabled: false,
    required: false
};

const SelectFactory = props => <Select {...props} />;

SelectFactory.propTypes = propTypes;
SelectFactory.defaultProps = defaultProps;

export { SelectFactory as default, SelectFactory };
