import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Paper, Typography } from '@material-ui/core';
import { composeClasses } from '../../helpers/materialUI';

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    styles: PropTypes.object,
    AdornmentComponent: PropTypes.object,
};

const defaultProps = {
    AdornmentComponent: <Fragment />,
};

const styles = ({ palette, spacing, typography }) => ({
    container: {
        borderRadius: 0,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        // height: spacing.unit * 7.5,
        height: spacing.unit * 6,
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4,

        backgroundColor: palette.grey[100],

        borderBottomWidth: 'thin',
        borderBottomStyle: 'solid',
        borderBottomColor: palette.grey[300],
    },
    title: {
        ...typography.h6,
        fontWeight: typography.fontWeightBold,
    },
});

const ContentContainer = withStyles(styles)(
    ({ classes, styles, children, title, AdornmentComponent }) => {
        const c = composeClasses({ classes, styles });
        return (
            <Paper className={c.container}>
                <div className={c.header}>
                    <Typography className={c.title}>{title}</Typography>
                    {AdornmentComponent}
                </div>
                {children}
            </Paper>
        );
    }
);

ContentContainer.propTypes = propTypes;
ContentContainer.defaultProps = defaultProps;

export { ContentContainer as default, ContentContainer };
