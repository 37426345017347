import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import {
    withStyles,
    Typography,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Local
import styles from './styles';
import { EditIcon, Tooltip, withModal } from 'components';
import { AddInksModal } from 'views/Sampling/modals';

// Actions
import { removeInkDetail } from 'store/actions';

class SamplingInkDetailsTable extends Component {
    render() {
        const { props } = this;

        const { classes: c, data, handleAddInksModal, removeInkDetail } = props;
        return (
            <Fragment>
                <Typography component='div' style={{ display: 'flex' }}>
                    <Typography style={{ flexGrow: 1, lineHeight: '35px' }}>
                        <b>Ink Details: </b>
                    </Typography>
                    <Button
                        variant='contained'
                        className={classNames(c.blackButton)}
                        onClick={() =>
                            handleAddInksModal({ inkModalType: 'ink' })
                        }
                    >
                        Add Ink
                    </Button>
                </Typography>
                <Table className={c.inkTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Added Inks{' '}
                                {data.length > 0
                                    ? '(' + data.length + ' items)'
                                    : null}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    {data.length ? (
                        <TableBody>
                            {data.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography className={c.inkTableText}>
                                            <span>
                                                Color:{' '}
                                                {item.colour ? item.colour : ''}
                                            </span>
                                            <span>
                                                Color ref:{' '}
                                                {item.colourRef
                                                    ? item.colourRef
                                                    : ''}
                                            </span>
                                            <span>
                                                Ink type:{' '}
                                                {item.InkTitle
                                                    ? item.InkTitle
                                                    : null}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Tooltip
                                            onClick={() =>
                                                handleAddInksModal({
                                                    inkDataForEdit: item,
                                                    inkIndexForEdit: index,
                                                    editInkType: 'ink',
                                                    inkModalType: 'ink'
                                                })
                                            }
                                            title='Edit it'
                                        >
                                            <EditIcon
                                                width='24px'
                                                height='24px'
                                                className={classNames(
                                                    c.tooltipTriggerIcon,
                                                    c.customSvgIcon
                                                )}
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            onClick={() => removeInkDetail({ index })}
                                            title='Delete it'
                                        >
                                            <CloseIcon
                                                className={classNames(
                                                    c.tooltipTriggerIcon
                                                )}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    style={{
                                        textAlign: 'center',
                                        color: '#aeaeae'
                                    }}
                                >
                                    Add ink
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </Fragment>
        );
    }
}

const mapDispatch = {
    removeInkDetail
};

const mapModals = {
    handleAddInksModal: AddInksModal
};

const _SamplingInkDetailsTable = compose(
    connect(
        undefined,
        mapDispatch
    ),
    withStyles(styles),
    withModal(mapModals)
)(SamplingInkDetailsTable);

export {
    _SamplingInkDetailsTable as default,
    _SamplingInkDetailsTable as SamplingInkDetailsTable
};
