import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, Button, withStyles } from '@material-ui/core';
import Chevron from '@material-ui/icons/ExpandMoreRounded';

const propTypes = {
    onToggle: PropTypes.func.isRequired,
    showLess: PropTypes.bool.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing, transitions }) => ({
    root: {
        width: '100%',
        height: spacing.unit * 3.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 0
    },
    label: {
        color: palette.primary.main
    },
    chevron: {
        color: palette.secondary.light,
        transform: 'rotate(var(--rotation))',
        transition: transitions.create('transform', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.short
        })
    }
});

const ViewToggle = withStyles(styles)(
    ({ classes: c, onToggle, showLess, ...props }) => {
        const chevronStyle = useMemo(
            () => ({
                '--rotation': showLess ? '360deg' : '180deg'
            }),
            [showLess]
        );

        return (
            <Button onClick={onToggle} className={c.root}>
                <Typography className={c.label}>{`View ${
                    showLess ? 'more' : 'less'
                }`}</Typography>
                <Chevron className={c.chevron} style={chevronStyle} />
            </Button>
        );
    }
);

ViewToggle.propTypes = propTypes;
ViewToggle.defaultProps = defaultProps;

export default ViewToggle;
