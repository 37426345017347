import React from 'react';
import { string } from 'prop-types';

import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing, palette }) => ({
    row: {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        marginRight: spacing.unit * 0.5
    },
    header: {
        fontSize: spacing.unit * 1.75,
        marginRight: spacing.unit * 0.5
    },
    value: {
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.light
    }
});

const propTypes = {
    label: string.isRequired,
    value: string
};

const Row = withStyles(styles)(({ classes: c, label, value }) => {
    return (
        <div className={c.row}>
            <Typography className={c.header}>{`${label}:`}</Typography>
            <Typography className={c.value}>{value}</Typography>
        </div>
    );
});

Row.propTypes = propTypes;

export default Row;
