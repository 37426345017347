import { uuidValidate } from 'helpers';

/*********
 * FABRIC *
 **********/
export const fabricPublishHelper = dataObject => {
    const fabricPublishForm = {
        //redux key: api key
        fabricId: 'fabricId',
        fabricType: 'fabricTypeId',
        fabricComposition: 'compositionId',
        fabricGsm: 'gsmId',
        fabricDye: 'dyeTypeId',
        fabricSpecialRef: 'specialFabricRef',
        fabricFinish: 'finishWashId',
        fabricColor: 'fabricColour',
        fabricEld: 'eld',
        fabricFor: 'fabricFor',
        fabricDischarge: 'isDischargeable',
        fabricYds: 'isYds',
        fabricEngineer: 'isEngineeredStripe',
        fabricFrom: 'fabricFrom'
    };

    // Extract value for each fabric card field
    const getExtractedValue = value => {
        let returnValue = value;
        if (
            typeof value === 'object' &&
            Object.keys(value).length &&
            value.type !== void 0
        ) {
            //Todo: Fab form
            if (value.title === 'Fab From') {
                returnValue = value.text || '';
            } else {
                returnValue = value.id || 0;
            }

            if (value.type.toLowerCase() === 'check') returnValue = value.text;
        } else if (uuidValidate(value)) {
            returnValue = 0;
        }

        return returnValue;
    };

    const splicedForm = Object.splice(dataObject, fabricPublishForm);
    Object.keys(splicedForm).forEach(item => {
        const value = getExtractedValue(splicedForm[item]);
        if (value) {
            splicedForm[item] = value;
        } else {
            delete splicedForm[item];
        }
    });

    // Todo: change fabricId to uuid
    if (!splicedForm.fabricId || splicedForm.fabricId.includes('fabric')) {
        splicedForm.fabricId = 0;
    }

    return splicedForm;
};

/************
 * BACKNECK *
 ************/
export const backneckPublishHelper = dataObject => {
    const backneckPublishForm = {
        //redux key: api key
        backNeckAutoId: 'backneckId',
        backneckAutoId: 'backneckId', // Todo: no duplicate please
        images: 'file',
        backneckPrintHeight: 'backneckPrintHeight',
        backneckPrintWidth: 'backneckPrintWidth',
        fabricDetails: 'fabricDetails',
        fabricPatchHeight: 'fabricPatchHeight',
        fabricPatchWidth: 'fabricPatchWidth',
        graphicRef: 'graphicRef',
        ink1: 'ink1',
        ink2: 'ink2',
        backNeckImageRefId: 'backNeckImageRefId'
    };
    // Extract value for each fabric card field
    const getExtractedValue = value => {
        let returnValue = value;
        if (
            typeof value === 'object' &&
            Object.keys(value).length &&
            value.type !== void 0
        ) {
            if (value.type.toLowerCase() === 'text')
                returnValue = value.text || '';
        } else if (uuidValidate(value)) {
            returnValue = 0;
        }

        return returnValue;
    };
    const splicedForm = Object.splice(dataObject, backneckPublishForm);
    Object.keys(splicedForm).forEach(item => {
        if (!!splicedForm[item] && splicedForm[item] !== 0) {
            splicedForm[item] = getExtractedValue(splicedForm[item]);
        }
    });

    if (!splicedForm.backNeckImageRefId) {
        splicedForm.backNeckImageRefId = 0;
    }

    return splicedForm;
};

/*********
 * BOUGHT *
 **********/
export const boughtPublishHelper = dataObject => {
    const boughtPublishForm = {
        //redux key: api key
        boughtAutoId: 'boughtDetailsId',
        boughtRef: 'boughtReference'
    };

    // Extract value for each fabric card field
    const getExtractedValue = value => {
        let returnValue = value;
        if (
            typeof value === 'object' &&
            Object.keys(value).length &&
            value.type !== void 0
        ) {
            if (value.type.toLowerCase() === 'text')
                returnValue = value.id || '';
        } else if (uuidValidate(value)) {
            returnValue = 0;
        }

        return returnValue;
    };

    const splicedForm = Object.splice(dataObject, boughtPublishForm);
    Object.keys(splicedForm).forEach(
        item => (splicedForm[item] = getExtractedValue(splicedForm[item]))
    );

    return splicedForm;
};

/********
 * TRIM *
 ********/
export const trimPublishHelper = dataObject => {
    const trimPublishForm = {
        //redux key: api key
        trimAutoId: 'trimsId',
        trimCardRef: 'trimCardRef',
        trimDevNo: 'trimDevNo',
        trimApplication: 'application',
        trimQty: 'quantity',
        trimColor: 'colour',
        trimPosition: 'position'
    };

    // Extract value for each fabric card field
    const getExtractedValue = value => {
        let returnValue = value;
        if (
            typeof value === 'object' &&
            Object.keys(value).length &&
            value.type !== void 0
        ) {
            if (value.type.toLowerCase() === 'text')
                returnValue = value.id || '';
        } else if (uuidValidate(value)) {
            returnValue = 0;
        }

        return returnValue;
    };

    const splicedForm = Object.splice(dataObject, trimPublishForm);
    Object.keys(splicedForm).forEach(
        item => (splicedForm[item] = getExtractedValue(splicedForm[item]))
    );
    // Todo: Figure out something to replace manual assignment
    if (!splicedForm.quantity) {
        splicedForm.quantity = 0;
    }

    return splicedForm;
};

/*******
 * INKS *
 ********/
export const inksPublishHelper = dataObject => {
    const trimPublishForm = {
        //redux key: api key
        inkAutoId: 'inksId',
        InkType: 'inkTypeId',
        colour: 'colour',
        colourRef: 'colourRef'
    };

    const splicedForm = Object.splice(dataObject, trimPublishForm);
    if (!splicedForm.hasOwnProperty('inksId') || !splicedForm.inksId)
        splicedForm.inksId = 0;

    return splicedForm;
};

export const inksImagesPublishHelper = dataArray => {
    const inkImages = {
        inkId: [],
        files: []
    };

    dataArray.map(item => {
        if (typeof item.file === 'object' && item.file.name !== void 0) {
            if (Number(item.id) === 0) {
                return inkImages['files'].push(item.file);
            } else {
                return inkImages['inkId'].push(item.id);
            }
        }
    });

    return inkImages;
};

/***************
 * ATTACHMENTS *
 **************/
export const attachmentsPublishHelper = dataArray => {
    const attachments = {
        attachmentId: [],
        attachments: []
    };
    dataArray.map(item => {
        if (
            item.file &&
            typeof item.file === 'object' &&
            item.file.name !== void 0
        ) {
            if (Number(item.id) === 0) {
                attachments['attachments'].push(item.file);
            } else {
                attachments['attachmentId'].push(item.id);
            }
        }
        return true;
    });

    return attachments;
};
