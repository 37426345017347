import React, { createContext } from 'react';

const initialState = {
    data: [],
    initialData: [],
    checkedItems: [],
    allChecked: false
};

const dialogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'initialize': {
            const { data, inititalValue } = payload;
            if (inititalValue === '') {
                return {
                    ...state,
                    data,
                    initialData: data
                };
            }

            const initialValuesToArray = inititalValue.split(',');
            const allChecked = data.every(({ value }) =>
                initialValuesToArray.includes(value.toString())
            );

            return {
                ...state,
                data,
                initialData: data,
                checkedItems: initialValuesToArray.map(value => Number(value)),
                allChecked
            };
        }
        case 'set-checked-items': {
            const { data, checkedItems, allChecked } = state;
            if (checkedItems.includes(payload)) {
                return {
                    ...state,
                    allChecked: false,
                    checkedItems: checkedItems.filter(
                        value => value !== payload
                    )
                };
            }
            if (payload === 0 && allChecked === true) {
                return {
                    ...state,
                    allChecked: false,
                    checkedItems: []
                };
            }
            const newCheckedItems = [...checkedItems, payload];
            if (payload === 0 || newCheckedItems.length === data.length) {
                return {
                    ...state,
                    allChecked: true,
                    checkedItems: data.map(({ value }) => value)
                };
            }
            return {
                ...state,
                allChecked: false,
                checkedItems: newCheckedItems
            };
        }
        case 'set-search': {
            const { initialData, checkedItems } = state;
            const results = initialData.filter(({ label }) => {
                return (
                    payload === '' ||
                    label
                        .toString()
                        .toLowerCase()
                        .includes(payload.toString().toLowerCase())
                );
            });
            const allChecked =
                !!results.length &&
                results.every(({ value }) => checkedItems.includes(value));
            return {
                ...state,
                data: results,
                allChecked
            };
        }
        default:
            return state;
    }
};

const MultipleSelectDialogContext = createContext({
    state: initialState,
    dispatch: void 0
});

const withDialogContext = Component => {
    return props => (
        <MultipleSelectDialogContext.Consumer>
            {({ state, dispatch }) => (
                <Component
                    {...props}
                    dialogState={state}
                    dialogDispatch={dispatch}
                />
            )}
        </MultipleSelectDialogContext.Consumer>
    );
};

export {
    withDialogContext as default,
    MultipleSelectDialogContext,
    dialogReducer,
    initialState
};
