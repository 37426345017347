import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid, withStyles } from '@material-ui/core';

const propTypes = {
    images: PropTypes.array.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {
        height: spacing.unit * 14,
        paddingTop: spacing.unit * 1.5,
        paddingBottom: spacing.unit * 1.5
    },
    imageContainer: {
        height: '100%',
        paddingRight: spacing.unit * 0.75,
        paddingLeft: spacing.unit * 0.75
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        backgroundColor: palette.background.main,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    }
});

const ImagesRow = withStyles(styles)(({ classes: c, images, ...props }) => {
    return (
        <Grid container className={c.root}>
            {images.map((image, index) => (
                <Grid className={c.imageContainer} item md={4}>
                    <img
                        className={c.image}
                        src={image}
                        alt={`Bought ${index + 1}`}
                    />
                </Grid>
            ))}
        </Grid>
    );
});

ImagesRow.propTypes = propTypes;
ImagesRow.defaultProps = defaultProps;

export default ImagesRow;
