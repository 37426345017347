import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {
    getPOSizeTableHeadersSelector,
    getPOSizeDataSummarySelector
} from 'orders/redux.selectors';

import Input from './input';

const propTypes = {
    data: PropTypes.array.isRequired,
    tableLocked: PropTypes.bool,
    sizeDetailsFieldsError: PropTypes.object,
    handleSizeDetailsFieldsError: PropTypes.func
};

const defaultProps = {
    tableLocked: false,
    sizeDetailsFieldsError: {},
    handleSizeDetailsFieldsError: f => f => f
};

const styles = ({ palette, spacing }) => ({
    poSizeTable: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#c8ced4',
        borderWidth: 1,
        height: 'auto'
    },
    poSizeTableRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    poSizeTableCellWrapper: {
        flex: 1,
        backgroundColor: fade(palette.background.light, 0.75),
        borderRight: '1px solid #c8ced4',
        borderBottom: '1px solid #c8ced4',
        '&:last-child': {
            borderRight: 'none'
        }
    },
    poSizeTableFooterCellWrapper: {
        borderBottom: 'none'
    },
    poSizeTableCell: {
        fontSize: spacing.unit * 1.75,
        fontFamily: 'Museo',
        padding: `${spacing.unit * 0.5}px ${spacing.unit * 2}px`
    },
    poSizeTableHeaderCell: {
        fontWeight: 600
    },
    poSizeTableFooterCell: {
        fontSize: spacing.unit * 2,
        fontWeight: 600
    }
});

const SizeBreakdownTable = withStyles(styles)(
    ({
        classes: c,
        data,
        tableLocked,
        sizeDetailsFieldsError,
        handleSizeDetailsFieldsError
    }) => {
        const sizeDataHeaders = getPOSizeTableHeadersSelector(data);
        const sizeDataSummary = getPOSizeDataSummarySelector(data);

        const getFieldType = fieldName => {
            return fieldName === 'quantity' || fieldName === 'ratio'
                ? 'number'
                : 'text';
        };

        const hasAnyFieldsError = useMemo(() => {
            return !!Object.keys(sizeDetailsFieldsError)[0];
        }, [sizeDetailsFieldsError]);

        const isFieldLocked = (index, fieldName) => {
            const fieldError = !!sizeDetailsFieldsError[
                `sizeDetails[${index}].${fieldName}`
            ];

            return data[index].locked[fieldName] || hasAnyFieldsError
                ? !fieldError
                : fieldError;
        };

        return data.length > 0 ? (
            <div className={c.poSizeTable}>
                <div className={c.poSizeTableRow}>
                    {sizeDataHeaders.map(({ header, name }) => (
                        <div
                            className={c.poSizeTableCellWrapper}
                            key={`header-cell-${name}`}
                        >
                            <Typography
                                className={clsx(
                                    c.poSizeTableCell,
                                    c.poSizeTableHeaderCell
                                )}
                            >
                                {header}
                            </Typography>
                        </div>
                    ))}
                </div>
                {data.map((data, index) => (
                    <div className={c.poSizeTableRow} key={index}>
                        {sizeDataHeaders.map(({ name }) => {
                            const isCellLocked =
                                tableLocked || isFieldLocked(index, name);
                            const fieldName = `sizeDetails[${index}].${name}`;
                            const fieldError = !!sizeDetailsFieldsError[
                                fieldName
                            ];
                            return (
                                <div
                                    className={c.poSizeTableCellWrapper}
                                    key={`body-cell-${name}`}
                                >
                                    {isCellLocked ? (
                                        <Typography
                                            className={c.poSizeTableCell}
                                        >
                                            {data[name]}
                                        </Typography>
                                    ) : (
                                        <Input
                                            name={fieldName}
                                            type={getFieldType(name)}
                                            hasError={fieldError}
                                            validateSizeDetailsFields={handleSizeDetailsFieldsError(
                                                name,
                                                index,
                                                data.quantity,
                                                sizeDataSummary
                                            )}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
                <div className={c.poSizeTableRow}>
                    {sizeDataHeaders.map(({ name }) => (
                        <div
                            className={clsx(
                                c.poSizeTableCellWrapper,
                                c.poSizeTableFooterCellWrapper
                            )}
                            key={`footer-cell-${name}`}
                        >
                            <Typography
                                className={clsx(
                                    c.poSizeTableCell,
                                    c.poSizeTableFooterCell
                                )}
                            >
                                {sizeDataSummary[name]}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        ) : null;
    }
);

SizeBreakdownTable.propTypes = propTypes;
SizeBreakdownTable.defaultProps = defaultProps;

export default SizeBreakdownTable;
