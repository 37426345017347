import React from 'react';
import { object } from 'prop-types';
// Materials
import { withStyles, Grid } from '@material-ui/core';
// Libs
import { GridItem } from '@formComponents';
// Local
import BasicColumn from './BasicColumn';
import { usePOBasics } from './hooks';

const propTypes = {
    basicInfo: object.isRequired
};

const styles = ({ spacing }) => ({
    basicContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        padding: `${spacing.unit * 2}px 0px`,
        margin: `0px ${spacing.unit * 1.5}px`
    },
    spaceBottom: {
        marginBottom: spacing.unit * 0.5
    }
});

const BasicContainer = withStyles(styles)(({ classes: c, basicInfo }) => {
    const columns = usePOBasics(basicInfo);
    return (
        <Grid container className={c.basicContainer}>
            {columns.map((column, idx) => (
                <GridItem
                    md={3}
                    className={c.spaceBottom}
                    key={`basic-column-${idx}`}
                >
                    <BasicColumn {...column} />
                </GridItem>
            ))}
        </Grid>
    );
});

BasicContainer.propTypes = propTypes;

export default BasicContainer;
