import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { withStyles, Paper, Typography } from '@material-ui/core';
import { composeClsx } from '@libs/materialUI';

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    required: PropTypes.bool,
    shouldBorderRed: PropTypes.bool,
    error: PropTypes.string,
    styles: PropTypes.object,
    AdornmentComponent: PropTypes.object
};

const defaultProps = {
    AdornmentComponent: <Fragment />,
    required: false,
    shouldBorderRed: false,
    error: ''
};

const styles = ({ palette, spacing, typography, shadows }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        boxShadow: shadows[0]
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        height: spacing.unit * 6.5,
        padding: spacing.unit,
        paddingLeft: spacing.unit * 4,

        backgroundColor: palette.background.light,

        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark
    },
    title: {
        ...typography.h6,
        fontWeight: typography.fontWeightBold,
        whiteSpace: 'nowrap',
        position: 'relative'
    },
    requiredAsterisk: {
        position: 'absolute',
        top: 0,
        right: -(spacing.unit * 0.75),
        color: palette.error.main,
        fontSize: typography.fontSize
    },
    borderRed: {
        borderColor: `${palette.error.main} !important`
    },
    error: {
        width: '100%',
        marginTop: spacing.unit * 0.5,
        marginBottom: spacing.unit * 0.5,
        color: palette.error.main,
        textAlign: 'center',
        fontSize: spacing.unit * 1.75,
        lineHeight: 1.2
    }
});

const ContentContainer = withStyles(styles)(
    ({
        classes,
        styles,
        children,
        title,
        AdornmentComponent,
        required,
        shouldBorderRed,
        error,
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });
        return (
            <Paper
                className={clsx(c.container, shouldBorderRed && c.borderRed)}
                {...restProps}
            >
                <div className={c.header}>
                    {title && (
                        <Typography className={c.title}>
                            {title}
                            {required && (
                                <span className={c.requiredAsterisk}>*</span>
                            )}
                        </Typography>
                    )}
                    {AdornmentComponent}
                </div>
                {error && <Typography className={c.error}>{error}</Typography>}
                {children}
            </Paper>
        );
    }
);

ContentContainer.propTypes = propTypes;
ContentContainer.defaultProps = defaultProps;

export { ContentContainer as default, ContentContainer };
