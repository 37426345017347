import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME } from '../common';
import { SPEC_REFERENCES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'specReference',
    label: 'Spec Reference',
    placeholder: 'Select reference',
    disabled: false,
    required: false
};

const SelectSpecReferences = props => (
    <PaginatedSelect {...props} domain={DOMAIN_NAME} entity={SPEC_REFERENCES} />
);

SelectSpecReferences.propTypes = propTypes;
SelectSpecReferences.defaultProps = defaultProps;

export { SelectSpecReferences as default, SelectSpecReferences };
