import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

import Checkbox from '@baseComponents/Checkbox';

const propTypes = {
    selectAll: PropTypes.bool.isRequired,
    onToggleAllSelection: PropTypes.func.isRequired
};

const styles = ({ spacing, palette }) => ({
    root: {
        fontFamily: 'Museo',
        width: '100%',
        padding: `${spacing.unit}px ${spacing.unit * 2}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    tableCell: {
        flex: 1,
        fontWeight: 700,
        fontSize: spacing.unit * 1.75,
        '&:last-child': {
            textAlign: 'center'
        }
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
});

const PermissionTableHeader = withStyles(styles)(
    ({ classes: c, selectAll, onToggleAllSelection }) => {
        return (
            <div className={c.root}>
                <div className={c.tableCell}>
                    <div
                        className={c.checkboxWrapper}
                        onClick={onToggleAllSelection}
                    >
                        <Checkbox checked={selectAll} />
                        <Typography>Select All</Typography>
                    </div>
                </div>
                <div className={c.tableCell}>Name</div>
                <div className={c.tableCell}></div>
            </div>
        );
    }
);

PermissionTableHeader.propTypes = propTypes;

export default PermissionTableHeader;
