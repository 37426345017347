export const generateReferenceSchema = {
    retailerId: 0,
    artworkSourceId: 0,
    manualRef: 'string',
    userId: 0
};

export const publishSchema = {
    id: 0,
    fabricReference: 'string',
    versionNo: 0,
    isDiscontuned: true,
    fabricDevTypeSentId: 0,
    fabricBoughtSampleReference: 'string',
    originalFabric: {
        typeId: 0,
        compositionId: 0,
        gsmId: 0
    },
    requiredFabric: {
        typeId: 0,
        compositionId: 0,
        gsmId: 0
    },
    isMatchFabricColor: true,
    isMatchWash: true,
    fabricSpecialFinishId: 0,
    fabricColorName: 'string',
    fabricColorReference: 'string',
    fabricComments: 'string',
    fabricRequestedById: 0,
    fabricDepartmentId: 0,
    fabricBuyerContactPerson: 'string',
    isSpeed: true,
    isAvailability: true,
    isSustainability: true,
    dateRequested: '2024-01-09T12:29:32.454Z',
    dateRequired: '2024-01-09T12:29:32.454Z',
    fabricHangerTypeId: 0,
    fabricFactoryId: 0,
    isCancel: true,
    isHandOver: true,
    yarnBatchNo: 'string',
    receipeNo: 'string',
    developedFabric: {
        typeId: 0,
        compositionId: 0,
        gsmId: 0
    },
    fabricYarnCountId: 0,
    yarnLotNo: 'string',
    yarnSupplierId: 0,
    shrinkageLength: 0,
    shrinkageWidth: 0,
    shrinkageSpirality: 0,
    pillingLength: 0,
    pillingWidth: 0,
    fabricPillingMethodId: 0,
    elastaneUnrecoveryLength: 0,
    elastaneUnrecoveryWidth: 0,
    fabricDryProcessId: 0,
    fabricApprovalStatusId: 0,
    fabricApprovalById: 0,
    dateApproved: '2024-01-09T12:29:32.454Z',
    fabricApprovalComments: 'string',
    userId: 0,
    originalImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    washImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    finalImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    }
};
