import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import ReactDOM from 'react-dom';

// Material
import {
    Select as MuiSelect,
    TextField,
    FormControl,
    LinearProgress,
    FormHelperText
} from '@material-ui/core';

// Local
import LabelBase from '../../Label';
import { composeClsx } from '@libs/materialUI';
import MultipleSelectCancellableIcon from './MultipleSelectCancellableIcon';
import MultipleSelectDropdownIcon from './MultipleSelectDropdownIcon';
import MultipleSelectPlaceholder from './MultipleSelectPlaceholder';
import MultipleSelectDialog from './MultipleSelectDialog';

/*
 * Domain: --
 * Page: MultipleSelect
 * Component: Base
 * Type: --
 * SelectBase
 */
class MultipleSelect extends Component {
    // Set prop types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        isCancellable: PropTypes.bool,
        displayFirst: PropTypes.bool,
        placeholder: PropTypes.string
    };

    // Set default props
    static defaultProps = {
        styles: {},
        displayFirst: false,
        isCancellable: false,
        placeholder: null
    };

    state = {
        data: this.props.data,
        initialSelected: this.props.value,
        selectStyle: null,
        placeholderStyle: null,
        showPlaceholder: !!this.props.placeholder,
        dialogAnchor: null,
        isDisabled: this.props.disabled || this.props.loading,
        selectWidth: 0
    };

    componentDidMount() {
        const {
            input: { onChange },
            data,
            displayFirst
        } = this.props;

        if (displayFirst) onChange(data[0]);
    }

    static getDerivedStateFromProps(props, state) {
        const { queryValue } = state;

        const {
            classes: { select, selectCancellable, selectQuery },
            isCancellable,
            styles,
            meta: { dirty },
            placeholder
        } = props;

        const nextState = {
            selectStyle: clsx(select, styles.select),
            showPlaceholder: Boolean(placeholder)
        };

        if (isCancellable && dirty) {
            nextState.selectStyle = clsx(
                nextState.selectStyle,
                selectCancellable
            );
        }

        // There's no other way <-- Efficient though
        if (Boolean(queryValue)) {
            if (dirty) {
                nextState.selectStyle = clsx(
                    nextState.selectStyle,
                    selectQuery
                );
            } else {
                if (Boolean(placeholder)) {
                    nextState.showPlaceholder = false;
                }
            }
        }

        return nextState;
    }

    onBlur = () => {
        this.setState({ selectFocus: false });
        this.props.input.onBlur();
    };

    renderDropdownIcon = () => {
        const { state, props, onFocus } = this;
        const { styles = {} } = props;
        const { isDisabled } = state;
        const propStyles = Object.splice(styles, [
            'dropdownRoot',
            'dropdownIcon'
        ]);
        return (
            <MultipleSelectDropdownIcon
                styles={propStyles}
                onClick={onFocus}
                disabled={isDisabled}
            />
        );
    };

    selectRef = ref => {
        try {
            const { offsetWidth } = ReactDOM.findDOMNode(ref);
            this.setState({
                selectWidth: offsetWidth
            });
        } catch {
            this.setState({
                selectWidth: 0
            });
        }
    };

    showDialog = event => {
        !this.props.disabled &&
            this.setState({ dialogAnchor: event.currentTarget });
    };

    onChange = newValue => {
        const {
            input: { onChange, value }
        } = this.props;
        if (value !== newValue) {
            onChange(newValue);
        }
    };

    hideDialog = selectedItems => {
        this.onChange(selectedItems);
        this.setState({ dialogAnchor: null });
    };

    render() {
        const {
            state,
            props,
            renderDropdownIcon,
            onChange,
            showDialog,
            hideDialog,
            selectRef
        } = this;

        const {
            selectStyle,
            showPlaceholder,
            isDisabled,
            dialogAnchor,
            selectWidth
        } = state;

        const {
            classes,
            styles,
            input,
            meta: { form, touched, error },
            isCancellable,
            placeholder: placeholderValue,
            className,
            label,
            required,
            disabled,
            loading,
            data
        } = props;

        const c = composeClsx({
            classes: { ...classes, select: selectStyle },
            styles
        });

        const { name, value, onBlur: noBlur } = input;
        const convertIdToName = () => {
            if (value === '') return '';
            const valuesArray = value.split(',');
            const allSelected = data.every(({ value }) =>
                valuesArray.includes(value.toString())
            );
            /* If only one item in data then name should be displayed reather than ALL  */
            if (allSelected && data.length > 1) return 'All';

            return data
                .filter(
                    ({ value: dataValue }) =>
                        valuesArray.indexOf(dataValue.toString()) > -1
                )
                .map(({ label }) => label)
                .toString();
        };
        const selectProps = {
            classes: { ...Object.splice(c, ['root', 'select', 'icon']) },
            IconComponent: renderDropdownIcon,
            disableUnderline: true,
            onClick: showDialog,
            value: 0,
            disabled: isDisabled
        };
        const id = `${form}-${name}`;
        const valid = !!value;
        const success = !isDisabled && valid && !dialogAnchor;

        return (
            <FormControl className={c.container}>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                        htmlFor={id}
                    />
                )}
                <FormControl
                    className={clsx(
                        c.control,
                        className,
                        isDisabled && c.disabled,
                        success && c.success
                    )}
                    styles={{ position: 'relative' }}
                >
                    {/*Todo: Convert to InputBase element */}

                    <TextField
                        classes={{ root: c.inputRoot }}
                        inputProps={{ className: c.input, readOnly: true }}
                        value={convertIdToName()}
                        htmlFor={id}
                        onClick={showDialog}
                        disabled={isDisabled}
                        ref={selectRef}
                    />

                    {showPlaceholder && !success && !isDisabled && (
                        <MultipleSelectPlaceholder
                            styles={Object.splice(c, ['placeholder'])}
                            htmlFor={id}
                            placeholder={placeholderValue}
                        />
                    )}
                    {isCancellable && success && (
                        <MultipleSelectCancellableIcon
                            styles={Object.splice(styles, [
                                'cancellableRoot',
                                'cancellableIcon'
                            ])}
                            onClick={() => onChange('')}
                        />
                    )}
                    <MuiSelect {...selectProps} />
                    {dialogAnchor && (
                        <MultipleSelectDialog
                            hideDialog={hideDialog}
                            anchor={dialogAnchor}
                            data={data}
                            dialogWidth={selectWidth}
                            value={value}
                        />
                    )}
                    {!disabled && loading && (
                        <LinearProgress
                            classes={{
                                root: c.progress,
                                barColorPrimary: c.progressPrimary
                            }}
                        />
                    )}
                </FormControl>
                {touched && error && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

export { MultipleSelect as default, MultipleSelect as MultipleSelectBase };
