import { useMemo } from 'react';

import { LABELS } from '../common';
import { useDetailsLabel } from '../hooks';

const useSchema = (
    mode,
    { hasManualReference, hasMatchWashAndFinish, hasMatchColor }
) => {
    const fabricDetailsLabel = useDetailsLabel(mode);

    const setupSchema = useMemo(
        () =>
            mode === 'new'
                ? {
                      retailer: true,
                      artworkSource: true,
                      ...(hasManualReference
                          ? {
                                manualReference: true,
                                manualReferenceAsync: fieldValue => {
                                    return fieldValue === 'Valid';
                                }
                            }
                          : {})
                  }
                : {},
        [mode, hasManualReference]
    );

    const detailsNonRequiredSchema = useMemo(
        () => ({
            originalFabricImage: (_, fieldError) => !fieldError,
            washedImage: (_, fieldError) => !fieldError
        }),
        []
    );

    const detailsSchema = useMemo(
        () => ({
            fabricReference: true,
            versionNo: true,
            developmentType: true,
            originalFabric: (_, fieldError = {}) => {
                return Object.keys(fieldError).length === 0;
            },
            requiredFabric: (_, fieldError = {}) => {
                return Object.keys(fieldError).length === 0;
            },
            specialFinish: !hasMatchWashAndFinish,
            color: !hasMatchColor,
            colorReference: !hasMatchColor,
            ...detailsNonRequiredSchema
        }),
        [hasMatchColor, hasMatchWashAndFinish]
    );

    const othersNonRequiredSchema = useMemo(
        () => ({
            finalImage: (_, fieldError) => !fieldError
        }),
        []
    );

    const othersSchema = useMemo(
        () => ({
            requestBy: true,
            retailer: true,
            dateRequested: true,
            dateRequired: true,
            ...othersNonRequiredSchema
        }),
        []
    );

    const draftSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsNonRequiredSchema,
            ...othersNonRequiredSchema
        }),
        [setupSchema]
    );

    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema,
            ...othersSchema
        }),
        [setupSchema, detailsSchema, othersSchema]
    );

    return useMemo(
        () => ({
            ...(mode === 'new' ? { [LABELS.setup]: setupSchema } : {}),
            [fabricDetailsLabel]: detailsSchema,
            [LABELS.others]: othersSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: draftSchema
        }),
        [
            mode,
            setupSchema,
            detailsSchema,
            othersSchema,
            fabricDetailsLabel,
            publishSchema,
            draftSchema
        ]
    );
};

export default useSchema;
