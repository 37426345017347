export default theme => ({
    blackButton: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    },
    inkTable: {
        backgroundColor: '#fcfcfc',
        border: '1px solid #e4e4e4',
        marginTop: 10,
        '& thead': {
            '& tr': {
                height: 40,
                backgroundColor: '#f4f4f4',
                '& th': {
                    color: 'black',
                    paddingRight: 0,
                    paddingLeft: 13,
                    fontSize: '0.875rem',
                    '&:last-child': {
                        width: 110,
                        paddingRight: '13px !important',
                        borderRight: '1px solid #e4e4e4;'
                    }
                }
            }
        },
        '& tbody': {
            '& tr': {
                height: 38,
                '& td': {
                    padding: 13,
                    position: 'relative',
                    '&:first-child': {
                        '& > img': {
                            maxHeight: 50
                        }
                    }
                }
            }
        }
    },
    inkTableText: {
        marginLeft: 15,
        display: 'inline-block',
        verticalAlign: 'top',
        fontSize: '.75rem',
        '& > span': {
            display: 'block',
            lineHeight: '15px',
            height: 15,
            overflow: 'hidden'
        }
    },
    tooltipTriggerIcon: {
        marginLeft: 10,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    customSvgIcon: {
        padding: 6
    },
})