import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

import { ImageDnD } from '@formComponents';
import { validateImageSizeAndExtension } from '@libs/validationRules';

import PreviewImage from '../PreviewImage';

const styles = ({ spacing }) => ({
    dndImageContent: {
        height: spacing.unit * 30
    }
});

const mapState = (state, { formName }) => ({
    originalFabricImage: formValueSelector(formName)(
        state,
        'originalFabricImage'
    )
});

const OriginalFabricImage = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, shouldLocked, originalFabricImage }) => {
    return (
        <>
            {shouldLocked ? (
                <PreviewImage
                    {...originalFabricImage}
                    alt='Original fabric image'
                />
            ) : (
                <ImageDnD
                    name='originalFabricImage'
                    validate={validateImageSizeAndExtension}
                    styles={{ content: c.dndImageContent }}
                />
            )}
        </>
    );
});

export default OriginalFabricImage;
