import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import ImagesProvider from './useImages';
import { IMAGE_CONTAINER_WIDTH_UNITS } from '../../constants';
import Image from './Image';
import { useInitializeStyles } from './hooks';

const propTypes = {
    images: PropTypes.array.isRequired
};

const styles = ({ spacing }) => ({
    imageContainer: {
        width: spacing.unit * IMAGE_CONTAINER_WIDTH_UNITS,
        maxHeight: spacing.unit * IMAGE_CONTAINER_WIDTH_UNITS * Math.sqrt(2),
        marginRight: spacing.unit * 2,
        flexDirection: 'row'
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    }
});

const Images = withPDFStyles(styles, { withTheme: true })(
    ({ images, theme, classes: c }) => {
        const {
            imagesHeight,
            imagesPerRow,
            imagesPaddingUnits,
            imageLabelHeightUnits,
            imageLabelFontSize
        } = useInitializeStyles({
            maxHeight: 130 * 8,
            imageCount: images.length,
            theme
        });
        const initialize = useCallback(
            state => ({
                ...state,
                imagesPerRow,
                imagesPaddingUnits,
                imageLabelHeightUnits,
                imageLabelFontSize
            }),
            [
                imagesPerRow,
                imagesPaddingUnits,
                imageLabelHeightUnits,
                imageLabelFontSize
            ]
        );
        return (
            <View style={c.imageContainer}>
                <ImagesProvider initialize={initialize}>
                    {images.map(({ url, header }, idx) => (
                        <Image
                            url={url}
                            header={header}
                            key={`pdf-row-image-${idx}`}
                        />
                    ))}
                </ImagesProvider>
            </View>
        );
    }
);

Images.propTypes = propTypes;

export default Images;
