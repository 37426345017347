import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    initialize as formInitialize,
    getFormAsyncErrors,
    getFormSubmitErrors,
    getFormSyncErrors
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import { useValidate, useNext } from '@libHooks/useValidate';
import Button from '@libComponents/Button';
import useStepperContainer from '@libHooks/useStepperContainer';
import { userIdSelector } from 'auth/redux.selectors';

import { CadUploadProvider } from './useCadUpload';
import { useHandleNext } from './hooks';
import { FORM_NAME, INITIAL_VALUES } from './common';
import useSchema from './content/useSchema';
import {
    isLicensedSelector,
    isShapedSelector,
    getDesignerByLoggedinUser,
    isPrimarkSelector
} from './redux.selectors';
import { destroyForm, publish, saveDraft } from './redux.actions';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)'
    }
});

const mapState = state => {
    const userId = userIdSelector(state);
    const submitErrors = getFormSubmitErrors(FORM_NAME)(state);
    const asyncErrors = getFormAsyncErrors(FORM_NAME)(state);
    const syncErrors = getFormSyncErrors(FORM_NAME)(state);

    return {
        formValues: getFormValues(FORM_NAME)(state),
        formErrors: { ...submitErrors, ...asyncErrors, ...syncErrors },
        isLicensed: isLicensedSelector(state),
        isShaped: isShapedSelector(state),
        designer: getDesignerByLoggedinUser(state, userId),
        isPrimark: isPrimarkSelector(state)
    };
};

const CadUploadContainer = compose(
    connect(
        mapState,
        { destroyForm, publish, saveDraft, formInitialize }
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors,
        mode,
        isLicensed,
        isShaped,
        designer,
        isPrimark,
        destroyForm,
        publish,
        saveDraft,
        handleReduxFormSubmit,
        formInitialize
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const schema = useSchema(mode, isLicensed, isShaped, isPrimark);

        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);

        const handleNext = useHandleNext({
            dispatch,
            mode,
            activeStep,
            publish
        });
        const handleNextWithSubmit = () => {
            if (!canProceed) {
                handleReduxFormSubmit();
                return;
            }
            handleNext();
        };

        useEffect(() => {
            formInitialize(
                FORM_NAME,
                mode === 'new' ? { ...INITIAL_VALUES, designer } : {}
            );

            // Unmount
            return () => {
                destroyForm();
            };
        }, [mode]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        const reset = useCallback(() => dispatch({ type: 'reset' }), [
            dispatch
        ]);

        const initialize = useCallback(
            state => ({
                ...state,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                mode,
                reset,
                handleSave: () => saveDraft({ mode }),
                handlePublish: handleNext,
                handleReduxFormSubmit
            }),
            [areValid.saveDraft, areValid.publish, mode, dispatch, isLicensed]
        );

        return (
            <Paper className={c.container}>
                <CadUploadProvider initialize={initialize}>
                    {children}
                </CadUploadProvider>
                {!complete && (
                    <Button
                        className={c.button}
                        onClick={handleNextWithSubmit}
                        //disabled={!canProceed}
                    >
                        {proceedText}
                    </Button>
                )}
            </Paper>
        );
    }
);

export default CadUploadContainer;
