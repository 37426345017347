import { useState, useCallback, useRef, useMemo, useEffect } from 'react';

export const useScrollWhileColumnDragging = (draggedColumn, columnWidths) => {
    const scrollbarRef = useRef(null);
    const draggedColumnWidth = useMemo(() => {
        if (draggedColumn) {
            return columnWidths[draggedColumn].width;
        }
    }, [JSON.stringify(columnWidths), draggedColumn]);

    const handleDrag = useCallback(
        event => {
            if (draggedColumn && scrollbarRef.current) {
                const dragPositionX = event.clientX;
                const scrollThreshold = draggedColumnWidth;
                const speed = 100;

                const scrollbarInstance = scrollbarRef.current.scrollbar;

                // Calculate container edges
                const containerLeftEdge = scrollbarInstance._viewElement.getBoundingClientRect()
                    .left;
                const containerRightEdge = scrollbarInstance._viewElement.getBoundingClientRect()
                    .right;

                if (dragPositionX < containerLeftEdge + scrollThreshold) {
                    // Scroll left
                    scrollbarInstance._viewElement.style.scrollBehavior =
                        'smooth';
                    scrollbarInstance._viewElement.scrollTo(
                        scrollbarInstance._viewElement.scrollLeft - speed,
                        0
                    );
                }

                if (dragPositionX > containerRightEdge - scrollThreshold) {
                    // Scroll right
                    scrollbarInstance._viewElement.style.scrollBehavior =
                        'smooth';
                    scrollbarInstance._viewElement.scrollTo(
                        scrollbarInstance._viewElement.scrollLeft + speed,
                        0
                    );
                }
            }
        },
        [draggedColumn, draggedColumnWidth]
    );
    useEffect(() => {
        if (!draggedColumn && scrollbarRef.current) {
            scrollbarRef.current.scrollbar._viewElement.style.scrollBehavior =
                'auto'; // Remove smooth scroll behavior
        }
    }, [draggedColumn]);

    return {
        handleDrag,
        scrollbarRef
    };
};

export const useColumnResizing = columns => {
    const [resizingColumn, setResizingColumn] = useState({});
    const [columnWidths, setColumnWidths] = useState(() => {
        return columns.reduce(
            (acm, { defaultWidth, minWidth, name, maxWidth }) => ({
                ...acm,
                [name]: { width: defaultWidth, minWidth, maxWidth }
            }),
            {}
        );
    });

    const startResize = useCallback(columnPosX => {
        setResizingColumn(columnPosX);
    }, []);

    const resizeColumn = useCallback(
        currentMouseX => {
            const [[columnName, initialPositionX] = []] = Object.entries(
                resizingColumn
            );
            if (columnName) {
                const deltaX = Math.abs(initialPositionX - currentMouseX);
                const column = columnWidths[columnName];
                const newWidth =
                    initialPositionX > currentMouseX
                        ? column.width - deltaX
                        : column.width + deltaX;
                const minWidth = column.minWidth;
                const maxWidth = column.maxWidth;
                const calculatedWidth = Math.min(
                    Math.max(newWidth, minWidth),
                    maxWidth
                );

                setColumnWidths({
                    ...columnWidths,
                    [columnName]: {
                        width: calculatedWidth,
                        minWidth,
                        maxWidth
                    }
                });
            }
        },
        [resizingColumn, columnWidths]
    );

    const stopResize = useCallback(() => {
        setResizingColumn({});
    }, []);

    return {
        resizingColumn,
        startResize,
        resizeColumn,
        stopResize,
        columnWidths
    };
};
