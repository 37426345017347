import { createSelector } from 'reselect';

export const authSelector = state => state.auth;

export const userIdSelector = createSelector(
    authSelector,
    ({ userId }) => Number(userId)
);

export const userNameSelector = createSelector(
    authSelector,
    ({ name }) => name
);

export const userEmailSelector = createSelector(
    authSelector,
    ({ email }) => email
);

export const userLastLoginDateTimeSelector = createSelector(
    authSelector,
    ({ lastLogOn }) => {
        return lastLogOn ? lastLogOn : new Date('1/1/1971').getTime();
    }
);

export const OTPLoadingSelector = createSelector(
    authSelector,
    ({ OTPLoading }) => OTPLoading
);

// Returns a boolean
export const isAuthenticatedSelector = createSelector(
    authSelector,
    ({ userId }) => !!userId
);

export const isUserLoginExpiredSelector = createSelector(
    userLastLoginDateTimeSelector,
    lastLogOn => {
        const diffTime = Math.abs(new Date(lastLogOn) - new Date(Date.now()));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 7; // if a user has logged in more than 7 days, it is considered expired
    }
);

export const userLabelSelector = createSelector(
    authSelector,
    ({ userLevelId }) => {
        userLevelId = Number(userLevelId);
        return userLevelId === 1 ? 'Dev' : userLevelId === 2 ? 'QA' : null;
    }
);

export const userRoleIdSelector = createSelector(
    authSelector,
    ({ userRoleId }) => Number(userRoleId)
);

export const uidSelector = createSelector(
    authSelector,
    ({ uid }) => uid
);

export const accessTokenSelector = createSelector(
    authSelector,
    ({ accessToken }) => accessToken
);

export const refreshTokenSelector = createSelector(
    authSelector,
    ({ refreshToken }) => refreshToken
);
