import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import { withStyles, Grid, Divider } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Checkbox, Input, Select } from '@formComponents';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { SPECIAL_FINISHES } from 'sampling/redux.datasets';

import SelectFabricType from '../SelectFabircType';
import SelectComposition from '../SelectComposition';
import SelectGSM from '../SelectGSM';

const propTypes = {
    shouldLocked: PropTypes.bool.isRequired
};

const mapState = (state, { formName }) => ({
    ...formValueSelector(formName)(
        state,
        'hasMatchWashAndFinish',
        'hasMatchColor'
    )
});

const styles = ({ spacing, typography }) => ({
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0
    },
    container: {
        width: '64%',
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    checkBoxes: {
        marginTop: spacing.unit * 2
    },
    divider: {
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 1.5
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const RequiredFabricContainer = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, shouldLocked, hasMatchWashAndFinish, hasMatchColor }) => {
    return (
        <ContentContainer
            title='Required fabric details'
            AdornmentComponent={<Fragment />}
            styles={{ container: c.contentContainer }}
        >
            <div className={c.container}>
                <Fragment>
                    <Grid container className={c.checkBoxes}>
                        <GridItem md={4}>
                            <Checkbox
                                name='hasSameAsOriginal'
                                label='Same as original fabric'
                                styles={{
                                    label: c.checkboxLabel
                                }}
                                disabled={shouldLocked}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Checkbox
                                name='hasMatchWashAndFinish'
                                label='Match wash and finish'
                                styles={{
                                    label: c.checkboxLabel
                                }}
                                disabled={shouldLocked}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Checkbox
                                name='hasMatchColor'
                                label='Match color'
                                styles={{
                                    label: c.checkboxLabel
                                }}
                                disabled={shouldLocked}
                            />
                        </GridItem>
                    </Grid>
                    <Divider className={c.divider} />
                </Fragment>
                <Grid container>
                    <GridItem md={4}>
                        <SelectFabricType
                            name='requiredFabric.fabricType'
                            disabled={shouldLocked}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectComposition
                            name='requiredFabric.composition'
                            disabled={shouldLocked}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectGSM
                            name='requiredFabric.gsm'
                            disabled={shouldLocked}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Select
                            name='specialFinish'
                            label='Finish/Wash'
                            domain={SAMPLING_DOMAIN_NAME}
                            entity={SPECIAL_FINISHES}
                            disabled={shouldLocked || hasMatchWashAndFinish}
                            required={!hasMatchWashAndFinish}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='color'
                            label='Color Name'
                            placeholder='Enter Color Name'
                            disabled={shouldLocked || hasMatchColor}
                            required={!hasMatchColor}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='colorReference'
                            label='Color Reference'
                            placeholder='Enter Color Reference'
                            disabled={shouldLocked || hasMatchColor}
                            required={!hasMatchColor}
                        />
                    </GridItem>
                </Grid>
            </div>
        </ContentContainer>
    );
});

RequiredFabricContainer.propTypes = propTypes;

export default RequiredFabricContainer;
