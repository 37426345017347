import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { string } from 'prop-types';

import { Field, Title } from 'components/PDF';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    specReference: string,
    specDescription: string
};

const SpecDetails = ({ specReference, specDescription }) => {
    return (
        <View style={styles.container}>
            <Title text={'Spec Details'} />
            <Field
                label={'Spec Reference'}
                value={specReference}
                style={{ width: '38%' }}
            />
            <Field
                label={'Spec description'}
                value={specDescription}
                style={{ width: '58%' }}
            />
        </View>
    );
};

SpecDetails.propTypes = propTypes;

export default SpecDetails;
