import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import {
    withStyles,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@material-ui/core';

import Checkbox from '../../../Checkbox';

const propTypes = {
    handleOptionChange: PropTypes.func.isRequired,
    isChecked: PropTypes.func.isRequired,
    data: PropTypes.array
};

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        maxHeight: spacing.unit * 28
    },
    listRoot: {
        width: '100%'
    },
    scroll: {
        overflowY: 'scroll'
    },
    emptyData: {
        width: '100%',
        textAlign: 'center',
        margin: spacing.unit,
        color: palette.grey[400]
    }
});

const MultiSelectDialogCheckboxes = ({
    classes: c,
    handleOptionChange,
    handleAllOptionClick,
    isChecked,
    isAllChecked,
    data
}) => {
    const [shouldScroll, setScroll] = useState(false);
    const paperRef = useRef(null);
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef.current && paperRef.current) {
            const listNode = ReactDOM.findDOMNode(listRef.current);
            const paperNode = ReactDOM.findDOMNode(paperRef.current);

            const listHeight = listNode.offsetHeight;
            const paperHeight = paperNode.offsetHeight;

            setScroll(listHeight > paperHeight || data.length > 2);
        }
    }, [data]);

    return (
        <Paper
            ref={paperRef}
            elevation={0}
            className={classNames(c.root, shouldScroll && c.scroll)}
        >
            <List className={c.listRoot} ref={listRef}>
                {!!data.length ? (
                    <>
                        <ListItem dense button onClick={handleAllOptionClick}>
                            <Checkbox
                                checked={isAllChecked}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText primary='All' />
                        </ListItem>

                        {data.slice(0, 20).map(({ label, value }, index) => (
                            <ListItem
                                key={index}
                                role={undefined}
                                dense
                                button
                                onClick={e => handleOptionChange(value, e)}
                            >
                                <Checkbox
                                    checked={isChecked(value)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                <ListItemText primary={label} />
                            </ListItem>
                        ))}
                    </>
                ) : (
                    <Typography className={c.emptyData}>
                        No option available
                    </Typography>
                )}
            </List>
        </Paper>
    );
};

MultiSelectDialogCheckboxes.propTypes = propTypes;

export default withStyles(styles)(MultiSelectDialogCheckboxes);
