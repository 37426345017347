import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import { CURRENCIES, TRIM_SOURCES, TRIM_SUPPLIERS, TRIM_TYPES } from 'libs';

// Currencies
export const selectCurrenciesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[CURRENCIES]
)(() => 'placeholder');

// Trim Sources
export const selectTrimSourcesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[TRIM_SOURCES]
)(() => 'placeholder');

// Trim Suppliers
export const selectTrimSuppliersData = createCachedSelector(
    selectDatasets,
    datasets => datasets[TRIM_SUPPLIERS]
)(() => 'placeholder');

// Trim Types
export const selectTrimTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[TRIM_TYPES]
)(() => 'placeholder');
