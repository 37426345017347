import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Grid } from '@material-ui/core';

import SelectedItemCount from './SelectedItemCount';

const propTypes = {
    RenderDataGridExportOptions: PropTypes.func,
    selectedRows: PropTypes.array,
    totalCount: PropTypes.number,
    gridColumns: PropTypes.array
};

const styles = ({ spacing }) => ({
    gridReportContainer: {
        padding: spacing.unit * 2
    }
});

const ExportContainer = withStyles(styles)(
    ({ classes: c, RenderDataGridExportOptions, selectedRows, ...props }) => {
        return (
            <Grid container className={c.gridReportContainer}>
                <Grid item md={3}>
                    <SelectedItemCount selectedRows={selectedRows} />
                </Grid>
                <Grid item md={9}>
                    <RenderDataGridExportOptions
                        {...props}
                        selectedRows={selectedRows}
                    />
                </Grid>
            </Grid>
        );
    }
);

ExportContainer.propTypes = propTypes;

export default ExportContainer;
