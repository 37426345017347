import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { InputBase, Typography, withStyles } from '@material-ui/core';

const propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
    ]),
    validator: PropTypes.func,
    placeholder: PropTypes.string
};

const defaultProps = {
    validator: () => true,
    placeholder: 'Enter text'
};

const styles = ({ palette, spacing, typography }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: spacing.unit * 3.5,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        fontWeight: typography.fontWeightBold
    },
    baseInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'end',
        width: '50%',
        color: palette.secondary.light,
        padding: 0
    },
    input: {
        padding: 0,
        textAlign: 'end',
        marginRight: spacing.unit * -0.5,
        paddingRight: spacing.unit * 0.5,
        backgroundColor: palette.background.main,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark
    }
});

const InputRow = withStyles(styles)(
    ({ classes: c, label, onChange, value, validator, placeholder }) => {
        const [input, setInput] = useState(value);

        const handleChange = useCallback(event => {
            if (validator(event.target.value)) setInput(event.target.value);
        });

        const inputProps = useMemo(
            () => ({
                className: c.input,
                value: input,
                onChange: handleChange,
                onBlur: () => onChange(input),
                placeholder
            }),
            [input, c, handleChange, placeholder]
        );

        return (
            <div className={c.root}>
                <Typography className={c.label}>{label}</Typography>
                <InputBase className={c.baseInput} inputProps={inputProps} />
            </div>
        );
    }
);

InputRow.propTypes = propTypes;
InputRow.defaultProps = defaultProps;

export default InputRow;
