import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    root: {
        width: spacing.unit * 3,
        height: spacing.unit * 3
    }
});

const QuantityIcon = ({ classes: { root }, className, color = '#234c72' }) => (
    <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_116_1206)'>
            <path
                d='M21.1046 17.6323V7.44158L12.3538 2.34619L3.60303 7.44158V17.6323L12.3538 22.7277L21.1046 17.6323Z'
                fill='#558FC4'
            />
            <mask
                id='mask0_116_1206'
                maskUnits='userSpaceOnUse'
                x='3'
                y='2'
                width='19'
                height='21'
            >
                <path
                    d='M21.1046 17.6323V7.44158L12.3538 2.34619L3.60303 7.44158V17.6323L12.3538 22.7277L21.1046 17.6323Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_116_1206)'>
                <path
                    d='M3.82471 7.44152L12.317 12.6477L21.1047 7.47844L12.3539 2.38306L3.82471 7.44152Z'
                    fill='white'
                />
            </g>
            <path
                d='M21.1046 17.6323V7.44158L12.3538 2.34619L3.60303 7.44158V17.6323L12.3538 22.7277L21.1046 17.6323Z'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.60303 7.44153L12.3538 12.5369L21.1046 7.44153'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.354 12.5369V22.7276'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.15391 24.1308C7.60096 24.1308 9.58468 22.1471 9.58468 19.7001C9.58468 17.253 7.60096 15.2693 5.15391 15.2693C2.70687 15.2693 0.723145 17.253 0.723145 19.7001C0.723145 22.1471 2.70687 24.1308 5.15391 24.1308Z'
                fill='white'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.81253 19.7001H2.49561'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.15381 16.9783V22.3585'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.36621 4.67236L16.5631 9.98929'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.0864 12.5739V14.42L18.4833 12.4262V10.6169L15.0864 12.5739Z'
                fill='white'
                stroke='#234C72'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_116_1206'>
                <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.354004 0.5)'
                />
            </clipPath>
        </defs>
    </svg>
);

const _QuantityIcon = withStyles(styles)(QuantityIcon);

export { _QuantityIcon as default, _QuantityIcon as QuantityIcon };
