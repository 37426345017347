const componentColumnDefaults = {
    defaultWidth: 200,
    minWidth: 150,
    maxWidth: 400
};
const columnDefaults = {
    headerAlign: 'center',
    textAlign: 'center',
    showColumnMenuTool: true,
    visible: true,
    sortable: true,
    draggable: true,
    filterable: false,
    resizable: true,
    freezable: true,
    editable: false,
    defaultFreezable: false,
    isCellEditable: () => true
};

const boolColumnDefaults = {
    ...columnDefaults,
    name: 'bool__column',
    header: 'Bool Column',
    defaultWidth: 100,
    minWidth: 100,
    maxWidth: 100,
    type: 'boolean'
};

const imageColumnDefaults = {
    ...columnDefaults,
    name: 'image',
    header: 'Image',
    defaultWidth: 80,
    minWidth: 80,
    maxWidth: 80,
    type: 'image',
    sortable: false,
    resizable: false
};

const textColumnDefaults = {
    ...columnDefaults,
    name: 'text__column',
    header: 'Text Column',
    defaultWidth: 100,
    minWidth: 80,
    maxWidth: 600,
    type: 'text'
};

const numberColumnDefaults = {
    ...columnDefaults,
    name: 'number__column',
    header: 'Number Column',
    defaultWidth: 80,
    minWidth: 50,
    maxWidth: 400,
    type: 'number'
};

const selectColumnDefaults = {
    ...columnDefaults,
    name: 'select__column',
    header: 'Select Column',
    freezable: false,
    defaultWidth: 100,
    minWidth: 80,
    maxWidth: 400,
    type: 'select'
};

const dateColumnDefaults = {
    ...columnDefaults,
    name: 'date__column',
    header: 'Date Column',
    freezable: false,
    maxWidth: 400,
    defaultWidth: 80,
    minWidth: 50,
    type: 'date',
    dateFormat: 'dd-MMM-yyyy'
};

export {
    columnDefaults,
    boolColumnDefaults,
    textColumnDefaults,
    numberColumnDefaults,
    selectColumnDefaults,
    dateColumnDefaults,
    componentColumnDefaults,
    imageColumnDefaults
};
