import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PdfIcon = props => {
    const fill = props.fill || '#3D3D3D';
    return (
        <SvgIcon {...props} viewBox="0 0 448 512">
            <path style={{ fill: '#B4B4B4' }}
                  d="M123.8,0a32.09,32.09,0,0,0-32,32V480a32.09,32.09,0,0,0,32,32h320a32.09,32.09,0,0,0,32-32V128L347.8,0Z"
                  transform="translate(-27.8)"/>
            <path style={{ fill: '#848484' }} d="M379.8,128h96L347.8,0V96A32.09,32.09,0,0,0,379.8,128Z" transform="translate(-27.8)"/>
            <polygon style={{ fill: '#A8A8A8' }} points="448 224 352 128 448 128 448 224"/>
            <path style={{ fill }}
                  d="M411.8,416a16,16,0,0,1-16,16H43.8a16,16,0,0,1-16-16V256a16,16,0,0,1,16-16h352a16,16,0,0,1,16,16Z"
                  transform="translate(-27.8)"/>
            <path style={{ fill: '#FFFFFF' }}
                  d="M97.54,303.15a8.8,8.8,0,0,1,8.69-8.83h29.55c16.64,0,31.62,11.14,31.62,32.48,0,20.22-15,31.49-31.62,31.49H114.42v16.9c0,5.63-3.58,8.82-8.19,8.82a8.62,8.62,0,0,1-8.69-8.82Zm16.88,7.28V342.3h21.36c8.58,0,15.36-7.57,15.36-15.5,0-8.94-6.78-16.37-15.36-16.37Z"
                  transform="translate(-27.8)"/>
            <path style={{ fill: '#FFFFFF' }}
                  d="M192.46,384c-4.22,0-8.83-2.3-8.83-7.92V303.41c0-4.59,4.61-7.94,8.83-7.94h29.3c58.46,0,57.18,88.53,1.15,88.53Zm8.06-72.91V368.4h21.23c34.54,0,36.08-57.31,0-57.31Z"
                  transform="translate(-27.8)"/>
            <path style={{ fill: '#FFFFFF' }}
                  d="M299.67,312.11v20.34H332.3c4.61,0,9.22,4.61,9.22,9.07,0,4.22-4.61,7.68-9.22,7.68H299.67v26.86A7.58,7.58,0,0,1,292,384c-5.63,0-9.07-3.44-9.07-7.92V303.39c0-4.59,3.46-7.94,9.07-7.94h44.91c5.63,0,9,3.34,9,7.94a8.84,8.84,0,0,1-9,8.7H299.67Z"
                  transform="translate(-27.8)"/>
            <path style={{ fill: '#848484' }} d="M395.8,432H91.8v16h304a16,16,0,0,0,16-16V416A16,16,0,0,1,395.8,432Z"
                  transform="translate(-27.8)"/>
        </SvgIcon>
    );
};

export { PdfIcon as default, PdfIcon };
