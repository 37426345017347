import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { makeSideEffectCallback } from '@libs/makeContext';

// Bought
import { TRIM_SUPPLIERS, TRIM_TYPES } from 'trim/redux.datasets';

// Local
import { TrimDetailsProvider } from './useTrim';
import Adornment from './Adornment';
import Content from './Content';
import { trimDetailsLoadingSelector } from '../../../../redux.selectors';
import { DOMAIN_NAME } from '../../../common';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'trimDetails'
};

const datasets = [TRIM_SUPPLIERS, TRIM_TYPES];

const styles = ({ spacing }) => ({
    container: {
        borderTopWidth: 0
    },
    content: {
        padding: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    }
});

const mapState = state => {
    const trimDetailsLoading = trimDetailsLoadingSelector(state);
    const datasetsLoading = datasets.reduce(
        (acm, entity) =>
            acm || datasetLoadingSelector(state, DOMAIN_NAME, entity),
        false
    );

    return {
        loading: datasetsLoading || trimDetailsLoading
    };
};

const mapDispatch = {
    fetchDatasets
};

const TrimContainer = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withField(),
    withStyles(styles)
)(({ classes: c, input: { value, onChange }, fetchDatasets, loading }) => {
    useEffect(() => {
        fetchDatasets(datasets);
    }, []);

    const initialize = useCallback(
        state => ({
            ...state,
            trimDetails: value || []
        }),
        [value]
    );

    const middlewareProps = useMemo(
        () => ({
            reduxFormOnChange: makeSideEffectCallback(onChange)
        }),
        [onChange]
    );

    return (
        <TrimDetailsProvider {...{ initialize, middlewareProps }}>
            <ContentContainer
                title='Trim details'
                AdornmentComponent={
                    <Adornment loading={!(value && value.length) && loading} />
                }
                styles={{ container: c.container }}
            >
                <Content loading={loading} />
            </ContentContainer>
        </TrimDetailsProvider>
    );
});

TrimContainer.propTypes = propTypes;
TrimContainer.defaultProps = defaultProps;

export default TrimContainer;
