import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { makeSideEffectCallback } from '@libs/makeContext';

// Bought
import { BOUGHT_BYS, BOUGHT_LOCATIONS } from 'bought/redux.datasets';

// Local
import { BoughtDetailsProvider } from './useBought';
import Adornment from './Adornment';
import Content from './Content';
import { boughtDetailsLoadingSelector } from '../../redux.selectors';
import { DOMAIN_NAME } from '../../common';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'boughtDetails'
};

const datasets = [BOUGHT_BYS, BOUGHT_LOCATIONS];

const styles = ({ spacing }) => ({
    container: {
        borderTopWidth: 0
    },
    content: {
        padding: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    }
});

const mapState = state => {
    const boughtDetailsLoading = boughtDetailsLoadingSelector(state);
    const datasetsLoading = datasets.reduce(
        (acm, entity) =>
            acm || datasetLoadingSelector(state, DOMAIN_NAME, entity),
        false
    );

    return {
        loading: datasetsLoading || boughtDetailsLoading
    };
};

const mapDispatch = {
    fetchDatasets
};

const BoughtContainer = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withField(),
    withStyles(styles)
)(({ classes: c, input: { value, onChange }, fetchDatasets, loading }) => {
    useEffect(() => {
        fetchDatasets(datasets);
    }, []);

    const initialize = useCallback(
        state => ({
            ...state,
            boughtDetails: value || []
        }),
        [value]
    );

    const middlewareProps = useMemo(
        () => ({
            reduxFormOnChange: makeSideEffectCallback(onChange)
        }),
        [onChange]
    );

    return (
        <BoughtDetailsProvider {...{ initialize, middlewareProps }}>
            <ContentContainer
                title='Bought details'
                AdornmentComponent={
                    <Adornment loading={!(value && value.length) && loading} />
                }
                styles={{ container: c.container }}
            >
                <Content loading={loading} />
            </ContentContainer>
        </BoughtDetailsProvider>
    );
});

BoughtContainer.propTypes = propTypes;
BoughtContainer.defaultProps = defaultProps;

export { BoughtContainer as default, BoughtContainer };
