import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { useVersionsState } from './useVersions';
import Version from './Version';

const propTypes = {
    versions: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowIndex: PropTypes.number.isRequired
};

const styles = () => ({
    versionRow: {
        flexDirection: 'row',
        width: '100%'
    }
});

const VersionRow = withPDFStyles(styles)(
    ({ classes: c, versions, rowIndex }) => {
        const { versionsPerRow } = useVersionsState();

        return (
            <View style={c.versionRow}>
                {versions.map((version, index) => (
                    <Version
                        key={`version-${index}`}
                        version={version}
                        index={rowIndex * versionsPerRow + index}
                    />
                ))}
            </View>
        );
    }
);

VersionRow.propTypes = propTypes;

export default VersionRow;
