import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';

import FinanceCard from './FinanceCard';

const styles = ({ spacing, palette }) => ({
    container: {
        width: `calc(100% - ${spacing.unit * 4}px)`,
        marginBottom: spacing.unit * 2.5
    }
});

const propTypes = {
    wipQuantityReport: array.isRequired
};

const WorkInProgress = withStyles(styles)(
    ({ classes: c, wipQuantityReport }) => {
        return (
            <ContentContainer
                title='Work in Progress Quantity Report'
                styles={{ container: c.container }}
            >
                <FinanceCard data={wipQuantityReport} />
            </ContentContainer>
        );
    }
);

WorkInProgress.propTypes = propTypes;

export { WorkInProgress as default, WorkInProgress };
