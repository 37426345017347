const imgTypeAllowed = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/tiff',
	'image/bmp'
];
const imgTypes = ['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.bmp'];
const fileSize = { megabytes: 3, bytes: 3000000 };

const getImageInfo = (cadState, type = 'title') => {
	let returnValue = '';
	const cadImg = cadState.filter(function(item) {
		return item.imageSize === '350_350';
	});
	if (cadImg.length > 0) {
		if (type === 'url') {
			returnValue = cadImg[0].imageUrl;
		} else if (type === 'title') {
			returnValue = cadImg[0].imageName;
		}
	}
	return returnValue;
};

export { imgTypeAllowed, imgTypes, fileSize, getImageInfo };
