// Entities
import {
    COMPOSITIONS,
    DYE_TYPES,
    FABRIC_FORS,
    FABRIC_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from 'libs';

export default {
    [COMPOSITIONS]: [],
    [DYE_TYPES]: [],
    [FABRIC_FORS]: [],
    [FABRIC_TYPES]: [],
    [FINISHED_GSMS]: [],
    [SPECIAL_FINISHES]: []
};
