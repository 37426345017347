import React from 'react';

import Button from '@libComponents/Button';

import useDetailsBreakdownChart from '../useDetailsBreakdownChart';

const ViewToggle = props => {
    const [
        {
            chartSettings: { view }
        },
        dispatch
    ] = useDetailsBreakdownChart();

    const handleToggleView = () => {
        dispatch({ type: 'toggleView' });
    };

    return (
        <Button variant='outlined' onClick={handleToggleView} {...props}>
            {view === '2x1' ? 'Switch to 1x1' : 'Switch to 2x1'}
        </Button>
    );
};

export default ViewToggle;
