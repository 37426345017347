import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { withStyles, TextField } from '@material-ui/core';

import SearchAdornment from './SearchAdornment';

const propTypes = {
    makeSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

const defaultProps = {
    placeholder: 'Search..'
};

const styles = ({ palette, spacing, shape }) => ({
    root: {
        border: `1px solid`,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        minWidth: spacing.unit * 28,
        backgroundColor: '#fff',
        height: spacing.unit * 4.25,

        '&:hover': {
            borderColor: palette.grey[400],
            zIndex: 4000
        }
    },
    input: {
        height: '100%',
        fontSize: spacing.unit * 1.5
    }
});

const Search = ({
    classes: { input: inputClass, ...c },
    value,
    makeSearch,
    placeholder
}) => {
    const [query, setQuery] = useState(value);
    const [timer, setTimer] = useState(null);
    const prevValue = useRef(value);

    const handleSearch = useCallback(
        searchValue => {
            clearTimeout(timer);
            if (searchValue !== prevValue.current) {
                prevValue.current = searchValue;
                makeSearch(searchValue);
            }
        },
        [timer, value, makeSearch]
    );

    const handleChange = ({ target: { value = '' } }) => {
        setQuery(value);
        clearTimeout(timer);
        const localTimer = setTimeout(() => {
            handleSearch(value);
        }, 750);
        setTimer(localTimer);
    };

    const handleKeyPress = ({ key }) => key === 'Enter' && handleSearch(query);

    useEffect(() => {
        setQuery(value);
    }, [value]);

    return (
        <TextField
            classes={c}
            InputProps={{
                disableUnderline: true,
                className: inputClass,
                startAdornment: (
                    <SearchAdornment onClick={() => handleSearch(query)} />
                )
            }}
            value={query}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
        />
    );
};

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default withStyles(styles)(Search);
