import React from 'react';
import PropTypes from 'prop-types';

import { SelectModel } from '@formComponents';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'color',
    label: 'Color Name',
    placeholder: 'Select Color',
    disabled: false,
    required: false
};

const SelectColor = props => <SelectModel {...props} />;

SelectColor.propTypes = propTypes;
SelectColor.defaultProps = defaultProps;

export default SelectColor;
