import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from '../auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import { SET_REDIRECT, SET_MODULE_FREQUENTLY_USED } from './redux.actionTypes';
import datasets from './redux.datasets';

const initialState = {
    isRedirected: false,
    frequentlyUsedModules: {},
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_REDIRECT: {
            return {
                ...state,
                isRedirected: payload
            };
        }
        case SET_MODULE_FREQUENTLY_USED: {
            const pathname = payload;
            const currentCount = state.frequentlyUsedModules[pathname] || 0;

            return {
                ...state,
                frequentlyUsedModules: {
                    ...state.frequentlyUsedModules,
                    [pathname]: currentCount + 1
                }
            };
        }
        case LOGOUT:
        case RESET_DATASETS: {
            return {
                ...initialState,
                frequentlyUsedModules: state.frequentlyUsedModules
            };
        }
        default: {
            return state;
        }
    }
};
