import React, { useState } from 'react';
import clsx from 'classnames';
import { withStyles, Badge, CircularProgress } from '@material-ui/core';

import { deserializeFilename } from '@libs/filenames';
import { NO_IMAGE_URL } from 'common/common';
import useLoadedImage from '@libHooks/useLoadedImage';

import Slider from './Slider';

const styles = ({ spacing, palette }) => ({
    gridImageWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    gridImageContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        lineHeight: 0,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    },
    gridImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    gridBadgeRoot: {
        cursor: 'pointer',
        width: '100%',
        height: '100%'
    },
    gridPlaceholderImage: {
        cursor: 'auto'
    },
    gridImageBadge: {
        top: spacing.unit * 1,
        right: spacing.unit * 0.5,
        backgroundColor: palette.grey[700],
        color: palette.primary.contrastText,
        fontSize: spacing.unit * 1.25
    },
    imageLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

const Image = withStyles(styles)(({ classes, column, row }) => {
    const {
        gridImageWrapper,
        gridImageContainer,
        gridImage,
        gridPlaceholderImage,
        gridBadgeRoot,
        gridImageBadge,
        imageLoader
    } = classes;
    const images = row[column.name] || [];
    const imagesLength = images.length;
    const version1 = imagesLength > 0 && images[0].size100;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const loadedImage = useLoadedImage(version1);

    const handleImageClick = e => {
        e.stopPropagation();
        setIsDialogOpen(true);
    };

    const handleCloseDialog = e => {
        e.stopPropagation();
        setIsDialogOpen(false);
    };

    const wrapperProps =
        imagesLength > 1
            ? {
                  badgeContent: imagesLength,
                  classes: {
                      root: clsx(
                          gridBadgeRoot,
                          !version1 && gridPlaceholderImage
                      ),
                      badge: gridImageBadge
                  },
                  onClick: handleImageClick
              }
            : {
                  classes: {
                      root: clsx(
                          gridBadgeRoot,
                          !version1 && gridPlaceholderImage
                      )
                  },
                  onClick: handleImageClick
              };

    return (
        <div className={gridImageWrapper}>
            <Badge {...wrapperProps}>
                <div className={gridImageContainer}>
                    {version1 ? (
                        <>
                            {loadedImage ? (
                                <img
                                    src={loadedImage}
                                    alt={deserializeFilename(loadedImage)}
                                    className={gridImage}
                                    onDoubleClick={e => e.stopPropagation()}
                                />
                            ) : (
                                <div className={imageLoader}>
                                    <CircularProgress
                                        color='inherit'
                                        size={20}
                                        thickness={2}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <img
                            src={NO_IMAGE_URL}
                            alt='placeholder'
                            className={gridImage}
                            onClick={e => e.stopPropagation()}
                        />
                    )}
                    {isDialogOpen && (
                        <Slider
                            images={images}
                            handleClose={handleCloseDialog}
                        />
                    )}
                </div>
            </Badge>
        </div>
    );
});

export default Image;
