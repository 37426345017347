import {
    FETCH_REF_FOR_AMEND_REQUEST,
    FETCH_SAMPLE_INFO_REQUEST,
    FETCH_SAMPLE_REFERENCES,
    SET_SAMPLE_REFERENCES,
    FETCH_SAMPLE_DATA,
    SET_SAMPLE_DATA
} from 'store/actionTypes';

// Pristine
export const fetchSampleReferences = (payload = {}) => ({
    type: FETCH_SAMPLE_REFERENCES,
    payload
});

export const setSampleReferences = sampleReferences => ({
    type: SET_SAMPLE_REFERENCES,
    payload: { sampleReferences }
});

export const fetchSampleData = sampleReferenceId => ({
    type: FETCH_SAMPLE_DATA,
    payload: { sampleReferenceId }
});

export const setSampleData = payload => ({
    type: SET_SAMPLE_DATA,
    payload
});

// Check
export function fetchSampleRefAmendIncomplete(payload) {
    return { type: FETCH_REF_FOR_AMEND_REQUEST, payload };
}

export function fetchSampleInfo(payload) {
    return { type: FETCH_SAMPLE_INFO_REQUEST, payload };
}
