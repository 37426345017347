import React from 'react';
import { View } from '@react-pdf/renderer';
import { array } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Title } from 'components/PDF';

import Summary from './Summary';
import Fabrics from './Fabrics';

const styles = ({ palette, spacing }) => ({
    content: {
        width: '100%',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        borderRadius: 4
    },
    spaceBottom: {
        marginBottom: spacing.unit * 0.75
    }
});

const propTypes = {
    products: array.isRequired
};

const ProductsDetails = withPDFStyles(styles)(
    ({ products, classes: c, ...rest }) => {
        const productsLength = products.length;
        return (
            <View {...rest}>
                <Title text={'Product Details'} />
                {products.map(({ fabrics, ...restProducts }, idx) => (
                    <View
                        style={{
                            ...c.content,
                            ...(productsLength - 1 === idx ? {} : c.spaceBottom)
                        }}
                        key={`product-${idx}`}
                    >
                        <Summary {...restProducts} />
                        <Fabrics fabrics={fabrics} />
                    </View>
                ))}
            </View>
        );
    }
);

ProductsDetails.propTypes = propTypes;

export default ProductsDetails;
