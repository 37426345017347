import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Local
import {
    GridContainer,
    GridItem,
    MultilineInput,
    Select,
    NavigateButton,
    PaginatedSelectTest,
} from 'components';
import SamplingSpecReferenceSelect from './SamplingSpecReferenceSelect';
import SamplingFabricsContainer from './SamplingFabricsContainer';
import { formListener } from 'store/middlewares/formMiddleware';
import { validateRequired } from 'helpers';

// Actions
import { fetchDatasets, setSpecRefData, fetchSpecRefs } from 'actions';

// Selectors
import {
    selectSpecRefList,
    selectHemlines,
    selectNecklines,
    selectArmholes,
    selectLabelTypes,
    selectFabrics,
} from 'store/selectors';

// Entities
import {
    ARMHOLES,
    HEMLINES,
    LABEL_TYPES,
    NECKLINES,
    COMPOSITIONS,
    DYE_TYPES,
    FABRIC_FORS,
    FABRIC_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES,
    SPEC_REFERENCES,
} from 'libs';

const validate = (values, { fabrics }) => {
    const required = [
        'specReference',
        'necklineId',
        'specDescription',
        'labelTypeId',
    ];
    if (!fabrics.length) required.push('fabrics');

    return { ...validateRequired(values, required) };
};

class SamplingPrintSpec extends Component {
    componentDidMount() {
        const { fetchDatasets, fetchSpecRefs } = this.props;

        fetchDatasets([
            ARMHOLES,
            HEMLINES,
            LABEL_TYPES,
            NECKLINES,
            COMPOSITIONS,
            DYE_TYPES,
            FABRIC_FORS,
            FABRIC_TYPES,
            FINISHED_GSMS,
            SPECIAL_FINISHES,
            SPEC_REFERENCES,
        ]);

        fetchSpecRefs();
    }
    render() {
        const {
            onSubmit,
            invalid,
            specRefsData,
            hemlines,
            necklines,
            armholes,
            labelTypes,
            fabrics,
        } = this.props;

        return (
            <Fragment>
                <GridContainer>
                    <GridItem>
                        <Select
                            name='hemlineId'
                            label='Hemline'
                            data={hemlines}
                        />
                        <PaginatedSelectTest
                            name='specReference'
                            label='Spec Reference'
                            placeholder='Please select reference'
                            required
                            entity={SPEC_REFERENCES}
                        />
                        <Select
                            name='labelTypeId'
                            label='Label Type'
                            data={labelTypes}
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            name='necklineId'
                            label='Neckline'
                            required
                            data={necklines}
                        />
                        <MultilineInput
                            name='specDescription'
                            label='Spec Description'
                            placeholder='Please write spec description'
                            required
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            name='armholeId'
                            label='Armholes/Cuffs'
                            data={armholes}
                        />
                    </GridItem>
                </GridContainer>
                <SamplingFabricsContainer fabrics={fabrics} />
                <NavigateButton onClick={onSubmit} disabled={invalid}>
                    Next
                </NavigateButton>
            </Fragment>
        );
    }
}

const mapState = (state) => ({
    specRefsData: selectSpecRefList(state),
    hemlines: selectHemlines(state),
    necklines: selectNecklines(state),
    armholes: selectArmholes(state),
    labelTypes: selectLabelTypes(state),
    fabrics: selectFabrics(state),
});

const mapDispatch = {
    fetchDatasets,
    fetchSpecRefs,
};

const mapInputs = {
    specReference: ({ payload }) => setSpecRefData(payload),
};

const _SamplingPrintSpec = compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: 'SamplingPrintForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        validate,
    }),
    formListener(mapInputs)
)(SamplingPrintSpec);

export {
    _SamplingPrintSpec as default,
    _SamplingPrintSpec as SamplingPrintSpec,
};
