import makeContext from '@libs/makeContext';

const initialState = {
    chartSettings: {
        cellSettings: {},
        view: '2x1'
    },
    data: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'toggleChartsVisibility': {
            const { payload } = action;

            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    ...payload
                }
            };
        }

        case 'hideChart': {
            const { payload: chartName } = action;

            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    [chartName]: false
                }
            };
        }

        case 'toggleView': {
            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    view: state.chartSettings.view === '2x1' ? '1x1' : '2x1'
                }
            };
        }

        case 'toggleChartCellsVisibility': {
            const {
                payload: { chartName, cells }
            } = action;
            const valuesLength = Object.keys(cells).length;

            const newCellSettings = {
                ...state.chartSettings.cellSettings,
                [chartName]: cells
            };

            if (valuesLength === 0) {
                delete newCellSettings[chartName];
            }

            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    cellSettings: newCellSettings
                }
            };
        }

        default: {
            return state;
        }
    }
};

const middleware = (state, action, { storeChartViewSettings }) => {
    const { chartSettings } = reducer(state, action);

    storeChartViewSettings(chartSettings);

    return action;
};

const [
    DetailsBreakdownProvider,
    useDetailsBreakdownChartState,
    useDetailsBreakdownChartDispatch,
    useDetailsBreakdownChart
] = makeContext(reducer, initialState, {
    name: 'breakdownChart',
    middleware
});
export {
    useDetailsBreakdownChart as default,
    DetailsBreakdownProvider,
    useDetailsBreakdownChartState,
    useDetailsBreakdownChartDispatch
};
