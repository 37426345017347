import { put, call } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import { setSnack } from 'utilities/redux.actions';
import { setPreviewData, setPreviewLoading } from '../../redux.actions';
//Grid data fetch worker
export default function*(action) {
    const {
        meta: { entity, endpoint },
        payload
    } = action;

    yield put(setPreviewLoading({ entity, loading: true }));

    try {
        const { data: { data: dataset } = {} } = yield call(apiRequest, {
            url: endpoint,
            params: { ...payload }
        });
        yield put(
            setPreviewData({
                entity,
                dataset
            })
        );
        yield put(setPreviewLoading({ entity, loading: false }));
    } catch {
        yield put(setPreviewLoading({ entity, loading: false }));
        yield put(
            setSnack({
                message: 'Failed to retrieve',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
        console.log(`Error while fetching ${entity} resources`);
    }
}
