import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';

import { INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID } from '../../../FabricDevelopment/common';
import { getFabricDevelopmentReferenceData } from '../../../redux.actions';

export default function*({ mode, fabricReference }) {
    if (mode !== 'incomplete') {
        yield put(setRedirect(true));
    }
    yield put(
        setSnack({
            message: `Saved draft: ${fabricReference}`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID));
    yield put(getFabricDevelopmentReferenceData(fabricReference));
}
