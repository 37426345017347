import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core/styles';

// Local
import { ReportsToolbarIcon } from 'views/Reports/components';
import { PdfIcon, XlsxIcon } from 'assets/Reports';
import styles from './styles';
import { API_BASE_URL } from "store/config";

/*
 * Domain: Reports
 * Page: Toolbar
 * Component: ViewMode
 * Type: --
 * ReportsToolbarMode
 */
const ReportsToolbarMode = ({
    classes: { root, icon },
    className,
    pdfDownload,
    xlsxDownload,
}) => {
    return (
        <div className={classNames(root, className)}>
            <ReportsToolbarIcon
                title='Download Pdf File'
                Icon={PdfIcon}
                className={icon}
                onClick={() =>
                    pdfDownload && window.location.assign(pdfDownload)
                }
            />
            <ReportsToolbarIcon
                title='Download Xlsx File'
                Icon={XlsxIcon}
                className={icon}
                onClick={() =>
                    xlsxDownload && window.location.assign(xlsxDownload)
                }
                disabled
            />
        </div>
    );
};
const composeDownload = reportId =>
    `${API_BASE_URL}api/Download/GetReportDownload?moduleName=Sample&reportid=${reportId}`;

const mapStateToProps = ({ reports: { pdfReportId, xlsxReportId } }) => ({
    pdfDownload: composeDownload(pdfReportId),
    xlsxDownload: composeDownload(xlsxReportId),
});

const _ReportsToolbarMode = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(ReportsToolbarMode);

export {
    _ReportsToolbarMode as default,
    _ReportsToolbarMode as ReportsToolbarMode,
};
