import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'setup/common';
import { PO_SIZE_SET } from 'setup/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const POSizeSet = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Size Set', canSort: true, sortKey: 'sizeSet' },
            { label: 'Sizes', canSort: false, sortKey: 'size' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'PO SIZE SET SETUP',
        domain: DOMAIN_NAME,
        entity: PO_SIZE_SET,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { POSizeSet as default, POSizeSet };
