const extensions = ['jpg', 'jpeg', 'png', 'gif'];
const imageExtensionFromFileName = filename => {
    const fileArray = filename.split('.');
    const extension = fileArray[fileArray.length - 1];
    if (!extensions.includes(extension)) {
        return { isImage: false, extension };
    }
    return { isImage: true, extension };
};

const imageExtensionFromType = type => {
    const [_, extension] = type.split('/');
    if (extensions.includes(extension)) return true;
    return false;
};

export { imageExtensionFromFileName as default, imageExtensionFromType };
