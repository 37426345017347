import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';

// Local
import { PaginatedSelect } from 'components';
import { fetchCopySampleRef } from 'store/actions';

const SamplingCopyReferenceSelect = ({
    input: { value, onChange },
    ...restProps
}) => {
    console.log('copy', restProps)
    return (
        <PaginatedSelect
            type='copySampleRef'
            success={!!value}
            value={value}
            action={onChange}
            {...restProps}
        />
    );
};

const normalize = ({ value }) => {
    if (typeof value === 'object') {
        const {
            value: {
                fullData: { sampleReference }
            }
        } = value;
        return sampleReference;
    }
    return value;
};

const mapDispatch = dispatch => ({
    loadMoreItems: (searchKey = '', pageNo = 1) =>
        dispatch(fetchCopySampleRef({ searchKey, pageNo }))
});

const _SamplingCopyReferenceSelect = compose(
    connect(
        undefined,
        mapDispatch
    )
)(SamplingCopyReferenceSelect);

const __SamplingCopyReferenceSelect = props => (
    <Field
        component={_SamplingCopyReferenceSelect}
        {...props}
        normalize={normalize}
    />
);

export {
    __SamplingCopyReferenceSelect as default,
    __SamplingCopyReferenceSelect as SamplingCopyReferenceSelect
};
