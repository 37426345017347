import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';

function _Toolbar({ ...props }) {
    const { children, disableGutters, ...restProps } = props;

    return (
        <Toolbar disableGutters={disableGutters} {...restProps}>
            {children}
        </Toolbar>
    );
}

_Toolbar.defaultProps = {
    disableGutters: false
};

_Toolbar.propTypes = {
    disableGutters: PropTypes.bool
};

export { _Toolbar as default, _Toolbar as Toolbar };
