import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import {
    withStyles,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../../../PaginatedDialogContext';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogTableRow
 */
const PaginatedDialogTableRow = ({
    classes: c,
    shaded = false,
    data,
    dialogDispatch,
}) => {
    const cells = Object.values(data);

    const handleClick = () =>
        dialogDispatch({ type: 'select-item', payload: cells });

    return (
        <TableRow
            className={classNames(c.root, shaded && c.shaded)}
            onClick={handleClick}
        >
            {cells.map((cell, idx) => {
                return (
                    <TableCell
                        key={`paginatedTableCell${idx}`}
                        className={c.cell}
                    >
                        {cell}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

const _PaginatedDialogTableRow = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogTableRow);

export {
    _PaginatedDialogTableRow as default,
    _PaginatedDialogTableRow as PaginatedDialogTableRow,
};
