const generatePieChartImage = ({
    data,
    colors,
    width = 200,
    height = 200,
    innerRadius = 30,
    minLabelSize = 10,
    fontSize = 8
}) => {
    // Scale factor for higher resolution
    const scaleFactor = 4;
    const canvas = document.createElement('canvas');
    canvas.width = width * scaleFactor;
    canvas.height = height * scaleFactor;
    const ctx = canvas.getContext('2d');

    // Scale the drawing context
    ctx.scale(scaleFactor, scaleFactor);

    const total = data.reduce((sum, item) => sum + item.value, 0);
    let cumulativePercent = 0;

    data.forEach(item => {
        const value = item.value / total;
        const startAngle = cumulativePercent * 2 * Math.PI;
        cumulativePercent += value;
        const endAngle = cumulativePercent * 2 * Math.PI;

        // Draw the pie slice with an inner radius
        ctx.beginPath();
        // Outer arc
        ctx.arc(width / 2, height / 2, width / 2, startAngle, endAngle);
        // Line to inner radius arc
        ctx.arc(width / 2, height / 2, innerRadius, endAngle, startAngle, true);
        ctx.closePath();
        ctx.fillStyle = colors[item.name]; // Set color
        ctx.fill(); // Fill the pie slice

        // Calculate the position for the label
        const midAngle = startAngle + (endAngle - startAngle) / 2;
        const sliceSize = (width / 2) * (endAngle - startAngle);
        const labelX =
            width / 2 + ((width / 2 + innerRadius) / 2) * Math.cos(midAngle);
        const labelY =
            height / 2 + ((height / 2 + innerRadius) / 2) * Math.sin(midAngle);

        // Determine if label should be inside or outside
        if (sliceSize >= minLabelSize) {
            // Draw the label inside the slice
            ctx.fillStyle = '#000'; // Set text color
            ctx.font = fontSize + 'px Arial'; // Set text font
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(item.value, labelX, labelY);
        }
    });

    // Convert the canvas to a base64 image
    return canvas.toDataURL('image/png');
};

export default generatePieChartImage;
