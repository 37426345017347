import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'setup/common';
import { TRIM_LABEL } from 'setup/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const TrimLabel = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Trim Label Type', canSort: true, sortKey: 'trimType' },
            { label: 'Trim Label Ref', canSort: true, sortKey: 'trimRef' },
            {
                label: 'Trim Label Desc',
                canSort: true,
                sortKey: 'trimDescription'
            },
            {
                label: 'Trim Label Colour',
                canSort: true,
                sortKey: 'trimColour'
            },
            {
                label: 'Trim Label Image',
                canSort: false,
                sortKey: 'image'
            },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'TRIM LABEL SETUP',
        domain: DOMAIN_NAME,
        entity: TRIM_LABEL,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { TrimLabel as default, TrimLabel };
