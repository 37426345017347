import { useMemo } from 'react';

import { useUploadCadLabel } from '../hooks';
import { LABELS } from '../common';

const useSchema = (mode, isLicensed, isShaped, isPrimark) => {
    const uploadCadLabel = useUploadCadLabel(mode);

    const licenseSchema = useMemo(
        () => (isLicensed ? { licensor: true, property: true } : {}),
        [isLicensed]
    );

    const shapeSchema = useMemo(
        () => (isShaped ? {} : { shapeDesigner: true }),
        [isShaped]
    );

    const commonSchema = useMemo(
        () => ({
            type: true,
            designer: true,
            retailer: true,
            gender: true,
            age: true,
            category: true,
            department: true,
            style: true,
            designDepartment: isPrimark
        }),
        [isPrimark]
    );

    const basicInformationSchema = useMemo(
        () =>
            mode === 'new'
                ? {
                      ...commonSchema,
                      ...licenseSchema
                  }
                : {},
        [mode, licenseSchema, commonSchema]
    );

    const cadUploadSchema = useMemo(
        () => ({
            cadReference: true,
            description: true,
            artworkSource: true,
            ...commonSchema,
            developmentType: true,
            versions: (versions = [], fieldError) =>
                !!versions.length && !fieldError,
            ...licenseSchema,
            ...shapeSchema
        }),
        [licenseSchema, isShaped, commonSchema]
    );

    const publishSchema = useMemo(
        () =>
            mode === 'new'
                ? { ...basicInformationSchema, ...cadUploadSchema }
                : cadUploadSchema,
        [mode, cadUploadSchema, basicInformationSchema]
    );

    return useMemo(
        () => ({
            ...(mode === 'new'
                ? { [LABELS.basicInformation]: basicInformationSchema }
                : {}),
            [uploadCadLabel]: cadUploadSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: { ...commonSchema, ...licenseSchema }
        }),
        [
            mode,
            basicInformationSchema,
            uploadCadLabel,
            cadUploadSchema,
            publishSchema,
            commonSchema,
            licenseSchema
        ]
    );
};

export default useSchema;
