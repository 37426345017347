import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { FormControl, LinearProgress } from '@material-ui/core';

import { composeClasses } from 'helpers';

import LabelBase from '../../Label';
import FormHelperText from '../../FormHelperText';
import SelectDropdownIcon from './SelectDropdownIcon';
import SelectedOptions from './SelectedOptions';
import SelectPlaceholder from './SelectPlaceholder';
import MultiSelectDialog from './MultiSelectDialog';

class MultiSelect extends React.Component {
    // Set prop types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        placeholder: PropTypes.string
    };

    // Set default props
    static defaultProps = {
        styles: {}
    };

    state = {
        data: this.props.data || [],
        selectedData: [],
        dialogAnchor: null,
        isDisabled: this.props.disabled || this.props.loading,
        isAllChecked: false
    };

    updateSelectedData = (newSelectedData, shouldOnBlurTrigger = true) => {
        const isAllChecked = newSelectedData.length === this.state.data.length;

        this.setState({ selectedData: newSelectedData, isAllChecked }, () => {
            const {
                input: { onChange, onBlur }
            } = this.props;
            onChange(newSelectedData.map(({ value }) => value));
            shouldOnBlurTrigger && onBlur();
        });
    };

    componentDidMount() {
        const {
            defaultValues = [],
            input: { value },
            data
        } = this.props;

        let newSelectedData = [];
        if (value.length === 0) {
            if (defaultValues.length === 0) {
                // nothing need to do
            } else if (defaultValues.includes('*')) {
                newSelectedData = data;
            } else {
                // Include only specific values from data based on defaultValues
                newSelectedData = data.filter(datum =>
                    defaultValues.includes(datum.value)
                );
            }
        } else {
            newSelectedData = data.filter(datum => value.includes(datum.value));
        }
        this.updateSelectedData(newSelectedData, false);
    }

    componentDidUpdate(prevProps) {
        const {
            props: { input },
            state: { data }
        } = this;
        if (
            JSON.stringify(prevProps.input.value) !==
            JSON.stringify(input.value)
        ) {
            this.setState({
                selectedData: data.filter(datum =>
                    input.value.includes(datum.value)
                )
            });
        }
    }

    // Controls the dropdown visibility
    showDialog = event => {
        this.setState({ dialogAnchor: event.currentTarget });
    };

    hideDialog = event => {
        event.stopPropagation();
        this.setState({ dialogAnchor: null });
        this.props.input.onBlur();
    };

    // Handles checking or unchecking of items
    handleOptionChange = (value, e) => {
        e.persist();
        const { selectedData, data } = this.state;

        let newSelectedData;
        if (!this.isChecked(value)) {
            // New item is added to the chosen items list
            const selectedItem = data.find(opt => opt.value === value);
            newSelectedData = [...selectedData, selectedItem];
        } else {
            // Unchecked item is removed from the chosen items list
            newSelectedData = selectedData.filter(opt => opt.value !== value);
        }
        this.updateSelectedData(newSelectedData);
    };

    // Remove item with X button
    handleOptionRemoveClick = (value, e) => {
        e.stopPropagation();
        const { selectedData } = this.state;
        const newSelectedData = selectedData.filter(
            item => item.value !== value
        );
        this.updateSelectedData(newSelectedData);
    };

    // All Select/Deselect
    handleAllOptionClick = () => {
        const { data, isAllChecked } = this.state;

        let newSelectedData;
        if (!isAllChecked) {
            // Select all options
            newSelectedData = [...data];
        } else {
            // Deselect all options
            newSelectedData = [];
        }

        this.updateSelectedData(newSelectedData);
    };

    // Check item is already in the chosen items list, if so then make that option checked
    isChecked = value => {
        return this.state.selectedData.find(item => item.value === value)
            ? true
            : false;
    };

    render() {
        const {
            state,
            props,
            showDialog,
            hideDialog,
            handleOptionRemoveClick,
            handleOptionChange,
            handleAllOptionClick,
            isChecked
        } = this;
        const {
            dialogAnchor,
            selectedData,
            data,
            isDisabled,
            selectStyle,
            isAllChecked
        } = state;
        const {
            classes,
            styles,
            input,
            label,
            required,
            meta: { form, touched, error },
            className,
            placeholder: placeholderValue,
            loading,
            ...restProps
        } = props;
        const { name } = input;

        const id = `${form}-${name}`;

        const c = composeClasses({
            classes: { ...classes, select: selectStyle },
            styles
        });

        const success = !isDisabled && !!selectedData.length;
        const hasError = touched && error;

        return (
            <FormControl className={c.container} id={id}>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                    />
                )}
                <FormControl
                    className={classNames(
                        c.control,
                        className,
                        success && c.success,
                        isDisabled && c.disabled,
                        hasError && c.error
                    )}
                    onClick={showDialog}
                >
                    <input
                        type='hidden'
                        value={selectedData.map(opt => opt.value)}
                        name={input.name}
                    />
                    {!!selectedData.length && (
                        <SelectedOptions
                            data={selectedData}
                            handleOptionRemoveClick={handleOptionRemoveClick}
                        />
                    )}
                    {placeholderValue && !success && !isDisabled && (
                        <SelectPlaceholder
                            styles={Object.splice(c, ['placeholder'])}
                            placeholder={placeholderValue}
                        />
                    )}
                    <SelectDropdownIcon
                        disabled={isDisabled}
                        styles={Object.splice(c, ['dropdownRoot'])}
                    />
                    {loading && (
                        <LinearProgress
                            classes={{
                                root: c.progress,
                                barColorPrimary: c.progressPrimary
                            }}
                        />
                    )}
                    {dialogAnchor && !isDisabled && (
                        <MultiSelectDialog
                            hideDialog={hideDialog}
                            anchor={dialogAnchor}
                            handleOptionChange={handleOptionChange}
                            handleAllOptionClick={handleAllOptionClick}
                            isChecked={isChecked}
                            isAllChecked={isAllChecked}
                            data={data}
                            {...restProps}
                        />
                    )}
                </FormControl>
                {hasError && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

export { MultiSelect as default, MultiSelect };
