import {
    DOWNLOAD_CAD_AS_EXCEL,
    SET_DOWNLOAD_CAD_AS_EXCEL_LOADING
} from './redux.actionTypes';

export const downloadCadAsExcel = payload => ({
    type: DOWNLOAD_CAD_AS_EXCEL,
    payload
});

export const setDownloadCadAsExcelLoading = loading => ({
    type: SET_DOWNLOAD_CAD_AS_EXCEL_LOADING,
    payload: loading
});
