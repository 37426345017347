import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles, IconButton } from '@material-ui/core';
import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from '@material-ui/icons';

const propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

const styles = ({ spacing, palette }) => ({
    root: {
        flexShrink: 0,
        color: palette.text.secondary,
        marginLeft: spacing.unit * 2.5
    }
});

const TablePaginationActions = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        onChangePage,
        page,
        count,
        rowsPerPage,
        theme: { direction }
    }) => {
        // Calculate the maximum number of pages
        const totalPages = Math.ceil(count / rowsPerPage) - 1;

        const handleFirstPageButtonClick = useCallback(e => {
            onChangePage(e, 0);
        }, []);

        const handleBackButtonClick = useCallback(
            e => {
                onChangePage(e, page - 1);
            },
            [page]
        );

        const handleNextButtonClick = useCallback(
            e => {
                onChangePage(e, page + 1);
            },
            [page]
        );

        const handleLastPageButtonClick = useCallback(
            e => {
                onChangePage(e, Math.max(0, totalPages));
            },
            [totalPages]
        );

        return (
            <div className={c.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label='First Page'
                >
                    {direction === 'rtl' ? <LastPage /> : <FirstPage />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label='Previous Page'
                >
                    {direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= totalPages}
                    aria-label='Next Page'
                >
                    {direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= totalPages}
                    aria-label='Last Page'
                >
                    {direction === 'rtl' ? <FirstPage /> : <LastPage />}
                </IconButton>
            </div>
        );
    }
);

TablePaginationActions.propTypes = propTypes;

export { TablePaginationActions as default, TablePaginationActions };
