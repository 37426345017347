import { put, takeLatest, call, select, fork } from 'redux-saga/effects';
import { formValueSelector, change, destroy, initialize } from 'redux-form';

import { setLoading, setSnack } from 'utilities/redux.actions';
import {
    denormalizeDate,
    normalizeDate
} from '@baseComponents/Date/normalizeDate';
import { entitySelector } from '@libs/datasets';
import { MAIL_FORM_NAME } from 'common/common';
import apiRequest from '@libs/apiRequest';
import { redirectToRoute } from 'navigation/redux.actions';
import { userEmailSelector } from 'auth/redux.selectors';

import {
    DISPATCH_REFERENCE_GENERATE,
    GET_SAMPLE_DISPATCH_DATA,
    SET_ACTUAL_SEND_DATE_CHILDREN,
    SET_DISPATCH_DETAILS_TYPE_CHILDREN,
    SET_DISPATCH_DETAILS_ORDER_CHILDREN,
    SET_DISPATCH_DETAILS_SAMPLE_REF_CHILDREN,
    SET_DISPATCH_DETAILS_COLOUR_CHILDREN,
    DISPATCH_PUBLISH,
    SET_DISPATCH_EMAIL_RECEIVERS,
    SET_DISPATCH_EMAIL_BODY,
    ROUTE_TO_DISPATCH,
    DISPATCH_REPORT_MAIL_SEND
} from '../../redux.actionTypes';
import { setDispatchReferenceLoading } from '../../redux.actions';
import {
    FORM_NAME,
    DISPATCH_DETAILS_FORM_NAME,
    INITIAL_VALUES,
    NEW_SAMPLE_DISPATCH_ROUTE_ID,
    INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID,
    AMEND_SAMPLE_DISPATCH_ROUTE_ID
} from '../../SampleDispatch/common';
import { dispatchTypeCategorySelector } from 'sampling/redux.selectors';
import {
    DISPATCH_SAMPLES_BY_ORDER_NO,
    DISPATCH_EMAIL_GROUP,
    DISPATCH_EMAIL_BODY
} from 'sampling/redux.datasets';
import { DOMAIN_NAME } from '../../SampleDispatch/common';
import generateDispatchReferenceWorker from './generateReferenceWorker';
import dispatchPublishWorker from './publishWorker';
import getReferenceDataWorker from './getReferenceDataWorker';
import dispatchReportUploadAndMailSendWorker from './dispatchReportUploadAndMailSendWorker';
import samplingDispatchViewSagas from './sampleDispatchViewSagas';

// Workers

const setDispatchEmailReceiversWorker = function*({ payload: groupId }) {
    if (groupId) {
        const initiator = yield select(userEmailSelector);
        const groups = yield select(
            entitySelector,
            DOMAIN_NAME,
            DISPATCH_EMAIL_GROUP
        );
        const { emailGroup } = groups.find(({ id }) => id === Number(groupId));
        const receivers = emailGroup.split(',').join(', ');
        yield put(change(MAIL_FORM_NAME, 'receivers', receivers));
        if (!receivers.includes(initiator)) {
            yield put(change(MAIL_FORM_NAME, 'cc', initiator));
        }
    } else {
        yield put(change(MAIL_FORM_NAME, 'receivers', ''));
        yield put(change(MAIL_FORM_NAME, 'cc', ''));
    }
};

const setDispatchEmailBodyWorker = function*({ payload: templateId }) {
    if (templateId) {
        const templates = yield select(
            entitySelector,
            DOMAIN_NAME,
            DISPATCH_EMAIL_BODY
        );
        const { emailBody } = templates.find(
            ({ id }) => id === Number(templateId)
        );
        yield new Promise(res => setTimeout(() => res(), 0));
        yield put(change(MAIL_FORM_NAME, 'body', `${emailBody}`));
    } else {
        yield put(change(MAIL_FORM_NAME, 'body', ''));
    }
};

function* generateReferenceWorker() {
    yield put(setDispatchReferenceLoading(true));

    yield call(generateDispatchReferenceWorker);

    yield put(setDispatchReferenceLoading(false));
}

const getSampleDispatchDataWorker = function*({ payload: dispatchReference }) {
    if (dispatchReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, dispatchReference);

        yield put(setLoading(false));
    }
};

const setActualSendDateChildrenWorker = function*({ payload: actualSendDate }) {
    yield put(change(FORM_NAME, 'eta', ''));
    if (!actualSendDate) return;

    const { originCountry, destinationCountry } = yield select(
        formValueSelector(FORM_NAME),
        'originCountry',
        'destinationCountry'
    );

    const bufferDaysCount = getBufferDaysCount(
        originCountry,
        destinationCountry,
        actualSendDate
    );

    const eta = calculateETA(actualSendDate, bufferDaysCount);
    yield put(change(FORM_NAME, 'eta', eta));
};

const getBufferDaysCount = (
    originCountry,
    destinationCountry,
    actualSendDate
) => {
    const dayOfWeek = new Date(denormalizeDate(actualSendDate)).getDay();
    const BDToEU = {
        0: 4, // Sunday
        1: 4, // Monday
        2: 4, // Tuesday
        3: 5, // Wednesday
        4: 4, // Thursday
        5: 4, // Friday
        6: 4 // Saturday
    };

    const EUToEU = {
        0: 1, // Sunday
        1: 1, // Monday
        2: 1, // Tuesday
        3: 1, // Wednesday
        4: 1, // Thursday
        5: 3, // Friday
        6: 2 // Saturday
    };

    return [1, 4].includes(originCountry) && [1, 4].includes(destinationCountry)
        ? EUToEU[dayOfWeek]
        : BDToEU[dayOfWeek];
};

const calculateETA = (actualSendDate, bufferDaysCount) => {
    const date = new Date(denormalizeDate(actualSendDate));
    date.setDate(date.getDate() + bufferDaysCount);
    return normalizeDate(date);
};

const setDispatchDetailsTypeChildren = function*() {
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'orderNo', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'sampleRef', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'colour', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'PONumbers', []));
};

const setDispatchDetailsOrderChildren = function*() {
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'sampleRef', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'colour', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'PONumbers', []));
};

const setDispatchDetailsSampleRefChildren = function*({ payload }) {
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'colour', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'PONumbers', []));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'sampleImage', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'version', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'description', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'gender', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'department', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'fabricType', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'fabricComposition', ''));
    yield put(change(DISPATCH_DETAILS_FORM_NAME, 'fabricGsm', ''));
    if (payload) {
        const dispatchType = yield select(
            formValueSelector(DISPATCH_DETAILS_FORM_NAME),
            'dispatchType'
        );
        const dispatchCategory = yield select(
            dispatchTypeCategorySelector,
            dispatchType
        );
        if (dispatchCategory === 'order') {
            const sampleRefs = yield select(
                entitySelector,
                DOMAIN_NAME,
                DISPATCH_SAMPLES_BY_ORDER_NO
            );
            const {
                image,
                version,
                description,
                gender,
                department,
                fabricType,
                fabricComposition,
                fabricGsm
            } = sampleRefs.find(({ sampleRef }) => sampleRef === payload);
            yield put(change(DISPATCH_DETAILS_FORM_NAME, 'sampleImage', image));
            yield put(change(DISPATCH_DETAILS_FORM_NAME, 'version', version));
            yield put(
                change(DISPATCH_DETAILS_FORM_NAME, 'description', description)
            );
            yield put(change(DISPATCH_DETAILS_FORM_NAME, 'gender', gender));
            yield put(
                change(DISPATCH_DETAILS_FORM_NAME, 'department', department)
            );
            yield put(
                change(DISPATCH_DETAILS_FORM_NAME, 'fabricType', fabricType)
            );
            yield put(
                change(
                    DISPATCH_DETAILS_FORM_NAME,
                    'fabricComposition',
                    fabricComposition
                )
            );
            yield put(
                change(DISPATCH_DETAILS_FORM_NAME, 'fabricGsm', fabricGsm)
            );
        } else if (dispatchCategory === 'sample') {
            try {
                const {
                    data: {
                        data: {
                            version,
                            description,
                            gender,
                            department,
                            fabricType,
                            fabricComposition,
                            fabricGsm,
                            image
                        } = {}
                    } = {}
                } = yield call(apiRequest, {
                    url: 'SampleDispatches/GetDispatchSampleDetailsBySample',
                    params: { sampleRef: payload }
                });
                yield put(
                    change(DISPATCH_DETAILS_FORM_NAME, 'sampleImage', image)
                );
                yield put(
                    change(DISPATCH_DETAILS_FORM_NAME, 'version', version)
                );
                yield put(
                    change(
                        DISPATCH_DETAILS_FORM_NAME,
                        'description',
                        description
                    )
                );
                yield put(change(DISPATCH_DETAILS_FORM_NAME, 'gender', gender));
                yield put(
                    change(DISPATCH_DETAILS_FORM_NAME, 'department', department)
                );
                yield put(
                    change(DISPATCH_DETAILS_FORM_NAME, 'fabricType', fabricType)
                );
                yield put(
                    change(
                        DISPATCH_DETAILS_FORM_NAME,
                        'fabricComposition',
                        fabricComposition
                    )
                );
                yield put(
                    change(DISPATCH_DETAILS_FORM_NAME, 'fabricGsm', fabricGsm)
                );
            } catch (error) {
                console.log(error);
                yield put(
                    setSnack({
                        message: 'Failed to fetch sample data.',
                        type: 'error',
                        duration: 6000
                    })
                );
            }
        }
    }
};

const setDispatchDetailsColourChildren = function*({ payload }) {
    if (payload) {
        const { dispatchType, sampleRef: selectedSampleRef } = yield select(
            formValueSelector(DISPATCH_DETAILS_FORM_NAME),
            'dispatchType',
            'sampleRef'
        );
        const dispatchCategory = yield select(
            dispatchTypeCategorySelector,
            dispatchType
        );
        if (dispatchCategory === 'order') {
            const sampleRefDataset = yield select(
                entitySelector,
                DOMAIN_NAME,
                DISPATCH_SAMPLES_BY_ORDER_NO
            );

            const { po = [] } = sampleRefDataset.find(
                ({ sampleRef }) => sampleRef === selectedSampleRef
            );

            const PONumbers = po
                .filter(({ colour }) => payload === colour)
                .map(({ poNo }) => poNo);

            yield put(
                change(DISPATCH_DETAILS_FORM_NAME, 'PONumbers', PONumbers)
            );
        }
    }
};

export function* routeToDispatchWorker({ payload: { mode = null } = {} }) {
    const routeID =
        mode === 'new'
            ? NEW_SAMPLE_DISPATCH_ROUTE_ID
            : mode === 'amend'
            ? AMEND_SAMPLE_DISPATCH_ROUTE_ID
            : INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID;

    yield put(destroy(FORM_NAME));

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    }

    yield put(redirectToRoute(routeID));
}

// Watchers
const samplingDispatchSagas = function*() {
    yield takeLatest(DISPATCH_REFERENCE_GENERATE, generateReferenceWorker);
    yield takeLatest(GET_SAMPLE_DISPATCH_DATA, getSampleDispatchDataWorker);
    yield takeLatest(
        SET_ACTUAL_SEND_DATE_CHILDREN,
        setActualSendDateChildrenWorker
    );
    yield takeLatest(
        SET_DISPATCH_DETAILS_TYPE_CHILDREN,
        setDispatchDetailsTypeChildren
    );
    yield takeLatest(
        SET_DISPATCH_DETAILS_ORDER_CHILDREN,
        setDispatchDetailsOrderChildren
    );
    yield takeLatest(
        SET_DISPATCH_DETAILS_SAMPLE_REF_CHILDREN,
        setDispatchDetailsSampleRefChildren
    );
    yield takeLatest(
        SET_DISPATCH_DETAILS_COLOUR_CHILDREN,
        setDispatchDetailsColourChildren
    );
    yield takeLatest(DISPATCH_PUBLISH, dispatchPublishWorker);
    yield takeLatest(
        SET_DISPATCH_EMAIL_RECEIVERS,
        setDispatchEmailReceiversWorker
    );
    yield takeLatest(SET_DISPATCH_EMAIL_BODY, setDispatchEmailBodyWorker);
    yield takeLatest(ROUTE_TO_DISPATCH, routeToDispatchWorker);
    yield takeLatest(
        DISPATCH_REPORT_MAIL_SEND,
        dispatchReportUploadAndMailSendWorker
    );
};

export default function*() {
    yield fork(samplingDispatchSagas);
    yield fork(samplingDispatchViewSagas);
}
