import {useMemo} from "react";

export const useSnackbarStyle = ({ type, theme: { palette } }) => {
    const backgroundColor = useMemo(() => {
        switch (type) {
            case 'success':
                return palette.success.alert;
            case 'error':
                return palette.error.alert;
            case 'warning':
                return palette.warning.light;
            case 'info':
            default:
                return palette.primary.alert;
        }
    }, [type, palette]);

    return useMemo(() => ({ '--backgroundColor': backgroundColor }), [
        backgroundColor
    ]);
};
