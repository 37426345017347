import React from 'react';

import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';

const styles = ({ spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },
    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const ActionsAdornment = withStyles(styles)(
    ({ classes: c, closeModal, handleMailForm, PDFLoading }) => {
        return (
            <div className={c.container}>
                <Button
                    className={c.button}
                    onClick={handleMailForm}
                    disabled={PDFLoading}
                >
                    Publish
                </Button>
                <Button color='error' className={c.button} onClick={closeModal}>
                    Close
                </Button>
            </div>
        );
    }
);

export default ActionsAdornment;
