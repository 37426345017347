import React, { useCallback } from 'react';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import { MuiRow, TableCell } from '@libComponents/Table';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { EditIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';
import { normalizeFile } from 'sampling/redux.normalizers';
import { NO_IMAGE_URL } from 'common/common';

import Modal from './Modal';

const styles = () => ({
    cellImage: {
        maxWidth: 100,
        maxHeight: 100
    }
});

const RowComponent = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    withStyles(styles)
)(({ classes: c, handleModal, serial, styles, ...row }) => {
    const handleEdit = useCallback(
        () =>
            handleModal({
                initialValues: {
                    ...row,
                    trimType: row.trimTypeId,
                    retailer: row.retailerId,
                    image: normalizeFile(
                        row.image && row.image.replace('100_100', '350_350')
                    )
                }
            }),
        [handleModal, row]
    );

    return (
        <MuiRow classes={styles}>
            <TableCell>{serial}</TableCell>
            <TableCell>{row.retailer}</TableCell>
            <TableCell>{row.trimType}</TableCell>
            <TableCell>{row.trimRef}</TableCell>
            <TableCell>{row.trimDescription}</TableCell>
            <TableCell>{row.trimColour}</TableCell>
            <TableCell>
                <img
                    src={row.image || NO_IMAGE_URL}
                    alt={`${row.trimRef}-${row.id}`}
                    className={c.cellImage}
                />
            </TableCell>

            <TableCell>
                <TooltipAction
                    IconComponent={EditIcon}
                    title='Edit it!'
                    onClick={handleEdit}
                />
            </TableCell>
        </MuiRow>
    );
});

export default RowComponent;
