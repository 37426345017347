import React from 'react';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles, IconButton } from '@material-ui/core';

const styles = ({ spacing }) => ({
    button: {
        marginRight: spacing.unit,
        padding: spacing.unit
    }
});

const ChartHideManage = withStyles(styles)(({ classes, ...props }) => (
    <IconButton
        aria-label='visibility-off'
        className={classes.button}
        {...props}
    >
        <VisibilityOff />
    </IconButton>
));

export default ChartHideManage;
