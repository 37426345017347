import { formValueSelector } from 'redux-form';
import { select, call } from 'redux-saga/effects';

// Domain
import { FORM_NAME } from '../../../Shape/common';
import generateShapeReferenceWorker from '../generateShapeReferenceWorker';

export default function*({ mode, handleLoading }) {
    const { sampleReference, id: refId, manualReference } = yield select(
        formValueSelector(FORM_NAME),
        'sampleReference',
        'id',
        'manualReference'
    );
    if (
        mode === 'new' &&
        !refId &&
        (!sampleReference || manualReference === sampleReference)
    ) {
        const { sampleReference, id } = yield call(
            generateShapeReferenceWorker
        );
        yield call(handleLoading);
        return { sampleReference, id };
    } else {
        yield call(handleLoading);
        return { sampleReference, id: refId };
    }
}
