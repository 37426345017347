import React from 'react';

const ListViewIcon = props => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24.19'
            height='19.398'
            viewBox='0 0 24.19 19.398'
            fill='#b9b9b9'
            {...props}
        >
            <path
                id='Path_914'
                data-name='Path 914'
                d='M6.961,16.846a1.961,1.961,0,1,0,1.961,1.961A1.959,1.959,0,0,0,6.961,16.846ZM6.961,9a1.961,1.961,0,1,0,1.961,1.961A1.959,1.959,0,0,0,6.961,9Zm0,15.907a1.746,1.746,0,1,0,1.746,1.746A1.745,1.745,0,0,0,6.961,24.907Zm3.923,3.053H29.19V25.345H10.884Zm0-7.846H29.19V17.5H10.884Zm0-10.461v2.615H29.19V9.654Z'
                transform='translate(-5 -9)'
            />
        </svg>
    );
};

export { ListViewIcon as default, ListViewIcon };
