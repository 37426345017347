import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import store, { persistor } from './store';

import { FullLoader } from '@libComponents/Loaders';
import setupInterceptors from '@libs/apiRequest/setupInterceptors';

const ReduxGate = ({ children }) => (
    <Provider store={store}>
        <PersistGate loading={<FullLoader />} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
);

setupInterceptors(store);

export { ReduxGate as default, store };
