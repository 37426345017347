import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { samplingReportBreakdownKeys } from '../../hooks';
import useDetailsBreakdownChart from '../../useDetailsBreakdownChart';
import VisibilityManageModal from '../../Components/VisibilityManageModal';
import SelectAllRow from '../../Components/VisibilityManageModal/SelectAllRow';
import CheckboxRow from '../../Components/VisibilityManageModal/CheckboxRow';

const propTypes = {
    onClose: PropTypes.func.isRequired
};

const styles = () => ({
    contentContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    }
});

const ChartsVisibilityModal = withStyles(styles)(({ classes: c, onClose }) => {
    const [
        {
            chartSettings: { view, cellSettings, ...chartSettings }
        },
        dispatch
    ] = useDetailsBreakdownChart();
    const [localChartSettings, setLocalChartSettings] = useState(chartSettings);
    const [selectAll, setSelectAll] = useState(false);

    const canSave = useMemo(() => {
        const someSelected = Object.values(localChartSettings).some(Boolean);
        return someSelected;
    }, [localChartSettings]);

    useEffect(() => {
        const allSelected = Object.values(localChartSettings).every(Boolean);
        setSelectAll(allSelected);
    }, [localChartSettings]);

    const handleCheckboxChange = chartName => {
        setLocalChartSettings(prevSettings => ({
            ...prevSettings,
            [chartName]: !prevSettings[chartName]
        }));
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        const updatedSettings = Object.fromEntries(
            Object.entries(localChartSettings).map(([key]) => [
                key,
                newSelectAll
            ])
        );
        setLocalChartSettings(updatedSettings);
        setSelectAll(newSelectAll);
    };

    const onSaveButtonClick = () => {
        dispatch({
            type: 'toggleChartsVisibility',
            payload: localChartSettings
        });
        onClose();
    };

    return (
        <VisibilityManageModal
            title='Manage Charts Visibility'
            onClose={onClose}
            onSaveButtonClick={onSaveButtonClick}
            canSave={canSave}
        >
            <SelectAllRow
                checked={selectAll}
                onChange={handleSelectAllChange}
            />
            <div className={c.contentContainer}>
                {samplingReportBreakdownKeys.map(({ name, label }) => (
                    <CheckboxRow
                        key={name}
                        checked={!!localChartSettings[label]}
                        label={label}
                        onChange={() => handleCheckboxChange(label)}
                    />
                ))}
            </div>
        </VisibilityManageModal>
    );
});

ChartsVisibilityModal.propTypes = propTypes;

export default ChartsVisibilityModal;
