import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    selectedItemCountText: {
        fontSize: spacing.unit * 1.63
    }
});

const SelectedItemCount = ({ classes, selectedRows }) => {
    const itemCountText = `${selectedRows.length} ${
        selectedRows.length > 1 ? 'items' : 'item'
    } selected`;

    return (
        <Typography className={classes.selectedItemCountText}>
            {itemCountText}
        </Typography>
    );
};

export default withStyles(styles)(React.memo(SelectedItemCount));
