import { normalizeDate, getCurrentFinancialYear } from '@baseComponents/Date';

const DOMAIN_NAME = 'cadDesignerReport';
const FORM_NAME = 'cadDesignerReport';

const INITIAL_VALUES = {
    type: '1,2',
    retailer: '',
    department: '',
    from: normalizeDate(getCurrentFinancialYear()),
    to: '',
    designer: ''
};

const DUMMY_DATASETS = {
    totalNumberofCads: '0',
    totalSampleGenerateByCad: '0',
    totalCadConvToSampleByCad: '0',
    totalOrderConvByCad: '0',
    totalOrderQtyByCad: '0',
    totalOrderShippedQtyByCad: '0',
    totalNumberofsrfs: '0',
    totalcadconvertsrfs: '0',
    totalsrfconvertords: '0',
    totalOrderQtyByDesigner: '0',
    totalOrderShippedQtyByDesigner: '0',
    totalCadDays: '0',
    totalSrfDays: '0',
    avgWeekCads: '6',
    avgWeekSrfs: '8',
    totalRevenuePound: '0',
    totalMarginPound: '0',
    totalRevenueDollar: '0',
    totalMarginDollar: '0',
    totalRevenueEuro: '0',
    totalMarginEuro: '0',
    totalOrderQtyBdByCad: '0',
    totalOrderQtyTkyByCad: '0',
    totalOrderShippedQtyBdByCad: '0',
    totalOrderShippedQtyTkyByCad: '0',
    totalGlobalRevenuePound: '0',
    totalGlobalMarginPound: '0',
    cadVsSample: '0',
    cadVsOrder: '0',
    designerName: '',
    buyerName: '',
    departmentName: '',
    cadTypeName: '',
    startDate: '',
    endDate: '',
    totalWorkInQtyByCad: '0',
    totalWorkInQtyBDByCad: '0',
    totalWorkInQtyTkyByCad: '0',
    globalWorkInRevenuePound: '0',
    globalWorkInMarginPound: '0',
    totalWorkInRevenuePound: '0',
    totalWorkInMarginPound: '0',
    totalWorkInRevenueDollar: '0',
    totalWorkInMarginDollar: '0',
    totalWorkInRevenueEuro: '0',
    totalWorkInMarginEuro: '0'
};

export { DOMAIN_NAME, FORM_NAME, INITIAL_VALUES, DUMMY_DATASETS };
