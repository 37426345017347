import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import ColumnRow from './ColumnRow';
import usePDFColumnContext from './useColumns';

const propTypes = {
    column: PropTypes.array.isRequired
};

const styles = () => ({
    columnContainer: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '33%'
    }
});

const Column = withPDFStyles(styles, { withTheme: true })(
    ({ classes: c, column }) => {
        const {
            PDFColumnState: { width }
        } = usePDFColumnContext();

        return (
            <View style={{ ...c.columnContainer, width }}>
                {column.map((col, idx) => (
                    <ColumnRow {...col} key={`pdf-column-row-${idx}`} />
                ))}
            </View>
        );
    }
);

Column.propTypes = propTypes;

export default Column;
