import DashboardIcon from '@assets/NavigationIcons/dashboard.svg';
import ColoredDashboardIcon from '@assets/NavigationIcons/dashboardColored.svg';
import AdministrationIcon from '@assets/NavigationIcons/administration.svg';
import ColoredAdministrationIcon from '@assets/NavigationIcons/administrationColored.svg';
import ReportsIcon from '@assets/NavigationIcons/reports.svg';
import ColoredReportsIcon from '@assets/NavigationIcons/reportsColored.svg';
import SetupIcon from '@assets/NavigationIcons/setup.svg';
import ColoredSetupIcon from '@assets/NavigationIcons/setupColored.svg';
import SamplingIcon from '@assets/NavigationIcons/sampling.svg';
import ColoredSamplingIcon from '@assets/NavigationIcons/samplingColored.svg';
import EnquiriesIcon from '@assets/NavigationIcons/enquiries.svg';
import ColoredEnquiriesIcon from '@assets/NavigationIcons/enquiriesColored.svg';
import ConfirmedIcon from '@assets/NavigationIcons/confirmed.svg';
import ColoredConfirmedIcon from '@assets/NavigationIcons/confirmedColored.svg';
import ApprovalsIcon from '@assets/NavigationIcons/approvals.svg';
import ColoredApprovalsIcon from '@assets/NavigationIcons/approvalsColored.svg';
import OrdersIcon from '@assets/NavigationIcons/orders.svg';
import ColoredOrdersIcon from '@assets/NavigationIcons/ordersColored.svg';
import FabricIcon from '@assets/NavigationIcons/fabric.svg';
import ColoredFabricIcon from '@assets/NavigationIcons/fabricColored.svg';
import CadsIcon from '@assets/NavigationIcons/cads.svg';
import ColoredCadsIcon from '@assets/NavigationIcons/cadsColored.svg';
import BoughtSampleIcon from '@assets/NavigationIcons/boughtSample.svg';
import ColoredBoughtSampleIcon from '@assets/NavigationIcons/boughtSampleColored.svg';
import UniqueCodeIcon from '@assets/NavigationIcons/uniqueCode.svg';
import ColoredUniqueIconIcon from '@assets/NavigationIcons/uniqueCodeColored.svg';
import HelpIcon from '@assets/NavigationIcons/help.svg';
import ColoredHelpIcon from '@assets/NavigationIcons/helpColored.svg';
import CostingIcon from '@assets/NavigationIcons/costing.svg';
import ColoredCostingIcon from '@assets/NavigationIcons/costingColored.svg';
import LinePlanIcon from '@assets/NavigationIcons/linePlan.svg';
import ColoredLinePlanIcon from '@assets/NavigationIcons/linePlanColored.svg';
import qualityControlIcon from '@assets/NavigationIcons/qualityControl.svg';
import qualityControlColoredIcon from '@assets/NavigationIcons/qualityControlColored.svg';
// import LinesheetsIcon from '@assets/NavigationIcons/linesheets';
// import ColoredLinesheetsIcon from '@assets/NavigationIcons/linesheetsColored';

export default {
    // Dashboard
    '1': { icon: DashboardIcon, coloredIcon: ColoredDashboardIcon },
    // Administration
    '2': { icon: AdministrationIcon, coloredIcon: ColoredAdministrationIcon },
    // Reports
    '10': { icon: ReportsIcon, coloredIcon: ColoredReportsIcon },
    // Setup
    '11': { icon: SetupIcon, coloredIcon: ColoredSetupIcon },
    // Sampling
    '85': { icon: SamplingIcon, coloredIcon: ColoredSamplingIcon },
    // Enquiries
    '86': { icon: EnquiriesIcon, coloredIcon: ColoredEnquiriesIcon },
    // Confirmed
    '87': { icon: ConfirmedIcon, coloredIcon: ColoredConfirmedIcon },
    // Approvals
    '88': { icon: ApprovalsIcon, coloredIcon: ColoredApprovalsIcon },
    // Orders
    '89': { icon: OrdersIcon, coloredIcon: ColoredOrdersIcon },
    // Fabric
    '90': { icon: FabricIcon, coloredIcon: ColoredFabricIcon },
    // Cads
    '141': { icon: CadsIcon, coloredIcon: ColoredCadsIcon },
    // Bought Sample
    '172': { icon: BoughtSampleIcon, coloredIcon: ColoredBoughtSampleIcon },
    // Unique Code
    '175': { icon: UniqueCodeIcon, coloredIcon: ColoredUniqueIconIcon },
    // Help
    '180': { icon: HelpIcon, coloredIcon: ColoredHelpIcon },
    // Costing
    '406': { icon: CostingIcon, coloredIcon: ColoredCostingIcon },
    // Line Plan
    '524': { icon: LinePlanIcon, coloredIcon: ColoredLinePlanIcon },
    // Linesheet
    // '531': { icon: LinesheetsIcon, coloredIcon: ColoredLinesheetsIcon }
    '545': { icon: LinePlanIcon, coloredIcon: LinePlanIcon },
    '608': { icon: qualityControlIcon, coloredIcon: qualityControlColoredIcon }
};
