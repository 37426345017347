const splitArray = (data, chunkSize) =>
    data.reduce(
        (acm, datum) => {
            const acmLast = acm[acm.length - 1];
            if (acmLast.length === Number(chunkSize)) {
                return [...acm, [datum]];
            }
            // Else
            acmLast.push(datum);
            return acm;
        },
        [[]]
    );

export { splitArray };