import { useMemo } from 'react';

const useUrl = url => {
    const extension = useMemo(
        () =>
            url
                .split('.')
                .reverse()[0]
                .substring(0, 3),
        [url]
    );

    const canPreview = useMemo(() => {
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return true;
            default:
                return false;
        }
    }, [url]);

    return { extension, canPreview };
};

export { useUrl };
