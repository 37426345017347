import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { ModalHeader, ModalContent } from '@libComponents/Modal';

import ModalFooter from './ModalFooter';

const propTypes = {
    onClose: PropTypes.func.isRequired,
    onSaveButtonClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    canSave: PropTypes.bool
};

const defaultProps = {
    canSave: true
};

const styles = ({ breakpoints, spacing }) => ({
    bodyContainer: {
        width: breakpoints.values.sm,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: spacing.unit * 2,
        paddingTop: spacing.unit * 1,
        [breakpoints.down(770)]: {
            width: '90%'
        }
    }
});

const VisibilityManageModal = withStyles(styles)(
    ({ classes, onClose, onSaveButtonClick, title, children, canSave }) => {
        return (
            <>
                <ModalHeader onClose={onClose}>{title}</ModalHeader>
                <ModalContent className={classes.bodyContainer}>
                    {children}
                </ModalContent>
                <ModalFooter onSave={onSaveButtonClick} disabled={!canSave} />
            </>
        );
    }
);

VisibilityManageModal.propTypes = propTypes;
VisibilityManageModal.defaultProps = defaultProps;

export default VisibilityManageModal;
