import React from 'react';

import { withStyles } from '@material-ui/core';

import RetailerBreakdownSelect from './RetailerBreakdownSelect';
import ManageChartsVisibility from './ManageChartsVisibility';
import ReportExport from './ReportExport';
import ViewToggle from './ViewToggle';

const styles = ({ spacing, breakpoints }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        columnGap: spacing.unit * 2
    },
    viewToggle: {
        [breakpoints.down(770)]: {
            display: 'none'
        }
    },
    select: {
        [breakpoints.down(770)]: {
            display: 'none'
        }
    }
});

const Adornments = withStyles(styles)(({ classes }) => {
    return (
        <div className={classes.root}>
            <ViewToggle className={classes.viewToggle} />
            <ManageChartsVisibility />
            <RetailerBreakdownSelect className={classes.select} />
            <ReportExport />
        </div>
    );
});

export default Adornments;
