// const baseURL = 'https://18.130.139.199/echoapi';
// const baseURL = 'http://185.38.37.19/echoapi';
// const baseURL = 'http://185.38.37.19/echodbapi/api/';
// const baseURL = 'http://18.134.242.106/echodbapi/api/';
// const baseURL = 'http://3.8.139.234/api/';
// const baseURL = 'http://api.echosourcing.net/api/';

const localBaseURL = 'http://localhost/api/';
const stagingBaseURL = 'https://apistaging.echosourcing.net/api/';
const prodBaseURL = 'https://api.echosourcing.net/api/';

const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;

const baseURL =
    BUILD_ENV === 'local'
        ? localBaseURL
        : BUILD_ENV === 'development'
        ? stagingBaseURL
        : prodBaseURL;

const baseConfig = {
    baseURL,
    header: {
        'Content-Type': 'application/json'
    },
    timeout: 60000
};

export { baseConfig as default, baseURL, BUILD_ENV };
