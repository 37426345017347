import React, { useMemo } from 'react';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';

import { withStyles } from '@material-ui/core';

// Lib
import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Local
import HeaderAdornment from '../HeaderAdornment';
import FabricReferenceContainer from './FabricReferenceContainer';
import OriginalFabricContainer from './OriginalFabricContainer';
import RequiredFabricContainer from './RequiredFabricContainer';
import FabricWashedImageAndCommentsContainer from './FabricWashedImageAndCommentsContainer';
import { FORM_NAME, LABELS } from '../../common';
import {
    COPY_ORIGINAL_FABRIC_DATA,
    SET_MATCH_COLOR_CHILDREN
} from '../../../redux.actionTypes';
import { useFabricDevelopmentState } from '../../useFabricDevelopment';

const mapInputs = {
    hasSameAsOriginal: ({ payload }) => ({
        type: COPY_ORIGINAL_FABRIC_DATA,
        payload
    }),
    hasMatchWashAndFinish: () => change(FORM_NAME, 'specialFinish', ''),
    hasMatchColor: () => ({
        type: SET_MATCH_COLOR_CHILDREN
    })
};

const styles = ({ spacing }) => ({
    container: {
        marginBottom: spacing.unit * 6
    }
});

const Details = compose(
    withStyles(styles),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs)
)(({ classes }) => {
    const { shouldLocked } = useFabricDevelopmentState();
    const props = useMemo(() => ({ formName: FORM_NAME, shouldLocked }), [
        shouldLocked
    ]);
    return (
        <ContentContainer
            title={LABELS.details}
            AdornmentComponent={<HeaderAdornment />}
            className={classes.container}
        >
            <FabricReferenceContainer {...props} />
            <OriginalFabricContainer {...props} />
            <RequiredFabricContainer {...props} />
            <FabricWashedImageAndCommentsContainer {...props} />
        </ContentContainer>
    );
});

export default Details;
