import { initializeDatasets } from '@libs/datasets';
import datasets from './redux.datasets';
import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import {
    SET_CLIENT_PO_TBC_LOADING,
    SET_CLIENT_PO_NO_CHECK_LOADING,
    SET_PRODUCTS_LOADING,
    SET_SAMPLE_REF_CHANGE_DATA_LOADING,
    SET_ORDER_REFERENCE_LOADING,
    SET_FACTORY_REF_LOADING,
    SET_PO_LAB_DIP_COLOR_LOADING,
    SET_PO_TBC_LOADING,
    SET_PO_PACK_TBC_LOADING,
    SET_PO_SIZE_SET_LOADING,
    SET_PO_OR_PACK_CHECK_LOADING,
    ORDER_TRIMS_LOADING,
    SET_PO_PAYMENT_TERMS_AND_SETTLEMENT_DISCOUNT_DEFAULT_VALUE_LOADING,
    SET_CP_EXPORT_EXCEL_DATA_LOADING
} from 'orders/redux.actionTypes';

const initialState = {
    clientPOTBCLoading: false,
    clientPONoCheckLoading: false,
    productsLoading: false,
    orderNoLoading: false,
    factoryRefLoading: false,
    poTBCLoading: false,
    poPackTBCLoading: false,
    poSizeSetLoading: false,
    poOrPackCheckLoading: false,
    orderTrimsLoading: false,
    sampleRefChangeDataLoading: false,
    paymentAndSettlementDeafaultValueLoading: false,
    orderCPExportExcelDataLoading: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CLIENT_PO_TBC_LOADING: {
            return { ...state, clientPOTBCLoading: payload };
        }

        case SET_CLIENT_PO_NO_CHECK_LOADING: {
            return { ...state, clientPONoCheckLoading: payload };
        }

        case SET_PRODUCTS_LOADING: {
            return { ...state, productsLoading: payload };
        }

        case SET_SAMPLE_REF_CHANGE_DATA_LOADING: {
            return { ...state, sampleRefChangeDataLoading: payload };
        }

        case SET_ORDER_REFERENCE_LOADING: {
            return { ...state, orderNoLoading: payload };
        }

        case SET_FACTORY_REF_LOADING: {
            return { ...state, factoryRefLoading: payload };
        }

        case SET_PO_LAB_DIP_COLOR_LOADING: {
            const [key, value] = Object.entries(payload)[0];
            return { ...state, [key]: value };
        }

        case SET_PO_OR_PACK_CHECK_LOADING: {
            return { ...state, poOrPackCheckLoading: payload };
        }

        case SET_PO_TBC_LOADING: {
            return { ...state, poTBCLoading: payload };
        }

        case SET_PO_PACK_TBC_LOADING: {
            return { ...state, poPackTBCLoading: payload };
        }

        case SET_PO_SIZE_SET_LOADING: {
            return { ...state, poSizeSetLoading: payload };
        }
        case ORDER_TRIMS_LOADING: {
            return { ...state, orderTrimsLoading: payload };
        }
        case SET_PO_PAYMENT_TERMS_AND_SETTLEMENT_DISCOUNT_DEFAULT_VALUE_LOADING: {
            return {
                ...state,
                paymentAndSettlementDeafaultValueLoading: payload
            };
        }
        case SET_CP_EXPORT_EXCEL_DATA_LOADING: {
            return {
                ...state,
                orderCPExportExcelDataLoading: payload
            };
        }

        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
