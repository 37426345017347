import React from 'react';

import Item from './Item';
import { CostForm as CostFormIcon } from '@assets/Icons';
import { renameExportHeader } from 'gridView/customSelectors';

const CostForm = ({
    costFormLoading,
    getFileName,
    exportToExcel,
    preparedExportData,
    shouldDisable,
    exportedColumns,
    headerMap,
    costColumns
}) => {
    const handleDownload = () => {
        let preparedData = preparedExportData(exportedColumns, 'xlsx');
        if (!!Object.keys(headerMap).length) {
            preparedData = renameExportHeader(
                preparedData,
                headerMap,
                costColumns
            );
        }
        exportToExcel({
            fileName: getFileName('costForm'),
            data: preparedData,
            reportType: 'costForm'
        });
    };
    return (
        <Item
            icon={CostFormIcon}
            title={'Cost form'}
            shouldDisable={shouldDisable(costFormLoading)}
            onClick={handleDownload}
        />
    );
};

export default CostForm;
