import React from 'react';
import { number, string, oneOfType, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Libs
import { valueSelector } from '@libs/datasets';
// Material
import { withStyles } from '@material-ui/core';
// Local
import { useDipsTableBody } from './hooks';
import { DOMAIN_NAME } from 'orders/common';
import { NP_COLORS, ORDER_SUSTAINABILITY } from 'orders/redux.datasets';
import { isNinetyPercentSelector } from 'orders/redux.selectors';
import {
    COMPOSITIONS,
    FABRIC_TYPES,
    FINISHED_GSMS
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import Cell from './Cell';

const propTypes = {
    sampleRef: string.isRequired,
    color: oneOfType([string, number]).isRequired,
    sustainability: number,
    colorCode: string,
    colorRecipe: string,
    echoPrice: object.isRequired,
    fobPrice: object.isRequired,
    licenseFee: object
};

const mapState = (
    state,
    { color, sustainability, composition, fabricType, gsm }
) => {
    composition = valueSelector(
        state,
        SAMPLING_DOMAIN_NAME,
        COMPOSITIONS,
        composition
    );
    fabricType = valueSelector(
        state,
        SAMPLING_DOMAIN_NAME,
        FABRIC_TYPES,
        fabricType
    );
    gsm = valueSelector(state, SAMPLING_DOMAIN_NAME, FINISHED_GSMS, gsm);
    const fabrication = composition
        ? `${composition} / ${fabricType} / ${gsm}`
        : null;
    const isNinetyPercent = isNinetyPercentSelector(state);
    let colorValue = '';
    if (isNinetyPercent) {
        colorValue = valueSelector(state, DOMAIN_NAME, NP_COLORS, color);
    } else {
        const colorArray = color.split('-');
        colorArray.pop();
        colorValue = colorArray.join();
    }
    return {
        color: colorValue,
        sustainability: valueSelector(
            state,
            DOMAIN_NAME,
            ORDER_SUSTAINABILITY,
            sustainability
        ),
        fabrication
    };
};

const styles = () => ({
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
});

const Row = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: { row }, fabricFor, fabricType, gsm, ...rowProps }) => {
    const values = useDipsTableBody(rowProps);
    return (
        <div className={row}>
            {values.map((cell, idx) => (
                <Cell value={cell} key={`dip-body-row-cell-${idx}`} />
            ))}
        </div>
    );
});

Row.propTypes = propTypes;

export default Row;
