import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { required } from '@libs/validationRules';

import { DOMAIN_NAME } from 'orders/common';
import { PO_PACK_TYPE } from 'orders/redux.datasets';

const packTypeRules = [required];

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'packType',
    label: 'Pack Type',
    placeholder: 'Select PO Pack Type',
    domain: DOMAIN_NAME,
    entity: PO_PACK_TYPE,
    disabled: false,
    required: true,
    validate: packTypeRules
};

const SelectPackType = props => <Select {...props} />;

SelectPackType.propTypes = propTypes;
SelectPackType.defaultProps = defaultProps;

export default SelectPackType;
