import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

// Libs
import { TooltipAction } from '@formComponents';
import { DeleteIcon } from '@assets/Icons';
import useConfirmDelete from '@libHooks/useConfirmDelete';
import useBlobURL from '@libHooks/useBlobURL';
import { useDimensionsObserver } from '@libHooks/useDimensions';

// Local
import DownloadLink from '../../DownloadLink';
import Preview from './Preview';

const propTypes = {
    url: PropTypes.string,
    filename: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired
};

const styles = ({ spacing, palette, typography }) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: spacing.unit * 9,

        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    fileInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '75%'
    },
    filename: {
        width: 'var(--filenameWidth)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: palette.primary.main,
        fontWeight: typography.fontWeightHeavy
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '25%'
    }
});

const useAttachmentStyle = ({ width, theme: { spacing } }) => {
    return useMemo(
        () => ({
            '--filenameWidth': `${width - spacing.unit * 9}px`
        }),
        [width]
    );
};

const Attachment = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme, url, filename, handleDelete }) => {
        const [infoRef, { width }] = useDimensionsObserver({
            width: theme.spacing.unit * 15
        });
        const [blobUrl, blobLoading, canDownload] = useBlobURL(url);
        const [renderConfirmDialog, handleConfirmDelete] = useConfirmDelete(
            handleDelete
        );

        return (
            <div
                className={c.root}
                style={useAttachmentStyle({ width, theme })}
            >
                <div className={c.fileInfo} ref={infoRef}>
                    <Preview url={blobUrl} filename={filename} />
                    <Typography className={c.filename}>{filename}</Typography>
                </div>
                <div className={c.actions}>
                    {canDownload && (
                        <DownloadLink
                            url={blobUrl}
                            name={filename}
                            loading={blobLoading}
                        />
                    )}
                    <TooltipAction
                        IconComponent={DeleteIcon}
                        title='Delete it!'
                        onClick={handleConfirmDelete}
                    />
                </div>
                {renderConfirmDialog}
            </div>
        );
    }
);

Attachment.propTypes = propTypes;

export default Attachment;
