export default ({ shadows, spacing, typography, palette }) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        height: spacing.unit * 6,

        padding: 'unset',
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,

        border: '1px solid',
        borderColor: palette.grey[400],
        borderTop: 'unset',

        backgroundColor: palette.grey[50],


        '&:last-child': {
            paddingBottom: 'unset'
        }
    },
    icon: {
        fontSize: typography.headline.fontSize,
        opacity: palette.icon.opacity.regular,
        '&:hover': {
            opacity: palette.icon.opacity.hover
        }
    },
});
