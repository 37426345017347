import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

// App
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

import { PaginatedSelect } from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME } from 'sampling/common';
import { getDevelopedFabricData } from 'sampling/redux.actions';

const propTypes = {
    handleAdd: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing, typography }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3,
        width: '100%'
    },

    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    // ----- Paginated Select

    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    },
    // button
    button: {
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    icon: {
        color: palette.grey[500],
        cursor: 'pointer',
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: '2px',
        backgroundColor: palette.common.white,
        height: spacing.unit * 3.5,
        marginLeft: spacing.unit * 0.5,
        '&:hover': {
            color: palette.grey[700],
            borderColor: '#bdbdbd'
        },
        transform: 'scale(1.3)'
    }
});

const mapInputs = {
    developedFabric: ({ payload, meta: { form } }) =>
        getDevelopedFabricData({
            reference: payload,
            formName: form
        })
};

const Adornment = compose(
    formListener(mapInputs),
    withStyles(styles, { withTheme: true })
)(({ classes: c, theme: { spacing }, entity, handleAdd, loading }) => {
    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null
        }),
        []
    );
    return (
        <div className={c.root}>
            {loading ? <CircularProgress size={spacing.unit * 4} /> : <div />}
            <div className={c.wrapper}>
                <div className={c.wrapper}>
                    <div className={c.selectContainerWrapper}>
                        <PaginatedSelect
                            name='developedFabric'
                            entity={entity}
                            domain={DOMAIN_NAME}
                            {...paginatedSelectProps}
                        />
                    </div>
                </div>
                <Add className={c.icon} onClick={handleAdd} />
            </div>
        </div>
    );
});

Adornment.propTypes = propTypes;
Adornment.defaultProps = defaultProps;

export default Adornment;
