import { FETCH_EMAIL_DATA_SUCCESS } from 'actionTypes';

const mailReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_EMAIL_DATA_SUCCESS: {
            return {
                ...state,
                datasets: payload
            };
        }

        default: {
            return state;
        }
    }
};

export { mailReducer as default, mailReducer };
