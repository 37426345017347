export const renameProperties = (data, mapping) => {
    try {
        const mappingIsObject =
            typeof mapping === 'object' && !Array.isArray(mapping); // Is an object
        const dataKeys = mappingIsObject ? Object.keys(mapping) : [...mapping];

        const mapKeys = mappingIsObject ? Object.values(mapping) : null;

        const renamedData = dataKeys.reduce((accumulator, key, index) => {
            if (typeof data[key] !== 'undefined') {
                if (mappingIsObject && mapKeys[index] !== '') {
                    return {
                        ...accumulator,
                        [mapKeys[index]]: data[key]
                    };
                } else {
                    return {
                        ...accumulator,
                        [key]: data[key]
                    };
                }
            } else {
                return accumulator;
                // throw Error(`Property '${key}' does not exist in object`);
            }
        }, {});

        const restData = Object.entries(data).reduce((acm, [key, value]) => {
            if (dataKeys.includes(key)) return acm;
            else return { ...acm, [key]: value };
        }, {});

        return { ...renamedData, ...restData };
    } catch (error) {
        console.error(error);
    }
};

// Returns and renames only the properties in the mapping (
// Property names are matched, values are renamed
export const splice = (data, mapping) => {
    try {
        const mappingIsObject =
            typeof mapping === 'object' && !Array.isArray(mapping); // Is an object
        const dataKeys = mappingIsObject ? Object.keys(mapping) : [...mapping];

        const mapKeys = mappingIsObject ? Object.values(mapping) : null;

        return dataKeys.reduce((accumulator, key, index) => {
            if (typeof data[key] !== 'undefined') {
                if (mappingIsObject && mapKeys[index] !== '') {
                    return {
                        ...accumulator,
                        [mapKeys[index]]: data[key]
                    };
                } else {
                    return {
                        ...accumulator,
                        [key]: data[key]
                    };
                }
            } else {
                return accumulator;
                // throw Error(`Property '${key}' does not exist in object`);
            }
        }, {});
    } catch (error) {
        console.error(error);
    }
};

export const convertObjectValuesToEmptyString = originalObj => {
    // Check if the argument is an object
    if (typeof originalObj === 'object' && originalObj !== null) {
        // Create a shallow copy of the original object
        const newObj = { ...originalObj };

        // Iterate over the object properties
        for (let key in newObj) {
            if (newObj.hasOwnProperty(key)) {
                // Check if the property is an object and apply recursion
                if (typeof newObj[key] === 'object' && newObj[key] !== null) {
                    newObj[key] = convertObjectValuesToEmptyString(newObj[key]);
                } else {
                    // Convert null or undefined values to empty string
                    newObj[key] =
                        newObj[key] === null || newObj[key] === undefined
                            ? ''
                            : newObj[key];
                }
            }
        }

        // Return the new object with modified values
        return newObj;
    }

    // Return the original object if it's not an object
    return originalObj;
};
