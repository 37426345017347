import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Material
import { withStyles, Tooltip } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import ContentLoader from '@libComponents/ContentLoader';
import {
    datasetLoadingSelector,
    entitySelector,
    fetchDatasets,
    refreshDataset
} from '@libs/datasets';
import {
    Table,
    TableHead,
    TableBody,
    MuiRow,
    TableCell,
    TableFooter,
    TablePagination,
    TablePaginationActions,
    TableSortLabel
} from '@libComponents/Table';
import { functionalComponentPropType } from '@libs/propTypes';

// Local
import { useSetup } from './hooks';
import Adornments from './Adornments';

// Define prop types for the component
const propTypes = {
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    AdornmentComponent: functionalComponentPropType,
    headers: PropTypes.array.isRequired,
    RowComponent: functionalComponentPropType
};

// Default props for the component
const defaultProps = {
    title: 'Setup',
    AdornmentComponent: Fragment
};

// Map state data to component props
const mapState = (state, { domain, entity }) => ({
    data: entitySelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

// Map dispatch functions to component props
const mapDispatch = (dispatch, { domain, entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity)),
    refreshData: () => dispatch(refreshDataset({ domain, entity }))
});

// Styles for the component
const styles = ({ spacing, palette, transitions }) => ({
    contentContainer: {
        marginTop: spacing.unit * 2.5,
        marginBottom: spacing.unit * 2.5
    },
    container: {
        padding: 0,
        width: '100%'
    },
    progress: {
        width: '100%',
        backgroundColor: palette.secondary.light,
        height: spacing.unit * 0.5,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: palette.secondary.main,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    table: {
        borderWidth: 0
    },
    tableHead: {
        backgroundColor: 'inherit',
        fontSize: spacing.unit * 1.75,
        fontWeight: 600
    },
    tableBodyRow: {
        height: spacing.unit * 5,
        transition: transitions.create('background', {
            easing: transitions.easing.easeInOut
        }),
        '&:hover': {
            backgroundColor: palette.grey[200]
        }
    },
    noDataCell: {
        textAlign: 'center'
    }
});

// Component to render header cell in the table
const Header = ({
    label,
    canSort,
    sortKey,
    order,
    orderBy,
    handleSorting,
    styles
}) => {
    return canSort ? (
        <TableCell
            styles={styles}
            sortDirection={orderBy === sortKey ? order : false}
        >
            <Tooltip title='Sort' enterDelay={300}>
                <TableSortLabel
                    active={orderBy === sortKey}
                    direction={order}
                    onClick={handleSorting}
                >
                    {label}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    ) : (
        <TableCell styles={styles}>{label}</TableCell>
    );
};

const SetupBase = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        data,
        loading,
        fetchDataset,
        refreshData,
        title,
        AdornmentComponent,
        headers,
        RowComponent,
        pageSize = 10
    }) => {
        const {
            paginatedRows,
            indexPrefix,
            handleSearch,
            searchValue,
            order,
            orderBy,
            createSortHandler,
            ...paginatedProps
        } = useSetup(data, pageSize); // it handles sorting, searching and pagination of the data

        useEffect(() => {
            fetchDataset();
        }, []);

        return (
            <ContentContainer
                title={title}
                AdornmentComponent={
                    <Adornments
                        Adornment={AdornmentComponent}
                        handleSearch={handleSearch}
                        searchValue={searchValue}
                        handleRefresh={refreshData}
                    />
                }
                styles={{ container: c.contentContainer }}
            >
                {loading && <ContentLoader />}
                <div className={c.container}>
                    <Table styles={{ table: c.table }}>
                        <TableHead>
                            <MuiRow>
                                {headers.map((header, idx) => (
                                    <Header
                                        key={`setup-table-head-${idx}`}
                                        styles={{ head: c.tableHead }}
                                        order={order}
                                        orderBy={orderBy}
                                        handleSorting={createSortHandler(
                                            header.sortKey
                                        )}
                                        {...header}
                                    />
                                ))}
                            </MuiRow>
                        </TableHead>
                        {!!paginatedRows.length && (
                            <TableBody>
                                {paginatedRows.map((row, idx) => (
                                    <RowComponent
                                        key={`setup-table-row-${idx}`}
                                        {...row}
                                        serial={indexPrefix + idx + 1}
                                        styles={{ root: c.tableBodyRow }}
                                    />
                                ))}
                            </TableBody>
                        )}
                    </Table>
                    <Table styles={{ table: c.table }}>
                        <TableFooter>
                            <MuiRow>
                                {!!paginatedRows.length ? (
                                    <TablePagination
                                        colSpan={headers.length}
                                        SelectProps={{
                                            native: true
                                        }}
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                        {...paginatedProps}
                                    />
                                ) : (
                                    <TableCell
                                        colSpan={headers.length}
                                        styles={{ root: c.noDataCell }}
                                    >
                                        No data Found !!
                                    </TableCell>
                                )}
                            </MuiRow>
                        </TableFooter>
                    </Table>
                </div>
            </ContentContainer>
        );
    }
);

SetupBase.propTypes = propTypes;
SetupBase.defaultProps = defaultProps;

export { SetupBase as default, SetupBase };
