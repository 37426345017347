import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

// libs
import Button from '@libComponents/Button';

const propTypes = {
    handleAdd: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
};

const defaultProps = {};

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3,
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        marginLeft: spacing.unit,
        width: 'auto'
    }
});

const Adornments = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing }, handleAdd, loading, disabled }) => {
        return (
            <div className={c.root}>
                {loading ? (
                    <CircularProgress size={spacing.unit * 4} />
                ) : (
                    <div />
                )}
                <div className='container'>
                    <Button
                        color='success'
                        onClick={handleAdd}
                        disabled={disabled}
                        className={c.button}
                    >
                        Add Dispatch Details
                    </Button>
                </div>
            </div>
        );
    }
);

Adornments.propTypes = propTypes;
Adornments.defaultProps = defaultProps;

export default Adornments;
