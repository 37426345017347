import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

// Libs
import { TooltipAction } from '@formComponents';
import { DeleteIcon } from '@assets/Icons';
import useConfirmDelete from '@libHooks/useConfirmDelete';
// Local
import { NO_IMAGE_URL } from 'common/common';

const propTypes = {
    labelRef: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string,
    color: PropTypes.string,
    image: PropTypes.string,
    handleDelete: PropTypes.func.isRequired
};

const styles = ({ spacing, palette }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr) 75px 50px',
        gridColumnGap: `${spacing.unit * 0.5}px`,
        alignItems: 'center',
        padding: `${spacing.unit * 0.75}px ${spacing.unit}px`,

        '&:not(:last-child)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: palette.background.dark
        }
    },
    headerText: {
        fontSize: spacing.unit * 1.5,
        opacity: 0.8
    },
    imageContainer: {
        width: spacing.unit * 7.5,
        height: spacing.unit * 7.5,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    }
});

const Brand = withStyles(styles)(
    ({
        classes: c,
        labelRef,
        type,
        description,
        color,
        image,
        handleDelete
    }) => {
        const [renderConfirmDialog, handleConfirmDelete] = useConfirmDelete(
            handleDelete
        );
        return (
            <div className={c.container}>
                <Typography className={c.headerText}>{labelRef}</Typography>
                <Typography className={c.headerText}>{type}</Typography>
                <Typography className={c.headerText}>{description}</Typography>
                <Typography className={c.headerText}>{color}</Typography>
                <div className={c.imageContainer}>
                    <img
                        src={image || NO_IMAGE_URL}
                        alt={`${labelRef}${type}`}
                        className={c.image}
                    />
                </div>
                <TooltipAction
                    IconComponent={DeleteIcon}
                    title='Delete it!'
                    onClick={handleConfirmDelete}
                />
                {renderConfirmDialog}
            </div>
        );
    }
);

Brand.propTypes = propTypes;

export default Brand;
