import React, { useMemo } from 'react';

import { ORDER_BASE_FACTORIES } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const OrderFactory = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Factory Name', canSort: true, sortKey: 'factoryName' },
            { label: 'Factory Code', canSort: true, sortKey: 'factoryCode' },
            {
                label: 'Factory Start Point',
                canSort: true,
                sortKey: 'factoryStartPoint'
            },
            { label: 'Country', canSort: true, sortKey: 'country' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'ORDER FACTORY SETUP',
        domain: DOMAIN_NAME,
        entity: ORDER_BASE_FACTORIES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { OrderFactory as default, OrderFactory };
