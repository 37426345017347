export const normalizeCollections = ({ data: collections }) =>
    collections.map(({ id, name }) => ({
        collectionId: Number(id),
        collection: name
    }));

export const normalizeLicensors = ({ data: licensors }) =>
    licensors.map(({ id, name, code }) => ({
        licensorId: Number(id),
        licensor: name,
        licensorCode: code
    }));

