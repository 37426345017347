import { createSelector } from 'reselect';

export const checkEverySizeSetValueEquality = createSelector(
    value => value,
    value =>
        value.every(
            ({ sizeSet }) => Number(sizeSet) === Number(value[0].sizeSet)
        )
);

export default (fieldValue = []) => {
    if (fieldValue.length === 0) return 'Please add at least 1 PO to proceed.';
    if (!checkEverySizeSetValueEquality(fieldValue))
        return 'Size set should be the same for every PO.';
    return null;
};
