import { normalizeDate } from '../../@baseComponents/Date';

export { DOMAIN_NAME } from '../common';
const FORM_NAME = 'shape';

const LABELS = {
    setup: 'New Sample Request Setup',
    details: 'Sample Reference & Spec Details',
    other: 'Other Information',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    sampleReference: '',
    id: '',
    developmentNumber: 102,
    fitOption: 7,
    dateRequested: normalizeDate(new Date()),
    departmentNumber: '',
    fabrics: [],
    attachments: [],
    boughtDetails: [],
    trimDetails: []
};

const DESIGN_SOURCE_IN_HOUSE_ID = 1;
const FIT_OPTION_SEALED_ID = 1;
const NEW_SHAPE_ROUTE_ID = 589;
const INCOMPLETE_SHAPE_ROUTE_ID = 136;
const AMEND_SHAPE_ROUTE_ID = 137;

export {
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    DESIGN_SOURCE_IN_HOUSE_ID,
    NEW_SHAPE_ROUTE_ID,
    INCOMPLETE_SHAPE_ROUTE_ID,
    AMEND_SHAPE_ROUTE_ID,
    FIT_OPTION_SEALED_ID
};
