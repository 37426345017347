export const publishTemplate = (
    body,
    links,
    title
) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>${title}</title>
  <style type="text/css">
    body {
      margin: 0;
      padding: 0;
      min-width: 100% !important;
    }
    .content {
      width: 100%;
      max-width: 600px;
    }
    .header {
      padding: 40px 30px 20px 30px;
    }
    .innerpadding {
      padding: 30px 30px 30px 30px;
    }
    .borderbottom {
      border-bottom: 1px solid #f2eeed;
    }
    .subhead {
      font-size: 15px;
      color: #ffffff;
      font-family: sans-serif;
      letter-spacing: 10px;
    }
    .h1,
    .h2,
    p {
      color: #153643;
      font-family: sans-serif;
    }

    @media only screen and (min-device-width: 601px) {
      .content {
        width: 600px !important;
      }
      .col425 {
        width: 425px !important;
      }
      .col380 {
        width: 380px !important;
      }
    }
  </style>
</head>

<body yahoo bgcolor="#f6f8f1">
  <table
    width="100%"
    bgcolor="#f6f8f1"
    border="0"
    cellpadding="0"
    cellspacing="0"
  >
    <tr>
      <td>
        <!--[if (gte mso 9)|(IE)]>
          <table width="600" align="center" cellpadding="0" cellspacing="0" border="0">
              <tr>
                  <td>
          <![endif]-->
        <table
          bgcolor="#ffffff"
          class="content"
          align="center"
          cellpadding="0"
          cellspacing="0"
          border="0"
        >
          <tr>
            <td bgcolor="#f26831" class="header">
              <table
                width="100%"
                align="left"
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <tr>
                  <td
                    height="30"
                    style="padding: 0 20px 20px 0"
                    align="center"
                  >
                    
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="innerpadding borderbottom">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                ${body}
                <p></p>
                ${links.map(({ url, filename }) => {
                    return `<p>
                    <a
                      href=${url}
                      target="__blank"
                      >${filename}</a
                    >
                  </p>`;
                })}
                
                <p>-Echosys Team</p>
              </table>
            </td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]>
          </td>
          </tr>
          </table>
          <![endif]-->
      </td>
    </tr>
  </table>
</body>
</html>
`;
