import { formValueSelector } from 'redux-form';
import { select, call, all, fork } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { getFileNameFromURL } from '@libs/filenames';
import { updateCcAddress } from '@libs/mailer';
import { sizePathsSelector } from 'common/redux.selectors';
import { userNameSelector, userEmailSelector } from 'auth/redux.selectors';
import { syncReportToDatabase, emailSend } from 'common/redux.sagas';

import { FORM_NAME } from '../../../Print/common';

export default function*({
    sampleReference,
    developmentName,
    mode,
    handleLoading
}) {
    try {
        const {
            mailData: {
                pdf,
                receivers: toAddress,
                cc: ccAddress,
                subject,
                body
            } = {},
            developmentNumber,
            specReportURL
        } = yield select(
            formValueSelector(FORM_NAME),
            'mailData',
            'developmentNumber',
            'specReportURL'
        );

        const [userEmail, userName, PDFBlobs, reportSizePaths] = yield all([
            select(userEmailSelector),
            select(userNameSelector),
            call(getAssetBlobsWorker, {
                assets: pdf,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'reportPath')
        ]);

        const [reportPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: PDFBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: reportSizePaths,
            handleLoading,
            developmentName
        });

        const reportPdfUrl = reportPayload ? reportPayload.sizeOriginal : '';

        const referenceDevNo =
            mode === 'amend'
                ? Number(developmentNumber) + 1
                : developmentNumber;

        const payload = {
            reportReference: sampleReference,
            reportUrl: reportPdfUrl,
            referenceDevNo
        };
        const absoluteURL = yield call(syncReportToDatabase, payload);

        const printReportFileName = yield call(getFileNameFromURL, absoluteURL);
        const shapeReportFileName = yield call(
            getFileNameFromURL,
            specReportURL
        );
        let composeBody = `
                ${body}
                <a style="color: #234c72;margin-right: 10px;font-size: 16px;" href="${absoluteURL}">${printReportFileName}</a>`;
        if (shapeReportFileName) {
            composeBody += ` <a style="color: #234c72;font-size: 16px;" href="${specReportURL}">${shapeReportFileName}</a>`;
        }

        const recipients = toAddress.replace(/\s/g, '');

        const updatedCcAddress = updateCcAddress(
            ccAddress,
            userEmail,
            recipients
        );

        const emailPayload = {
            userName,
            toAddress: recipients,
            ccAddress: updatedCcAddress,
            subject,
            body: composeBody,
            sampleRef: sampleReference
        };
        yield fork(emailSend, emailPayload);
    } catch (error) {
        console.log('error in pdf worker', error);
    }
}
