import { useEffect, useState } from 'react';

import getDataUrl from '@libs/getDataUrl';
import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDFContent from './PDFContent';

const replaceImageWith800 = image => {
    if (image) {
        return image.replace(
            'FabricDevelopment_350_350',
            'FabricDevelopment_800_800'
        );
    }
};

export const loadProps = async ({
    values: { originalFabricImage, washedImage, finalImage, ...restValues },
    ...restProps
}) => {
    const [
        originalFabricImageUrl,
        washedImageUrl,
        finalImageUrl
    ] = await Promise.all([
        originalFabricImage &&
            originalFabricImage.url &&
            getDataUrl(replaceImageWith800(originalFabricImage.url)),
        washedImage &&
            washedImage.url &&
            getDataUrl(replaceImageWith800(washedImage.url)),
        finalImage &&
            finalImage.url &&
            getDataUrl(replaceImageWith800(finalImage.url))
    ]);

    return {
        values: {
            originalFabricImage: originalFabricImageUrl,
            washedImage: washedImageUrl,
            finalImage: finalImageUrl,
            ...restValues
        },
        ...restProps
    };
};

export const useMakePDF = ({ props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleClick, loading, disabled, PDF, handleRemove] = usePDF({
        document: PDFContent,
        fileName: 'FabricDevelopment.pdf',
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 8000,
                action: {
                    label: 'Retry',
                    handle: handleClick
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleClick, PDF, handleRemove];
};
