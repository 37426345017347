import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import StepLabel from '@material-ui/core/StepLabel';

function getSteps() {
    return [
        'Select campaign settings',
        'Create an ad group',
        'Create an ad',
        'Do more stuff',
        'And even more!'
    ];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Step 1: Select campaign settings...';
        case 1:
            return 'Step 2: What is an ad group anyways?';
        case 2:
            return 'Step 3: This is the bit I really care about!';
        default:
            return 'Unknown step';
    }
}

class Stepper extends React.Component {
    state = {
        activeStep: 0,
        completed: []
    };

    totalSteps = () => {
        return getSteps().length;
    };

    completedSteps = () => {
        return Object.keys(this.state.completed).length;
    };

    isLastStep = () => {
        const { state, totalSteps } = this;
        return state.activeStep === totalSteps() - 1;
    };

    allStepsCompleted = () => {
        const { completedSteps, totalSteps } = this;
        return completedSteps() === totalSteps();
    };

    handleNext = () => {
        const {
            setActiveStep,
            isLastStep,
            allStepsCompleted,
            setCompleted,
            state
        } = this;
        const newCompleted = state.completed;
        newCompleted[state.activeStep] = true;
        setCompleted(newCompleted);

        const steps = getSteps();
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in state.completed))
                : state.activeStep + 1;
        setActiveStep(newActiveStep);
    };

    setActiveStep = activeStep => this.setState({ activeStep });

    handleBack = () => {
        this.setActiveStep(this.state.activeStep - 1);
    };

    handleStep = step => () => {
        this.setActiveStep(step);
    };

    setCompleted = completed => this.setState({ completed });

    handleComplete = () => {
        const { state, setCompleted, handleNext } = this;
        const newCompleted = state.completed;
        newCompleted[state.activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    handleReset = () => {
        this.setActiveStep(0);
        this.setCompleted({});
    };

    render() {
        const {
            state,
            props,
            allStepsCompleted,
            handleReset,
            handleBack,
            handleNext,
            handleComplete,
            handleStep,
            totalSteps,
            completedSteps
        } = this;

        const { activeStep, completed } = state;
        const { classes: c } = props;
        const steps = getSteps();

        return (
            <Fragment>
                <MuiStepper
                    className={c.root}
                    nonLinear
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {steps.map((label, index) => (
                        <Step key={label} className={c.visitedStep}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={completed[index]}
                            >
                                <StepLabel
                                    icon={index}
                                    classes={{
                                        labelContainer: c.stepLabelContainer
                                    }}
                                    StepIconProps={{
                                        classes: {
                                            root: c.stepRoot,
                                            active: c.stepActive,
                                            completed: c.stepCompleted
                                        }
                                    }}
                                    //className={classes.multiLabel}
                                    className={c.multiLabelVisited}
                                >
                                    {label}
                                </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </MuiStepper>
                <div>
                    {allStepsCompleted() ? (
                        <div>
                            <Typography
                                className={this.props.classes.instructions}
                            >
                                All steps completed - youre finished
                            </Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <div>
                            <Typography
                                className={this.props.classes.instructions}
                            >
                                {getStepContent(activeStep)}
                            </Typography>
                            <div>
                                <Button
                                    disabled={Number(activeStep) === 0}
                                    onClick={handleBack}
                                    className={this.props.classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleNext}
                                    className={this.props.classes.button}
                                >
                                    Next
                                </Button>
                                {Number(activeStep) !== steps.length &&
                                    (completed[activeStep] ? (
                                        <Typography
                                            variant='caption'
                                            className={
                                                this.props.classes.completed
                                            }
                                        >
                                            Step {activeStep + 1} already
                                            completed
                                        </Typography>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={handleComplete}
                                        >
                                            {completedSteps() ===
                                            totalSteps() - 1
                                                ? 'Finish'
                                                : 'Complete Step'}
                                        </Button>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

const _Stepper = withStyles(styles)(Stepper);

export { _Stepper as default, _Stepper as MultiStepper };
