import {
    SIGN_IN_REQUEST,
    SIGN_OUT,
    CHANGE_PASSWORD_REQUEST
} from 'actionTypes';

export const signIn = ({ userName, password }) => ({
    type: SIGN_IN_REQUEST,
    payload: { userName, password }
});

export const signOut = () => ({ type: SIGN_OUT });

export const requestPasswordChange = () => ({ type: CHANGE_PASSWORD_REQUEST });
