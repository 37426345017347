export const generateReferenceSchema = {
    designerFromId: 0,
    retailerId: 0,
    genderId: 0,
    ageId: 0,
    categoryId: 0,
    departmentId: 0,
    styleId: 0,
    countryId: 0,
    designDepartmentId: 0,
    shapeDesignerId: 0,
    copySampleRef: 'string',
    manualRef: 'string',
    userId: 0
};

export const publishSchema = {
    id: 0,
    userId: 0,
    categoryId: 0,
    departmentId: 0,
    genderId: 0,
    styleId: 0,
    ageId: 0,
    sampleRef: 'string',
    developmentNo: 0,
    countryId: 0,
    designDepartmentId: 0,
    isDiscontinued: true,
    requestTypeId: 0, //$
    requestedDate: '2021-01-07T13:50:32.775Z',
    sampleRequiredDate: '2021-01-07T13:50:32.775Z',
    ukSizeId: 0,
    buyerSizeId: 0,
    departmentContactPerson: 'string',
    sampleCategoryId: 0,
    seasonId: 0,
    fitId: 0,
    styleDescription: 'string',
    departmentNumber: 'string',
    specRefFrom: 'string',
    sizeRangeId: 0,
    necklineId: 0,
    waistBandId: 0,
    armholesCuffsId: 0,
    hemlineId: 0,
    labelTypeId: 0,
    specRef: 'string',
    garmentDescription: 'string',
    specComments: 'string',
    isSampleRequired: true,
    isPendingPrint: true,
    isHandover: true,
    frontImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    backImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    fabrics: [
        {
            id: 0,
            fabricTypeId: 'string',
            compositionId: 'string',
            gsmId: 'string',
            dyeTypeId: 'string',
            specialFabricRef: 'string',
            specialFinishId: 'string',
            fabricColour: 'string',
            eld: 'string',
            fabricForId: 'string',
            isDischargeable: true,
            isYds: true,
            isEngineeredStripe: true,
            sl: 0,
            fabFrom: 'string',
            istc: true,
            tcText: 'string'
        }
    ],
    boughts: [
        {
            id: 0,
            boughtRef: 'string',
            sl: 0
        }
    ],
    trims: [
        {
            id: 0,
            trimCardRef: 'string',
            colour: 'string',
            quantity: 'string'
        }
    ],
    attachments: [
        {
            id: 0,
            sizeOriginal: 'string'
        }
    ]
};
