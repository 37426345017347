import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';

const propTypes = {
    title: PropTypes.string.isRequired
};

const styles = ({ spacing }) => ({
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: 2
    }
});

const CommentContainer = withStyles(styles)(
    ({ classes: c, className, title, children }) => {
        return (
            <ContentContainer
                title={title}
                AdornmentComponent={<Fragment />}
                styles={{ container: clsx(c.contentContainer, className) }}
            >
                {children}
            </ContentContainer>
        );
    }
);

CommentContainer.propTypes = propTypes;

export default CommentContainer;
