import { useEffect, useState } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';
import getDataUrl from '@libs/getDataUrl';

import { Report } from '../../components/Report';

const loadProps = async ({ preview, versions, ...restProps }) => {
    const previewUrl = await getDataUrl(preview.url);
    const versionsUrls = await Promise.all(
        versions.map(async version => {
            const dataURL = await getDataUrl(version.url);
            return !!dataURL ? dataURL : undefined;
        })
    );

    return {
        preview: !!previewUrl ? previewUrl : undefined,
        versions: versionsUrls,
        ...restProps
    };
};

export const useReport = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document: Report,
        fileName,
        props,
        loadProps,
        errorResolver: error => setPDFError(error)
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleExport, exportLoading, exportDisabled];
};
