import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid, Typography, withStyles } from '@material-ui/core';

// Local
import Card from './Card';
import useBought from './useBought';
import LoaderCard from '@baseComponents/Card/LoaderCard';

const propTypes = {
    loading: PropTypes.bool.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    content: {
        padding: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    emptyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.unit * 10,
        marginBottom: spacing.unit * 3
    },
    emptyContent: {
        color: palette.secondary.light
    }
});

const Content = withStyles(styles)(({ classes: c, loading }) => {
    const [{ boughtDetails }, dispatch] = useBought();

    const makeHandleDelete = useCallback(
        index => () => {
            dispatch({ type: 'deleteBoughtDetail', payload: index });
        },
        []
    );

    return !!boughtDetails.length ? (
        <Grid container justify='flex-start' spacing={24} className={c.content}>
            {boughtDetails.map((bought, index) => (
                <Grid item md={3} key={index}>
                    <Card
                        {...bought}
                        title={`#${index + 1}`}
                        handleDelete={makeHandleDelete(index)}
                    />
                </Grid>
            ))}
            {loading && (
                <Grid item md={3}>
                    <LoaderCard />
                </Grid>
            )}
        </Grid>
    ) : (
        <div className={c.emptyContainer}>
            <Typography className={c.emptyContent}>No bought details added yet!</Typography>
        </div>
    );
});

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
