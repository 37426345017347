import { useState, useEffect, useMemo } from 'react';

import { useDimensionsObserver } from '@libHooks/useDimensions';

// Custom vibrant color palette with at least 20 different colors
const vibrantColors = [
    '#BEE1E6',
    '#FDE2E4',
    '#FFF1E6',
    '#EAE4E9',
    '#FAD2E1',
    '#E2ECE9',
    '#F0EFEB',
    '#DFE7FD',
    '#CDDAFD',
    '#7bdff2',
    '#b2f7ef',
    '#d6e2e9',
    '#bcd4e6',
    '#99c1de',
    '#abc4ff',
    '#adf7b6',
    '#e5d9f2',
    '#a594f9',
    '#b8b8ff',
    '#7371fc'
];

// Function to get color from the palette based on index
const getVibrantColor = index => vibrantColors[index % vibrantColors.length];

// Custom hook to generate vibrant colors dynamically based on data item index
const useDynamicColors = data => {
    return useMemo(() => {
        const colorMap = {};
        data.forEach((item, index) => {
            const bgColor = getVibrantColor(index);
            colorMap[item.name] = bgColor;
        });
        return colorMap;
    }, [data]);
};

const useResponsivePieChartSize = () => {
    const [ref, { width }] = useDimensionsObserver();
    const [screen, setScreen] = useState('sm');

    useEffect(() => {
        if (width > 1215) {
            setScreen('lg');
        } else if (width > 895) {
            setScreen('md');
        } else if (width < 895 && width > 575) {
            setScreen('sm');
        } else {
            setScreen('xs');
        }
    }, [width]);

    return [screen, ref];
};

export { useDynamicColors as default, useResponsivePieChartSize };
