import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Local
import SelectModel from '@formComponents/SelectModel';
import { DOMAIN_NAME } from '../common';
import { RETAILERS } from '../redux.datasets';
import { retailersSelector } from '../redux.selectors';

// Datasets
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'retailer',
    label: 'Retailer',
    placeholder: 'Select retailer',
    domain: DOMAIN_NAME,
    entity: RETAILERS,
    disabled: false,
    required: false
};

const mapState = (state, { domain, entity }) => ({
    data: retailersSelector(state),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectRetailers = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return <SelectModel {...props} />;
});

SelectRetailers.propTypes = propTypes;
SelectRetailers.defaultProps = defaultProps;

export { SelectRetailers as default, SelectRetailers };
