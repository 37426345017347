import React from 'react';
import { compose } from 'redux';

import { withStyles, withTheme, LinearProgress } from '@material-ui/core';

import { useTransition, useProgress } from './hooks';

const styles = ({ palette, spacing, transitions }) => ({
    root: {
        position: 'absolute',
        top: `calc(50% - ${spacing.unit * 0.25}px)`, // 50% - (height - 4px)

        width: '100%',
        height: spacing.unit * 0.75,

        backgroundColor: palette.background.dark,
        zIndex: 3
    },
    barColorPrimary: {
        backgroundColor: palette.success.main,
        backgroundImage: 'linear-gradient(90deg, var(--gradient))',

        transition: transitions.create(['transform'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.complex
        }),

        '&::before': {
            display: 'block',
            content: '""',
            width: '100%',
            height: '100%',

            backgroundColor: palette.success.main,
            backgroundImage: 'linear-gradient(90deg, var(--phantomGradient))',

            opacity: 'var(--phantomOpacity)',

            transition: transitions.create(['opacity'], {
                easing: transitions.easing.easeInOut
            }),
            transitionDuration: 'var(--phantomDuration)'
        }
    }
});

const Progress = compose(
    withStyles(styles),
    withTheme()
)(({ classes: c, theme }) => {
    return (
        <LinearProgress
            classes={c}
            variant='determinate'
            value={useProgress()}
            style={useTransition(theme)}
        />
    );
});

export { Progress as default };
