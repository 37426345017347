export default ({ unitHeight }) => ({ spacing, palette, shape }) => {
    const menuItemHeight = unitHeight / 2;

    return {
        control: {
            position: 'relative',
            width: '100%',
            minHeight: spacing.unitHeight,
            border: '1px solid',
            borderColor: palette.grey[300],
            borderRadius: shape.borderRadius,
            '&:hover': {
                borderColor: palette.grey[400]
            }
        },
        placeholder: {
            lineHeight: `${spacing.unit * 4.5}px`
        },

        dropdownRoot: {
            position: 'absolute',
            right: spacing.unit,
            top: spacing.unit * 0.75
        },
        modal: {
            width: 0
        },
        selectQuery: {
            color: palette.common.transparent
        },
        placeholderQuery: {
            display: 'none'
        },
        success: {
            borderColor: `#212121 !important`, // Focus override
            '&:hover': {
                borderColor: `#18191a !important` // Focus override
            }
        },
        error: {
            borderColor: `${palette.error.light} !important`, // Focus override
            '&:hover': {
                borderColor: `${palette.error.dark} !important` // Focus override
            }
        },
        disabled: {
            backgroundColor: palette.background.light,
            color: palette.secondary.main,
            '&:hover': {
                borderColor: palette.grey[300]
            }
        },
        container: {
            position: 'unset',
            width: '100%'
        },
        progress: {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: palette.secondary.light,
            height: spacing.unit * 0.5,
            marginTop: `${spacing.unit * -0.75}px !important`,
            borderBottomLeftRadius: spacing.unit * 0.25 + 1,
            borderBottomRightRadius: spacing.unit * 0.25 + 1
        },
        progressPrimary: {
            backgroundColor: palette.secondary.main,
            borderBottomLeftRadius: spacing.unit * 0.25 + 1,
            borderBottomRightRadius: spacing.unit * 0.25 + 1
        }
    };
};
