import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Libs
import Input from '@formComponents/Input';
import { required, email } from '@libs/validationRules';
import { userRoleIdSelector } from 'auth/redux.selectors';

import { ADMIN_ROLE_ID } from '../../common';

const DOMAIN_NAMES = ['echosourcing.com', 'echotex.com', 'echologyx.com'];

const emailDomainValidator = (value, roleId) => {
    const domainName = value.split('@')[1] || '';

    if (!DOMAIN_NAMES.includes(domainName) && roleId !== ADMIN_ROLE_ID) {
        return 'External domain not allowed, please contact admin';
    }
    return null;
};

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'email',
    label: 'Email',
    required: true
};

const mapState = state => ({
    roleId: userRoleIdSelector(state)
});

const UserEmail = connect(mapState)(({ roleId, ...props }) => {
    const validationRules = useMemo(
        () => [required, email, value => emailDomainValidator(value, roleId)],
        [roleId]
    );

    return <Input validate={validationRules} {...props} />;
});

UserEmail.propTypes = propTypes;
UserEmail.defaultProps = defaultProps;

export default UserEmail;
