export default ({
    fetchGridData = () => {},
    createUpdateRequest = () => {}
}) => {
    return (state, action) => {
        switch (action.type) {
            case 'set-filters': {
                const { filters, pageSize, search, category } = state;
                fetchGridData({
                    ...filters,
                    ...action.payload,
                    filter: search,
                    category,
                    pageSize
                });
                return action;
            }
            case 'query': {
                const { filters, search, category, pageSize } = state;
                fetchGridData({
                    ...filters,
                    pageSize,
                    filter: search,
                    category
                });

                return action;
            }
            case 'set-category-and-query': {
                const { filters, search, pageSize } = state;

                fetchGridData({
                    ...filters,
                    pageSize,
                    filter: search,
                    category: action.payload
                });

                return action;
            }
            case 'set-pageNumber': {
                const { filters, pageSize, search, category } = state;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                fetchGridData({
                    ...filters,
                    pageSize,
                    filter: search,
                    category,
                    pageNumber: action.payload
                });
                return action;
            }

            case 'set-pageSize': {
                const { filters, search, category } = state;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                fetchGridData({
                    ...filters,
                    filter: search,
                    category,
                    pageSize: action.payload
                });
                return action;
            }

            case 'update-grid-cell': {
                const { selectedItems: { originalIds } = {}, filters } = state;
                const { id, name, value, type } = action.payload;
                createUpdateRequest({
                    fieldName: name,
                    fieldValue: value,
                    id,
                    type,
                    selectedIds: originalIds,
                    filters
                });
                return action;
            }

            default: {
                return action;
            }
        }
    };
};
