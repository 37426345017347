const filterFabricAutoid = fabricAutoId => {
	let final = false;
	if (
		(typeof fabricAutoId === 'number' && Number(fabricAutoId) === 0) ||
		(typeof fabricAutoId === 'string' &&
			String(fabricAutoId).includes('duplicate'))
	) {
		final = true;
	}
	return final;
};

const checkForMainBodyFabric = (fabrics) => {

	return fabrics.some(({ fabricFor }) => fabricFor === 1)

	//
	// const fabricForMainExists = fabricsArr.filter(
	// 	fabric => fabric.fabricFor && Number(fabric.fabricFor) === 1
	// );
	//
	// //by default reset
	// let resetObj = { id: fabric.fabricFor.id, text: fabric.fabricFor.text };
	//
	// //reset fabricFor value
	// if (fabricForMainExists.length > 0) {
	// 	if (fabricFors.length > 0) {
	// 		//by default change fabric for to next fabric
	// 		const item = fabricFors.filter(
	// 			fabric => Number(fabric.value) !== 1
	// 		);
	//
	// 		if (item.length > 0)
	// 			resetObj = { id: item[0].value, text: item[0].label };
	// 	}
	// }
	//
	// return resetObj;
};

export { filterFabricAutoid, checkForMainBodyFabric };
