import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from 'sampling/common';
import { SEASONS } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: SEASONS,
    name: 'season',
    label: 'Season',
    placeholder: 'Select Season',
    disabled: false,
    required: false
};

const SelectSeasons = props => <Select {...props} />;

SelectSeasons.propTypes = propTypes;
SelectSeasons.defaultProps = defaultProps;

export { SelectSeasons as default, SelectSeasons };
