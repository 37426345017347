import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@libComponents/Table';
import { useFabricTableHeader } from './hooks';
import Content from './Content';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'fabrics'
};

const styles = ({ spacing }) => ({
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        marginBottom: 0
    },
    container: {
        padding: spacing.unit * 3
    }
});

const FabricsContainer = compose(
    withField(),
    withStyles(styles)
)(({ classes: c, input: { value = [] } }) => {
    const headers = useFabricTableHeader(value[0]);
    return (
        <ContentContainer
            title={`Fabric Table`}
            AdornmentComponent={<Fragment />}
            styles={{ container: c.contentContainer }}
        >
            <Grid container className={c.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) => (
                                <TableCell key={`fabric-table-header-${idx}`}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.map((content, idx) => (
                            <Content
                                key={`fabric-table-content-${idx}`}
                                content={content}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </ContentContainer>
    );
});

FabricsContainer.propTypes = propTypes;
FabricsContainer.defaultProps = defaultProps;

export { FabricsContainer as default, FabricsContainer };
