import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Material
import { withStyles, Paper, Popover } from '@material-ui/core';

// Local
import { composeClasses } from 'helpers';
import styles from './styles';
import MultiSelectDialogSpotlight from './MultiSelectDialogSpotlight';
import MultiSelectDialogCheckboxes from './MultiSelectDialogCheckboxes';

/*
 * Domain: --
 * Page: MultiSelectDialog
 * Component: MultiSelectDialog
 * Type: --
 * MultiSelectDialog
 */
class PaginatedDialog extends Component {
    state = {
        data: this.props.data || [],
        filteredData: this.props.data || [],
        dialogHeight: 0
    };

    handleSearchChange = value => {
        this.setState(() => {
            const filter = new RegExp(value, 'i');
            const filteredData = this.state.data.filter(opt => {
                return filter.test(opt.label);
            });
            return {
                filteredData
            };
        });
    };
    handleRef = ref => {
        try {
            if (!this.state.unitWidth) {
                const { offsetHeight } = ReactDOM.findDOMNode(ref);

                this.setState({
                    dialogHeight: offsetHeight
                });
            }
        } catch {
            this.setState({
                dialogWidth: 0,
                dialogHeight: 0
            });
        }
    };

    render() {
        const { props, handleSearchChange, state, handleRef } = this;
        const {
            classes,
            styles,
            hideDialog,
            anchor,
            dialogWidth,
            ...restProps
        } = props;
        const { filteredData, dialogHeight } = state;

        const c = composeClasses({ classes, styles });

        return (
            <Popover
                open={!!anchor}
                onClose={hideDialog}
                anchorEl={anchor}
                PaperProps={{
                    style: {
                        width: dialogWidth,
                        height: dialogHeight,
                        borderTopRightRadius: 'unset',
                        borderTopLeftRadius: 'unset'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Paper classes={c} ref={handleRef}>
                    <MultiSelectDialogSpotlight
                        handleFilter={handleSearchChange}
                    />
                    <MultiSelectDialogCheckboxes
                        {...restProps}
                        data={filteredData}
                    />
                </Paper>
            </Popover>
        );
    }
}

export default withStyles(styles)(PaginatedDialog);
