import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import { IconButton as MuiIconButton, withStyles } from '@material-ui/core';

const propTypes = {
    IconComponent: PropTypes.func.isRequired
};

const styles = ({ spacing, palette }) => ({
    root: {
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    button: {
        width: spacing.unit * 6,
        margin: spacing.unit,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,

        backgroundColor: palette.primary.contrastText,
        padding: spacing.unit * 0.5,
        borderRadius: spacing.unit * 0.5
    }
});

const IconButton = withStyles(styles)(
    ({ classes: c, className, IconComponent, ...props }) => (
        <MuiIconButton className={clsx(c.button, className)} {...props}>
            <IconComponent className={c.root} />
        </MuiIconButton>
    )
);

IconButton.propTypes = propTypes;

export { IconButton as default, IconButton };
