import React, { useMemo } from 'react';

import { FIT_OPTIONS } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const FitType = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Type', canSort: true, sortKey: 'fitType' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'FIT TYPE SETUP',
        domain: DOMAIN_NAME,
        entity: FIT_OPTIONS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { FitType as default, FitType };
