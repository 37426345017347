// Entities
import {
    REQUEST_TYPES,
    SEND_TO_LICENSORS,
    SEND_TO_BUYERS,
    SEND_TO_UKS
} from 'libs/datasets';

// Selectors
import {
    selectRequestTypesData,
    selectSendToBuyersData,
    selectSendToLicensorsData,
    selectSendToUksData
} from 'selectors';

// Normalizers
import {
    normalizeNestedData,
    normalizeSendToBuyers,
    normalizeSendToLicensors,
    normalizeSendToUks
} from 'normalizers';

// References
export default [
    {
        entity: REQUEST_TYPES,
        url: '/api/RequestTypes',
        selector: selectRequestTypesData,
        normalizer: normalizeNestedData
    },
    {
        entity: SEND_TO_BUYERS,
        url: '/api/SendtoBuyers',
        selector: selectSendToBuyersData,
        normalizer: normalizeSendToBuyers
    },
    {
        entity: SEND_TO_LICENSORS,
        url: '/api/SendtoLicences',
        selector: selectSendToLicensorsData,
        normalizer: normalizeSendToLicensors
    },
    {
        entity: SEND_TO_UKS,
        url: '/api/SendtoUkss',
        selector: selectSendToUksData,
        normalizer: normalizeSendToUks
    }
];
