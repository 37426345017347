import { change, formValueSelector } from 'redux-form';
import { put, select, all, call } from 'redux-saga/effects';

// Libs
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import {
    composeLoadingWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker
} from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { publishFailedWorker } from '@libs/publishWorkers';
import { normalizeImagePayload } from 'sampling/redux.normalizers';

import getSealSampleReferenceWorker from './getSealSampleReferenceWorker';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import { FORM_NAME } from '../../../SealSample/common';
import { publishSchema } from './schemas';
import savedDraftWorker from './savedDraftWorker';
import MailSendWorker from './MailSendWorker';

export default function*(action) {
    const {
        meta: {
            handleComplete = () => {},
            saveDraft = false,
            handover = false
        },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {},
            hasEmail = false
        }
    } = action;

    const {
        frontImage,
        backImage,
        attachments = [],
        qcVersion = 1
    } = yield select(
        formValueSelector(FORM_NAME),
        'frontImage',
        'backImage',
        'attachments',
        'qcVersion'
    );

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [...attachments],
        mode
    });

    try {
        const [
            { qcReference, id },
            frontBlobs,
            backBlobs,
            attachmentBlobs,
            frontSizePaths,
            backSizePaths,
            attachmentSizePaths
        ] = yield all([
            call(getSealSampleReferenceWorker, { mode, handleLoading }),
            call(getAssetBlobsWorker, {
                assets: frontImage,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: backImage,
                mode,
                handleLoading
            }),

            call(getAssetBlobsWorker, {
                assets: attachments,
                mode,
                handleLoading
            }),

            select(sizePathsSelector, 'qcPreSealSampleFront'),
            select(sizePathsSelector, 'qcPreSealSampleBack'),
            select(sizePathsSelector, 'qcPreSealSampleAttachment')
        ]);

        const qcVersionNo =
            mode === 'amend' ? Number(qcVersion) + 1 : qcVersion;

        const [frontPayload = {}] = yield call(uploadAssetsWorker, {
            assetBlobs: frontBlobs,
            folderPath: `${frontSizePaths.sizeOriginal}/${qcReference}`,
            reference: qcReference,
            sizePaths: frontSizePaths,
            handleLoading,
            prefix: 'Front',
            developmentName: qcVersionNo
        });

        const [backPayload = {}] = yield call(uploadAssetsWorker, {
            assetBlobs: backBlobs,
            folderPath: `${backSizePaths.sizeOriginal}/${qcReference}`,
            reference: qcReference,
            sizePaths: backSizePaths,
            handleLoading,
            prefix: 'Back',
            developmentName: qcVersionNo
        });

        const attachmentsPayload = yield call(uploadAssetsWorker, {
            assetBlobs: attachmentBlobs,
            folderPath: `${attachmentSizePaths.sizeOriginal}/${qcReference}`,
            reference: qcReference,
            sizePaths: attachmentSizePaths,
            handleLoading,
            developmentName: qcVersionNo,
            enableSerial: true
        });

        const payload = yield call(getPublishPayloadWorker, {
            id,
            qcReference
        });

        const publishData = composeApiPayload(
            {
                ...payload,
                qcVersion: qcVersionNo,
                isDiscontinued: !saveDraft,
                frontImage: normalizeImagePayload(frontPayload),
                backImage: normalizeImagePayload(backPayload),
                attachments: attachmentsPayload
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'QcPreSealSamples/PublishQcPreSealSample',
            data: publishData
        });

        /*
           --- After publish
        */

        if (mode === 'new') {
            yield put(change(FORM_NAME, 'qcReference', qcReference));
            yield put(change(FORM_NAME, 'id', id));
        }

        if (hasEmail) {
            yield call(MailSendWorker, {
                qcReference,
                qcVersionNo,
                mode,
                hasEmail,
                handleLoading
            });
        }

        if (saveDraft) {
            yield call(savedDraftWorker, {
                mode,
                qcReference,
                handover
            });
        }
        closeMailModal();
        closePreviewModal();
        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
