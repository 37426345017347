import React from 'react';
import clsx from 'classnames';

import { Grid } from '@material-ui/core';
import { withStylesAndRef } from '../@libs/materialUI';

const styles = ({ spacing }) => ({
    root: {
        width: '64%',
        margin: 'auto'
    },
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 4
    }
});

const GridContainer = withStylesAndRef(styles)(
    ({ classes: c, className, forwardRef, ...props }) => (
        <div className={c.root} ref={forwardRef}>
            <Grid
                container
                className={clsx(c.container, className)}
                {...props}
            />
        </div>
    )
);

export { GridContainer as default, GridContainer };
