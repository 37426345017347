import { setCookie } from '@libs/cookies';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'auth/constant';
function parseJwt(token) {
    try {
        if (token) {
            const [, payload] = token.split('.');

            const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(decodeURIComponent(atob(base64Payload)));
        }
    } catch (err) {
        console.log(`JWT Parsing error: ${err}`);
    }
}

function createTokenExpireTime(expire) {
    try {
        if (expire) {
            const tokenExpireTime = Math.floor(
                (new Date(expire * 1000).getTime() - new Date().getTime()) /
                    1000
            );
            return tokenExpireTime;
        }
    } catch (err) {
        console.log(`unix_timestamp error: ${err}`);
    }
}

export default function handleJWT({ accessToken, refreshToken }) {
    const { exp: accessTokenExpire, iss, aud, id, ...userData } = parseJwt(
        accessToken
    );
    const { exp: refreshTokenExpire } = parseJwt(refreshToken);

    const accessTokenExpiryTime = createTokenExpireTime(accessTokenExpire);
    const refreshTokenExpiryTime = createTokenExpireTime(refreshTokenExpire);

    setCookie(ACCESS_TOKEN_KEY, accessToken, accessTokenExpiryTime);
    setCookie(REFRESH_TOKEN_KEY, refreshToken, refreshTokenExpiryTime);

    return {
        ...userData,
        userId: id
    };
}
