import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// Libs
import {
    GridContainer,
    GridItem,
    Input,
    Select,
    PaginatedSelect
} from '@formComponents';
import { fetchDatasets } from '@libs/datasets';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import { BOUGHT_REFERENCES } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import { DEVELOPMENT_TYPE_SENT } from '../../../redux.datasets';
import { DOMAIN_NAME } from '../../common';
import { fabricDevelopmentReferenceLoadingSelector } from '../../../redux.selectors';

const propTypes = {
    formName: PropTypes.string.isRequired,
    shouldLocked: PropTypes.bool.isRequired
};

const mapState = (state, { formName }) => {
    const versionNo = formValueSelector(formName)(state, 'versionNo');

    return {
        version: versionNo ? `V${versionNo}` : null,
        fabricDevelopmentReferenceLoading: fabricDevelopmentReferenceLoadingSelector(
            state
        )
    };
};

const mapDispatch = {
    fetchDatasets
};

const FabricReferenceContainer = connect(
    mapState,
    mapDispatch
)(
    ({
        version,
        fabricDevelopmentReferenceLoading,
        shouldLocked,
        fetchDatasets
    }) => {
        useEffect(() => {
            fetchDatasets(DEVELOPMENT_TYPES);
        }, []);
        return (
            <GridContainer>
                <GridItem md={4}>
                    <Input
                        name='fabricReference'
                        label='FD Reference'
                        disabled
                        loading={fabricDevelopmentReferenceLoading}
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='versionNo'
                        label='Version No'
                        staticValue={version}
                        disabled
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='developmentType'
                        label='Development Type Sent'
                        placeholder='Select Development Type Sent'
                        disabled={shouldLocked}
                        domain={DOMAIN_NAME}
                        entity={DEVELOPMENT_TYPE_SENT}
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <PaginatedSelect
                        name='boughtReference'
                        label='Bought Reference'
                        placeholder='Select Bought Ref'
                        domain={SAMPLING_DOMAIN_NAME}
                        entity={BOUGHT_REFERENCES}
                        disabled={shouldLocked}
                    />
                </GridItem>
            </GridContainer>
        );
    }
);

FabricReferenceContainer.propTypes = propTypes;

export default FabricReferenceContainer;
