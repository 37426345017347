import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid, Typography, withStyles } from '@material-ui/core';

// Local
import Card from './Card';
import LoaderCard from '@baseComponents/Card/LoaderCard';
import useFabrics from './useFabrics';

import { Error } from 'components/Message';

const propTypes = {
    handleModal: PropTypes.func,
    loading: PropTypes.bool.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    content: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2
    },
    emptyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.unit * 10
    },
    emptyContent: {
        color: palette.secondary.light
    }
});

const Content = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { spacing },
        handleModal,
        loading,
        validationMsg
    }) => {
        const [{ fabrics, includeTc }, dispatch] = useFabrics();

        const makeHandleDelete = useCallback(
            index => () => {
                dispatch({ type: 'deleteFabric', payload: index });
            },
            []
        );

        const handleEdit = useCallback(
            index => values => {
                dispatch({ type: 'editFabric', payload: { index, values } });
            },
            []
        );

        const makeHandleEdit = useCallback(
            index => () =>
                handleModal({
                    handleSave: handleEdit(index),
                    initialValues: fabrics[index]
                }),
            [handleModal, fabrics]
        );

        const makeHandleDuplicate = useCallback(
            index => () => {
                dispatch({ type: 'duplicateFabric', payload: index });
            },
            []
        );

        return (
            <>
                {validationMsg && (
                    <Error
                        message={validationMsg}
                        styles={{ textAlign: 'center' }}
                    />
                )}
                {!!fabrics.length ? (
                    <Grid
                        container
                        justify='flex-start'
                        spacing={24}
                        className={c.content}
                    >
                        {fabrics.map((fabric, index) => (
                            <Grid item md={3} key={index}>
                                <Card
                                    {...fabric}
                                    handleDuplicate={makeHandleDuplicate(index)}
                                    handleEdit={makeHandleEdit(index)}
                                    handleDelete={makeHandleDelete(index)}
                                    includeTc={includeTc}
                                />
                            </Grid>
                        ))}
                        {loading && (
                            <Grid item md={3}>
                                <LoaderCard />
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <div className={c.emptyContainer}>
                        <Typography className={c.emptyContent}>
                            No fabrics added yet!
                        </Typography>
                    </div>
                )}
            </>
        );
    }
);

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
