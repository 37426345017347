import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    isSubmitting,
    initialize as formInitialize
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';
import { useValidate, useNext } from '@libHooks/useValidate';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { formErrorsSelector } from 'common/redux.selectors';

import useSchema from './content/useSchema';
import Preview from './content/Preview';
import { FabricDevelopmentProvider } from './useFabricDevelopment';
import { useHandles, useContentFields } from './hooks';
import { FORM_NAME, INITIAL_VALUES } from './common';
import {
    fabricDevelopmentReferenceGenerate,
    fabricDevelopmentSaveDraft,
    fabricDevelopmentPublish,
    destroyFabricDevelopmentForm
} from '../redux.actions';
import { fabricDevelopmentReferenceLoadingSelector } from '../redux.selectors';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => ({
    formValues: getFormValues(FORM_NAME)(state),
    formErrors: formErrorsSelector(state, FORM_NAME),
    formSubmitting: isSubmitting(FORM_NAME)(state),
    fabricDevelopmentReferenceLoading: fabricDevelopmentReferenceLoadingSelector(
        state
    )
});

const mapDispatch = {
    saveDraft: fabricDevelopmentSaveDraft,
    publish: fabricDevelopmentPublish,
    generateReference: fabricDevelopmentReferenceGenerate,
    formInitialize,
    destroyForm: destroyFabricDevelopmentForm
};

const FabricDevelopmentContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        fabricDevelopmentReferenceLoading,
        saveDraft,
        publish,
        generateReference,
        formInitialize,
        destroyForm,
        mode,
        handleReduxFormSubmit,
        handleModal
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const formFields = useContentFields(mode, activeStep);

        const [fabricDevelopmentFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            formFields,
            FORM_NAME
        );

        const handleFabricDevelopmentFormSubmit = useCallback(() => {
            handleReduxFormSubmit();
            handleScroll();
        }, [handleReduxFormSubmit, handleScroll]);

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const { fabricReference } = formValues;

        const schema = useSchema(mode, formValues);
        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);

        const canResetForm = useMemo(() => {
            return !!Object.keys(formValues).length;
        }, [formValues]);

        const shouldFetchData = mode !== 'new' && activeStep === 0;

        const shouldSave = useMemo(() => {
            // Don't show "Save Draft" button in 'amend' mode
            if (mode === 'amend' || mode === 'developedFabric') {
                return false;
            }

            // Show "Save Draft" button for all steps except 0 when mode is 'new'
            return !(mode === 'new' && activeStep === 0);
        }, [mode, activeStep]);

        const shouldLocked = mode === 'developedFabric';

        const shouldPreview = useMemo(() => {
            return activeStep === contents.length - 2;
        }, [activeStep, contents.length]);

        const [handleNext, handlePublish, setupLocked, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish,
            fabricReference,
            generateReference
        });

        const handleDraft = useCallback(() => saveDraft({ mode }), [
            mode,
            saveDraft
        ]);

        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleFabricDevelopmentFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep, handleFabricDevelopmentFormSubmit]);

        const handlePreview = useCallback(
            props => {
                if (!canProceed) {
                    handleFabricDevelopmentFormSubmit();
                    return;
                }
                handleModal({ ...props, handlePublish, mode });
            },
            [handleModal, canProceed, handleFabricDevelopmentFormSubmit]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                mode,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                handlePublish,
                fabricDevelopmentReferenceLoading,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleDraft,
                reset,
                shouldPreview,
                handlePreview,
                canResetForm,
                shouldLocked
            }),
            [
                mode,
                areValid.saveDraft,
                areValid.publish,
                fabricDevelopmentReferenceLoading,
                handlePublish,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleDraft,
                reset,
                shouldPreview,
                handlePreview,
                canResetForm,
                shouldLocked
            ]
        );

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => destroyForm();
        }, [mode]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        return (
            <Paper className={c.container}>
                <FabricDevelopmentProvider initialize={initialize}>
                    <div ref={fabricDevelopmentFormRef}>{children}</div>
                </FabricDevelopmentProvider>
                {!complete &&
                    (shouldPreview ? (
                        <Button className={c.button} onClick={handlePreview}>
                            Preview
                        </Button>
                    ) : (
                        <Button
                            className={c.button}
                            onClick={handleNextWithSubmit}
                            loading={
                                formSubmitting ||
                                fabricDevelopmentReferenceLoading
                            }
                        >
                            {proceedText}
                        </Button>
                    ))}
            </Paper>
        );
    }
);

export default FabricDevelopmentContainer;
