export const BOUGHT_BYS = `@boughtBys`;
export const BOUGHT_LOCATIONS = `@boughtLocations`;

export default {
    [BOUGHT_BYS]: {
        url: 'BoughtBy'
    },
    [BOUGHT_LOCATIONS]: {
        url: 'BoughtLocation'
    }
};
