import {
    ROUTE_TO_PRINT,
    SET_VERSIONS,
    SET_PRINT_RETAILER_CHILDREN,
    SET_SHAPE_RETAILER_CHILDREN,
    SET_SAMPLE_FIT_OPTIONS_CHILDREN,
    SET_SPEC_REFERENCE_CHILDREN,
    GET_COPY_PRINT_DATA,
    GET_BOUGHT_DETAIL_DATA,
    GET_TRIM_DETAIL_DATA,
    SET_FABRICS_LOADING,
    SET_BOUGHT_DETAILS_LOADING,
    SET_TRIM_DETAILS_LOADING,
    DESTROY_PRINT_FORM,
    DESTROY_SHAPE_FORM,
    PRINT_PUBLISH,
    SHAPE_PUBLISH,
    GET_INCOMPLETE_PRINT_DATA,
    GET_AMEND_PRINT_DATA,
    GET_INCOMPLETE_SHAPE_DATA,
    GET_AMEND_SHAPE_DATA,
    GET_COPY_SHAPE_DATA,
    ROUTE_TO_SHAPE,
    SET_SPEC_REFERENCE_AND_DESCRIPTION,
    SHAPE_REFERENCE_GENERATE,
    SET_SAMPLE_REFERENCE_GENERATE_LOADING,
    GET_DEVELOPED_FABRIC_DATA,
    SET_SAMPLE_EMAIL_RECEIVERS,
    SET_SAMPLE_EMAIL_BODY,
    SAMPLE_REPORT_MAIL_SEND,
    PRINT_REFERENCE_GENERATE,
    SET_FABRIC_OPTION_CHILDREN,
    ROUTE_TO_NEW_ORDER,
    DOWNLOAD_SAMPLES_AS_EXCEL,
    SET_DOWNLOAD_SAMPLES_AS_EXCEL_LOADING,
    DOWNLOAD_SAMPLES_AS_MULTI_PAGE,
    SET_DOWNLOAD_SAMPLES_AS_MULTI_PAGE_LOADING,
    DOWNLOAD_SAMPLES_AS_SINGLE_PAGE,
    SET_DOWNLOAD_SAMPLES_AS_SINGLE_PAGE_LOADING,
    DOWNLOAD_SAMPLES_AS_COST_FORM,
    SET_DOWNLOAD_SAMPLES_AS_COST_FORM_LOADING,
    PUBLISH_FINAL_PHOTO,
    ROUTE_TO_FINAL_PHOTO_UPLOAD,
    DESTROY_FINAL_PHOTO_FORM,
    SEND_FINAL_PHOTO_EMAIL,
    SET_FINAL_PHOTO_EMAIL_LOADING,
    SET_FINAL_PHOTO_DATA_LOADING,
    DISPATCH_REFERENCE_GENERATE,
    SET_DISPATCH_REFERENCE_GENERATE_LOADING,
    GET_SAMPLE_DISPATCH_DATA,
    DISPATCH_PUBLISH,
    ROUTE_TO_DISPATCH,
    DISPATCH_REPORT_MAIL_SEND,
    SET_DISPATCH_EXPORT_EXCEL_DATA_LOADING,
    FETCH_SAMPLING_REPORT_DATA,
    SET_SAMPLING_REPORT_DATA,
    SET_SAMPLING_REPORT_DATA_LOADING,
    STORE_SAMPLING_REPORT_CHART_VIEW_SETTINGS
} from './redux.actionTypes';

export const routeToPrint = mode => ({
    type: ROUTE_TO_PRINT,
    payload: { mode }
});

export const routeToShape = mode => ({
    type: ROUTE_TO_SHAPE,
    payload: { mode }
});

export const setVersions = ({ images = [], meta = 'succeeded' }) => ({
    type: SET_VERSIONS,
    payload: images,
    meta
});

export const setPrintRetailerChildren = () => ({
    type: SET_PRINT_RETAILER_CHILDREN
});

export const setShapeRetailerChildren = () => ({
    type: SET_SHAPE_RETAILER_CHILDREN
});

export const setSpecReferenceChildren = specReference => ({
    type: SET_SPEC_REFERENCE_CHILDREN,
    payload: { specReference }
});

export const setSampleFitOptionsChildren = (payload, formName) => ({
    type: SET_SAMPLE_FIT_OPTIONS_CHILDREN,
    payload,
    meta: { formName }
});

export const getBoughtDetailData = ({ boughtReference, formName }) => ({
    type: GET_BOUGHT_DETAIL_DATA,
    payload: { boughtReference, formName }
});

export const getTrimDetailData = trimReference => ({
    type: GET_TRIM_DETAIL_DATA,
    payload: trimReference
});

export const setFabricsLoading = loading => ({
    type: SET_FABRICS_LOADING,
    payload: loading
});

export const setBoughtDetailsLoading = loading => ({
    type: SET_BOUGHT_DETAILS_LOADING,
    payload: loading
});

export const setTrimDetailsLoading = loading => ({
    type: SET_TRIM_DETAILS_LOADING,
    payload: loading
});

export const destroyPrintForm = () => ({
    type: DESTROY_PRINT_FORM
});

export const destroyShapeForm = () => ({
    type: DESTROY_SHAPE_FORM
});

export const destroyFinalPhotoForm = () => ({
    type: DESTROY_FINAL_PHOTO_FORM
});

export const getIncompletePrintData = reference => ({
    type: GET_INCOMPLETE_PRINT_DATA,
    payload: reference
});

export const getAmendPrintData = reference => ({
    type: GET_AMEND_PRINT_DATA,
    payload: reference
});

export const getIncompleteShapeData = reference => ({
    type: GET_INCOMPLETE_SHAPE_DATA,
    payload: reference
});

export const getAmendShapeData = reference => ({
    type: GET_AMEND_SHAPE_DATA,
    payload: reference
});

export const getCopyPrintData = sampleReference => ({
    type: GET_COPY_PRINT_DATA,
    payload: sampleReference
});

export const getCopyShapeData = sampleReference => ({
    type: GET_COPY_SHAPE_DATA,
    payload: sampleReference
});

export const printPublish = ({ handleComplete, ...payload }) => ({
    type: PRINT_PUBLISH,
    meta: { handleComplete },
    payload
});

export const printSaveDraft = ({ mode }) => ({
    type: PRINT_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});

export const printHandover = ({ handleComplete, ...payload }) => ({
    type: PRINT_PUBLISH,
    meta: { saveDraft: true, handover: true, handleComplete },
    payload
});

export const shapePublish = ({ handleComplete, ...payload }) => ({
    type: SHAPE_PUBLISH,
    meta: { handleComplete },
    payload
});

export const shapeSaveDraft = ({ mode }) => ({
    type: SHAPE_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});
export const shapeHandover = ({ handleComplete, ...payload }) => ({
    type: SHAPE_PUBLISH,
    meta: { saveDraft: true, handover: true, handleComplete },
    payload
});
export const setSampleEmailReceivers = groupId => ({
    type: SET_SAMPLE_EMAIL_RECEIVERS,
    payload: groupId
});

export const setSampleEmailBody = templateId => ({
    type: SET_SAMPLE_EMAIL_BODY,
    payload: templateId
});

export const setSpecReferenceAndDescription = requestType => ({
    type: SET_SPEC_REFERENCE_AND_DESCRIPTION,
    payload: { requestType }
});

export const shapeReferenceGenerate = () => ({
    type: SHAPE_REFERENCE_GENERATE,
    meta: { loader: true }
});

export const printReferenceGenerate = () => ({
    type: PRINT_REFERENCE_GENERATE,
    meta: { loader: true }
});

export const setSampleReferenceLoading = value => ({
    type: SET_SAMPLE_REFERENCE_GENERATE_LOADING,
    payload: value
});

export const getDevelopedFabricData = ({ reference, formName }) => ({
    type: GET_DEVELOPED_FABRIC_DATA,
    payload: { reference, formName }
});

export const samplingReportMailSend = ({
    sampleReference,
    developmentNumber,
    developmentName,
    closeModals
}) => ({
    type: SAMPLE_REPORT_MAIL_SEND,
    meta: { closeModals },
    payload: { sampleReference, developmentNumber, developmentName }
});

export const setFabricOptionChildren = ({ formName, hasFabricOption }) => ({
    type: SET_FABRIC_OPTION_CHILDREN,
    meta: { formName },
    payload: !!hasFabricOption
});

export const routeToNewOrder = formName => ({
    type: ROUTE_TO_NEW_ORDER,
    payload: { formName }
});

export const downloadSamplesAsExcel = payload => ({
    type: DOWNLOAD_SAMPLES_AS_EXCEL,
    payload
});

export const setDownloadSamplesAsExcelLoading = loading => ({
    type: SET_DOWNLOAD_SAMPLES_AS_EXCEL_LOADING,
    payload: loading
});

export const downloadSamplesAsMultiPage = payload => ({
    type: DOWNLOAD_SAMPLES_AS_MULTI_PAGE,
    payload
});

export const setDownloadSamplesAsMultiPageLoading = loading => ({
    type: SET_DOWNLOAD_SAMPLES_AS_MULTI_PAGE_LOADING,
    payload: loading
});

export const downloadSamplesAsSinglePage = payload => ({
    type: DOWNLOAD_SAMPLES_AS_SINGLE_PAGE,
    payload
});

export const setDownloadSamplesAsSinglePageLoading = loading => ({
    type: SET_DOWNLOAD_SAMPLES_AS_SINGLE_PAGE_LOADING,
    payload: loading
});

export const downloadSamplesAsCostForm = payload => ({
    type: DOWNLOAD_SAMPLES_AS_COST_FORM,
    payload
});

export const setDownloadSamplesAsCostFormLoading = loading => ({
    type: SET_DOWNLOAD_SAMPLES_AS_COST_FORM_LOADING,
    payload: loading
});

export const publishFinalPhoto = ({ handleComplete }) => ({
    type: PUBLISH_FINAL_PHOTO,
    meta: { handleComplete }
});

export const routeToFinalPhotoUpload = () => ({
    type: ROUTE_TO_FINAL_PHOTO_UPLOAD
});

export const sendFinalPhotoEmail = ({ PDF, handleRemoveBlobURL }) => ({
    type: SEND_FINAL_PHOTO_EMAIL,
    payload: PDF,
    meta: { handleRemoveBlobURL }
});

export const setFinalPhotoEmailLoading = loading => ({
    type: SET_FINAL_PHOTO_EMAIL_LOADING,
    payload: loading
});
export const setFinalPhotoDataLoading = loading => ({
    type: SET_FINAL_PHOTO_DATA_LOADING,
    payload: loading
});
/* Dispatch actions */
export const dispatchReferenceGenerate = () => ({
    type: DISPATCH_REFERENCE_GENERATE
});
export const setDispatchReferenceLoading = loading => ({
    type: SET_DISPATCH_REFERENCE_GENERATE_LOADING,
    payload: loading
});

export const getSampleDispatchData = reference => ({
    type: GET_SAMPLE_DISPATCH_DATA,
    payload: reference
});
export const dispatchPublish = ({ handleComplete, ...payload }) => ({
    type: DISPATCH_PUBLISH,
    meta: { handleComplete },
    payload
});

export const dispatchSaveDraft = ({ mode }) => ({
    type: DISPATCH_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});
export const routeToDispatch = mode => ({
    type: ROUTE_TO_DISPATCH,
    payload: { mode }
});
export const dispatchReportMailSend = ({
    dispatchRef,
    versionNo,
    closeModals
}) => ({
    type: DISPATCH_REPORT_MAIL_SEND,
    meta: { closeModals },
    payload: { dispatchRef, versionNo }
});
export const setDispatchExportExcelLoading = loading => ({
    type: SET_DISPATCH_EXPORT_EXCEL_DATA_LOADING,
    payload: loading
});

export const fetchSamplingReportData = (payload = {}) => ({
    type: FETCH_SAMPLING_REPORT_DATA,
    payload
});

export const setSamplingReportDataLoading = loading => ({
    type: SET_SAMPLING_REPORT_DATA_LOADING,
    payload: loading
});

export const setSamplingReportData = data => ({
    type: SET_SAMPLING_REPORT_DATA,
    payload: data
});
