import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { destroy } from 'redux-form';

// Local
import SamplingPrint from 'views/Sampling/Container/homePage';
import { selectPageType } from 'selectors';

// Actions
import { resetSamplingData, setSamplingPageType } from 'actions';

class SamplingPrintNew extends PureComponent {
    componentDidMount() {
        this.props.setSamplingPageType('new');
    }

    componentWillUnmount() {
        const { destroy, resetSamplingData } = this.props;
        destroy('SamplingRequestForm');
        destroy('SamplingPrintForm');
        resetSamplingData();
    }

    render() {
        return (
            this.props.isNewePage && (
                <SamplingPrint
                    newPage
                    title='Print Sample Request'
                    parent='print'
                />
            )
        );
    }
}

const mapState = state => ({
    isNewePage: selectPageType(state, 'new')
});

const mapDispatch = { destroy, resetSamplingData, setSamplingPageType };

const _SamplingPrintNew = compose(
    connect(
        mapState,
        mapDispatch
    )
)(SamplingPrintNew);

export { _SamplingPrintNew as default, _SamplingPrintNew as SamplingPrintNew };
