import React from 'react';
import PropTypes from 'prop-types';

import { Date as DateInput } from '@formComponents';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'actualSendDate',
    label: 'Actual Send Date',
    disabled: false,
    required: true
};

const ActualSendDate = props => {
    return <DateInput {...props} />;
};

ActualSendDate.propTypes = propTypes;
ActualSendDate.defaultProps = defaultProps;

export default ActualSendDate;
