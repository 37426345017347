import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';

import FinanceCard from './FinanceCard';

const styles = ({ spacing }) => ({
    container: {
        width: `calc(100% - ${spacing.unit * 4}px)`,
        marginTop: spacing.unit * 2.5,
        borderBottomWidth: 0
    }
});

const propTypes = {
    shippedQuantityReport: array.isRequired
};

const ShippedReport = withStyles(styles)(
    ({ classes: c, shippedQuantityReport }) => {
        return (
            <ContentContainer
                title='Shipped Quantity Report'
                styles={{ container: c.container }}
            >
                <FinanceCard data={shippedQuantityReport} />
            </ContentContainer>
        );
    }
);

ShippedReport.propTypes = propTypes;

export { ShippedReport as default, ShippedReport };
