import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

// Local
import { composeClasses } from 'helpers';

const styles = ({ palette }) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 1
    },
    loader: {
        position: 'relative',
        color: palette.primary.main
    }
});

const InputLoader = withStyles(styles)(
    ({
        classes,
        styles,
        offset,
        rootHeight,
        rootPadding,
        size,
        ...restProps
    }) => {
        const c = composeClasses({ classes, styles });

        const loaderOffset = 16;
        const loaderSize = rootHeight ? rootHeight - loaderOffset : size;
        const paddingBottom = rootPadding + loaderOffset / 2;
        return (
            <div className={c.root} style={{ paddingBottom }}>
                <CircularProgress
                    className={c.loader}
                    size={loaderSize}
                    {...restProps}
                />
            </div>
        );
    }
);

InputLoader.propTypes = {
    classes: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    rootHeight: PropTypes.number.isRequired,
    rootPadding: PropTypes.number,
    offset: PropTypes.number,
    size: PropTypes.number
};

InputLoader.defaultProps = {
    rootPadding: 0,
    offset: 16,
    size: 48
};

export { InputLoader as default, InputLoader };
