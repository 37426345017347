import React from 'react';
import { string } from 'prop-types';
import clsx from 'classnames';

import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = ({ palette, spacing }) => ({
    root: {
        marginBottom: spacing.unit
    },
    content: {
        width: `100%`,
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gridTemplateRows: 'repeat(5,auto)',
        gridAutoFlow: 'row',

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark
    },
    contentRow: {
        display: 'flex',

        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,

        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark
    },
    contentRowHeader: {
        fontSize: spacing.unit * 1.75,
        flex: `${spacing.unit * 16}px 0 0`,
        padding: spacing.unit * 0.75,

        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,

        wordBreak: 'break-word'
    },
    contentRowValue: {
        width: '100%',
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.light,
        padding: spacing.unit * 0.75,

        wordBreak: 'break-word'
    },
    noRightBorder: {
        borderRightWidth: 0
    },
    noBottomBorder: {
        borderBottomWidth: 0
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        borderLeftWidth: 0
    },
    image: {
        width: spacing.unit * 25,
        height: spacing.unit * 25,
        objectFit: 'contain'
    }
});

const propTypes = {
    cadRef: string,
    designer: string,
    description: string,
    type: string,
    developmentNo: string,
    licensor: string,
    property: string,
    sleeve: string,
    designedBy: string,
    shapeDesigner: string,
    retailerId: string,
    department: string,
    buyerReference: string,
    category: string,
    style: string,
    gender: string,
    age: string,
    image: string,
    version: string
};

const cadHeaders = [
    { name: 'cadRef', header: 'CAD Ref' },
    { name: 'type', header: 'CAD Type' },
    { name: 'licensor', header: 'Licensor' },
    { name: 'property', header: 'Property' },
    { name: 'designer', header: 'Processed By' },
    { name: 'retailerId', header: 'Retailer' },
    { name: 'gender', header: 'Gender' },
    { name: 'age', header: 'Age' },
    { name: 'category', header: 'Category' },
    { name: 'department', header: 'Department' },
    { name: 'style', header: 'Style' },
    { name: 'description', header: 'Description' },
    { name: 'artworkSource', header: 'Artwork Source' },
    { name: 'buyerReference', header: 'Buyer Ref' },
    { name: 'shapeDesigner', header: 'Shape Designer' },
    { name: 'developmentNo', header: 'Development Version' },
    { name: 'version', header: 'Image Version' }
];

const CadTable = withStyles(styles)(({ classes: c, image, ...props }) => {
    const numColumns = 3;
    const itemsInLastRow = cadHeaders.length % numColumns;

    return (
        <Grid container className={c.root}>
            <Grid item md={9} className={c.content}>
                {cadHeaders.map(({ name, header }, index) => (
                    <div
                        className={clsx(
                            c.contentRow,
                            (index + 1) % 3 === 0 && c.noRightBorder,
                            index >= cadHeaders.length - itemsInLastRow &&
                                c.noBottomBorder
                        )}
                        key={name}
                    >
                        <Typography className={c.contentRowHeader}>
                            {header}
                        </Typography>
                        <Typography className={c.contentRowValue}>
                            {props[name] || 'N/A'}
                        </Typography>
                    </div>
                ))}
            </Grid>
            <Grid item md={3} className={c.imageContainer}>
                <img className={c.image} src={image} alt='cad' />
            </Grid>
        </Grid>
    );
});

CadTable.propTypes = propTypes;

export default CadTable;
