import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import useConfirmDelete from '@libHooks/useConfirmDelete';

// Local
import { DeleteIcon, EditIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';

const propTypes = {
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
};

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
});

const Actions = withStyles(styles)(({ classes, handleEdit, handleDelete }) => {
    const [ConfirmDeleteDialog, handleConfirmDelete] = useConfirmDelete(
        handleDelete
    );
    return (
        <div className={classes.root}>
            <TooltipAction
                IconComponent={EditIcon}
                title='Edit it!'
                onClick={handleEdit}
            />
            <TooltipAction
                IconComponent={DeleteIcon}
                title='Delete it!'
                onClick={handleConfirmDelete}
            />
            {ConfirmDeleteDialog}
        </div>
    );
});

Actions.propTypes = propTypes;

export default Actions;
