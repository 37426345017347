import React, { useCallback } from 'react';

// Material
import { withStyles, Typography } from '@material-ui/core';

// Libs
import isBlobUrl from '@libs/isBlobUrl';
import { TooltipAction } from '@formComponents';

// Assets
import { DeleteIcon } from '@assets/Icons';

import { Error } from 'components/Message';
import { DownloadLink } from 'components';
import useBlobURL from '@libHooks/useBlobURL';

// Local
import PreviewAction from './PreviewAction';
import useUpload from '../useUpload';
import { useUploadError } from './hooks';

const propTypes = {};
const defaultProps = {};

const styles = ({ spacing, typography }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: spacing.unit * 1.5,
        marginTop: spacing.unit * 0.5
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: spacing.unit * 0.5
    },
    actionButton: {
        marginLeft: spacing.unit * 0.5,
        marginRight: spacing.unit * 0.5
    },

    info: {
        fontSize: typography.caption.fontSize,
        lineHeight: 1
    }
});

const Actions = withStyles(styles)(({ classes: c }) => {
    const [
        {
            value,
            filename,
            uploadConstraints: { helperText, accept },
            handleBlur
        } = {},
        dispatch
    ] = useUpload();
    const [blobUrl, blobLoading, canDownload] = useBlobURL(value);
    const { hasError, message } = useUploadError();

    const handleRemove = useCallback(() => {
        dispatch({ type: 'removeFile' });
        handleBlur();
    }, [handleBlur]);

    return (
        <div className={c.root}>
            {hasError ? (
                <Error message={message} />
            ) : (
                <Typography className={c.info} variant='subtitle2'>
                    {helperText}
                </Typography>
            )}

            <div className={c.actionsContainer}>
                {canDownload && (
                    <DownloadLink
                        url={blobUrl}
                        loading={blobLoading}
                        name={filename}
                    />
                )}
                {value && (
                    <TooltipAction
                        onClick={handleRemove}
                        className={c.actionButton}
                        IconComponent={DeleteIcon}
                        title='Delete it!'
                    />
                )}
                {accept.includes('application/pdf') && value && (
                    <PreviewAction url={value} name={filename} />
                )}
            </div>
        </div>
    );
});

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;

export default Actions;
