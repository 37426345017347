import { formValueSelector } from 'redux-form';
// Entities

import {
    ARMHOLES,
    HEMLINES,
    LABEL_TYPES,
    NECKLINES,
    SPEC_REFERENCES,
} from 'libs/datasets';
// Selectors

import {
    selectArmholesData,
    selectHemlinesData,
    selectLabelTypesData,
    selectNecklinesData,
    selectUserId,
} from 'selectors';
// Normalizers
import { normalizeNestedData, normalizeArmholes } from 'normalizers';
import { composePaginatedEntity, composeUrl } from '../../../common';

// References
export default [
    {
        entity: ARMHOLES,
        url: '/api/Armholes',
        selector: selectArmholesData,
        normalizer: normalizeArmholes,
    },
    {
        entity: HEMLINES,
        url: '/api/Hemlines',
        selector: selectHemlinesData,
        normalizer: normalizeNestedData,
    },
    {
        entity: LABEL_TYPES,
        url: '/api/LabelTypes',
        selector: selectLabelTypesData,
        normalizer: normalizeNestedData,
    },
    {
        entity: NECKLINES,
        url: '/api/Necklines',
        selector: selectNecklinesData,
        normalizer: normalizeNestedData,
    },
    {
        entity: SPEC_REFERENCES,
        url: (state, queryBuilder) =>
            composeUrl(
                'api/SampleRequestMains/GetSpecReference',
                queryBuilder(state)
            ),
        ...composePaginatedEntity(
            SPEC_REFERENCES,
            { sampleReference: 'Reference', specDescription: 'Description' },
            (state) => ({
                userId: selectUserId(state),
                retailerId: formValueSelector('SamplingPrintForm')(
                    state,
                    'retailerId'
                ),
            })
        ),
    },
];
