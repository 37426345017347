import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, withStyles } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const propTypes = {
    onClick: PropTypes.func.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {
        minWidth: 'unset',
        borderRadius: 0,
        width: spacing.unit * 3.5,
        height: spacing.unit * 3.5,
        '&:hover': {
            color: palette.error.main
        }
    },
    icon: {}
});

const Adornment = withStyles(styles)(({ classes: c, ...props }) => (
    <Button className={c.root} {...props}>
        <ClearIcon fontSize='large' />
    </Button>
));

Adornment.propTypes = propTypes;
Adornment.defaultProps = defaultProps;

export { Adornment as default, Adornment };
