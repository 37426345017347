import { RouteInfo } from 'routes/libs';
import { Dashboard } from 'assets';

const menuGenerator = MENUS => {
    const getIndieRouteInfo = id => {
        const isExists = Object.hasOwnProperty.call(RouteInfo, String(id));

        return {
            depth: (isExists && RouteInfo[String(id)].depth) || 1,
            icon: (isExists && RouteInfo[String(id)].icon) || Dashboard,
            url: (isExists && RouteInfo[String(id)].url) || ''
        };
    };

    const generateMenus = parent => {
        const menuList = MENUS.filter(({ menuId, parentId }) =>
            parent
                ? menuId !== parent && parentId === parent
                : menuId === parentId
        );

        if (menuList.length) {
            return menuList
                .sort((a, b) => a.serial - b.serial)
                .map(item => ({
                    ...item,
                    ...getIndieRouteInfo(item.menuId),
                    children: [...generateMenus(item.menuId)]
                }));
        }

        return [];
    };


    const menus = generateMenus();
    console.log('menus', MENUS, menus)
    return menus
};

export { menuGenerator as default, menuGenerator };
