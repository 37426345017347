import { useMemo } from 'react';
import { denormalizeDate } from '@baseComponents/Date/normalizeDate';

const useRequiredByMinDate = dateRequested =>
    useMemo(() => {
        const daysBuffer = 7;

        const timeRequested = new Date(
            denormalizeDate(dateRequested)
        ).getTime();
        const timeBuffer = 1000 * 60 * 60 * 24 * daysBuffer;
        const timeRequiredBy = timeRequested + timeBuffer;

        return new Date(timeRequiredBy);
    }, [dateRequested]);

export { useRequiredByMinDate };
