import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

// Libs
import Search from '@libComponents/Search';
import { functionalComponentPropType } from '@libs/propTypes';
import { IconButton } from '@libComponents/IconButton';

const propTypes = {
    handleSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    Adornment: functionalComponentPropType,
    placeholder: PropTypes.string,
    handleRefresh: PropTypes.func.isRequired
};

const defaultProps = {
    Adornment: Fragment
};

const styles = () => ({
    container: { display: 'flex', alignItems: 'center' }
});

const Adornments = withStyles(styles)(
    ({
        classes: c,
        handleSearch,
        searchValue,
        Adornment,
        handleRefresh,
        ...rest
    }) => {
        return (
            <div className={c.container}>
                <Search
                    value={searchValue}
                    makeSearch={handleSearch}
                    {...rest}
                />
                <Adornment />
                <IconButton IconComponent={Refresh} onClick={handleRefresh} />
            </div>
        );
    }
);

Adornments.propTypes = propTypes;
Adornments.defaultProps = defaultProps;

export default Adornments;
