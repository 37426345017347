import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { dispatchReportMailSend } from 'sampling/redux.actions';
import {
    SET_DISPATCH_EMAIL_RECEIVERS,
    SET_DISPATCH_EMAIL_BODY
} from 'sampling/redux.actionTypes';
import SelectMailGroup from 'sampling/SampleDispatch/content/Preview/MailModal/SelectMailGroup';
import SelectMailTemplate from 'sampling/SampleDispatch/content/Preview/MailModal/SelectMailTemplate';

const mapDispatch = dispatch => ({
    formInitialize: (...args) => dispatch(initialize(...args)),
    sendReportMail: (...args) => dispatch(dispatchReportMailSend(...args))
});

const mapInputs = {
    group: ({ payload }) => ({ type: SET_DISPATCH_EMAIL_RECEIVERS, payload }),
    template: ({ payload }) => ({ type: SET_DISPATCH_EMAIL_BODY, payload })
};

const PreviewMailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: v => v
    }),
    connect(
        null,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose,
        valid,
        emailSubject,
        retailer,
        PDFData,
        reference,
        handlePreviewModalClose,
        versionNo,
        formInitialize,
        sendReportMail
    }) => {
        const handleMailModalClose = useCallback(() => {
            onClose();
            setTimeout(() => handlePreviewModalClose(), 0);
        }, []);

        const handleSendMail = useCallback(() => {
            sendReportMail({
                dispatchRef: reference,
                versionNo,
                closeModals: handleMailModalClose
            });
        }, [reference, versionNo]);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, {
                subject: emailSubject,
                report: PDFData
            });
        }, []);

        const mailProps = {
            MailGroupComponent: <SelectMailGroup retailer={retailer} />,
            MailTemplatesComponent: <SelectMailTemplate retailer={retailer} />,
            handleModalClose: onClose,
            handleSubmit: handleSendMail,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default PreviewMailModal;
