import { MODULE_NAME } from './common';

// Module name should be same with accumulation api moduleName param
const accumulation = { show: false, moduleName: MODULE_NAME };

const editInfo = {
    endpoint: 'Cad/ListviewDataEdit'
};

const searchableColumns = [
    {
        value: 'cadRef',
        label: 'Cad Ref'
    },
    {
        value: 'buyerReference',
        label: 'Buyer Ref'
    },
    {
        value: 'description',
        label: 'Cad Desc'
    },
    {
        value: 'designer',
        label: 'Designer'
    },
    {
        value: 'cadType',
        label: 'Cad Type'
    },
    {
        value: 'retailer',
        label: 'Retailer'
    }
];

const tileView = { show: true, columns: ['cadReference', 'cad', 'id'] };

const exportableColumns = [
    'cadReference',
    'buyerReference',
    'cad',
    'devNo',
    'designer',
    'cadType',
    'licensor',
    'property',
    'retailer',
    'gender',
    'age',
    'category',
    'department',
    'styles',
    'artworkSource',
    'shapeDesigner',
    'description',
    'status',
    'availability'
];

const reportsInfo = {
    xlsx: {
        show: true,
        columns: exportableColumns
    },
    pdf: {
        show: true,
        columns: exportableColumns
    }
};

export const config = {
    accumulation,
    editInfo,
    searchableColumns,
    moduleName: MODULE_NAME,
    tileView,
    reportsInfo
};
