import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
    formValueSelector,
    initialize,
    change,
    stopSubmit,
    destroy
} from 'redux-form';
import _ from 'lodash';

import { redirectToRoute } from 'navigation/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';
import apiRequest from '@libs/apiRequest';
import { setLoading } from 'utilities/redux.actions';
import { normalizeFile } from 'sampling/redux.normalizers';

import {
    ROUTE_TO_SEAL_SAMPLE,
    DESTROY_SEAL_SAMPLE_FORM,
    SEAL_SAMPLE_PUBLISH,
    SET_SEAL_SAMPLE_COLOR_CHILDREN,
    SEAL_SAMPLE_REF_GENERATE,
    GET_SEAL_SAMPLE_DATA,
    GET_COPY_SEAL_SAMPLE_DATA,
    REDIRECT_TO_SS_AND_FETCH_REF_DATA
} from '../../redux.actionTypes';
import {
    destroySealSampleForm,
    setQCColorChildrenLoading,
    setPreproductionReferenceLoading,
    getPreproductionQCData
} from '../../redux.actions';
import {
    FORM_NAME,
    INITIAL_VALUES,
    NEW_SEAL_SAMPLE_ROUTE_ID,
    INCOMPLETE_SEAL_SAMPLE_ROUTE_ID,
    AMEND_SEAL_SAMPLE_ROUTE_ID
} from '../../SealSample/common';
import sealSamplePublishWorker from './publishWorker';
import generateSealSampleReferenceWorker from './generateSealSampleReferenceWorker';
import getReferenceDataWorker from './getReferenceDataWorker';

// Workers
export const routeToSealSampleWorker = function*({
    payload: { mode = null } = {}
}) {
    const routeID =
        mode === 'new'
            ? NEW_SEAL_SAMPLE_ROUTE_ID
            : mode === 'amend'
            ? AMEND_SEAL_SAMPLE_ROUTE_ID
            : INCOMPLETE_SEAL_SAMPLE_ROUTE_ID;

    yield put(destroySealSampleForm()); // Not redundant -- takes care of object URLS

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    }

    yield put(redirectToRoute(routeID));
};

export function* destroySealSampleFormWorker() {
    // URL blob clean up
    const { attachments, front, back } = yield select(
        formValueSelector(FORM_NAME),
        'attachments',
        'front',
        'back'
    );

    if (attachments)
        attachments.forEach(
            attachment =>
                attachment &&
                isBlobUrl(attachment.url) &&
                URL.revokeObjectURL(attachment.url)
        );

    if (front && isBlobUrl(front.url)) URL.revokeObjectURL(front.url);
    if (back && isBlobUrl(back.url)) URL.revokeObjectURL(back.url);

    yield put(destroy(FORM_NAME));
}

function* setSealSampleColorChildrenWorker({ payload: colorId }) {
    yield put(change(FORM_NAME, 'factory', ''));
    yield put(change(FORM_NAME, 'quantity', ''));
    yield put(change(FORM_NAME, 'styleDesc', ''));
    yield put(change(FORM_NAME, 'finishWash', ''));
    if (colorId) {
        try {
            yield put(setQCColorChildrenLoading(true));
            const { jwpNo, qcStyle } = yield select(
                formValueSelector(FORM_NAME),
                'jwpNo',
                'qcStyle'
            );
            const {
                data: {
                    data: [
                        {
                            orderFactory,
                            totalQty,
                            styleDescription,
                            finishWashId,
                            fabrics,
                            qcExists,
                            frontImage,
                            backImage
                        } = {}
                    ] = []
                } = {}
            } = yield call(apiRequest, {
                url: 'QcPreSealSamples/GetQcStyleDetails',
                params: { jwpNo, styleRef: qcStyle, colorId }
            });
            const { qcRef, qcMode } = qcExists || {};

            if (qcRef && qcMode) {
                yield put(
                    stopSubmit(FORM_NAME, {
                        qcColor: `Copy available in '${qcMode}' section. Ref: ${qcRef}`
                    })
                );
                /*                 yield put(
                    setSnack({
                        message: 'This sheet already started.',
                        type: 'error',
                        duration: 15000,
                        action: {
                            label: 'Click here',
                            handle: redirectToPPAndFetchRefData(
                                qcRef,
                                qcMode === 'Incomplete'
                                    ? INCOMPLETE_SEAL_SAMPLE_ROUTE_ID
                                    : AMEND_SEAL_SAMPLE_ROUTE_ID
                            )
                        }
                    })
                ); */
            } else {
                const { image350SizeUrl = '' } = frontImage || {};
                const { image350SizeUrl: back350 = '' } = backImage || {};

                yield put(change(FORM_NAME, 'factory', orderFactory));
                yield put(change(FORM_NAME, 'quantity', totalQty));
                yield put(change(FORM_NAME, 'styleDesc', styleDescription));
                yield put(change(FORM_NAME, 'finishWash', finishWashId));
                yield put(change(FORM_NAME, 'fabrics', fabrics));
                yield put(
                    change(
                        FORM_NAME,
                        'frontImage',
                        normalizeFile(image350SizeUrl)
                    )
                );
                yield put(
                    change(FORM_NAME, 'backImage', normalizeFile(back350))
                );
            }
            yield put(setQCColorChildrenLoading(false));
        } catch (error) {
            yield put(setQCColorChildrenLoading(false));
            console.log(error);
        }
    }
}

function* generateReferenceWorker() {
    yield put(setPreproductionReferenceLoading(true));

    yield call(generateSealSampleReferenceWorker);

    yield put(setPreproductionReferenceLoading(false));
}

const getSealSampleDataWorker = function*({ payload: qcReference }) {
    if (qcReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, qcReference);

        yield put(setLoading(false));
    }
};

const getCopyPreproductionQCDataWorker = function*({ payload: qcReference }) {
    if (qcReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, qcReference, true);

        yield put(setLoading(false));
    }
};
const redirectToPPAndFetchRefDataWorker = function*({
    payload: { qcReference, routeId }
}) {
    if (qcReference && routeId) {
        yield put(redirectToRoute(routeId));
        yield put(getPreproductionQCData(qcReference));
    }
};

// Watchers
export default function*() {
    yield takeLatest(ROUTE_TO_SEAL_SAMPLE, routeToSealSampleWorker);
    yield takeLatest(SEAL_SAMPLE_PUBLISH, sealSamplePublishWorker);
    yield takeLatest(DESTROY_SEAL_SAMPLE_FORM, destroySealSampleFormWorker);
    yield takeLatest(
        SET_SEAL_SAMPLE_COLOR_CHILDREN,
        setSealSampleColorChildrenWorker
    );
    yield takeLatest(SEAL_SAMPLE_REF_GENERATE, generateReferenceWorker);
    yield takeLatest(GET_SEAL_SAMPLE_DATA, getSealSampleDataWorker);
    yield takeLatest(
        GET_COPY_SEAL_SAMPLE_DATA,
        getCopyPreproductionQCDataWorker
    );
    yield takeLatest(
        REDIRECT_TO_SS_AND_FETCH_REF_DATA,
        redirectToPPAndFetchRefDataWorker
    );
}
