import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import {
    withStyles,
    Typography,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Local
import styles from './styles';
import { EditIcon, Tooltip, withModal } from 'components';
import { AddInksModal } from 'views/Sampling/modals';

// Actions
import { removeInkImage } from 'store/actions';

class SamplingInkImagesTable extends Component {
    render() {
        const { props } = this;

        const {
            classes: c,
            handleAddInkImagesModal,
            removeInkImage,
            data
        } = props;

        return (
            <Fragment>
                <Typography component='div' style={{ display: 'flex' }}>
                    <Typography style={{ flexGrow: 1, lineHeight: '35px' }}>
                        <b>Ink Images: </b>
                    </Typography>
                    <Button
                        variant='contained'
                        className={c.blackButton}
                        onClick={() =>
                            handleAddInkImagesModal({ inkModalType: 'img' })
                        }
                    >
                        Add Image
                    </Button>
                </Typography>
                <Table className={c.inkTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Added Images </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    {data.length > 0 ? (
                        <TableBody>
                            {Object.entries(data).map(([key, image]) =>
                                (data[key].file &&
                                    data[key].file.name !== void 0) ||
                                data[key].imageUrl !== void 0 ? (
                                    <TableRow key={key}>
                                        <TableCell>
                                            {
                                                <img
                                                    src={
                                                        !!data[key].file
                                                            ? URL.createObjectURL(
                                                                  data[key].file
                                                              )
                                                            : data[key].imageUrl
                                                    }
                                                    alt='ink'
                                                />
                                            }
                                            <Typography
                                                className={c.inkTableText}
                                            >
                                                <span>
                                                    File:{' '}
                                                    {!!data[key].file
                                                        ? data[key].file.name
                                                        : data[key].imageName}
                                                </span>
                                                <span>
                                                    Size:{' '}
                                                    {data[key].file
                                                        ? (
                                                              data[key].file
                                                                  .size / 1000
                                                          ).toFixed(2) + ' KB'
                                                        : null}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Tooltip
                                                onClick={() =>
                                                    removeInkImage({
                                                        id: key
                                                    })
                                                }
                                                title='Delete it'
                                            >
                                                <CloseIcon
                                                    className={
                                                        c.tooltipTriggerIcon
                                                    }
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ) : null
                            )}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    style={{
                                        textAlign: 'center',
                                        color: '#aeaeae'
                                    }}
                                >
                                    Add image
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </Fragment>
        );
    }
}

const mapDispatch = {
    removeInkImage
};

const mapModals = {
    handleAddInkImagesModal: AddInksModal
};

const _SamplingInkImagesTable = compose(
    connect(
        undefined,
        mapDispatch
    ),
    withStyles(styles),
    withModal(mapModals)
)(SamplingInkImagesTable);

export {
    _SamplingInkImagesTable as default,
    _SamplingInkImagesTable as SamplingInkImagesTable
};
