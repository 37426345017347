import artworkReferences from './artwork';
import cadReferences from './cad';
import fabricsReferences from './fabrics';
import inksReferences from './inks';
import referenceReferences from './reference';
import requestReferences from './request';
import specReferences from './spec';
import trimsReferences from './trims';

export default [
    ...artworkReferences,
    ...cadReferences,
    ...fabricsReferences,
    ...inksReferences,
    ...referenceReferences,
    ...requestReferences,
    ...specReferences,
    ...trimsReferences
];
