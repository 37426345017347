import React from 'react';
import classnames from 'classnames';
import { string, func } from 'prop-types';

import { withStyles } from '@material-ui/core';

import { ListViewIcon, GridViewIcon } from '@assets/Icons';

const styles = ({ spacing, palette }) => ({
    wrapper: {
        display: 'flex'
    },
    icon: {
        fill: '#b9b9b9',
        '&:hover': {
            cursor: 'pointer',
            fill: palette.grey[700]
        },
        '&:focus': {
            outline: 'none',
            fill: '#0F70C3'
        },
        '&:first-child': {
            marginRight: spacing.unit * 1.5
        }
    },
    active: {
        fill: '#0F70C3',
        pointerEvents: 'none'
    }
});

const propTypes = {
    currentView: string.isRequired,
    dispatch: func.isRequired
};

const ViewToggle = withStyles(styles)(
    ({ currentView, dispatch, classes: c }) => {
        const handleChangeView = type => {
            dispatch({ type: 'view-change', payload: type });
        };

        return (
            <div className={c.wrapper}>
                <ListViewIcon
                    className={classnames(
                        c.icon,
                        currentView === 'list' ? c.active : ''
                    )}
                    onClick={() => handleChangeView('list')}
                    tabIndex={currentView === 'list' ? -1 : 0}
                    onKeyPress={({ key }) =>
                        key === 'Enter' && handleChangeView('list')
                    }
                />
                <GridViewIcon
                    onClick={() => handleChangeView('tile')}
                    className={classnames(
                        c.icon,
                        currentView === 'tile' ? c.active : ''
                    )}
                    tabIndex={currentView === 'tile' ? -1 : 0}
                    onKeyPress={({ key }) =>
                        key === 'Enter' && handleChangeView('tile')
                    }
                />
            </div>
        );
    }
);

ViewToggle.propTypes = propTypes;

export default ViewToggle;
