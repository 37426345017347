import { Field, FieldArray } from 'redux-form';
import React from 'react';

export const validateRequired = required => values => {
    const valid = Object.values(Object.splice(values, required)).filter(
        value => value !== '' || value != 0
    ).length;
    if (valid < required.length) return { [required[0]]: 'error' };
};

export const validate = fields => (values = {}) => {
    const errors = {};
    fields.map(field => {
        if (!values[field]) {
            errors[field] = 'Required!';
        }
    });

    return errors;
};

export const fieldRequired = value => (value ? undefined : 'Required!');

export const normalizeNumber = value => (value ? Number(value) : value);

export const withField = (fieldProps = {}) => Component => ({
    validate,
    required = false,
    ...props
}) => {
    return (
        <Field
            fieldName={props.name}
            component={Component}
            validate={validate || (required ? fieldRequired : undefined)}
            required={required}
            {...(props.type === 'number' ? { normalize: normalizeNumber } : {})}
            {...props}
            {...fieldProps}
        />
    );
};

export const withFieldArray = (fieldProps = {}) => Component => ({
    required = false,
    ...props
}) => {
    return (
        <FieldArray
            fieldName={props.name}
            component={Component}
            required={required}
            {...props}
            {...fieldProps}
        />
    );
};
