import { useCallback, useMemo, useState } from 'react';

import useStepperHandle from '@libHooks/useStepperHandle';

import Setup from './content/Setup';
import Details from './content/Details';
import Others from './content/Others';
import Complete from './content/Complete';
import {
    LABELS,
    FD_SETUP_FIELDS,
    FD_DETAILS_FIELDS,
    FD_OTHERS_FIELDS
} from './common';

const useDetailsLabel = mode =>
    useMemo(() => {
        switch (mode) {
            case 'incomplete':
                return LABELS.editFabricDevelopment;
            case 'amend':
                return LABELS.amendFabricDevelopment;
            default:
                return LABELS.details;
        }
    }, [mode]);

const useContent = mode => {
    const detailsLabel = useDetailsLabel(mode);
    return useMemo(() => {
        const setup = mode === 'new' ? { [LABELS.setup]: Setup } : {};
        return {
            ...setup,
            [detailsLabel]: Details,
            [LABELS.others]: Others,
            [LABELS.complete]: Complete
        };
    }, [mode, detailsLabel]);
};

const useContentFields = (mode, activeStep) => {
    return useMemo(() => {
        const modeSpecificFields = mode === 'new' ? [FD_SETUP_FIELDS] : [];
        const contentFields = [
            ...modeSpecificFields,
            FD_DETAILS_FIELDS,
            FD_OTHERS_FIELDS
        ];
        return contentFields[activeStep] || [];
    }, [mode, activeStep]);
};

const useHandles = ({
    dispatch,
    mode,
    activeStep,
    publish,
    fabricReference,
    generateReference
}) => {
    const amountOfSteps = mode === 'new' ? 4 : 3;
    const [setupLocked, setSetupLocked] = useState(mode !== 'new');

    const reset = useCallback(() => {
        mode === 'new' && setSetupLocked(false);
        dispatch({ type: 'reset' });
    }, [dispatch, mode]);

    const handlePublish = useCallback(
        dispatchProps => {
            return useStepperHandle({
                amountOfSteps,
                dispatch,
                mode,
                publish,
                activeStep: amountOfSteps - 2, // Forces the handle to think it's about to publish,
                dispatchProps
            });
        },
        [mode, dispatch, publish]
    );

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        activeStep,
        publish
    });

    const handleNext = useCallback(() => {
        const isFirstStepForNewMode = mode === 'new' && activeStep === 0;

        if (isFirstStepForNewMode) {
            setSetupLocked(true);

            if (!fabricReference) {
                generateReference();
            }
        }

        handleStepper();
    }, [mode, activeStep, handleStepper, fabricReference, generateReference]);

    return [handleNext, handlePublish, setupLocked, reset];
};

export { useContent, useHandles, useContentFields, useDetailsLabel };
