import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';

const styles = ({ palette, spacing }) => ({
    wrapper: {
        width: spacing.unit * 4,
        height: spacing.unit * 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #C8CED4',
        borderRadius: '50%',
        backgroundColor: '#fff',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: palette.grey[300]
        }
    }
});
const CloseIcon = withStyles(styles)(
    ({ color: fill = '#a5aab3', className, classes: c, ...restProps }) => {
        return (
            <div className={classNames(c.wrapper, className)} {...restProps}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20.999'
                    height='20.999'
                    viewBox='0 0 20.999 20.999'
                >
                    <defs>
                        <filter
                            id='Forma_1_copy_11'
                            x='0'
                            y='0'
                            width='20.999'
                            height='20.999'
                            filterUnits='userSpaceOnUse'
                        >
                            <feOffset
                                dx='0.743'
                                dy='0.669'
                                input='SourceAlpha'
                            />
                            <feGaussianBlur stdDeviation='1' result='blur' />
                            <feFlood
                                floodColor='#000235'
                                floodOpacity='0.569'
                            />
                            <feComposite operator='in' in2='blur' />
                            <feComposite in='SourceGraphic' />
                        </filter>
                    </defs>
                    <g id='popup_copy' transform='translate(-1363.744 -139.67)'>
                        <g
                            transform='matrix(1, 0, 0, 1, 1363.74, 139.67)'
                            filter='url(#Forma_1_copy_11)'
                        >
                            <path
                                id='Forma_1_copy_11-2'
                                d='M1375.283,149.5l5.348-5.348a1.261,1.261,0,0,0-1.783-1.783l-5.348,5.348-5.348-5.348a1.261,1.261,0,0,0-1.783,1.783l5.348,5.347-5.348,5.348a1.26,1.26,0,0,0,1.783,1.783l5.348-5.348,5.348,5.348a1.26,1.26,0,0,0,1.783-1.783Z'
                                transform='translate(-1363.74 -139.67)'
                            />
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
);

export { CloseIcon as default, CloseIcon };
