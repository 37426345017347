import createCachedSelector from 're-reselect';

import { selectMenusData } from '../datasets/common';
import { mergeMenus, menuGenerator, routeGenerator } from 'helpers/routes';

export const selectAuth = state => state.auth;

export const selectUserId = createCachedSelector(
    selectAuth,
    auth => auth.userId
)(state => selectAuth(state).userId || 'empty');

export const selectAuthenticatedMenus = createCachedSelector(
    selectAuth,
    authDatas => authDatas.menus
)(() => 'placeholder');

export const mergeMenuInfos = createCachedSelector(
    selectMenusData,
    selectAuthenticatedMenus,
    (menus, authMenus) => mergeMenus(menus, authMenus)
)(state => 'placeholder');

export const selectAuthMenus = createCachedSelector(mergeMenuInfos, data =>
    menuGenerator(data)
)(state => 'placeholder');

export const selectAuthRoutes = createCachedSelector(mergeMenuInfos, menus =>
    routeGenerator(menus)
)(state => 'placeholder');

const menuSerializer = menus => {
    return menus.map(({ menuName, icon = null, children = [] }) => ({
        label: menuName,
        icon,
        route: menuName.replace(' ', '-').toLowerCase(),
        children: menuSerializer(children)
    }));
};

export const selectMenus = createCachedSelector(
    selectAuthMenus,
    menuSerializer
)(() => 'placeholder');
