import makeContext from '@libs/makeContext';
import isBlobUrl from '@libs/isBlobUrl';

const initialState = {
    file: null,
    isDragging: false,
    onBlur: f => f
};

const reducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case 'setDragging': {
            return {
                ...state,
                isDragging: payload
            };
        }

        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addFile': {
            const { file, onBlur } = state;
            const { payload } = action;

            if (file && isBlobUrl(file.url)) URL.revokeObjectURL(file.url);

            reduxFormOnChange({
                url: URL.createObjectURL(payload),
                filename: payload.name,
                size: payload.size
            });
            onBlur();
            return { type: 'setDragging', payload: false };
        }

        case 'removeFile': {
            const { file, onBlur } = state;

            if (file && isBlobUrl(file.url)) URL.revokeObjectURL(file.url);

            reduxFormOnChange(null);
            onBlur();
            return action;
        }

        default:
            return action;
    }
};

const [
    ImageDnDProvider,
    useImageDnDState,
    useImageDnDDispatch,
    useImageDnD
] = makeContext(reducer, initialState, {
    name: 'imageDnD',
    middleware
});

export {
    useImageDnD as default,
    ImageDnDProvider,
    useImageDnDState,
    useImageDnDDispatch
};
