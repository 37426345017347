import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from 'sampling/common';
import { FINISHED_GSMS } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'gsm',
    label: 'GSM',
    placeholder: 'Select GSM',
    domain: DOMAIN_NAME,
    entity: FINISHED_GSMS,
    disabled: false,
    required: false
};

const SelectGSM = props => <Select {...props} />;

SelectGSM.propTypes = propTypes;
SelectGSM.defaultProps = defaultProps;

export default SelectGSM;
