import createCachedSelector from 're-reselect';

// Local
import { mapToSelect, mapToCard } from 'helpers';

// Selectors
import { selectSamplingPrint } from '../common';
import {
    selectCompositionsData,
    selectDyeTypesData,
    selectFabricForsData,
    selectFabricTypesData,
    selectFinishedGsmsData,
    selectSpecialFinishesData
} from '../../datasets/sampling/fabric';

import { deserializeData } from 'helpers/fabricDetails';

// Compositions
export const selectCompositions = createCachedSelector(
    selectCompositionsData,
    mapToSelect
)(() => 'placeholder');

// Dye Types
export const selectDyeTypes = createCachedSelector(
    selectDyeTypesData,
    mapToSelect
)(() => 'placeholder');

// Fabric Fors
export const selectFabricFors = createCachedSelector(
    selectFabricForsData,
    mapToSelect
)(() => 'placeholder');

// Fabric Types
export const selectFabricTypes = createCachedSelector(
    selectFabricTypesData,
    mapToSelect
)(() => 'placeholder');

// Finished GSMs
export const selectFinishedGsms = createCachedSelector(
    selectFinishedGsmsData,
    mapToSelect
)(() => 'placeholder');

// Special Finishes
export const selectSpecialFinishes = createCachedSelector(
    selectSpecialFinishesData,
    mapToSelect
)(() => 'placeholder');

// Dirty
// Todo: redo this, inefficient
// Fabric List
const selectAddedFabric = createCachedSelector(
    selectSamplingPrint,
    samplingPrint => samplingPrint.addedFabric
)(state =>
    selectSamplingPrint(state)
        .addedFabric.map(({ fabricAutoId }) => fabricAutoId)
        .join('-')
);

const selectDuplicatedFabric = createCachedSelector(
    selectSamplingPrint,
    samplingPrint => samplingPrint.duplicatedFabric
)(state =>
    selectSamplingPrint(state)
        .duplicatedFabric.map(({ fabricAutoId }) => fabricAutoId)
        .join('-')
);

const selectCopyRefFabric = createCachedSelector(
    selectSamplingPrint,
    samplingPrint => samplingPrint.copyRefFabric
)(state =>
    selectSamplingPrint(state)
        .copyRefFabric.map(({ fabricAutoId }) => fabricAutoId)
        .join('-')
);

const selectSpecRefFabric = createCachedSelector(
    selectSamplingPrint,
    samplingPrint => samplingPrint.specRefFabric
)(state =>
    selectSamplingPrint(state)
        .specRefFabric.map(({ fabricAutoId }) => fabricAutoId)
        .join('-')
);

export const selectFabricList = createCachedSelector(
    selectAddedFabric,
    selectDuplicatedFabric,
    selectCopyRefFabric,
    selectSpecRefFabric,
    (addedFabric, duplicatedFabric, copyRefFabric, specRefFabric) => [
        ...copyRefFabric,
        ...specRefFabric,
        ...addedFabric,
        ...duplicatedFabric
    ]
)(() => 'placeholder'); // Yikes

// Fabric For
export const selectFabricForList = createCachedSelector(
    ({
        initSamplingPrint: {
            copyRefFabric,
            specRefFabric,
            addedFabric,
            duplicatedFabric
        },
        samplingRetailers: { fabricFor }
    }) => ({
        fabricFor,
        copyRefFabric,
        specRefFabric,
        addedFabric,
        duplicatedFabric
    }),
    ({
        fabricFor,
        copyRefFabric,
        specRefFabric,
        addedFabric,
        duplicatedFabric
    }) => {
        if (!fabricFor.length) return [];

        const fabricData = [
            ...copyRefFabric,
            ...specRefFabric,
            ...addedFabric,
            ...duplicatedFabric
        ];
        const existsData = fabricData.filter(
            item => item.fabricFor && Number(item.fabricFor.id) === 1
        );
        let fabricForList = fabricFor;
        fabricForList = fabricForList.map(fabric => ({
            value: fabric.fabricForId,
            label: fabric.fabricFor
        }));

        if (existsData.length) {
            fabricForList = fabricForList.filter(fitem => fitem.value !== 1);
        }
        return fabricForList;
    }
)(() => 'placeholder');

export const selectFabrics = createCachedSelector(
    selectSamplingPrint,
    samplingPrint => samplingPrint['fabrics']
)(() => 'placeholder');

export const selectFabricDetails = createCachedSelector(
    selectFabrics,
    (state, fabricIndex) => fabricIndex,
    (fabrics, fabricIndex) => {
        return fabrics.filter((fabric, index) => index === fabricIndex)[0];
    }
)((state, fabricIndex) => 'index');

export const selectMainFabricFor = createCachedSelector(
    selectFabrics,
    selectFabricFors,
    (fabrics, fabricFors) => {
        const mainBodyFabricId = 1;
        let nextFabricForId = 0;
        if (fabrics.some(({ fabricFor }) => fabricFor === mainBodyFabricId)) {
            const [{ value }] = fabricFors.filter(
                ({ value }) => value !== mainBodyFabricId
            );
            nextFabricForId = value;
        }
        return nextFabricForId;
    }
)(() => '');

const selectMainFabricExists = createCachedSelector(selectFabrics, fabrics =>
    fabrics.some(({ fabricFor }) => fabricFor == 1)
)(() => 'placeholder');

export const selectParsedFabrics = createCachedSelector(
    selectMainFabricExists,
    selectFabricFors,
    (state, fabrics) => (Array.isArray(fabrics) ? fabrics : [fabrics]),
    () => 1, // Main Body Fabric Id
    (mainFabricExists, fabricFors, fabrics, mainBodyFabricId) =>
        fabrics.map(({ fabricFor, ...fabric }) => {
            if (mainFabricExists && fabricFor == mainBodyFabricId) {
                const [{ value: nextFabricFor }] = fabricFors.filter(
                    ({ value }) => value !== mainBodyFabricId
                );
                fabricFor = nextFabricFor;
            }
            return { ...fabric, fabricFor };
        })
)(() => 'placeholder');

export const selectDuplicateFabricFor = createCachedSelector(
    selectMainFabricExists,
    selectFabricFors,
    (state, fabricDetails) => fabricDetails,
    (mainFabricExists, fabricFors, { fabricFor }) => {
        if (fabricFor === 1 && mainFabricExists) {
            const [{ value }] = fabricFors.filter(({ value }) => value !== 1);
            return value;
        } else return fabricFor;
    }
)(() => 'placeholder');

export const selectFilteredFabricFor = createCachedSelector(
    selectMainFabricExists,
    selectFabricFors,
    selectFabricDetails,
    (mainFabricExists, fabricFors, { fabricFor = 0 } = {}) => {
        const mainBodyFabricId = 1;
        if (mainFabricExists && fabricFor != mainBodyFabricId) {
            return fabricFors.filter(({ value }) => value !== mainBodyFabricId);
        } else return fabricFors;
    }
)(() => 'placeholder');

export const selectSerializedFabricDetails = createCachedSelector(
    (state, fabricDetails) => fabricDetails,
    selectFabricFors,
    selectFabricTypes,
    selectCompositions,
    selectFinishedGsms,
    selectDyeTypes,
    selectSpecialFinishes,
    (
        fabricDetails,
        fabricFors,
        fabricTypes,
        compositions,
        finishedGsms,
        dyeTypes,
        specialFinishes
    ) => {
        // label, variable name [,selectData || boolean]
        const fabricDetailsMap = [
            ['Fabric For', 'fabricFor', fabricFors],
            ['Fabric Type', 'fabricType', fabricTypes],
            ['Composition', 'composition', compositions],
            ['Finished GSM', 'finishedGsm', finishedGsms],
            ['Dye Type', 'dyeType', dyeTypes],
            ['Special Fabric Reference', 'specialFabricRef'],
            ['Special Finish', 'specialFinish', specialFinishes],
            ['Fabric Colour', 'fabricColour'],
            ['ELD', 'eld'],
            ['Is Dischargeable', 'isDischargeable', true],
            ['Is Yds', 'isYds', true],
            ['Is Engineered Stripe', 'isEngineeredStripe', true],
            ['Fabric From', 'fabFrom']
        ];

        return mapToCard(fabricDetails)(fabricDetailsMap);
    }
)(() => 'probably fabricIndex');
