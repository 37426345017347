import { change, initialize, destroy, formValueSelector } from 'redux-form';
import { put, takeLatest, select, call } from 'redux-saga/effects';

import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { isRedirectedSelector } from 'navigation/redux.selectors';
import { setLoading } from 'utilities/redux.actions';
import { routeToPrintWorker } from 'sampling/redux.sagas/printSagas';
import { routeToShapeWorker } from 'sampling/redux.sagas/shapeSagas';
import { FORM_NAME as PRINT_SAMPLE_FORM_NAME } from 'sampling/Print/common';
import { FORM_NAME as SHAPE_SAMPLE_FORM_NAME } from 'sampling/Shape/common';

import { FORM_NAME } from '../common';
import { versionsSelector, isShapedSelector } from '../redux.selectors';
import { destroyForm } from '../redux.actions';
import {
    ROUTE_TO_NEW,
    ROUTE_TO_AMEND,
    DESTROY_FORM,
    PUBLISH,
    GET_INCOMPLETE_DATA,
    GET_AMEND_DATA,
    RESET_LICENSOR_PROPERTY,
    ROUTE_TO_NEW_SAMPLE,
    SET_CAD_RETAILER_CHILDREN,
    SET_CAD_PROPERTY_CHILDREN,
    SET_ARTWORK_SOURCE
} from '../redux.actionTypes';
import { NEW_CAD_ROUTE_ID, AMEND_CAD_ROUTE_ID } from '../common';

import publishWorker from './publishWorker';
import getDataReferenceWorker from './getDataReferenceWorker';

// Workers
function* routeToNewWorker({ payload: { mode } }) {
    if (mode === 'new') yield put(destroyForm());

    yield put(redirectToRoute(NEW_CAD_ROUTE_ID)); // Route key changed
}

function* routeToAmendWorker({ payload: { mode } }) {
    if (mode !== 'amend') yield put(setRedirect(true));

    yield put(redirectToRoute(AMEND_CAD_ROUTE_ID)); // Route key changed
}

function* routeToNewSampleWorker({ payload: { mode } }) {
    const { cadReference, retailer, department } = yield select(
        formValueSelector(FORM_NAME),
        'cadReference',
        'retailer',
        'department'
    );
    const isShaped = yield select(isShapedSelector);
    yield put(setRedirect(true));

    let REDIRECT_FORM = '';

    if (isShaped) {
        REDIRECT_FORM = SHAPE_SAMPLE_FORM_NAME;
        yield call(routeToShapeWorker, { payload: { mode: 'new' } });
    } else {
        REDIRECT_FORM = PRINT_SAMPLE_FORM_NAME;
        yield call(routeToPrintWorker, { payload: { mode: 'new' } });
    }

    yield new Promise(res => setTimeout(() => res(), 0));
    yield put(change(REDIRECT_FORM, 'cad', cadReference));
    yield put(change(REDIRECT_FORM, 'retailer', retailer));

    yield new Promise(res => setTimeout(() => res(), 0));
    yield put(change(REDIRECT_FORM, 'department', department));
}

function* resetLicensorPropertyWorker() {
    yield put(change(FORM_NAME, 'licensor', ''));
    yield put(change(FORM_NAME, 'property', ''));
}

function* cadUploadDestroyWorker() {
    const isRedirected = yield select(isRedirectedSelector);

    if (!isRedirected) {
        const versions = yield select(versionsSelector);

        // URL blob clean up
        if (versions) {
            versions.forEach(versionUrl => URL.revokeObjectURL(versionUrl));
        }

        yield put(destroy(FORM_NAME));
    }

    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    yield put(setRedirect(false));
}

function* getIncompleteDataWorker({ payload }) {
    if (!!payload) {
        yield put(setLoading(true));
        const values = yield call(getDataReferenceWorker, payload);
        yield put(
            initialize(FORM_NAME, {
                ...values,
                incompleteReference: values.cadReference
            })
        );
        yield put(setLoading(false));
    }
}

const setCadRetailerChildrenWorker = function*() {
    yield put(change(FORM_NAME, 'department', ''));
};

const setCadPropertyChildrenWorker = function*() {
    yield put(change(FORM_NAME, 'property', ''));
};

function* getAmendDataWorker({ payload }) {
    if (!!payload) {
        yield put(setLoading(true));
        const values = yield call(getDataReferenceWorker, payload);
        yield put(
            initialize(FORM_NAME, {
                ...values,
                amendReference: values.cadReference
            })
        );
        yield put(setLoading(false));
    }
}

function* setArtworkSourceWorker({ payload: { field } }) {
    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    if (field === 'type') {
        //reset the licensor related value
        yield call(resetLicensorPropertyWorker);
    }
    const { type, designer } = yield select(
        formValueSelector(FORM_NAME),
        'type',
        'designer'
    );
    if (type === 2) {
        yield put(change(FORM_NAME, 'artworkSource', 2));
    } else {
        if (designer === 46) {
            yield put(change(FORM_NAME, 'artworkSource', 2));
        } else if (designer === 47) {
            yield put(change(FORM_NAME, 'artworkSource', 3));
        } else {
            yield put(change(FORM_NAME, 'artworkSource', 1));
        }
    }
}

// Watchers
export default function*() {
    yield takeLatest(PUBLISH, publishWorker);
    yield takeLatest(GET_INCOMPLETE_DATA, getIncompleteDataWorker);
    yield takeLatest(GET_AMEND_DATA, getAmendDataWorker);
    yield takeLatest(ROUTE_TO_NEW, routeToNewWorker);
    yield takeLatest(ROUTE_TO_AMEND, routeToAmendWorker);
    yield takeLatest(ROUTE_TO_NEW_SAMPLE, routeToNewSampleWorker);
    yield takeLatest(SET_CAD_RETAILER_CHILDREN, setCadRetailerChildrenWorker);
    yield takeLatest(SET_CAD_PROPERTY_CHILDREN, setCadPropertyChildrenWorker);
    yield takeLatest(DESTROY_FORM, cadUploadDestroyWorker);
    yield takeLatest(RESET_LICENSOR_PROPERTY, resetLicensorPropertyWorker);
    yield takeLatest(SET_ARTWORK_SOURCE, setArtworkSourceWorker);
}
