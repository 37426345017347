import { useMemo, useCallback } from 'react';

import { IMAGE_CONTAINER_WIDTH_UNITS } from '../../constants';
import {
    IMAGES_PER_ROW,
    IMAGES_PADDING_UNITS,
    IMAGE_LABEL_HEIGHT_UNITS,
    IMAGE_LABEL_FONT_SIZE
} from './constants';
import { useImagesState } from './useImages';

export const useImageStyles = ({ classes: c, theme: { spacing } }) => {
    const {
        imagesPerRow,
        imagesPaddingUnits,
        imageLabelHeightUnits,
        imageLabelFontSize
    } = useImagesState();

    const imageWidthUnits = useMemo(() => {
        return (
            (IMAGE_CONTAINER_WIDTH_UNITS -
                (1 + imagesPerRow) * 2 * imagesPaddingUnits) /
            imagesPerRow
        );
    }, [imagesPerRow, imagesPaddingUnits]);

    const container = useMemo(() => {
        const width = spacing.unit * imageWidthUnits;
        const height = spacing.unit * (imageWidthUnits * Math.sqrt(2));

        const margin = spacing.unit * imagesPaddingUnits;

        return {
            ...c.container,
            width,
            height,
            marginTop: margin,
            marginLeft: margin,
            marginRight: margin
        };
    }, [imagesPerRow, imagesPaddingUnits, imageWidthUnits]);

    const imageContainer = useMemo(() => {
        const height = spacing.unit * imageWidthUnits * Math.sqrt(2);

        return { ...c.imageContainer, height };
    }, []);

    const labelContainer = useMemo(() => {
        const height = spacing.unit * imageLabelHeightUnits;

        return { ...c.labelContainer, height };
    }, [imageLabelHeightUnits]);

    const label = useMemo(
        () => ({ ...c.label, fontSize: imageLabelFontSize }),
        [imageLabelFontSize]
    );

    return { ...c, container, imageContainer, labelContainer, label };
};

export const useInitializeStyles = ({
    maxHeight,
    imageCount,
    theme: { spacing }
}) => {
    const calculateImageHeight = useCallback(
        countPerRow => {
            const scale = IMAGES_PER_ROW / countPerRow;
            const imagesPaddingUnits = IMAGES_PADDING_UNITS * scale;
            const imageLabelHeightUnits = IMAGE_LABEL_HEIGHT_UNITS * scale;

            const imageWidthUnits =
                (IMAGE_CONTAINER_WIDTH_UNITS -
                    (1 + countPerRow) * 2 * imagesPaddingUnits) /
                countPerRow;

            // Add Image Height + Label Height + MarginTop
            const rowHeight =
                (imageWidthUnits * Math.sqrt(2) +
                    imageLabelHeightUnits +
                    imagesPaddingUnits) *
                spacing.unit;

            const rows = Math.ceil(imageCount / countPerRow);

            // Add total row height  + Padding height x 3 (top + bottom + extra bottom)
            return rowHeight * rows + imagesPaddingUnits * 3 * spacing.unit;
        },
        [imageCount, spacing]
    );

    const calculateCountPerRow = useCallback(
        countPerRow => {
            const imageHeight = calculateImageHeight(countPerRow);
            const canFit = imageHeight <= maxHeight;

            if (canFit) return countPerRow;
            else return calculateCountPerRow(countPerRow + 1);
        },
        [calculateImageHeight, maxHeight]
    );

    return useMemo(() => {
        const countPerRow = calculateCountPerRow(IMAGES_PER_ROW);
        const scale = IMAGES_PER_ROW / countPerRow;
        const imagesPaddingUnits = IMAGES_PADDING_UNITS * scale;
        const imagesHeight = calculateImageHeight(countPerRow);

        return {
            imagesHeight,
            imagesPerRow: imageCount > 1 ? countPerRow : 1,
            imageLabelHeightUnits: IMAGE_LABEL_HEIGHT_UNITS * scale,
            imageLabelFontSize: IMAGE_LABEL_FONT_SIZE * scale,
            imagesPaddingUnits
        };
    }, [calculateImageHeight, calculateCountPerRow]);
};
