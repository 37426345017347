import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import SelectModel from '@formComponents/SelectModel';

import { DOMAIN_NAME, FORM_NAME } from 'orders/Order/common';
import { PACKING_PACK_TYPE } from 'orders/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: PACKING_PACK_TYPE,
    name: 'packType',
    label: 'Pack Type',
    placeholder: 'Select Pack Type',
    disabled: false,
    required: false
};

const dataSelector = createCachedSelector(
    entitySelector,
    (state, domain, entity, retailer) => retailer,
    (data, retailer) =>
        data
            .filter(({ retailerId }) => Number(retailerId) === retailer)
            .map(({ id, packType }) => ({
                label: packType,
                value: id
            }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => {
    const retailer = formValueSelector(FORM_NAME)(state, 'retailer');
    return {
        data: dataSelector(state, domain, entity, retailer),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectPackingPackType = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectPackingPackType.propTypes = propTypes;
SelectPackingPackType.defaultProps = defaultProps;

export default SelectPackingPackType;
