import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
    formValueSelector,
    getFormInitialValues,
    getFormMeta,
    getFormValues
} from 'redux-form';

// Libs
import { valueSelector, entitySelector } from '@libs/datasets';
import { normalizeDate } from '@baseComponents/Date/normalizeDate';
import { deserializeFilename } from '@libs/filenames';

//other domain
import { BOUGHT_BYS, BOUGHT_LOCATIONS } from 'bought/redux.datasets';
import { DOMAIN_NAME as BOUGHT_DOMAIN_NAME } from 'bought/common';
import { DOMAIN_NAME as TRIM_DOMAIN_NAME } from 'trim/common';
import { TRIM_TYPES, TRIM_SUPPLIERS } from 'trim/redux.datasets';
import {
    developmentNameSelector,
    sizePathsSelector
} from 'common/redux.selectors';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';
import {
    SHAPE_DESIGNERS,
    GENDERS,
    AGES,
    CATEGORIES,
    STYLES,
    DEVELOPMENT_TYPES
} from 'common/redux.datasets';

// Local
import { ARTWORK_SOURCE_LICENSED_ID, MAIN_BODY_FABRIC_ID } from './common';
import { DOMAIN_NAME, FORM_NAME, DESIGN_SOURCE_BUYER_ID } from './Print/common';
import {
    DESIGN_SOURCE_IN_HOUSE_ID,
    FIT_OPTION_SEALED_ID,
    FORM_NAME as SHAPE_FORM_NAME
} from './Shape/common';
import {
    COUNTRIES,
    DEPARTMENTS,
    DESIGN_SOURCES,
    FABRIC_FORS,
    RETAILERS,
    SEASONS,
    SPEC_REFERENCES,
    GET_SAMPLE_SIZES,
    REQUEST_TYPES,
    FABRIC_TYPES,
    COMPOSITIONS,
    FINISHED_GSMS,
    DYE_TYPES,
    SPECIAL_FINISHES,
    SAMPLE_EMAIL_GROUP,
    FIT_OPTIONS,
    SIZE_RANGES,
    NECKLINES,
    ARMHOLES,
    HEMLINES,
    LABEL_TYPES,
    SAMPLE_EMAIL_BODY,
    WAISTBANDS,
    DISPATCH_TYPE,
    CURRIER,
    DISPATCH_SIZE,
    SAMPLE_FACTORY
} from './redux.datasets';
import { FORM_NAME as FINAL_UPLOAD_FORM_NAME } from 'sampling/FinalPhotoUpload/common';
import {
    FORM_NAME as SAMPLE_DISPATCH_FORM_NAME,
    SEASALT_ID,
    HAND_CARRIED_COURIER_ID
} from 'sampling/SampleDispatch/common';

const samplingSelector = state => state[DOMAIN_NAME];

const printFormSelector = formValueSelector(FORM_NAME);
const shapeFormSelector = formValueSelector(SHAPE_FORM_NAME);
const finalUploadFormSelector = formValueSelector(FINAL_UPLOAD_FORM_NAME);
const sampleDispatchFormSelector = formValueSelector(SAMPLE_DISPATCH_FORM_NAME);
const sampleDispatchFormAllValuesSelector = state =>
    getFormValues(SAMPLE_DISPATCH_FORM_NAME)(state);

const versionsSelector = createSelector(
    samplingSelector,
    ({ versions }) => versions
);

const versionsDataSelector = createSelector(
    versionsSelector,
    versions =>
        versions.map(({ imageVersion }) => ({
            label: imageVersion,
            value: imageVersion
        }))
);

const cadSizePathsSelector = createSelector(
    state => sizePathsSelector(state, 'cad'),
    sizePaths => {
        return Object.entries(sizePaths).reduce((acm, [key, path]) => {
            const [trimmedPath] = path.split('/').reverse();
            return { ...acm, [key]: trimmedPath };
        }, {});
    }
);

const previewsSelector = createSelector(
    versionsSelector,
    cadSizePathsSelector,
    (versions, { sizeOriginal, size100 }) => {
        return versions.map(({ imageUrl }) =>
            imageUrl.replace(sizeOriginal, size100)
        );
    }
);

const previewSelector = createSelector(
    versionsSelector,
    cadSizePathsSelector,
    (_, previewVersion) => previewVersion,
    state => formValueSelector(FORM_NAME)(state, 'version'),
    state => getFormMeta(FORM_NAME)(state),

    (
        versions,
        { sizeOriginal, size100, size800 },
        preview,
        version,
        { version: { active = false } = {} }
    ) => {
        const imageSize = active ? size100 : size800;

        const previewVersion = active ? preview : version;

        return versions.reduce((acm, { imageVersion, imageUrl }) => {
            if (imageVersion === previewVersion)
                return imageUrl.replace(sizeOriginal, imageSize);
            else return acm;
        }, '');
    }
);

const previewLoadingSelector = createSelector(
    samplingSelector,
    ({ versionsLoading }) => versionsLoading
);

const buyerCadImageSelector = createSelector(
    state => printFormSelector(state, 'cadImageForBuyer'),
    cadImage => cadImage
);

const fabricForMainBodyExistsSelector = createCachedSelector(
    (state, formName) => formValueSelector(formName)(state, 'fabrics'),
    fabrics =>
        fabrics.some(({ fabricFor = -1 }) => fabricFor === MAIN_BODY_FABRIC_ID)
)((state, formName) => `fabricForChecker-${formName}`);

const fabricForsSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, FABRIC_FORS),
    fabricForMainBodyExistsSelector,
    state => getFormInitialValues('fabric')(state),
    (fabricFors, mainBodyExists, { fabricFor = void 0 } = {}) => {
        // If current isn't index(filterIndex) && index(filterIndex) exists in others
        const shouldAlter = fabricFor !== MAIN_BODY_FABRIC_ID && mainBodyExists;

        return fabricFors.reduce((acm, { id, ...datum }) => {
            const [label] = Object.values(datum);

            if (shouldAlter && id === MAIN_BODY_FABRIC_ID) return acm;

            return [...acm, { label, value: id }];
        }, []);
    }
)((state, formName) => `fabricFor-${formName}`);

const retailersSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, RETAILERS),
    retailers =>
        retailers.map(({ id, retailer }) => ({ label: retailer, value: id }))
);

const departmentsSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, DEPARTMENTS),
    departments =>
        departments
            .filter(({ isActive }) => isActive)
            .map(({ id, department }) => ({ value: id, label: department }))
);

const countriesSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, COUNTRIES),
    state => entitySelector(state, DOMAIN_NAME, RETAILERS),
    (state, retailer) => retailer,
    (countriesDataset, retailers, retailer) => {
        const [{ countries = [] } = {}] = retailers.filter(
            ({ id }) => id === retailer
        );

        return countries.map(countryId => {
            const [{ id, name } = {}] = countriesDataset.filter(
                ({ id }) => Number(countryId) === id
            );
            return { label: name, value: id };
        });
    }
)((state, retailer) => `countries-${retailer}`);

const isLicensedSelector = createCachedSelector(
    (state, formName) => formValueSelector(formName)(state, 'designSource'),
    designSource => Number(designSource) === ARTWORK_SOURCE_LICENSED_ID
)((state, formName) => `isLicensed-${formName}`);

const specDescriptionSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, SPEC_REFERENCES),
    (state, specReference) => specReference,
    ({ data }, specReference) => {
        if (specReference) {
            const shapeData =
                data.find(spec => spec['Sample Ref'] === specReference) || {};
            return shapeData['Spec Desc'];
        } else return '';
    }
)((state, specReference) => `specDescription-${specReference}`);

const specReportURLSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, SPEC_REFERENCES),
    (state, specReference) => specReference,
    ({ data }, specReference) => {
        if (specReference) {
            const [specData = {}] = data.filter(
                spec => spec['Sample Reference'] === specReference
            );
            return specData['Shape Report URL'];
        } else return '';
    }
)((state, specReference) => `specReportURL-${specReference}`);

const fabricsLoadingSelector = createSelector(
    samplingSelector,
    ({ fabricsLoading }) => fabricsLoading
);

const boughtDetailsLoadingSelector = createSelector(
    samplingSelector,
    ({ boughtDetailsLoading }) => boughtDetailsLoading
);

const trimDetailsLoadingSelector = createSelector(
    samplingSelector,
    ({ trimDetailsLoading }) => trimDetailsLoading
);

const seasonsSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, SEASONS),
    (state, retailerId) => retailerId,
    (seasons, id) =>
        seasons
            .filter(({ retailerId }) => Number(retailerId) === Number(id))
            .map(({ id, collection }) => ({ label: collection, value: id }))
)((state, retailerId) => `seasons-${retailerId}`);

const deptNoSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, DEPARTMENTS),
    (state, departmentId) => departmentId,
    (departments, departmentId) =>
        departments.reduce(
            (acm, { id, departmentNo }) =>
                Number(id) === Number(departmentId) ? departmentNo : acm,
            ''
        )
)((state, departmentId) => `deptNo-${departmentId}`);

const isInHouseDesignSourceSelector = createSelector(
    state => formValueSelector(SHAPE_FORM_NAME)(state, 'designSource'),
    designSource => Number(designSource) === DESIGN_SOURCE_IN_HOUSE_ID
);

const isBuyerDesignSourceSelector = createSelector(
    state => formValueSelector(FORM_NAME)(state, 'designSource'),
    designSource => Number(designSource) === DESIGN_SOURCE_BUYER_ID
);

const isSealedFitOptionSelector = createSelector(
    state => formValueSelector(SHAPE_FORM_NAME)(state, 'fitOption'),
    fitOption => Number(fitOption) === FIT_OPTION_SEALED_ID
);

const sampleSizeSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, GET_SAMPLE_SIZES),
    sizes =>
        sizes.map(({ id, sampleSize, isDefault }) => ({
            label: sampleSize,
            value: id,
            isDefault
        }))
);

const fabricDetailsSelector = createSelector(
    (state, fabrics, from) => fabrics || [],
    (state, fabrics, from) => state,
    (fabrics, state) =>
        fabrics.reduce((acm, curr) => {
            let details = [];
            let obj = {};
            for (const property in curr) {
                switch (property) {
                    case 'fabricFor':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            FABRIC_FORS,
                            curr[property]
                        );
                        obj['label'] = 'Fabric For';
                        obj['order'] = 0;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'fabricType':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            FABRIC_TYPES,
                            curr[property]
                        );
                        obj['label'] = 'Fabric Type';
                        obj['order'] = 1;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'composition':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            COMPOSITIONS,
                            curr[property]
                        );
                        obj['label'] = 'Composition';
                        obj['order'] = 2;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'finishedGsm':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            FINISHED_GSMS,
                            curr[property]
                        );
                        obj['label'] = 'GSM';
                        obj['order'] = 3;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'dyeType':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            DYE_TYPES,
                            curr[property]
                        );
                        obj['label'] = 'Dye Type';
                        obj['order'] = 4;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'specialFabricRef':
                        obj['value'] = curr[property];
                        obj['label'] = 'Sp Fabric Ref';
                        obj['order'] = 5;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'specialFinish':
                        obj['value'] = valueSelector(
                            state,
                            DOMAIN_NAME,
                            SPECIAL_FINISHES,
                            curr[property]
                        );
                        obj['label'] = 'Special Finish';
                        obj['order'] = 6;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'fabricColour':
                        obj['value'] = curr[property];
                        obj['label'] = 'Fabric Color';
                        obj['order'] = 7;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'eld':
                        obj['value'] = curr[property];
                        obj['label'] = 'ELD';
                        obj['order'] = 8;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'isDischargeable':
                        obj['value'] = curr[property] ? 'YES' : 'NO';
                        obj['label'] = 'Dischargeable';
                        obj['order'] = 9;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'isYds':
                        obj['value'] = curr[property] ? 'YES' : 'NO';
                        obj['label'] = 'YDS';
                        obj['order'] = 10;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'isEngineeredStripe':
                        obj['value'] = curr[property] ? 'YES' : 'NO';
                        obj['label'] = 'Engi Stripe';
                        obj['order'] = 11;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'isTc':
                        obj['value'] = curr[property] ? 'YES' : 'NO';
                        obj['label'] = 'TC';
                        obj['order'] = 12;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'fabFrom':
                        obj['value'] = curr[property];
                        obj['label'] = 'Fab From';
                        obj['order'] = 13;
                        details = [...details, obj];
                        obj = {};
                        break;
                    default:
                        break;
                }
            }
            details = details
                .sort((a, b) => a.order - b.order)
                .filter(({ value }) => value)
                .map(({ order, ...rest }) => rest);
            return [...acm, details];
        }, [])
);

const boughtDetailsSelector = createSelector(
    (state, boughtDetails, from) => boughtDetails || [],
    (state, boughtDetails, from) => state,
    (boughtDetails, state) =>
        boughtDetails.reduce((acm, curr) => {
            let details = [];
            let obj = {};
            for (const property in curr) {
                switch (property) {
                    case 'boughtSampleRef':
                        obj['value'] = curr[property];
                        obj['label'] = 'Bought Sample Ref';
                        obj['order'] = 0;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'boughtBy':
                        obj['value'] = valueSelector(
                            state,
                            BOUGHT_DOMAIN_NAME,
                            BOUGHT_BYS,
                            curr[property]
                        );
                        obj['label'] = 'Bought By';
                        obj['order'] = 1;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'boughtLocation':
                        obj['value'] = valueSelector(
                            state,
                            BOUGHT_DOMAIN_NAME,
                            BOUGHT_LOCATIONS,
                            curr[property]
                        );
                        obj['label'] = 'Bought Location';
                        obj['order'] = 2;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'tripReference':
                        obj['value'] = curr[property];
                        obj['label'] = 'Trip Reference';
                        obj['order'] = 3;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'boughtDate':
                        obj['value'] = curr[property];
                        obj['label'] = 'Bought Date';
                        obj['order'] = 4;
                        details = [...details, obj];
                        obj = {};
                        break;
                    default:
                        break;
                }
            }
            details = details
                .sort((a, b) => a.order - b.order)
                .filter(({ value }) => value)
                .map(({ order, ...rest }) => rest);
            return [...acm, details];
        }, [])
);

const trimDetailsSelector = createSelector(
    (state, trimDetails, from) => trimDetails || [],
    (state, trimDetails, from) => state,
    (trimDetails, state) =>
        trimDetails.reduce((acm, curr) => {
            let details = [];
            let obj = {};
            for (const property in curr) {
                switch (property) {
                    case 'trimCardReference':
                        obj['value'] = curr[property];
                        obj['label'] = 'Trim Card Ref';
                        obj['order'] = 0;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'trimType':
                        obj['value'] = valueSelector(
                            state,
                            TRIM_DOMAIN_NAME,
                            TRIM_TYPES,
                            curr[property]
                        );
                        obj['label'] = 'Trim Type';
                        obj['order'] = 1;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'description':
                        obj['value'] = curr[property];
                        obj['label'] = 'Description';
                        obj['order'] = 2;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'size':
                        obj['value'] = curr[property];
                        obj['label'] = 'Size';
                        obj['order'] = 3;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'composition':
                        obj['value'] = curr[property];
                        obj['label'] = 'Composition';
                        obj['order'] = 4;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'trimSupplier':
                        obj['value'] = valueSelector(
                            state,
                            TRIM_DOMAIN_NAME,
                            TRIM_SUPPLIERS,
                            curr[property]
                        );
                        obj['label'] = 'Trim Supplier';
                        obj['order'] = 5;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'supplierRef':
                        obj['value'] = curr[property];
                        obj['label'] = 'Supplier Ref';
                        obj['order'] = 6;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'colour':
                        obj['value'] = curr[property];
                        obj['label'] = 'Colour';
                        obj['order'] = 7;
                        details = [...details, obj];
                        obj = {};
                        break;
                    case 'quantity':
                        obj['value'] = curr[property];
                        obj['label'] = 'Quantity';
                        obj['order'] = 8;
                        details = [...details, obj];
                        obj = {};
                        break;
                    default:
                        break;
                }
            }
            details = details
                .sort((a, b) => a.order - b.order)
                .filter(({ value }) => value)
                .map(({ order, ...rest }) => rest);
            return [...acm, details];
        }, [])
);

const printReportPropsSelector = createSelector(
    state =>
        printFormSelector(
            state,
            'developmentNumber',
            'requestType',
            'fitOption',
            'designSource',
            'retailer',
            'gender',
            'age',
            'category',
            'department',
            'style',
            'sendToEcho',
            'sendToBuyer',
            'sendToLicensor',
            'country',
            'fabrics',
            'sampleReference',
            'dateRequested',
            'cad',
            'version',
            'cadDescription',
            'dateRequiredBy',
            'buyer',
            'specDescription',
            'specReference',
            'hasCopyReference',
            'copyReference',
            'hasManualReference',
            'manualReference',
            'photoApproval',
            'numberOfColours',
            'techPack',
            'attachments',
            'additionalComments',
            'artworkLinks',
            'boughtDetails',
            'cadImageForBuyer'
        ),
    state => state,
    (_, mode) => mode,
    (values, state, mode) => {
        const {
            cad,
            version,
            developmentNumber,
            requestType: rt,
            fitOption: fo,
            designSource: ds,
            retailer: retail,
            department: dept,
            sendToEcho: ste,
            sendToBuyer: stb,
            sendToLicensor: stl,
            country: c,
            hasCopyReference,
            copyReference,
            hasManualReference,
            manualReference,
            sampleReference,
            dateRequested,
            dateRequiredBy,
            buyer,
            photoApproval,
            cadDescription,
            specReference,
            specDescription,
            fabrics,
            boughtDetails,
            cadImageForBuyer,
            ...rest
        } = values;
        const developmentName = developmentNameSelector(
            state,
            'Print',
            mode === 'amend' ? developmentNumber + 1 : developmentNumber
        );
        const requestType = valueSelector(
            state,
            DOMAIN_NAME,
            REQUEST_TYPES,
            rt
        );
        const fitOption = valueSelector(state, DOMAIN_NAME, FIT_OPTIONS, fo);
        const designSourceName = valueSelector(
            state,
            DOMAIN_NAME,
            DESIGN_SOURCES,
            ds
        );
        const retailer = valueSelector(state, DOMAIN_NAME, RETAILERS, retail);
        const gender = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            GENDERS,
            values.gender
        );
        const age = valueSelector(state, COMMON_DOMAIN_NAME, AGES, values.age);
        const category = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            CATEGORIES,
            values.category
        );
        const department = valueSelector(state, DOMAIN_NAME, DEPARTMENTS, dept);
        const style = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            STYLES,
            values.style
        );
        const sendToEcho = valueSelector(
            state,
            DOMAIN_NAME,
            GET_SAMPLE_SIZES,
            ste
        );
        const sendToBuyer = valueSelector(
            state,
            DOMAIN_NAME,
            GET_SAMPLE_SIZES,
            stb
        );
        const sendToLicensor = valueSelector(
            state,
            DOMAIN_NAME,
            GET_SAMPLE_SIZES,
            stl
        );
        const country = valueSelector(state, DOMAIN_NAME, COUNTRIES, c);
        const fabricDetails = fabricDetailsSelector(state, fabrics, 'print');
        const boughtDetailsValue = boughtDetailsSelector(
            state,
            boughtDetails,
            'print'
        );
        const cadImage = previewSelector(state, version);

        return {
            setup: {
                cad,
                version,
                cadDescription,
                designSourceName,
                retailer,
                gender,
                age,
                category,
                department,
                style,
                country,
                hasCopyReference,
                copyReference,
                hasManualReference,
                manualReference
            },
            details: {
                sampleReference,
                developmentName,
                requestType,
                fitOption,
                dateRequested,
                dateRequiredBy,
                sendToEcho,
                sendToBuyer,
                sendToLicensor,
                buyer,
                photoApproval
            },
            specDetails: {
                specReference,
                specDescription
            },
            fabricDetails: {
                data: fabricDetails,
                count: fabricDetails.length
            },
            others: {
                boughtDetails: {
                    data: boughtDetailsValue,
                    count: boughtDetailsValue.length
                },
                ...rest
            },
            cadInfo: {
                version: cadImage || cadImageForBuyer,
                name: cad
            },
            docTitle: `${sampleReference}_${cad}_${developmentName}_print_report.pdf`,
            pageTitle: `SRP, ${sampleReference} ${!!cad ? cad : ''} ${
                !!version ? version : ''
            } ${!!cadDescription ? cadDescription : ''}`
        };
    }
);

const emailGroupsSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, SAMPLE_EMAIL_GROUP),
    groups => groups
);

const emailBodySelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, SAMPLE_EMAIL_BODY),
    templates => templates
);

const printMailSubjectSelector = createSelector(
    state =>
        printFormSelector(
            state,
            'sampleReference',
            'developmentNumber',
            'cad',
            'cadDescription'
        ),
    state => state,
    (state, mode) => mode,
    (
        { sampleReference, developmentNumber, cad, cadDescription },
        state,
        mode
    ) => {
        let requestType;
        if (mode === 'amend') {
            requestType = 'Amend';
            developmentNumber += 1;
        } else if (mode === 'handover') {
            requestType = 'HAND OVER';
        } else {
            requestType = 'New';
        }
        const developmentName = developmentNameSelector(
            state,
            'Print',
            developmentNumber
        );
        const cadText = cad ? cad : '';
        const cadDescriptionText = cadDescription ? cadDescription : '';

        return `${requestType} Print Request - ${sampleReference} ${developmentName} ${cadText} ${cadDescriptionText}`;
    }
);

const shapeMailSubjectSelector = createSelector(
    state => shapeFormSelector(state, 'sampleReference', 'developmentNumber'),
    state => state,
    (state, mode) => mode,
    ({ sampleReference, developmentNumber }, state, mode) => {
        let requestType;
        if (mode === 'amend') {
            requestType = 'Amend';
            developmentNumber += 1;
        } else if (mode === 'handover') {
            requestType = 'HAND OVER';
        } else {
            requestType = 'New';
        }

        const developmentName = developmentNameSelector(
            state,
            'Non Print',
            developmentNumber
        );

        return `${requestType} Shape Request - ${sampleReference} ${developmentName}`;
    }
);

const shapeReportPropsSelector = createSelector(
    state => getFormValues(SHAPE_FORM_NAME)(state),
    state => state,
    (_, mode) => mode,
    (values, state, mode) => {
        const {
            retailer: retail,
            department: dept,
            designSource: ds,
            country: c,
            shapeDesigner: sd,
            sendToEcho: ste,
            sendToBuyer: stb,
            season: ssn,
            category: cat,
            developmentNumber,
            fitOption: fo,
            sizeRange: sr,
            neckline: nl,
            waistband: wb,
            armhole: ah,
            hemline: hl,
            labelType: lt,
            fabrics,
            ...rest
        } = values || {};

        if (!values) {
            return {
                setup: {},
                details: {},
                specDetails: {},
                fabricDetails: {},
                others: {},
                cadInfo: {},
                docTitle: '',
                pageTitle: ''
            };
        }

        const retailer = valueSelector(state, DOMAIN_NAME, RETAILERS, retail);
        const gender = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            GENDERS,
            values.gender
        );
        const age = valueSelector(state, COMMON_DOMAIN_NAME, AGES, values.age);
        const category = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            CATEGORIES,
            values.category
        );
        const department = valueSelector(state, DOMAIN_NAME, DEPARTMENTS, dept);
        const style = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            STYLES,
            values.style
        );
        const designSourceName = valueSelector(
            state,
            DOMAIN_NAME,
            DESIGN_SOURCES,
            ds
        );
        const country = valueSelector(state, DOMAIN_NAME, COUNTRIES, c);
        const shapeDesigner = valueSelector(
            state,
            COMMON_DOMAIN_NAME,
            SHAPE_DESIGNERS,
            sd
        );

        const sendToEcho = valueSelector(
            state,
            DOMAIN_NAME,
            GET_SAMPLE_SIZES,
            ste
        );
        const sendToBuyer = valueSelector(
            state,
            DOMAIN_NAME,
            GET_SAMPLE_SIZES,
            stb
        );
        const season = valueSelector(state, DOMAIN_NAME, SEASONS, ssn);
        const developmentName = developmentNameSelector(
            state,
            'Non Print',
            mode === 'amend' ? developmentNumber + 1 : developmentNumber
        );
        const fitOption = valueSelector(state, DOMAIN_NAME, FIT_OPTIONS, fo);
        const sizeRange = valueSelector(state, DOMAIN_NAME, SIZE_RANGES, sr);
        const neckline = valueSelector(state, DOMAIN_NAME, NECKLINES, nl);
        const waistband = valueSelector(state, DOMAIN_NAME, WAISTBANDS, wb);
        const armhole = valueSelector(state, DOMAIN_NAME, ARMHOLES, ah);
        const hemline = valueSelector(state, DOMAIN_NAME, HEMLINES, hl);
        const labelType = valueSelector(state, DOMAIN_NAME, LABEL_TYPES, lt);
        const fabricDetails = fabricDetailsSelector(state, fabrics, 'shape');
        const boughtDetails = boughtDetailsSelector(
            state,
            rest.boughtDetails,
            'shape'
        );
        const trimDetails = trimDetailsSelector(
            state,
            rest.trimDetails,
            'shape'
        );

        return {
            setup: {
                retailer,
                gender,
                age,
                category,
                department,
                style,
                designSourceName,
                country,
                shapeDesigner,
                hasCopyReference: rest.hasCopyReference,
                copyReference: rest.copyReference,
                hasManualReference: rest.hasManualReference,
                manualReference: rest.manualReference,
                dateRequested: rest.dateRequested,
                dateRequiredBy: rest.dateRequiredBy,
                sendToEcho,
                sendToBuyer,
                buyer: rest.buyer,
                season
            },
            refAndDetails: {
                sampleReference: rest.sampleReference,
                developmentName,
                fitOption,
                styleDescription: rest.styleDescription,
                specReference: rest.specReference,
                specDescription: rest.specDescription,
                departmentNumber: rest.departmentNumber,
                referenceFrom: rest.referenceFrom,
                sizeRange,
                neckline,
                waistband,
                armhole,
                hemline,
                labelType,
                pendingPrint: rest.pendingPrint,
                photoApproval: rest.photoApproval
            },
            fabricDetails: {
                data: fabricDetails,
                count: fabricDetails.length
            },
            front: rest.front,
            back: rest.back,
            samples: [rest.front, rest.back],
            others: {
                attachments: rest.attachments,
                additionalComments: rest.additionalComments
            },
            boughtDetails: {
                data: boughtDetails,
                count: boughtDetails.length
            },
            trimDetails: {
                data: trimDetails,
                count: trimDetails.length
            },
            docTitle: `${rest.sampleReference}_${developmentName}_shape_report.pdf`,
            pageTitle: `SAMPLE REQUEST SHAPE, ${rest.sampleReference}`
        };
    }
);
const referenceLoadingSelector = createSelector(
    samplingSelector,
    ({ referenceLoading }) => referenceLoading
);

const sampleGridPreviewFabricsSelector = fabrics => {
    const properties = {
        fabricFor: { label: 'Fabric For', order: 0 },
        fabricType: { label: 'Fabric Type', order: 1 },
        composition: { label: 'Composition', order: 2 },
        finishedGsm: { label: 'GSM', order: 3 },
        gsm: { label: 'GSM', order: 3 },
        dyeType: { label: 'Dye Type', order: 4 },
        specialFabricRef: { label: 'Sp Fabric Ref', order: 5 },
        specialFinish: { label: 'Special Finish', order: 6 },
        fabricColour: { label: 'Fabric Color', order: 7 },
        eld: { label: 'ELD', order: 8 },
        isDischargeable: { label: 'Dischargeable', order: 9 },
        isYds: { label: 'YDS', order: 10 },
        isEngineeredStripe: { label: 'Engi Stripe', order: 11 },
        isTc: { label: 'TC', order: 12 },
        fabFrom: { label: 'Fab From', order: 13 }
    };
    return fabrics.map(curr => {
        const details = [];

        for (const property in curr) {
            if (properties.hasOwnProperty(property)) {
                const obj = {
                    value: curr[property],
                    ...properties[property]
                };
                if (obj.value) {
                    details.push(obj);
                }
            }
        }

        return details
            .sort((a, b) => a.order - b.order)
            .map(({ order, ...rest }) => rest);
    });
};

const sampleGridPreviewBoughtsSelector = boughts => {
    const properties = {
        boughtSampleRef: { label: 'Bought Sample Ref', order: 0 },
        boughtBy: { label: 'Bought By', order: 1 },
        boughtLocation: { label: 'Bought Location', order: 2 },
        tripReference: { label: 'Trip Reference', order: 3 },
        boughtDate: { label: 'Bought Date', order: 4 }
    };
    return boughts.map(curr => {
        const details = [];

        for (const property in curr) {
            if (properties.hasOwnProperty(property)) {
                const obj = {
                    value: curr[property],
                    ...properties[property]
                };
                if (obj.value) {
                    details.push(obj);
                }
            }
        }

        return details
            .sort((a, b) => a.order - b.order)
            .map(({ order, ...rest }) => rest);
    });
};

const sampleGridPreviewTrimsSelector = trims => {
    const properties = {
        trimCardReference: { label: 'Trim Card Ref', order: 0 },
        trimType: { label: 'Trim Type', order: 1 },
        description: { label: 'Description', order: 2 },
        size: { label: 'Size', order: 3 },
        composition: { label: 'Composition', order: 4 },
        trimSupplier: { label: 'Trim Supplier', order: 5 },
        supplierRef: { label: 'Supplier Ref', order: 6 },
        colour: { label: 'Colour', order: 7 },
        quantity: { label: 'Quantity', order: 8 }
    };
    return trims.map(curr => {
        const details = [];

        for (const property in curr) {
            if (properties.hasOwnProperty(property)) {
                const obj = {
                    value: curr[property],
                    ...properties[property]
                };
                if (obj.value) {
                    details.push(obj);
                }
            }
        }

        return details
            .sort((a, b) => a.order - b.order)
            .map(({ order, ...rest }) => rest);
    });
};

const printSampleGridPreviewDataSelector = createSelector(
    data => data,
    data => {
        const attachments = data.attachments || [];
        const emailSubject = `Print Request- ${data.sampleRef} ${data.developmentName} ${data.cadRef} ${data.graphicDescription}`;
        const downloadAbleAttachments = [
            { id: 0, sizeOriginal: data.techPackPdfUrl },
            { id: 1, sizeOriginal: data.shapeReportLink },
            ...attachments
        ];
        const reportLink = data.reportLink;
        const retailer = data.retailerId;
        const developmentNumber = data.developmentNo;
        const developmentName = data.developmentName;
        const previewTitle = `SRP Preview - ${data.sampleRef}`;
        if (reportLink)
            return {
                retailer,
                developmentNumber,
                developmentName,
                emailSubject,
                downloadAbleAttachments,
                previewTitle,
                storedPDFData: {
                    url: data.reportLink,
                    filename: deserializeFilename(data.reportLink)
                }
            };

        const version =
            data.versions.find(
                ({ imageVersion }) => imageVersion === data.cadVersion
            ) || {};
        return {
            setup: {
                cad: data.cadRef,
                version: data.cadVersion,
                cadDescription: data.graphicDescription,
                designSourceName: data.designerFrom,
                retailer: data.retailer,
                department: data.department,
                gender: data.gender,
                age: data.age,
                category: data.category,
                style: data.style,
                country: data.country
            },
            details: {
                sampleReference: data.sampleRef,
                developmentName: data.developmentName,
                requestType: data.requestType,
                dateRequested: normalizeDate(data.requestedDate),
                dateRequiredBy: normalizeDate(data.sampleRequiredDate),
                sendToEcho: data.ukSize,
                sendToBuyer: data.buyerSize,
                sendToLicensor: data.licensorSize,
                buyer: data.departmentContactPerson,
                photoApproval: data.isSampleRequired
            },
            specDetails: {
                specReference: data.specRef,
                specDescription: data.garmentDescription
            },
            fabricDetails: {
                data: sampleGridPreviewFabricsSelector(data.fabrics),
                count: data.fabrics.length
            },
            others: {
                boughtDetails: {
                    data: sampleGridPreviewBoughtsSelector(data.boughts),
                    count: data.boughts.length
                },
                attachments: data.attachments,
                numberOfColours: data.numberOfColours,
                techPack: data.techPackPdfUrl,
                artworkLinks: data.artworkLinks,
                additionalComments: data.specComments
            },
            cadInfo: {
                version: version.size350 || data.cadImageForBuyer,
                name: data.cadRef || ''
            },
            docTitle: `${data.sampleRef}${
                data.cadRef ? `_${data.cadRef}_` : ''
            }${data.developmentName}_print_report.pdf`,
            pageTitle: `SRP, ${data.sampleRef} ${data.cadRef ||
                ''} ${data.cadVersion || ''} ${data.graphicDescription || ''}`,
            previewTitle,
            retailer,
            developmentNumber,
            developmentName,
            emailSubject,
            downloadAbleAttachments
        };
    }
);

const shapeSampleGridPreviewDataSelector = createSelector(
    data => data,
    data => {
        const attachments = data.attachments || [];
        const emailSubject = `Shape Request- ${data.sampleRef} ${data.developmentName}`;
        const downloadAbleAttachments = attachments;
        const reportLink = data.reportLink;
        const retailer = data.retailerId;
        const developmentNumber = data.developmentNo;
        const developmentName = data.developmentName;
        const previewTitle = `Shape Preview - ${data.sampleRef}`;
        if (reportLink)
            return {
                retailer,
                developmentNumber,
                developmentName,
                emailSubject,
                downloadAbleAttachments,
                previewTitle,
                storedPDFData: {
                    url: data.reportLink,
                    filename: deserializeFilename(data.reportLink)
                }
            };

        const frontImage = !!data.frontImage.length
            ? data.frontImage[0].size350
            : null;
        const backImage = !!data.backImage.length
            ? data.backImage[0].size350
            : null;

        return {
            setup: {
                retailer: data.retailer,
                department: data.department,
                gender: data.gender,
                age: data.age,
                category: data.category,
                style: data.style,
                designSourceName: data.designerFrom,
                country: data.country,
                shapeDesigner: data.shapeDesigner,
                dateRequested: normalizeDate(data.requestedDate),
                dateRequiredBy: normalizeDate(data.sampleRequiredDate),
                sendToEcho: data.ukSize,
                sendToBuyer: data.buyerSize,
                buyer: data.departmentContactPerson,
                season: data.season
            },
            refAndDetails: {
                sampleReference: data.sampleRef,
                developmentName: data.developmentName,
                fitOption: data.fit,
                specReference: data.specRef,
                specDescription: data.styleDescription,
                styleDescription: data.garmentDescription,
                departmentNumber: data.departmentNumber,
                referenceFrom: data.specRefFrom,
                sizeRange: data.sizeRange,
                neckline: data.neckline,
                armhole: data.armholesCuffs,
                hemline: data.hemline,
                labelType: data.labelType,
                pendingPrint: data.isPendingPrint,
                photoApproval: data.isSampleRequired
            },
            fabricDetails: {
                data: sampleGridPreviewFabricsSelector(data.fabrics),
                count: data.fabrics.length
            },
            front: frontImage,
            back: backImage,
            samples:
                !!frontImage || !!backImage
                    ? [{ url: frontImage }, { url: backImage }]
                    : [],
            others: {
                attachments: data.attachments,
                additionalComments: data.specComments
            },
            boughtDetails: {
                data: sampleGridPreviewBoughtsSelector(data.boughts),
                count: data.boughts.length
            },
            trimDetails: {
                data: sampleGridPreviewTrimsSelector(data.trims),
                count: data.trims.length
            },
            downloadAbleAttachments,
            docTitle: `${data.sampleRef}_${data.developmentName}_shape_report.pdf`,
            pageTitle: `SAMPLE REQUEST SHAPE, ${data.sampleRef}`,
            emailSubject
        };
    }
);

const samplingGridPreviewDataSelector = createCachedSelector(
    data => data,
    (data, sampleRef) => sampleRef,
    (data, sampleRef) => {
        if (
            !data ||
            Object.entries(data).length === 0 ||
            data.sampleRef !== sampleRef
        ) {
            return {};
        }

        return data.sampleType === 'PRINT'
            ? {
                  ...printSampleGridPreviewDataSelector(data),
                  sampleType: 'print'
              }
            : {
                  ...shapeSampleGridPreviewDataSelector(data),
                  sampleType: 'shape'
              };
    }
)((data, sampleRef) => `sampling-grid-data-preview-${sampleRef}`);

const getDataForSelectedRowsWithSamplesSelector = createSelector(
    data => data,
    (_, selectedRows) => selectedRows,
    (data, selectedRows) => {
        const selectedSamples = data.filter(sample =>
            selectedRows.includes(sample.id)
        );

        // Check if both PRINT and SHAPE types exist in selectedSamples
        const hasPrint = selectedSamples.some(
            sample => sample.sampleType === 'P'
        );
        const hasShape = selectedSamples.some(
            sample => sample.sampleType === 'NP'
        );

        const hasBoth = hasPrint && hasShape;

        const sampleType =
            !!selectedSamples.length && selectedSamples[0].sampleType;

        return { sampleType, hasBoth, selectedSamples };
    }
);

const downloadSamplesAsExcelLoadingSelector = createSelector(
    samplingSelector,
    ({ downloadSamplesAsExcelLoading }) => downloadSamplesAsExcelLoading
);

const downloadSamplesAsMultiPageLoadingSelector = createSelector(
    samplingSelector,
    ({ downloadSamplesAsMultiPageLoading }) => downloadSamplesAsMultiPageLoading
);

const downloadSamplesAsSinglePageLoadingSelector = createSelector(
    samplingSelector,
    ({ downloadSamplesAsSinglePageLoading }) =>
        downloadSamplesAsSinglePageLoading
);

const downloadSamplesAsCostFormLoadingSelector = createSelector(
    samplingSelector,
    ({ downloadSamplesAsCostFormLoading }) => downloadSamplesAsCostFormLoading
);

const finalUploadPDFPropsSelector = createSelector(
    state =>
        finalUploadFormSelector(
            state,
            'sampleRef',
            'developmentNo',
            'department',
            'buyer',
            'garmentsType',
            'initialCad',
            'finalCad',
            'initialFront',
            'initialBack',
            'finalFront',
            'finalBack'
        ),
    ({
        garmentsType,
        initialCad,
        finalCad,
        initialFront,
        finalFront,
        initialBack,
        finalBack,
        ...values
    }) => {
        const images = [];
        if (garmentsType === 'P') {
            initialCad &&
                initialCad.url &&
                images.push({ ...initialCad, title: 'Initial CAD' });
            finalCad &&
                finalCad.url &&
                images.push({
                    ...finalCad,
                    title: 'Final Image'
                });
        } else {
            initialFront &&
                initialFront.url &&
                images.push({ ...initialFront, title: 'Initial Front' });
            finalFront &&
                finalFront.url &&
                images.push({
                    ...finalFront,
                    title: 'Final Front'
                });
            initialBack &&
                initialBack.url &&
                images.push({ ...initialBack, title: 'Initial Back' });
            finalBack &&
                finalBack.url &&
                images.push({
                    ...finalBack,
                    title: 'Final Back'
                });
        }
        return {
            ...values,
            images
        };
    }
);

const finalPhotoEmailLoadingSelector = createSelector(
    samplingSelector,
    ({ finalPhotoEmailLoading }) => finalPhotoEmailLoading
);

const finalPhotoDateLoadingSelector = createSelector(
    samplingSelector,
    ({ finalPhotoDataLoading }) => finalPhotoDataLoading
);
const sampleDispatchReferenceLoadingSelector = createSelector(
    samplingSelector,
    ({ dispatchReferenceLoading }) => dispatchReferenceLoading
);

const dispatchTypeCategorySelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, DISPATCH_TYPE),
    (state, dispatchId) => dispatchId,
    (data, dispatchId) => {
        if (dispatchId) {
            const { isManual, IsOrder } = data.find(
                ({ id }) => id === Number(dispatchId)
            );
            if (IsOrder) {
                return 'order';
            }
            if (isManual) {
                return 'manual';
            }
            if (!isManual && !IsOrder) {
                return 'sample';
            }
        } else return '';
    }
)((state, dispatchId) => `dispatch-type-${dispatchId}`);

const dispatchPDFPropsSelector = createSelector(
    state => sampleDispatchFormAllValuesSelector(state),
    state => state,
    (_, mode) => mode,
    (values, state, mode) => {
        const {
            retailer,
            originCountry,
            destinationCountry,
            dispatchReference,
            versionNo,
            trackingReference,
            fao,
            actualSendDate,
            eta,
            currier,
            dispatchDetails = []
        } = values;

        const version =
            mode === 'amend' ? `V${versionNo + 1}` : `V${versionNo}`;

        // Calculate total quantity
        const totalQuantity = dispatchDetails.reduce(
            (totalQuantity, { quantity }) => totalQuantity + (quantity || 0),
            0
        );

        return {
            values: {
                retailer: valueSelector(
                    state,
                    DOMAIN_NAME,
                    RETAILERS,
                    retailer
                ),
                originCountry: valueSelector(
                    state,
                    DOMAIN_NAME,
                    COUNTRIES,
                    originCountry
                ),
                destinationCountry: valueSelector(
                    state,
                    DOMAIN_NAME,
                    COUNTRIES,
                    destinationCountry
                ),
                dispatchReference,
                versionNo: version,
                fao,
                currier: valueSelector(state, DOMAIN_NAME, CURRIER, currier),
                actualSendDate,
                eta,
                trackingReference: {
                    value: trackingReference,
                    isMandatory: HAND_CARRIED_COURIER_ID !== currier
                },

                dispatchDetails: dispatchDetails.map(
                    ({
                        dispatchType,
                        size,
                        factory,
                        season,
                        PONumbers = [],
                        ...details
                    }) => ({
                        ...details,
                        dispatchType: valueSelector(
                            state,
                            DOMAIN_NAME,
                            DISPATCH_TYPE,
                            dispatchType
                        ),
                        size: valueSelector(
                            state,
                            DOMAIN_NAME,
                            DISPATCH_SIZE,
                            size
                        ),
                        factory: valueSelector(
                            state,
                            DOMAIN_NAME,
                            SAMPLE_FACTORY,
                            factory
                        ),
                        season: valueSelector(
                            state,
                            DOMAIN_NAME,
                            SEASONS,
                            season
                        ),
                        po: PONumbers.join(',')
                    })
                ),
                totalQuantity,
                isSeasalt: isSeasaltSelector(state)
            },
            docTitle: 'Sample Dispatch Report',
            pageTitle: 'PRODUCT DISPATCH NOTIFICATION'
        };
    }
);

const dispatchMailSubject = createSelector(
    state =>
        sampleDispatchFormSelector(
            state,
            'dispatchReference',
            'department',
            'currier',
            'trackingReference'
        ),
    state => state,
    ({ dispatchReference, currier, trackingReference }, state) => {
        const currierValue = valueSelector(
            state,
            DOMAIN_NAME,
            CURRIER,
            currier
        );
        return `Dispatch Notification - ${dispatchReference} ${currierValue} ${trackingReference}`;
    }
);

const isSeasaltSelector = createSelector(
    state => sampleDispatchFormSelector(state, 'retailer'),
    retailer => retailer === SEASALT_ID
);

const retailerIdSelectorByName = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, RETAILERS),
    (state, retailerName) => retailerName,
    (retailers, retailerName) => {
        const retailerData =
            retailers.find(({ retailer }) => retailer === retailerName) || {};
        return retailerData.id;
    }
)((state, retailerName) => `retailers-${retailerName}`);

const sampleDevIdSelectorByName = createCachedSelector(
    state => entitySelector(state, COMMON_DOMAIN_NAME, DEVELOPMENT_TYPES),
    (state, devName) => devName,
    (state, devName, type) => type,
    (types, devName, type) => {
        const devType =
            types.find(
                ({ developmentName, developmentModuleType }) =>
                    developmentName === devName &&
                    developmentModuleType === type
            ) || {};
        return devType.id;
    }
)((state, devName, type) => `sample-dev-types-${devName}-${type}`);

const dispatchGridPreviewDataSelector = createCachedSelector(
    data => data,
    (data, dispatchRef) => dispatchRef,
    (data, dispatchRef) => {
        if (
            !data ||
            Object.entries(data).length === 0 ||
            data.dispatchRef !== dispatchRef
        ) {
            return {};
        }

        const emailSubject = `Dispatch Notification - ${dispatchRef} ${data.currier} ${data.trackingReference}`;
        const previewTitle = `Dispatch Preview - ${dispatchRef}`;
        const versionNo = `V${data.versionNo}`;
        const retailer = data.retailerId;

        if (data.reportLink) {
            return {
                emailSubject,
                storedPDFData: {
                    url: data.reportLink,
                    filename: deserializeFilename(data.reportLink)
                },
                previewTitle,
                versionNo,
                retailer
            };
        }

        const totalQuantity = data.dispatchDetails.reduce(
            (totalQuantity, { quantity = 0 }) => totalQuantity + quantity,
            0
        );

        return {
            values: {
                ...data,
                dispatchReference: dispatchRef,
                versionNo,
                fao: data.notifyFAO,
                actualSendDate: normalizeDate(data.actSentDate),
                eta: normalizeDate(data.eta),
                trackingReference: {
                    value: data.trackingReference,
                    isMandatory: 'HAND CARRIED' !== data.currier
                },
                dispatchDetails: data.dispatchDetails.map(
                    ({ pos = [], ...details }) => ({
                        ...details,
                        dispatchType: details.type,
                        po: pos.map(({ poNo }) => poNo).join(',')
                    })
                ),
                totalQuantity,
                isSeasalt: data.retailerId === SEASALT_ID
            },
            versionNo: data.versionNo,
            emailSubject,
            retailer,
            docTitle: 'Sample Dispatch Report',
            pageTitle: 'PRODUCT DISPATCH NOTIFICATION'
        };
    }
)((data, dispatchRef) => `dispatch-grid-preview-${dispatchRef}`);

const dispatchExportExcelDataLoadingSelector = createSelector(
    samplingSelector,
    ({ dispatchExportExcelDataLoading }) => dispatchExportExcelDataLoading
);

const samplingReportDataSelector = createSelector(
    samplingSelector,
    ({ samplingReportData }) => samplingReportData
);

const samplingReportDataLoadingSelector = createSelector(
    samplingSelector,
    ({ samplingReportDataLoading }) => samplingReportDataLoading
);

export {
    versionsDataSelector,
    previewsSelector,
    previewSelector,
    previewLoadingSelector,
    fabricForMainBodyExistsSelector,
    fabricForsSelector,
    retailersSelector,
    countriesSelector,
    isLicensedSelector,
    specDescriptionSelector,
    fabricsLoadingSelector,
    boughtDetailsLoadingSelector,
    trimDetailsLoadingSelector,
    seasonsSelector,
    deptNoSelector,
    isInHouseDesignSourceSelector,
    sampleSizeSelector,
    printReportPropsSelector,
    emailGroupsSelector,
    shapeReportPropsSelector,
    specReportURLSelector,
    referenceLoadingSelector,
    emailBodySelector,
    printMailSubjectSelector,
    shapeMailSubjectSelector,
    downloadSamplesAsExcelLoadingSelector,
    downloadSamplesAsMultiPageLoadingSelector,
    downloadSamplesAsSinglePageLoadingSelector,
    downloadSamplesAsCostFormLoadingSelector,
    isSealedFitOptionSelector,
    isBuyerDesignSourceSelector,
    buyerCadImageSelector,
    finalUploadPDFPropsSelector,
    finalPhotoEmailLoadingSelector,
    finalPhotoDateLoadingSelector,
    sampleDispatchReferenceLoadingSelector,
    dispatchTypeCategorySelector,
    sampleDispatchFormSelector,
    dispatchPDFPropsSelector,
    dispatchMailSubject,
    isSeasaltSelector,
    samplingGridPreviewDataSelector,
    sampleDevIdSelectorByName,
    retailerIdSelectorByName,
    dispatchGridPreviewDataSelector,
    dispatchExportExcelDataLoadingSelector,
    getDataForSelectedRowsWithSamplesSelector,
    departmentsSelector,
    samplingReportDataSelector,
    samplingReportDataLoadingSelector
};
