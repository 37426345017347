import React, { useState, useCallback } from 'react';
import {
    withStyles,
    Dialog,
    DialogContent,
    IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { deserializeFilename } from '@libs/filenames';

import ImageLoader from './ImageLoader';
import NavigationControls from './NavigationControls';
import ImageInfo from './ImageInfo';

const styles = ({ spacing, palette, shape }) => ({
    dialogContent: {
        position: 'relative',
        padding: 0,
        '&:first-child': {
            padding: 0
        }
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        backgroundColor: palette.grey[200],
        padding: spacing.unit * 0.5,
        '&:hover': {
            backgroundColor: palette.grey[300]
        }
    }
});

const Slider = withStyles(styles)(({ images, handleClose, classes }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalImages = images.length;
    const showControls = totalImages > 1;
    const currentImagePath = images[currentIndex].size800;
    const currentImageName =
        images[currentIndex].imageVersion ||
        deserializeFilename(currentImagePath);

    const handlePrevImage = useCallback(() => {
        setCurrentIndex((currentIndex - 1 + totalImages) % totalImages);
    }, [currentIndex, totalImages]);

    const handleNextImage = useCallback(() => {
        setCurrentIndex((currentIndex + 1) % totalImages);
    }, [currentIndex, totalImages]);

    return (
        <Dialog open={true} onClose={handleClose} maxWidth='md'>
            <DialogContent className={classes.dialogContent}>
                <IconButton
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
                <ImageLoader
                    imageSrc={currentImagePath}
                    altText={currentImageName}
                />
                {showControls && (
                    <NavigationControls
                        onPrev={handlePrevImage}
                        onNext={handleNextImage}
                    />
                )}
                <ImageInfo
                    imageName={currentImageName}
                    currentIndex={currentIndex}
                    totalImages={totalImages}
                />
            </DialogContent>
        </Dialog>
    );
});

export default Slider;
