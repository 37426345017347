import { DOMAIN_NAME } from '../common';

const FORM_NAME = `sampleDispatch`;
const DISPATCH_DETAILS_FORM_NAME = `dispatchDetails`;

const LABELS = {
    setup: 'Sample Dispatch Setup',
    details: 'Sample Dispatch Info',
    complete: 'Complete!',
    editSampleDispatch: 'Edit Sample Dispatch',
    amendSampleDispatch: 'Amend Sample Dispatch'
};

const SETUP_FIELDS = [
    { name: 'retailer', type: 'select' },
    { name: 'department', type: 'select' },
    { name: 'country', type: 'select' }
];

const INFO_FIELDS = [
    ...SETUP_FIELDS,
    { name: 'dispatchReference', type: 'input' },
    { name: 'trackingReference', type: 'input' },
    { name: 'fao', type: 'input' },
    { name: 'actualSendDate', type: 'date' },
    { name: 'eta', type: 'date' },
    { name: 'currier', type: 'select' },
    { name: 'dispatchDetails' }
];

const INITIAL_VALUES = { country: 3, versionNo: 1 };

const NEW_SAMPLE_DISPATCH_ROUTE_ID = 276;
const INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID = 277;
const AMEND_SAMPLE_DISPATCH_ROUTE_ID = 478;
const SEASALT_ID = 66;
const HAND_CARRIED_COURIER_ID = 3;

export {
    FORM_NAME,
    DISPATCH_DETAILS_FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_SAMPLE_DISPATCH_ROUTE_ID,
    INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID,
    AMEND_SAMPLE_DISPATCH_ROUTE_ID,
    DOMAIN_NAME,
    SETUP_FIELDS,
    INFO_FIELDS,
    SEASALT_ID,
    HAND_CARRIED_COURIER_ID
};
