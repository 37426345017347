import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Libs
import MultiSelectModel from '@formComponents/MultiSelectModel';
import {
    datasetSelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

// Local
import { DOMAIN_NAME } from '../../common';
import { REPORT_TO } from '../../../redux.datasets';
import { isGeneralUserRoleSelector } from '../../../redux.selectors';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'reportTo',
    label: 'Report to',
    domain: DOMAIN_NAME,
    entity: REPORT_TO,
    disabled: false,
    required: false
};

const mapState = (state, { domain, entity }) => ({
    data: datasetSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    required: isGeneralUserRoleSelector(state)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const MultiSelectReportTo = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <MultiSelectModel {...props} />;
});

MultiSelectReportTo.propTypes = propTypes;
MultiSelectReportTo.defaultProps = defaultProps;

export default MultiSelectReportTo;
