import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { Checkbox, AuthInput, NavigateButton } from 'components';
import { validateRequired } from 'helpers';

import { signIn } from 'actions';

function mapStateToProps(state) {
    return {
        global: state.global,
        auth: state.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            signIn
        },
        dispatch
    );
};

const validate = values => {
    const required = ['userName', 'password'];

    return { ...validateRequired(values, required) };
};

class SignIn extends PureComponent {
    keypress = e => {
        // Submit on Enter
        const { invalid, submitting } = this.props;
        if (e.keyCode === 13 && invalid && !submitting) this.submit();
    };

    submit = values => {
        const { signIn } = this.props;
        signIn(values);
    };

    render() {
        const { invalid, handleSubmit, submitting } = this.props;

        return (
            <Fragment>
                <AuthInput
                    name='userName'
                    label='Username'
                    placeholder='Username'
                    variant='user'
                    required
                />
                <AuthInput
                    name='password'
                    label='Password'
                    type='password'
                    placeholder='Password'
                    variant='lock'
                    required
                />

                {/*<Checkbox name='remeberme' label='Remember me' />*/}

                <NavigateButton
                    onClick={handleSubmit(this.submit)}
                    // disabled={invalid || submitting}
                >
                    Login
                </NavigateButton>
            </Fragment>
        );
    }
}

SignIn.defaultProps = {
    signIn: PropTypes.func
};

const _SignIn = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'AuthenticationForm',
        submitAsSideEffect: true,
        validate
    })
)(SignIn);

export { _SignIn as default, _SignIn as SignIn };
