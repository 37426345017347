import React from 'react';
import { string, object, element, oneOfType } from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    iconWrapper: {
        display: 'grid',
        gridTemplateColumns: `${spacing.unit * 12.25}px 1fr`,
        gridColumnGap: `${spacing.unit * 0.4}px`,
        alignItems: 'center'
    },
    icon: {
        width: spacing.unit * 12.25,
        height: spacing.unit * 12.25
    },
    title: {
        fontSize: spacing.unit * 2,
        color: '#85888E',
        lineHeight: 1.3,
        textTransform: 'capitalize'
    },
    value: {
        fontSize: spacing.unit * 7,
        color: '#000000',
        fontWeight: 600,
        lineHeight: 1
    },
    ratioWrapper: {
        position: 'relative',
        left: `${spacing.unit * 12.5}px`
    },
    rationContent: {
        fontSize: spacing.unit * 2,
        fontWeight: 600,
        color: '#FBB1B0'
    }
});

const propTypes = {
    title: string.isRequired,
    value: string.isRequired,
    icon: string.isRequired,
    child: object
};

const Card = withStyles(styles)(({ title, value, icon, child, classes: c }) => {
    return (
        <div className={c.container}>
            <div className={c.iconWrapper}>
                <img src={icon} alt={title} className={c.icon} />
                <div>
                    <Typography className={c.title}>{title}</Typography>
                    <Typography className={c.value}>{value}</Typography>
                </div>
            </div>
            {child && (
                <div className={c.ratioWrapper}>
                    <Typography className={c.rationContent}>
                        {child.title}
                    </Typography>
                    <Typography className={c.rationContent}>
                        {child.value}%
                    </Typography>
                </div>
            )}
        </div>
    );
});

Card.propTypes = propTypes;

export default Card;
