import { takeLatest, call, put, select } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';

// Local
import { API_BASE_URL, apiClient } from 'store/config';

// Actions
import { updateSamplingPrintForm, setFabrics } from 'store/actions';

// Action Types
import {
    FETCH_SPEC_REF_REQUEST,
    FETCH_SPEC_REF_SUCCESS,
    SET_SPEC_REF_DATA,
} from 'store/actionTypes';

// Selectors
import {
    selectSpecRefData,
    selectHemlineList,
    selectNecklineList,
    selectArmholesList,
    selectLabelsList,
    selectParsedFabrics,
    selectSpecReference,
} from 'selectors';

//api calling
async function getSpecRefList(
    { id, retailerId, searchKey = '', pageNo = 1 },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_SPEC_REF_REQUEST;
    return await apiClient.get(
        API_BASE_URL +
            `api/SampleRequestMains/GetSpecReference?userid=${id}&retailerId=${retailerId}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}`
    );
}

//workers
function* specRefEffectSaga({ payload }) {
    const selectSpecRefData = (state) => ({
        id: state.auth.userId,
        retailerId: formValueSelector('SamplingPrintForm')(state, 'retailerId'),
    });

    const specRefData = yield select(selectSpecRefData);

    const res = yield call(getSpecRefList, { ...specRefData, ...payload });

    yield put({
        type: FETCH_SPEC_REF_SUCCESS,
        payload: res.data,
    });
}

function* specRefDataWorker({ payload: { sampleReference } }) {
    // Defaults to null for when specRef is removed
    const { fabricData, ...specRefData } = {
        specDescription: null,
        necklineId: null,
        hemlineId: null,
        armholeId: null,
        labelTypeId: null,
        fabricData: [],
        ...(yield select(selectSpecReference, sampleReference)),
    };

    yield put(updateSamplingPrintForm(specRefData));

    const fabrics = yield select(selectParsedFabrics, fabricData);
    yield put(setFabrics(fabrics, 'specRefFabric'));
}

// Watchers
const specWatchers = function* () {
    yield takeLatest(FETCH_SPEC_REF_REQUEST, specRefEffectSaga);
    yield takeLatest(SET_SPEC_REF_DATA, specRefDataWorker);
};

export { specWatchers as default, specWatchers };
