// Entities
import {
    ARTWORK_LOCATIONS,
    ARTWORK_SCALES,
    DESIGNERS,
    PRINT_POSITIONS,
    PRINT_TYPES,
    GRAPHIC_REFERENCES,
} from 'libs/datasets';

// Selectors
import {
    selectArtworkLocationsData,
    selectArtworkScalesData,
    selectDesignersData,
    selectPrintPositionsData,
    selectPrintTypesData,
    selectUserId,
} from 'selectors';

// Normalizers
import {
    normalizeNestedData,
    normalizeArtworkLocations,
    normalizeDesigners,
} from 'normalizers';
import { composePaginatedEntity, composeUrl } from '../../../common';

// References
export default [
    {
        entity: ARTWORK_LOCATIONS,
        url: '/api/ArtWorkLocations',
        selector: selectArtworkLocationsData,
        normalizer: normalizeArtworkLocations,
    },
    {
        entity: ARTWORK_SCALES,
        url: '/api/ArtworkScales',
        selector: selectArtworkScalesData,
        normalizer: normalizeNestedData,
    },
    {
        entity: DESIGNERS,
        url: 'api/Designers',
        selector: selectDesignersData,
        normalizer: normalizeDesigners,
    },
    {
        entity: PRINT_POSITIONS,
        url: '/api/PrintPositions',
        selector: selectPrintPositionsData,
        normalizer: normalizeNestedData,
    },
    {
        entity: PRINT_TYPES,
        url: '/api/PrintTypes',
        selector: selectPrintTypesData,
        normalizer: normalizeNestedData,
    },
    {
        entity: GRAPHIC_REFERENCES,
        url: (state, queryBuilder) =>
            composeUrl(
                'api/CadRequestMains/GetGraphicRefDropDown',
                queryBuilder(state)
            ),
        ...composePaginatedEntity(
            GRAPHIC_REFERENCES,
            {
                cadRef: 'Reference',
                description: 'Description',
                designerName: 'Designer',
            },
            (state) => ({
                userId: selectUserId(state),
            })
        ),
    },
];
