export default (newRow, oldRow) => {
    const fieldName = 'cadDescription';
    let oldValue = oldRow[fieldName] || '';
    let newValue = newRow[fieldName] || '';

    if (newValue !== oldValue) {
        const mutationText = `Description for Cad Ref '${oldRow.cadRef}' from '${oldValue}' to '${newValue}'`;
        return {
            fieldName,
            fieldValue: newRow[fieldName],
            id: newRow.id,
            mutationText
        };
    }

    return {};
};
