import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { destroy } from 'redux-form';

// Local
import SamplingPrint from 'views/Sampling/Container/homePage';
import { selectPageType } from 'selectors';

// Actions
import { setSamplingPageType, resetAmends, resetSamplingData } from 'actions';

class SamplingPrintAmend extends Component {
    componentDidMount() {
        this.props.setSamplingPageType('amend');
    }

    componentWillUnmount() {
        const { destroy, resetAmends, resetSamplingData } = this.props;
        resetAmends();
        resetSamplingData();
        destroy('SamplingPrintForm');
    }

    render() {
        return (
            this.props.isAmendPage && (
                <SamplingPrint
                    amendPage
                    title='Print Sample Amend'
                    parent='print'
                />
            )
        );
    }
}

const mapState = state => ({
    isAmendPage: selectPageType(state, 'amend')
});

const mapDispatch = {
    destroy,
    resetAmends,
    resetSamplingData,
    setSamplingPageType
};

const _SamplingPrintAmend = compose(
    connect(
        mapState,
        mapDispatch
    )
)(SamplingPrintAmend);

export {
    _SamplingPrintAmend as default,
    _SamplingPrintAmend as SamplingPrintAmend
};
