import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { getFormValues } from 'redux-form';

import {
    formatDateToShort,
    denormalizeDate
} from '@baseComponents/Date/normalizeDate';
import { valueSelector, customColumnValueSelector } from '@libs/datasets';

import { DOMAIN_NAME } from './common';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import {
    DOMAIN_NAME as COMMON_DOMAIN_NAME,
    FILTERS_FORM_NAME
} from 'common/common';
import { DOMAIN_NAME as ORDER_DOMAIN_NAME } from 'orders/common';
import { CATEGORIES, STYLES, GENDERS, AGES } from 'common/redux.datasets';
import {
    TYPES,
    LICENSORS,
    ALL_DESIGNERS,
    PROPERTIES
} from 'cadUpload/redux.datasets';
import {
    ORDER_DATE_FILTER_FIELD,
    ORDER_BASE_FACTORIES,
    COLUMN_GROUP
} from 'orders/redux.datasets';
import { RETAILERS, DEPARTMENTS, COUNTRIES } from 'sampling/redux.datasets';

const gridViewSelector = state => state[DOMAIN_NAME];

const gridFilterFormValuesSelector = getFormValues(FILTERS_FORM_NAME);

const isGridFilterItemSelectedSelector = createSelector(
    state => gridFilterFormValuesSelector(state) || {},
    formValues => Object.values(formValues).some(fieldValue => !!fieldValue)
);

const gridEntitySelector = createCachedSelector(
    gridViewSelector,
    (state, entity) => entity,
    (domain, entity) => domain[entity]
)((state, entity) => `@entity-${entity}`);

const rowRefByIdSelector = createSelector(
    gridEntitySelector,
    (state, entity, id) => id,
    (state, entity, id, referenceColumn) => referenceColumn,
    ({ data }, rowId, referenceColumn) =>
        data.find(({ id }) => Number(id.value) === Number(rowId))[
            referenceColumn
        ].value
);

const gridDataSelector = createCachedSelector(gridEntitySelector, gridData => {
    const { data = [], ...rest } = gridData || {};
    const filteredData = data.map(({ reportLink, ...data }) => data);
    return { data: filteredData, ...rest };
})((state, entity) => `@grid-${entity}-data`);

const gridDataLoadingSelector = createCachedSelector(
    gridViewSelector,
    (state, entity) => entity,
    (domain, entity) => domain.loading[entity]
)((state, entity) => `@grid-${entity}-loading`);

const rowUpdateLoadingSelector = createSelector(
    gridViewSelector,
    (state, rowId) => rowId,
    ({ rowUpdateLoading }, rowId) => rowUpdateLoading[rowId]
);

const reportLoadingSelector = createSelector(
    gridViewSelector,
    (state, type) => type,
    ({ reportLoading }, type) => reportLoading[type]
);

const chipValueSelector = createCachedSelector(
    state => state,
    (state, filterKey) => filterKey,
    (state, filterKey, label) => label,
    (state, filterKey, label) => {
        switch (filterKey) {
            case 'cadType': {
                return {
                    filterKey,
                    label: valueSelector(state, CAD_DOMAIN_NAME, TYPES, label)
                };
            }
            case 'licensor': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        CAD_DOMAIN_NAME,
                        LICENSORS,
                        label
                    )
                };
            }
            case 'property': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        CAD_DOMAIN_NAME,
                        PROPERTIES,
                        label
                    )
                };
            }
            case 'designer': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        CAD_DOMAIN_NAME,
                        ALL_DESIGNERS,
                        label
                    )
                };
            }
            case 'retailer': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        RETAILERS,
                        label
                    )
                };
            }
            case 'department': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        DEPARTMENTS,
                        label
                    )
                };
            }
            case 'genderId': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        COMMON_DOMAIN_NAME,
                        GENDERS,
                        label
                    )
                };
            }

            case 'ageId': {
                return {
                    filterKey,
                    label: valueSelector(state, COMMON_DOMAIN_NAME, AGES, label)
                };
            }

            case 'categoryId': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        COMMON_DOMAIN_NAME,
                        CATEGORIES,
                        label
                    )
                };
            }
            case 'styleId': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        COMMON_DOMAIN_NAME,
                        STYLES,
                        label
                    )
                };
            }
            case 'country': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        COUNTRIES,
                        label
                    )
                };
            }
            case 'orderFactory': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        ORDER_DOMAIN_NAME,
                        ORDER_BASE_FACTORIES,
                        label
                    )
                };
            }
            case 'groupId': {
                return {
                    filterKey,
                    label: customColumnValueSelector(
                        state,
                        ORDER_DOMAIN_NAME,
                        COLUMN_GROUP,
                        'groupId',
                        'groupName',
                        label
                    )
                };
            }
            case 'dateFilterId': {
                return {
                    filterKey,
                    label: valueSelector(
                        state,
                        ORDER_DOMAIN_NAME,
                        ORDER_DATE_FILTER_FIELD,
                        label
                    )
                };
            }
            case 'startDt': {
                return {
                    filterKey,
                    label: formatDateToShort(denormalizeDate(label))
                };
            }
            case 'endtDt': {
                return {
                    filterKey,
                    label: formatDateToShort(denormalizeDate(label))
                };
            }
            default: {
                return { filterKey, label };
            }
        }
    }
)((state, key, value) => `chip-${key}-${value}`);

export {
    gridViewSelector,
    gridDataSelector,
    gridDataLoadingSelector,
    rowUpdateLoadingSelector,
    reportLoadingSelector,
    isGridFilterItemSelectedSelector,
    rowRefByIdSelector,
    chipValueSelector
};
