import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';

import GridInput from '../../../GridInput';

const propTypes = {
    type: PropTypes.string.isRequired,
    filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filterOperatorValue: PropTypes.string,
    onFilterValueChange: PropTypes.func.isRequired
};

const styles = ({ palette, shape, spacing }) => ({
    gridInputRangeWrapper: {
        display: 'flex',
        height: '100%'
    },
    gridInputRangeStart: {
        marginRight: spacing.unit * 0.75
    },
    gridInputFilter: {
        backgroundColor: darken(palette.background.light, 0.1),
        border: 0,
        borderRadius: shape.borderRadius,
        '&:hover': {
            border: `1px solid`,
            borderColor: palette.grey[400]
        },
        padding: spacing.unit * 1
    }
});

const InputFilter = withStyles(styles)(
    ({
        classes: c,
        filterValue,
        onFilterValueChange,
        filterOperatorValue,
        ...props
    }) => {
        const isRangeInput = ['inrange', 'notinrange'].includes(
            filterOperatorValue
        );

        const [searchText, setSearchText] = useState(
            filterValue || (isRangeInput ? { start: '', end: '' } : '')
        );

        const handleSearchChange = useCallback(
            debounce(query => {
                onFilterValueChange(query);
            }, 1500),
            [onFilterValueChange]
        );

        const handleInputChange = useCallback(
            event => {
                const query = event.target.value;
                const newSearchText = isRangeInput
                    ? {
                          ...(typeof searchText === 'object' ? searchText : {}),
                          [event.target.name]: query
                      }
                    : query;

                setSearchText(newSearchText);
                handleSearchChange(newSearchText);
            },
            [isRangeInput, searchText, handleSearchChange]
        );

        const handleClearFilter = useCallback(
            field => () => {
                let newValue = '';
                if (isRangeInput) {
                    newValue = { ...searchText, [field]: '' };
                }
                setSearchText(newValue);
                onFilterValueChange(newValue);
            },
            [isRangeInput, searchText, onFilterValueChange]
        );

        useEffect(() => {
            if (!filterValue) {
                setSearchText(isRangeInput ? { start: '', end: '' } : '');
            }
        }, [filterValue, isRangeInput]);

        if (isRangeInput) {
            return (
                <div className={c.gridInputRangeWrapper}>
                    <GridInput
                        name='start'
                        value={searchText.start || ''}
                        onChange={handleInputChange}
                        isCancellable={!!searchText.start}
                        handleClearValue={handleClearFilter('start')}
                        styles={{
                            root: clsx(c.gridInputFilter, c.gridInputRangeStart)
                        }}
                        placeholder='start'
                        {...props}
                    />
                    <GridInput
                        name='end'
                        value={searchText.end || ''}
                        onChange={handleInputChange}
                        isCancellable={!!searchText.end}
                        handleClearValue={handleClearFilter('end')}
                        styles={{ root: c.gridInputFilter }}
                        placeholder='end'
                        {...props}
                    />
                </div>
            );
        }

        return (
            <GridInput
                value={typeof searchText === 'object' ? '' : searchText}
                onChange={handleInputChange}
                isCancellable={!!searchText}
                handleClearValue={handleClearFilter('')}
                styles={{ root: c.gridInputFilter }}
                {...props}
            />
        );
    }
);

InputFilter.propTypes = propTypes;

export default InputFilter;
