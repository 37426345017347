export const compositionSchema = {
    composition: 'string',
    sustainabilityId: 0,
    retailerId: 0,
    id: 0
};
export const GSMSchema = {
    gsm: 'string',
    id: 0
};
export const dyeTypesSchema = {
    dyeType: 'string',
    id: 0
};
export const finishWashSchema = {
    finishWash: 'string',
    id: 0
};
export const fabricForSchema = {
    fabricFor: 'string',
    id: 0
};
export const fabricTypesSchema = {
    type: 'string',
    fabricGroupId: 0,
    id: 0
};
export const fabricGroupSchema = {
    fabricGroup: 'string',
    id: 0
};
export const sustainabilitySchema = {
    sustainability: 'string',
    id: 0
};
