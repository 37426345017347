import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

import { reportGenerateDate } from '@baseComponents/Date/normalizeDate';
import { reportLoadingSelector } from 'gridView/redux.selectors';
import {
    gridExcelExporting,
    gridPdfPageExporting,
    gridAllColumnsExcelExporting,
    gridAllRowsExcelExporting
} from 'gridView/redux.actions';
import {
    exportDataSelector,
    tileExportDataSelector
} from 'gridView/customSelectors';
import Xlsx from './Xlsx';
import CustomXlsx from './CustomXlsx';
import Pdf from './Pdf/';
import SinglePage from './SinglePage';
import MultiPage from './MultiPage';
import CostForm from './CostForm';
import { SINGLE_PAGE, MULTI_PAGE } from './constants';
import { orderListsUniqueRetailerSelector } from 'orders/redux.selectors';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        listStyleType: 'none',
        margin: 0
    },
    item: {
        position: 'relative',
        '&:not(:last-child)': {
            marginRight: spacing.unit * 6
        },
        '&:not(:last-child)::after': {
            position: 'absolute',
            content: '""',
            width: '1px',
            height: spacing.unit * 2.5,
            background: '#C8CED4',
            top: 0,
            right: '-24px'
        }
    }
});

const mapStateToProps = state => ({
    xlsxLoading: reportLoadingSelector(state, 'xlsx'),
    allColumnsXlsxLoading: reportLoadingSelector(state, 'allColumnsXlsx'),
    allRowsXlsxLoading: reportLoadingSelector(state, 'allRowsXlsx'),
    costFormLoading: reportLoadingSelector(state, 'costForm'),
    multiPageLoading: reportLoadingSelector(state, MULTI_PAGE),
    singlePageLoading: reportLoadingSelector(state, SINGLE_PAGE),
    retailerId: orderListsUniqueRetailerSelector(state)
});

const mapDispatch = {
    exportToExcel: gridExcelExporting,
    exportAsPage: gridPdfPageExporting,
    exportAllColumns: gridAllColumnsExcelExporting,
    exportAllRows: gridAllRowsExcelExporting
};

const Report = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        xlsxLoading,
        allColumnsXlsxLoading,
        allRowsXlsxLoading,
        costFormLoading,
        multiPageLoading,
        singlePageLoading,
        exportToExcel,
        exportAsPage,
        exportAllColumns,
        exportAllRows,
        moduleName,
        reportsInfo,
        viewType,
        tileData,
        originalIds,
        data,
        retailerId,
        filters,
        classes: c
    }) => {
        const {
            xlsx,
            pdf,
            singlePage,
            multiPage,
            costForm,
            allXlsx,
            sampleOrderedXL
        } = reportsInfo;

        const getFileName = useCallback(
            (trailTitle = 'report') =>
                `${moduleName}_${trailTitle}_${reportGenerateDate()}`,
            [moduleName]
        );

        const preparedExportData = useCallback(
            (exportedColumns, exportType = 'pdf', imageSize = '100') => {
                if (viewType === 'list') {
                    return exportDataSelector({
                        data,
                        selectedIds: originalIds,
                        exportedColumns,
                        exportType,
                        imageSize
                    });
                }
                return tileExportDataSelector({
                    data: tileData,
                    exportType,
                    imageSize: exportType === 'pdf' ? '800' : imageSize,
                    selectedIds: originalIds
                });
            },
            [originalIds, moduleName, viewType]
        );

        const disableExport = useCallback(
            (loading = false) => {
                return originalIds.length === 0 || loading;
            },
            [originalIds]
        );

        const exportAllColumnsToExcel = useCallback(() => {
            return exportAllColumns({
                fileName: getFileName('xlsx'),
                moduleName,
                rowIds: originalIds,
                retailerId
            });
        }, [originalIds, moduleName, retailerId]);

        const exportAllRowsToExcel = useCallback(() => {
            return exportAllRows({
                fileName: getFileName('xlsx'),
                ...filters
            });
        }, [filters]);

        return (
            <ul className={c.root}>
                {xlsx && xlsx.show && (
                    <li className={c.item}>
                        <Xlsx
                            getFileName={getFileName}
                            exportToExcel={exportToExcel}
                            xlsxLoading={xlsxLoading}
                            preparedExportData={preparedExportData}
                            shouldDisable={disableExport}
                            exportedColumns={xlsx.columns}
                        />
                    </li>
                )}
                {allXlsx && allXlsx.show && (
                    <li className={c.item}>
                        <CustomXlsx
                            exportToExcel={exportAllColumnsToExcel}
                            shouldDisable={
                                disableExport(allColumnsXlsxLoading) ||
                                !retailerId
                            }
                            title={allXlsx.title}
                        />
                    </li>
                )}
                {sampleOrderedXL && sampleOrderedXL.show && (
                    <li className={c.item}>
                        <CustomXlsx
                            exportToExcel={exportAllRowsToExcel}
                            title={sampleOrderedXL.title}
                            shouldDisable={
                                data.length === 0 || allRowsXlsxLoading
                            }
                        />
                    </li>
                )}
                {pdf && pdf.show && (
                    <li className={c.item}>
                        <Pdf
                            preparedExportData={preparedExportData}
                            getFileName={getFileName}
                            shouldDisable={disableExport}
                            exportedColumns={pdf.columns}
                        />
                    </li>
                )}
                {multiPage && multiPage.show && (
                    <li className={c.item}>
                        <MultiPage
                            getFileName={getFileName}
                            selectedIds={originalIds}
                            shouldDisable={disableExport}
                            exportAsPage={exportAsPage}
                            multiPageLoading={multiPageLoading}
                            moduleName={moduleName}
                        />
                    </li>
                )}
                {singlePage && singlePage.show && (
                    <li className={c.item}>
                        <SinglePage
                            getFileName={getFileName}
                            selectedIds={originalIds}
                            shouldDisable={disableExport}
                            exportAsPage={exportAsPage}
                            singlePageLoading={singlePageLoading}
                            moduleName={moduleName}
                        />
                    </li>
                )}
                {costForm && costForm.show && (
                    <li className={c.item}>
                        <CostForm
                            getFileName={getFileName}
                            exportToExcel={exportToExcel}
                            costFormLoading={costFormLoading}
                            preparedExportData={preparedExportData}
                            shouldDisable={disableExport}
                            exportedColumns={costForm.columns}
                            headerMap={costForm.headerMap}
                            costColumns={costForm.costColumns}
                        />
                    </li>
                )}
            </ul>
        );
    }
);

export default Report;
