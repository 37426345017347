import axios from 'axios';
import { call } from 'redux-saga/effects';

export function* sendMail(data) {
    const API_URL =
        'https://h28f5waqo3.execute-api.eu-west-2.amazonaws.com/prod/email-send';
    const headers = {
        'x-api-key': process.env.REACT_APP_EMAIL_API_KEY,
        'Content-Type': 'application/json',
        Authorization: ''
    };
    yield call(axios.post, API_URL, data, { headers });
}
