import {
    COMPOSITIONS,
    DYE_TYPES,
    FABRIC_FORS,
    FABRIC_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from '../../entities';

// Selectors
import {
    selectCompositionsData,
    selectDyeTypesData,
    selectFabricForsData,
    selectFabricTypesData,
    selectFinishedGsmsData,
    selectSpecialFinishesData
} from 'selectors';

// Normalizers
import {
    normalizeNestedData,
    normalizeCompositions,
    normalizeFinishedGsms
} from 'normalizers';

// References
export default [
    {
        entity: COMPOSITIONS,
        url: `/api/FabricCompositions`,
        selector: selectCompositionsData,
        normalizer: normalizeCompositions
    },
    {
        entity: DYE_TYPES,
        url: '/api/DyeTypes',
        selector: selectDyeTypesData,
        normalizer: normalizeNestedData
    },
    {
        entity: FABRIC_FORS,
        url: '/api/FabricFors',
        selector: selectFabricForsData,
        normalizer: normalizeNestedData
    },
    {
        entity: FABRIC_TYPES,
        url: '/api/FabricTypes',
        selector: selectFabricTypesData,
        normalizer: normalizeNestedData
    },
    {
        entity: FINISHED_GSMS,
        url: '/api/Gsms',
        selector: selectFinishedGsmsData,
        normalizer: normalizeFinishedGsms
    },
    {
        entity: SPECIAL_FINISHES,
        url: '/api/SpecialFinishs',
        selector: selectSpecialFinishesData,
        normalizer: normalizeNestedData
    }
];
