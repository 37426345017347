import {
    SET_INK_DETAILS,
    EDIT_INK_DETAIL,
    REMOVE_INK_DETAIL,
    REMOVE_INK_IMAGE,
    FETCH_SAMPLE_INFO_SUCCESS,
    PUBLISH_TO_DATABASE_DRAFT_SUCCESS
} from 'actionTypes';

import { normalizeInkDetails, normalizeInkImages } from 'store/normalizers';

const inksReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_SAMPLE_INFO_SUCCESS: {
            const { inkImages = [], inks = [] } = payload;

            const normalizedInks = normalizeInkDetails(inks);
            const normalizedInkImages = normalizeInkImages(inkImages);

            const stateInks = state.inkDetails;
            const stateImages = state.inkImages;
            return {
                ...state,
                inkDetails: [...stateInks, ...normalizedInks],
                inkImages: [...stateImages, ...normalizedInkImages]
            };
        }

        case PUBLISH_TO_DATABASE_DRAFT_SUCCESS: {
            const {
                sta: {
                    value: {
                        data: { inkImages, inks }
                    }
                }
            } = payload;

            const newInks = state.inkDetails.map((ink, index) => {
                return { ...ink, inkAutoId: inks[index] };
            });

            let count = -1;
            const newImages = state.inkImages.map((img, index) => {
                if (Number(img.id) === 0) {
                    count++;
                    return { ...img, id: inkImages[count] };
                } else {
                    return img;
                }
            });

            return {
                ...state,
                inkDetails: newInks,
                inkImages: newImages
            };
        }

        case SET_INK_DETAILS: {
            const data = payload;
            let inkObj = {};
            if (
                data.hasOwnProperty('inks') &&
                Object.keys(data.inks).length > 0
            ) {
                const inkData = state.inkDetails;
                inkObj['inkDetails'] = [...inkData, ...data.inks];
            }

            if (
                data.hasOwnProperty('inkImages') &&
                Object.keys(data.inkImages).length > 0
            ) {
                const inkImages = state.inkImages;
                inkObj['inkImages'] = [...inkImages, ...data.inkImages];
            }
            return {
                ...state,
                ...inkObj
            };
        }

        case EDIT_INK_DETAIL: {
            const data = payload;
            let inkDetails = state.inkDetails.map(i => ({ ...i }));

            if (data.hasOwnProperty('inkIndex')) {
                inkDetails.map(function(item, index) {
                    return index === Number(data.inkIndex)
                        ? ((item.colour = data.colour),
                          (item.colourRef = data.colourRef),
                          (item.InkTitle = data.InkTitle),
                          (item.InkType = data.InkType))
                        : item;
                });
            }
            return {
                ...state,
                inkDetails
            };
        }

        case REMOVE_INK_DETAIL: {
            const data = payload;
            const allInks = state.inkDetails.map(i => ({ ...i }));
            const inkDetails = allInks.filter((item, index) => {
                return index !== data.index;
            });
            return {
                ...state,
                inkDetails
            };
        }

        case REMOVE_INK_IMAGE:
            return {
                ...state,
                inkImages: state.inkImages.filter(
                    (item, index) => index !== Number(payload.id)
                )
            };

        default: {
            return state;
        }
    }
};

export { inksReducer as default, inksReducer };
