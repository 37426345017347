import { userIdSelector } from 'auth/redux.selectors';

export const DESIGNERS_BY_USER_ID = '@designersByUserId';

export default {
    [DESIGNERS_BY_USER_ID]: {
        url: 'Designer/GetCadDesignerByUserId',
        params: state => ({
            userId: userIdSelector(state)
        })
    }
};
