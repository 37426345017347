import { put, call } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { splice } from '@libs/object';
import { normalizeDate } from '@baseComponents/Date';
import {
    normalizeAttachments,
    normalizeInputGroup
} from 'common/redux.normalizers';
import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';

import { FORM_NAME, INITIAL_VALUES } from '../../Order/common';
import {
    normalizePODetails,
    normalizeOrderSamples,
    normalizeLicensor
} from 'orders/redux.normalizers';

export default function*(orderNo, isAmend = false) {
    try {
        const {
            data: {
                data: [
                    {
                        isTbcSample,
                        isCancel,
                        isHold,
                        bdCommission,
                        otherCommission,
                        orderSamples,
                        orderAttachments,
                        orderDate,
                        orderAmendDate,
                        orderPoDetails,
                        costingDate,
                        retailerId,
                        sampleEta,
                        lastApiPayload,
                        orderVersionNo,
                        softPublish,
                        orderPack,
                        ...data
                    } = {}
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'OrderMains/GetOrderDetailsByOrderNo',
            params: {
                orderNo
            }
        });

        const versionNo = Number(orderVersionNo);
        const samples = normalizeOrderSamples(orderSamples);
        const licensor = normalizeLicensor(orderSamples);

        const nestedNormalizedData = {
            ...data,
            licensor,
            versionNo,
            BDCommission: !!bdCommission
                ? normalizeInputGroup(bdCommission)
                : {},
            otherCommission: !!otherCommission
                ? normalizeInputGroup(otherCommission)
                : {},
            attachments: normalizeAttachments(orderAttachments),
            products: orderSamples,
            sampleRefs: samples,
            originalSampleRefs: samples,
            orderAmendedDate: normalizeDate(orderAmendDate),
            orderPlacedDate: normalizeDate(orderDate),
            purchaseOrders: normalizePODetails(orderPoDetails, retailerId),
            costDated: normalizeDate(costingDate),
            sampleSendDate: normalizeDate(sampleEta),
            retailer: retailerId,
            noOfPacks: orderPack,
            isCanceledOrHeld: isCancel || isHold
        };

        const values = splice(nestedNormalizedData, {
            id: 'id',
            tbcOrder: 'tbcOrder',
            isTBC: 'isTBC',
            type: 'type',
            retailer: 'retailer',
            batchRef: 'batchConfirmation',
            noOfPacks: 'noOfPacks',
            sampleRefs: 'sampleRefs',
            originalSampleRefs: 'originalSampleRefs',
            departmentId: 'department',
            versionNo: 'versionNo',
            orderSupplierRef: 'echoOrderNo',
            orderAmendedDate: 'orderAmendedDate',
            orderPlacedDate: 'orderPlacedDate',
            sampleSendDate: 'sampleSendDate',
            licensor: 'licensor',
            nlKind: 'nlKind',
            isBlankOrder: 'blankOrder',
            styleNo: 'styleNo',
            productionDescription: 'productDescription',
            orderFactory: 'factory',
            clientPoNo: 'clientPONo',
            orderFactoryRef: 'factoryRef',
            orderSupplierNo: 'supplierNo',
            factorySl: 'factorySl',
            country: 'factoryOrigin',
            orderSeason: 'season',
            orderProductionSamples: 'productionSampleType',
            ukSize: 'sendToEcho',
            buyerSize: 'sendToBuyer',
            kimbelNo: 'kimballNo',
            kimbleColour: 'kimballColor',
            commodity: 'commodity',
            cid: 'cidNo',
            sysFacNo: 'systemFactoryNo',
            products: 'products',
            purchaseOrders: 'purchaseOrders',
            orderLabelings: 'brands',
            attachments: 'attachments',
            departmentNo: 'packingDepartmentNo',
            asosDepartmentName: 'asosDepartment',
            sectionDescription: 'packingSection',
            licensingCompany: 'licensingCompany',
            packType: 'packType',
            boxType: 'boxType',
            costDated: 'costDated',
            costBasedOn: 'costBasedOn',
            htsCode: 'htsCode',
            BDCommission: 'BDCommission',
            otherCommission: 'otherCommission',
            orderComments: 'orderComment',
            specialorderComments: 'specialComments',
            isCanceledOrHeld: 'isCanceledOrHeld'
        });

        yield put(
            initialize(
                FORM_NAME,
                {
                    ...INITIAL_VALUES,
                    ...values,
                    ...(isAmend
                        ? {
                              lastFullPublishedPayload: lastApiPayload || {}
                          }
                        : {})
                },
                false,
                {
                    updateUnregisteredFields: true,
                    keepDirty: false
                }
            )
        );
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(
            setSnack({
                message: `Failed to fetch data ${serverMessage}`,
                type: 'error',
                duration: 8000
            })
        );
    }
}
