import React from 'react';
import { string, oneOfType, number, bool } from 'prop-types';

import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing, palette }) => ({
    row: {
        display: 'flex',

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark
    },
    header: {
        padding: spacing.unit * 0.75,
        flex: `${spacing.unit * 16}px 0 0`,
        fontSize: spacing.unit * 1.75,

        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark
    },
    value: {
        padding: spacing.unit * 0.75,
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.light
    }
});

const propTypes = {
    label: string.isRequired,
    value: oneOfType([string, number, bool])
};

const Row = withStyles(styles)(({ classes: c, label, value }) => {
    return (
        <div className={c.row}>
            <Typography className={c.header}>{`${label}:`}</Typography>
            <Typography className={c.value}>{value}</Typography>
        </div>
    );
});

Row.propTypes = propTypes;

export default Row;
