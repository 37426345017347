import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
// Material
import { withStyles } from '@material-ui/core';
// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { makeSideEffectCallback } from '@libs/makeContext';

import Adornments from './Adornments';
import Content from './Content';
import useDetailsBreakdown from './hooks';
import { DetailsBreakdownProvider } from './useDetailsBreakdownChart';

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    data: PropTypes.array,
    retailer: PropTypes.string,
    chartSettings: PropTypes.object,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    storeChartViewSettings: PropTypes.func
};

const defaultProps = {
    chartSettings: {},
    storeChartViewSettings: () => {}
};

const SampleDetailsBreakdownChart = withStyles(styles)(
    ({
        classes: c,
        data,
        retailer,
        chartSettings,
        storeChartViewSettings,
        startDate,
        endDate
    }) => {
        const breakdownData = useDetailsBreakdown(data, retailer);

        const initialChartVisibility = useCallback(
            (
                { cellSettings, view, ...chartSettings },
                breakdownData,
                initialState
            ) => {
                const values = {
                    cellSettings: cellSettings || initialState.cellSettings,
                    view: view || initialState.view
                };

                if (Object.keys(chartSettings).length === 0 && breakdownData) {
                    const initialVisibleCharts = Object.keys(
                        breakdownData
                    ).reduce(
                        (acm, chartName) => ({ ...acm, [chartName]: true }),
                        {}
                    );

                    return {
                        ...initialVisibleCharts,
                        ...values
                    };
                }

                return {
                    ...chartSettings,
                    ...values
                };
            },
            []
        );

        const initialize = useCallback(
            state => ({
                ...state,
                data: breakdownData,
                chartSettings: {
                    ...state.chartSettings,
                    ...initialChartVisibility(
                        chartSettings,
                        breakdownData,
                        state.chartSettings
                    )
                },
                retailer,
                startDate,
                endDate
            }),
            [breakdownData, chartSettings, retailer, startDate, endDate]
        );

        const middlewareProps = useMemo(
            () => ({
                storeChartViewSettings: makeSideEffectCallback(
                    storeChartViewSettings
                )
            }),
            [storeChartViewSettings]
        );

        return (
            <DetailsBreakdownProvider
                initialize={initialize}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Breakdown'
                    AdornmentComponent={<Adornments />}
                    styles={{ container: c.container }}
                >
                    <Content />
                </ContentContainer>
            </DetailsBreakdownProvider>
        );
    }
);

SampleDetailsBreakdownChart.propTypes = propTypes;
SampleDetailsBreakdownChart.defaultProps = defaultProps;

export default SampleDetailsBreakdownChart;
