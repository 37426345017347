import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { ARTWORK_SOURCES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'artworkSource',
    label: 'Artwork source',
    placeholder: 'Select artwork source',
    disabled: false,
    required: false
};

const SelectArtworkSources = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={ARTWORK_SOURCES} />
);

SelectArtworkSources.propTypes = propTypes;
SelectArtworkSources.defaultProps = defaultProps;

export { SelectArtworkSources as default, SelectArtworkSources };
