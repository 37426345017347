import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, Paper, Table } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../PaginatedDialogContext';
import PaginatedTableHead from './PaginatedDialogTableHead';
import PaginatedTableBody from './PaginatedDialogTableBody';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogTable
 */
const PaginatedDialogTable = ({
    classes: c,
    dialogState: { data, nextPage },
}) => {
    const [shouldScroll, setScroll] = useState(false);
    const tableRef = useRef(null);
    const paperRef = useRef(null);

    useEffect(() => {
        if (tableRef.current && paperRef.current) {
            const tableNode = ReactDOM.findDOMNode(tableRef.current);
            const paperNode = ReactDOM.findDOMNode(paperRef.current);

            const tableHeight = tableNode.offsetHeight;
            const paperHeight = paperNode.offsetHeight + 1; // Border

            setScroll(tableHeight > paperHeight || data.length > 10);
        }
    }, [data]);

    return (
        <Paper
            ref={paperRef}
            elevation={0}
            className={classNames(
                c.root,
                (!data.length || !nextPage) && c.lastPage,
                shouldScroll && c.scroll
            )}
        >
            <Table ref={tableRef}>
                <PaginatedTableHead />
                <PaginatedTableBody />
            </Table>
        </Paper>
    );
};

const _PaginatedDialogTable = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogTable);

export {
    _PaginatedDialogTable as default,
    _PaginatedDialogTable as PaginatedDialogTable,
};
