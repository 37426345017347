import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components';
import { isLoading } from 'helpers';
import {
    printNewHandoverValidate,
    printNewPublishValidate,
    printIncompleteHandoverValidate,
    printAmendHandoverValidate
} from '../validator';
import styles from './style';
import {
    PUBLISH_TO_DATABASE,
    PUBLISH_TO_DATABASE_HANDOVER
} from 'store/actionTypes';
import { publishToDatabase } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { selectPageType } from 'selectors';

function mapStateToProps(state) {
    return {
        global: state.global,
        stepData: state.initSamplingPrint,
        form: state.form,
        isNewPage: selectPageType(state, 'new'),
        isIncompletePage: selectPageType(state, 'incomplete'),
        isAmendPage: selectPageType(state, 'amend')
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            publishToDatabase: publishToDatabase
        },
        dispatch
    );
};

class TopBar extends PureComponent {
    submitForm = reqType => {
        const {
            publishToDatabase,
            form: { SamplingPrintForm }
        } = this.props;
        publishToDatabase({ reqType, formData: SamplingPrintForm.values });
    };

    isStepLoading = key => {
        return isLoading(this.props.global, key);
    };

    render() {
        const {
            classes,
            stepData,
            global,
            title,
            isAmendPage,
            isHandoverDisabled,
            isPublishDisabled
        } = this.props;

        return (
            <Fragment>
                <Typography component='div' className={classes.stepperTitleBar}>
                    <Typography component='h1' className={classes.stepperTitle}>
                        {stepData.selectedRetailer
                            ? stepData.selectedRetailer.label + ' ' + title
                            : title}
                    </Typography>
                    {!isAmendPage ? (
                        <Button
                            disabled={global.xloading || isHandoverDisabled}
                            onClick={() => this.submitForm('handover')}
                            category='default'
                            isLoading={this.isStepLoading(
                                PUBLISH_TO_DATABASE_HANDOVER
                            )}
                            className={classes.handoverButton}
                        />
                    ) : null}

                    <Button
                        disabled={global.xloading || isPublishDisabled}
                        onClick={() => this.submitForm('publish')}
                        category='publish-md'
                        isLoading={this.isStepLoading(PUBLISH_TO_DATABASE)}
                    />
                </Typography>
            </Fragment>
        );
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired
};

const SamplingBar = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(TopBar);

export { SamplingBar as default, SamplingBar };
