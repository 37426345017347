import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './styles';

const _MenuItem = ({ ...props }) => {
	const { children, onClick } = props;

	return <MenuItem onClick={onClick}>{children}</MenuItem>;
};

_MenuItem.propTypes = {
	onClick: () => {}
};

_MenuItem.propTypes = {
	onClick: PropTypes.func
};

const __MenuItem = withStyles(styles)(_MenuItem);

export { __MenuItem as default, __MenuItem as MenuItem };
