import createCachedSelector from 're-reselect';

// Local
import { mapToSelect } from 'helpers';
import { selectDatasets } from '../common';

// Entities
import {
    COMPOSITIONS,
    DYE_TYPES,
    FABRIC_FORS,
    FABRIC_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from 'libs';

export const selectCompositionsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[COMPOSITIONS]
)(() => 'placeholder');

export const selectDyeTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[DYE_TYPES]
)(() => 'placeholder');

export const selectFabricForsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[FABRIC_FORS]
)(() => 'placeholder');

export const selectFabricTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[FABRIC_TYPES]
)(() => 'placeholder');

export const selectFinishedGsmsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[FINISHED_GSMS]
)(() => 'placeholder');

export const selectSpecialFinishesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[SPECIAL_FINISHES]
)(() => 'placeholder');
