import React, { Component } from 'react';
import { compose } from 'redux';

// Material
import { withStyles, TableBody, TableRow, TableCell } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';
import PaginatedTableRow from './PaginatedDialogTableRow';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogTableBody
 */
class PaginatedDialogTableBody extends Component {
    render() {
        const { props } = this;
        const {
            dialogState: { data = [], categories },
            classes
        } = props;

        return (
            <TableBody>
                {!!data.length ? (
                    data.map((row, idx) => (
                        <PaginatedTableRow
                            key={`paginatedTableRow${idx}`}
                            data={row}
                            shaded={idx % 2 !== 0}
                        />
                    ))
                ) : (
                    <TableRow>
                        <TableCell
                            className={classes.emptyData}
                            colSpan={categories.length}
                        >
                            No data available
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    }
}

const _PaginatedDialogTableBody = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogTableBody);

export {
    _PaginatedDialogTableBody as default,
    _PaginatedDialogTableBody as PaginatedDialogTableBody
};
