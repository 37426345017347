import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Material
import { withStyles, Checkbox as MaterialCheckbox } from '@material-ui/core';
import clsx from 'classnames';

import { functionalComponentPropType } from '@libs/propTypes';

// Local
import { CheckboxBlankIcon, CheckboxFilledIcon } from '@assets/Icons';
import { composeClsx } from '@libs/materialUI';

const propTypes = {
    checked: PropTypes.bool.isRequired,
    FilledIcon: functionalComponentPropType
};

const defaultProps = {
    color: 'default',
    FilledIcon: CheckboxFilledIcon
};

const styles = ({ spacing }) => ({
    root: {
        padding: spacing.unit
    },
    icon: {
        width: spacing.unit * 3,
        height: spacing.unit * 3
    }
});

const Checkbox = compose(withStyles(styles))(
    ({
        classes,
        className,
        styles,
        color,
        checked,
        disabled,
        loading,
        FilledIcon,
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });
        return (
            <MaterialCheckbox
                className={clsx(c.root, className)}
                icon={<CheckboxBlankIcon className={c.icon} />}
                checkedIcon={<FilledIcon className={c.icon} />}
                color={color}
                checked={checked}
                disabled={disabled || loading}
                {...restProps}
            />
        );
    }
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export { Checkbox as default, Checkbox };
