import { call, put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { apiRequest } from '@libs/apiRequest';
import { setReportLoading } from '../../redux.actions';
import downloadWorker from './downloadWorker';

export default function*(action) {
    const {
        payload,
        meta: { reportType }
    } = action;
    try {
        yield put(setReportLoading({ [reportType]: true }));
        const { data: blob } = yield call(apiRequest, {
            method: 'post',
            url: 'FileUpload/ExportToExcel',
            data: payload,
            responseType: 'blob'
        });
        yield call(downloadWorker, {
            blob,
            fileName: `${payload.fileName}.xlsx`
        });
        yield put(setReportLoading({ [reportType]: false }));
    } catch (error) {
        yield put(setReportLoading({ [reportType]: false }));
        yield put(
            setSnack({
                message: 'Failed to download',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
