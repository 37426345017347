export default ({ typography }) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: typography.headline.fontSize,
    },
});
