import React from 'react';
import { func } from 'prop-types';

import { withStyles } from '@material-ui/core';

const propTypes = {
    onStartResize: func.isRequired
};

const styles = ({ spacing }) => ({
    gridCellResizing: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        borderRight: `${spacing.unit * 1.25}px solid transparent`,
        cursor: 'col-resize'
    }
});

const Resizing = withStyles(styles)(({ classes, onStartResize }) => {
    const handleMouseDown = e => {
        e.preventDefault();
        onStartResize(e.clientX);
    };

    const stopClick = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            className={classes.gridCellResizing}
            onMouseDown={handleMouseDown}
            onClick={stopClick}
        />
    );
});

Resizing.propTypes = propTypes;

export default Resizing;
