export const FETCH_DATASETS = 'app@fetchDatasets';
export const FETCH_PAGINATED_DATASET = 'app@fetchPaginatedDataset';
export const FETCH_GRID_DATASET = 'app@fetchGridDataset';
export const FETCH_GRID_PREVIEW_DATA_BY_REF = 'app@fetchGridPreviewDataByRef';
export const SET_DATASET = '@setDataset';
export const SET_PAGINATED_DATASET = '@setPaginatedDataset';
export const SET_GRID_DATASET = '@setGridDataset';
export const SET_DATASET_LOADING = '@setDatasetLoading';
export const REFRESH_DATASET = '@refresh-dataset';

export const RESET_DATASETS = 'utilities@reset-datasets';
