import React from 'react';
import { Document, Page } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FULL_WIDTH_UNITS, FULL_HEIGHT_UNITS } from './constants';
import Row from './Row';

const styles = ({ spacing }) => ({
    page: {
        width: spacing.unit * FULL_WIDTH_UNITS,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        paddingLeft: spacing.unit * 4.5,
        paddingRight: spacing.unit * 4.5,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 4,
        backgroundColor: '#f5f5f5'
    }
});

const Report = withPDFStyles(styles)(({ pages, classes: c, onClose }) => {
    return (
        <Document>
            {pages.map((rows, idx) => (
                <Page size='A3' style={c.page} key={`pdf-page-${idx}`}>
                    {rows.map((row, idx) => (
                        <Row
                            {...row}
                            key={`pdf-row-${idx}`}
                            isLast={rows.length - 1 === idx}
                        />
                    ))}
                </Page>
            ))}
        </Document>
    );
});

export default Report;
