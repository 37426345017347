import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material
import { withStyles } from '@material-ui/core';

// Local
import { composeClsx } from '@libs/materialUI';
import MultiSelectBase from '@baseComponents/MultiSelect';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    styles: {},
    data: [],
    amended: false
};

const styles = ({ spacing }) => ({
    container: {
        paddingBottom: spacing.unitPadding
    },
    control: {
        minHeight: spacing.unitHeight,
        width: '100%'
    }
});

const MultiSelectModel = compose(withStyles(styles))(
    ({ classes, placeholder = 'Select...', amended, styles, ...restProps }) => {
        const c = composeClsx({ classes, styles });

        const props = {
            placeholder,
            styles: {
                ...c
            },
            ...restProps
        };
        return <MultiSelectBase {...props} />;
    }
);

MultiSelectModel.propTypes = propTypes;
MultiSelectModel.defaultProps = defaultProps;

export { MultiSelectModel as default, MultiSelectModel };
