const defaultFields = [
    { name: 'dispatchType', label: 'Type', width: '13%' },
    { name: 'sampleRef', label: 'Sample Ref', width: '12%' },
    { name: 'sampleImage', label: 'Image', width: '9%' },
    { name: 'version', label: 'Version', width: '7%' },
    { name: 'description', label: 'Description', width: '13%' },
    { name: 'fabricType', label: 'Fab Type', width: '8%' },
    { name: 'fabricComposition', label: 'Composition', width: '13%' },
    { name: 'fabricGsm', label: 'GSM', width: '5%' },
    { name: 'colour', label: 'Colour', width: '8%' },
    { name: 'quantity', label: 'Units', width: '5%' },
    { name: 'size', label: 'Size', width: '7%' }
];
const seasaltFields = [
    { name: 'dispatchType', label: 'Type', width: '10%' },
    { name: 'sampleRef', label: 'Sample Ref', width: '10%' },
    { name: 'sampleImage', label: 'Image', width: '8%' },
    { name: 'version', label: 'Version', width: '6%' },
    { name: 'description', label: 'Description', width: '10%' },
    { name: 'fabricType', label: 'Fab Type', width: '7%' },
    { name: 'fabricComposition', label: 'Composition', width: '10%' },
    { name: 'fabricGsm', label: 'GSM', width: '5%' },
    { name: 'colour', label: 'Colour', width: '8%' },
    { name: 'season', label: 'Season', width: '5%' },
    { name: 'fabricGroup', label: 'Fab Group', width: '5%' },
    { name: 'designer', label: 'Designer', width: '5%' },
    { name: 'quantity', label: 'Units', width: '5%' },
    { name: 'size', label: 'Size', width: '5%' }
];
const getHeaders = isSeasalt => {
    return isSeasalt ? seasaltFields : defaultFields;
};

export default getHeaders;
