import React, { useMemo } from 'react';

import { FABRIC_GROUP } from '../../redux.datasets';
import { DOMAIN_NAME } from '../../common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const FabricGroup = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Fabric Group', canSort: true, sortKey: 'fabricGroup' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'FABRIC GROUP SETUP',
        domain: DOMAIN_NAME,
        entity: FABRIC_GROUP,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { FabricGroup as default, FabricGroup };
