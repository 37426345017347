import { useState, useRef, useCallback, useEffect } from 'react';

const useInfiniteScroll = (data, loadMoreThreshold = 10) => {
    const [visibleData, setVisibleData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    useEffect(() => {
        setVisibleData(data.slice(0, loadMoreThreshold));
    }, [data, loadMoreThreshold]);

    const lastItemRef = useCallback(
        node => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    const currentLength = visibleData.length;
                    const moreData = data.slice(
                        currentLength,
                        currentLength + loadMoreThreshold
                    );
                    if (moreData.length > 0) {
                        setVisibleData(prevData => [...prevData, ...moreData]);
                    } else {
                        setHasMore(false);
                    }
                }
            });
            if (node) observer.current.observe(node);
        },
        [visibleData, hasMore, data, loadMoreThreshold]
    );

    return [visibleData, lastItemRef, hasMore];
};

export default useInfiniteScroll;
