import React from 'react';
import PropTypes from 'prop-types';

import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    noDataMessage: {
        padding: spacing.unit * 4,
        textAlign: 'center',
        width: '100%'
    }
});

const NoDataMessage = ({ classes, message }) => (
    <Typography variant='body1' className={classes.noDataMessage}>
        {message}
    </Typography>
);

NoDataMessage.propTypes = {
    message: PropTypes.string
};

NoDataMessage.defaultProps = {
    message: 'No data available'
};

export default withStyles(styles)(NoDataMessage);
