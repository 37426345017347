const defaultValues = {
    string: '',
    number: null,
    boolean: false,
    object: {},
    array: []
};

const composeApiPayload = (payload = null, schema) =>
    Object.entries(schema).reduce((acm, [property, propertyType]) => {
        const type = typeof propertyType;
        const payloadKeyExists = payload && payload.hasOwnProperty(property);
        switch (type) {
            case 'object': {
                // Is array
                if (Array.isArray(propertyType)) {
                    // Check array value
                    const arrayValueExists =
                        payloadKeyExists &&
                        payload[property] &&
                        !!payload[property].length;
                    // Grabs the schema from the first element in the schema
                    const [arraySchema] = schema[property];
                    // Recursively calls composeApiPayload for each element
                    const value = arrayValueExists
                        ? payload[property].map((arrayPayload, idx) => {
                              if (typeof arrayPayload !== 'object') {
                                  return payload[property][idx];
                              }
                              return composeApiPayload(
                                  arrayPayload,
                                  arraySchema
                              );
                          })
                        : defaultValues.array;

                    return { ...acm, [property]: value };
                } else {
                    // Directly calls composeApiPayload recursively
                    const value = composeApiPayload(
                        payload[property],
                        schema[property]
                    );

                    return { ...acm, [property]: value };
                }
            }

            default: {
                const value =
                    payloadKeyExists && !!payload[property]
                        ? payload[property]
                        : defaultValues[type];

                return { ...acm, [property]: value };
            }
        }
    }, {});

export default composeApiPayload;
