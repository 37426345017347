import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { string } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ palette, spacing }) => ({
    headline: {
        backgroundColor: palette.background.light,
        textAlign: 'center',
        padding: spacing.unit * 0.5,
        fontSize: 10
    },
    imageContainer: {
        width: 'auto',
        height: spacing.unit * 18.75,

        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,

        margin: spacing.unit * 0.5
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    }
});

const propTypes = {
    type: string,
    labelRef: string,
    image: string
};

const Brand = withPDFStyles(styles, { withTheme: true })(
    ({
        type,
        labelRef,
        image,
        hasComments,
        theme: { spacing },
        classes: c
    }) => {
        return (
            <View style={c.container}>
                <Text style={c.headline}>{type}</Text>
                <View
                    style={{
                        ...c.imageContainer,
                        ...(hasComments ? { height: spacing.unit * 15.75 } : {})
                    }}
                >
                    {!!image && <Image style={c.image} src={image} />}
                </View>
                <Text style={{ ...c.headline, backgroundColor: '#fff' }}>
                    {labelRef}
                </Text>
            </View>
        );
    }
);

Brand.propTypes = propTypes;

export default Brand;
