import React from 'react';
import { string, bool, oneOfType, number } from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { FONT_FAMILY_BOLD } from 'common/constants';
import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    label: string,
    value: oneOfType([string, number]),
    required: bool
};

const defaultProps = {
    required: true
};

const styles = ({ spacing, palette, shape }) => ({
    field: {
        width: '31%',
        margin: spacing.unit * 0.25
    },
    label: {
        color: palette.secondary.main,
        fontSize: spacing.unit * 1.5,
        marginBottom: spacing.unit * 0.25,
        fontFamily: FONT_FAMILY_BOLD
    },
    required: {
        color: '#db3131'
    },
    value: {
        border: 1,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        padding: spacing.unit * 0.25,
        fontSize: spacing.unit * 1.25,
        minHeight: spacing.unit * 2.25
    },
    amendBG: {
        backgroundColor: 'yellow'
    }
});

const Field = withPDFStyles(styles)(
    ({ classes: c, label, value, required, isAmend = false, style }) => {
        return (
            <View style={{ ...c.field, ...style }}>
                {label && (
                    <Text style={c.label}>
                        {label} :{required && <Text style={c.required}>*</Text>}
                    </Text>
                )}
                <Text style={{ ...c.value, ...(isAmend ? c.amendBG : {}) }}>
                    {value}
                </Text>
            </View>
        );
    }
);

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export { Field as default, Field };
