import 'date-fns';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudIcon from '@material-ui/icons/CloudQueue';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CompactIcon from '@material-ui/icons/ViewCompact';

function mapStateToProps(state) {
    return {
        // user: state.currentUser,
        // stepData: state.initSamplingPrint,
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            // setDataForSteps: actionCreators.setStepData,
        },
        dispatch
    );
};

const styles = theme => ({
    defaultTopPadding: {
        padding: '15px 16px 0px',
    },
    defaultContentPadding: {
        padding: '30px 0px 15px',
    },
    titleStyle: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    fontStyle: {
        fontSize: 32,
        lineHeight: '48px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    anchorHoverStyle: {
        padding: 0,
        marginBottom: 5,
        '&:hover': {
            background: 'transparent',
            cursor: 'auto',
        },
        '&:focus': {
            background: 'transparent',
        },
    },
});

class drawerSettings extends PureComponent {
    handleChange = name => event => {
        console.log([name], event.target.checked);
    };

    render() {
        const { classes } = this.props;

        const settingsItems = [
            {
                title: 'Notifications',
                icon: NotificationsIcon,
                disabled: false,
                slug: 'checkedNotification',
            },
            {
                title: 'Cloud Sync',
                icon: CloudIcon,
                disabled: true,
                slug: 'checkedCloud',
            },
            {
                title: 'Retro thrusters',
                icon: CompactIcon,
                disabled: true,
                slug: 'checkedRetro',
            },
        ];
        const components = [];
        for (let i = 0; i < settingsItems.length; i++) {
            let IconComponent = settingsItems[i].icon;
            components.push(
                <li key={i}>
                    <ListItem
                        button
                        disableRipple
                        className={classes.anchorHoverStyle}
                    >
                        <ListItemIcon>
                            <IconComponent />
                        </ListItemIcon>
                        <ListItemText primary={settingsItems[i].title} />
                        <Switch
                            checked={settingsItems[i].disabled}
                            onChange={this.handleChange(settingsItems[i].slug)}
                            value={settingsItems[i].slug}
                            color='primary'
                        />
                    </ListItem>
                </li>
            );
        }

        return (
            <React.Fragment>
                <Grid
                    container
                    justify='center'
                    spacing={0}
                    className={classes.defaultTopPadding}
                >
                    <Grid item md={12}>
                        <Typography className={classes.titleStyle}>
                            Quick Settings
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={classes.defaultContentPadding}
                    >
                        <List>{components}</List>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(drawerSettings));
