import { fork } from 'redux-saga/effects';

// Proper
import authSaga from 'auth/redux.sagas';
import cadUploadSaga from 'cadUpload/redux.sagas';
import cadViewSaga from 'cadView/redux.sagas';
import samplingSaga from 'sampling/redux.sagas';
import navigationSaga from 'navigation/redux.sagas';
import gridViewSaga from 'gridView/redux.sagas';
import cadDesignerReportSaga from 'cadDesignerReport/redux.sagas';
import ordersSaga from 'orders/redux.sagas';
import commonSaga from 'common/redux.sagas';
import qualityControlSaga from 'qualityControl/redux.sagas';
import setupSaga from 'setup/redux.sagas';
import administratorSaga from 'administrator/redux.sagas';
import fabricSaga from 'fabric/redux.sagas';
import personalizationSettingsSaga from 'personalizationSettings/redux.sagas';

// Legacy
import datasetsSaga from 'sagas/datasets';
import reportsSaga from 'sagas/reports';
// import samplingSaga from 'sagas/sampling';
import utilsSaga from 'sagas/utils';

// Datasets
import datasets from './datasets';
import composeDatasetsSaga from '@libs/datasets/libs.sagas';

export default function*() {
    yield fork(commonSaga);
    yield fork(authSaga);
    yield fork(cadUploadSaga);
    yield fork(cadViewSaga);
    yield fork(samplingSaga);
    yield fork(navigationSaga);
    yield fork(gridViewSaga);
    yield fork(cadDesignerReportSaga);
    yield fork(ordersSaga);
    yield fork(qualityControlSaga);
    yield fork(setupSaga);
    yield fork(administratorSaga);
    yield fork(fabricSaga);
    yield fork(personalizationSettingsSaga);
    // yield fork(datasetsSaga);
    // yield fork(reportsSaga);
    // yield fork(samplingSaga);
    // yield fork(utilsSaga);
    yield fork(composeDatasetsSaga(datasets));
}
