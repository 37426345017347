import React, { useMemo } from 'react';

import { ARMHOLES } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const ArmholeCuff = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Armhole/Cuff', canSort: true, sortKey: 'armholes' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'AEMHOLE/CUFF SETUP',
        domain: DOMAIN_NAME,
        entity: ARMHOLES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { ArmholeCuff as default, ArmholeCuff };
