import axios from 'axios';
import { call } from 'redux-saga/effects';

// Local
import config from './config';
import { getCookie } from 'helpers/auth';
import uploadProgress from './uploadProgress';

// Transforms object into FormData
// Todo: Make usable for nested objects
// Nested append uses key.key for keys
// take into account recursion
const transformIntoFormData = object =>
    Object.entries(object).reduce((formData, [key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(data => formData.append(key, data));
        } else {
            formData.append(key, value);
        }
        return formData;
    }, new FormData());

const apiClient = axios.create(config);

function* apiRequest({ type, transformData = false, uploadToken, ...rest }) {
    const AUTH_TOKEN = getCookie();

    // For isLoading
    if (type) apiClient.defaults.headers.common['xtype'] = type;
    if (AUTH_TOKEN)
        apiClient.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${AUTH_TOKEN}`;

    const loadedConfig = {
        ...rest,
        transformRequest: transformData ? transformIntoFormData : null,
        onUploadProgress: uploadToken
            ? yield* uploadProgress(uploadToken)
            : null
    };

    return yield call(apiClient, loadedConfig);
}

export { apiRequest as default, apiRequest, apiClient };
