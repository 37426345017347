import { put } from 'redux-saga/effects';
import { destroy } from 'redux-form';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute } from 'navigation/redux.actions';
import { fetchDatasets } from '@libs/datasets';

import { FORM_NAME, AMEND_ORDER_ROUTE_ID } from 'orders/Order/common';
import { AMEND_ORDERS } from 'orders/redux.datasets';

export default function*({ echoOrderNo, statusText }) {
    yield put(destroy(FORM_NAME));
    yield put(redirectToRoute(AMEND_ORDER_ROUTE_ID));
    yield put(fetchDatasets(AMEND_ORDERS));
    yield put(
        setSnack({
            message: `Order No: ${echoOrderNo} has been ${statusText}`,
            type: 'success'
        })
    );
}
