import React from 'react';

const wrapperStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: 24,
	height: '100vh'
};

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		console.log('ErrorBoundary', error);
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<div style={wrapperStyle}>
					SOMETHING WENT WRONG. PLEASE
					<span onClick='window.location.reload();'>
						&nbsp;<b>REFRESH</b>&nbsp;
					</span>
					THE PAGE AND TRY AGAIN.
				</div>
			);
		}

		return this.props.children;
	}
}

export { ErrorBoundary as default, ErrorBoundary };
