import { select, call, all } from 'redux-saga/effects';

// Libs
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { composeLoadingWorker } from '@libs/publishWorkers';
import {
    publishFailedWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker
} from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { normalizeImagePayload } from 'sampling/redux.normalizers';

import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';
import reportUploadAndMailSendWorker from './reportUploadAndMailSendWorker';
import { publishSchema } from './schema';
import { fabricDevelopmentFormSelector } from '../../../redux.selectors';

// Workers
export default function*(action) {
    const {
        meta: {
            handleComplete = () => {},
            closeModals = () => {},
            saveDraft = false
        },
        payload: { mode }
    } = action;

    const {
        fabricReference,
        versionNo,
        originalFabricImage,
        washedImage,
        finalImage
    } = yield select(
        fabricDevelopmentFormSelector,
        'fabricReference',
        'versionNo',
        'originalFabricImage',
        'washedImage',
        'finalImage'
    );

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [originalFabricImage, washedImage, finalImage],
        mode
    });

    const version = mode === 'amend' ? versionNo + 1 : versionNo;
    const versionName = `V${version}`;

    try {
        const [
            originalFabricImageBlobs,
            washedImageBlobs,
            finalImageBlobs,
            originalFabricImageSizePaths,
            washedImageSizePaths,
            finalImageSizePaths
        ] = yield all([
            call(getAssetBlobsWorker, {
                assets: originalFabricImage,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: washedImage,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: finalImage,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'fabricDevelopmentOriginal'),
            select(sizePathsSelector, 'fabricDevelopmentWash'),
            select(sizePathsSelector, 'fabricDevelopmentFinal')
        ]);

        const [originalFabricImagePayload] = yield call(uploadAssetsWorker, {
            assetBlobs: originalFabricImageBlobs,
            folderPath: `${originalFabricImageSizePaths.sizeOriginal}/${fabricReference}`,
            reference: fabricReference,
            sizePaths: originalFabricImageSizePaths,
            handleLoading,
            prefix: 'Original',
            developmentName: versionName
        });

        const [washedImagePayload] = yield call(uploadAssetsWorker, {
            assetBlobs: washedImageBlobs,
            folderPath: `${washedImageSizePaths.sizeOriginal}/${fabricReference}`,
            reference: fabricReference,
            sizePaths: washedImageSizePaths,
            handleLoading,
            prefix: 'Wash',
            developmentName: versionName
        });

        const [finalImagePayload] = yield call(uploadAssetsWorker, {
            assetBlobs: finalImageBlobs,
            folderPath: `${finalImageSizePaths.sizeOriginal}/${fabricReference}`,
            reference: fabricReference,
            sizePaths: finalImageSizePaths,
            handleLoading,
            prefix: 'Final',
            developmentName: versionName
        });

        const payload = yield call(getPublishPayloadWorker);

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontuned: !saveDraft,
                versionNo: version,
                originalImage: normalizeImagePayload(
                    originalFabricImagePayload
                ),
                washImage: normalizeImagePayload(washedImagePayload),
                finalImage: normalizeImagePayload(finalImagePayload)
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'FabricDevelopment/PublishFabricDevelopment',
            data: publishData
        });

        /*
            --- After publish
         */

        if (!saveDraft) {
            yield call(reportUploadAndMailSendWorker, {
                fabricReference,
                versionNo: version,
                mode,
                handleLoading
            });
        }

        yield call(handleLoading, true);

        if (saveDraft) {
            yield call(savedDraftWorker, {
                mode,
                fabricReference
            });
        }

        closeModals();
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to ';

        if (saveDraft) actionErrorMessage += 'save';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
