import axios from 'axios';
import { all, call } from 'redux-saga/effects';

import isBlobUrl from '../isBlobUrl';

// Accepts 1 asset or an array of assets
export const getAssetBlobsWorker = function*({ assets, mode, handleLoading }) {
    const normalizedAssets = Array.isArray(assets) ? assets : [assets];
    const assetBlobRequests = normalizedAssets.reduce((acm, asset = null) => {
        if (!asset) return acm;

        const { filename, url = null, ...restAsset } = asset;
        // If on amend, and is already on the server
        if (mode !== 'new' && !isBlobUrl(url))
            return [...acm, { filename, blob: null, url, ...restAsset }];
        else {
            const imageUrl = !isBlobUrl(url)
                ? url + '?not-from-cache-please'
                : url;
            const blobRequest = call(function*() {
                const { data: file } = yield call(axios, {
                    url: imageUrl,
                    responseType: 'blob',
                    headers: {
                        Authorization: ''
                    }
                });

                if (!isBlobUrl(url)) yield call(handleLoading);

                const type = file.type.split('/').slice(-1);

                return { filename, blob: { file, type }, ...restAsset };
            });

            return [...acm, blobRequest];
        }
    }, []);

    return yield all(assetBlobRequests);
};
