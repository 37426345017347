import React, { createContext } from 'react';

const initialState = {
    search: '',
    data: [],
    categories: [],
    categoryValue: 0,
    loading: false,
    nextPage: false,
};

const dialogReducer = (state = initialState, middleware, action) => {
    const { type, payload } = middleware(state, action);
    switch (type) {
        case 'set-search': {
            return { ...state, search: payload };
        }
        case 'set-category': {
            return { ...state, categoryValue: payload };
        }

        // Data driven
        case 'initialize': {
            const { data } = payload;
            const categories = data.length ? Object.keys(data[0]) : [];
            return {
                ...state,
                ...payload,
                loading: false,
                categories,
            };
        }

        case 'set-data': {
            return { ...state, ...payload, loading: false };
        }

        case 'clear': {
            const { search, categoryValue } = initialState;
            return {
                ...state,
                search,
                categoryValue,
                loading: true,
            };
        }

        case 'load-more':
        case 'query': {
            return { ...state, loading: true };
        }

        default:
            return state;
    }
};

const PaginatedSelectContext = createContext({
    state: initialState,
    dispatch: void 0,
});

const withDialogContext = (Component) => {
    return (props) => (
        <PaginatedSelectContext.Consumer>
            {({ state, dispatch }) => (
                <Component
                    {...props}
                    dialogState={state}
                    dialogDispatch={dispatch}
                />
            )}
        </PaginatedSelectContext.Consumer>
    );
};

export {
    withDialogContext as default,
    PaginatedSelectContext,
    dialogReducer,
    initialState,
};
