import React, { useMemo } from 'react';
import { View } from '@react-pdf/renderer';
import { array, bool } from 'prop-types';

import { Title } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

import Brand from './Brand';
import { A3, PAGE_PADDING_LEFT, PAGE_PADDING_RIGHT } from 'common/constants';

const styles = ({ palette }) => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    grid: {
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    }
});

const propTypes = {
    brands: array,
    hasComments: bool
};

const useGridStyles = ({ classes: c, theme: { spacing }, brands }) => {
    const grid = useMemo(() => {
        const {
            LANDSCAPE: { WIDTH }
        } = A3;
        const brandMarginUnit = spacing.unit * 0.5 * 2;
        const actualContainerWidth =
            WIDTH - PAGE_PADDING_LEFT - PAGE_PADDING_RIGHT - 2;
        const brandBorderWidth = c.grid.borderWidth * 2;
        const brandWidth =
            actualContainerWidth / brands.length -
            brandMarginUnit -
            brandBorderWidth -
            6;
        const maxWidth = 180;
        return {
            ...c.grid,
            margin: brandMarginUnit,
            width: Math.min(Math.floor(brandWidth), maxWidth)
        };
    }, [c, brands]);

    return { ...c, grid };
};

const BrandingDetails = withPDFStyles(styles, { withTheme: true })(
    ({ classes, brands, hasComments, theme, ...rest }) => {
        const c = useGridStyles({
            classes,
            theme,
            brands
        });
        return !!brands.length ? (
            <View {...rest}>
                <Title text={'Branding Details'} />
                <View style={c.container}>
                    {brands.map((brand, idx) => (
                        <View key={`brand-${idx}`} style={c.grid}>
                            <Brand {...brand} hasComments={hasComments} />
                        </View>
                    ))}
                </View>
            </View>
        ) : null;
    }
);

BrandingDetails.propTypes = propTypes;

export default BrandingDetails;
