// Action Types
import { FETCH_COPY_SAMPLE_REF_SUCCESS } from 'store/actionTypes';

const requestReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_COPY_SAMPLE_REF_SUCCESS: {
            const { data, paginationMetadata } = payload;
            return {
                ...state,
                copyReferences: { data, pageInfo: paginationMetadata },
            };
        }
        default: {
            return state;
        }
    }
};

export { requestReducer as default, requestReducer };
