import React from 'react';
import { array } from 'prop-types';
import { withStyles } from '@material-ui/core';

import CadTable from './CadTable';
import FabricTable from './FabricTable';

const styles = () => ({
    container: {
        width: '100%'
    }
});

const propTypes = {
    fabrics: array.isRequired
};

const ExpansionPanelDetails = withStyles(styles)(
    ({ classes: c, fabrics, cads }) => {
        return (
            <div className={c.container}>
                {cads && <CadTable {...cads} />}
                {fabrics.map((fabric, idx) => (
                    <FabricTable {...fabric} key={`fabric-${idx}`} />
                ))}
            </div>
        );
    }
);

ExpansionPanelDetails.propTypes = propTypes;

export default ExpansionPanelDetails;
