import React, { useMemo } from 'react';

import { ARTWORK_SOURCES } from 'cadUpload/redux.datasets';
import { DOMAIN_NAME } from 'cadUpload/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const ArtworkSource = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            {
                label: 'Artwork Source',
                canSort: true,
                sortKey: 'artworkSource'
            },
            {
                label: 'Short Name',
                canSort: true,
                sortKey: 'typeShort'
            },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'ARTWORK SOURCE SETUP',
        domain: DOMAIN_NAME,
        entity: ARTWORK_SOURCES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { ArtworkSource as default, ArtworkSource };
