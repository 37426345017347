import React from 'react';
import PropTypes from 'prop-types';

// Material
import {
    withStyles,
    ClickAwayListener,
    Grow,
    Paper,
    Popper
} from '@material-ui/core';

// Local
import Options from './Options';
import { optionShape } from './common';

const propTypes = {
    options: PropTypes.arrayOf(PropTypes.exact(optionShape)).isRequired,
    color: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.object
};

const styles = () => ({
    root: {
        zIndex: 36000
    }
});

const Dropdown = withStyles(styles)(
    ({ classes: c, anchorEl, open, handleClose, ...restProps }) => {
        return (
            <Popper
                open={open}
                className={c.root}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Options
                                    handleClose={handleClose}
                                    {...restProps}
                                />
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }
);

Dropdown.propTypes = propTypes;

export default Dropdown;
