import { useEffect, useState } from 'react';

import { FULL_WIDTH_UNITS } from '../constants';

const useDynamicPieChartStyles = (view, spacing) => {
    const [dynamicStyles, setDynamicStyles] = useState({
        pieChartWidth: spacing.unit * (FULL_WIDTH_UNITS / 2 + 2),
        imageContainerSize: spacing.unit * 25,
        legendItemWidth: spacing.unit * 17
    });

    useEffect(() => {
        const pieChartWidth =
            view === '1x1' ? '100%' : spacing.unit * (FULL_WIDTH_UNITS / 2 + 2);
        const imageContainerSize =
            view === '1x1' ? spacing.unit * 50 : spacing.unit * 25;

        const legendItemWidth =
            view === '1x1' ? spacing.unit * 22 : spacing.unit * 16;

        setDynamicStyles({
            pieChartWidth,
            imageContainerSize,
            legendItemWidth
        });
    }, [view, spacing]);

    return dynamicStyles;
};

export default useDynamicPieChartStyles;
