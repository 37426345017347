import { select, put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { change, formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../common';
import { getIncompleteData } from '../../redux.actions';

export default function*({ mode, cadReference }) {
    if (mode !== 'incomplete') {
        yield put(setRedirect(true));
        // Select stepper
        const { contents = [] } =
            (yield select(formValueSelector(FORM_NAME), 'stepper')) || {};

        if (contents.length)
            yield put(
                change(FORM_NAME, 'stepper', { activeStep: 1, contents })
            );
    }
    yield put(
        setSnack({
            message: `Saved draft: ${cadReference}`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(270)); // Route key changed

    yield put(getIncompleteData(cadReference));
}
