import React, { Fragment, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

// Material
import clsx from 'classnames';
import { withStyles, Tooltip as MaterialTooltip } from '@material-ui/core';

const propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onClick: PropTypes.func,
    dark: PropTypes.bool
};

const defaultProps = { dark: false };

const styles = ({ palette, shadows }) => ({
    arrow: {
        position: 'absolute',
        width: '3em',
        height: '3em',

        boxShadow: '0px 3px 21px 0px rgba(0, 4, 52, 0.11)',
        fontSize: 6,
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },

    popper: {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',

            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent var(--color) transparent`
            }
        }
    },
    tooltip: {
        boxShadow: shadows[2],
        borderRadius: 0,
        color: palette.common.black,
        backgroundColor: palette.common.white,
        padding: '6px 10px',
        fontSize: '.9rem'
    },
    tooltipDark: {
        backgroundColor: palette.common.black,
        color: palette.common.white
    }
});

const Tooltip = withStyles(styles, { withTheme: true })(
    ({
        classes: { arrow, tooltip, tooltipDark, ...c },
        theme: { palette },
        children,
        title,
        dark,
        ...restProps
    }) => {
        const arrowRef = useRef(null);

        const popperOptions = useMemo(
            () => ({
                modifiers: {
                    arrow: {
                        enabled: !!arrowRef.current,
                        element: arrowRef.current
                    }
                }
            }),
            [arrowRef]
        );

        const arrowStyle = useMemo(
            () => ({
                '--color': dark ? palette.common.black : palette.common.white
            }),
            [palette, dark]
        );

        return (
            <MaterialTooltip
                classes={{ ...c, tooltip: clsx(tooltip, dark && tooltipDark) }}
                title={
                    <Fragment>
                        {title}
                        <span
                            style={arrowStyle}
                            className={arrow}
                            ref={arrowRef}
                        />
                    </Fragment>
                }
                PopperProps={{ popperOptions }}
                {...restProps}
            >
                {children}
            </MaterialTooltip>
        );
    }
);

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export { Tooltip as default, Tooltip };
