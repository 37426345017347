import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { string, object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD, FONT_FAMILY_ITALIC } from 'common/constants';

import PDFConverter from './PDFConverter';

const styles = ({ spacing, palette }) => ({
    commentContainer: {
        width: '49%',
        padding: `${spacing.unit * 0.75}px ${spacing.unit * 1.25}px`,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        marginTop: spacing.unit * 2
    },
    comment: {
        padding: spacing.unit * 0.5,
        position: 'relative'
    },
    label: {
        fontSize: 12,
        fontFamily: FONT_FAMILY_BOLD,
        position: 'absolute',
        marginTop: -spacing.unit * 2
    }
});

const propTypes = {
    label: string.isRequired,
    styles: object.isRequired,
    comment: string
};

const defaultProps = {
    styles: {}
};

const RichComments = withPDFStyles(styles)(
    ({ classes: c, label, comments, styles }) => {
        return (
            <View style={{ ...c.commentContainer, ...styles }}>
                <Text style={c.label}>{label}:</Text>
                <View style={c.comment}>
                    {comments ? (
                        PDFConverter(
                            comments,
                            FONT_FAMILY_BOLD,
                            FONT_FAMILY_ITALIC
                        )
                    ) : (
                        <Text></Text>
                    )}
                </View>
            </View>
        );
    }
);

RichComments.propTypes = propTypes;
RichComments.defaultProps = defaultProps;

export { RichComments as default, RichComments };
