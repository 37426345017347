import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    Paper,
    Typography,
    CardHeader,
    CardContent
} from '@material-ui/core';

// Actions
import { setReportsNumPages } from 'store/actions';

// Selectors
import { selectAttachments } from 'store/selectors';

// Local
import styles from './styles';
import ReportsAttachmentsItem from './ReportsAttachmentsItem';

/*
 * Domain: Reports
 * Page: --
 * Component: Attachments
 * Type: --
 * ReportsAttachments
 */
const ReportsAttachments = ({
    classes: { root, container, title, cardHeader, noAttachments },
    file,
    attachments
}) => {
    return (
        <Fragment>
            {file && (
                <Paper className={root}>
                    <Typography className={title} variant='h6'>
                        Attachment Details:
                    </Typography>
                    <Card className={container}>
                        <CardHeader
                            className={cardHeader}
                            title='File name'
                            titleTypographyProps={{ variant: 'h6' }}
                        />

                        {attachments.length ? (
                            attachments.map(({ fileName, downloadUrl }) => {
                                return (
                                    <ReportsAttachmentsItem
                                        fileName={fileName}
                                        downloadUrl={downloadUrl}
                                        key={downloadUrl}
                                    />
                                );
                            })
                        ) : (
                            <CardContent className={noAttachments}>
                                <Typography color='inherit'>
                                    No attachments yet!
                                </Typography>
                            </CardContent>
                        )}
                    </Card>
                </Paper>
            )}
        </Fragment>
    );
};

const mapStateToProps = ({ reports: { pdfUrl, module, attachments } }) => ({
    file: pdfUrl,
    attachments: selectAttachments(module, attachments)
});

const mapDispatchToProps = dispatch => ({
    setReportsNumPages: numPages => dispatch(setReportsNumPages({ numPages }))
});

const _ReportsAttachments = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(ReportsAttachments);

export {
    _ReportsAttachments as default,
    _ReportsAttachments as ReportsAttachments
};
