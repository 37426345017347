import React from 'react';

import {
    PO_SIZE_SET_ROUTE_ID,
    ORDER_EMAIL_BODY_ROUTE_ID,
    ORDER_EMAIL_GROUP_ROUTE_ID,
    ORDER_FACTORY_ROUTE_ID,
    ORDER_RETAILER_FACTORY_ROUTE_ID,
    TRIM_LABEL_TYPE_ROUTE_ID,
    TRIM_LABEL_ROUTE_ID,
    PACKING_PACK_TYPE_ROUTE_ID,
    SEASON_ROUTE_ID,
    COLOUR_ROUTE_ID,
    PO_SHIP_MODE_ROUTE_ID,
    PO_SHIP_DESTINATION_ROUTE_ID,
    PO_TYPE_ROUTE_ID,
    PACKING_BOX_TYPE_ROUTE_ID,
    ORDER_NOTE_ROUTE_ID,
    ORDER_STATUS_ROUTE_ID
} from './common';
import POSizeSet from './POSizeSet';
import OrderEmailBody from './OrderEmailBody';
import OrderEmailGroup from './OrderEmailGroup';
import OrderFactory from './OrderFactory';
import OrderRetailerFactory from './OrderRetailerFactory';
import TrimLabelType from './TrimLabelType';
import TrimLabel from './TrimLabel';
import PackingPackType from './PackingPackType';
import Season from './Season';
import Colour from './Colour';
import POShipMode from './POShipMode';
import POShipDestination from './POShipDestination';
import POType from './POType';
import PackingBoxType from './PackingBoxType';
import OrderNotes from './OrderNotes';
import Status from './Status';

export default {
    [PO_SIZE_SET_ROUTE_ID]: () => <POSizeSet />,
    [ORDER_EMAIL_BODY_ROUTE_ID]: () => <OrderEmailBody />,
    [ORDER_EMAIL_GROUP_ROUTE_ID]: () => <OrderEmailGroup />,
    [ORDER_FACTORY_ROUTE_ID]: () => <OrderFactory />,
    [ORDER_RETAILER_FACTORY_ROUTE_ID]: () => <OrderRetailerFactory />,
    [TRIM_LABEL_TYPE_ROUTE_ID]: () => <TrimLabelType />,
    [TRIM_LABEL_ROUTE_ID]: () => <TrimLabel />,
    [PACKING_PACK_TYPE_ROUTE_ID]: () => <PackingPackType />,
    [SEASON_ROUTE_ID]: () => <Season />,
    [COLOUR_ROUTE_ID]: () => <Colour />,
    [PO_SHIP_MODE_ROUTE_ID]: () => <POShipMode />,
    [PO_SHIP_DESTINATION_ROUTE_ID]: () => <POShipDestination />,
    [PO_TYPE_ROUTE_ID]: () => <POType />,
    [PACKING_BOX_TYPE_ROUTE_ID]: () => <PackingBoxType />,
    [ORDER_NOTE_ROUTE_ID]: () => <OrderNotes />,
    [ORDER_STATUS_ROUTE_ID]: () => <Status />
};
