import React, { useMemo } from 'react';

import { PO_SHIP_DESTINATION } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const POShipDestination = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            {
                label: 'Ship Destination',
                canSort: true,
                sortKey: 'destination'
            },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'PO SHIP DESTINATION SETUP',
        domain: DOMAIN_NAME,
        entity: PO_SHIP_DESTINATION,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { POShipDestination as default, POShipDestination };
