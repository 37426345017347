import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/core';

import SearchBar from './SearchBar';
import Badge from './Badge';
import ViewToggle from './ViewToggle';
import FilteredChips from './FilteredChips';
import ClearAll from './ClearAll';
import { FilterIcon } from '@assets/Icons';
import useGridView from 'gridView/useGridView';

const styles = ({ spacing, palette }) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        height: spacing.unit * 6.5,

        backgroundColor: palette.background.light,

        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2
    },
    inline: {
        display: 'flex',
        alignItems: 'center'
    },
    badge: { position: 'relative', top: '-12px', left: '-12px' }
});

const Header = ({
    classes: c,
    filtersDialog: FiltersDialog,
    searchableColumns = []
}) => {
    const [dialogAnchor, setDialogAnchor] = useState(null);
    const [
        {
            filters: allFiltered,
            tileView: { show: hasMultiView },
            viewType
        },
        dispatch
    ] = useGridView();

    const { dialogFilteredCount, dialogFilteredItems } = useMemo(() => {
        const dialogFilteredItems = Object.entries(allFiltered).reduce(
            (acm, [key, value]) => {
                if (key !== 'filterString' && value) {
                    return { ...acm, [key]: value };
                }
                return acm;
            },
            {}
        );
        return {
            dialogFilteredCount: Object.values(dialogFilteredItems).length,
            dialogFilteredItems
        };
    }, [allFiltered]);

    const handleClearFilter = useCallback(
        filterKey => {
            let newFilters = { ...allFiltered };
            if (filterKey) {
                newFilters = { ...newFilters, [filterKey]: '' };
            } else {
                newFilters = {
                    ...newFilters,
                    ...Object.entries(dialogFilteredItems).reduce(
                        (acm, [key]) => {
                            return { ...acm, [key]: '' };
                        },
                        {}
                    )
                };
            }
            dispatch({
                type: 'set-filters',
                payload: newFilters
            });
        },
        [allFiltered, dialogFilteredItems]
    );

    const showDialog = useCallback(
        e => {
            setDialogAnchor(e.currentTarget);
        },
        [setDialogAnchor]
    );

    const hideDialog = useCallback(() => {
        setDialogAnchor(null);
    }, [setDialogAnchor]);

    useEffect(() => {
        dispatch({
            type: 'set-categories',
            payload: searchableColumns
        });
    }, [searchableColumns]);

    return (
        <div className={c.container}>
            <div className={c.inline}>
                <SearchBar />
                <FilterIcon onClick={showDialog} />
                {!dialogAnchor && (
                    <Badge
                        count={dialogFilteredCount}
                        styles={{ badge: c.badge }}
                    />
                )}
                {!!dialogFilteredCount && (
                    <FilteredChips
                        chips={dialogFilteredItems}
                        handleClearFilter={handleClearFilter}
                    />
                )}

                {dialogFilteredCount > 1 && (
                    <ClearAll handleClearFilter={handleClearFilter} />
                )}
                {dialogAnchor && (
                    <FiltersDialog
                        hideDialog={hideDialog}
                        anchor={dialogAnchor}
                        handleFilters={dispatch}
                        appliedFilters={allFiltered}
                    />
                )}
            </div>
            {hasMultiView && (
                <div className={c.inline}>
                    <ViewToggle currentView={viewType} dispatch={dispatch} />
                </div>
            )}
        </div>
    );
};

const _Header = withStyles(styles)(Header);

export { _Header as default, _Header as Header };
