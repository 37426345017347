import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { SLEEVE_TYPES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sleeveType',
    label: 'Sleeve type',
    placeholder: 'Select sleeve type',
    disabled: false,
    required: false
};

const SelectSleeveTypes = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={SLEEVE_TYPES} />
);

SelectSleeveTypes.propTypes = propTypes;
SelectSleeveTypes.defaultProps = defaultProps;

export { SelectSleeveTypes as default, SelectSleeveTypes };
