import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MultiPageIcon = props => {
    const primary = props.fill || '#3D3D3D';
    const secondary = props.fill || '#B4B4B4';
    return (
        <SvgIcon {...props} x='0px' y='0px' viewBox='0 0 197.1 206.75'>
            <g style={{ isolation: 'isolate' }}>
                <path
                    style={{ fill: secondary }}
                    d='M83.29,15.6v8.61H201.23v146.5h11.32V15.6Zm0,0'
                    transform='translate(-15.45 -15.6)'
                />

                <path
                    style={{ fill: secondary }}
                    d='M60.68,41.45H178.61V187.89h11.32V32.83H60.68Zm0,0'
                    transform='translate(-15.45 -15.6)'
                />
                <path
                    style={{ fill: secondary }}
                    d='M38.06,58.64H156V205.13h11.29V50H38.06Zm0,0'
                    transform='translate(-15.45 -15.6)'
                />
                <path
                    style={{ mixBlendMode: 'soft-light', fill: primary }}
                    d='M15.45,222.35H144.67V67.25H15.45Zm27.7-116.51h66.59v5.43H43.15Zm0,24.16h51.3v5.42H43.15Zm0,24.17h51.3v5.42H43.15Zm0,24.16h66.59v5.42H43.15Zm0,0'
                    transform='translate(-15.45 -15.6)'
                />
            </g>
        </SvgIcon>
    );
};
export { MultiPageIcon as default, MultiPageIcon };
