import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

import Checkbox from '@baseComponents/Checkbox';
import { ExpandIcon, ShrinkIcon } from '@assets/Icons';

import useRowColor from './hooks';

const propTypes = {
    row: PropTypes.object.isRequired,
    level: PropTypes.number.isRequired,
    isRowSelected: PropTypes.func.isRequired,
    onToggleSelection: PropTypes.func.isRequired,
    onToggleExpandRow: PropTypes.func,
    expandedRows: PropTypes.array
};

const defaultProps = {
    level: 0,
    expandedRows: []
};

const styles = ({ spacing, palette }) => ({
    tableRow: {
        fontFamily: 'Museo',
        width: '100%',
        padding: `${spacing.unit * 1.25}px ${spacing.unit * 2}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'var(--permissionTableRowBackgroundColor)',

        '&:not(:last-child)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: palette.background.dark
        }
    },
    tableCell: {
        flex: 1,
        fontSize: spacing.unit * 1.75,
        fontWeight: 400,
        '&:last-child': {
            textAlign: 'center'
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    checkbox: {
        padding: `0px ${spacing.unit}px`
    }
});

const PermissionTabsTableRow = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { spacing },
        row,
        level,
        expandedRows,
        isRowSelected,
        onToggleSelection,
        onToggleExpandRow
    }) => {
        const getRowColor = useRowColor();
        const isExpanded = expandedRows.includes(row.id);

        const handleRowExpandToggle = () => {
            onToggleExpandRow(row.id);
        };

        const handleToggleSelection = () => {
            onToggleSelection(row.id);
        };

        return (
            <Fragment>
                <div className={c.tableRow} style={getRowColor(level)}>
                    <div className={c.tableCell}>
                        <Checkbox
                            checked={isRowSelected(row.id)}
                            onChange={handleToggleSelection}
                            styles={{ root: c.checkbox }}
                        />
                    </div>
                    <div
                        className={c.tableCell}
                        style={{
                            marginLeft: `${spacing.unit * 5 * level}px`
                        }}
                    >
                        {row.name}
                    </div>
                    <div
                        className={clsx(c.tableCell, c.pointer)}
                        onClick={handleRowExpandToggle}
                    >
                        {row.children &&
                            !!row.children.length &&
                            (isExpanded ? <ShrinkIcon /> : <ExpandIcon />)}
                    </div>
                </div>
                {isExpanded &&
                    row.children &&
                    row.children.map(child => (
                        <PermissionTabsTableRow
                            key={child.id}
                            row={child}
                            level={level + 1}
                            expandedRows={expandedRows}
                            isRowSelected={isRowSelected}
                            onToggleSelection={onToggleSelection}
                            onToggleExpandRow={onToggleExpandRow}
                        />
                    ))}
            </Fragment>
        );
    }
);

PermissionTabsTableRow.propTypes = propTypes;
PermissionTabsTableRow.defaultProps = defaultProps;

export default PermissionTabsTableRow;
