export default ({ spacing, transitions, zIndex }) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    flexBox: {
        display: 'flex'
    },
    rightSectionItemWrapper: {
        borderLeft: '1px solid rgb(245, 245, 245)',
        lineHeight: '64px',
        padding: '0px 10px',
        position: 'relative'
    },
    titleStyle: {
        borderBottom: '1px solid #e6e6e6',
        padding: '10px 16px',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        letterSpacing: '1px'
    }
});
