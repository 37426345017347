import { put, takeLatest, select, call, fork } from 'redux-saga/effects';
import {
    change,
    focus,
    touch,
    formValueSelector,
    startSubmit,
    stopSubmit,
    initialize
} from 'redux-form';

import apiRequest from '@libs/apiRequest';
import { setRedirect } from 'navigation/redux.actions';
import { FORM_NAME as ORDER_FORM } from 'orders/Order/common';
import { MAIL_FORM_NAME } from 'common/common';
import { userEmailSelector } from 'auth/redux.selectors';

import getDevelopedFabricDataWorker from './getDevelopedFabricDataWorker';
import sampleViewSagas from './sampleViewSagas';
import { setBoughtDetailsLoading } from '../redux.actions';
import { normalizeBoughtDetails } from '../redux.normalizers';
import { INITIAL_VALUES, FORM_NAME as SHAPE_FORM_NAME } from '../Shape/common';
import { INITIAL_VALUES as PRINT_INITIAL_VALUES } from '../Print/common';
import { destroyPrintFormWorker } from './printSagas';
import { destroyShapeFormWorker } from './shapeSagas';
import { routeToOrderWorker } from 'orders/redux.sagas/orderSagas';
import {
    GET_BOUGHT_DETAIL_DATA,
    GET_DEVELOPED_FABRIC_DATA,
    ROUTE_TO_NEW_ORDER,
    SET_FABRIC_OPTION_CHILDREN,
    SET_SAMPLE_EMAIL_BODY,
    SET_SAMPLE_EMAIL_RECEIVERS,
    SET_HAS_MANUAL_REF_CHILDREN,
    SET_SAMPLE_FIT_OPTIONS_CHILDREN
} from '../redux.actionTypes';
import { emailGroupsSelector, emailBodySelector } from '../redux.selectors';

// Local
import printSagas from './printSagas';
import shapeSagas from './shapeSagas';
import finalPhotoSagas from './finalPhotoSagas';
import dispatchSagas from './sampleDispatchSagas';
import samplingReportSagas from './samplingReportSagas';

// Workers
function* getBoughtDetailDataWorker({
    payload: { boughtReference, formName }
}) {
    if (boughtReference) {
        yield put(startSubmit(formName));
        yield put(setBoughtDetailsLoading(true));

        const boughtDetails = yield select(
            formValueSelector(formName),
            'boughtDetails'
        );

        // API call goes here
        const {
            data: { data }
        } = yield call(apiRequest, {
            url: 'Bought/GetBoughtByRef',
            params: { boughtRef: boughtReference }
        });

        const newBoughtDetails = normalizeBoughtDetails(data, true);

        yield put(
            change(formName, 'boughtDetails', [
                ...boughtDetails,
                ...newBoughtDetails
            ])
        );

        yield put(stopSubmit(formName));
        yield put(setBoughtDetailsLoading(false));
    }
}

function* setFabricOptionChildren({ meta: { formName } = {}, payload } = {}) {
    const referenceRelatedData = {
        hasCopyReference: payload,
        hasManualReference: payload,
        copyReference: '',
        manualReference: ''
    };
    let values = {};
    if (formName === SHAPE_FORM_NAME) {
        values = { ...INITIAL_VALUES, ...referenceRelatedData };
    } else {
        values = { ...PRINT_INITIAL_VALUES, ...referenceRelatedData };
    }
    yield put(initialize(formName, values));
}

function* routeToNewOrderWorker({ payload: { formName } }) {
    const { sampleReference, retailer } = yield select(
        formValueSelector(formName),
        'sampleReference',
        'retailer'
    );

    const type = formName === SHAPE_FORM_NAME ? 'NP' : 'P';

    if (type === 'P') yield call(destroyPrintFormWorker);
    else yield call(destroyShapeFormWorker);

    yield put(setRedirect(true)); // To avoid redundant destroy in order form.

    yield call(routeToOrderWorker, { payload: { mode: 'new' } });

    yield new Promise(res => setTimeout(() => res(), 0));
    yield put(change(ORDER_FORM, 'type', type));
    yield put(change(ORDER_FORM, 'retailer', retailer));
    yield new Promise(res => setTimeout(() => res(), 0));
    yield put(change(ORDER_FORM, 'sampleRefs[0]', sampleReference));
}

const setEmailReceiversWorker = function*({ payload: groupId }) {
    if (groupId) {
        const initiator = yield select(userEmailSelector);
        const groups = yield select(emailGroupsSelector);
        const { emailGroup } = groups.find(({ id }) => id === Number(groupId));
        const receivers = emailGroup.split(',').join(', ');
        yield put(change(MAIL_FORM_NAME, 'receivers', receivers));
        yield put(focus(MAIL_FORM_NAME, 'receivers'));
        yield put(touch(MAIL_FORM_NAME, 'receivers'));
        if (!receivers.includes(initiator)) {
            yield put(change(MAIL_FORM_NAME, 'cc', initiator));
            yield put(focus(MAIL_FORM_NAME, 'cc'));
            yield put(touch(MAIL_FORM_NAME, 'cc'));
        }
    } else {
        yield put(change(MAIL_FORM_NAME, 'receivers', ''));
        yield put(change(MAIL_FORM_NAME, 'cc', ''));
    }
};
const setEmailBodyWorker = function*({ payload: templateId }) {
    if (templateId) {
        const templates = yield select(emailBodySelector);
        const { emailBody } = templates.find(
            ({ id }) => id === Number(templateId)
        );
        yield new Promise(res => setTimeout(() => res(), 0));
        yield put(change(MAIL_FORM_NAME, 'body', `${emailBody}`));
    } else {
        yield put(change(MAIL_FORM_NAME, 'body', ''));
    }
};

const setHasManualRefChildren = function*({ payload, meta: { formName } }) {
    const copyReference = yield select(
        formValueSelector(formName),
        'copyReference'
    );
    const manualReference = payload && copyReference ? `${copyReference}-` : '';
    yield put(change(formName, 'manualReference', manualReference));
};

const setSampleFitOptionsChildrenWorker = function*({
    payload,
    meta: { formName }
}) {
    const COSTING_ONLY_ID = 8;
    const NONE_OPTION_ID = 5309;
    yield put(change(formName, 'sendToEcho', ''));
    yield put(change(formName, 'sendToBuyer', ''));
    if (payload === COSTING_ONLY_ID) {
        yield put(change(formName, 'sendToEcho', NONE_OPTION_ID));
        yield put(change(formName, 'sendToBuyer', NONE_OPTION_ID));
    }
};

// Watchers
const samplingSagas = function*() {
    yield takeLatest(GET_BOUGHT_DETAIL_DATA, getBoughtDetailDataWorker);
    yield takeLatest(GET_DEVELOPED_FABRIC_DATA, getDevelopedFabricDataWorker);
    yield takeLatest(SET_FABRIC_OPTION_CHILDREN, setFabricOptionChildren);
    yield takeLatest(ROUTE_TO_NEW_ORDER, routeToNewOrderWorker);
    yield takeLatest(SET_SAMPLE_EMAIL_RECEIVERS, setEmailReceiversWorker);
    yield takeLatest(SET_SAMPLE_EMAIL_BODY, setEmailBodyWorker);
    yield takeLatest(SET_HAS_MANUAL_REF_CHILDREN, setHasManualRefChildren);
    yield takeLatest(
        SET_SAMPLE_FIT_OPTIONS_CHILDREN,
        setSampleFitOptionsChildrenWorker
    );
};

export default function*() {
    yield fork(samplingSagas);
    yield fork(printSagas);
    yield fork(shapeSagas);
    yield fork(sampleViewSagas);
    yield fork(finalPhotoSagas);
    yield fork(dispatchSagas);
    yield fork(samplingReportSagas);
}
