import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Material
import { withStyles, Grid, Divider } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/InsertPhotoOutlined';

// Libs
import { GridItem, Input, PaginatedSelect, ImageDnD } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware';
import { validateImageSizeAndExtension } from '@libs/validationRules';

// Local
import { FORM_NAME, LABELS, DOMAIN_NAME } from '../common';
import { GET_SAMPLES } from '../../redux.datasets';
import { SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN } from '../../redux.actionTypes';
import { finalPhotoDateLoadingSelector } from '../../redux.selectors';

const required = value => {
    if (typeof value === 'object' && value !== null) {
        return value.url ? undefined : 'Required';
    }

    return value ? undefined : 'Required';
};

const imageValidation = [required, validateImageSizeAndExtension];

const styles = ({ spacing, palette, shadows }) => ({
    contentContainer: {
        paddingBottom: spacing.unit * 6
    },
    fieldContainer: {
        width: '90%',
        margin: 'auto',
        padding: spacing.unit * 2
    },
    divider: {
        width: '80%',
        margin: 'auto',
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 3
    },
    sourceImageCard: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        marginBottom: spacing.unit * 1.5
    },
    previewContainer: {
        width: '100%',
        minHeight: spacing.unit * 20,
        boxShadow: shadows[0],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRadius: 0,
        marginBottom: spacing.unit * 2
    },
    header: {
        height: spacing.unit * 5.5
    },
    imageContainer: {
        width: '100%',
        height: spacing.unit * 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.background.main
    },
    image: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain'
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: palette.background.dark
    },
    icon: {
        color: palette.background.dark,
        fontSize: spacing.unit * 4
    },
    marginBottom: {
        marginBottom: spacing.unit * 2
    }
});

const mapState = state => {
    const {
        initialCad,
        initialFront,
        initialBack,
        garmentsType,
        sampleRef
    } = formValueSelector(FORM_NAME)(
        state,
        'initialCad',
        'initialFront',
        'initialBack',
        'garmentsType',
        'sampleRef'
    );
    return {
        initialCad: !!initialCad && initialCad.url,
        initialFront: !!initialFront && initialFront.url,
        initialBack: !!initialBack && initialBack.url,
        isPrintSample: garmentsType === 'P',
        hasSampleRef: !!sampleRef,
        isDataLoading: finalPhotoDateLoadingSelector(state)
    };
};

const mapInputs = {
    sampleRef: ({ payload }) => ({
        type: SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN,
        payload
    })
};

const Upload = compose(
    connect(mapState),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        isPrintSample,
        initialCad,
        initialFront,
        initialBack,
        hasSampleRef,
        isDataLoading
    }) => {
        const PreviewImageCard = ({ url, alt, title }) => (
            <ContentContainer
                title={`Initial ${title}`}
                styles={{ container: c.previewContainer, header: c.header }}
                AdornmentComponent={<Fragment />}
            >
                <div className={c.imageContainer}>
                    {url ? (
                        <img src={url} alt={alt} className={c.image} />
                    ) : (
                        <div className={c.image}>
                            <div className={c.empty}>
                                <ImageIcon className={c.icon} />
                            </div>
                        </div>
                    )}
                </div>
            </ContentContainer>
        );

        const PrintBlock = (
            <Fragment>
                <GridItem md={6}>
                    <PreviewImageCard
                        alt='Cad Image'
                        title='CAD'
                        url={initialCad}
                    />
                </GridItem>
                <GridItem md={6}>
                    <ImageDnD
                        name='finalCad'
                        label='Final Image'
                        validate={imageValidation}
                        required
                    />
                </GridItem>
            </Fragment>
        );

        const ShapeBlock = (
            <Fragment>
                <GridItem md={6}>
                    <PreviewImageCard
                        url={initialFront}
                        alt='Front Image'
                        title='Front'
                    />
                </GridItem>
                <GridItem md={6} className={c.marginBottom}>
                    <ImageDnD
                        name='finalFront'
                        label='Final Front'
                        validate={imageValidation}
                        required
                    />
                </GridItem>
                <GridItem md={6}>
                    <PreviewImageCard
                        url={initialBack}
                        alt='Back Image'
                        title='Back'
                    />
                </GridItem>
                <GridItem md={6}>
                    <ImageDnD
                        name='finalBack'
                        label='Final Back'
                        validate={validateImageSizeAndExtension}
                    />
                </GridItem>
            </Fragment>
        );

        return (
            <ContentContainer
                title={LABELS.upload}
                styles={{ container: c.contentContainer }}
                AdornmentComponent={<Fragment />}
            >
                <Grid container className={c.fieldContainer}>
                    <GridItem md={3}>
                        <PaginatedSelect
                            entity={GET_SAMPLES}
                            domain={DOMAIN_NAME}
                            name='sampleRef'
                            label='Sample Reference No'
                            required
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='developmentNo'
                            label='Development NO'
                            loading={isDataLoading}
                            disabled
                            required
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='department'
                            label='Department'
                            loading={isDataLoading}
                            disabled
                            required
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='buyer'
                            label='Buyer'
                            loading={isDataLoading}
                            disabled
                        />
                    </GridItem>
                    {hasSampleRef && (
                        <>
                            <Divider className={c.divider} />
                            <GridItem md={12}>
                                <Grid container>
                                    {isPrintSample ? PrintBlock : ShapeBlock}
                                </Grid>
                            </GridItem>
                        </>
                    )}
                </Grid>
            </ContentContainer>
        );
    }
);

export default Upload;
