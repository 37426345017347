import { useCallback, useMemo, useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createShadow } from '@libs/materialUI';
import useImageDnD, { useImageDnDState } from '../useImageDnD';

export const useDropZoneMouseOverStyle = ({ palette, shadows }) => {
    const { file, isDragging } = useImageDnDState();
    const [isHovered, setHovered] = useState(false);

    const onMouseEnter = useCallback(() => {
        if (!file && !isDragging) setHovered(true);
    }, [isDragging, file]);

    const onMouseLeave = useCallback(() => {
        if (!file && !isDragging) setHovered(false);
    }, [isDragging, file]);

    const style = useMemo(() => {
        const backgroundColor = isDragging
            ? fade(palette.secondary.main, 0.65)
            : palette.common.transparent;

        const boxShadow = isDragging
            ? createShadow({
                  blur: 6,
                  spread: 6,
                  color: fade(palette.secondary.main, 0.3),
                  inset: true
              })
            : shadows[0];

        const isDragBoxHovered = isDragging || isHovered;

        const color = isDragBoxHovered
            ? palette.secondary.main
            : palette.background.dark;

        const textColor = fade(
            palette.secondary.main,
            isDragBoxHovered ? 0.9 : 0.4
        );

        const strongTextColor = fade(
            palette.secondary.main,
            isDragBoxHovered ? 1 : 0.7
        );

        const hoverEnabled = !file && !isDragging;

        const cursor = hoverEnabled ? 'pointer' : 'default';

        return {
            '--backgroundColor': backgroundColor,
            '--boxShadow': boxShadow,
            '--color': color,
            '--textColor': textColor,
            '--strongTextColor': strongTextColor,
            '--cursor': cursor
        };
    }, [isDragging, file, isHovered, palette]);

    return { onMouseEnter, onMouseLeave, style };
};

export const useDropZone = handleFile => {
    const [{ file, isDragging }, dispatch] = useImageDnD();

    const onDragEnter = useCallback(() => {
        if (!file) {
            dispatch({ type: 'setDragging', payload: true });
        }
    }, [dispatch, file]);

    const onDragLeave = useCallback(() => {
        dispatch({ type: 'setDragging', payload: false });
    }, [dispatch]);

    const onClick = useCallback(() => {
        if (!file && !isDragging) {
            handleFile();
        }
    }, [file, isDragging]);

    const onDrop = useCallback(
        event => {
            event.preventDefault();
            const { dataTransfer: { items = [] } = {} } = event;

            const [file] = [...items]
                .filter(({ kind }) => kind === 'file')
                .map(image => image.getAsFile());
            dispatch({ type: 'addFile', payload: file });
            dispatch({ type: 'setDragging', payload: false });
        },
        [dispatch]
    );

    return { onDragEnter, onDragLeave, onClick, onDrop };
};
