import { call, all } from 'redux-saga/effects';
import { composeRelativeUrls, fileUploadWorker } from '@libs/sagaWorkers';
import { serializeFilename } from '@libs/filenames';

/*
    Returns absolute URLs
 */
export const uploadAssetsWorker = function*({
    assetBlobs,
    folderPath,
    reference,
    sizePaths,
    handleLoading,
    developmentName,
    prefix = '',
    enableSerial = false
}) {
    const assetPayloads = assetBlobs.map(
        ({ filename, blob = null, url, ...restAsset }, index) => {
            if (!blob) {
                return call(function*() {
                    const pathKeys = Object.values(sizePaths).map(
                        path => path.split('/').slice(-1)[0]
                    );
                    const urlExistsPathKey = pathKeys.find(key =>
                        url.includes(key)
                    );

                    const assetUrls = Object.entries(sizePaths).reduce(
                        (acm, [size, path]) => {
                            const [pathKey] = path.split('/').slice(-1);
                            return {
                                ...acm,
                                [size]: url.replace(urlExistsPathKey, pathKey)
                            };
                        },
                        {}
                    );

                    const composedUrls = composeRelativeUrls(assetUrls);

                    return { ...composedUrls, ...restAsset };
                });
            } else {
                return call(function*() {
                    const { file } = blob;

                    const name = serializeFilename({
                        reference,
                        developmentName,
                        filename,
                        prefix,
                        serial: enableSerial ? index + 1 : null
                    });

                    const filepath = `${folderPath}/${name}`;

                    const assetUrls = yield call(
                        fileUploadWorker,
                        file,
                        filepath,
                        sizePaths
                    );

                    yield call(handleLoading);

                    const composedUrls = composeRelativeUrls(assetUrls);

                    return { ...composedUrls, ...restAsset };
                });
            }
        }
    );

    return yield all(assetPayloads);
};
