import React, { useMemo, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import {
    withStyles,
    InputBase,
    InputAdornment,
    FormControl,
    LinearProgress
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { withField } from '@libs/reduxForm';
import { composeClsx } from '@libs/materialUI';

// Local
import Label from '../Label';
import FormHelperText from '../FormHelperText';
import Select from './Select';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    staticValue: PropTypes.string,
    selectData: PropTypes.array
};

const defaultProps = {
    required: false,
    staticValue: '',
    inputProps: {}
};

const styles = ({ spacing, palette, shape, typography }) => ({
    root: {
        width: '100%',
        height: spacing.unit * 4.5,
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,
        fontSize: typography.subtitle2.fontSize,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '&:hover': {
            borderColor: palette.grey[400]
        }
    },
    success: {
        // borderColor: `${palette.success.primary} !important`, // Focus override
        // '&:hover': {
        //     borderColor: `${palette.success.hover} !important` // Focus override
        // },
        borderColor: `#212121 !important`, // Focus override
        '&:hover': {
            borderColor: `#18191a !important` // Focus override
        }
    },
    error: {
        borderColor: `${palette.error.light} !important`, // Focus override
        '&:hover': {
            borderColor: `${palette.error.dark} !important` // Focus override
        }
    },
    inputGroup: {
        display: 'flex'
    },
    input: {
        height: '100%',
        padding: 0,
        borderLeft: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    focused: {
        borderColor: palette.grey[500],
        '&:hover': {
            borderColor: palette.grey[700]
        }
    },
    multiline: {
        display: 'flex',
        alignItems: 'flex-start',
        overflow: 'hidden',
        paddingTop: spacing.unit,
        paddingBottom: spacing.unit,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2
    },
    inputMultiline: {
        paddingRight: spacing.unit * 2
    },
    disabled: {
        display: 'flex',
        alignItems: 'center',

        backgroundColor: palette.background.light,
        color: palette.secondary.main,

        '&:hover': {
            borderColor: palette[300]
        }
    },
    label: {
        color: `${palette.grey[800]} !important` // Overrides focused
    },
    container: {
        width: '100%'
    },
    adornment: {
        zIndex: 1
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        position: 'absolute'
    },
    loader: {
        position: 'relative',
        color: 'tomato'
    },
    progress: {
        backgroundColor: 'var(--secondary)',
        height: spacing.unit * 0.5,
        marginTop: `${spacing.unit * -0.5}px !important`,
        marginLeft: 1,
        marginRight: 1,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: 'var(--primary)',
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    }
});

const LockAdornment = props => (
    <InputAdornment {...props} position='end'>
        <LockIcon color='disabled' />
    </InputAdornment>
);

const InputGroupSelect = compose(
    withField(),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: { inputGroup, ...classes },
        styles,
        className,
        theme,
        meta: { form, touched, error = null, asyncValidating },
        fieldName,
        input,
        inputProps,
        disabled,
        required,
        label,
        loading,
        staticValue,
        selectData = [],
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });
        const {
            value: {
                selectedValue = selectData[0].value,
                inputValue = ''
            } = {},
            onChange,
            onBlur,
            onFocus,
            ...restInput
        } = input;

        const handleOnBlur = useCallback(() => {
            onBlur();
        }, [onBlur]);

        const handleInputChange = useCallback(
            ({ target: { value = '' } }) => {
                onChange({
                    selectedValue,
                    inputValue:
                        value && inputProps.type === 'number'
                            ? Number(value)
                            : value
                });
            },
            [selectedValue, onChange]
        );

        const handleSelectChange = useCallback(
            input => {
                let value = input;
                if (typeof input === 'object') {
                    value = input.target.value;
                }
                onChange({
                    selectedValue: value,
                    inputValue
                });
            },
            [selectedValue, inputValue, onChange]
        );

        const id = `${form}-${fieldName}`;

        const isDisabled = disabled || loading;

        const success = !isDisabled && Boolean(inputValue);
        const hasError = touched && !!error;

        const SelectBaseProps = {
            data: selectData,
            value: selectedValue,
            handleChange: handleSelectChange,
            isDisabled,
            success,
            error: hasError
        };
        const inputBaseProps = {
            classes: {
                root: clsx(
                    c.root,
                    className,
                    success && c.success,
                    hasError && c.error
                ),
                ...Object.splice(c, [
                    'input',
                    'focused',
                    'multiline',
                    'inputMultiline',
                    'disabled'
                ])
            },
            ...(staticValue
                ? { value: staticValue }
                : {
                      ...restInput,
                      name: void 0,
                      value: inputValue,
                      onChange: handleInputChange,
                      onBlur: handleOnBlur
                  }),
            endAdornment: disabled && <LockAdornment className={c.adornment} />,
            disabled: isDisabled,
            id,
            ...inputProps,
            ...restProps
        };

        const progressStyle = useMemo(() => {
            const { palette } = theme;
            const primary = asyncValidating
                ? palette.primary.main
                : palette.secondary.main;
            const secondary = asyncValidating
                ? palette.primary.light
                : palette.secondary.light;

            return { '--primary': primary, '--secondary': secondary };
        }, [asyncValidating, theme]);

        return (
            <FormControl className={c.container}>
                {label && (
                    <Label
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                        htmlFor={id}
                    />
                )}
                <div className={inputGroup}>
                    <Select {...SelectBaseProps} />
                    <InputBase {...inputBaseProps} />
                </div>
                {(loading || asyncValidating) && (
                    <LinearProgress
                        classes={{
                            root: c.progress,
                            barColorPrimary: c.progressPrimary
                        }}
                        style={progressStyle}
                    />
                )}
                {touched && error && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
);

InputGroupSelect.propTypes = propTypes;
InputGroupSelect.defaultProps = defaultProps;

export default InputGroupSelect;
