import { useMemo } from 'react';

const useRetailerAggregatedData = data => {
    // Aggregating the data
    const retailerAggregatedData = useMemo(() => {
        const retailerMap = {};

        data.forEach(item => {
            const retailer = item.retailer;
            if (!retailerMap[retailer]) {
                retailerMap[retailer] = {
                    name: retailer,
                    value: 0
                };
            }
            retailerMap[retailer].value += 1;
        });

        return Object.values(retailerMap).sort((a, b) => b.value - a.value);
    }, [data]);

    return retailerAggregatedData;
};

export { useRetailerAggregatedData as default };
