import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = () => ({
    item: {
        paddingTop: `0 !important`,
        paddingBottom: `0 !important`
    }
});

const GridItem = ({
    classes: c,
    xs = 12,
    sm = 6,
    md = 3,
    children,
    noPadding = false,
    ...restProps
}) => (
    <Grid
        classes={noPadding ? c : {}}
        item
        xs={xs}
        sm={sm}
        md={md}
        {...restProps}
    >
        {children}
    </Grid>
);

GridItem.propTypes = {
    classes: PropTypes.object
};

const _GridItem = withStyles(styles)(GridItem);

export { _GridItem as default, _GridItem as GridItem };
