import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import { COLUMN_CONTAINER_WIDTH_UNITS } from '../../constants';
import Column from './Column';
import { PDFColumnContextProvider } from './useColumns';

const propTypes = {
    columns: PropTypes.array.isRequired
};

const styles = ({ spacing }) => ({
    columnContainer: {
        width: spacing.unit * COLUMN_CONTAINER_WIDTH_UNITS,
        marginTop: spacing.unit * 1,
        flexDirection: 'row'
    }
});

const DataColumns = withPDFStyles(styles)(({ columns, classes: c }) => {
    return (
        <PDFColumnContextProvider columns={columns}>
            <View style={c.columnContainer}>
                {columns.map((column, idx) => (
                    <Column
                        column={column}
                        key={`pdf-column-${idx}`}
                        columnNo={idx + 1}
                    />
                ))}
            </View>
        </PDFColumnContextProvider>
    );
});

DataColumns.propTypes = propTypes;

export default DataColumns;
