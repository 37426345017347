import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Collapse } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

// Local
import { detailsPropType } from './common';
import Row from './Row';

const propTypes = {
    details: detailsPropType.isRequired,
    minAmountShown: PropTypes.number.isRequired
};

const styles = ({ palette }) => ({
    root: {
        '& div:nth-child(even)': {
            background: lighten(palette.background.light, 0.8)
        },
        '& div:last-child': {
            border: 'none'
        }
    }
});

const Content = withStyles(styles)(
    ({ classes: c, details, showLess, minAmountShown }) => {
        const minimumDetails = details.slice(0, minAmountShown);
        const restDetails = details.slice(minAmountShown, details.length);

        return (
            <div className={c.root}>
                {minimumDetails.map((detail, index) => (
                    <Row {...detail} key={`cardRowMin--${index}`} />
                ))}
                <Collapse in={!showLess} unmountOnExit>
                    {restDetails.map((detail, index) => (
                        <Row {...detail} key={`cardRowRest--${index}`} />
                    ))}
                </Collapse>
            </div>
        );
    }
);

Content.propTypes = propTypes;

export default Content;
