import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import usePDFColumnContext from './useColumns';
import { FONT_FAMILY_BOLD } from 'common/constants';

const propTypes = {
    header: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

const styles = ({ spacing, palette }) => ({
    columnRow: {
        flexDirection: 'row',
        width: '70%',
        minHeight: spacing.unit * 3
    },
    columnHeader: {
        marginLeft: spacing.unit * 1.25,
        fontFamily: FONT_FAMILY_BOLD,
        flexDirection: 'row',
        alignItems: 'center'
    },
    columnCellText: { fontSize: 10 },

    columnCell: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: spacing.unit * 0.5,
        marginRight: spacing.unit * 1.25
    }
});

const ColumnRow = withPDFStyles(styles)(({ classes: c, header, value }) => {
    const {
        PDFColumnState: { fontSize }
    } = usePDFColumnContext();
    return (
        <View style={c.columnRow}>
            <View style={c.columnHeader}>
                <Text style={{ ...c.columnCellText, fontSize }}>{header}:</Text>
            </View>
            <View style={c.columnCell}>
                <Text style={{ ...c.columnCellText, fontSize }}>{value}</Text>
            </View>
        </View>
    );
});

ColumnRow.propTypes = propTypes;

export default ColumnRow;
