import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import {
    ARTWORK_LOCATIONS,
    ARTWORK_SCALES,
    DESIGNERS,
    PRINT_POSITIONS,
    PRINT_TYPES
} from 'libs';

// Artwork Locations
export const selectArtworkLocationsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[ARTWORK_LOCATIONS]
)(() => 'placeholder');

// Artwork Scales
export const selectArtworkScalesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[ARTWORK_SCALES]
)(() => 'placeholder');

// Designers
export const selectDesignersData = createCachedSelector(
    selectDatasets,
    datasets => datasets[DESIGNERS]
)(() => 'placeholder');

// Print Positions
export const selectPrintPositionsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[PRINT_POSITIONS]
)(() => 'placeholder');

// Print Type
export const selectPrintTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[PRINT_TYPES]
)(() => 'placeholder');
