import { startSubmit } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

import { DOMAIN_NAME } from 'orders/common';
import {
    ORDER_BASE_FACTORIES,
    ORDER_USER_RETAILER_WISE_FACTORIES
} from 'orders/redux.datasets';
import { FORM_NAME } from '../../Order/OrderFactory/common';
import { orderFactorySchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

function* updateDependentDataset(id, factoryName) {
    const dependentDataset = yield select(
        entitySelector,
        DOMAIN_NAME,
        ORDER_USER_RETAILER_WISE_FACTORIES
    );

    const dependentDatasetAfterEdit = dependentDataset.map(
        ({ factoryId, ...prevData }) => ({
            ...(factoryId === id
                ? { ...prevData, factory: factoryName }
                : prevData),
            factoryId
        })
    );

    yield put(
        setDataset({
            entity: ORDER_USER_RETAILER_WISE_FACTORIES,
            domain: DOMAIN_NAME,
            dataset: dependentDatasetAfterEdit
        })
    );
}

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            factoryName: payload.name,
            factoryCode: payload.code,
            factoryStartPoint: payload.startPoint,
            countryId: payload.country,
            isActive: id ? payload.isActive : true
        };

        const publishData = composeApiPayload(
            preparedPayload,
            orderFactorySchema
        );

        const { data } = yield call(apiRequest, {
            url: 'OrderFactories',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: ORDER_BASE_FACTORIES,
            isNew: id === 0,
            data
        });

        if (id > 0) {
            yield updateDependentDataset(id, payload.name);
        }

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Order factory',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
