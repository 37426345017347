import { takeLatest, put, call, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { renameProperties } from '@libs/object';
import composeApiPayload from '@libs/composeApiPayload';

import { setSnack } from 'utilities/redux.actions';
import { FORM_NAME } from '../common';

import { FETCH_CAD_DESIGNER_REPORT_DATA } from '../redux.actionTypes';
import {
    setCadDesignerReportData,
    setCadDesignerReportDataLoading
} from '../redux.actions';
import { cadDesignerReportSchema } from './schemas';

//Grid data fetch worker
function* fetchDesignerReportData(action) {
    const filteredItems = yield select(getFormValues(FORM_NAME));
    const payload = renameProperties(filteredItems, {
        from: 'startdate',
        to: 'enddate',
        designer: 'designerId',
        retailer: 'retailerId',
        department: 'departmentId',
        type: 'cadTypeId'
    });

    const requestPayload = composeApiPayload(payload, cadDesignerReportSchema);

    yield put(setCadDesignerReportDataLoading(true));
    try {
        const { data: { data = [] } = {} } = yield call(apiRequest, {
            url: 'Cad/GetCadDesignerReport',
            params: { ...requestPayload }
        });
        yield put(setCadDesignerReportData(data[0]));
        yield put(setCadDesignerReportDataLoading(false));
    } catch {
        yield put(setCadDesignerReportDataLoading(false));
        yield put(
            setSnack({
                message: 'Failed to retrieve',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
        console.log(`Error while fetching GetCadDesignerReport resources`);
    }
}

// Watchers
export default function*() {
    yield takeLatest(FETCH_CAD_DESIGNER_REPORT_DATA, fetchDesignerReportData);
}
