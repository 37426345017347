import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing }) => ({
    imageWrapper: {
        position: 'relative',
        lineHeight: 0,
        width: spacing.unit * 100,
        height: spacing.unit * 75
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

const ImageLoader = ({ imageSrc, altText, classes }) => {
    const [loadedImage, setLoadedImage] = useState(null);

    useEffect(() => {
        setLoadedImage(null);
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => setLoadedImage(imageSrc);
    }, [imageSrc]);

    return (
        <div className={classes.imageWrapper}>
            {loadedImage ? (
                <img
                    className={classes.image}
                    src={loadedImage}
                    alt={altText}
                />
            ) : (
                <div className={classes.loader}>
                    <CircularProgress color='inherit' size={50} thickness={2} />
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(ImageLoader);
