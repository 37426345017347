import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import { withStyles } from '@material-ui/core';
//Libs
import { fetchDatasets } from '@libs/datasets';
//Local
import OverlaySelectSample from './OverlaySelectSample';
import { FORM_NAME } from '../../../common';
import { ALL_ORDER_SAMPLES } from 'orders/redux.datasets';

const styles = ({ spacing }) => ({
    container: {
        display: 'grid',
        gridGap: `${spacing.unit * 1.25}px`
    }
});

const mapState = state => ({
    ...formValueSelector(FORM_NAME)(
        state,
        'type',
        'retailer',
        'noOfPacks',
        'isTBC',
        'sampleRefs'
    )
});

const SamplesContainer = compose(
    connect(
        mapState,
        { fetchDatasets }
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        type,
        retailer,
        noOfPacks,
        isTBC,
        fetchDatasets,
        sampleRefs = [null],
        orderSamplesLocked,
        mode
    }) => {
        const firstSampleRef = sampleRefs[0];

        const shouldRenderSameStyleCheckbox = index => {
            return (
                noOfPacks > 1 &&
                !!firstSampleRef &&
                index === 0 &&
                !orderSamplesLocked &&
                mode === 'new'
            );
        };

        const isDisabled = !type || !retailer || orderSamplesLocked;

        useEffect(() => {
            fetchDatasets(ALL_ORDER_SAMPLES);
        }, [type, retailer, isTBC]);

        return (
            <div className={c.container}>
                {sampleRefs.map((sample, idx) => (
                    <OverlaySelectSample
                        {...sample}
                        key={`sample-ref-${idx}`}
                        serial={idx + 1}
                        name={`sampleRefs[${idx}]`}
                        shouldRenderSameStyleCheckbox={shouldRenderSameStyleCheckbox(
                            idx
                        )}
                        disabled={isDisabled}
                        entity={ALL_ORDER_SAMPLES}
                    />
                ))}
            </div>
        );
    }
);

export default SamplesContainer;
