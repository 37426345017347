export default ({ palette, transitions, zIndex }) => ({
	appBar: {
		zIndex: zIndex.drawer + 1,
		transition: transitions.create(['width', 'margin'], {
			easing: transitions.easing.sharp,
			duration: transitions.duration.leavingScreen
		})
	},
	appBarCss: {
		borderBottom: `1px solid ${palette.grey[100]}`,
		boxShadow: 'none'
	}
});
