import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, GridContainer, Input, Select } from '@formComponents';

import { DOMAIN_NAME } from '../../common';
import { PILLING_METHOD, DRY_PROCESS } from '../../../redux.datasets';

const styles = () => ({
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0
    }
});

const PhysicalTestResultContainer = withStyles(styles)(({ classes: c }) => {
    return (
        <ContentContainer
            title='Physical Test Result'
            AdornmentComponent={<Fragment />}
            styles={{ container: c.contentContainer }}
        >
            <GridContainer>
                <GridItem md={4}>
                    <Input
                        name='shrinkageLength'
                        label='Shrinkage Length'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='shrinkageWidth'
                        label='Shrinkage Width'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='shrinkageSpirality'
                        label='Spirality'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='pillingLength'
                        label='Pilling Length'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='pillingWidth'
                        label='Pilling Width'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='pillingMethod'
                        label='Select Pilling Method'
                        domain={DOMAIN_NAME}
                        entity={PILLING_METHOD}
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='elastaneUnrecoveryLength'
                        label='Elastane Unrecovery Length'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='elastaneUnrecoveryWidth'
                        label='Elastane Unrecovery Width'
                        type='number'
                        adornment='%'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='dryProcess'
                        label='Select Dry Process'
                        domain={DOMAIN_NAME}
                        entity={DRY_PROCESS}
                    />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default PhysicalTestResultContainer;
