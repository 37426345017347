import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { printMailSubjectSelector } from 'sampling/redux.selectors';
import { FORM_NAME } from 'sampling/Print/common';
import {
    SampleHandoverEmailGroup,
    SampleHandoverEmailTemplate
} from '../../../components';
import {
    setSampleEmailBody,
    setSampleEmailReceivers
} from 'sampling/redux.actions';

const mapStateToProps = state => ({
    retailer: formValueSelector(FORM_NAME)(state, 'retailer'),
    emailSubject: printMailSubjectSelector(state, 'handover')
});

const mapDispatch = dispatch => ({
    formInitialize: (...args) => dispatch(initialize(...args))
});

const mapInputs = {
    groups: ({ payload }) => setSampleEmailReceivers(payload),
    templates: ({ payload }) => setSampleEmailBody(payload)
};

const Email = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: (values, dispatch, { handleSave }) => {
            handleSave(values);
        }
    }),
    connect(
        mapStateToProps,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        handleSubmit,
        valid,
        handleHandover,
        closePreviewModal,
        retailer,
        emailSubject,
        formInitialize
    }) => {
        const handoverCallbackHandler = handleHandover({
            closePreviewModal,
            closeMailModal
        });
        const handleSendMail = useCallback(() => {
            handleSubmit();
            setTimeout(() => handoverCallbackHandler(), 1);
        }, [handleSubmit, handoverCallbackHandler]);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, { subject: emailSubject });
        }, [emailSubject]);

        const mailProps = {
            MailGroupComponent: (
                <SampleHandoverEmailGroup retailer={retailer} />
            ),
            MailTemplatesComponent: (
                <SampleHandoverEmailTemplate retailer={retailer} />
            ),
            handleModalClose: closeMailModal,
            handleSubmit: handleSendMail,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default Email;
