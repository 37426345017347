import { formValueSelector } from 'redux-form';
import { select, call, all, put, fork } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { sizePathsSelector } from 'common/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';
import { userNameSelector } from 'auth/redux.selectors';
import { getFileNameFromURL } from '@libs/filenames';
import { MAIL_FORM_NAME } from 'common/common';
import { setLoading } from 'utilities/redux.actions';
import { setSnack } from 'utilities/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';

import { orderEmailSchema } from './orderPublishWorker/schemas';

export default function*(action) {
    const {
        payload: { orderNo, orderVersionNo },
        meta: { closeModals }
    } = action;
    try {
        yield put(setLoading(true));
        const userName = yield select(userNameSelector);
        const {
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body,
            report
        } = yield select(
            formValueSelector(MAIL_FORM_NAME),
            'receivers',
            'cc',
            'subject',
            'body',
            'report'
        );

        let absoluteURL = report.url;
        if (isBlobUrl(absoluteURL)) {
            const [reportBlobs, reportSizePaths] = yield all([
                call(getAssetBlobsWorker, {
                    assets: report,
                    mode: 'new',
                    handleLoading: f => f
                }),
                select(sizePathsSelector, 'reportPath')
            ]);

            const [reportPayload] = yield call(uploadAssetsWorker, {
                assetBlobs: reportBlobs,
                folderPath: `${reportSizePaths.sizeOriginal}/${orderNo}`,
                reference: orderNo,
                sizePaths: reportSizePaths,
                handleLoading: f => f,
                developmentName: `V${orderVersionNo}`
            });
            const reportPath = reportPayload ? reportPayload.sizeOriginal : '';
            absoluteURL = yield call(syncReportToDatabase, {
                reportReference: orderNo,
                reportUrl: reportPath,
                referenceDevNo: orderVersionNo
            });
        }

        const fileName = yield call(getFileNameFromURL, absoluteURL);

        let composedBody = `
          ${body}
          <a style="color: #234c72;margin-right: 10px;font-size: 16px;" href="${absoluteURL}">${fileName}</a>`;

        const emailPayload = composeApiPayload(
            {
                userName,
                toAddress: toAddress.replace(/\s/g, ''),
                ccAddress,
                subject,
                body: composedBody,
                echoOrderNo: orderNo
            },
            orderEmailSchema
        );
        yield call(apiRequest, {
            method: 'post',
            url: 'EmailSend/OrderEmailSend',
            data: emailPayload
        });

        closeModals && closeModals();
        yield put(setLoading(false));
        yield put(
            setSnack({
                message: 'Email request received. It will be sent soon.',
                type: 'success'
            })
        );
    } catch (error) {
        yield put(setLoading(false));
        yield put(
            setSnack({
                message: 'Failed to send.',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
