import React from 'react';

import Item from './Item';
import { MultiPage as MultiPageIcon } from '@assets/Icons';
import { MULTI_PAGE } from './constants';

const MultiPage = ({
    getFileName,
    selectedIds,
    shouldDisable,
    exportAsPage,
    multiPageLoading,
    moduleName
}) => {
    const handleDownload = () => {
        exportAsPage({
            fileName: getFileName('multi'),
            data: selectedIds,
            module: moduleName,
            reportType: MULTI_PAGE
        });
    };
    return (
        <Item
            icon={MultiPageIcon}
            title={'Multi page'}
            shouldDisable={shouldDisable(multiPageLoading)}
            onClick={handleDownload}
        />
    );
};

export default MultiPage;
