import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core/styles';

// Actions
import { setReportsViewMode } from 'store/actions';

// Local
import { ReportsToolbarIcon } from 'views/Reports/components';
import { MultiPageIcon, SinglePageIcon } from 'assets/Reports';
import styles from './styles';

/*
 * Domain: Reports
 * Page: Toolbar
 * Component: ViewMode
 * Type: --
 * ReportsToolbarViewMode
 */
const ReportsToolbarViewMode = ({
    classes: { root, icon },
    numPages,
    pageNumber,
    viewMode,
    className,
    setReportsViewMode,
}) => {
    return (
        <div className={classNames(root, className)}>
            <ReportsToolbarIcon
                title='Multiple Pages'
                Icon={MultiPageIcon}
                className={icon}
                onClick={() => setReportsViewMode('multi')}
                disabled={viewMode === 'multi'}
            />
            <ReportsToolbarIcon
                title='Single Page'
                Icon={SinglePageIcon}
                className={icon}
                onClick={() => setReportsViewMode('single')}
                disabled={viewMode === 'single'}
            />
        </div>
    );
};

const mapStateToProps = ({ reports: { pagination, viewMode } }) => ({
    ...pagination,
    viewMode,
});

const mapDispatchToProps = dispatch => ({
    setReportsViewMode: viewMode => dispatch(setReportsViewMode({ viewMode })),
});

const _ReportsToolbarViewMode = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(ReportsToolbarViewMode);

export {
    _ReportsToolbarViewMode as default,
    _ReportsToolbarViewMode as ReportsToolbarViewMode,
};
