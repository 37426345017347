import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles';
import { AttachmentsModal } from 'views/Sampling/modals';
import { SamplingEditor } from 'views/Sampling/components';
import AccordionContent from './AccordionContent';

// Local
import { API_BASE_URL } from 'config';
import { validateRequired } from 'helpers';
import {
    GridContainer,
    GridItem,
    FileIcon,
    DeleteIcon,
    Tooltip,
    withModal
} from 'components';

// Actions
import { fetchDatasets, setStepData, deleteAttachment } from 'actions';

// Entities
import { CURRENCIES, TRIM_SUPPLIERS, TRIM_TYPES,TRIM_DETAILS } from 'libs';

const validate = values => {
    const required = [];
    return { ...validateRequired(values, required) };
};

const mapStateToProps = ({ initSamplingPrint: { attachments } }) => ({
    attachments
});

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            fetchDatasets,
            setDataForSteps: setStepData,
            deleteAttachment
        },
        dispatch
    );
};

class SamplingPrintOther extends PureComponent {
    componentDidMount() {
        this.props.fetchDatasets([CURRENCIES, TRIM_SUPPLIERS, TRIM_TYPES]);
    }

    download = (uri, name) => {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        link.click();
    };

    composeDownload = attachmentId => {
        const link = `${API_BASE_URL}api/Download/GetDetailsDownload?moduleName=sample&detailsType=attachment&detailsId=${attachmentId}`;
        return window.location.assign(link);
    };

    render() {
        const {
            classes,
            handleAttachmentModal,
            deleteAttachment,
            attachments
        } = this.props;

        return (
            <Fragment>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <Typography component='div' style={{ display: 'flex' }}>
                            <Typography
                                style={{ flexGrow: 1, lineHeight: '35px' }}
                            >
                                <b>Attachment Details: </b>
                            </Typography>
                            <Button
                                variant='contained'
                                className={classNames(
                                    classes.blackButton,
                                    classes.addAttachmentButton
                                )}
                                onClick={() => handleAttachmentModal()}
                            >
                                Add attachment
                            </Button>
                        </Typography>
                        <Table className={classes.attachemntTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>File name</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            {attachments ? (
                                <TableBody>
                                    {attachments.map(
                                        (
                                            {
                                                file,
                                                imageUrl,
                                                imageName,
                                                imageContentType,
                                                attachmentRefId
                                            },
                                            index
                                        ) =>
                                            (file &&
                                                typeof file === 'object' &&
                                                file.name !== void 0) ||
                                            (imageUrl && imageName) ? (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {file &&
                                                        file.type !== void 0 &&
                                                        file.type.includes(
                                                            'image'
                                                        ) ? (
                                                            <img
                                                                src={URL.createObjectURL(
                                                                    file
                                                                )}
                                                                alt={file.name}
                                                            />
                                                        ) : !!imageContentType &&
                                                          imageContentType.includes(
                                                              'image'
                                                          ) ? (
                                                            <img
                                                                src={imageUrl}
                                                                alt={imageName}
                                                            />
                                                        ) : (
                                                            <FileIcon height='40px' />
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {file
                                                                ? file.name
                                                                : imageName}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <Tooltip
                                                            onClick={() =>
                                                                file &&
                                                                file.name !==
                                                                    void 0
                                                                    ? this.download(
                                                                          URL.createObjectURL(
                                                                              file
                                                                          ),
                                                                          file.name
                                                                      )
                                                                    : this.composeDownload(
                                                                          attachmentRefId
                                                                      )
                                                            }
                                                            title='Download it!'
                                                        >
                                                            <ExpandMoreIcon
                                                                width='24px'
                                                                height='24px'
                                                                className={classNames(
                                                                    classes.tooltipTriggerIcon,
                                                                    classes.customSvgIcon
                                                                )}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip
                                                            onClick={() =>
                                                                deleteAttachment(
                                                                    {
                                                                        index
                                                                    }
                                                                )
                                                            }
                                                            title='Delete it!'
                                                        >
                                                            <DeleteIcon
                                                                width='24px'
                                                                height='24px'
                                                                className={classNames(
                                                                    classes.tooltipTriggerIcon,
                                                                    classes.customSvgIcon
                                                                )}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ) : null
                                    )}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={3}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Add attachment
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <SamplingEditor
                            name='additionalComments'
                            label='Additional Comments'
                            placeholder='Write additional comments'
                        />
                    </GridItem>

                    {/* Inside accordion */}
                </GridContainer>

                <AccordionContent />
            </Fragment>
        );
    }
}

SamplingPrintOther.propTypes = {
    classes: PropTypes.object
};

const mapModals = {
    handleAttachmentModal: AttachmentsModal
};

const _SamplingPrintOther = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'SamplingPrintForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        validate
    }),
    withStyles(styles),
    withModal(mapModals)
)(SamplingPrintOther);

export {
    _SamplingPrintOther as default,
    _SamplingPrintOther as SamplingPrintOther
};
