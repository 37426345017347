import React, { useMemo } from 'react';
import { Page, Document, View, Text } from '@react-pdf/renderer';
import { string, object, bool } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';
import { Title, Comments } from 'components/PDF';

import Setup from './Setup';
import Details from './Details';
import Other from './Other';
import BrandingDetails from './BrandingDetails';
import ProductsDetails from './ProductDetails';
import PODetails from './PODetails';

const propTypes = {
    pageTitle: string.isRequired,
    docTitle: string.isRequired,
    setup: object.isRequired,
    details: object.isRequired,
    other: object.isRequired,
    hasWatermark: bool.isRequired,
    watermarkText: (props, propName) => {
        if (
            props.hasWatermark === true &&
            props[propName] === undefined &&
            typeof props[propName] !== 'string'
        ) {
            return new Error(
                'Please give watermarkText as string when you passed hasWatermark prop as true'
            );
        }
    }
};

const defaultProps = {
    hasWatermark: false,
    docTitle: 'Document'
};

const styles = ({ palette, spacing }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    },
    pageTitle: { fontSize: 14, borderBottom: 1 },

    watermarkContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',

        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    watermarkText: {
        position: 'relative',
        transform: 'rotate(-45deg)',
        textTransform: 'uppercase',
        color: palette.error.main,
        opacity: 0.6
    }
});

const PDFContent = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { spacing },
        pageTitle,
        docTitle,
        setup: { noOfPacks },
        details,
        other: { orderComment, specialComments, brands = [], ...other },
        products,
        poLength,
        poTable,
        grandTotal,
        hasWatermark,
        watermarkText
    }) => {
        const watermarkFont = useMemo(() => {
            if (hasWatermark) {
                switch (watermarkText) {
                    case 'held':
                        return spacing.unit * 15;
                    case 'soft publish':
                        return spacing.unit * 6;
                    default:
                        return spacing.unit * 10;
                }
            }
        }, [hasWatermark, watermarkText]);

        const renderWatermark = () => (
            <View style={c.watermarkContainer}>
                <Text
                    style={{
                        ...c.watermarkText,
                        fontSize: watermarkFont
                    }}
                >
                    {watermarkText}
                </Text>
            </View>
        );

        const pdfPageProps = useMemo(
            () => ({
                size: 'A3',
                orientation: 'landscape',
                style: c.page
            }),
            [c]
        );

        const poDetailsProps = useMemo(
            () => ({
                poTable,
                grandTotal
            }),
            [poTable, grandTotal]
        );

        return (
            <Document title={docTitle}>
                <Page {...pdfPageProps} wrap>
                    <View>
                        <Title text={pageTitle} style={c.pageTitle} />
                        <Details {...details} />
                        <Other {...other} />
                        {!!orderComment && (
                            <Comments
                                label='Order Comments'
                                comments={orderComment}
                            />
                        )}
                        {!!specialComments && (
                            <Comments
                                label='Special Product Comments'
                                comments={specialComments}
                                style={{ marginTop: 5 }}
                            />
                        )}
                        <BrandingDetails
                            brands={brands}
                            hasComments={!!orderComment && !!specialComments}
                            wrap={false}
                        />
                        <ProductsDetails products={products} wrap={false} />
                        <PODetails {...poDetailsProps} />
                    </View>
                    {hasWatermark && renderWatermark()}
                </Page>
            </Document>
        );
    }
);

PDFContent.propTypes = propTypes;
PDFContent.defaultProps = defaultProps;

export default PDFContent;
