import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import { string } from 'prop-types';
import RPDFImage from '@libComponents/ReactPDF/Image';

import imageExtensionFromFileName from '@libs/imageChecker';

const styles = StyleSheet.create({
    image: {
        width: '100%'
    },
    extension: {
        color: '#c8ced4'
    }
});

const propTypes = {
    url: string.isRequired,
    filename: string.isRequired
};

const Image = ({ filename, url }) => {
    const { isImage, extension } = imageExtensionFromFileName(filename);

    return isImage && url ? (
        <RPDFImage src={url} style={styles.image} />
    ) : (
        <Text className={styles.extension}>{extension.toUpperCase()}</Text>
    );
};

Image.propTypes = propTypes;

export { Image as default, Image };
