import React from 'react';
import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing }) => ({
    gridBodyCellGridCellContent: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: spacing.unit * 1.5,
        fontWeight: 400
    }
});

const GridCellContent = withStyles(styles)(
    React.memo(({ classes, className, content, ...rest }) => {
        return (
            <Typography
                className={clsx(classes.gridBodyCellGridCellContent, className)}
                {...rest}
            >
                {content}
            </Typography>
        );
    })
);

export { GridCellContent as default, GridCellContent };
