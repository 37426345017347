import React, { useRef, forwardRef, useEffect } from 'react';
import { withStyles, Checkbox as MaUCheckbox } from '@material-ui/core';
import { bool, string } from 'prop-types';

import { CheckboxBlankIcon, CheckboxFilledIcon } from '@assets/Icons';

const styles = ({ spacing }) => ({
    root: {
        padding: spacing.unit
    },
    icon: {
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5
    }
});

const propTypes = {
    indeterminate: bool,
    label: string
};

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, label, classes: c, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;
        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);
        return (
            <MaUCheckbox
                className={c.root}
                icon={<CheckboxBlankIcon className={c.icon} />}
                checkedIcon={<CheckboxFilledIcon className={c.icon} />}
                ref={resolvedRef}
                {...rest}
            />
        );
    }
);

IndeterminateCheckbox.propTypes = propTypes;
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

const _IndeterminateCheckbox = withStyles(styles)(IndeterminateCheckbox);

export {
    _IndeterminateCheckbox as default,
    _IndeterminateCheckbox as IndeterminateCheckbox
};
