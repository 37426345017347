import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Fade, Typography } from '@material-ui/core';

import useTransitionToggle from '@libHooks/useTransitionToggle';

import { useImageDnDState } from '../useImageDnD';
import { useDropZoneMouseOverStyle, useDropZone } from './hooks';

const propTypes = {
    handleClick: PropTypes.func.isRequired
};

const styles = ({ spacing, typography }) => ({
    root: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: `calc(100% - ${spacing.unit * 10}px)`,
        background: 'var(--backgroundColor)',
        boxShadow: 'var(--boxShadow)',
        cursor: 'var(--cursor)'
    },
    dragBox: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: 'var(--color)',
        pointerEvents: 'none'
    },
    dragIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 2.5,
        height: 2,
        backgroundColor: 'var(--color)',

        '&:before': {
            display: 'block',
            content: "''",
            width: 2,
            height: spacing.unit * 2.5,
            backgroundColor: 'var(--color)'
        }
    },
    dragText: {
        position: 'absolute',
        bottom: spacing.unit * -5,
        color: 'var(--textColor)',
        fontSize: '0.95rem',
        fontWeight: typography.fontWeightLight,
        whiteSpace: 'nowrap',
        '& > strong': {
            color: 'var(--strongTextColor)'
        }
    }
});

const DropZone = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { transitions }, theme, handleClick }) => {
        const dropZoneHandles = useDropZone(handleClick);
        const { isDragging, file } = useImageDnDState();
        const dropZoneMouseOverStyleHandles = useDropZoneMouseOverStyle(theme);

        const isVisible = useMemo(() => !file || isDragging, [
            file,
            isDragging
        ]);

        const shouldRender = useTransitionToggle(
            isVisible,
            transitions.duration.complex
        );

        return (
            shouldRender && (
                <Fade in={isVisible}>
                    <div
                        className={c.root}
                        onDragOver={e => {
                            e.preventDefault();
                        }}
                        {...dropZoneHandles}
                        {...dropZoneMouseOverStyleHandles}
                    >
                        <div className={c.dragBox}>
                            <div className={c.dragIcon} />
                            <Typography
                                variant='subtitle2'
                                className={c.dragText}
                            >
                                <strong>Click browse</strong> or drag in here
                            </Typography>
                        </div>
                    </div>
                </Fade>
            )
        );
    }
);

DropZone.propTypes = propTypes;

export default DropZone;
