import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';

export const composeClsx = ({ classes, styles = {} }, renames = {}) =>
    Object.entries(classes).reduce(
        (accumulator, [key, value]) => ({
            ...accumulator,
            [[renames[key] ? renames[key] : key]]: classNames(
                value,
                styles[key]
            )
        }),
        {}
    );

export const createShadow = ({
    hOffset = 0,
    vOffset = 0,
    blur = 0,
    spread = 0,
    color = 'black',
    inset = false
}) => {
    const insetText = inset ? 'inset' : '';

    return `${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color} ${insetText}`;
};

export const withStylesAndRef = (...args) => Component => {
    const StyledComponent = withStyles.apply(null, args)(props => (
        <Component {...props} />
    ));
    return forwardRef((props, ref) => (
        <StyledComponent {...props} forwardRef={ref} />
    ));
};
