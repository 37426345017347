import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Libs
import { withField } from '@libs/reduxForm';
import { makeSideEffectCallback } from '@libs/makeContext';

// Local
import { BrandingDetailsProvider } from './useBrandingDetails';
import Container from './Container';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'brands'
};

const BrandingDetails = withField()(({ input: { value, onChange } }) => {
    const initialize = useCallback(
        state => ({
            ...state,
            brands: value || []
        }),
        [value]
    );

    const middlewareProps = useMemo(
        () => ({
            reduxFormOnChange: makeSideEffectCallback(onChange)
        }),
        [onChange]
    );

    return (
        <BrandingDetailsProvider
            initialize={initialize}
            middlewareProps={middlewareProps}
        >
            <Container />
        </BrandingDetailsProvider>
    );
});

BrandingDetails.propTypes = propTypes;
BrandingDetails.defaultProps = defaultProps;

export { BrandingDetails as default, BrandingDetails };
