import { select, all } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';

import { renameProperties } from '@libs/object';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';
import { userIdSelector } from 'auth/redux.selectors';

import { FORM_NAME } from '../../../Order/common';
import { isNinetyPercentSelector } from 'orders/redux.selectors';

export default function*() {
    const [
        userId,
        isNinetyPercent,
        {
            sampleSendDate,
            purchaseOrders = [],
            brands = [],
            BDCommission,
            otherCommission,
            costDated,
            ...values
        }
    ] = yield all([
        select(userIdSelector),
        select(isNinetyPercentSelector),
        select(
            formValueSelector(FORM_NAME),
            'id',
            'department',
            'sampleSendDate',
            'styleNo',
            'productDescription',
            'clientPONo',
            'factory',
            'factoryRef',
            'factorySl',
            'supplierNo',
            'season',
            'productionSampleType',
            'sendToEcho',
            'sendToBuyer',
            'systemFactoryNo',
            'kimballColor',
            'kimballNo',
            'commodity',
            'nlKind',
            'blankOrder',
            'purchaseOrders',
            'brands',
            'packingDepartmentNo',
            'asosDepartment',
            'packingSection',
            'licensingCompany',
            'packType',
            'boxType',
            'costDated',
            'costBasedOn',
            'htsCode',
            'BDCommission',
            'otherCommission',
            'orderComment',
            'specialComments',
            'isTBC',
            'isSampleAmend'
        )
    ]);

    return renameProperties(
        {
            ...values,
            sampleEta: deserializeDate(sampleSendDate),
            userId,
            costingDate: deserializeDate(costDated),

            poDetails: purchaseOrders.map(
                ({
                    retailPrice,
                    yarnPrice,
                    dips,
                    estimateFactoryDate,
                    freeOnBoardDate,
                    sizeDetails,
                    poNoTaken,
                    isEdit,
                    ...details
                }) => ({
                    ...renameProperties(details, {
                        cidNO: 'cid',
                        poNo: 'pono',
                        paymentTerms: 'paymentTermsId',
                        settlementDiscount: 'settlementDiscountId',
                        poPackId: 'packId',
                        orderType: 'poTypeName',
                        shipMode: 'poModeName',
                        shipDestination: 'poDestinationName',
                        packType: 'poPackType',
                        orderQuantity: 'quantity',
                        totalRatio: 'totalPack',
                        orderStyle: 'styleNo',
                        totalFabric: 'totalFabricKg'
                    }),
                    retailPrice: renameProperties(retailPrice || {}, {
                        selectedValue: 'name',
                        inputValue: 'value'
                    }),
                    yarnPrice: renameProperties(yarnPrice || {}, {
                        selectedValue: 'name',
                        inputValue: 'value'
                    }),
                    exFtyDate: deserializeDate(estimateFactoryDate),
                    fobDate: deserializeDate(freeOnBoardDate),
                    labDetails: dips.map(
                        ({
                            fobPrice,
                            echoPrice,
                            licenseFee,
                            color,
                            ...dip
                        }) => ({
                            ...renameProperties(dip, {
                                batchConfirmation: 'batchRef',
                                colorRecipe: 'colorNote',
                                sustainability: 'orderSustainabilityId',
                                composition: 'compositionId',
                                fabricType: 'fabricTypeId',
                                gsm: 'gsmId'
                            }),
                            fobPrice: renameProperties(fobPrice || {}, {
                                selectedValue: 'name',
                                inputValue: 'value'
                            }),
                            echoPrice: renameProperties(echoPrice || {}, {
                                selectedValue: 'name',
                                inputValue: 'value'
                            }),
                            licensorFee: renameProperties(licenseFee || {}, {
                                selectedValue: 'name',
                                inputValue: 'value'
                            }),
                            colorName: isNinetyPercent
                                ? color
                                : Number(color.split('-').pop())
                        })
                    ),
                    sizeDetails: sizeDetails.map(({ locked, ...rest }) => rest)
                })
            ),
            labelingDetails: brands.map(({ id, basicTrimId }) => ({
                id,
                basicTrimId
            })),
            bdCommission: renameProperties(BDCommission || {}, {
                selectedValue: 'name',
                inputValue: 'value'
            }),
            otherCommission: renameProperties(otherCommission || {}, {
                selectedValue: 'name',
                inputValue: 'value'
            })
        },
        {
            department: 'departmentId',
            clientPONo: 'clientPoNo',
            factoryRef: 'orderFactoryRef',
            productDescription: 'productionDescription',
            season: 'orderSeason',
            factory: 'orderFactory',
            supplierNo: 'orderSupplierNo',
            productionSampleType: 'orderProductionSamples',
            systemFactoryNo: 'sysFacNo',
            blankOrder: 'isBlankOrder',
            kimballNo: 'kimbelNo',
            kimballColor: 'kimbleColour',
            sendToEcho: 'ukSize',
            sendToBuyer: 'buyerSize',
            packingDepartmentNo: 'departmentNo',
            asosDepartment: 'asosDepartmentName',
            packingSection: 'sectionDescription',
            orderComment: 'orderComments',
            specialComments: 'specialorderComments',
            tbcOrder: 'isTBC'
        }
    );
}
