import React from 'react';

import {
    ARMHOLE_CUFF_ROUTE_ID,
    HEMLINE_ROUTE_ID,
    NECKLINE_ROUTE_ID,
    FIT_TYPE_ROUTE_ID,
    REQUEST_TYPE_ROUTE_ID,
    SIZE_RANGE_ROUTE_ID,
    LABEL_TYPE_ROUTE_ID,
    SAMPLE_EMAIL_GROUP_ROUTE_ID,
    SAMPLE_EMAIL_BODY_ROUTE_ID,
    SAMPLE_FACTORY_ROUTE_ID,
    SAMPLE_STATUS_ROUTE_ID,
    SAMPLE_ACTION_ROUTE_ID,
    SAMPLE_SIZE_ROUTE_ID,
    CURRIER_ROUTE_ID,
    DISPATCH_TYPE_ROUTE_ID,
    DISPATCH_SIZE_ROUTE_ID,
    DISPATCH_EMAIL_GROUP_ROUTE_ID,
    DISPATCH_EMAIL_BODY_ROUTE_ID
} from './common';
import ArmholeCuff from './ArmholeCuff';
import Hemline from './Hemline';
import Neckline from './Neckline';
import FitType from './FitType';
import RequestType from './RequestType';
import SizeRange from './SizeRange';
import LabelType from './LabelType';
import SampleEmailGroup from './SampleEmailGroup';
import SampleEmailBody from './SampleEmailBody';
import SampleFactory from './SampleFactory';
import SampleStatus from './SampleStatus';
import SampleAction from './SampleAction';
import SampleSize from './SampleSize';

import Currier from './Currier';
import DispatchType from './DispatchType';
import DispatchSize from './DispatchSize';
import DispatchEmailGroup from './DispatchEmailGroup';
import DispatchEmailBody from './DispatchEmailBody';

export default {
    [ARMHOLE_CUFF_ROUTE_ID]: () => <ArmholeCuff />,
    [HEMLINE_ROUTE_ID]: () => <Hemline />,
    [NECKLINE_ROUTE_ID]: () => <Neckline />,
    [FIT_TYPE_ROUTE_ID]: () => <FitType />,
    [REQUEST_TYPE_ROUTE_ID]: () => <RequestType />,
    [SIZE_RANGE_ROUTE_ID]: () => <SizeRange />,
    [LABEL_TYPE_ROUTE_ID]: () => <LabelType />,
    [SAMPLE_EMAIL_GROUP_ROUTE_ID]: () => <SampleEmailGroup />,
    [SAMPLE_EMAIL_BODY_ROUTE_ID]: () => <SampleEmailBody />,
    [SAMPLE_FACTORY_ROUTE_ID]: () => <SampleFactory />,
    [SAMPLE_STATUS_ROUTE_ID]: () => <SampleStatus />,
    [SAMPLE_ACTION_ROUTE_ID]: () => <SampleAction />,
    [SAMPLE_SIZE_ROUTE_ID]: () => <SampleSize />,
    [CURRIER_ROUTE_ID]: () => <Currier />,
    [DISPATCH_TYPE_ROUTE_ID]: () => <DispatchType />,
    [DISPATCH_SIZE_ROUTE_ID]: () => <DispatchSize />,
    [DISPATCH_EMAIL_GROUP_ROUTE_ID]: () => <DispatchEmailGroup />,
    [DISPATCH_EMAIL_BODY_ROUTE_ID]: () => <DispatchEmailBody />
};
