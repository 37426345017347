import { useMemo } from 'react';

export const useCadStatistics = data => {
    return useMemo(() => {
        const convertRatio = data.splice(4, 5);
        const ratioParent = data.splice(1, 2);
        ratioParent[0].child = convertRatio[0];
        ratioParent[1].child = convertRatio[1];
        return [data[0], ...ratioParent, data[1]];
    }, [data]);
};
