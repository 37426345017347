import { createSelector } from 'reselect';
import { getFormValues, formValueSelector } from 'redux-form';

import { valueSelector } from '@libs/datasets';

import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { SPECIAL_FINISHES } from 'sampling/redux.datasets';
import { userLabelSelector } from 'auth/redux.selectors';
import { MAIN_BODY_FABRIC_ID } from 'sampling/common';

import { DOMAIN_NAME } from './common';
import { FORM_NAME } from './SealSample/common';
import {
    SEAL_SAMPLE_COLORS,
    LAUNDRY_STATUS,
    STOCKPILE_STATUS,
    CONSENT_STATUS,
    SEAL_SAMPLE_TYPES,
    SEAL_SAMPLE_STATUS
} from './redux.datasets';

const qualityControlSelector = state => state[DOMAIN_NAME];
const sealSampleFormSelector = formValueSelector(FORM_NAME);
const sealSampleFormAllValuesSelector = state =>
    getFormValues(FORM_NAME)(state);

const colorChildrenLoadingSelector = createSelector(
    qualityControlSelector,
    ({ colorChildrenLoading }) => colorChildrenLoading
);
const referenceGenerateLoadingSelector = createSelector(
    qualityControlSelector,
    ({ referenceGenerateLoading }) => referenceGenerateLoading
);

const PDFPropsSelector = createSelector(
    state => sealSampleFormAllValuesSelector(state),
    state => state,
    (_, mode) => mode,
    (values, state, mode) => {
        const {
            jwpNo,
            qcStyle,
            qcColor,
            qcDate,
            sealDate,
            factory,
            quantity,
            styleDesc,
            qcVersion,
            finishWash,
            fabricBatch,
            prodUnit,
            panelStatus,
            bulkProductionUnit = {},
            qcReference,
            bodyFabricStatus,
            sampleColorStatus,
            accAndTrimStatus,
            missingTrims,
            embellishmentStatus,
            washStatus,
            stichingStatus,
            washShrinkage = {},
            printShrinkage = {},
            spirality,
            foundGSM,
            patternStatus,
            fitStatus,
            typeOfSample,
            fabrics,
            frontImage = {},
            backImage = {},
            commentsFromBuyer,
            generalInstructions,
            sealOrTrimCardVerification,
            workmanshipComments,
            measurementComments,
            preventiveCorrective,
            fabricCTQ,
            embellishmentCTQ,
            accTrimsCTQ,
            CPRTWashCTQ,
            cuttingCTQ,
            finishingPackingCTQ,
            sewingCTQ,
            bulkProductionComplicacy,
            sampleQTY,
            sampleReviewStatus,
            rejectQTY,
            dhu,
            tdr,
            remarks,
            attachments
        } = values;

        const bulkProductionUnitValues = Object.values(bulkProductionUnit)
            .filter(value => value)
            .map((value, index) => `${index + 1}. ${value}`)
            .join(' ');

        const versionNo = 'V' + (mode === 'amend' ? qcVersion + 1 : qcVersion);

        const { composition, gsm } =
            fabrics.find(
                ({ fabricForId }) => fabricForId === MAIN_BODY_FABRIC_ID
            ) || {};

        return {
            setup: {
                jwpNo,
                qcStyle,
                qcColor: valueSelector(
                    state,
                    DOMAIN_NAME,
                    SEAL_SAMPLE_COLORS,
                    qcColor
                ),
                qcDate,
                sealDate,
                factory: factory || 'N/A',
                quantity: quantity || 'N/A',
                styleDesc: styleDesc || 'N/A',
                finishWash:
                    valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        SPECIAL_FINISHES,
                        finishWash
                    ) || 'N/A',
                fabricBatch: fabricBatch || 'N/A',
                prodUnit: prodUnit || 'N/A',
                panelStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        LAUNDRY_STATUS,
                        panelStatus
                    ) || 'N/A',
                bulkProductionUnit: bulkProductionUnitValues || 'N/A',
                versionNo: versionNo || 1
            },
            details: {
                qcReference,
                bodyFabricStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        bodyFabricStatus
                    ) || 'N/A',
                sampleColorStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        sampleColorStatus
                    ) || 'N/A',
                trimFabricStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        bodyFabricStatus
                    ) || 'N/A',
                accAndTrimStatus: accAndTrimStatus || 'N/A',
                missingTrims: missingTrims || 'N/A',
                embellishmentStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        embellishmentStatus
                    ) || 'N/A',
                washStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        washStatus
                    ) || 'N/A',
                stichingStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        STOCKPILE_STATUS,
                        stichingStatus
                    ) || 'N/A',
                patternStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        CONSENT_STATUS,
                        patternStatus
                    ) || 'N/A',
                fitStatus:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        CONSENT_STATUS,
                        fitStatus
                    ) || 'N/A',
                typeOfSample:
                    valueSelector(
                        state,
                        DOMAIN_NAME,
                        SEAL_SAMPLE_TYPES,
                        typeOfSample
                    ) || 'N/A',
                composition: composition || 'N/A',
                gsm: gsm || 'N/A',
                fabrics,
                front: frontImage && frontImage.url,
                back: backImage && backImage.url,
                sealSampleEvaluation: {
                    commentsFromBuyer: commentsFromBuyer || '<p>N/A</p>',
                    generalInstructions: generalInstructions || '<p>N/A</p>',
                    sealOrTrimCardVerification:
                        sealOrTrimCardVerification || 'N/A'
                },
                preProductionEvaluation: {
                    workmanshipComments: workmanshipComments || 'N/A',
                    measurementComments: measurementComments || 'N/A',
                    preventiveCorrective: preventiveCorrective || 'N/A'
                },
                productRiskAnalysis: {
                    fabricCTQ: fabricCTQ || 'N/A',
                    embellishmentCTQ: embellishmentCTQ || 'N/A',
                    accTrimsCTQ: accTrimsCTQ || 'N/A',
                    CPRTWashCTQ: CPRTWashCTQ || 'N/A',
                    cuttingCTQ: cuttingCTQ || 'N/A',
                    finishingPackingCTQ: finishingPackingCTQ || 'N/A',
                    sewingCTQ: sewingCTQ || 'N/A',
                    bulkProductionComplicacy: bulkProductionComplicacy || 'N/A'
                }
            },
            others: {
                washShrinkage: `${washShrinkage.length ||
                    0} x ${washShrinkage.width || 0}`,
                printShrinkage: `${printShrinkage.length ||
                    0} x ${printShrinkage.width || 0}`,
                spirality: `${spirality || 0}`,
                foundGSM: foundGSM || 'N/A',
                remarks: remarks || 'N/A',
                attachments,
                sampleQTY: sampleQTY || 0,
                sampleReviewStatus: valueSelector(
                    state,
                    DOMAIN_NAME,
                    SEAL_SAMPLE_STATUS,
                    sampleReviewStatus
                ),
                rejectQTY: rejectQTY || 0,
                dhu: `${dhu || 0}%`,
                tdr: `${tdr || 0}%`
            },
            isQA: userLabelSelector(state) === 'QA',
            docTitle: 'Seal and Pre-Production Sample Evaluation.pdf',
            pageTitle: 'Seal and Pre-Production Sample Evaluation'
        };
    }
);

const QCMailSubject = createSelector(
    state =>
        sealSampleFormSelector(state, 'qcReference', 'qcVersion', 'qcStyle'),
    (state, mode) => mode,
    ({ qcReference, qcVersion, qcStyle }, mode) => {
        const version =
            mode === 'amend'
                ? `V${Number(qcVersion) + 1}`
                : `V${Number(qcVersion)}`;

        return `${
            mode === 'amend' ? 'Amend' : 'New'
        } Seal Sample - ${qcReference} ${qcStyle} ${version}`;
    }
);

export {
    colorChildrenLoadingSelector,
    referenceGenerateLoadingSelector,
    PDFPropsSelector,
    QCMailSubject
};
