import React, { useMemo } from 'react';

import { TRIM_LABEL_TYPE } from 'setup/redux.datasets';
import { DOMAIN_NAME } from 'setup/common';
import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const TrimType = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Trim Label Type', canSort: true, sortKey: 'trimType' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'TRIM LABEL TYPE SETUP',
        domain: DOMAIN_NAME,
        entity: TRIM_LABEL_TYPE,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { TrimType as default, TrimType };
