import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import datasets from './redux.datasets';
import {
    SET_FABRIC_DEVELOPMENT_REFERENCE_GENERATE_LOADING,
    SET_FABRIC_EXPORT_XL_DATA_LOADING
} from './redux.actionTypes';

const initialState = {
    fabricDevelopmentReferenceLoading: false,
    fabricExportXLDataLoading: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }
        case SET_FABRIC_DEVELOPMENT_REFERENCE_GENERATE_LOADING: {
            return {
                ...state,
                fabricDevelopmentReferenceLoading: payload
            };
        }
        case SET_FABRIC_EXPORT_XL_DATA_LOADING: {
            return {
                ...state,
                fabricExportXLDataLoading: payload
            };
        }

        default: {
            return state;
        }
    }
};
