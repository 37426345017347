import { useCallback, useMemo, useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createShadow } from '@libs/materialUI';
import useAttachmentDetails, {
    useAttachmentDetailsState
} from '../useAttachmentDetails';

export const useDropZoneMouseOverStyle = ({ palette, shadows }) => {
    const { attachments, isDragging } = useAttachmentDetailsState();
    const [isHovered, setHovered] = useState(false);

    const onMouseEnter = useCallback(() => {
        if (!attachments.length && !isDragging) setHovered(true);
    }, [isDragging, attachments.length]);

    const onMouseLeave = useCallback(() => {
        if (!attachments.length && !isDragging) setHovered(false);
    }, [isDragging, attachments.length]);

    const style = useMemo(() => {
        const backgroundColor = isDragging
            ? fade(palette.secondary.main, 0.65)
            : palette.common.transparent;

        const boxShadow = isDragging
            ? createShadow({
                  blur: 6,
                  spread: 6,
                  color: fade(palette.secondary.main, 0.3),
                  inset: true
              })
            : shadows[0];

        const isDragBoxHovered = isDragging || isHovered;

        const color = isDragBoxHovered
            ? palette.secondary.main
            : palette.background.dark;

        const textColor = fade(
            palette.secondary.main,
            isDragBoxHovered ? 0.9 : 0.4
        );

        const strongTextColor = fade(
            palette.secondary.main,
            isDragBoxHovered ? 1 : 0.7
        );

        const hoverEnabled = !attachments.length && !isDragging;

        const cursor = hoverEnabled ? 'pointer' : 'default';

        return {
            '--backgroundColor': backgroundColor,
            '--boxShadow': boxShadow,
            '--color': color,
            '--textColor': textColor,
            '--strongTextColor': strongTextColor,
            '--cursor': cursor
        };
    }, [isDragging, attachments.length, isHovered, palette]);

    return { onMouseEnter, onMouseLeave, style };
};

export const useDropZone = handleFile => {
    const [{ attachments, isDragging }, dispatch] = useAttachmentDetails();

    const onDragEnter = useCallback(() => {
        if (!attachments.length) {
            dispatch({ type: 'setDragging', payload: true });
        }
    }, [dispatch, attachments.length]);

    const onDragLeave = useCallback(() => {
        dispatch({ type: 'setDragging', payload: false });
    }, [dispatch]);

    const onClick = useCallback(() => {
        if (!attachments.length && !isDragging) {
            handleFile();
        }
    }, [attachments.length, isDragging]);

    const onDrop = useCallback(
        event => {
            event.preventDefault();
            const { dataTransfer: { items = [] } = {} } = event;

            const attachments = [...items]
                .filter(({ kind }) => kind === 'file')
                .map(image => image.getAsFile());

            dispatch({ type: 'addAttachments', payload: attachments });
        },
        [dispatch]
    );
    return { onDragEnter, onDragLeave, onClick, onDrop };
};
