import React from 'react';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = () => ({
    container: {
        width: '100%',
        flexDirection: 'column',
        flexWrap: 'wrap'
    }
});

const ColumnContainer = withPDFStyles(styles)(
    ({ classes, styles, children }) => {
        return (
            <View style={{ ...classes.container, ...styles }}>{children}</View>
        );
    }
);

export default ColumnContainer;
