import makeContext from '@libs/makeContext';
import {
    VERSION_LABEL_HEIGHT_UNITS,
    VERSIONS_PADDING_UNITS,
    VERSIONS_PER_ROW
} from './constants';

const initialState = {
    versionsHeight: 0,
    versionsPerRow: VERSIONS_PER_ROW,
    versionsPaddingUnits: VERSIONS_PADDING_UNITS,
    versionLabelHeightUnits: VERSION_LABEL_HEIGHT_UNITS
};

const versionsReducer = state => state;

const [VersionsProvider, useVersionsState] = makeContext(
    versionsReducer,
    initialState,
    { name: 'Versions' }
);

export { VersionsProvider as default, useVersionsState };
