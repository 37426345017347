import React from 'react';

import Checkbox from '../components/GridCheckbox';

const CHECKBOX_COLUMN_ID = '__checkbox-column';
const dataGridDefaultCheckboxProps = {
    name: CHECKBOX_COLUMN_ID,
    header: 'Checkbox',
    headerAlign: 'center',
    textAlign: 'center',
    renderCheckbox: props => <Checkbox {...props} />,
    showColumnMenuTool: false,
    sortable: false,
    draggable: false,
    resizable: false,
    width: 60,
    minWidth: 50,
    maxWidth: 80
};

export {
    dataGridDefaultCheckboxProps as default,
    dataGridDefaultCheckboxProps,
    CHECKBOX_COLUMN_ID
};
