import { useMemo } from 'react';

import { LABELS, GENERAL_USER_ROLE_ID } from '../common';

const useSchema = (mode, { role }) => {
    const isGeneralUserRole = role === GENERAL_USER_ROLE_ID;

    const basicInformationSchema = useMemo(
        () => ({
            name: true,
            email: true,
            active: mode === 'new',
            role: true,
            reportTo: isGeneralUserRole
        }),
        [isGeneralUserRole, mode]
    );

    const permissionsSchema = useMemo(
        () => ({
            permissions: (value, error) => !error
        }),
        []
    );

    return useMemo(
        () => ({
            [LABELS.basicInformation]: basicInformationSchema,
            [LABELS.permissions]: permissionsSchema,
            [LABELS.complete]: {},
            publish: { ...basicInformationSchema, ...permissionsSchema }
        }),
        [basicInformationSchema, permissionsSchema]
    );
};

export { useSchema as default };
