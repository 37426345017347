const normalizeDate = date => {
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	// Format - dd/MM/yyyy
	return new Date(date).toLocaleString('en-GB', options);
};

// Format - MM/dd/yyyy
const denormalizeDate = date => {
	const [day, month, year] = date.split('/');
	return [month, day, year].join('/');
};

export { normalizeDate as default, normalizeDate, denormalizeDate };
