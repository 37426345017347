export default theme => ({
    root: {
        boxShadow: theme.shadows[3],
        marginBottom: theme.spacing.unit * 4,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: 0,
        boxShadow: 'unset',
    },
    document: {
        width: '100%'
    },
    page: {
        '& > canvas': {
            height: '100% !important',
            width: '100% !important',
        },
        '& > .react-pdf__Page__annotations': {
            display: 'none',
        },
    },
});
