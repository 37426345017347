import { call, select } from 'redux-saga/effects';

import { apiRequest } from './apiRequest';
import { reorderColumnsWithFrozen } from '@libs/dataGridUtils';
import { userIdSelector } from 'auth/redux.selectors';
import downloadBlob from '@libs/downloadBlob';

const URL_PROPERTIES = ['sizeOriginal', 'size100', 'size350', 'size800'];

export const composeRelativeUrls = urls =>
    Object.entries(urls).reduce((acm, [key, value]) => {
        if (URL_PROPERTIES.includes(key)) {
            const [url] = value.split('.com/').slice(-1);

            return { ...acm, [key]: url };
        } else return { ...acm, [key]: value };
    }, {});

export function* fileUploadWorker(file, originalUrl, sizePaths) {
    const { sizeOriginal } = sizePaths;
    const payload = Object.entries(sizePaths).reduce(
        (acm, [size, path]) => {
            const url = originalUrl.replace(sizeOriginal, path);
            return { ...acm, [size]: url };
        },
        { file }
    );

    const {
        data: { data }
    } = yield call(apiRequest, {
        method: 'post',
        url: 'FileUpload',
        data: payload,
        transformData: true
    });

    // Clears away any empty values
    return Object.entries(data).reduce((acm, [size, path]) => {
        if (path) return { ...acm, [size]: path };
        else return acm;
    }, {});
}

export function* gridXLFileDownload({ payload, fileName, endpoint }) {
    const { filters, gridColumns, selectedRows, frozenColumns } = payload;

    const userId = yield select(userIdSelector);

    const normalizedColumns = reorderColumnsWithFrozen(
        gridColumns,
        frozenColumns
    );

    const normalizedFilters = Object.entries(filters).map(([name, filter]) => ({
        name,
        ...filter
    }));

    const requestData = {
        filters:
            normalizedFilters.length > 0
                ? JSON.stringify(normalizedFilters)
                : null,
        id: selectedRows.length > 0 ? selectedRows.join(',') : null,
        excelColumns: normalizedColumns,
        userId
    };

    const { data: blob } = yield call(apiRequest, {
        method: 'POST',
        url: endpoint,
        data: requestData,
        responseType: 'blob'
    });

    yield call(downloadBlob, { blob, fileName });
}
