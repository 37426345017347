import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

// Material
import { withStyles, FormControl } from '@material-ui/core';

// Local
import containerStyles from '@baseComponents/MultiSelect/styles';
import selectStyles from '@baseComponents/MultiSelect/Select/styles';
import Select from '@baseComponents/MultiSelect/Select';

const propTypes = {
    name: PropTypes.string.isRequired,
    defaultValues: PropTypes.array,
    data: PropTypes.array.isRequired,
    handleSelect: PropTypes.func.isRequired
};

const defaultProps = {
    placeholder: 'Select..',
    handleSelect: f => f
};

class GridMultiSelect extends Component {
    state = {
        unitHeight: null,
        unitWidth: null,
        iconRight: null
    };

    componentDidMount() {
        const node = node => ReactDOM.findDOMNode(node);
        const containerNode = node(this.containerRef);

        const scrollbarWidth =
            containerNode.offsetWidth - containerNode.clientWidth;

        this.setState({
            unitWidth: containerNode.offsetWidth - scrollbarWidth,
            unitHeight: containerNode.offsetHeight
        });
    }

    renderSelect = () => {
        const { state, props } = this;
        const { unitHeight, unitWidth } = state;
        const {
            classes,
            name,
            value = [],
            handleOnBlur,
            handleSelect,
            ...selectProps
        } = props;
        const SelectComponent = withStyles(
            selectStyles({ unitHeight, unitWidth })
        )(Select);

        return (
            unitHeight && (
                <SelectComponent
                    {...selectProps}
                    meta={{
                        form: 'dataGrid',
                        initial: value
                    }}
                    input={{
                        name,
                        value,
                        onChange: handleSelect,
                        onFocus: f => f,
                        onBlur: !!handleOnBlur ? handleOnBlur : f => f
                    }}
                    dialogWidth={unitWidth}
                />
            )
        );
    };

    render() {
        const { state, props, renderSelect } = this;
        const { unitHeight } = state;
        const {
            classes: { container },
            className,
            styles = {}
        } = props;

        const containerClassName = classNames(
            container,
            styles.control || className
        );

        return (
            <Fragment>
                {Boolean(unitHeight) || (
                    <FormControl
                        className={containerClassName}
                        ref={ref => (this.containerRef = ref)}
                    />
                )}

                {renderSelect()}
            </Fragment>
        );
    }
}

GridMultiSelect.propTypes = propTypes;
GridMultiSelect.defaultProps = defaultProps;

const _GridMultiSelect = withStyles(containerStyles)(GridMultiSelect);

export { _GridMultiSelect as default, _GridMultiSelect as GridMultiSelect };
