import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Libs
import { composeClsx, withStylesAndRef } from '@libs/materialUI';

// Local
import { useSwitchColor, useDropdownStyle } from './hooks';
import { optionShape } from './common';

const propTypes = {
    size: PropTypes.number.isRequired,
    option: PropTypes.exact(optionShape).isRequired,
    color: PropTypes.string.isRequired,
    handleOpen: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

const styles = ({ palette, spacing, typography, shadows }) => ({
    buttonRoot: {
        height: 'var(--height)',
        width: spacing.unit * 15,

        fontWeight: typography.fontWeightRegular,
        textTransform: 'none',
        boxShadow: shadows[0],
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,

        borderRightColor: 'var(--hoverColor)',
        borderRightStyle: 'solid',
        borderRightWidth: 1,

        color: palette.primary.contrastText,
        backgroundColor: 'var(--color)',
        '&:hover': {
            backgroundColor: 'var(--hoverColor)'
        },

        '&$disabled': {
            backgroundColor: 'var(--disabledColor)',
            color: palette.grey[200],
            '&:hover': {
                backgroundColor: 'var(--disabledColor)'
            }
        }
    },
    buttonLabel: {
        fontSize: '90%',
        fontWeight: typography.fontWeightRegular,
        justifyContent: 'start'
    },
    dropdownButton: {
        height: 'var(--height)',
        minWidth: spacing.unit * 4,
        maxWidth: spacing.unit * 4,

        fontWeight: typography.fontWeightRegular,
        textTransform: 'none',
        boxShadow: shadows[0],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,

        color: palette.primary.contrastText,
        backgroundColor: 'var(--color)',
        '&:hover': {
            backgroundColor: 'var(--hoverColor)'
        },

        '&$disabled': {
            backgroundColor: 'var(--disabledColor)',
            color: palette.grey[200],
            '&:hover': {
                backgroundColor: 'var(--disabledColor)'
            }
        }
    },
    disabled: {}
});

const ButtonGroup = withStylesAndRef(styles, { withTheme: true })(
    ({
        classes,
        styles,
        className,
        theme,
        color,
        forwardRef,
        size,
        handleOpen,
        option: { label, callback, disabled: defaultDisabled },
        disabled
    }) => {
        const c = useMemo(() => composeClsx({ classes, styles }), [
            classes,
            styles
        ]);

        const handleToggle = useCallback(() => {
            handleOpen(open => !open);
        }, [handleOpen]);

        const handleClick = useCallback(
            event => {
                callback(event);
                handleOpen(false);
            },
            [callback, handleOpen]
        );

        return (
            <div
                ref={forwardRef}
                style={useSwitchColor(theme, color)}
                className={className}
            >
                <Button
                    classes={{
                        root: c.buttonRoot,
                        label: c.buttonLabel,
                        disabled: c.disabled
                    }}
                    style={useDropdownStyle(size)}
                    variant='contained'
                    onClick={handleClick}
                    disabled={disabled || defaultDisabled}
                >
                    {label}
                </Button>
                <Button
                    classes={{
                        root: c.dropdownButton,
                        disabled: c.disabled
                    }}
                    style={useDropdownStyle(size)}
                    variant='contained'
                    onClick={handleToggle}
                    disabled={disabled}
                >
                    <ArrowDropDownIcon />
                </Button>
            </div>
        );
    }
);

ButtonGroup.propTypes = propTypes;

export default ButtonGroup;
