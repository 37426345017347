import React from 'react';
import clsx from 'classnames';

// Material
import { withStyles, FormHelperText } from '@material-ui/core';

const styles = () => ({
    root: {
        lineHeight: 1.2
    }
});

const LabelBase = ({ classes: c, children, className, ...restProps }) => (
    <FormHelperText
        classes={{
            root: clsx(c.root, className)
        }}
        {...restProps}
    >
        {children}
    </FormHelperText>
);

const FormHelperTextBase = withStyles(styles)(LabelBase);

export { FormHelperTextBase as default, FormHelperTextBase as LabelBase };
