import React from 'react';
import PropTypes from 'prop-types';

import InputGroupSelect from '@baseComponents/InputGroupSelect';
import { number, mustBeGreaterThanZero, required } from '@libs/validationRules';
import isTBCPO from '../../../isTBCPO';

import { currencyData } from '../../../../../../customData';

export const customRequired = (fieldValue, { poNo }) => {
    if (!poNo) return null;
    if (isTBCPO(poNo)) return null;
    return required(fieldValue);
};

const echoPriceGreaterThanFob = (fieldValue = {}, { dips }, _, field) => {
    const echoPrice = fieldValue.inputValue;
    const dipIndex = Number(field.split('[')[1].split(']')[0]);

    const { inputValue: fobPrice } = dips[dipIndex].fobPrice || {};
    return echoPrice && fobPrice && Number(fobPrice) >= Number(echoPrice)
        ? 'Echo price must be greater than Fob Price'
        : null;
};

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'echoPrice',
    label: 'Echo Price',
    placeholder: 'Echo Price',
    disabled: false,
    required: true,
    validate: [
        customRequired,
        number,
        mustBeGreaterThanZero,
        echoPriceGreaterThanFob
    ],
    inputProps: { type: 'number' }
};

const EchoPrice = props => (
    <InputGroupSelect {...props} selectData={currencyData} />
);

EchoPrice.propTypes = propTypes;
EchoPrice.defaultProps = defaultProps;

export default EchoPrice;
