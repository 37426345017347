export default ({ spacing, palette, transitions }) => ({
    card: {
        maxWidth: 345,
        borderRadius: 0,
        fontSize: 12,
        marginBottom: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: 0,
        transition: transitions.create('transform', {
            duration: transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
});
