import { startSubmit } from 'redux-form';
import { put, call } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';

import { DOMAIN_NAME } from 'orders/common';
import { PO_SHIP_MODE } from 'orders/redux.datasets';
import { FORM_NAME } from '../../Order/POShipMode/common';
import { poShipModeSchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            retailerId: payload.retailer
        };

        const publishData = composeApiPayload(
            preparedPayload,
            poShipModeSchema
        );

        const { data } = yield call(apiRequest, {
            url: 'ShipModeRetailers',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: PO_SHIP_MODE,
            isNew: id === 0,
            data
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'PO Ship Mode',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
