import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles, Typography, CircularProgress } from '@material-ui/core';

// Libs
import { PaginatedSelect } from '@formComponents/PaginatedSelect';

// Domain
import { DOMAIN_NAME } from '../../../common';
import { TRIM_REFERENCES } from '../../../../redux.datasets';

const propTypes = {
    loading: PropTypes.bool.isRequired
};

const styles = ({ palette, spacing, typography }) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3
    },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const Adornment = compose(withStyles(styles, { withTheme: true }))(
    ({ classes: c, theme: { spacing }, loading }) => {
        const paginatedSelectProps = useMemo(
            () => ({
                styles: {
                    container: c.selectContainer,
                    control: c.selectControl
                },
                name: 'trimReference',
                label: null,
                placeholder: 'Select reference',
                domain: DOMAIN_NAME,
                entity: TRIM_REFERENCES
            }),
            []
        );

        return (
            <div className={c.container}>
                {loading ? (
                    <CircularProgress size={spacing.unit * 4} />
                ) : (
                    <div />
                )}
                <div className={c.selectWrapper}>
                    <Typography variant='subtitle1' className={c.selectLabel}>
                        Add trim details:
                    </Typography>
                    <div className={c.selectContainerWrapper}>
                        <PaginatedSelect {...paginatedSelectProps} />
                    </div>
                </div>
            </div>
        );
    }
);

Adornment.propTypes = propTypes;

export default Adornment;
