import React from 'react';

const OrderHoldIcon = () => (
    <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_116_1221)'>
            <path
                d='M21.3753 17.6323V7.44158L12.6614 2.34619L3.91064 7.44158V17.6323L12.6614 22.7277L21.3753 17.6323Z'
                fill='#FFEFBE'
            />
            <mask
                id='mask0_116_1221'
                maskUnits='userSpaceOnUse'
                x='3'
                y='2'
                width='19'
                height='21'
            >
                <path
                    d='M21.3753 17.6323V7.44158L12.6614 2.34619L3.91064 7.44158V17.6323L12.6614 22.7277L21.3753 17.6323Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_116_1221)'>
                <path
                    d='M4.09521 7.44152L12.5875 12.6477L21.4121 7.47844L12.6244 2.38306L4.09521 7.44152Z'
                    fill='white'
                />
            </g>
            <path
                d='M21.3753 17.6323V7.44158L12.6614 2.34619L3.91064 7.44158V17.6323L12.6614 22.7277L21.3753 17.6323Z'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.91064 7.44153L12.6614 12.5369L21.3753 7.44153'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.6616 12.5369V22.7276'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.6461 24.1308C8.09315 24.1308 10.0769 22.1471 10.0769 19.7001C10.0769 17.253 8.09315 15.2693 5.6461 15.2693C3.19906 15.2693 1.21533 17.253 1.21533 19.7001C1.21533 22.1471 3.19906 24.1308 5.6461 24.1308Z'
                fill='white'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.646 17.2948V20.1432'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <circle cx='5.64615' cy='22.042' r='0.632967' fill='#FFC823' />
            <path
                d='M8.63672 4.67236L16.8336 9.98929'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.394 12.5739V14.42L18.754 12.4262V10.6169L15.394 12.5739Z'
                fill='white'
                stroke='#FFC823'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_116_1221'>
                <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.846191 0.5)'
                />
            </clipPath>
        </defs>
    </svg>
);

export { OrderHoldIcon as default, OrderHoldIcon };
