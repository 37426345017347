import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Local
import { ImageBrowser } from 'components';
import { imgTypeAllowed, fileSize } from 'helpers';

// Actions
import { setCadImage } from 'store/actions';

// Selectors
import { selectCadImage } from 'store/selectors';

class SamplingCadImageBrowser extends Component {
    state = {
        error: ''
    };

    handleCadImageModal = () => {
        const {
            modalCarousel: { handleModalCarousel },
            stepData: { cadImage }
        } = this.props;

        const cadImageArr = Object.keys(cadImage)
            .filter(imageSize => imageSize === '900_900')
            .map(image => cadImage[image].url);

        handleModalCarousel(cadImageArr.length ? cadImageArr : cadImage);
    };

    handleCadFile = ({ target: { files } }) => {
        this.resetError();
        if (files.length) {
            const { props, updateError } = this;
            const { setCadImage } = props;

            const file = files[0];
            if (file.size <= fileSize.bytes) {
                if (imgTypeAllowed.includes(file.type)) {
                    setCadImage(file);
                } else {
                    updateError('File type not allowed.');
                }
            } else {
                updateError('File size limit exceeded.');
            }
        }
    };

    resetError = () => this.setState({ error: '' });

    updateError = error => {
        this.setState({ error });
        setTimeout(this.resetError, 3000);
    };

    composeImageFile = () => {
        const { cadImage } = this.props;
        const cadInfo = { url: null };

        if (Array.isArray(cadImage) && cadImage.length) {
            //from API
            cadInfo.url = cadImage[0];
            cadInfo.name = 'Cad Image';
        } else if (cadImage && typeof cadImage === 'object') {
            //local file
            if (cadImage.name !== void 0)
                cadInfo.url = URL.createObjectURL(cadImage);
            if (cadImage.imageUrl !== void 0) cadInfo.url = cadImage.imageUrl;
        }

        return cadInfo;
    };

    render() {
        const { state } = this;
        const { error } = state;

        return (
            <ImageBrowser
                label='Cad'
                identifier='cad-upload'
                required
                multiple={false}
                onChange={this.handleCadFile}
                //handleImageModal={this.handleCadImageModal}
                handleImageModal={() => {}}
                imgFile={this.composeImageFile()}
                uploadError={error}
            />
        );
    }
}

const mapState = state => ({
    cadImage: selectCadImage(state)
});

const mapDispatch = {
    setCadImage
};

const _SamplingCadImageBrowser = connect(
    mapState,
    mapDispatch
)(SamplingCadImageBrowser);

export {
    _SamplingCadImageBrowser as default,
    _SamplingCadImageBrowser as SamplingCadImageBrowser
};
