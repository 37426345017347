import React from 'react';

import { useDetailsBreakdownChartState } from '../../useDetailsBreakdownChart';

import ExportPDF from './ExportPDF';
import ExportPDFButtonWithDisableState from './ExportButton';

const ReportExport = () => {
    const { retailer } = useDetailsBreakdownChartState();
    return retailer ? <ExportPDF /> : <ExportPDFButtonWithDisableState />;
};

export default ReportExport;
