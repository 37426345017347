import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import FilterSettings from './FilterSettings';

const propTypes = {
    FilterComponent: PropTypes.element.isRequired
};

const styles = () => ({
    gridFilterWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    }
});

const FilterWrapper = withStyles(styles)(
    ({ classes: c, FilterComponent, ...props }) => {
        return (
            <div className={c.gridFilterWrapper}>
                {FilterComponent}

                <FilterSettings {...props} />
            </div>
        );
    }
);

FilterWrapper.propTypes = propTypes;

export default FilterWrapper;
