import { printNewSteps, printAmendSteps } from '../static';

function getSteps(newPage = false, parent) {
    let steps = null;
    // parent => print, shape/non-print
    if (parent === 'print') {
        if (newPage) {
            steps = printNewSteps;
        } else {
            steps = printAmendSteps;
        }
    }

    return steps;
}

export { getSteps as default, getSteps };
