import { call, put } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { normalizeDate } from '@baseComponents/Date';
import { splice } from '@libs/object';

import { normalizeFile } from 'sampling/redux.normalizers';

import { FORM_NAME, INITIAL_VALUES } from '../../FabricDevelopment/common';

export default function*(fabricReference, isCopy = false) {
    const {
        data: {
            data: [
                {
                    dateRequested,
                    dateRequired,
                    originalFabric,
                    requiredFabric,
                    retailer,
                    department,
                    originalImage,
                    washImage,
                    finalImage,
                    developedFabric,
                    dateApproved,
                    ...data
                } = {}
            ] = []
        } = {}
    } = yield call(apiRequest, {
        url: 'FabricDevelopment/GetFabricDevelopmentDetailsByRef',
        params: {
            fabricReference
        }
    });

    const fabricKeyRenamed = fabric => ({
        fabricType: fabric.typeId,
        composition: fabric.compositionId,
        gsm: fabric.gsmId
    });

    const copyWhenAmendOrIncomplete = {
        id: 'id',
        fabricReference: 'fabricReference',
        dateRequested: 'dateRequested',
        dateRequired: 'dateRequired',
        versionNo: 'versionNo',
        developedFabric: 'developedFabric',
        finalImage: 'finalImage',
        yarnBatchNo: 'batchNo',
        receipeNo: 'recipeNo',
        fabricYarnCountId: 'yarnCount',
        yarnLotNo: 'yarnLot',
        yarnSupplierId: 'yarnSupplier',
        shrinkageLength: 'shrinkageLength',
        shrinkageWidth: 'shrinkageWidth',
        shrinkageSpirality: 'shrinkageSpirality',
        pillingLength: 'pillingLength',
        pillingWidth: 'pillingWidth',
        fabricPillingMethodId: 'pillingMethod',
        elastaneUnrecoveryLength: 'elastaneUnrecoveryLength',
        elastaneUnrecoveryWidth: 'elastaneUnrecoveryWidth',
        fabricDryProcessId: 'dryProcess',
        fabricApprovalStatusId: 'approvalStatus',
        fabricApprovalById: 'approvedBy',
        dateOfApproved: 'dateOfApproved',
        fabricApprovalComments: 'approvalComments'
    };

    const values = splice(
        {
            ...data,
            originalFabric: fabricKeyRenamed(originalFabric),
            requiredFabric: fabricKeyRenamed(requiredFabric),
            dateRequested: normalizeDate(dateRequested),
            dateRequired: normalizeDate(dateRequired),
            originalFabricImage: normalizeFile(originalImage),
            washedImage: normalizeFile(washImage),
            developedFabric: fabricKeyRenamed(developedFabric),
            dateOfApproved: normalizeDate(dateApproved),
            finalImage: normalizeFile(finalImage)
        },
        {
            ...(isCopy ? {} : copyWhenAmendOrIncomplete),
            retailerId: 'retailer',
            departmentId: 'department',
            artworkSourceId: 'artworkSource',
            destinationCountryId: 'destinationCountry',
            fabricDevTypeSentId: 'developmentType',
            fabricBoughtSampleReference: 'boughtReference',
            originalFabric: 'originalFabric',
            originalFabricImage: 'originalFabricImage',
            requiredFabric: 'requiredFabric',
            isMatchFabricColor: 'hasMatchColor',
            isMatchWash: 'hasMatchWashAndFinish',
            fabricSpecialFinishId: 'specialFinish',
            fabricColorName: 'color',
            fabricColorReference: 'colorReference',
            washedImage: 'washedImage',
            fabricComments: 'comments',
            fabricRequestedById: 'requestBy',
            fabricBuyerContactPerson: 'buyer',
            isSpeed: 'hasSpeed',
            isAvailability: 'hasAvailability',
            isSustainability: 'hasSustainability',
            fabricHangerTypeId: 'hangerType',
            fabricFactoryId: 'factory'
        }
    );

    yield put(
        initialize(
            FORM_NAME,
            {
                ...INITIAL_VALUES,
                ...values
            },
            isCopy,
            {
                updateUnregisteredFields: true,
                keepDirty: isCopy
            }
        )
    );
}
