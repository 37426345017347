import React from 'react';

// Material
import { withStyles } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = ({ palette, spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: `calc(100% - ${spacing.unit * 6.5}px)`,
        backgroundColor: fade(palette.secondary.main, 0.7),
        position: 'absolute',
        zIndex: 3,
        bottom: 0
    },
    icon: {
        width: spacing.unit * 10,
        height: spacing.unit * 10,
        opacity: 0.3,
        marginRight: spacing.unit * 5
    }
});

const SetupLocked = withStyles(styles)(({ classes: c }) => (
    <div className={c.root}>
        <Lock className={c.icon} />
    </div>
));

export default SetupLocked;
