import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './styles';
import { fileSize } from 'helpers';
import {
    GridContainer,
    GridItem,
    ImageDeleteBar,
    ModalHeader,
    ModalContent,
    ModalFooter
} from 'components';

import { uploadAttachments } from 'store/actions';

function mapStateToProps(state) {
    return {
        store: state
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            uploadAttachments
        },
        dispatch
    );
};

class AttachmentsModal extends PureComponent {
    state = {
        uploadError: ''
    };

    handleAttachments = event => {
        this.setState({ uploadError: '' });
        if (event.target.files.length > 0) {
            const imgArr = [];
            [...event.target.files].map((file, index) => {
                if (file.size <= fileSize.bytes) {
                    imgArr.push({ id: 0, file: file });
                } else {
                    this.showUploadError('File size limit exceeded.');
                }
                return true;
            });
            if (imgArr.length) this.props.uploadAttachments(imgArr);
        }
    };

    showUploadError = msg => {
        this.setState({ uploadError: msg });
        const THIS = this;
        setTimeout(function() {
            THIS.setState({ uploadError: '' });
        }, 3000);
    };

    handleAttachmentFiles = () => {
        const {
            store: { attachments }
        } = this.props;
        return (
            attachments &&
            attachments.map(({ name }, index) => ({
                name,
                onClick: () => this.handleAttachmentDelete(index)
            }))
        );
    };

    handleAttachmentDelete = index => {
        const {
            store: { attachments },
            uploadAttachments
        } = this.props;
        const uploadAttachmentFiles = [...attachments];
        uploadAttachmentFiles.splice(index, 1);
        uploadAttachments(uploadAttachmentFiles);
    };

    render() {
        const { state, props } = this;
        const {
            classes,
            onClose,
            store: { attachments }
        } = props;
        const { uploadError } = state;

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>Add attachment</ModalHeader>
                <ModalContent>
                    <GridContainer
                        spacing={0}
                        className={classes.containerStyle}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                label='Upload attachment image: '
                                InputProps={{
                                    onChange: e => this.handleAttachments(e),
                                    disableUnderline: true,
                                    inputProps: {
                                        type: 'file',
                                        multiple: true,
                                        accept: '*',
                                        size: fileSize.bytes,
                                        id: 'attachment-upload-input'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.inputLabelRoot,
                                        focused: classes.inputLabelFocused,
                                        shrink: classNames(
                                            classes.inputLabelShrank,
                                            classes.labelFileField
                                        )
                                    }
                                }}
                                className={classes.textField}
                            />
                            <label
                                htmlFor='attachment-upload-input'
                                className={classes.browseButtonRoot}
                            >
                                {attachments && attachments.length ? (
                                    <Typography
                                        className={classes.fileListLabel}
                                    >
                                        {attachments.map((file, index) => (
                                            <span key={index}>{file.name}</span>
                                        ))}
                                    </Typography>
                                ) : null}
                                <Button
                                    component='span'
                                    className={classes.browseButton}
                                    disableRipple
                                >
                                    Browse
                                </Button>
                            </label>

                            <Typography
                                style={{ color: '#aeaeae', marginTop: 15 }}
                            >
                                * Maximum file size: {fileSize.megabytes} MB.
                            </Typography>
                            <ImageDeleteBar
                                images={this.handleAttachmentFiles()}
                            />
                            <Typography
                                style={{
                                    color: 'red',
                                    marginTop: 15,
                                    textAlign: 'center'
                                }}
                            >
                                {uploadError}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer
                        spacing={0}
                        className={classes.containerStyle}
                    >
                        <Button
                            variant='contained'
                            classes={{
                                root: classes.saveButton,
                                disabled: classes.saveButtonDisabled
                            }}
                            onClick={() => onClose()}
                        >
                            Save
                        </Button>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

AttachmentsModal.propTypes = {
    classes: PropTypes.object
};

const _AttachmentsModal = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(AttachmentsModal);

export { _AttachmentsModal as default, _AttachmentsModal as AttachmentsModal };
