import { fork } from 'redux-saga/effects';

// Local
import mailWatchers from './mail';

const reportsSaga = function*() {
    yield fork(mailWatchers);
};

export { reportsSaga as default, reportsSaga };
