import axios from 'axios';
import isBlobUrl from './isBlobUrl';

export default async url => {
    try {
        if (isBlobUrl(url)) {
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: ''
                }
            });

            const image = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            );
            return `data:${response.headers[
                'content-type'
            ].toLowerCase()};base64,${image}`;
        }
        const response = await axios.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: ''
            }
        });

        const image = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
        );
        const mimeType = response.headers['content-type'].toLowerCase();

        const fixCorruptedImage =
            mimeType === 'image/png'
                ? image.replace(/(\r\n|\n|\r|\s|charset=UTF-8;)/gm, '')
                : image;

        return `data:${
            mimeType === 'image/png' ? 'image/jpeg' : mimeType
        };base64,${fixCorruptedImage}`;
    } catch (error) {
        console.log(error);
    }
};
