import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

// libs
import Search from '@libComponents/Search';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import Button from '@libComponents/Button';

// Local
import usePurchaseOrders from './usePurchaseOrders';

const propTypes = {
    handleAdd: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
};

const defaultProps = {};

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3,
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        marginLeft: spacing.unit
    }
});

const mapInputs = {};

const Adornments = compose(
    formListener(mapInputs),
    withStyles(styles, { withTheme: true })
)(({ classes: c, theme: { spacing }, handleAdd, loading, disabled }) => {
    const [{ queryValue }, dispatch] = usePurchaseOrders();

    const makeSearch = useCallback(
        value => {
            dispatch({ type: 'set-po-search', payload: value });
        },
        [dispatch]
    );

    return (
        <div className={c.root}>
            {loading ? <CircularProgress size={spacing.unit * 4} /> : <div />}
            <div className='container'>
                <Search
                    makeSearch={makeSearch}
                    placeholder='Search by po no'
                    value={queryValue}
                />
                <Button
                    color='success'
                    onClick={handleAdd}
                    disabled={disabled}
                    className={c.button}
                >
                    Add PO Details
                </Button>
            </div>
        </div>
    );
});

Adornments.propTypes = propTypes;
Adornments.defaultProps = defaultProps;

export default Adornments;
