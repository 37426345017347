import React from 'react';
import clsx from 'classnames';

import { withStyles, Grid } from '@material-ui/core';

const styles = ({ spacing }) => ({
    root: {
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2
    }
});

const GridItem = withStyles(styles)(({ classes: c, className, ...props }) => (
    <Grid item className={clsx(c.root, className)} {...props} />
));

export { GridItem as default, GridItem };
