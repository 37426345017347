import makeContext from '@libs/makeContext';
import {
    IMAGES_PADDING_UNITS,
    IMAGES_PER_ROW,
    IMAGE_LABEL_HEIGHT_UNITS
} from './constants';

const initialState = {
    imagesHeight: 0,
    imagesPerRow: IMAGES_PER_ROW,
    imagesPaddingUnits: IMAGES_PADDING_UNITS,
    imageLabelHeightUnits: IMAGE_LABEL_HEIGHT_UNITS
};

const imagesReducer = state => state;

const [ImagesProvider, useImagesState] = makeContext(
    imagesReducer,
    initialState,
    { name: 'Images' }
);

export { ImagesProvider as default, useImagesState };
