import { select, put } from 'redux-saga/effects';

import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

export default function*({ domain, entity, isNew, data }) {
    const dataset = yield select(entitySelector, domain, entity);
    if (isNew) {
        yield put(setDataset({ entity, domain, dataset: [data, ...dataset] }));
        return;
    }
    const datasetAfterEdit = dataset.map(({ id, ...prevData }) => ({
        id,
        ...(id === data.id ? data : prevData)
    }));
    yield put(setDataset({ entity, domain, dataset: datasetAfterEdit }));
}
