import React from 'react';

import { LinearProgress } from '@material-ui/core';

import { withStyles } from '@material-ui/core';

const styles = ({ palette, spacing }) => ({
    root: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        borderBottomLeftRadius: spacing.unit * 0.5,
        borderBottomRightRadius: spacing.unit * 0.5
    },
    colorPrimary: {
        backgroundColor: palette.common.transparent
    },
    barColorPrimary: {
        backgroundColor: 'var(--color)'
    }
});

export default withStyles(styles)(({ classes: c, ...restProps }) => {
    return <LinearProgress classes={c} {...restProps} />;
});
