import { formValueSelector } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { setSnack } from 'utilities/redux.actions';
import { BUILD_ENV } from '@libs/apiRequest/config';
import { ASSET_BASE_URL, MAIL_SENDER_ADDRESS } from 'common/common';
import { finalPhotoUploadTemplate, sendMail } from '@libs/mailer';
import { userNameSelector, userEmailSelector } from 'auth/redux.selectors';
import { apiRequest } from '@libs/apiRequest';

import { FORM_NAME } from 'sampling/FinalPhotoUpload/common';
import { setFinalPhotoEmailLoading } from 'sampling/redux.actions';

export default function*(action) {
    const {
        payload,
        meta: { handleRemoveBlobURL }
    } = action;
    try {
        yield put(setFinalPhotoEmailLoading(true));

        const [
            userName,
            userEmail,
            { sampleRef: sampleReference, retailerId, developmentNo }
        ] = yield all([
            select(userNameSelector),
            select(userEmailSelector),
            select(
                formValueSelector(FORM_NAME),
                'sampleRef',
                'retailerId',
                'developmentNo'
            )
        ]);

        /* PDF upload and uploaded URL */

        const [reportBlobs, reportSizePaths] = yield all([
            call(getAssetBlobsWorker, {
                assets: payload,
                mode: 'new',
                handleLoading: f => f
            }),
            select(sizePathsSelector, 'reportPath')
        ]);
        const [reportPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: reportBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/Final/${sampleReference}`,
            reference: sampleReference,
            sizePaths: reportSizePaths,
            handleLoading: f => f,
            developmentName: developmentNo
        });
        const reportURL = `${ASSET_BASE_URL[BUILD_ENV]}${reportPayload.sizeOriginal}`;

        /* Email */

        const {
            data: { data: [{ emailGroup = '' } = {}] = [] } = {}
        } = yield call(apiRequest, {
            url: 'SampleEmailGroup/GetSampleFinalEmailGroupByRetailerId',
            params: {
                id: retailerId
            }
        });

        const body = finalPhotoUploadTemplate(sampleReference, reportURL);
        const subject = `${userName}: Final Photo Upload ${sampleReference} ${developmentNo}`;
        const toAddress = emailGroup.split(',').map(email => email.trim());

        yield call(
            sendMail,
            JSON.stringify({
                fromAddress: MAIL_SENDER_ADDRESS,
                toAddress,
                ccAddress: userEmail,
                subject,
                body
            })
        );

        yield put(setFinalPhotoEmailLoading(false));

        handleRemoveBlobURL();

        yield put(
            setSnack({
                message: `Final photo email has been sent!`,
                type: 'success'
            })
        );
    } catch (error) {
        yield put(setFinalPhotoEmailLoading(false));

        const {
            response: { data: { message = 'server error' } = {} } = {}
        } = error;

        yield put(
            setSnack({
                message: 'Failed to send email due to ' + message,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
