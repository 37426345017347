export default ({ spacing, palette }) => ({
	root: {
		display: 'flex',
		padding: '5px 16px',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palette.grey[50],
		borderBottom: `1px solid ${palette.grey[200]}`,
		'&:last-child': {
			paddingBottom: 0
		},
	},
	rightAlign: {
		textAlign: 'right'
	}
});
