import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles';

function _Drawer({ ...props }) {
	const { children, classes, open, ...restPrps } = props;
	return (
		<Drawer
			classes={{
				paper: classNames(!open && classes.drawerPaperClose),
				paperAnchorLeft: classes.drawerPaper,
				paperAnchorRight: classes.drawerPaperRight
			}}
			open={open}
			{...restPrps}
		>
			{children}
		</Drawer>
	);
}

_Drawer.defaultProps = {
	open: false
};

_Drawer.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool
};

const __Drawer = withStyles(styles)(_Drawer);

export { __Drawer as default, __Drawer as Drawer };
