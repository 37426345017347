// Todo: CSS re-factor required. Current version is only for top right menu
export default ({ spacing, transitions, zIndex }) => ({
    profileDropdownMenu: {
        top: '54px !important',
        left: 'auto !important',
        right: '69px',
        borderRadius: 0,
        minWidth: '180px',
        '& > ul': {
            padding: 0,
            '& > li:hover': {
                backgroundColor: 'black',
                color: 'white'
            }
        }
    }
});
