import React from 'react';
import PropTypes from 'prop-types';

import InputGroupSelect from '@baseComponents/InputGroupSelect';
import { required, number, mustBeGreaterThanZero } from '@libs/validationRules';

import { currencyData } from '../../../../customData';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'yarnPrice',
    label: 'Yarn Price',
    placeholder: 'Yarn Price',
    disabled: false,
    required: true,
    validate: [required, number, mustBeGreaterThanZero],
    inputProps: { type: 'number' }
};

const YarnPrice = props => (
    <InputGroupSelect {...props} selectData={currencyData} />
);

YarnPrice.propTypes = propTypes;
YarnPrice.defaultProps = defaultProps;

export { YarnPrice as default, YarnPrice };
