import { useState, useMemo, useCallback } from 'react';

const usePagination = rows => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState(rows);
    const [initialData] = useState(rows);
    const [searchValue, setSearchValue] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowsPerPageOptions = useMemo(() => [10, 25, 50, 100], []);
    const paginatedRows = useMemo(
        () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [data, page, rowsPerPage]
    );

    const onChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const onChangeRowsPerPage = useCallback(event => {
        setPage(0);
        setRowsPerPage(Number(event.target.value));
    }, []);

    const handleSearch = useCallback(
        queryValue => {
            const filteredData = initialData.filter(({ composition }) => {
                return (
                    queryValue === '' ||
                    composition
                        .toString()
                        .toLowerCase()
                        .includes(queryValue.toString().toLowerCase())
                );
            });
            setSearchValue(queryValue);
            setData(filteredData);
        },
        [initialData]
    );

    return {
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        paginatedRows,
        count: data.length,
        rowsPerPageOptions,
        indexPrefix: rowsPerPage * page,
        handleSearch,
        searchValue
    };
};

const useFieldsArrayItemMove = fields => {
    const [draggedOverIndex, setDraggedOverIndex] = useState(null);
    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = index => {
        setDraggedIndex(index);
    };

    const handleDragOver = (e, draggedOverIndex) => {
        e.preventDefault();

        setDraggedOverIndex(draggedOverIndex);
    };

    const handleDragLeave = () => {
        setDraggedOverIndex(null);
    };

    const handleDragDrop = droppedIndex => {
        if (draggedIndex !== null && droppedIndex !== draggedIndex) {
            fields.move(draggedIndex, droppedIndex);
        }
        setDraggedOverIndex(null);
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    return {
        handleDragStart,
        handleDragOver,
        handleDragLeave,
        handleDragDrop,
        handleDragEnd,
        draggedOverIndex,
        draggedIndex
    };
};

export { usePagination, useFieldsArrayItemMove };
