import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { printMailSubjectSelector } from 'sampling/redux.selectors';
import { FORM_NAME } from 'sampling/Print/common';
import { SampleEmailGroup, SampleEmailTemplate } from '../../../components';
import {
    setSampleEmailBody,
    setSampleEmailReceivers
} from 'sampling/redux.actions';

const mapStateToProps = (state, { mode }) => ({
    retailer: formValueSelector(FORM_NAME)(state, 'retailer'),
    emailSubject: printMailSubjectSelector(state, mode)
});

const mapDispatch = dispatch => ({
    formInitialize: (...args) => dispatch(initialize(...args))
});

const mapInputs = {
    groups: ({ payload }) => setSampleEmailReceivers(payload),
    templates: ({ payload }) => setSampleEmailBody(payload)
};

const EmailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: (values, dispatch, { handleSave }) => {
            handleSave(values);
        }
    }),
    connect(
        mapStateToProps,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        handleSubmit,
        valid,
        handlePublish,
        closePreviewModal,
        retailer,
        emailSubject,
        formInitialize
    }) => {
        const handlePublishWithEmail = handlePublish({
            closePreviewModal,
            closeMailModal
        });

        const handleMailSend = useCallback(() => {
            handleSubmit();
            setTimeout(() => handlePublishWithEmail(), 1);
        }, [handleSubmit, handlePublishWithEmail]);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, { subject: emailSubject });
        }, [emailSubject]);

        const mailProps = {
            MailGroupComponent: <SampleEmailGroup retailer={retailer} />,
            MailTemplatesComponent: <SampleEmailTemplate retailer={retailer} />,
            handleModalClose: closeMailModal,
            handleSubmit: handleMailSend,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default EmailModal;
