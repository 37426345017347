import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';
import clsx from 'classnames';

const propTypes = {
    size: PropTypes.number
};

const defaultProps = {
    size: 160
};

const styles = ({ palette }) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: palette.background.main,
        color: palette.primary.main
    }
});

const FullLoader = withStyles(styles)(({ classes: c, className, size }) => (
    <div className={clsx(c.container, className)}>
        <CircularProgress size={size} color='inherit' />
    </div>
));

FullLoader.propTypes = propTypes;
FullLoader.defaultProps = defaultProps;

export { FullLoader as default, FullLoader };
