import React, { useState } from 'react';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core';

import useGridContext from '../../../../useGridContext';

const styles = ({ palette }) => ({
    gridMainHeaderCellContainer: {
        borderBottom: `1px solid ${palette.background.dark}`,
        borderRight: `1px solid ${palette.background.dark}`
    },
    gridMainHeaderCellContainerDragOver: {
        background: palette.grey[500]
    }
});

const DragAndSortWrapper = withStyles(styles)(
    ({ classes: c, column, isThisColumnFrozen, children }) => {
        const {
            sortColumn,
            handleDragStart,
            handleOnDrop,
            handleDragEnd,
            draggedColumn
        } = useGridContext();
        const [isDragOver, setIsDragOver] = useState(false);

        const handleSortColumn = () => {
            if (column.sortable) {
                sortColumn(column.name);
            }
        };

        const handleDragOver = e => {
            e.preventDefault();
            if (draggedColumn !== column.name) {
                setIsDragOver(true);
            }
        };

        const handleDragLeave = () => {
            setIsDragOver(false);
        };

        const onDropHandler = () => {
            handleOnDrop(column.name);
            setIsDragOver(false);
        };

        const draggable = column.draggable && !isThisColumnFrozen;

        const dragHandlerProps = draggable
            ? {
                  onDragStart: () => handleDragStart(column.name),
                  onDragOver: handleDragOver,
                  onDragLeave: handleDragLeave,
                  onDrop: onDropHandler,
                  onDragEnd: handleDragEnd
              }
            : {};

        const sortingProps = column.sortable
            ? {
                  onClick: handleSortColumn,
                  style: { cursor: 'pointer' }
              }
            : { style: { cursor: 'auto' } };

        const wrapperProps = { ...sortingProps, ...dragHandlerProps };

        const wrapperClasses = clsx(
            c.gridMainHeaderCellContainer,
            isDragOver && c.gridMainHeaderCellContainerDragOver
        );

        return (
            <div
                className={wrapperClasses}
                draggable={draggable}
                {...wrapperProps}
            >
                {children}
            </div>
        );
    }
);

export default DragAndSortWrapper;
