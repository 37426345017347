import { takeLatest, call, put, select } from 'redux-saga/effects';
import uuidv1 from 'uuid/v1';
import { API_BASE_URL, apiClient } from 'store/config';
import {
    filterFabricAutoid,
    checkForMainBodyFabric
} from 'helpers/fabricDetails';

import {
    selectFabrics,
    selectFabricDetails,
    selectDuplicateFabricFor,
    selectParsedFabrics
} from 'store/selectors';

// Actions
import { setFabrics } from 'store/actions';

// Action Types
import {
    DUPLICATE_FABRIC,
    COPY_FROM_REF_FABRIC_DETAILS_REQUEST
} from 'store/actionTypes';
import { selectFabricFors } from 'selectors';

//workers
function* duplicateFabricEffectSaga({ meta: { fabricIndex } }) {
    const fabricDetails = yield select(selectFabricDetails, fabricIndex);

    const [{ amends, ...fabric }] = yield select(
        selectParsedFabrics,
        fabricDetails
    );

    yield put(setFabrics({ ...fabric, fabFrom: '' }));
}

function* copyFabricEffectSaga({ payload }) {
    try {
        const fabricsArr = yield select(selectFabrics);
        const fabricFor = yield select(
            ({ samplingRetailers: { fabricFor } }) => fabricFor
        );
        const { fabricData, type } = payload;

        let fabricDetails = [];
        if (Array.isArray(fabricData)) {
            let resetObj = null;
            fabricDetails = fabricData.map(function(item) {
                resetObj = checkForMainBodyFabric(fabricsArr, item, fabricFor);
                return {
                    ...item,
                    fabricAutoId: uuidv1(), // Sets unique composite key
                    fabricFor: {
                        ...item.fabricFor,
                        ...resetObj
                    }
                };
            });
        }

        yield put(setFabrics(fabricDetails, type));
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const fabricsWatchers = function*() {
    yield takeLatest(DUPLICATE_FABRIC, duplicateFabricEffectSaga);
    yield takeLatest(
        COPY_FROM_REF_FABRIC_DETAILS_REQUEST,
        copyFabricEffectSaga
    );
};

export { fabricsWatchers as default, fabricsWatchers };
