import { select, call, put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { userIdSelector } from 'auth/redux.selectors';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

import { setGridRowLoading, fetchAccumulatedData } from '../../redux.actions';
import { gridUpdateSchema } from './schema';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';
import { DOMAIN_NAME } from 'gridView/common';

const typeDefaultValue = {
    date: 0,
    string: ''
};

export default function*(action) {
    const {
        meta: { endpoint, accuShow, moduleName, entity },
        payload: { type = 'string', selectedIds = [], filters, ...payload }
    } = action;

    const userId = yield select(userIdSelector);
    let updatePayload = composeApiPayload(
        {
            ...payload,
            userId,
            fieldValue: payload.fieldValue || typeDefaultValue[type]
        },
        {
            ...gridUpdateSchema,
            ...(payload.fieldValue
                ? {}
                : { fieldValue: typeDefaultValue[type] })
        }
    );

    if (entity === '@orderLists') {
        const { groupId, retailer } = filters;
        updatePayload = {
            ...updatePayload,
            id: selectedIds.join(',') || payload.id,
            groupId,
            retailer
        };
    }

    try {
        yield put(setGridRowLoading({ rowId: payload.id, value: true }));
        const { data: { data: updatedRows } = {} } = yield call(apiRequest, {
            method: 'put',
            url: endpoint,
            data: updatePayload
        });
        if (accuShow) {
            //Based on grid update action accumulation data might change.
            yield put(fetchAccumulatedData(moduleName));
        }

        let { data, ...restDataset } = yield select(
            entitySelector,
            DOMAIN_NAME,
            entity
        );

        let datasetAfterEdit = [];
        let message = '';

        // Only for @orderLists
        if (entity === '@orderLists') {
            let columnName = '';
            // Update dataset whose id is included in selectedIds
            datasetAfterEdit = data.map(item => {
                let updatedItem = updatedRows.find(
                    updated => updated.id.value === item.id.value
                );
                if (updatedItem) {
                    columnName = item[payload.fieldName].header;
                    return updatedItem;
                }
                return item;
            });
            const length = selectedIds.length;
            message =
                length > 1
                    ? `'${columnName}' value has been successfully updated for ${length} rows`
                    : `${columnName}' value has been successfully updated`;
        } else {
            // Update field value for rest of the gridView
            datasetAfterEdit = data.map(item => {
                if (item.id.value === payload.id) {
                    item[payload.fieldName].value = payload.fieldValue;
                    message = `'${item[payload.fieldName].header}' value has been successfully updated`;
                }
                return item;
            });
        }

        // Dispatch an action to set the updated dataset
        yield put(
            setDataset({
                entity,
                domain: DOMAIN_NAME,
                dataset: { ...restDataset, data: datasetAfterEdit }
            })
        );

        yield put(setGridRowLoading({ rowId: payload.id, value: false }));
        yield put(
            setSnack({
                message,
                type: 'success',
                duration: 8000
            })
        );
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(setGridRowLoading({ rowId: payload.id, value: false }));
        yield put(
            setSnack({
                message: `Failed to update ${serverMessage}`,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
