import React from 'react';
import { RADIAN } from './constants';

const ValueLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    startAngle,
    endAngle
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const angleDelta = endAngle - startAngle;

    const arcLength = radius * angleDelta * RADIAN;
    const arcLengthThreshold = 10;

    return arcLength >= arcLengthThreshold ? (
        <text
            x={x}
            y={y}
            fill='black'
            textAnchor='middle'
            dominantBaseline='middle'
        >
            {value}
        </text>
    ) : null;
};

export { ValueLabel as default, ValueLabel };
