import { userIdSelector } from 'auth/redux.selectors';
import { normalizeRetailers, normalizeSampleSizes } from './redux.normalizers';
import { formValueSelector } from 'redux-form';
import { FORM_NAME as PRINT_FORM_NAME } from './Print/common';
import { FORM_NAME as SHAPE_FORM_NAME } from './Shape/common';
import {
    FORM_NAME as DISPATCH_FORM_NAME,
    DISPATCH_DETAILS_FORM_NAME
} from './SampleDispatch/common';

export const PRINT_COPY_SAMPLES = '@printCopySamples';
export const DESIGN_FROMS = '@designFroms';
export const COUNTRIES = '@countries';
export const DEPARTMENTS = '@departments';
export const DESIGN_SOURCES = '@designSources';
export const REQUEST_TYPES = '@requestTypes';
export const RETAILERS = '@retailers';
export const GET_SAMPLE_SIZES = '@getSampleSizes';
export const BOUGHT_REFERENCES = '@boughtReferences';

export const SHAPE_COPY_SAMPLES = '@shapeCopySamples';
export const FIT_OPTIONS = '@fitOptions';
export const SIZE_RANGES = '@sizeRanges';
export const NECKLINES = '@necklines';
export const WAISTBANDS = '@waistbands';
export const ARMHOLES = '@armholes';
export const HEMLINES = '@hemlines';
export const LABEL_TYPES = '@labelTypes';
export const TRIM_REFERENCES = '@trimReferences';

export const FABRIC_FORS = '@fabricFors';
export const FABRIC_TYPES = '@fabricTypes';
export const COMPOSITIONS = '@compositions';
export const DYE_TYPES = '@dyeTypes';
export const FINISHED_GSMS = '@finishedGsms';
export const SPECIAL_FINISHES = '@specialFinishes';
export const SPEC_REFERENCES = '@specReferences';

export const PRINT_SAMPLE_CADS = '@printSampleCads';
export const PRINT_INCOMPLETE_SAMPLES = '@printIncompleteSamples';
export const PRINT_AMEND_SAMPLES = '@printAmendSamples';

export const SHAPE_INCOMPLETE_SAMPLES = '@shapeIncompleteSamples';
export const SHAPE_AMEND_SAMPLES = '@shapeAmendSamples';

export const SAMPLE_EMAIL_GROUP = '@sampleEmailGroup';
export const SAMPLE_EMAIL_BODY = '@sampleEmailBody';
export const DEVELOPED_FABRICS_FOR_PRINT = '@developedFabricsForPrint';
export const DEVELOPED_FABRICS_FOR_SHAPE = '@developedFabricsForShape';
export const GET_SAMPLES = '@getSamples';

export const SHAPE_GRID_DATA = '@shapeGridData';
export const SHAPE_GRID_PREVIEW_DATA = '@shapeGridPreviewData';
export const SEASONS = '@seasons';

// Sampling data grid datasets
export const SAMPLING_GRID_DATA = '@samplingGridData';
export const SAMPLE_STATUSES = '@sampleStatuses';
export const SAMPLE_FACTORY = '@sampleFactory';
export const GARMENTS_TYPE = '@garmentsType';
export const SAMPLE_ACTIONS = '@sampleActions';
export const SAMPLE_ORDER_STATUS = '@sampleOrderStatus';
export const SAMPLING_GRID_PREVIEW_DATA = '@samplingGridPreviewData';

// Dispatch form datasets
export const DISPATCH_INCOMPLETE_SAMPLES = '@dispatchIncompleteSamples';
export const DISPATCH_AMEND_SAMPLES = '@dispatchAmendSamples';
export const CURRIER = '@currier';
export const DISPATCH_TYPE = '@dispatchType';
export const DISPATCH_SIZE = '@dispatchSize';
export const DISPATCH_SAMPLE_REFERENCES = '@dispatchSampleReferences';
export const DISPATCH_ORDERS_NO = '@dispatchOrdersNo';
export const DISPATCH_SAMPLES_BY_ORDER_NO = '@dispatchSamplesByOrderNo';
export const DISPATCH_EMAIL_GROUP = '@dispatchEmailGroup';
export const DISPATCH_EMAIL_BODY = '@dispatchEmailBody';

// Dispatch data grid datasets
export const DISPATCH_GRID_DATA = '@dispatchGridData';
export const DISPATCH_GRID_PREVIEW_DATA = '@dispatchGridPreviewData';

const developedFabrics = formName => ({
    url: 'Sample/GetFabricDevelopments',
    params: state => ({
        retailerId: formValueSelector(formName)(state, 'retailer')
    }),
    fields: {
        fabricReference: 'Reference',
        fabricType: 'Type',
        fabricComposition: 'Composition',
        gsm: 'GSM'
    },
    selector: () => false
});

const dispatchSamples = (amend = false) => ({
    url: 'SampleDispatches/GetDropdownDispatches',
    params: state => ({
        userId: userIdSelector(state),
        isAmend: amend
    }),
    fields: {
        dispatchRef: 'Reference',
        retailer: 'Retailer',
        originCountry: 'Source Country',
        destinationCountry: 'Dest. Country'
    },
    selector: () => false
});

export default {
    [PRINT_COPY_SAMPLES]: {
        url: 'Sample/GetCopySamples',
        params: state => {
            const fabricOption = formValueSelector(PRINT_FORM_NAME)(
                state,
                'hasFabricOption'
            );
            return {
                userId: userIdSelector(state),
                sampleType: 'P',
                sampleStatus: !!fabricOption ? 'P' : ''
            };
        },
        fields: {
            sampleRef: 'Sample Reference',
            status: 'Status'
        },
        selector: () => false
    },
    [SHAPE_COPY_SAMPLES]: {
        url: 'Sample/GetCopySamples',
        params: state => {
            const fabricOption = formValueSelector(SHAPE_FORM_NAME)(
                state,
                'hasFabricOption'
            );
            return {
                userId: userIdSelector(state),
                sampleType: 'NP',
                sampleStatus: !!fabricOption ? 'P' : ''
            };
        },
        fields: {
            sampleRef: 'Sample Reference',
            status: 'Status'
        },
        selector: () => false
    },
    [DESIGN_FROMS]: {
        url: 'CadType'
    },
    [COUNTRIES]: {
        url: 'Country'
    },
    [DEPARTMENTS]: {
        url: 'Department'
    },
    [DESIGN_SOURCES]: {
        url: 'ArtworkSource'
    },
    [REQUEST_TYPES]: {
        url: 'RequestTypes'
    },
    [RETAILERS]: {
        url: 'Retailer/GetRetailerByUserId',
        params: state => ({
            userId: userIdSelector(state)
        }),
        normalizer: normalizeRetailers
    },

    [GET_SAMPLE_SIZES]: {
        url: 'SampleSize',
        normalizer: normalizeSampleSizes
    },
    [FIT_OPTIONS]: {
        url: 'FitType'
    },
    [SIZE_RANGES]: {
        url: 'SizeRange'
    },
    [NECKLINES]: {
        url: 'Neckline'
    },
    [WAISTBANDS]: {
        url: 'WaistBands'
    },
    [ARMHOLES]: {
        url: 'ArmholeCuff'
    },
    [HEMLINES]: {
        url: 'Hemline'
    },
    [LABEL_TYPES]: {
        url: 'LabelType'
    },

    [BOUGHT_REFERENCES]: {
        url: 'Bought',
        fields: {
            boughtRef: 'Bought Reference',
            tripReference: 'Trip Reference'
        },
        selector: () => false
    },
    [FABRIC_FORS]: {
        url: 'FabricFors'
    },
    [FABRIC_TYPES]: {
        url: 'FabricTypes'
    },
    [COMPOSITIONS]: {
        url: 'FabricCompositions'
    },
    [DYE_TYPES]: {
        url: 'FabricDyeTypes'
    },
    [FINISHED_GSMS]: {
        url: 'FabricGsms'
    },
    [SPECIAL_FINISHES]: {
        url: 'FabricFinishWashes'
    },

    [TRIM_REFERENCES]: {
        url: 'Trims/GetTrimCards',
        fields: {
            trimCardRef: 'Reference',
            description: 'Description',
            supplier: 'Supplier',
            supplierRef: 'Supplier ref',
            retailer: 'Retailer'
        },
        selector: () => false
    },
    [PRINT_INCOMPLETE_SAMPLES]: {
        url: 'Sample/GetPrintIncompleteSamples',
        params: state => ({
            userId: userIdSelector(state)
        }),
        fields: {
            sampleRef: 'Sample Ref',
            graphicRef: 'Graphic Ref',
            graphicDescription: 'Graphic Desc'
        },
        selector: () => false
    },
    [PRINT_AMEND_SAMPLES]: {
        url: 'Sample/GetPrintAmendSamples',
        params: state => ({
            userId: userIdSelector(state)
        }),
        fields: {
            sampleRef: 'Sample Ref',
            graphicRef: 'Graphic Ref',
            graphicDescription: 'Graphic Desc'
        },
        selector: () => false
    },

    [SHAPE_INCOMPLETE_SAMPLES]: {
        url: 'Sample/GetShapeIncompleteSamples',
        params: state => ({
            userId: userIdSelector(state)
        }),
        fields: {
            sampleRef: 'Sample Ref',
            specDescription: 'Spec Desc',
            styleDescription: 'Style Desc'
        },
        selector: () => false
    },
    [SHAPE_AMEND_SAMPLES]: {
        url: 'Sample/GetShapeAmendSamples',
        params: state => ({
            userId: userIdSelector(state)
        }),
        fields: {
            sampleRef: 'Sample Ref',
            specDescription: 'Spec Desc',
            styleDescription: 'Style Desc'
        },
        selector: () => false
    },
    [SPEC_REFERENCES]: {
        url: 'Sample/GetSpecRef',
        params: state => ({
            userId: userIdSelector(state),
            departmentId: formValueSelector(PRINT_FORM_NAME)(
                state,
                'department'
            )
        }),
        fields: {
            sampleRef: 'Sample Ref',
            specDescription: 'Spec Desc',
            styleDescription: 'Style Desc',
            shapeReportUrl: 'Shape Report URL'
        },
        selector: () => false
    },
    [PRINT_SAMPLE_CADS]: {
        url: 'Cad/GetCadsForSample',
        params: state => ({
            shape: false,
            userId: userIdSelector(state)
        }),
        fields: {
            cadRef: 'Reference',
            designerName: 'Designer',
            description: 'Description'
        },
        selector: () => false
    },
    [SAMPLE_EMAIL_GROUP]: {
        url: 'SampleEmailGroup',
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    },
    [SAMPLE_EMAIL_BODY]: {
        url: 'SampleEmailBody',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [DEVELOPED_FABRICS_FOR_PRINT]: developedFabrics(PRINT_FORM_NAME),
    [DEVELOPED_FABRICS_FOR_SHAPE]: developedFabrics(SHAPE_FORM_NAME),
    [GET_SAMPLES]: {
        url: 'Sample/GetAmendSamples',
        params: state => ({
            userId: userIdSelector(state)
        }),
        fields: {
            sampleRef: 'Sample Ref',
            graphicRef: 'Graphic Ref',
            graphicDescription: 'Graphic Desc'
        },
        selector: () => false
    },

    [SEASONS]: {
        url: 'Collection'
    },

    /* Sampling Grid */

    [SAMPLE_STATUSES]: {
        url: 'SampleStatuses'
    },
    [SAMPLE_ACTIONS]: {
        url: 'SampleActions'
    },
    [SAMPLE_FACTORY]: {
        url: 'SampleFactory'
    },

    [GARMENTS_TYPE]: {
        url: 'GarmentsType'
    },

    [SAMPLE_ORDER_STATUS]: {
        url: 'SampleOrderStatus'
    },

    /* Dispatch form */
    [DISPATCH_INCOMPLETE_SAMPLES]: dispatchSamples(),
    [DISPATCH_AMEND_SAMPLES]: dispatchSamples(true),
    [CURRIER]: {
        url: 'Currier'
    },
    [DISPATCH_TYPE]: {
        url: 'DispatchType'
    },
    [DISPATCH_SIZE]: {
        url: 'DispatchSize'
    },
    [DISPATCH_SAMPLE_REFERENCES]: {
        url: 'SampleDispatches/GetSampleDropdownByType',
        params: state => ({
            userId: userIdSelector(state),
            retailerId: formValueSelector(DISPATCH_FORM_NAME)(state, 'retailer')
        }),
        fields: {
            sampleRef: 'Sample Ref',
            devNo: 'Dev No',
            description: 'Description'
        },
        selector: () => false
    },
    [DISPATCH_ORDERS_NO]: {
        url: 'SampleDispatches/GetOrderDropdownByType',
        params: state => ({
            userId: userIdSelector(state),
            retailerId: formValueSelector(DISPATCH_FORM_NAME)(state, 'retailer')
        }),
        fields: {
            orderNo: 'Order No',
            retailer: 'Retailer'
        },
        selector: () => false
    },
    [DISPATCH_SAMPLES_BY_ORDER_NO]: {
        url: 'SampleDispatches/GetSampleArrayListByOrder',
        params: state => ({
            orderNo: formValueSelector(DISPATCH_DETAILS_FORM_NAME)(
                state,
                'orderNo'
            )
        }),
        selector: () => false
    },
    [DISPATCH_EMAIL_GROUP]: {
        url: 'DispatchEmailGroup',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [DISPATCH_EMAIL_BODY]: {
        url: 'DispatchEmailBody',
        params: state => ({
            userId: userIdSelector(state)
        })
    }
};
