import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'components';

import styles from './styles';

const _Breadcrumb = memo(function _Breadcrumb(props) {
    const { classes, path } = props;
    return (
        <div className={classes.breadcumbBar}>
            <Breadcrumb path={path} />
        </div>
    );
});

_Breadcrumb.defaultProps = {
    classes: PropTypes.object.isRequired
};

_Breadcrumb.propTypes = {
    classes: PropTypes.object.isRequired
};

const __Breadcrumb = withStyles(styles)(_Breadcrumb);

export { __Breadcrumb as default, __Breadcrumb as Breadcrumb };
