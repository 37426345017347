import React from 'react';
import { reduxForm } from 'redux-form';

import GridView from 'gridView';
import Filters from './components/Filters';
import { config } from './config';

const SampleOrderedView = reduxForm({
    form: 'sampleOrderedForm'
})(() => {
    return (
        <GridView
            entity={{
                name: '@sampleOrderedList',
                endpoint: 'Sample/GetSampleReportWithOrderData'
            }}
            FiltersComponent={Filters}
            config={config}
        />
    );
});

export { SampleOrderedView, SampleOrderedView as default };
