import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles, Typography } from '@material-ui/core';
import {
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Close
} from '@material-ui/icons';

const styles = ({ spacing }) => ({
    overlay: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.90)',
        zIndex: 9999
    },
    slider: {
        position: 'relative',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    slide: {
        opacity: 0,
        transitionDuration: '1s ease'
    },
    active: {
        opacity: 1,
        transitionDuration: '1s',
        transform: 'scale(1.08)'
    },
    image: {
        width: '800px',
        height: '500px',
        borderRadius: '10px',
        border: '2px solid #fff',
        objectFit: 'contain',
        backgroundColor: '#fff'
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        zIndex: 10,
        cursor: 'pointer',
        userSelect: 'none',
        backgroundColor: '#ffffff66',
        height: spacing.unit * 5,
        width: spacing.unit * 5,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#ffffff55'
        }
    },
    right: {
        right: spacing.unit * 4
    },
    left: {
        left: spacing.unit * 4
    },
    icon: {
        color: '#fff',
        transform: 'scale(1.5)'
    },
    imageIndex: {
        position: 'absolute',
        color: 'rgb(226, 226, 226)',
        top: spacing.unit * 1.25,
        right: spacing.unit * 9,
        fontSize: '18px',
        userSelect: 'none'
    },
    close: {
        position: 'absolute',
        top: spacing.unit * 1.25,
        right: spacing.unit * 4,
        color: '#fff',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ffffff55'
        }
    }
});

const Slider = withStyles(styles)(({ sliderData, onClose, classes: c }) => {
    const [current, setCurrent] = useState(0);
    const length = sliderData.length;
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };
    return (
        <div className={c.overlay}>
            <div className={c.slider}>
                <Close onClick={onClose} className={c.close} />
                <Typography className={c.imageIndex}>
                    {current + 1} / {length}
                </Typography>
                {length > 1 && (
                    <>
                        <div
                            className={classNames(c.arrow, c.left)}
                            onClick={prevSlide}
                        >
                            <KeyboardArrowLeft className={c.icon} />
                        </div>
                        <div
                            className={classNames(c.arrow, c.right)}
                            onClick={nextSlide}
                        >
                            <KeyboardArrowRight className={c.icon} />
                        </div>
                    </>
                )}
                {sliderData.map(({ size800, imageVersion }, idx) => {
                    return (
                        <div
                            className={
                                idx === current
                                    ? classNames(c.slide, c.active)
                                    : c.slide
                            }
                            key={idx}
                        >
                            {idx === current && (
                                <img
                                    src={size800}
                                    alt={imageVersion}
                                    className={c.image}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default Slider;
