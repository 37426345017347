import { fork } from 'redux-saga/effects';

// Local

import fabricDevelopmentSagas from './fabricDevelopmentSagas';

// Workers

// Watchers
const fabricSagas = function*() {};

export default function*() {
    yield fork(fabricSagas);
    yield fork(fabricDevelopmentSagas);
}
