import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import {
    Select as MuiSelect,
    TextField,
    FormControl,
    ClickAwayListener,
    Popover,
} from '@material-ui/core';

// Local
import { LabelBase } from 'components';
import { composeClasses } from 'helpers';
import SelectCancellableIcon from './SelectCancellableIcon';
import SelectDropdownIcon from './SelectDropdownIcon';
import SelectPlaceholder from './SelectPlaceholder';
import PaginatedSelectDialog from './PaginatedSelectDialog';

/*
 * Domain: --
 * Page: Select
 * Component: Base
 * Type: --
 * SelectBase
 */
class Select extends Component {
    // Set prop types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        isCancellable: PropTypes.bool,
        displayFirst: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    // Set default props
    static defaultProps = {
        styles: {},
        displayFirst: false,
        isCancellable: false,
        placeholder: null,
    };

    state = {
        data: this.props.data,
        selectStyle: null,
        placeholderStyle: null,
        showPlaceholder: !!this.props.placeholder,
        dialogAnchor: null,
    };

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props) {
        const {
            classes: { select, selectCancellable },
            isCancellable,
            styles,
            meta: { dirty },
            placeholder,
        } = props;

        const nextState = {
            selectStyle: classNames(select, styles.select),
            showPlaceholder: Boolean(placeholder),
        };

        if (isCancellable && dirty) {
            nextState.selectStyle = classNames(
                nextState.selectStyle,
                selectCancellable
            );
        }
        return nextState;
    }

    renderDropdownIcon = () => {
        const { props, onFocus } = this;
        const { styles = {}, disabled } = props;
        const propStyles = Object.splice(styles, [
            'dropdownRoot',
            'dropdownIcon',
        ]);
        return (
            <SelectDropdownIcon
                styles={propStyles}
                onClick={onFocus}
                disabled={disabled}
            />
        );
    };

    showDialog = (event) => {
        this.setState({ dialogAnchor: event.currentTarget });
    };

    hideDialog = () => {
        this.setState({ dialogAnchor: null });
    };

    render() {
        const {
            state,
            props,
            renderDropdownIcon,
            showDialog,
            hideDialog,
        } = this;

        const { selectStyle, showPlaceholder } = state;

        const {
            classes,
            styles,
            input,
            meta: { form },
            isCancellable,
            placeholder: placeholderValue,
            className,
            label,
            required,
            disabled,
            ...restProps
        } = props;

        const c = composeClasses({
            classes: { ...classes, select: selectStyle },
            styles,
        });

        const { name, value, onChange } = input;

        const selectProps = {
            classes: { ...Object.splice(c, ['root', 'select', 'icon']) },
            IconComponent: renderDropdownIcon,
            disableUnderline: true,
            onClick: showDialog,
            value: 0,
            disabled,
        };
        const id = `${form}-${name}`;
        const success = !disabled && !!value;

        return (
            <FormControl className={c.container}>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={disabled}
                        required={required}
                        success={success}
                        htmlFor={id}
                    />
                )}

                <FormControl
                    className={classNames(
                        c.control,
                        className,
                        success && c.success,
                        disabled && c.disabled
                    )}
                >
                    {/*Todo: Convert to InputBase element */}

                    <TextField
                        classes={{ root: c.inputRoot }}
                        inputProps={{ className: c.input, readOnly: true }}
                        value={value}
                        htmlFor={id}
                        onClick={showDialog}
                        disabled={disabled}
                    />

                    {showPlaceholder && !success && !disabled && (
                        <SelectPlaceholder
                            styles={Object.splice(c, ['placeholder'])}
                            htmlFor={id}
                            placeholder={placeholderValue}
                        />
                    )}
                    {isCancellable && success && (
                        <SelectCancellableIcon
                            styles={Object.splice(styles, [
                                'cancellableRoot',
                                'cancellableIcon',
                            ])}
                            onClick={() => onChange('')}
                        />
                    )}
                    <MuiSelect {...selectProps} />

                    <PaginatedSelectDialog
                        hideDialog={hideDialog}
                        anchor={this.state.dialogAnchor}
                        handleChange={onChange}
                        {...restProps}
                    />
                </FormControl>
            </FormControl>
        );
    }
}

export { Select as default, Select };
