export const welcomeEmailTemplate = ({
    name,
    email,
    passwordSetUrl,
    loginUrl
}) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Welcome to Echosys</title>
    <style type="text/css">
      body {
        margin: 0;
        padding: 0;
        min-width: 100% !important;
      }
      img {
        height: auto;
        background: #ffffff;
        padding: 8px;
      }
      .content {
        width: 100%;
        max-width: 600px;
      }
      .header {
        padding: 40px 30px 20px 30px;
      }
      .innerpadding {
        padding: 30px 30px 30px 30px;
      }
      .borderbottom {
        border-bottom: 1px solid #f2eeed;
      }
      .subhead {
        font-size: 15px;
        color: #ffffff;
        font-family: sans-serif;
        letter-spacing: 10px;
      }
      p {
        color: #153643;
        font-family: sans-serif;
        font-size: 14px;
        line-height: 22px;
      }
      .h1 {
        font-size: 33px;
        line-height: 38px;
        font-weight: bold;
      }
      .h2 {
        padding: 0 0 15px 0;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }

      @media only screen and (min-device-width: 601px) {
        .content {
          width: 600px !important;
        }
        .col425 {
          width: 425px !important;
        }
        .col380 {
          width: 380px !important;
        }
      }
    </style>
  </head>

  <body yahoo bgcolor="#f6f8f1">
    <table
      width="100%"
      bgcolor="#f6f8f1"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tr>
        <td>
          <!--[if (gte mso 9)|(IE)]>
            <table width="600" align="center" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td>
            <![endif]-->
          <table
            bgcolor="#ffffff"
            class="content"
            align="center"
            cellpadding="0"
            cellspacing="0"
            border="0"
          >
            <tr>
              <td bgcolor="#f26831" class="header">
                <table
                  width="100%"
                  align="left"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tr>
                    <td
                      height="30"
                      style="padding: 0 20px 20px 0"
                      align="center"
                    >
                      
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td class="innerpadding borderbottom">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <p>Hi ${name},</p>
                  <p>We're excited to have you join Echosys using your email address: ${email}</p>
                  <p>Your account has been successfully created. To set your password and access your account, please click on the following link:</p>
                  <p>
                  Set Password:
                    <a
                      href=${passwordSetUrl}
                      target="__blank"
                      >Link</a
                    >
                  </p>
                  <p>Please make sure to set a strong and unique password to ensure the security of your account. Please note that this link will expire after 7 days. If you ever forget your password, you can reset it by clicking the "Forgot Password" link on our 
                  <a href=${loginUrl} target="__blank">login</a> page</p>
                  <p>-Echosys Team</p>
                </table>
              </td>
            </tr>
          </table>
          <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
      </tr>
    </table>
  </body>
</html>


`;
