import React, { useMemo } from 'react';
import ImageRow from './ImageRow';
import InputRow from './InputRow';

const useBoughtDetails = (details, imageResolver, dispatch, index) =>
    useMemo(
        () =>
            Object.entries(details)
                .map(([label, value]) => {
                    switch (label) {
                        case 'image': {
                            const image = imageResolver(value);
                            return {
                                type: 'custom',
                                value: <ImageRow image={image} />,
                                order: 0
                            };
                        }
                        case 'trimCardReference':
                            return {
                                label: 'Trim Card Ref',
                                value,
                                order: 1
                            };
                        case 'trimType':
                            return value
                                ? { label: 'Trim Type', value, order: 2 }
                                : null;
                        case 'description':
                            return value
                                ? { label: 'Description', value, order: 3 }
                                : null;
                        case 'size':
                            return value
                                ? { label: 'Size', value, order: 4 }
                                : null;
                        case 'composition':
                            return value
                                ? { label: 'Composition', value, order: 5 }
                                : null;
                        case 'application':
                            return value
                                ? { label: 'Application', value, order: 6 }
                                : null;
                        case 'trimSupplier':
                            return value
                                ? { label: 'Trim Supplier', value, order: 7 }
                                : null;
                        case 'supplierRef':
                            return value
                                ? { label: 'Supplier Ref', value, order: 8 }
                                : null;
                        case 'position':
                            return value
                                ? { label: 'Position', value, order: 9 }
                                : null;

                        case 'colour': {
                            const onChange = value => {
                                dispatch({
                                    type: 'updateTrimDetail',
                                    payload: { field: 'colour', value, index }
                                });
                            };

                            return {
                                type: 'custom',
                                value: (
                                    <InputRow
                                        label='Colour'
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Enter colour'
                                    />
                                ),
                                order: 10
                            };
                        }
                        case 'quantity': {
                            const onChange = value => {
                                dispatch({
                                    type: 'updateTrimDetail',
                                    payload: {
                                        field: 'quantity',
                                        value: Number(value),
                                        index
                                    }
                                });
                            };

                            return {
                                type: 'custom',
                                value: (
                                    <InputRow
                                        label='Quantity'
                                        value={value}
                                        onChange={onChange}
                                        validator={value => !isNaN(value)}
                                        placeholder='Enter quantity'
                                    />
                                ),
                                order: 11
                            };
                        }
                        default:
                            return null;
                    }
                })
                .filter(details => !!details)
                .sort((a, b) => a.order - b.order)
                .map(({ order, ...detail }) => detail),
        [details, imageResolver, dispatch, index]
    );

export { useBoughtDetails };
