import React from 'react';
import { array } from 'prop-types';
// Material
import { withStyles } from '@material-ui/core';
// Local
import Header from './Header';
import Row from './Row';

const propTypes = {
    dips: array.isRequired
};

const styles = ({ palette }) => ({
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        height: 'auto',
        borderRight: 'none',
        borderBottom: 'none'
    }
});

const Dips = withStyles(styles)(({ classes: c, dips }) => {
    return (
        <div className={c.table}>
            <Header data={dips[0]} />
            {dips.map((row, idx) => (
                <Row key={`dip-body-row-${idx}`} {...row} />
            ))}
        </div>
    );
});

Dips.propTypes = propTypes;

export default Dips;
