import React from 'react';
import { string, bool } from 'prop-types';

import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ spacing, palette, shape }) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: spacing.unit * 0.25,
        width: '30%'
    },
    box: {
        width: spacing.unit * 1.75,
        height: spacing.unit * 1.75,
        backgroundColor: '#fff',
        border: 1,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    checked: {
        width: spacing.unit,
        height: spacing.unit,
        backgroundColor: palette.secondary.light,
        borderRadius: 2,
        opacity: 0.8
    },
    label: {
        fontSize: spacing.unit * 1.25,
        marginLeft: spacing.unit * 0.25
    }
});

const propTypes = {
    label: string.isRequired,
    checked: bool
};

const defaultProps = {
    checked: false
};

const Checkbox = withPDFStyles(styles)(
    ({ classes: c, checked, label, style }) => {
        return (
            <View style={{ ...c.container, ...style }}>
                <View style={c.box}>
                    {checked && <View style={c.checked} />}
                </View>
                <Text style={c.label}>{label}</Text>
            </View>
        );
    }
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export { Checkbox as default, Checkbox };
