import { useCallback } from 'react';

import makeContext from '@libs/makeContext';
import isBlobUrl from '@libs/isBlobUrl';

const initialState = {
    mail: {}
};

const reducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'saveData': {
            const { mail } = state;
            const { payload } = action;
            reduxFormOnChange({ ...mail, ...payload });

            return action;
        }

        case 'addPDF': {
            const { mail } = state;
            const { payload } = action;
            reduxFormOnChange({ ...mail, ...payload });

            return action;
        }

        case 'removePDF': {
            const {
                mail: { pdf: { url } = {} }
            } = state;
            if (isBlobUrl(url)) {
                URL.revokeObjectURL(url);
                reduxFormOnChange({});
            }

            return action;
        }

        default:
            return action;
    }
};

const useStoreEmailFormData = ({ handleModal, setAction, formName }) => {
    const handleSave = useCallback(values => {
        setAction({ type: 'saveData', payload: values });
    }, []);

    return useCallback(() => {
        handleModal({ handleSave, formName });
    }, [handleModal, handleSave, formName]);
};

const [
    MailFormProvider,
    useMailFormState,
    useMailFormDispatch,
    useMailForm
] = makeContext(reducer, initialState, {
    name: 'mail',
    middleware
});

export {
    useMailForm as default,
    MailFormProvider,
    useMailFormState,
    useMailFormDispatch,
    useStoreEmailFormData
};
