import {
    DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN,
    PRIMARK_ID,
    NEWLOOK_ID,
    ASOS_ID
} from '../common';

export { DOMAIN_NAME } from '../common';

const FORM_NAME = 'order';
const PO_FORM_NAME = 'purchaseOrders';

const MAX_PACKS = 15;

const LABELS = {
    setup: 'New Order Sheet',
    details: 'Order Details',
    other: 'Other Information',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    type: 'P',
    noOfPacks: 1,
    sampleRefs: [null],
    versionNo: 1
};
//
const RETAILER_WISE_INTITIAL_VALUE = (origin, retailerId = -1) => {
    switch (retailerId) {
        case PRIMARK_ID: {
            return {
                shipMode: 1064
            };
        }
        case NEWLOOK_ID: {
            return {
                yarnPrice: {
                    selectedValue: DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN[origin],
                    inputValue: ''
                },
                retailPrice: {
                    selectedValue: '£',
                    inputValue: ''
                }
            };
        }
        default: {
            return {};
        }
    }
};

const PO_INITIAL_VALUES = (origin, retailerId) => ({
    ...(Number(retailerId) !== ASOS_ID
        ? {
              retailPrice: {
                  selectedValue: DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN[origin],
                  inputValue: ''
              }
          }
        : {}),
    ...RETAILER_WISE_INTITIAL_VALUE(origin, retailerId)
});

const DIP_INITIAL_VALUES = (retailerId, origin) => ({
    fobPrice: {
        selectedValue: DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN[origin],
        inputValue: ''
    },
    echoPrice: {
        selectedValue: DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN[origin],
        inputValue: ''
    },
    ...(Number(retailerId) === NEWLOOK_ID
        ? {}
        : {
              licenseFee: {
                  selectedValue: DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN[origin],
                  inputValue: ''
              }
          })
});

const NEW_ORDER_ROUTE_ID = 116;
const INCOMPLETE_ORDER_ROUTE_ID = 605;
const AMEND_ORDER_ROUTE_ID = 606;

const ORDER_FORM_FIELDS = {
    0: [
        { name: 'type', type: 'select' },
        { name: 'retailer', type: 'select' },
        { name: 'noOfPacks', type: 'input' },
        { name: 'sampleRefs', type: 'array' }
    ],
    1: [
        { name: 'retailer', type: 'select' },
        { name: 'department', type: 'select' },
        { name: 'echoOrderNo' },
        { name: 'versionNo' },
        { name: 'orderAmendedDate', type: 'date' },
        { name: 'orderPlacedDate', type: 'date' },
        { name: 'sampleSendDate', type: 'date' },
        { name: 'styleNo', type: 'input' },
        { name: 'productDescription', type: 'input' },
        { name: 'clientPONo', type: 'input' },
        { name: 'factory', type: 'select' },
        { name: 'factoryRef' },
        { name: 'supplierNo', type: 'input' },
        { name: 'season', type: 'select' },
        { name: 'productionSampleType', type: 'select' },
        { name: 'sendToEcho', type: 'select' },
        { name: 'sendToBuyer', type: 'select' },
        { name: 'systemFactoryNo', type: 'input' },
        { name: 'kimballNo', type: 'input' },
        { name: 'kimballColor', type: 'input' },
        { name: 'commodity', type: 'input' },
        { name: 'nlKind', type: 'input' },
        { name: 'products' },
        { name: 'purchaseOrders' }
    ],
    2: [
        { name: 'attachments', type: 'files' },
        { name: 'packingDepartmentNo', type: 'input' },
        { name: 'asosDepartment', type: 'input' },
        { name: 'packingSection', type: 'input' },
        { name: 'licensingCompany', type: 'input' },
        { name: 'packType', type: 'select' },
        { name: 'boxType', type: 'select' },
        { name: 'costDated', type: 'date' },
        { name: 'costBasedOn', type: 'select' },
        { name: 'htsCode', type: 'input' },
        { name: 'BDCommission', type: 'input' },
        { name: 'otherCommission', type: 'input' }
    ]
};

const PO_LAB_DIP_FIELDS = [
    { name: 'batchConfirmation', type: 'select' },
    { name: 'color', type: 'select' },
    { name: 'colorCode', type: 'input' },
    { name: 'colorRecipe', type: 'input' },
    { name: 'fobPrice', type: 'input' },
    { name: 'licenseFee', type: 'input' },
    { name: 'echoPrice', type: 'input' },
    { name: 'sustainability', type: 'select' },
    { name: 'composition', type: 'select' },
    { name: 'fabricType', type: 'select' },
    { name: 'gsm', type: 'select' }
];
const PO_FORM_FIELDS = [
    { name: 'dips', type: 'array', fields: PO_LAB_DIP_FIELDS },
    { name: 'cidNO', type: 'input' },
    { name: 'poNo', type: 'input' },
    { name: 'poPackId', type: 'input' },
    { name: 'shipMode', type: 'select' },
    { name: 'shipDestination', type: 'select' },
    { name: 'paymentTerms', type: 'select' },
    { name: 'settlementDiscount', type: 'select' },
    { name: 'freeOnBoardDate', type: 'date' },
    { name: 'estimateFactoryDate', type: 'date' },
    { name: 'orderType', type: 'select' },
    { name: 'discount', type: 'input' },
    { name: 'orderStyle', type: 'input' },
    { name: 'orderQuantity', type: 'input' },
    { name: 'fabricConsumption', type: 'input' },
    { name: 'yarnCount', type: 'input' },
    { name: 'yarnPrice', type: 'input' },
    { name: 'totalFabric', type: 'input' },
    { name: 'totalYarnPrice', type: 'input' },
    { name: 'retailPrice', type: 'input' },
    { name: 'packType', type: 'select' },
    { name: 'totalRatio', type: 'select' },
    { name: 'sizeSet', type: 'select' },
    { name: 'sizeDetails', type: 'manual' }
];

export {
    FORM_NAME,
    PO_FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_ORDER_ROUTE_ID,
    INCOMPLETE_ORDER_ROUTE_ID,
    AMEND_ORDER_ROUTE_ID,
    PO_INITIAL_VALUES,
    DIP_INITIAL_VALUES,
    MAX_PACKS,
    ORDER_FORM_FIELDS,
    PO_FORM_FIELDS
};
