import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

import Image from '../Image';
import ColumnContainer from '../ColumnContainer';
import Row from '../Row';
import Divider from '../Divider';

const propTypes = {
    item: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired
};

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 4,
        flexDirection: 'row'
    },
    imageContainer: {
        width: '25%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: spacing.unit * 20,
        marginRight: spacing.unit * 2.5
    },
    contentContainer: {
        width: '75%',
        flexDirection: 'row'
    }
});

const Item = withPDFStyles(styles)(
    ({
        classes: c,
        item: {
            frontImage,
            backImage,
            sampleRef,
            department,
            devNo,
            factory,
            designer,
            designSource,
            sampleAction,
            sampleStatus,
            sampleOrderStatus,
            garmentDescription,
            styleDescription,
            designerRef,
            specRef,
            fabrication,
            fabricColour,
            specialFabricRef,
            location,
            season,
            dateRequested,
            targetSentDate,
            sampleEta,
            actualSendDate,
            fabBookDate,
            fabRcvDate,
            currier,
            trackingRef
        },
        isLast
    }) => {
        return (
            <View>
                <View style={c.container}>
                    <View style={c.imageContainer}>
                        <Image
                            url={frontImage}
                            label='Front'
                            styles={{ width: '48%' }}
                        />
                        <Image
                            url={backImage}
                            label='Back'
                            styles={{ width: '48%' }}
                        />
                    </View>
                    <View style={c.contentContainer}>
                        <ColumnContainer styles={{ width: '27%' }}>
                            <Row header='Sample Ref' value={sampleRef} />
                            <Row header='Department' value={department} />
                            <Row header='Revision No' value={devNo} />
                            <Row header='Factory' value={factory} />
                            <Row header='Designer' value={designer} />
                            <Row header='Dsg From' value={designSource} />
                            <Row header='Sample Action' value={sampleAction} />
                            <Row header='Sample Status' value={sampleStatus} />
                            <Row header='Status' value={sampleOrderStatus} />
                        </ColumnContainer>
                        <ColumnContainer styles={{ width: '44%' }}>
                            <Row
                                header='Spec Desc'
                                value={garmentDescription}
                            />
                            <Row header='Style Desc' value={styleDescription} />
                            <Row header='Design Ref' value={designerRef} />
                            <Row header='Spec' value={specRef} />
                            <Row header='Fabrication' value={fabrication} />
                            <Row header='Fabric Colour' value={fabricColour} />
                            <Row header='Fabric Ref' value={specialFabricRef} />
                            <Row header='Location' value={location} />
                            <Row header='Season' value={season} />
                        </ColumnContainer>
                        <ColumnContainer styles={{ width: '29%' }}>
                            <Row
                                header='1st Req Dt'
                                value={formatDateToShort(dateRequested)}
                            />
                            <Row
                                header='Target Snt Dt'
                                value={formatDateToShort(targetSentDate)}
                            />
                            <Row
                                header='Sam ETA'
                                value={formatDateToShort(sampleEta)}
                            />
                            <Row
                                header='Act Snt Dt'
                                value={formatDateToShort(actualSendDate)}
                            />
                            <Row
                                header='Fabric Bking Dt'
                                value={formatDateToShort(fabBookDate)}
                            />
                            <Row
                                header='Fabric Rcv Dt'
                                value={formatDateToShort(fabRcvDate)}
                            />
                            <Row header='Courier' value={currier} />
                            <Row header='Trac. Ref' value={trackingRef} />
                        </ColumnContainer>
                    </View>
                </View>
                {!isLast && <Divider />}
            </View>
        );
    }
);

Item.propTypes = propTypes;

export default Item;
