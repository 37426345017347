import React, { useEffect } from 'react';
import { useTable, useExpanded, useRowSelect, useSortBy } from 'react-table';
import {
    Table as MaUTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles
} from '@material-ui/core';

import useHasMounted from '@libHooks/useHasMounted';

import RowLoader from './RowLoader';
import ExpandedView from './ExpandedView';
import SortedIcon from './SortedIcon';

const styles = ({ spacing, palette }) => ({
    headerCell: {
        color: palette.secondary.main,
        padding: `0px ${spacing.unit * 1}px 0px ${spacing.unit * 0.5}px`,
        lineHeight: 1.5,
        fontWeight: 600,
        maxWidth: spacing.unit * 10,
        '&:first-child': {
            paddingLeft: spacing.unit * 2.5
        }
    },
    bodyCell: {
        fontSize: spacing.unit * 1.5,
        padding: `${spacing.unit * 0.5}px ${spacing.unit *
            1}px 0px ${spacing.unit * 0.5}px`,
        lineHeight: 1.5,
        wordBreak: 'break-word',
        color: palette.grey[700],
        maxWidth: spacing.unit * 10,
        height: spacing.unit * 8.5, // it works like min-height. table cell will automatically expand when content doesn't fit.
        '&:first-child': {
            paddingLeft: spacing.unit * 2.5
        }
    }
});

const DataTable = withStyles(styles)(
    ({ data, columns, selectedRowIds, dispatch, expandedRow, classes: c }) => {
        const hasMounted = useHasMounted();
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            selectedFlatRows,
            visibleColumns,
            state: { selectedRowIds: selectedIndex, expanded }
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    selectedRowIds,
                    expanded: expandedRow
                }
            },
            useSortBy,
            useExpanded,
            useRowSelect
        );
        useEffect(() => {
            if (hasMounted) {
                const payload = selectedFlatRows.reduce(
                    (acc, { id, original }) => {
                        return {
                            originalIds: [...acc.originalIds, original.id],
                            rowIds: { ...acc.rowIds, [id]: true }
                        };
                    },
                    { originalIds: [], rowIds: {} }
                );
                dispatch({
                    type: 'set-selectedRows',
                    payload
                });
            }
        }, [selectedIndex, hasMounted]);

        useEffect(() => {
            if (hasMounted) {
                dispatch({
                    type: 'update-expanded',
                    payload: expanded
                });
            }
        }, [expanded, hasMounted]);

        return (
            <MaUTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps({
                                            title: undefined
                                        })
                                    )}
                                    padding='checkbox'
                                    className={c.headerCell}
                                    style={{
                                        cursor: column.canSort
                                            ? 'pointer'
                                            : 'auto'
                                    }}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted ? (
                                            <SortedIcon
                                                isSortedDesc={
                                                    column.isSortedDesc
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, idx) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={`datatable-row-${row.id}`}>
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                padding='checkbox'
                                                className={c.bodyCell}
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <RowLoader
                                    rowId={row.original.id}
                                    visibleColumns={visibleColumns}
                                />

                                {row.isExpanded ? (
                                    <TableRow>
                                        <TableCell
                                            colSpan={visibleColumns.length}
                                            style={{ padding: '0px' }}
                                        >
                                            <ExpandedView
                                                rowId={row.original.id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </MaUTable>
        );
    }
);
export { DataTable as default, DataTable };
