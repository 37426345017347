import {
    FETCH_CAD_GRAPHIC_REF_INCOMPLETE,
    FETCH_CAD_GRAPHIC_REF_AMEND,
    FETCH_CAD_REF_INFO,
    POST_NEW_CAD_REQUEST,
    POST_CAD_COLLECTION_REQUEST,
    UPDATE_NEW_CAD_REQUEST,
    POST_CAD_COLOR_REQUEST,
    CHECK_COLOR_DUPLICATE
} from 'store/actionTypes';

export function fetchIncompleteGraphicRefForCad(payload) {
    return { type: FETCH_CAD_GRAPHIC_REF_INCOMPLETE, payload };
}

export function fetchAmendGraphicRefForCad(payload) {
    return { type: FETCH_CAD_GRAPHIC_REF_AMEND, payload };
}

export function fetchCadRefInfo(payload) {
    return { type: FETCH_CAD_REF_INFO, payload };
}

// new cad modal
export function newCadSubmit(payload) {
    return { type: POST_NEW_CAD_REQUEST, payload };
}

export function postNewCadColorData() {
    return { type: POST_CAD_COLOR_REQUEST };
}

export function updateNewCad(payload) {
    return { type: UPDATE_NEW_CAD_REQUEST, payload };
}

export function checkColorDuplicate() {
    return { type: CHECK_COLOR_DUPLICATE };
}

export function createNewCollection(payload) {
    return { type: POST_CAD_COLLECTION_REQUEST, payload };
}
