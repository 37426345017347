import getDataUrl from '@libs/getDataUrl';

const loadProps = async ({ cadInfo, others, ...restProps }) => {
    const { attachments, ...rest } = others;
    const versionURL = await getDataUrl(cadInfo.version);
    /*  const attachmentUrls = await Promise.all(
        attachments.map(async ({ url, ...restInfo }) => {
            const dataURL = await getDataUrl(url);
            return { url: !!dataURL ? dataURL : undefined, ...restInfo };
        })
    ); */
    return {
        cadInfo: { ...cadInfo, version: !!versionURL ? versionURL : undefined },
        others: { attachments: [], ...rest },
        ...restProps
    };
};

export { loadProps };
