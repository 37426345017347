import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import {
    Button,
    Grow,
    IconButton,
    Snackbar as MaterialSnackBar,
    SnackbarContent,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

import { snackSelector } from '../redux.selectors';
import { resetSnack } from '../redux.actions';
import { useSnackbarStyle } from './hooks';
import { fade } from '@material-ui/core/styles/colorManipulator';

const propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    duration: PropTypes.number,
    action: PropTypes.object
};

const styles = ({ palette, spacing }) => ({
    content: {
        backgroundColor: 'var(--backgroundColor)',
        color: fade(palette.secondary.main, 0.7)
    },
    button: {
        color: palette.error.main,
        textTransform: 'none'
    },
    close: {
        padding: spacing.unit * 0.5,
        marginLeft: spacing.unit * 0.5
    }
});

const mapState = state => snackSelector(state);

const mapDispatch = dispatch => ({
    resetSnack: () => dispatch(resetSnack()),
    dispatch
});

const Snackbar = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: c,
        theme,
        resetSnack,
        dispatch,
        message,
        type,
        duration,
        action
    }) => {
        const { label = '', handle = () => {} } = action || {};

        const style = useSnackbarStyle({ type, theme });

        const handleClose = useCallback(resetSnack, [resetSnack]);

        const actionHandle = useCallback(() => {
            if (handle !== null) {
                if (typeof handle === 'object') dispatch(handle);
                else handle();
                handleClose();
            }
        }, [handle, dispatch]);

        useEffect(() => {
            let snackTimeout = null;
            if (!!message) {
                snackTimeout = setTimeout(handleClose, duration);
            }
            return () => clearTimeout(snackTimeout);
        }, [message, handleClose]);

        return (
            !!message && (
                <MaterialSnackBar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={!!message}
                    key={message}
                    TransitionComponent={props => <Grow {...props} />}
                >
                    <SnackbarContent
                        className={c.content}
                        style={style}
                        message={message}
                        action={
                            label ? (
                                <Fragment>
                                    <Button
                                        className={c.button}
                                        variant='text'
                                        onClick={actionHandle}
                                    >
                                        {label}
                                    </Button>
                                    <IconButton
                                        aria-label='close'
                                        color='inherit'
                                        className={c.close}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Fragment>
                            ) : (
                                <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    className={c.close}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )
                        }
                    />
                </MaterialSnackBar>
            )
        );
    }
);

Snackbar.propTypes = propTypes;

export default Snackbar;
