import React, { useState, useCallback, useEffect } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import clsx from 'classnames';
import debounce from 'lodash/debounce';

import { withStyles, InputBase } from '@material-ui/core';

import { withField } from '@libs/reduxForm';

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none',
        backgroundColor: '#fff'
    },
    input: {
        height: '100%',
        width: '100%',
        fontFamily: 'Museo',
        fontSize: spacing.unit * 1.75,
        padding: `${spacing.unit * 0.5}px ${spacing.unit * 2}px`,
        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    error: {
        border: '2px solid',
        borderColor: `${palette.error.main} !important`, // Focus override
        '&:hover': {
            borderColor: `${palette.error.dark} !important` // Focus override
        }
    }
});

const propTypes = {
    name: string.isRequired
};

const Input = compose(
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        disabled,
        input: { onChange, onBlur, value, ...input },
        type,
        hasError,
        validateSizeDetailsFields
    }) => {
        const [localValue, setLocalValue] = useState(value);

        const onChangeError = useCallback(
            debounce(newValue => {
                validateSizeDetailsFields(newValue, value);
            }, 500),
            [validateSizeDetailsFields, value]
        );

        const handleChange = useCallback(
            event => {
                const newValue = event.target.value;
                setLocalValue(newValue);
                onChangeError(newValue);
            },
            [onChangeError, setLocalValue]
        );

        const handleOnBlur = useCallback(() => {
            if (value !== localValue && !hasError) {
                onChange(localValue || null);
                onBlur();
            }
        }, [onChange, onBlur, localValue, hasError, value]);

        const inputBaseProps = {
            classes: {
                root: clsx(c.root, hasError && c.error),
                ...Object.splice(c, ['input', 'focused', 'disabled'])
            },
            disabled,
            type,
            value: localValue,
            onChange: handleChange,
            onBlur: handleOnBlur,
            ...input
        };

        useEffect(() => {
            if (value !== localValue) {
                setLocalValue(value);
            }
        }, [value]);

        return <InputBase {...inputBaseProps} />;
    }
);

Input.propTypes = propTypes;

export default Input;
