export * from './templates';
export * from './sendMail';

export function updateCcAddress(ccAddress, userEmail, recipients) {
    const shouldAddToCc =
        userEmail &&
        (!ccAddress || !ccAddress.includes(userEmail)) &&
        !recipients.includes(userEmail);
    return shouldAddToCc
        ? ccAddress
            ? ccAddress + ',' + userEmail
            : userEmail
        : ccAddress || '';
}
