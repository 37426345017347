export const isoDate = (inputDate, start = true) => {
    if (!inputDate) return null;
    inputDate = new Date(inputDate);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    if (start) {
        // Return date with the start of the day
        return `${year}-${month}-${day}T00:00:00.000Z`;
    } else {
        // Return date with the end of the day
        return `${year}-${month}-${day}T23:59:59.999Z`;
    }
};
