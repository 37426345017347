export const FETCH_TRIMS_INFO = 'get_trims_list_info';
export const FETCH_TRIMS_INFO_SUCCESS = 'get_trims_list_info_success';
export const FETCH_TRIMS_INFO_FAILURE = 'get_trims_list_info_failure';

export const GET_SINGLE_TRIM_INFO_REQUEST = 'get_trims_single_info';
export const GET_SINGLE_TRIM_INFO_SUCCESS = 'get_trims_single_info_success';
export const GET_SINGLE_TRIM_INFO_FAILURE = 'get_trims_single_info_failure';

export const POST_NEW_TRIM_STEP_FIRST = 'post_new_trim_modal_first';
export const POST_NEW_TRIM_STEP_FIRST_SUCCESS =
	'post_new_trim_modal_first_success';

export const UPDATE_NEW_TRIM_FINAL_STEP = 'put_new_trim_modal_final';

export const DELETE_TRIM_ITEM_REQUEST = 'delete_trim_item';
export const DELETE_TRIM_ITEM_SUCCESS = 'delete_trim_item_success';
