import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import GridXlsxExport from 'dataGrid/components/GridXLSXExport';

import { downloadCadAsExcel } from '../../redux.actions';
import { downloadCadAsExcelLoadingSelector } from '../../redux.selectors';
import PDF from './PDF';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: `${spacing.unit * 4}px`
    }
});

const mapStateToProps = state => ({
    exportAsExcelloading: downloadCadAsExcelLoadingSelector(state)
});

const mapDispatchToProps = (dispatch, { ...payload }) => ({
    handleXLDownload: () => dispatch(downloadCadAsExcel(payload))
});

const ExportOptions = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(({ classes, exportAsExcelloading, handleXLDownload, selectedRows, data }) => {
    return (
        <div className={classes.root}>
            <GridXlsxExport
                title='XLSX'
                loading={exportAsExcelloading}
                onClick={handleXLDownload}
            />
            <PDF data={data} selectedRows={selectedRows} />
        </div>
    );
});

export { ExportOptions as default, ExportOptions };
