import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'classnames';

// Material
import { FormControl, withStyles } from '@material-ui/core';

// Libs
import Label from '@baseComponents/Label';
import FormHelperTextBase from '@baseComponents/FormHelperText';
import { withField } from '@libs/reduxForm';
import { composeClsx } from '@libs/materialUI';

// Local
import Control from './Control';
import validateAttachmentSizeAndExtension from './attachmentValidationRule';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    staticValue: PropTypes.string
};

const defaultProps = {
    name: 'attachments',
    label: 'Additional attachments',
    validate: validateAttachmentSizeAndExtension
};

const styles = ({ palette, spacing }) => ({
    root: {
        height: spacing.unitHeight
    },
    container: {
        width: '100%',
        paddingBottom: spacing.unitPadding
    },
    amendRoot: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: palette.amend.dark
    }
});

const mapState = (state, { name }) => ({
    // amended: selectAmendedField(state, name)
});

const AttachmentDetails = compose(
    connect(mapState),
    withField(),
    withStyles(styles)
)(
    ({
        classes,
        className,
        placeholder,
        amended,
        styles,
        fieldName,
        loading,
        disabled,
        label,
        required,
        meta: { error, touched, form },
        ...restProps
    }) => {
        const { root, amendRoot, amendDisabled, container, ...c } = composeClsx(
            {
                classes,
                styles
            }
        );

        const id = `${form}-${fieldName}`;
        const hasError = touched && !!error;

        const props = {
            styles: {
                ...c,
                root: clsx(root, className, amended && amendRoot),
                disabled: clsx(c.disabled, amended && amendDisabled)
            },
            disabled: disabled || loading,
            placeholder: placeholder || restProps.label || '',
            id,
            hasError,
            ...restProps
        };

        return (
            <FormControl className={container} id={id}>
                {label && (
                    <Label
                        label={label}
                        disabled={disabled || loading}
                        required={required}
                        htmlFor={id}
                    />
                )}
                <Control {...props} />
                <FormHelperTextBase error={hasError}>
                    {hasError
                        ? error
                        : '*Accepted formats: images, PDF. Excel, Word, Max file size 10MB'}
                </FormHelperTextBase>
            </FormControl>
        );
    }
);

AttachmentDetails.propTypes = propTypes;
AttachmentDetails.defaultProps = defaultProps;

export { AttachmentDetails as default, AttachmentDetails };
