const mergeMenus = (menus, authMenus = []) => {
	const authMenuIds = authMenus.map(menu => menu && menu.menuId);
	const filteredMenus = menus.filter(menu =>
		authMenuIds.includes(menu.menuId)
	);

	const findSingleMenuInfo = menuId =>
		filteredMenus.filter(menu => menu.menuId === menuId)[0];

	return authMenus
		.map(menu => ({
			...menu,
			...findSingleMenuInfo(menu.menuId)
		}))
		.filter(({ menuName }) => menuName && menuName.length);
};

export { mergeMenus as default, mergeMenus };
