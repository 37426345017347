import React from 'react';
import PropTypes from 'prop-types';

// Libs
import FileUpload from '@formComponents/FileUpload';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'techPack',
    label: 'Tech Pack',
    placeholder: 'Upload tech pack'
};

const UploadTechPack = props => {
    return (
        <FileUpload
            uploadConstraints={{
                helperText: '*PDF ONLY / MAX 15MB',
                accept: 'application/pdf'
            }}
            {...props}
        />
    );
};

UploadTechPack.propTypes = propTypes;
UploadTechPack.defaultProps = defaultProps;

export default UploadTechPack;
