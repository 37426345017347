import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import FormHelperTextBase from '@baseComponents/FormHelperText';
import { useDimensionsObserver } from '@libHooks/useDimensions';
import { ContentContainer } from '@libComponents/ContentContainer';

import useVersionsViewer from '../useVersionsViewer';
import Versions from './Versions';
import UploadHandleBar from './UploadHandleBar';
import DropZone from './DropZone';
import { imageExtensionFromType } from '@libs/imageChecker';

const propTypes = {
    title: PropTypes.string.isRequired
};

const styles = ({ palette, spacing, transitions }) => ({
    wrapper: {
        marginLeft: spacing.unit * 3,
        width: `calc(66.66% - ${spacing.unit * 3}px)`
    },
    contentContainer: {
        position: 'relative',
        height: `calc(var(--viewerHeight) - var(--childHeight))`,
        transition: transitions.create('height', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter
        })
    },
    contentHeader: {
        height: 'var(--headerFooterHeight)'
    },
    overlayScroll: {
        width: '100%',
        height: `calc(100% - ${spacing.unit * 5.5}px + var(--handleBarHeight))`,
        backgroundColor: palette.background.main
    },
    viewer: {
        padding: spacing.unit
    },
    child: {
        width: '100%',
        marginBottom: 'calc(var(--childHeight) * -1)'
    }
});

const Viewer = withStyles(styles)(({ classes: c, title, children }) => {
    const [
        { readOnly, hasError, error, required },
        dispatch
    ] = useVersionsViewer();
    const [key, setKey] = useState(new Date().getTime());
    const inputRef = useRef(null);

    const handleClick = useCallback(() => {
        inputRef.current.click();
    }, [inputRef]);

    const handleFile = useCallback(
        ({ target: { files } = {} }) => {
            const images = [...files].filter(({ type }) =>
                imageExtensionFromType(type)
            );
            dispatch({ type: 'addImages', payload: images });
            setKey(new Date().getTime());
        },
        [dispatch]
    );

    const [childRef, { height: childHeight }] = useDimensionsObserver();

    const style = {
        '--handleBarCount': readOnly ? 0 : 1,
        '--childHeight': `${childHeight}px`
    };

    return (
        <div className={c.wrapper}>
            <ContentContainer
                title={title}
                styles={{
                    container: c.contentContainer,
                    header: c.contentHeader
                }}
                style={style}
                required={required}
                shouldBorderRed={hasError}
            >
                <Versions handleClick={handleClick} />
                {!readOnly && <DropZone handleClick={handleClick} />}
                {!readOnly && <UploadHandleBar handleClick={handleClick} />}
                <input
                    key={key}
                    accept='image/*'
                    type='file'
                    multiple
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={handleFile}
                />
                <div className={c.child} ref={childRef}>
                    {children}
                </div>
            </ContentContainer>
            {hasError && <FormHelperTextBase error>{error}</FormHelperTextBase>}
        </div>
    );
});

Viewer.propTypes = propTypes;

export default Viewer;
