import React from 'react';

import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing, palette }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr) 75px 50px',
        gridColumnGap: `${spacing.unit * 0.5}px`,
        padding: `${spacing.unit * 0.75}px ${spacing.unit}px`,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    headerText: {
        fontSize: spacing.unit * 1.75
    }
});

const Header = withStyles(styles)(({ classes: c }) => {
    return (
        <div className={c.container}>
            <Typography className={c.headerText}>Label Ref</Typography>
            <Typography className={c.headerText}>Type</Typography>
            <Typography className={c.headerText}>Description</Typography>
            <Typography className={c.headerText}>Color</Typography>
            <Typography className={c.headerText}>Image</Typography>
            <Typography></Typography>
        </div>
    );
});

export default Header;
