import React from 'react';
import { withStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const styles = ({ palette }) => ({
    icon: {
        color: palette.grey[500],
        '&:hover': {
            color: palette.grey[700]
        },
        transform: 'scale(0.8)'
    }
});

const SortedIcon = withStyles(styles)(({ isSortedDesc, classes: c }) => {
    return isSortedDesc ? (
        <KeyboardArrowDown className={c.icon} />
    ) : (
        <KeyboardArrowUp className={c.icon} />
    );
});

export default SortedIcon;
