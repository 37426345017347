// Local
import { composeExistingKeys } from './common';

export const composeStoreAmends = (storeData, colorData) => {
    // Color keys that have data
    const colorKeys = Object.entries(colorData)
        .filter(([key, data]) => !!data.length)
        .map(([key]) => key);

    const storeKeys = Object.keys(storeData).filter(
        key => colorKeys.indexOf(key) >= 0
    );

    const filteredStore = storeKeys.reduce((accumulator, key) => {
        // Finds the id key
        const [idKey] = Object.keys(storeData[key][0]).filter(
            key =>
                String(key)
                    .slice(-2)
                    .toLowerCase() === 'id'
        );

        const colorKeyIds = colorData[key].map(keyData => keyData[idKey]);

        const storeKeyData = storeData[key].map(keyData => {
            const colorKeyIndex = colorKeyIds.indexOf(keyData[idKey]);

            if (colorKeyIndex < 0) {
                return keyData;
            } else {
                const colorKeyData = colorData[key][colorKeyIndex];
                const amends = composeExistingKeys(colorKeyData).filter(
                    key => key !== idKey // Prune Id key
                );

                return { ...keyData, amends };
            }
        });

        return {
            ...accumulator,
            [key]: storeKeyData
        };
    }, {});

    return { ...storeData, ...filteredStore };
};
