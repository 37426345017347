import React from 'react';

import CADS from '@assets/ReportIcons/cads.svg';
import CadConvertToSample from '@assets/ReportIcons/cadConvertToSample.svg';
import cadConvertToOrder from '@assets/ReportIcons/cadConvertToOrder.svg';
import cadConvertToDesigner from '@assets/ReportIcons/cadConvertToDesigner.svg';
import orderQuantity from '@assets/ReportIcons/orderQuantity.svg';
import bdFlag from '@assets/ReportIcons/bdFlag.svg';
import turkeyFlag from '@assets/ReportIcons/turkeyFlag.svg';
import orderShipped from '@assets/ReportIcons/orderShipped.svg';
import bdOrderShipped from '@assets/ReportIcons/bdOrderShipped.svg';
import turkeyOrderShipped from '@assets/ReportIcons/turkeyOrderShipped.svg';
import pound from '@assets/ReportIcons/pound.svg';
import coloredPound from '@assets/ReportIcons/coloredPound.svg';
import usd from '@assets/ReportIcons/usd.svg';
import euro from '@assets/ReportIcons/euro.svg';

export default {
    totalNumberofCads: { icon: CADS },
    totalCadConvToSampleByCad: { icon: CadConvertToSample },
    totalOrderConvByCad: {
        icon: cadConvertToOrder
    },
    totalNumberofsrfs: {
        icon: cadConvertToDesigner
    },
    totalOrderQtyByCad: {
        icon: orderQuantity
    },
    totalOrderQtyBdByCad: {
        icon: bdFlag
    },
    totalOrderQtyTkyByCad: {
        icon: turkeyFlag
    },
    totalOrderShippedQtyByCad: {
        icon: orderShipped
    },
    totalOrderShippedQtyBdByCad: {
        icon: bdOrderShipped
    },
    totalOrderShippedQtyTkyByCad: {
        icon: turkeyOrderShipped
    },
    totalWorkInQtyByCad: {
        icon: orderQuantity
    },
    totalWorkInQtyBDByCad: {
        icon: bdFlag
    },
    totalWorkInQtyTkyByCad: {
        icon: turkeyFlag
    },
    totalGlobalRevenuePound: {
        icon: pound
    },
    totalRevenuePound: {
        icon: coloredPound
    },
    totalRevenueDollar: {
        icon: usd
    },
    totalRevenueEuro: {
        icon: euro
    },
    totalGlobalMarginPound: {
        icon: pound
    },
    totalMarginPound: {
        icon: coloredPound
    },
    totalMarginDollar: {
        icon: usd
    },
    totalMarginEuro: {
        icon: euro
    },
    globalWorkInRevenuePound: {
        icon: pound
    },
    totalWorkInRevenuePound: {
        icon: coloredPound
    },
    totalWorkInRevenueDollar: {
        icon: usd
    },
    totalWorkInRevenueEuro: {
        icon: euro
    },
    globalWorkInMarginPound: {
        icon: pound
    },
    totalWorkInMarginPound: {
        icon: coloredPound
    },
    totalWorkInMarginDollar: {
        icon: usd
    },
    totalWorkInMarginEuro: {
        icon: euro
    }
};
