import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

export const selectSamplingPrint = state => state.samplingPrint;

export const selectPageType = createCachedSelector(
	selectSamplingPrint,
	(state, pageType) => pageType.toLowerCase(),
	(samplingPrint, pageType) =>
		samplingPrint.pageType.toLowerCase() === pageType.toLowerCase()
)(() => 'placeholder');

export const selectPageType1 = createCachedSelector(
	selectSamplingPrint,
	(state, pageType) => pageType,
	(samplingPrint, pageType) =>
		samplingPrint.pageType.toLowerCase() === pageType
)(() => 'placeholder');

export const selectFirstFormSubmitData = state => {
	const { userName } = state.auth;
	const select = formValueSelector('SamplingRequestForm');
	const data = select(
		state,
		'retailerId',
		'productType',
		'factoryLocationId',
		'copySampleReference',
		'departmentId',
		'designerFrom',
		'manualReferenceCheck',
		'sampleReference'
	);

	data['createdBy'] = userName;

	return data;
};
