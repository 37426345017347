import React, { useCallback } from 'react';
import { compose } from 'redux';

import { MuiRow, TableCell } from '@libComponents/Table';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { EditIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';

import Modal from './Modal';

const RowComponent = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    })
)(({ classes: c, handleModal, serial, styles, ...row }) => {
    const handleEdit = useCallback(
        () =>
            handleModal({
                initialValues: row
            }),
        [handleModal, row]
    );

    return (
        <MuiRow classes={styles}>
            <TableCell>{serial}</TableCell>
            <TableCell>{row.trimType}</TableCell>
            <TableCell>{row.isActive ? 'Yes' : 'No'}</TableCell>
            <TableCell>
                {
                    <TooltipAction
                        IconComponent={EditIcon}
                        title='Edit it!'
                        onClick={handleEdit}
                    />
                }
            </TableCell>
        </MuiRow>
    );
});

export default RowComponent;
