import React, { useCallback } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';
import { Edit as Icon } from '@material-ui/icons';

import { gridEditHandle } from '../redux.actions';

const styles = ({ spacing, transitions }) => ({
    icon: {
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5,
        cursor: 'pointer',
        transition: transitions.create('transform', {
            duration: transitions.duration.shortest
        }),
        '&:hover': {
            transform: 'scale(1.2)'
        }
    }
});

const propTypes = {
    id: string.isRequired,
    moduleName: string.isRequired
};

const mapDispatch = (dispatch, { id, moduleName }) => ({
    gridEditHandle: () => dispatch(gridEditHandle({ id, viewName: moduleName }))
});

const EditItem = compose(
    connect(
        null,
        mapDispatch
    ),
    withStyles(styles, { withTheme: true })
)(({ classes: c, gridEditHandle }) => {
    const handleEdit = useCallback(() => {
        gridEditHandle();
    }, [gridEditHandle]);
    return <Icon className={c.icon} onClick={handleEdit} />;
});

EditItem.propTypes = propTypes;

export { EditItem as default, EditItem };
