import {
    FETCH_DATASETS,
    SET_DATASET,
    FETCH_PAGINATION_DATASET,
    SET_PAGINATION_DATASET,
} from 'actionTypes';

// Accepts either single dataset 'String', or array of dataset 'String's.
export const fetchDatasets = (datasets) => ({
    type: FETCH_DATASETS,
    meta: { datasets: Array.isArray(datasets) ? datasets : [datasets] },
});

export const setDataset = (entity, dataset) => ({
    type: SET_DATASET,
    meta: { entity },
    payload: { dataset },
});

export const fetchPaginationDataset = (
    entity,
    { pageNumber = 1, pageSize = 10, filterKey = '', filterCategory = '' },
    shouldAppend = false
) => ({
    type: FETCH_PAGINATION_DATASET,
    meta: { entity, shouldAppend },
    payload: {
        pageNumber,
        pageSize,
        filterKey,
        filterCategory,
    },
});

export const setPaginationDataset = (
    { entity, shouldAppend },
    { data, nextPage }
) => ({
    type: SET_PAGINATION_DATASET,
    meta: { entity, shouldAppend },
    payload: { data, nextPage },
});
