import React from 'react';
import { reduxForm } from 'redux-form';

import ConfirmationDialog from './ConfirmationDialog';
import { FORM_NAME } from './common';

const OrderCPUpdateConfirmation = reduxForm({ form: FORM_NAME })(
    ({ open, onClose, onConfirm, mutationText, loading, updatingRowId }) => {
        return (
            <ConfirmationDialog
                open={open}
                onClose={onClose}
                onConfirm={onConfirm}
                mutationText={mutationText}
                loading={loading}
                updatingRowId={updatingRowId}
            />
        );
    }
);

export { OrderCPUpdateConfirmation as default, OrderCPUpdateConfirmation };
