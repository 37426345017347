import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { fabricDevelopmentMailSubject } from 'fabric/redux.selectors';
import {
    SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
    SET_FABRIC_DEVELOPMENT_MAIL_BODY
} from 'fabric/redux.actionTypes';
import { FORM_NAME } from 'fabric/FabricDevelopment/common';
import SelectMailGroup from './SelectMailGroup';
import SelectMailTemplate from './SelectMailTemplate';

const mapStateToProps = (state, { mode }) => ({
    emailSubject: fabricDevelopmentMailSubject(state, mode),
    retailer: formValueSelector(FORM_NAME)(state, 'retailer')
});

const mapInputs = {
    group: ({ payload }) => ({
        type: SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
        payload
    }),
    template: ({ payload }) => ({
        type: SET_FABRIC_DEVELOPMENT_MAIL_BODY,
        payload
    })
};

const MailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: (values, dispatch, { handleSave }) => {
            handleSave(values);
        }
    }),
    connect(mapStateToProps),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        handleSubmit,
        valid,
        handlePublish,
        closePreviewModal,
        emailSubject,
        retailer,
        initialize
    }) => {
        const handleModalClose = useCallback(() => {
            closeMailModal();
            closePreviewModal();
        }, [closePreviewModal]);

        const publishCallbackHandler = handlePublish({
            closeModals: handleModalClose
        });

        const handleMailFormSubmit = useCallback(() => {
            handleSubmit();
            setTimeout(() => publishCallbackHandler(), 1);
        }, [handleSubmit, publishCallbackHandler]);

        useEffect(() => {
            initialize({ subject: emailSubject });
        }, []);

        const mailProps = {
            MailGroupComponent: <SelectMailGroup retailer={retailer} />,
            MailTemplatesComponent: <SelectMailTemplate retailer={retailer} />,
            handleModalClose: closeMailModal,
            handleSubmit: handleMailFormSubmit,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default MailModal;
