import { userIdSelector } from 'auth/redux.selectors';

export const MENU_GROUPS = '@menuGroups';
export const MENUS = '@menus';

export default {
    [MENU_GROUPS]: {
        url: 'MenuGroup'
    },
    [MENUS]: {
        url: 'Menu/GetMenuByUser',
        params: state => ({ userId: userIdSelector(state) })
    }
};
