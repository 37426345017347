import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadingProgressSelector, loadingSelector } from './redux.selectors';
import { setLoading, setLoadingProgress } from './redux.actions';

const mapState = state => {
    const loadingProgress = loadingProgressSelector(state);
    const loading = loadingSelector(state);
    return {
        loading: loading || !!loadingProgress
    };
};

const mapDispatch = dispatch => ({
    setLoadingFalse: () => dispatch(setLoading(false)),
    setLoadingProgressZero: () => dispatch(setLoadingProgress(0))
});

const LoadingPrompt = ({
    loading,
    setLoadingFalse,
    setLoadingProgressZero
}) => {
    useEffect(() => {
        const handleBeforeUnload = event => {
            if (loading) {
                const message =
                    'Loading is in progress. Please do not close the browser until it is finished.';
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        const handlePageHide = () => {
            if (loading) {
                setLoadingFalse();
                setLoadingProgressZero();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('pagehide', handlePageHide);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('pagehide', handlePageHide);
        };
    }, [loading]);

    return <Fragment />;
};

export default connect(
    mapState,
    mapDispatch
)(LoadingPrompt);
