import createCachedSelector from 're-reselect';

// Local
import { selectSamplingPrint } from './common';
import { mapToSelect } from 'helpers';

// Action Types
import { FETCH_GRAPHIC_REF_REQUEST, SET_CAD_IMAGE } from 'actionTypes';

// Selectors
import {
    selectArtworkLocationsData,
    selectArtworkScalesData,
    selectDesignersData,
    selectPrintPositionsData,
    selectPrintTypesData,
} from '../datasets/sampling/artwork';
import { selectDataset } from '../datasets';
import { GRAPHIC_REFERENCES } from 'libs';

// Artwork Locations
export const selectArtworkLocations = createCachedSelector(
    selectArtworkLocationsData,
    (artworkLocations) => mapToSelect(artworkLocations)
)(() => 'placeholder');

// Artwork Scales
export const selectArtworkScales = createCachedSelector(
    selectArtworkScalesData,
    (artworkScales) => mapToSelect(artworkScales)
)(() => 'placeholder');

// Designers
export const selectDesigners = createCachedSelector(
    selectDesignersData,
    (designers) => mapToSelect(designers)
)(() => 'placeholder');

// Print Positions
export const selectPrintPositions = createCachedSelector(
    selectPrintPositionsData,
    (printPositions) => mapToSelect(printPositions)
)(() => 'placeholder');

// Print Types
export const selectPrintTypes = createCachedSelector(
    selectPrintTypesData,
    (printTypes) => mapToSelect(printTypes)
)(() => 'placeholder');

// Dirty
export const selectCadImage = createCachedSelector(
    selectSamplingPrint,
    (samplingPrint) => samplingPrint.cadImage
)(() => SET_CAD_IMAGE);

export const selectInkDetails = createCachedSelector(
    selectSamplingPrint,
    (samplingPrint) => samplingPrint.inkDetails
)(() => 'placeholder');

export const selectCadImageData = ({
    auth: { userName, userId },
    initSamplingPrint: {
        sampleReferenceAutoId,
        sampleRef,
        developmentNo,
        developmentName,
    },
}) => ({
    UserId: userId,
    UserName: userName,
    SampleMainId: sampleReferenceAutoId,
    SampleRef: sampleRef,
    DevId: developmentNo,
    DevText: developmentName,
});

// Graphic refs
const selectGraphicReferences = createCachedSelector(
    selectSamplingPrint,
    ({ graphicReferences }) => graphicReferences
)(() => 'placeholder');

export const selectGraphicRefList = createCachedSelector(
    selectGraphicReferences,
    (data = []) => {
        if (!data) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: [],
            };
        }
        const graphicRefsData = data.data || [];
        const mainInfo = graphicRefsData.map(function (ref) {
            const { cadRef, description, designerName } = ref;
            const value = { value: cadRef, fullData: ref };

            return [
                { title: cadRef, value },
                { title: description, value },
                { title: designerName, value },
            ];
        });

        return {
            rowInColumnWidth: [25, 50, 25],
            paginationData: data.paginationMetadata,
            columnHeader: ['CAD REF', 'CAD DESCRIPTION', 'DESIGNER'],
            columnInfo: mainInfo,
        };
    }
)(() => FETCH_GRAPHIC_REF_REQUEST);

export const selectGraphicDescription = createCachedSelector(
    (state) => selectDataset(state, GRAPHIC_REFERENCES),
    (state, reference) => reference,
    ({ data }, reference) => {
        return data.reduce((acm, { cadRef = null, description = '' }) => {
            if (cadRef === reference) return description;
            else return acm;
        }, '');
    }
)((state, reference) => reference);
