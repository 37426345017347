import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Image as PDFImage } from '@libComponents/ReactPDF';
import ReportTable from './ReportTable';
import Versions from './Versions';
import { useTitle, useTableData, useVersionsMaxHeight } from './hooks';
import {
    FULL_WIDTH_UNITS,
    FULL_HEIGHT_UNITS,
    IMAGE_CONTAINER_WIDTH_UNITS,
    TABLE_MARGIN_UNITS,
    TITLE_HEIGHT_UNITS
} from './constants';

const propTypes = {
    cadReference: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    licensor: PropTypes.string,
    property: PropTypes.string,
    artworkSource: PropTypes.string.isRequired,
    designer: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    developmentVersion: PropTypes.string.isRequired,
    gender: PropTypes.string,
    style: PropTypes.string,
    sleeveType: PropTypes.string,
    versions: PropTypes.arrayOf(PropTypes.string).isRequired,
    preview: PropTypes.string.isRequired
};

const defaultProps = {
    cadReference: '',
    description: '',
    licensor: '',
    property: '',
    artworkSource: '',
    designer: '',
    type: '',
    developmentVersion: '',
    gender: '',
    style: '',
    sleeveType: '',
    versions: []
};

const styles = ({ spacing, typography }) => ({
    page: {
        width: spacing.unit * FULL_WIDTH_UNITS,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        paddingLeft: spacing.unit * 7.5,
        paddingRight: spacing.unit * 7.5,
        paddingTop: spacing.unit * 9,
        paddingBottom: spacing.unit * 9
    },
    pageImage: {
        justifyContent: 'center'
    },
    pageFooter: {
        position: 'absolute',
        width: spacing.unit * FULL_WIDTH_UNITS,
        marginLeft: spacing.unit * 7.5,
        marginRight: spacing.unit * 7.5,
        height: spacing.unit * 9,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 0
    },
    pageWatermark: {
        position: 'absolute',
        width: spacing.unit * 7.5,
        marginTop: spacing.unit * 9,
        marginBottom: spacing.unit * 9,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        alignItems: 'center',
        justifyContent: 'flex-end',
        right: 0,
        opacity: 0.4
    },
    watermark: {
        transform: 'rotate(-90deg)',
        fontSize: 20,
        marginBottom: spacing.unit * 20.5,
        width: spacing.unit * 50
    },
    tableSection: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: spacing.unit * TABLE_MARGIN_UNITS
    },
    versionSection: {
        width: '100%'
    },
    titleContainer: {
        flexDirection: 'row',
        width: '100%',
        height: spacing.unit * TITLE_HEIGHT_UNITS,
        justifyContent: 'center',
        paddingBottom: spacing.unit * TABLE_MARGIN_UNITS
    },
    title: {
        fontSize: 27,
        fontWeight: typography.fontWeightHeavy
    },
    imageContainer: {
        width: spacing.unit * IMAGE_CONTAINER_WIDTH_UNITS,
        maxHeight: spacing.unit * IMAGE_CONTAINER_WIDTH_UNITS * Math.sqrt(2),
        marginRight: spacing.unit * 4
    },
    image: { width: 'auto', objectFit: 'contain' }
});

const Report = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme,
        cadReference,
        description,
        licensor,
        property,
        artworkSource,
        designer,
        type,
        developmentVersion,
        sleeveType,
        versions,
        preview
    }) => {
        const title = useTitle({ cadReference, description });

        const { totalLines, tableData } = useTableData({
            cadReference,
            description,
            licensor,
            property,
            artworkSource,
            designer,
            type,
            developmentVersion,
            sleeveType
        });

        const versionsMaxHeight = useVersionsMaxHeight({
            totalLines,
            preview,
            theme
        });

        const year = useMemo(() => new Date().getFullYear(), []);

        return (
            <Document>
                <Page size='A3' style={c.page}>
                    <View style={c.titleContainer}>
                        <Text style={c.title}>{title}</Text>
                    </View>
                    <View style={c.tableSection}>
                        <View style={c.imageContainer}>
                            {preview && (
                                <PDFImage style={c.image} src={preview} />
                            )}
                        </View>
                        <ReportTable
                            tableData={tableData}
                            totalLines={totalLines}
                        />
                    </View>
                    <View style={c.versionSection}>
                        <Versions
                            versions={versions}
                            maxHeight={versionsMaxHeight}
                        />
                    </View>
                </Page>
                {versions.map((version, index) => {
                    return (
                        <Page
                            size='A3'
                            style={{ ...c.page, ...c.pageImage }}
                            key={`imagePage-${index}`}
                        >
                            <View style={c.pageFooter}>
                                <Text>{`${cadReference} V${index + 1}`}</Text>
                            </View>
                            <View style={c.pageWatermark}>
                                <Text style={c.watermark}>
                                    &copy;{` ${year} Echotech Systems Limited`}
                                </Text>
                            </View>
                            {version && (
                                <PDFImage style={c.image} src={version} />
                            )}
                        </Page>
                    );
                })}
            </Document>
        );
    }
);

Report.propTypes = propTypes;
Report.defaultProps = defaultProps;

export { Report as default, Report };
