import React, { useCallback, useRef, useState } from 'react';
import clsx from 'classnames';

// Material
import { Button, withStyles } from '@material-ui/core';

// Local
import useUpload from './useUpload';
import { useDropZone } from './hooks';

const propTypes = {};
const defaultProps = {};

const styles = ({ palette, spacing, typography, shadows }) => ({
    root: {
        display: 'flex',
        height: spacing.unitHeight,
        width: '100%'
    },
    label: {
        width: '80%',
        height: '100%',
        justifyContent: 'start',
        textTransform: 'none',
        color: 'var(--textColor)',
        fontWeight: typography.fontWeightLight,

        borderWidth: 1,
        borderColor: palette.common.black,
        borderStyle: 'solid',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:hover': {
            backgroundColor: palette.common.white
        }
    },
    upload: {
        width: '20%',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        height: '100%',
        color: palette.common.white,
        fontSize: typography.caption.fontSize,
        fontWeight: typography.fontWeightHeavy,
        borderRadius: 0,
        textTransform: 'none',
        boxShadow: shadows[0],
        background: `linear-gradient(to bottom, ${palette.success.main}, ${palette.success.dark})`,

        '&:hover': {
            background: `linear-gradient(to bottom, ${palette.success.dark}, ${palette.success.dark})`
        }
    },
    onDragInPosition: {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            backgroundColor: '#000',
            opacity: 0.5,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
});

const Control = withStyles(styles)(({ classes: c }) => {
    const [key, setKey] = useState(new Date().getTime());
    const [
        { filename, isDragging, handleBlur, uploadConstraints },
        dispatch
    ] = useUpload();
    const dropZoneHandles = useDropZone(handleBlur);
    const inputRef = useRef(null);

    const handleClick = useCallback(() => {
        inputRef.current.click();
    }, [inputRef]);

    const handleFile = useCallback(
        ({ target: { files: [file] } = {} }) => {
            dispatch({ type: 'addFile', payload: file });
            setKey(new Date().getTime());
            handleBlur();
        },
        [dispatch, handleBlur]
    );

    return (
        <div
            className={c.root}
            onDragOver={e => {
                e.preventDefault();
            }}
            {...dropZoneHandles}
        >
            <Button
                className={clsx(c.label, isDragging ? c.onDragInPosition : '')}
                onClick={handleClick}
            >
                {isDragging ? 'Drop here..' : filename}
            </Button>
            <Button className={c.upload} onClick={handleClick}>
                Upload
            </Button>
            <input
                accept={uploadConstraints.accept}
                type='file'
                hidden
                ref={inputRef}
                onChange={handleFile}
                key={key}
            />
        </div>
    );
});

Control.propTypes = propTypes;
Control.defaultProps = defaultProps;

export default Control;
