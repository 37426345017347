import { FETCH_TRIMS_INFO_SUCCESS } from 'actionTypes';

const trimsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_TRIMS_INFO_SUCCESS: {
            return { ...state, trimsReferences: payload };
        }

        default: {
            return state;
        }
    }
};

export { trimsReducer as default, trimsReducer };
