import { startSubmit } from 'redux-form';
import { put, call } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { renameProperties } from '@libs/object';

import { FORM_NAME } from '../../Fabric/FabricTypes/common';
import { fabricTypesSchema } from './schemas';
import { DOMAIN_NAME } from 'sampling/common';
import { FABRIC_TYPES } from 'sampling/redux.datasets';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const publishData = composeApiPayload(
            renameProperties(payload, {
                fabricType: 'type'
            }),
            fabricTypesSchema
        );

        const { data } = yield call(apiRequest, {
            url: 'FabricTypes',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: FABRIC_TYPES,
            isNew: id === 0,
            data
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Fabric type',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
