import { normalizeDate } from '@baseComponents/Date';

export { DOMAIN_NAME } from '../common';
const FORM_NAME = 'print';

const LABELS = {
    setup: 'New Sample Request Setup',
    details: 'Sample Reference & Spec Details',
    other: 'Other Information',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    sampleReference: '',
    developmentNumber: 1,
    requestType: 2,
    dateRequested: normalizeDate(new Date()),
    fabrics: [],
    attachments: [],
    boughtDetails: [],
    numberOfColours: 1
};

const NEW_PRINT_ROUTE_ID = 588;
const DESIGN_SOURCE_BUYER_ID = 3;
const INCOMPLETE_PRINT_ROUTE_ID = 131;
const AMEND_PRINT_ROUTE_ID = 132;

export {
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_PRINT_ROUTE_ID,
    INCOMPLETE_PRINT_ROUTE_ID,
    AMEND_PRINT_ROUTE_ID,
    DESIGN_SOURCE_BUYER_ID
};
