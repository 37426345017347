import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import styles from './styles';
import { isLoading } from 'helpers';
import { selectTrimsList, selectBoughtDetailsList } from 'selectors';
import {
    BacknecksModal,
    TrimsModal,
    TrimDetailsModal
} from 'views/Sampling/modals';

import { FETCH_TRIMS_INFO, FETCH_BOUGHT_DETAILS_TYPES } from 'actionTypes';

import {
    Accordion,
    AccordionPanel,
    SelectFieldMultiple,
    CheckBoxIconBlank,
    CheckBoxIconFilled,
    EditIcon,
    DeleteIcon,
    Tooltip,
    withModal,
    PaginatedSelectTest
} from 'components';

import {
    setStepData,
    uploadAttachments,
    fetchTrimsInfo,
    postSingleTrimInfo,
    fetchBoughtDetails,
    postSingleBoughtValue,
    deleteBackneckDetails,
    deleteSingleBoughtValue,
    deleteSingleTrimInfo
} from 'actions';
import {TRIM_DETAILS} from "../../../../../store/libs/datasets/entities/sampling";

function mapStateToProps(state) {
    return {
        store: state,
        trims: selectTrimsList(state),
        boughtDetails: selectBoughtDetailsList(state)
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            setDataForSteps: setStepData,
            deleteTrimItem: deleteSingleTrimInfo,
            deleteBoughtItem: deleteSingleBoughtValue,
            uploadAttachments,
            deleteBackneckDetails,
            fetchTrimsInfo,
            postSingleTrimInfo,
            fetchBoughtDetails,
            postSingleBoughtValue
        },
        dispatch
    );
};

class AccordionContent extends PureComponent {
    componentDidMount = () => {
        const {
            store: {
                samplingPrint: {
                    trimsReferences: trimsInfo,
                    boughtReferences: boughtDetailsTypes
                }
            }
        } = this.props;

        if (
            !trimsInfo ||
            !trimsInfo.length ||
            (trimsInfo.data !== void 0 && !trimsInfo.data.length)
        ) {
            this.getTrimsList();
        }

        if (
            !boughtDetailsTypes ||
            !boughtDetailsTypes.length ||
            (boughtDetailsTypes.data !== void 0 &&
                !boughtDetailsTypes.data.length)
        ) {
            this.getBoughtItems();
        }
    };

    setValueToState = param => {
        let stepKey = param.type;
        let stepData = param.value;
        this.props.setDataForSteps(stepKey, stepData);
    };

    getTrimsList = async (searchKey = '', pageNo = 1) => {
        const { fetchTrimsInfo } = this.props;

        return fetchTrimsInfo({ searchKey, pageNo });
    };

    getBoughtItems = async (searchKey = '', pageNo = 1) => {
        const { fetchBoughtDetails } = this.props;

        return fetchBoughtDetails({ searchKey, pageNo });
    };

    isLoadingStatus = key => isLoading(this.props.store.global, key);

    render() {
        const { props, setValueToState, isLoadingStatus } = this;
        const {
            classes,
            trims,
            boughtDetails,
            handleBackneckModal,
            handleTrimModal,
            handleTrimNextModal,
            postSingleBoughtValue,
            postSingleTrimInfo,
            deleteBackneckDetails,
            deleteTrimItem,
            deleteBoughtItem,
            store: {
                initSamplingPrint: {
                    backnecks,
                    trims: trimsData,
                    boughts,
                    selectedTrim,
                    selectedBought
                }
            }
        } = props;

        // TODO: Accordion Style FIX
        return (
            <div style={{ width: '100%', marginTop: 24 }}>
                <Accordion>
                    {/*Backenck Details*/}
                    <AccordionPanel
                        border={true}
                        tag='backenck-123'
                        title='Backneck'
                    >
                        <div style={{ paddingTop: 16 }}>
                            {backnecks.length > 0 ? (
                                <Grid
                                    container
                                    justify='flex-start'
                                    spacing={24}
                                >
                                    <Grid item md={12}>
                                        <Button
                                            variant='contained'
                                            className={classNames(
                                                classes.addBackneckButton
                                            )}
                                            onClick={() =>
                                                handleBackneckModal()
                                            }
                                        >
                                            Add more backneck
                                        </Button>
                                    </Grid>
                                    {backnecks.map((item, index) => (
                                        <Grid item md={3} key={index}>
                                            <Table
                                                className={classes.cardTable}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>
                                                                Backneck{' '}
                                                                {index + 1}{' '}
                                                                Details:
                                                            </b>
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <Tooltip
                                                                onClick={() =>
                                                                    handleBackneckModal(
                                                                        {
                                                                            backneckEditIndex: index
                                                                        }
                                                                    )
                                                                }
                                                                title='Edit it'
                                                            >
                                                                <EditIcon
                                                                    width='10px'
                                                                    height='10px'
                                                                    className={
                                                                        classes.tooltipTriggerIcon
                                                                    }
                                                                />
                                                            </Tooltip>
                                                            <Tooltip
                                                                onClick={() =>
                                                                    deleteBackneckDetails(
                                                                        {
                                                                            index
                                                                        }
                                                                    )
                                                                }
                                                                title='Delete it'
                                                            >
                                                                <DeleteIcon
                                                                    width='10px'
                                                                    height='10px'
                                                                    className={
                                                                        classes.tooltipTriggerIcon
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <Fragment>
                                                        {item.images &&
                                                        (typeof item.images &&
                                                            item.images.name !==
                                                                void 0) ? (
                                                            <TableRow
                                                                className={
                                                                    classes.imageRow
                                                                }
                                                            >
                                                                <TableCell
                                                                    component='th'
                                                                    scope='row'
                                                                >
                                                                    <img
                                                                        src={URL.createObjectURL(
                                                                            item.images
                                                                        )}
                                                                        alt='backneck'
                                                                        style={{
                                                                            maxHeight: 50
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {
                                                                            item
                                                                                .images
                                                                                .name
                                                                        }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align='right' />
                                                            </TableRow>
                                                        ) : null}

                                                        {Object.keys(item).map(
                                                            (key, i) =>
                                                                item[key] &&
                                                                item[key]
                                                                    .title ? (
                                                                    <TableRow
                                                                        key={i}
                                                                    >
                                                                        <TableCell
                                                                            component='th'
                                                                            scope='row'
                                                                        >
                                                                            {
                                                                                item[
                                                                                    key
                                                                                ]
                                                                                    .title
                                                                            }
                                                                        </TableCell>
                                                                        {item[
                                                                            key
                                                                        ]
                                                                            .type ===
                                                                        'Check' ? (
                                                                            <TableCell align='right'>
                                                                                {item[
                                                                                    key
                                                                                ]
                                                                                    .text ? (
                                                                                    <CheckBoxIconFilled
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                ) : (
                                                                                    <CheckBoxIconBlank
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell align='right'>
                                                                                {
                                                                                    item[
                                                                                        key
                                                                                    ]
                                                                                        .text
                                                                                }
                                                                            </TableCell>
                                                                        )}
                                                                    </TableRow>
                                                                ) : null
                                                        )}
                                                    </Fragment>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Button
                                        variant='contained'
                                        className={classNames(
                                            classes.addBackneckButton,
                                            classes.centerAlign
                                        )}
                                        onClick={() => handleBackneckModal()}
                                    >
                                        Add backneck
                                    </Button>
                                    <Typography
                                        component='p'
                                        style={{
                                            textAlign: 'center',
                                            color: '#cccccc',
                                            padding: '10px 0'
                                        }}
                                    >
                                        No backneck yet! Click above button to
                                        add backneck.
                                    </Typography>
                                </Fragment>
                            )}
                        </div>
                    </AccordionPanel>

                    {/*Trim Details*/}
                    <AccordionPanel
                        border={true}
                        tag='trim-456'
                        title='Trim Details'
                    >
                        <div style={{ paddingTop: 16 }}>
                            <Grid
                                container
                                justify={
                                    trimsData.length > 0
                                        ? 'flex-start'
                                        : 'center'
                                }
                                spacing={40}
                            >
                                <Grid item md={4}>
                                    <Typography
                                        component='div'
                                        className={classNames(
                                            classes.sidebyside
                                        )}
                                    >
                                        <Typography
                                            component='div'
                                            className={classNames(
                                                classes.leftSide,
                                                classes.singleCardBox
                                            )}
                                        >
                                            {/*<PaginatedSelectTest*/}
                                            {/*    name='trimDetails'*/}
                                            {/*    label='Trim Details'*/}
                                            {/*    placeholder='Please select reference'*/}
                                            {/*    required*/}
                                            {/*    entity={TRIM_DETAILS}*/}
                                            {/*/>*/}
                                            <SelectFieldMultiple
                                                searchOption={true}
                                                pagination={true}
                                                rowInColumnWidth={
                                                    trims.rowInColumnWidth
                                                }
                                                paginationData={
                                                    trims.paginationData
                                                }
                                                columnHeader={
                                                    trims.columnHeader
                                                }
                                                columnInfo={trims.columnInfo}
                                                loading={isLoadingStatus(
                                                    FETCH_TRIMS_INFO
                                                )}
                                                success={!!selectedTrim}
                                                required={true}
                                                action={param =>
                                                    setValueToState(param)
                                                }
                                                loadMoreItems={(key, page) =>
                                                    this.getTrimsList(key, page)
                                                }
                                                label=''
                                                placeholder='Select trim card ref.'
                                                value={
                                                    selectedTrim
                                                        ? selectedTrim.value
                                                              .value
                                                        : null
                                                }
                                                type='selectedTrim'
                                            />
                                        </Typography>

                                        {selectedTrim ? (
                                            <Button
                                                variant='contained'
                                                classes={{
                                                    root: classes.nextButton,
                                                    disabled:
                                                        classes.disabledNextButton
                                                }}
                                                onClick={() =>
                                                    postSingleTrimInfo()
                                                }
                                            >
                                                Save
                                            </Button>
                                        ) : null}

                                        <Button
                                            variant='outlined'
                                            className={classNames(
                                                classes.plusButton,
                                                classes.rightSide,
                                                classes.trimPlusButton
                                            )}
                                            disabled={!!selectedTrim}
                                            onClick={() =>
                                                handleTrimModal({
                                                    moveToNextModal: handleTrimNextModal
                                                })
                                            }
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* TRIM HERE */}
                            {trimsData.length > 0 ? (
                                <Grid
                                    container
                                    justify='flex-start'
                                    spacing={24}
                                >
                                    {trimsData.map((item, index) => (
                                        <Grid item md={3} key={index}>
                                            <Table
                                                className={classes.cardTable}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>
                                                                Trim {index + 1}{' '}
                                                                Details:
                                                            </b>
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <Tooltip
                                                                onClick={() =>
                                                                    deleteTrimItem(
                                                                        {
                                                                            deleteTrimIndex: index
                                                                        }
                                                                    )
                                                                }
                                                                title='Delete it'
                                                            >
                                                                <DeleteIcon
                                                                    width='10px'
                                                                    height='10px'
                                                                    className={
                                                                        classes.tooltipTriggerIcon
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <Fragment>
                                                        {item.trimImage &&
                                                        item.trimImage.length >
                                                            0
                                                            ? item.trimImage.map(
                                                                  (
                                                                      tImage,
                                                                      tIndex
                                                                  ) => {
                                                                      return tImage.imageSize ===
                                                                          '75_75' ? (
                                                                          <TableRow
                                                                              key={
                                                                                  tIndex
                                                                              }
                                                                          >
                                                                              <TableCell
                                                                                  component='th'
                                                                                  scope='row'
                                                                              >
                                                                                  <img
                                                                                      src={
                                                                                          tImage.imageUrl
                                                                                      }
                                                                                      alt={
                                                                                          tImage.imageName
                                                                                      }
                                                                                  />
                                                                              </TableCell>
                                                                              <TableCell align='right' />
                                                                          </TableRow>
                                                                      ) : null;
                                                                  }
                                                              )
                                                            : null}

                                                        {Object.keys(item).map(
                                                            (key, i) =>
                                                                item[key]
                                                                    .title ? (
                                                                    <TableRow
                                                                        key={i}
                                                                    >
                                                                        <TableCell
                                                                            component='th'
                                                                            scope='row'
                                                                        >
                                                                            {
                                                                                item[
                                                                                    key
                                                                                ]
                                                                                    .title
                                                                            }
                                                                        </TableCell>
                                                                        {item[
                                                                            key
                                                                        ]
                                                                            .type ===
                                                                        'Check' ? (
                                                                            <TableCell align='right'>
                                                                                {item[
                                                                                    key
                                                                                ]
                                                                                    .text ? (
                                                                                    <CheckBoxIconFilled
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                ) : (
                                                                                    <CheckBoxIconBlank
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell align='right'>
                                                                                {
                                                                                    item[
                                                                                        key
                                                                                    ]
                                                                                        .text
                                                                                }
                                                                            </TableCell>
                                                                        )}
                                                                    </TableRow>
                                                                ) : null
                                                        )}
                                                    </Fragment>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : null}
                        </div>
                    </AccordionPanel>

                    {/*Bought Details*/}
                    <AccordionPanel
                        border={true}
                        tag='bought-789'
                        title='Bought Details'
                    >
                        <div style={{ paddingTop: 16 }}>
                            <Grid
                                container
                                justify={
                                    boughts.length > 0 ? 'flex-start' : 'center'
                                }
                                spacing={40}
                            >
                                <Grid item md={3}>
                                    <Typography
                                        component='div'
                                        className={classNames(
                                            classes.sidebyside
                                        )}
                                    >
                                        <Typography
                                            component='div'
                                            className={classNames(
                                                classes.leftSide,
                                                classes.singleCardBox
                                            )}
                                        >
                                            <SelectFieldMultiple
                                                searchOption={true}
                                                pagination={true}
                                                rowInColumnWidth={
                                                    boughtDetails.rowInColumnWidth
                                                }
                                                paginationData={
                                                    boughtDetails.paginationData
                                                }
                                                columnHeader={
                                                    boughtDetails.columnHeader
                                                }
                                                columnInfo={
                                                    boughtDetails.columnInfo
                                                }
                                                loading={isLoadingStatus(
                                                    FETCH_BOUGHT_DETAILS_TYPES
                                                )}
                                                success={
                                                    selectedBought
                                                        ? true
                                                        : false
                                                }
                                                required={true}
                                                action={param =>
                                                    setValueToState(param)
                                                }
                                                loadMoreItems={(key, page) =>
                                                    this.getBoughtItems(
                                                        key,
                                                        page
                                                    )
                                                }
                                                label=''
                                                placeholder='Select bought details'
                                                value={
                                                    selectedBought
                                                        ? selectedBought.value
                                                              .value
                                                        : null
                                                }
                                                type='selectedBought'
                                            />
                                        </Typography>
                                        <Button
                                            variant='contained'
                                            disabled={
                                                selectedBought ? false : true
                                            }
                                            classes={{
                                                root: classes.nextButton,
                                                disabled:
                                                    classes.disabledNextButton
                                            }}
                                            onClick={() =>
                                                postSingleBoughtValue()
                                            }
                                        >
                                            Save
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* BOUGHT HERE */}
                            {boughts.length > 0 ? (
                                <Grid
                                    container
                                    justify='flex-start'
                                    spacing={24}
                                >
                                    {boughts.map((item, index) => (
                                        <Grid item md={3} key={index}>
                                            <Table
                                                className={classes.cardTable}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>
                                                                Bought{' '}
                                                                {index + 1}{' '}
                                                                Details:
                                                            </b>
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <Tooltip
                                                                onClick={() =>
                                                                    deleteBoughtItem(
                                                                        {
                                                                            deleteBoughtIndex: index
                                                                        }
                                                                    )
                                                                }
                                                                title='Delete it'
                                                            >
                                                                <DeleteIcon
                                                                    width='10px'
                                                                    height='10px'
                                                                    className={
                                                                        classes.tooltipTriggerIcon
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <Fragment>
                                                        {item.images &&
                                                        item.images.length > 0
                                                            ? item.images.map(
                                                                  (
                                                                      bImage,
                                                                      bIndex
                                                                  ) => {
                                                                      return bImage.imageSize ===
                                                                          '75_75' ? (
                                                                          <TableRow
                                                                              key={
                                                                                  bIndex
                                                                              }
                                                                          >
                                                                              <TableCell
                                                                                  component='th'
                                                                                  scope='row'
                                                                              >
                                                                                  <img
                                                                                      src={
                                                                                          bImage.imageUrl
                                                                                      }
                                                                                      alt={
                                                                                          bImage.imageName
                                                                                      }
                                                                                  />
                                                                              </TableCell>
                                                                              <TableCell align='right' />
                                                                          </TableRow>
                                                                      ) : null;
                                                                  }
                                                              )
                                                            : null}

                                                        {Object.keys(item).map(
                                                            (key, i) =>
                                                                item[key]
                                                                    .title ? (
                                                                    <TableRow
                                                                        key={i}
                                                                    >
                                                                        <TableCell
                                                                            component='th'
                                                                            scope='row'
                                                                        >
                                                                            {
                                                                                item[
                                                                                    key
                                                                                ]
                                                                                    .title
                                                                            }
                                                                        </TableCell>
                                                                        {item[
                                                                            key
                                                                        ]
                                                                            .type ===
                                                                        'Check' ? (
                                                                            <TableCell align='right'>
                                                                                {item[
                                                                                    key
                                                                                ]
                                                                                    .text ? (
                                                                                    <CheckBoxIconFilled
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                ) : (
                                                                                    <CheckBoxIconBlank
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        color='black'
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell align='right'>
                                                                                {
                                                                                    item[
                                                                                        key
                                                                                    ]
                                                                                        .text
                                                                                }
                                                                            </TableCell>
                                                                        )}
                                                                    </TableRow>
                                                                ) : null
                                                        )}
                                                    </Fragment>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : null}
                        </div>
                    </AccordionPanel>
                </Accordion>
            </div>
        );
    }
}

AccordionContent.propTypes = {
    classes: PropTypes.object
};

const mapModals = {
    handleBackneckModal: BacknecksModal,
    handleTrimModal: TrimsModal,
    handleTrimNextModal: TrimDetailsModal
};

const _AccordionContent = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withModal(mapModals)
)(AccordionContent);

export { _AccordionContent as default, _AccordionContent as AccordionContent };
