import {
    RESET_SAMPLES_REFS,
    RESET_TYPE_CHILDREN,
    RESET_RETAILER_CHILDREN,
    SET_SAMPLE_REFS_AMEND,
    ORDER_PUBLISH,
    SET_CLIENT_PO_TBC_CHILDREN,
    SET_CLIENT_PO_TBC_LOADING,
    SET_CLIENT_PO_NO_CHECK_LOADING,
    FETCH_PRODUCT_DETAILS_BY_REFERENCE,
    SET_PRODUCTS_LOADING,
    FETCH_SAMPLE_REF_CHANGE_DATA,
    SET_SAMPLE_REF_CHANGE_DATA_LOADING,
    ORDER_REFERENCE_GENERATE,
    SET_ORDER_REFERENCE_LOADING,
    SET_FACTORY_CHILDREN,
    SET_FACTORY_REF_LOADING,
    SET_PO_LAB_DIP_COLOR_CHILDREN,
    SET_PO_LAB_DIP_COLOR_LOADING,
    SET_PO_TBC_CHILDREN,
    SET_PO_TBC_LOADING,
    SET_PO_SHIP_MODE_CHILDREN,
    SET_PO_PAYMENT_TERMS_AND_SETTLEMENT_DISCOUNT_DEFAULT_VALUE_LOADING,
    SET_PO_OR_PACK_CHECK_LOADING,
    SET_PO_PACK_TBC_CHILDREN,
    SET_PO_PACK_TBC_LOADING,
    SET_PO_SIZE_SET_CHILDREN,
    SET_PO_SIZE_SET_LOADING,
    SET_PACK_TYPE_CHILDREN,
    WATCH_PO_REMOVED,
    RESET_SIZE_DETAILS_ON_QUANITY_OR_RATIO_CHANGE,
    SET_ORDER_BASIC_TRIMS_CHILDREN,
    ORDER_TRIMS_LOADING,
    SET_ORDER_NOTE_CHILDREN,
    ROUTE_TO_ORDER,
    DESTROY_ORDER_FORM,
    GET_AMEND_ORDER_DATA,
    GET_INCOMPLETE_ORDER_DATA,
    ROUTE_TO_AMEND_ORDER,
    SET_PO_NO_CHILDREN,
    SET_PO_EX_FTY_DATE,
    SET_SAME_STYLE_IN_ALL_PACKS,
    GENERATE_PO_SIZE_SKU,
    ORDER_REPORT_MAIL_SEND,
    SET_ORDER_EMAIL_BODY,
    SET_ORDER_EMAIL_RECEIVERS,
    SET_PO_SIZE_TABLE_QUANTITY_CHILDREN,
    SET_PO_SIZE_TABLE_RATIO_CHILDREN,
    RESET_ORDER_FORM,
    SET_CP_EXPORT_EXCEL_DATA_LOADING
} from './redux.actionTypes';

export const resetSamplesRefs = fieldName => ({
    type: RESET_SAMPLES_REFS,
    meta: { fieldName }
});
export const resetTypeChildren = payload => ({
    type: RESET_TYPE_CHILDREN,
    payload
});
export const resetRetailerChildren = () => ({ type: RESET_RETAILER_CHILDREN });
export const setSampleRefsAmend = () => ({ type: SET_SAMPLE_REFS_AMEND });
export const orderSaveDraft = ({ mode }) => ({
    type: ORDER_PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});
export const fetchProductDetailsByReference = () => ({
    type: FETCH_PRODUCT_DETAILS_BY_REFERENCE
});
export const setProductsLoading = loading => ({
    type: SET_PRODUCTS_LOADING,
    payload: loading
});
export const fetchSampleRefChangeData = () => ({
    type: FETCH_SAMPLE_REF_CHANGE_DATA
});
export const setSampleChangeDataLoading = loading => ({
    type: SET_SAMPLE_REF_CHANGE_DATA_LOADING,
    payload: loading
});
export const orderReferenceGenerate = () => ({
    type: ORDER_REFERENCE_GENERATE
});
export const setOrderReferenceLoading = loading => ({
    type: SET_ORDER_REFERENCE_LOADING,
    payload: loading
});
export const setClientPOTBCChildren = tbcChecked => ({
    type: SET_CLIENT_PO_TBC_CHILDREN,
    payload: !!tbcChecked
});
export const setClientPOTBCLoading = loading => ({
    type: SET_CLIENT_PO_TBC_LOADING,
    payload: loading
});
export const setClientPONoCheckLoading = loading => ({
    type: SET_CLIENT_PO_NO_CHECK_LOADING,
    payload: loading
});
export const setFactoryChildren = value => ({
    type: SET_FACTORY_CHILDREN,
    payload: value
});
export const setFactoryRefLoading = loading => ({
    type: SET_FACTORY_REF_LOADING,
    payload: loading
});
export const setPOLabDipColorChildren = ({ color, field }) => ({
    type: SET_PO_LAB_DIP_COLOR_CHILDREN,
    meta: { field },
    payload: color
});
export const setPOLabDipColorLoading = payload => ({
    type: SET_PO_LAB_DIP_COLOR_LOADING,
    payload
});
export const setPOTBCChildren = tbcChecked => ({
    type: SET_PO_TBC_CHILDREN,
    payload: !!tbcChecked
});
export const setPOTBCLoading = loading => ({
    type: SET_PO_TBC_LOADING,
    payload: loading
});
export const setPOShipModeChildren = modeId => ({
    type: SET_PO_SHIP_MODE_CHILDREN,
    payload: modeId
});
export const setPaymentAndSettlementDefaultValueLoading = loading => ({
    type: SET_PO_PAYMENT_TERMS_AND_SETTLEMENT_DISCOUNT_DEFAULT_VALUE_LOADING,
    payload: loading
});
export const setPOPackTBCChildren = tbcChecked => ({
    type: SET_PO_PACK_TBC_CHILDREN,
    payload: !!tbcChecked
});
export const setPOPackTBCLoading = loading => ({
    type: SET_PO_PACK_TBC_LOADING,
    payload: loading
});
export const setPoOrPackCheckLoading = loading => ({
    type: SET_PO_OR_PACK_CHECK_LOADING,
    payload: loading
});
export const setPONoChildren = poNo => ({
    type: SET_PO_NO_CHILDREN,
    payload: poNo
});
export const setPOSizeSetChildren = sizeSetId => ({
    type: SET_PO_SIZE_SET_CHILDREN,
    payload: sizeSetId
});
export const setPOSizeSetLoading = loading => ({
    type: SET_PO_SIZE_SET_LOADING,
    payload: loading
});
export const setPOExFtyDate = fobDate => ({
    type: SET_PO_EX_FTY_DATE,
    payload: fobDate
});
export const setPackTypeChildren = () => ({
    type: SET_PACK_TYPE_CHILDREN
});
export const resetSizeDetailsOnQuantityOrRatioChange = (
    isRatioChange = false
) => ({
    type: RESET_SIZE_DETAILS_ON_QUANITY_OR_RATIO_CHANGE,
    meta: { isRatioChange }
});
export const watchPORemoved = (mode, payload) => ({
    type: WATCH_PO_REMOVED,
    payload,
    meta: { mode }
});
export const setPOSizeTableQuantityChildren = ({ quantity, field }) => ({
    type: SET_PO_SIZE_TABLE_QUANTITY_CHILDREN,
    payload: quantity,
    meta: { field }
});
export const setPOSizeTableRatioChildren = ({ ratio, field }) => ({
    type: SET_PO_SIZE_TABLE_RATIO_CHILDREN,
    payload: ratio,
    meta: { field }
});
export const setOrderBasicTrimsChildren = labelRef => ({
    type: SET_ORDER_BASIC_TRIMS_CHILDREN,
    payload: labelRef
});
export const setOrderTrimsLoading = loading => ({
    type: ORDER_TRIMS_LOADING,
    payload: loading
});
export const setOrderNoteChildren = commentId => ({
    type: SET_ORDER_NOTE_CHILDREN,
    payload: commentId
});
export const orderPublish = ({ handleComplete, ...payload }) => ({
    type: ORDER_PUBLISH,
    meta: { handleComplete },
    payload
});
export const routeToOrder = mode => ({
    type: ROUTE_TO_ORDER,
    payload: { mode }
});
export const destroyOrderForm = () => ({
    type: DESTROY_ORDER_FORM
});
export const resetOrderForm = () => ({
    type: RESET_ORDER_FORM
});
export const getIncompleteOrderData = orderNo => ({
    type: GET_INCOMPLETE_ORDER_DATA,
    payload: orderNo
});
export const getAmendOrderData = orderNo => ({
    type: GET_AMEND_ORDER_DATA,
    payload: orderNo
});
export const orderStatusChange = ({
    handleComplete,
    isCancel,
    isHold,
    ...payload
}) => ({
    type: ORDER_PUBLISH,
    meta: { handleComplete, isCancel, isHold },
    payload
});
export const routeToAmendOrder = mode => ({
    type: ROUTE_TO_AMEND_ORDER,
    payload: { mode }
});
export const setSameStyleInAllPacks = hasSameStyle => ({
    type: SET_SAME_STYLE_IN_ALL_PACKS,
    payload: hasSameStyle
});
export const generatePOSizeSKU = () => ({
    type: GENERATE_PO_SIZE_SKU
});
export const orderSoftPublish = ({ mode }) => ({
    type: ORDER_PUBLISH,
    meta: { softPublish: true },
    payload: { mode }
});

export const orderReportMailSend = ({
    orderNo,
    orderVersionNo,
    closeModals
}) => ({
    type: ORDER_REPORT_MAIL_SEND,
    meta: { closeModals },
    payload: { orderNo, orderVersionNo }
});
export const setOrderEmailReceivers = groupId => ({
    type: SET_ORDER_EMAIL_RECEIVERS,
    payload: groupId
});
export const setOrderEmailBody = templateId => ({
    type: SET_ORDER_EMAIL_BODY,
    payload: templateId
});

/* Order Data Grid */

export const setOrderCPExportExcelLoading = loading => ({
    type: SET_CP_EXPORT_EXCEL_DATA_LOADING,
    payload: loading
});
