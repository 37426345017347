import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles, Paper } from '@material-ui/core';

import { useCadStatistics } from './hooks';
import Card from '../Card';

const styles = ({ spacing }) => ({
    container: {
        boxShadow: 'none',

        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gridColumnGap: `${spacing.unit * 1.25}px`,
        padding: `${spacing.unit * 4}px ${spacing.unit * 1.5}px`
    }
});

const propTypes = {
    cadStatistics: array.isRequired
};

const CadStatistics = withStyles(styles)(({ classes: c, cadStatistics }) => {
    const cards = useCadStatistics(cadStatistics);
    return (
        <Paper className={c.container}>
            {cards.map((card, idx) => (
                <Card {...card} key={`card-${idx}`} />
            ))}
        </Paper>
    );
});

CadStatistics.propTypes = propTypes;

export { CadStatistics as default, CadStatistics };
