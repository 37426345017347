export const backneckBaseFormatData = data => {
    const baseMap = {
        backneckAutoId: 0,
        backneckSampleRef: null,
        graphicRef: { text: '', title: 'Fabric For', type: 'text' },
        fabricPatchHeight: {
            text: '',
            title: 'Fabric Patch Height',
            type: 'text'
        },
        fabricPatchWidth: {
            text: '',
            title: 'Fabric Patch Width',
            type: 'text'
        },
        backneckPrintHeight: {
            text: '',
            title: 'Backneck Print Height',
            type: 'text'
        },
        backneckPrintWidth: {
            text: '',
            title: 'Backneck Print Width',
            type: 'text'
        },
        fabricDetails: { text: '', title: 'Fabric Details', type: 'text' },
        ink1: { text: '', title: 'Ink1', type: 'text' },
        ink2: { text: '', title: 'Ink2', type: 'text' },
        images: null
    };

    Object.keys(data).forEach((item, index) => {
        if (data[item] && baseMap.hasOwnProperty(item)) {
            if (typeof data[item] === 'string') {
                baseMap[item].text = data[item];
            } else {
                baseMap[item] = data[item];
            }
        }
    });

    return baseMap;
};

export const deserializeBackneckData = data => {
    const reduxApiKey = {
        // redux key : api key
        graphicRef: 'backneckGraphicRef',
        fabricPatchWidth: 'backneckFabricPatchW',
        fabricPatchHeight: 'backneckFabricPatchH',
        fabricDetails: 'backneckFabricDetails',
        backneckPrintHeight: 'backneckPrintH',
        backneckPrintWidth: 'backneckPrintW',
        ink1: 'backneckInc1Details',
        ink2: 'backneckInc2Details',
        images: 'backneckImg'
    };

    const serializedData = Object.splice(data, reduxApiKey);

    for (let item in serializedData) {
        if (serializedData[item] && typeof serializedData[item] === 'object') {
            serializedData[item] = serializedData[item].text;
        }
    }

    serializedData.backneckImg = data.images; //temp solution for local image

    return serializedData;
};
