import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';
import { userIdSelector } from 'auth/redux.selectors';

import { fabricDevelopmentFormSelector } from '../../../redux.selectors';

export default function*() {
    const [
        {
            dateRequested,
            dateRequired,
            originalFabric,
            requiredFabric,
            developedFabric = {},
            dateOfApproved,
            ...values
        },
        userId
    ] = yield all([
        select(
            fabricDevelopmentFormSelector,
            'id',
            'fabricReference',
            'versionNo',
            'developmentType',
            'boughtReference',
            'originalFabric',
            'requiredFabric',
            'hasSameAsOriginal',
            'hasMatchWashAndFinish',
            'hasMatchColor',
            'specialFinish',
            'color',
            'colorReference',
            'comments',
            'requestBy',
            'department',
            'buyer',
            'dateRequested',
            'dateRequired',
            'hasSpeed',
            'hasAvailability',
            'hasSustainability',
            'hangerType',
            'factory',
            'developedFabric',
            'batchNo',
            'recipeNo',
            'yarnCount',
            'yarnLot',
            'yarnSupplier',
            'shrinkageLength',
            'shrinkageWidth',
            'shrinkageSpirality',
            'pillingLength',
            'pillingWidth',
            'pillingMethod',
            'elastaneUnrecoveryLength',
            'elastaneUnrecoveryWidth',
            'dryProcess',
            'approvalStatus',
            'approvedBy',
            'dateOfApproved',
            'approvalComments'
        ),
        yield select(userIdSelector)
    ]);

    return {
        ...renameProperties(
            {
                ...values,
                userId,
                dateRequested: deserializeDate(dateRequested),
                dateRequired: deserializeDate(dateRequired),
                dateApproved: deserializeDate(dateOfApproved)
            },
            {
                developmentType: 'fabricDevTypeSentId',
                boughtReference: 'fabricBoughtSampleReference',
                hasMatchColor: 'isMatchFabricColor',
                hasMatchWashAndFinish: 'isMatchWash',
                specialFinish: 'fabricSpecialFinishId',
                color: 'fabricColorName',
                colorReference: 'fabricColorReference',
                comments: 'fabricComments',
                requestBy: 'fabricRequestedById',
                department: 'fabricDepartmentId',
                buyer: 'fabricBuyerContactPerson',
                hasSpeed: 'isSpeed',
                hasAvailability: 'isAvailability',
                hasSustainability: 'isSustainability',
                hangerType: 'fabricHangerTypeId',
                factory: 'fabricFactoryId',
                batchNo: 'yarnBatchNo',
                recipeNo: 'receipeNo',
                yarnCount: 'fabricYarnCountId',
                yarnLot: 'yarnLotNo',
                yarnSupplier: 'yarnSupplierId',
                pillingMethod: 'fabricPillingMethodId',
                dryProcess: 'fabricDryProcessId',
                approvalStatus: 'fabricApprovalStatusId',
                approvedBy: 'fabricApprovalById',
                approvalComments: 'fabricApprovalComments'
            }
        ),
        originalFabric: renameProperties(originalFabric, {
            fabricType: 'typeId',
            composition: 'compositionId',
            gsm: 'gsmId'
        }),
        requiredFabric: renameProperties(requiredFabric, {
            fabricType: 'typeId',
            composition: 'compositionId',
            gsm: 'gsmId'
        }),
        developedFabric: renameProperties(developedFabric, {
            fabricType: 'typeId',
            composition: 'compositionId',
            gsm: 'gsmId'
        })
    };
}
