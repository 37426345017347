import { takeLatest } from 'redux-saga/effects';

import {
    ADD_LICENSOR,
    ADD_LICENSOR_PROPERTY,
    ADD_CAD_TYPE,
    ADD_ARTWORK_SOURCE
} from 'setup/redux.actionTypes';

import licensorWorker from './licensorWorker.js';
import licensorPropertyWorker from './licensorPropertyWorker.js';
import cadTypeWorker from './cadTypeWorker.js';
import artworkSourceWorker from './artworkSourceWorker.js';

// Watchers
export default function*() {
    yield takeLatest(ADD_LICENSOR, licensorWorker);
    yield takeLatest(ADD_LICENSOR_PROPERTY, licensorPropertyWorker);
    yield takeLatest(ADD_CAD_TYPE, cadTypeWorker);
    yield takeLatest(ADD_ARTWORK_SOURCE, artworkSourceWorker);
}
