import React, { useEffect } from 'react';
import { withStyles, LinearProgress, Paper } from '@material-ui/core';
import { string, object, shape, array } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';

import Backdrop from '@baseComponents/Backdrop';

import { GridViewContextProvider } from './useGridView';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { fetchGridData, gridUpdate } from './redux.actions';
import gridMiddleware from './middleware';
import { gridDataSelector, gridDataLoadingSelector } from './redux.selectors';

import styles from './styles';

const propTypes = {
    entity: shape({
        name: string.isRequired,
        endpoint: string.isRequired
    }),
    config: shape({
        accumulation: object,
        editInfo: shape({
            endpoint: string.isRequired
        }),
        searchableColumns: array.isRequired,
        reportsInfo: object.isRequired,
        tileView: object
    })
};

const reduxPropsSelector = createSelector(
    props => props,
    ({
        entity: { name: entityName, endpoint: entityEndpoint } = {},
        config: {
            accumulation: { show: accuShow = false, moduleName = '' } = {},
            editInfo: { endpoint: editEndpoint, updateDataEndpoint } = {}
        } = {}
    }) => ({
        entityName,
        entityEndpoint,
        editEndpoint,
        accuShow,
        moduleName
    })
);

const mapState = (state, props) => {
    const { entityName } = reduxPropsSelector(props);
    return {
        gridData: gridDataSelector(state, entityName),
        gridLoading: gridDataLoadingSelector(state, entityName)
    };
};

const mapDispatch = (dispatch, props) => {
    const {
        entityName,
        entityEndpoint,
        editEndpoint,
        ...restProps
    } = reduxPropsSelector(props);

    return {
        fetchGridData: (payload = {}) => {
            const hasPayload = !!Object.keys(payload).length;
            dispatch(
                fetchGridData({
                    entity: entityName,
                    endpoint: entityEndpoint,
                    ...(hasPayload ? {} : restProps) // only on mount accu api will be invoked
                })(payload)
            );
        },
        createUpdateRequest: payload =>
            dispatch(
                gridUpdate({
                    entity: entityName,
                    endpoint: editEndpoint,
                    ...restProps
                })(payload)
            )
    };
};

const GridView = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        gridData,
        gridLoading,
        fetchGridData,
        createUpdateRequest,
        FiltersComponent,
        config: { searchableColumns, ...restConfig } = {},
        classes: c,
        ...restProps
    }) => {
        const { data = [], ...footerData } = gridData;
        const props = { ...restConfig, ...restProps };

        useEffect(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            fetchGridData();
        }, []);

        return (
            <GridViewContextProvider
                middleWare={gridMiddleware({
                    fetchGridData,
                    createUpdateRequest
                })}
            >
                {gridLoading && <Backdrop />}
                <Paper className={c.container}>
                    <Header
                        filtersDialog={FiltersComponent}
                        searchableColumns={searchableColumns}
                    />
                    {gridLoading && (
                        <LinearProgress
                            classes={{
                                root: c.progress,
                                barColorPrimary: c.progressPrimary
                            }}
                        />
                    )}
                    <Content data={data} gridLoading={gridLoading} {...props} />
                    {data.length > 0 && <Footer {...footerData} />}
                </Paper>
            </GridViewContextProvider>
        );
    }
);

GridView.propTypes = propTypes;

export { GridView, GridView as default };
