import React from 'react';

const PreviewIcon = ({ color: fill = 'rgba(0, 0, 0, 0.87)', ...restProps }) => {
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            fill={fill}
            {...restProps}
        >
            <path d='M256.49,420.08c101.67-4.49,181.82-50.36,237.85-136.18,11.59-17.73,11.59-38.54-.24-56.74a259.89,259.89,0,0,0-15.6-22.47c-57.45-72.82-136.42-112.77-222-112.77C161.68,91.92,72.78,142,18.4,226.21c-12.29,19.15-12.53,39.72-.71,57.69,56,85.59,135.71,131.46,236.91,136.18h1.89ZM163.57,184.83c.47-.23,39-19.62,93.86-19.62a222.65,222.65,0,0,1,91.5,19.62c31.92,16.79,58.17,40,80.39,70.93-48,62.66-104.27,93.39-171.89,93.87H256c-68.1,0-124.6-30.74-172.84-93.87C105.41,224.79,131.89,201.62,163.57,184.83Z' />
            <circle cx='254.6' cy='256' r='72.11' />
        </svg>
    );
};

export { PreviewIcon as default, PreviewIcon };
