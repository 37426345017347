export const FETCH_SAMPLE_REFERENCES = 'fetch_sample_references';
export const SET_SAMPLE_REFERENCES = 'set_sample_references';

export const FETCH_SAMPLE_DATA = 'fetch_sample_data';
export const SET_SAMPLE_DATA = 'set_sample_data';

export const FETCH_REF_FOR_AMEND_REQUEST = 'get_amend_incomplete_ref';
export const FETCH_REF_FOR_AMEND_SUCCESS = 'get_amend_incomplete_ref_success';
export const FETCH_REF_FOR_AMEND_FAILURE = 'get_amend_incomplete_ref_failure';

export const FETCH_SAMPLE_INFO_REQUEST = 'get_sample_info';
export const FETCH_SAMPLE_INFO_SUCCESS = 'get_sample_info_success';
export const FETCH_SAMPLE_INFO_FAILURE = 'get_sample_info_failure';
