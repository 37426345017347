import React, { useState } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { ContentContainer } from '@libComponents/ContentContainer';
import Image from '../../GridImage/Image';
import Adornment from './Adornment';
import { useLoadedImage } from '../../../hooks';
import Slider from '../../GridImage/Slider';

const headerStyles = ({ spacing }) => ({
    header: {
        height: spacing.unit * 5.5,
        padding: 0,
        paddingLeft: spacing.unit * 1
    },
    title: {
        fontSize: '11px'
    }
});

const rootStyles = ({ palette, spacing, shadows }) => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: spacing.unit * 25,
        boxShadow: shadows[0],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRadius: 0
    }
});

const frameStyles = {
    frame: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const loaderStyles = ({ palette, spacing }) => ({
    loader: {
        width: '100%',
        minHeight: spacing.unit * 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: fade(palette.secondary.main, 0.3),
        color: palette.primary.light
    }
});

const imageStyles = {
    image: {
        width: '100%',
        cursor: 'pointer'
    }
};

const styles = theme => ({
    ...headerStyles(theme),
    ...rootStyles(theme),
    ...frameStyles,
    ...loaderStyles(theme),
    ...imageStyles
});

const Card = withStyles(styles)(({ item, itemIndex, classes: c }) => {
    const [show, setShow] = useState(false);
    const [reference, { value: image }, { value: id }] = item;
    const { size350, imageVersion } = image[0] || {};
    const loadedImage = !!size350 ? useLoadedImage({ image: size350 }) : true;
    return (
        <ContentContainer
            title={`${reference.header}: ${reference.value}`}
            styles={{ container: c.root, header: c.header, title: c.title }}
            AdornmentComponent={<Adornment id={id} itemIndex={itemIndex} />}
        >
            {loadedImage ? (
                <>
                    <div className={c.frame}>
                        <Image
                            url={size350}
                            filename={imageVersion}
                            className={c.image}
                            onClick={() => setShow(true)}
                        />
                    </div>
                    {show && (
                        <Slider
                            sliderData={image}
                            onClose={() => setShow(false)}
                        />
                    )}
                </>
            ) : (
                <div className={c.loader}>
                    <CircularProgress color='inherit' size={55} thickness={2} />
                </div>
            )}
        </ContentContainer>
    );
});

export default Card;
