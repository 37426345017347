import { useEffect, useState, useCallback } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';
import getDataUrl from '@libs/getDataUrl';
import Report from './Report';
import TileReport from './TileReport';
import { useGridViewState } from 'gridView/useGridView';

const loadProps = async rows => {
    // Preparing datasets that will be loaded for pdf
    const preparedRows = rows.map(row => {
        return row.reduce(
            (result, currentItem) => {
                // If the item has an 'image' property, add it to the imageArray
                if (currentItem.type === 'image') {
                    result.images.push({
                        header: currentItem.header,
                        url: currentItem.value
                    });
                } else {
                    // Otherwise, add the non-image item to the result array
                    if (
                        result.columns.length === 0 ||
                        result.columns[result.columns.length - 1].length === 10
                    ) {
                        // Start a new chunk when needed
                        result.columns.push([currentItem]);
                    } else {
                        // Add the item to the current chunk
                        result.columns[result.columns.length - 1].push(
                            currentItem
                        );
                    }
                }
                return result;
            },
            { images: [], columns: [] }
        );
    });

    // handle async call
    const loadedProps = await Promise.all(
        preparedRows.map(async ({ images, columns }) => {
            const loadedImages = await Promise.all(
                images.map(async ({ url, ...restInfo }) => {
                    const dataURL = !!url ? await getDataUrl(url) : undefined;
                    return {
                        url:
                            dataURL && dataURL.includes('data')
                                ? dataURL
                                : undefined,
                        ...restInfo
                    };
                })
            );
            return { images: loadedImages, columns };
        })
    );

    // Each pdf page there will be 3 items.
    const pages = [...Array(Math.ceil(loadedProps.length / 3))];
    const itemPage = pages.map((page, idx) =>
        loadedProps.slice(idx * 3, idx * 3 + 3)
    );

    return { pages: itemPage };
};

const loadTileProps = async rows => {
    // Preparing datasets that will be loaded for tile pdf
    const preparedRows = rows.map(row => {
        return row.reduce(
            (accu, { header, value, type }) => {
                const { images } = accu;
                if (type === 'image') {
                    return {
                        ...accu,
                        images: [...images, value]
                    };
                } else {
                    if (header.includes('REFERENCE')) {
                        return {
                            ...accu,
                            reference: value
                        };
                    }
                    return accu;
                }
            },
            { images: [], reference: null }
        );
    });

    // handle async call
    const loadedProps = await Promise.all(
        preparedRows.map(async ({ images, reference }) => {
            const loadedImages = await Promise.all(
                images.map(async image =>
                    !!image ? await getDataUrl(image) : undefined
                )
            );
            return { images: loadedImages, reference };
        })
    );
    return { data: loadedProps };
};

export const usePdfReport = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);
    const { viewType } = useGridViewState();
    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document: viewType === 'list' ? Report : TileReport,
        fileName,
        props,
        loadProps: viewType === 'list' ? loadProps : loadTileProps,
        errorResolver: error => setPDFError(error),
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleExport, exportLoading, exportDisabled];
};
// Use only for testing pdf viewer.
export const usePDFProps = props => {
    const [propsReady, setPropsReady] = useState(false);
    const [loadedProps, setLoadedProps] = useState(null);
    const handleLoadProps = useCallback(async () => {
        try {
            const loadedProps = await loadProps(props);
            setLoadedProps(loadedProps);
            setPropsReady(true);
        } catch (e) {
            console.error(e);
        }
    }, [props]);
    useEffect(() => {
        if (props !== null) handleLoadProps();
    }, [props]);
    return [propsReady, loadedProps];
};
