import React, { useMemo } from 'react';
import { PieChart } from '@libComponents/Charts';
import ContentContainer from '@libComponents/ContentContainer';

import Legend from './LegendContainer';
import AdornmentComponent from './AdornmentComponent';
import useDynamicColors from '../../../hooks';

const BreakdownChart = ({ chartName, data, cellSettings, ...props }) => {
    const visibleCells = useMemo(
        () => data.filter(cell => cellSettings[cell.name] === undefined),
        [data, cellSettings]
    );
    const colors = useDynamicColors(visibleCells);

    return (
        <ContentContainer
            title={chartName}
            AdornmentComponent={<AdornmentComponent chartName={chartName} />}
        >
            <PieChart
                data={visibleCells}
                colors={colors}
                legendRenderer={Legend}
                layout='vertical'
                align='right'
                verticalAlign='middle'
                {...props}
            />
        </ContentContainer>
    );
};

export default BreakdownChart;
