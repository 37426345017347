import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { string } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
//import { Image } from '@libComponents/ReactPDF';

const styles = ({ spacing, palette }) => ({
    container: {
        width: '46%'
    },
    imageWrapper: {
        height: spacing.unit * 12,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    },
    label: {
        color: palette.secondary.light,
        fontWeight: 500,
        fontSize: spacing.unit * 1.5,
        padding: spacing.unit * 0.5
    }
});

const propTypes = {
    url: string,
    name: string
};

const BasicContentContainer = withPDFStyles(styles)(
    ({ classes: c, url, name }) => {
        return (
            <View style={c.container}>
                <View style={c.imageWrapper}>
                    {url && <Image src={url} style={c.image} />}
                </View>
                <Text style={c.label}>{name}</Text>
            </View>
        );
    }
);

BasicContentContainer.propTypes = propTypes;

export default BasicContentContainer;
