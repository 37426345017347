import React from 'react';
import { bool } from 'prop-types';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

const propTypes = {
    loading: bool
};

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3,
        width: '100%'
    }
});

const Adornment = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing }, loading }) => {
        return (
            <div className={c.root}>
                {loading ? (
                    <CircularProgress size={spacing.unit * 4} />
                ) : (
                    <div />
                )}
            </div>
        );
    }
);

Adornment.propTypes = propTypes;

export default Adornment;
