import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { change } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { setSnack } from 'utilities/redux.actions';
import { FILTERS_FORM_NAME } from 'common/common';
import { userIdSelector } from 'auth/redux.selectors';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { MODULE_NAME as CAD_MODULE_NAME } from 'cadGridView/common';
import { AMEND_PRINT_ROUTE_ID } from 'sampling/Print/common';
import { AMEND_SHAPE_ROUTE_ID } from 'sampling/Shape/common';

import { getAmendData } from 'cadUpload/redux.actions';
import { getAmendPrintData } from 'sampling/redux.actions';
import { getAmendShapeData } from 'sampling/redux.actions';

import {
    FETCH_GRID_DATA,
    GRID_DATA_UPDATE,
    GRID_EXCEL_EXPORTING,
    GRID_PDF_PAGE_EXPORTING,
    FETCH_ACCUMULATED_DATA,
    SET_GRID_FILTER_RETAILER_CHILDREN,
    SET_GRID_FILTER_PROPERTY_VALUE,
    FETCH_PREVIEW_DATA,
    GRID_ALL_COLUMNS_EXCEL_EXPORTING,
    GRID_EDIT_HANDLE,
    GRID_ALL_ROWS_EXCEL_EXPORTING
} from '../redux.actionTypes';
import {
    setGridData,
    setGridLoading,
    setAccumulatedData
} from '../redux.actions';
import {
    fetchAccumulatedDataWorker,
    fetchAccumulatedData,
    gridUpdateWorker,
    excelExportingWorker,
    gridPdfPageExportingWorker,
    fetchPreviewDataWorker,
    excelAllColumnsExportingWorker,
    exportAllRowsExportingWorker
} from './workers';
import { rowRefByIdSelector } from '../redux.selectors';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';

const setGridFilterRetailerChildrenWorker = function*() {
    yield put(change(FILTERS_FORM_NAME, 'department', ''));
    yield put(change(FILTERS_FORM_NAME, 'groupId', ''));
};

const setGridFilterPropertyValueWorker = function*() {
    yield put(change(FILTERS_FORM_NAME, 'property', ''));
};

const gridEditHandle = function*({ payload: { viewName, id } }) {
    const redirectData = {
        [CAD_MODULE_NAME]: {
            entity: '@cadLists',
            referenceColumn: 'cadReference',
            routeId: 271,
            fetchDataByRef: getAmendData
        }
    };
    if (redirectData[viewName]) {
        yield put(setRedirect(true));
        const reference = yield select(
            rowRefByIdSelector,
            redirectData[viewName].entity,
            id,
            redirectData[viewName].referenceColumn
        );
        yield put(redirectToRoute(redirectData[viewName].routeId));
        yield put(redirectData[viewName].fetchDataByRef(reference));
    }
};

//Grid data fetch worker
function* fetchGridData(action) {
    const {
        meta: { entity, endpoint, accuShow, moduleName },
        payload
    } = action;

    yield put(setGridLoading({ entity, loading: true }));
    const userId = yield select(userIdSelector);

    try {
        const {
            gridData: { data },
            accumulation
        } = yield all({
            gridData: call(apiRequest, {
                url: endpoint,
                params: { ...payload, userId }
            }),
            ...(accuShow
                ? { accumulation: call(fetchAccumulatedData, moduleName) }
                : { accumulation: null })
        });
        let { data: dataset, ...restResponse } = data;

        if (accumulation) {
            yield put(setAccumulatedData({ moduleName, accumulation }));
        }

        yield put(
            setGridData({
                entity,
                dataset,
                ...restResponse
            })
        );
        yield put(setGridLoading({ entity, loading: false }));
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(setGridLoading({ entity, loading: false }));
        yield put(
            setSnack({
                message: `Failed to retrieve ${serverMessage}`,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
        console.log(`Error while fetching ${entity} resources`);
    }
}

// Watchers
export default function*() {
    yield takeLatest(FETCH_GRID_DATA, fetchGridData);
    yield takeLatest(FETCH_ACCUMULATED_DATA, fetchAccumulatedDataWorker);
    yield takeLatest(GRID_DATA_UPDATE, gridUpdateWorker);
    yield takeLatest(GRID_EXCEL_EXPORTING, excelExportingWorker);
    yield takeLatest(GRID_PDF_PAGE_EXPORTING, gridPdfPageExportingWorker);
    yield takeLatest(
        SET_GRID_FILTER_RETAILER_CHILDREN,
        setGridFilterRetailerChildrenWorker
    );
    yield takeLatest(
        SET_GRID_FILTER_PROPERTY_VALUE,
        setGridFilterPropertyValueWorker
    );
    yield takeLatest(FETCH_PREVIEW_DATA, fetchPreviewDataWorker);
    yield takeLatest(
        GRID_ALL_COLUMNS_EXCEL_EXPORTING,
        excelAllColumnsExportingWorker
    );
    yield takeLatest(
        GRID_ALL_ROWS_EXCEL_EXPORTING,
        exportAllRowsExportingWorker
    );
    yield takeLatest(GRID_EDIT_HANDLE, gridEditHandle);
}
