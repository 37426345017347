import { useContext } from 'react';
import { useStepperState } from '../../../StepContext';

const useProgress = () => {
    const { activeStep, contents } = useStepperState();

    const amountOfSteps = contents.length;

    if (amountOfSteps) {
        const progressFraction = (activeStep + 1) / amountOfSteps;
        return progressFraction * 100;
    } else return 0;
};

export { useProgress };
