import React from 'react';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

import SelectRetailer from './SelectRetailer';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: spacing.unit * 28,
        backgroundColor: 'white'
    },
    container: {
        paddingBottom: 0
    }
});

const RetailerBreakdownSelect = withStyles(styles)(
    ({ classes: c, className }) => {
        const selectProps = {
            label: '',
            styles: { container: c.container }
        };
        return (
            <div className={clsx(c.root, className)}>
                <SelectRetailer {...selectProps} />
            </div>
        );
    }
);

export default RetailerBreakdownSelect;
