export const DOMAIN_NAME = 'common';
export const FILTERS_FORM_NAME = 'filters';
export const MAIL_FORM_NAME = 'mail';
export const MAIL_SENDER_ADDRESS = 'echodb@echosourcing.com';
export const NO_IMAGE_URL =
    'https://echouk-db-test.s3.eu-west-2.amazonaws.com/UploadControl/UploadImages/Cad/2021/Aug/Cad_100_100/EG-10195/EG-10195_V1.png';
export const ASSET_BASE_URL = {
    local: 'https://echouk-db-test.s3.eu-west-2.amazonaws.com/',
    development: 'https://echouk-db-test.s3.eu-west-2.amazonaws.com/',
    production: 'https://echouk-db-pd.s3.eu-west-2.amazonaws.com/'
};
