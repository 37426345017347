export default (
    { inputValue, name, selectedValue, value } = {},
    selectValueLast = false
) => {
    if (inputValue || value) {
        if (selectValueLast)
            return `${inputValue || value || ''}${selectedValue || name || ''}`;
        return `${selectedValue || name || ''}${inputValue || value || ''}`;
    }
    return '';
};
