import cadUploadRoutes from '../cadUpload/routes';
import samplingRoutes from '../sampling/routes';
import cadGridView from '../cadGridView/routes';
import cadDesignerReport from '../cadDesignerReport/routes';
import ordersRoutes from '../orders/routes';
import home from '../home/routes';
import qualityControl from '../qualityControl/routes';
import setup from '../setup/routes';
import administrator from '../administrator/routes';
import fabric from '../fabric/routes';

export default {
    ...cadUploadRoutes,
    ...samplingRoutes,
    ...cadGridView,
    ...cadDesignerReport,
    ...ordersRoutes,
    ...home,
    ...qualityControl,
    ...setup,
    ...administrator,
    ...fabric
};
