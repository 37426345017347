import { formValueSelector } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import isBlobUrl from '@libs/isBlobUrl';
import composeApiPayload from '@libs/composeApiPayload';
import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { apiRequest } from '@libs/apiRequest';
import { sizePathsSelector } from 'common/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';
import { userNameSelector } from 'auth/redux.selectors';
import { MAIL_FORM_NAME } from 'common/common';
import { setLoading } from 'utilities/redux.actions';
import { setSnack } from 'utilities/redux.actions';

import { dispatchEmailSchema } from './publishWorker/schema';

export default function*(action) {
    try {
        const {
            payload: { dispatchRef, versionNo },
            meta: { closeModals }
        } = action;

        yield put(setLoading(true));

        const userName = yield select(userNameSelector);

        const {
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body,
            report
        } = yield select(
            formValueSelector(MAIL_FORM_NAME),
            'receivers',
            'cc',
            'subject',
            'body',
            'report'
        );

        let PDFURL = report.url;

        if (isBlobUrl(PDFURL)) {
            const [reportBlobs, reportSizePaths] = yield all([
                call(getAssetBlobsWorker, {
                    assets: report,
                    mode: 'new',
                    handleLoading: f => f
                }),
                select(sizePathsSelector, 'reportPath')
            ]);

            const [reportPayload] = yield call(uploadAssetsWorker, {
                assetBlobs: reportBlobs,
                folderPath: `${reportSizePaths.sizeOriginal}/${dispatchRef}`,
                reference: dispatchRef,
                sizePaths: reportSizePaths,
                handleLoading: f => f,
                developmentName: `V${versionNo}`
            });

            const reportPath = reportPayload ? reportPayload.sizeOriginal : '';
            PDFURL = yield call(syncReportToDatabase, {
                reportReference: dispatchRef,
                reportUrl: reportPath,
                referenceDevNo: versionNo
            });
        }

        const emailPayload = composeApiPayload(
            {
                userName,
                toAddress: toAddress.replace(/\s/g, ''),
                ccAddress,
                subject,
                body,
                dispatchRef,
                attachment: PDFURL
            },
            dispatchEmailSchema
        );

        yield call(apiRequest, {
            method: 'post',
            url: 'EmailSend/DispatchEmailSend',
            data: emailPayload
        });

        if (closeModals) {
            closeModals();
        }

        yield put(setLoading(false));

        yield put(
            setSnack({
                message: 'Email request received. It will be sent soon.',
                type: 'success',
                duration: 8000
            })
        );
    } catch (error) {
        yield put(setLoading(false));

        yield put(
            setSnack({
                message: 'Failed to send email',
                type: 'error',
                duration: 8000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
