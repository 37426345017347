import { select, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
    startSubmit,
    setSubmitSucceeded,
    stopSubmit,
    formValueSelector
} from 'redux-form';
import storage from 'redux-persist/lib/storage';

// Libs
import apiRequest from '@libs/apiRequest';
import handleJWT from '@libs/handleJWT';
import { history } from 'app';
import { eraseAllCookies } from '@libs/cookies';
import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute } from 'navigation/redux.actions';

// Local
import {
    LOGIN,
    SET_AUTH_DATA,
    LOGOUT,
    FORGOT_PASSWORD_EMAIL_SEND,
    CHANGE_PASSWORD,
    VERIFY_OTP,
    RESEND_OTP
} from '../redux.actionTypes';
import { uidSelector } from '../redux.selectors';
import { VERIFY_OTP_ROUTE } from '../constant';
import { sendOTPLoading } from '../redux.actions';
// Workers

function* loginWorker({ payload }) {
    const formName = 'Login';
    try {
        yield put(startSubmit(formName));
        const {
            data: { data: { need2FA, uid, ...auth } = {} } = {}
        } = yield call(apiRequest, {
            url: 'User/GetUserByLogin',
            method: 'get',
            params: payload,
            withAuthToken: false
        });

        yield put(stopSubmit(formName));
        yield put(setSubmitSucceeded(formName));

        if (need2FA) {
            yield put({
                type: SET_AUTH_DATA,
                payload: { uid }
            });
            yield fork(otpSendWorker);
            history.push(VERIFY_OTP_ROUTE);
        } else {
            yield put({
                type: SET_AUTH_DATA,
                payload: yield call(handleJWT, auth)
            });
        }
    } catch (error) {
        if (error.response) {
            const { status } = error.response;
            if (status === 401) {
                yield put(
                    stopSubmit(formName, {
                        _error: 'The username or password is incorrect!'
                    })
                );
            } else {
                yield put(
                    stopSubmit(formName, {
                        _error: 'Something went wrong! Please try again later.'
                    })
                );
            }
        } else {
            yield put(
                stopSubmit(formName, {
                    _error:
                        'Something went wrong Or you are in a restricted country, please contact Echosys admin'
                })
            );
        }
    }
}

function* logoutWorker() {
    eraseAllCookies();
    yield storage.removeItem('persist:root');
}

function* forgotPasswordEmailSendWorker({ payload }) {
    const formName = 'ForgetPassword';
    try {
        yield put(startSubmit(formName));
        yield call(apiRequest, {
            url: 'User/ForgetPasswordandSendEmail',
            method: 'get',
            params: payload,
            withAuthToken: false
        });
        yield put(stopSubmit(formName));
        yield put(setSubmitSucceeded(formName));
    } catch ({ response: { status } = {} }) {
        yield put(
            stopSubmit(formName, {
                _error: 'Something went wrong! Please try again later.'
            })
        );
    }
}

function* changePasswordWorker() {
    const formName = 'ChangePassword';
    try {
        const payload = yield select(
            formValueSelector(formName),
            'email',
            'token',
            'password'
        );
        yield put(startSubmit(formName));
        const {
            data: { data: { need2FA, uid, ...auth } = {} } = {}
        } = yield call(apiRequest, {
            url: 'User/ChangePasswordandLogin',
            method: 'get',
            params: payload,
            withAuthToken: false
        });
        yield put(stopSubmit(formName));
        yield put(setSubmitSucceeded(formName));
        yield put({
            type: SET_AUTH_DATA,
            payload: yield call(handleJWT, auth)
        });
        yield put(redirectToRoute(1)); // redirect to home
    } catch ({ response: { status } = {} }) {
        if (status === 404) {
            yield put(
                stopSubmit(formName, {
                    _error: 'Invalid email or token!'
                })
            );
        } else {
            yield put(
                stopSubmit(formName, {
                    _error: 'Something went wrong! Please try again later.'
                })
            );
        }
    }
}

function* otpSendWorker() {
    try {
        const uid = yield select(uidSelector);
        if (!uid) throw 'No identity found!!';

        yield put(sendOTPLoading(true));
        yield call(apiRequest, {
            url: 'User/DoubleAuthenticationandSendEmail',
            method: 'get',
            params: { uid },
            withAuthToken: false
        });
        yield put(sendOTPLoading(false));
    } catch (error) {
        const { response: { data: { ErrorMessage } = {} } = {} } = error;
        const message = typeof error === 'string' ? error : ErrorMessage;
        yield put(
            setSnack({
                message: message || 'An unexpected error occurred.',
                type: 'error',
                duration: 6000
            })
        );
        yield put(sendOTPLoading(false));
    }
}

function* verifyOTPWorker({ payload: token }) {
    const formName = 'VerifyOTP';
    try {
        yield put(startSubmit(formName));
        const uid = yield select(uidSelector);
        const { data: { data: authData } = {} } = yield call(apiRequest, {
            url: 'User/DoubleAuthenticationCheck',
            method: 'get',
            params: { uid, token },
            withAuthToken: false
        });
        yield put(stopSubmit(formName));
        yield put(setSubmitSucceeded(formName));
        yield put({
            type: SET_AUTH_DATA,
            payload: yield call(handleJWT, authData)
        });
        yield put(redirectToRoute(1)); // redirect to home
    } catch ({ response: { status } = {} }) {
        if (status === 401) {
            yield put(
                stopSubmit(formName, {
                    _error: 'Invalid OTP!'
                })
            );
        } else {
            yield put(
                stopSubmit(formName, {
                    _error: 'Something went wrong! Please try again later.'
                })
            );
        }
    }
}

// Watchers
export default function*() {
    yield takeLatest(LOGIN, loginWorker);
    yield takeLatest(LOGOUT, logoutWorker);
    yield takeLatest(FORGOT_PASSWORD_EMAIL_SEND, forgotPasswordEmailSendWorker);
    yield takeLatest(CHANGE_PASSWORD, changePasswordWorker);
    yield takeLatest(RESEND_OTP, otpSendWorker);
    yield takeLatest(VERIFY_OTP, verifyOTPWorker);
}
