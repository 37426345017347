import React from 'react';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';

import { withStyles, Grid, Divider } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { AttachmentDetails } from 'components';
import { GridItem, Input } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { setReloadAction } from 'utilities/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';

import { FORM_NAME, LABELS } from '../../common';
import { useSealSampleState } from '../../useSealSample';
import HeaderAdornment from '../HeaderAdornment';
import SampleReviewSummary from './SampleReviewSummary';

const styles = ({ spacing }) => ({
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 5
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 8
    },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 1.5,
        marginBottom: spacing.unit * 3
    }
});

const mapInputs = {
    attachments: ({ payload }) => {
        const shouldPrompt = payload.some(
            attachment =>
                attachment.hasOwnProperty('url') && isBlobUrl(attachment.url)
        );

        const nonBlobAttachments = payload.filter(
            attachment =>
                !(attachment.hasOwnProperty('url') && isBlobUrl(attachment.url))
        );
        const action = change(FORM_NAME, 'attachments', nonBlobAttachments);
        return setReloadAction({ field: 'attachments', shouldPrompt, action });
    }
};

const Other = compose(
    withStyles(styles),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs)
)(({ classes: c }) => {
    const { isQA } = useSealSampleState();

    return (
        <ContentContainer
            title={LABELS.other}
            AdornmentComponent={<HeaderAdornment />}
        >
            <Grid container className={c.container}>
                {isQA && (
                    <GridItem md={12}>
                        <SampleReviewSummary />
                        <Divider className={c.divider} />
                    </GridItem>
                )}
                <GridItem md={6}>
                    <Grid container>
                        <GridItem md={12}>
                            <AttachmentDetails />
                        </GridItem>
                    </Grid>
                </GridItem>

                {isQA && (
                    <GridItem md={6}>
                        <Grid container>
                            <GridItem md={12}>
                                <Input
                                    name='remarks'
                                    label='Remarks'
                                    placeholder='Write comments here'
                                    multiline
                                    rowsMax={4}
                                    styles={{ root: c.inputRoot }}
                                />
                            </GridItem>
                        </Grid>
                    </GridItem>
                )}
            </Grid>
        </ContentContainer>
    );
});

export default Other;
