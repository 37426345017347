import {
    UPDATE_SAMPLING_PRINT_FORM,
    UPDATE_SAMPLING_REQUEST_FORM
} from 'store/actionTypes';

export const updateSamplingPrintForm = values => ({
    type: UPDATE_SAMPLING_PRINT_FORM,
    payload: { values }
});

export const updateSamplingRequestForm = values => ({
    type: UPDATE_SAMPLING_REQUEST_FORM,
    payload: { values }
});
