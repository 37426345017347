import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Lib
import { GridItem, Input } from '@formComponents';

// Local
import CommentContainer from './CommentContainer';

const styles = ({ spacing }) => ({
    container: {
        marginBottom: spacing.unit * 5
    },
    commentContainer: {
        padding: `${spacing.unit * 1}px ${spacing.unit * 1}px`
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 8
    }
});

const ProductRiskAnalysis = withStyles(styles)(({ classes: c }) => {
    return (
        <CommentContainer
            title={'Product Risk Analysis'}
            className={c.container}
        >
            <Grid container className={c.commentContainer}>
                <GridItem md={6}>
                    <Input
                        name='fabricCTQ'
                        label='Fabric CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='embellishmentCTQ'
                        label='Embellishment CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='accTrimsCTQ'
                        label='Acc & trims CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='CPRTWashCTQ'
                        label='CPRT & Wash CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='cuttingCTQ'
                        label='Cutting CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='finishingPackingCTQ'
                        label='Finishing & Packing CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='sewingCTQ'
                        label='Sewing CTQ'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='bulkProductionComplicacy'
                        label='Bulk Production Complicacy'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
            </Grid>
        </CommentContainer>
    );
});

export default ProductRiskAnalysis;
