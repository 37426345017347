import GreenTick from 'components/Global/assets/img/green-tick.svg';

export default theme => ({
    loader: {
        color: '#ffffff',
        position: 'absolute'
    },
    gridContainer: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.md,
        margin: 0
    },
    footer: {
        padding: 20
    },
    inputTextField: {
        height: '32px',
        fontSize: '.875rem',
        padding: '6px 15px 7px 20px',
        border: '1px solid  #e6e6e6',
        borderRadius: 4,
        marginTop: -6
    },
    disabledTextField: {
        height: '45px',
        fontSize: '.875rem',
        padding: '6px 0 7px 20px;',
        border: '1px solid  #e6e6e6',
        borderRadius: 4,
        marginTop: '10px !important',
        backgroundColor: '#f5f5f5'
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px',
        '&::after': {
            content: `url(${GreenTick})`,
            marginLeft: 15,
            display: 'none'
        }
    },
    inputHasSuccess: {
        borderColor: '#8fc255',
        '&::after': {
            display: 'inline-block'
        },
        '& + div > div': {
            borderColor: '#8fc255'
        }
    },
    textField: {
        marginBottom: theme.spacing.unit * 2.5
    },
    textFieldCollection: {
        marginBottom: 0,
        marginTop: 10
    },
    textArea: {
        border: '1px solid #e6e6e6',
        borderRadius: '4px',
        padding: '6px 15px 7px',
        marginTop: -12,
        minHeight: 129,
        marginBottom: 13,
        fontSize: '.875rem'
    },
    cancelButton: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        width: '100%',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    },
    mainTitle: {
        marginTop: '20px',
        marginBottom: '5px',
        fontWeight: '500'
    },
    disabledCheckboxLabel: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    nextButton: {
        boxShadow: 'none',
        height: '38px',
        color: 'white',
        display: 'block',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&:active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        }
    },
    nextSelectButton: {
        height: '45px',
        marginTop: '10px',
        marginLeft: '10px'
    },
    plusButton: {
        height: '45px',
        marginTop: '10px',
        marginLeft: '10px'
    },
    separatorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > div': {
            flexGrow: 1
        }
    },
    flexGrowOne: {
        flexGrow: '1'
    },
    dropdownContainer: {
        '& > div': {
            flexGrow: 1
        }
    },
    connectorActive: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#8fc255',
            borderTopWidth: '4px'
        }
    },
    connectorCompleted: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#8fc255',
            borderTopWidth: '4px'
        }
    },
    connectorDisabled: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#cccccc',
            borderTopWidth: '4px'
        }
    },
    connectorLine: {
        transition: theme.transitions.create('border-color')
    },
    visitedStep: {
        '& $connectorLine': {
            borderColor: '#8fc255' //#c2dca7
        }
    },
    stepLabelContainer: {
        maxWidth: '300px'
    },
    firstStep: {
        display: 'none'
    },
    stepRoot: {
        color: '#cccccc'
    },
    stepActive: {
        color: '#8fc255 !important'
    },
    stepCompleted: {
        color: '#8fc255 !important',
        backgroundColor: '#ffffff'
    },
    multiLabel: {
        position: 'relative',
        zIndex: 2
    },
    multiLabelVisited: {
        cursor: 'pointer !important',
        '& svg': {
            color: '#8fc255 !important'
        }
    },
    root: {
        cursor: 'pointer !important',
        padding: '24px 0px'
    },
    paperRoot: {
        boxShadow: 'none',
        border: 'none'
    },
    dialogsTitle: {
        padding: '16px 24px'
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: 34,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))'
    },
    browseButtonPlaceholder: {
        lineHeight: '80px',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#aeaeae',
        fontSize: '1rem'
    },
    browseButtonRoot: {
        border: '1px solid #e6e6e6',
        height: 35,
        borderRadius: 4,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex'
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 35,
        lineHeight: '35px',
        flexGrow: 1,
        paddingLeft: 15,
        '& span:not(:first-child):before': {
            content: '", "'
        }
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: 45,
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    },
    labelFileField: {
        '& + div': {
            display: 'none'
        }
    },
    paddingTopNone: {
        paddingTop: '0 !important'
    },
    dialogActionsRoot: {
        margin: 0,
        padding: 0
    },
    actionsGridContainer: {
        margin: 0
    },
    editorLabel: {
        height: '35px',
        fontWeight: 'bold',
        lineHeight: '35px',
        marginBottom: '10px'
    }
});

