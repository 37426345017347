import { select, put, all } from 'redux-saga/effects';
import { change, formValueSelector } from 'redux-form';

import isBlobUrl from '@libs/isBlobUrl';

import { FORM_NAME } from '../../common';
import { developmentTypeSelector } from '../../redux.selectors';

import getDevelopmentTypeId from './getDevelopmentTypeId';
import { developmentTypesSelector } from 'common/redux.selectors';

export default function*({ mode, versions, newVersions }) {
    const [
        developmentType,
        developmentTypes,
        { contents = [] } = {}
    ] = yield all([
        select(developmentTypeSelector),
        select(developmentTypesSelector, 'CAD'),
        select(formValueSelector(FORM_NAME), 'stepper')
    ]);

    const developmentTypeId = getDevelopmentTypeId(
        developmentTypes,
        developmentType,
        mode
    );

    const unchangedVersions = versions.filter(
        version => !isBlobUrl(version.url)
    );

    yield put(change(FORM_NAME, 'developmentType', developmentTypeId));
    yield put(
        change(FORM_NAME, 'versions', [...unchangedVersions, ...newVersions])
    );
    if (contents.length)
        yield put(change(FORM_NAME, 'stepper', { activeStep: 1, contents }));
}
