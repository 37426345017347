import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { array, number, bool } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Title } from 'components/PDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

import Header from './Header';
import Row from './Row';

const styles = ({ spacing, palette }) => ({
    container: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    },
    totalContainer: {
        width: '100%',
        flexDirection: 'row',
        fontSize: spacing.unit * 1.5,
        fontFamily: FONT_FAMILY_BOLD,
        margin: spacing.unit * 0.5
    },
    totalText: {
        width: '88%',
        textAlign: 'right'
    },
    totalValue: {
        marginLeft: spacing.unit * 0.5
    }
});

const propTypes = {
    data: array.isRequired,
    totalQuantity: number.isRequired,
    isSeasalt: bool.isRequired
};

const DispatchDetails = withPDFStyles(styles)(
    ({ classes: c, data, totalQuantity, isSeasalt }) => {
        const dataLength = data.length;
        return (
            <View>
                <Title text={'Dispatch Details'} />
                <View style={c.container}>
                    <Header isSeasalt={isSeasalt} />
                    {data.map((detail, idx) => {
                        return (
                            <Row
                                {...detail}
                                key={`dispatch-details-pdf-row-${idx}`}
                                isLast={idx === dataLength - 1}
                                isSeasalt={isSeasalt}
                            />
                        );
                    })}
                </View>
                <View style={c.totalContainer}>
                    <Text
                        style={{
                            ...c.totalText,
                            ...(isSeasalt ? { width: '89%' } : {})
                        }}
                    >
                        Total:
                    </Text>
                    <Text style={c.totalValue}> {totalQuantity}</Text>
                </View>
            </View>
        );
    }
);

DispatchDetails.propTypes = propTypes;

export default DispatchDetails;
