import { RouteInfo } from 'routes/libs';

const routeGenerator = menus => {
	const MENUS = [];

	const getIndieRouteInfo = id => {
		const isExists = Object.hasOwnProperty.call(RouteInfo, String(id));

		if (isExists) {
			const { component = null, url = null, exact = false } = RouteInfo[
				String(id)
			];
			if (component && url) MENUS.push({ component, url, exact });
		}

		return;
	};

	menus.map(({ menuId }, idx) => getIndieRouteInfo(menuId));

	return MENUS;
};

export { routeGenerator as default, routeGenerator };
