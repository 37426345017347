import {
    SET_LOADER,
    DELETE_LOADER,
    PROGRESS_LOADER
} from 'store/actionTypes';

export const setLoader = (loader) => ({
    type: SET_LOADER,
    meta: { loader }
});

export const deleteLoader = (loader) => ({
    type: DELETE_LOADER,
    meta: { loader }
});

export const progressLoader = ({ progress, loader }) => ({
    type: PROGRESS_LOADER,
    payload: { progress },
    meta: { loader }
});
