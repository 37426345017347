import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';

// Local
import GridSelect from '../../../GridSelect';

const propTypes = {
    name: PropTypes.string.isRequired,
    filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onFilterValueChange: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    loading: function(props, propName, componentName) {
        if (props[propName] === undefined) {
            return new Error(
                `The prop '${propName}' is required for column '${props.name}' in '${componentName}'`
            );
        }
        return null;
    }
};

const styles = ({ spacing, palette }) => ({
    control: {
        height: spacing.unit * 4.5,
        width: '100%',
        backgroundColor: darken(palette.background.light, 0.1)
    },
    disabled: {
        backgroundColor: `${darken(palette.background.light, 0.1)} !important`
    }
});

const SelectFilter = ({
    classes,
    onFilterValueChange,
    filterValue,
    ...restProps
}) => {
    const selectFilterProps = useMemo(
        () => ({
            styles: {
                control: classes.control,
                disabled: classes.disabled
            },
            handleOnChange: onFilterValueChange,
            value: filterValue,
            ...restProps
        }),
        [onFilterValueChange, filterValue, restProps]
    );

    return <GridSelect {...selectFilterProps} />;
};

SelectFilter.propTypes = propTypes;

export default withStyles(styles)(React.memo(SelectFilter));
