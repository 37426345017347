import React, { Fragment } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Material
import { Grid, withStyles, Typography } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import { GridItem, Input, ImageDnD } from '@formComponents';
import { SelectRetailers } from 'sampling/components';

// Local
import { addSetupItem } from '../../../redux.actions';
import { ADD_TRIM_LABEL } from '../../../redux.actionTypes';
import { FORM_NAME } from '../common';
import SelectTrimLabelType from './SelectTrimLabelType';

const styles = ({ breakpoints, spacing, palette }) => ({
    content: { width: breakpoints.values.md },
    button: {
        width: '100%',
        margin: spacing.unit * 2
    },
    error: {
        color: palette.error.main,
        padding: spacing.unit * 1.5,
        textAlign: 'center'
    },
    imageContentHeight: {
        height: spacing.unit * 35
    }
});

const Modal = compose(
    reduxForm({
        form: FORM_NAME,

        onSubmit: (values, dispatch, { onClose }) => {
            dispatch(
                addSetupItem({
                    ...values,
                    type: ADD_TRIM_LABEL,
                    handleCloseModal: onClose
                })
            );
        }
    }),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleSubmit,
        initialized,
        invalid,
        submitting,
        error
    }) => {
        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {`${initialized ? 'Update' : 'Add'} Trim Label`}
                </ModalHeader>
                <ModalContent className={c.content}>
                    {error && (
                        <Typography className={c.error}>{error}</Typography>
                    )}
                    <Grid container>
                        <GridItem md={4}>
                            <SelectRetailers required disabled={initialized} />
                        </GridItem>
                        <GridItem md={4}>
                            <SelectTrimLabelType
                                required
                                disabled={initialized}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='trimRef'
                                label='Trim Reference'
                                required
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input name='trimColour' label='Trim Colour' />
                        </GridItem>
                        <GridItem md={8}>
                            <Input name='trimDescription' label='Trim Desc' />
                        </GridItem>

                        <GridItem md={6}>
                            <ImageDnD
                                name='image'
                                label='Image'
                                styles={{ content: c.imageContentHeight }}
                                required
                            />
                        </GridItem>
                    </Grid>
                </ModalContent>
                <ModalFooter>
                    <Button
                        className={c.button}
                        onClick={handleSubmit}
                        loading={submitting}
                        disabled={invalid}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Fragment>
        );
    }
);

export { Modal as default, Modal };
