import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

/* TODO: COMPLETE RE-FACTOR */

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'flex-start',
        width: 300,
        border: '1px solid #e4e4e4',
        backgroundColor: '#f5f5f5',
        borderRadius: '21px',
        padding: 0
    },
    input: {
        marginLeft: 18,
        flex: 1,
        borderRight: '1px solid #e4e4e4'
    },
    iconButton: {
        padding: '7px 10px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4
    }
};

function Search(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <Typography component='div' className={classes.root}>
                <InputBase className={classes.input} placeholder='Search...' />
                <IconButton
                    className={classes.iconButton}
                    aria-label='Search'
                    disableRipple
                >
                    <SearchIcon />
                </IconButton>
            </Typography>
        </React.Fragment>
    );
}

Search.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Search);
