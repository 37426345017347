const getHeaders = isSeasalt => [
    { name: 'dispatchType', label: 'Dispatch Type' },
    { name: 'sampleRef', label: 'Sample Ref' },
    { name: 'sampleImage', label: 'Image' },
    { name: 'gender', label: 'Gender' },
    { name: 'department', label: 'Dept.' },
    { name: 'PONumbers', label: 'PO' },
    { name: 'size', label: 'Size' },
    { name: 'quantity', label: 'Qty' },
    { name: 'factory', label: 'Factory' },
    ...(isSeasalt
        ? [
              { name: 'season', label: 'Season' },
              { name: 'designer', label: 'Designer' },
              { name: 'fabricGroup', label: 'Fabric Group' }
          ]
        : []),
    { name: 'remarks', label: 'Remarks' },
    { name: 'action', label: '' }
];

export { getHeaders };
