import React from 'react';
import { array, string } from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

import Title from './Title';
import Table from './Table';

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
});
const propTypes = {
    data: array.isRequired,
    title: string
};

const TabularView = ({ data, title }) => (
    <View>
        <Title text={title} />
        <View style={styles.wrapper}>
            {data.map((datum, idx) => (
                <Table data={datum} key={`table-${idx}`} />
            ))}
        </View>
    </View>
);

TabularView.propTypes = propTypes;

export { TabularView as default, TabularView };
