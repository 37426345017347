export const emailSchema = {
    userName: 'string',
    toAddress: 'string',
    ccAddress: 'string',
    subject: 'string',
    body: 'string',
    sampleRef: 'string'
};

export const reportSchema = {
    reportReference: 'string',
    reportUrl: 'string',
    referenceDevNo: 'string'
};
