import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useDimensionsObserver = ({
    width: initialWidth = 0,
    height: initialHeight = 0,
    throttle = 50
} = {}) => {
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);
    const ref = useRef(null);

    useEffect(() => {
        const refElement = ref.current;
        let isSubscribed = true;
        let timeoutCallback = null;
        const resizeObserver = new ResizeObserver(([{ target } = {}]) => {
            const { offsetWidth, offsetHeight } = target;
            clearTimeout(timeoutCallback);
            timeoutCallback = setTimeout(() => {
                isSubscribed && setWidth(offsetWidth);
                isSubscribed && setHeight(offsetHeight);
            }, throttle);
        });
        refElement && resizeObserver.observe(refElement);
        return () => {
            isSubscribed = false;
            refElement && resizeObserver.unobserve(refElement);
        };
    }, [ref.current]);

    useLayoutEffect(() => {
        if (ref && ref.current) {
            setWidth(ref.current.offsetWidth);
            setHeight(ref.current.offsetHeight);
        }
    }, []);

    return [ref, { width, height }];
};

export default ({
    width: initialWidth = 0,
    height: initialHeight = 0
} = {}) => {
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (ref && ref.current) {
            setWidth(ref.current.offsetWidth);
            setHeight(ref.current.offsetHeight);
        }
    }, []);

    return [ref, { width, height }];
};
