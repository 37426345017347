export default ({ palette, shape }) => ({
    container: {
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: palette.common.transparent,
        borderRadius: shape.borderRadius,

        '&:hover': {
            borderColor: palette.grey[500]
        }
    }
});
