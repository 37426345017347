import React, { Component } from 'react';
import { compose } from 'redux';

// Material
import { withStyles, TextField } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../MultipleSelectDialogContext';
import Adornment from './Adornment';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * DialogSearch
 */
class DialogSearch extends Component {
    state = {
        queryValue: '',
        currentIndex: 0
    };

    onQuery = ({ target: { value: queryValue } }) => {
        const { dialogDispatch } = this.props;
        dialogDispatch({ type: 'set-search', payload: queryValue });
        this.setState({ queryValue });
    };

    render() {
        const { props, onQuery, state } = this;
        const { queryValue } = state;
        const {
            classes: { input: inputClass, ...c }
        } = props;

        return (
            <TextField
                classes={c}
                InputProps={{
                    disableUnderline: true,
                    className: inputClass,
                    startAdornment: <Adornment />
                }}
                value={queryValue}
                onChange={onQuery}
                autoFocus
                placeholder='Search..'
            />
        );
    }
}

const _DialogSearch = compose(
    withStyles(styles),
    withDialogContext
)(DialogSearch);

export { _DialogSearch as default, _DialogSearch as DialogSearch };
