import createCachedSelector from 're-reselect';

// Local
import { selectUniques, mapToSelect } from 'helpers';
import { selectSamplingPrint } from './common';

// Selectors
import {
    selectDepartmentsData,
    selectFactoriesData,
    selectCountriesData,
    selectJoinedRetailerFactoriesData,
    selectProductTypesData,
    selectDesignTypesData,
    selectRetailersData,
    selectCopyReferenceData,
    selectCopySampleReferencesData,
} from '../datasets/sampling/request';

// Product Types
export const selectProductTypes = createCachedSelector(
    selectProductTypesData,
    (state, filter = null) => filter,
    (productTypes, filter) =>
        mapToSelect(
            productTypes.filter(
                ({ productTypeId }) => !filter || productTypeId === filter
            )
        )
)((state, filter) => filter || 'empty');

// Design Types
export const selectDesignTypes = createCachedSelector(
    selectDesignTypesData,
    mapToSelect
)(() => 'placeholder');

// Retailers
export const selectRetailers = createCachedSelector(
    selectRetailersData,
    mapToSelect
)(() => 'placeholder');

// Departments
export const selectDepartments = createCachedSelector(
    selectDepartmentsData,
    (state, retailerId = 0) => retailerId,
    (departments = [], retailerId) =>
        mapToSelect(
            departments.filter(({ retailerId: id }) => id == retailerId) // eslint-disable-line eqeqeq
        )
)((state, retailerId = 0) => retailerId);

// Factories
export const selectRetailerFactories = createCachedSelector(
    selectJoinedRetailerFactoriesData,
    (state, retailerId = 0) => retailerId,
    (joinedRetailersFactories = [], retailerId) =>
        joinedRetailersFactories
            .filter(({ retailerId: id }) => id == retailerId) // eslint-disable-line eqeqeq
            .map(({ factoryId }) => factoryId)
)((state, retailerId = 0) => retailerId);

const selectRetailerFactoryCountries = createCachedSelector(
    selectFactoriesData,
    selectRetailerFactories,
    (factories = [], retailerFactories = []) =>
        factories
            .filter(({ factoryId }) => retailerFactories.includes(factoryId))
            .map(({ countryId }) => countryId)
            .filter(selectUniques)
)((state, retailerId = 0) => retailerId);

export const selectFactoryLocations = createCachedSelector(
    selectCountriesData,
    selectRetailerFactoryCountries,
    (countries = [], retailerFactoryCountries) =>
        mapToSelect(
            countries.filter(({ countryId }) =>
                retailerFactoryCountries.includes(countryId)
            )
        )
)((state, retailerId = 0) => retailerId);

// ------ Unclean
// Copy sample reference
const selectCopySampleReferences = createCachedSelector(
    selectSamplingPrint,
    ({ copySampleReferences }) => copySampleReferences
)(() => 'placeholder');
export const selectCopySampleRefList = createCachedSelector(
    selectCopySampleReferences,
    (copySampleRefs) => {
        if (!copySampleRefs) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: [],
            };
        }

        const sampleRefList = copySampleRefs.data || [];
        let value;
        let mainInfo = sampleRefList.map(function (ref) {
            value = { value: ref.sampleReference, fullData: ref };
            return [
                { title: ref.sampleReference, value: value },
                { title: ref.status, value: value },
            ];
        });
        return {
            rowInColumnWidth: [60, 40],
            paginationData: copySampleRefs.paginationMetadata,
            columnHeader: ['Sample reference', 'Status'],
            columnInfo: mainInfo,
        };
    }
)(() => 'placeholder');

// Todo: Refactor when fixing multistepper
export const selectRequestFormLoading = createCachedSelector(
    selectSamplingPrint,
    ({ requestLoading }) => requestLoading
)(() => 'placeholder');

// Copy References
export const selectCopyReferences = createCachedSelector(
    selectCopyReferenceData,
    () => []
)(() => 'placeholder');

// Copy Sample References
// Todo: fix naming
export const selectCopySampleReferencesNew = createCachedSelector(
    selectCopySampleReferencesData,
    (data) => data
)(() => 'placeholder');
