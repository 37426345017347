// Entities
import {
    GRAPHIC_REFERENCES,
    ARTWORK_LOCATIONS,
    ARTWORK_SCALES,
    DESIGNERS,
    PRINT_POSITIONS,
    PRINT_TYPES,
} from 'libs';

export default {
    [GRAPHIC_REFERENCES]: { nextPage: false, data: [] },
    [ARTWORK_LOCATIONS]: [],
    [ARTWORK_SCALES]: [],
    [DESIGNERS]: [],
    [PRINT_POSITIONS]: [],
    [PRINT_TYPES]: [],
};
