import { green, grey } from '@material-ui/core/colors';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const PRIMARY = '#234c72';
const SECONDARY = '#212121';
const SUCCESS = '#5cd3b4';
const ERROR = '#e36362';
const WARNING = '#ffc823';
const TERTIARY = '#f26831';
const BLUE = '#2878c1';

export default {
    primary: {
        light: lighten(PRIMARY, 0.4),
        main: PRIMARY,
        dark: darken(PRIMARY, 0.1),
        alert: '#d1eef8'
    },
    secondary: {
        light: lighten(SECONDARY, 0.6),
        main: SECONDARY,
        dark: darken(SECONDARY, 0.1)
    },
    success: {
        light: lighten(SUCCESS, 0.4),
        main: SUCCESS,
        dark: darken(SUCCESS, 0.1),
        alert: '#beede1',

        // Legacy
        primary: '#5cd3b4',
        hover: lighten('#5cd3b4', 0.2)
    },
    warning: {
        light: lighten(WARNING, 0.4),
        main: WARNING,
        dark: darken(WARNING, 0.1)
    },
    error: {
        light: lighten(ERROR, 0.4),
        main: ERROR,
        dark: darken(ERROR, 0.1),
        alert: '#ffe2e1'
    },
    blue: {
        light: lighten(BLUE, 0.7),
        main: BLUE,
        dark: darken(BLUE, 0.1)
    },
    background: {
        light: '#eff3f6', // Border light
        main: '#f5f5f5', // Background
        dark: '#c8ced4' // Border dark
    },

    // Legacy
    common: {
        transparent: 'rgba(0, 0, 0, 0)'
    },
    icon: {
        opacity: {
            regular: 0.5,
            hover: 1
        }
    },

    amend: {
        main: '#d1eef8',
        dark: lighten('#d1eef8', 0.2)
    },
    cancel: {
        main: grey[800]
    },
    green,
    orange: {
        light: lighten(TERTIARY, 0.4),
        main: TERTIARY,
        dark: darken(TERTIARY, 0.1)
    }
};
