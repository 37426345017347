import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import datasets from './redux.datasets';

import {
    SET_CAD_DESIGNER_REPORT_DATA,
    SET_CAD_DESIGNER_REPORT_DATA_LOADING,
    ERASE_CAD_DESIGNER_REPORT_DATA
} from './redux.actionTypes';

const initialState = {
    reportData: {},
    reportDataLoading: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CAD_DESIGNER_REPORT_DATA: {
            return { ...state, reportData: payload };
        }
        case SET_CAD_DESIGNER_REPORT_DATA_LOADING: {
            return {
                ...state,
                reportDataLoading: payload
            };
        }
        case ERASE_CAD_DESIGNER_REPORT_DATA: {
            return {
                ...state,
                reportData: {},
                reportDataLoading: false
            };
        }
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
