import React, { useMemo, useCallback } from 'react';
import clsx from 'classnames';
import { withStyles } from '@material-ui/core';

import useGridContext from '../../../useGridContext';
import useResizingContext from '../useResizingContext';
import MainHeader from './MainHeader';
import FilterHeader from './FilterHeader';
import GridCellResizing from '../GridCellResizing';
import {
    dataGridDefaultCheckboxProps,
    CHECKBOX_COLUMN_ID
} from '../../../dataGridDefaultValues';

const styles = ({ palette }) => ({
    gridHeaderRowContainer: {
        display: 'flex',
        backgroundColor: palette.background.light,
        width: 'max-content',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    gridHeaderRow: {
        display: 'flex'
    },
    gridHeaderCellContainer: {
        position: 'relative',
        flexGrow: 0,
        flexShrink: 0,
        width: 'var(--columnWidth)',
        maxWidth: 'var(--columnWidth)',
        minWidth: 'var(--columnWidth)'
    },
    gridHeaderStickyCells: {
        position: 'sticky',
        zIndex: 4,
        left: 0,
        boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'inherit'
    }
});

const GridHeader = withStyles(styles)(({ classes: c }) => {
    const {
        gridColumns,
        gridFrozenColumns,
        isColumnFrozen,
        disableFieldFilter,
        disableCheckboxColumn,
        filterValues,
        handleFilterChange,
        removeFilter,
        removeAllFilters,
        disableColumnResize,
        datasetSelector,
        datasetLoadingSelector
    } = useGridContext();
    const { columnWidths, startResize } = useResizingContext();

    const handleStartResize = useCallback(
        columnName => clientX => {
            startResize({ [columnName]: clientX });
        },
        [startResize]
    );

    const renderHeaders = (columns, isFrozen) => {
        return columns.map(column => {
            const isColumnCheckbox = column.name === CHECKBOX_COLUMN_ID;
            const columnWidth = isColumnCheckbox
                ? column.width
                : columnWidths[column.name].width;
            const isThisColumnFrozen = isColumnFrozen(column.name);

            if (isThisColumnFrozen === isFrozen || isColumnCheckbox) {
                return (
                    <div
                        style={{
                            '--columnWidth': `${columnWidth}px`
                        }}
                        className={c.gridHeaderCellContainer}
                        key={column.name}
                        role='columnheader'
                    >
                        <MainHeader
                            column={column}
                            isThisColumnFrozen={isThisColumnFrozen}
                            isColumnCheckbox={isColumnCheckbox}
                        />

                        {!disableFieldFilter && (
                            <FilterHeader
                                column={column}
                                filterValues={filterValues}
                                handleFilterChange={handleFilterChange}
                                removeFilter={removeFilter}
                                removeAllFilters={removeAllFilters}
                                datasetSelector={datasetSelector}
                                datasetLoadingSelector={datasetLoadingSelector}
                            />
                        )}
                        {column.resizable && !disableColumnResize && (
                            <GridCellResizing
                                onStartResize={handleStartResize(column.name)}
                            />
                        )}
                    </div>
                );
            }

            return null;
        });
    };

    const hasFrozenColumns = gridFrozenColumns.length > 0;

    const gridColumnsWithCheckbox = useMemo(
        () =>
            disableCheckboxColumn
                ? gridColumns
                : [dataGridDefaultCheckboxProps, ...gridColumns],
        [gridColumns, disableCheckboxColumn]
    );

    const gridFrozenColumnsWithCheckbox = useMemo(
        () =>
            disableCheckboxColumn
                ? gridFrozenColumns
                : [dataGridDefaultCheckboxProps, ...gridFrozenColumns],
        [gridFrozenColumns, disableCheckboxColumn]
    );

    return (
        <div className={c.gridHeaderRowContainer}>
            {hasFrozenColumns && (
                <div
                    className={clsx(c.gridHeaderRow, c.gridHeaderStickyCells)}
                    role='row'
                >
                    {renderHeaders(gridFrozenColumnsWithCheckbox, true)}
                </div>
            )}
            <div className={c.gridHeaderRow} role='row'>
                {renderHeaders(
                    !hasFrozenColumns ? gridColumnsWithCheckbox : gridColumns,
                    false
                )}
            </div>
        </div>
    );
});

export default GridHeader;
