import { put, takeLatest, call, fork, select } from 'redux-saga/effects';
import { formValueSelector, change, initialize, destroy } from 'redux-form';

import { setLoading, setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { isRedirectedSelector } from 'navigation/redux.selectors';
import isBlobUrl from '@libs/isBlobUrl';
import { entitySelector } from '@libs/datasets';
import { SAMPLE_EMAIL_GROUP, SAMPLE_EMAIL_BODY } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { MAIL_FORM_NAME } from 'common/common';
import { userEmailSelector } from 'auth/redux.selectors';

import {
    FABRIC_DEVELOPMENT_REFERENCE_GENERATE,
    COPY_ORIGINAL_FABRIC_DATA,
    GET_FABRIC_DEVELOPMENT_REFERENCE_DATA,
    FABRIC_DEVELOPMENT_PUBLISH,
    SET_MATCH_COLOR_CHILDREN,
    ROUTE_TO_FABRIC_DEVELOPMENT,
    DESTROY_FABRIC_DEVELOPMENT_FORM,
    SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
    SET_FABRIC_DEVELOPMENT_MAIL_BODY,
    FABRIC_REPORT_MAIL_SEND,
    GET_COPY_FABRIC_DATA
} from '../../redux.actionTypes';
import {
    setFabricDevelopmentReferenceLoading,
    destroyFabricDevelopmentForm
} from '../../redux.actions';
import {
    FORM_NAME,
    NEW_FABRIC_DEVELOPMENT_ROUTE_ID,
    INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID,
    AMEND_FABRIC_DEVELOPMENT_ROUTE_ID,
    DEVELOPED_FABRIC_ROUTE_ID,
    INITIAL_VALUES
} from '../../FabricDevelopment/common';
import generateFabricDevelopmentReferenceWorker from './generateReferenceWorker';
import getFabricReferenceDataWorker from './getFabricReferenceDataWorker';
import fabricReportUploadAndMailSendWorker from './fabricReportUploadAndMailSendWorker';
import fabricDevelopmentPublishWorker from './publishWorker';
import fabricDevelopmentViewSagas from './fabricDevelopmentViewSagas';

// Workers

const getCopyFabricDataWorker = function*({ payload: fabricReference }) {
    if (fabricReference) {
        yield put(setLoading(true));

        yield call(getFabricReferenceDataWorker, fabricReference, true);

        yield put(setLoading(false));
    }
};

function* generateReferenceWorker() {
    yield put(setFabricDevelopmentReferenceLoading(true));

    yield call(generateFabricDevelopmentReferenceWorker);

    yield put(setFabricDevelopmentReferenceLoading(false));
}

function* copyOriginalFabricDataWorker({ payload }) {
    const originalFabric = yield select(
        formValueSelector(FORM_NAME),
        'originalFabric'
    );
    yield put(
        change(FORM_NAME, 'requiredFabric', payload ? originalFabric : null)
    );
}
function* setMatchColorChildrenWorker() {
    yield put(change(FORM_NAME, 'color', ''));
    yield put(change(FORM_NAME, 'colorReference', ''));
}

function* getFabricDevelopmentReferenceDataWorker(action) {
    const { payload: fabricReference } = action;

    if (fabricReference) {
        try {
            yield put(setLoading(true));
            yield call(getFabricReferenceDataWorker, fabricReference);
        } catch (error) {
            const serverMessage = yield call(
                normalizeServerFailedResponse,
                error
            );

            yield put(
                setSnack({
                    message: serverMessage,
                    type: 'error',
                    duration: 15000,
                    action: {
                        label: 'Retry',
                        handle: action
                    }
                })
            );
        }
        yield put(setLoading(false));
    }
}

export const routeToFabricDevelopmentWorker = function*({
    meta: { mode } = {}
}) {
    const routeID =
        mode === 'new'
            ? NEW_FABRIC_DEVELOPMENT_ROUTE_ID
            : mode === 'amend'
            ? AMEND_FABRIC_DEVELOPMENT_ROUTE_ID
            : mode === 'developedFabric'
            ? DEVELOPED_FABRIC_ROUTE_ID
            : INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID;

    yield put(destroyFabricDevelopmentForm());

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    }

    yield put(redirectToRoute(routeID));
};

export function* destroyFabricDevelopmentFormWorker() {
    const isRedirected = yield select(isRedirectedSelector);
    if (!isRedirected) {
        // URL blob clean up
        const { originalFabricImage, washedImage, finalImage } = yield select(
            formValueSelector(FORM_NAME),
            'originalFabricImage',
            'washedImage',
            'finalImage'
        );

        if (originalFabricImage && isBlobUrl(originalFabricImage.url))
            URL.revokeObjectURL(originalFabricImage.url);

        if (washedImage && isBlobUrl(washedImage.url))
            URL.revokeObjectURL(washedImage.url);

        if (finalImage && isBlobUrl(finalImage.url))
            URL.revokeObjectURL(finalImage.url);

        yield put(destroy(FORM_NAME));
    }

    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    yield put(setRedirect(false));
}

const setFabricDevelopmentMailReceiversWorker = function*({
    payload: groupId
}) {
    if (groupId) {
        const initiator = yield select(userEmailSelector);
        const groups = yield select(
            entitySelector,
            SAMPLING_DOMAIN_NAME,
            SAMPLE_EMAIL_GROUP
        );
        const { emailGroup } = groups.find(({ id }) => id === Number(groupId));
        const receivers = emailGroup.split(',').join(', ');
        yield put(change(MAIL_FORM_NAME, 'receivers', receivers));
        if (!receivers.includes(initiator)) {
            yield put(change(MAIL_FORM_NAME, 'cc', initiator));
        }
    } else {
        yield put(change(MAIL_FORM_NAME, 'receivers', ''));
        yield put(change(MAIL_FORM_NAME, 'cc', ''));
    }
};

const setFabricDevelopmentMailBodyWorker = function*({ payload: templateId }) {
    if (templateId) {
        const templates = yield select(
            entitySelector,
            SAMPLING_DOMAIN_NAME,
            SAMPLE_EMAIL_BODY
        );
        const { emailBody } = templates.find(
            ({ id }) => id === Number(templateId)
        );
        yield put(change(MAIL_FORM_NAME, 'body', `${emailBody}`));
    } else {
        yield put(change(MAIL_FORM_NAME, 'body', ''));
    }
};

// Watchers
const fabricDevelopmentSagas = function*() {
    yield takeLatest(
        FABRIC_DEVELOPMENT_REFERENCE_GENERATE,
        generateReferenceWorker
    );
    yield takeLatest(COPY_ORIGINAL_FABRIC_DATA, copyOriginalFabricDataWorker);
    yield takeLatest(SET_MATCH_COLOR_CHILDREN, setMatchColorChildrenWorker);
    yield takeLatest(
        GET_FABRIC_DEVELOPMENT_REFERENCE_DATA,
        getFabricDevelopmentReferenceDataWorker
    );
    yield takeLatest(
        FABRIC_DEVELOPMENT_PUBLISH,
        fabricDevelopmentPublishWorker
    );
    yield takeLatest(
        ROUTE_TO_FABRIC_DEVELOPMENT,
        routeToFabricDevelopmentWorker
    );
    yield takeLatest(
        DESTROY_FABRIC_DEVELOPMENT_FORM,
        destroyFabricDevelopmentFormWorker
    );
    yield takeLatest(
        SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
        setFabricDevelopmentMailReceiversWorker
    );
    yield takeLatest(
        SET_FABRIC_DEVELOPMENT_MAIL_BODY,
        setFabricDevelopmentMailBodyWorker
    );
    yield takeLatest(
        FABRIC_REPORT_MAIL_SEND,
        fabricReportUploadAndMailSendWorker
    );
    yield takeLatest(GET_COPY_FABRIC_DATA, getCopyFabricDataWorker);
};

export default function*() {
    yield fork(fabricDevelopmentSagas);
    yield fork(fabricDevelopmentViewSagas);
}
