import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { array, object } from 'prop-types';
import Image from './Image';

const styles = StyleSheet.create({
    container: {
        border: 1,
        borderColor: '#c8ced4',
        minHeight: 200
    },
    label: {
        color: '#212121',
        fontSize: 12,
        marginBottom: 4,
        fontWeight: 'bold'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderBottomColor: '#c8ced4',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    header: {
        backgroundColor: '#EFF2F6',
        paddingBottom: 5,
        paddingTop: 5
    },
    body: {
        paddingBottom: 5,
        paddingTop: 5
    },
    item: {
        marginLeft: 10,
        marginRight: 10,
        fontSize: 12
    },
    imageContainer: {
        width: 64,
        height: 48,
        marginRight: 10,
        marginLeft: 10,
        border: 1,
        borderColor: '#c8ced4',
        backgroundColor: '#eff3f6',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '100%'
    }
});

const propTypes = {
    attachments: array.isRequired,
    style: object
};

const AttachmentDetails = ({ attachments = [], style = {} }) => {
    return (
        <View style={style}>
            <Text style={styles.label}>Attachment Details :</Text>
            <View style={styles.container}>
                <View style={{ ...styles.row, ...styles.header }}>
                    <Text style={styles.item}>Image</Text>
                    <Text style={styles.item}>File Name</Text>
                </View>
                {attachments.map((attachment, idx) => (
                    <View
                        style={{ ...styles.row, ...styles.body }}
                        key={`attachment-${idx}`}
                    >
                        <View style={styles.imageContainer}>
                            <Image
                                url={attachment.url}
                                filename={attachment.filename}
                            />
                        </View>
                        <Text style={styles.item}>{attachment.filename}</Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

AttachmentDetails.propTypes = propTypes;

export { AttachmentDetails as default, AttachmentDetails };
