import { takeLatest, call, put, select } from 'redux-saga/effects';

// Local
import { API_BASE_URL, apiClient } from 'config';

// Actions
import { updateSamplingPrintForm } from 'actions';

// Action Types
import {
    FETCH_GRAPHIC_REF_REQUEST,
    FETCH_GRAPHIC_REF_SUCCESS,
    SET_GRAPHIC_DESCRIPTION,
    SET_CAD_IMAGE,
    FETCH_SAMPLE_INFO_SUCCESS,
} from 'actionTypes';

// Selectors
import { selectGraphicDescription } from 'selectors';

//api calling

async function getGraphicRefList(
    { id, searchKey = '', pageNo = 1 },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_GRAPHIC_REF_REQUEST;
    return await apiClient.get(
        API_BASE_URL +
            `api/CadRequestMains/GetGraphicRefDropDown?userid=${id}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}`
    );
}

//workers
function* graphicRefEffectSaga({ payload }) {
    try {
        const userId = yield select((state) => state.auth.userId);
        const data = {
            id: userId,
            ...payload,
        };
        const res = yield call(getGraphicRefList, data);
        yield put({
            type: FETCH_GRAPHIC_REF_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* graphicDescriptionEffectSaga({ payload: { reference } }) {
    const graphicDescription = yield select(
        selectGraphicDescription,
        reference
    );

    yield put(updateSamplingPrintForm({ graphicDescription }));
}

function* cadImageWorker() {
    yield put(updateSamplingPrintForm({ cadImage: true }));
}

function* sampleInfoWorker({ payload: { cadImage = [] } }) {
    yield put(updateSamplingPrintForm({ cadImage: !!cadImage.length }));
}

// Watchers
const artworkWatchers = function* () {
    yield takeLatest(FETCH_GRAPHIC_REF_REQUEST, graphicRefEffectSaga);
    yield takeLatest(SET_GRAPHIC_DESCRIPTION, graphicDescriptionEffectSaga);
    yield takeLatest(SET_CAD_IMAGE, cadImageWorker);
    yield takeLatest(FETCH_SAMPLE_INFO_SUCCESS, sampleInfoWorker);
};

export { artworkWatchers as default, artworkWatchers };
