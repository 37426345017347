import { useMemo } from 'react';

import { LABELS, HAND_CARRIED_COURIER_ID } from '../common';
import { useSampleDispatchDetailsLabel } from '../hooks';

const useSchema = (mode, { currier, hasManualReference }) => {
    const sampleDispatchDetails = useSampleDispatchDetailsLabel(mode);
    const commonSchema = useMemo(
        () => ({
            retailer: true,
            originCountry: true,
            destinationCountry: (_, fieldError) => {
                return !fieldError;
            }
        }),
        []
    );

    const setupSchema = useMemo(
        () =>
            mode === 'new'
                ? {
                      ...commonSchema,
                      ...(hasManualReference
                          ? {
                                manualReference: true,
                                manualReferenceAsync: fieldValue => {
                                    return fieldValue === 'Valid';
                                }
                            }
                          : {})
                  }
                : {},
        [mode, hasManualReference]
    );

    const detailsSchema = useMemo(
        () => ({
            ...commonSchema,
            dispatchReference: true,
            trackingReference: (_, fieldError) => {
                return !fieldError;
            },
            fao: (_, fieldError) => {
                return !fieldError;
            },
            actualSendDate: true,
            eta: true,
            currier: HAND_CARRIED_COURIER_ID !== currier,
            dispatchDetails: (_, fieldError) => {
                return !fieldError;
            }
        }),
        [currier]
    );

    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema
        }),
        [setupSchema]
    );

    return useMemo(
        () => ({
            ...(mode === 'new' ? { [LABELS.setup]: setupSchema } : {}),
            [sampleDispatchDetails]: detailsSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: commonSchema
        }),
        [mode, setupSchema, sampleDispatchDetails]
    );
};

export default useSchema;
