const normalizeUsersList = ({
    data: { data = [], nextPage = false } = {}
} = {}) => {
    const normalizedData = data.reduce((acm, { email, name, isActive }) => {
        return [
            ...acm,
            {
                Email: email,
                Name: name,
                Active: isActive ? 'Yes' : 'No'
            }
        ];
    }, []);
    return { data: normalizedData, nextPage };
};

const normalizePermissionsMenus = menus => menus.map(({ menuId }) => menuId);

export { normalizeUsersList, normalizePermissionsMenus };
