import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';

const propTypes = {
    IconComponent: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    iconPosition: PropTypes.oneOf(['left', 'right'])
};

const defaultProps = {
    variant: 'outlined',
    iconPosition: 'left'
};

const styles = ({ spacing, breakpoints }) => ({
    root: {
        width: 'auto'
    },
    leftIcon: {
        marginRight: spacing.unit
    },
    rightIcon: {
        marginLeft: spacing.unit
    },
    label: {
        display: 'inline',
        [breakpoints.down(770)]: {
            display: 'none'
        }
    }
});

const IconLabelButton = withStyles(styles)(
    ({
        classes: c,
        IconComponent,
        iconPosition,
        label,
        className,
        ...props
    }) => (
        <Button className={clsx(c.root, className)} {...props}>
            {iconPosition === 'right' && (
                <span className={c.label}>{label}</span>
            )}
            <IconComponent
                className={iconPosition === 'left' ? c.leftIcon : c.rightIcon}
            />
            {iconPosition === 'left' && (
                <span className={c.label}>{label}</span>
            )}
        </Button>
    )
);

IconLabelButton.propTypes = propTypes;
IconLabelButton.defaultProps = defaultProps;

export { IconLabelButton as default, IconLabelButton };
