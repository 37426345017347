export const generateReferenceSchema = {
    jwpNo: 'string',
    styleRef: 'string',
    colorId: 0,
    userId: 0
};

export const publishSchema = {
    id: 0,
    qcRef: 'string',
    jwpNo: 'string',
    styleRef: 'string',
    colorId: 0,
    qcDate: '2023-02-17T08:36:30.389Z',
    sealByDate: '2023-02-17T08:36:30.389Z',
    fabricBatch: 'string',
    finishWashId: 0,
    coveringLot: 'string',
    ftyPrdUnit: 'string',
    prewashPanel: 0,
    bulkProductionUnit: {
        one: 'string',
        two: 'string',
        three: 'string',
        four: 'string',
        five: 'string',
        six: 'string',
        seven: 'string'
    },
    isDiscontinued: true,
    isHandover: true,
    qcVersion: 0,
    bodyFabric: 0,
    sampleColorStatusId: 0,
    trimFabric: 0,
    accTrim: 'string',
    missingTrims: 'string',
    emblishment: 0,
    colorShade: 0,
    styling: 0,
    washShrinkage: { length: 0, width: 0 },
    printShrinkage: { length: 0, width: 0 },
    spiralityPercent: 0,
    foundGsm: 'string',
    pattern: 0,
    fitStatus: 0,
    typesOfSample: 0,
    clarificationFromBuyer: 'string',
    generalInstruction: 'string',
    sealVerification: 'string',
    patternFit: 'string',
    specialComments: 'string',
    workmanshipComments: 'string',
    measurementComments: 'string',
    preventiveAction: 'string',
    fabricCTQ: 'string',
    emblishmentCTQ: 'string',
    accTrimCTQ: 'string',
    cprtwashCTQ: 'string',
    cuttingCTQ: 'string',
    finishingPackingCTQ: 'string',
    sewingCTQ: 'string',
    bulkProductionComplicacy: 'string',
    sampleQty: 0,
    sampleReviewStatus: 0,
    rejectQty: 0,
    dhu: 0,
    thDefectRate: 0,
    remarks: 'string',
    userId: 0,
    frontImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    backImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    attachments: [
        {
            id: 0,
            sizeOriginal: 'string'
        }
    ]
};

export const QCEmailSchema = {
    userName: 'string',
    toAddress: 'string',
    ccAddress: 'string',
    subject: 'string',
    body: 'string',
    preSealQcRef: 'string'
};
