import React, { useContext } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles, Step as MaterialStep, withTheme } from '@material-ui/core';

import StepLabel from './StepLabel';
import StepIcon from './StepIcon';
import useStepper from '../../StepContext';

// Hooks
import { useStepPadding } from '../hooks';
import { useColor } from './hooks';

const propTypes = {
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired
};

const styles = () => ({
    root: {
        cursor: 'var(--cursor)',
        zIndex: 4,
        paddingLeft: 'var(--padding)',
        paddingRight: 'var(--padding)'
    }
});

const Step = compose(
    withStyles(styles),
    withTheme()
)(({ classes: c, theme, index, label, touched }) => {
    const [{ activeStep }, dispatch] = useStepper();

    const isActive = index === activeStep;

    const handleMove = index => () =>
        dispatch({ type: 'move', payload: index });

    const style = {
        ...useStepPadding(),
        '--cursor': touched ? 'pointer' : 'unset'
    };

    return (
        <MaterialStep
            classes={c}
            active={isActive}
            onClick={handleMove(index)}
            style={style}
        >
            <StepLabel
                disabled={!touched}
                style={useColor(index, theme)}
                StepIconComponent={StepIcon}
                StepIconProps={{
                    index,
                    activeStep
                }}
            >
                {label}
            </StepLabel>
        </MaterialStep>
    );
});

Step.propTypes = propTypes;

export { Step as default };
