import React from 'react';
import { string, object } from 'prop-types';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

const propTypes = {
    comments: string,
    style: object
};

const defaultProps = {
    label: 'Additional Comments'
};

const styles = StyleSheet.create({
    container: {
        padding: 5,
        border: 1,
        borderColor: '#c8ced4',
        minHeight: 100
    },
    label: {
        color: '#212121',
        fontSize: 12,
        marginBottom: 4,
        fontFamily: 'Times-Bold'
    }
});

const Comments = ({ comments, label, style = {} }) => {
    const preparedHTML = !!comments
        ? `
    <html>
        <body>
            <style>
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,p,ul,li {
                    margin:0;
                    line-height:1.5;
                }
            </style>
            ${comments
                .replace(/(<style([\S\s]*?)>([\S\s]*?)<\/style>)/g, '')
                .replace(/(style=".+?")/gm, '')}
        </body>
    </html>`
        : '';

    return (
        <View style={style}>
            <Text style={styles.label}>{label} :</Text>
            <View style={styles.container}>
                <Html style={{ fontSize: 10, lineHeight: 1.5 }}>
                    {preparedHTML}
                </Html>
            </View>
        </View>
    );
};

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export { Comments as default, Comments };
