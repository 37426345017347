import { fork } from 'redux-saga/effects';

import fabricSagas from './fabrics';
import sampleSagas from './samples';
import cadsSagas from './cads';
import commonSetupSagas from './common';
import orderSagas from './order';

export default function*() {
    yield fork(fabricSagas);
    yield fork(sampleSagas);
    yield fork(cadsSagas);
    yield fork(commonSetupSagas);
    yield fork(orderSagas);
}
