import { formValueSelector } from 'redux-form';
import { select, call, all } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';

import { sizePathsSelector } from 'common/redux.selectors';
import { userEmailSelector, userNameSelector } from 'auth/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';
import { publishTemplate, sendMail } from '@libs/mailer';
import { deserializeFilename } from '@libs/filenames';
import { sanitizedString } from '@libs/string';
import { MAIL_SENDER_ADDRESS } from 'common/common';
import { updateCcAddress } from '@libs/mailer';

import { FORM_NAME } from 'fabric/FabricDevelopment/common';

export default function*({ fabricReference, versionNo, mode, handleLoading }) {
    try {
        const {
            pdf,
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body
        } = yield select(formValueSelector(FORM_NAME), 'mailData');

        const [userEmail, userName, reportBlobs, reportSizePaths] = yield all([
            select(userEmailSelector),
            select(userNameSelector),
            call(getAssetBlobsWorker, {
                assets: pdf,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'reportPath')
        ]);

        const sanitizedReference = sanitizedString(fabricReference);

        /* Upload to s3 bucket */
        const [reportPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: reportBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/${sanitizedReference}`,
            reference: sanitizedReference,
            sizePaths: reportSizePaths,
            handleLoading,
            developmentName: `V${versionNo}`
        });

        const originalPath = reportPayload ? reportPayload.sizeOriginal : '';

        const reportURL = yield call(syncReportToDatabase, {
            reportReference: fabricReference,
            reportUrl: originalPath,
            referenceDevNo: versionNo
        });

        const links = [
            { url: reportURL, filename: deserializeFilename(reportURL) }
        ];

        const composeBodyTemplate = publishTemplate(
            body,
            links,
            'Fabric Development Email'
        );

        const recipients = toAddress.replace(/\s/g, '');

        const updatedCcAddress = updateCcAddress(
            ccAddress,
            userEmail,
            recipients
        );

        const recipientsArray = recipients.split(',').map(email => email);

        const ccRecipients =
            updatedCcAddress &&
            updatedCcAddress.split(',').map(email => email.trim());

        yield call(
            sendMail,
            JSON.stringify({
                fromAddress: MAIL_SENDER_ADDRESS,
                toAddress: recipientsArray,
                ccAddress: ccRecipients,
                subject: `${userName}: ${subject}`,
                body: composeBodyTemplate
            })
        );
    } catch (err) {
        console.log('error in report worker', err);
    }
}
