import {
	SamplingPrintNew,
	SamplingPrintIncomplete,
	SamplingPrintAmend,
	Reports
} from 'views';

import { Sampling, HorizontalV1, HorizontalV2 } from 'assets';

export default {
	'85': { depth: 1, icon: Sampling }, //Sampling
	'526': {
		depth: 3,
		component: SamplingPrintNew,
		icon: HorizontalV1,
		url: '/sampling/print/new'
	},
	'129': {
		depth: 2,
		icon: HorizontalV2
	}, //Print
	'130': {
		depth: 2,
		icon: HorizontalV2
	}, //Shape
	'131': {
		depth: 3,
		component: SamplingPrintIncomplete,
		icon: HorizontalV1,
		url: '/sampling/print/incomplete'
	},
	'132': {
		depth: 3,
		component: SamplingPrintAmend,
		icon: HorizontalV1,
		url: '/sampling/print/amend'
	},
	'134': {
		depth: 3,
		component: Reports,
		icon: HorizontalV1,
		url: '/sampling/print/reports'
	}
};
