import React, { useMemo } from 'react';

import { DYE_TYPES } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const DyeTypes = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Dye Type', canSort: true, sortKey: 'dyeType' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'FABRIC DYE TYPE SETUP',
        domain: DOMAIN_NAME,
        entity: DYE_TYPES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { DyeTypes as default, DyeTypes };
