import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material
import { withStyles, FormControlLabel } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import { composeClsx } from '@libs/materialUI';
import CheckboxBase from '@baseComponents/Checkbox';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    color: PropTypes.string
};

const styles = ({ }) => ({ label: {} });

const Checkbox = compose(
    withField(),
    withStyles(styles)
)(
    ({
        classes,
        styles,
        label,
        disabled,
        input: { value, ...input },
        fieldName,
        ...rest
    }) => {
        return (
            <FormControlLabel
                control={
                    <CheckboxBase
                        disabled={disabled}
                        checked={!!value}
                        styles={styles}
                        {...input}
                        {...rest}
                    />
                }
                disabled={disabled}
                label={label}
                classes={composeClsx({ classes, styles })}
            />
        );
    }
);

Checkbox.propTypes = propTypes;

export { Checkbox as default, Checkbox };
