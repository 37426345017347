import { useMemo } from 'react';
import { NO_IMAGE_URL } from 'common/common';

const useProductSummary = summary =>
    useMemo(
        () =>
            Object.entries(summary)
                .map(([label, value]) => {
                    switch (label) {
                        case 'sampleRef':
                            return {
                                label: 'Sample Ref',
                                value: !!value ? value : 'N/A',
                                order: 0
                            };
                        case 'specRef':
                            return {
                                label: 'Spec Ref',
                                value: !!value ? value : 'N/A',
                                order: 1
                            };
                        case 'description':
                            return {
                                label: 'Spec Desc',
                                value: !!value ? value : 'N/A',
                                order: 2
                            };
                        case 'developmentNo':
                            return {
                                label: 'Dev No',
                                value: !!value ? value : 'N/A',
                                order: 3
                            };
                        case 'fitStatus':
                            return {
                                label: 'Fit Status',
                                value: !!value ? value : 'N/A',
                                order: 4
                            };
                        case 'frontImage':
                            return {
                                label: '',
                                value: !!value ? value : NO_IMAGE_URL,
                                order: 5
                            };

                        default:
                            return null;
                    }
                })
                .filter(details => !!details)
                .sort((a, b) => a.order - b.order)
                .map(({ order, ...detail }) => detail),
        [summary]
    );

export { useProductSummary };
