import React from 'react';

import { RADIAN } from './constants';

const PercentageLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    startAngle,
    endAngle
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const angleDelta = endAngle - startAngle;

    const arcLength = radius * angleDelta * RADIAN;
    const arcLengthThreshold = 15;

    return arcLength >= arcLengthThreshold ? (
        <text
            x={x}
            y={y}
            fill='black'
            textAnchor='middle'
            dominantBaseline='middle'
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    ) : null;
};

export { PercentageLabel as default, PercentageLabel };
