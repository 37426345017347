import { useMemo } from 'react';
import { darken } from '@material-ui/core/styles/colorManipulator';

const useRowStyles = ({
    rowIdx,
    palette,
    isRowSelected,
    customRowColor,
    rowHeight
}) => {
    const bgColor = useMemo(() => {
        if (isRowSelected) {
            return palette.orange.light;
        } else if (customRowColor) {
            return customRowColor;
        } else {
            return rowIdx % 2 === 0 ? '#f2f2f2' : '#ffffff';
        }
    }, [rowIdx, isRowSelected, palette, customRowColor]);

    const bgHoverColor = useMemo(() => {
        return darken(bgColor, 0.1);
    }, [bgColor]);

    return useMemo(
        () => ({
            '--row-height': `${rowHeight}px`,
            '--bg-color': bgColor,
            '--bg-hover-color': bgHoverColor
        }),
        [bgColor, bgHoverColor, rowHeight]
    );
};

export default useRowStyles;
