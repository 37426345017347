import React from 'react';
import PropTypes from 'prop-types';

import { withFieldArray } from '@libs/reduxForm';

// Local
import DipComponent from './DipComponent';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'dips'
};

const DipsContainer = withFieldArray()(({ fields }) => {
    return fields.map((field, index) => {
        return (
            <DipComponent
                dip={field}
                key={`dip-${index}`}
                sampleRef={fields.get(index).sampleRef}
                batchConfirmation={fields.get(index).batchConfirmation}
            />
        );
    });
});

DipsContainer.propTypes = propTypes;
DipsContainer.defaultProps = defaultProps;

export { DipsContainer as default, DipsContainer };
