import React from 'react';
import { array } from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Row from './Row';

const propTypes = {
    summary: array.isRequired
};

const styles = ({ palette, spacing, typography }) => ({
    container: {
        width: '100%',
        flexDirection: 'column'
    },
    titleContainer: {
        width: '100%',
        padding: spacing.unit * 0.4,

        backgroundColor: palette.grey[300]
    },
    title: {
        fontSize: 10,
        fontWeight: typography.fontWeightHeavy,
        textTransform: 'uppercase'
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        height: 'auto'
    }
});

const Summary = withPDFStyles(styles)(({ classes: c, summary }) => {
    return (
        <View style={c.container}>
            <View style={c.titleContainer}>
                <Text style={c.title}>Report Summary</Text>
            </View>
            <View style={c.table}>
                {summary.map(({ title, value }, index) => (
                    <Row
                        key={`key-report-table-${index}`}
                        label={title}
                        value={value}
                        isLast={index === summary.length - 1}
                    />
                ))}
            </View>
        </View>
    );
});

Summary.propTypes = propTypes;

export default Summary;
