import {
    SET_SETTINGS_DATA,
    RESET_SETTINGS_FLAG,
    SET_PIE_CHART_SETTINGS,
    SET_DATA_GRID_SETTINGS
} from './redux.actionTypes';

const initialState = {
    settings: {
        pieChartSettings: {},
        dataGridSettings: {}
    },
    settingsChanged: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS_DATA: {
            return {
                ...state,
                settings: action.payload
            };
        }
        case SET_PIE_CHART_SETTINGS: {
            const { module, settings } = action.payload;
            const settingsState = state.settings;
            const pieChartState = settingsState.pieChartSettings;
            const moduleState = pieChartState[module] || {};
            return {
                ...state,
                settings: {
                    ...settingsState,
                    pieChartSettings: {
                        ...pieChartState,
                        [module]: {
                            ...moduleState,
                            ...settings
                        }
                    }
                },
                settingsChanged: true
            };
        }

        case SET_DATA_GRID_SETTINGS: {
            const { module, settings } = action.payload;
            const settingsState = state.settings;
            const dataGridState = settingsState.dataGridSettings;
            const moduleState = dataGridState[module] || {};
            return {
                ...state,
                settings: {
                    ...settingsState,
                    dataGridSettings: {
                        ...dataGridState,
                        [module]: {
                            ...moduleState,
                            ...settings
                        }
                    }
                },
                settingsChanged: true
            };
        }

        case RESET_SETTINGS_FLAG:
            return {
                ...state,
                settingsChanged: false
            };

        default:
            return state;
    }
};
