import React, { memo } from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    text: {
        color: '#333333',
        fontSize: spacing.unit * 1.63
    }
});

const SelectedRows = withStyles(styles)(
    memo(({ classes: c, originalIds }) => (
        <Typography className={c.text}>{`${originalIds.length} ${
            originalIds.length > 1 ? 'items' : 'item'
        } selected`}</Typography>
    ))
);

export default SelectedRows;
