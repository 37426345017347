import { takeLatest, select, call, put } from 'redux-saga/effects';
import { formValueSelector, change } from 'redux-form';

import apiRequest from '@libs/apiRequest';
import { setLoading } from 'utilities/redux.actions';

import {
    FETCH_USER_PERMISSIONS_DATA,
    SET_COPY_USER_GRANTED_PERMISSIONS,
    USER_PUBLISH,
    GET_AMEND_USER_DATA
} from '../../redux.actionTypes';
import {
    setUserPermissionsDataLoading,
    setUserPermissionsData,
    setCopyUserPermissionsLoading
} from '../../redux.actions';
import { FORM_NAME } from '../../User/common';
import { normalizePermissionsMenus } from '../../redux.normalizers';
import { userPermissionsDataSelector } from '../../redux.selectors';

import publishWorker from './publishWorker';
import getUserDetailsWorker from './getUserDetailsWorker';

// Workers

const fetchUserPermissionsDataWorker = function*() {
    const { role, reportTo } = yield select(
        formValueSelector(FORM_NAME),
        'role',
        'reportTo'
    );
    try {
        yield put(setUserPermissionsDataLoading(true));
        const { data: { data = {} } = {} } = yield call(apiRequest, {
            url: 'User/GetUserPermissions',
            params: {
                roleId: role,
                reportUserIds: reportTo && reportTo.join(',')
            }
        });
        yield put(setUserPermissionsData(data));
        yield put(setUserPermissionsDataLoading(false));
    } catch (error) {
        yield put(setUserPermissionsDataLoading(false));
    }
};

const setCopyUserGrantedPermissionsWorker = function*({ payload }) {
    if (payload) {
        yield put(setCopyUserPermissionsLoading(true));
        const { data: { data: grantedPermissions = {} } = {} } = yield call(
            apiRequest,
            {
                url: 'User/GetCopyUserPermission',
                params: { email: payload }
            }
        );

        // Normalize menus
        const normalizedGrantedMenus = normalizePermissionsMenus(
            grantedPermissions.menus
        );

        // Rendered permissions data
        const permissionsData = yield select(userPermissionsDataSelector);

        // Sometimes the API returns extra items in granted permissions for copy users which we don't have in the permission data so we need to filter them out
        const filterPermissionsEntity = (data, permissionEntity, idKey) =>
            data
                .filter(({ [idKey]: id }) => permissionEntity.includes(id))
                .map(({ [idKey]: id }) => id);

        // Prepare granted permissions of copy user
        const grantedFilteredPermissions = {
            menus: filterPermissionsEntity(
                permissionsData.menus,
                normalizedGrantedMenus,
                'menuId'
            ),
            retailers: filterPermissionsEntity(
                permissionsData.retailers,
                grantedPermissions.retailers,
                'retailerId'
            ),
            sampleFactories: filterPermissionsEntity(
                permissionsData.sampleFactories,
                grantedPermissions.sampleFactories,
                'sampleFactoryId'
            ),
            orderFactories: filterPermissionsEntity(
                permissionsData.orderFactories,
                grantedPermissions.orderFactories,
                'orderFactoryId'
            )
        };

        yield put(change(FORM_NAME, 'permissions', grantedFilteredPermissions));
        yield put(setCopyUserPermissionsLoading(false));
    }
};

const getAmendUserDataWorker = function*({ payload: email }) {
    if (email) {
        yield put(setLoading(true));

        yield call(getUserDetailsWorker, email);

        yield put(setLoading(false));
    }
};

// Watchers
export default function*() {
    yield takeLatest(
        FETCH_USER_PERMISSIONS_DATA,
        fetchUserPermissionsDataWorker
    );
    yield takeLatest(
        SET_COPY_USER_GRANTED_PERMISSIONS,
        setCopyUserGrantedPermissionsWorker
    );
    yield takeLatest(USER_PUBLISH, publishWorker);
    yield takeLatest(GET_AMEND_USER_DATA, getAmendUserDataWorker);
}
