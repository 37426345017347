import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, CardContent } from '@material-ui/core';

// Local
import styles from './styles';
import CardMedia from './CardMedia';
import { Checkbox } from 'assets';
import { GridItem } from 'components';
import { composeClasses } from 'helpers';

const CardContentBase = ({ classes, styles, label, value }) => {
    const c = composeClasses({ classes, styles });

    const content =
        typeof value === 'boolean' ? <Checkbox checked={value} /> : value;

    return label === 'image' ? (
        <CardMedia image={content} />
    ) : (
        <CardContent classes={{ root: c.root }}>
            <GridItem xs={6} md={6}>
                {label}
            </GridItem>
            <GridItem xs={6} md={6} className={c.rightAlign}>
                {content}
            </GridItem>
        </CardContent>
    );
};

CardContentBase.defaultProps = {
    label: ''
};

CardContentBase.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object //for local image file
    ])
};
const _CardContentBase = withStyles(styles)(CardContentBase);

export { _CardContentBase as default, _CardContentBase as CardContentBase };
