import React from 'react';
import { string, func } from 'prop-types';

import { withStyles, Chip as MuiChip } from '@material-ui/core';

const styles = ({ spacing, palette }) => ({
    root: {
        height: spacing.unit * 3,
        fontSize: spacing.unit * 1.5,
        margin: spacing.unit * 0.25,
        backgroundColor: palette.background.light,
        borderRadius: spacing.unit * 0.5,
        '&:focus': {
            backgroundColor: palette.background.dark
        }
    },
    deleteIcon: {
        width: spacing.unit * 2
    }
});

const propTypes = {
    label: string.isRequired,
    onDelete: func.isRequired
};

const SelectedItem = withStyles(styles)(({ classes: c, label, onDelete }) => {
    return (
        <MuiChip
            label={label}
            className={c.root}
            classes={{ deleteIcon: c.deleteIcon }}
            onDelete={onDelete}
        />
    );
});

SelectedItem.propTypes = propTypes;

export default SelectedItem;
