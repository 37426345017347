import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change } from 'redux-form';

// Material
import { withStyles, Button } from '@material-ui/core';

// Actions
import { postEmailDataRequest } from 'store/actions';

// Local
import styles from './styles';
import { formListener } from 'store/middlewares/formMiddleware';
import ReportsMailFormHeaders from './ReportsMailFormHeaders';
import ReportsMailFormEditor from './ReportsMailFormEditor';
import {
    withModalProvider,
    withModal,
    ModalHeader,
    ModalContent,
    ModalFooter,
} from 'components/Modals'


/*
 * Domain: Reports
 * Page: --
 * Component: Mail
 * Type: Form
 * ReportsMailForm
 */
class ReportsMailForm extends React.Component {
    render() {
        const { props } = this;
        const {
            classes: { header, footer, mailForm, send },
            onClose,
            handleSubmit,
            postEmailData
        } = props;


        return (
            <Fragment>
                <ModalHeader className={header} onClose={onClose}>
                    Mail form
                </ModalHeader>
                <ModalContent className={mailForm}>
                    <ReportsMailFormHeaders />
                    <ReportsMailFormEditor />
                </ModalContent>
                <ModalFooter className={footer}>
                    <Button
                        className={send}
                        color='primary'
                        variant='contained'
                        onClick={handleSubmit(postEmailData)}
                    >
                        Send
                    </Button>
                </ModalFooter>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ auth: { userEmail } }) => ({
    initialValues: {
        cc: userEmail,
        test: 'wow a real value',
    },
});

const mapDispatchToProps = dispatch => ({
    postEmailData: values => dispatch(postEmailDataRequest(values))
});


const mapFormToAction = {
    mailGroup: ({ payload }) => change('ReportsMailForm', 'to', payload),
    template: ({ payload }) => change('ReportsMailForm', 'mail', payload),
};

const _ReportsMailForm = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'ReportsMailForm',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
    formListener(mapFormToAction),
    withStyles(styles)
)(ReportsMailForm);

export { _ReportsMailForm as default, _ReportsMailForm as ReportsMailForm };
