import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    CHARACTERS_PER_LINE,
    LINE_HEIGHT,
    ROW_HEIGHT,
    TABLE_MAP,
    LINE_SPACING_UNITS,
    IMAGE_CONTAINER_WIDTH_UNITS,
    TABLE_MARGIN_UNITS,
    FULL_HEIGHT_UNITS,
    TITLE_HEIGHT_UNITS
} from './constants';

export const useTitle = ({ cadReference, description }) =>
    useMemo(() => {
        const titleDescription =
            description.length > 18
                ? `${description.slice(0, 18).trim()}...`
                : description;

        return `${cadReference} ${titleDescription}`;
    }, [cadReference, description]);

export const useTableData = data =>
    useMemo(
        () =>
            Object.entries(data).reduce(
                (acm, [label, value]) => {
                    if (!value) return acm;
                    else {
                        const { totalLines, tableData } = acm;

                        // Calculates amount of lines needed for the amount of line-breaks
                        const lineBreaks = Math.ceil(
                            value.length / CHARACTERS_PER_LINE
                        );
                        const lines = Math.ceil(
                            (lineBreaks * LINE_HEIGHT) / ROW_HEIGHT
                        );

                        return {
                            totalLines: totalLines + lines,
                            tableData: [
                                ...tableData,
                                { ...TABLE_MAP[label], value, lines }
                            ]
                        };
                    }
                },
                { totalLines: 0, tableData: [] }
            ),
        [data]
    );

export const useVersionsMaxHeight = ({
    totalLines,
    preview,
    theme: { spacing }
}) => {
    const containerWidth = IMAGE_CONTAINER_WIDTH_UNITS * spacing.unit;
    const containerMaxHeight = containerWidth * Math.sqrt(2);

    const [versionsMaxHeight, setVersionsMaxHeight] = useState(
        containerMaxHeight
    );

    const handleImageLoad = useCallback(
        ({ width, height }) => {
            // Calculate absolute lengths
            const pageHeight = FULL_HEIGHT_UNITS * spacing.unit;
            const titleSectionHeight =
                (TABLE_MARGIN_UNITS + TITLE_HEIGHT_UNITS) * spacing.unit;
            const tableSectionMargin = spacing.unit * TABLE_MARGIN_UNITS;
            const tableHeight = LINE_SPACING_UNITS * totalLines * spacing.unit;

            const ratio = containerWidth / width;
            const scaledHeight = height * ratio;

            const imageHeight =
                containerMaxHeight <= scaledHeight
                    ? containerMaxHeight
                    : scaledHeight;

            const tableSectionHeight =
                Math.max(imageHeight, tableHeight) + tableSectionMargin;

            setVersionsMaxHeight(
                pageHeight - tableSectionHeight - titleSectionHeight
            );
        },
        [spacing, totalLines]
    );

    useEffect(() => {
            let image = new Image();

            image.onload = () => {
                handleImageLoad(image);
            };
            image.src = preview;

    }, [preview]);

    return versionsMaxHeight;
};
