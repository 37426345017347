import React from 'react';
import { View } from '@react-pdf/renderer';
import { object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { useCustomDate } from 'sampling/hooks';
import { Title, RichComments } from 'components/PDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

import BasicContentContainer from '../BasicContentContainer';
import Field from '../BasicContentContainer/Field';
import Comment from '../Comment';
import QualityDashboard from './QualityDashboard';

const styles = ({ spacing }) => ({
    container: { position: 'relative' },
    content: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    subTitle: {
        fontSize: spacing.unit * 1.75,
        fontFamily: FONT_FAMILY_BOLD,
        marginTop: spacing.unit * 0.75
    },
    commentsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    regularCommentsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    setup: object.isRequired,
    details: object.isRequired
};

const QCContent = withPDFStyles(styles)(
    ({
        classes: c,
        setup: {
            qcDate,
            sealDate,
            jwpNo,
            qcStyle,
            qcColor,
            factory,
            quantity,
            styleDesc,
            finishWash,
            fabricBatch,
            prodUnit,
            panelStatus,
            bulkProductionUnit,
            versionNo
        },
        details: {
            qcReference,
            front,
            back,
            bodyFabricStatus,
            sampleColorStatus,
            trimFabricStatus,
            accAndTrimStatus,
            missingTrims,
            embellishmentStatus,
            washStatus,
            stichingStatus,
            patternStatus,
            fitStatus,
            typeOfSample,
            composition,
            gsm,
            sealSampleEvaluation: {
                commentsFromBuyer,
                generalInstructions,
                sealOrTrimCardVerification
            },
            preProductionEvaluation: {
                workmanshipComments,
                measurementComments,
                preventiveCorrective
            },
            productRiskAnalysis: {
                fabricCTQ,
                embellishmentCTQ,
                accTrimsCTQ,
                CPRTWashCTQ,
                cuttingCTQ,
                finishingPackingCTQ,
                sewingCTQ,
                bulkProductionComplicacy
            }
        },
        others
    }) => {
        return (
            <View style={c.container}>
                <BasicContentContainer front={front} back={back}>
                    <View style={c.content}>
                        <Field
                            label='QC Evaluation Date'
                            value={useCustomDate(qcDate) || 'N/A'}
                            required
                        />
                        <Field
                            label='Seal By Date '
                            value={useCustomDate(sealDate) || 'N/A'}
                        />
                        <Field label='JWP' value={jwpNo} required />
                        <Field label='Style' value={qcStyle} required />
                        <Field label='Colour' value={qcColor} required />

                        <Field
                            label='Factory'
                            value={factory}
                            style={{ width: '14%' }}
                        />
                        <Field
                            label='QTY'
                            value={quantity}
                            style={{ width: '10%' }}
                        />
                        <Field
                            label='Style Desc'
                            value={styleDesc}
                            style={{ width: '30%' }}
                        />
                        <Field
                            label='Fabric Composition'
                            value={composition}
                            style={{ width: '30%' }}
                        />
                        <Field
                            label='GSM'
                            value={gsm}
                            style={{ width: '12%' }}
                        />

                        <Field label='Finish Wash' value={finishWash} />
                        <Field label='Fabric Batch' value={fabricBatch} />
                        <Field label='Fty Prod Unit' value={prodUnit} />
                        <Field label='Panel Status' value={panelStatus} />
                        <Field label='Body Fabric' value={bodyFabricStatus} />

                        <Field
                            label='Colour Status'
                            value={sampleColorStatus}
                            style={{ width: '17%' }}
                        />
                        <Field
                            label='Trim Fabric'
                            value={trimFabricStatus}
                            style={{ width: '17%' }}
                        />
                        <Field
                            label='Acc & Trims'
                            value={accAndTrimStatus}
                            style={{ width: '22%' }}
                        />
                        <Field
                            label='Acc & Trims Missing'
                            value={missingTrims}
                            style={{ width: '22%' }}
                        />
                        <Field
                            label='Embellishment'
                            value={embellishmentStatus}
                        />

                        <Field label='Wash Status' value={washStatus} />
                        <Field label='Stitching' value={stichingStatus} />
                        <Field label='Pattern' value={patternStatus} />
                        <Field label='Fit' value={fitStatus} />
                        <Field label='Sample Type' value={typeOfSample} />

                        <Field
                            label='Bulk Production Process'
                            value={bulkProductionUnit}
                            style={{ width: '65%' }}
                        />
                        <Field label='Reference' value={qcReference} required />
                        <Field
                            label='Version No'
                            value={versionNo}
                            style={{ width: '14%' }}
                        />
                    </View>
                </BasicContentContainer>
                <Title
                    text='Seal Sample Evaluation (ES GS / Sample Development Team)'
                    style={c.subTitle}
                />
                <View style={c.commentsContainer}>
                    <RichComments
                        label='Comments & Clarification from Buyer'
                        comments={commentsFromBuyer}
                    />
                    <RichComments
                        label='General Instructions'
                        comments={generalInstructions}
                    />
                    <Comment
                        label='Seal/TP/Spec/Trim Card Verification'
                        value={sealOrTrimCardVerification}
                    />
                </View>
                <Title
                    text='Pre Production Sample Evaluation(ES QA Team)'
                    style={c.subTitle}
                />
                <View style={c.regularCommentsContainer}>
                    <Comment
                        label='Workmanship comments'
                        value={workmanshipComments}
                        style={{ width: '33%' }}
                    />
                    <Comment
                        label='Measurement Comments'
                        value={measurementComments}
                        style={{ width: '33%' }}
                    />
                    <Comment
                        label='Preventive & corrective action'
                        value={preventiveCorrective}
                        style={{ width: '33%' }}
                    />
                </View>
                <Title text='Product Risk Analysis' style={c.subTitle} />
                <View style={c.regularCommentsContainer}>
                    <Comment
                        label='Fabric CTQ'
                        value={fabricCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Embellishment CTQ'
                        value={embellishmentCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Acc & trims CTQ'
                        value={accTrimsCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='CPRT & Wash CTQ'
                        value={CPRTWashCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Cutting CTQ'
                        value={cuttingCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Finishing & Packing CTQ'
                        value={finishingPackingCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Sewing CTQ'
                        value={sewingCTQ}
                        style={{ width: '49%' }}
                    />
                    <Comment
                        label='Bulk Production Complicacy'
                        value={bulkProductionComplicacy}
                        style={{ width: '49%' }}
                    />
                </View>
                <Title text='Quality Dashboard' style={c.subTitle} />
                <QualityDashboard {...others} />
            </View>
        );
    }
);

QCContent.propTypes = propTypes;

export default QCContent;
