import React, { Fragment } from 'react';

import Loader from './Loader';
import Snackbar from './Snackbar';
import ReloadPrompt from './ReloadPrompt';
import LoadingPrompt from './LoadingPrompt';
//import ResetDatasets from './ResetDatasets';

export default () => (
    <Fragment>
        <Loader />
        <Snackbar />
        <ReloadPrompt />
        <LoadingPrompt />
        {/* <ResetDatasets /> */}
    </Fragment>
);
