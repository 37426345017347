import {
    SET_USER_PERMISSIONS_DATA_LOADING,
    SET_USER_PERMISSIONS_DATA,
    SET_COPY_USER_GRANTED_PERMISSIONS_LOADING,
    USER_PUBLISH,
    ROUTE_TO_AMEND_USER,
    GET_AMEND_USER_DATA,
    SET_EXPORT_USER_XL_DATA_LOADING
} from './redux.actionTypes';

export const setUserPermissionsDataLoading = loading => ({
    type: SET_USER_PERMISSIONS_DATA_LOADING,
    payload: loading
});

export const setUserPermissionsData = data => ({
    type: SET_USER_PERMISSIONS_DATA,
    payload: data
});

export const setCopyUserPermissionsLoading = loading => ({
    type: SET_COPY_USER_GRANTED_PERMISSIONS_LOADING,
    payload: loading
});

export const userPublish = ({ handleComplete, ...payload }) => ({
    type: USER_PUBLISH,
    meta: { handleComplete },
    payload
});

export const getAmendUserData = email => ({
    type: GET_AMEND_USER_DATA,
    payload: email
});

export const routeToAmendUser = email => ({
    type: ROUTE_TO_AMEND_USER,
    payload: email
});

export const setExportUserXLDataLoading = payload => ({
    type: SET_EXPORT_USER_XL_DATA_LOADING,
    payload
});
