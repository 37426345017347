import createCachedSelector from 're-reselect';

// Local
import { mapToSelect } from 'helpers';
import { selectSamplingPrint } from '../common';

// Selectors
import { selectInkTypesData } from '../../datasets/sampling/inks';

// Ink Types
export const selectInkTypes = createCachedSelector(
	selectInkTypesData,
	inkTypes => mapToSelect(inkTypes)
)(() => 'placeholder');

// Dirty

// Images
export const selectInkImagesList = createCachedSelector(
	selectSamplingPrint,
	samplingPrint => samplingPrint['inkImages']
)(() => 'placeholder');
