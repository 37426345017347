import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME } from '../common';
import { AMEND_CADS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'amendReference',
    label: 'Select Amend Cads',
    placeholder: 'Select reference',
    disabled: false,
    required: false
};

const SelectAmendCads = props => (
    <PaginatedSelect {...props} domain={DOMAIN_NAME} entity={AMEND_CADS} />
);

SelectAmendCads.propTypes = propTypes;
SelectAmendCads.defaultProps = defaultProps;

export { SelectAmendCads as default, SelectAmendCads };
