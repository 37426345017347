import React, { useCallback } from 'react';
import { compose } from 'redux';

import { SettingsOutlined } from '@material-ui/icons';
import { withStyles, IconButton } from '@material-ui/core';

import { withModalProvider, withModal } from '@libComponents/Modal';

import CellsVisibilityModal from './CellsVisibilityModal';

const styles = ({ spacing }) => ({
    button: {
        marginRight: spacing.unit,
        padding: spacing.unit
    }
});

const ManageCellsVisibility = compose(
    withModalProvider,
    withModal({
        handleModal: CellsVisibilityModal
    }),
    withStyles(styles)
)(({ classes, handleModal, chartName }) => {
    const handleManageChartsClick = useCallback(
        () => handleModal({ chartName }),
        [handleModal]
    );
    return (
        <IconButton
            aria-label='visibility-manage'
            className={classes.button}
            onClick={handleManageChartsClick}
        >
            <SettingsOutlined />
        </IconButton>
    );
});

export default ManageCellsVisibility;
