import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import {
    Select as MuiSelect,
    TextField,
    FormControl,
    LinearProgress
} from '@material-ui/core';

// Local
import LabelBase from '../../Label';
import FormHelperText from '../../FormHelperText';
import { composeClasses } from 'helpers';
import SelectCancellableIcon from './SelectCancellableIcon';
import SelectDropdownIcon from './SelectDropdownIcon';
import SelectPlaceholder from './SelectPlaceholder';
import PaginatedSelectDialog from './PaginatedSelectDialog';

/*
 * Domain: --
 * Page: Select
 * Component: Base
 * Type: --
 * SelectBase
 */
class Select extends Component {
    // Set prop types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        isCancellable: PropTypes.bool,
        displayFirst: PropTypes.bool,
        placeholder: PropTypes.string
    };

    // Set default props
    static defaultProps = {
        styles: {},
        displayFirst: false,
        isCancellable: false,
        placeholder: null
    };

    state = {
        data: this.props.data,
        selectStyle: null,
        placeholderStyle: null,
        showPlaceholder: !!this.props.placeholder,
        dialogAnchor: null,
        isDisabled: this.props.disabled || this.props.loading
    };

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props) {
        const {
            classes: { select, selectCancellable },
            isCancellable,
            styles,
            meta: { dirty },
            placeholder
        } = props;

        const nextState = {
            selectStyle: classNames(select, styles.select),
            showPlaceholder: Boolean(placeholder)
        };

        if (isCancellable && dirty) {
            nextState.selectStyle = classNames(
                nextState.selectStyle,
                selectCancellable
            );
        }
        return nextState;
    }

    renderDropdownIcon = () => {
        const { props, state, onFocus } = this;
        const { styles = {} } = props;
        const { isDisabled } = state;
        const propStyles = Object.splice(styles, [
            'dropdownRoot',
            'dropdownIcon'
        ]);
        return (
            <SelectDropdownIcon
                styles={propStyles}
                onClick={onFocus}
                disabled={isDisabled}
            />
        );
    };

    showDialog = event => {
        this.setState({ dialogAnchor: event.currentTarget });
    };

    hideDialog = () => {
        this.setState({ dialogAnchor: null });
        this.props.input.onBlur();
    };

    handleChange = payload => {
        const { onChange, onBlur } = this.props.input;
        onChange(payload);
        onBlur();
    };

    render() {
        const {
            state,
            props,
            renderDropdownIcon,
            showDialog,
            hideDialog,
            handleChange
        } = this;

        const {
            selectStyle,
            showPlaceholder,
            dialogAnchor,
            isDisabled
        } = state;

        const {
            classes,
            styles,
            input,
            meta: { form, touched, error },
            isCancellable,
            placeholder: placeholderValue,
            className,
            label,
            required,
            disabled,
            loading,
            ...restProps
        } = props;

        const c = composeClasses({
            classes: { ...classes, select: selectStyle },
            styles
        });

        const { name, value } = input;

        const id = `${form}-${name}`;

        const selectProps = {
            classes: { ...Object.splice(c, ['root', 'select', 'icon']) },
            IconComponent: renderDropdownIcon,
            disableUnderline: true,
            onClick: showDialog,
            value: 0,
            disabled: isDisabled
        };
        const success = !isDisabled && !!value;
        const hasError = touched && error;

        return (
            <FormControl className={c.container} id={id}>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                    />
                )}

                <FormControl
                    className={classNames(
                        c.control,
                        className,
                        success && c.success,
                        isDisabled && c.disabled,
                        hasError && c.error
                    )}
                >
                    {/*Todo: Convert to InputBase element */}

                    <TextField
                        classes={{
                            root: c.inputRoot
                        }}
                        inputProps={{
                            className: classNames(
                                c.input,
                                isDisabled && c.inputDisabled
                            ),
                            readOnly: true
                        }}
                        value={value}
                        onClick={showDialog}
                        disabled={isDisabled}
                    />

                    {showPlaceholder && !success && !isDisabled && (
                        <SelectPlaceholder
                            styles={Object.splice(c, ['placeholder'])}
                            placeholder={placeholderValue}
                        />
                    )}
                    {isCancellable && success && (
                        <SelectCancellableIcon
                            styles={Object.splice(styles, [
                                'cancellableRoot',
                                'cancellableIcon'
                            ])}
                            onClick={() => handleChange('')}
                        />
                    )}
                    <MuiSelect {...selectProps} />

                    {dialogAnchor && !isDisabled && (
                        <PaginatedSelectDialog
                            hideDialog={hideDialog}
                            anchor={dialogAnchor}
                            handleChange={handleChange}
                            {...restProps}
                        />
                    )}
                    {!disabled && loading && (
                        <LinearProgress
                            classes={{
                                root: c.progress,
                                barColorPrimary: c.progressPrimary
                            }}
                        />
                    )}
                </FormControl>
                {hasError && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

export { Select as default, Select };
