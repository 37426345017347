import React from 'react';
import { SvgIcon } from '@material-ui/core';

const XlsxIcon = props => {
    const primary = props.fill || '3D3D3D';
    const secondary = props.fill || '#B4B4B4';
    return (
        <SvgIcon {...props} viewBox='0 0 229.09 233.57'>
            <g style={{ isolation: 'isolate' }}>
                <g id='Layer_1' data-name='Layer 1'>
                    <path
                        style={{ fill: primary }}
                        d='M202.74,246H11.7a6.79,6.79,0,0,1-6.79-6.79V129.63a6.79,6.79,0,1,1,13.57,0V232.43H195.95v-15a6.79,6.79,0,0,1,13.57,0v21.76A6.79,6.79,0,0,1,202.74,246Z'
                        transform='translate(-4.91 -12.43)'
                    />
                    <polygon
                        style={{ fill: secondary, mixBlendMode: 'soft-light' }}
                        points='6.79 80.8 6.79 78.62 77.86 6.79 77.86 80.8 6.79 80.8'
                    />
                    <rect
                        style={{ fill: secondary, mixBlendMode: 'soft-light' }}
                        x='87.24'
                        y='100.1'
                        width='135.06'
                        height='74.66'
                    />
                    <path
                        style={{ fill: primary }}
                        d='M227.21,105.75H209.52V19.22a6.79,6.79,0,0,0-6.79-6.79H82.29a6.77,6.77,0,0,0-.87.11h0a6.72,6.72,0,0,0-.83.23l-.21.08a6.8,6.8,0,0,0-.78.35h0a6.71,6.71,0,0,0-.75.47l-.18.14a6.72,6.72,0,0,0-.68.59L6.87,86.27a6.71,6.71,0,0,0-.45.5c-.09.12-.18.24-.26.36L6,87.31c-.1.15-.18.3-.27.46l-.07.11c-.08.16-.16.32-.23.48l-.05.12c-.06.16-.12.31-.17.47l-.05.15c0,.15-.08.29-.11.44s0,.13,0,.2S5,90,5,90.16s0,.16,0,.24,0,.28,0,.42,0,.15,0,.23v2.19A6.79,6.79,0,0,0,11.7,100H82.77a6.79,6.79,0,0,0,6.79-6.79V26H196v79.75H92.15a6.79,6.79,0,0,0-6.79,6.79v74.65A6.79,6.79,0,0,0,92.15,194H227.21a6.79,6.79,0,0,0,6.79-6.79V112.53A6.79,6.79,0,0,0,227.21,105.75ZM25.79,86.45,76,35.72V86.45Zm194.63,94H98.94V119.32H220.43Z'
                        transform='translate(-4.91 -12.43)'
                    />
                    <path
                        style={{ fill: '#fff' }}
                        d='M119.31,155.62,112,168.07a2.11,2.11,0,0,1-1.86.8c-2.16,0-5.22-1.71-5.22-3.87a2.71,2.71,0,0,1,.45-1.41L114,150.19l-8.34-13.36a2.84,2.84,0,0,1-.5-1.56c0-2.11,2.86-3.72,5.07-3.72a2.44,2.44,0,0,1,2.36,1.31l6.68,11.65L126,132.87a2.44,2.44,0,0,1,2.36-1.31c2.21,0,5.07,1.61,5.07,3.72a2.85,2.85,0,0,1-.5,1.56l-8.34,13.36,8.69,13.41a2.72,2.72,0,0,1,.45,1.41c0,2.16-3.06,3.87-5.22,3.87a2.05,2.05,0,0,1-1.86-.8Z'
                        transform='translate(-4.91 -12.43)'
                    />
                    <path
                        style={{ fill: '#fff' }}
                        d='M140.25,168.57c-1.71,0-3.42-.8-3.42-2.41V134.22c0-1.66,2-2.36,3.92-2.36s3.92.7,3.92,2.36v27.52h11.45c1.51,0,2.26,1.71,2.26,3.42s-.75,3.41-2.26,3.41H140.25Z'
                        transform='translate(-4.91 -12.43)'
                    />
                    <path
                        style={{ fill: '#fff' }}
                        d='M176.61,158.73c0-6.08-15.92-5-15.92-16.77,0-7.53,6.58-10.4,12.86-10.4,2.66,0,10,.5,10,4.42,0,1.36-.9,4.12-3.11,4.12-1.81,0-2.76-1.91-6.88-1.91-3.57,0-5,1.46-5,3,0,5,15.92,4.07,15.92,16.72,0,7.23-5.27,11.15-12.56,11.15-6.58,0-12.1-3.21-12.1-6.53,0-1.71,1.51-4.22,3.41-4.22,2.36,0,3.87,3.72,8.54,3.72C174.05,162,176.61,161.14,176.61,158.73Z'
                        transform='translate(-4.91 -12.43)'
                    />
                    <path
                        style={{ fill: '#fff' }}
                        d='M200.42,155.62l-7.33,12.45a2.11,2.11,0,0,1-1.86.8c-2.16,0-5.22-1.71-5.22-3.87a2.72,2.72,0,0,1,.45-1.41l8.69-13.41-8.34-13.36a2.84,2.84,0,0,1-.5-1.56c0-2.11,2.86-3.72,5.07-3.72a2.44,2.44,0,0,1,2.36,1.31l6.68,11.65,6.68-11.65a2.44,2.44,0,0,1,2.36-1.31c2.21,0,5.07,1.61,5.07,3.72a2.84,2.84,0,0,1-.5,1.56l-8.34,13.36,8.69,13.41a2.72,2.72,0,0,1,.45,1.41c0,2.16-3.06,3.87-5.22,3.87a2.05,2.05,0,0,1-1.86-.8Z'
                        transform='translate(-4.91 -12.43)'
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export { XlsxIcon as default, XlsxIcon };
