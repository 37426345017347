import React from 'react';
import clsx from 'classnames';

// Material
import { withStyles, InputAdornment } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/DateRangeOutlined';
import DisabledCalendarIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback } from 'react';

const styles = ({ palette, spacing }) => ({
    icon: {
        color: palette.grey[500],
        cursor: 'pointer',
        '&:hover': {
            color: palette.grey[600]
        }
    },

    cancelIcon: {
        color: palette.grey[700],
        fontSize: spacing.unit * 2.5,
        marginRight: spacing.unit * 0.5,
        '&:hover': {
            color: palette.error.main
        }
    },

    disabled: {
        pointerEvents: 'none',
        cursor: 'none',
        color: palette.grey[500],
        '&:hover': {
            color: palette.grey[500]
        }
    }
});

const Adornment = withStyles(styles)(
    ({
        classes: c,
        disabled,
        isCancellable,
        handleClearValue,
        ...restProps
    }) => {
        const handleClear = useCallback(
            e => {
                e.stopPropagation();
                handleClearValue();
            },
            [handleClearValue]
        );
        return (
            <InputAdornment {...restProps} position='end'>
                {!disabled ? (
                    <>
                        {isCancellable && (
                            <CloseIcon
                                className={clsx(c.icon, c.cancelIcon)}
                                onClick={handleClear}
                            />
                        )}
                        <CalendarIcon className={c.icon} />
                    </>
                ) : (
                    <DisabledCalendarIcon />
                )}
            </InputAdornment>
        );
    }
);

export default Adornment;
