import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import ReportTableRow from './ReportTableRow';
import { LINE_SPACING_UNITS } from '../constants';

const propTypes = {
    tableData: PropTypes.array.isRequired,
    totalLines: PropTypes.number.isRequired
};

const styles = ({ spacing, palette }) => ({
    table: {
        width: spacing.unit * 45,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        height: 'auto'
    }
});

const Table = withPDFStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing }, tableData, totalLines }) => {
        return (
            <View
                style={{
                    ...c.table,
                    height: spacing.unit * LINE_SPACING_UNITS * totalLines
                }}
            >
                {tableData.map((rowData, index) => (
                    <ReportTableRow
                        key={`key-report-table-${index}`}
                        isLast={index === tableData.length - 1}
                        {...rowData}
                    />
                ))}
            </View>
        );
    }
);

Table.propTypes = propTypes;

export default Table;
