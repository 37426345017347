export default ({ palette, spacing }) => ({
    root: {
        backgroundColor: palette.background.light,
        margin: 0,
        padding: spacing.unit * 2,
        paddingLeft: spacing.unit * 5,
        paddingRight: spacing.unit * 5,
        borderRadius: 0,
    },
    button: {
        position: 'absolute',
        top: spacing.unit,
        right: spacing.unit,
        color: palette.grey[500],
    },
});