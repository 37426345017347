export const generateReferenceSchema = {
    retailerId: 0,
    originCountryId: 0,
    destinationCountryId: 0,
    userId: 0,
    manualRef: 'string'
};

export const publishSchema = {
    id: 0,
    dispatchRef: 'string',
    isDiscontinued: true,
    versionNo: 0,
    userId: 0,
    trackingReference: 'string',
    notifyFAO: 'string',
    actSentDate: '2023-08-03T09:34:55.521Z',
    eta: '2023-08-03T09:34:55.521Z',
    currierId: 0,
    dispatchDetails: [
        {
            id: 0,
            sampleRef: 'string',
            gender: 'string',
            department: 'string',
            quantity: 0,
            sizeId: 0,
            typeId: 0,
            factoryId: 0,
            colour: 'string',
            remarks: 'string',
            orderNo: 'string',
            designer: 'string',
            seasonId: 0,
            fabricGroup: 'string',
            pos: [
                {
                    poNo: 'string'
                }
            ]
        }
    ]
};

export const dispatchEmailSchema = {
    dispatchRef: 'string',
    userName: 'string',
    toAddress: 'string',
    ccAddress: 'string',
    subject: 'string',
    body: 'string',
    attachment: 'string'
};
