import React from 'react';
import PropTypes from 'prop-types';

// Todo -- Have to fix routing issues for rehydrating state
// import { Stepper } from '@formComponents/Stepper';
import Stepper from '@libComponents/Stepper';
import CadUploadContainer from './CadUploadContainer';
import { useContent } from './hooks';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from './common';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'incomplete', 'amend'])
};
const defaultProps = {
    mode: 'new'
};

const CadUpload = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ mode, handleSubmit: handleReduxFormSubmit }) => (
    <Stepper
        content={useContent(mode)}
        ContainerComponent={CadUploadContainer}
        ContainerProps={{ mode, handleReduxFormSubmit }}
    />
));

CadUpload.propTypes = propTypes;
CadUpload.defaultProps = defaultProps;

export { CadUpload };
