import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const orderReducer = state => state;

const [OrderProvider, useOrderState, useOrderDispatch, useOrder] = makeContext(
    orderReducer,
    initialState,
    { name: 'Order' }
);

export { useOrder as default, OrderProvider, useOrderState, useOrderDispatch };
