import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles, LinearProgress } from '@material-ui/core';

import Brand from './Brand';
import OverlayScrollContainer from '@baseComponents/OverlayScrollContainer';
import useBrandingDetails from '../../useBrandingDetails';
import Header from './Header';
import { orderTrimsLoadingSelector } from 'orders/redux.selectors';

const mapState = state => ({
    loading: orderTrimsLoadingSelector(state)
});

const styles = ({ spacing, palette }) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: spacing.unit * 40.5
    },
    progress: {
        width: '100%',
        backgroundColor: palette.secondary.light,
        height: spacing.unit * 0.5
    },
    progressPrimary: {
        backgroundColor: palette.secondary.main
    }
});

const Brands = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, loading }) => {
    const [{ brands }, dispatch] = useBrandingDetails();

    const makeHandleDelete = useCallback(
        index => () => {
            dispatch({ type: 'removeBrand', payload: index });
        },
        [dispatch]
    );

    const Wrapper = brands.length > 3 ? OverlayScrollContainer : Fragment;

    return (
        <div className={c.root}>
            {loading && (
                <LinearProgress
                    classes={{
                        root: c.progress,
                        barColorPrimary: c.progressPrimary
                    }}
                />
            )}
            {!!brands.length && (
                <Wrapper>
                    <Header />
                    {brands.map(({ labelRef, ...brand }, index) => (
                        <Brand
                            key={`${labelRef}-${index}`}
                            handleDelete={makeHandleDelete(index)}
                            labelRef={labelRef}
                            {...brand}
                        />
                    ))}
                </Wrapper>
            )}
        </div>
    );
});

export default Brands;
