import createCachedSelector from 're-reselect';

// Local
import { selectSamplingPrint } from './common';


export const selectAttachmentsData = ({
    auth: { userName, userId },
    initSamplingPrint: {
        sampleReferenceAutoId,
        sampleRef,
        developmentNo,
        developmentName
    }
}) => ({
    UserId: userId,
    UserName: userName,
    RefId: sampleReferenceAutoId,
    RefNo: sampleRef,
    DevId: developmentNo,
    DevText: developmentName
});

// Bought References
const selectBoughtReferences = createCachedSelector(
    selectSamplingPrint,
    ({ boughtReferences }) => boughtReferences
)(() => 'placeholder');

export const selectBoughtDetailsList = createCachedSelector(
    selectBoughtReferences,
    boughtDetailsTypes => {
        if (
            !boughtDetailsTypes ||
            (Array.isArray(boughtDetailsTypes) && !boughtDetailsTypes.length)
        ) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }

        const boughtDetailsTypesList = boughtDetailsTypes.data || [];
        let value;
        let mainInfo = boughtDetailsTypesList.map(function(bought) {
            value = { value: bought.boughtRef, fullData: bought };
            return [
                { title: bought.boughtRef, value: value },
                { title: bought.tripReference, value: value }
            ];
        });

        return {
            rowInColumnWidth: [60, 40],
            paginationData: boughtDetailsTypes.paginationMetadata,
            columnHeader: ['Bought ref.', 'Trip reference'],
            columnInfo: mainInfo
        };
    }
)(() => 'placeholder');
