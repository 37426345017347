import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FullLoader } from '@libComponents/Loaders';

import { isAuthenticatedSelector } from './redux.selectors';

const PublicContainer = lazy(() => import('./PublicContainer'));
const PrivateContainer = lazy(() => import('../navigation'));

const mapState = state => ({
    authenticated: isAuthenticatedSelector(state)
});

export default withRouter(
    connect(mapState)(({ authenticated }) => {
        return (
            <Suspense fallback={<FullLoader />}>
                {authenticated ? <PrivateContainer /> : <PublicContainer />}
            </Suspense>
        );
    })
);
