import React from 'react';
import { Page, Document } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import { TabularView, Title } from 'components/PDF';
import Details from '../Details';
import SpecDetails from '../SpecDetails';
import Setup from '../Setup';
import Others from '../Others';
import Cad from '../Cad';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';

const styles = () => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    }
});

const Content = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        setup,
        details,
        specDetails,
        fabricDetails,
        others,
        cadInfo,
        docTitle,
        pageTitle
    }) => {
        const { count: fabDetailCount, data: fabricData } = fabricDetails;
        return (
            <Document title={docTitle}>
                <Page size='A4' style={c.page}>
                    <Title
                        text={pageTitle}
                        style={{ fontSize: 18, borderBottom: 1 }}
                    />
                    <Setup {...setup} />
                    <Details {...details} />
                    <SpecDetails {...specDetails} />
                    {fabDetailCount > 0 && (
                        <TabularView
                            data={fabricData}
                            title={'Fabric Details'}
                        />
                    )}
                    <Others {...others} />
                    <Cad {...cadInfo} />
                </Page>
            </Document>
        );
    }
);

export default Content;
