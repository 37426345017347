import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { required } from '@libs/validationRules';

import { DOMAIN_NAME } from 'orders/common';
import { SIZE_SETS } from 'orders/redux.datasets';

const validateSizeSetSame = (fieldValue, { poSizeSetTaken }) => {
    if (!fieldValue) return null;
    const hasMixedSizeSet = poSizeSetTaken.some(
        ({ sizeSet }) => Number(sizeSet) !== Number(fieldValue)
    );
    return hasMixedSizeSet
        ? 'Same size set required for all purchase orders.'
        : null;
};

const sizeSetValidateRules = [required, validateSizeSetSame];

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sizeSet',
    label: 'Size Set',
    placeholder: 'Select size set',
    domain: DOMAIN_NAME,
    entity: SIZE_SETS,
    disabled: false,
    required: true,
    validate: sizeSetValidateRules
};

const SelectSizeSet = props => <Select {...props} />;

SelectSizeSet.propTypes = propTypes;
SelectSizeSet.defaultProps = defaultProps;

export default SelectSizeSet;
