import { formValueSelector, change } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';

import { userIdSelector } from 'auth/redux.selectors';

// Domain
import { FORM_NAME } from 'qualityControl/SealSample/common';

// Local
import { generateReferenceSchema } from './publishWorker/schemas';

export default function*() {
    try {
        const [
            userId,
            { qcStyle: styleRef, qcColor: colorId, ...values }
        ] = yield all([
            select(userIdSelector),
            select(formValueSelector(FORM_NAME), 'jwpNo', 'qcStyle', 'qcColor')
        ]);

        const payload = {
            userId,
            styleRef,
            colorId,
            ...values
        };

        const {
            data: { qcRef, id }
        } = yield call(apiRequest, {
            method: 'post',
            url: 'QcPreSealSamples/GenerateQcPreSealSampleReference',
            data: composeApiPayload(payload, generateReferenceSchema)
        });
        yield put(change(FORM_NAME, 'qcReference', qcRef));
        yield put(change(FORM_NAME, 'id', id));
        return { qcReference: qcRef, id };
    } catch {
        console.log('Error while generating reference');
    }
}
