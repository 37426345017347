import axios from 'axios';
import { call } from 'redux-saga/effects';

import { getCookie } from '@libs/cookies';
import { ACCESS_TOKEN_KEY } from 'auth/constant';

import config from './config';
import uploadProgress from './uploadProgress';

// Transforms object into FormData
// Todo: Make usable for nested objects
// Nested append uses key.key for keys
// take into account recursion
const transformIntoFormData = data =>
    Object.entries(data).reduce((formData, [key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(data => formData.append(key, data));
        } else {
            formData.append(key, value);
        }
        return formData;
    }, new FormData());

const apiClient = axios.create(config);

function* apiRequest({
    type,
    transformData = false,
    uploadToken,
    withAuthToken = true,
    ...rest
}) {
    const AUTH_TOKEN = getCookie(ACCESS_TOKEN_KEY);

    if (withAuthToken)
        apiClient.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${AUTH_TOKEN}`;

    const loadedConfig = {
        ...rest,
        transformRequest: transformData ? transformIntoFormData : null,
        onUploadProgress: uploadToken
            ? yield* uploadProgress(uploadToken)
            : null
    };

    return yield call(apiClient, loadedConfig);
}

export { apiRequest as default, apiRequest, apiClient };
