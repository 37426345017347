import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

const propTypes = {
    title: PropTypes.node.isRequired,
    ActionsAdornment: PropTypes.element
};

const styles = ({ palette, spacing, shadows }) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: spacing.unit * 5,
        paddingLeft: spacing.unit * 1.5,
        paddingRight: spacing.unit * 1.5,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,
        backgroundColor: palette.background.light
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    }
});

const Header = withStyles(styles)(({ classes: c, title, ActionsAdornment }) => {
    return (
        <div className={c.header}>
            <div className={c.title}>{title}</div>
            <div className={c.actions}>{ActionsAdornment}</div>
        </div>
    );
});

Header.propTypes = propTypes;

export default Header;
