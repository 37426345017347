export const normalizeCountries = (countries) =>
    countries.map(({ countryId, countryName }) => ({
        countryId: Number(countryId),
        country: countryName,
    }));

export const normalizeDepartments = ({ data: departments }) =>
    departments.map(({ buyerId, departmentId, department, buyerName }) => ({
        retailerId: Number(buyerId),
        departmentId: Number(departmentId),
        department,
        buyerName,
    }));

export const normalizeFactories = (factories) =>
    factories.map(
        ({ sampleFactoryId, sampleFactoryName, factoryCode, countryid }) => ({
            factoryId: Number(sampleFactoryId),
            factory: sampleFactoryName,
            factoryCode,
            countryId: Number(countryid),
        })
    );

export const normalizeJoinedRetailerFactories = (
    { data: retailersFactories },
    { auth: { userId } }
) =>
    retailersFactories
        .filter(({ userId: id }) => id == userId) // eslint-disable-line eqeqeq
        .map(({ buyerId, factoryId }) => ({
            retailerId: Number(buyerId),
            factoryId,
        }));

export const normalizeRetailers = ({ data: retailers }) =>
    retailers.map(({ retailerId, retailer }) => ({
        retailerId: Number(retailerId),
        retailer,
    }));

export const normalizeCopySampleReferences = ({
    data = [],
    paginationMetadata: { nextPage = 'No' } = {},
}) => ({
    data,
    nextPage: nextPage === 'Yes',
});
