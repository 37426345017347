import { select, call } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';

import {
    cadUploadFormSelector,
    generateCadPayloadSelector
} from '../../redux.selectors';

export default function*({ mode, handleLoading }) {
    if (mode === 'new') {
        const generateCadPayload = yield select(generateCadPayloadSelector);
        const {
            data: { cadRef: cadReference, id }
        } = yield call(apiRequest, {
            method: 'post',
            url: 'Cad/generateCadReference',
            data: generateCadPayload
        });

        yield call(handleLoading);
        return { cadReference, id };
    } else {
        const cadReference = yield select(
            cadUploadFormSelector,
            'cadReference'
        );
        const id = yield select(cadUploadFormSelector, 'id');

        yield call(handleLoading);
        return { cadReference, id };
    }
}
