import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import { withModalProvider, withModal } from '@libComponents/Modal';
import { GridCellContent } from 'dataGrid/components';

import PDFPreview from './PDFPreview';

const propTypes = {
    sampleRef: PropTypes.string.isRequired
};

const styles = ({ palette, typography }) => ({
    gridLinkCell: {
        cursor: 'pointer',
        color: palette.primary.main,
        fontWeight: typography.fontWeightSemi,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const ReferenceLinkModal = compose(
    withModalProvider,
    withModal({
        handleModal: PDFPreview
    }),
    withStyles(styles)
)(({ classes, handleModal, sampleRef }) => {
    const handleClick = useCallback(
        () =>
            handleModal({
                sampleRef
            }),
        [handleModal, sampleRef]
    );

    return (
        <GridCellContent
            content={sampleRef}
            onClick={handleClick}
            className={classes.gridLinkCell}
        />
    );
});

ReferenceLinkModal.propTypes = propTypes;

export { ReferenceLinkModal as default, ReferenceLinkModal };
