import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';
import Checkbox from '@baseComponents/Checkbox';

const styles = ({ typography }) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const SelectAllRow = withStyles(styles)(({ classes: c, checked, onChange }) => (
    <div className={c.container} onClick={onChange}>
        <Checkbox checked={checked} />
        <Typography className={c.checkboxLabel}>Select All</Typography>
    </div>
));

SelectAllRow.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SelectAllRow;
