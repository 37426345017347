import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { Paper, Typography, withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';
import { setSnack } from 'utilities/redux.actions';

import { FORM_NAME } from '../../common';
import {
    routeToFinalPhotoUpload,
    sendFinalPhotoEmail
} from '../../../redux.actions';
import { useFinalPhotoUploadState } from '../../useFinalPhotoUpload';
import {
    finalUploadPDFPropsSelector,
    finalPhotoEmailLoadingSelector
} from '../../../redux.selectors';
import { useReport } from './hooks';

const styles = ({ palette, shadows, spacing, typography }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        boxShadow: shadows[0]
    },
    congratulations: {
        marginTop: spacing.unit * 5.5,
        fontWeight: typography.fontWeightBold,
        fontSize: '3.7vw',
        color: palette.common.black
    },
    uploadedContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: spacing.unit,
        marginBottom: spacing.unit * 3,
        paddingLeft: spacing.unit * 30,
        paddingRight: spacing.unit * 30
    },
    uploaded: {
        fontWeight: typography.fontWeightLight,
        fontSize: '1.8vw',
        whiteSpace: 'nowrap',
        paddingRight: spacing.unit // Space
    },
    uploadedName: {
        color: palette.success.main,
        fontWeight: typography.fontWeightHeavy,
        fontSize: '1.8vw',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: spacing.unit
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 10
    },
    button: {
        height: spacing.unit * 5.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        paddingLeft: spacing.unit * 3.5,
        paddingRight: spacing.unit * 3.5,
        marginRight: spacing.unit,
        marginTop: spacing.unit * 1.5
    },
    alertText: {
        fontSize: spacing.unit * 2.5,
        color: palette.error.main,
        animation: 'fade-in 1s'
    },
    '@keyframes fade-in': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    }
});

const mapState = state => ({
    ...formValueSelector(FORM_NAME)(state, 'sampleRef', 'developmentNo'),
    reportProps: finalUploadPDFPropsSelector(state),
    isEmailSending: finalPhotoEmailLoadingSelector(state)
});

const mapDispatch = dispatch => ({
    routeTo: () => dispatch(routeToFinalPhotoUpload()),
    setSnack: (...args) => dispatch(setSnack.apply(null, args)),
    sendEmail: (...args) => dispatch(sendFinalPhotoEmail.apply(null, args))
});

const Complete = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withStyles(styles)
)(
    ({
        classes: c,
        sampleRef,
        routeTo,
        setSnack,
        reportProps,
        sendEmail,
        isEmailSending
    }) => {
        const [loading, setLoading] = useState(true);
        const { reset } = useFinalPhotoUploadState();

        const [handlePDFURL, PDF, handleRemoveBlobURL] = useReport({
            fileName: `Final.pdf`,
            props: reportProps,
            setSnack
        });

        const handleRouteToNew = useCallback(() => {
            reset();
            routeTo();
        }, [routeTo, reset]);

        useEffect(() => {
            handlePDFURL();
        }, []);

        useEffect(() => {
            if (PDF.url) {
                setLoading(false);
                sendEmail({ PDF, handleRemoveBlobURL });
            }
        }, [PDF]);

        return (
            <Paper className={c.container}>
                <Typography className={c.congratulations} variant='h2'>
                    CONGRATULATIONS!
                </Typography>
                <div className={c.uploadedContainer}>
                    <Typography variant='h4' className={c.uploaded}>
                        You have successfully uploaded final photo for:
                    </Typography>
                    <Typography variant='h4' className={c.uploadedName}>
                        {sampleRef && sampleRef.toUpperCase()}
                    </Typography>
                </div>
                <div className={c.actionsContainer}>
                    {loading || isEmailSending ? (
                        <>
                            <Typography
                                variant='subtitle1'
                                className={c.alertText}
                            >
                                Please do not leave the page. We are in the
                                process of sending an email.
                            </Typography>
                            <Button className={c.button} loading={true}>
                                Email sending..
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography variant='subtitle1'>
                                What would you like to do next?
                            </Typography>
                            <Button
                                onClick={handleRouteToNew}
                                className={c.button}
                            >
                                Send another
                            </Button>
                        </>
                    )}
                </div>
            </Paper>
        );
    }
);

export default Complete;
