import React, { useCallback } from 'react';
import { compose } from 'redux';

import { SettingsOutlined } from '@material-ui/icons';

import IconLabelButton from '@libComponents/IconLabelButton';
import { withModalProvider, withModal } from '@libComponents/Modal';

import ManageColumnsModal from './ManageColumnsModal';

const ManageColumn = compose(
    withModalProvider,
    withModal({
        handleModal: ManageColumnsModal
    })
)(({ className, handleModal, ...rest }) => {
    const handleManageColumnsClick = useCallback(
        () => handleModal({ ...rest }),
        [handleModal]
    );
    return (
        <IconLabelButton
            label='Manage Columns'
            IconComponent={SettingsOutlined}
            className={className}
            onClick={handleManageColumnsClick}
        />
    );
});

export default ManageColumn;
