import React from 'react';

import { USER_ROLES } from '../redux.datasets';
import { DOMAIN_NAME } from '../common';
import RedirectToAmendUser from './components/RedirectToAmendUser';

export const userDatasets = [USER_ROLES];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number',
            resizable: false,
            freezable: false
        },
        {
            name: 'userName',
            header: 'User Name',
            filterable: true,
            type: 'text'
        },
        {
            name: 'emailAddress',
            header: 'Email',
            filterable: true,
            type: 'text',
            render: ({ column, row }) => (
                <RedirectToAmendUser reference={row[column.name]} />
            )
        },
        {
            name: 'roleId',
            header: 'Role',
            filterable: true,
            type: 'select',
            domain: DOMAIN_NAME,
            entity: USER_ROLES
        },
        {
            name: 'reportTo',
            header: 'Report To',
            filterable: true,
            type: 'text'
        },
        {
            name: 'active',
            header: 'Is Active',
            type: 'boolean',
            filterable: true,
            editable: true,
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'isCadDesigner',
            header: 'Is Cad Designer',
            type: 'boolean',
            filterable: true,
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'isShapeDesigner',
            header: 'Is Shape Designer',
            type: 'boolean',
            filterable: true,
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'creatorDate',
            header: 'Created on',
            filterable: true,
            type: 'date'
        },
        {
            name: 'creatorBy',
            header: 'Created by',
            filterable: true,
            type: 'text'
        },
        {
            name: 'modifiedDate',
            header: 'Updated on',
            filterable: true,
            type: 'date'
        },
        {
            name: 'modifiedBy',
            header: 'Updated by',
            filterable: true,
            type: 'text'
        }
    ];
};
