import { useMemo } from 'react';

import { number, absoluteNumber } from '@libs/validationRules';

import { LABELS } from '../common';
import { ASOS_ID, NEWLOOK_ID, PRIMARK_ID } from 'orders/common';
import { checkEverySizeSetValueEquality } from './Details/PurchaseOrdersContainer/validation';

const useSchema = (mode, { retailer, noOfPacks = 1 }) => {
    const setupSchema = useMemo(
        () => ({
            type: true,
            retailer: true,
            noOfPacks: (fieldValue, fieldError) => {
                return fieldValue && !fieldError;
            },
            sampleRefs: (values = [], fieldErrors = []) => {
                if (fieldErrors.length === 0) return true;
                for (let i = 0; i < noOfPacks; i++) {
                    if (!values[i]) {
                        return false;
                    }
                }
                return true;
            }
        }),
        [noOfPacks]
    );

    const detailsSchema = useMemo(
        () => ({
            retailer: true,
            department: true,
            echoOrderNo: true,
            versionNo: true,
            orderAmendedDate: true,
            orderPlacedDate: true,
            ...(Number(retailer) === NEWLOOK_ID
                ? { sampleSendDate: true }
                : {}),
            ...(Number(retailer) === PRIMARK_ID
                ? {
                      clientPONo: (fieldValue, fieldError) => {
                          return fieldValue && !fieldError;
                      },
                      kimballNo: true
                  }
                : {}),
            factory: true,
            factoryRef: true,
            ...(Number(retailer) === ASOS_ID
                ? {}
                : {
                      supplierNo: true,
                      styleNo: true
                  }),
            season: true,
            purchaseOrders: (_, fieldError = []) =>
                fieldError.length === 0 ? true : false
        }),
        [retailer]
    );

    const nonMandatorySchemaStep3 = useMemo(
        () => ({
            attachments: (_, fieldError) => !fieldError,
            BDCommission: fieldValue => {
                const notNumber = number(fieldValue);
                const negativeValue = absoluteNumber(fieldValue);
                return !notNumber && !negativeValue;
            },
            otherCommission: fieldValue => {
                const notNumber = number(fieldValue);
                const negativeValue = absoluteNumber(fieldValue);
                return !notNumber && !negativeValue;
            }
        }),
        []
    );

    const saveDraftSchema = useMemo(
        () => ({
            ...setupSchema,
            echoOrderNo: true,
            purchaseOrders: (purchaseOrders = []) =>
                checkEverySizeSetValueEquality(purchaseOrders),
            ...nonMandatorySchemaStep3
        }),
        [setupSchema]
    );

    const otherSchema = useMemo(
        () => ({
            ...(Number(retailer) === ASOS_ID
                ? {
                      asosDepartment: true
                  }
                : {}),
            ...nonMandatorySchemaStep3
        }),
        [retailer]
    );

    // Combines
    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema,
            ...otherSchema
        }),
        [setupSchema, detailsSchema, otherSchema]
    );

    return useMemo(
        () => ({
            [LABELS.setup]: setupSchema,
            [LABELS.details]: detailsSchema,
            [LABELS.other]: otherSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: saveDraftSchema
        }),
        [
            saveDraftSchema,
            setupSchema,
            detailsSchema,
            otherSchema,
            publishSchema
        ]
    );
};

export default useSchema;
