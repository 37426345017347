// Local
import { composeExistingKeys, composeStoreAmends } from 'helpers';

export const normalizeSampleData = sampleData => [
    Object.apply(
        Object.splice(sampleData, {
            armHoleId: 'armholeId',
            additionalComments: '',
            artWorkFileLocationId: 'artworkFileLocationId',
            artWorkScaleId: 'artworkScaleId',
            dateRequested: '',
            downloadLink: '',
            graphicDescription: '',
            graphicReference: '',
            hemLineId: 'hemlineId',
            labelTypeId: '',
            neckLineId: 'necklineId',
            noofScreens: 'noOfScreens',
            positionId: '',
            positioningMeasurement: '',
            printTypeId: '',
            requestTypeId: '',
            sampleRequiredDate: '',
            sendToBuyerId: '',
            sendToLicId: 'sendToLicensorId',
            sendToUkId: '',
            seperationId: 'separationId',
            specReference: '',
            specDescription: '',
            isSampleReq: 'photoApprovalCheck',
            buyerName: '',
            sampleReference: '',
            // For non-new
            departmentId: '',
            developmentId: '',
            fabricId: 0,
            factoryId: '',
            // Ghost
            designerFrom: '',
            retailerId: '',
            copySampleReference: 'copySampleReference',
            productType: '',
            sampleReferenceAutoId: ''
        }),
        value => value || ''
    ),
    Object.splice(sampleData, [
        'fabrics',
        'cadImage',
        'backnecks',
        'attachments',
        'inks',
        'inkImages',
        'trims',
        'boughts'
    ])
];

export const normalizeAmendSampleData = (
    [sampleData = {}] = [],
    { sample: [colorFormData = {}] = [], ...colorStoreData }
) => {
    const [amendedFields] = normalizeSampleData(colorFormData);
    const [formData, storeData] = normalizeSampleData(sampleData);

    const amendedFormData = composeExistingKeys(amendedFields);
    const amendedStoreData = composeStoreAmends(storeData, colorStoreData);

    return [formData, amendedStoreData, amendedFormData];
};
