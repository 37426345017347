import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';

// Local
import GridDate from '../../../GridDate';

const propTypes = {
    name: PropTypes.string.isRequired,
    filterValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    filterOperatorValue: PropTypes.string,
    onFilterValueChange: PropTypes.func.isRequired
};

const styles = ({ palette, spacing }) => ({
    gridDateRangeWrapper: {
        display: 'flex',
        height: '100%'
    },
    gridDateRangeStart: {
        marginRight: spacing.unit * 0.75
    },
    gridDateFilter: {
        height: '100%',
        width: '100%',
        backgroundColor: darken(palette.background.light, 0.1)
    }
});

const DateFilter = withStyles(styles)(
    ({
        classes: c,
        onFilterValueChange,
        filterValue,
        filterOperatorValue,
        ...restProps
    }) => {
        const isRangeInput = useMemo(
            () => ['inrange', 'notinrange'].includes(filterOperatorValue),
            [filterOperatorValue]
        );
        const [filterDate, setFilterDate] = useState(
            filterValue || (isRangeInput ? { start: '', end: '' } : '')
        );

        const handleOnChange = useCallback(
            field => value => {
                let newValue = value;
                if (isRangeInput) {
                    newValue = { ...filterDate, [field]: value };
                }
                setFilterDate(newValue);
                onFilterValueChange(newValue);
            },
            [isRangeInput, filterDate, onFilterValueChange]
        );

        useEffect(() => {
            if (!filterValue) {
                setFilterDate(isRangeInput ? { start: '', end: '' } : '');
            }
        }, [filterValue, isRangeInput]);

        if (isRangeInput) {
            return (
                <div className={c.gridDateRangeWrapper}>
                    <GridDate
                        placeholder='start'
                        value={filterDate.start}
                        handleOnChange={handleOnChange('start')}
                        styles={{
                            root: c.gridDateFilter,
                            container: c.gridDateRangeStart
                        }}
                        {...restProps}
                    />
                    <GridDate
                        placeholder='end'
                        value={filterDate.end}
                        handleOnChange={handleOnChange('end')}
                        styles={{
                            root: c.gridDateFilter
                        }}
                        {...restProps}
                    />
                </div>
            );
        }

        return (
            <GridDate
                value={typeof filterDate === 'object' ? '' : filterDate}
                handleOnChange={handleOnChange()}
                styles={{ root: c.gridDateFilter }}
                {...restProps}
            />
        );
    }
);

DateFilter.propTypes = propTypes;

export { DateFilter as default, DateFilter };
