import { arrowGenerator } from 'helpers';

export default theme => ({
    textField: {
        marginBottom: theme.spacing.unit * 2.5
    },
    nowrapText: {
        whiteSpace: 'nowrap'
    },
    gridContainer: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.sm,
        margin: 0
    },
    footer: {
        padding: 20
    },
    loader: {
        color: '#ffffff',
        position: 'absolute'
    },
    inputTextField: {
        height: '32px',
        fontSize: '.875rem',
        padding: '6px 15px 7px 20px',
        border: '1px solid  #e6e6e6',
        borderRadius: 4,
        marginTop: -6
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    inputLabelAsterisk: {
        color: 'red'
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px'
    },
    inputHasSuccess: {
        borderColor: '#8fc255'
    },
    inputHasError: {
        borderColor: '#eb5151'
    },
    labelFileField: {
        '& + div': {
            display: 'none'
        }
    },
    browseButtonHTMLLabel: {
        border: '1px solid #e6e6e6',
        borderRadius: 4,
        marginTop: 10,
        display: 'block'
    },
    browseButtonImageWrapper: {
        minHeight: 200
    },
    browseButtonPlaceholder: {
        lineHeight: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#aeaeae',
        fontSize: '1.5rem'
    },
    browseButtonUploadedImage: {
        padding: 30,
        '& img': {
            maxHeight: 140
        },
        '& > p': {
            display: 'block',
            lineHeight: '20px'
        }
    },
    browseButtonRoot: {
        borderTop: '1px solid #e6e6e6',
        height: 45,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex'
    },
    inkUploadButton: {
        border: '1px solid #e6e6e6',
        borderRadius: 4
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 45,
        flexGrow: 1,
        lineHeight: '45px',
        paddingLeft: 15,
        '& span:not(:first-child):before': {
            content: '", "'
        }
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        borderRadius: 0,
        height: 44,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))'
    },
    checkIcon: {
        margin: '7px 15px 0'
    },
    dialogPaper: {
        boxShadow: '0px 0px 21px 0px rgba(0, 4, 52, 0.27)'
    },
    dialogContentRoot: {
        margin: 0,
        padding: 20
    },
    dialogActionsRoot: {
        margin: 0,
        padding: 0
    },
    actionsGridContainer: {
        margin: 0
    },
    inkTable: {
        backgroundColor: '#fcfcfc',
        border: '1px solid #e4e4e4',
        marginTop: 30,
        '& thead': {
            '& tr': {
                height: 40,
                backgroundColor: '#f4f4f4',
                '& th': {
                    color: 'black',
                    paddingRight: 0,
                    paddingLeft: 13,
                    fontSize: '0.875rem',
                    '&:last-child': {
                        width: 110,
                        paddingRight: '13px !important'
                    }
                }
            }
        },
        '& tbody': {
            '& tr': {
                height: 28,
                '& td': {
                    padding: 13,
                    '&:first-child': {
                        '& > img': {
                            maxHeight: 50
                        }
                    }
                }
            }
        }
    },
    inkTableHeaderButton: {
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        marginLeft: 10,
        opacity: 0.5,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&:active': {
            boxShadow: 'none',
            opacity: 1
        }
    },
    inkTableHeaderButtonIcon: {
        marginRight: 5
    },
    inkTableText: {
        marginLeft: 15,
        display: 'inline-block',
        verticalAlign: 'top',
        fontSize: '.75rem',
        '& > span': {
            display: 'block',
            lineHeight: '15px',
            height: 15,
            overflow: 'hidden'
        }
    },
    centerAlign: {
        display: 'block',
        margin: '0 auto'
    },
    customSvgIcon: {
        padding: 6
    },
    tooltipTriggerIcon: {
        marginLeft: 10,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    arrowPopper: arrowGenerator('white'),
    arrow: {
        position: 'absolute',
        border: '1px solid rgab(0, 0, 0, .1)',
        fontSize: 6,
        boxShadow: '0px 3px 21px 0px rgba(0, 4, 52, 0.11)',
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    arrowLabel: {
        boxShadow: '0px 0px 7px 0px rgba(0, 2, 53, 0.29)',
        color: 'black',
        borderRadius: 0,
        backgroundColor: 'white',
        padding: '6px 10px',
        fontSize: '.9rem'
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    },
    cancelButton: {
        width: '100%',
        marginTop: '1%',
        boxShadow: 'none'
    },
    hideThisRow: {
        display: 'none'
    }
});