import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'orders/common';
import { ORDER_NOTES } from 'orders/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const OrderNotes = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            {
                label: 'Retailer',
                canSort: true,
                sortKey: 'retailer'
            },
            { label: 'Note Name', canSort: true, sortKey: 'orderNoteName' },
            { label: 'Note Body', canSort: false },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'ORDER NOTE SETUP',
        domain: DOMAIN_NAME,
        entity: ORDER_NOTES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { OrderNotes as default, OrderNotes };
