import React from 'react';

// Material
import { withStyles } from '@material-ui/core';

const styles = () => ({
    root: {
        position: 'absolute',
        backgroundColor: 'rgba(220, 53, 69, 0.1)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const RowLocked = withStyles(styles)(({ classes: c }) => (
    <div className={c.root}></div>
));

export default RowLocked;
