import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';

import { DOMAIN_NAME } from 'sampling/common';
import { DISPATCH_ORDERS_NO } from 'sampling/redux.datasets';

const propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'orderNo',
    label: 'Select Order No',
    domain: DOMAIN_NAME,
    entity: DISPATCH_ORDERS_NO,
    required: true
};

const SelectPaginatedOrderNo = props => <PaginatedSelect {...props} />;

SelectPaginatedOrderNo.propTypes = propTypes;
SelectPaginatedOrderNo.defaultProps = defaultProps;

export default SelectPaginatedOrderNo;
