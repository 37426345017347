import React from 'react';
import { string, number, oneOfType } from 'prop-types';
import { View } from '@react-pdf/renderer';

import { Field, Title } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    batchNo: string,
    recipeNo: string,
    composition: string,
    yarnCount: string,
    yarnLot: string,
    yarnSupplier: string,
    gsm: oneOfType([string, number])
};

const styles = ({ spacing }) => ({
    title: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldWidth: {
        width: '23%'
    }
});

const YarnDetailsPDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        batchNo,
        recipeNo,
        yarnCount,
        yarnLot,
        yarnSupplier,
        composition,
        gsm
    }) => {
        return (
            <View>
                <Title text='Yarn Details' style={c.title} />
                <View style={c.container}>
                    <Field
                        label='Batch No'
                        value={batchNo}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Recipe No'
                        value={recipeNo}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Yarn Count'
                        value={yarnCount}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Yarn Lot'
                        value={yarnLot}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Yarn Supplier'
                        value={yarnSupplier}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Composition'
                        value={composition}
                        style={{ width: '60%' }}
                        required={false}
                    />
                    <Field
                        label='GSM'
                        value={gsm}
                        style={{ width: '10%' }}
                        required={false}
                    />
                </View>
            </View>
        );
    }
);

YarnDetailsPDFContent.propTypes = propTypes;

export {
    YarnDetailsPDFContent as default,
    propTypes as YarnDetailsPDFContentPropTypes
};
