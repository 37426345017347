import React from 'react';
import PropTypes from 'prop-types';

import { Button, withStyles } from '@material-ui/core';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

const defaultProps = {};
const styles = ({ palette, spacing, typography, shadows }) => ({
    root: {
        display: 'flex',
        height: spacing.unitHeight,
        width: '100%',

        borderTopWidth: 1,
        borderTopColor: palette.background.dark,
        borderTopStyle: 'solid',
        borderRadius: 0
    },
    labelButton: {
        width: '80%',
        height: '100%',
        justifyContent: 'start',
        textTransform: 'none',
        color: palette.secondary.light,
        fontWeight: typography.fontWeightLight,

        '&:hover': {
            backgroundColor: palette.common.white
        }
    },
    labelButtonLabel: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'left'
    },
    upload: {
        width: '20%',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        height: '100%',
        color: palette.common.white,
        fontSize: typography.caption.fontSize,
        fontWeight: typography.fontWeightHeavy,
        borderRadius: 0,
        textTransform: 'none',
        boxShadow: shadows[0],
        background: `linear-gradient(to bottom, ${palette.success.main}, ${palette.success.dark})`,

        '&:hover': {
            background: `linear-gradient(to bottom, ${palette.success.dark}, ${palette.success.dark})`
        }
    }
});

const Footer = withStyles(styles)(({ classes: c, onClick, label }) => {
    return (
        <div className={c.root}>
            <Button
                classes={{ root: c.labelButton, label: c.labelButtonLabel }}
                onClick={onClick}
            >
                {label}
            </Button>
            <Button className={c.upload} onClick={onClick}>
                Upload
            </Button>
        </div>
    );
});

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
