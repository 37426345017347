import React, { useEffect, useMemo } from 'react';
import { array, object, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';

import TileView from './TileView';
import ListView from './ListView';
import NoDataFound from './NoDataFound';
import useGridView from '../useGridView';
import { tileViewDataSelector } from 'gridView/customSelectors';

const styles = () => ({
    container: {
        flex: 1
    }
});

const propTypes = {
    data: array.isRequired,
    tileView: object,
    moduleName: string.isRequired,
    reportsInfo: object.isRequired,
    gridLoading: bool
};

const Content = withStyles(styles)(
    ({
        data: gridData,
        gridLoading,
        tileView: { show = false, columns = [] } = {},
        moduleName,
        reportsInfo,
        dataLinkable,
        disableCheckbox = false,
        disableEditIcon = false,
        classes: c
    }) => {
        const [{ data, viewType }, dispatch] = useGridView();
        const tileViewData = show
            ? tileViewDataSelector(gridData, columns)
            : [];

        useEffect(() => {
            dispatch({
                type: 'grid-initialize',
                payload: {
                    data: gridData,
                    moduleName,
                    reportsInfo,
                    dataLinkable,
                    tileView: { show, data: tileViewData },
                    disableCheckbox,
                    disableEditIcon
                }
            });
        }, [gridData]);

        const content = useMemo(() => {
            if (data.length === 0) {
                return <NoDataFound gridLoading={gridLoading} />;
            }
            if (viewType === 'tile') {
                return <TileView data={tileViewData} />;
            }
            return <ListView />;
        }, [data.length, gridLoading, tileViewData, viewType]);

        return <div className={c.container}>{content}</div>;
    }
);

Content.propTypes = propTypes;

export { Content as default, Content };
