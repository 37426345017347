import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

import { Accordion, AccordionPanel, List } from 'components';

import styles from './styles';

const mainListItems = props => {
    const { classes, menus } = props;
    const Menu = ({ children, ...restProps }) => {
        return <Accordion {...restProps}>{children}</Accordion>;
    };

    const MenuItem = ({ children, ...restProps }) => {
        return (
            <AccordionPanel reverse={true} {...restProps}>
                {children}
            </AccordionPanel>
        );
    };

    const CreateMenuItem = ({ menu }) => {
        if (menu) {
            const { menuName, menuId, children, url, icon, depth } = menu;

            if (children.length) {
                return (
                    <Menu>
                        <MenuItem
                            tag={menuId}
                            title={menuName}
                            icon={icon}
                            depth={depth}
                        >
                            {children.map((cmenu, idx) => (
                                <CreateMenuItem key={idx} menu={cmenu} />
                            ))}
                        </MenuItem>
                    </Menu>
                );
            } else {
                return (
                    <Link
                        to={url}
                        title={menuName}
                        className={classNames(classes.anchorStyle)}
                    >
                        <ListItem
                            className={classes.listItem}
                            style={{ paddingLeft: `${depth * 16}px` }}
                        >
                            {icon ? (
                                <ListItemIcon>
                                    <img
                                        className={classes.image}
                                        src={icon}
                                        alt={menuName}
                                    />
                                </ListItemIcon>
                            ) : null}
                            <ListItemText
                                primary={menuName}
                                classes={{
                                    root: classes.textPadding,
                                    primary: classes.textColor
                                }}
                            />
                        </ListItem>
                    </Link>
                );
            }
        }

        return;
    };

    const CreateMenu = menuList => {
        if (menuList.length) {
            return (
                <List
                    disablePadding={true}
                    dense={true}
                    className={classes.list}
                >
                    {menuList.map((menu, index) => (
                        <CreateMenuItem key={index} menu={menu} />
                    ))}
                </List>
            );
        }

        return [];
    };

    return CreateMenu(menus);
};

mainListItems.propTypes = {
    classes: PropTypes.object
};

const _mainListItems = withStyles(styles)(mainListItems);

export { _mainListItems as default, _mainListItems as mainListItems };
