import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { FORM_NAME } from 'orders/Order/common';
import { orderMailSubjectSelector } from 'orders/redux.selectors';
import {
    setOrderEmailReceivers,
    setOrderEmailBody
} from 'orders/redux.actions';
import { OrderEmailGroup, OrderEmailBody } from 'orders/components';

const mapStateToProps = (state, { mode, statusActionName }) => ({
    retailer: formValueSelector(FORM_NAME)(state, 'retailer'),
    emailSubject: orderMailSubjectSelector(state, mode, statusActionName)
});

const mapInputs = {
    groups: ({ payload }) => setOrderEmailReceivers(payload),
    templates: ({ payload }) => setOrderEmailBody(payload)
};

const MailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: (values, dispatch, { handleSave }) => {
            handleSave(values);
        }
    }),
    connect(mapStateToProps),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        handleSubmit,
        valid,
        handlePublish,
        closePreviewModal,
        retailer,
        emailSubject,
        initialize
    }) => {
        const publishCallbackHandler = handlePublish({
            closePreviewModal,
            closeMailModal
        });

        const handleMailSend = useCallback(() => {
            handleSubmit();
            setTimeout(() => publishCallbackHandler(), 1);
        }, [handleSubmit, publishCallbackHandler]);

        useEffect(() => {
            initialize({ subject: emailSubject });
        }, []);

        const mailProps = {
            MailGroupComponent: <OrderEmailGroup retailer={retailer} />,
            MailTemplatesComponent: <OrderEmailBody retailer={retailer} />,
            handleModalClose: closeMailModal,
            handleSubmit: handleMailSend,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default MailModal;
