import { createSelector } from 'reselect';

import { valueSelector } from '@libs/datasets';
import {
    ALL_DESIGNERS,
    ARTWORK_SOURCES,
    TYPES,
    LICENSORS,
    PROPERTIES
} from 'cadUpload/redux.datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import {
    STYLES,
    CATEGORIES,
    GENDERS,
    AGES,
    DESIGN_DEPARTMENT,
    SHAPE_DESIGNERS
} from 'common/redux.datasets';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';
import { RETAILERS, DEPARTMENTS } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import { DOMAIN_NAME } from './common';
import { DOMAIN_NAME as CAD_VIEW_DOMAIN_NAME } from './common';
import { CAD_AVAILABILITY } from './redux.datasets';

const cadViewSelector = state => state[DOMAIN_NAME];

const downloadCadAsExcelLoadingSelector = createSelector(
    cadViewSelector,
    ({ downloadCadAsExcelLoading }) => downloadCadAsExcelLoading
);

const cadViewPDFReportSelectedDataSelector = createSelector(
    state => state,
    (_, data) => data,
    (_, __, selectedRows) => selectedRows,
    (state, data, selectedRows) => {
        const selectedCads = data.filter(cad => selectedRows.includes(cad.id));

        return selectedCads.map(item => {
            return {
                ...item,
                processedBy: valueSelector(
                    state,
                    CAD_DOMAIN_NAME,
                    ALL_DESIGNERS,
                    item.processedById
                ),
                cadType: valueSelector(
                    state,
                    CAD_DOMAIN_NAME,
                    TYPES,
                    item.cadTypeId
                ),
                licensor: valueSelector(
                    state,
                    CAD_DOMAIN_NAME,
                    LICENSORS,
                    item.licensorId
                ),
                property: valueSelector(
                    state,
                    CAD_DOMAIN_NAME,
                    PROPERTIES,
                    item.propertyId
                ),
                artworkSource: valueSelector(
                    state,
                    CAD_DOMAIN_NAME,
                    ARTWORK_SOURCES,
                    item.artworkSourceId
                ),
                retailer: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    RETAILERS,
                    item.retailerId
                ),
                department: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    DEPARTMENTS,
                    item.departmentId
                ),
                category: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    CATEGORIES,
                    item.categoryId
                ),
                style: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    STYLES,
                    item.styleId
                ),
                gender: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    GENDERS,
                    item.genderId
                ),
                age: valueSelector(state, COMMON_DOMAIN_NAME, AGES, item.ageId),
                designDepartment: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    DESIGN_DEPARTMENT,
                    item.designDepartmentId
                ),
                shapeDesigner: valueSelector(
                    state,
                    COMMON_DOMAIN_NAME,
                    SHAPE_DESIGNERS,
                    item.shapeDesignerId
                ),
                availability: valueSelector(
                    state,
                    CAD_VIEW_DOMAIN_NAME,
                    CAD_AVAILABILITY,
                    item.cadAvailityId
                )
            };
        });
    }
);
export {
    downloadCadAsExcelLoadingSelector,
    cadViewPDFReportSelectedDataSelector
};
