import React, { createContext, useContext, useReducer, useMemo } from 'react';

const initialState = {
    width: '33%',
    fontSize: '8'
};

const PDFColumnContext = createContext();

const usePDFColumnContext = () => {
    const state = useContext(PDFColumnContext);
    if (state === void 0) {
        throw new Error(`usePDFColumnContext must be used within its Provider`);
    }
    return state;
};

const PDFColumnContextProvider = ({ children, columns }) => {
    const makeState = useMemo(() => {
        let state = initialState;
        if (columns.length <= 2) {
            state = { width: '50%', fontSize: '10' };
        }
        return state;
    }, [columns]);
    const [PDFColumnState, _] = useReducer(state => state, makeState);
    const value = { PDFColumnState };
    return (
        <PDFColumnContext.Provider value={value}>
            {children}
        </PDFColumnContext.Provider>
    );
};

export { usePDFColumnContext as default, PDFColumnContextProvider };
