import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Local
import { mapToSelect } from 'helpers';

// Selectors
import { selectDevelopmentTypesData } from '../datasets/common';
import {
    selectRequestTypesData,
    selectSendToBuyersData,
    selectSendToLicensorsData,
    selectSendToUksData
} from '../datasets/sampling/reference';
import { selectFactoriesData } from '../datasets/sampling/request';
import { selectRetailerFactories } from '../sampling/newRequest';

export const selectDevelopmentTypes = createCachedSelector(
    selectDevelopmentTypesData,
    mapToSelect
)(() => 'placeholder');

export const selectRequestTypes = createCachedSelector(
    selectRequestTypesData,
    mapToSelect
)(() => 'placeholder');

export const selectSendToBuyers = createCachedSelector(
    selectSendToBuyersData,
    mapToSelect
)(() => 'placeholder');

export const selectSendToLicensors = createCachedSelector(
    selectSendToLicensorsData,
    mapToSelect
)(() => 'placeholder');

export const selectSendToUks = createCachedSelector(
    selectSendToUksData,
    mapToSelect
)(() => 'placeholder');

// Dirty
const printFormSelector = formValueSelector('SamplingPrintForm');
const selectDesignerFrom = state => printFormSelector(state, 'designerFrom');
export const selectRetailerId = state => printFormSelector(state, 'retailerId');
const selectFactoryId = state => printFormSelector(state, 'factoryId');

// Factories
const selectFactoryLocation = createCachedSelector(
    selectFactoriesData,
    selectFactoryId,
    (factories = [], factoryId) => {
        const [{ countryId } = {}] = factories.filter(
            ({ factoryId: id }) => factoryId == id
        );
        return countryId;
    }
)(state => selectFactoryId(state) || 'empty'); // Todo: fix cache key

export const selectFactoryNames = createCachedSelector(
    selectFactoriesData,
    state => selectRetailerFactories(state, selectRetailerId(state)),
    selectFactoryLocation,
    (factories = [], retailerFactories, factoryLocation) =>
        mapToSelect(
            factories.filter(
                ({ factoryId, countryId }) =>
                    retailerFactories.includes(factoryId) &&
                    countryId === factoryLocation
            )
        )
)(state => selectFactoryLocation(state) + selectRetailerId(state));

export const selectLicenseType = createCachedSelector(
    selectDesignerFrom,
    designerFrom => {
        const licenseTypes = ['L', 'DL'];
        return licenseTypes.includes(designerFrom);
    }
)(state => selectDesignerFrom(state) || 'empty');
