// sampling-print step 1
function getDesignTypes() {
	return [
		{ value: 'K', label: 'INHOUSE' },
		{ value: 'L', label: 'ECHO LICENCE' },
		{ value: 'DL', label: 'DIRECT FROM LICENSOR' },
		{ value: 'B', label: 'FROM BUYER' }
	];
}

// sampling-print step 1
function getProductTypes(type) {
	let productType = [{ value: 'P', label: 'PRINT' }];
	switch (type) {
		case 'P':
			productType = [{ value: 'P', label: 'PRINT' }];
			break;
		case 'NP':
			productType = [{ value: 'NP', label: 'SHAPE' }];
			break;
		default:
			productType = [{ value: 'P', label: 'PRINT' }];
			break;
	}

	return productType;
}

// sampling-print step 5 = Trim modal
function getTrimFromList() {
	return [
		{ value: 'K', label: 'INHOUSE' },
		{ value: 'B', label: 'FROM BUYER' },
		{ value: 'L', label: 'LICENSE' }
	];
}

export { getDesignTypes, getProductTypes, getTrimFromList };
