import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Local
import {
    GridContainer,
    GridItem,
    Input,
    MultilineInput,
    Select,
    NavigateButton,
    PaginatedSelectTest,
} from 'components';
import { SamplingEditor } from 'views/Sampling/components';
import SamplingCadImageBrowser from './SamplingCadImageBrowser';
import SamplingInkDetailsTable from './SamplingInkDetailsTable';
import SamplingInkImagesTable from './SamplingInkImagesTable';
import { formListener } from 'middlewares/formMiddleware';
import { validateRequired } from 'helpers';

// Actions
import { fetchDatasets, setGraphicDescription } from 'actions';

// Selectors
import {
    selectGraphicRefList,
    selectInkDetails,
    selectInkImagesList,
    selectArtworkLocations,
    selectArtworkScales,
    selectDesigners,
    selectPrintPositions,
    selectPrintTypes,
} from 'selectors';

// Entities
import {
    GRAPHIC_REFERENCES,
    COLLECTIONS,
    LICENSORS,
    ARTWORK_LOCATIONS,
    ARTWORK_SCALES,
    DESIGNERS,
    PRINT_POSITIONS,
    PRINT_TYPES,
    INK_TYPES,
} from 'libs';

const validate = ({ copySampleRefCheck, manualSampleRefCheck, ...values }) => {
    const required = [
        'graphicReference',
        'artworkFileLocationId',
        'graphicDescription',
        'printTypeId',
        'artworkScaleId',
        'cadImage',
    ];
    return { ...validateRequired(values, required) };
};

class SamplingPrintArtwork extends Component {
    componentDidMount() {
        const { fetchDatasets } = this.props;

        fetchDatasets([
            GRAPHIC_REFERENCES,
            COLLECTIONS,
            LICENSORS,
            ARTWORK_LOCATIONS,
            ARTWORK_SCALES,
            DESIGNERS,
            PRINT_POSITIONS,
            PRINT_TYPES,
            INK_TYPES,
        ]);
    }

    render() {
        const { props } = this;
        const {
            onSubmit,
            invalid,
            handleAddInksModal,
            artworkLocations,
            artworkScales,
            designers,
            printPositions,
            printTypes,
            inkDetailsData, //Todo: yikes,
            inkImagesData,
        } = props;
        return (
            <Fragment>
                <GridContainer>
                    <GridItem>
                        <PaginatedSelectTest
                            name='graphicReference'
                            label='Graphic Reference'
                            placeholder='Please select reference'
                            required
                            entity={GRAPHIC_REFERENCES}
                            addHandler={() => console.log('cad modal')}
                        />
                        <Select
                            name='artworkFileLocationId'
                            label='Artwork File Location'
                            required
                            data={artworkLocations}
                        />
                        <Input
                            name='downloadLink'
                            label='Download Link'
                            placeholder='www.link.com'
                        />
                        <Select
                            name='artworkScaleId'
                            label='Scale'
                            required
                            data={artworkScales}
                        />
                    </GridItem>
                    <GridItem>
                        <MultilineInput
                            name='graphicDescription'
                            label='Graphic Description'
                            placeholder='Please write a description'
                            required
                        />
                        <Select
                            name='positionId'
                            label='Position'
                            disabled
                            data={printPositions}
                        />
                        <Input
                            name='positioningMeasurement'
                            label='Position Measurement'
                            placeholder='Please write measurement'
                            disabled
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            name='separationId'
                            label='Separated By'
                            data={designers}
                        />
                        <Select
                            name='printTypeId'
                            label='Print Type'
                            required
                            data={printTypes}
                        />
                        <Input
                            name='noOfScreens'
                            label='Number of Screens'
                            type='number'
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer hasDivider>
                    <GridItem md={4}>
                        <SamplingCadImageBrowser />
                        <SamplingEditor
                            name='additionalComments'
                            label='Additional Comments'
                            placeholder='Write additional comments'
                        />
                    </GridItem>

                    <GridItem md={4}>
                        <SamplingInkDetailsTable
                            data={inkDetailsData}
                            handleModal={handleAddInksModal}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SamplingInkImagesTable
                            data={inkImagesData}
                            handleModal={handleAddInksModal}
                        />
                    </GridItem>
                </GridContainer>

                <NavigateButton onClick={onSubmit} disabled={invalid}>
                    Next
                </NavigateButton>
            </Fragment>
        );
    }
}

const mapState = (state) => ({
    graphicRefData: selectGraphicRefList(state),
    artworkLocations: selectArtworkLocations(state),
    artworkScales: selectArtworkScales(state),
    designers: selectDesigners(state),
    printPositions: selectPrintPositions(state),
    printTypes: selectPrintTypes(state),
    inkDetailsData: selectInkDetails(state),
    inkImagesData: selectInkImagesList(state),
});

const mapDispatch = {
    fetchDatasets,
};

const mapInputs = {
    graphicReference: ({ payload }) => setGraphicDescription(payload),
};

const _SamplingPrintArtwork = compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: 'SamplingPrintForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        validate,
    }),
    formListener(mapInputs)
)(SamplingPrintArtwork);

export {
    _SamplingPrintArtwork as default,
    _SamplingPrintArtwork as SamplingPrintArtwork,
};
