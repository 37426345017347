import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, reduxForm } from 'redux-form';

import { withStyles, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { GridItem, Input, Editor } from '@formComponents';
import { AttachmentDetails } from 'components';
import { setReloadAction } from 'utilities/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';

// Local
import HeaderAdornment from '../HeaderAdornment';
import UploadTechPack from './UploadTechPack';
import { BoughtContainer, ArtworkLink } from '../../../components';
import { getBoughtDetailData } from '../../../redux.actions';
import { FORM_NAME, LABELS } from '../../common';

const styles = ({ spacing }) => ({
    wrapper: {
        marginBottom: spacing.unit * 4
    },
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 4
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 48
        }
    },
    dateRequested: {
        paddingRight: spacing.unit * 0.5
    },
    dateRequiredBy: {
        paddingLeft: spacing.unit * 0.5
    },
    divider: {
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    }
});

const mapInputs = {
    boughtReference: ({ payload }) =>
        getBoughtDetailData({ boughtReference: payload, formName: FORM_NAME }),
    techPack: ({ payload }) => {
        const shouldPrompt =
            payload.hasOwnProperty('url') && isBlobUrl(payload.url);
        const action = change(FORM_NAME, 'techPack', '');

        return setReloadAction({
            field: 'techPack',
            shouldPrompt,
            action
        });
    },
    attachments: ({ payload }) => {
        const shouldPrompt = payload.some(
            attachment =>
                attachment.hasOwnProperty('url') && isBlobUrl(attachment.url)
        );

        const nonBlobAttachments = payload.filter(
            attachment =>
                !(attachment.hasOwnProperty('url') && isBlobUrl(attachment.url))
        );
        const action = change(FORM_NAME, 'attachments', nonBlobAttachments);
        return setReloadAction({ field: 'attachments', shouldPrompt, action });
    }
};

const mapState = state => ({
    initialValues: {
        numberOfColours: '1'
    }
});

const Other = compose(
    connect(mapState),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c }) => {
    return (
        <div className={c.wrapper}>
            <ContentContainer
                title={LABELS.other}
                AdornmentComponent={<HeaderAdornment />}
            >
                <Grid container className={c.container}>
                    <GridItem md={6}>
                        <Grid container>
                            <GridItem md={6}>
                                <Input
                                    name='numberOfColours'
                                    label='Number of colours'
                                    placeholder='Enter number'
                                    type='number'
                                    min='0'
                                />
                            </GridItem>
                            <GridItem md={6}>
                                <UploadTechPack />
                            </GridItem>
                            <GridItem md={12}>
                                <AttachmentDetails />
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem md={6}>
                        <Grid container>
                            <GridItem md={12}>
                                <Editor
                                    name='additionalComments'
                                    label='Additional Comments'
                                    placeholder='Write comments here'
                                    styles={{ editor: c.editor }}
                                />
                            </GridItem>
                            <GridItem md={10}>
                                <ArtworkLink formname={FORM_NAME} />
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </ContentContainer>
            <BoughtContainer />
        </div>
    );
});

export default Other;
