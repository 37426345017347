import React from 'react';
import PropTypes from 'prop-types';

// Libs
import { withField } from '@libs/reduxForm';
import BaseStepper from '@libComponents/Stepper';

const componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

const propTypes = {
    name: PropTypes.string,
    content: PropTypes.objectOf(componentPropType).isRequired,
    ContainerComponent: componentPropType,
    ContainerProps: PropTypes.object
};

const defaultProps = { name: 'stepper' };

const Stepper = withField()(
    ({ classes: c, name, input: { value, onChange }, ...props }) => {
        return (
            <BaseStepper
                {...props}
                initialState={!!value ? value : {}}
                storeState={onChange}
            />
        );
    }
);

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export { Stepper as default, Stepper };
