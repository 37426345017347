import { call, put, takeLatest, select } from 'redux-saga/effects';
import { change, setSubmitSucceeded } from 'redux-form';

// Local
import { apiRequest, newApi } from 'store/config';

// Actions
import { setRoute, fetchEmailDataSuccess, closeModal } from 'store/actions';

// Action Types
import {
    FETCH_EMAIL_DATA_REQUEST,
    POST_EMAIL_DATA_REQUEST
} from 'store/actionTypes';

// Selectors
import { selectMailData } from 'store/selectors';

// Workers
function* fetchEmailDataWorker({
    payload: { reportId: reportid, module: moduleName }
}) {
    const {
        data: {
            data: { mailBody, toMailGroup, subject }
        }
    } = yield call(apiRequest, {
        method: 'get',
        uri: 'api/Emails/GetEmailData',
        queries: {
            reportid,
            moduleName
        }
    });

    const mailData = {
        mailGroupDataset: toMailGroup.map(({ groupName, groupText }) => ({
            value: groupText,
            label: groupName
        })),
        templateDataset: mailBody.map(({ bodyName, bodyText }) => ({
            value: bodyText,
            label: bodyName
        }))
    };

    yield put(fetchEmailDataSuccess(mailData));
    yield put(change('ReportsMailForm', 'subject', subject));
}

function* postEmailDataWorker({ payload: { to, cc, subject, mail } }) {
    const {
        pdfReportId,
        xlsxReportId,
        module,
        userId,
        userName,
        userEmail
    } = yield select(selectMailData);

    yield call(apiRequest, {
        method: 'post',
        uri: 'api/Emails/SendEmailData',
        data: {
            PdfReportId: pdfReportId,
            ExcelReportId: xlsxReportId,
            UserId: userId,
            UserName: userName,
            UserEmail: userEmail,
            ModuleName: module,
            ToMail: to,
            CcMail: cc,
            Subject: subject,
            MailBody: mail
        }
    });

    yield put(closeModal('handleModalReportsMailForm'));
    // flashMessage('Mail successfully sent!');
}

// Watchers
const mailWatchers = function*() {
    yield takeLatest(FETCH_EMAIL_DATA_REQUEST, fetchEmailDataWorker);
    yield takeLatest(POST_EMAIL_DATA_REQUEST, postEmailDataWorker);
};

export { mailWatchers as default, mailWatchers };
