import React, { Fragment, useContext } from 'react';

import { withStyles, Stepper } from '@material-ui/core';

import Step from './Step';
import Progress from './Progress';
import { useStepperState } from '../StepContext';
import { useStepPadding } from './hooks';

const styles = ({ palette, spacing }) => ({
    container: {
        position: 'relative'
    },
    root: {
        backgroundColor: palette.common.transparent,
        justifyContent: 'space-between',
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2,
        paddingLeft: `calc(var(--padding) - ${(spacing.unit * 3) / 4}px)`,
        paddingRight: `calc(var(--padding) - ${(spacing.unit * 3) / 4}px)`,
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 4
    }
});

const StepperBar = withStyles(styles)(({ classes: { container, ...c } }) => {
    const { activeStep, contents } = useStepperState();

    return (
        <div className={container}>
            <Stepper
                classes={c}
                activeStep={activeStep}
                connector={<Fragment />}
                style={useStepPadding()}
            >
                {contents.map((content, index) => (
                    <Step key={`stepper-${index}`} index={index} {...content} />
                ))}
            </Stepper>
            <Progress />
        </div>
    );
});

export { StepperBar as default };
