import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../../common';
import { STOCKPILE_STATUS } from '../../../redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    disabled: false,
    required: false,
    domain: DOMAIN_NAME,
    entity: STOCKPILE_STATUS
};

const SelectStockpileStatus = props => <Select {...props} />;

SelectStockpileStatus.propTypes = propTypes;
SelectStockpileStatus.defaultProps = defaultProps;

export { SelectStockpileStatus as default, SelectStockpileStatus };
