import React from 'react';
import { withStyles, Popover, Grid } from '@material-ui/core';
import { compose } from 'redux';
import { reduxForm, formValueSelector, initialize, change } from 'redux-form';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';

import Button from '@libComponents/Button';
import { GridItem } from '@formComponents';
import { Date as DateInput } from '@formComponents';
import useMinDate from '@libHooks/useMinDate';
import { isGridFilterItemSelectedSelector } from 'gridView/redux.selectors';

import {
    SelectRetailers,
    SelectFactory,
    SelectDepartments
} from 'sampling/components';
import {
    SelectShapeDesigners,
    SelectCategories,
    SelectGenders,
    SelectStyles,
    SelectAges
} from 'common/components';
import { formListener } from 'app/ReduxGate/store/middleware';
import { setGridFilterRetailerChildren } from 'gridView/redux.actions';
import { FILTERS_FORM_NAME as FORM_NAME } from 'common/common';

const INITIAL_VALUES = {
    retailer: '',
    factory: '',
    designer: '',
    startDt: '',
    endtDt: '',
    genderId: '',
    ageId: '',
    categoryId: '',
    department: '',
    styleId: ''
};

const styles = ({ spacing, typography }) => ({
    container: {
        position: 'absolute',
        height: `unset !important`,
        zIndex: 2,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 2
    },
    button: {
        height: spacing.unit * 4.5,
        width: '100%',
        fontSize: typography.subtitle1.fontSize
    }
});

const propTypes = {
    handleFilters: func.isRequired,
    hideDialog: func.isRequired,
    anchor: object.isRequired
};

const mapStateToProps = (state, { appliedFilters }) => ({
    initialValues: appliedFilters,
    dateFrom: formValueSelector(FORM_NAME)(state, 'startDt'),
    isFilterItemSelected: isGridFilterItemSelectedSelector(state)
});

const mapDispatch = dispatch => ({
    handleResetValue: () => dispatch(initialize(FORM_NAME, INITIAL_VALUES))
});

const mapInputs = {
    retailer: setGridFilterRetailerChildren,
    startDt: () => change(FORM_NAME, 'endtDt', '')
};

const Filters = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        onSubmit: (values, dispatch, { handleFilters, hideDialog }) => {
            handleFilters({ type: 'set-filters', payload: values });
            hideDialog();
        }
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        anchor,
        hideDialog,
        handleSubmit,
        classes: c,
        dateFrom,
        handleResetValue,
        isFilterItemSelected
    }) => {
        return (
            <Popover
                open={!!anchor}
                onClose={hideDialog}
                anchorEl={anchor}
                PaperProps={{
                    style: { width: 580, height: 314 }
                }}
            >
                <Grid container className={c.container}>
                    <GridItem md={6}>
                        <SelectRetailers label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectFactory />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectShapeDesigners label='' name='designer' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectGenders label='' name='genderId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectAges label='' name='ageId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectCategories label='' name='categoryId' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectDepartments label='' />
                    </GridItem>
                    <GridItem md={6}>
                        <SelectStyles label='' name='styleId' />
                    </GridItem>
                    <GridItem md={6}>
                        <DateInput
                            name='startDt'
                            label=''
                            placeholder='From'
                            maxDate={new Date()}
                            minDate={new Date('2015-01-01')}
                        />
                    </GridItem>
                    <GridItem md={6}>
                        <DateInput
                            name='endtDt'
                            label=''
                            placeholder='To'
                            minDate={useMinDate(dateFrom)}
                        />
                    </GridItem>
                    <Grid container>
                        <GridItem md={3}>
                            <Button className={c.button} onClick={handleSubmit}>
                                Apply
                            </Button>
                        </GridItem>
                        <GridItem md={3}>
                            <Button
                                className={c.button}
                                onClick={handleResetValue}
                                color='warning'
                                disabled={!isFilterItemSelected}
                            >
                                Reset
                            </Button>
                        </GridItem>
                    </Grid>
                </Grid>
            </Popover>
        );
    }
);

Filters.propTypes = propTypes;

export default Filters;
