import React from 'react';

const OrderCancelledIcon = () => (
    <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_116_1250)'>
            <path
                d='M21.3966 17.5954V7.40459L12.6458 2.3092L3.89502 7.40459V17.5954L12.6458 22.6907L21.3966 17.5954Z'
                fill='#FCAAA9'
            />
            <mask
                id='mask0_116_1250'
                maskUnits='userSpaceOnUse'
                x='3'
                y='2'
                width='19'
                height='21'
            >
                <path
                    d='M21.397 17.5954V7.40459L12.6463 2.3092L3.89551 7.40459V17.5954L12.6463 22.6907L21.397 17.5954Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_116_1250)'>
                <path
                    d='M4.11719 7.40459L12.6095 12.5738L21.3972 7.44151L12.6464 2.3092L4.11719 7.40459Z'
                    fill='white'
                />
            </g>
            <path
                d='M21.3966 17.5954V7.40459L12.6458 2.3092L3.89502 7.40459V17.5954L12.6458 22.6907L21.3966 17.5954Z'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.89502 7.40466L12.6458 12.5L21.3966 7.40466'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.646 12.5V22.6908'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.44639 24.1307C7.89344 24.1307 9.87716 22.147 9.87716 19.6999C9.87716 17.2529 7.89344 15.2692 5.44639 15.2692C2.99935 15.2692 1.01562 17.2529 1.01562 19.6999C1.01562 22.147 2.99935 24.1307 5.44639 24.1307Z'
                fill='white'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.34517 17.801L3.54736 21.5988'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.54736 17.801L7.34517 21.5988'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.6582 4.59851L16.8551 9.95236'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.3784 12.5369V14.383L18.7753 12.3523V10.58L15.3784 12.5369Z'
                fill='white'
                stroke='#E36362'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_116_1250'>
                <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.645996 0.5)'
                />
            </clipPath>
        </defs>
    </svg>
);

export { OrderCancelledIcon as default, OrderCancelledIcon };
