import { combineReducers } from 'redux';
import composeDatasetReducers from '@libs/datasets/libs.reducer';

// Reducers
import auth from 'auth/redux.reducer.js';
import bought from 'bought/redux.reducer.js';
import common from 'common/redux.reducer.js';
import cadUpload from 'cadUpload/redux.reducer.js';
import cadView from 'cadView/redux.reducer.js';
import navigation from 'navigation/redux.reducer.js';
import sampling from 'sampling/redux.reducer.js';
import trim from 'trim/redux.reducer.js';
import utilities from 'utilities/redux.reducer.js';
import gridView from 'gridView/redux.reducer.js';
import cadDesignerReport from 'cadDesignerReport/redux.reducer.js';
import orders from 'orders/redux.reducer.js';
import qualityControl from 'qualityControl/redux.reducer.js';
import setup from 'setup/redux.reducer';
import administrator from 'administrator/redux.reducer';
import fabric from 'fabric/redux.reducer';
import personalizationSettings from 'personalizationSettings/redux.reducer';

// Todo: Legacy Reducers
import datasets from 'reducers/datasets';
import form from 'reducers/form';
import global from 'reducers/global';
import initSamplingPrint from 'reducers/sampling/initialData';
import loadingState from 'reducers/loadingState';
import reports from 'reducers/reports';
import samplingPrint from 'reducers/samplingPrint';
import utils from 'reducers/utils';

export default combineReducers(
    composeDatasetReducers({
        auth,
        bought,
        common,
        cadUpload,
        cadView,
        navigation,
        sampling,
        trim,
        utilities,
        form,
        gridView,
        cadDesignerReport,
        orders,
        qualityControl,
        setup,
        administrator,
        fabric,
        personalizationSettings
    })
);
