import React, { useMemo } from 'react';
import classnames from 'classnames';

import DownloadIcon from '@material-ui/icons/ExpandMore';
import { Button, Typography, withStyles } from '@material-ui/core';
import { useAttachmentDetailsState } from '../../useAttachmentDetails';
import { useDownloadAll } from './hooks';

const styles = ({ spacing, shape, typography, palette }) => ({
    root: {
        display: 'flex',
        textTransform: 'none',
        padding: spacing.unit * 0.5,
        marginRight: spacing.unit,

        // Targets iconContainer with own hover state
        '& div': {
            opacity: 0.5
        },

        '&:hover': {
            '& div': {
                opacity: 1
            }
        },
        '&$disabled': {
            pointerEvents: 'none',
            cursor: 'none'
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: spacing.unit * 2,
        height: spacing.unit * 2,
        marginRight: spacing.unit * 0.5,
        color: 'inherit'
    },
    icon: {
        borderRadius: shape.borderRadius * 0.5
    },
    label: {
        fontWeight: typography.fontWeightSemi,
        '&$disabled': {
            color: palette.grey[500]
        }
    },
    disabled: {}
});

const DownloadAll = withStyles(styles)(({ classes: c, ...restProps }) => {
    const { attachments } = useAttachmentDetailsState();
    const { loading, handleDownloadAll } = useDownloadAll(attachments);

    return (
        <Button
            className={classnames(c.root, loading ? c.disabled : '')}
            onClick={handleDownloadAll}
        >
            <div className={c.iconContainer}>
                <DownloadIcon className={c.icon} />
            </div>
            <Typography
                className={classnames(c.label, loading ? c.disabled : '')}
            >
                Download all
            </Typography>
        </Button>
    );
});

export default DownloadAll;
