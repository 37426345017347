export default theme => ({
    list: {
        color: 'inherit',
        fontSize: 14
    },
    listItem: {
        height: '56px',
        backgroundColor: '#232323',
        borderBottom: `2px solid #232323`
    },
    listItemText: {
        height: '56px',
        backgroundColor: '#232323'
    },
    anchorStyle: {
        textDecoration: 'none'
    },
    image: {
        width: '18px',
        height: '18px'
    },
    textPadding: { paddingLeft: 0, whiteSpace: 'normal' },
    textColor: { color: '#fff' }
});
