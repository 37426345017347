import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';

import { useQuantityReport } from './hooks';
import Card from '../Card';
import CardSM from '../CardSM';

const styles = ({ spacing, palette }) => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    row: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)'
    },

    column: {
        padding: `${spacing.unit * 4}px ${spacing.unit * 1.5}px`,
        '&:not(:last-child)': {
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: palette.background.dark
        }
    },

    children: {
        marginTop: spacing.unit * 1.25,
        display: 'flex',
        gap: `${spacing.unit * 1.5}px`
    }
});

const propTypes = {
    quantityReport: array.isRequired
};

const QuantityReport = withStyles(styles)(({ classes: c, quantityReport }) => {
    const { firstColumn, secondColumn, thirdColumn } = useQuantityReport(
        quantityReport
    );
    return (
        <ContentContainer
            title='Quantity Report'
            styles={{ container: c.container }}
        >
            <div className={c.row}>
                <div className={c.column}>
                    <Card {...firstColumn[0]} />
                    <div className={c.children}>
                        {firstColumn.slice(1, 3).map((child, idx) => (
                            <CardSM {...child} key={`column-child-${idx}`} />
                        ))}
                    </div>
                </div>
                <div className={c.column}>
                    <Card {...secondColumn[0]} />
                    <div className={c.children}>
                        {secondColumn.slice(1, 3).map((child, idx) => (
                            <CardSM {...child} key={`column-child-${idx}`} />
                        ))}
                    </div>
                </div>
                <div className={c.column}>
                    <Card {...thirdColumn[0]} />
                    <div className={c.children}>
                        {thirdColumn.slice(1, 3).map((child, idx) => (
                            <CardSM {...child} key={`column-child-${idx}`} />
                        ))}
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
});

QuantityReport.propTypes = propTypes;

export { QuantityReport as default, QuantityReport };
