import { formValueSelector } from 'redux-form';
import { select, call, all, fork } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';
import { userNameSelector, userEmailSelector } from 'auth/redux.selectors';
import { getFileNameFromURL } from '@libs/filenames';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { updateCcAddress } from '@libs/mailer';
import { sizePathsSelector } from 'common/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';

import { FORM_NAME } from 'orders/Order/common';
import { orderEmailSchema } from './schemas';

export default function*({ echoOrderNo, orderVersionNo, mode, handleLoading }) {
    try {
        const {
            pdf,
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body
        } = yield select(formValueSelector(FORM_NAME), 'mailData');

        const [userEmail, userName, reportBlobs, reportSizePaths] = yield all([
            select(userEmailSelector),
            select(userNameSelector),
            call(getAssetBlobsWorker, {
                assets: pdf,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'reportPath')
        ]);

        const [reportPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: reportBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/${echoOrderNo}`,
            reference: echoOrderNo,
            sizePaths: reportSizePaths,
            handleLoading,
            developmentName: `V${orderVersionNo}`
        });

        const originalPath = reportPayload ? reportPayload.sizeOriginal : '';

        const absoluteURL = yield call(syncReportToDatabase, {
            reportReference: echoOrderNo,
            reportUrl: originalPath,
            referenceDevNo: orderVersionNo
        });

        const fileName = yield call(getFileNameFromURL, absoluteURL);

        let composedBody = `
                    ${body}
                    <br/>
                    <a style="color: #234c72;margin-right: 10px;font-size: 16px;" href="${absoluteURL}">${fileName}</a>`;

        const recipients = toAddress.replace(/\s/g, '');

        const updatedCcAddress = updateCcAddress(
            ccAddress,
            userEmail,
            recipients
        );

        const emailPayload = composeApiPayload(
            {
                userName,
                toAddress: recipients,
                ccAddress: updatedCcAddress,
                subject,
                body: composedBody,
                echoOrderNo
            },
            orderEmailSchema
        );

        yield fork(apiRequest, {
            method: 'post',
            url: 'EmailSend/OrderEmailSend',
            data: emailPayload
        });
    } catch (err) {
        console.log('error in report worker', err);
    }
}
