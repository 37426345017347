export default ({ palette, spacing }) => ({
    queryContainer: {
        marginTop: spacing.unit * 2,
        height: 60,
        backgroundColor: palette.common.black,
        color: palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing.unit * 2,
    },
    queryBar: {
        height: 60,
        backgroundColor: palette.common.black,
        color: palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing.unit * 2,
    },
    sampleRefLabel: {
        marginTop: -14,
        paddingRight: 20,
    },
    sampleRefSelect: {
        marginTop: 2,
    },
    checkbox: {
        marginLeft: 20,
        marginTop: 2,
        alignSelf: 'normal',
    },
    checkboxLabel: {
        color: 'white',
    },
    mail: {
        marginTop: 5,
        alignSelf: 'normal',
    },
    mailButton: {
        color: palette.primary.main,
        backgroundColor: palette.primary.contrastText,
        '&:hover': {
            backgroundColor: palette.grey[50]
        }
    },
});
