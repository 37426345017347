import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

// Local
import styles from './styles';
import { PaginatedSelectBase } from 'components';
import AddHandlerContainer from './AddHandlerContainer';

// Selectors
import { selectAmendedField } from 'selectors';

const PaginatedSelectTest = ({
    classes: {
        container,
        control,
        addContainer,
        placeholder: placeholderClass,
        amendControl,
        amendDisabled,
        amendPlaceholder,
        ...restClasses
    },
    placeholder = 'Select...',
    amended,
    addHandler = null,
    containerClass = null,
    ...restProps
}) => {
    const props = {
        placeholder,
        isCancellable: true,
        styles: {
            ...restClasses,
            container: classNames(container, containerClass),
            control: classNames(control, amended && amendControl),
            disabled: amended && amendDisabled,
            placeholder: classNames(
                placeholderClass,
                amended && amendPlaceholder
            ),
        },
        ...restProps,
    };

    return !!addHandler ? (
        <AddHandlerContainer onClick={addHandler}>
            <PaginatedSelectBase {...props} />
        </AddHandlerContainer>
    ) : (
        <PaginatedSelectBase {...props} />
    );
};

const mapState = (state, { name }) => ({
    amended: selectAmendedField(state, name),
});

const _PaginatedSelectTest = compose(
    connect(mapState),
    withStyles(styles)
)(PaginatedSelectTest);

export {
    _PaginatedSelectTest as default,
    _PaginatedSelectTest as PaginatedSelectTest,
};
