import createCachedSelector from 're-reselect';

// Entities

import { MENU_LIST, MENU_GROUPS, getDatasetReference } from 'libs';

export const selectDatasets = (state) => state.datasets;

export const selectDataset = createCachedSelector(
    selectDatasets,
    (state, entity) => entity,
    (datasets, entity) => datasets[entity]
)((state, entity) => entity);

export const selectDevelopmentTypesData = createCachedSelector(
    selectDatasets,
    ({ developmentTypes }) => developmentTypes
)(() => 'placeholder');

// Menu list
export const selectMenusData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[MENU_LIST]
)(() => 'placeholder');

// Menu Groups
export const selectMenuGroupsData = createCachedSelector(
    selectDatasets,
    (datasets) => datasets[MENU_GROUPS]
)(() => 'placeholder');

// Paginated Select
export const selectPaginatedDataset = createCachedSelector(
    selectDatasets,
    (state, entity) => entity,
    (datasets, entity) => datasets[entity]
)((state, entity) => entity);

// Filtered Paginated Dataset
export const selectPaginatedData = createCachedSelector(
    selectDataset,
    (state, entity) => entity,
    ({ nextPage = false, data = [] }, entity) => {
        const { fields } = getDatasetReference(entity);

        const fieldEntries = Object.entries(fields);

        const filteredData = data.map((datum) => {
            return fieldEntries.reduce((acm, [key, value]) => {
                return { ...acm, [value]: datum[key] };
            }, {});
        });

        return { nextPage, data: filteredData };
    }
)((state, entity) => entity);
