import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import useDetailsBreakdownChart from '../../../../useDetailsBreakdownChart';
import VisibilityManageModal from '../../../../Components/VisibilityManageModal';
import SelectAllRow from '../../../../Components/VisibilityManageModal/SelectAllRow';
import CheckboxRow from '../../../../Components/VisibilityManageModal/CheckboxRow';

const propTypes = {
    onClose: PropTypes.func.isRequired
};

const styles = () => ({
    contentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    }
});

const CellsVisibilityModal = withStyles(styles)(
    ({ classes: c, onClose, chartName }) => {
        const [
            {
                chartSettings: { cellSettings },
                data
            },
            dispatch
        ] = useDetailsBreakdownChart();

        const [localCells, setLocalCells] = useState(
            cellSettings[chartName] || {}
        );

        const [selectAll, setSelectAll] = useState(false);

        const canSave = useMemo(
            () => Object.keys(localCells).length < data[chartName].length,
            [localCells, data, chartName]
        );

        const handleSelectAllChange = () => {
            const newSelectAll = !selectAll;
            if (newSelectAll) {
                // Deselect all cells by clearing localCells
                setLocalCells({});
            } else {
                // Select all cells by setting all to false (hidden)
                const updatedSettings = data[chartName].reduce((acc, cell) => {
                    return {
                        ...acc,
                        [cell.name]: false
                    };
                }, {});
                setLocalCells(updatedSettings);
            }
            setSelectAll(newSelectAll);
        };

        const handleCheckboxChange = cellName => {
            let newCells = { ...localCells };
            if (localCells[cellName] !== undefined) {
                // Remove cell from localCells if it is already hidden
                delete newCells[cellName];
            } else {
                // Add cell to localCells as hidden
                newCells[cellName] = false;
            }
            setLocalCells(newCells);
        };

        const handleSaveButtonClick = () => {
            dispatch({
                type: 'toggleChartCellsVisibility',
                payload: {
                    chartName,
                    cells: localCells
                }
            });
            onClose();
        };

        useEffect(() => {
            const allSelected = data[chartName].every(
                cell => localCells[cell.name] === undefined
            );
            setSelectAll(allSelected);
        }, [localCells, data, chartName]);

        return (
            <VisibilityManageModal
                title={`Manage '${chartName}' Cells Visibility`}
                onClose={onClose}
                onSaveButtonClick={handleSaveButtonClick}
                canSave={canSave}
            >
                <SelectAllRow
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                />
                <div className={c.contentContainer}>
                    {data[chartName].map(({ name }) => (
                        <CheckboxRow
                            key={name}
                            checked={localCells[name] === undefined}
                            label={name}
                            onChange={() => handleCheckboxChange(name)}
                        />
                    ))}
                </div>
            </VisibilityManageModal>
        );
    }
);

CellsVisibilityModal.propTypes = propTypes;

export default CellsVisibilityModal;
