export const finalPhotoUploadSchema = {
    id: 0,
    userId: 0,
    sampleRef: 'string',
    frontImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    },
    backImage: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    }
};
