import React from 'react';
import { string } from 'prop-types';

import { withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    image: {
        width: spacing.unit * 10,
        height: spacing.unit * 10,
        objectFit: 'contain'
    }
});

const propTypes = {
    url: string,
    sampleRef: string
};

const Image = withStyles(styles)(({ classes: c, url, sampleRef }) => (
    <img src={url} alt={sampleRef} className={c.image} />
));

Image.propTypes = propTypes;

export default Image;
