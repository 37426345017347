import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Lib
import { GridItem, Editor, Input } from '@formComponents';

// Local
import CommentContainer from './CommentContainer';

const styles = ({ spacing }) => ({
    commentContainer: {
        padding: `${spacing.unit * 1}px ${spacing.unit * 1}px`
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 40
        }
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 8
    }
});

const SealSampleEvaluation = withStyles(styles)(({ classes: c, isQA }) => {
    return (
        <CommentContainer title={'Seal Sample Evaluation'}>
            <Grid container className={c.commentContainer}>
                <GridItem md={6}>
                    <Editor
                        name='commentsFromBuyer'
                        label='Comments & clarification from buyer'
                        placeholder='Write comments here'
                        styles={{ editor: c.editor }}
                        disabled={isQA}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Editor
                        name='generalInstructions'
                        label='General instructions'
                        placeholder='Write comments here'
                        styles={{ editor: c.editor }}
                        disabled={isQA}
                    />
                </GridItem>
                {isQA && (
                    <GridItem md={6}>
                        <Input
                            name='sealOrTrimCardVerification'
                            label='Seal/TP/Spec/Trim Card Verification'
                            placeholder='Write comments here'
                            multiline
                            rowsMax={4}
                            styles={{ root: c.inputRoot }}
                        />
                    </GridItem>
                )}
            </Grid>
        </CommentContainer>
    );
});

export default SealSampleEvaluation;
