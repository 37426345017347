import { useContext } from 'react';
import createCachedSelector from 're-reselect';

import { useDebounce } from 'hooks';

import { useStepperState } from '../../../StepContext';

const selectGradient = createCachedSelector(
    activeStep => activeStep,
    (_, contents) => contents.map(({ valid, touched }) => ({ valid, touched })),
    (_, __, validColor) => validColor,
    (_, __, ___, invalidColor) => invalidColor,
    (activeStep, contents, validColor, invalidColor) => {
        const amountOfSteps = contents.length;
        const stepIncrement = 1 / (amountOfSteps * 2);

        return contents
            .reduce((acm, { valid, touched }, idx) => {
                if (idx <= activeStep) {
                    // Calculate how much of the full width is filled
                    const normalizedBar = (activeStep + 1) / amountOfSteps;

                    // Calculate where the current index is in the entire progress
                    const normalizedProgress = (idx + 1) / amountOfSteps;

                    // Position of the step in the entire progress
                    const stepPosition = normalizedProgress - stepIncrement; // 3/10

                    // Position of the step in the current set of active steps
                    const gradientPosition = stepPosition / normalizedBar;

                    // Turns into a percentage
                    const gradientPercentage = (gradientPosition * 100).toFixed(
                        2
                    );

                    // Checks to see if the latest field should be valid
                    const nextContent = contents[idx + 1];

                    // Accounts for if it's on the last index
                    const normalizedValid = nextContent
                        ? !nextContent.touched || valid
                        : true;

                    // Assign the color based in validity
                    const color = normalizedValid ? validColor : invalidColor;

                    const gradient =
                        activeStep > 0
                            ? `${color} ${gradientPercentage}%`
                            : `${color}, ${color}`;

                    // Append to accumulator
                    return [...acm, gradient];
                } else return acm;
            }, [])
            .join(',');
    }
)((activeStep, contents) => {
    return contents.reduce(
        (acm, { valid = '', touched = '' }) =>
            `${acm}${valid.toString()}${touched.toString()}`,
        activeStep.toString()
    );
});

const useGradient = ({ palette }) => {
    const { activeStep, contents } = useStepperState();
    const validColor = palette.success.main;
    const invalidColor = palette.error.main;

    const gradient = selectGradient(
        activeStep,
        contents,
        validColor,
        invalidColor
    );

    // Accounts for re-render flickering
    return useDebounce(gradient, 10);
};

export { useGradient };
