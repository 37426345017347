export default datasets =>
    Object.entries(datasets).reduce(
        (acm, [entity, { fields = null, dataGrid = false }]) => {
            let initialDataset = [];
            if (!!fields) {
                initialDataset = { data: [], nextPage: false };
            }

            const loading = acm.loading || {};

            return {
                ...acm,
                [entity]: initialDataset,
                loading: { ...loading, [entity]: false }
            };
        },
        {}
    );
