import React from 'react';

import Item from './Item';
import { SinglePage as SinglePageIcon } from '@assets/Icons';
import { SINGLE_PAGE } from './constants';

const SinglePage = ({
    getFileName,
    selectedIds,
    shouldDisable,
    exportAsPage,
    moduleName,
    singlePageLoading
}) => {
    const handleDownload = () => {
        exportAsPage({
            fileName: getFileName('single'),
            data: selectedIds,
            module: moduleName,
            reportType: SINGLE_PAGE
        });
    };
    return (
        <Item
            icon={SinglePageIcon}
            title={'Single page'}
            shouldDisable={shouldDisable(singlePageLoading)}
            onClick={handleDownload}
        />
    );
};

export default SinglePage;
