import React from 'react';
import { string } from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: `${spacing.unit * 7}px 1fr`,
        gridColumnGap: `${spacing.unit * 0.4}px`,
        alignItems: 'center'
    },
    icon: {
        width: spacing.unit * 7,
        height: spacing.unit * 7
    },
    title: {
        fontSize: spacing.unit * 1.75,
        color: '#85888E',
        lineHeight: 1.2,
        textTransform: 'capitalize'
    },
    value: {
        fontSize: spacing.unit * 3,
        color: '#000000',
        fontWeight: 600,
        lineHeight: 1
    }
});

const propTypes = {
    title: string.isRequired,
    value: string.isRequired,
    icon: string.isRequired
};

const CardSM = withStyles(styles)(({ title, value, icon, classes: c }) => {
    return (
        <div className={c.container}>
            <img src={icon} alt={title} className={c.icon} />
            <div>
                <Typography className={c.title}>{title}</Typography>
                <Typography className={c.value}>{value}</Typography>
            </div>
        </div>
    );
});

CardSM.propTypes = propTypes;

export default CardSM;
