import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@formComponents';
import { required, number, minValue, maxDigits } from '@libs/validationRules';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'orderQuantity',
    label: 'Total Quantity',
    type: 'number',
    required: true,
    disabled: false,
    validate: [required, number, minValue(1), maxDigits(6)]
};

const TotalQuantity = props => <Input {...props} />;

TotalQuantity.propTypes = propTypes;
TotalQuantity.defaultProps = defaultProps;

export { TotalQuantity as default, TotalQuantity };
