import React, { useState, useCallback, useMemo } from 'react';

import { CancelDialog as ConfirmDialog } from '@formComponents/CancelDialog';

const useConfirmDelete = (handleDelete = f => f) => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), []);

    const handleClose = useCallback(() => setOpen(false), []);

    const handleConfirmDelete = useCallback(() => {
        handleDelete();
        handleClose();
    }, [handleClose, handleDelete]);

    const renderDialog = useMemo(
        () => (
            <ConfirmDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirmDelete}
                promptText='Do you really want to delete?'
            />
        ),
        [open, handleConfirmDelete]
    );

    return [renderDialog, handleOpen];
};

export { useConfirmDelete as default, useConfirmDelete };
