import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import SelectModel from '@formComponents/SelectModel';
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import { userRoleIdSelector } from 'auth/redux.selectors';

// Local
import {
    DOMAIN_NAME,
    ADMIN_ROLE_ID,
    HEAD_OF_DEPT_ROLE_ID,
    GENERAL_USER_ROLE_ID
} from '../../common';
import { USER_ROLES } from '../../../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'role',
    label: 'User role',
    domain: DOMAIN_NAME,
    entity: USER_ROLES,
    disabled: false,
    required: true
};

const dataSelector = createCachedSelector(
    entitySelector,
    (_, __, ___, userRoleId) => userRoleId,
    (data, userRoleId) => {
        let filteredRoles = [];

        if (userRoleId === ADMIN_ROLE_ID) {
            filteredRoles = data;
        } else if (userRoleId === HEAD_OF_DEPT_ROLE_ID) {
            filteredRoles = data.filter(
                role => role.id === GENERAL_USER_ROLE_ID
            );
        } else if (userRoleId === GENERAL_USER_ROLE_ID) {
            filteredRoles = [];
        }
        return filteredRoles.map(({ role, id }) => ({
            label: role,
            value: id
        }));
    }
)(
    (state, domain, entity, userRoleId) =>
        `@data-${domain}-${entity}-${userRoleId}`
);

const mapState = (state, { domain, entity }) => {
    const userRoleId = userRoleIdSelector(state);
    return {
        data: dataSelector(state, domain, entity, userRoleId),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectRoles = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectRoles.propTypes = propTypes;
SelectRoles.defaultProps = defaultProps;

export default SelectRoles;
