import React, { Fragment, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import { userRetailerPermissionsDataSelector } from 'administrator/redux.selectors';
import PermissionTabsTableHeader from './PermissionTabsTableHeader';
import PermissionTabsTableRow from './PermissionTabsTableRow';
import useRolePermissions from '../useRolePermissions';

const styles = () => ({});

const mapState = state => ({
    retailers: userRetailerPermissionsDataSelector(state)
});

const RetailerPermissionTab = compose(
    connect(mapState),
    withStyles(styles)
)(({ retailers }) => {
    const [
        { retailerAllSelected, permissions },
        dispatch
    ] = useRolePermissions();

    const toggleSelectionHandler = useCallback(rowId => {
        dispatch({ type: 'TOGGLE_RETAILER_SELECTION', payload: rowId });
    }, []);

    const toggleAllSelectionHandler = useCallback(() => {
        dispatch({ type: 'TOGGLE_RETAILER_SELECTION', payload: 'ALL' });
    }, []);

    const isRowSelectedHandler = useCallback(
        rowId => permissions.retailers.includes(rowId),
        [permissions.retailers]
    );

    return (
        <Fragment>
            <PermissionTabsTableHeader
                selectAll={retailerAllSelected}
                onToggleAllSelection={toggleAllSelectionHandler}
            />
            {retailers.map(row => {
                return (
                    <PermissionTabsTableRow
                        row={row}
                        key={row.id}
                        isRowSelected={isRowSelectedHandler}
                        onToggleSelection={toggleSelectionHandler}
                    />
                );
            })}
        </Fragment>
    );
});

export default RetailerPermissionTab;
