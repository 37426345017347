const DOMAIN_NAME = 'orders';

const NINETY_PERCENT_ID = 3;
const PRIMARK_ID = 2;
const NEWLOOK_ID = 1;
const ASOS_ID = 50;
const SEASALT_ID = 66;
const FACTORY_ORIGIN = { TR: 'TURKEY', BD: 'BANGLADESH' };
const DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN = {
    [FACTORY_ORIGIN.BD]: '$',
    [FACTORY_ORIGIN.TR]: '€'
};

export {
    DOMAIN_NAME,
    NINETY_PERCENT_ID,
    PRIMARK_ID,
    ASOS_ID,
    NEWLOOK_ID,
    SEASALT_ID,
    FACTORY_ORIGIN,
    DEFAULT_CURRENCY_FOR_FACTORY_ORIGIN
};
