import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { SHAPE_DESIGNERS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'shapeDesigner',
    label: 'Shape designer',
    placeholder: 'Select designer',
    disabled: false,
    required: false
};

const SelectShapeDesigners = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={SHAPE_DESIGNERS} />
);

SelectShapeDesigners.propTypes = propTypes;
SelectShapeDesigners.defaultProps = defaultProps;

export { SelectShapeDesigners as default, SelectShapeDesigners };
