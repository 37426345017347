import React, { useCallback } from 'react';

import { FormControlLabel } from '@material-ui/core';

import GridCheckbox from 'dataGrid/components/GridCheckbox';
import useManageColumnsContext from '../../../useManageColumnsContext';

const ModalContentToolbarToggleAll = props => {
    const {
        state: { allVisible },
        dispatch
    } = useManageColumnsContext();

    const handleChange = useCallback(() => {
        dispatch({ type: 'TOGGLE_ALL_COLUMN_VISIBILITY' });
    }, [dispatch]);

    return (
        <FormControlLabel
            control={
                <GridCheckbox
                    checked={allVisible}
                    handleChange={handleChange}
                />
            }
            label={allVisible ? 'Deselect All' : 'Select All'}
            {...props}
        />
    );
};

export default ModalContentToolbarToggleAll;
