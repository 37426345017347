import React from 'react';

const SinglePage = ({ color: fill }) => (
    <svg
        id='Group_178'
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='17'
        viewBox='0 0 14 17'
    >
        <g id='Vector_Smart_Object4' data-name='Vector Smart Object4'>
            <path
                id='Path_162'
                d='M13.872,37.265H.8a.48.48,0,0,1-.464-.494V28.8A.48.48,0,0,1,.8,28.3a.48.48,0,0,1,.464.494v7.482H13.407v-1.09a.465.465,0,1,1,.929,0v1.584A.48.48,0,0,1,13.872,37.265Z'
                transform='translate(-0.336 -20.265)'
                fill={fill ? fill : '#234c72'}
            />
            <g id='Group_40' transform='translate(0.464 0.494)'>
                <path
                    id='Path_163'
                    d='M.8,26.146v-.159l4.863-5.228v5.387Z'
                    transform='translate(-0.8 -20.759)'
                    fill={fill ? fill : '#50a2bc'}
                />
            </g>
            <path
                id='Path_164'
                d='M14.336,35.543V20.759a.48.48,0,0,0-.464-.494H5.631a.44.44,0,0,0-.06.008h0a.428.428,0,0,0-.057.017L5.5,20.3a.446.446,0,0,0-.053.025h0a.45.45,0,0,0-.051.035l-.013.01a.461.461,0,0,0-.047.043L.47,25.64c-.011.012-.021.024-.031.037L.421,25.7l-.009.013c-.007.011-.013.022-.018.033l0,.008c-.006.011-.011.023-.015.035l0,.009c0,.011-.008.023-.012.035l0,.011c0,.011-.005.021-.008.032l0,.015c0,.01,0,.02,0,.03s0,.012,0,.018,0,.02,0,.03,0,.011,0,.017v.159A.48.48,0,0,0,.8,26.64H5.663a.48.48,0,0,0,.464-.494V21.253h7.28v14.29M1.765,25.652,5.2,21.96v3.692Z'
                transform='translate(-0.336 -20.265)'
                fill={fill ? fill : '#234c72'}
            />
        </g>
        <g id='Group_175' data-name='Group 175' transform='translate(3.5 9.5)'>
            <line
                id='Line_7'
                x2='7'
                fill='none'
                stroke={fill ? fill : '#234c72'}
                strokeWidth='1'
            />
            <line
                id='Line_8'
                x2='7'
                transform='translate(0 2)'
                fill='none'
                stroke={fill ? fill : '#234c72'}
                strokeWidth='1'
            />
            <line
                id='Line_9'
                x2='7'
                transform='translate(0 4)'
                fill='none'
                stroke={fill ? fill : '#234c72'}
                strokeWidth='1'
            />
        </g>
    </svg>
);

export { SinglePage as default, SinglePage };
