import React, { Fragment } from 'react';

import { Grid, withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import {
    GridItem,
    GridContainer,
    Select,
    Date as DateInput,
    Editor,
    ImageDnD
} from '@formComponents';
import { validateImageSizeAndExtension } from '@libs/validationRules';

import { DOMAIN_NAME } from '../../common';
import { APPROVAL_STATUS, APPROVED_BY } from '../../../redux.datasets';

const styles = ({ spacing }) => ({
    wrapper: {
        width: '100%'
    },
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0
    },
    commentsContainer: {
        padding: spacing.unit * 2,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    imageWrapper: {
        marginTop: spacing.unit * 3.5
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 40
        }
    }
});

const ApprovalStatusContainer = withStyles(styles)(({ classes: c }) => {
    return (
        <div className={c.wrapper}>
            <ContentContainer
                title='Approval Status'
                AdornmentComponent={<Fragment />}
                styles={{ container: c.contentContainer }}
            >
                <GridContainer>
                    <GridItem md={4}>
                        <Select
                            name='approvalStatus'
                            label='Select Approval Status'
                            domain={DOMAIN_NAME}
                            entity={APPROVAL_STATUS}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Select
                            name='approvedBy'
                            label='Select Approved By'
                            domain={DOMAIN_NAME}
                            entity={APPROVED_BY}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <DateInput
                            name='dateOfApproved'
                            label='Date Approved'
                        />
                    </GridItem>
                </GridContainer>
            </ContentContainer>
            <Grid container className={c.commentsContainer}>
                <GridItem md={7}>
                    <Editor
                        name='approvalComments'
                        label='FD Approval Comments'
                        placeholder='Write here..'
                        styles={{ editor: c.editor }}
                    />
                </GridItem>
                <GridItem md={5}>
                    <div className={c.imageWrapper}>
                        <ImageDnD
                            name='finalImage'
                            label='Final Image'
                            validate={validateImageSizeAndExtension}
                        />
                    </div>
                </GridItem>
            </Grid>
        </div>
    );
});

export default ApprovalStatusContainer;
