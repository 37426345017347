import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { imgTypeAllowed, imgTypes, fileSize } from 'helpers';
import styles from './styles';
import {
    GridContainer,
    GridItem,
    TextInput,
    ImageDeleteBar,
    ModalHeader,
    ModalContent,
    ModalFooter
} from 'components';

import {
    setStepData,
    startLoadBackneckDetails,
    addBackneckData,
    updateBackneckData
} from 'store/actions';


function mapStateToProps(state) {
    return {
        store: state,
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            setStepData,
            addBackneckData,
            updateBackneckData,
            loadBackneckDetails: startLoadBackneckDetails
        },
        dispatch
    );
};

class BacknecksModal extends PureComponent {
    state = {
        uploadError: ''
    };

    componentDidMount() {
        const { loadBackneckDetails, backneckEditIndex } = this.props;
        if (backneckEditIndex !== void 0) {
            loadBackneckDetails({ backneckEditIndex });
        }
    }

    submitBackneckData = backNeckAutoId => {
        const {
            onClose,
            addBackneckData,
            updateBackneckData,
            backneckEditIndex
        } = this.props;

        if (backneckEditIndex !== void 0) {
            // if in edit mode
            updateBackneckData({ backneckEditIndex });
            onClose();
        } else {
            addBackneckData();
            onClose();
        }
    };

    handleBackneckImg = event => {
        this.setState({ uploadError: '' });
        if (event.target.files.length > 0) {
            if (
                event.target.files[0].size <= fileSize.bytes &&
                imgTypeAllowed.includes(event.target.files[0].type)
            ) {
                this.props.setStepData('backneckImg', event.target.files[0]);
            } else if (!imgTypeAllowed.includes(event.target.files[0].type)) {
                this.showUploadError('File type not allowed.');
            } else {
                this.showUploadError('File size limit exceeded.');
            }
        }
    };

    deleteBackneckImg = () => this.props.setStepData('backneckImg', null);

    showUploadError = msg => {
        this.setState({ uploadError: msg });
        const THIS = this;
        setTimeout(function() {
            THIS.setState({ uploadError: '' });
        }, 3000);
    };

    handleText = (e, type) => this.props.setStepData(type, e.target.value);

    setValueToState = param => {
        let stepKey = param.type;
        let stepData = param.value;
        this.props.setStepData(stepKey, stepData);
    };

    render() {
        const {
            state,
            props,
            handleText,
            deleteBackneckImg,
            submitBackneckData
        } = this;
        const {
            classes,
            onClose,
            backneckEditIndex,
            store: {
                initSamplingPrint: {
                    backneckGraphicRef,
                    backneckFabricPatchH,
                    backneckFabricPatchW,
                    backneckPrintH,
                    backneckPrintW,
                    backneckFabricDetails,
                    backneckInc1Details,
                    backneckInc2Details,
                    backneckImg
                }
            }
        } = props;
        const { uploadError } = state;
        return (
            <Fragment>
                <ModalHeader onClose={onClose}>{`${
                    backneckEditIndex !== void 0 ? 'Update' : 'Add'
                } backneck details`}</ModalHeader>
                <ModalContent>
                    <GridContainer
                        spacing={40}
                        className={classes.gridContainerStyle}
                    >
                        <GridItem md={6}>
                            <TextInput
                                required={true}
                                labelText='Graphic Ref: '
                                value={backneckGraphicRef}
                                placeholderText='Graphic Ref'
                                handleText={e =>
                                    handleText(e, 'backneckGraphicRef')
                                }
                            />
                            <TextInput
                                labelText='Fabric Patch Height (CM): '
                                value={backneckFabricPatchH}
                                placeholderText='Fabric Patch Height'
                                handleText={e =>
                                    handleText(e, 'backneckFabricPatchH')
                                }
                            />
                            <TextInput
                                labelText='Backneck Print Height (CM): '
                                value={backneckPrintH}
                                placeholderText='Backneck Print Height'
                                handleText={e =>
                                    handleText(e, 'backneckPrintH')
                                }
                            />
                            <TextInput
                                labelText='Ink1 Details: '
                                value={backneckInc1Details}
                                placeholderText='Ink 1 Details'
                                handleText={e =>
                                    handleText(e, 'backneckInc1Details')
                                }
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <TextInput
                                labelText='Fabric Patch Width (CM): '
                                value={backneckFabricPatchW}
                                placeholderText='Fabric Patch Width'
                                handleText={e =>
                                    handleText(e, 'backneckFabricPatchW')
                                }
                            />
                            <TextInput
                                labelText='Fabric Details: '
                                value={backneckFabricDetails}
                                placeholderText='Fabric Details'
                                handleText={e =>
                                    handleText(e, 'backneckFabricDetails')
                                }
                            />
                            <TextInput
                                labelText='Backneck Print Width (CM): '
                                value={backneckPrintW}
                                placeholderText='Backneck Print Width'
                                handleText={e =>
                                    handleText(e, 'backneckPrintW')
                                }
                            />
                            <TextInput
                                labelText='Ink2 Details: '
                                value={backneckInc2Details}
                                placeholderText='Ink 2 Details'
                                handleText={e =>
                                    handleText(e, 'backneckInc2Details')
                                }
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer
                        spacing={40}
                        className={classes.gridContainerVpadding}
                    >
                        <GridItem md={6}>
                            <TextField
                                fullWidth
                                label='Upload image: '
                                InputProps={{
                                    onChange: e => this.handleBackneckImg(e),
                                    disableUnderline: true,
                                    inputProps: {
                                        type: 'file',
                                        multiple: false,
                                        accept: imgTypes.join(', '),
                                        size: fileSize.bytes,
                                        id: 'attachment-upload-input'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.inputLabelRoot,
                                        focused: classes.inputLabelFocused,
                                        shrink: classNames(
                                            classes.inputLabelShrank,
                                            classes.labelFileField
                                        )
                                    }
                                }}
                                className={classes.textField}
                            />
                            <label
                                htmlFor='attachment-upload-input'
                                className={classes.browseButtonRoot}
                            >
                                <Typography className={classes.fileListLabel}>
                                    {uploadError.length > 0 ? (
                                        <span style={{ color: 'red' }}>
                                            {uploadError}
                                        </span>
                                    ) : backneckImg ? (
                                        backneckImg.name
                                    ) : null}
                                </Typography>
                                <Button
                                    component='span'
                                    className={classes.browseButton}
                                    disableRipple
                                >
                                    Browse
                                </Button>
                            </label>
                            {backneckEditIndex && backneckImg ? (
                                <img
                                    src={backneckImg.url}
                                    alt={backneckImg.name}
                                />
                            ) : (
                                <ImageDeleteBar
                                    images={
                                        backneckImg && [
                                            {
                                                name: backneckImg.name,
                                                onClick: deleteBackneckImg
                                            }
                                        ]
                                    }
                                />
                            )}
                        </GridItem>
                        <GridItem md={6}>
                            <Typography
                                style={{ color: '#aeaeae', marginTop: 10 }}
                            >
                                * Allowed file extensions: {imgTypes.join(', ')}
                                <br />* Maximum file size: {
                                    fileSize.megabytes
                                }{' '}
                                MB
                                <br />* Maximum 1 image
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer
                        spacing={0}
                        justify='flex-end'
                        className={classes.footer}
                    >
                        <GridItem md={6}>
                            <Button
                                onClick={() => submitBackneckData()}
                                variant='contained'
                                disabled={!(backneckGraphicRef.length > 2)}
                                classes={{
                                    root: classes.saveButton,
                                    disabled: classes.saveButtonDisabled
                                }}
                            >
                                {backneckEditIndex ? 'Update' : 'Save'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

BacknecksModal.propTypes = {
    classes: PropTypes.object
};

const _BacknecksModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BacknecksModal));

export { _BacknecksModal as default, _BacknecksModal as BacknecksModal };
