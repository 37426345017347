import React from 'react';
import { connect } from 'react-redux';

import { setSnack } from 'utilities/redux.actions';
import Item from '../Item';
import { Pdf as PdfIcon } from '@assets/Icons';
import { usePdfReport } from './hooks';

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Pdf = connect(
    null,
    mapDispatchToProps
)(
    ({
        preparedExportData,
        getFileName,
        shouldDisable,
        exportedColumns,
        setSnack
    }) => {
        const [handleExport, exportLoading] = usePdfReport({
            fileName: getFileName('pdf'),
            props: preparedExportData(exportedColumns, 'pdf', '350'),
            setSnack
        });

        return (
            <Item
                icon={PdfIcon}
                title={'PDF'}
                shouldDisable={shouldDisable(exportLoading)}
                onClick={handleExport}
            />
        );
    }
);

export default Pdf;
