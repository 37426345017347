import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Grid, Typography } from '@material-ui/core';

// Libs
import {
    GridContainer,
    GridItem,
    Input,
    Select,
    Date as DateInput,
    Checkbox
} from '@formComponents';

import { SelectRetailers, SelectDepartments } from 'sampling/components';
import { SAMPLE_FACTORY } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import { REQUEST_BY, HANGER_TYPE } from '../../../redux.datasets';
import { DOMAIN_NAME } from '../../common';

const propTypes = {
    shouldLocked: PropTypes.bool
};
const styles = ({ spacing, typography }) => ({
    checkboxHeader: {
        marginTop: spacing.unit * 1.25
    },
    checkboxLabel: { fontSize: typography.caption.fontSize },
    speedCheckbox: { paddingLeft: spacing.unit * 3 }
});

const CriticalPathContainer = withStyles(styles)(
    ({ classes: c, shouldLocked }) => {
        return (
            <GridContainer>
                <GridItem md={4}>
                    <Select
                        name='requestBy'
                        label='Request By'
                        placeholder='Select Request By'
                        disabled={shouldLocked}
                        domain={DOMAIN_NAME}
                        entity={REQUEST_BY}
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectRetailers label='For Retailer' required disabled />
                </GridItem>
                <GridItem md={4}>
                    <SelectDepartments disabled={shouldLocked} />
                </GridItem>
                <GridItem md={4}>
                    <Input name='buyer' label='Buyer' disabled={shouldLocked} />
                </GridItem>
                <GridItem md={4}>
                    <DateInput
                        name='dateRequested'
                        label='Date Requested'
                        disabled={shouldLocked}
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <DateInput
                        name='dateRequired'
                        label='Date Required'
                        disabled={shouldLocked}
                        required
                    />
                </GridItem>
                <Grid container>
                    <GridItem md={12}>
                        <Typography
                            variant='body2'
                            className={c.checkboxHeader}
                        >
                            Please Develop Considering:
                        </Typography>
                    </GridItem>
                    <GridItem md={3} className={c.speedCheckbox}>
                        <Checkbox
                            name='hasSpeed'
                            label='Speed'
                            styles={{
                                label: c.checkboxLabel
                            }}
                            disabled={shouldLocked}
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Checkbox
                            name='hasAvailability'
                            label='Availability'
                            styles={{
                                label: c.checkboxLabel
                            }}
                            disabled={shouldLocked}
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Checkbox
                            name='hasSustainability'
                            label='Sustainability'
                            styles={{
                                label: c.checkboxLabel
                            }}
                            disabled={shouldLocked}
                        />
                    </GridItem>
                </Grid>
                <GridItem md={4}>
                    <Select
                        name='hangerType'
                        label='Number Required'
                        placeholder='Select Number'
                        disabled={shouldLocked}
                        domain={DOMAIN_NAME}
                        entity={HANGER_TYPE}
                    />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='factory'
                        label='Factory'
                        placeholder='Select Factory'
                        disabled={shouldLocked}
                        domain={SAMPLING_DOMAIN_NAME}
                        entity={SAMPLE_FACTORY}
                    />
                </GridItem>
            </GridContainer>
        );
    }
);

CriticalPathContainer.propTypes = propTypes;

export default CriticalPathContainer;
