import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@libComponents/Stepper';
//import { Stepper } from '@formComponents/Stepper';
import PrintContainer from './PrintContainer';
import { useContent } from './hooks';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from './common';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'incomplete', 'amend'])
};
const defaultProps = {
    mode: 'new'
};

const Print = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ mode, handleSubmit: handleReduxFormSubmit }) => (
    <Stepper
        content={useContent(mode)}
        ContainerComponent={PrintContainer}
        ContainerProps={{ mode, handleReduxFormSubmit }}
    />
));

Print.propTypes = propTypes;
Print.defaultProps = defaultProps;

export { Print as default, Print };
