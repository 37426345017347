import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { isLoading, getTrimFromList } from 'helpers';
import styles from './styles';
import {
    GridContainer,
    GridItem,
    TextInput,
    CheckboxInput,
    SelectFieldMultiple,
    SelectFields,
    ModalHeader,
    ModalContent,
    ModalFooter
} from 'components';

import { FETCH_TRIMS_INFO } from 'store/actionTypes';

import {
    selectTrimsList,
    selectTrimSources,
    selectTrimTypes
} from 'store/selectors';

import { fetchDatasets, setStepData, fetchTrimsInfo } from 'store/actions';

import { selectRetailers } from 'selectors';

// Entities
import { TRIM_TYPES } from 'libs';

function mapStateToProps(state) {
    const selector = formValueSelector('SamplingPrintForm');
    const retailerId = selector(state, 'retailerId');
    const [selectedRetailer] = selectRetailers(state).filter(
        ({ value }) => value == retailerId
    );
    return {
        store: state,
        trims: selectTrimsList(state),
        trimTypes: selectTrimTypes(state),
        trimSources: selectTrimSources(state),
        selectedRetailer
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            setDataForSteps: setStepData,
            fetchTrimsInfo,
            fetchDatasets
        },
        dispatch
    );
};

class TrimsModal extends PureComponent {
    componentDidMount() {
        this.props.fetchDatasets(TRIM_TYPES);
    }

    isValidToSave = () => {
        const {
            store: {
                initSamplingPrint: {
                    trimType,
                    trimFrom,
                    trimCopyTrimChecked,
                    trimManualRefChecked,
                    trimManualRefTxt,
                    trimCardCopyRef
                }
            }
        } = this.props;

        let isValid = false;
        if (
            trimType &&
            trimFrom &&
            (trimCopyTrimChecked ? (trimCardCopyRef ? true : false) : true) &&
            (trimManualRefChecked
                ? trimManualRefTxt && trimManualRefTxt.length > 0
                    ? true
                    : false
                : true)
        ) {
            isValid = true;
        }
        return isValid;
    };

    checkboxHandler = key =>
        this.setValueToState({
            type: key,
            value: !this.props.store.initSamplingPrint[key]
        });

    handleText = (e, type) =>
        this.setValueToState({ type: type, value: e.target.value });

    setValueToState = param => {
        let stepKey = param.type;
        let stepData = param.value;

        this.props.setDataForSteps(stepKey, stepData);
    };

    isLoadingStatus = key => isLoading(this.props.store.global, key);

    submitData = () => {
        const { moveToNextModal, onClose } = this.props;
        onClose();
        moveToNextModal();
    };

    render() {
        const { props, isLoadingStatus, submitData } = this;
        const {
            classes,
            onClose,
            trims,
            trimTypes,
            trimSources,
            store: {
                initSamplingPrint: {
                    trimType,
                    trimFrom,
                    trimCopyTrimChecked,
                    trimManualRefChecked,
                    trimManualRefTxt,
                    trimCardCopyRef
                }
            },
            selectedRetailer
        } = props;

        const propData = {
            trimFromList: getTrimFromList()
        };

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>New trim card setup</ModalHeader>
                <ModalContent>
                    <GridContainer
                        justify='center'
                        spacing={0}
                        className={classes.gridContainer}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextInput
                                    required={true}
                                    isDisabled={true}
                                    labelText='Trim development for: '
                                    placeholderText=''
                                    value={
                                        selectedRetailer
                                            ? selectedRetailer.label
                                            : ''
                                    }
                                />
                                <SelectFields
                                    label='Trim type: '
                                    required={true}
                                    success={!!trimType}
                                    data={trimTypes}
                                    value={trimType}
                                    action={this.setValueToState}
                                    placeholder='Select Trim Type'
                                    type='trimType'
                                />
                                <SelectFields
                                    label='Where is the trim from: '
                                    required={true}
                                    success={!!trimFrom}
                                    data={trimSources}
                                    value={trimFrom}
                                    action={this.setValueToState}
                                    placeholder='Trim From'
                                    type='trimFrom'
                                />
                                <Typography component='div'>
                                    <CheckboxInput
                                        onChange={() =>
                                            this.checkboxHandler(
                                                'trimCopyTrimChecked'
                                            )
                                        }
                                        labelText='Copy data from another trim card'
                                        isChecked={trimCopyTrimChecked}
                                    />
                                    {trimCopyTrimChecked ? (
                                        <SelectFieldMultiple
                                            searchOption={false}
                                            pagination={true}
                                            rowInColumnWidth={
                                                trims.rowInColumnWidth
                                            }
                                            paginationData={
                                                trims.paginationData
                                            }
                                            columnHeader={trims.columnHeader}
                                            columnInfo={trims.columnInfo}
                                            loading={isLoadingStatus(
                                                FETCH_TRIMS_INFO
                                            )}
                                            success={!!trimCardCopyRef}
                                            required={true}
                                            action={this.setValueToState}
                                            label=''
                                            placeholder='Copy trim card from'
                                            value={
                                                trimCardCopyRef
                                                    ? trimCardCopyRef.value
                                                          .value
                                                    : null
                                            }
                                            type='trimCardCopyRef'
                                        />
                                    ) : null}
                                </Typography>
                                <Typography component='div'>
                                    <CheckboxInput
                                        onChange={() =>
                                            this.checkboxHandler(
                                                'trimManualRefChecked'
                                            )
                                        }
                                        labelText='I want to give this a manual reference'
                                        isChecked={trimManualRefChecked}
                                    />
                                    {trimManualRefChecked ? (
                                        <TextInput
                                            labelText=''
                                            placeholderText='Manual trim card reference'
                                            value={trimManualRefTxt}
                                            handleText={e =>
                                                this.handleText(
                                                    e,
                                                    'trimManualRefTxt'
                                                )
                                            }
                                        />
                                    ) : null}
                                </Typography>
                            </GridItem>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer
                        justify='center'
                        spacing={0}
                        className={classes.gridContainer}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                variant='contained'
                                disabled={!this.isValidToSave()}
                                classes={{
                                    root: classes.saveButton,
                                    disabled: classes.saveButtonDisabled
                                }}
                                onClick={() => submitData()}
                            >
                                Save
                            </Button>
                        </GridItem>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

TrimsModal.propTypes = {
    classes: PropTypes.object
};

const _TrimsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TrimsModal));

export { _TrimsModal as default, _TrimsModal as TrimsModal };
