import {
    CHANGE_STEP_DATA,
    CHANGE_NEW_CAD_DATA_SUCCESS,
    PUBLISH_TO_DATABASE,
    RESET_SAMPLING_DATA,
    SET_SAMPLING_PAGE_TYPE
} from 'store/actionTypes';

// Pristine
export const setSamplingPageType = pageType => ({
    type: SET_SAMPLING_PAGE_TYPE,
    payload: { pageType }
});

// Check
export const setStepData = (
    stepKey,
    stepData,
    isMultiple = false
) => async dispatch => {
    try {
        dispatch({
            type: CHANGE_STEP_DATA,
            stepKey: stepKey,
            stepData: stepData,
            multiple: isMultiple
        });
    } catch (err) {}
};

export function setCadStep(payload) {
    return { type: CHANGE_NEW_CAD_DATA_SUCCESS, payload };
}

export function publishToDatabase(payload) {
    return { type: PUBLISH_TO_DATABASE, payload };
}

export const resetSamplingData = () => {
    return { type: RESET_SAMPLING_DATA };
};
