import React, { Fragment, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Material
import { withStyles, Grid, Divider } from '@material-ui/core';

// Libs
import { GridItem, Input, PaginatedSelect } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware';

// Local
import { FORM_NAME, DOMAIN_NAME } from './common';
import { GET_SAMPLES } from '../redux.datasets';
import { SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN } from '../redux.actionTypes';
import ImageCard from './ImageCard';
import { finalPhotoDateLoadingSelector } from '../redux.selectors';

const styles = ({ spacing, palette }) => ({
    contentContainer: {
        marginTop: spacing.unit * 2.5,
        marginBottom: spacing.unit * 2
    },
    fieldContainer: {
        width: '90%',
        margin: 'auto',
        padding: spacing.unit * 2
    },
    divider: {
        width: '80%',
        margin: 'auto',
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 3
    },
    sourceImageCard: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        marginBottom: spacing.unit * 1.5
    }
});

const mapState = state => {
    const { garmentsType, sampleRef } = formValueSelector(FORM_NAME)(
        state,
        'garmentsType',
        'sampleRef'
    );
    return {
        isPrintSample: garmentsType === 'P',
        hasSampleRef: !!sampleRef,
        isDataLoading: finalPhotoDateLoadingSelector(state)
    };
};

const mapInputs = {
    sampleRef: ({ payload }) => ({
        type: SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN,
        payload
    })
};

const FinalPhotoView = compose(
    connect(
        mapState,
        null
    ),
    reduxForm({
        form: FORM_NAME,
        initialValues: {},
        enableReinitialize: true
    }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, isPrintSample, hasSampleRef, isDataLoading }) => {
    const PrintBlock = (
        <Fragment>
            <GridItem md={6}>
                <ImageCard name='initialCad' title='Initial CAD' />
            </GridItem>
            <GridItem md={6}>
                <ImageCard name='finalCad' title='Final Image' />
            </GridItem>
        </Fragment>
    );

    const ShapeBlock = (
        <Fragment>
            <GridItem md={6}>
                <ImageCard name='initialFront' title='Initial Front' />
            </GridItem>
            <GridItem md={6}>
                <ImageCard name='finalFront' title='Final Front' />
            </GridItem>
            <GridItem md={6}>
                <ImageCard name='initialBack' title='Initial Back' />
            </GridItem>
            <GridItem md={6}>
                <ImageCard name='finalBack' title='Final Back' />
            </GridItem>
        </Fragment>
    );

    return (
        <ContentContainer
            title='Final Photo Info'
            styles={{ container: c.contentContainer }}
            AdornmentComponent={<Fragment />}
        >
            <Grid container className={c.fieldContainer}>
                <GridItem md={3}>
                    <PaginatedSelect
                        entity={GET_SAMPLES}
                        domain={DOMAIN_NAME}
                        name='sampleRef'
                        label='Sample Reference No'
                        required
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='developmentNo'
                        label='Development NO'
                        loading={isDataLoading}
                        disabled
                        required
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='department'
                        label='Department'
                        loading={isDataLoading}
                        disabled
                        required
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='buyer'
                        label='Buyer'
                        loading={isDataLoading}
                        disabled
                    />
                </GridItem>
                {hasSampleRef && (
                    <>
                        <Divider className={c.divider} />
                        <GridItem md={12}>
                            <Grid container>
                                {isPrintSample ? PrintBlock : ShapeBlock}
                            </Grid>
                        </GridItem>
                    </>
                )}
            </Grid>
        </ContentContainer>
    );
});

export default FinalPhotoView;
