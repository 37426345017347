import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, withStyles } from '@material-ui/core';

const propTypes = {
    value: PropTypes.string.isRequired
};

const styles = ({ spacing, typography }) => ({
    root: { display: 'flex', alignItems: 'center', height: '100%' },
    value: { marginLeft: spacing.unit, lineHeight: 1.5 }
});

const Title = withStyles(styles)(({ classes: c, value }) => {
    return (
        <div className={c.root}>
            <Typography >Fabric for:</Typography>
            <Typography className={c.value}> {value}</Typography>
        </div>
    );
});

Title.propTypes = propTypes;

export default Title;
