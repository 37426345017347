import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { string } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ palette, spacing, typography }) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        backgroundColor: palette.background.light,
        padding: spacing.unit * 0.5
    },
    fieldWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: spacing.unit * 0.5
    },
    label: {
        fontWeight: typography.fontWeightSemi,
        fontSize: 10,
        marginRight: spacing.unit * 0.5,
        width: 'auto'
    },
    value: {
        fontSize: 8,
        maxWidth: '65%',
        opacity: 0.8
    },
    imageContainer: {
        width: spacing.unit * 5.5,
        height: spacing.unit * 5.5,

        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    }
});

const propTypes = {
    sampleRef: string,
    developmentNo: string,
    description: string,
    specRef: string,
    fitStatus: string,
    frontImage: string
};

const Summary = withPDFStyles(styles)(
    ({
        sampleRef,
        developmentNo,
        description,
        specRef,
        fitStatus,
        frontImage,
        classes: c
    }) => {
        return (
            <View style={c.container}>
                <View style={c.fieldWrapper}>
                    <Text style={c.label}>Sample Ref: </Text>
                    <Text style={c.value}>{sampleRef}</Text>
                </View>
                <View style={c.fieldWrapper}>
                    <Text style={c.label}>Spec Ref: </Text>
                    <Text style={c.value}>{specRef}</Text>
                </View>
                <View style={c.fieldWrapper}>
                    <Text style={c.label}>Spec Desc: </Text>
                    <Text style={c.value}>{description}</Text>
                </View>
                <View style={c.fieldWrapper}>
                    <Text style={c.label}>Dev No: </Text>
                    <Text style={c.value}>{developmentNo}</Text>
                </View>
                <View style={c.fieldWrapper}>
                    <Text style={c.label}>Fit Status: </Text>
                    <Text style={c.value}>{fitStatus}</Text>
                </View>
                <View style={c.imageContainer}>
                    {!!frontImage && <Image style={c.image} src={frontImage} />}
                </View>
            </View>
        );
    }
);

Summary.propTypes = propTypes;

export default Summary;
