import { RESET_AMENDS, SET_AMENDS, REMOVE_AMENDS } from 'actionTypes';

export const resetAmends = (amends = []) => ({
    type: RESET_AMENDS,
    payload: { amends}
});

export const setAmends = amends => ({
    type: SET_AMENDS,
    payload: { amends }
});

export const removeAmends = removedAmends => ({
    type: REMOVE_AMENDS,
    payload: { removedAmends }
});
