import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

// Local
import PaginatedSelectBase from '@baseComponents/PaginatedSelect';
import AddHandlerContainer from './AddHandlerContainer';

// Selectors
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { composeClsx } from '../../@libs/materialUI';

const propTypes = {
    name: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired
};

const mapState = (state, { domain, entity }) => ({
    loading: datasetLoadingSelector(state, domain, entity)
    // amended: selectAmendedField(state, name)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const styles = ({ palette, spacing }) => ({
    container: {
        paddingBottom: spacing.unitPadding
    },
    control: {
        height: spacing.unitHeight,
        width: '100%'
    },
    addContainer: {
        width: '80% !important'
    },
    cancellableRoot: {
        right: spacing.unit * 4
    },
    dropdownRoot: {
        '&:before': {
            display: 'none'
        }
    },
    placeholder: {
        paddingRight: spacing.unit * 6
    },
    amendControl: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: `${palette.amend.dark} !important`
    },
    amendPlaceholder: {
        color: palette.text.secondary
    }
});

const PaginatedSelect = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: {
            container,
            control,
            addContainer,
            placeholder: placeholderClass,
            amendControl,
            amendDisabled,
            amendPlaceholder,
            ...restClasses
        },
        placeholder = 'Select...',
        amended,
        addHandler = null,
        containerClass = null,
        styles = {},
        fetchDataset,
        ...restProps
    }) => {
        useEffect(() => {
            fetchDataset();
        }, []);

        const props = {
            placeholder,
            isCancellable: true,
            styles: composeClsx({
                classes: {
                    ...restClasses,
                    container: clsx(container, containerClass),
                    control: clsx(control, amended && amendControl),
                    disabled: amended && amendDisabled,
                    placeholder: clsx(
                        placeholderClass,
                        amended && amendPlaceholder
                    )
                },
                styles
            }),
            ...restProps
        };

        return !!addHandler ? (
            <AddHandlerContainer onClick={addHandler}>
                <PaginatedSelectBase {...props} />
            </AddHandlerContainer>
        ) : (
            <PaginatedSelectBase {...props} />
        );
    }
);

PaginatedSelect.propTypes = propTypes;

export { PaginatedSelect as default, PaginatedSelect };
