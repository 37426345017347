import reduceReducer from 'reduce-reducers';

// Local
import initialState from './initialState';

// Reducers
import amendsReducer from './amends';
import loadersReducer from './loaders';
import modalsReducer from './modals';
import notificationReducer from './notification';

const utilsReducer = reduceReducer(
	initialState,
	amendsReducer,
	loadersReducer,
	modalsReducer,
	notificationReducer
);

export { utilsReducer as default, utilsReducer };
