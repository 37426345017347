import { useCallback } from 'react';

import { apiClient } from '@libs/apiRequest';

export default ({
    endpoint,
    userId,
    promiseArguments,
    setPromiseArguments,
    setSnack
}) => {
    return useCallback(
        async selectedIds => {
            const {
                fieldName,
                fieldValue,
                reject,
                resolve,
                id
            } = promiseArguments;

            try {
                const { data } = await apiClient({
                    url: endpoint,
                    method: 'put',
                    data: {
                        fieldName,
                        fieldValue,
                        userId,
                        ids: selectedIds ? selectedIds : [id]
                    }
                });

                resolve(data);
                setPromiseArguments(null);
                setSnack({
                    message: 'The data update has been completed successfully',
                    type: 'success'
                });
            } catch (error) {
                reject(error);
                setPromiseArguments(null);
                setSnack({
                    message: 'The data update has been failed',
                    type: 'error'
                });
            }
        },
        [endpoint, promiseArguments, setPromiseArguments, setSnack]
    );
};
