import { fork, put, takeLatest, select } from 'redux-saga/effects';
import { change } from 'redux-form';

import { MAIL_FORM_NAME } from 'common/common';
import { entitySelector } from '@libs/datasets';

// Local
import sealSampleSagas from './sealSampleSagas';
import {
    SET_SEAL_SAMPLE_MAIL_BODY,
    SET_SEAL_SAMPLE_MAIL_RECEIVER
} from '../redux.actionTypes';
import {
    SEAL_SAMPLE_MAIL_GROUP,
    SEAL_SAMPLE_MAIL_TEMPLATE
} from '../redux.datasets';
import { DOMAIN_NAME } from '../common';

// Workers

const setEmailReceiversWorker = function*({ payload: groupId }) {
    if (groupId) {
        const groups = yield select(
            entitySelector,
            DOMAIN_NAME,
            SEAL_SAMPLE_MAIL_GROUP
        );
        const { emailGroup } = groups.find(({ id }) => id === Number(groupId));
        yield put(
            change(
                MAIL_FORM_NAME,
                'receivers',
                emailGroup.split(',').join(', ')
            )
        );
    } else {
        yield put(change(MAIL_FORM_NAME, 'receivers', ''));
    }
};

const setEmailBodyWorker = function*({ payload: templateId }) {
    if (templateId) {
        const templates = yield select(
            entitySelector,
            DOMAIN_NAME,
            SEAL_SAMPLE_MAIL_TEMPLATE
        );
        const { emailBody } = templates.find(
            ({ id }) => id === Number(templateId)
        );
        yield new Promise(res => setTimeout(() => res(), 0));
        yield put(change(MAIL_FORM_NAME, 'body', `${emailBody}`));
    } else {
        yield put(change(MAIL_FORM_NAME, 'body', ''));
    }
};

// Watchers
const qualityControlSagas = function*() {
    yield takeLatest(SET_SEAL_SAMPLE_MAIL_RECEIVER, setEmailReceiversWorker);
    yield takeLatest(SET_SEAL_SAMPLE_MAIL_BODY, setEmailBodyWorker);
};

export default function*() {
    yield fork(qualityControlSagas);
    yield fork(sealSampleSagas);
}
