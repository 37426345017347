import { useMemo } from 'react';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const useSwitchColor = ({ palette }, switchColor) =>
    useMemo(() => {
        const color = palette[switchColor];
        return {
            '--disabledColor': color.light,
            '--color': color.main,
            '--hoverColor': color.dark,
            '--menuColor': lighten(color.main, 0.1),
            '--menuHoverColor': lighten(color.dark, 0.1)
        };
    }, [switchColor]);

const useListStyle = ({ theme, color, width, height }) => {
    const switchColors = useSwitchColor(theme, color);
    return useMemo(
        () => ({
            ...switchColors,
            '--width': `${width - height - 1}px`, // 1 accounts for border
            '--height': `${height}px`
        }),
        [switchColors, width, height]
    );
};

const useDropdownStyle = height =>
    useMemo(
        () => ({
            '--width': `${height}px`,
            '--height': `${height}px`
        }),
        [height]
    );

export { useSwitchColor, useListStyle, useDropdownStyle };
