import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';
import Checkbox from '@baseComponents/Checkbox';

const styles = ({ typography }) => ({
    container: {
        flex: '0 0 25%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const CheckboxRow = withStyles(styles)(
    ({ classes: c, checked, label, onChange }) => (
        <div className={c.container} onClick={onChange}>
            <Checkbox checked={checked} color='primary' />
            <Typography className={c.checkboxLabel}>{label}</Typography>
        </div>
    )
);

CheckboxRow.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default CheckboxRow;
