import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { Input } from '@formComponents';
import { sampleSendDateSelector } from 'orders/redux.selectors';
import { FORM_NAME } from 'orders/Order/common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sampleSendDate',
    label: 'Sample Send Date',
    placeholder: 'Sample Send Date',
    disabled: true,
    required: true
};

const mapState = state => ({
    staticValue: sampleSendDateSelector(state)
});

const mapDispatch = {
    change
};

const SampleSendDate = connect(
    mapState,
    mapDispatch
)(({ change, staticValue, ...props }) => {
    useEffect(() => {
        change(FORM_NAME, 'sampleSendDate', staticValue);
    }, [staticValue]);
    return <Input {...props} />;
});

SampleSendDate.propTypes = propTypes;
SampleSendDate.defaultProps = defaultProps;

export { SampleSendDate as default, SampleSendDate };
