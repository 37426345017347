import React from 'react';
import PropTypes from 'prop-types';

// Libs
import PaginatedSelect from '@formComponents/PaginatedSelect';

// Local
import { DOMAIN_NAME } from '../../common';
import { AMEND_FABRICS } from '../../../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'copyReference',
    label: '',
    domain: DOMAIN_NAME,
    entity: AMEND_FABRICS,
    disabled: false,
    required: false
};

const SelectReferenceForCopyData = props => {
    return <PaginatedSelect {...props} />;
};

SelectReferenceForCopyData.propTypes = propTypes;
SelectReferenceForCopyData.defaultProps = defaultProps;

export default SelectReferenceForCopyData;
