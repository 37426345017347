import { useLayoutEffect, useReducer } from 'react';

import { useGradient } from './useGradient';

const initialStyles = {
    gradient: null,
    phantomGradient: null,
    phantomOpacity: 0,
    phantomDuration: 0
};

const transitionReducer = (
    state,
    { type, payload: { gradient = null, transition = 0 } = {} }
) => {
    switch (type) {
        case 'fadeIn': {
            return {
                ...state,
                phantomGradient: gradient,
                phantomOpacity: 100,
                phantomDuration: transition
            };
        }
        case 'fadeOut': {
            return {
                ...state,
                gradient: gradient,
                phantomOpacity: 0,
                phantomDuration: transition
            };
        }
        case 'duration': {
            return {
                ...state,
                phantomDuration: transition
            };
        }
        default: {
            return state;
        }
    }
};


const useTransition = theme => {
    const [state, dispatch] = useReducer(transitionReducer, initialStyles);

    const gradient = useGradient(theme);
    useLayoutEffect(() => {
        let timeout = null;
        const transition = theme.transitions.duration.complex;
        dispatch({ type: 'duration', transition: 0 });

        const transitionGradient = ({ dispatch, gradient, transition}) =>
            new Promise(resolve => {
                dispatch({
                    type: 'fadeIn',
                    payload: {
                        gradient,
                        transition
                    }
                });

                timeout = setTimeout(() => {
                    dispatch({
                        type: 'fadeOut',
                        payload: { gradient }
                    });
                    resolve();
                }, transition);
            });

        transitionGradient({
            dispatch,
            gradient: null,
            transition
        }).then(() => {
                transitionGradient({
                    dispatch,
                    gradient,
                    transition
                });
        });

        return () => {
            clearTimeout(timeout)
        };
    }, [gradient]);

    return {
        '--gradient': state.gradient,
        '--phantomGradient': state.phantomGradient,
        '--phantomOpacity': state.phantomOpacity,
        '--phantomDuration': `${state.phantomDuration / 1000}s`
    };
};

export { useTransition };
