import React, { useMemo } from 'react';

import { SEASONS } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';
import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Season = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Season', canSort: true, sortKey: 'season' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'SEASON SETUP',
        domain: DOMAIN_NAME,
        entity: SEASONS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Season as default, Season };
