import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const CardHeaderBase = props => {
    return (
        <CardHeader {...Object.splice(props, ['title', 'action', 'classes'])} />
    );
};

CardHeaderBase.defaultProps = {
    title: ''
};

CardHeaderBase.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string
};

const _CardHeaderBase = withStyles(styles)(CardHeaderBase);

export { _CardHeaderBase as default, _CardHeaderBase as CardHeaderBase };
