import makeContext from '@libs/makeContext';

const initialState = {
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const UserReducer = state => state;

const [UserProvider, useUserState, useUserDispatch, useUser] = makeContext(
    UserReducer,
    initialState,
    { name: 'User' }
);

export { useUser as default, UserProvider, useUserState, useUserDispatch };
