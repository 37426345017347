import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { DOMAIN_NAME } from './common';

const personalizationSettingsSelector = state => state[DOMAIN_NAME];

const settingsSelector = createSelector(
    personalizationSettingsSelector,
    personalizationSettings => personalizationSettings.settings
);

const settingsChangedSelector = createSelector(
    personalizationSettingsSelector,
    personalizationSettings => personalizationSettings.settingsChanged
);

const pieChartSettingsSelector = createCachedSelector(
    settingsSelector,
    (_, moduleName) => moduleName,
    (personalizationSettings, moduleName) =>
        personalizationSettings.pieChartSettings[moduleName]
)((state, moduleName) => `pie-chart-settings-${moduleName}`);

const dataGridSettingsSelector = createCachedSelector(
    settingsSelector,
    (state, moduleName) => moduleName,
    (personalizationSettings, moduleName) =>
        personalizationSettings.dataGridSettings[moduleName] || {}
)((state, moduleName) => `data-grid-settings-${moduleName}`);

export {
    pieChartSettingsSelector,
    dataGridSettingsSelector,
    settingsChangedSelector,
    settingsSelector
};
