import { takeLatest, put, call } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { gridXLFileDownload } from '@libs/sagaWorkers';

import { DOWNLOAD_CAD_AS_EXCEL } from './redux.actionTypes';
import { setDownloadCadAsExcelLoading } from './redux.actions';

function* downloadCadAsExcelWorker({ payload }) {
    const ROW_DOWNLOAD_LIMIT = 500;
    const { totalCount, selectedRows } = payload || {};

    if (
        totalCount <= ROW_DOWNLOAD_LIMIT ||
        (!!selectedRows.length && selectedRows.length <= ROW_DOWNLOAD_LIMIT)
    ) {
        try {
            yield put(setDownloadCadAsExcelLoading(true));
            yield call(gridXLFileDownload, {
                payload,
                fileName: 'cads.xlsx',
                endpoint: 'FileUpload/ExportToExcelAllCadsColumn'
            });
            yield put(setDownloadCadAsExcelLoading(false));
        } catch (error) {
            yield put(setDownloadCadAsExcelLoading(false));
            yield put(
                setSnack({
                    message: 'Failed to download the Excel file.',
                    type: 'error',
                    duration: 6000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Row count exceeds ${ROW_DOWNLOAD_LIMIT}. Please narrow your search.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

// Watchers
export default function*() {
    yield takeLatest(DOWNLOAD_CAD_AS_EXCEL, downloadCadAsExcelWorker);
}
