import { SET_SAMPLING_PAGE_TYPE } from 'actionTypes';

const referenceReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default: {
            return state;
        }
    }
};

export { referenceReducer as default, referenceReducer };
