export default ({ shadows, spacing, typography, palette }) => ({
    root: {
        boxShadow: shadows[3],
        marginBottom: spacing.unit * 8,
        padding: spacing.unit * 3,
        paddingTop: spacing.unit * 2
    },
    title: {
        fontWeight: typography.fontWeightHeavy,
        marginBottom: spacing.unit,
    },
    container: {
        width: '100%',
        padding: 0,
        boxShadow: 'unset',
        borderRadius: 'unset'
    },
    cardHeader: {
        backgroundColor: palette.grey[200],
        height: spacing.unit * 6,
        border: '1px solid',
        borderColor: palette.grey[400],
    },
    noAttachments: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height: spacing.unit * 10,

        padding: 'unset',

        border: '1px solid',
        borderColor: palette.grey[400],
        borderTop: 'unset',

        backgroundColor: palette.grey[50],
        color: palette.action.active,

        '&:last-child': {
            paddingBottom: 'unset'
        }
    },
});
