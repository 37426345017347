import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';

import styles from './styles';

const PreviousPageIcon = ({
    classes: { transform },
    className,
    ...restProps
}) => {
    const props = {
        className: className ? classNames(transform, className) : transform,
        ...restProps
    };
    return (
        <PlayArrow {...props}  />
    );
};

const _PreviousPageIcon = withStyles(styles)(PreviousPageIcon);

export {
    _PreviousPageIcon as default,
    _PreviousPageIcon as PreviousPageIcon
}