import { call } from 'redux-saga/effects';

import { welcomeEmailTemplate, sendMail } from '@libs/mailer';
import getBaseUrl from '@libs/getBaseUrl';
import { MAIL_SENDER_ADDRESS } from 'common/common';

export default function*({ name, email, token }) {
    try {
        const url = `${getBaseUrl()}/change-password/${email}/${token}`;
        const subject = 'Welcome to Echosys';
        const emailBody = welcomeEmailTemplate({
            name,
            email,
            passwordSetUrl: url,
            loginUrl: getBaseUrl()
        });

        yield call(
            sendMail,
            JSON.stringify({
                fromAddress: MAIL_SENDER_ADDRESS,
                toAddress: email,
                subject,
                body: emailBody
            })
        );
    } catch (error) {
        console.error(error);
    }
}
