import React from 'react';
import classNames from 'classnames';

// Material
import { withStyles, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// Local
import styles from './styles';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * Adornment
 */
const Adornment = ({
    classes: { icon, iconHighlight, ...c },
    highlight,
    ...restProps
}) => {
    return (
        <InputAdornment classes={c} position='start' {...restProps}>
            <SearchIcon
                className={classNames(icon, highlight && iconHighlight)}
            />
        </InputAdornment>
    );
};

const _Adornment = withStyles(styles)(Adornment);

export { _Adornment as default, _Adornment as Adornment };
