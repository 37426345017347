const FIT_TYPE_ROUTE_ID = 154;
const LABEL_TYPE_ROUTE_ID = 160;
const NECKLINE_ROUTE_ID = 161;
const ARMHOLE_CUFF_ROUTE_ID = 162;
const HEMLINE_ROUTE_ID = 163;
const REQUEST_TYPE_ROUTE_ID = 199;
const SIZE_RANGE_ROUTE_ID = 620;
const SAMPLE_EMAIL_GROUP_ROUTE_ID = 247;
const SAMPLE_EMAIL_BODY_ROUTE_ID = 248;
const SAMPLE_FACTORY_ROUTE_ID = 200;
const SAMPLE_STATUS_ROUTE_ID = 626;
const SAMPLE_ACTION_ROUTE_ID = 628;
const SAMPLE_SIZE_ROUTE_ID = 627;

// DISPATCH
const CURRIER_ROUTE_ID = 195;
const DISPATCH_TYPE_ROUTE_ID = 196;
const DISPATCH_SIZE_ROUTE_ID = 197;
const DISPATCH_EMAIL_GROUP_ROUTE_ID = 244;
const DISPATCH_EMAIL_BODY_ROUTE_ID = 245;

export {
    FIT_TYPE_ROUTE_ID,
    LABEL_TYPE_ROUTE_ID,
    NECKLINE_ROUTE_ID,
    ARMHOLE_CUFF_ROUTE_ID,
    HEMLINE_ROUTE_ID,
    REQUEST_TYPE_ROUTE_ID,
    SIZE_RANGE_ROUTE_ID,
    SAMPLE_EMAIL_GROUP_ROUTE_ID,
    SAMPLE_EMAIL_BODY_ROUTE_ID,
    SAMPLE_FACTORY_ROUTE_ID,
    SAMPLE_STATUS_ROUTE_ID,
    SAMPLE_ACTION_ROUTE_ID,
    SAMPLE_SIZE_ROUTE_ID,
    CURRIER_ROUTE_ID,
    DISPATCH_TYPE_ROUTE_ID,
    DISPATCH_SIZE_ROUTE_ID,
    DISPATCH_EMAIL_GROUP_ROUTE_ID,
    DISPATCH_EMAIL_BODY_ROUTE_ID
};
