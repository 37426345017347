import React from 'react';

import propTypes from './propTypes';
import { GridContextProvider } from './useGridContext';
import { GridRoot } from './components';

// DataGrid Component
const DataGrid = props => {
    return (
        <GridContextProvider {...props}>
            <GridRoot />
        </GridContextProvider>
    );
};

DataGrid.propTypes = propTypes;

export default DataGrid;
