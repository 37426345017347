import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'sampling/common';
import { DISPATCH_EMAIL_GROUP } from 'sampling/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const DispatchEmailGroup = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Group Name', canSort: true, sortKey: 'emailGroupName' },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Emails', canSort: false },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'DISPATCH EMAIL GROUP SETUP',
        domain: DOMAIN_NAME,
        entity: DISPATCH_EMAIL_GROUP,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { DispatchEmailGroup as default, DispatchEmailGroup };
