import { LOGOUT } from 'auth/redux.actionTypes';

import {
    RESET_LOADING,
    SET_LOADING,
    SET_LOADING_PROGRESS,
    RESET_SNACK,
    SET_SNACK,
    SET_RELOAD_ACTION,
    RESET_RELOAD_ACTIONS
} from './redux.actionTypes';

const initialState = {
    loading: false,
    loadingProgress: 0,
    snack: null,
    reloadActions: {}
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case RESET_LOADING: {
            return {
                ...state,
                loading: initialState.loading,
                loadingProgress: initialState.loadingProgress
            };
        }

        case SET_LOADING: {
            return { ...state, loading: payload };
        }

        case SET_LOADING_PROGRESS: {
            return { ...state, loadingProgress: payload };
        }

        case RESET_SNACK: {
            return { ...state, snack: initialState.snack };
        }

        case SET_SNACK: {
            return { ...state, snack: payload };
        }

        case SET_RELOAD_ACTION: {
            const { field, fieldAction, shouldPrompt } = payload;

            const reloadActions = shouldPrompt
                ? { ...state.reloadActions, [field]: fieldAction }
                : Object.entries(state.reloadActions).reduce(
                      (acm, [key, value]) => {
                          if (key === field) return acm;
                          else return { ...acm, [key]: value };
                      },
                      {}
                  );

            return { ...state, reloadActions };
        }
        case RESET_RELOAD_ACTIONS: {
            return { ...state, reloadActions: {} };
        }

        case LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
