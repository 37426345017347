import React from 'react';

import Print from './Print';
import Shape from './Shape';
import SamplingView from './SamplingView';
import FinalPhotoUpload from './FinalPhotoUpload';
import FinalPhotoView from './FinalPhotoView';
import SampleOrderedView from './SampleOrderedView';
import SampleDispatch from './SampleDispatch';
import SampleDispatchView from './SampleDispatchView';
import SamplingReport from './SamplingReport';

import {
    NEW_PRINT_ROUTE_ID,
    INCOMPLETE_PRINT_ROUTE_ID,
    AMEND_PRINT_ROUTE_ID
} from './Print/common';
import {
    AMEND_SHAPE_ROUTE_ID,
    INCOMPLETE_SHAPE_ROUTE_ID,
    NEW_SHAPE_ROUTE_ID
} from './Shape/common';
import { SAMPLING_VIEW_ROUTE_ID } from './SamplingView/common';
import { FINAL_PHOTO_UPLOAD_ROUTE_ID } from './FinalPhotoUpload/common';
import { FINAL_PHOTO_VIEW_ROUTE_ID } from './FinalPhotoView/common';
import { SAMPLE_ORDERED_VIEW_ROUTE_ID } from './SampleOrderedView/common';
import {
    NEW_SAMPLE_DISPATCH_ROUTE_ID,
    INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID,
    AMEND_SAMPLE_DISPATCH_ROUTE_ID
} from './SampleDispatch/common';
import { DISPATCH_VIEW_ROUTE_ID } from './SampleDispatchView/common';
import { SAMPLING_REPORT_ROUTE_ID } from './SamplingReport/common';

export default {
    [NEW_PRINT_ROUTE_ID]: () => <Print mode='new' />,
    [INCOMPLETE_PRINT_ROUTE_ID]: () => <Print mode='incomplete' />,
    [AMEND_PRINT_ROUTE_ID]: () => <Print mode='amend' />,
    [NEW_SHAPE_ROUTE_ID]: () => <Shape mode='new' />,
    [INCOMPLETE_SHAPE_ROUTE_ID]: () => <Shape mode='incomplete' />,
    [AMEND_SHAPE_ROUTE_ID]: () => <Shape mode='amend' />,
    [SAMPLING_VIEW_ROUTE_ID]: () => <SamplingView />,
    [FINAL_PHOTO_UPLOAD_ROUTE_ID]: () => <FinalPhotoUpload />,
    [FINAL_PHOTO_VIEW_ROUTE_ID]: () => <FinalPhotoView />,
    [SAMPLE_ORDERED_VIEW_ROUTE_ID]: () => <SampleOrderedView />,
    [NEW_SAMPLE_DISPATCH_ROUTE_ID]: () => <SampleDispatch />,
    [INCOMPLETE_SAMPLE_DISPATCH_ROUTE_ID]: () => (
        <SampleDispatch mode='incomplete' />
    ),
    [AMEND_SAMPLE_DISPATCH_ROUTE_ID]: () => <SampleDispatch mode='amend' />,
    [DISPATCH_VIEW_ROUTE_ID]: () => <SampleDispatchView />,
    [SAMPLING_REPORT_ROUTE_ID]: () => <SamplingReport />
};
