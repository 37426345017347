import { all, select } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';

import { FORM_NAME } from '../../../Shape/common';
import {
    developmentNameSelector,
    developmentTypesSelector
} from 'common/redux.selectors';
import { composeDevelopmentNumber } from '@libs/publishWorkers';

export default function*(mode) {
    const [developmentNumber, developmentTypes] = yield all([
        select(formValueSelector(FORM_NAME), 'developmentNumber'),
        select(developmentTypesSelector, 'Non Print')
    ]);

    const trueDevelopmentNumber = composeDevelopmentNumber(
        developmentTypes,
        developmentNumber,
        mode
    );

    return yield select(
        developmentNameSelector,
        'Non Print',
        trueDevelopmentNumber
    );
}
