import React, { useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { PaginatedSelect, CancelDialog } from '@formComponents';
import SplitButton from '@libComponents/SplitButton';
import Button from '@libComponents/Button';
import { withModalProvider, withModal } from '@libComponents/Modal';

import Preview from './Preview';
import { useSealSampleState } from '../useSealSample';
import { FORM_NAME, DOMAIN_NAME } from '../common';
import {
    INCOMPLETE_SEAL_SAMPLE,
    AMEND_SEAL_SAMPLE
} from '../../redux.datasets';
import {
    getPreproductionQCData,
    sealSampleRefGenerate,
    destroySealSampleForm
} from '../../redux.actions';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    dropdownButton: {
        height: spacing.unit * 4.5
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    referenceNo: ({ payload }) => getPreproductionQCData(payload)
};

const mapStateToProps = state => ({
    qcReference: formValueSelector(FORM_NAME)(state, 'qcReference')
});

const mapDispatch = {
    sealSampleRefGenerate,
    destroySealSampleForm
};

const HeaderAdornment = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        qcReference,
        sealSampleRefGenerate,
        handleModal,
        destroySealSampleForm
    }) => {
        const {
            mode,
            canSave,
            shouldFetchData,
            shouldSave,
            handleSaveDraft,
            handleHandOver,
            shouldPreview,
            handlePreview,
            reset,
            canResetForm
        } = useSealSampleState();
        const [open, setOpen] = useState(false);

        const paginatedSelectProps = useMemo(
            () => ({
                styles: {
                    container: c.selectContainer,
                    control: c.selectControl
                },
                label: null
            }),
            []
        );

        const handleReset = useCallback(() => setOpen(true), []);
        const handleClose = useCallback(() => setOpen(false), []);
        const handleConfirm = useCallback(() => {
            destroySealSampleForm();
            handleClose();
            reset();
        }, []);
        const handleHandoverWithPreview = useCallback(
            event => {
                event.persist();
                if (!qcReference) {
                    sealSampleRefGenerate();
                }
                handleModal({
                    handlePublish: handleHandOver,
                    mode,
                    watermark: 'incomplete'
                });
            },
            [handleHandOver, handleModal, qcReference]
        );

        const saveOptions = useMemo(
            () => [
                { label: 'Save Draft', callback: handleSaveDraft },
                { label: 'Handover', callback: handleHandoverWithPreview }
            ],
            [handleHandoverWithPreview]
        );

        return (
            <div className={c.container}>
                {shouldFetchData && (
                    <div className={c.selectWrapper}>
                        <Typography
                            variant='subtitle1'
                            className={c.selectLabel}
                        >
                            Select QC ref:
                        </Typography>
                        <div className={c.selectContainerWrapper}>
                            {mode === 'amend' ? (
                                <PaginatedSelect
                                    name='referenceNo'
                                    entity={AMEND_SEAL_SAMPLE}
                                    domain={DOMAIN_NAME}
                                    {...paginatedSelectProps}
                                />
                            ) : (
                                <PaginatedSelect
                                    name='referenceNo'
                                    entity={INCOMPLETE_SEAL_SAMPLE}
                                    domain={DOMAIN_NAME}
                                    {...paginatedSelectProps}
                                />
                            )}
                        </div>
                    </div>
                )}
                <Button
                    className={c.button}
                    color='error'
                    onClick={handleReset}
                    disabled={!canResetForm}
                >
                    Reset Form
                </Button>
                {shouldSave && (
                    <SplitButton
                        className={c.button}
                        color='success'
                        options={saveOptions}
                        disabled={!canSave}
                    />
                )}
                {shouldPreview && (
                    <Button
                        className={c.button}
                        onClick={() => handlePreview()}
                    >
                        Preview
                    </Button>
                )}
                <CancelDialog
                    open={open}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                />
            </div>
        );
    }
);

export default HeaderAdornment;
