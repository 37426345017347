import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { string, oneOfType, number } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

const styles = ({ spacing, palette }) => ({
    container: {
        width: '100%',
        flexDirection: 'column',
        marginTop: spacing.unit * 0.5
    },
    label: {
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.5,
        marginBottom: spacing.unit * 0.25
    },
    value: {
        fontSize: spacing.unit * 1.25,
        minHeight: spacing.unit * 2.25,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        padding: spacing.unit,
        borderRadius: 4
    }
});

const propTypes = {
    label: string.isRequired,
    value: oneOfType([string, number])
};

const Comment = withPDFStyles(styles)(({ classes: c, label, value, style }) => {
    return (
        <View style={{ ...c.container, ...style }}>
            <Text style={c.label}>{label}:</Text>
            <Text style={c.value}>{value}</Text>
        </View>
    );
});

Comment.propTypes = propTypes;

export default Comment;
