import React from 'react';
import { connect } from 'react-redux';

import PDFIcon from '@assets/Icons/Pdf';
import { setSnack } from 'utilities/redux.actions';

import { IconWithTitle } from 'dataGrid/components';

import { useCadPDF } from './hooks';
import { cadViewPDFReportSelectedDataSelector } from '../../../redux.selectors';

const mapStateToProps = (state, { data, selectedRows }) => ({
    data: cadViewPDFReportSelectedDataSelector(state, data, selectedRows)
});

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const PDF = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ data, setSnack }) => {
    const [handleExport, exportLoading] = useCadPDF({
        data,
        setSnack
    });
    return (
        <IconWithTitle
            Icon={PDFIcon}
            title='PDF'
            onClick={handleExport}
            disabled={exportLoading}
        />
    );
});

export default PDF;
