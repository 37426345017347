import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { Echo } from 'assets/Containers';
import { Drawer, Divider, GridContainer, GridItem } from 'components';
import Lists from './Lists';

import styles from './styles';

const Menus = memo(function Menus({ ...props }) {
    const { classes, isOpen, menus, hoverAction } = props;
    return (
        <Drawer
            open={isOpen}
            variant='permanent'
            onMouseEnter={() => hoverAction()}
            onMouseLeave={() => hoverAction()}
        >
            <GridContainer
                className={classNames(
                    classes.gridParent,
                    !isOpen && classes.menuButtonHidden
                )}
            >
                <GridItem md={4}>
                    <img src={Echo} alt='EchoLogyx' />
                </GridItem>
                <GridItem md={8}>
                    <div className={classes.logoText}>EchoLogyx</div>
                </GridItem>
            </GridContainer>
            <Divider />

            {/*MENUS*/}
            <Lists isOpen={isOpen} menus={menus} />
        </Drawer>
    );
});

Menus.defaultProps = {
    isOpen: false,
    hoverAction: () => {}
};

Menus.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    hoverAction: PropTypes.func,
    menus: PropTypes.array
};

const _Menus = withStyles(styles)(Menus);

export { _Menus as default, _Menus as Menus };
