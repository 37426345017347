import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

import getHeaders from './common';

const styles = ({ spacing, palette }) => ({
    headerContainer: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    headerCell: {
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.25,
        padding: spacing.unit * 0.5,
        overflowWrap: 'break-word',
        overflow: 'hidden',

        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark
    },
    headerLastCell: {
        borderRight: 'none'
    }
});

const Header = withPDFStyles(styles)(({ classes: c, isSeasalt }) => {
    const headers = getHeaders(isSeasalt);
    const headersLength = headers.length;
    return (
        <View style={c.headerContainer}>
            {headers.map(({ name, label, width }, idx) => (
                <Text
                    key={name}
                    style={{
                        ...c.headerCell,
                        width,
                        ...(headersLength - 1 === idx ? c.headerLastCell : {})
                    }}
                >
                    {label}
                </Text>
            ))}
        </View>
    );
});

export default Header;
