import React, { useMemo } from 'react';

import { RETAILER_BASE } from 'setup/redux.datasets';
import { DOMAIN_NAME } from 'setup/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Retailer = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Retailer Code', canSort: true, sortKey: 'code' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'RETAILER SETUP',
        domain: DOMAIN_NAME,
        entity: RETAILER_BASE,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Retailer as default, Retailer };
