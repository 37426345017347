import React from 'react';
import { Page, Document, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import { TabularView, Title } from 'components/PDF';
import Details from '../Details';
import SpecDetails from '../SpecDetails';
import Setup from '../Setup';
import Others from '../Others';
import Cad from '../Cad';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';

const styles = ({ palette }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    },
    watermarkContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',

        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    watermarkText: {
        position: 'relative',
        transform: 'rotate(-45deg)',
        textTransform: 'uppercase',
        color: palette.error.main,
        opacity: 0.6,
        fontSize: 78
    }
});

const Content = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        setup,
        details,
        specDetails,
        fabricDetails,
        others,
        cadInfo,
        docTitle,
        pageTitle
    }) => {
        const { count: fabDetailCount, data: fabricData } = fabricDetails;

        return (
            <Document title={docTitle}>
                <Page size='A4' style={c.page}>
                    <View>
                        <Title
                            text={pageTitle}
                            style={{ fontSize: 20, borderBottom: 1 }}
                        />
                        <Setup {...setup} />
                        <Details {...details} />
                        <SpecDetails {...specDetails} />
                        {fabDetailCount > 0 && (
                            <TabularView
                                data={fabricData}
                                title={'Fabric Details'}
                            />
                        )}
                        <Others {...others} />
                        <Cad {...cadInfo} />
                    </View>
                    <View style={c.watermarkContainer}>
                        <Text style={c.watermarkText}>incomplete</Text>
                    </View>
                </Page>
            </Document>
        );
    }
);

export default Content;
