export default theme => ({
    gridParent: {
        backgroundColor: '#000',
        margin: 0,
        alignItems: 'center'
    },
    menuButtonHidden: {
        display: 'none'
    },
    logoText: {
        color: '#fff',
        fontSize: '1.3em',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontWeight: '700'
    }
});
