import {
    FETCH_GRAPHIC_REF_SUCCESS,
    FETCH_SAMPLE_INFO_SUCCESS,
    SET_CAD_IMAGE
} from 'actionTypes';

const artworkReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        // Todo: Fix with multicolumn
        case FETCH_GRAPHIC_REF_SUCCESS: {
            return { ...state, graphicReferences: payload };
        }

        case FETCH_SAMPLE_INFO_SUCCESS: {
            const { cadImage = [] } = payload;

            return {
                ...state,
                cadImage: cadImage.map(img => img && img.originalImageUrl)
            };
        }

        case SET_CAD_IMAGE: {
            const { cadImage } = payload;
            return {
                ...state,
                cadImage
            };
        }

        default: {
            return state;
        }
    }
};

export { artworkReducer as default, artworkReducer };
