import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

// Local
import { CopyIcon, DeleteIcon, EditIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';

const propTypes = {
    handleDuplicate: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {}
});

const ActionsAdornment = withStyles(styles)(
    ({ classes: c, handleDuplicate, handleEdit, handleDelete, ...props }) => {
        return (
            <div>
                <TooltipAction
                    IconComponent={CopyIcon}
                    title='Duplicate it!'
                    onClick={handleDuplicate}
                />
                <TooltipAction
                    IconComponent={EditIcon}
                    title='Edit it!'
                    onClick={handleEdit}
                />
                <TooltipAction
                    IconComponent={DeleteIcon}
                    title='Delete it!'
                    onClick={handleDelete}
                />
            </div>
        );
    }
);

ActionsAdornment.propTypes = propTypes;
ActionsAdornment.defaultProps = defaultProps;

export default ActionsAdornment;
