import React, { useCallback } from 'react';

import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing, palette, transitions }) => ({
    clearFilter: {
        fontSize: spacing.unit * 1.5,
        color: palette.blue.main,
        cursor: 'pointer',
        textTransform: 'uppercase',
        transition: transitions.create('color', {
            duration: transitions.duration.shortest
        }),

        '&:hover': {
            color: palette.blue.dark
        },
        '&:focus': {
            outline: 'none',
            color: palette.orange.main
        }
    }
});

const ClearAll = withStyles(styles)(
    ({ classes: { clearFilter }, handleClearFilter }) => {
        const handleKeyPress = useCallback(
            ({ key }) => key === 'Enter' && handleClearFilter(),
            [handleClearFilter]
        );

        return (
            <Typography
                className={clearFilter}
                onClick={() => handleClearFilter()}
                onKeyPress={handleKeyPress}
                tabIndex={0}
            >
                clear all
            </Typography>
        );
    }
);

export default ClearAll;
