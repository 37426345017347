export default ({ palette, spacing, typography }) => ({
    head: {
        backgroundColor: palette.grey[300]
    },
    row: {
      height: spacing.unit * 5
    },
    cell: {
        color: palette.grey[900],
        fontSize: typography.subtitle1.fontSize,
        fontWeight: typography.fontWeightHeavy

    },
});
