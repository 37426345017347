import React from 'react';
import { View } from '@react-pdf/renderer';
import { array } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

import FabricHeader from './FabricHeader';
import FabricBody from './FabricBody';

const styles = ({ spacing }) => ({
    fabricsContainer: {
        width: '100%',
        padding: spacing.unit * 0.5
    }
});

const propTypes = {
    fabrics: array.isRequired
};

const Fabrics = withPDFStyles(styles)(({ fabrics, classes: c }) => {
    return (
        <View style={c.fabricsContainer}>
            <FabricHeader />
            {fabrics.map((fabric, idx) => (
                <FabricBody {...fabric} key={`product-fabric-${idx}`} />
            ))}
        </View>
    );
});

Fabrics.propTypes = propTypes;

export default Fabrics;
