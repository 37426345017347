import React, { Fragment, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import { userSampleFactoryPermissionsDataSelector } from 'administrator/redux.selectors';
import PermissionTabsTableHeader from './PermissionTabsTableHeader';
import PermissionTabsTableRow from './PermissionTabsTableRow';
import useRolePermissions from '../useRolePermissions';

const styles = () => ({});

const mapState = state => ({
    sampleFactories: userSampleFactoryPermissionsDataSelector(state)
});

const SampleFactoryPermissionTab = compose(
    connect(mapState),
    withStyles(styles)
)(({ sampleFactories }) => {
    const [
        { sampleFactoryAllSelected, permissions },
        dispatch
    ] = useRolePermissions();

    const toggleSelectionHandler = useCallback(rowId => {
        dispatch({ type: 'TOGGLE_SAMPLE_FACTORY_SELECTION', payload: rowId });
    }, []);

    const toggleAllSelectionHandler = useCallback(() => {
        dispatch({ type: 'TOGGLE_SAMPLE_FACTORY_SELECTION', payload: 'ALL' });
    }, []);

    const isRowSelectedHandler = useCallback(
        rowId => permissions.sampleFactories.includes(rowId),
        [permissions.sampleFactories]
    );

    return (
        <Fragment>
            <PermissionTabsTableHeader
                selectAll={sampleFactoryAllSelected}
                onToggleAllSelection={toggleAllSelectionHandler}
            />
            {sampleFactories.map(row => {
                return (
                    <PermissionTabsTableRow
                        row={row}
                        key={row.id}
                        isRowSelected={isRowSelectedHandler}
                        onToggleSelection={toggleSelectionHandler}
                    />
                );
            })}
        </Fragment>
    );
});

export default SampleFactoryPermissionTab;
