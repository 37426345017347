import { useMemo } from 'react';
import { useUploadState } from '../useUpload';

export const useUploadError = () => {
    const { excessUploadLimit, isValidType, touched } = useUploadState();
    const hasError = useMemo(() => excessUploadLimit || !isValidType, [
        excessUploadLimit,
        isValidType
    ]);
    const message = useMemo(() => {
        if (touched && !isValidType) {
            return 'The file you have attempted to upload is not permitted';
        } else if (excessUploadLimit) {
            return 'File size is too large to upload. Max 15MB';
        } else {
            return '';
        }
    }, [excessUploadLimit, touched, isValidType]);

    return { hasError, message };
};
