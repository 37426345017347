import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, GridContainer, Input, Select } from '@formComponents';

import SelectComposition from '../SelectComposition';
import SelectGSM from '../SelectGSM';
import { DOMAIN_NAME } from '../../common';
import { YARN_COUNT, YARN_SUPPLIER } from '../../../redux.datasets';

const styles = () => ({
    contentContainer: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0
    }
});

const DevelopedFabricDetails = withStyles(styles)(({ classes: c }) => {
    return (
        <ContentContainer
            title='Yarn details'
            AdornmentComponent={<Fragment />}
            styles={{ container: c.contentContainer }}
        >
            <GridContainer>
                <GridItem md={4}>
                    <Input
                        name='batchNo'
                        label='Batch No'
                        placeholder='Enter batch no'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='recipeNo'
                        label='Recipe No'
                        placeholder='Enter recipe no'
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectComposition name='developedFabric.composition' />
                </GridItem>
                <GridItem md={4}>
                    <SelectGSM name='developedFabric.gsm' />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='yarnCount'
                        label='Select Yarn Count'
                        domain={DOMAIN_NAME}
                        entity={YARN_COUNT}
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='yarnLot'
                        label='Yarn Lot'
                        placeholder='Enter yarn lot'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='yarnSupplier'
                        label='Select Supplier'
                        domain={DOMAIN_NAME}
                        entity={YARN_SUPPLIER}
                    />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default DevelopedFabricDetails;
