// Entities
import {
    COUNTRIES,
    FACTORIES,
    DEPARTMENTS,
    JOINED_RETAILER_FACTORIES,
    RETAILERS,
    COPY_SAMPLE_REFERENCES,
} from 'libs/datasets';

import { composeUrl, composePaginatedEntity } from '../../../common';

// Selectors
import {
    selectCountriesData,
    selectDepartmentsData,
    selectFactoriesData,
    selectJoinedRetailerFactoriesData,
    selectRetailersData,
    selectUserId,
    selectPaginatedDataset,
} from 'selectors';

// Normalizers
import {
    normalizeCountries,
    normalizeDepartments,
    normalizeFactories,
    normalizeJoinedRetailerFactories,
    normalizeRetailers,
    normalizePaginatedData,
} from 'normalizers';

// References
export default [
    {
        entity: COUNTRIES,
        url: '/api/CountryMs',
        selector: selectCountriesData,
        normalizer: normalizeCountries,
    },
    {
        entity: DEPARTMENTS,
        url: '/api/DepartmentMsts',
        selector: selectDepartmentsData,
        normalizer: normalizeDepartments,
    },
    {
        entity: FACTORIES,
        url: '/api/SampleFactoryMsts',
        selector: selectFactoriesData,
        normalizer: normalizeFactories,
    },
    {
        entity: JOINED_RETAILER_FACTORIES,
        url: '/api/UserWiseSampleFactorys',
        selector: selectJoinedRetailerFactoriesData,
        normalizer: normalizeJoinedRetailerFactories,
    },
    {
        entity: RETAILERS,
        url: (state) => `/api/UserWiseRetailer/${selectUserId(state)}`,
        selector: selectRetailersData,
        normalizer: normalizeRetailers,
    },
    {
        entity: COPY_SAMPLE_REFERENCES,
        url: (state, queryBuilder) =>
            composeUrl(
                'api/SampleRequestMains/GetCopySampleReference',
                queryBuilder(state)
            ),
        ...composePaginatedEntity(
            COPY_SAMPLE_REFERENCES,
            { sampleReference: 'Reference', status: 'Status' },
            (state) => ({
                userId: selectUserId(state),
                producttype: 'p',
            })
        ),
    },
];
