import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import { DateBase, normalizeDate } from '@baseComponents/Date';
import { isoDate } from '@libs/date';

const propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleOnChange: PropTypes.func.isRequired,
    handleOnBlur: PropTypes.func
};

const defaultProps = {
    label: '',
    placeholder: '',
    isCancellable: true
};

const styles = ({ palette, shape }) => ({
    root: {
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: palette.common.transparent,
        borderRadius: shape.borderRadius,

        '&:hover': {
            borderColor: palette.grey[400]
        }
    }
});

const GridDate = withStyles(styles)(
    ({
        classes: c,
        handleOnChange,
        handleOnBlur,
        name,
        value,
        ...dateProps
    }) => {
        const onChange = useCallback(
            (newValue = '') => {
                const iso8601Datetime =
                    (newValue && isoDate(newValue)) || newValue;
                if (iso8601Datetime !== value) {
                    handleOnChange(iso8601Datetime);
                }
            },
            [value, handleOnChange]
        );

        return (
            <DateBase
                {...dateProps}
                meta={{
                    form: 'dataGrid'
                }}
                input={{
                    name,
                    value: normalizeDate(value),
                    onChange: onChange,
                    onFocus: f => f,
                    onClose: !!handleOnBlur ? handleOnBlur : f => f
                }}
                fieldName={name}
            />
        );
    }
);

GridDate.propTypes = propTypes;
GridDate.defaultProps = defaultProps;

export default GridDate;
