import { fork, takeLatest, put, select } from 'redux-saga/effects';
import { change, focus, touch } from 'redux-form';

import { entitySelector } from '@libs/datasets';
import { userEmailSelector } from 'auth/redux.selectors';
import { MAIL_FORM_NAME } from 'common/common';

import { ORDER_EMAIL_GROUP, ORDER_EMAIL_BODY } from 'orders/redux.datasets';
import { DOMAIN_NAME } from '../common';
import {
    SET_ORDER_EMAIL_RECEIVERS,
    SET_ORDER_EMAIL_BODY
} from 'orders/redux.actionTypes';

// Local
import orderSagas from './orderSagas';

const setEmailReceiversWorker = function*({ payload: groupId }) {
    if (groupId) {
        const initiator = yield select(userEmailSelector);

        const groups = yield select(
            entitySelector,
            DOMAIN_NAME,
            ORDER_EMAIL_GROUP
        );

        const { emailGroup } = groups.find(({ id }) => id === Number(groupId));

        const receivers = emailGroup.split(',').join(', ');
        yield put(change(MAIL_FORM_NAME, 'receivers', receivers));
        yield put(focus(MAIL_FORM_NAME, 'receivers'));
        yield put(touch(MAIL_FORM_NAME, 'receivers'));
        if (!receivers.includes(initiator)) {
            yield put(change(MAIL_FORM_NAME, 'cc', initiator));
            yield put(focus(MAIL_FORM_NAME, 'cc'));
            yield put(touch(MAIL_FORM_NAME, 'cc'));
        }
    } else {
        yield put(change(MAIL_FORM_NAME, 'receivers', ''));
        yield put(change(MAIL_FORM_NAME, 'cc', ''));
    }
};

const setEmailBodyWorker = function*({ payload: templateId }) {
    if (templateId) {
        const templates = yield select(
            entitySelector,
            DOMAIN_NAME,
            ORDER_EMAIL_BODY
        );
        const { emailBody } = templates.find(
            ({ id }) => id === Number(templateId)
        );
        yield new Promise(res => setTimeout(() => res(), 0));
        yield put(change(MAIL_FORM_NAME, 'body', `${emailBody}`));
    } else {
        yield put(change(MAIL_FORM_NAME, 'body', ''));
    }
};

// Watchers
const ordersSagas = function*() {
    yield takeLatest(SET_ORDER_EMAIL_RECEIVERS, setEmailReceiversWorker);
    yield takeLatest(SET_ORDER_EMAIL_BODY, setEmailBodyWorker);
};

export default function*() {
    yield fork(ordersSagas);
    yield fork(orderSagas);
}
