import { put, select } from 'redux-saga/effects';
import { setLoadingProgress } from 'utilities/redux.actions';
import { loadingProgressSelector } from 'utilities/redux.selectors';
import isBlobUrl from '../isBlobUrl';

// Asset elements must be an object and have a url
export const composeLoadingWorker = function*({ assets, mode }) {
    const defaultIncrements = mode === 'new' ? 3 : 2;

    const increments = assets
        .filter(asset => asset && asset.hasOwnProperty('url'))
        .reduce((acm, { url }) => {
            /*
            If New or copyReference (only in New)
                if exists on local (blob)
                    - Only needs to upload, download is fast (+1)
                else
                    - Needs to download and upload (+2)
            If Amend or Incomplete
                If Blob (exists locally)
                    - Needs to download and upload (+1)
                Else if exists on server (no Blob)
                    - Can just pass the urls back (+0)
         */
            if (mode !== 'new' && !isBlobUrl(url)) return acm;
            else {
                if (isBlobUrl(url)) return acm + 1;
                else return acm + 2;
            }
        }, defaultIncrements); // Initial reference and Final publish

    yield put(setLoadingProgress(0.1));

    return function*(isFinal) {
        if (isFinal) return yield put(setLoadingProgress(0));

        const currentProgress = yield select(loadingProgressSelector);
        const incrementProgress = (1 / increments) * 100;

        const progress = currentProgress + incrementProgress;

        yield put(setLoadingProgress(progress));
    };
};

export const makeLoadingWorker = increments => {
    return function*(isFinal) {
        if (isFinal) return yield put(setLoadingProgress(0));

        const currentProgress = yield select(loadingProgressSelector);
        const incrementProgress = (1 / increments) * 100;

        const progress = currentProgress + incrementProgress;

        yield put(setLoadingProgress(progress));
    };
};
