import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

// Libs
import useDimensions from '@libHooks/useDimensions';

// Local
import ButtonGroup from './ButtonGroup';
import Dropdown from './Dropdown';
import { optionShape } from './common';

const optionsValidator = (props, propName, componentName) => {
    const options = props[propName];

    // Checks to see if options is an array
    if (!Array.isArray(options))
        return new Error(`${propName} must be an array`);
    if (options.length === 0)
        return new Error(`${propName} must have at least one element`);

    options.forEach(option => {
        return PropTypes.checkPropTypes(
            optionShape,
            option,
            'prop',
            `${componentName}.${propName}`
        );
    });
};

const propTypes = {
    options: optionsValidator,
    color: PropTypes.string,
    disabled: PropTypes.bool
};

const defaultProps = {
    color: 'primary',
    disabled: false
};

const SplitButton = ({
    color,
    options: [defaultOption, ...options],
    ...restProps
}) => {
    const [open, setOpen] = React.useState(false);

    const [anchorRef, { width, height }] = useDimensions();

    const handleClose = useCallback(
        ({ target }) =>
            (anchorRef.current && anchorRef.current.contains(target)) ||
            setOpen(false),
        [anchorRef.current]
    );

    return (
        <Fragment>
            <ButtonGroup
                ref={anchorRef}
                size={height}
                option={defaultOption}
                color={color}
                handleOpen={setOpen}
                {...restProps}
            />
            <Dropdown
                open={open}
                handleClose={handleClose}
                options={options}
                color={color}
                anchorEl={anchorRef.current}
                width={width}
                height={height}
            />
        </Fragment>
    );
};

SplitButton.propTypes = propTypes;
SplitButton.defaultProps = defaultProps;

export { SplitButton as default, SplitButton };
