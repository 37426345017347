import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

import { SelectBase } from 'components';

const styles = ({ spacing }) => ({
    root: { fontSize: spacing.unit * 1.8 },
    control: {
        height: spacing.unitHeight,
        minWidth: spacing.unit * 20,
        borderTopLeftRadius: `0 !important`,
        borderBottomLeftRadius: `0 !important`
    },

    placeholder: {
        paddingRight: spacing.unit * 6
    }
});

const SearchBarCategories = ({
    classes,
    gridViewState: { category: categoryValue, categories },
    gridViewDispatch
}) => {
    const handleChange = ({ target: { value = '' } }) => {
        gridViewDispatch({
            type: 'set-category-and-query',
            payload: value
        });
    };

    const selectProps = {
        name: 'void0',
        data: categories,
        input: {
            onFocus: () => {},
            onBlur: () => {},
            onChange: handleChange,
            value: categoryValue
        },
        showSuccess: false,
        styles: classes
    };

    return (
        <React.Fragment>
            <SelectBase {...selectProps} />
        </React.Fragment>
    );
};

const _SearchBarCategories = compose(withStyles(styles))(SearchBarCategories);

export {
    _SearchBarCategories as default,
    _SearchBarCategories as SearchBarCategories
};
