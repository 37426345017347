import React from 'react';
import { string, bool } from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { Field, Title, Checkbox } from 'components/PDF';
import { FONT_FAMILY_BOLD } from 'common/constants';
import { withPDFStyles } from '@libComponents/ReactPDF';
import { useCustomDate } from 'sampling/hooks';

const propTypes = {
    requestBy: string.isRequired,
    retailer: string.isRequired,
    department: string.isRequired,
    dateRequested: string.isRequired,
    dateRequired: string.isRequired,
    buyer: string,
    hangerType: string,
    factory: string,
    hasSpeed: bool,
    hasAvailability: bool,
    hasSustainability: bool
};

const styles = ({ spacing, typography }) => ({
    title: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldWidth: {
        width: '23%'
    },
    checkboxContainer: {
        margin: spacing.unit * 0.5
    },
    checkboxLabel: {
        fontSize: spacing.unit * 1.5,
        fontFamily: FONT_FAMILY_BOLD
    },
    checkboxes: {
        flexDirection: 'row',
        marginTop: spacing.unit * 0.5
    }
});

const CriticalPathContent = withPDFStyles(styles)(
    ({
        classes: c,
        requestBy,
        retailer,
        department,
        buyer,
        dateRequested,
        dateRequired,
        hangerType,
        factory,
        hasSpeed,
        hasAvailability,
        hasSustainability
    }) => {
        return (
            <View>
                <Title text='Critical Path' style={c.title} />
                <View style={c.container}>
                    <Field
                        label='Request By'
                        value={requestBy}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='For Retailer'
                        value={retailer}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Department'
                        value={department}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Buyer'
                        value={buyer}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Date Requested'
                        value={useCustomDate(dateRequested) || 'N/A'}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Date Required'
                        value={useCustomDate(dateRequired) || 'N/A'}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Number Required'
                        value={hangerType}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Factory'
                        value={factory}
                        style={c.fieldWidth}
                        required={false}
                    />
                </View>
                <View style={c.checkboxContainer}>
                    <Text style={c.checkboxLabel}>
                        Please Develop Considering:
                    </Text>
                    <View style={c.checkboxes}>
                        <Checkbox
                            label='Speed'
                            checked={hasSpeed}
                            style={{ width: '10%' }}
                        />
                        <Checkbox
                            label='Availability'
                            checked={hasAvailability}
                            style={{ width: '15%' }}
                        />
                        <Checkbox
                            label='Sustainability'
                            checked={hasSustainability}
                            style={{ width: '15%' }}
                        />
                    </View>
                </View>
            </View>
        );
    }
);

CriticalPathContent.propTypes = propTypes;

export {
    CriticalPathContent as default,
    propTypes as CriticalPathContentPropTypes
};
