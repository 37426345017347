import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets
} from '@libs/datasets';
import { Input } from '@formComponents';

import { DOMAIN_NAME } from 'sampling/common';
import { RETAILERS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'retailer',
    label: 'Retailer',
    placeholder: ' ',
    disabled: true,
    required: true
};

const mapState = (state, { form }) => {
    const retailer = formValueSelector(form)(state, 'retailer');
    return {
        staticValue: valueSelector(state, DOMAIN_NAME, RETAILERS, retailer),
        loading: datasetLoadingSelector(state, DOMAIN_NAME, RETAILERS)
    };
};

const mapDispatch = {
    fetchDatasets
};

const Retailer = connect(
    mapState,
    mapDispatch
)(({ fetchDatasets, ...props }) => {
    useEffect(() => {
        fetchDatasets(RETAILERS);
    }, []);

    return <Input {...props} />;
});

Retailer.propTypes = propTypes;
Retailer.defaultProps = defaultProps;

export { Retailer as default, Retailer };
