export const FETCH_BOUGHT_DETAILS_TYPES = 'get_bought_details_types';
export const FETCH_BOUGHT_DETAILS_TYPES_SUCCESS =
	'get_bought_details_types_success';
export const FETCH_BOUGHT_DETAILS_TYPES_FAILURE =
	'get_bought_details_types_failure';

export const GET_BOUGHT_DETAILS_REQUEST = 'post_bought_details';
export const GET_BOUGHT_DETAILS_SUCCESS = 'post_bought_details_success';

export const DELETE_BOUGHT_DETAIL_TYPE = 'delete_bought_detail_type';
export const DELETE_BOUGHT_DETAIL_TYPE_SUCCESS =
	'delete_bought_detail_type_success';

export const DELETE_BOUGHT_ITEM_REQUEST = 'delete_bought_item';
export const DELETE_BOUGHT_ITEM_SUCCESS = 'delete_bought_item_success';
