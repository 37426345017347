import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { string } from 'prop-types';
import { Image } from '@libComponents/ReactPDF';

const styles = StyleSheet.create({
    frameWrapper: {
        width: '48%',
        border: 1,
        borderColor: '#c8ced4',
        flexDirection: 'column',
        minHeight: '150px',

        marginBottom: '10px'
    },
    label: {
        width: '100%',
        height: '44px',
        flexDirection: 'row',
        padding: '8px 8px 8px 24px',
        alignItems: 'center',
        backgroundColor: '#eff3f6',
        borderBottom: 1,
        borderBottomColor: '#c8ced4'
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    }
});

const propTypes = {
    url: string,
    label: string
};

const defaultProps = {
    label: 'Image'
};

const Frame = ({ url, label }) => (
    <View style={styles.frameWrapper}>
        <Text style={styles.label}>{label}</Text>
        {url && <Image src={url} style={styles.image} />}
    </View>
);
Frame.propTypes = propTypes;
Frame.defaultProps = defaultProps;

export { Frame as default, Frame };
