import axios from 'axios';
import { getCookie } from 'helpers/auth';

export const API_BASE_URL = 'https://18.130.139.199/echoapi/';
// export const API_BASE_URL = "http://172.16.20.24/";
export const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

export const apiRequest = ({
    method: rawMethod = 'get',
    uri,
    type = null,
    queries = null,
    data = null
}) => {
    const methods = ['get', 'post', 'put', 'delete'];

    const method = rawMethod.toLowerCase();

    const AUTH_TOKEN = getCookie();

    const requestURI = [API_BASE_URL, uri];

    try {
        // Method validation
        if (!methods.includes(method)) {
            throw Error('Invalid URI method');
        }

        if (
            (method === 'post' || method === 'put') &&
            typeof data === 'undefined'
        ) {
            throw Error(`${method} data is undefined`);
        }

        // For isLoading
        if (type) apiClient.defaults.headers.common['xtype'] = type;
        if (AUTH_TOKEN)
            apiClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${AUTH_TOKEN}`;

        // Query handling & validation
        if (queries && typeof queries === 'object') {
            if (Array.isArray(queries)) {
                throw Error('Queries must be a key-value object');
            }
            requestURI.push(
                Object.keys(queries)
                    .reduce(
                        (fullQuery, query) =>
                            `${fullQuery}${query}=${queries[query]}&`,
                        '?'
                    )
                    // Taking off the last (&) ampersand
                    .slice(0, -1)
            );
        }

        // One source of truth
        let apiRequest;

        // Data handling & validation
        if (method === 'post' || method === 'put') {
            if (data && typeof data === 'object') {
                if (Array.isArray(data)) {
                    throw Error('Data must be a key-value object');
                }
                const formData = new FormData();

                Object.entries(data).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                console.log('in config')
                apiRequest = apiClient[method](requestURI.join(''), formData);
            }
        } else {
            apiRequest = apiClient[method](requestURI.join(''));
        }

        return apiRequest;
    } catch (error) {
        console.error(error);
    }
};

//timeout in 30 seconds
apiClient.defaults.timeout = 60000;
