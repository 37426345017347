import React from 'react';
import PropTypes from 'prop-types';

// Material
import {
    withStyles,
    ListItem as MuiListItem,
    ListItemText
} from '@material-ui/core';

import GridCheckbox from '../../../../../../GridCheckbox';

const propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired
};

const styles = () => ({
    root: {
        padding: 0,
        cursor: 'pointer'
    },
    textRoot: {
        padding: 0
    }
});

const ListItem = withStyles(styles)(
    ({ classes: c, name, header, isChecked, ...rest }) => {
        return (
            <MuiListItem dense {...rest} className={c.root}>
                <GridCheckbox checked={isChecked} />
                <ListItemText primary={header} className={c.textRoot} />
            </MuiListItem>
        );
    }
);

ListItem.propTypes = propTypes;

export default ListItem;
