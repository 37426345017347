import React, { useCallback } from 'react';
import { compose } from 'redux';
import { string } from 'prop-types';

// Libs
import { TooltipAction } from '@formComponents';
import { withModalProvider, withModal } from '@libComponents/Modal';

// Assets
import { PreviewIcon } from '@assets/Icons';

// Local
import PreviewModal from './PreviewModal';

const propTypes = {
    url: string.isRequired,
    name: string.isRequired
};

const PreviewAction = compose(
    withModalProvider,
    withModal({
        handleModal: PreviewModal
    })
)(({ url, name, handleModal }) => {
    const handlePreviewModal = useCallback(() => {
        handleModal({
            url,
            name
        });
    }, [url, name, handleModal]);

    return (
        <TooltipAction
            onClick={handlePreviewModal}
            IconComponent={PreviewIcon}
            title='Preview it!'
        />
    );
});

PreviewAction.propTypes = propTypes;

export default PreviewAction;
