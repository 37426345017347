import PropTypes from 'prop-types';

const DETAIL_TYPES = {
    boolean: 'boolean',
    custom: 'custom',
    string: 'string'
};

const valuePropType = PropTypes.oneOfType([PropTypes.node, PropTypes.bool])
    .isRequired;

const labelPropType = PropTypes.string;

const typePropType = PropTypes.oneOf(Object.values(DETAIL_TYPES));

const detailsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        value: valuePropType,
        label: labelPropType,
        type: typePropType
    })
);

export {
    DETAIL_TYPES,
    valuePropType,
    labelPropType,
    typePropType,
    detailsPropType
};
