import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import createCachedSelector from 're-reselect';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

// Local
import { composeClsx } from '@libs/materialUI';
import SelectBase from '@baseComponents/Select';
import MultipleSelectBase from '@baseComponents/MultipleSelect';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    styles: {},
    data: [],
    amended: false
};

const styles = ({ palette, spacing }) => ({
    container: {
        paddingBottom: spacing.unitPadding
    },
    control: {
        height: spacing.unitHeight,
        width: '100%'
    },
    cancellableRoot: {
        right: spacing.unit * 4
    },
    dropdownRoot: {
        '&:before': {
            display: 'none'
        }
    },
    placeholder: {
        paddingRight: spacing.unit * 6
    },
    amendControl: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: `${palette.amend.dark} !important`
    },
    amendPlaceholder: {
        color: palette.text.secondary
    }
});

const SelectModel = compose(withStyles(styles))(
    ({
        classes,
        placeholder = 'Select...',
        amended,
        styles,
        multiple = false,
        ...restProps
    }) => {
        const {
            control,
            placeholder: placeholderClass,
            amendControl,
            amendDisabled,
            amendPlaceholder,
            ...restClasses
        } = composeClsx({ classes, styles });

        const props = {
            placeholder,
            isCancellable: true,
            styles: {
                ...restClasses,
                control: clsx(control, amended && amendControl),
                disabled: amended && amendDisabled,
                placeholder: clsx(placeholderClass, amended && amendPlaceholder)
            },
            ...restProps
        };
        return multiple ? (
            <MultipleSelectBase {...props} />
        ) : (
            <SelectBase {...props} />
        );
    }
);

SelectModel.propTypes = propTypes;
SelectModel.defaultProps = defaultProps;

export { SelectModel as default, SelectModel };
