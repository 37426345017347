// Entities
import {
    REQUEST_TYPES,
    SEND_TO_BUYERS,
    SEND_TO_LICENSORS,
    SEND_TO_UKS
} from 'libs';

export default {
    [REQUEST_TYPES]: [],
    [SEND_TO_BUYERS]: [],
    [SEND_TO_LICENSORS]: [],
    [SEND_TO_UKS]: [],
    sampleReferences: {}
};
