import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

const styles = ({ spacing }) => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    rowHeader: {
        marginRight: spacing.unit * 0.5,
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.5
    },
    rowValue: {
        marginRight: spacing.unit * 1.5,
        fontSize: spacing.unit * 1.5
    }
});

const TitleRow = withPDFStyles(styles)(({ classes: c, label, value }) => {
    return (
        <View style={c.row}>
            <Text style={c.rowHeader}>{label}:</Text>
            <Text style={c.rowValue}>{value}</Text>
        </View>
    );
});

export default TitleRow;
