import React, { useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { PaginatedSelect, CancelDialog } from '@formComponents';
import Button from '@libComponents/Button';

import { useUserState } from '../useUser';
import { FORM_NAME, DOMAIN_NAME, INITIAL_VALUES } from '../common';
import { getAmendUserData } from '../../redux.actions';
import { AMEND_USERS_LIST } from '../../redux.datasets';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    dropdownButton: {
        height: spacing.unit * 4.5
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    reference: ({ payload }) => getAmendUserData(payload)
};

const mapDispatch = dispatch => ({
    resetUserForm: mode =>
        dispatch(initialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {}))
});

const HeaderAdornment = compose(
    connect(
        null,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, resetUserForm }) => {
    const { shouldFetchData, reset, canResetForm, mode } = useUserState();
    const [open, setOpen] = useState(false);

    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null
        }),
        []
    );

    const handleResetForm = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleConfirm = useCallback(() => {
        resetUserForm(mode);
        handleClose();
        reset();
    }, [mode]);

    return (
        <div className={c.container}>
            {shouldFetchData && (
                <div className={c.selectWrapper}>
                    <Typography variant='subtitle1' className={c.selectLabel}>
                        Select user:
                    </Typography>
                    <div className={c.selectContainerWrapper}>
                        <PaginatedSelect
                            name='reference'
                            entity={AMEND_USERS_LIST}
                            domain={DOMAIN_NAME}
                            {...paginatedSelectProps}
                        />
                    </div>
                </div>
            )}
            <Button
                className={c.button}
                color='error'
                onClick={handleResetForm}
                disabled={!canResetForm}
            >
                Reset Form
            </Button>

            <CancelDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </div>
    );
});

export default HeaderAdornment;
