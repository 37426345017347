import React from 'react';
import PropTypes from 'prop-types';

import { Date as DateInput } from '@formComponents';
import { required } from '@libs/validationRules';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';

const validateSendDateAndETA = (fieldValue, { actualSendDate }) => {
    const eta = new Date(deserializeDate(fieldValue));
    const actSendDate = new Date(deserializeDate(actualSendDate));
    if (actSendDate > eta) {
        return `'ETA' must be greater than 'Actual Send Date'`;
    }
    return null;
};

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'eta',
    label: 'ETA',
    validate: [required, validateSendDateAndETA],
    required: true
};

const ETADate = props => {
    return <DateInput {...props} />;
};

ETADate.propTypes = propTypes;
ETADate.defaultProps = defaultProps;

export default ETADate;
