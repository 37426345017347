import { takeLatest, call, put, select } from 'redux-saga/effects';
import { API_BASE_URL, apiClient } from 'store/config';
import { apiRequest } from 'helpers';

import { initialize } from 'redux-form';

// Action Types
import {
    POST_NEW_CAD_REQUEST,
    POST_NEW_CAD_SUCCESS,
    UPDATE_NEW_CAD_REQUEST,
    UPDATE_NEW_CAD_SUCCESS,
    POST_CAD_COLOR_REQUEST,
    POST_CAD_COLOR_SUCCESS,
    CHECK_COLOR_DUPLICATE,
    CHECK_COLOR_DUPLICATE_SUCCESS,
    FETCH_CAD_GRAPHIC_REF_INCOMPLETE,
    FETCH_CAD_GRAPHIC_REF_INCOMPLETE_SUCCESS,
    FETCH_CAD_GRAPHIC_REF_AMEND,
    FETCH_CAD_GRAPHIC_REF_AMEND_SUCCESS,
    FETCH_CAD_REF_INFO,
    FETCH_CAD_REF_INFO_SUCCESS,
    SET_CAD_IMAGE,
    POST_CAD_COLLECTION_REQUEST
} from 'store/actionTypes';
import { selectLicensors } from '../../selectors/sampling/modals';
import { selectLicensorsData } from '../../selectors/datasets/sampling';

//api calling

    async function postNewCad(payload) {
        apiClient.defaults.headers.common['xtype'] = POST_NEW_CAD_REQUEST;
        return await apiClient.post(
            API_BASE_URL + 'api/CadRequestMains/PostSamGraphRefSave',
            payload
        );
    }

    async function postCadColor(payload) {
        apiClient.defaults.headers.common['xtype'] = POST_CAD_COLOR_REQUEST;
        return await apiClient.post(
            API_BASE_URL + 'api/CadRequestMains/PostCadColorSave',
            payload
        );
    }

    async function updateNewCad({ payload, refId }) {
        apiClient.defaults.headers.common['xtype'] = UPDATE_NEW_CAD_REQUEST;
        return await apiClient.put(
            API_BASE_URL + `api/CadRequestMains/PutCadRequest?id=${refId}`,
            payload
        );
    }

    async function checkColorDuplicate({ cadRef, colorOption }) {
        apiClient.defaults.headers.common['xtype'] = CHECK_COLOR_DUPLICATE;
        return await apiClient.get(
            API_BASE_URL +
            `api/CadRequestMains/GetCadColorDuplicate?cadRef=${cadRef}&coloroption=${colorOption}`
        );
    }

    async function getGraphicRefIncompleteList(
        { userId, type, searchKey, pageNo },
        pageSize = 10
    ) {
    apiClient.defaults.headers.common[
        'xtype'
    ] = FETCH_CAD_GRAPHIC_REF_INCOMPLETE;
    return await apiClient.get(
        API_BASE_URL +
            `api/CadRequestMains/GetCadReference?userid=${userId}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}&type=${type}`
    );
}

async function getGraphicRefAmendList(
    { userId, type, searchKey, pageNo },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_CAD_GRAPHIC_REF_AMEND;
    return await apiClient.get(
        API_BASE_URL +
            `api/CadRequestMains/GetCadReference?userid=${userId}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}&type=${type}`
    );
}

async function getCadRefInfo(cadRefId) {
    apiClient.defaults.headers.common['xtype'] = FETCH_CAD_REF_INFO;
    return await apiClient.get(
        API_BASE_URL +
            `api/CadRequestMains/GetCadReferenceInfo?cadref=${cadRefId}`
    );
}

//workers
function* newCadEffectSaga({ payload: { isManualChecked } }) {
    try {
        const state = yield select();
        const data = state.initSamplingPrint;
        const auth = state.auth;
        const cadPayload = new FormData();

        const licensors = yield select(selectLicensorsData);
        const licensorId = data.newCadLicensor.value;
        const [{ licensorCode }] = licensors.filter(
            ({ licensorId: id }) => id === licensorId
        );

        if (data.newCadDesigner)
            cadPayload.append('Designer', Number(data.newCadDesigner.value));
        if (data.newCadType)
            cadPayload.append('CadType', data.newCadType.value);
        if (data.newCadLicensor) cadPayload.append('Licensor', licensorId);
        if (data.newCadLicensor)
            cadPayload.append('LicensorCode', licensorCode);
        if (isManualChecked)
            cadPayload.append('CadRefferenceId', data.newCadManualRef);
        cadPayload.append('ManualReferenceCheck', isManualChecked);
        cadPayload.append('UserId', auth.userId);
        cadPayload.append('UserName', auth.userName);

        const res = yield call(postNewCad, cadPayload);
        //cadType: 1 = new, 2 = incomplete, 3 = amend, 4 = color
        // SET cadUpload return
        // 201
        yield put({
            type: POST_NEW_CAD_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // // if (err && err.response) flashMessage(err.response, true);
    }
}

function* postCadColorEffectSaga() {
    try {
        const payload = yield select(
            ({
                auth: { userId, userName },
                initSamplingPrint: {
                    newCadDesigner,
                    newCadType,
                    newCadLicensor,
                    newCadManualRef,
                    newCadIncompleteRef,
                    newCadAmendCadRef,
                    newCadColourRef,
                    newCadColourOp,
                    newCadRefId,
                    newCadRef,
                    newCadImg,
                    newCadBuyer,
                    newCadDevNo,
                    newCadDevName,
                    newCadDescription,
                    newCadRetailer,
                    newCadDepartment,
                    newCadCollection,
                    newCadCopyDevNo,
                    newCadCopyCadId,
                    newCadCopyCadRef
                }
            }) => ({
                Designer: newCadDesigner ? newCadDesigner.value : undefined,
                CadType: newCadType ? newCadType.value : null,
                Licensor: newCadLicensor ? newCadLicensor.value.id : undefined,
                LicensorCode: newCadLicensor ? newCadLicensor.value.code : null,
                ColorOption: newCadColourOp,
                CadId: newCadRefId,
                CadRefferenceId: newCadRef,
                Buyer: newCadBuyer,
                DevelopmentNo: newCadDevNo,
                DevelopmentText: newCadDevName,
                Description: newCadDescription,
                Department: newCadDepartment
                    ? newCadDepartment.value
                    : undefined,
                CadCollection: newCadCollection
                    ? newCadCollection.value
                    : undefined,
                IsDiscontinue: true,
                UserId: userId,
                UserName: userName,
                CopyCadReference: newCadCopyCadRef,
                CopyCadDevelopmentNo: newCadCopyDevNo,
                CopyCadId: newCadCopyCadId,
                file: newCadImg
            })
        );

        const cadPayload = new FormData();

        for (let key in payload) {
            if (!!payload[key]) cadPayload.append(key, payload[key]);
        }

        yield call(postCadColor, cadPayload);
        yield put(
            initialize(
                'SamplingPrintForm',
                {
                    cadImage: true,
                    graphicReference: payload.CadRefferenceId,
                    graphicDescription: payload.Description
                },
                { keepValues: true }
            )
        );

        yield put({
            type: SET_CAD_IMAGE,
            payload: { cadImage: payload.file }
        });

        yield put({
            type: POST_CAD_COLOR_SUCCESS
        });
    } catch (err) {
        // // if (err && err.response) flashMessage(err.response, true);
    }
}

function* updateCadEffectSaga() {
    try {
        const payload = yield select(
            ({
                auth: { userId, userName },
                initSamplingPrint: {
                    newCadDesigner,
                    newCadType,
                    newCadLicensor,
                    newCadManualRef,
                    newCadIncompleteRef,
                    newCadAmendCadRef,
                    newCadColourRef,
                    newCadColourOp,
                    newCadRefId,
                    newCadRef,
                    newCadImg,
                    newCadBuyer,
                    newCadDevNo,
                    newCadDevName,
                    newCadDescription,
                    newCadRetailer,
                    newCadDepartment,
                    newCadCollection,
                    newCadCopyDevNo,
                    newCadCopyCadId,
                    newCadCopyCadRef
                }
            }) => ({
                Designer: newCadDesigner ? newCadDesigner.value : undefined,
                CadType: newCadType ? newCadType.value : null,
                Licensor: newCadLicensor ? newCadLicensor.value.id : undefined,
                LicensorCode: newCadLicensor ? newCadLicensor.value.code : null,
                ColorOption: newCadColourOp,
                CadId: newCadRefId,
                CadRefferenceId: newCadRef,
                Buyer: newCadBuyer,
                DevelopmentNo: newCadDevNo,
                DevelopmentText: newCadDevName,
                Description: newCadDescription,
                Department: newCadDepartment
                    ? newCadDepartment.value
                    : undefined,
                CadCollection: newCadCollection
                    ? newCadCollection.value
                    : undefined,
                IsDiscontinue: true,
                UserId: userId,
                UserName: userName,
                CopyCadReference: newCadCopyCadRef,
                CopyCadDevelopmentNo: newCadCopyDevNo,
                CopyCadId: newCadCopyCadId,
                file: newCadImg
            })
        );

        const cadPayload = new FormData();

        for (let key in payload) {
            if (!!payload[key]) cadPayload.append(key, payload[key]);
        }

        yield call(updateNewCad, {
            payload: cadPayload,
            refId: payload.CadId
        });
        yield put(
            initialize(
                'SamplingPrintForm',
                {
                    cadImage: true,
                    graphicReference: payload.CadRefferenceId,
                    graphicDescription: payload.Description
                },
                { keepValues: true }
            )
        );

        yield put({
            type: SET_CAD_IMAGE,
            payload: { cadImage: payload.file }
        });

        yield put({
            type: UPDATE_NEW_CAD_SUCCESS
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* colorCheckEffectSaga() {
    try {
        const payload = yield select(
            ({ initSamplingPrint: { newCadColourRef, newCadColourOp } }) => ({
                cadRef: newCadColourRef ? newCadColourRef.value.value : null,
                colorOption: newCadColourOp
            })
        );
        const res = yield call(checkColorDuplicate, payload);

        //200
        const response = {
            data: res.data.data[0],
            type: 4 // cadType: color
        };
        if (!response.exists) {
            yield put({
                type: CHECK_COLOR_DUPLICATE_SUCCESS,
                payload: response
            });
        }
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* getGraphicRefIncompleteEffectSaga({ payload }) {
    try {
        const userId = yield select(state => state.auth.userId);
        const data = {
            userId,
            ...payload
        };
        const res = yield call(getGraphicRefIncompleteList, data);
        yield put({
            type: FETCH_CAD_GRAPHIC_REF_INCOMPLETE_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* getGraphicRefAmendEffectSaga({ payload }) {
    try {
        const userId = yield select(state => state.auth.userId);
        const data = {
            userId,
            ...payload
        };
        const res = yield call(getGraphicRefAmendList, data);
        yield put({
            type: FETCH_CAD_GRAPHIC_REF_AMEND_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* getCadRefInfoEffectSaga({ payload: { type } }) {
    try {
        let cadRefId;
        if (type === 2) {
            // incomplete
            cadRefId = yield select(state =>
                state.initSamplingPrint.newCadIncompleteRef
                    ? state.initSamplingPrint.newCadIncompleteRef.value.value
                    : null
            );
        } else if (type === 3) {
            // amend
            cadRefId = yield select(state =>
                state.initSamplingPrint.newCadAmendCadRef
                    ? state.initSamplingPrint.newCadAmendCadRef.value.value
                    : null
            );
        }

        const res = yield call(getCadRefInfo, cadRefId);
        const data = {
            data: res.data.data[0]
        };
        // SET cadUpload return
        yield put({
            type: FETCH_CAD_REF_INFO_SUCCESS,
            payload: data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* postNewCollectionSaga({ payload }) {
    try {
        yield call(apiRequest, {
            url: '/api/Collections',
            method: 'post',
            data: payload
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const cadWatchers = function*() {
    yield takeLatest(POST_NEW_CAD_REQUEST, newCadEffectSaga);
    yield takeLatest(POST_CAD_COLOR_REQUEST, postCadColorEffectSaga);
    yield takeLatest(UPDATE_NEW_CAD_REQUEST, updateCadEffectSaga);
    yield takeLatest(CHECK_COLOR_DUPLICATE, colorCheckEffectSaga);
    yield takeLatest(
        FETCH_CAD_GRAPHIC_REF_INCOMPLETE,
        getGraphicRefIncompleteEffectSaga
    );
    yield takeLatest(FETCH_CAD_GRAPHIC_REF_AMEND, getGraphicRefAmendEffectSaga);
    yield takeLatest(FETCH_CAD_REF_INFO, getCadRefInfoEffectSaga);
    yield takeLatest(POST_CAD_COLLECTION_REQUEST, postNewCollectionSaga);
};

export { cadWatchers as default, cadWatchers };
