import { takeLatest, put, call } from 'redux-saga/effects';

import { redirectToRoute } from 'navigation/redux.actions';
import { setSnack } from 'utilities/redux.actions';
import { gridXLFileDownload } from '@libs/sagaWorkers';

import { ROUTE_TO_AMEND_USER, EXPORT_USER_XL_DATA } from '../redux.actionTypes';
import { AMEND_USER_ROUTE_ID } from '../User/common';
import { getAmendUserData, setExportUserXLDataLoading } from '../redux.actions';

const routeToAmendUserWorker = function*({ payload: email }) {
    yield put(redirectToRoute(AMEND_USER_ROUTE_ID));
    yield put(getAmendUserData(email));
};

function* exportXLDataWorker({ payload }) {
    const ROW_DOWNLOAD_LIMIT = 500;
    const { totalCount, selectedRows } = payload || {};

    if (
        totalCount <= ROW_DOWNLOAD_LIMIT ||
        (!!selectedRows.length && selectedRows.length <= ROW_DOWNLOAD_LIMIT)
    ) {
        try {
            yield put(setExportUserXLDataLoading(true));
            yield call(gridXLFileDownload, {
                payload,
                fileName: 'Users.xlsx',
                endpoint: 'FileUpload/ExportToExcelAllUserColumn'
            });
            yield put(setExportUserXLDataLoading(false));
        } catch (error) {
            yield put(setExportUserXLDataLoading(false));
            yield put(
                setSnack({
                    message: 'Failed to download the Users Excel file.',
                    type: 'error',
                    duration: 6000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Row count exceeds ${ROW_DOWNLOAD_LIMIT}. Please narrow your search.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

// Watchers
export default function*() {
    yield takeLatest(ROUTE_TO_AMEND_USER, routeToAmendUserWorker);
    yield takeLatest(EXPORT_USER_XL_DATA, exportXLDataWorker);
}
