import React from 'react';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import clsx from 'classnames';

import { withStyles, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { Date as DateInput } from '@formComponents';
import { denormalizeDate } from '@baseComponents/Date/normalizeDate';

import { FORM_NAME } from '../common';
import useDateRangeNavigation from './hooks';

const styles = ({ spacing, breakpoints, palette }) => ({
    container: {
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gridGap: `${spacing.unit}px`,
        [breakpoints.down(770)]: {
            width: '100%'
        }
    },
    gridItem: {
        paddingLeft: spacing.unit,
        paddingRight: spacing.unit
    },
    inputRoot: {
        backgroundColor: 'white'
    },
    iconButton: {
        width: spacing.unit * 5,
        height: spacing.unit * 5
    },
    icon: {
        width: spacing.unit * 5,
        height: spacing.unit * 5,
        color: palette.grey[600]
    },
    disabled: {
        color: palette.grey[400]
    }
});

const mapStateToProps = state => ({
    startDate: formValueSelector(FORM_NAME)(state, 'startDate'),
    endDate: formValueSelector(FORM_NAME)(state, 'endDate')
});

const mapDispatchToProps = dispatch => ({
    changeDate: (startDate, endDate) => {
        dispatch(change(FORM_NAME, 'startDate', startDate));
        dispatch(change(FORM_NAME, 'endDate', endDate));
    }
});

const DateRangeFilter = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(({ classes: c, startDate, endDate, changeDate }) => {
    const {
        endDateMax,
        navigateDates,
        isNextDisabled
    } = useDateRangeNavigation(startDate, endDate, changeDate);

    const dateInputProps = {
        label: '',
        styles: { root: c.inputRoot }
    };

    return (
        <div className={c.container}>
            <IconButton
                onClick={() => navigateDates(-1)}
                className={c.iconButton}
            >
                <ChevronLeft className={c.icon} />
            </IconButton>
            <DateInput
                name='startDate'
                placeholder='From'
                minDate={new Date('2015-01-01')}
                {...dateInputProps}
            />
            <DateInput
                name='endDate'
                placeholder='To'
                minDate={denormalizeDate(startDate)}
                maxDate={endDateMax}
                {...dateInputProps}
            />
            <IconButton
                onClick={() => navigateDates(1)}
                className={c.iconButton}
                disabled={isNextDisabled}
            >
                <ChevronRight
                    className={clsx(c.icon, isNextDisabled && c.disabled)}
                />
            </IconButton>
        </div>
    );
});

export default DateRangeFilter;
