import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { Input } from '@formComponents';
import { roundNumber } from '@libs/number';

import { PO_FORM_NAME } from 'orders/Order/common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'totalYarnPrice',
    label: 'Total Yarn Price',
    disabled: true,
    required: true
};

const mapState = state => {
    const {
        totalFabric = 0,
        yarnPrice: { inputValue = 0 } = {}
    } = formValueSelector(PO_FORM_NAME)(state, 'totalFabric', 'yarnPrice');
    return {
        totalYarnPrice: roundNumber(totalFabric * inputValue) || ''
    };
};

const mapDispatch = {
    change
};

const TotalYarnPrice = connect(
    mapState,
    mapDispatch
)(({ change, totalYarnPrice, ...props }) => {
    useEffect(() => {
        change(PO_FORM_NAME, 'totalYarnPrice', totalYarnPrice);
    }, [totalYarnPrice]);
    return <Input {...props} />;
});

TotalYarnPrice.propTypes = propTypes;
TotalYarnPrice.defaultProps = defaultProps;

export { TotalYarnPrice as default, TotalYarnPrice };
