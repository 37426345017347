// Entities
import {
    trimSources,
    TRIM_DETAILS,
    CURRENCIES,
    TRIM_SOURCES,
    TRIM_SUPPLIERS,
    TRIM_TYPES,
} from 'libs';

export default {
    [TRIM_DETAILS]: { nextPage: false, data: [] },
    [CURRENCIES]: [],
    [TRIM_SUPPLIERS]: [],
    [TRIM_TYPES]: [],
    [TRIM_SOURCES]: trimSources,
};
