import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

// Local
import {
    GridContainer,
    GridItem,
    Input,
    Select,
    Checkbox,
    Filler,
    NavigateButton,
} from 'components';
import { SamplingCopyReferenceSelect } from 'views/Sampling/components';
import { formListener } from 'middlewares';
import { validateRequired } from 'helpers';

// Actions
import { fetchDatasets, fetchCopySampleRef, requestFormSubmit } from 'actions';

// Selectors
import {
    selectRetailers,
    selectDepartments,
    selectFactoryLocations,
    selectCopySampleRefList,
    selectProductTypes,
    selectDesignTypes,
    selectCopyReferences,
    selectCopySampleReferencesNew,
    selectCopySampleReferencesData,
} from 'selectors';

// Libs
import {
    COUNTRIES,
    DEPARTMENTS,
    FACTORIES,
    JOINED_RETAILER_FACTORIES,
    RETAILERS,
    COPY_SAMPLE_REFERENCES,
} from 'libs';

// Todo: Temporary in dev
import { PaginatedSelectTest } from '../../../components/FormComponents/PaginatedSelectTest';

// Todo: Get rid of this
import { MultiStepper } from 'components';

const validate = ({ copySampleRefCheck, manualReferenceCheck, ...values }) => {
    const required = [
        'retailerId',
        'productType',
        'factoryLocationId',
        'departmentId',
        'designerFrom',
    ];

    if (copySampleRefCheck) required.push('copySampleReference');
    if (manualReferenceCheck) required.push('sampleReference');

    return { ...validateRequired(values, required) };
};

class SamplingPrintRequest extends Component {
    componentDidMount() {
        const { fetchCopySampleRef, fetchDatasets } = this.props;
        //fetchRetailers();
        fetchCopySampleRef();

        fetchDatasets([
            COUNTRIES,
            DEPARTMENTS,
            FACTORIES,
            JOINED_RETAILER_FACTORIES,
            RETAILERS,
            COPY_SAMPLE_REFERENCES,
        ]);
    }

    componentDidUpdate(prevProps) {
        const { submitting, submitSucceeded, onSubmit } = this.props;
        return (
            prevProps.submitSucceeded !== submitSucceeded &&
            submitSucceeded &&
            !submitting &&
            onSubmit()
        );
    }

    submit = (values) => {
        const { onSubmit, requestFormSubmit, submitSucceeded } = this.props;

        if (submitSucceeded) onSubmit();
        else requestFormSubmit(values);
    };

    render() {
        const {
            invalid,
            retailerId,
            copyReferenceChecked,
            manualReferenceChecked,
            retailers,
            departments,
            factoryLocations,
            productTypes,
            designTypes,
            submitSucceeded,
            submitting,
            handleSubmit,
        } = this.props;

        return (
            <Fragment>
                <GridContainer>
                    <GridItem>
                        <Select
                            name='retailerId'
                            label='Retailer'
                            placeholder='Please select retailer'
                            required
                            disabled={submitSucceeded}
                            data={retailers}
                        />
                        <Select
                            name='productType'
                            label='Product Type'
                            placeholder='Please select type'
                            required
                            disabled={submitSucceeded}
                            data={productTypes}
                        />
                        <Select
                            name='factoryLocationId'
                            label='Factory Name'
                            placeholder='Please select factory'
                            required
                            disabled={!retailerId || submitSucceeded}
                            data={factoryLocations}
                        />
                        <Checkbox
                            name='copySampleRefCheck'
                            label='Copy data from another sample request?'
                            disabled={submitSucceeded}
                        />
                        {copyReferenceChecked && (
                            <PaginatedSelectTest
                                name='copySampleReference'
                                label='Copy data from'
                                placeholder='Please select reference'
                                required
                                entity={COPY_SAMPLE_REFERENCES}
                            />
                        )}
                    </GridItem>
                    <GridItem>
                        <Select
                            name='departmentId'
                            label='Department'
                            placeholder='Please select department'
                            required
                            disabled={!retailerId || submitSucceeded}
                            data={departments}
                        />
                        <Select
                            name='designerFrom'
                            label='Where is the design from?'
                            placeholder='Please select design'
                            required
                            data={designTypes}
                            disabled={submitSucceeded}
                        />
                        <Filler />
                        <Checkbox
                            name='manualReferenceCheck'
                            label='I want to give this a manual reference'
                            disabled={submitSucceeded}
                        />
                        {manualReferenceChecked && (
                            <Input
                                name='sampleReference'
                                label='Manual Sample Reference'
                                placeholder='Write manual reference'
                                required
                                disabled={submitSucceeded}
                            />
                        )}
                    </GridItem>
                </GridContainer>
                <NavigateButton
                    onClick={handleSubmit(this.submit)}
                    disabled={invalid || submitting}
                >
                    Next
                </NavigateButton>
            </Fragment>
        );
    }
}

const mapState = (state) => {
    const selector = formValueSelector('SamplingRequestForm');
    const retailerId = selector(state, 'retailerId');
    return {
        productTypes: selectProductTypes(state, 'P'),
        retailers: selectRetailers(state),
        departments: selectDepartments(state, retailerId),
        factoryLocations: selectFactoryLocations(state, retailerId),
        designTypes: selectDesignTypes(state),
        retailerId,
        copyReferenceChecked: selector(state, 'copySampleRefCheck'),
        manualReferenceChecked: selector(state, 'manualReferenceCheck'),
        initialValues: {
            sampleReference: '',
            productType: 'P',
            manualReferenceCheck: false,
        },
    };
};

const mapDispatch = {
    fetchCopySampleRef,
    requestFormSubmit,
    fetchDatasets,
};

const mapInputs = {
    copySampleRefCheck: ({ payload }) =>
        !payload && change('SamplingRequestForm', 'copySampleReference', ''),
    manualReferenceCheck: ({ payload }) =>
        !payload && change('SamplingRequestForm', 'sampleReference', ''),
};

const _SamplingPrintRequest = compose(
    connect(mapState, mapDispatch),
    reduxForm({
        form: 'SamplingRequestForm',
        destroyOnUnmount: false,
        submitAsSideEffect: true,
        forceUnregisterOnUnmount: true,
        validate,
    }),
    formListener(mapInputs)
)(SamplingPrintRequest);

export {
    _SamplingPrintRequest as default,
    _SamplingPrintRequest as SamplingPrintRequest,
};
