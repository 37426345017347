import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { splitArray } from '@libs/array';

import VersionsProvider from './useVersions';
import { HEADER_HEIGHT_UNITS } from './constants';
import { useInitializeStyles, useContainerStyles } from './hooks';
import VersionRow from './VersionRow';

const propTypes = {
    versions: PropTypes.array.isRequired,
    maxHeight: PropTypes.number.isRequired
};

const styles = ({ spacing, palette, typography }) => ({
    container: {
        width: '100%',
        backgroundColor: palette.background.main,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    header: {
        width: '100%',
        height: spacing.unit * HEADER_HEIGHT_UNITS,
        justifyContent: 'center',
        paddingLeft: spacing.unit * 2,
        backgroundColor: palette.background.light,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderBottomWidth: 1
    },
    headerText: {
        fontWeight: typography.fontWeightSemi,
        fontSize: 14
    },
    grid: {}
});

const Versions = withPDFStyles(styles, { withTheme: true })(
    ({ classes, theme, maxHeight, versions }) => {
        const {
            versionsHeight,
            versionsPerRow,
            versionsPaddingUnits,
            versionLabelHeightUnits,
            versionLabelFontSize
        } = useInitializeStyles({
            maxHeight,
            versionCount: versions.length,
            theme
        });

        const c = useContainerStyles({
            classes,
            theme,
            versionsHeight,
            versionsPaddingUnits
        });

        const initialize = useCallback(
            state => ({
                ...state,
                versionsPerRow,
                versionsPaddingUnits,
                versionLabelHeightUnits,
                versionLabelFontSize
            }),
            [
                versionsPerRow,
                versionsPaddingUnits,
                versionLabelHeightUnits,
                versionLabelFontSize
            ]
        );

        const versionRows = splitArray(versions, versionsPerRow);
        return (
            <View style={c.container}>
                <View style={c.header}>
                    <Text style={c.headerText}>Versions</Text>
                </View>
                <View style={c.grid}>
                    <VersionsProvider initialize={initialize}>
                        {versionRows.map((versions, index) => (
                            <VersionRow
                                key={`versionRows-${index}`}
                                versions={versions}
                                rowIndex={index}
                            />
                        ))}
                    </VersionsProvider>
                </View>
            </View>
        );
    }
);

Versions.propTypes = propTypes;

export default Versions;
