import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import SelectModel from '@formComponents/SelectModel';
// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { DOMAIN_NAME } from 'sampling/common';
import { DISPATCH_EMAIL_BODY } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    retailer: PropTypes.number.isRequired
};

const defaultProps = {
    name: 'template',
    label: '',
    placeholder: 'Mail Template..',
    disabled: false,
    required: false,
    domain: DOMAIN_NAME,
    entity: DISPATCH_EMAIL_BODY
};

const dataSelector = createCachedSelector(
    (state, domain, entity) => entitySelector(state, domain, entity),
    (state, domain, entity, retailer) => retailer,
    (templates, retailer) =>
        templates
            .filter(({ retailerId }) => Number(retailerId) === retailer)
            .map(({ id, emailBodyName }) => ({
                label: emailBodyName,
                value: id
            }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity, retailer }) => {
    return {
        data: dataSelector(state, domain, entity, retailer),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectMailTemplate = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, retailer, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectMailTemplate.propTypes = propTypes;
SelectMailTemplate.defaultProps = defaultProps;

export { SelectMailTemplate as default, SelectMailTemplate };
