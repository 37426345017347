import { useState, useMemo, useCallback } from 'react';

const useSetup = (rows, pageSize) => {
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(pageSize);
    const [{ order, orderBy }, setSorting] = useState({
        order: 'asc',
        orderBy: null
    });
    const rowsPerPageOptions = useMemo(() => [5, 10, 25, 50, 100], []);

    const createSortHandler = useCallback(
        property => () => {
            let localOrder = 'desc';
            if (orderBy === property && order === 'desc') {
                localOrder = 'asc';
            }
            setSorting({ order: localOrder, orderBy: property });
        },
        [orderBy, order]
    );

    const desc = useCallback(
        (a, b) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        },
        [orderBy]
    );

    const getSorting = useCallback(() => {
        return order === 'desc' ? (a, b) => desc(a, b) : (a, b) => -desc(a, b);
    }, [order, desc]);

    const stableSort = useCallback((array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }, []);

    const filteredData = useMemo(() => {
        return rows.filter(({ id, ...item }) => {
            if (!searchValue) return true;
            return Object.values(item).some(value => {
                return (
                    value &&
                    value
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.toString().toLowerCase())
                );
            });
        });
    }, [rows, searchValue]);

    const sortedData = useMemo(() => {
        return stableSort(filteredData, getSorting());
    }, [filteredData, getSorting, stableSort]);

    const paginatedRows = useMemo(
        () =>
            sortedData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [sortedData, page, rowsPerPage]
    );

    const onChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const onChangeRowsPerPage = useCallback(event => {
        setPage(0);
        setRowsPerPage(Number(event.target.value));
    }, []);

    const handleSearch = useCallback(queryValue => {
        setSearchValue(queryValue);
        setPage(0);
    }, []);

    return {
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        paginatedRows,
        count: sortedData.length,
        rowsPerPageOptions,
        indexPrefix: rowsPerPage * page,
        handleSearch,
        searchValue,
        createSortHandler,
        order,
        orderBy
    };
};

export { useSetup };
