import getBaseUrl from '@libs/getBaseUrl';

export const CHANGE_PASS_LINK = `${getBaseUrl()}/change-password`;
export const RESET_PASSWORD_EMAIL_SUCCESS =
    'If the email address exists in our system, we will send you a reset link. Please follow the link to reset your password.';
export const OTP_SENT_TEXT =
    'We have sent 6 digits OTP token via email. Please use that OTP below.';
export const VERIFY_OTP_ROUTE = '/verify-otp';
export const ACCESS_TOKEN_KEY = 'echodb2@accessToken';
export const REFRESH_TOKEN_KEY = 'echodb2@refreshToken';
