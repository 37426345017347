import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { withStyles, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Input, Checkbox } from '@formComponents';
import {
    required,
    minLength,
    maxLength,
    validateCharsAndSpaces
} from '@libs/validationRules';

// Local
import { FORM_NAME, LABELS } from '../../common';
import { useUserState } from '../../useUser';
import UserEmail from './UserEmail';
import SelectRoles from './SelectRoles';
import MultiSelectReportTo from './MultiSelectReportTo';
import HeaderAdornment from '../HeaderAdornment';
import SetupLocked from './SetupLocked';
import asyncValidate from './asyncValidate';

const nameVailidateRules = [
    required,
    validateCharsAndSpaces,
    minLength(2),
    maxLength(16)
];

const styles = ({ spacing, typography }) => ({
    container: {
        position: 'relative',
        width: '94%',
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const BasicInformation = compose(
    withStyles(styles),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['email']
    })
)(({ classes: c }) => {
    const { mode, setupLocked } = useUserState();
    return (
        <ContentContainer
            title={LABELS.basicInformation}
            AdornmentComponent={<HeaderAdornment />}
        >
            {setupLocked && <SetupLocked />}
            <div className={c.container}>
                <Grid container>
                    <GridItem md={3}>
                        <Input
                            name='name'
                            label='Name'
                            required={true}
                            validate={nameVailidateRules}
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <UserEmail />
                    </GridItem>
                    <GridItem md={3}>
                        <SelectRoles />
                    </GridItem>
                    <GridItem md={3}>
                        <MultiSelectReportTo />
                    </GridItem>
                    <GridItem md={3}>
                        <Checkbox
                            name='active'
                            label='Active'
                            styles={{
                                label: c.checkboxLabel
                            }}
                            disabled={mode === 'new'}
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Checkbox
                            name='cadDesigner'
                            label='CAD Designer'
                            styles={{
                                label: c.checkboxLabel
                            }}
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Checkbox
                            name='shapeDesigner'
                            label='Shape Designer'
                            styles={{
                                label: c.checkboxLabel
                            }}
                        />
                    </GridItem>
                </Grid>
            </div>
        </ContentContainer>
    );
});

export default BasicInformation;
