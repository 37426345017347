import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../../common';
import { CONSENT_STATUS } from '../../../redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    disabled: false,
    required: false,
    domain: DOMAIN_NAME,
    entity: CONSENT_STATUS
};

const SelectConsentStatus = props => <Select {...props} />;

SelectConsentStatus.propTypes = propTypes;
SelectConsentStatus.defaultProps = defaultProps;

export { SelectConsentStatus as default, SelectConsentStatus };
