import React from 'react';
import { withStyles } from '@material-ui/core';
import { string, func } from 'prop-types';
import ContentContainer from '@libComponents/ContentContainer';

import { Iframe } from 'common/components';
import Adornment from './Adornment';

const styles = ({ breakpoints }) => ({
    container: {
        width: breakpoints.values.md,
        minHeight: '80vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    url: string.isRequired,
    name: string.isRequired,
    onClose: func.isRequired
};

const PreviewModal = withStyles(styles)(
    ({ url, name, onClose, classes: c }) => {
        return (
            <ContentContainer
                title={name}
                AdornmentComponent={<Adornment closeModal={onClose} />}
                styles={{ container: c.container }}
            >
                <Iframe src={url} />
            </ContentContainer>
        );
    }
);

PreviewModal.propTypes = propTypes;

export { PreviewModal as default, PreviewModal };
