import React, { useCallback, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { ContentContainer } from '@libComponents/ContentContainer';

import Adornment from './Adornment';
import Footer from './Footer';
import { useAttachmentDetailsDispatch } from '../useAttachmentDetails';
import Attachments from '../Attachments';

const styles = ({ spacing }) => ({
    header: {
        height: spacing.unit * 4.5,
        padding: 0
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
});

const Container = withStyles(styles)(({ classes: c, hasError }) => {
    const [key, setKey] = useState(new Date().getTime());
    const dispatch = useAttachmentDetailsDispatch();

    const inputRef = useRef(null);

    const handleClick = useCallback(() => {
        inputRef.current.click();
    }, [inputRef]);

    const handleFile = useCallback(({ target: { files } = {} }) => {
        dispatch({ type: 'addAttachments', payload: files });
        setKey(new Date().getTime());
    }, []);

    return (
        <ContentContainer
            styles={c}
            title=''
            AdornmentComponent={<Adornment />}
            shouldBorderRed={hasError}
        >
            <div className={c.content}>
                <Attachments onClick={handleClick} />
                <Footer onClick={handleClick} />
                <input
                    key={key}
                    accept='*'
                    type='file'
                    hidden
                    multiple
                    ref={inputRef}
                    onChange={handleFile}
                />
            </div>
        </ContentContainer>
    );
});

export default Container;
