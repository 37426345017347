import { formValueSelector } from 'redux-form';
import { select, all, call, fork } from 'redux-saga/effects';

// Libs
import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import {
    composeLoadingWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker
} from '@libs/publishWorkers';

import { FORM_NAME } from '../../../Shape/common';

import { publishFailedWorker } from '@libs/publishWorkers';
import getSampleReferenceWorker from './getSampleReferenceWorker';
import { sizePathsSelector } from 'common/redux.selectors';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';
import reportsWorker from './reportsWorker';

import { publishSchema } from './schemas';
import handoverWorker from './handoverWorker';
import getDevelopmentName from './getDevelopmentName';
import { normalizeImagePayload } from '../../../redux.normalizers';

export default function*(action) {
    const {
        meta: {
            handleComplete = () => {},
            saveDraft = false,
            handover = false
        },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {}
        }
    } = action;

    const [{ attachments, front, back }, developmentName] = yield all([
        select(
            formValueSelector(FORM_NAME),
            'attachments',
            'front',
            'back',
            'developmentNumber'
        ),
        call(getDevelopmentName, mode)
    ]);

    const assets = [...attachments, front, back];

    const handleLoading = yield call(composeLoadingWorker, { assets, mode });

    try {
        const [
            { sampleReference, id },
            attachmentBlobs,
            frontBlobs,
            backBlobs,
            attachmentSizePaths,
            frontSizePaths,
            backSizePaths
        ] = yield all([
            call(getSampleReferenceWorker, { mode, handleLoading }),
            call(getAssetBlobsWorker, {
                assets: attachments,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: front,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: back,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'shapeSampleAttachment'),
            select(sizePathsSelector, 'shapeSampleFront'),
            select(sizePathsSelector, 'shapeSampleBack')
        ]);

        const attachmentsPayload = yield call(uploadAssetsWorker, {
            assetBlobs: attachmentBlobs,
            folderPath: `${attachmentSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: attachmentSizePaths,
            handleLoading,
            developmentName,
            enableSerial: true
        });

        const [frontPayload = {}] = yield call(uploadAssetsWorker, {
            assetBlobs: frontBlobs,
            folderPath: `${frontSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: frontSizePaths,
            handleLoading,
            prefix: 'Front',
            developmentName
        });

        const [backPayload = {}] = yield call(uploadAssetsWorker, {
            assetBlobs: backBlobs,
            folderPath: `${backSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: backSizePaths,
            handleLoading,
            prefix: 'Back',
            developmentName
        });

        const payload = yield call(getPublishPayloadWorker, {
            mode,
            id,
            sampleReference
        });

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinued: !saveDraft,
                isHandover: handover,
                attachments: attachmentsPayload,
                frontImage: normalizeImagePayload(frontPayload),
                backImage: normalizeImagePayload(backPayload)
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'Sample/PublishShapeSampleReference',
            data: publishData
        });

        /*
           --- After publish
        */
        if (!saveDraft || handover) {
            yield call(reportsWorker, {
                sampleReference,
                developmentName,
                mode,
                handleLoading
            });
        }

        if (saveDraft) {
            // Handover
            if (handover) {
                yield call(handoverWorker, { mode, sampleReference });
            }
            // Save draft
            else {
                yield call(savedDraftWorker, {
                    mode,
                    sampleReference
                });
            }
        }
        closeMailModal();
        closePreviewModal();
        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to ';

        if (saveDraft && !handover) actionErrorMessage += 'save';
        else if (handover) actionErrorMessage += 'handover';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
