import React from 'react';

import { withStyles, Paper, Table as MuiTable } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';

const styles = ({ spacing, palette, shadows }) => ({
    root: {
        width: '100%',
        boxShadow: shadows[0]
    },
    table: {
        minWidth: spacing.unitTableMinWidth,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});

const Table = withStyles(styles)(({ classes, children, styles }) => {
    const c = composeClsx({ classes, styles });

    return (
        <Paper className={c.root}>
            <div className={c.tableWrapper}>
                <MuiTable className={c.table}>{children}</MuiTable>
            </div>
        </Paper>
    );
});

export { Table as default, Table };
