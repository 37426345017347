import {
    FETCH_COPY_SAMPLE_REF_REQUEST,
    REQUEST_FORM_SUBMIT,
    REQUEST_FORM_SUBMIT_SUCCEEDED
} from 'actionTypes';

export function fetchCopySampleRef(payload) {
    return { type: FETCH_COPY_SAMPLE_REF_REQUEST, payload };
}

export const requestFormSubmit = ({
    sampleReference = '',
    productType,
    manualReferenceCheck = false,
    retailerId,
    departmentId,
    designerFrom,
    factoryLocationId,
    copySampleReference
}) => ({
    type: REQUEST_FORM_SUBMIT,
    payload: {
        sampleReference,
        productType,
        manualReferenceCheck,
        retailerId,
        departmentId,
        designerFrom,
        factoryLocationId,
        copySampleReference
    }
});

export const requestFormSubmitSucceeded = () => ({
    type: REQUEST_FORM_SUBMIT_SUCCEEDED
});
