export default theme => ({
    containerStyle: {
        padding: 20,
        minWidth: 350
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px'
    },
    labelFileField: {
        '& + div': {
            display: 'none'
        }
    },
    textField: {
        marginBottom: theme.spacing.unit * 2.5
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        borderRadius: 0,
        height: 43,
        position: 'absolute',
        right: 0,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))'
    },
    browseButtonRoot: {
        border: '1px solid #e6e6e6',
        borderRadius: 4,
        height: 45,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex',
        position: 'relative'
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 45,
        flexGrow: 1,
        lineHeight: '45px',
        paddingLeft: 15,
        '& span:not(:first-child):before': {
            content: '", "'
        }
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    }
});
