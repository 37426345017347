import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BlobProvider } from '@react-pdf/renderer';

import { withStyles } from '@material-ui/core';

import Button from '../Button';

const propTypes = {
    document: PropTypes.element.isRequired,
    fileName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
        .isRequired
};

const styles = () => ({
    link: {
        color: 'unset',
        textDecoration: 'none',
        pointerEvents: 'none'
    }
});

const DownloadButton = withStyles(styles)(
    ({ classes: c, document, fileName, children, ...restProps }) => {
        const [ready, setReady] = useState(true);

        const [loading, setLoading] = useState(false);
        const linkRef = useRef(null);

        useEffect(() => {
            let readyTimeout;
            setReady(false);
            readyTimeout = setTimeout(() => setReady(true), 1);
            return () => clearTimeout(readyTimeout);
        }, []);

        const handleClick = useCallback(() => {
            linkRef && linkRef.current && !loading && linkRef.current.click();
        }, [linkRef.current, loading]);

        return (
            ready && (
                <BlobProvider document={document}>
                    {({ url, loading }) => {
                        setLoading(loading);
                        return (
                            !loading && (
                                <Button {...restProps} onClick={handleClick}>
                                    <a
                                        ref={linkRef}
                                        className={c.link}
                                        href={url}
                                        download={fileName}
                                    >
                                        {children}
                                    </a>
                                </Button>
                            )
                        );
                    }}
                </BlobProvider>
            )
        );
    }
);

DownloadButton.propTypes = propTypes;

export { DownloadButton as default, DownloadButton };
