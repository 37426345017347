export default ({ palette, spacing, shadows }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,

        boxShadow: shadows[0],

        minHeight: spacing.unit * 75,
        margin: `${spacing.unit * 2.5}px 0px`
    },

    progress: {
        width: '100%',
        backgroundColor: palette.secondary.light,
        height: spacing.unit * 0.5,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: palette.secondary.main,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    }
});
