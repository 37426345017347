import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';

import ContentContainer from '@libComponents/ContentContainer';
import { setSnack } from 'utilities/redux.actions';
import { withField } from '@libs/reduxForm';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { makeSideEffectCallback } from '@libs/makeContext';
import { Iframe } from 'common/components';
import { MailFormProvider, useStoreEmailFormData } from '@libHooks/useMailForm';

import MailModal from './MailModal';
import ActionsAdornment from './ActionsAdornment';
import { useMakePDF } from './hooks';
import { fabricDevelopmentPDFPropsSelector } from '../../../redux.selectors';

const styles = ({ spacing }) => ({
    container: {
        width: '80vw',
        minHeight: '90vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    loadingText: {
        position: 'absolute',
        top: '50%',
        fontSize: spacing.unit * 2.5
    }
});

const propTypes = {
    name: string
};

const defaultProps = {
    name: 'mailData'
};

const mapStateToProps = (state, { mode }) => ({
    PDFProps: fabricDevelopmentPDFPropsSelector(state, mode)
});

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Preview = compose(
    withModalProvider,
    withModal({
        handleModal: MailModal
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handlePublish,
        setSnack,
        handleModal,
        input,
        meta: { form },
        PDFProps,
        mode
    }) => {
        const pdfHandlerInvocationCompleted = useRef(false);
        const [PDFLoading, setPDFLoading] = useState(true);
        const [
            PDFBlobURLGenerator,
            PDFBlobURLObject,
            handlePDFBlobURLRemove
        ] = useMakePDF({
            props: PDFProps,
            setSnack
        });

        const { value, onChange } = input;
        const [action, setAction] = useState({});

        const initialize = useCallback(
            state => ({
                ...state,
                mail: value || {}
            }),
            [value]
        );
        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const handlePreviewModalClose = useCallback(() => {
            handlePDFBlobURLRemove();
            setAction({ type: 'removePDF' });
            setTimeout(() => onClose(), 0);
        }, [onClose, handlePDFBlobURLRemove]);

        const handleMailModal = useMemo(
            () => props =>
                handleModal({
                    ...props,
                    formName: form,
                    handlePublish,
                    closePreviewModal: handlePreviewModalClose,
                    mode
                }),
            [handleModal, form, mode, handlePreviewModalClose]
        );

        const handleMailForm = useStoreEmailFormData({
            handleModal: handleMailModal,
            setAction,
            formName: form
        });

        useEffect(() => {
            if (
                !pdfHandlerInvocationCompleted.current &&
                !!Object.values(PDFProps).length
            ) {
                pdfHandlerInvocationCompleted.current = true;
                PDFBlobURLGenerator();
            }
        }, [PDFProps]);

        useEffect(() => {
            if (PDFBlobURLObject && PDFBlobURLObject.url) {
                setPDFLoading(false);
                setAction({
                    type: 'addPDF',
                    payload: { pdf: PDFBlobURLObject }
                });
            }
        }, [PDFBlobURLObject]);

        return (
            <MailFormProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Preview'
                    styles={{ container: c.container }}
                    AdornmentComponent={
                        <ActionsAdornment
                            closeModal={handlePreviewModalClose}
                            handleMailForm={handleMailForm}
                            PDFLoading={PDFLoading}
                        />
                    }
                >
                    {!PDFLoading ? (
                        <Iframe src={PDFBlobURLObject.url} />
                    ) : (
                        <Typography className={c.loadingText}>
                            Loading document...
                        </Typography>
                    )}
                </ContentContainer>
            </MailFormProvider>
        );
    }
);

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
