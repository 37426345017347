import React from 'react';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core';
import DropdownIcon from '@material-ui/icons/KeyboardArrowDown';

// Local
import styles from './styles';
import { Icon } from 'components/Icons';

/*
 * Domain: --
 * Page: Select
 * Component: Dropdown
 * Type: Icon
 * MultipleSelectDropdownIcon
 */
const MultipleSelectDropdownIcon = ({
    classes: { root, icon, disabled: disabledStyle },
    styles,
    disabled,
    ...restProps
}) => (
    <Icon
        className={classNames(
            root,
            disabled && disabledStyle,
            styles.dropdownRoot
        )}
        {...restProps}
    >
        <DropdownIcon className={classNames(icon, styles.dropdownIcon)} />
    </Icon>
);

const _SelectDropdownIcon = withStyles(styles)(MultipleSelectDropdownIcon);

export {
    _SelectDropdownIcon as default,
    _SelectDropdownIcon as MultipleSelectDropdownIcon
};
