import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { AuthInput, NavigateButton } from 'components';
import { requestPasswordChange } from 'actions';

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            requestPasswordChange
        },
        dispatch
    );
};

const validate = ({ currentPassword, newPassword, newPasswordConfirm }) => {
    const error = {};

    if (!currentPassword) {
        error.currentPassword = 'Required';
    }

    if (!newPassword) {
        error.newPassword = 'Required';
    }

    if (!newPasswordConfirm) {
        error.newPasswordConfirm = 'Required';
    }

    if (newPassword !== newPasswordConfirm) {
        error.newPasswordMatch = 'Required';
    }

    return error;
};

class ChangePassword extends PureComponent {
    render() {
        const {
            invalid,
            handleSubmit,
            submitting,
            requestPasswordChange
        } = this.props;

        return (
            <Fragment>
                <AuthInput
                    name='currentPassword'
                    label='Current Password'
                    type='password'
                    placeholder='Current Password'
                    variant='lock'
                    required
                />

                <AuthInput
                    name='newPassword'
                    label='New Password'
                    type='password'
                    placeholder='New Password'
                    variant='lock'
                    required
                />

                <AuthInput
                    name='newPasswordConfirm'
                    label='Confirm New Password'
                    type='password'
                    placeholder='Confirm New Password'
                    variant='lock'
                    required
                />

                <NavigateButton
                    onClick={handleSubmit(requestPasswordChange)}
                    disabled={invalid || submitting}
                >
                    Request to Change Password
                </NavigateButton>
            </Fragment>
        );
    }
}

ChangePassword.defaultProps = {
    requestPasswordChange: PropTypes.func
};

const _ChangePassword = compose(
    connect(
        null,
        mapDispatchToProps
    ),
    reduxForm({
        form: 'AuthenticationForm',
        submitAsSideEffect: true,
        validate
    })
)(ChangePassword);

export { _ChangePassword as default, _ChangePassword as ChangePassword };
