export const ROUTE_TO_PRINT = 'sampling@route-to-print';
export const ROUTE_TO_SHAPE = 'sampling@route-to-shape';

export const GET_VERSIONS = 'sampling@get-versions';
export const SET_HAS_MANUAL_REF_CHILDREN =
    'sampling@set-has-manual-ref-children';
export const SET_VERSIONS = 'sampling@set-versions';
export const SET_PRINT_RETAILER_CHILDREN =
    'sampling@set-print-retailer-children';
export const SET_SPEC_REFERENCE_CHILDREN =
    'sampling@set-spec-reference-children';
export const SET_SPEC_REFERENCE_AND_DESCRIPTION =
    'sampling@set-spec-reference-and-description';
export const SET_SHAPE_RETAILER_CHILDREN =
    'sampling@set-shape-retailer-children';
export const SET_SAMPLE_FIT_OPTIONS_CHILDREN =
    'sampling@set-sample-fit-options-children';

export const GET_BOUGHT_DETAIL_DATA = 'sampling@get-bought-detail-data';
export const GET_TRIM_DETAIL_DATA = 'sampling@get-trim-detail-data';

export const SET_FABRICS_LOADING = 'sampling@set-fabrics-loading';
export const SET_BOUGHT_DETAILS_LOADING = 'sampling@set-bought-details-loading';
export const SET_TRIM_DETAILS_LOADING = 'sampling@set-trim-details-loading';

export const DESTROY_PRINT_FORM = 'sampling@destroy-print-form';
export const DESTROY_SHAPE_FORM = 'sampling@destroy-shape-form';
export const DESTROY_FINAL_PHOTO_FORM = 'sampling@destroy-final-photo-form';

export const PRINT_PUBLISH = 'sampling@print-publish';
export const SHAPE_PUBLISH = 'sampling@shape-publish';

export const GET_INCOMPLETE_PRINT_DATA = 'sampling@get-incomplete-print-data';
export const GET_AMEND_PRINT_DATA = 'sampling@get-amend-print-data';
export const GET_COPY_PRINT_DATA = 'sampling@get-copy-print-data';

export const GET_INCOMPLETE_SHAPE_DATA = 'sampling@get-incomplete-shape-data';
export const GET_AMEND_SHAPE_DATA = 'sampling@get-amend-shape-data';
export const GET_COPY_SHAPE_DATA = 'sampling@get-copy-shape-data';

export const SET_SAMPLE_EMAIL_RECEIVERS = 'sampling@set-sample-email-receivers';
export const SET_SAMPLE_EMAIL_BODY = 'sampling@set-sample-email-body';
export const SAMPLE_REPORT_MAIL_SEND = 'sampling@report-mail-send';

export const SHAPE_REFERENCE_GENERATE = 'sampling@shape-reference-generate';
export const PRINT_REFERENCE_GENERATE = 'sampling@print-reference-generate';

export const SET_SAMPLE_REFERENCE_GENERATE_LOADING =
    'sampling@set-sample-reference-generate-loading';

export const GET_DEVELOPED_FABRIC_DATA = 'sampling@get-developed-fabric-data';
export const SET_FABRIC_OPTION_CHILDREN = 'sampling@set-fabric-option-children';
export const ROUTE_TO_NEW_ORDER = 'sampling@route-to-new-order';
export const DOWNLOAD_SAMPLES_AS_EXCEL = 'sampling@download-samples-as-excel';
export const SET_DOWNLOAD_SAMPLES_AS_EXCEL_LOADING =
    'sampling@set-download-samples-as-excel-loading';
export const DOWNLOAD_SAMPLES_AS_MULTI_PAGE =
    'sampling@download-samples-as-multi-page';
export const SET_DOWNLOAD_SAMPLES_AS_MULTI_PAGE_LOADING =
    'sampling@set-download-samples-as-multi-page-loading';
export const DOWNLOAD_SAMPLES_AS_SINGLE_PAGE =
    'sampling@download-samples-as-single-page';
export const SET_DOWNLOAD_SAMPLES_AS_SINGLE_PAGE_LOADING =
    'sampling@set-download-samples-as-single-page-loading';
export const DOWNLOAD_SAMPLES_AS_COST_FORM =
    'sampling@download-samples-as-cost-form';
export const SET_DOWNLOAD_SAMPLES_AS_COST_FORM_LOADING =
    'sampling@set-download-samples-as-cost-form-loading';

/* Final Photo action types */
export const PUBLISH_FINAL_PHOTO = 'sampling@publish-final-photo';
export const ROUTE_TO_FINAL_PHOTO_UPLOAD =
    'sampling@route-to-final-photo-upload';
export const SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN =
    'sampling@set-final-photo-sample-ref-children';
export const SEND_FINAL_PHOTO_EMAIL = 'sampling@send-final-photo-email';
export const SET_FINAL_PHOTO_EMAIL_LOADING =
    'sampling@set-final-photo-email-loading';
export const SET_FINAL_PHOTO_DATA_LOADING =
    'sampling@set-final-photo-data-loading';

/* Dispatch action types */
export const DISPATCH_REFERENCE_GENERATE =
    'sampling@dispatch-reference-generate';
export const SET_DISPATCH_REFERENCE_GENERATE_LOADING =
    'sampling@set-dispatch-reference-generate-loading';
export const GET_SAMPLE_DISPATCH_DATA = 'sampling@get-sample-dispatch-data';
export const SET_ACTUAL_SEND_DATE_CHILDREN =
    'sampling@set-actual-send-date-children';
export const SET_DISPATCH_DETAILS_TYPE_CHILDREN =
    'sampling@set-dispatch-details-type-children';
export const SET_DISPATCH_DETAILS_ORDER_CHILDREN =
    'sampling@set-dispatch-details-order-children';
export const SET_DISPATCH_DETAILS_SAMPLE_REF_CHILDREN =
    'sampling@set-dispatch-details-sample-ref-children';
export const SET_DISPATCH_DETAILS_COLOUR_CHILDREN =
    'sampling@set-dispatch-details-colour-children';
export const DISPATCH_PUBLISH = 'sampling@dispatch-publish';
export const SET_DISPATCH_EMAIL_RECEIVERS =
    'sampling@set-dispatch-email-receivers';
export const SET_DISPATCH_EMAIL_BODY = 'sampling@set-dispatch-email-body';
export const ROUTE_TO_DISPATCH = 'sampling@route-to-dispatch';
export const DISPATCH_REPORT_MAIL_SEND = 'sampling@dispatch-report-mail-send';
export const DISPATCH_EXPORT_XL_DATA = 'sampling@dispatch-export-xl-data';
export const SET_DISPATCH_EXPORT_EXCEL_DATA_LOADING =
    'sampling@set-dispatch-export-excel-data-loading';

// Sampling report action types
export const FETCH_SAMPLING_REPORT_DATA = 'sampling@fetch-sampling-report-data';
export const SET_SAMPLING_REPORT_DATA_LOADING =
    'sampling@set-sampling-report-data-loading';
export const SET_SAMPLING_REPORT_DATA = 'sampling@set-sampling-report-data';
