import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';

import { DOMAIN_NAME } from '../common';
import { GET_SAMPLE_SIZES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    domain: PropTypes.string,
    entity: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sendToBuyer',
    label: 'Send to buyer',
    placeholder: 'Select',
    domain: DOMAIN_NAME,
    entity: GET_SAMPLE_SIZES,
    disabled: false,
    required: false
};

const SelectSendToBuyer = props => <Select {...props} />;

SelectSendToBuyer.propTypes = propTypes;
SelectSendToBuyer.defaultProps = defaultProps;

export { SelectSendToBuyer as default, SelectSendToBuyer };
