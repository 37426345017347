import React from 'react';

import { withStyles, TableRow as MuiTableRow } from '@material-ui/core';

const styles = ({ palette }) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: palette.background.default
        }
    }
});

const TableRow = withStyles(styles)(({ classes, children }) => {
    return <MuiTableRow classes={classes}>{children}</MuiTableRow>;
});

export { TableRow as default, TableRow };
