import React from 'react';
import { View } from '@react-pdf/renderer';
import { number, string } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Field from '../BasicContentContainer/Field';
import Comment from '../Comment';

const styles = () => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    subContainer: {
        width: '80%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    subField: {
        width: '24%'
    }
});

const propTypes = {
    sampleQTY: number.isRequired,
    sampleReviewStatus: string.isRequired,
    rejectQTY: number,
    dhu: string,
    tdr: string,
    remarks: string
};

const QualityDashboard = withPDFStyles(styles)(
    ({
        classes: c,
        sampleQTY,
        sampleReviewStatus,
        rejectQTY,
        dhu,
        tdr,
        foundGSM,
        spirality,
        washShrinkage,
        printShrinkage,
        remarks
    }) => {
        return (
            <View>
                <View style={c.container}>
                    <Field label='Sample QTY' value={sampleQTY} required />
                    <Field
                        label='Sample Status'
                        value={sampleReviewStatus}
                        required
                    />
                    <Field label='Reject QTY' value={rejectQTY} />
                    <Field label='DHU' value={dhu} />
                    <Field label='TDR' value={tdr} />
                    <View style={c.subContainer}>
                        <Field
                            label='Found GSM'
                            value={foundGSM}
                            style={c.subField}
                        />
                        <Field
                            label='Spirality%'
                            value={spirality}
                            style={c.subField}
                        />
                        <Field
                            label='Wash Shrinkage%(LxW)'
                            value={washShrinkage}
                            style={c.subField}
                        />
                        <Field
                            label='Print Shrinkage%(LxW)'
                            value={printShrinkage}
                            style={c.subField}
                        />
                    </View>
                </View>
                <Comment value={remarks} label='Remarks' />
            </View>
        );
    }
);

QualityDashboard.propTypes = propTypes;

export default QualityDashboard;
