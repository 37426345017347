import {
    ROUTE_TO_NEW,
    ROUTE_TO_AMEND,
    ROUTE_TO_NEW_SAMPLE,
    DESTROY_FORM,
    PUBLISH,
    GET_INCOMPLETE_DATA,
    GET_AMEND_DATA,
    SET_CAD_RETAILER_CHILDREN,
    SET_CAD_PROPERTY_CHILDREN,
    SET_ARTWORK_SOURCE
} from './redux.actionTypes';

export const routeToAmend = mode => ({
    type: ROUTE_TO_AMEND,
    payload: { mode }
});

export const routeToNew = mode => ({
    type: ROUTE_TO_NEW,
    payload: { mode }
});

export const routeToNewSample = mode => ({
    type: ROUTE_TO_NEW_SAMPLE,
    payload: { mode }
});

export const destroyForm = () => ({ type: DESTROY_FORM });

export const publish = ({ mode, handleComplete }) => ({
    type: PUBLISH,
    meta: { handleComplete },
    payload: { mode }
});

export const saveDraft = ({ mode }) => ({
    type: PUBLISH,
    meta: { saveDraft: true },
    payload: { mode }
});

export const getIncompleteData = reference => ({
    type: GET_INCOMPLETE_DATA,
    payload: reference
});

export const getAmendData = reference => ({
    type: GET_AMEND_DATA,
    payload: reference
});

export const setCadRetailerChildren = () => ({
    type: SET_CAD_RETAILER_CHILDREN
});

export const setCadPropertyChildren = () => ({
    type: SET_CAD_PROPERTY_CHILDREN
});

export const setArtworkSource = () => ({
    type: SET_ARTWORK_SOURCE,
    payload: { field: 'designer' }
});

export const setArtworkSourceWithResetLicensor = () => ({
    type: SET_ARTWORK_SOURCE,
    payload: { field: 'type' }
});
