import { useMemo } from 'react';

const useSchema = ({ garmentsType }) =>
    useMemo(
        () => ({
            publish: {
                sampleRef: true,
                developmentNo: true,
                department: true,
                ...(garmentsType === 'P'
                    ? { finalCad: (_, fieldError) => !fieldError }
                    : {
                          finalFront: (_, fieldError) => !fieldError,
                          finalBack: (_, fieldError) => !fieldError
                      })
            }
        }),
        [garmentsType]
    );

export default useSchema;
