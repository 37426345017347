import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Local
import { SelectModel } from './SelectModel';

// Datasets
import {
    datasetLoadingSelector,
    datasetSelector,
    fetchDatasets
} from '@libs/datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    styles: {}
};

const mapState = (state, { name, domain, entity }) => ({
    // amended: selectAmendedField(state, name),
    data: datasetSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const Select = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...restProps }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return <SelectModel {...restProps} />;
});

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export { Select as default, Select };
