const RETAILER_ROUTE_ID = 143;
const DEPARTMENT_ROUTE_ID = 142;
const STYLE_ROUTE_ID = 565;
const GENDER_ROUTE_ID = 563;
const CATEGORY_ROUTE_ID = 552;
const COUNTRY_ROUTE_ID = 165;
const AGE_ROUTE_ID = 633;
const DESIGN_DEPARTMENT_ROUTE_ID = 635;

export {
    RETAILER_ROUTE_ID,
    DEPARTMENT_ROUTE_ID,
    STYLE_ROUTE_ID,
    GENDER_ROUTE_ID,
    CATEGORY_ROUTE_ID,
    COUNTRY_ROUTE_ID,
    AGE_ROUTE_ID,
    DESIGN_DEPARTMENT_ROUTE_ID
};
