import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import {
    INK_TYPES,
} from 'libs';

// Artwork Locations
export const selectInkTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[INK_TYPES]
)(() => 'placeholder');
