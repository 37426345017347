import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, StepLabel as MaterialStepLabel } from '@material-ui/core';

const propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool.isRequired,
    style: PropTypes.objectOf(PropTypes.string),
};

const styles = ({ palette, spacing, transitions, typography }) => ({
    root: {
        color: palette.background.dark,
        flexDirection: 'column',
        zIndex: 2,
    },
    label: {
        color: 'var(--color)',
        position: 'absolute',
        whiteSpace: 'nowrap',
        fontSize: typography.subtitle1.fontSize,
        fontWeight: typography.fontWeightSemi,
        paddingTop: spacing.unit,
        right: '50%',
        transform: 'translateX(50%)',
        transition: transitions.create(['color'], {
            easing: transitions.easing.easeOut,
        }),
        transitionDuration: 'var(--duration)',

        '&$active': {
            color: 'var(--color)',
            fontWeight: typography.fontWeightHeavy,
        },
    },
    labelContainer: {
        position: 'relative',
    },
    iconContainer: {
        paddingRight: 0,
    },
    disabled: {},
    active: {},
});

const StepLabel = withStyles(styles)(
    ({ classes: c, children, disabled, style, ...restProps }) => {
        const styleVariables = {
            ...style,
            ...(disabled ? { '--color': 'unset' } : {}),
        };

        return (
            <MaterialStepLabel
                classes={c}
                disabled={disabled}
                style={styleVariables}
                {...restProps}
            >
                {children}
            </MaterialStepLabel>
        );
    }
);

StepLabel.propTypes = propTypes;

export { StepLabel as default };
