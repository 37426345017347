import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, Paper, Button } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../PaginatedDialogContext';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogFooter
 */
const PaginatedDialogFooter = ({
    classes,
    dialogState: { data, nextPage },
    dialogDispatch,
}) => {
    const handleClick = () => dialogDispatch({ type: 'load-more' });
    return (
        !!data.length && nextPage && (
            <Button classes={classes} onClick={handleClick} fullWidth>
                Load More
            </Button>
        )
    );
};

const _PaginatedDialogFooter = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogFooter);

export {
    _PaginatedDialogFooter as default,
    _PaginatedDialogFooter as PaginatedDialogFooter,
};
