import { useMemo } from 'react';

const headerProps = {
    fabricFor: { value: 'Fabric For', order: 0 },
    fabricType: { value: 'Fabric Type', order: 1 },
    composition: { value: 'Fabric Composition', order: 2 },
    gsm: { value: 'GSM', order: 3 },
    fabricColour: { value: 'Fabric Color', order: 4 },
    specialFinish: { value: 'Finish Wash', order: 5 }
};

const useFabricTableHeader = (headers = {}) => {
    return useMemo(() => {
        return Object.keys(headers)
            .filter(key => headerProps[key])
            .sort((a, b) => headerProps[a].order - headerProps[b].order)
            .map(key => headerProps[key].value);
    }, [headers]);
};
const useFabricTableContent = content =>
    useMemo(
        () =>
            Object.entries(content)
                .map(([key, value]) => {
                    switch (key) {
                        case 'fabricFor':
                            return { value, order: 0 };
                        case 'fabricType':
                            return { value, order: 1 };
                        case 'composition':
                            return { value, order: 2 };
                        case 'gsm':
                            return { value, order: 3 };
                        case 'fabricColour':
                            return {
                                value: value || 'N/A',
                                order: 4
                            };
                        case 'specialFinish':
                            return { value: value || 'N/A', order: 5 };
                        default:
                            return null;
                    }
                })
                .filter(cell => !!cell)
                .sort((a, b) => a.order - b.order)
                .map(({ value }) => value),
        [content]
    );

export { useFabricTableHeader, useFabricTableContent };
