import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';
import SpotlightAdornment from './PaginatedDialogSpotlightAdornment';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogToolbarSpotlight
 */
class PaginatedDialogToolbarSpotlight extends Component {
    state = {
        timedSearch: void 0,
        previousSearch: '',
        previousCategory: 0,
    };

    handleSearch = () => {
        const { state, props } = this;
        const { timedSearch, previousSearch, previousCategory } = state;
        const {
            dialogDispatch,
            dialogState: { search, categoryValue },
        } = props;

        clearTimeout(timedSearch);
        if (previousSearch !== search || previousCategory !== categoryValue) {
            dialogDispatch({ type: 'query' });
            this.setState({
                previousSearch: search,
                previousCategory: categoryValue,
            });
        }
    };

    handleChange = ({ target: { value = '' } }) => {
        const { state, props, handleSearch } = this;

        props.dialogDispatch({
            type: 'set-search',
            payload: value,
        });

        clearTimeout(state.timedSearch);
        const timedSearch = setTimeout(handleSearch, 1500);
        this.setState({ timedSearch });
    };

    handleKeyPress = ({ key }) => key === 'Enter' && this.handleSearch();

    render() {
        const {
            props,
            handleChange,
            handleKeyPress,
            handleSearch,
        } = this;

        const {
            dialogState: { search },
            classes: { input: inputClass, ...c },
        } = props;

        const adornmentProps = {
            onClick: handleSearch,
        };

        return (
            <TextField
                classes={c}
                InputProps={{
                    disableUnderline: true,
                    className: inputClass,
                    startAdornment: <SpotlightAdornment {...adornmentProps} />,
                }}
                value={search}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder='Spotlight search'
            />
        );
    }
}

const _PaginatedDialogToolbarSpotlight = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogToolbarSpotlight);

export {
    _PaginatedDialogToolbarSpotlight as default,
    _PaginatedDialogToolbarSpotlight as PaginatedDialogToolbarSpotlight,
};
