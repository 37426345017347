import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

export default (columns, valueSelector) => (newRow, oldRow) => {
    for (const column of columns) {
        const fieldName = column.name;
        const fieldType = column.type;
        const isSelectColumn = fieldType === 'select';

        let oldValue = oldRow[fieldName] || '';
        let newValue = newRow[fieldName] || '';

        if (isSelectColumn && column.entity && column.domain) {
            oldValue = valueSelector(column.domain, column.entity, oldValue);
            newValue = valueSelector(column.domain, column.entity, newValue);
        } else if (fieldType === 'date') {
            oldValue = formatDateToShort(oldValue);
            newValue = formatDateToShort(newValue);
        }

        if (newValue !== oldValue) {
            const mutationText = `'${column.header}' for Dispatch Ref '${oldRow.dispatchRef}' from '${oldValue}' to '${newValue}'. This will also affect other items if they exist in this reference.`;
            return {
                fieldName,
                fieldValue: newRow[fieldName],
                id: newRow.id,
                mutationText
            };
        }
    }

    return {};
};
