import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import datasets from './redux.datasets';

import {
    SET_SEAL_SAMPLE_COLOR_CHILDREN_LOADING,
    SET_SEAL_SAMPLE_REF_GENERATE_LOADING
} from './redux.actionTypes';

const initialState = {
    colorChildrenLoading: false,
    referenceGenerateLoading: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_SEAL_SAMPLE_COLOR_CHILDREN_LOADING: {
            return { ...state, colorChildrenLoading: payload };
        }
        case SET_SEAL_SAMPLE_REF_GENERATE_LOADING: {
            return { ...state, referenceGenerateLoading: payload };
        }
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
