import { change } from 'redux-form';
import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { FORM_NAME, INCOMPLETE_ORDER_ROUTE_ID } from '../../../Order/common';
import { getIncompleteOrderData } from '../../../redux.actions';

export default function*({ mode, echoOrderNo }) {
    if (mode !== 'incomplete') {
        yield put(setRedirect(true));
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(
        setSnack({
            message: `Saved draft: ${echoOrderNo}`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(INCOMPLETE_ORDER_ROUTE_ID));
    yield put(getIncompleteOrderData(echoOrderNo));
}
