import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { fabricReportMailSend } from 'fabric/redux.actions';
import {
    SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
    SET_FABRIC_DEVELOPMENT_MAIL_BODY
} from 'fabric/redux.actionTypes';
import SelectMailGroup from 'fabric/FabricDevelopment/content/Preview/MailModal/SelectMailGroup';
import SelectMailTemplate from 'fabric/FabricDevelopment/content/Preview/MailModal/SelectMailTemplate';

const mapDispatch = dispatch => ({
    formInitialize: (...args) => dispatch(initialize(...args)),
    sendReportMail: (...args) => dispatch(fabricReportMailSend(...args))
});

const mapInputs = {
    group: ({ payload }) => ({
        type: SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS,
        payload
    }),
    template: ({ payload }) => ({
        type: SET_FABRIC_DEVELOPMENT_MAIL_BODY,
        payload
    })
};

const PreviewMailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: v => v
    }),
    connect(
        null,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose,
        valid,
        emailSubject,
        retailer,
        PDFData,
        reference,
        handlePreviewModalClose,
        versionNo,
        formInitialize,
        sendReportMail
    }) => {
        const handleMailModalClose = useCallback(() => {
            onClose();
            setTimeout(() => handlePreviewModalClose(), 0);
        }, []);

        const handleSendMail = useCallback(() => {
            sendReportMail({
                fabricRef: reference,
                versionNo,
                closeModals: handleMailModalClose
            });
        }, [reference, versionNo]);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, {
                subject: emailSubject,
                report: PDFData
            });
        }, []);

        const mailProps = {
            MailGroupComponent: <SelectMailGroup retailer={retailer} />,
            MailTemplatesComponent: <SelectMailTemplate retailer={retailer} />,
            handleModalClose: onClose,
            handleSubmit: handleSendMail,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default PreviewMailModal;
