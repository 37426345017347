import React from 'react';
import { withStyles, Paper } from '@material-ui/core';

import PageCount from './PageCount';
import Pagination from './Pagination';
import SelectPageSize from './SelectPageSize';
import { useGridViewDispatch } from 'gridView/useGridView';

const styles = ({ palette, spacing, shadows }) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        height: spacing.unit * 8,
        padding: `${spacing.unit}px ${spacing.unit * 2}px`,

        backgroundColor: palette.background.light,

        borderRadius: 0,
        boxShadow: shadows[0],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark
    }
});

const FooterTop = withStyles(styles)(
    ({ classes: c, totalCount, currentPage, totalPages, pageSize }) => {
        const dispatch = useGridViewDispatch();
        const handlePageChange = pageNumber => {
            dispatch({ type: 'set-pageNumber', payload: pageNumber });
        };
        const handlePageSize = size =>
            dispatch({ type: 'set-pageSize', payload: size });
        return (
            <Paper className={c.container}>
                <PageCount
                    currentPage={currentPage}
                    items={totalCount}
                    totalPages={totalPages}
                />
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    handlePageChange={handlePageChange}
                />
                <SelectPageSize
                    pageSize={pageSize}
                    handlePageSize={handlePageSize}
                />
            </Paper>
        );
    }
);

export default FooterTop;
