import React from 'react';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core';

import useGridContext from '../../../../useGridContext';

import DragAndSortWrapper from './DragAndSortWrapper';
import Title from './Title';
import Sorting from './Sorting';
import Menu from './Menu';
import EditableColumnIndicator from './EditableColumnIndicator';

const styles = ({ spacing }) => ({
    gridMainHeaderCell: {
        width: '100%',
        height: spacing.unit * 5.5,
        padding: spacing.unit,

        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '&:hover $gridMainHeaderMenu': {
            display: 'block'
        }
    },
    gridMainHeaderRightItems: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    gridMainHeaderMenu: {
        display: 'none',
        marginRight: spacing.unit * 0.5
    },
    noPointerEvents: {
        pointerEvents: 'none'
    },
    gridHeaderCheckboxCell: {
        padding: spacing.unit * 0.5
    }
});

const MainHeader = withStyles(styles)(
    ({ classes: c, column, isColumnCheckbox, ...rest }) => {
        const {
            sortedColumn,
            sortDirection,
            draggedColumn,
            isAllRowsSelected,
            isPartiallySelected,
            toggleAllRowSelection
        } = useGridContext();

        const cellClasses = clsx(
            c.gridMainHeaderCell,
            draggedColumn && c.noPointerEvents
        );

        const renderColumnContent = () => {
            if (isColumnCheckbox) {
                return column.renderCheckbox({
                    checked: isAllRowsSelected,
                    partiallyChecked: isPartiallySelected,
                    handleChange: toggleAllRowSelection,
                    styles: { root: c.gridHeaderCheckboxCell }
                });
            } else {
                return <Title label={column.header} />;
            }
        };

        return (
            <DragAndSortWrapper column={column} {...rest}>
                <div className={cellClasses}>
                    {renderColumnContent()}
                    {column.sortable && (
                        <Sorting
                            column={column}
                            sortedColumnName={sortedColumn}
                            direction={sortDirection}
                        />
                    )}
                    <div className={c.gridMainHeaderRightItems}>
                        {column.showColumnMenuTool && (
                            <Menu
                                column={column}
                                className={c.gridMainHeaderMenu}
                            />
                        )}
                        {column.editable && <EditableColumnIndicator />}
                    </div>
                </div>
            </DragAndSortWrapper>
        );
    }
);

export default MainHeader;
