import React from 'react';
import { View } from '@react-pdf/renderer';
import { object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { useCustomDate } from 'sampling/hooks';
import { Title, RichComments } from 'components/PDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

import BasicContentContainer from './BasicContentContainer';
import Field from './BasicContentContainer/Field';

const styles = ({ spacing, palette }) => ({
    container: { position: 'relative' },
    content: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    subTitle: {
        fontSize: 14,
        fontFamily: FONT_FAMILY_BOLD
    },
    commentsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    subContainer: {
        width: '60%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    subField: {
        width: '32%'
    }
});

const propTypes = {
    setup: object.isRequired,
    details: object.isRequired
};

const DevContent = withPDFStyles(styles)(
    ({
        classes: c,
        setup: {
            sealDate,
            jwpNo,
            qcStyle,
            qcColor,
            factory,
            quantity,
            styleDesc,
            finishWash,
            versionNo
        },
        details: {
            qcReference,
            front,
            back,
            bodyFabricStatus,
            sampleColorStatus,
            trimFabricStatus,
            accAndTrimStatus,
            missingTrims,
            embellishmentStatus,
            washStatus,
            stichingStatus,
            patternStatus,
            fitStatus,
            typeOfSample,
            composition,
            gsm,
            sealSampleEvaluation: { commentsFromBuyer, generalInstructions }
        }
    }) => {
        return (
            <View style={c.container}>
                <BasicContentContainer front={front} back={back}>
                    <View style={c.content}>
                        <Field
                            label='Seal By Date'
                            value={useCustomDate(sealDate) || 'N/A'}
                            required
                        />
                        <Field label='JWP' value={jwpNo} required />
                        <Field label='Style' value={qcStyle} required />
                        <Field label='Colour' value={qcColor} required />
                        <Field label='Factory' value={factory} />

                        <Field
                            label='QTY'
                            value={quantity}
                            style={{ width: '10%' }}
                        />
                        <Field
                            label='Style Desc'
                            value={styleDesc}
                            style={{ width: '28%' }}
                        />
                        <Field
                            label='Fabric Composition'
                            value={composition}
                            style={{ width: '28%' }}
                        />
                        <Field
                            label='GSM'
                            value={gsm}
                            style={{ width: '10%' }}
                        />
                        <Field label='Finish Wash' value={finishWash} />

                        <Field label='Body Fabric' value={bodyFabricStatus} />
                        <Field
                            label='Colour Status'
                            value={sampleColorStatus}
                        />
                        <Field label='Trim Fabric' value={trimFabricStatus} />
                        <Field label='Acc & Trims' value={accAndTrimStatus} />
                        <Field
                            label='Acc & Trims Missing'
                            value={missingTrims}
                        />
                        <Field label='Wash Status' value={washStatus} />
                        <Field
                            label='Embellishment'
                            value={embellishmentStatus}
                        />
                        <Field label='Stitching' value={stichingStatus} />
                        <Field label='Pattern' value={patternStatus} />
                        <Field label='Fit' value={fitStatus} />
                        <View style={c.subContainer}>
                            <Field
                                label='Sample Type'
                                value={typeOfSample}
                                style={c.subField}
                            />
                            <Field
                                label='Reference'
                                value={qcReference}
                                style={c.subField}
                                required
                            />
                            <Field
                                label='Version No'
                                value={versionNo}
                                style={c.subField}
                            />
                        </View>
                    </View>
                </BasicContentContainer>
                <Title
                    text='Seal Sample Evaluation (ES GS / Sample Development Team)'
                    style={c.subTitle}
                />
                <View style={c.commentsContainer}>
                    <RichComments
                        label='Comments & Clarification from Buyer'
                        comments={commentsFromBuyer}
                    />
                    <RichComments
                        label='General Instructions'
                        comments={generalInstructions}
                    />
                </View>
            </View>
        );
    }
);

DevContent.propTypes = propTypes;

export default DevContent;
