import { useEffect, useState } from 'react';

import getDataUrl from '@libs/getDataUrl';
import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDFContent from './PDFContent';

export const loadProps = async ({
    details: { front, back, ...restDetails },
    ...restProps
}) => {
    const frontURL = front && (await getDataUrl(front));
    const backURL = back && (await getDataUrl(back));

    return {
        details: {
            front: frontURL,
            back: backURL,
            ...restDetails
        },
        ...restProps
    };
};

export const useMakePDF = ({ props, fileName, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleClick, loading, disabled, PDF, handleRemove] = usePDF({
        document: PDFContent,
        fileName,
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleClick
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleClick, PDF, handleRemove];
};
