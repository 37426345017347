import { put } from 'redux-saga/effects';
import { setSubmitSucceeded, stopSubmit } from 'redux-form';

import { setSnack } from 'utilities/redux.actions';

export default function*(id, handleCloseModal, moduleName, formName) {
    yield put(stopSubmit(formName));
    yield put(setSubmitSucceeded(formName));
    handleCloseModal();
    yield put(
        setSnack({
            message: `${moduleName} ${
                id === 0 ? 'added' : 'updated'
            } successfully`,
            type: 'success'
        })
    );
}
