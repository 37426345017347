// Entities
import { COLLECTIONS, LICENSORS } from 'libs/datasets';

// Selectors
import { selectCollections, selectLicensorsData } from 'selectors';

// Normalizers
import { normalizeCollections, normalizeLicensors } from 'normalizers';

// References
export default [
    {
        entity: COLLECTIONS,
        url: '/api/Collections',
        selector: selectCollections,
        normalizer: normalizeCollections
    },
    {
        entity: LICENSORS,
        url: '/api/Licensors',
        selector: selectLicensorsData,
        normalizer: normalizeLicensors
    }
];
