export default theme => ({
    gridContainer: {
        padding: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.sm,
        margin: 0
    },
    saveCollectionBtn: {
        height: 35
    },
    cancelButton: {
        width: '100%',
        marginTop: '1%',
        boxShadow: 'none'
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    }
});
