import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';

// Material
import { Button, Paper, Typography, withStyles } from '@material-ui/core';

// Local
import { useValidate, useNext } from 'hooks';
import { GridContainer, GridItem, Input, Select } from 'components';
import { Stepper as MultiStepper } from '@formComponents/Stepper';

import { reduxForm } from 'redux-form';

const styles = ({ typography, spacing, palette, shadows }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0],
        // Temp
        marginBottom: spacing.unit * 25
    },
    toolbar: {
        backgroundColor: palette.common.transparent,
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: shadows[0]
    },
    title: {
        color: palette.common.black,
        fontWeight: typography.fontWeightBold,
        fontSize: typography['h5'].fontSize
    },
    button: {
        height: spacing.unit * 4,
        width: spacing.unit * 15,
        textTransform: 'none'
    },
    nextButton: {
        position: 'absolute',
        height: spacing.unit * 5,
        width: spacing.unit * 45,
        bottom: 0,
        right: '50%',
        textTransform: 'none',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        transform: 'translate(50%, 50%)',

        '&:hover': {
            backgroundColor: palette.primary.dark
        }
    }
});

const mapState = state => ({
    formValues: getFormValues('Testing')(state)
});

const schema = {
    First: {
        name1: true,
        password1: value => value === 'password'
    },
    Second: {
        name2: true,
        password2: true
    },
    Third: {
        name3: true,
        password3: true
    },
    Fourth: {
        name4: true,
        password4: true
    },
    Fifth: {
        name5: true,
        password5: true
    },
    get saveDraft() {
        return {
            ...this.First,
            ...this.Second,
            ...this.Third
        };
    },
    get publish() {
        return {
            ...this.First,
            ...this.Second,
            ...this.Third,
            ...this.Fourth,
            ...this.Fifth
        };
    }
};

const StepperContainer = compose(
    connect(mapState),
    reduxForm({
        form: 'Testing'
    }),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {}
    }) => {
        const state = getState();
        const { activeStep, contents } = state;

        const areValid = useValidate(formValues, schema);

        const canProceed = useNext(state, areValid);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep]);

        console.log('container', contents[2] && contents[2].valid);

        return (
            <Paper className={c.container}>
                <Paper className={c.toolbar}>
                    <Typography className={c.title}>CAD UPLOAD</Typography>
                    <Button
                        variant='contained'
                        className={c.button}
                        onClick={() => dispatch({ type: 'next' })}
                        disabled={!areValid.saveDraft}
                    >
                        Save Draft
                    </Button>
                    <Button
                        variant='contained'
                        className={c.button}
                        onClick={() => dispatch({ type: 'next' })}
                        disabled={!areValid.publish}
                    >
                        Publish
                    </Button>
                </Paper>
                {children}
                <Button
                    variant='contained'
                    className={c.nextButton}
                    onClick={() => dispatch({ type: 'next' })}
                    disabled={!canProceed}
                >
                    Next
                </Button>
            </Paper>
        );
    }
);

const Content = number =>
    compose(
        connect(),
        reduxForm({
            form: 'Testing',
            destroyOnUnmount: false
        })
    )(props => {
        return (
            <Paper style={{ height: 400 }}>
                <Input
                    name={`name${number}`}
                    label='Name'
                    placeholder='Write something!'
                    required
                />
                <Input
                    name={`password${number}`}
                    label='Password'
                    placeholder='Write something!'
                    required
                />
            </Paper>
        );
    });

const Stepper = props => {
    const content = {
        First: Content(1),
        Second: Content(2),
        Third: Content(3),
        Fourth: Content(4),
        Fifth: Content(5)
        // Sixth: Content(6),
        // Seventh: Content(7),
        // Eighth: Content(8),
        // Ninth: Content(9),
    };
    return (
        <Fragment>
            <MultiStepper
                ContainerComponent={StepperContainer}
                content={content}
            />
        </Fragment>
    );
};

export { Stepper as default, Stepper as FormStepper };
