import { useState, useEffect, useCallback } from 'react';

export default (columns, storedDataGridSettings, setDataGridSettings) => {
    const visibleColumnsSettingsKey = `visibleColumns`;

    const [visibleColumns, setVisibleColumns] = useState(() => {
        const storedVisibleColumns =
            storedDataGridSettings[visibleColumnsSettingsKey];
        if (storedVisibleColumns) {
            const columnNames = columns.map(column => column.name);

            // Filter the stored visible columns to keep only those that exist in the current 'columns' array.
            const columnsAfterDelete = storedVisibleColumns.filter(column =>
                columnNames.includes(column)
            );

            // Return the filtered 'columnsAfterDelete' as the initial state for 'visibleColumns'.
            return columnsAfterDelete;
        }

        // If there are no stored visible columns, use the following logic:
        // - Filter the 'columns' array to include only columns with 'visible' not set to false.
        // - Extract the names of these columns and return them as the initial state for 'visibleColumns'.
        return columns
            .filter(column => column.visible !== false)
            .map(column => column.name);
    });

    const hideColumn = useCallback(columnName => {
        setVisibleColumns(prevVisibleColumns =>
            prevVisibleColumns.filter(column => column !== columnName)
        );
    }, []);

    const showColumn = useCallback(
        columnName => {
            if (!visibleColumns.includes(columnName)) {
                setVisibleColumns(prevVisibleColumns => [
                    ...prevVisibleColumns,
                    columnName
                ]);
            }
        },
        [visibleColumns]
    );

    const isColumnVisible = useCallback(
        columnName => {
            return visibleColumns.includes(columnName);
        },
        [visibleColumns]
    );

    const toggleColumnVisibility = useCallback(
        columnName => {
            if (isColumnVisible(columnName)) {
                hideColumn(columnName);
            } else {
                showColumn(columnName);
            }
        },
        [isColumnVisible, hideColumn, showColumn]
    );

    const clearColumnVisibilitySettings = useCallback(() => {
        const defaultColumnsVisibleList = columns
            .filter(column => column.visible !== false)
            .map(column => column.name);
        setVisibleColumns(defaultColumnsVisibleList);
    }, [columns]);

    useEffect(() => {
        setDataGridSettings({
            [visibleColumnsSettingsKey]: visibleColumns
        });
    }, [visibleColumns, setDataGridSettings]);

    return {
        visibleColumns,
        hideColumn,
        showColumn,
        isColumnVisible,
        toggleColumnVisibility,
        clearColumnVisibilitySettings,
        setVisibleColumns
    };
};
