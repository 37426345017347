import {
    FETCH_SETTINGS_REQUEST,
    SET_SETTINGS_DATA,
    SYNC_SETTINGS,
    RESET_SETTINGS_FLAG,
    SET_PIE_CHART_SETTINGS,
    SET_DATA_GRID_SETTINGS
} from './redux.actionTypes';

export const fetchSettingsRequest = () => ({
    type: FETCH_SETTINGS_REQUEST
});

export const setSettingsData = settings => ({
    type: SET_SETTINGS_DATA,
    payload: settings
});

export const syncSettings = () => ({
    type: SYNC_SETTINGS
});

export const resetSettingsFlag = () => ({
    type: RESET_SETTINGS_FLAG
});

export const setPieChartSettings = module => settings => ({
    type: SET_PIE_CHART_SETTINGS,
    payload: { module, settings }
});

export const setDataGridSettings = module => settings => ({
    type: SET_DATA_GRID_SETTINGS,
    payload: { module, settings }
});
