import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import { useDetailsBreakdownChartDispatch } from '../../../useDetailsBreakdownChart';
import ChartHideManage from './ChartHideManage';
import ManageCellsVisibility from './ManageCellsVisibilityModal';

const propTypes = {
    chartName: PropTypes.string.isRequired
};

const styles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    }
});

const AdornmentComponent = withStyles(styles)(({ classes, chartName }) => {
    const dispatch = useDetailsBreakdownChartDispatch();

    const handleHideChartClick = useCallback(() => {
        dispatch({ type: 'hideChart', payload: chartName });
    }, [chartName]);

    return (
        <div className={classes.container}>
            <ChartHideManage onClick={handleHideChartClick} />
            <ManageCellsVisibility chartName={chartName} />
        </div>
    );
});

AdornmentComponent.propTypes = propTypes;

export default AdornmentComponent;
