import createCachedSelector from 're-reselect';

// Local
import { mapToSelect } from 'helpers';

// Selectors
import {
    selectArmholesData,
    selectHemlinesData,
    selectLabelTypesData,
    selectNecklinesData
} from '../datasets/sampling/spec';
import { selectDataset } from '../datasets';
import { SPEC_REFERENCES } from '../../libs/datasets/entities/sampling';

// Armholes
export const selectArmholes = createCachedSelector(
    selectArmholesData,
    mapToSelect
)(() => 'placeholder');

// Hemlines
export const selectHemlines = createCachedSelector(
    selectHemlinesData,
    mapToSelect
)(() => 'placeholder');

// Label Types
export const selectLabelTypes = createCachedSelector(
    selectLabelTypesData,
    mapToSelect
)(() => 'placeholder');

// Necklines
export const selectNecklines = createCachedSelector(
    selectNecklinesData,
    mapToSelect
)(() => 'placeholder');

// Dirty
function getInitData(state, key, type = 1) {
    // 1 = dataset, 2 = init
    return type === 1
        ? state.samplingRetailers[key]
        : state.initSamplingPrint[key];
}

// Spec refs
export const selectSpecRefList = createCachedSelector(
    state => getInitData(state, 'specRefs', 2),
    data => {
        if (!data || (Array.isArray(data) && !data.length)) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }
        const specRefs = data.data;
        let mainInfo = specRefs.map(function(specRef) {
            const { sampleReference, specDescription } = specRef;
            const value = { value: sampleReference, fullData: specRef };

            return [
                { title: sampleReference, value },
                { title: specDescription, value }
            ];
        });
        return {
            rowInColumnWidth: [60, 40],
            paginationData: data.paginationMetadata,
            columnHeader: ['SPEC REF', 'SPEC DESCRIPTION'],
            columnInfo: mainInfo
        };
    }
)(() => 'placeholder');

// Todo: property names globals && check for parity (to see if they are being passed in)
export const selectSpecRefData = (
    { initSamplingPrint: { specRefs = [] } },
    sampleReference
) => {
    return specRefs.data
        .filter(({ sampleReference: ref }) => ref == sampleReference)
        .map(
            ({
                specDescription,
                necklineId,
                hemlineId,
                armholeId,
                labeltypeId,
                fabrics
            }) => ({
                specDescription,
                necklineId,
                hemlineId,
                armholeId,
                labelTypeId: labeltypeId,
                fabricData: fabrics
            })
        )[0];
};
export const selectSpecReference = createCachedSelector(
    state => selectDataset(state, SPEC_REFERENCES),
    (state, sampleReference) => sampleReference,
    ({ data = [] }, reference) =>
        data.reduce(
            (acm, { sampleReference, labeltypeId, fabrics, ...restDatum }) => {
                if (reference === sampleReference)
                    return {
                        ...restDatum,
                        labelTypeId: labeltypeId,
                        fabricData: fabrics
                    };
                else return acm;
            },
            {}
        )
)((state, sampleReference) => sampleReference);
