import {
    CHANGE_STEP_DATA,
    RESET_SAMPLING_PRINT_FORM,
    FETCH_SAMPLE_INFO_SUCCESS,
    FETCH_SPEC_REF_SUCCESS,
    FETCH_BACKNECK_DETAILS_SUCCESS,
    DELETE_INK_IMAGE_SUCCESS,
    POST_BACKNECK_DATA_SUCCESS,
    POST_CAD_COLOR_SUCCESS,
    UPDATE_BACKNECK_DETAILS_SUCCESS,
    UPDATE_NEW_CAD_SUCCESS,
    UPLOAD_CAD_IMAGE_SUCCESS,
    UPLOAD_ATTACHMENTS_SUCCESS,
    GET_SINGLE_TRIM_INFO_SUCCESS,
    POST_NEW_TRIM_STEP_FIRST_SUCCESS,
    GET_BOUGHT_DETAILS_SUCCESS,
    DELETE_TRIM_ITEM_SUCCESS,
    DELETE_BOUGHT_ITEM_SUCCESS,
    DELETE_BACKNECK_DETAILS_SUCCESS,
    RESET_SAMPLING_DATA,
    RESET_BACKNECK_DATA_REQUEST,
    DELETE_ATTACHMENT_SUCCESS,
    PUT_SAMPLING_PRINT_AMEND_SUCCESS,
    POST_NEW_CAD_SUCCESS,
    FETCH_CAD_REF_INFO_SUCCESS,
    CHECK_COLOR_DUPLICATE_SUCCESS,
    CHANGE_NEW_CAD_DATA_SUCCESS,
    LOAD_BACKNECK_DATA,
    PUBLISH_TO_DATABASE_DRAFT_SUCCESS
} from 'store/actionTypes';

const initialData = {
    saveDraftEnabled: false,
    firstFormSubmitted: false,
    isFormPublished: false,
    firstFormSubmitError: 0,

    selectedRetailer: null,
    selectedDepartment: null,
    designFrom: null,
    factoryLocation: null,
    copySampleRef: '',
    copySampleRefChecked: false,
    manualSampleRefChecked: false,
    photoApprovalCheck: false,
    attachmentsLoaded: false,
    sampleRef: '',
    sampleReferenceAutoId: null,
    requestedDate: null,
    sampleRequestedDate: null,
    selectedFactory: null,
    developmentNo: null,
    developmentName: '',

    //amend
    developmentNoAmend: null,
    developmentNameAmend: '',
    isFirstAmend: true,

    sendToUk: null,
    buyer: '',
    requestType: null,
    sendToBuyer: null,
    sendToLicensor: null,

    artWorkFileLocation: null,
    artWorkScale: null,
    artworkComments: '',
    printPosition: null,
    graphicref: '',
    graphicdesc: '',
    cadRef: null,
    graphicDesigner: null,
    designer: null,
    printType: null,
    measurement: '',
    dlLink: '',
    noOfScreens: 0,

    hemline: null,
    labeltype: null,
    neckline: null,
    specref: null,
    specdesc: '',
    armholes: null,

    backnecks: [],
    inks: [],
    inkImages: [],
    trims: [],
    boughts: [],
    specRefs: [],
    selectedTrim: null,
    selectedTrimData: null,
    selectedBought: null,
    selectedBoughtData: null,

    changeNotes: '',

    backneckImg: null,
    backneckGraphicRef: '',
    backneckFabricPatchW: '',
    backneckFabricPatchH: '',
    backneckFabricDetails: '',
    backneckPrintH: '',
    backneckPrintW: '',
    backneckInc1Details: '',
    backneckInc2Details: '',

    attachments: [],
    cadImage: null,

    newCadDesigner: null,
    newCadType: null,
    newCadLicensor: null,
    newCadManualRef: '',
    newCadIncompleteRef: null,
    newCadAmendCadRef: null,
    newCadColourRef: null,
    newCadColourOp: '',
    newCadRefId: null,
    newCadRef: '',
    newCadImg: null,
    newCadBuyer: '',
    newCadDevNo: '',
    newCadDevName: '',
    newCadDescription: '',
    newCadRetailer: null,
    newCadDepartment: null,
    newCadCollection: null,
    newCadIsColor: false,
    newCadCopyDevNo: null,
    newCadCopyCadId: null,
    newCadCopyCadRef: null,

    trimType: null,
    trimFrom: null,
    trimCopy: null,
    trimCardCopyRef: null,
    trimCopyTrimChecked: false,
    trimManualRefChecked: false,
    trimManualRefTxt: '',
    trimDescription: '',
    trimSize: '',
    trimComposition: '',
    trimSupplier: null,
    trimSupplierRef: '',
    trimCost: '',
    trimCurrency: null,
    trimUnit: '',
    trimComments: '',
    trimCadImg: null,
    trimAttachments: [],

    sampleRefAmend: null,
    sampleRefIncomplete: null,

    amendPageColorInfo: null
};

export default (state = initialData, action) => {
    /* eslint-disable no-use-before-define, no-unused-vars */
    let response = null;
    let data;

    switch (action.type) {
        case CHECK_COLOR_DUPLICATE_SUCCESS:
        case FETCH_CAD_REF_INFO_SUCCESS:
        case POST_NEW_CAD_SUCCESS:
            const designerData = state.newCadDesigner;
            const {
                data: {
                    buyer,
                    cadCollection,
                    cadCollectionName,
                    cadReference,
                    cadReferenceAutoId,
                    cadType,
                    colorOption,
                    devName,
                    department,
                    departmentName,
                    developmentNo,
                    description,
                    licensor,
                    licensorCode,
                    licensorName,
                    retailerId,
                    retailerName,
                    copyCadDevelopmentNo,
                    copyCadId,
                    copyCadReference,
                    designer,
                    designerName
                },
                type
            } = action.payload;
            return {
                ...state,
                newCadRefId: cadReferenceAutoId,
                newCadRef: cadReference,
                newCadDevNo: developmentNo,
                newCadDevName: devName,
                newCadDesigner:
                    designer && designerName
                        ? { value: designer, label: designerName }
                        : designerData,
                newCadDescription: description || '',
                newCadBuyer: buyer || '',
                newCadRetailer:
                    retailerId && retailerName
                        ? { value: retailerId, label: retailerName }
                        : null,
                newCadDepartment:
                    department && departmentName
                        ? { value: department, label: departmentName }
                        : null,
                newCadCollection:
                    cadCollection && cadCollectionName
                        ? { value: cadCollection, label: cadCollectionName }
                        : null,
                newCadColourOp: colorOption || '',
                newCadIsColor: !!type,
                newCadCopyDevNo: copyCadDevelopmentNo,
                newCadCopyCadId: copyCadId,
                newCadCopyCadRef: copyCadReference
            };

        case PUBLISH_TO_DATABASE_DRAFT_SUCCESS: {
            const {
                sta: {
                    value: {
                        data: { attachments, backnecks, boughts, trims }
                    }
                }
            } = action.payload;
            let count = -1;
            const newAttachments = state.attachments.map((item, index) => {
                if (Number(item.id) === 0) {
                    count++;
                    return { ...item, id: attachments[count] };
                } else {
                    return item;
                }
            });

            const newBacknecks = state.backnecks.map((backneck, index) => {
                return { ...backneck, backneckAutoId: backnecks[index] };
            });

            const newBoughts = state.boughts.map((bought, index) => {
                return { ...bought, boughtAutoId: boughts[index] };
            });

            const newTrims = state.trims.map((trim, index) => {
                return { ...trim, trimAutoId: trims[index] };
            });

            return {
                ...state,
                attachments: newAttachments,
                backnecks: newBacknecks,
                boughts: newBoughts,
                trims: newTrims
            };
        }

        case CHANGE_STEP_DATA:
            let newObj = {};
            if (action.multiple) {
                action.stepData.map((item, index) => {
                    return (newObj[item.type] = item.value);
                });
            } else {
                newObj[action.stepKey] = action.stepData;
            }
            return Object.assign({}, state, newObj);

        case PUT_SAMPLING_PRINT_AMEND_SUCCESS:
            data = action.payload.data;
            return {
                ...state,
                developmentNoAmend: data.sampleAmendData.developmentNo,
                developmentNameAmend: data.sampleAmendData.developmentText,
                isFirstAmend: false
            };

        case RESET_SAMPLING_PRINT_FORM:
            return {
                ...initialData,
                isFormPublished: true
            };

        case RESET_SAMPLING_DATA:
            return initialData;

        case FETCH_SAMPLE_INFO_SUCCESS:
            const {
                attachments = [],
                backnecks = [],
                trims = [],
                boughts = []
            } = action.payload;

            const filterdAttachments = attachments.map(
                attach => attach.originalImageUrl
            );

            return {
                ...state,
                backnecks,
                boughts,
                trims,
                attachments: [...state.attachments, ...filterdAttachments]
            };

        case FETCH_SPEC_REF_SUCCESS:
            response = action.payload;
            return {
                ...state,
                specRefs: response
            };

        case UPLOAD_ATTACHMENTS_SUCCESS:
            data = action.payload;
            return {
                ...state,
                attachments: [...state.attachments, ...data]
            };

        case DELETE_ATTACHMENT_SUCCESS:
            const stateAttachments = state.attachments;

            return {
                ...state,
                attachments: stateAttachments.filter(
                    (item, index) => index !== action.payload.index
                )
            };

        case UPLOAD_CAD_IMAGE_SUCCESS:
            data = action.payload;

            return {
                ...state,
                cadImage: data
            };

        case UPDATE_NEW_CAD_SUCCESS:
        case POST_CAD_COLOR_SUCCESS:
            return {
                ...state,
                newCadDesigner: null, // reset start
                newCadType: null,
                newCadLicensor: null,
                newCadManualRef: '',
                newCadIncompleteRef: null,
                newCadAmendCadRef: null,
                newCadColourRef: null,
                newCadColourOp: '',
                newCadRefId: null,
                newCadRef: '',
                newCadImg: null,
                newCadBuyer: '',
                newCadDevNo: '',
                newCadDevName: '',
                newCadDescription: '',
                newCadRetailer: null,
                newCadDepartment: null,
                newCadCollection: null,
                newCadIsColor: false,
                newCadCopyDevNo: null,
                newCadCopyCadId: null,
                newCadCopyCadRef: null
            };

        case GET_SINGLE_TRIM_INFO_SUCCESS:
            data = action.payload.data;
            const stateTrims = state.trims;
            return {
                ...state,
                trims: [...stateTrims, ...data]
            };

        case DELETE_TRIM_ITEM_SUCCESS:
            let { deleteTrimIndex } = action.payload;
            const allTrim = state.trims.map(i => ({ ...i }));
            const newTrimArr = allTrim.filter((item, index) => {
                return index !== deleteTrimIndex;
            });

            return {
                ...state,
                trims: newTrimArr
            };

        case GET_BOUGHT_DETAILS_SUCCESS:
            data = action.payload.data;
            const stateBoughts = state.boughts;
            return {
                ...state,
                boughts: [...stateBoughts, ...data]
            };

        case POST_NEW_TRIM_STEP_FIRST_SUCCESS:
            return {
                ...state,
                trimType: null,
                trimFrom: null,
                trimCopy: null,
                trimCardCopyRef: null,
                trimCopyTrimChecked: false,
                trimManualRefChecked: false,
                trimManualRefTxt: '',
                trimDescription: '',
                trimSize: '',
                trimComposition: '',
                trimSupplier: null,
                trimSupplierRef: '',
                trimCost: '',
                trimCurrency: null,
                trimUnit: '',
                trimComments: '',
                trimCadImg: null,
                trimAttachments: []
            };

        case DELETE_BOUGHT_ITEM_SUCCESS:
            let { deleteBoughtIndex } = action.payload;
            const allBought = state.boughts.map(i => ({ ...i }));
            const newBoughtArr = allBought.filter((item, index) => {
                return index !== deleteBoughtIndex;
            });

            return {
                ...state,
                boughts: newBoughtArr
            };

        // Redone -- Artwork
        case DELETE_INK_IMAGE_SUCCESS:
            return {
                ...state,
                inkImages: state.inkImages.filter(
                    (item, index) => index !== Number(action.payload.id)
                )
            };

        // Redone -- Others
        case POST_BACKNECK_DATA_SUCCESS:
            response = action.payload;
            return {
                ...state,
                backnecks: [...state.backnecks, ...response]
            };

        case RESET_BACKNECK_DATA_REQUEST:
            return {
                ...state,
                backneckGraphicRef: '',
                backneckFabricPatchW: '',
                backneckFabricPatchH: '',
                backneckFabricDetails: '',
                backneckPrintH: '',
                backneckPrintW: '',
                backneckInc1Details: '',
                backneckInc2Details: '',
                backneckImg: null
            };

        case LOAD_BACKNECK_DATA:
            return {
                ...state,
                ...action.payload
            };

        case FETCH_BACKNECK_DETAILS_SUCCESS:
            return {
                ...state,
                ...action.payload.backNeckDetails
            };

        case UPDATE_BACKNECK_DETAILS_SUCCESS:
            const backneckPayload = action.payload;
            return {
                ...state,
                backnecks: state.backnecks.map((item, index) => {
                    if (index === backneckPayload.backneckEditIndex) {
                        return backneckPayload.data;
                    } else {
                        return item;
                    }
                })
            };

        case DELETE_BACKNECK_DETAILS_SUCCESS:
            return {
                ...state,
                backnecks: state.backnecks.filter(
                    (item, index) => index !== action.payload.index
                )
            };

        default:
            return state;
    }
};
