import React from 'react';

import { withStyles } from '@material-ui/core';

const styles = () => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.10);',
        zIndex: 9999
    }
});

const Backdrop = withStyles(styles)(({ classes: { overlay } }) => {
    return <div className={overlay}></div>;
});

export default Backdrop;
