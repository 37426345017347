import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/InsertPhotoOutlined';

import { withStylesAndRef } from '@libs/materialUI';
import { useVersionsViewerState } from './useVersionsViewer';

const styles = ({ palette, spacing }) => ({
    preview: {
        width: '33.33%',
        height: 0,
        position: 'relative',
        paddingTop: `calc(33.33% * ${Math.sqrt(2)})`,
        backgroundColor: palette.background.main,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    },
    image: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        top: 0,
        objectFit: 'contain'
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: palette.background.dark
    },
    icon: {
        color: palette.background.dark,
        fontSize: spacing.unit * 4
    },
    infoContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
        bottom: spacing.unit * -6
    },
    info: {
        height: spacing.unit * 6,
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacing.unit,
        lineHeight: 1
    }
});

const Preview = withStylesAndRef(styles)(({ classes: c, forwardRef }) => {
    const {
        versions,
        activeIndex,
        readOnly,
        imageResolver
    } = useVersionsViewerState();

    const [image, setImage] = useState(null);

    useEffect(() => {
        let isSubscribed = true;
        if (!!versions.length) {
            const source = versions[activeIndex];
            const loadImage = async () =>
                await new Promise(resolve => {
                    let refImage = new Image();
                    refImage.src = imageResolver(source, 'big');
                    refImage.onload = resolve;
                });

            setImage(imageResolver(source, 'small'));
            loadImage().then(
                () => isSubscribed && setImage(imageResolver(source, 'big'))
            );
        } else setImage(null);

        return () => {
            isSubscribed = false;
        };
    }, [imageResolver, activeIndex, versions]);

    return (
        <div className={c.preview} ref={forwardRef}>
            {image ? (
                <img
                    className={c.image}
                    // src={imageResolver(image, 'big')}
                    src={image}
                    alt='preview'
                />
            ) : (
                <div className={c.image}>
                    <div className={c.empty}>
                        <ImageIcon className={c.icon} />
                    </div>
                </div>
            )}
            {!readOnly && (
                <div className={c.infoContainer}>
                    <Typography className={c.info} variant='subtitle2'>
                        *Allowed extensions - jpeg & png
                    </Typography>
                    <Typography className={c.info} variant='subtitle2'>
                        *Max file size, 10 MB
                    </Typography>
                </div>
            )}
        </div>
    );
});

export default Preview;
