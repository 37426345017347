import { useMemo, useCallback } from 'react';
import { useUploadDispatch } from './useUpload';

const useUploadStyle = ({ theme: { palette }, value }) =>
    useMemo(
        () => ({
            '--textColor': value
                ? palette.common.black
                : palette.secondary.light
        }),
        [value]
    );

export { useUploadStyle };

export const useDropZone = handleBlur => {
    const dispatch = useUploadDispatch();

    const onDragEnter = useCallback(() => {
        dispatch({ type: 'setDragging', payload: true });
    }, [dispatch]);

    const onDragLeave = useCallback(() => {
        dispatch({ type: 'setDragging', payload: false });
    }, [dispatch]);

    const onDrop = useCallback(
        event => {
            event.preventDefault();
            const { dataTransfer: { items = [] } = {} } = event;

            const file = [...items]
                .find(({ kind }) => kind === 'file')
                .getAsFile();
            dispatch({ type: 'addFile', payload: file });
            handleBlur();
        },
        [dispatch, handleBlur]
    );
    return { onDragEnter, onDragLeave, onDrop };
};
