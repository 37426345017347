import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import GridCheckbox from '../../../../../GridCheckbox';

const propTypes = {
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    handleGridDataUpdate: PropTypes.func.isRequired
};

const styles = () => ({
    gridCheckboxCellEditor: {
        padding: 0
    }
});

const CheckboxCell = ({ classes, column, row, handleGridDataUpdate }) => {
    const handleChange = useCallback(() => {
        const newRow = {
            ...row,
            [column.name]: row[column.name] === 'True' ? 'False' : 'True'
        };
        handleGridDataUpdate(newRow);
    }, [row, column.name, handleGridDataUpdate]);

    return (
        <GridCheckbox
            checked={row[column.name] === 'True'}
            handleChange={handleChange}
            className={classes.gridCheckboxCellEditor}
        />
    );
};

CheckboxCell.propTypes = propTypes;

export default withStyles(styles)(React.memo(CheckboxCell));
