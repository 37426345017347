import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Libs
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import SelectModel from '@formComponents/SelectModel';

// Local
import { SAMPLE_FACTORY } from 'sampling/redux.datasets';
import { FORM_NAME, DOMAIN_NAME } from 'sampling/SampleDispatch/common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'factory',
    label: 'Factory',
    placeholder: 'Select factory',
    domain: DOMAIN_NAME,
    entity: SAMPLE_FACTORY,
    disabled: false,
    required: false
};

const filterFactoriesByCountry = (factories, countryId) => {
    // Filter factories that match the countryId
    const filteredFactories = factories.filter(
        factory => factory.countryId === countryId
    );

    // If no factories match the countryId, return all factories
    if (filteredFactories.length === 0) {
        return factories;
    }

    return filteredFactories;
};

const dataSelector = createCachedSelector(
    entitySelector,
    (_, __, ___, originCountry) => originCountry,
    (data, originCountry) =>
        filterFactoriesByCountry(data, originCountry).map(
            ({ id, ...datum }) => ({
                label: Object.values(datum)[0],
                value: id
            })
        )
)(
    (state, domain, entity, originCountry) =>
        `@data-${domain}-${entity}-${originCountry}`
);

const mapState = (state, { domain, entity }) => {
    const originCountry = formValueSelector(FORM_NAME)(state, 'originCountry');
    return {
        data: dataSelector(state, domain, entity, originCountry),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectSampleFactory = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectSampleFactory.propTypes = propTypes;
SelectSampleFactory.defaultProps = defaultProps;

export default SelectSampleFactory;
