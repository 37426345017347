import authReferences from './auth';
import commonReferences from './common';
import samplingReferences from './sampling';

export const datasetReferences = [
	...authReferences,
	...commonReferences,
	...samplingReferences
];

export const getDatasetReference = dataset => {
	const datasetReference = datasetReferences.filter(
		({ entity }) => entity.toUpperCase() === dataset.toUpperCase()
	);

	return datasetReference.length ? datasetReference[0] : null;
};

export default getDatasetReference;
