import React from 'react';

// Material
import { withStyles, CardActions } from '@material-ui/core';

// Local
import styles from './styles';

const CardActionsBase = props => {
    return <CardActions {...props} />;
};

const _CardActionsBase = withStyles(styles)(CardActionsBase);

export { _CardActionsBase as default, _CardActionsBase as CardActionsBase };
