import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

import GridXlsxExport from 'dataGrid/components/GridXLSXExport';
import Tooltip from '@libComponents/Tooltip';
import {
    QuantityIcon,
    ShippedIcon,
    OrderCancelledIcon,
    OrderHoldIcon
} from '@assets/Icons';
import { ORDER_CP_EXPORT_XL_DATA } from 'orders/redux.actionTypes';
import { orderCPExportExcelDataLoadingSelector } from 'orders/redux.selectors';
import { useAggregatedData } from './hooks';

const styles = ({ spacing, palette }) => ({
    rootContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    quantity: {
        marginLeft: spacing.unit,
        marginRight: spacing.unit * 2,
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.main
    },
    primaryColor: {
        color: palette.primary.main
    },
    successColor: {
        color: palette.success.main
    },
    errorColor: {
        color: palette.error.main
    },
    warningColor: {
        color: palette.warning.main
    }
});

const mapStateToProps = state => ({
    loading: orderCPExportExcelDataLoadingSelector(state)
});

const mapDispatchToProps = (dispatch, { data, ...payload }) => ({
    handleXLDownload: () => dispatch({ type: ORDER_CP_EXPORT_XL_DATA, payload })
});

const ExportOptions = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(({ classes: c, loading, handleXLDownload, data, selectedRows }) => {
    const { totalQty, shippedQty, cancelledQty, holdqty } = useAggregatedData(
        data,
        selectedRows
    );

    const renderQuantityInfo = (icon, quantity, colorClass, tooltipTitle) => (
        <Tooltip title={tooltipTitle} dark={true}>
            <div className={c.container}>
                {icon}
                <Typography className={clsx(c.quantity, colorClass)}>
                    {quantity}
                </Typography>
            </div>
        </Tooltip>
    );

    return (
        <div className={c.rootContainer}>
            <div className={c.container}>
                {renderQuantityInfo(
                    <QuantityIcon />,
                    totalQty,
                    c.primaryColor,
                    'Total order qty for this page'
                )}
                {renderQuantityInfo(
                    <ShippedIcon />,
                    shippedQty,
                    c.successColor,
                    'Qty shipped on this page'
                )}
                {renderQuantityInfo(
                    <OrderCancelledIcon />,
                    cancelledQty,
                    c.errorColor,
                    'Cancelled orders qty on this page'
                )}
                {renderQuantityInfo(
                    <OrderHoldIcon />,
                    holdqty,
                    c.warningColor,
                    'On hold qty on this'
                )}
            </div>
            <div className={c.container}>
                <GridXlsxExport
                    title='XLSX'
                    loading={loading}
                    onClick={handleXLDownload}
                />
            </div>
        </div>
    );
});

export { ExportOptions as default, ExportOptions };
