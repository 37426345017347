import { change, formValueSelector } from 'redux-form';
import { put, select, all, call, fork } from 'redux-saga/effects';

// Libs
import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import {
    composeLoadingWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker
} from '@libs/publishWorkers';
import { FORM_NAME } from '../../../Print/common';

import { publishFailedWorker } from '@libs/publishWorkers';
import getSampleReferenceWorker from './getSampleReferenceWorker';
import { sizePathsSelector } from 'common/redux.selectors';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';

import { publishSchema } from './schemas';
import handoverWorker from './handoverWorker';
import reportsWorker from './reportsWorker';
import getDevelopmentName from './getDevelopmentName';

export default function*(action) {
    const {
        meta: {
            handleComplete = () => {},
            saveDraft = false,
            handover = false
        },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {}
        }
    } = action;
    const [{ attachments, techPack }, developmentName] = yield all([
        select(formValueSelector(FORM_NAME), 'attachments', 'techPack'),
        call(getDevelopmentName, mode)
    ]);

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [...attachments, techPack],
        techPack,
        mode
    });

    try {
        const [
            { sampleReference, id },
            attachmentBlobs,
            techPackBlobs,
            attachmentSizePaths,
            techPackSizePaths
        ] = yield all([
            call(getSampleReferenceWorker, { mode, handleLoading }),
            call(getAssetBlobsWorker, {
                assets: attachments,
                mode,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: techPack,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'printSampleAttachment'),
            select(sizePathsSelector, 'printSampleTechPack')
        ]);

        const attachmentsPayload = yield call(uploadAssetsWorker, {
            assetBlobs: attachmentBlobs,
            folderPath: `${attachmentSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: attachmentSizePaths,
            handleLoading,
            developmentName,
            enableSerial: true
        });

        const [techPackPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: techPackBlobs,
            folderPath: `${techPackSizePaths.sizeOriginal}/${sampleReference}`,
            reference: sampleReference,
            sizePaths: techPackSizePaths,
            handleLoading,
            developmentName
        });

        const payload = yield call(getPublishPayloadWorker, {
            mode,
            id,
            sampleReference
        });

        const techPackPdfUrl = techPackPayload
            ? techPackPayload.sizeOriginal
            : '';

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinued: !saveDraft,
                isHandover: handover,
                attachments: attachmentsPayload,
                techPackPdfUrl
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'Sample/PublishPrintSampleReference',
            data: publishData
        });

        /*
           --- After publish
        */

        if (mode === 'new') {
            yield put(change(FORM_NAME, 'sampleReference', sampleReference));
            yield put(change(FORM_NAME, 'id', id));
        }

        if (!saveDraft || handover) {
            yield call(reportsWorker, {
                sampleReference,
                developmentName,
                mode,
                handleLoading
            });
        }

        if (saveDraft) {
            // Handover
            if (handover) {
                yield call(handoverWorker, { mode, sampleReference });
            }
            // Save draft
            else {
                yield call(savedDraftWorker, {
                    mode,
                    sampleReference
                });
            }
        }
        closeMailModal();
        closePreviewModal();
        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to ';

        if (saveDraft && !handover) actionErrorMessage += 'save';
        else if (handover) actionErrorMessage += 'handover';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
