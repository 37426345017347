import React from 'react';
import PropTypes from 'prop-types';

// Material
import clsx from 'classnames';
import { IconButton, withStyles } from '@material-ui/core';

// Local
import Tooltip from '@libComponents/Tooltip';

const propTypes = {
    IconComponent: PropTypes.func.isRequired
};

const styles = ({ spacing, shape }) => ({
    root: {
        width: spacing.unit * 1.5,
        height: spacing.unit * 1.5,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    button: {
        padding: spacing.unit * 0.5,
        borderRadius: shape.borderRadius * 0.5
    }
});

const TooltipAction = withStyles(styles)(
    ({ classes: c, className, IconComponent, ...props }) => (
        <Tooltip {...props}>
            <IconButton className={clsx(c.button, className)}>
                <IconComponent className={c.root} />
            </IconButton>
        </Tooltip>
    )
);

TooltipAction.propTypes = propTypes;

export { TooltipAction as default, TooltipAction };
