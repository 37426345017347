import makeContext from '@libs/makeContext';

const initialState = {
    versions: [],
    activeIndex: 0,
    isDragging: false,
    dragIndex: -1,
    hoverIndex: -1,
    readOnly: false,
    disableRemove: false,
    disableDrag: false,
    imageResolver: image => image
};

const reducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case 'setActiveIndex': {
            return { ...state, activeIndex: payload };
        }

        case 'removeImage': {
            const { activeIndex } = state;

            const shouldDecrement = activeIndex >= payload && activeIndex !== 0;

            return {
                ...state,
                activeIndex: shouldDecrement ? activeIndex - 1 : activeIndex
            };
        }

        case 'setDragging': {
            return {
                ...state,
                isDragging: payload
            };
        }

        case 'setDragIndex': {
            return {
                ...state,
                dragIndex: payload,
                hoverIndex: payload >= 0 ? state.hoverIndex : payload
            };
        }

        case 'setHoverVersions': {
            const { versions } = state;
            const { dragImage, hoverIndex } = payload;

            const dragIndex = versions.indexOf(dragImage);

            const versionsAfterHover = versions.reduce(
                (acm, version, index) => {
                    if (dragIndex !== hoverIndex) {
                        if (index === dragIndex) return acm;
                        if (index === hoverIndex) {
                            if (dragIndex < hoverIndex)
                                return [...acm, version, dragImage];

                            if (dragIndex > hoverIndex)
                                return [...acm, dragImage, version];
                        }
                    }

                    return [...acm, version];
                },
                []
            );

            return {
                ...state,
                versions: versionsAfterHover,
                hoverIndex,
                activeIndex: hoverIndex
            };
        }

        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addImages': {
            const { versions } = state;
            const { payload } = action;

            // From FileList to Array
            const addedVersions = [...payload].map(imageFile => ({
                id: 0,
                url: URL.createObjectURL(imageFile),
                filename: imageFile.name,
                size: imageFile.size
            }));

            reduxFormOnChange([...versions, ...addedVersions]);

            return { type: 'setDragging', payload: false };
        }

        case 'removeImage': {
            const { versions } = state;
            const { payload } = action;

            const versionsAfterRemoval = versions.filter(
                (versionUrl, index) => {
                    if (index === payload) {
                        URL.revokeObjectURL(versionUrl);
                    }
                    return index !== payload;
                }
            );

            reduxFormOnChange(versionsAfterRemoval);

            return action;
        }

        case 'dropVersion': {
            const { versions } = state;

            reduxFormOnChange(versions);

            return action;
        }

        default:
            return action;
    }
};

const [
    VersionsViewerProvider,
    useVersionsViewerState,
    useVersionsViewerDispatch,
    useVersionsViewer
] = makeContext(reducer, initialState, {
    name: 'VersionsViewer',
    middleware
});

export {
    useVersionsViewer as default,
    VersionsViewerProvider,
    useVersionsViewerState,
    useVersionsViewerDispatch
};
