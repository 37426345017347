import { createSelector } from 'reselect';

import { DOMAIN_NAME } from './common';

const utilitiesSelector = state => state[DOMAIN_NAME];

const loadingSelector = createSelector(
    utilitiesSelector,
    utilities => utilities.loading
);
const loadingProgressSelector = createSelector(
    utilitiesSelector,
    utilities => utilities.loadingProgress
);

const snackSelector = createSelector(
    utilitiesSelector,
    utilities => utilities.snack || {}
);

const reloadActionsSelector = createSelector(
    utilitiesSelector,
    utilities => utilities.reloadActions
);


export {
    loadingSelector,
    loadingProgressSelector,
    snackSelector,
    reloadActionsSelector
};
