import { useEffect, useState, useMemo } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDFContent from './PDFContent';

export const useSamplingReport = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document: PDFContent,
        fileName,
        props,
        errorResolver: error => setPDFError(error),
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleExport, exportLoading, exportDisabled];
};

export const useFilteredData = (data, chartSettings) => {
    const filteredData = useMemo(() => {
        const newData = {};

        Object.keys(data).forEach(key => {
            if (chartSettings[key]) {
                newData[key] = data[key];
            }
        });

        return newData;
    }, [data, chartSettings]);

    return filteredData;
};

const getAggregatedValue = data => {
    const firstKey = Object.keys(data)[0];
    if (data[firstKey] && data[firstKey].length > 0) {
        return data[firstKey].reduce((acc, item) => acc + item.value, 0);
    }
    return 0;
};

export const useAggregatedValue = data => {
    const aggregatedValue = useMemo(() => {
        return getAggregatedValue(data);
    }, [data]);

    return aggregatedValue;
};
