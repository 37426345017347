import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute } from 'navigation/redux.actions';

import { AMEND_ORDER_ROUTE_ID } from '../../../Order/common';
import { getAmendOrderData } from '../../../redux.actions';

export default function*({ echoOrderNo }) {
    yield put(
        setSnack({
            message: `Data has been saved successfully!`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(AMEND_ORDER_ROUTE_ID));
    yield put(getAmendOrderData(echoOrderNo));
}
