export default ({ palette, spacing }) => ({
    root: {
        borderBottom: `1px solid`,
        borderBottomColor: palette.grey[300],
        padding: `${spacing.unit * 0.5}px ${spacing.unit * 1.25}px`,

        '&:hover': {
            borderBottomColor: palette.grey[400],
            zIndex: 4000
        }
    },
    input: {
        height: '100%'
    }
});
