import { useMemo } from 'react';

export const useAggregatedData = (data, selectedRows) =>
    useMemo(() => {
        const reducer = (accumulator, itemId) => {
            const selectedItem = data.find(item => item.id === itemId);
            if (selectedItem) {
                return {
                    totalQty:
                        accumulator.totalQty + Number(selectedItem.poQuantity),
                    shippedQty:
                        accumulator.shippedQty +
                        Number(selectedItem.shippedQty),
                    cancelledQty:
                        selectedItem.orderStatus === 'CANCELLED'
                            ? accumulator.cancelledQty +
                              Number(selectedItem.poQuantity)
                            : accumulator.cancelledQty,
                    holdqty:
                        selectedItem.orderStatus === 'HELD'
                            ? accumulator.holdqty +
                              Number(selectedItem.poQuantity)
                            : accumulator.holdqty
                };
            }
            return accumulator;
        };

        const initialAccumulator = {
            totalQty: 0,
            shippedQty: 0,
            cancelledQty: 0,
            holdqty: 0
        };

        if (selectedRows && selectedRows.length > 0) {
            // Calculate totalQty and shippedQty from selectedRows
            return selectedRows.reduce(reducer, initialAccumulator);
        }

        // Calculate totalQty and shippedQty from all data if no selectedRows
        return data.reduce(
            (accumulator, item) => ({
                totalQty: accumulator.totalQty + Number(item.poQuantity),
                shippedQty: accumulator.shippedQty + Number(item.shippedQty),
                cancelledQty:
                    item.orderStatus === 'CANCELLED'
                        ? accumulator.cancelledQty + Number(item.poQuantity)
                        : accumulator.cancelledQty,
                holdqty:
                    item.orderStatus === 'HELD'
                        ? accumulator.holdqty + Number(item.poQuantity)
                        : accumulator.holdqty
            }),
            initialAccumulator
        );
    }, [data, selectedRows]);
