export default theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    container: {},
    breadcrumbBar: {
        background: '#f5f5f5',
        margin: '0 -24px 15px',
        padding: '10px 24px',
        borderBottom: '1px solid #e4e4e4',
        boxShadow: '0px 0px 7px 0px rgba(184, 184, 184, 0.39)',
    },
});
