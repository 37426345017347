import { formValueSelector } from 'redux-form';
import { select, all, call } from 'redux-saga/effects';
// Libs
import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import {
    publishFailedWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker,
    composeLoadingWorker
} from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
// Local
import { FORM_NAME } from '../../../Order/common';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';
import softPublishWorker from './softPublishWorker';
import { publishSchema } from './schemas';
import reportSendWorker from './reportSendWorker';
import statusChangeWorker from './statusChangeWorker';

export default function*(action) {
    const {
        meta: {
            handleComplete = () => {},
            saveDraft = false,
            isHold = false,
            isCancel = false,
            softPublish = false
        },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {}
        }
    } = action;

    const { attachments = [], echoOrderNo, versionNo } = yield select(
        formValueSelector(FORM_NAME),
        'attachments',
        'echoOrderNo',
        'versionNo'
    );

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [...attachments],
        mode
    });

    try {
        const [attachmentBlobs, attachmentSizePaths] = yield all([
            call(getAssetBlobsWorker, {
                assets: attachments,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'orderAttachment')
        ]);
        const orderVersionNo =
            mode === 'amend' && !softPublish
                ? Number(versionNo) + 1
                : versionNo;

        const attachmentsPayload = yield call(uploadAssetsWorker, {
            assetBlobs: attachmentBlobs,
            folderPath: `${attachmentSizePaths.sizeOriginal}/${echoOrderNo}`,
            reference: echoOrderNo,
            sizePaths: attachmentSizePaths,
            handleLoading,
            developmentName: orderVersionNo,
            enableSerial: true
        });

        yield call(handleLoading);

        const payload = yield call(getPublishPayloadWorker);

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinued: !saveDraft,
                isCancel,
                isHold,
                softPublish,
                attachments: attachmentsPayload,
                orderVersionNo
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'OrderMains/PublishOrder',
            data: publishData
        });

        if (!saveDraft && !softPublish) {
            yield call(reportSendWorker, {
                echoOrderNo,
                orderVersionNo,
                mode,
                handleLoading
            });
        }

        if (saveDraft) {
            yield call(savedDraftWorker, {
                mode,
                echoOrderNo
            });
        }

        if (softPublish) {
            yield call(softPublishWorker, {
                echoOrderNo
            });
        }

        closeMailModal();
        closePreviewModal();

        if (isCancel || isHold) {
            yield call(statusChangeWorker, {
                echoOrderNo,
                statusText: isHold ? 'held' : 'canceled'
            });
        }
        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to ';

        if (saveDraft) actionErrorMessage += 'save';
        else if (isCancel || isHold)
            actionErrorMessage += `${isHold ? 'hold' : 'cancel'}`;
        else if (softPublish) actionErrorMessage += 'soft publish';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
