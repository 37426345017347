export default ({ palette, typography }) => ({
    toolbarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: 'unset',
        padding: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        // borderTop: '1px solid',
        // borderColor: theme.palette.grey[300],
        boxShadow: 'unset',
        padding: 0,
    },
    divider: {
        borderRight: '1px solid',
        borderColor: palette.grey[300],
        /* Size that matches all icons (taken from fontSize as well) */
        height: typography.display1.fontSize,
    },
});
