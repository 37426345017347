import React, { useMemo } from 'react';
import { Page, Document, View, Image as PDFImage } from '@react-pdf/renderer';
import { string, object, bool } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT,
    FONT_FAMILY_NORMAL
} from 'common/constants';
import { Title, RichComments } from 'components/PDF';
import { extractProps } from '@libs/propTypes';

import FabricReferencePDFContent from './FabricReferencePDFContent';
import FabricDetailsPDFContent, {
    FabricDetailsPDFContentPropTypes
} from './FabricDetailsPDFContent';
import Image from './Image';
import CriticalPathContent, {
    CriticalPathContentPropTypes
} from './CriticalPathContent';
import YarnDetailsPDFContent, {
    YarnDetailsPDFContentPropTypes
} from './YarnDetailsPDFContent';
import PhysicalTestResultsPDFContent, {
    PhysicalTestResultsPDFContentPropTypes
} from './PhysicalTestResultsPDFContent';

const propTypes = {
    pageTitle: string.isRequired,
    docTitle: string.isRequired,
    values: object.isRequired,
    isDevelopedFabric: bool
};

const defaultProps = {
    docTitle: 'Document',
    pageTitle: 'PDF Page'
};

const styles = ({ spacing, palette }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM,
        fontFamily: FONT_FAMILY_NORMAL
    },
    pageTitle: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    leftContent: {
        width: '65%'
    },
    originalImageWrapper: {
        width: '34%',
        maxHeight: spacing.unit * 41.5,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        borderColor: palette.grey[300]
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    comments: {
        width: '60%',
        marginLeft: spacing.unit * 0.5,
        marginRight: spacing.unit * 0.5,
        marginTop: spacing.unit * 2.5
    }
});

const PDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        pageTitle,
        docTitle,
        isDevelopedFabric,
        values: {
            fabricReference,
            versionName,
            developmentType,
            boughtReference,
            originalFabric,
            requiredFabric,
            originalFabricImage,
            washedImage,
            comments,
            developedFabric,
            ...props
        }
    }) => {
        const pdfPageProps = useMemo(
            () => ({
                size: 'A4',
                orientation: 'landscape',
                style: c.page
            }),
            []
        );

        return (
            <Document title={docTitle}>
                <Page {...pdfPageProps}>
                    <View>
                        <Title text={pageTitle} style={c.pageTitle} />
                        <View style={c.container}>
                            <View style={c.leftContent}>
                                <FabricReferencePDFContent
                                    fabricReference={fabricReference}
                                    versionName={versionName}
                                    developmentType={developmentType}
                                    boughtReference={boughtReference}
                                />
                                <FabricDetailsPDFContent
                                    title='Original Fabric Details'
                                    {...originalFabric}
                                />
                                <FabricDetailsPDFContent
                                    {...extractProps(
                                        props,
                                        FabricDetailsPDFContentPropTypes
                                    )}
                                    {...requiredFabric}
                                    title='Required Fabric Details'
                                    hasWashColor={true}
                                />
                                <View style={c.container}>
                                    <Image
                                        url={washedImage}
                                        title='Image'
                                        styles={{
                                            width: '40%'
                                        }}
                                    />
                                    <RichComments
                                        label='Comments'
                                        comments={comments}
                                        styles={c.comments}
                                    />
                                </View>
                            </View>
                            <View style={c.originalImageWrapper}>
                                {originalFabricImage && (
                                    <PDFImage
                                        src={originalFabricImage}
                                        style={c.image}
                                        alt='original fabric image'
                                    />
                                )}
                            </View>
                        </View>
                        <CriticalPathContent
                            {...extractProps(
                                props,
                                CriticalPathContentPropTypes
                            )}
                        />
                    </View>
                </Page>
                {isDevelopedFabric && (
                    <Page {...pdfPageProps}>
                        <YarnDetailsPDFContent
                            {...extractProps(
                                props,
                                YarnDetailsPDFContentPropTypes
                            )}
                            {...developedFabric}
                        />
                        <PhysicalTestResultsPDFContent
                            {...extractProps(
                                props,
                                PhysicalTestResultsPDFContentPropTypes
                            )}
                        />
                    </Page>
                )}
            </Document>
        );
    }
);

PDFContent.propTypes = propTypes;
PDFContent.defaultProps = defaultProps;

export default PDFContent;
