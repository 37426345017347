import { useEffect, useState } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';
import getDataUrl from '@libs/getDataUrl';

import Report from './Report';

const loadProps = async ({ images, ...restProps }) => {
    const imagesURL = await Promise.all(
        images.map(async ({ url, link, ...image }) => {
            const dataURL = await getDataUrl(url);
            return !!dataURL
                ? { ...image, url: dataURL, link: link || url }
                : {};
        })
    );

    return {
        images: imagesURL,
        ...restProps
    };
};

export const useReport = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleExport, loading, disabled, PDF, handleRemoveURL] = usePDF({
        document: Report,
        fileName,
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleExport, PDF, handleRemoveURL];
};
