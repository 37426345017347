import React from 'react';
import { View } from '@react-pdf/renderer';
import { string, bool, object } from 'prop-types';

import { Field } from 'components/PDF';
import { useCustomDate } from 'sampling/hooks';
import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = () => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldWidth: {
        width: '15%'
    }
});

const propTypes = {
    retailer: string,
    department: string,
    echoOrderNo: string,
    versionNo: object,
    orderAmendedDate: string,
    orderPlacedDate: string,
    licensor: string,
    styleNo: object,
    productDescription: object,
    factory: object,
    factoryRef: object,
    supplierNo: object,
    season: object,
    productionSampleType: object,
    sendToEcho: object,
    sendToBuyer: object,
    systemFactoryNo: object,
    kimballNo: object,
    commodity: object,
    nlKind: object,
    blankOrder: bool
};

const Details = withPDFStyles(styles)(
    ({
        retailer,
        department,
        sampleSendDate,
        echoOrderNo,
        versionNo,
        orderAmendedDate,
        orderPlacedDate,
        licensor,
        styleNo,
        productDescription,
        factory,
        factoryRef,
        supplierNo,
        season,
        productionSampleType,
        sendToEcho,
        sendToBuyer,
        systemFactoryNo,
        kimballNo,
        commodity,
        nlKind,
        classes: c
    }) => {
        return (
            <View>
                <View style={c.container}>
                    <Field
                        label='Buyer'
                        value={retailer}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Department'
                        value={department}
                        style={c.fieldWidth}
                    />
                    {!!sampleSendDate.value && (
                        <Field
                            label='Sample Send Date'
                            value={useCustomDate(sampleSendDate.value)}
                            style={c.fieldWidth}
                            isAmend={sampleSendDate.isAmend}
                        />
                    )}
                    <Field
                        label='Echo Order No'
                        value={echoOrderNo}
                        style={c.fieldWidth}
                    />
                    <Field
                        label='Version No'
                        value={versionNo.value}
                        style={{ width: '10%' }}
                        isAmend={versionNo.isAmend}
                    />
                    <Field
                        label='Order Amended'
                        value={useCustomDate(orderAmendedDate)}
                        style={{ width: '10%' }}
                    />
                    <Field
                        label='Order Placed'
                        value={useCustomDate(orderPlacedDate)}
                        style={{ width: '10%' }}
                    />
                    <Field
                        label='Style No / ORIN'
                        value={styleNo.value}
                        style={c.fieldWidth}
                        isAmend={styleNo.isAmend}
                        required={!!styleNo.value}
                    />
                    <Field
                        label='Product Description'
                        value={productDescription.value}
                        style={{ width: '30%' }}
                        required={false}
                        isAmend={productDescription.isAmend}
                    />
                    <Field
                        label='Factory'
                        value={factory.value}
                        style={c.fieldWidth}
                        isAmend={factory.isAmend}
                    />
                    <Field
                        label='Factory Ref'
                        value={factoryRef.value}
                        style={c.fieldWidth}
                        isAmend={factoryRef.isAmend}
                    />
                    {!!supplierNo.value && (
                        <Field
                            label='Supplier No'
                            value={supplierNo.value}
                            style={c.fieldWidth}
                            isAmend={supplierNo.isAmend}
                        />
                    )}
                    <Field
                        label='Season'
                        value={season.value}
                        style={c.fieldWidth}
                        isAmend={season.isAmend}
                    />
                    <Field
                        label='Production Sample Type'
                        value={productionSampleType.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={productionSampleType.isAmend}
                    />
                    <Field
                        label='No. Send To Echo'
                        value={sendToEcho.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={sendToEcho.isAmend}
                    />
                    <Field
                        label='No. Send To Buyer'
                        value={sendToBuyer.value}
                        required={false}
                        style={c.fieldWidth}
                        isAmend={sendToBuyer.isAmend}
                    />
                    {!!systemFactoryNo.value && (
                        <Field
                            label='System Factory No'
                            value={systemFactoryNo.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={systemFactoryNo.isAmend}
                        />
                    )}
                    {!!kimballNo.value && (
                        <Field
                            label='Kimball No'
                            value={kimballNo.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={kimballNo.isAmend}
                        />
                    )}
                    {!!commodity.value && (
                        <Field
                            label='Commodity'
                            value={commodity.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={commodity.isAmend}
                        />
                    )}
                    {!!nlKind.value && (
                        <Field
                            label='Nl Kind'
                            value={nlKind.value}
                            required={false}
                            style={c.fieldWidth}
                            isAmend={nlKind.isAmend}
                        />
                    )}
                    <Field
                        label='Licensor'
                        value={licensor || 'N/A'}
                        required={false}
                        style={c.fieldWidth}
                    />
                </View>
            </View>
        );
    }
);

Details.propTypes = propTypes;

export default Details;
