import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';
import { setSnack } from 'utilities/redux.actions';
import { reportGenerateDate } from '@baseComponents/Date/normalizeDate';

import { usePDFReport } from './hooks';
import { cadDesignerReportPDFData } from 'cadDesignerReport/redux.selectors';

const styles = ({ typography }) => ({
    button: {
        height: '78%',
        fontSize: typography.subtitle1.fontSize
    }
});

const propTypes = {
    canExport: bool.isRequired
};

const mapState = state => ({
    data: cadDesignerReportPDFData(state)
});

const mapDispatch = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Export = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(({ data, canExport, setSnack, classes: c }) => {
    const fileName = `designer-report_${reportGenerateDate()}`;

    const [handleExport, exportLoading, exportDisabled] = usePDFReport({
        fileName,
        props: data,
        setSnack
    });
    return (
        <Button
            className={c.button}
            color='success'
            disabled={!canExport || exportDisabled}
            onClick={handleExport}
            loading={exportLoading}
        >
            Export
        </Button>
    );
});

Export.propTypes = propTypes;

export default Export;
