import React, { useCallback } from 'react';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';

import useManageColumnsContext from '../useManageColumnsContext';

const styles = ({ spacing }) => ({
    button: {
        width: '100%',
        margin: spacing.unit * 2
    }
});

const ManageColumnsModalFooter = withStyles(styles)(({ classes: c }) => {
    const { state } = useManageColumnsContext();
    const { visibleColumns, setVisibleColumns, onClose } = state;

    const handleSave = useCallback(() => {
        setVisibleColumns(visibleColumns);
        onClose();
    }, [visibleColumns, setVisibleColumns, onClose]);

    return (
        <Button
            onClick={handleSave}
            className={c.button}
            disabled={visibleColumns.length === 0}
        >
            Save
        </Button>
    );
});

export default ManageColumnsModalFooter;
