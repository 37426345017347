import React from 'react';

import Item from './Item';
import { Xlsx as XlsxIcon } from '@assets/Icons';

const Xlsx = ({
    xlsxLoading,
    getFileName,
    exportToExcel,
    preparedExportData,
    shouldDisable,
    exportedColumns
}) => {
    const handleDownload = () => {
        return exportToExcel({
            fileName: getFileName('xlsx'),
            data: preparedExportData(exportedColumns, 'xlsx'),
            reportType: 'xlsx'
        });
    };
    return (
        <Item
            icon={XlsxIcon}
            title={'Xlsx'}
            shouldDisable={shouldDisable(xlsxLoading)}
            onClick={handleDownload}
        />
    );
};

export default Xlsx;
