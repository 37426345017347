import { put, call } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { splice } from '@libs/object';
import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';

import { FORM_NAME, INITIAL_VALUES } from '../../User/common';
import { normalizePermissionsMenus } from '../../redux.normalizers';

export default function*(email, isCopy = false) {
    try {
        const {
            data: {
                data: [
                    {
                        menus,
                        retailers,
                        sampleFactories,
                        orderFactories,
                        ...data
                    }
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'User/GetUserDetailsByUser',
            params: {
                email
            }
        });

        const permissions = {
            menus: normalizePermissionsMenus(menus),
            retailers,
            sampleFactories,
            orderFactories
        };

        const values = splice(
            { permissions, hasUserData: true, ...data },
            {
                id: 'id',
                name: 'name',
                email: 'email',
                mobileNo: 'mobileNo',
                roleId: 'role',
                isActive: 'active',
                isCadDesigner: 'cadDesigner',
                isShapeDesigner: 'shapeDesigner',
                reportToId: 'reportTo',
                permissions: 'permissions',
                hasUserData: 'hasUserData'
            }
        );

        yield put(
            initialize(FORM_NAME, { ...INITIAL_VALUES, ...values }, isCopy, {
                updateUnregisteredFields: true,
                keepDirty: isCopy
            })
        );
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(
            setSnack({
                message: `Failed to fetch data ${serverMessage}`,
                type: 'error',
                duration: 6000
            })
        );
    }
}
