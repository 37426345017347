import React from 'react';
import { object } from 'prop-types';
// Material
import { withStyles } from '@material-ui/core';
// Local
import Cell from './Cell';
import { useDipsTableHeader } from './hooks';

const propTypes = {
    data: object.isRequired
};

const styles = ({ palette }) => ({
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        backgroundColor: palette.background.light
    }
});

const Header = withStyles(styles)(({ classes: { header }, data, ...rest }) => {
    const values = useDipsTableHeader(data);
    return (
        <div className={header}>
            {values.map((cell, idx) => (
                <Cell value={cell} key={`dip-header-${idx}`} {...rest} />
            ))}
        </div>
    );
});

Header.propTypes = propTypes;

export default Header;
