import {
    REQUEST_FIRED,
    REQUEST_FINISHED,
    HAS_ERROR,
    RESET_SAMPLING_DATA
} from '../actionTypes/index';

const initialData = {
    xloading: false,
    xtype: [],
    errorMsg: null,
    errorStatus: null
};

export default (state = initialData, action) => {
    let newObj = {};

    switch (action.type) {
        case REQUEST_FIRED:
            let request = action.params;
            newObj['xloading'] = true;
            newObj['xtype'] = state.xtype;
            if (!newObj['xtype'].includes(request.xtype))
                newObj['xtype'].push(request.xtype);
            return Object.assign({}, state, newObj);

        case REQUEST_FINISHED:
            let response = action.params;
            let allTypes = state.xtype;
            let index = allTypes.indexOf(response.xtype);
            if (index > -1) {
                allTypes.splice(index, 1);
            }
            newObj['xtype'] = allTypes;
            if (newObj['xtype'].length === 0) newObj['xloading'] = false;
            return Object.assign({}, state, newObj);

        case HAS_ERROR:
            return Object.assign({}, state, initialData);

        case RESET_SAMPLING_DATA:
            return Object.assign({}, state, initialData);

        default:
            return state;
    }
};
