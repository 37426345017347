import React from 'react';
import PropTypes from 'prop-types';

import { PaginatedSelect } from '@formComponents';
import { DOMAIN_NAME } from '../common';
import { PRINT_SAMPLE_CADS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'cad',
    label: 'Select CAD',
    placeholder: 'Select CAD',
    disabled: false,
    required: false
};

const SelectPrintSampleCads = props => (
    <PaginatedSelect
        {...props}
        domain={DOMAIN_NAME}
        entity={PRINT_SAMPLE_CADS}
    />
);

SelectPrintSampleCads.propTypes = propTypes;
SelectPrintSampleCads.defaultProps = defaultProps;

export { SelectPrintSampleCads as default, SelectPrintSampleCads };
