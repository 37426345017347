import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import BaseImageDnD from '@baseComponents/ImageDnD';
import { composeClsx } from '@libs/materialUI';

// Material
import { withStyles } from '@material-ui/core';

import { withField } from '@libs/reduxForm';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    imageResolver: PropTypes.func
};

const defaultProps = {
    imageResolver: img => img,
    label: 'Image',
    placeholder: 'Click browse or drag in here',
    canDelete: true
};

const styles = ({ spacing }) => ({
    content: {
        height: spacing.unit * 40
    }
});

const ImageDnD = compose(
    withField(),
    withStyles(styles)
)(({ classes, styles, ...props }) => {
    return (
        <BaseImageDnD {...props} styles={composeClsx({ classes, styles })} />
    );
});

ImageDnD.propTypes = propTypes;
ImageDnD.defaultProps = defaultProps;

export { ImageDnD as default, ImageDnD };
