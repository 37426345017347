const COOKIE_NAME = 'bhouse-user';

const matchCookie = () =>
    document.cookie.match(new RegExp(`(^| )${COOKIE_NAME}=([^;]+)`));

const setCookie = (value, expireOn) => {
    let expires = '';
    if (expireOn) {
        const date = new Date(expireOn);
        expires = '; expires=' + date.toUTCString();
    }
    //TODO: during complete auth implementation us this params for security => domain=echotechsys.com;secure;expires=expires


    document.cookie = `${COOKIE_NAME}=${value}`;
};

const getCookie = () => {
    const cookieKey = matchCookie();

    if (cookieKey) {

        return cookieKey[2].trim();

    }
    return null;
};

const eraseCookie = () =>
    matchCookie()
        ? (document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`)
        : false;

export { setCookie, getCookie, eraseCookie };
