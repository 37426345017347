import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { Input } from '@formComponents';
import { roundNumber } from '@libs/number';

import { PO_FORM_NAME } from 'orders/Order/common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'totalFabric',
    label: 'Total Fabric',
    disabled: true,
    required: true
};

const mapState = state => {
    const { orderQuantity = 0, fabricConsumption = 0 } = formValueSelector(
        PO_FORM_NAME
    )(state, 'orderQuantity', 'fabricConsumption');
    const totalFabric =
        roundNumber(Number(fabricConsumption) * orderQuantity) || '';
    return {
        totalFabric,
        staticValue: totalFabric ? `${totalFabric}KG` : ''
    };
};

const mapDispatch = {
    change
};

const TotalFabric = connect(
    mapState,
    mapDispatch
)(({ change, totalFabric, ...props }) => {
    useEffect(() => {
        change(PO_FORM_NAME, 'totalFabric', totalFabric);
    }, [totalFabric]);
    return <Input {...props} />;
});

TotalFabric.propTypes = propTypes;
TotalFabric.defaultProps = defaultProps;

export { TotalFabric as default, TotalFabric };
