import React from 'react';

import SealSample from './SealSample';

import {
    NEW_SEAL_SAMPLE_ROUTE_ID,
    INCOMPLETE_SEAL_SAMPLE_ROUTE_ID,
    AMEND_SEAL_SAMPLE_ROUTE_ID
} from './SealSample/common';

export default {
    [NEW_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample />,
    [INCOMPLETE_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample mode='incomplete' />,
    [AMEND_SEAL_SAMPLE_ROUTE_ID]: () => <SealSample mode='amend' />
};
