import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';

import { Error } from 'components/Message/Error';

// Material
import {
    withStyles,
    InputBase as MuiInputBase,
    InputAdornment,
    FormControl
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

// Local
import styles from './styles';
import { LabelBase, InputLoader } from 'components';
import { composeClasses } from 'helpers';
import { useComputedStyle } from 'hooks';

const LockAdornment = props => (
    <InputAdornment {...props} position='end'>
        <LockIcon color='disabled' />
    </InputAdornment>
);

const InputBase = ({
    classes,
    styles,
    className,
    meta: { form, touched, error },
    fieldName,
    input,
    disabled,
    required,
    label,
    loading,
    ...restProps
}) => {
    const c = composeClasses({ classes, styles });
    const [containerRef, containerPadding] = useComputedStyle('padding-bottom');
    const [inputRef, inputHeight] = useComputedStyle('height');

    const id = `${form}-${fieldName}`;

    const success = !disabled && Boolean(input.value);

    const inputBaseProps = {
        classes: {
            root: classNames(c.root, className, success && c.success),
            ...Object.splice(c, [
                'input',
                'focused',
                'multiline',
                'inputMultiline',
                'disabled'
            ])
        },
        ...input,
        endAdornment: disabled && <LockAdornment className={c.adornment} />,
        disabled: disabled || loading,
        id,
        ref: inputRef,
        ...restProps
    };

    return (
        <FormControl className={c.container} ref={containerRef}>
            {label && (
                <LabelBase
                    label={label}
                    disabled={disabled || loading}
                    required={required}
                    success={success}
                    htmlFor={id}
                />
            )}
            {loading && (
                <InputLoader
                    rootHeight={inputHeight}
                    rootPadding={containerPadding}
                />
            )}
            <MuiInputBase {...inputBaseProps} />
            {touched && error && <Error message={error} />}
        </FormControl>
    );
};

const _InputBase = withStyles(styles)(InputBase);

const __InputBase = props => (
    <Field fieldName={props.name} component={_InputBase} {...props} />
);

export { __InputBase as default, __InputBase as InputBase };
