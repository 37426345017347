import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

// Local
import styles from './styles';
import { SelectBase } from 'components';
import ReactQuill from 'react-quill';

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        ['clean'],
    ],
};

const renderReactQuill = ({ input, ...restProps }) => (
    <ReactQuill {...input} {...restProps} />
);

const ReportsMailFormEditor = ({
    classes: { root, input, select, editor },
    templateDataset,
}) => (
    <div className={root}>
        <SelectBase
            name='template'
            data={templateDataset}
            placeholder='Select template'
            className={select}
        />

        <Field
            name='mail'
            component={renderReactQuill}
            modules={modules}
            className={editor}
        />
    </div>
);

const mapStateToProps = ({
    reports: {
        datasets: { templateDataset },
    },
}) => ({
    templateDataset,
});

const _ReportsMailFormEditor = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(ReportsMailFormEditor);

export {
    _ReportsMailFormEditor as default,
    _ReportsMailFormEditor as ReportsMailFormEditor,
};
