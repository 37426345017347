import { change } from 'redux-form';

import { apiClient } from '@libs/apiRequest';

import { FORM_NAME } from '../../common';

export default async ({ manualReference = '' }, dispatch) => {
    // Responds with 404 on fail
    const checkReference = new Promise(
        async resolve =>
            await apiClient({
                url: 'FabricDevelopment/GetFabricReferenceDuplicateCheck',
                params: {
                    fabricReference: manualReference
                }
            })
                .then(() => resolve(true))
                .catch(() => resolve(false))
    );
    const referenceExists = await checkReference;

    if (referenceExists) {
        throw { manualReference: 'This reference already exists' };
    } else {
        dispatch(change(FORM_NAME, 'manualReferenceAsync', 'Valid'));
    }
};
