import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

import ErrorMessage from '@baseComponents/FormHelperText';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
};

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.unit * 3,
        width: '100%'
    },
    errorMessage: {
        marginTop: 0
    }
});

const Adornment = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing }, loading, error }) => {
        return (
            <div className={c.root}>
                {loading ? (
                    <CircularProgress size={spacing.unit * 4} />
                ) : error ? (
                    <ErrorMessage error={true} className={c.errorMessage}>
                        {error}
                    </ErrorMessage>
                ) : null}
            </div>
        );
    }
);

Adornment.propTypes = propTypes;

export default Adornment;
