import { call, all } from 'redux-saga/effects';
import { fileUploadWorker } from '@libs/sagaWorkers';


export default function*({
    versionBlobs,
    folderPath,
    cadReference,
    sizePaths,
    handleLoading
}) {
    const versionPayloads = versionBlobs.reduce((acm, blob, index) => {
        if (!blob) return acm;
        else
            return [
                ...acm,
                call(function*() {
                    const { file, type } = blob;
                    const imageVersion = `V${index + 1}`;
                    const originalUrl = `${folderPath}/${cadReference}_${imageVersion}.${type}`;

                    const imageUrls = yield call(
                        fileUploadWorker,
                        file,
                        originalUrl,
                        sizePaths
                    );

                    yield call(handleLoading);


                    return { imageUrls, imageVersion };
                })
            ];
    }, []);

    return yield all(versionPayloads);
}
