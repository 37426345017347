import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Local
import { SelectModel } from '@formComponents/SelectModel';

// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { DOMAIN_NAME } from '../../common';
import { FINISHED_GSMS } from '../../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    name: 'finishedGsm',
    label: 'Finish GSM/Gauge',
    domain: DOMAIN_NAME,
    entity: FINISHED_GSMS,
    required: true,
    styles: {}
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data
        .map(({ id, ...datum }) => ({
            label: Object.values(datum)[0],
            value: id
        }))
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { name, domain, entity }) => ({
    // amended: selectAmendedField(state, name),
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectFinishedGSM = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...restProps }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return <SelectModel {...restProps} />;
});

SelectFinishedGSM.propTypes = propTypes;
SelectFinishedGSM.defaultProps = defaultProps;

export { SelectFinishedGSM as default, SelectFinishedGSM };
