import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Material
import { Grid, withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import {
    Checkbox,
    GridItem,
    Input,
    Select,
    SelectModel
} from '@formComponents';

// Local
import { DOMAIN_NAME } from '../../common';
import {
    FABRIC_TYPES,
    COMPOSITIONS,
    DYE_TYPES,
    SPECIAL_FINISHES
} from '../../redux.datasets';
import { fabricForsSelector } from '../../redux.selectors';
import SelectFinishedGSM from './SelectFinishedGSM';

const propTypes = {
    handleSave: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    includeTc: PropTypes.bool
};

const defaultProps = {
    includeTc: false
};

const tcTextData = ['SELECT', 'YES', 'NO'].map(text => ({
    label: text,
    value: text
}));

const mapState = (state, { formName }) => ({
    fabricFors: fabricForsSelector(state, formName)
});

const styles = ({ breakpoints, spacing, typography }) => ({
    content: { width: breakpoints.values.sm },
    button: {
        width: '100%',
        margin: spacing.unit * 2
    },
    checkboxLabel: {
        fontSize: typography.caption.fontSize
    }
});

const Modal = compose(
    connect(mapState),
    reduxForm({
        form: 'fabric',
        onSubmit: (values, dispatch, { handleSave, onClose }) => {
            handleSave(values);
            onClose();
        }
    }),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleSubmit,
        includeTc,
        initialized,
        fabricFors
    }) => {
        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {`${initialized ? 'Update' : 'Add'} fabric details`}
                </ModalHeader>
                <ModalContent className={c.content}>
                    <Grid container>
                        <GridItem md={6}>
                            <SelectModel
                                name='fabricFor'
                                label='Fabric For'
                                data={fabricFors}
                                required
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Select
                                name='fabricType'
                                label='Fabric/Yarn Type'
                                domain={DOMAIN_NAME}
                                entity={FABRIC_TYPES}
                                required
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Select
                                name='composition'
                                label='Composition'
                                domain={DOMAIN_NAME}
                                entity={COMPOSITIONS}
                                required
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <SelectFinishedGSM />
                        </GridItem>
                        <GridItem md={6}>
                            <Select
                                name='dyeType'
                                label='Dye Type'
                                domain={DOMAIN_NAME}
                                entity={DYE_TYPES}
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Select
                                name='specialFinish'
                                label='Finish/Wash'
                                domain={DOMAIN_NAME}
                                entity={SPECIAL_FINISHES}
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Input
                                name='fabricColour'
                                label='Fabric/Yarn Colour'
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Input name='eld' label='ELD' />
                        </GridItem>
                        <GridItem md={6}>
                            <Input
                                name='specialFabricRef'
                                label='Special Fabric Ref'
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Checkbox
                                name='isDischargeable'
                                label='Dischargeable'
                                styles={{ label: c.checkboxLabel }}
                            />
                            <Checkbox
                                name='isEngineeredStripe'
                                label='E/Stripe'
                                styles={{ label: c.checkboxLabel }}
                            />
                            <Checkbox
                                name='isYds'
                                label='YDS'
                                styles={{ label: c.checkboxLabel }}
                            />
                            {includeTc && (
                                <Checkbox
                                    name='isTc'
                                    label='Is TC'
                                    styles={{ label: c.checkboxLabel }}
                                />
                            )}
                        </GridItem>
                        {includeTc && (
                            <GridItem md={6}>
                                <SelectModel
                                    name='tcText'
                                    placeholder='Select TC'
                                    label='TC Text'
                                    data={tcTextData}
                                />
                            </GridItem>
                        )}
                    </Grid>
                </ModalContent>
                <ModalFooter>
                    <Button className={c.button} onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </Fragment>
        );
    }
);

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export { Modal as default, Modal };
