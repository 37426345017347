import React from 'react';
import PropTypes from 'prop-types';

import { SelectModel } from '@formComponents';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'costBasedOn',
    label: 'Cost Based On ',
    placeholder: 'Select..',
    disabled: false,
    required: false
};

const SelectCostBasedOn = props => <SelectModel {...props} />;

SelectCostBasedOn.propTypes = propTypes;
SelectCostBasedOn.defaultProps = defaultProps;

export { SelectCostBasedOn as default, SelectCostBasedOn };
