import React, { useMemo } from 'react';

import { PACKING_BOX_TYPE } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const PackingBoxType = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Box Type', canSort: true, sortKey: 'boxType' },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'PACKING BOX TYPE SETUP',
        domain: DOMAIN_NAME,
        entity: PACKING_BOX_TYPE,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { PackingBoxType as default, PackingBoxType };
