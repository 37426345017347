import React, { useCallback } from 'react';

// Material
import { Typography, withStyles } from '@material-ui/core';
// Libs
import useConfirmDelete from '@libHooks/useConfirmDelete';
// Local
import { useAttachmentDetailsDispatch } from '../useAttachmentDetails';
import DownloadAll from './DownloadAll/';
import DeleteAll from './DeleteAll';

const styles = ({ spacing, typography }) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 0.5
    },
    headers: {
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        height: '100%'
    },
    imageHeader: {
        width: spacing.unit * 7,
        fontWeight: typography.fontWeightSemi,
        marginRight: spacing.unit * 2
    },
    filenameHeader: {
        fontWeight: typography.fontWeightSemi
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '50%',
        height: '100%'
    }
});

const Adornment = withStyles(styles)(({ classes: c }) => {
    const dispatch = useAttachmentDetailsDispatch();
    const handleDeleteAll = useCallback(() => dispatch({ type: 'removeAll' }), [
        dispatch
    ]);
    const [renderConfirmDialog, handleConfirmDelete] = useConfirmDelete(
        handleDeleteAll
    );

    return (
        <div className={c.root}>
            <div className={c.headers}>
                <Typography className={c.imageHeader}>Image</Typography>
                <Typography className={c.filenameHeader}>File name</Typography>
            </div>
            <div className={c.actions}>
                <DownloadAll />
                <DeleteAll onClick={handleConfirmDelete} />
            </div>
            {renderConfirmDialog}
        </div>
    );
});

export default Adornment;
