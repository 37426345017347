import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import SelectModel from '@formComponents/SelectModel';

// Local
import { DOMAIN_NAME } from 'sampling/common';
import { COUNTRIES } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: COUNTRIES,
    disabled: false,
    required: true
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data
        .filter(({ isActive, isDispatch }) => isActive && isDispatch)
        .map(({ id, ...datum }) => ({
            label: Object.values(datum)[0],
            value: id
        }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectDispatchCountry = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectDispatchCountry.propTypes = propTypes;
SelectDispatchCountry.defaultProps = defaultProps;

export default SelectDispatchCountry;
