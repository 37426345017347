import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import SelectModel from '@formComponents/SelectModel';

// Local
import { DOMAIN_NAME } from 'orders/common';
import { PAYMENT_TERMS } from 'orders/redux.datasets';
import { paymentAndSettlementDeafaultValueLoadingSelector } from 'orders/redux.selectors';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'paymentTerms',
    label: 'Payment Terms',
    placeholder: 'Select Terms',
    domain: DOMAIN_NAME,
    entity: PAYMENT_TERMS,
    disabled: false,
    required: false
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data.map(({ id, ...datum }) => ({
        label: Object.values(datum)[0],
        value: id
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => {
    const isDefaultValueLoading = paymentAndSettlementDeafaultValueLoadingSelector(
        state
    );
    return {
        data: dataSelector(state, domain, entity),
        loading:
            isDefaultValueLoading ||
            datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectPaymentTerms = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectPaymentTerms.propTypes = propTypes;
SelectPaymentTerms.defaultProps = defaultProps;

export { SelectPaymentTerms as default, SelectPaymentTerms };
