import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogToolbarClear
 */
const PaginatedDialogToolbarClear = ({ classes, dialogDispatch }) => {
    const handleClick = () => dialogDispatch({ type: 'clear' });
    return (
        <Button classes={classes} onClick={handleClick}>
            Clear
        </Button>
    );
};

const _PaginatedDialogToolbarClear = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogToolbarClear);

export {
    _PaginatedDialogToolbarClear as default,
    _PaginatedDialogToolbarClear as PaginatedDialogToolbarClear,
};
