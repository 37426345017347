import React from 'react';

import { withStyles } from '@material-ui/core';

import { EditIcon } from '@assets/Icons';
import Tooltip from '@libComponents/Tooltip';

const styles = ({ spacing }) => ({
    gridColumnEditIndicatorRoot: {
        marginRight: spacing.unit * 0.5
    },
    gridColumnEditIndicatorIcon: {
        width: spacing.unit * 1.5,
        height: spacing.unit * 1.5,
        cursor: 'auto',
        opacity: 0.5
    }
});

const EditableColumnIndicator = withStyles(styles)(({ classes }) => {
    const handleIndicatorClick = e => {
        e.stopPropagation();
    };
    return (
        <div
            className={classes.gridColumnEditIndicatorRoot}
            onClick={handleIndicatorClick}
        >
            <Tooltip title='This column is editable'>
                <EditIcon className={classes.gridColumnEditIndicatorIcon} />
            </Tooltip>
        </div>
    );
});

export default EditableColumnIndicator;
