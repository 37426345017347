import { startSubmit } from 'redux-form';
import { put, call } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';

import { DOMAIN_NAME } from 'orders/common';
import { ORDER_STATUS } from 'orders/redux.datasets';

import { FORM_NAME } from '../../Order/Status/common';
import { statusSchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const publishData = composeApiPayload(payload, statusSchema);

        const { data } = yield call(apiRequest, {
            url: 'orderStatus',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: ORDER_STATUS,
            isNew: id === 0,
            data
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Status',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
