import React from 'react';

import { withStyles, Dialog, DialogTitle } from '@material-ui/core';

import DialogContent from './DialogContent';
import DialogActions from './DialogActions';

const styles = () => ({
    container: {
        fontFamily: 'Museo'
    }
});

const ConfirmationDialog = withStyles(styles)(
    React.memo(
        ({
            classes: c,
            open,
            onClose,
            onConfirm,
            mutationText,
            loading,
            updatingRowId
        }) => {
            return (
                <Dialog
                    maxWidth='sm'
                    open={open}
                    onClose={onClose}
                    className={c.container}
                    disableBackdropClick
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent
                        mutationText={mutationText}
                        updatingRowId={updatingRowId}
                    />
                    <DialogActions
                        onClose={onClose}
                        onConfirm={onConfirm}
                        loading={loading}
                    />
                </Dialog>
            );
        }
    )
);

export default ConfirmationDialog;
