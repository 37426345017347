import { LoginBg, IconTextLogo } from 'assets';

export default ({ breakpoints, palette, spacing }) => ({
    containerWrappper: {
        display: 'flex',
        backgroundImage: `url(${LoginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    containerLeftColumn: {
        flexGrow: 1,
        Height: '100vh',
        backgroundImage: `url(${IconTextLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [breakpoints.down(940)]: {
            backgroundSize: 220
        }
    },
    containerRightColumn: {
        width: 480,
        minHeight: '100vh',
        background: '#fff',
        boxShadow: '0px 3px 62px 0px rgba(0, 4, 52, 0.19)',
        display: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
        [breakpoints.down(770)]: {
            width: '100vw'
        }
    },
    containerRightColumnContents: {
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
        flexDirection: 'column'
    },
    rightLogo: {
        display: 'block',
        margin: '50px auto '
    },
    containerTitle: {
        textAlign: 'center',
        marginBottom: 50
    }
});
