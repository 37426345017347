import React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';

const PDFConverter = (html, fontBold, fontItalic) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const processNode = (node, index) => {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const tagName = node.tagName.toLowerCase();

            switch (tagName) {
                case 'p':
                    return (
                        <Text
                            key={index}
                            style={{
                                fontSize: 10,
                                lineHeight: 1.2
                                //fontFamily: fontNormal
                            }}
                        >
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'h1':
                    return (
                        <Text key={index} style={{ fontSize: 16 }}>
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'h2':
                    return (
                        <Text key={index} style={{ fontSize: 14 }}>
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'h3':
                    return (
                        <Text key={index} style={{ fontSize: 12 }}>
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'ul':
                    return <View key={index}>{processList(node, 'disc')}</View>;

                case 'ol':
                    return (
                        <View key={index}>{processList(node, 'decimal')}</View>
                    );

                case 'strong':
                case 'u':
                    return (
                        <Text key={index} style={{ fontFamily: fontBold }}>
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'em':
                    return (
                        <Text key={index} style={{ fontFamily: fontItalic }}>
                            {processChildNodes(node)}
                        </Text>
                    );

                case 'a':
                    return (
                        <Link key={index} src={node.getAttribute('href')}>
                            {processChildNodes(node)}
                        </Link>
                    );

                case 'br':
                    return <Text key={index}>{'\n'}</Text>;

                default:
                    // Handle unknown tags
                    return (
                        <Text
                            key={index}
                            style={{
                                fontSize: 12,
                                lineHeight: 1.2
                            }}
                        >
                            N/A
                        </Text>
                    );
            }
        }
    };

    const processChildNodes = (parentNode, isFirstNode = false) => {
        const childNodes = Array.from(parentNode.childNodes);
        return childNodes.map((childNode, index) => {
            if (childNode.nodeType === Node.TEXT_NODE) {
                if (isFirstNode) {
                    return (
                        <Text
                            key={index}
                            style={{
                                fontSize: 10,
                                lineHeight: 1.2
                            }}
                        >
                            {childNode.textContent}
                        </Text>
                    );
                }
                return childNode.textContent;
            } else {
                return processNode(childNode, index);
            }
        });
    };

    const processList = (listNode, listType) => {
        const listItems = Array.from(listNode.childNodes).map(
            (childNode, index) => {
                const listStyle =
                    listType === 'decimal' ? `${index + 1}.` : '\u2022';
                return (
                    <Text
                        key={index}
                        style={{
                            fontSize: 12,
                            marginBottom: 2,
                            lineHeight: 1.2
                        }}
                    >
                        {`${listStyle} `}
                        {processChildNodes(childNode)}
                    </Text>
                );
            }
        );
        return listItems;
    };

    return processChildNodes(doc.body, true);
};

export default PDFConverter;
