export const normalizeSignIn = payload => {
    const UserInfoMap = {
        emailAddress: 'userEmail',
        isNeedChangePass: 'changePassword',
        isshapedesigner: 'isShapeDesigner',
        menus: 'menus',
        phoneNo: 'phoneNo',
        shapedesigncode: 'shapeDesignCode',
        userid: 'userId',
        username: 'userName'
    };

    const NormalizeUserInfo = Object.splice(payload, UserInfoMap);



    NormalizeUserInfo.menus = NormalizeUserInfo.menus
        // .filter(({ wbMenuId, wbCanView }) => wbCanView && wbMenuId !== 125)
        .filter(({ wbCanView }) => !!wbCanView)
        .map(
            ({
                slno,
                wbMenuId,
                wbMenuName = '',
                wbParentId,
                wbCanDelete,
                wbCanEdit,
                wbCanPermission,
                wbCanView,
                groupId
            }) => ({
                menuId: wbMenuId,
                parentId: wbParentId,
                menuGroupId: groupId,
                menuName: wbMenuName
                    .split(' ')
                    .map(
                        word =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                    )
                    .join(' '),
                serial: slno,
                canDelete: wbCanDelete,
                canEdit: wbCanEdit,
                canPermission: wbCanPermission,
                canView: wbCanView
            })
        );

    return NormalizeUserInfo;
};
