import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { makeSideEffectCallback } from '@libs/makeContext';
import { withField } from '@libs/reduxForm';

// Local
import {
    userPermissionsLoadingSelector,
    userPermissionsDataSelector,
    copyUserPermissionsDataLoadingSelector
} from 'administrator/redux.selectors';
import {
    RolePermissionsProvider,
    initializeUserPermission
} from './useRolePermissions';
import PermissionTabs from './PermissionTabs';
import Adornment from './Adornment';
import permissionValidator from './permissionValidator';

const defaultProps = {
    name: 'permissions',
    required: true,
    validate: permissionValidator
};

const mapState = state => ({
    loading:
        userPermissionsLoadingSelector(state) ||
        copyUserPermissionsDataLoadingSelector(state),
    data: userPermissionsDataSelector(state)
});

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        marginTop: spacing.unit * 1.5
    }
});

const RolePermissionsContainer = compose(
    connect(mapState),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        loading,
        input: { value, onChange },
        meta: { form, touched, error },
        fieldName,
        required,
        data
    }) => {
        const permissionFieldId = `${form}-${fieldName}`;
        const initialize = useCallback(
            state => ({
                ...state,
                ...initializeUserPermission(value, data)
            }),
            [value, data]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        return (
            <RolePermissionsProvider
                initialize={initialize}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Role Permission'
                    AdornmentComponent={
                        <Adornment
                            loading={loading}
                            error={touched && error ? error : ''}
                        />
                    }
                    styles={{ container: c.container }}
                    id={permissionFieldId}
                    required={required}
                >
                    <PermissionTabs />
                </ContentContainer>
            </RolePermissionsProvider>
        );
    }
);

RolePermissionsContainer.defaultProps = defaultProps;

export { RolePermissionsContainer as default, RolePermissionsContainer };
