import { takeLatest, put } from 'redux-saga/effects';

// Action Types
import {
	SET_NOTIFICATION,
	SET_NOTIFICATION_SUCCESS,
	RESET_NOTIFICATION,
	RESET_NOTIFICATION_SUCCESS
} from 'actionTypes';

// Normalizer
import { normalizeResponse } from 'normalizers';

// Workers
const setNotificationWorker = function*({
	response = null,
	message = null,
	variant = null
}) {
	let filteredResponse = {};
	if (response && !message) filteredResponse = normalizeResponse(response);

	const payload = {
		message,
		variant,
		...filteredResponse
	};

	try {
		yield put({
			type: SET_NOTIFICATION_SUCCESS,
			payload
		});
	} catch (err) {}
};

const resetNotificationWorker = function*() {
	try {
		yield put({
			type: RESET_NOTIFICATION_SUCCESS
		});
	} catch (err) {}
};

// Watchers
const notificationWatcher = function*() {
	yield takeLatest(SET_NOTIFICATION, setNotificationWorker);
	yield takeLatest(RESET_NOTIFICATION, resetNotificationWorker);
};

export { notificationWatcher as default, notificationWatcher };
