export const TRIM_SUPPLIERS = '@trimSuppliers';
export const TRIM_TYPES = '@trimTypes';

export default {
    [TRIM_SUPPLIERS]: {
        url: 'TrimSupplier'
    },
    [TRIM_TYPES]: {
        url: 'TrimType'
    }
};
