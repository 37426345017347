export default ({ palette, spacing }) => ({
    root: {
        borderTop: '1px solid',
        borderColor: palette.grey[400],
        borderRadius: 0,
        height: spacing.unit * 5,
        textTransform: 'none',
        color: palette.grey[700],
    },
});
