import { API_BASE_URL, apiClient } from 'store/config';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    setSubmitSucceeded,
    startSubmit,
    stopSubmit,
    initialize,
    formValueSelector
} from 'redux-form';

// Local
import { apiRequest } from 'helpers';

// Action Types
import {
    FETCH_COPY_SAMPLE_REF_REQUEST,
    FETCH_COPY_SAMPLE_REF_SUCCESS,
    REQUEST_FORM_SUBMIT,
    REQUEST_FORM_SUBMIT_SUCCEEDED,
    FETCH_SAMPLE_INFO_REQUEST
} from 'actionTypes';

//api calling
async function getCopySampleRefList(
    { id, type, searchKey = '', pageNo = 1 },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_COPY_SAMPLE_REF_REQUEST;




    return await apiClient.get(
        API_BASE_URL +
            `api/SampleRequestMains/GetCopySampleReference/?userId=${id}&producttype=${type}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}`
    );
}

//workers
function* getCopySampleRefListWorker({ payload }) {
    const state = yield select();
    const selector = formValueSelector('SamplingRequestForm');
    const data = {
        id: state.auth.userId,
        type: selector(state, 'productType'),
        ...payload
    };
    const res = yield call(getCopySampleRefList, data);


    yield put({
        type: FETCH_COPY_SAMPLE_REF_SUCCESS,
        payload: res.data
    });
}


//workers
function* fetchCopySample({ payload }) {
    const state = yield select();
    const selector = formValueSelector('SamplingRequestForm');
    const data = {
        id: state.auth.userId,
        type: selector(state, 'productType'),
        ...payload
    };
    const res = yield call(getCopySampleRefList, data);


    yield put({
        type: FETCH_COPY_SAMPLE_REF_SUCCESS,
        payload: res.data
    });
}


function* submitFirstFormWorker({
    payload,
    payload: { copySampleReference, departmentId, productType }
}) {
    try {
        const createdBy = yield select(state => state.auth.userName);
        yield put(setSubmitSucceeded('SamplingRequestForm'));
        // yield put(startSubmit('SamplingRequestForm'));
        const {
            data: {
                data: {
                    sampleReferenceAutoId,
                    sampleReference,
                    buyerName,
                    developmentId,
                    factoryId,
                    retailer,
                    designerFrom
                }
            }
        } = yield call(apiRequest, {
            url: '/api/SampleRequestMains/NewSampleSave',
            method: 'post',
            data: { ...payload, createdBy }
        });

        // yield put(stopSubmit('SamplingRequestForm'));
        yield put(setSubmitSucceeded('SamplingRequestForm'));

        // Todo: Remove when refactoring multi-stepper
        yield put({ type: REQUEST_FORM_SUBMIT_SUCCEEDED });

        if (copySampleReference) {
            yield put({
                type: FETCH_SAMPLE_INFO_REQUEST,
                payload: { ref: copySampleReference }
            });
        }

        yield put(
            initialize('SamplingPrintForm', {
                sampleReferenceAutoId,
                sampleReference,
                buyerName,
                developmentId,
                factoryId,
                // Ghost
                designerFrom,
                departmentId,
                copySampleReference,
                productType,
                retailerId: retailer
            })
        );
    } catch (err) {
        yield put(stopSubmit('SamplingRequestForm'));
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const requestWatchers = function*() {
    yield takeLatest(FETCH_COPY_SAMPLE_REF_REQUEST, getCopySampleRefListWorker);
    yield takeLatest(REQUEST_FORM_SUBMIT, submitFirstFormWorker);
};

export { requestWatchers as default, requestWatchers };
