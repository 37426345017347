import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { compose } from 'redux';

// Material
import { FormControl, withStyles } from '@material-ui/core';

// Libs
import { composeClsx } from '@libs/materialUI';
import Label from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';

import Upload from './Upload';

// Selectors
// import { selectAmendedField } from 'selectors';

const propTypes = {
    uploadConstraints: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

const defaultProps = {
    uploadConstraints: {
        helperText: '*PDF,IMAGE,XL ONLY / MAX 15MB',
        accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*'
    },
    name: 'file',
    label: 'File',
    placeholder: 'Upload File'
};

const styles = ({ palette, spacing }) => ({
    root: {
        height: spacing.unitHeight
    },
    container: {
        width: '100%',
        paddingBottom: spacing.unitPadding
    },
    amendRoot: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: palette.amend.dark
    }
});

const FileUpload = compose(
    withField(),
    withStyles(styles)
)(
    ({
        classes,
        className,
        placeholder,
        amended,
        styles,
        form,
        fieldName,
        loading,
        disabled,
        label,
        required,

        ...restProps
    }) => {
        const { root, amendRoot, amendDisabled, container, ...c } = composeClsx(
            {
                classes,
                styles
            }
        );

        const id = `${form}-${fieldName}`;

        const props = {
            styles: {
                ...c,
                root: clsx(root, className, amended && amendRoot),
                disabled: clsx(c.disabled, amended && amendDisabled)
            },
            disabled: disabled || loading,
            placeholder: placeholder || restProps.label || '',
            id,
            ...restProps
        };

        return (
            <FormControl className={container}>
                {label && (
                    <Label
                        label={label}
                        disabled={disabled || loading}
                        required={required}
                        htmlFor={id}
                    />
                )}
                <Upload {...props} />
            </FormControl>
        );
    }
);

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export { FileUpload as default, FileUpload };
