import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import { useFinanceReport } from './hooks';
import Card from '../Card';
import CardSM from '../CardSM';

const styles = ({ spacing, palette }) => ({
    row: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },

    column: {
        padding: `${spacing.unit * 4}px ${spacing.unit * 1.5}px`,
        '&:not(:last-child)': {
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: palette.background.dark
        }
    },

    children: {
        marginTop: spacing.unit * 1.25,
        display: 'flex',
        gap: `${spacing.unit * 1.5}px`
    }
});

const propTypes = {
    data: array.isRequired
};

const FinanceCard = withStyles(styles)(({ classes: c, data }) => {
    const { firstColumn, secondColumn } = useFinanceReport(data);
    return (
        <div className={c.row}>
            <div className={c.column}>
                <Card {...firstColumn[0]} />
                <div className={c.children}>
                    {firstColumn.slice(1, 4).map((child, idx) => (
                        <CardSM {...child} key={`column-child-${idx}`} />
                    ))}
                </div>
            </div>
            <div className={c.column}>
                <Card {...secondColumn[0]} />
                <div className={c.children}>
                    {secondColumn.slice(1, 4).map((child, idx) => (
                        <CardSM {...child} key={`column-child-${idx}`} />
                    ))}
                </div>
            </div>
        </div>
    );
});

FinanceCard.propTypes = propTypes;

export { FinanceCard as default, FinanceCard };
