import { takeLatest } from 'redux-saga/effects';

import {
    ADD_PO_SIZE_SET,
    ADD_ORDER_EMAIL_BODY,
    ADD_ORDER_EMAIL_GROUP,
    ADD_ORDER_FACTORY,
    ADD_ORDER_RETAILER_FACTORY,
    ADD_TRIM_LABEL_TYPE,
    ADD_TRIM_LABEL,
    ADD_PACKING_PACK_TYPE,
    ADD_SEASON,
    ADD_COLOUR,
    ADD_PO_SHIP_MODE,
    ADD_PO_SHIP_DESTINATION,
    ADD_PO_TYPE,
    ADD_PACKING_BOX_TYPE,
    ADD_ORDER_NOTE,
    ADD_ORDER_STATUS
} from 'setup/redux.actionTypes';

import POSizeSetWorker from './POSizeSetWorker';
import orderMailBodyWorker from './orderMailBodyWorker';
import orderMailGroupWorker from './orderMailGroupWorker';
import orderFactoryWorker from './orderFactoryWorker';
import orderRetailerFactoryWorker from './orderRetailerFactoryWorker';
import trimLabelTypeWorker from './trimLabelTypeWorker';
import trimLabelWorker from './trimLabelWorker';
import packingPackTypeWorker from './packingPackTypeWorker';
import seasonWorker from './seasonWorker';
import colourWorker from './colourWorker';
import POShipModeWorker from './POShipModeWorker';
import POShipDestinationWorker from './POShipDestinationWorker';
import POTypeWorker from './POTypeWorker';
import packingBoxTypeWorker from './packingBoxTypeWorker';
import orderNoteWorker from './orderNoteWorker';
import statusWorker from './statusWorker';

// Watchers
export default function*() {
    yield takeLatest(ADD_PO_SIZE_SET, POSizeSetWorker);
    yield takeLatest(ADD_ORDER_EMAIL_BODY, orderMailBodyWorker);
    yield takeLatest(ADD_ORDER_EMAIL_GROUP, orderMailGroupWorker);
    yield takeLatest(ADD_ORDER_FACTORY, orderFactoryWorker);
    yield takeLatest(ADD_ORDER_RETAILER_FACTORY, orderRetailerFactoryWorker);
    yield takeLatest(ADD_TRIM_LABEL_TYPE, trimLabelTypeWorker);
    yield takeLatest(ADD_TRIM_LABEL, trimLabelWorker);
    yield takeLatest(ADD_PACKING_PACK_TYPE, packingPackTypeWorker);
    yield takeLatest(ADD_SEASON, seasonWorker);
    yield takeLatest(ADD_COLOUR, colourWorker);
    yield takeLatest(ADD_PO_SHIP_MODE, POShipModeWorker);
    yield takeLatest(ADD_PO_SHIP_DESTINATION, POShipDestinationWorker);
    yield takeLatest(ADD_PO_TYPE, POTypeWorker);
    yield takeLatest(ADD_PACKING_BOX_TYPE, packingBoxTypeWorker);
    yield takeLatest(ADD_ORDER_NOTE, orderNoteWorker);
    yield takeLatest(ADD_ORDER_STATUS, statusWorker);
}
