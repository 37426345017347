import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SelectModel } from '@formComponents';
import {
    versionsDataSelector,
    previewLoadingSelector
} from '../../../redux.selectors';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../common';

const propTypes = {
    handleHover: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'version',
    label: 'Version no',
    placeholder: 'Select version',
    disabled: false,
    required: false
};

const mapState = state => ({
    data: versionsDataSelector(state),
    disabled: !formValueSelector(FORM_NAME)(state, 'cad'),
    loading: previewLoadingSelector(state)
});

const SelectVersions = connect(mapState)(({ handleHover, ...props }) => {
    return <SelectModel onChangeHover={handleHover} {...props} />;
});

SelectVersions.propTypes = propTypes;
SelectVersions.defaultProps = defaultProps;

export default SelectVersions;
