import { useMemo } from 'react';

const useFabricDetails = details =>
    useMemo(() => {
        return Object.entries(details)
            .map(([label, value]) => {
                switch (label) {
                    case 'fabricFor':
                        return {
                            label: 'Fabric For',
                            value: !!value ? value : 'N/A',
                            order: 0
                        };
                    case 'fabricType':
                        return {
                            label: 'Fabric Type',
                            value: !!value ? value : 'N/A',
                            order: 1
                        };
                    case 'composition':
                        return {
                            label: 'Composition',
                            value: !!value ? value : 'N/A',
                            order: 2
                        };
                    case 'gsm':
                        return {
                            label: 'GSM',
                            value: !!value ? value : 'N/A',
                            order: 3
                        };
                    case 'dyeType':
                        return {
                            label: 'Dye Type',
                            value: !!value ? value : 'N/A',
                            order: 4
                        };
                    case 'specialFabricRef':
                        return {
                            label: 'Special Fabric Ref',
                            value: !!value ? value : 'N/A',
                            order: 5
                        };
                    case 'specialFinish':
                        return {
                            label: 'Special Finish',
                            value: !!value ? value : 'N/A',
                            order: 6
                        };
                    case 'fabricColour':
                        return {
                            label: 'Fabric Colour',
                            value: !!value ? value : 'N/A',
                            order: 7
                        };
                    case 'eld':
                        return {
                            label: 'ELD',
                            value: !!value ? value : 'N/A',
                            order: 8
                        };
                    case 'isYds':
                        return {
                            label: 'Is YDS',
                            value: !!value ? 'Yes' : 'No',
                            order: 9
                        };
                    case 'istc':
                        return {
                            label: 'Is TC',
                            value: !!value ? 'Yes' : 'No',
                            order: 10
                        };
                    case 'fabFrom':
                        return {
                            label: 'Fab From',
                            value: !!value ? value : 'N/A',
                            order: 11
                        };
                    default:
                        return null;
                }
            })
            .filter(details => !!details)
            .sort((a, b) => a.order - b.order)
            .map(({ order, ...detail }) => detail);
    }, [details]);

export { useFabricDetails };
