import React from 'react';
import PropTypes from 'prop-types';

import { Date as DateInput } from '@formComponents';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const RequestedDate = props => {
    return (
        <DateInput
            name='dateRequested'
            label='Date requested'
            disabled
            required
            {...props}
        />
    );
};

RequestedDate.propTypes = propTypes;

export { RequestedDate as default, RequestedDate };
