import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { BlobProvider } from '@react-pdf/renderer';

import { usePDFContext } from './Provider';

const BlobUrl = () => {
    const [
        { document: Document, props, fileName, loadProps },
        dispatch
    ] = usePDFContext();
    const [propsReady, setPropsReady] = useState(false);
    const [loadedProps, setLoadedProps] = useState(null);
    const [blobPayload, setBlobPayload] = useState({});
    const [updateCount, setUpdateCount] = useState(0);

    const handleUpdate = useCallback(() => setUpdateCount(updateCount + 1), [
        updateCount
    ]);

    const handleLoadProps = useCallback(async () => {
        try {
            const loadedProps = await loadProps(props);
            setLoadedProps(loadedProps);
            setPropsReady(true);
        } catch {
            dispatch({ type: 'removeDocument', payload: true });
        }
    }, [props, loadProps, setLoadedProps, setPropsReady, dispatch]);

    useEffect(() => {
        if (Document !== null) handleLoadProps();
    }, [Document, handleLoadProps]);

    useEffect(() => {
        let timeout = null;
        const { url: blobUrl, loading } = blobPayload;
        if (!loading) {
            timeout = setTimeout(() => {
                dispatch({
                    type: 'setBlobURL',
                    payload: {
                        url: blobUrl,
                        filename: fileName
                    }
                });
                handleUpdate();
            }, 250);
        }
        return () => clearTimeout(timeout);
    }, [blobPayload]);
    return propsReady && Document ? (
        <BlobProvider document={<Document {...loadedProps} />}>
            {blobPayload => {
                setTimeout(() => setBlobPayload(blobPayload), 1);
                return <Fragment />;
            }}
        </BlobProvider>
    ) : (
        <Fragment />
    );
};

export default BlobUrl;
