import React, { memo } from 'react';
import { withStyles, Typography } from '@material-ui/core';

import GridSelect from '../../../GridSelect';

const styles = ({ spacing }) => ({
    gridPageSizeControl: {
        minWidth: spacing.unit * 12,
        height: spacing.unitHeight,
        backgroundColor: '#fff'
    },
    gridPageSizeWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    gridPageSizeLabel: {
        fontSize: spacing.unit * 1.63,
        textWrap: 'nowrap',
        marginRight: spacing.unit * 1.25
    },
    gridPageSizeDropdownIcon: {
        transform: 'scale(1.1)'
    }
});

const SelectPageSize = withStyles(styles)(
    memo(({ pageSize, setPageSize, data, classes }) => {
        const {
            gridPageSizeWrapper,
            gridPageSizeLabel,
            gridPageSizeControl,
            gridPageSizeDropdownIcon
        } = classes;

        const selectProps = {
            name: 'pageSize',
            data: data.map(value => ({
                label: value,
                value
            })),
            handleOnChange: value => setPageSize(value),
            value: pageSize,
            isCancellable: false,
            showSuccess: false,
            listMaxNumber: 3,
            styles: {
                control: gridPageSizeControl,
                dropdownIcon: gridPageSizeDropdownIcon
            }
        };
        return (
            <div className={gridPageSizeWrapper}>
                <Typography className={gridPageSizeLabel}>Page Size</Typography>
                <GridSelect {...selectProps} />
            </div>
        );
    })
);

export default SelectPageSize;
