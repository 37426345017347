import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets
} from '@libs/datasets';
import { Input } from '@formComponents';

import { DOMAIN_NAME } from '../common';
import { SHAPE_DESIGNERS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'shapeDesigner',
    label: 'Shape designer',
    placeholder: ' ',
    disabled: true,
    required: true
};

const mapState = (state, { form }) => {
    const shapeDesigner = formValueSelector(form)(state, 'shapeDesigner');
    return {
        staticValue: valueSelector(
            state,
            DOMAIN_NAME,
            SHAPE_DESIGNERS,
            shapeDesigner
        ),
        loading: datasetLoadingSelector(state, DOMAIN_NAME, SHAPE_DESIGNERS)
    };
};

const mapDispatch = {
    fetchDatasets
};

const ShapeDesigner = connect(
    mapState,
    mapDispatch
)(({ fetchDatasets, ...props }) => {
    useEffect(() => {
        fetchDatasets(SHAPE_DESIGNERS);
    }, []);

    return <Input {...props} />;
});

ShapeDesigner.propTypes = propTypes;
ShapeDesigner.defaultProps = defaultProps;

export { ShapeDesigner as default, ShapeDesigner };
