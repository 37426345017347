import PropTypes from 'prop-types';

// Define separate PropTypes object

const columnPropTypes = PropTypes.shape({
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    headerAlign: PropTypes.oneOf(['left', 'center', 'right']),
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    render: PropTypes.func,
    showColumnMenuTool: PropTypes.bool,
    visible: PropTypes.bool,
    sortable: PropTypes.bool,
    draggable: PropTypes.bool,
    filterable: PropTypes.bool,
    freezable: PropTypes.bool,
    hideable: PropTypes.bool,
    editable: PropTypes.bool,
    isCellEditable: PropTypes.func,
    defaultWidth: PropTypes.number,
    minWidth: PropTypes.number,
    dateFormat: PropTypes.string,
    domain: function(props, propName, componentName) {
        if (props.type === 'select') {
            if (!props[propName]) {
                return new Error(
                    `The prop '${propName}' is required for column '${props.name}' in '${componentName}' when type is select.`
                );
            }
        }
        return null;
    },
    entity: function(props, propName, componentName) {
        if (props.type === 'select') {
            if (!props[propName]) {
                return new Error(
                    `The prop '${propName}' is required for column '${props.name}' in '${componentName}' when type is select.`
                );
            }
        }
        return null;
    }
});

export default columnPropTypes;
