import React, { createContext, useContext, useReducer } from 'react';
import PDFDownloader from './PDFDownloader';
import BlobUrl from './BlobUrl';

const StateContext = createContext({ loading: true });
const DispatchContext = createContext(action => action);

const usePDFContext = () => [
    useContext(StateContext),
    useContext(DispatchContext)
];

const initialState = {
    loadingId: null,
    document: null,
    fileName: null,
    props: {},
    loadProps: async props => props,
    errorResolver: error => console.log('Error downloading PDF: ', error),
    withDownload: null,
    blobUrl: null,
    report: {}
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'setDocument': {
            const {
                id,
                document,
                fileName,
                props,
                loadProps,
                errorResolver,
                withDownload,
                blobUrl
            } = payload;
            return {
                ...state,
                document,
                fileName,
                loadingId: id,
                props: props !== null ? props : initialState.props,
                loadProps:
                    loadProps !== null ? loadProps : initialState.loadProps,
                errorResolver:
                    errorResolver !== null
                        ? errorResolver
                        : initialState.resolveError,
                withDownload,
                blobUrl
            };
        }
        case 'removeDocument': {
            const { report: { url } = {} } = state;
            URL.revokeObjectURL(url);
            payload && state.errorResolver('Failed to generate PDF');
            return initialState;
        }
        case 'setBlobURL': {
            return {
                ...state,
                report: payload
            };
        }
        default:
            return state;
    }
};

const PDFProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { withDownload, blobUrl } = state;
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {withDownload && <PDFDownloader />}
                {blobUrl && <BlobUrl />}
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export { PDFProvider as default, usePDFContext };
