import { put, call } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { renameProperties } from '@libs/object';
import { normalizeDate } from '@baseComponents/Date';
import { splice } from '@libs/object';

import { FORM_NAME, INITIAL_VALUES } from 'sampling/SampleDispatch/common';

export default function*(dispatchRef) {
    const {
        data: {
            data: [{ actSentDate, eta, dispatchDetails, ...data } = {}] = []
        } = {}
    } = yield call(apiRequest, {
        url: 'SampleDispatches/GetSampleDispatchByRef',
        params: {
            dispatchRef
        }
    });

    const renamedData = renameProperties(data, {
        retailerId: 'retailer',
        originCountryId: 'originCountry',
        destinationCountryId: 'destinationCountry',
        dispatchRef: 'dispatchReference',
        notifyFAO: 'fao',
        currierId: 'currier'
    });

    const normalizedDispatchDetails = dispatchDetails.map(
        ({
            typeId,
            sizeId,
            factoryId,
            seasonId,
            image,
            pos = [],
            ...restDetails
        }) => {
            return splice(
                {
                    ...restDetails,
                    dispatchType: typeId,
                    size: sizeId,
                    factory: factoryId,
                    season: seasonId,
                    sampleImage: image,
                    PONumbers: pos.map(({ poNo }) => poNo)
                },
                {
                    id: 'id',
                    dispatchType: 'dispatchType',
                    orderNo: 'orderNo',
                    sampleRef: 'sampleRef',
                    gender: 'gender',
                    department: 'department',
                    sampleImage: 'sampleImage',
                    colour: 'colour',
                    PONumbers: 'PONumbers',
                    size: 'size',
                    quantity: 'quantity',
                    factory: 'factory',
                    season: 'season',
                    fabricGroup: 'fabricGroup',
                    designer: 'designer',
                    remarks: 'remarks',
                    description: 'description',
                    fabricType: 'fabricType',
                    fabricComposition: 'fabricComposition',
                    fabricGsm: 'fabricGsm',
                    version: 'version'
                }
            );
        }
    );

    const values = splice(
        {
            ...renamedData,
            dispatchDetails: normalizedDispatchDetails,
            actualSendDate: normalizeDate(actSentDate),
            eta: normalizeDate(eta)
        },
        {
            id: 'id',
            retailer: 'retailer',
            department: 'department',
            originCountry: 'originCountry',
            destinationCountry: 'destinationCountry',
            dispatchReference: 'dispatchReference',
            versionNo: 'versionNo',
            trackingReference: 'trackingReference',
            fao: 'fao',
            actualSendDate: 'actualSendDate',
            eta: 'eta',
            currier: 'currier',
            dispatchDetails: 'dispatchDetails'
        }
    );

    yield put(
        initialize(
            FORM_NAME,
            {
                ...INITIAL_VALUES,
                ...values
            },
            false,
            {
                updateUnregisteredFields: true,
                keepDirty: false
            }
        )
    );
}
