const validateAttachmentSizeAndExtension = attachments => {
    if (!attachments || !Array.isArray(attachments)) {
        return null;
    }

    const MAX_SIZE = 10 * 1024 * 1024; // 10 MB
    const allowedExtensions = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'pdf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'psd'
    ];

    for (const attachment of attachments) {
        if (!attachment.filename || !attachment.size) {
            continue;
        }

        if (attachment.size > MAX_SIZE) {
            return `The file "${attachment.filename}" exceeds the maximum file size of 10 MB.`;
        }

        const fileExtension = attachment.filename
            .toLowerCase()
            .slice(((attachment.filename.lastIndexOf('.') - 1) >>> 0) + 2);

        if (!allowedExtensions.includes(fileExtension)) {
            const supportedExtensions = allowedExtensions.join(', ');
            return `The file "${attachment.filename}" has an unsupported file type. Supported extensions are: ${supportedExtensions}.`;
        }
    }

    return null;
};

export default validateAttachmentSizeAndExtension;
