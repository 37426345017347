import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    withStyles,
    Fade,
    CircularProgress
} from '@material-ui/core';

import useTransitionToggle from '@libHooks/useTransitionToggle';

import { useVersionsViewerState } from '../../../useVersionsViewer';
import {
    useVersionMouseOverStyle,
    useDropStyle,
    useDndRefs,
    useLoadedImage
} from './hooks';
import VersionOverlay from './VersionOverlay';
import { fade } from '@material-ui/core/styles/colorManipulator';

const propTypes = {
    image: PropTypes.object.isRequired,
    index: PropTypes.number
};

const defaultProps = {
    index: void 0
};

const styles = ({ palette, spacing, typography, transitions }) => ({
    root: {
        position: 'relative',
        width: 'var(--versionWidth)',
        height: 'var(--versionHeight)',
        margin: spacing.unit,
        marginBottom: spacing.unit * 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'var(--borderColor)',
        opacity: 'var(--opacity)',
        transition: transitions.create(['width', 'opacity'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.short
        })
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: fade(palette.secondary.main, 0.3),
        color: palette.primary.light
    },
    dragContainer: {
        width: '100%',
        height: '100%'
    },
    dropContainer: {
        position: 'absolute',
        top: 0,
        right: 'var(--right)',
        width: 'var(--width)',
        height: '100%',
        zIndex: 30
    },
    hoverContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        backgroundColor: palette.background.dark
    },
    title: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        bottom: spacing.unit * -4,
        left: 0,
        right: 0,
        fontWeight: typography.fontWeightHeavy
    },
    previewContainer: {
        width: '80%',
        height: '80%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        pointerEvents: 'none',
        zIndex: 30,
        opacity: 'var(--previewOpacity)'
    },
    preview: {
        width: '100%',
        height: '100%',
        transform: 'rotate(-7deg)'
    },
    previewImage: {
        width: '100%',
        objectFit: 'contain',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.secondary.light
    }
});

const Version = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme, theme: { transitions }, image, index }) => {
        const transitionDuration = transitions.duration.short;

        const { hoverIndex, readOnly } = useVersionsViewerState();

        const loadedImage = useLoadedImage({ image, index });

        const isHovering = hoverIndex === index;

        const shouldRender = useTransitionToggle(
            !isHovering,
            transitionDuration
        );

        const title = useMemo(() => `V${index + 1}`, [index]);

        const [
            handleMouseEnter,
            handleMouseLeave,
            style
        ] = useVersionMouseOverStyle({ theme, shouldRender, index, image });

        const dropStyle = useDropStyle({ index });

        const [dragRef, dropRef, hoverRef, previewRef] = useDndRefs({
            index,
            image
        });

        return (
            <Grid item className={c.root} style={style}>
                {shouldRender && (
                    <Fade in={!isHovering} timeout={transitionDuration}>
                        <div className={c.dragContainer} ref={dragRef}>
                            <VersionOverlay
                                index={index}
                                image={image.url}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                            />
                            {!isHovering &&
                                (loadedImage ? (
                                    <img
                                        className={c.image}
                                        alt={title}
                                        src={loadedImage}
                                    />
                                ) : (
                                    <div className={c.loader}>
                                        <CircularProgress
                                            color='inherit'
                                            size={80}
                                            thickness={2}
                                        />
                                    </div>
                                ))}
                            <div
                                className={c.dropContainer}
                                ref={dropRef}
                                style={dropStyle}
                            />
                        </div>
                    </Fade>
                )}
                {!readOnly && (
                    <Fade in={isHovering} timeout={transitionDuration}>
                        <div className={c.hoverContainer} ref={hoverRef} />
                    </Fade>
                )}
                {!readOnly && (
                    <div className={c.previewContainer} ref={previewRef}>
                        <div className={c.preview}>
                            {loadedImage ? (
                                <img
                                    className={c.previewImage}
                                    alt={title}
                                    src={loadedImage}
                                />
                            ) : (
                                <Fragment />
                            )}
                        </div>
                    </div>
                )}
                <Typography className={c.title} variant='subtitle2'>
                    {title}
                </Typography>
            </Grid>
        );
    }
);

Version.propTypes = propTypes;
Version.defaultProps = defaultProps;

export default Version;
