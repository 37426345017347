import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, StepIcon as MaterialStepIcon } from '@material-ui/core';

const propTypes = {
    index: PropTypes.number.isRequired,
    activeStep: PropTypes.number.isRequired,
};

const styles = ({ palette, spacing, transitions }) => ({
    root: {
        color: palette.background.dark,
        fontSize: spacing.unit * 3.5,
        transition: transitions.create(['color'], {
            easing: transitions.easing.easeOut,
        }),
        transitionDuration: 'var(--duration)',

        '&$active': {
            color: 'var(--color)',
        },
    },
    active: {},
});

const StepIcon = withStyles(styles)(({ classes: c, index, activeStep }) => {
    return (
        <MaterialStepIcon
            classes={c}
            completed={false}
            active={index <= activeStep}
            icon={index + 1}
        />
    );
});

StepIcon.propTypes = propTypes;

export { StepIcon as default };
