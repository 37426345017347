import { initializeDatasets } from '@libs/datasets';
import datasets from './redux.datasets';
import {
    SET_VERSIONS,
    SET_FABRICS_LOADING,
    SET_BOUGHT_DETAILS_LOADING,
    SET_TRIM_DETAILS_LOADING,
    SET_SAMPLE_REFERENCE_GENERATE_LOADING,
    SET_DOWNLOAD_SAMPLES_AS_EXCEL_LOADING,
    SET_DOWNLOAD_SAMPLES_AS_MULTI_PAGE_LOADING,
    SET_DOWNLOAD_SAMPLES_AS_SINGLE_PAGE_LOADING,
    SET_DOWNLOAD_SAMPLES_AS_COST_FORM_LOADING,
    SET_FINAL_PHOTO_EMAIL_LOADING,
    SET_FINAL_PHOTO_DATA_LOADING,
    SET_DISPATCH_REFERENCE_GENERATE_LOADING,
    SET_DISPATCH_EXPORT_EXCEL_DATA_LOADING,
    SET_SAMPLING_REPORT_DATA,
    SET_SAMPLING_REPORT_DATA_LOADING
} from './redux.actionTypes';
import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

const initialState = {
    isRedirected: false,
    versionsLoading: false,
    fabricsLoading: false,
    boughtDetailsLoading: false,
    trimDetailsLoading: false,
    referenceLoading: false,
    downloadSamplesAsExcelLoading: false,
    downloadSamplesAsMultiPageLoading: false,
    downloadSamplesAsSinglePageLoading: false,
    downloadSamplesAsCostFormLoading: false,
    finalPhotoEmailLoading: false,
    finalPhotoDataLoading: false,
    dispatchReferenceLoading: false,
    dispatchExportExcelDataLoading: false,
    versions: [],
    samplingReportData: [],
    samplingReportDataLoading: false,

    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_VERSIONS: {
            return {
                ...state,
                versionsLoading: action.meta === 'started',
                versions: payload
            };
        }

        case SET_FABRICS_LOADING: {
            return { ...state, fabricsLoading: payload };
        }

        case SET_BOUGHT_DETAILS_LOADING: {
            return { ...state, boughtDetailsLoading: payload };
        }

        case SET_TRIM_DETAILS_LOADING: {
            return { ...state, trimDetailsLoading: payload };
        }

        case SET_SAMPLE_REFERENCE_GENERATE_LOADING: {
            return { ...state, referenceLoading: payload };
        }

        case SET_DOWNLOAD_SAMPLES_AS_EXCEL_LOADING: {
            return {
                ...state,
                downloadSamplesAsExcelLoading: payload
            };
        }

        case SET_DOWNLOAD_SAMPLES_AS_MULTI_PAGE_LOADING: {
            return {
                ...state,
                downloadSamplesAsMultiPageLoading: payload
            };
        }

        case SET_DOWNLOAD_SAMPLES_AS_SINGLE_PAGE_LOADING: {
            return {
                ...state,
                downloadSamplesAsSinglePageLoading: payload
            };
        }

        case SET_DOWNLOAD_SAMPLES_AS_COST_FORM_LOADING: {
            return {
                ...state,
                downloadSamplesAsCostFormLoading: payload
            };
        }

        case SET_FINAL_PHOTO_EMAIL_LOADING: {
            return { ...state, finalPhotoEmailLoading: payload };
        }

        case SET_FINAL_PHOTO_DATA_LOADING: {
            return { ...state, finalPhotoDataLoading: payload };
        }

        case SET_DISPATCH_REFERENCE_GENERATE_LOADING: {
            return { ...state, dispatchReferenceLoading: payload };
        }

        case SET_DISPATCH_EXPORT_EXCEL_DATA_LOADING: {
            return {
                ...state,
                dispatchExportExcelDataLoading: payload
            };
        }

        case SET_SAMPLING_REPORT_DATA: {
            return {
                ...state,
                samplingReportData: payload
            };
        }

        case SET_SAMPLING_REPORT_DATA_LOADING: {
            return {
                ...state,
                samplingReportDataLoading: payload
            };
        }

        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
