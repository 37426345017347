import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import SelectModel from '@formComponents/SelectModel';

import {
    samplingReportDataSelector,
    samplingReportDataLoadingSelector
} from '../../../../redux.selectors';

const removeDuplicates = (array, key) => {
    const seen = new Set();
    return array.filter(item => {
        const value = item[key];
        if (seen.has(value)) {
            return false;
        } else {
            seen.add(value);
            return true;
        }
    });
};

const retailersSelector = createSelector(
    data => data,
    data => {
        const uniqueData = removeDuplicates(data, 'retailerId');
        return uniqueData.map(({ retailer }) => ({
            label: retailer,
            value: retailer
        }));
    }
);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'retailer',
    label: 'Retailer',
    placeholder: 'Select retailer',
    disabled: false,
    required: false
};

const mapState = state => {
    const samplingReportData = samplingReportDataSelector(state);
    return {
        data: retailersSelector(samplingReportData),
        loading: samplingReportDataLoadingSelector(state)
    };
};

const SelectRetailer = connect(mapState)(props => {
    return <SelectModel {...props} />;
});

SelectRetailer.propTypes = propTypes;
SelectRetailer.defaultProps = defaultProps;

export default SelectRetailer;
