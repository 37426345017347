import { useCallback } from 'react';

export default ({
    amountOfSteps,
    dispatch,
    mode,
    activeStep,
    publish,
    dispatchProps = {}
}) => {
    const handleComplete = useCallback(() => {
        const locked = Array(amountOfSteps)
            .fill(false)
            .map((lock, index) => index < amountOfSteps - 1);

        dispatch({ type: 'lock', payload: locked });
        dispatch({ type: 'forceMove', payload: amountOfSteps - 1 });
    }, [activeStep, amountOfSteps, dispatch]);

    return useCallback(() => {
        // On publish
        if (activeStep === amountOfSteps - 2) {
            publish({ mode, handleComplete, ...dispatchProps });
        } else dispatch({ type: 'next' });
    }, [activeStep, amountOfSteps, publish, mode, handleComplete]);
};
