import {
    FETCH_CAD_DESIGNER_REPORT_DATA,
    SET_CAD_DESIGNER_REPORT_DATA,
    SET_CAD_DESIGNER_REPORT_DATA_LOADING,
    ERASE_CAD_DESIGNER_REPORT_DATA
} from './redux.actionTypes';

export const fetchCadDesignerReportData = () => ({
    type: FETCH_CAD_DESIGNER_REPORT_DATA
});
export const setCadDesignerReportData = payload => ({
    type: SET_CAD_DESIGNER_REPORT_DATA,
    payload
});
export const setCadDesignerReportDataLoading = payload => ({
    type: SET_CAD_DESIGNER_REPORT_DATA_LOADING,
    payload
});
export const eraseCadDesignerReportData = () => ({
    type: ERASE_CAD_DESIGNER_REPORT_DATA
});
