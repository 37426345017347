import { useCallback } from 'react';

const useHandleAdd = ({ handleModal, setAction, formName }) => {
    const handleSave = useCallback(values => {
        setAction({ type: 'addDispatchDetails', payload: values });
    }, []);

    return useCallback(() => {
        handleModal({ handleSave, formName });
    }, [handleModal, handleSave, formName]);
};

export { useHandleAdd };
