import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

// Local
import customMiddlewares from 'store/middlewares';
import reducers from 'store/reducers';
import rootSaga from 'store/sagas';

//check for dev environment
const ENV = process.env;
const isDevEnv = () => ENV.NODE_ENV === 'development';

//dev sampling reducer
const devReducer = ['form', 'navigation'];

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [
        'auth',
        'datasets',
        ...(isDevEnv() ? devReducer : [])
    ],
    stateReconciler: autoMergeLevel2
};

const prsistReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

let enhancers = compose(
    applyMiddleware(...customMiddlewares, thunk, sagaMiddleware)
);

// For dev, enable redux dev tool
if (isDevEnv()) {
    enhancers = compose(
        applyMiddleware(...customMiddlewares, thunk, sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    );
}

const store = createStore(prsistReducer, {}, enhancers);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
const storageName = storage;

export { store as default, store, persistor, storageName };
