import { useMemo } from 'react';

import { roundNumber } from '@libs/number';

const usePOBasics = basics =>
    useMemo(
        () =>
            Object.entries(basics)
                .map(([label, value]) => {
                    switch (label) {
                        case 'cidNO':
                            return !!value
                                ? { label: 'CID No', value, order: 0 }
                                : null;
                        case 'poPackId':
                            return !!value
                                ? { label: 'Pack ID', value, order: 1 }
                                : null;
                        case 'shipMode':
                            return { label: 'Mode', value, order: 2 };
                        case 'shipDestination':
                            return { label: 'Destination', value, order: 3 };
                        case 'paymentTerms':
                            return !!value
                                ? { label: 'Payment Term', value, order: 4 }
                                : null;
                        case 'settlementDiscount':
                            return !!value
                                ? {
                                      label: 'Settlement Discount',
                                      value,
                                      order: 5
                                  }
                                : null;
                        case 'orderType':
                            return !!value
                                ? { label: 'PO Type', value, order: 6 }
                                : null;
                        case 'orderStyle':
                            return !!value
                                ? { label: 'Option ID', value, order: 7 }
                                : null;
                        case 'retailPrice': {
                            const { inputValue, selectedValue } = value || {};
                            if (!inputValue) return null;
                            return {
                                label: 'Retail Price',
                                value: `${selectedValue}${inputValue}`,
                                order: 6
                            };
                        }
                        case 'orderQuantity':
                            return { label: 'Quantity', value, order: 8 };
                        case 'totalRatio':
                            return !!value
                                ? { label: 'Total Ratio', value, order: 9 }
                                : null;
                        case 'packQuantity':
                            return !!value
                                ? { label: 'Total Pack Qty.', value, order: 10 }
                                : null;
                        case 'sizeSet':
                            return { label: 'Size Set', value, order: 11 };

                        case 'estimateFactoryDate':
                            return { label: 'Ex-Fty', value, order: 12 };
                        case 'freeOnBoardDate':
                            return {
                                label: 'FOB',
                                value,
                                order: 13
                            };
                        case 'echoDiscount':
                            if (!value) return null;
                            return {
                                label: 'Echo After Discount',
                                value,
                                order: 14
                            };
                        case 'fobDiscount':
                            if (!value) return null;
                            return {
                                label: 'Fob in Pack (after disc)',
                                value,
                                order: 15
                            };
                        case 'fabricConsumption':
                            if (!value) return null;
                            return {
                                label: 'Fabric Consumption',
                                value: `${value}KG`,
                                order: 16
                            };
                        case 'yarnCount':
                            if (!value) return null;
                            return {
                                label: 'Yarn Count',
                                value,
                                order: 17
                            };
                        case 'yarnPrice': {
                            const { inputValue, selectedValue } = value || {};
                            if (!inputValue) return null;
                            return {
                                label: 'Yarn Price',
                                value: `${selectedValue}${inputValue}`,
                                order: 18
                            };
                        }
                        case 'totalFabric':
                            if (!value) return null;
                            return {
                                label: 'Total Fabric',
                                value: `${roundNumber(value)}KG`,
                                order: 19
                            };
                        case 'totalYarnPrice':
                            if (!value) return null;
                            return {
                                label: 'Total Yarn Price',
                                value: roundNumber(value),
                                order: 20
                            };

                        default:
                            return null;
                    }
                })
                .filter(basics => !!basics)
                .sort((a, b) => a.order - b.order)
                .map(({ order, ...detail }) => detail),
        [basics]
    );

export { usePOBasics };
