export default ({ spacing, palette, typography }) => ({
    placeholder: {
        color: palette.grey[400],
        position: 'absolute',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingRight: spacing.unit * 4,
        textOverflow: 'ellipsis',
        height: 'inherit',
        paddingLeft: spacing.unit * 2,
        fontSize: typography.subtitle2.fontSize
    },
    focused: {
        display: 'none !important'
    }
});
