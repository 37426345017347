import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Material
import { withStyles, Paper, Popover } from '@material-ui/core';

// Local
import styles from './styles';
import {
    MultipleSelectDialogContext,
    dialogReducer,
    initialState
} from './MultipleSelectDialogContext';
import DialogSearch from './DialogSearch';
import DialogContent from './DialogContent';

/*
 * Domain: --
 * Page: PaginatedSelect
 * Component: MultipleSelectDialog
 * Type: --
 * MultipleSelectDialog
 */
class MultipleSelectDialog extends Component {
    state = {
        dialogState: initialState,
        dialogHeight: 0
    };

    componentDidMount() {
        const {
            props: { data = [], value: inititalValue },
            dispatch
        } = this;
        dispatch({ type: 'initialize', payload: { data, inititalValue } });
    }

    handleRef = ref => {
        try {
            if (!this.state.unitWidth) {
                const { offsetHeight } = ReactDOM.findDOMNode(ref);
                this.setState({
                    dialogHeight: offsetHeight
                });
            }
        } catch {
            this.setState({
                dialogHeight: 0
            });
        }
    };

    dispatch = action => {
        const {
            state: { dialogState: contextState }
        } = this;
        const dialogState = dialogReducer(contextState, action);
        this.setState({ dialogState });
    };

    render() {
        const { props, state, handleRef, dispatch } = this;
        const { dialogState, dialogHeight } = state;
        const { hideDialog, anchor, dialogWidth } = props;
        const { checkedItems } = dialogState;
        const contextPayload = {
            state: dialogState,
            dispatch
        };

        return (
            <Popover
                open={!!anchor}
                onClose={() => hideDialog(checkedItems.toString())}
                anchorEl={anchor}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: { width: dialogWidth, height: dialogHeight }
                }}
            >
                <Paper ref={handleRef}>
                    <MultipleSelectDialogContext.Provider
                        value={contextPayload}
                    >
                        <DialogSearch />
                        <DialogContent />
                    </MultipleSelectDialogContext.Provider>
                </Paper>
            </Popover>
        );
    }
}

const _MultipleSelectDialog = compose(withStyles(styles))(MultipleSelectDialog);

export {
    _MultipleSelectDialog as default,
    _MultipleSelectDialog as MultipleSelectDialog
};
