import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Image as PDFImage } from '@libComponents/ReactPDF';
import { FULL_WIDTH_UNITS, FULL_HEIGHT_UNITS } from './constants';

const propTypes = {
    data: PropTypes.array.isRequired
};

const defaultProps = {
    data: []
};

const styles = ({ spacing }) => ({
    page: {
        width: spacing.unit * FULL_WIDTH_UNITS,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        paddingLeft: spacing.unit * 5,
        paddingRight: spacing.unit * 5,
        paddingTop: spacing.unit * 1,
        paddingBottom: spacing.unit * 1
    },
    pageImage: {
        justifyContent: 'center'
    },
    pageWatermark: {
        position: 'absolute',
        width: spacing.unit * 7.5,
        marginTop: spacing.unit * 9,
        marginBottom: spacing.unit * 9,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        alignItems: 'center',
        justifyContent: 'flex-end',
        right: 0,
        opacity: 0.4
    },
    watermark: {
        transform: 'rotate(-90deg)',
        fontSize: 12,
        marginBottom: spacing.unit * 20.5,
        width: spacing.unit * 50
    },
    image: { width: 'auto', objectFit: 'contain' }
});

const TileReport = withPDFStyles(styles, { withTheme: true })(
    ({ classes: c, data }) => {
        const year = useMemo(() => new Date().getFullYear(), []);

        return (
            <Document>
                {data.map(({ images }) => {
                    return images.map((image, idx) => {
                        return !!image ? (
                            <Page
                                size='A3'
                                style={{ ...c.page, ...c.pageImage }}
                                key={`imagePage-${idx}`}
                            >
                                <View style={c.pageWatermark}>
                                    <Text style={c.watermark}>
                                        &copy;
                                        {` ${year} Echotech Systems Limited`}
                                    </Text>
                                </View>
                                <PDFImage style={c.image} src={image} />
                            </Page>
                        ) : (
                            <Page
                                size='A3'
                                style={{ ...c.page, ...c.pageImage }}
                            ></Page>
                        );
                    });
                })}
            </Document>
        );
    }
);

TileReport.propTypes = propTypes;
TileReport.defaultProps = defaultProps;

export { TileReport as default, TileReport };
