import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Material
import { withStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/InsertPhotoOutlined';

import {
    previewSelector,
    previewsSelector,
    buyerCadImageSelector
} from '../../../redux.selectors';
import { fetchDatasets } from '@libs/datasets';

import { SIZE_PATHS } from 'cadUpload/redux.datasets';
import { useDimensionsObserver } from '../../../../@libHooks/useDimensions';

const propTypes = { previewVersion: PropTypes.string };

const defaultProps = { previewVersion: null };

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: 'var(--height)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: spacing.unit * 1.5,
        backgroundColor: palette.background.main,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    },
    image: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain'
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: palette.background.dark
    },
    icon: {
        color: palette.background.dark,
        fontSize: spacing.unit * 4
    }
});

const mapState = (state, { previewVersion }) => ({
    preview: previewSelector(state, previewVersion),
    previews: previewsSelector(state),
    buyerCadImage: buyerCadImageSelector(state)
});

const mapDispatch = { fetchDatasets };

const Preview = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles, { withTheme: true })
)(({ classes: c, theme, fetchDatasets, previews, preview, buyerCadImage }) => {
    useEffect(() => {
        fetchDatasets(SIZE_PATHS);
    }, []);

    useEffect(() => {
        let isSubscribed = true;

        Promise.all(
            previews.map(
                source =>
                    new Promise(resolve => {
                        if (isSubscribed) {
                            let refImage = new Image();
                            refImage.src = source;
                            refImage.onload = resolve;
                        }
                    })
            )
        );
        return () => {
            isSubscribed = false;
        };
    }, [previews]);

    const [ref, { width }] = useDimensionsObserver();

    const previewStyle = useMemo(
        () => ({
            '--height': `${Math.round(width * Math.sqrt(2))}px`
        }),
        [width]
    );

    return (
        <div className={c.root} ref={ref} style={previewStyle}>
            {/* Some scenario we don't have a cad and it's image but we have buyer image.*/
            preview || !!buyerCadImage ? (
                <img
                    src={preview || buyerCadImage}
                    alt='cadImage'
                    className={c.image}
                />
            ) : (
                <div className={c.image}>
                    <div className={c.empty}>
                        <ImageIcon className={c.icon} />
                    </div>
                </div>
            )}
        </div>
    );
});

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
