import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import { ContentContainer } from '@libComponents/ContentContainer';
import sanitizeHTML from '@libs/sanitizeHTML';

// Local
import Adornment from './Adornment';

const propTypes = {
    name: string
};

const defaultProps = {
    name: 'orderComment'
};

const styles = ({ spacing }) => ({
    header: {
        padding: `0px ${spacing.unit * 2}px`
    },
    content: {
        width: '100%',
        minHeight: spacing.unit * 41,
        padding: spacing.unit * 1.5,
        lineHeight: 1.4,

        fontSize: spacing.unit * 1.5,
        fontFamily: 'Museo'
    }
});

const OrderComment = compose(
    withField(),
    withStyles(styles)
)(({ input: { value }, classes: c }) => {
    const sanitizedHtml = sanitizeHTML(value);
    return (
        <ContentContainer
            styles={c}
            title='Order Notes'
            AdornmentComponent={<Adornment />}
        >
            <div
                className={c.content}
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            ></div>
        </ContentContainer>
    );
});

OrderComment.propTypes = propTypes;
OrderComment.defaultProps = defaultProps;

export { OrderComment as default, OrderComment };
