import {
    ALL_DESIGNERS,
    ARTWORK_SOURCES,
    TYPES,
    LICENSORS,
    PROPERTIES
} from 'cadUpload/redux.datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import {
    STYLES,
    CATEGORIES,
    GENDERS,
    AGES,
    DESIGN_DEPARTMENT,
    SHAPE_DESIGNERS
} from 'common/redux.datasets';
import { DOMAIN_NAME as COMMON_DOMAIN_NAME } from 'common/common';
import { RETAILERS, DEPARTMENTS } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import { DOMAIN_NAME as CAD_VIEW_DOMAIN_NAME } from './common';
import { CAD_AVAILABILITY } from './redux.datasets';

export const DATASETS = [
    ALL_DESIGNERS,
    ARTWORK_SOURCES,
    TYPES,
    LICENSORS,
    PROPERTIES,
    STYLES,
    CATEGORIES,
    GENDERS,
    AGES,
    DESIGN_DEPARTMENT,
    SHAPE_DESIGNERS,
    RETAILERS,
    DEPARTMENTS,
    CAD_AVAILABILITY
];

export const PAGE_SIZES = [10, 25, 50, 100, 250];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            type: 'number',
            visible: false
        },
        {
            name: 'cadRef',
            header: 'CAD Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'buyerRef',
            header: 'Buyer Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'cad',
            header: 'CAD',
            type: 'image'
        },
        {
            name: 'devNo',
            header: 'Dev No',
            type: 'text'
        },
        {
            name: 'processedById',
            header: 'Processed By',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: ALL_DESIGNERS
        },
        {
            name: 'cadTypeId',
            header: 'CAD Type',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: TYPES
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: RETAILERS
        },
        {
            name: 'departmentId',
            header: 'Department',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: DEPARTMENTS
        },
        {
            name: 'categoryId',
            header: 'Category',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: CATEGORIES
        },
        {
            name: 'styleId',
            header: 'Style',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: STYLES
        },
        {
            name: 'genderId',
            header: 'Gender',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: GENDERS
        },
        {
            name: 'designDepartmentId',
            header: 'Design Department',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: DESIGN_DEPARTMENT
        },
        {
            name: 'licensorId',
            header: 'Licensor',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: LICENSORS
        },
        {
            name: 'propertyId',
            header: 'Licensor',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: PROPERTIES
        },
        {
            name: 'artworkSourceId',
            header: 'Artwork Source',
            type: 'select',
            filterable: true,
            domain: CAD_DOMAIN_NAME,
            entity: ARTWORK_SOURCES
        },
        {
            name: 'shapeDesignerId',
            header: 'Shape Designer',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: SHAPE_DESIGNERS
        },
        {
            name: 'cadDescription',
            header: 'Description',
            type: 'text',
            editable: true
        },
        {
            name: 'cadAvailityId',
            header: 'Availability',
            type: 'select',
            filterable: true,
            domain: CAD_VIEW_DOMAIN_NAME,
            entity: CAD_AVAILABILITY
        },
        {
            name: 'ageId',
            header: 'Age',
            type: 'select',
            filterable: true,
            domain: COMMON_DOMAIN_NAME,
            entity: AGES
        },
        {
            name: 'cadCreatedDate',
            header: 'Created Date',
            type: 'date',
            filterable: true
        },
        {
            name: 'cadModifiedDate',
            header: 'Modified Date',
            type: 'date',
            filterable: true
        }
    ];
};
