import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import {
    withStyles,
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Table,
} from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogTableHead
 */
const PaginatedDialogTableHead = ({
    classes: c,
    dialogState: { categories },
}) => (
    <TableHead className={c.head}>
        <TableRow className={c.row}>
            {categories.map((header, idx) => {
                return (
                    <TableCell
                        key={`paginatedTableHead${idx}`}
                        className={c.cell}
                    >
                        {header}
                    </TableCell>
                );
            })}
        </TableRow>
    </TableHead>
);

const _PaginatedDialogTableHead = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogTableHead);

export {
    _PaginatedDialogTableHead as default,
    _PaginatedDialogTableHead as PaginatedDialogTableHead,
};
