import makeContext from '@libs/makeContext';

const initialState = {
    boughtDetails: []
};

const boughtDetailsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'deleteBoughtDetail': {
            const { boughtDetails } = state;
            const { payload } = action;

            const boughtDetailsAfterDelete = boughtDetails.filter(
                (bought, index) => index !== payload
            );

            reduxFormOnChange(boughtDetailsAfterDelete);

            return action;
        }
        default:
            return action;
    }
};

const [
    BoughtDetailsProvider,
    useBoughtDetailsState,
    useBoughtDetailsDispatch,
    useBoughtDetails
] = makeContext(boughtDetailsReducer, initialState, {
    name: 'boughtDetails',
    middleware
});

export {
    useBoughtDetails as default,
    BoughtDetailsProvider,
    useBoughtDetailsState,
    useBoughtDetailsDispatch
};
