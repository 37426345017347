import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

import { withStyles, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Checkbox } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware';

// Local
import { FORM_NAME, LABELS } from '../../common';
import { SET_COPY_USER_GRANTED_PERMISSIONS } from '../../../redux.actionTypes';
import HeaderAdornment from '../HeaderAdornment';
import SelectUserForCopyPermission from './SelectUserForCopyPermission';
import RolePermissionContainer from './RolePermissionsContainer';

const styles = ({ spacing, typography }) => ({
    container: {
        width: '94%',
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const mapState = state => ({
    hasCopyUserPermissions: formValueSelector(FORM_NAME)(
        state,
        'hasCopyUserPermissions'
    )
});

const mapInputs = {
    hasCopyUserPermissions: ({ payload }) => {
        if (!payload) return change(FORM_NAME, 'copyUser', '');
    },
    copyUser: ({ payload }) => ({
        type: SET_COPY_USER_GRANTED_PERMISSIONS,
        payload
    })
};

const Permissions = compose(
    connect(mapState),
    withStyles(styles),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    formListener(mapInputs)
)(({ classes: c, hasCopyUserPermissions }) => {
    return (
        <ContentContainer
            title={LABELS.permissions}
            AdornmentComponent={<HeaderAdornment />}
        >
            <div className={c.container}>
                <Grid container>
                    <GridItem md={4}>
                        <Checkbox
                            name='hasCopyUserPermissions'
                            label='I want to copy another user’s permissions'
                            styles={{
                                label: c.checkboxLabel
                            }}
                        />
                    </GridItem>
                    <GridItem md={4} />
                    <GridItem md={4} />
                    {hasCopyUserPermissions && (
                        <GridItem md={4}>
                            <SelectUserForCopyPermission />
                        </GridItem>
                    )}
                </Grid>
                <RolePermissionContainer />
            </div>
        </ContentContainer>
    );
});

export default Permissions;
