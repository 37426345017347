import React from 'react';

// Material
import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

// Local
import ReportsToolbarPagination from './ReportsToolbarPagination';
import ReportsToolbarViewMode from './ReportsToolbarViewMode';
import ReportsToolbarDownload from './ReportsToolbarDownload';
import styles from './styles';

/*
  * Domain: Reports
  * Page: --
  * Component: Toolbar
  * Type: --
  * ReportsToolbar
*/
const ReportsToolbar = ({
    classes: { toolbarContainer, divider },
}) => (
    <Toolbar className={toolbarContainer}>
        <ReportsToolbarPagination className={divider}/>
        <ReportsToolbarViewMode className={divider}/>
        <ReportsToolbarDownload className={divider}/>
    </Toolbar>
);

const _ReportsToolbar = withStyles(styles)(ReportsToolbar);

export { _ReportsToolbar as default, _ReportsToolbar as ReportsToolbar};
