import React from 'react';
import classNames from 'classnames';
import { withStyles, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = ({ palette, spacing, shape }) => ({
    root: {
        paddingLeft: spacing.unit * 0.75
    },
    icon: {
        color: palette.grey[400],
        borderRadius: shape.borderRadius,
        '&:hover': {
            color: palette.grey[700],
            cursor: 'pointer'
        }
    },
    iconHighlight: {
        backgroundColor: palette.primary.light,
        color: palette.primary.contrastText,
        '&:hover': {
            color: palette.primary.contrastText,
            cursor: 'pointer',
            backgroundColor: palette.primary.dark
        }
    }
});

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * SpotlightAdornment
 */
const SpotlightAdornment = ({
    classes: { icon, iconHighlight, ...c },
    highlight,
    ...restProps
}) => {
    return (
        <InputAdornment classes={c} position='start' {...restProps}>
            <SearchIcon
                className={classNames(icon, highlight && iconHighlight)}
            />
        </InputAdornment>
    );
};

const _SpotlightAdornment = withStyles(styles)(SpotlightAdornment);

export {
    _SpotlightAdornment as default,
    _SpotlightAdornment as SpotlightAdornment
};
