import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    root: {
        width: spacing.unit * 3,
        height: spacing.unit * 3
    }
});

const CheckboxPartiallyFilledIcon = ({
    classes: { root },
    className,
    color = '#373A3F'
}) => (
    <svg
        className={classNames(root, className)}
        x='0px'
        y='0px'
        viewBox='0 0 20 20'
    >
        <path
            d='M6.15789 4H13.5263C15.3158 4 16.6842 5.36825 16.6842 7.1575V13.4725C16.6842 15.2618 15.3158 16.63 13.5263 16.63H6.15789C4.36842 16.63 3 15.2618 3 13.4725V7.1575C3 5.36825 4.36842 4 6.15789 4Z'
            style={{
                fill: '#FFFFFF',
                fillOpacity: 0,
                stroke: color,
                strokeLinejoin: 'round'
            }}
        />
        <path
            d='M13.8 9.80005H5.80005V10.8H13.8V9.80005Z'
            style={{
                fill: color
            }}
        />
    </svg>
);

const _CheckboxPartiallyFilledIcon = withStyles(styles)(
    CheckboxPartiallyFilledIcon
);

export {
    _CheckboxPartiallyFilledIcon as default,
    _CheckboxPartiallyFilledIcon as CheckboxPartiallyFilledIcon
};
