export default ({ palette, spacing }) => ({
    root: {
        height: spacing.unit * 25,
        boxShadow: 'none',
        overflow: 'hidden'
    },

    menus: {
        height: spacing.unit * 20,
        paddingTop: spacing.unit * 0.75,
        borderTop: ` 1px solid ${palette.grey[300]}`
    },

    scroll: {
        overflowY: 'scroll',
        overflowX: 'hidden'
    }
});
