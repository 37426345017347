import React from 'react';
import { array, func } from 'prop-types';

import { withStyles, Paper } from '@material-ui/core';

import SelectedItem from './SelectedItem';

const styles = ({ spacing }) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        padding: spacing.unit * 0.25,
        margin: spacing.unit * 0.5,
        boxShadow: 'none',
        paddingRight: spacing.unit * 4
    }
});

const propTypes = {
    data: array.isRequired,
    handleOptionRemoveClick: func.isRequired
};

const SelectedData = withStyles(styles)(
    ({ classes: c, data, handleOptionRemoveClick }) => {
        return (
            <Paper className={c.root}>
                {data.map(({ label, value }, idx) => {
                    return (
                        <SelectedItem
                            key={`${value}-${idx}`}
                            label={label}
                            onDelete={e => handleOptionRemoveClick(value, e)}
                        />
                    );
                })}
            </Paper>
        );
    }
);

SelectedData.propTypes = propTypes;

export default SelectedData;
