export const publishSchema = {
    id: 0,
    userId: 0,
    name: 'string',
    email: 'string',
    mobileNo: 'string',
    roleId: 0,
    reportToId: [0],
    isActive: true,
    isCadDesigner: true,
    isShapeDesigner: true,
    menus: [
        {
            menuId: 0,
            parentMenuId: 0,
            menu: 'string'
        }
    ],
    retailers: [0],
    sampleFactories: [0],
    orderFactories: [0]
};
