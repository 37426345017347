import React, { useEffect, useMemo, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, initialize } from 'redux-form';
import { change } from 'redux-form';

import { withStyles, LinearProgress } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

import { FORM_NAME, INITIAL_VALUES } from './common';
import {
    CadStatistics,
    QuantityReport,
    FinanceReport,
    FilteredItems,
    LoadingScreen
} from './components';
import {
    fetchCadDesignerReportData,
    eraseCadDesignerReportData
} from './redux.actions';
import {
    cadDesignerReportData,
    cadDesignerReportDataLoading,
    cadDesignerServerDataExist
} from './redux.selectors';

const styles = ({ spacing, palette }) => ({
    root: {
        position: 'relative'
    },
    container: {
        marginBottom: spacing.unit * 2
    },
    progress: {
        width: '100%',
        backgroundColor: palette.secondary.light,
        height: spacing.unit * 0.5,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: palette.secondary.main,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    }
});

const mapInputs = {
    from: () => change(FORM_NAME, 'to', ''),
    retailer: () => change(FORM_NAME, 'department', '')
};
const mapState = state => ({
    data: cadDesignerReportData(state),
    prevData: cadDesignerServerDataExist(state),
    loading: cadDesignerReportDataLoading(state),
    filteredItems: getFormValues(FORM_NAME)(state) || INITIAL_VALUES
});
const mapDispatch = {
    fetchData: fetchCadDesignerReportData,
    formInitialize: initialize,
    eraseData: eraseCadDesignerReportData
};

const CadDesignerReport = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: true
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        filteredItems,
        fetchData,
        eraseData,
        formInitialize,
        data: {
            cadStatistics,
            quantityReport,
            shippedQuantityReport,
            wipQuantityReport
        } = {},
        prevData,
        loading
    }) => {
        const eraseRef = useRef(0);
        const didMountRef = useRef(false);

        useEffect(() => {
            eraseData();
            formInitialize(FORM_NAME, INITIAL_VALUES);
        }, []);

        const hasAllValues = useMemo(
            () => Object.values(filteredItems).every(value => !!value),
            [filteredItems]
        );

        const canExport = useMemo(() => hasAllValues, [hasAllValues]);

        useEffect(() => {
            /* If all the dropdown has selected and component did mount then api will be call. 
            Reason: If we refresh the page it can't destroy the filter form. As a result,
            filters values remain same until formInitialize fully executed on mount and by this time this effect 
            got hasAllValues true and api gets executed. That's why we ignore the first mount to api call then by this time 
            formInitialize gets executed and hasAllValues turns into false.
            */
            if (hasAllValues && didMountRef.current) {
                eraseRef.current = 1;
                /* Before make api call we need to erase prev data if it is exist. 
                If some reason api call failed it will show previous request data. 
                */
                prevData && eraseData();
                fetchData();
            }
            didMountRef.current = true;
        }, [filteredItems]);

        useEffect(() => {
            /* Once all are selected and user removes one selection then loaded data needs to be removed.
               Basically data fetched based on all selected values if one is removed then no point to show data there.
               We don't need to invoke eraseData action everytime while removing data. That's why we keeping track using 
               eraseRef
            */
            if (!hasAllValues && eraseRef.current === 1) {
                eraseRef.current = 0;
                eraseData();
            }
        }, [hasAllValues, eraseRef.current]);

        return (
            <>
                <div className={c.root}>
                    {loading && <LoadingScreen />}
                    <FilteredItems canExport={canExport} />
                    <ContentContainer
                        title='Cad and Sampling Report'
                        styles={{ container: c.container }}
                    >
                        {loading && (
                            <LinearProgress
                                classes={{
                                    root: c.progress,
                                    barColorPrimary: c.progressPrimary
                                }}
                            />
                        )}
                        <CadStatistics cadStatistics={cadStatistics} />
                        <QuantityReport quantityReport={quantityReport} />
                        <FinanceReport
                            shippedQuantityReport={shippedQuantityReport}
                            wipQuantityReport={wipQuantityReport}
                        />
                    </ContentContainer>
                </div>
            </>
        );
    }
);

export { CadDesignerReport as default, CadDesignerReport };
