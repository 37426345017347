import { useState, useCallback } from 'react';

export default () => {
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const sortColumn = useCallback(
        columnName => {
            if (sortedColumn === columnName) {
                setSortDirection(prevSortDirection =>
                    prevSortDirection === 'asc' ? 'desc' : 'asc'
                );
            } else {
                setSortedColumn(columnName);
                setSortDirection('asc');
            }
        },
        [sortDirection, sortedColumn]
    );

    const sortColumnAscending = useCallback(
        columnName => {
            setSortedColumn(columnName);
            setSortDirection('asc');
        },
        [sortDirection, sortedColumn]
    );

    const sortColumnDescending = useCallback(
        columnName => {
            setSortedColumn(columnName);
            setSortDirection('desc');
        },
        [sortDirection, sortedColumn]
    );

    return {
        sortedColumn,
        sortDirection,
        sortColumn,
        sortColumnAscending,
        sortColumnDescending
    };
};
