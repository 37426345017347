import React from 'react';
import { compose } from 'redux';

// Material
import { withStyles } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';
import { SelectBase } from 'components';
/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogToolbarCategories
 */
const PaginatedDialogToolbarCategories = ({
    classes,
    dialogState: { categories, categoryValue },
    dialogDispatch
}) => {
    const handleChange = e => {
        dialogDispatch({ type: 'set-category', payload: e.target.value });
        setTimeout(() => dialogDispatch({ type: 'query' }), 150);
    };

    const selectProps = {
        name: 'void0',
        data: categories.map((category, idx) => ({
            value: idx,
            label: category
        })),
        input: {
            onFocus: () => {},
            onBlur: () => {},
            onChange: handleChange,
            value: categoryValue
        },
        showSuccess: false,
        styles: classes
    };

    return (
        <React.Fragment>
            <SelectBase {...selectProps} />
        </React.Fragment>
    );
};

const _PaginatedDialogToolbarCategories = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogToolbarCategories);

export {
    _PaginatedDialogToolbarCategories as default,
    _PaginatedDialogToolbarCategories as PaginatedDialogToolbarCategories
};
