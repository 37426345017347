// Datasets
import bought from 'bought/redux.datasets.js';
import cadUpload from 'cadUpload/redux.datasets.js';
import cadView from 'cadView/redux.datasets.js';
import common from 'common/redux.datasets.js';
import navigation from 'navigation/redux.datasets.js';
import sampling from 'sampling/redux.datasets.js';
import trim from 'trim/redux.datasets.js';
import cadDesignerReport from 'cadDesignerReport/redux.datasets';
import orders from 'orders/redux.datasets';
import qualityControl from 'qualityControl/redux.datasets';
import setup from 'setup/redux.datasets';
import administrator from 'administrator/redux.datasets';
import fabric from 'fabric/redux.datasets';

export default {
    bought,
    cadUpload,
    cadView,
    common,
    navigation,
    sampling,
    trim,
    cadDesignerReport,
    orders,
    qualityControl,
    setup,
    administrator,
    fabric
};
