import { useCallback, useMemo, useState } from 'react';

export const useMouseOverStyle = ({ palette }) => {
    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setHovered(true), []);
    const handleMouseLeave = useCallback(() => setHovered(false), []);

    const style = useMemo(
        () => ({
            '--versionHandleColor': isHovered
                ? palette.secondary.main
                : palette.background.dark
        }),
        [isHovered]
    );

    return [handleMouseEnter, handleMouseLeave, style];
};
