import { combineReducers } from 'redux';

// Reducers
import auth from './auth';
import datasets from './datasets';
import form from './form';
import global from './global';
import initSamplingPrint from './sampling/initialData';
import loadingState from './loadingState';
import reports from './reports';
import samplingPrint from './samplingPrint';
import utils from './utils';

const rootReducer = combineReducers({
    auth,
    datasets,
    form,
    global, // prune
    initSamplingPrint, // prune
    loadingState, // prune
    reports,
    samplingPrint,
    utils
});

export default rootReducer;
