export default ({ palette, spacing }) => ({
    root: {
        display: 'flex',
        backgroundColor: palette.grey[100],
        color: '#333333'
    },
    content: {
        flexGrow: 1,
        padding: spacing.unit * 3,
        position: 'relative'
    },
    contentWrapper: {
        paddingTop: spacing.unit * 12
    }
});
