import { useCallback, useEffect, useState } from 'react';

import useDimensions from '@libHooks/useDimensions';

import { PO_INITIAL_VALUES } from 'orders/Order/common';

const useHandleAdd = ({
    handleModal,
    setAction,
    formName,
    factoryOrigin,
    retailer,
    initialDipValues
}) => {
    const handleSave = useCallback(values => {
        setAction({ type: 'addPurchaseOrder', payload: values });
    }, []);

    return useCallback(() => {
        handleModal({
            handleSave,
            formName,
            initialValues: {
                ...PO_INITIAL_VALUES(factoryOrigin, retailer),
                dips: initialDipValues
            }
        });
    }, [handleModal, handleSave, formName, factoryOrigin, retailer]);
};

const usePoCardRef = setHeight => {
    const [poCardRef, { height }] = useDimensions({
        width: null,
        height: null
    });

    useEffect(() => {
        setHeight(height);
    }, [height]);

    return poCardRef;
};

const usePOLoadMore = (purchaseOrders, queryValue) => {
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);

    const handleLoadMore = useCallback(() => {
        setPage(prevPage => prevPage + 1);
        setNextPage(data.length - (page + 1) * 3 > 0);
    }, [data, page]);

    useEffect(() => {
        setInitialData(purchaseOrders);
    }, [purchaseOrders]);

    useEffect(() => {
        const filteredData = initialData.filter(({ poNo }) => {
            return (
                queryValue === '' ||
                poNo
                    .toString()
                    .toLowerCase()
                    .includes(queryValue.toString().toLowerCase())
            );
        });
        setData(filteredData);
        setNextPage(filteredData.length - page * 3 > 0);
    }, [queryValue, initialData]);

    useEffect(() => {
        setPage(1);
    }, [queryValue]);

    return {
        data: data.slice(page * -3).reverse(),
        nextPage,
        handleLoadMore
    };
};
export { useHandleAdd, usePoCardRef, usePOLoadMore };
