import React, { useCallback } from 'react';

import InputCell from './InputCell';
import SelectCell from './SelectCell';
import DateCell from './DateCell';
import CheckboxCell from './CheckboxCell';
import GridCellContent from '../../../../../GridCellContent';

const Editor = ({
    datasetSelector,
    datasetLoadingSelector,
    syncGridDataWithServerAsync,
    updateRowsInGrid,
    handleCellEditModeOff,
    ...props
}) => {
    const { column, row } = props;
    const handleGridDataUpdate = useCallback(
        async newRow => {
            try {
                const updatedRowDataFromServer = await syncGridDataWithServerAsync(
                    newRow,
                    row
                );
                const updatedRowsDataArray = Array.isArray(
                    updatedRowDataFromServer
                )
                    ? updatedRowDataFromServer
                    : [updatedRowDataFromServer];
                updateRowsInGrid(updatedRowsDataArray);
                handleCellEditModeOff();
            } catch (error) {
                handleCellEditModeOff();
                console.error('Error updating row:', error);
            }
        },
        [
            syncGridDataWithServerAsync,
            row,
            updateRowsInGrid,
            handleCellEditModeOff
        ]
    );

    const renderInputCell = () => (
        <InputCell {...props} handleGridDataUpdate={handleGridDataUpdate} />
    );
    const renderSelectCell = () => {
        const { domain, entity } = column;
        const data = datasetSelector(domain, entity);
        const loading = datasetLoadingSelector(domain, entity);
        return (
            <SelectCell
                {...props}
                handleOnBlur={handleCellEditModeOff}
                data={data}
                loading={loading}
                handleGridDataUpdate={handleGridDataUpdate}
            />
        );
    };
    const renderDateCell = () => (
        <DateCell
            {...props}
            handleOnBlur={handleCellEditModeOff}
            handleGridDataUpdate={handleGridDataUpdate}
        />
    );
    const renderCheckboxCell = () => (
        <CheckboxCell {...props} handleGridDataUpdate={handleGridDataUpdate} />
    );
    const renderDefaultCell = () => (
        <GridCellContent content={row[column.name]} />
    );

    switch (column.type) {
        case 'number':
        case 'text':
            return renderInputCell();
        case 'select':
            return renderSelectCell();
        case 'date':
            return renderDateCell();
        case 'boolean':
            return renderCheckboxCell();
        default:
            return renderDefaultCell();
    }
};

export default Editor;
