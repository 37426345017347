import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';
import { CheckBoxIconBlank, CheckBoxIconFilled } from 'components';

const Checkbox = ({ label, checked, labelStyle }) => {
    return (
        <FormControlLabel
            control={
                <MUICheckbox
                    icon={
                        <CheckBoxIconBlank
                            width='24px'
                            height='24px'
                            color='white'
                        />
                    }
                    checkedIcon={
                        <CheckBoxIconFilled
                            width='24px'
                            height='24px'
                            color='white'
                        />
                    }
                    checked={checked}
                    value='checked'
                    onChange={() => {}}
                />
            }
            label={label}
            classes={{ label: labelStyle }}
        />
    );
};

export { Checkbox as default, Checkbox };
