import { SIGN_IN_SUCCESS } from 'actionTypes';
import { normalizeSignIn } from 'normalizers';
import { setCookie } from 'helpers/auth';

const commonReducer = (state, { type, payload }) => {
	switch (type) {
		case SIGN_IN_SUCCESS: {
			return {
				...state,
				...normalizeSignIn(payload)
			};
		}

		default: {
			return state;
		}
	}
};

export { commonReducer as default, commonReducer };
