import GreenTick from 'components/Global/assets/img/green-tick.svg';

export default theme => ({
    dialogPaper: {
        boxShadow: 'none',
        maxHeight: 'calc(100% - 300px)'
    },
    dialogContentRoot: {
        margin: 0,
        padding: 20
    },
    gridContainerStyle: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.md,
        margin: 0
    },
    gridContainerVpadding: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.md,
        margin: 0,
        marginTop: theme.spacing.unitPadding * -2
    },
    footer: {
        padding: 20
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px',
        '&::after': {
            content: `url(${GreenTick})`,
            marginLeft: 15,
            display: 'none'
        }
    },
    textField: {
        marginBottom: theme.spacing.unit * 2.5
    },
    paperRoot: {
        boxShadow: 'none',
        border: 'none'
    },
    dialogsTitle: {
        padding: '16px 24px'
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        height: 47,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    },
    browseButtonRoot: {
        border: '1px solid #e6e6e6',
        height: 47,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex',
        borderRadius: 4
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 47,
        flexGrow: 1,
        lineHeight: 47,
        paddingLeft: 15,
        '& span:not(:first-child):before': {
            content: '", "'
        }
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    },
    labelFileField: {
        '& + div': {
            display: 'none'
        }
    },
    dialogActionsRoot: {
        margin: 0,
        padding: 0
    }
});

