import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import useImageDnD from './useImageDnD';
import DropZone from './DropZone';

const propTypes = {
    onClick: PropTypes.func.isRequired
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: `calc(100% - ${spacing.unit * 4.5}px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '100%',
        height: 'var(--imageHeight)',
        objectFit: 'contain',
    }
});

const Content = withStyles(styles)(({ classes: c, onClick, ...props }) => {
    const [{ file }, dispatch] = useImageDnD();

    const handleDragEnter = useCallback(
        () => dispatch({ type: 'setDragging', payload: true }),
        [dispatch]
    );

    return (
        <div className={c.root} onDragEnter={handleDragEnter}>
            <DropZone handleClick={onClick} />
            {file && (
                <img className={c.image} src={file.url} alt={file.filename} />
            )}
        </div>
    );
});

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export { Content as default, Content };
