import { fork } from 'redux-saga/effects';

// Local
import commonWatchers from './common';

const authSaga = function*() {
    yield fork(commonWatchers);
};

export { authSaga as default, authSaga };
