import React, { useCallback } from 'react';

import { withStyles, Button } from '@material-ui/core';

import useManageColumnsContext from '../../../useManageColumnsContext';

const styles = ({ palette, spacing }) => ({
    root: {
        textTransform: 'none',
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: spacing.unit * 1,
        color: palette.primary.main
    }
});

const ModalContentToolbarClear = withStyles(styles)(({ classes }) => {
    const { dispatch } = useManageColumnsContext();

    const handleClear = useCallback(() => {
        dispatch({ type: 'SEARCH_CLEAR' });
    }, [dispatch]);

    return (
        <Button classes={classes} onClick={handleClear}>
            Clear
        </Button>
    );
});

export default ModalContentToolbarClear;
