import { createSelector } from 'reselect';

import { entitySelector } from '@libs/datasets';
import { userIdSelector } from 'auth/redux.selectors';

import { DOMAIN_NAME } from './common';

export const ARTWORK_SOURCES = '@artworkSources';
export const DESIGNERS = '@designers';
export const ALL_DESIGNERS = '@allDesigners';
export const LICENSORS = '@licensors';
export const PROPERTIES = '@properties';
export const SLEEVE_TYPES = '@sleeveTypes';
export const STYLES = '@Style';
export const SIZE_PATHS = '@cadSizePaths';
export const TYPES = '@types';

// Paginated
export const INCOMPLETE_CADS = '@incompleteCads';
export const AMEND_CADS = '@amendCads';

export default {
    [ARTWORK_SOURCES]: {
        url: 'ArtworkSource'
    },
    [DESIGNERS]: {
        url: 'Designer'
    },
    [ALL_DESIGNERS]: {
        url: 'Designer/GetAllDesigners'
    },
    [LICENSORS]: {
        url: 'Licensor'
    },
    [PROPERTIES]: {
        url: 'LicensorProperty'
    },
    [SLEEVE_TYPES]: {
        url: 'SleeveType'
    },
    [SIZE_PATHS]: {
        url: 'SizePath/GetSizePaths',
        params: { moduleName: 'cad' },
        selector: createSelector(
            state => entitySelector(state, DOMAIN_NAME, SIZE_PATHS),
            sizePaths => !Array.isArray(sizePaths)
        ),
        normalizer: ({ data: { data } }) => data
    },
    [STYLES]: {
        url: 'Style'
    },
    [TYPES]: {
        url: 'CadType'
    },
    [INCOMPLETE_CADS]: {
        url: 'Cad/GetIncompleteCads',
        fields: {
            cadRef: 'Reference',
            designerName: 'Designer',
            description: 'Description'
        },
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    },
    [AMEND_CADS]: {
        url: 'Cad/GetAmendCads',
        fields: {
            cadRef: 'Reference',
            designerName: 'Designer',
            description: 'Description'
        },
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    }
};
