import React, { createContext, useState, useContext } from 'react';

const initialState = {
    search: '',
    data: [],
    category: 0,
    categories: [],
    pageNumber: 1,
    pageSize: 10,
    filters: {},
    viewType: 'list',
    selectedItems: { originalIds: [], rowIds: {} },
    tileView: { show: false, data: [] },
    moduleName: 'grid',
    expanded: {},
    reportsInfo: {}
};

const commonReturnObject = {
    expanded: initialState.expanded,
    selectedItems: initialState.selectedItems
};

const GridViewState = createContext();

const GridViewDispatch = createContext();

const useGridViewState = () => {
    const state = useContext(GridViewState);
    if (state === void 0) {
        throw new Error(`useGridViewState must be used within its Provider`);
    }
    return state;
};

const useGridViewDispatch = () => {
    const state = useContext(GridViewDispatch);
    if (state === void 0) {
        throw new Error(`useGridViewDispatch must be used within its Provider`);
    }
    return state;
};

const useGridView = () => [useGridViewState(), useGridViewDispatch()];

const GridViewReducer = (state = initialState, middleware, action) => {
    const { type, payload } = middleware(state, action);
    switch (type) {
        case 'set-search': {
            return { search: payload, ...commonReturnObject };
        }
        case 'set-categories': {
            return {
                categories: payload,
                category: payload[0].value
            };
        }
        case 'set-category-and-query': {
            return { category: payload };
        }

        case 'clear': {
            const { search, category, filters } = initialState;
            return {
                search,
                category,
                filters,
                pageNumber: 1,
                pageSize: 10
            };
        }

        case 'set-filters': {
            const { filters } = state;
            return {
                filters: { ...filters, ...payload },
                ...commonReturnObject
            };
        }

        case 'set-pageNumber': {
            return {
                pageNumber: payload,
                ...commonReturnObject
            };
        }

        case 'set-pageSize': {
            return {
                pageSize: payload,
                ...commonReturnObject
            };
        }

        case 'view-change': {
            return { viewType: payload };
        }

        case 'grid-initialize': {
            return payload;
        }
        case 'set-selectedRows': {
            return { selectedItems: payload };
        }

        case 'update-expanded': {
            return { expanded: payload };
        }

        default:
            return {};
    }
};

const GridViewContextProvider = ({
    children,
    middleWare = (state, action) => action
}) => {
    const [state, setState] = useState(initialState);
    const dispatch = action => {
        const newState = GridViewReducer(state, middleWare, action);
        setState(prevState => ({ ...prevState, ...newState }));
    };
    return (
        <GridViewState.Provider value={state}>
            <GridViewDispatch.Provider value={dispatch}>
                {children}
            </GridViewDispatch.Provider>
        </GridViewState.Provider>
    );
};

export {
    useGridView as default,
    GridViewContextProvider,
    useGridViewState,
    useGridViewDispatch
};
