import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import { REQUEST_TYPES , SEND_TO_LICENSORS, SEND_TO_BUYERS, SEND_TO_UKS } from 'libs';


// Request Type
export const selectRequestTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[REQUEST_TYPES]
)(() => 'placeholder');


// Send to Buyer
export const selectSendToBuyersData = createCachedSelector(
    selectDatasets,
    datasets => datasets[SEND_TO_BUYERS]
)(() => 'placeholder');


// Send to Licensor
export const selectSendToLicensorsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[SEND_TO_LICENSORS]
)(() => 'placeholder');


// Send to Uk
export const selectSendToUksData = createCachedSelector(
    selectDatasets,
    datasets => datasets[SEND_TO_UKS]
)(() => 'placeholder');


// Todo: Unclean
const selectSampleReferences = createCachedSelector(
    selectDatasets,
    ({ sampleReferences }) => sampleReferences
)(({ datasets: { sampleReferences }, samplingPrint: { pageType } }) => {
    const pageData = sampleReferences.paginationData || null;
    const page = pageData ? pageData.currentPageNo : 0;

    return `${pageType}-${page}`;
});

export const selectSampleReferencesList = createCachedSelector(
    selectSampleReferences,
    ({ data, paginationMetadata }) => {
        if (!data) {
            return {
                rowInColumnWidth: [],
                paginationData: null,
                columnHeader: [],
                columnInfo: []
            };
        }

        const columnInfo = data.map(ref => {
            const {
                sampleReference,
                graphicReference,
                graphicDescription
            } = ref;
            const value = { value: sampleReference, fullData: ref };
            return [
                { title: sampleReference, value },
                { title: graphicReference, value },
                { title: graphicDescription, value }
            ];
        });

        return {
            rowInColumnWidth: [30, 30, 40],
            paginationData: paginationMetadata,
            columnHeader: ['Sample Ref', 'Graphic Ref', 'Graphic Description'],
            columnInfo
        };
    }
)(() => 'placeholder');
