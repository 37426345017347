import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Footer = ({ ...props }) => {
	const { classes } = props;

	return (
		<footer className={classes.footerBar}>
			&copy; {new Date().getFullYear()} <span>EchoLogyx</span>
		</footer>
	);
};

Footer.propTypes = {
	classes: PropTypes.object
};

const _Footer = withStyles(styles)(Footer);

export { _Footer as default, _Footer as Footer };
