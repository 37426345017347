import React, { memo } from 'react';
import { string, oneOf, object } from 'prop-types';

import { withStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const propTypes = {
    column: object.isRequired,
    sortedColumnName: string,
    direction: oneOf(['asc', 'desc'])
};

const styles = ({ spacing, palette }) => ({
    gridHeaderSort: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: `0px ${spacing.unit * 0.5}px`
    },
    gridHeaderSortIcon: {
        color: palette.grey[500],
        '&:hover': {
            color: palette.grey[700]
        },
        transform: 'scale(0.8)'
    }
});

const Sorting = withStyles(styles)(
    memo(({ classes: c, column, sortedColumnName, direction }) => {
        return (
            sortedColumnName === column.name && (
                <div className={c.gridHeaderSort}>
                    {direction === 'desc' ? (
                        <KeyboardArrowDown className={c.gridHeaderSortIcon} />
                    ) : (
                        <KeyboardArrowUp className={c.gridHeaderSortIcon} />
                    )}
                </div>
            )
        );
    })
);

Sorting.propTypes = propTypes;

export default Sorting;
