import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Local
import MultiSelectModel from '@formComponents/MultiSelectModel';
import {
    DOMAIN_NAME,
    DISPATCH_DETAILS_FORM_NAME
} from 'sampling/SampleDispatch/common';
import { DISPATCH_SAMPLES_BY_ORDER_NO } from 'sampling/redux.datasets';

// Datasets
import { datasetLoadingSelector, entitySelector } from '@libs/datasets';

const dataSelector = createCachedSelector(
    entitySelector,
    (state, _, __, sampleRef) => sampleRef,
    (state, _, __, ___, colour) => colour,
    (data, sampleRef, colour) => {
        if (!sampleRef || !colour) return [];
        const { po = [] } =
            data.find(value => value.sampleRef === sampleRef) || {};

        return po
            .filter(value => value.colour === colour)
            .map(({ poNo }) => ({ label: poNo, value: poNo }));
    }
)(
    (state, domain, entity, sampleRef, colour) =>
        `@data-${domain}-${entity}-${sampleRef}-${colour}`
);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'PONumbers',
    label: 'PO',
    domain: DOMAIN_NAME,
    entity: DISPATCH_SAMPLES_BY_ORDER_NO,
    disabled: false,
    required: true,
    defaultValues: ['*']
};

const mapState = (state, { domain, entity }) => {
    const { sampleRef, colour } = formValueSelector(DISPATCH_DETAILS_FORM_NAME)(
        state,
        'sampleRef',
        'colour'
    );
    return {
        data: dataSelector(state, domain, entity, sampleRef, colour),
        loading: datasetLoadingSelector(state, domain, entity),
        disabled: !colour
    };
};

const SelectPOByOrderColour = connect(mapState)(props => {
    return <MultiSelectModel {...props} />;
});

SelectPOByOrderColour.propTypes = propTypes;
SelectPOByOrderColour.defaultProps = defaultProps;

export default SelectPOByOrderColour;
