import { API_BASE_URL, apiClient } from 'store/config';
import { apiRequest } from 'helpers';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';

// Action Types
import {
    FETCH_SAMPLE_INFO_REQUEST,
    FETCH_SAMPLE_INFO_SUCCESS,
    FETCH_SAMPLE_REFERENCES,
    FETCH_SAMPLE_DATA
} from 'store/actionTypes';

// Actions
import {
    setSampleReferences,
    resetAmends,
    setAmends,
    setFabrics
} from 'actions';

// Selectors
import { selectUserId, selectPageType, selectParsedFabrics } from 'selectors';

// Normalizers
import { normalizeSampleData, normalizeAmendSampleData } from 'normalizers';

// WTF
async function getSampleInfoRefList({ ref, id = null }) {
    apiClient.defaults.headers.common['xtype'] = FETCH_SAMPLE_INFO_REQUEST;

    // id: 1 = incomplete, 2 =  erefrwe
    let reqUrl = `api/SampleRequestMains/GetPrintCopySampleInfo?sampleReferenceId=${ref}`;
    if (id && id === 1) {
        reqUrl = `api/SampleRequestMains/GetIncompleteSampleInfo?sampleReferenceId=${ref}`;
    } else if (id && id === 2) {
        reqUrl = `api/SampleRequestMains/GetAmendSampleInfo?sampleReferenceId=${ref}`;
    }

    return await apiClient.get(API_BASE_URL + reqUrl);
}

//workers

// Dirty
function* sampleInfoEffectSaga({ payload }) {
    const {
        data: {
            data: [sampleData]
        }
    } = yield call(getSampleInfoRefList, payload);

    const [formData, { fabrics, ...storeData }] = normalizeSampleData(
        sampleData
    );

    const parsedFabrics = yield select(selectParsedFabrics, fabrics);

    yield put(setFabrics(parsedFabrics));
    yield put(initialize('SamplingPrintForm', formData, { keepValues: true }));
    yield put({
        type: FETCH_SAMPLE_INFO_SUCCESS,
        payload: storeData
    });
}

// Pristine
function* fetchSampleReferencesWorker({
    payload: { pageNumber = 1, searchKey = '' } = {}
}) {
    const isAmendPageType = yield select(selectPageType, 'amend');
    const userId = yield select(selectUserId);

    const { data } = yield call(apiRequest, {
        url: '/api/SampleRequestMains/GetSamplePrintDropDown',
        params: {
            userId,
            type: isAmendPageType ? 'amend' : 'incomplete',
            pageSize: 10,
            pageNumber,
            filterKey: searchKey
        }
    });

    // Todo: change later
    yield put(
        setSampleReferences(Object.splice(data, ['data', 'paginationMetadata']))
    );
}

function* setIncompleteSampleDataWorker(sampleReferenceId) {
    const {
        data: { data: [sampleData = {}] = [] } = {}
    } = yield sampleReferenceId &&
        call(apiRequest, {
            url: `api/SampleRequestMains/GetIncompleteSampleInfo`,
            params: { sampleReferenceId }
        });
    const [formData, { fabrics, ...storeData }] = normalizeSampleData(
        sampleData
    );

    const parsedFabrics = yield select(selectParsedFabrics, fabrics);

    yield put(setFabrics(parsedFabrics));
    yield put(initialize('SamplingPrintForm', formData));
    yield put({
        type: FETCH_SAMPLE_INFO_SUCCESS,
        payload: storeData
    });
}

function* setAmendSampleDataWorker(sampleReferenceId) {
    const {
        data: { data: { sample = [], color = {} } = {} } = {}
    } = yield sampleReferenceId &&
        call(apiRequest, {
            url: `api/SampleRequestMains/GetAmendSampleInfo`,
            params: { sampleReferenceId }
        });

    const [
        formData,
        { fabrics, ...storeData },
        amendedFields
    ] = normalizeAmendSampleData(sample, color);

    const parsedFabrics = yield select(selectParsedFabrics, fabrics);

    yield put(setFabrics(parsedFabrics));
    yield put(initialize('SamplingPrintForm', formData));
    yield put({
        type: FETCH_SAMPLE_INFO_SUCCESS,
        payload: storeData
    });

    yield put(resetAmends());
    yield put(setAmends(amendedFields));
}

function* fetchSampleDataWorker({ payload: { sampleReferenceId } }) {
    const isAmendPageType = yield select(selectPageType, 'amend');
    if (isAmendPageType) {
        yield call(setAmendSampleDataWorker, sampleReferenceId);
    } else {
        yield call(setIncompleteSampleDataWorker, sampleReferenceId);
    }
}

// Watchers
const referenceWatchers = function*() {
    yield takeLatest(FETCH_SAMPLE_INFO_REQUEST, sampleInfoEffectSaga);
    yield takeLatest(FETCH_SAMPLE_REFERENCES, fetchSampleReferencesWorker);
    yield takeLatest(FETCH_SAMPLE_DATA, fetchSampleDataWorker);
};

export { referenceWatchers as default, referenceWatchers };
