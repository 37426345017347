import React from 'react';

// Material
import { Button, withStyles } from '@material-ui/core';

// Local
import styles from './styles';
import { Header } from 'components';
import { ErrorIcon } from 'assets';

// Assets
import { CheckIcon } from 'assets';

const SamplingFabricsHeader = ({ classes: c, fabricExists, modalHandler }) => {
    return (
        <Header title='Fabric Details' styles={Object.splice(c, ['toolbar'])}>
            {fabricExists ? (
                <CheckIcon className={c.icon} />
            ) : (
                <ErrorIcon className={c.icon} />
            )}
            {fabricExists && (
                <Button
                    variant='contained'
                    className={c.button}
                    onClick={modalHandler}
                >
                    Add more
                </Button>
            )}
        </Header>
    );
};

const _SamplingFabricsHeader = withStyles(styles)(SamplingFabricsHeader);

export {
    _SamplingFabricsHeader as default,
    _SamplingFabricsHeader as SamplingFabricsHeader
};
