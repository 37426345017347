import React, { memo } from 'react';
import { withStyles, Paper } from '@material-ui/core';

import PageInformation from './PageInformation';
import Pagination from './Pagination';
import SelectPageSize from './SelectPageSize';

const styles = ({ palette, spacing, shadows }) => ({
    gridPaginationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        height: spacing.unit * 8,
        padding: `${spacing.unit}px ${spacing.unit * 2}px`,

        backgroundColor: palette.background.light,

        borderRadius: 0,
        boxShadow: shadows[0],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark
    }
});

const PaginationContainer = withStyles(styles)(
    memo(({ classes, paginationProps, pageSizes }) => {
        const {
            currentPage,
            totalPages,
            totalCount,
            pageSize,
            setPageSize
        } = paginationProps;

        return (
            <Paper className={classes.gridPaginationContainer}>
                <PageInformation
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalCount={totalCount}
                />
                <Pagination {...paginationProps} />
                <SelectPageSize
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    data={pageSizes}
                />
            </Paper>
        );
    })
);

export default PaginationContainer;
