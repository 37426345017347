import React, { useState } from 'react';

import { withStyles, Tabs, Tab } from '@material-ui/core';

import MenuPermissionTab from './MenuPermissionTab';
import RetailerPermissionTab from './RetailerPermissionTab';
import SampleFactoryPermissionTab from './SampleFactoryPermissionTab';
import OrderFactoryPermissionTab from './OrderFactoryPermissionTab';

const TABS = [
    '1. Menu',
    '2. Retailer',
    '3. Sample Factory',
    '4. Order Factory'
];

const styles = ({ spacing, palette, typography }) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff'
    },
    tabsRoot: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    tabsIndicator: {
        backgroundColor: palette.secondary.main
    },
    tabRoot: {
        textTransform: 'initial',
        width: '100%',
        fontWeight: typography.fontWeightRegular,
        transition: 'background-color 0.3s ease-in-out',

        '&:hover': {
            color: palette.secondary.main,
            backgroundColor: '#dbdee0',
            opacity: 1
        },
        '&$tabSelected': {
            color: palette.secondary.main,
            backgroundColor: '#dbdee0',
            fontWeight: typography.fontWeightBold
        },
        '&:focus': {
            color: palette.secondary.main
        },
        '&:not(:last-child)': {
            borderRightStyle: 'solid',
            borderRightWidth: 1,
            borderRightColor: palette.background.dark
        }
    },
    tabSelected: {},
    tabLabel: {
        fontSize: spacing.unit * 2,
        fontWeight: typography.fontWeightBold
    }
});

const PermissionTabs = withStyles(styles)(({ classes: c }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={c.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant='fullWidth'
                classes={{
                    root: c.tabsRoot,
                    indicator: c.tabsIndicator
                }}
            >
                {TABS.map((tab, index) => (
                    <Tab
                        key={index}
                        disableRipple
                        classes={{
                            root: c.tabRoot,
                            label: c.tabLabel,
                            selected: c.tabSelected
                        }}
                        label={tab}
                    />
                ))}
            </Tabs>
            {value === 0 && <MenuPermissionTab />}
            {value === 1 && <RetailerPermissionTab />}
            {value === 2 && <SampleFactoryPermissionTab />}
            {value === 3 && <OrderFactoryPermissionTab />}
        </div>
    );
});

export default PermissionTabs;
