const DOMAIN_NAME = 'sampling';

const MAIN_BODY_FABRIC_ID = 1;
const ARTWORK_SOURCE_LICENSED_ID = 2;

const SAMPLING_GRID_EDIT_INFO = {
    endpoint: 'Sample/SamplePrintAndShapeGridViewDataEdit'
};
const SAMPLING_GRID_COST_COLUMNS = [
    { header: 'ECHO COST', value: '', type: 'text' },
    { header: 'ADDITONAL FEES', value: '', type: 'text' },
    { header: 'FINAL PRICE', value: '', type: 'text' },
    { header: 'HOD', value: '', type: 'text' }
];

export {
    DOMAIN_NAME,
    MAIN_BODY_FABRIC_ID,
    ARTWORK_SOURCE_LICENSED_ID,
    SAMPLING_GRID_EDIT_INFO,
    SAMPLING_GRID_COST_COLUMNS
};
