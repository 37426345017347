import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import { SelectModel } from '@formComponents';

// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { DOMAIN_NAME } from 'sampling/common';
import { DISPATCH_EMAIL_GROUP } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    retailer: PropTypes.number.isRequired
};

const defaultProps = {
    name: 'group',
    label: '',
    placeholder: 'To..',
    disabled: false,
    required: false,
    domain: DOMAIN_NAME,
    entity: DISPATCH_EMAIL_GROUP
};

const dataSelector = createCachedSelector(
    (state, domain, entity) => entitySelector(state, domain, entity),
    (state, domain, entity, retailer) => retailer,
    (groups, retailer) => {
        return groups
            .filter(({ retailerId }) => Number(retailerId) === retailer)
            .map(({ id, emailGroupName }) => ({
                label: emailGroupName,
                value: id
            }));
    }
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity, retailer }) => {
    return {
        data: dataSelector(state, domain, entity, retailer),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectMailGroup = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, retailer, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectMailGroup.propTypes = propTypes;
SelectMailGroup.defaultProps = defaultProps;

export { SelectMailGroup as default, SelectMailGroup };
