import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

//TODO: Refactor, has issues when using single function with withStyles

const ExpansionPanelSummary = withStyles(theme => {
	return {
		root: {
			backgroundColor: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[200]}`,
			marginBottom: -1,
			minHeight: theme.spacing.unit * 7,
			padding: '0 16px 0 16px',
			'&$expanded': {
				minHeight: theme.spacing.unit * 7,
				backgroundColor: theme.palette.grey[100]
			}
		},
		content: {
			'&$expanded': {
				margin: '12px 0'
			}
		},
		expanded: {}
	};
})(MuiExpansionPanelSummary);

const ReverseExpansionPanelSummary = withStyles(theme => {
	return {
		root: {
			backgroundColor: '#1d1c1c',
			borderBottom: `2px solid #232323`,
			color: theme.palette.common.white,
			marginBottom: -1,
			minHeight: theme.spacing.unit * 7,
			padding: '0 16px 0 16px',
			'&$expanded': {
				minHeight: theme.spacing.unit * 7
			}
		},
		content: {
			'&$expanded': {
				margin: '12px 0'
			}
		},
		expanded: {}
	};
})(MuiExpansionPanelSummary);

export {
	ExpansionPanelSummary as default,
	ExpansionPanelSummary,
	ReverseExpansionPanelSummary
};
