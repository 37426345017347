import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { QCMailSubject } from 'qualityControl/redux.selectors';
import { setQCMailBody, setQCMailReceiver } from 'qualityControl/redux.actions';
import SelectMailGroup from './SelectMailGroup';
import SelectMailTemplate from './SelectMailTemplate';

const mapStateToProps = (state, { mode }) => ({
    emailSubject: QCMailSubject(state, mode)
});

const mapInputs = {
    group: ({ payload }) => setQCMailReceiver(payload),
    template: ({ payload }) => setQCMailBody(payload)
};

const MailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: (values, dispatch, { handleSave }) => {
            handleSave(values);
        }
    }),
    connect(mapStateToProps),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        handleSubmit,
        valid,
        handlePublish,
        closePreviewModal,
        emailSubject,
        initialize
    }) => {
        const publishCallbackHandler = handlePublish({
            hasEmail: true,
            closePreviewModal,
            closeMailModal
        });

        const handleSendMail = useCallback(() => {
            handleSubmit();
            setTimeout(() => publishCallbackHandler(), 1);
        }, [handleSubmit, publishCallbackHandler]);

        useEffect(() => {
            initialize({ subject: emailSubject });
        }, []);

        const mailProps = {
            MailGroupComponent: <SelectMailGroup />,
            MailTemplatesComponent: <SelectMailTemplate />,
            handleModalClose: closeMailModal,
            handleSubmit: handleSendMail,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default MailModal;
