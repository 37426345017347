import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { makeSideEffectCallback } from '@libs/makeContext';
import { setSnack } from 'utilities/redux.actions';
import ContentContainer from '@libComponents/ContentContainer';
import { withField } from '@libs/reduxForm';
import { withModalProvider, withModal } from '@libComponents/Modal';
import ActionsAdornment from './ActionsAdornment';
import Content from './Content';
import Email from './Email';
import { printReportPropsSelector } from 'sampling/redux.selectors';
import { useReport } from 'sampling/hooks';
import { loadProps } from '../../../Print/components/hooks';
import { Iframe } from 'common/components';
import { usePrintState } from '../../../Print/usePrint';
import { MailFormProvider, useStoreEmailFormData } from '@libHooks/useMailForm';

const styles = ({ breakpoints }) => ({
    container: {
        width: breakpoints.values.md,
        minHeight: '80vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'mailData'
};

const mapStateToProps = (state, { mode }) => ({
    printReportProps: printReportPropsSelector(state, mode)
});

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Preview = compose(
    withModalProvider,
    withModal({
        handleModal: Email
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleHandover,
        printReportProps,
        setSnack,
        handleModal,
        input,
        meta: { form },
        mode
    }) => {
        const { sampleRefLoading } = usePrintState();
        const [pdfLoading, setPdfloading] = useState(true);
        const propsLoadedRef = useRef(0);
        const [generatePDFURL, PDFBlob, handleDocRemove] = useReport({
            fileName: 'print_handover.pdf',
            document: Content,
            props: printReportProps,
            loadProps,
            setSnack
        });

        const { value, onChange } = input;
        const [action, setAction] = useState({});

        const initialize = useCallback(
            state => ({
                ...state,
                mail: value || {}
            }),
            [value]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const handlePreviewModalClose = useCallback(() => {
            handleDocRemove(); // re initialize PDFBlob obj
            setAction({ type: 'removePDF' }); //release blob url from memory
            setTimeout(() => onClose(), 0);
        }, [onClose, handleDocRemove]);

        const handleMailModal = useMemo(
            () => props =>
                handleModal({
                    ...props,
                    formName: form,
                    handleHandover,
                    closePreviewModal: handlePreviewModalClose,
                    mode
                }),
            [handleModal, form, mode]
        );

        const handleHandoverEmail = useStoreEmailFormData({
            handleModal: handleMailModal,
            setAction,
            formName: form
        });

        const handlePDFAdd = useCallback(pdfInfo => {
            setAction({ type: 'addPDF', payload: pdfInfo });
        }, []);

        useEffect(() => {
            if (
                !!Object.keys(printReportProps.setup).length &&
                propsLoadedRef.current === 0 &&
                !sampleRefLoading
            ) {
                propsLoadedRef.current = 1;
                generatePDFURL();
            }
        }, [printReportProps]);

        useEffect(() => {
            if (PDFBlob && PDFBlob.url) {
                setPdfloading(false);
                handlePDFAdd({ pdf: PDFBlob });
            }
        }, [PDFBlob]);

        return (
            <MailFormProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Preview'
                    AdornmentComponent={
                        <ActionsAdornment
                            closeModal={handlePreviewModalClose}
                            handleHandoverEmail={handleHandoverEmail}
                            isPDFLoading={pdfLoading || sampleRefLoading}
                        />
                    }
                    styles={{ container: c.container }}
                >
                    {!pdfLoading && !sampleRefLoading ? (
                        <Iframe src={PDFBlob.url} />
                    ) : (
                        <CircularProgress
                            size='50px'
                            style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                position: 'absolute',
                                top: '50%'
                            }}
                        />
                    )}
                </ContentContainer>
            </MailFormProvider>
        );
    }
);

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
