import React from 'react';
import { withStyles } from '@material-ui/core';
import { func, bool } from 'prop-types';

import Button from '@libComponents/Button';

const propTypes = {
    handlePreviewModalClose: func.isRequired,
    handleMailForm: func.isRequired,
    loading: bool.isRequired,
    isPublishPreview: bool.isRequired
};

const styles = ({ spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },
    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const ActionsAdornment = withStyles(styles)(
    ({
        classes: c,
        handlePreviewModalClose,
        handleMailForm,
        loading,
        isPublishPreview
    }) => {
        return (
            <div className={c.container}>
                <Button
                    className={c.button}
                    onClick={handleMailForm}
                    disabled={loading}
                >
                    {isPublishPreview ? 'Publish' : 'Email'}
                </Button>
                <Button
                    color='error'
                    className={c.button}
                    onClick={handlePreviewModalClose}
                >
                    Close
                </Button>
            </div>
        );
    }
);

ActionsAdornment.propTypes = propTypes;

export default ActionsAdornment;
