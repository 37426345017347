import React from 'react';
import Typography from '@material-ui/core/Typography';
import { imgTypes, fileSize } from 'helpers/filesValidation';

function ImageHelperText(props) {
    return (
        <Typography
            style={{
                color: '#aeaeae',
                marginTop: 15,
                marginBottom: 20,
            }}
        >
            * Allowed file extensions: {imgTypes.join(', ')}
            <br />
            <span>* Maximum file size: {fileSize.megabytes} MB.</span>
        </Typography>
    );
}

export { ImageHelperText as default, ImageHelperText };
