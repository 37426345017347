import React from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from '@material-ui/core';

import { Button } from '@libComponents/Button';

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    mutationText: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
};

const styles = ({ spacing, palette, typography }) => ({
    container: {
        fontFamily: 'Museo'
    },
    content: {
        borderTopWidth: 1,
        borderTopColor: palette.background.dark,
        borderTopStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,
        borderBottomStyle: 'solid'
    },
    confirmText: {
        fontSize: spacing.unit * 1.75,
        fontWeight: 400,
        color: palette.grey[800],
        lineHeight: 1.5,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2
    },
    button: {
        width: spacing.unit * 12,
        height: spacing.unit * 4,
        fontSize: typography.subtitle1.fontSize
    }
});

const GridConfirmDialog = withStyles(styles)(
    ({
        classes: c,
        open,
        onClose,
        onConfirm,
        mutationText,
        loading,
        disabled
    }) => {
        return (
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={onClose}
                className={c.container}
                disableBackdropClick
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent className={c.content}>
                    <Typography className={c.confirmText}>
                        {`Please confirm you are changing the ${mutationText}`}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={c.button}
                        color='error'
                        onClick={onClose}
                        disabled={loading}
                    >
                        Deny
                    </Button>
                    <Button
                        className={c.button}
                        onClick={onConfirm}
                        loading={loading}
                        disabled={disabled}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

GridConfirmDialog.propTypes = propTypes;

export { GridConfirmDialog as default, GridConfirmDialog };
