import React from 'react';

// Material
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: fade(palette.secondary.main, 0.05),
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0
    }
});

const LoadingScreen = withStyles(styles)(({ classes: c }) => (
    <div className={c.root}></div>
));

export { LoadingScreen as default, LoadingScreen };
