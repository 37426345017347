export default ({ typography, palette, spacing }) => ({
    root: {
        height: spacing.unit * 5,
        backgroundColor: palette.background.light,
        borderTop: `1px solid ${palette.grey[200]}`,
        borderBottom: `1px solid ${palette.grey[200]}`
    },
    title: {
        fontSize: typography.fontSize,
        fontWeight: typography.fontWeightBold
    }
});
