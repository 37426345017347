import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const useComputedStyle = (
    property,
    options = {
        initialState: null,
        transformProperty: property => property.slice(0, -2),
        transformScalar: property => Number(property)
    }
) => {
    const { initialState, transformProperty, transformScalar } = options;

    const [computedStyle, setComputedStyle] = useState(initialState);
    const componentRef = useRef(null);

    useEffect(() => {
        const ref = ReactDOM.findDOMNode(componentRef.current);
        const propertyValue = window
            .getComputedStyle(ref)
            .getPropertyValue(property);

        const transformedProperty = transformProperty(propertyValue);
        const computedProperty = transformScalar(transformedProperty);

        setComputedStyle(Number(computedProperty));
    }, [componentRef.current]);

    return [componentRef, computedStyle];
};

export { useComputedStyle as default, useComputedStyle };
