import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiCardMedia from '@material-ui/core/CardMedia';

import styles from './styles';

const CardMedia = props => {
    const { classes, title, ...restProps } = props;

    return (
        <MuiCardMedia className={classes.media} title={title} {...restProps} />
    );
};

CardMedia.defaultProps = {
    title: ''
};

CardMedia.propTypes = {
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const _CardMedia = withStyles(styles)(CardMedia);

export { _CardMedia as default, _CardMedia as CardMedia };
