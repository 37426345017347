import React from 'react';
// Material
import { withStyles } from '@material-ui/core/styles';
import { Typography, CardContent } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/KeyboardArrowDown';

// Local
import styles from './styles';
import { Icon, Tooltip } from 'components';

/*
 * Domain: Reports
 * Page: --
 * Component: Attachments
 * Type: -- Item
 * ReportsAttachmentsItem
 */
const ReportsAttachmentsItem = ({
    classes: { container, icon },
    fileName,
    downloadUrl,
}) => {
    return (
        <CardContent className={container}>
            <Typography color='primary'>{fileName}</Typography>
            <Tooltip dark title='Download'>
                <Icon
                    className={icon}
                    onClick={() => window.location.assign(downloadUrl)}
                >
                    <DownloadIcon />
                </Icon>
            </Tooltip>
        </CardContent>
    );
};

const _ReportsAttachmentsItem = withStyles(styles)(ReportsAttachmentsItem);

export {
    _ReportsAttachmentsItem as default,
    _ReportsAttachmentsItem as ReportsAttachmentsItem,
};
