import React from 'react';
import PropTypes from 'prop-types';


// Local
import { DeleteIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';

const propTypes = {
    handleDelete: PropTypes.func.isRequired
};

const defaultProps = {};

const ActionsAdornment = ({ handleDelete }) => (
    <div>
        <TooltipAction
            IconComponent={DeleteIcon}
            title='Delete it!'
            onClick={handleDelete}
        />
    </div>
);

ActionsAdornment.propTypes = propTypes;
ActionsAdornment.defaultProps = defaultProps;

export default ActionsAdornment;
