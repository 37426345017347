import amendsState from './amends/initialState';
import loadersState from './loaders/initialState';
import modalsState from './modals/initialState';
import notificationState from './notification/initialState';

export default {
	...amendsState,
	...loadersState,
	...modalsState,
	...notificationState
};
