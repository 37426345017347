export const retailerSchema = {
    id: 0,
    retailer: 'string',
    code: 'string',
    slNo: 0,
    isActive: true
};

export const departmentSchema = {
    id: 0,
    department: 'string',
    contactPerson: 'string',
    departmentNo: 'string',
    isActive: true
};

export const styleSchema = {
    id: 0,
    style: 'string',
    code: 'string'
};

export const genderSchema = {
    id: 0,
    gender: 'string',
    code: 'string'
};

export const categorySchema = {
    id: 0,
    category: 'string',
    code: 'string'
};

export const countrySchema = {
    id: 0,
    name: 'string',
    shortName: 'string',
    isActive: true,
    isSample: false,
    isDispatch: false
};

export const ageSchema = {
    id: 0,
    age: 'string',
    code: 'string'
};

export const designDepartmentSchema = {
    id: 0,
    designDepartment: 'string',
    genderId: 0,
    isActive: true
};
