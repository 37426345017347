import React from 'react';
import { string, bool } from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    label: string.isRequired,
    value: string.isRequired,
    isLast: bool.isRequired,
    threeColumn: bool
};

const styles = ({ spacing, palette, typography }) => ({
    tableRow: {
        flexDirection: 'row',
        width: '100%',
        height: 'auto',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderBottomWidth: 1
    },
    emptyColumn: {
        width: '10%',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRightWidth: 1
    },
    tableHeader: {
        width: '80%',
        flexDirection: 'row',
        padding: spacing.unit * 0.5,
        alignItems: 'center'
    },
    tableHeaderCell: {
        fontWeight: typography.fontWeightHeavy,
        fontSize: 9,
        textTransform: 'uppercase'
    },
    tableCellText: { fontSize: 9 },
    tableCell: {
        width: '20%',
        minHeight: 0,
        paddingLeft: spacing.unit * 1.75,
        paddingRight: spacing.unit * 1.75,
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderLeftWidth: 1
    }
});

const Row = withPDFStyles(styles)(
    ({ classes: c, label, value, isLast, threeColumn }) => {
        return (
            <View
                style={{
                    ...c.tableRow,
                    ...(isLast ? { borderBottomWidth: 0 } : {})
                }}
            >
                {threeColumn && <View style={c.emptyColumn}></View>}
                <View
                    style={{
                        ...c.tableHeader,
                        ...(threeColumn ? { width: '70%' } : {})
                    }}
                >
                    <Text style={c.tableHeaderCell}>{label}</Text>
                </View>
                <View style={c.tableCell}>
                    <Text style={c.tableCellText}>{value}</Text>
                </View>
            </View>
        );
    }
);

Row.propTypes = propTypes;

export default Row;
