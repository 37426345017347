import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

const propTypes = {
    header: PropTypes.string.isRequired,
    value: PropTypes.string
};

const styles = ({ spacing }) => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        lineHeight: 1.5,
        marginBottom: spacing.unit * 1
    },
    rowHeader: {
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.25,
        marginRight: spacing.unit * 0.5
    },
    rowValue: {
        fontSize: spacing.unit * 1.25,
        marginRight: spacing.unit * 1.5,
        maxWidth: '75%'
    }
});

const Row = withPDFStyles(styles)(({ classes: c, header, value }) => {
    return (
        <View style={c.row}>
            <Text style={c.rowHeader}>{header}:</Text>
            <Text style={c.rowValue}>{value}</Text>
        </View>
    );
});

Row.propTypes = propTypes;

export default Row;
