import { LOGOUT } from 'auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import {
    SET_GRID_DATA,
    SET_GRID_LOADING,
    GRID_ROW_UPDATE_LOADING,
    SET_GRID_REPORT_LOADING,
    SET_ACCUMULATED_DATA,
    SET_PREVIEW_DATA,
    SET_PREVIEW_LOADING
} from './redux.actionTypes';

const initialState = {
    rowUpdateLoading: {},
    reportLoading: {},
    previewDataLoading: {},
    previewData: {},
    loading: {}
};

export default (state = initialState, { type, payload, meta, ...action }) => {
    switch (type) {
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }
        case SET_GRID_DATA: {
            const { entity } = meta;
            const { dataset: data, ...rest } = payload;
            return { ...state, [entity]: { data, ...rest } };
        }
        case SET_GRID_LOADING: {
            const { entity } = meta;
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [entity]: payload
                }
            };
        }
        case GRID_ROW_UPDATE_LOADING: {
            const { rowId, value } = payload;
            return { ...state, rowUpdateLoading: { [rowId]: value } };
        }
        case SET_GRID_REPORT_LOADING: {
            return {
                ...state,
                reportLoading: { ...state.reportLoading, ...payload }
            };
        }
        case SET_ACCUMULATED_DATA: {
            const { moduleName } = meta;
            return { ...state, [moduleName + 'Accumulation']: payload };
        }
        case SET_PREVIEW_DATA: {
            const { entity } = meta;
            return { ...state, previewData: { [entity]: payload[0] } };
        }
        case SET_PREVIEW_LOADING: {
            const { entity } = meta;
            return { ...state, previewDataLoading: { [entity]: payload } };
        }
        default: {
            return state;
        }
    }
};
