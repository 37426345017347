import {
    SET_NOTIFICATION_SUCCESS,
    RESET_NOTIFICATION_SUCCESS
} from 'actionTypes';

const notificationReducer = (state, { type, payload }) => {
    switch (type) {
        case SET_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notification: payload
            };
        }

        case RESET_NOTIFICATION_SUCCESS: {
            return state;
        }

        default: {
            return state;
        }
    }
};

export { notificationReducer as default, notificationReducer };
