import { useEffect, useState, useCallback, useMemo } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

export const usePDFProps = (props, loadProps) => {
    const [propsReady, setPropsReady] = useState(false);
    const [loadedProps, setLoadedProps] = useState(null);
    const handleLoadProps = useCallback(async () => {
        try {
            const loadedProps = await loadProps(props);
            setLoadedProps(loadedProps);
            setPropsReady(true);
        } catch (e) {
            console.error(e);
        }
    }, [props]);
    useEffect(() => {
        if (props !== null) handleLoadProps();
    }, [props]);
    return [propsReady, loadedProps];
};

export const useCustomDate = date =>
    useMemo(() => {
        if (!date) return null;
        const monthObj = {
            '01': 'Jan',
            '02': 'Feb',
            '03': 'Mar',
            '04': 'Apr',
            '05': 'May',
            '06': 'Jun',
            '07': 'Jul',
            '08': 'Aug',
            '09': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec'
        };
        const [day, mon, year] = date.split('/');
        return `${day}-${monthObj[mon]}-${year}`;
    }, [date]);

export const useReport = ({
    fileName,
    document,
    props,
    loadProps,
    setSnack
}) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleClick, loading, disabled, report, handleRemove] = usePDF({
        document,
        fileName,
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleClick
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleClick, report, handleRemove];
};

export const useReportSend = ({ handleModal, setAction, formName }) => {
    const handleSave = useCallback(values => {
        setAction({ type: 'sendReport', payload: values });
    }, []);

    return useCallback(() => {
        handleModal({ handleSave, formName });
    }, [handleModal, handleSave, formName]);
};
