import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { DESIGN_SOURCES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'designSource',
    label: 'Where is the design from',
    placeholder: 'Select design source',
    disabled: false,
    required: false
};

const SelectDesignSources = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={DESIGN_SOURCES} />
);

SelectDesignSources.propTypes = propTypes;
SelectDesignSources.defaultProps = defaultProps;

export { SelectDesignSources as default, SelectDesignSources };
