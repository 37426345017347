export default theme => ({
    loader: {
        color: '#ffffff',
        position: 'absolute'
    },
    containerStyle: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        //overflowX: 'hidden',
        width: theme.breakpoints.values.md,
        margin: 0
    },
    footer: {
        padding: 20
    },
    dialogPaper: {
        boxShadow: '0px 0px 21px 0px rgba(0, 4, 52, 0.27)'
    },
    dialogContentRoot: {
        margin: 0,
        padding: 20
    },
    dialogActionsRoot: {
        margin: 0,
        padding: 0
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    }
});

