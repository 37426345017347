import { useMemo } from 'react';

export const useRowStyle = ({
    classes: c,
    theme: { spacing },
    isLast,
    lines
}) => {
    const tableRow = useMemo(
        () => ({
            ...c.tableRow,
            ...(isLast ? { borderBottomWidth: 0 } : {}),
            height: spacing.unit * 3.75 * lines
        }),
        [isLast, lines, spacing, c]
    );

    const tableCell = useMemo(
        () => ({
            ...c.tableCell,
            ...(lines > 1
                ? {
                      paddingTop: spacing.unit * 1.1,
                      justifyContent: 'flex-start'
                  }
                : {})
        }),
        [lines, spacing, c]
    );

    const tableCellText = useMemo(
        () => ({
            ...c.tableCellText,
            ...(lines > 1 ? { lineHeight: 1.6 } : {})
        }),
        [lines, c]
    );

    const tableCellRequired = { ...c.tableCellText, ...c.tableCellRequired };

    return { ...c, tableRow, tableCell, tableCellText, tableCellRequired };
};
