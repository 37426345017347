import React, { Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/InsertPhotoOutlined';

import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';
import { DownloadLink } from 'components';
import useBlobURL from '@libHooks/useBlobURL';

const styles = ({ spacing, palette, shadows }) => ({
    wrapper: {
        marginBottom: spacing.unit * 2
    },
    previewContainer: {
        width: '100%',
        minHeight: spacing.unit * 20,
        boxShadow: shadows[0],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRadius: 0,
        marginBottom: spacing.unit * 1
    },
    header: {
        height: spacing.unit * 5.5
    },
    imageContainer: {
        width: '100%',
        height: spacing.unit * 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.background.main
    },
    image: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain'
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: palette.background.dark
    },
    icon: {
        color: palette.background.dark,
        fontSize: spacing.unit * 4
    }
});

const propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

const ImageCard = compose(
    withField(),
    withStyles(styles)
)(({ input: { value }, title, classes: c }) => {
    const { url, link, filename } = value || {};
    const [blobUrl, blobLoading] = useBlobURL(link);

    return (
        <div className={c.wrapper}>
            <ContentContainer
                title={title}
                styles={{ container: c.previewContainer, header: c.header }}
                AdornmentComponent={<Fragment />}
            >
                <div className={c.imageContainer}>
                    {url ? (
                        <img src={url} alt={filename} className={c.image} />
                    ) : (
                        <div className={c.image}>
                            <div className={c.empty}>
                                <ImageIcon className={c.icon} />
                            </div>
                        </div>
                    )}
                </div>
            </ContentContainer>
            {link && (
                <DownloadLink
                    url={blobUrl}
                    name={filename}
                    loading={blobLoading}
                    showIcon={false}
                />
            )}
        </div>
    );
});

ImageCard.propTypes = propTypes;

export default ImageCard;
