import { formValueSelector } from 'redux-form';

import { userRoleIdSelector, userEmailSelector } from 'auth/redux.selectors';

import { FORM_NAME } from './User/common';
import { normalizeUsersList } from './redux.normalizers';

export const USER_ROLES = '@userRoles';
export const REPORT_TO = '@reportTo';
export const COPY_USERS_LIST = '@copyUsersList';
export const AMEND_USERS_LIST = '@amendUsersList';

// User List data grid datasets
export const USER_LIST_GRID_DATA = '@userListGridData';

export default {
    [USER_ROLES]: {
        url: 'UserRole'
    },
    [REPORT_TO]: {
        url: 'User/GetReportUserList',
        params: state => ({ roleId: userRoleIdSelector(state) })
    },
    [COPY_USERS_LIST]: {
        url: 'User/GetCopyUserList',
        params: state => {
            const { role, reportTo } = formValueSelector(FORM_NAME)(
                state,
                'role',
                'reportTo'
            );
            return {
                reportUserIds: reportTo && reportTo.join(','),
                roleId: role
            };
        },
        fields: {
            email: 'Email',
            name: 'Name'
        },
        selector: () => false
    },
    [AMEND_USERS_LIST]: {
        url: 'User/GetUsersListByLoggedInUser',
        params: state => ({
            email: userEmailSelector(state),
            roleId: userRoleIdSelector(state)
        }),
        fields: {
            email: 'Email',
            name: 'Name',
            isActive: 'Active'
        },
        normalizer: normalizeUsersList,
        selector: () => false
    }
};
