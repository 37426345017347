import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from 'orders/common';
import { PRODUCTION_SAMPLE_TYPE } from 'orders/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: PRODUCTION_SAMPLE_TYPE,
    name: 'productionSampleType',
    label: 'Production Sample Type',
    placeholder: 'Select Prod Sample Type',
    disabled: false,
    required: false
};

const SelectProductionSampleType = props => <Select {...props} />;

SelectProductionSampleType.propTypes = propTypes;
SelectProductionSampleType.defaultProps = defaultProps;

export { SelectProductionSampleType as default, SelectProductionSampleType };
