// Action Types
import {} from 'store/actionTypes';

const fabricReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default: {
            return state;
        }
    }
};

export { fabricReducer as default, fabricReducer };
