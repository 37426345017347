import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Image from './Image';
import ColumnContainer from './ColumnContainer';
import Row from './Row';
import Divider from './Divider';

const propTypes = {
    item: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired
};

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        paddingTop: spacing.unit * 3,
        paddingBottom: spacing.unit * 3,
        flexDirection: 'row'
    },
    imageContainer: {
        width: '30%',
        maxHeight: spacing.unit * 25,
        marginRight: spacing.unit * 2.5
    },
    contentContainer: {
        width: '70%',
        flexDirection: 'row'
    }
});

const Item = withPDFStyles(styles)(
    ({
        classes: c,
        item: {
            cadImage,
            cadRef,
            buyerRef,
            devNo,
            processedBy,
            cadType,
            licensor,
            property,
            retailer,
            gender,
            age,
            category,
            department,
            style,
            artworkSource,
            shapeDesigner,
            cadDescription,
            availability
        },
        isLast
    }) => {
        return (
            <View>
                <View style={c.container}>
                    <View style={c.imageContainer}>
                        <Image url={cadImage} label='Cad' />
                    </View>
                    <View style={c.contentContainer}>
                        <ColumnContainer styles={{ width: '50%' }}>
                            <Row header='Cad Ref' value={cadRef} />
                            <Row header='Buyer Ref' value={buyerRef} />
                            <Row header='Dev No' value={devNo} />
                            <Row header='Processed By' value={processedBy} />
                            <Row header='Cad Type' value={cadType} />
                            <Row header='Licensor' value={licensor} />
                            <Row header='Property' value={property} />
                            <Row header='Retailer' value={retailer} />
                            <Row header='Gender' value={gender} />
                            <Row header='Age' value={age} />
                        </ColumnContainer>
                        <ColumnContainer styles={{ width: '50%' }}>
                            <Row header='Category' value={category} />
                            <Row header='Department' value={department} />
                            <Row header='Style' value={style} />
                            <Row
                                header='Artwork Source'
                                value={artworkSource}
                            />
                            <Row
                                header='Shape Designer'
                                value={shapeDesigner}
                            />
                            <Row header='Description' value={cadDescription} />
                            <Row header='Availability' value={availability} />
                        </ColumnContainer>
                    </View>
                </View>
                {!isLast && <Divider />}
            </View>
        );
    }
);

Item.propTypes = propTypes;

export default Item;
