import PropTypes from 'prop-types';

import columnPropTypes from './columnPropTypes';

const propTypes = {
    columns: PropTypes.arrayOf(columnPropTypes).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    fetchGridData: PropTypes.func,
    syncGridDataWithServerAsync: PropTypes.func,
    rowStyle: PropTypes.func,
    isRowEditable: PropTypes.func,
    dataGridLoading: PropTypes.bool,
    storedDataGridSettings: PropTypes.object,
    RenderDataGridExportOptions: PropTypes.func,
    datasetSelector: PropTypes.func,
    datasetLoadingSelector: PropTypes.func,
    valueSelector: PropTypes.func,
    rowHeight: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number)
};

export default propTypes;
