import React from 'react';
import { withStyles } from '@material-ui/core';

import { ContentContainer } from '@libComponents/ContentContainer';

import Adornment from './Adornment';
import Brands from './Brands';

const styles = ({ spacing }) => ({
    header: {
        padding: `0px ${spacing.unit * 2}px`
    },
    content: {
        width: '100%',
        height: '100%'
    }
});

const Container = withStyles(styles)(({ classes: c }) => {
    return (
        <ContentContainer
            styles={c}
            title='Branding Details'
            AdornmentComponent={<Adornment />}
        >
            <div className={c.content}>
                <Brands />
            </div>
        </ContentContainer>
    );
});

export default Container;
