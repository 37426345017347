export const normalizeCurrencies = ({ data: currencies }) =>
    currencies.map(({ currencyId, currencyName, symbol }) => ({
        currencyId: Number(currencyId),
        currency: currencyName,
        currencySymbol: symbol
    }));

export const normalizeTrimSuppliers = ({ data: trimSuppliers }) =>
    trimSuppliers.map(({ trimSupplierId, trimSupplierName }) => ({
        trimSupplierId: Number(trimSupplierId),
        trimSupplier: trimSupplierName
    }));

export const normalizeTrimTypes = ({ data: trimTypes }) =>
    trimTypes.map(({ trimTypeID, trimType, trimTypeCode, trimCategory }) => ({
        trimTypeId: Number(trimTypeID),
        trimType,
        trimTypeCode,
        trimTypeCategory: trimCategory
    }));
