import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

import { withField } from '@libs/reduxForm';

import Button from '@libComponents/Button';
import { setSnack } from 'utilities/redux.actions';

const styles = ({ spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },
    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const defaultProps = {
    name: 'report'
};

const mapDispatch = (dispatch, { mode }) => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const ActionsAdornment = compose(
    connect(
        null,
        mapDispatch
    ),
    withField(),
    withStyles(styles)
)(({ classes: c, closeModal, handleHandoverEmail, isPDFLoading }) => {
    return (
        <div className={c.container}>
            <Button
                className={c.button}
                onClick={handleHandoverEmail}
                disabled={isPDFLoading}
            >
                Email{' '}
            </Button>
            <Button color='error' className={c.button} onClick={closeModal}>
                Close
            </Button>
        </div>
    );
});

ActionsAdornment.defaultProps = defaultProps;

export default ActionsAdornment;
