import { call, fork, put, take, takeEvery, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';

// Local
import { apiRequest } from 'helpers';

// Actions
import { setDataset, setPaginationDataset } from 'actions';

// Action Types
import { FETCH_DATASETS, FETCH_PAGINATION_DATASET } from 'actionTypes';

// Libs
import { getDatasetReference } from 'libs';

// Workers
function* fetchPaginationDatasetWorker({
    meta,
    payload: { filterCategory = '', ...paginationInfo },
}) {
    const { url, normalizer, queryBuilder } = getDatasetReference(meta.entity);

    // Fixes naming conventions
    const queryBuilderPayload = {
        ...paginationInfo,
        filtercategory: filterCategory,
    };

    const composedUrl = yield select(url, queryBuilder(queryBuilderPayload));

    const { data } = yield call(apiRequest, { url: composedUrl });

    const normalizedData = normalizer(data);

    yield put(setPaginationDataset(meta, normalizedData));
}

function* fetchDatasetWorker(datasetsChannel) {
    while (true) {
        const {
            entity,
            url,
            selector,
            normalizer,
            queryBuilder = () => void 0,
        } = yield take(datasetsChannel);

        const selectedData = yield select(selector);

        if (!selectedData.length) {
            let store = void 0;
            const isUrlString = typeof url === 'string';
            // If normalizer takes more than one argument (more than just 'data')
            const isStoreNeeded =
                !isUrlString || (!!normalizer && normalizer.length > 1);

            if (isStoreNeeded) store = yield select();

            const res = yield call(apiRequest, {
                url: isUrlString ? url : url(store, queryBuilder()),
            });

            const { data } = res;

            const dataset = normalizer ? normalizer(data, store) : data;

            yield put(setDataset(entity, dataset));
            return 'hi';
        }
    }
}
// Channels
function* fetchDatasetsChannel({ meta: { datasets } }) {
    const datasetsChannel = yield call(channel);

    // create 6 worker 'threads'
    // Todo: make into globals
    for (let i = 0; i < 6; i++) {
        yield fork(fetchDatasetWorker, datasetsChannel);
    }

    for (const dataset of datasets) {
        yield put(datasetsChannel, getDatasetReference(dataset));
    }
}

// Watchers
const commonWatchers = function* () {
    yield takeEvery(FETCH_DATASETS, fetchDatasetsChannel);
    yield takeEvery(FETCH_PAGINATION_DATASET, fetchPaginationDatasetWorker);
};

export { commonWatchers as default, commonWatchers };
