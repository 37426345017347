const priceValidator = price => {
    let isValid = true;
    let priceText = String(price);

    function countChar(char) {
        return priceText.split(char).length - 1;
    }

    function countAfterDecimal(char) {
        return countChar(char) === 1 ? priceText.split(char)[1].length : 0;
    }

    for (let i = 0; i < priceText.length; i++) {
        let c = priceText[i];
        //allow only numbers
        //ascii: 0 -> 9 = 48 -> 57
        //countChar(char): max one dot
        //countAfterDecimal(char): max two digit after dot
        if (
            (c.charCodeAt() < 48 && c.charCodeAt() !== 46) ||
            c.charCodeAt() > 57 ||
            countChar('.') > 1 ||
            countAfterDecimal('.') > 2
        ) {
            isValid = false;
        }
    }
    return isValid;
};

export { priceValidator as default, priceValidator };
