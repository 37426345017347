import React from 'react';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import set from 'lodash/set';

import { ExpandIcon, ShrinkIcon } from '@assets/Icons';
import { valueSelector } from '@libs/datasets';
import store from 'app/ReduxGate/store';
import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

import IndeterminateCheckbox from './components/IndeterminateCheckbox';
import GridImage from './components/GridImage';
import NoImage from './components/GridImage/NoImage';
import { Select, Input, DateInput, Link, EditItem } from './components';
import { NO_IMAGE_URL } from 'common/common';
import { DOMAIN_NAME } from 'gridView/common';

const visibleColumnHeadersSelector = createSelector(
    data => data,
    data => {
        const columns = Object.entries(data)
            .filter(([, { isShow }]) => isShow)
            .slice(0, 11)
            .map(([accessor, { header, type }]) => ({
                Header: header,
                accessor,
                disableSortBy: type === 'Image'
            }));
        return columns;
    }
);

const tableColumnsSelector = createSelector(
    headers => headers,
    (_, meta) => meta,
    (
        headers,
        { isExpandable, moduleName, disableCheckbox, disableEditIcon }
    ) => {
        const columns = disableCheckbox
            ? headers
            : [
                  {
                      id: 'selection',
                      Header: ({ getToggleAllRowsSelectedProps }) => (
                          <div
                              style={{ display: 'flex', alignItems: 'center' }}
                          >
                              <IndeterminateCheckbox
                                  {...getToggleAllRowsSelectedProps()}
                              />
                              <span>ALL</span>
                          </div>
                      ),
                      Cell: ({ row }) => {
                          return (
                              <IndeterminateCheckbox
                                  {...row.getToggleRowSelectedProps()}
                              />
                          );
                      }
                  },
                  ...headers
              ];
        const editColumn = {
            // Make an edit cell
            Header: () => null, // No header
            id: 'edit', // It needs an ID
            Cell: ({ row }) => {
                return (
                    <EditItem id={row.original.id} moduleName={moduleName} />
                );
            }
        };
        const expanderColumn = {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => (
                <span
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    {...row.getToggleRowExpandedProps()}
                >
                    {!!row.isExpanded ? <ShrinkIcon /> : <ExpandIcon />}
                </span>
            )
        };
        return isExpandable
            ? [
                  ...columns,
                  ...(disableEditIcon ? [] : [editColumn]),
                  expanderColumn
              ]
            : [...columns, ...(disableEditIcon ? [] : [editColumn])];
    }
);

const rowSelector = createCachedSelector(
    row => row,
    ({
        key,
        value,
        type,
        id,
        isShow = true,
        dataLinkable = {},
        dataSource
    }) => {
        switch (type) {
            case 'Image':
                return {
                    [key]:
                        !!value.length && !!value[0].size100 ? (
                            <GridImage image={value} />
                        ) : (
                            <NoImage url={NO_IMAGE_URL} />
                        )
                };
            case 'DropDown':
                return {
                    [key]: (
                        <Select
                            name={key}
                            entity={`@${key}`}
                            domain={dataSource || DOMAIN_NAME}
                            id={id}
                            value={value}
                            //placeholder={`Select ${key}`}
                        />
                    )
                };
            case 'Text':
                return {
                    [key]: (
                        <Input
                            value={value || ''}
                            id={id}
                            name={key}
                            viewFromExpand={!isShow}
                        />
                    )
                };
            case 'Date':
                return {
                    [key]: (
                        <DateInput
                            name={key}
                            id={id}
                            value={value}
                            placeholder={'Select Date'}
                        />
                    )
                };
            case 'Link':
                if (dataLinkable[key] && dataLinkable[key].endpoint)
                    return {
                        [key]: (
                            <Link
                                param={value}
                                targetLink={dataLinkable[key].endpoint}
                            />
                        )
                    };
                return { [key]: !!value ? value : 'N/A' };
            default:
                return { [key]: !!value ? value : 'N/A' };
        }
    }
)(({ key, id }) => `${key}${id}`);

const rowsSelector = createSelector(
    rows => rows,
    (_, dataLinkable) => dataLinkable,
    (rows, dataLinkable) =>
        rows.map(datum => {
            const row = Object.entries(datum)
                .filter(([key, { isShow }]) => isShow || key === 'id')
                .reduce((accu, [key, { value, type, dataSource }]) => {
                    const row = rowSelector({
                        value,
                        key,
                        type,
                        id: datum.id.value,
                        dataLinkable,
                        dataSource
                    });
                    return { ...accu, ...row };
                }, {});
            return row;
        })
);

const expandedDataSelector = createSelector(
    (data, _) => data,
    (_, rowId) => rowId,
    (data, rowId) => {
        const selectedRow = data.find(({ id }) => id.value === rowId);
        return !!selectedRow
            ? Object.entries(selectedRow).reduce(
                  (
                      accu,
                      [key, { header, value, isShow, type, dataSource }]
                  ) => {
                      if (!isShow && key !== 'id' && key !== 'retailerId') {
                          const expandedRow = rowSelector({
                              value,
                              key,
                              type,
                              id: rowId,
                              isShow,
                              dataSource
                          });
                          const row = { header, value: expandedRow[key] };
                          return [...accu, row];
                      }
                      return accu;
                  },
                  []
              )
            : [];
    }
);

const editableDataSelector = createSelector(
    data => data,
    data =>
        data.reduce((accu, datum) => {
            const row = Object.entries(datum).reduce(
                (accu, [key, { isEdit, value }]) => {
                    if (isEdit) {
                        set(accu, `${datum.id.value}.${key}`, value);
                        return accu;
                    }
                    return accu;
                },
                {}
            );
            return { ...accu, ...row };
        }, {})
);

const tileViewDataSelector = createSelector(
    (data, _) => data,
    (_, columns) => columns,
    (data, columns) => {
        return data.reduce((accu, datum) => {
            const row = Object.entries(datum)
                .reduce((accu, [key, { header, value }]) => {
                    if (columns.includes(key)) {
                        return [
                            ...accu,
                            { header, value, serial: columns.indexOf(key) }
                        ];
                    }
                    return accu;
                }, [])
                .sort((a, b) => a.serial - b.serial)
                .map(({ serial, ...rest }) => rest);
            return [...accu, row];
        }, []);
    }
);

const exportRowSelector = createCachedSelector(
    row => row,
    ({ key, header, value, type, exportType, imageSize, dataSource }) => {
        switch (type) {
            case 'Image':
                return {
                    header,
                    value: !!value.length
                        ? value[0][`size${imageSize}`]
                        : NO_IMAGE_URL,
                    type: exportType === 'xlsx' ? `image|${imageSize}` : 'image'
                };
            case 'DropDown':
                const entity = `@${key}`;
                return {
                    header,
                    value: valueSelector(
                        store.getState(),
                        dataSource || DOMAIN_NAME,
                        entity,
                        value
                    ),
                    type: 'text'
                };

            case 'Date':
                return {
                    header,
                    value: formatDateToShort(value),
                    type: 'text'
                };

            default:
                return key !== 'id'
                    ? {
                          header,
                          value: !!value ? value : '',
                          type: 'text'
                      }
                    : null;
        }
    }
)(data => `${data.key}${data.id}`);

const exportDataSelector = createSelector(
    values => values,
    ({ data, selectedIds, exportedColumns, exportType, imageSize }) => {
        const length = exportedColumns.length;
        return data.reduce((accu, datum) => {
            const row = Object.entries(datum)
                .reduce((accu, [key, { header, value, type, dataSource }]) => {
                    if (selectedIds.includes(datum.id.value)) {
                        if (length > 0) {
                            if (exportedColumns.includes(key)) {
                                const row = exportRowSelector({
                                    key,
                                    header,
                                    value,
                                    type,
                                    id: datum.id.value,
                                    exportType,
                                    imageSize,
                                    dataSource
                                });
                                return row
                                    ? [
                                          ...accu,
                                          {
                                              ...row,
                                              serial: exportedColumns.indexOf(
                                                  key
                                              )
                                          }
                                      ]
                                    : accu;
                            }
                            return accu;
                        } else {
                            const row = exportRowSelector({
                                key,
                                header,
                                value,
                                type,
                                id: datum.id.value,
                                dataSource
                            });
                            return row ? [...accu, row] : accu;
                        }
                    }
                    return accu;
                }, [])
                .sort((a, b) => a.serial - b.serial)
                .map(({ serial, ...rest }) => rest);
            return row.length > 0 ? [...accu, row] : accu;
        }, []);
    }
);

const renameExportHeader = (data, mapping, costFields = []) => {
    try {
        return data.map(datum => {
            const renamedData = datum.reduce(
                (accumulator, { header, ...rest }) => {
                    if (typeof mapping[header] !== 'undefined') {
                        return [
                            ...accumulator,
                            { header: mapping[header], ...rest }
                        ];
                    } else {
                        return [...accumulator, { header, ...rest }];
                    }
                },
                []
            );
            return [...renamedData, ...costFields];
        });
    } catch (error) {
        console.error(error);
    }
};

const tileExportDataSelector = createSelector(
    values => values,
    ({ data, imageSize, exportType, selectedIds }) => {
        return data
            .reduce((accu, curr, idx) => {
                const selectedData = curr
                    .map(({ header, value }) => {
                        if (header === 'ID' && selectedIds.includes(value)) {
                            return data[idx];
                        }
                    })
                    .filter(data => data);
                return !!selectedData.length
                    ? [...accu, ...selectedData]
                    : accu;
            }, [])
            .map(datum => {
                let otherDataArray = [];
                let imageArray = [];
                return datum
                    .reduce((accu, { header, value }, dataIdx) => {
                        if (Array.isArray(value)) {
                            imageArray = value.map(
                                ({ imageVersion, ...rest }, imageIdx) => ({
                                    header: `${header}-${imageVersion}`,
                                    value: rest[`size${imageSize}`],
                                    type:
                                        exportType === 'xlsx'
                                            ? `image|${imageSize}`
                                            : 'image',
                                    serial: dataIdx + 1 + imageIdx
                                })
                            );
                        } else {
                            if (header !== 'ID') {
                                otherDataArray = [
                                    ...otherDataArray,
                                    {
                                        header,
                                        value,
                                        type: 'text',
                                        serial: dataIdx + 1
                                    }
                                ];
                            }
                        }
                        return [...imageArray, ...otherDataArray];
                    }, [])
                    .sort((a, b) => a.serial - b.serial)
                    .map(({ serial, ...restData }) => restData);
            });
    }
);

export {
    visibleColumnHeadersSelector,
    tableColumnsSelector,
    rowsSelector,
    expandedDataSelector,
    editableDataSelector,
    tileViewDataSelector,
    exportDataSelector,
    renameExportHeader,
    tileExportDataSelector
};
