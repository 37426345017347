import { useCallback, useMemo, useState } from 'react';
import uuid from 'uuid/v4';

import { usePDFContext } from './Provider';

/*
    Params
        @document   Document    required    Component function
        @filename   string      required    File name of download
        @props      object                  Props to be passed into Document component
        @loadProps  async                   Asynchronous function, takes in props, resolves props
 */
export default ({
    document,
    fileName,
    props = null,
    loadProps = null,
    errorResolver = null,
    withDownload = true,
    blobUrl = false,
    generateOnChange = false
}) => {
    const [{ loadingId, report }, dispatch] = usePDFContext();
    const [id, setId] = useState(null);
    const handleClick = useCallback(() => {
        if (!id || generateOnChange) {
            const id = uuid();
            setId(id);
            dispatch({
                type: 'setDocument',
                payload: {
                    id,
                    document,
                    fileName,
                    props,
                    loadProps,
                    errorResolver,
                    withDownload,
                    blobUrl
                }
            });
        }
    }, [Document, props]);

    const handleRemove = useCallback(() => {
        dispatch({
            type: 'removeDocument'
        });
    }, []);

    // If is loading self
    const loading = useMemo(() => id && loadingId === id, [loadingId, id]);

    // If is loading, but not loading self
    const disabled = loadingId && loadingId !== id;
    return [handleClick, loading, disabled, report, handleRemove];
};
