import React, { createContext, useContext, useReducer, useMemo } from 'react';

const initialState = {
    searchQuery: '',
    visibleColumns: [],
    initialColumns: [],
    columns: [],
    orderedColumns: [],
    allVisible: false
};

const ManageColumnsContext = createContext(initialState);

const filterColumns = (columns, query) => {
    if (!query) return columns;
    return columns.filter(column => {
        return column.header
            .toString()
            .toLowerCase()
            .includes(query.toString().toLowerCase());
    });
};

const updateVisibleColumns = (visibleColumns, columnName) => {
    const existingIndex = visibleColumns.indexOf(columnName);
    if (existingIndex !== -1) {
        // Remove the column from visibleColumns if it exists
        return [
            ...visibleColumns.slice(0, existingIndex),
            ...visibleColumns.slice(existingIndex + 1)
        ];
    } else {
        // Add the column to visibleColumns if it doesn't exist
        return [...visibleColumns, columnName];
    }
};

const manageColumnsReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_COLUMN_VISIBILITY': {
            const { visibleColumns, orderedColumns } = state;
            const newVisibleColumns = updateVisibleColumns(
                visibleColumns,
                action.payload
            );
            return {
                ...state,
                visibleColumns: newVisibleColumns,
                allVisible: newVisibleColumns.length === orderedColumns.length
            };
        }
        case 'REORDER_COLUMNS': {
            return { ...state, orderedColumns: action.payload };
        }

        case 'SEARCH_COLUMN': {
            return {
                ...state,
                columns: filterColumns(state.initialColumns, action.payload),
                searchQuery: action.payload
            };
        }
        case 'SEARCH_CLEAR': {
            return {
                ...state,
                columns: state.initialColumns,
                searchQuery: ''
            };
        }
        case 'TOGGLE_ALL_COLUMN_VISIBILITY': {
            const { allVisible, orderedColumns } = state;
            return {
                ...state,
                visibleColumns: allVisible ? [] : [...orderedColumns],
                allVisible: !allVisible
            };
        }

        default:
            return state;
    }
};

const ManageColumnsContextProvider = ({
    children,
    columnMap,
    orderedColumns,
    visibleColumns,
    ...rest
}) => {
    const initialColumns = useMemo(
        () =>
            orderedColumns
                .filter(columnName => columnMap[columnName])
                .map(columnName => columnMap[columnName]),
        [orderedColumns, columnMap]
    );

    const initialState = {
        ...rest,
        columns: initialColumns,
        initialColumns,
        orderedColumns,
        visibleColumns,
        allVisible: orderedColumns.length === visibleColumns.length
    };

    const [state, dispatch] = useReducer(manageColumnsReducer, initialState);

    return (
        <ManageColumnsContext.Provider value={{ state, dispatch }}>
            {children}
        </ManageColumnsContext.Provider>
    );
};

const useManageColumnsContext = () => {
    const context = useContext(ManageColumnsContext);
    if (context === undefined) {
        throw new Error(
            'useManageColumnsContext must be used within a ManageColumnsContextProvider'
        );
    }
    return context;
};

export { useManageColumnsContext as default, ManageColumnsContextProvider };
