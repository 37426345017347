import React, { useCallback } from 'react';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import { MuiRow, TableCell } from '@libComponents/Table';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { EditIcon } from '@assets/Icons';
import { TooltipAction } from '@formComponents';

import Modal from './Modal';

const styles = () => ({
    whiteSpaceNoWrap: {
        whiteSpace: 'nowrap'
    }
});

const RowComponent = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    withStyles(styles)
)(({ classes: c, handleModal, serial, styles, ...row }) => {
    const handleEdit = useCallback(
        () =>
            handleModal({
                initialValues: {
                    ...row,
                    retailer: row.retailerId,
                    isEdit: true
                }
            }),
        [handleModal, row]
    );

    return (
        <MuiRow classes={styles}>
            <TableCell>{serial}</TableCell>
            <TableCell styles={{ root: c.whiteSpaceNoWrap }}>
                {row.retailer}
            </TableCell>
            <TableCell styles={{ root: c.whiteSpaceNoWrap }}>
                {row.sizeSet}
            </TableCell>
            <TableCell>{row.sizes.map(size => size.name).join(', ')}</TableCell>
            <TableCell>{row.isActive ? 'Yes' : 'No'}</TableCell>

            <TableCell>
                <TooltipAction
                    IconComponent={EditIcon}
                    title='Edit it!'
                    onClick={handleEdit}
                />
            </TableCell>
        </MuiRow>
    );
});

export default RowComponent;
