import React, { useMemo } from 'react';
import { Page, Document, View } from '@react-pdf/renderer';
import { string, object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT,
    FONT_FAMILY_NORMAL
} from 'common/constants';
import { Title, Field } from 'components/PDF';
import { useCustomDate } from 'sampling/hooks';

import DispatchDetails from './DispatchDetails';

const propTypes = {
    pageTitle: string.isRequired,
    docTitle: string.isRequired,
    values: object.isRequired
};

const defaultProps = {
    docTitle: 'Document',
    pageTitle: 'PDF Page'
};

const styles = ({ spacing }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM,
        fontFamily: FONT_FAMILY_NORMAL
    },
    pageTitle: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

const PDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        pageTitle,
        docTitle,
        values: {
            dispatchReference,
            retailer,
            fao,
            currier,
            trackingReference: {
                value: trackingReferenceValue,
                isMandatory: isTrackingReferenceMandatory
            },
            actualSendDate,
            eta,
            originCountry,
            destinationCountry,
            dispatchDetails,
            totalQuantity,
            isSeasalt
        }
    }) => {
        const pdfPageProps = useMemo(
            () => ({
                size: 'A4',
                orientation: 'landscape',
                style: c.page
            }),
            [c]
        );

        return (
            <Document title={docTitle}>
                <Page {...pdfPageProps}>
                    <View>
                        <Title text={pageTitle} style={c.pageTitle} />
                        <View style={c.container}>
                            <Field
                                label='Dispatch Reference'
                                value={dispatchReference}
                                style={{ width: '19%' }}
                            />
                            <Field
                                label='Retailer'
                                value={retailer}
                                style={{ width: '19%' }}
                            />
                            <Field
                                label='FAO'
                                value={fao}
                                style={{ width: '19%' }}
                                required={false}
                            />
                            <Field
                                label='Sent Via'
                                value={currier}
                                style={{ width: '16%' }}
                            />
                            <Field
                                label='Source Country'
                                value={originCountry}
                                style={{ width: '19%' }}
                            />
                            <Field
                                label='Destination Country'
                                value={destinationCountry}
                                style={{ width: '19%' }}
                            />
                            <Field
                                label='Dispatch Date'
                                value={useCustomDate(actualSendDate)}
                                style={{ width: '17%' }}
                            />
                            <Field
                                label='ETA'
                                value={useCustomDate(eta)}
                                style={{ width: '17%' }}
                            />
                            <Field
                                label='Tracking No'
                                value={trackingReferenceValue}
                                style={{ width: '19%' }}
                                required={isTrackingReferenceMandatory}
                            />
                        </View>
                        <DispatchDetails
                            data={dispatchDetails}
                            totalQuantity={totalQuantity}
                            isSeasalt={isSeasalt}
                        />
                    </View>
                </Page>
            </Document>
        );
    }
);

PDFContent.propTypes = propTypes;
PDFContent.defaultProps = defaultProps;

export default PDFContent;
