import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    TableCell,
    TableRow,
    LinearProgress,
    withStyles
} from '@material-ui/core';

import { rowUpdateLoadingSelector } from 'gridView/redux.selectors';

const styles = ({ palette, spacing, shadows }) => ({
    progress: {
        width: '100%',
        backgroundColor: palette.secondary.light,
        height: spacing.unit * 0.3,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: palette.secondary.main,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    }
});

const mapStateToProps = (state, { rowId }) => ({
    loading: rowUpdateLoadingSelector(state, rowId)
});

const RowLoader = compose(
    connect(
        mapStateToProps,
        null
    ),
    withStyles(styles)
)(({ loading, visibleColumns, classes: c }) => {
    return loading ? (
        <TableRow style={{ height: '0px' }}>
            <TableCell
                colSpan={visibleColumns.length}
                style={{ padding: '0px' }}
            >
                <LinearProgress
                    classes={{
                        root: c.progress,
                        barColorPrimary: c.progressPrimary
                    }}
                />
            </TableCell>
        </TableRow>
    ) : null;
});

export default RowLoader;
