import reduceReducer from 'reduce-reducers';

// Local
//import initialState from './initialStateLater';
import initialState from './initialStateLater';

// Reducers
import commonReducer from './common';
import samplingReducer from './sampling';

// Action Types
import { SIGN_OUT } from 'actionTypes';

const stemReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SIGN_OUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

const datasetsReducers = reduceReducer(
    initialState,
    stemReducer,
    commonReducer,
    samplingReducer
);

export { datasetsReducers as default, datasetsReducers };
