import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Card as MaterialCard } from '@material-ui/core';

// Local
import { detailsPropType } from "./common";
import Header from './Header';
import Content from './Content';
import ViewToggle from './ViewToggle';

const propTypes = {
    title: PropTypes.node.isRequired,
    details: detailsPropType.isRequired,
    ActionsAdornment: PropTypes.element,
    minAmountShown: PropTypes.number
};

const defaultProps = {
    minAmountShown: 6
};

const styles = ({ palette, shadows }) => ({
    root: {
        borderRadius: 0,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        boxShadow: shadows[0]
    }
});

const Card = withStyles(styles)(
    ({ classes: c, title, ActionsAdornment, details, minAmountShown }) => {
        const [showLess, setShowLess] = useState(true);

        const handleToggle = useCallback(() => setShowLess(value => !value), [
            setShowLess
        ]);

        return (
            <div>
                <MaterialCard className={c.root}>
                    <Header title={title} ActionsAdornment={ActionsAdornment} />
                    <Content
                        details={details}
                        showLess={showLess}
                        minAmountShown={minAmountShown}
                    />
                </MaterialCard>
                {details.length > minAmountShown && (
                    <ViewToggle onToggle={handleToggle} showLess={showLess} />
                )}
            </div>
        );
    }
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
