import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';

//import Image from '@libComponents/ReactPDF/Image';

import { Title } from 'components/PDF';

const styles = StyleSheet.create({
    imageWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        borderColor: '#c8ced4'
    },
    image: {
        width: '50%'
    }
});

const Cad = ({ version, name }) => {
    return (
        <View>
            <Title text={`CAD${name ? `(${name})` : ''}`} />
            <View style={styles.imageWrapper}>
                {version && <Image src={version} style={styles.image} />}
            </View>
        </View>
    );
};

export default Cad;
