import React from 'react';

import { withStyles } from '@material-ui/core';

import Checkbox from './Checkbox';

const MenuItem = ({ label, ...rest }) => {
    return <Checkbox label={label} {...rest} />;
};

export default MenuItem;
