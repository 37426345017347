import { call, put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';

export const publishFailedWorker = function*({
    handleLoading,
    action,
    actionErrorMessage,
    error
}) {
    const serverMessage = yield call(normalizeServerFailedResponse, error);

    yield call(handleLoading, true);
    yield put(
        setSnack({
            message: `${actionErrorMessage} ${serverMessage}`,
            type: 'error',
            duration: 15000,
            action: {
                label: 'Retry',
                handle: action
            }
        })
    );
};
