import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { RefreshOutlined } from '@material-ui/icons';

import SplitButton from '@libComponents/SplitButton';
import { withStylesAndRef } from '@libs/materialUI';
import IconLabelButton from '@libComponents/IconLabelButton';

import ManageColumns from './ManageColumns';
import useGridContext from '../../../useGridContext';

const propTypes = {
    forwardRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
};

const styles = ({ spacing, typography }) => ({
    dataGridActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: spacing.unit
    },
    gridActionButton: {
        height: spacing.unit * 4,
        marginRight: spacing.unit * 0.5,
        padding: `${spacing.unit * 0.75}px ${spacing.unit * 1.5}px`
    },
    gridActionSplitButton: {
        position: 'relative',
        height: spacing.unit * 4,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize
    }
});

const DataGridHeaderActions = withStylesAndRef(styles)(
    ({ classes: c, forwardRef }) => {
        const {
            clearColumnVisibilitySettings,
            clearColumnFreezeSettings,
            clearColumnReorderSettings,
            handleClearAllSettings,
            refetchData,
            columnMap,
            orderedColumns,
            visibleColumns,
            setVisibleColumns
        } = useGridContext();

        const resetOptions = useMemo(
            () => [
                {
                    label: 'Reset All',
                    callback: handleClearAllSettings
                },
                {
                    label: 'Reset Hidden Cols',
                    callback: clearColumnVisibilitySettings
                },
                {
                    label: 'Reset Frozen Cols',
                    callback: clearColumnFreezeSettings
                },
                {
                    label: 'Reset Order Col',
                    callback: clearColumnReorderSettings
                }
            ],
            [
                clearColumnVisibilitySettings,
                clearColumnFreezeSettings,
                clearColumnReorderSettings,
                handleClearAllSettings,
                refetchData
            ]
        );
        return (
            <div ref={forwardRef} className={c.dataGridActions}>
                <ManageColumns
                    className={c.gridActionButton}
                    columnMap={columnMap}
                    orderedColumns={orderedColumns}
                    visibleColumns={visibleColumns}
                    setVisibleColumns={setVisibleColumns}
                />
                <IconLabelButton
                    label='Refresh Data'
                    IconComponent={RefreshOutlined}
                    className={c.gridActionButton}
                    onClick={refetchData}
                    color='success'
                />
                <SplitButton
                    className={c.gridActionSplitButton}
                    options={resetOptions}
                    color='error'
                />
            </div>
        );
    }
);

// Define PropTypes for the component
DataGridHeaderActions.propTypes = propTypes;

export default DataGridHeaderActions;
