import React, { useState, useEffect, useRef } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { string, bool } from 'prop-types';
import clsx from 'classnames';

import { useGridViewDispatch } from 'gridView/useGridView';

const styles = ({ spacing, palette, typography, shape }) => ({
    root: {
        border: 'none',
        outline: 'none'
    },
    input: {
        fontFamily: typography.fontFamily,
        fontSize: spacing.unit * 1.5,
        padding: `0px ${spacing.unit * 0.5}px`,
        color: palette.grey[600]
    },
    focus: {
        outline: '2px solid #000'
    },
    expandView: {
        width: '100%',
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        '&:hover': {
            borderColor: palette.grey[400]
        }
    }
});

const propTypes = {
    name: string.isRequired,
    id: string.isRequired,
    value: string,
    viewFromExpand: bool
};

const Input = withStyles(styles)(
    ({ value: initialValue, name, id, rowIndex, viewFromExpand, classes }) => {
        const [value, setValue] = useState(initialValue);
        const prevValue = useRef(initialValue);
        const dispatch = useGridViewDispatch();

        const { input: inputClass, focus, expandView, ...c } = classes;

        const handleChange = e => {
            setValue(e.target.value);
        };

        const handleSave = () => {
            if (prevValue.current.trim() !== value.trim()) {
                prevValue.current = value;
                dispatch({
                    type: 'update-grid-cell',
                    payload: {
                        id,
                        name,
                        value,
                        rowIndex
                    }
                });
            }
        };
        const handleBlur = ({ currentTarget }) => {
            !viewFromExpand &&
                currentTarget.parentNode.parentNode.classList.remove(focus);
            handleSave();
        };
        const handleClick = ({ currentTarget }) =>
            !viewFromExpand && currentTarget.classList.add(focus);

        const handleKeyPress = ({ key }) => key === 'Enter' && handleSave();

        useEffect(() => {
            setValue(initialValue);
            prevValue.current = initialValue;
        }, [initialValue]);
        return (
            <TextField
                classes={{
                    ...c,
                    root: clsx(c.root, viewFromExpand && expandView)
                }}
                InputProps={{
                    disableUnderline: true,
                    className: inputClass
                }}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={handleClick}
                onKeyPress={handleKeyPress}
            />
        );
    }
);

Input.propTypes = propTypes;

export { Input as default, Input };
