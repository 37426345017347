import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const cadUploadReducer = state => state;

const [
    CadUploadProvider,
    useCadUploadState,
    useCadUploadDispatch,
    useCadUpload
] = makeContext(cadUploadReducer, initialState, { name: 'CadUpload' });

export {
    useCadUpload as default,
    CadUploadProvider,
    useCadUploadState,
    useCadUploadDispatch
};
