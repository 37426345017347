import React, { useMemo } from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import {
    denormalizeDate,
    formatDateToShort
} from '@baseComponents/Date/normalizeDate';
import { FONT_FAMILY_BOLD, FONT_FAMILY_NORMAL } from 'common/constants';
import { FULL_WIDTH_UNITS } from './constants';
import TitleRow from './TitleRow';
import Chart from './Chart';

const styles = ({ spacing, palette }) => ({
    page: {
        position: 'relative',
        width: spacing.unit * FULL_WIDTH_UNITS,
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 4,
        fontFamily: FONT_FAMILY_NORMAL
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderBottom: 1,
        paddingBottom: spacing.unit
    },
    title: {
        width: '45%',
        fontFamily: FONT_FAMILY_BOLD,
        fontSize: spacing.unit * 1.75
    },
    metaDataContainer: {
        width: '55%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pieChartWrapper: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: spacing.unit * 2
    },
    pageNumber: {
        position: 'absolute',
        bottom: spacing.unit * 2,
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: spacing.unit * 1.5
    },
    copyright: {
        position: 'absolute',
        bottom: spacing.unit * 2,
        right: spacing.unit * 2,
        fontSize: spacing.unit,
        fontFamily: FONT_FAMILY_NORMAL,
        color: palette.grey[800]
    }
});

const PDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        data,
        startDate,
        endDate,
        retailer,
        total,
        view,
        cellSettings
    }) => {
        const dateRange = useMemo(() => {
            const start = formatDateToShort(denormalizeDate(startDate));
            const end = formatDateToShort(denormalizeDate(endDate));
            return `${start} to ${end}`;
        }, [startDate, endDate]);

        return (
            <Document>
                <Page size='A3' style={c.page}>
                    <View style={c.titleContainer}>
                        <Text style={c.title}>{retailer}</Text>
                        <View style={c.metaDataContainer}>
                            <TitleRow label='Date' value={dateRange} />
                            <TitleRow
                                label='No. Samples Requested'
                                value={total}
                            />
                        </View>
                    </View>
                    <View style={c.pieChartWrapper}>
                        {Object.entries(data).map(([key, values]) => (
                            <Chart
                                key={key}
                                data={values}
                                chartTitle={key}
                                view={view}
                                cellSettings={cellSettings[key] || {}}
                            />
                        ))}
                    </View>
                    <Text
                        style={c.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                    <Text style={c.copyright} fixed>
                        &copy; {new Date().getFullYear()} Echo Sourcing Ltd.
                    </Text>
                </Page>
            </Document>
        );
    }
);

export default PDFContent;
