import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import {
    withStyles,
    InputBase,
    InputAdornment,
    FormControl,
    LinearProgress
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

// Local
import Label from './Label';
import FormHelperText from './FormHelperText';
import { withField } from '@libs/reduxForm';
import { composeClsx } from '@libs/materialUI';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    staticValue: PropTypes.string,
    adornment: PropTypes.string
};

const defaultProps = {
    required: false,
    staticValue: ''
};

const styles = ({ spacing, palette, shape, typography }) => ({
    root: {
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,
        fontSize: typography.subtitle2.fontSize,

        '&:hover': {
            borderColor: palette.grey[400]
        }
    },
    success: {
        // borderColor: `${palette.success.primary} !important`, // Focus override
        // '&:hover': {
        //     borderColor: `${palette.success.hover} !important` // Focus override
        // },
        borderColor: `#212121 !important`, // Focus override
        '&:hover': {
            borderColor: `#18191a !important` // Focus override
        }
    },
    error: {
        borderColor: `${palette.error.light} !important`, // Focus override
        '&:hover': {
            borderColor: `${palette.error.dark} !important` // Focus override
        }
    },
    input: {
        height: '100%',
        padding: 0,
        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    focused: {
        borderColor: palette.grey[500],
        '&:hover': {
            borderColor: palette.grey[700]
        }
    },
    multiline: {
        display: 'flex',
        alignItems: 'flex-start',
        overflow: 'hidden',
        paddingTop: spacing.unit,
        paddingBottom: spacing.unit,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2
    },
    inputMultiline: {
        paddingRight: spacing.unit * 2
    },
    disabled: {
        display: 'flex',
        alignItems: 'center',

        backgroundColor: palette.background.light,
        color: palette.grey[700],

        '&:hover': {
            borderColor: palette[300]
        }
    },
    label: {
        color: `${palette.grey[800]} !important` // Overrides focused
    },
    container: {
        width: '100%'
    },
    adornment: {
        zIndex: 1
    },
    adornmentStart: {
        marginRight: spacing.unit * 1
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        position: 'absolute'
    },
    loader: {
        position: 'relative',
        color: 'tomato'
    },
    progress: {
        backgroundColor: 'var(--secondary)',
        height: spacing.unit * 0.5,
        marginTop: `${spacing.unit * -0.5}px !important`,
        marginLeft: 1,
        marginRight: 1,
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    },
    progressPrimary: {
        backgroundColor: 'var(--primary)',
        borderBottomLeftRadius: spacing.unit * 0.25 + 1,
        borderBottomRightRadius: spacing.unit * 0.25 + 1
    }
});

const Adornment = ({ adornment, disabled, ...props }) => {
    if (disabled || adornment) {
        return (
            <InputAdornment {...props}>
                {disabled ? <LockIcon color='disabled' /> : adornment}
            </InputAdornment>
        );
    }

    return null;
};

const Input = compose(
    withField(),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes,
        styles,
        className,
        theme,
        fieldName,
        input: { onDrop, onDragStart, ...input },
        disabled,
        required,
        label,
        loading,
        staticValue,
        min,
        max,
        adornment,
        position = 'end',
        meta: { form, touched, error = null, asyncValidating },
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const success = !isDisabled && Boolean(input.value);
        const hasError = touched && error;

        const adornmentProps = {
            disabled,
            adornment,
            className: c.adornment,
            position
        };

        const inputBaseProps = {
            id,
            classes: {
                root: clsx(
                    c.root,
                    className,
                    success && c.success,
                    hasError && c.error
                ),
                ...Object.splice(c, [
                    'input',
                    'focused',
                    'multiline',
                    'inputMultiline',
                    'disabled'
                ])
            },
            ...(position === 'start'
                ? { startAdornment: <Adornment {...adornmentProps} /> }
                : { endAdornment: <Adornment {...adornmentProps} /> }),
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...(restProps.type === 'number' && min
                ? { inputProps: { min, max } }
                : {}),
            ...restProps
        };

        const progressStyle = useMemo(() => {
            const { palette } = theme;
            const primary = asyncValidating
                ? palette.primary.main
                : palette.secondary.main;
            const secondary = asyncValidating
                ? palette.primary.light
                : palette.secondary.light;

            return { '--primary': primary, '--secondary': secondary };
        }, [asyncValidating, theme]);

        return (
            <FormControl className={c.container}>
                {label && (
                    <Label
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                        htmlFor={id}
                    />
                )}
                <InputBase {...inputBaseProps} />
                {(loading || asyncValidating) && (
                    <LinearProgress
                        classes={{
                            root: c.progress,
                            barColorPrimary: c.progressPrimary
                        }}
                        style={progressStyle}
                    />
                )}
                {hasError && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
