import { API_BASE_URL } from 'store/config';

export const selectAttachments = (module, attachments) => {
    const attachmentDownloadComposer = attachmentId =>
        `${API_BASE_URL}api/Download/GetDetailsDownload?` +
            `moduleName=${module}` +
            `&detailsType=attachment` +
            `&detailsId=${attachmentId}`;

    return attachments.map(({ id, fileName }) => ({
        fileName,
        downloadUrl: attachmentDownloadComposer(id),
    }));
};

export * from './mail';
