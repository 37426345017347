import React, { useMemo } from 'react';

import { LICENSORS } from 'cadUpload/redux.datasets';
import { DOMAIN_NAME } from 'cadUpload/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Licensor = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Licensor Name', canSort: true, sortKey: 'licensor' },
            { label: 'Licensor Code', canSort: true, sortKey: 'code' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'LICENSOR SETUP',
        domain: DOMAIN_NAME,
        entity: LICENSORS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Licensor as default, Licensor };
