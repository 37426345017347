import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { withStyles, Card, Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Local
import styles from './styles';

import {
    GridContainer,
    CardActionsBase,
    CardContentBase,
    CardHeaderBase
} from 'components';
import { composeClasses } from 'helpers';

const composeData = data =>
    data.reduce(
        ([card, collapsed], datum, index) => {
            if (index < 5) card.push(datum);
            else collapsed.push(datum);
            return [card, collapsed];
        },
        [[], []]
    );

const CardBase = ({
    classes,
    styles,
    CustomActions,
    CustomContent,
    CustomHeader,
    data,
    ...restProps
}) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const c = composeClasses({ classes, styles });

    const [cardData, collapsedData] = composeData(data);
    const CardContent = CustomContent || CardContentBase;
    const CardActions = CustomActions || CardActionsBase;
    const CardHeader = CustomHeader || CardHeaderBase;

    return (
        <Card className={classes.card} elevation={0}>
            <CardHeader {...restProps} />
            <GridContainer spacing={0} direction='column'>
                <Fragment>
                    {/*Default view content*/}
                    {cardData.map((card, index) => (
                        <CardContent key={index} {...card} />
                    ))}

                    {/*Collapsed content*/}
                    {!!collapsedData.length && (
                        <Fragment>
                            <Collapse
                                in={expanded}
                                timeout='auto'
                                unmountOnExit
                            >
                                {collapsedData.map((card, index) => (
                                    <CardContent key={index} {...card} />
                                ))}
                            </Collapse>

                            {/* Expand list */}
                            <CardActions onClick={handleExpandClick}>
                                <IconButton
                                    className={classNames(
                                        c.expand,
                                        expanded && c.expandOpen
                                    )}
                                    aria-expanded={expanded}
                                    aria-label='Show more'
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </CardActions>
                        </Fragment>
                    )}
                </Fragment>
            </GridContainer>
        </Card>
    );
};

CardBase.defaultProps = {
    CustomContent: null,
    CustomHeader: null,
    CustomActions: null,
    data: [],
    title: ''
};

CardBase.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    CustomActions: PropTypes.any,
    CustomContent: PropTypes.any,
    CustomHeader: PropTypes.any,
    data: PropTypes.array,
    title: PropTypes.string
};

const _CardBase = withStyles(styles)(CardBase);

export { _CardBase as default, _CardBase as CardBase };
