import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const printReducer = state => state;

const [
    PrintProvider,
    usePrintState,
    usePrintDispatch,
    usePrint
] = makeContext(printReducer, initialState, { name: 'Print' });

export {
    usePrint as default,
    PrintProvider,
    usePrintState,
    usePrintDispatch
};
