import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { change, formValueSelector, destroy } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { splice } from '@libs/object';
import composeApiPayload from '@libs/composeApiPayload';
import {
    composeLoadingWorker,
    getAssetBlobsWorker,
    uploadAssetsWorker
} from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { userIdSelector } from 'auth/redux.selectors';
import { publishFailedWorker } from '@libs/publishWorkers';
import isBlobUrl from '@libs/isBlobUrl';
import { redirectToRoute } from 'navigation/redux.actions';
import { BUILD_ENV } from '@libs/apiRequest/config';
import { ASSET_BASE_URL } from 'common/common';

import {
    PUBLISH_FINAL_PHOTO,
    ROUTE_TO_FINAL_PHOTO_UPLOAD,
    SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN,
    DESTROY_FINAL_PHOTO_FORM,
    SEND_FINAL_PHOTO_EMAIL
} from 'sampling/redux.actionTypes';
import {
    destroyFinalPhotoForm,
    setFinalPhotoDataLoading
} from 'sampling/redux.actions';
import {
    normalizeFile,
    normalizeImagePayload
} from 'sampling/redux.normalizers';
import {
    FORM_NAME,
    FINAL_PHOTO_UPLOAD_ROUTE_ID
} from '../../FinalPhotoUpload/common';

import { finalPhotoUploadSchema } from './schema';
import reportUploadAndMailSendWorker from './reportUploadAndMailSendWorker';

const INITIAL_VALUES = {
    id: 0,
    retailerId: 0,
    developmentNo: '',
    department: '',
    buyer: '',
    initialCad: '',
    finalCad: '',
    initialFront: '',
    initialBack: '',
    finalFront: '',
    finalBack: ''
};

// Workers

function* publishFinalPhotoWorker(action) {
    const {
        meta: { handleComplete = () => {} }
    } = action;

    const [
        userId,
        {
            id,
            finalCad,
            finalFront,
            finalBack,
            developmentNo,
            sampleRef,
            garmentsType
        }
    ] = yield all([
        select(userIdSelector),
        select(
            formValueSelector(FORM_NAME),
            'id',
            'finalCad',
            'finalFront',
            'finalBack',
            'developmentNo',
            'sampleRef',
            'garmentsType'
        )
    ]);

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [finalCad, finalFront, finalBack]
    });

    try {
        const [
            finalCadBlobs,
            finalFrontBlobs,
            finalBackBlobs,
            frontSizePaths,
            backSizePaths
        ] = yield all([
            call(getAssetBlobsWorker, {
                assets: finalCad,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: finalFront,
                handleLoading
            }),
            call(getAssetBlobsWorker, {
                assets: finalBack,
                handleLoading
            }),
            select(sizePathsSelector, 'sampleFinalFront'),
            select(sizePathsSelector, 'sampleFinalBack')
        ]);

        const [finalCadPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: finalCadBlobs,
            folderPath: `${frontSizePaths.sizeOriginal}/${sampleRef}`,
            reference: sampleRef,
            sizePaths: frontSizePaths,
            handleLoading,
            developmentName: developmentNo
        });
        const [finalFrontPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: finalFrontBlobs,
            folderPath: `${frontSizePaths.sizeOriginal}/${sampleRef}`,
            reference: sampleRef,
            sizePaths: frontSizePaths,
            handleLoading,
            developmentName: developmentNo
        });
        const [finalBackPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: finalBackBlobs,
            folderPath: `${backSizePaths.sizeOriginal}/${sampleRef}`,
            reference: sampleRef,
            sizePaths: backSizePaths,
            handleLoading,
            developmentName: developmentNo
        });
        const payload = {
            id,
            userId,
            sampleRef,
            frontImage:
                garmentsType === 'P'
                    ? normalizeImagePayload(finalCadPayload)
                    : normalizeImagePayload(finalFrontPayload),
            backImage: normalizeImagePayload(finalBackPayload)
        };

        const publishData = composeApiPayload(payload, finalPhotoUploadSchema);

        yield call(apiRequest, {
            method: 'put',
            url: 'Sample/PublishSampleFinalPhoto',
            data: publishData
        });

        /*
           --- After publish
        */

        /* update the  */

        if (finalCadPayload && finalCadPayload.sizeOriginal) {
            const imageURL = `${ASSET_BASE_URL[BUILD_ENV]}${finalCadPayload.sizeOriginal}`;
            yield put(
                change(FORM_NAME, 'finalCad', { ...finalCad, link: imageURL })
            );
        }
        if (finalFrontPayload && finalFrontPayload.sizeOriginal) {
            const imageURL = `${ASSET_BASE_URL[BUILD_ENV]}${finalFrontPayload.sizeOriginal}`;
            yield put(
                change(FORM_NAME, 'finalFront', {
                    ...finalFront,
                    link: imageURL
                })
            );
        }
        if (finalBackPayload && finalBackPayload.sizeOriginal) {
            const imageURL = `${ASSET_BASE_URL[BUILD_ENV]}${finalBackPayload.sizeOriginal}`;
            yield put(
                change(FORM_NAME, 'finalBack', { ...finalBack, link: imageURL })
            );
        }
        yield call(handleLoading, true);
        return handleComplete();
    } catch (error) {
        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage: 'Failed to Publish',
            error
        });
    }
}
function* updateMultipleFieldsSaga(action) {
    const { values, formName } = action.payload;
    for (const [fieldName, fieldValue] of Object.entries(values)) {
        yield put(change(formName, fieldName, fieldValue));
    }
}
function* setFinalPhotoSampleRefChildrenWorker({ payload: sampleRef }) {
    yield updateMultipleFieldsSaga({
        payload: { values: INITIAL_VALUES, formName: FORM_NAME }
    });
    if (sampleRef) {
        yield put(setFinalPhotoDataLoading(true));
        const {
            data: {
                data: [
                    {
                        initialImage = {},
                        finalImage = {},
                        garmentsType,
                        ...data
                    } = {}
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'Sample/GetFinalSampleDetailsBySampleRef',
            params: {
                sampleRef
            }
        });
        const normalizeSize800 = url => {
            if (url) {
                const image800 = url.replace(/Original_Image/g, '800_800');
                return { ...normalizeFile(image800), link: url };
            }
        };
        const nestedNormalizedData = {
            ...data,
            garmentsType,
            ...(garmentsType === 'P'
                ? {
                      initialCad: normalizeSize800(initialImage.frontImage),
                      finalCad: normalizeSize800(finalImage.frontImage)
                  }
                : {
                      initialFront: normalizeSize800(initialImage.frontImage),
                      finalFront: normalizeSize800(finalImage.frontImage),
                      initialBack: normalizeSize800(initialImage.backImage),
                      finalBack: normalizeSize800(finalImage.backImage)
                  })
        };
        const values = splice(nestedNormalizedData, {
            id: 'id',
            retailerId: 'retailerId',
            developmentNo: 'developmentNo',
            department: 'department',
            garmentsType: 'garmentsType',
            departmentContactPerson: 'buyer',
            ...(garmentsType === 'P'
                ? { initialCad: 'initialCad', finalCad: 'finalCad' }
                : {
                      initialFront: 'initialFront',
                      initialBack: 'initialBack',
                      finalFront: 'finalFront',
                      finalBack: 'finalBack'
                  })
        });

        yield updateMultipleFieldsSaga({
            payload: {
                values: { ...INITIAL_VALUES, ...values },
                formName: FORM_NAME
            }
        });
        yield put(setFinalPhotoDataLoading(false));
    }
}
function* routeToFinalPhotoUploadWorker() {
    yield put(destroyFinalPhotoForm());

    yield put(redirectToRoute(FINAL_PHOTO_UPLOAD_ROUTE_ID));
}
function* destroyFinalPhotoFormWorker() {
    const { finalCad, finalFront, finalBack } = yield select(
        formValueSelector(FORM_NAME),
        'finalCad',
        'finalFront',
        'finalBack'
    );

    if (finalCad && isBlobUrl(finalCad.url)) URL.revokeObjectURL(finalCad.url);
    if (finalFront && isBlobUrl(finalFront.url))
        URL.revokeObjectURL(finalFront.url);
    if (finalBack && isBlobUrl(finalBack.url))
        URL.revokeObjectURL(finalBack.url);

    yield put(destroy(FORM_NAME));
}

// Watchers
export default function*() {
    yield takeLatest(PUBLISH_FINAL_PHOTO, publishFinalPhotoWorker);
    yield takeLatest(
        SET_FINAL_PHOTO_SAMPLE_REF_CHILDREN,
        setFinalPhotoSampleRefChildrenWorker
    );
    yield takeLatest(
        ROUTE_TO_FINAL_PHOTO_UPLOAD,
        routeToFinalPhotoUploadWorker
    );
    yield takeLatest(DESTROY_FINAL_PHOTO_FORM, destroyFinalPhotoFormWorker);
    yield takeLatest(SEND_FINAL_PHOTO_EMAIL, reportUploadAndMailSendWorker);
}
