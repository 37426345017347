// Entities
import { INK_TYPES } from 'libs/datasets';

// Selectors
import { selectInkTypesData } from 'selectors';

// Normalizers
import { normalizeNestedData } from 'normalizers';

// References
export default [
    {
        entity: INK_TYPES,
        url: '/api/InkTypes',
        selector: selectInkTypesData,
        normalizer: normalizeNestedData
    }
];
