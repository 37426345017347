export const POSizeSetSchema = {
    retailerId: 0,
    sizeSet: 'string',
    isActive: true,
    sizes: [
        {
            id: 0,
            name: 'string',
            slNo: 0
        }
    ],
    userId: 0
};

export const orderEmailGroupSchema = {
    id: 0,
    emailGroupName: 'string',
    emailGroup: 'string',
    retailerId: 0
};

export const orderEmailBodySchema = {
    id: 0,
    emailBodyName: 'string',
    emailBody: 'string',
    retailerId: 0
};

export const orderFactorySchema = {
    id: 0,
    factoryName: 'string',
    factoryCode: 'string',
    factoryStartPoint: 'string',
    countryId: 0,
    isActive: false
};

export const orderRetailerFactorySchema = {
    id: 0,
    factoryId: 0,
    retailerId: 0,
    supplierNo: 'string',
    isActive: false
};

export const trimLabelTypeSchema = {
    id: 0,
    trimType: 'string',
    isActive: true
};

export const trimLabelSchema = {
    id: 0,
    trimTypeId: 0,
    trimRef: 'string',
    trimDescription: 'string',
    trimColour: 'string',
    retailerId: 0,
    userId: 0,
    images: {
        originalImageSizeUrl: 'string',
        image100SizeUrl: 'string',
        image350SizeUrl: 'string',
        image800SizeUrl: 'string'
    }
};

export const packingPackTypeSchema = {
    id: 0,
    packType: 'string',
    retailerId: 0
};

export const packingBoxTypeSchema = {
    id: 0,
    boxType: 'string',
    retailerId: 0
};

export const seasonSchema = {
    id: 0,
    season: 'string'
};

export const colourSchema = {
    id: 0,
    colorName: 'string'
};

export const poShipModeSchema = {
    id: 0,
    shipMode: 'string',
    retailerId: 0
};

export const poShipDestinationSchema = {
    id: 0,
    destination: 'string',
    retailerId: 0
};

export const poTypeSchema = {
    id: 0,
    poType: 'string',
    retailerId: 0,
    isActive: true
};

export const orderNoteSchema = {
    id: 0,
    orderNoteName: 'string',
    orderNote: 'string',
    retailerId: 0
};

export const statusSchema = {
    id: 0,
    orderStatus: 'string'
};
