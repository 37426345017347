import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Snackbar } from 'components';
import { resetNotification } from 'actions';
class _NotificationContainer extends PureComponent {
	// shouldComponentUpdate(nextProps, nextState) {
	// 	const { status, message } = this.props.notification;
	// 	const {
	// 		status: nextStatus,
	// 		message: nextMessage
	// 	} = nextProps.notification;
	// 	console.log('shouldComponentUpdate');
	// 	console.log(status, nextStatus, message, nextMessage);
	// 	return status !== nextStatus || message !== nextMessage;
	// }

	state = {
		open: false,
		status: null,
		message: ''
	};

	componentDidUpdate() {
		const { notification, resetNotification } = this.props;
		const { status, message } = notification;
		if (status || message) {
			this.setState({ open: true, status: status, message: message });
			resetNotification();
		}
	}

	render() {
		const { status, message } = this.state;
		return <Snackbar status={status} message={message} />;
	}
}

const mapStateToProps = ({ utils: { notification } }) => ({ notification });

const mapDispatch = {
	resetNotification
};

_NotificationContainer.propTypes = {
	classes: PropTypes.object
};

const NotificationContainer = connect(
	mapStateToProps,
	mapDispatch
)(_NotificationContainer);

export { NotificationContainer as default, NotificationContainer };
