import { useState, useEffect } from 'react';

export const useLoadedImage = ({ image }) => {
    const [loadedImage, setLoadedImage] = useState(null);

    useEffect(() => {
        let isSubscribed = true;
        const loadImage = async () =>
            await new Promise((resolve, reject) => {
                let refImage = new Image();
                refImage.src = image;
                refImage.onload = () => resolve(image);
                refImage.onerror = error => reject(error);
            });

        if (!!image) {
            setLoadedImage(null);
            loadImage()
                .then(src => isSubscribed && setLoadedImage(src))
                .catch(error => {
                    if (isSubscribed) {
                        setLoadedImage(null);
                    }
                });
        }

        return () => {
            isSubscribed = false;
        };
    }, [image]);

    return loadedImage;
};
