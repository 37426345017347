import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';

import { DOMAIN_NAME } from 'orders/common';
import { ORDER_SUSTAINABILITY } from 'orders/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: ORDER_SUSTAINABILITY,
    name: 'sustainability',
    label: 'Sustainability',
    placeholder: 'Select Sustainability',
    disabled: false,
    required: false
};

const SelectColor = props => <Select {...props} />;

SelectColor.propTypes = propTypes;
SelectColor.defaultProps = defaultProps;

export default SelectColor;
