import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import GridXlsxExport from 'dataGrid/components/GridXLSXExport';

import { DISPATCH_EXPORT_XL_DATA } from 'sampling/redux.actionTypes';
import { dispatchExportExcelDataLoadingSelector } from 'sampling/redux.selectors';

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

const mapStateToProps = state => ({
    loading: dispatchExportExcelDataLoadingSelector(state)
});

const mapDispatchToProps = (dispatch, { ...payload }) => ({
    handleXLDownload: () => dispatch({ type: DISPATCH_EXPORT_XL_DATA, payload })
});

const ExportOptions = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(({ classes, loading, handleXLDownload }) => {
    return (
        <div className={classes.root}>
            <GridXlsxExport
                title='XLSX'
                loading={loading}
                onClick={handleXLDownload}
            />
        </div>
    );
});

export { ExportOptions as default, ExportOptions };
