import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectModel from '@formComponents/SelectModel';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

import { licensorsSelector } from '../redux.selectors';
import { DOMAIN_NAME } from '../common';
import { LICENSORS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'licensor',
    label: 'Licensor',
    placeholder: 'Select licensor',
    disabled: false,
    required: false
};

const mapStateToProps = state => ({
    data: licensorsSelector(state),
    loading: datasetLoadingSelector(state, DOMAIN_NAME, LICENSORS)
});

const mapDispatchToProps = dispatch => ({
    fetchDataset: () => dispatch(fetchDatasets(LICENSORS))
});

const SelectLicensors = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectLicensors.propTypes = propTypes;
SelectLicensors.defaultProps = defaultProps;

export { SelectLicensors as default, SelectLicensors };
