import makeContext from '@libs/makeContext';
import { MAIN_BODY_FABRIC_ID } from '../../common';

const initialState = {
    fabrics: [],
    includeTc: false
};

const fabricsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addFabric': {
            const { fabrics } = state;
            const { payload } = action;

            reduxFormOnChange([...fabrics, { ...payload, id: 0 }]);

            return action;
        }

        case 'duplicateFabric': {
            const { fabrics } = state;
            const { payload } = action;

            // If selected one is MainBodyFabric then
            // fabricFor will be null for this duplication.
            // Other than remain same.
            // We can keep only one mainBodyFabric for each publish.

            const shouldAlter =
                fabrics[payload].fabricFor === MAIN_BODY_FABRIC_ID;

            const { fabFrom, ...newFabric } = fabrics[payload];

            const duplicateFabric = {
                ...newFabric,
                ...(shouldAlter ? { fabricFor: null } : {}),
                id: 0
            };

            reduxFormOnChange([...fabrics, duplicateFabric]);

            return action;
        }
        case 'editFabric': {
            const { fabrics } = state;
            const { index, values } = action.payload;

            const fabricsAfterEdit = fabrics.map(
                ({ id, ...fabric }, fabricIndex) => ({
                    id,
                    ...(index === fabricIndex ? values : fabric)
                })
            );

            reduxFormOnChange(fabricsAfterEdit);

            return action;
        }

        case 'deleteFabric': {
            const { fabrics } = state;
            const { payload } = action;

            const fabricsAfterDelete = fabrics.filter(
                (fabric, index) => index !== payload
            );

            reduxFormOnChange(fabricsAfterDelete);

            return action;
        }
        default:
            return action;
    }
};

const [
    FabricsProvider,
    useFabricsState,
    useFabricsDispatch,
    useFabrics
] = makeContext(fabricsReducer, initialState, { name: 'fabrics', middleware });

export {
    useFabrics as default,
    FabricsProvider,
    useFabricsState,
    useFabricsDispatch
};
