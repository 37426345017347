import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';

// Material
import { withStyles, TextField, Grid } from '@material-ui/core';

// Local
import styles from './styles';
import {
    Select,
    SelectBase,
    Input,
    MultilineInput,
    Checkbox
} from 'components';

const renderTextField = ({
    label,
    placeholder,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        label={label}
        placeholder={placeholder || label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);


const ReportsMailFormHeaders = ({
    classes: { root, input, select, inputBase },
    mailGroupDataset
}) => (
    <div className={root}>
        <SelectBase
            name='mailGroup'
            data={mailGroupDataset}
            placeholder='Select Group'
            className={select}
        />
        <Field
            name='to'
            component={renderTextField}
            label='To'
            className={input}
        />
        <Field
            name='cc'
            component={renderTextField}
            label='Cc'
            className={input}
        />
        <Field
            name='subject'
            component={renderTextField}
            label='Subject'
            className={input}
        />
    </div>
);

const mapStateToProps = ({
    auth: { userEmail },
    reports: {
        datasets: { mailGroupDataset }
    }
}) => ({
    mailGroupDataset
});

const _ReportsMailFormHeaders = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(ReportsMailFormHeaders);

export {
    _ReportsMailFormHeaders as default,
    _ReportsMailFormHeaders as ReportsMailFormHeaders
};
