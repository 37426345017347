import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import { httpStatus, httpType } from 'helpers';

import { SnackbarBase } from './_variants';

const _Snackbar = memo(function _Snackbar(props) {
    const { status, message } = props;
    const [open, setOpen] = useState(false);
    if (status || (message && message.length)) {
        setOpen(true);
    }
    let snackMessage = '';
    let snackVariant = 'info';

    if (status) snackMessage = httpStatus(status);
    if (!message || !message.length) snackVariant = httpType(status);

    const handleClose = event => setOpen(false);

    return (
        <SnackbarBase
            open={open}
            onClose={handleClose}
            message={snackMessage}
            variant={snackVariant}
        />
    );
});

_Snackbar.defaultProps = {
    status: null,
    message: ''
};

_Snackbar.propTypes = {
    status: PropTypes.number,
    message: PropTypes.string
};

export { _Snackbar as default, _Snackbar as Snackbar };
