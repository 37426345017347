export const normalizeSendToUks = ({ data: sendToUks }) =>
    sendToUks.map(({ sendtoNabilId, sendtoNabil }) => ({
        sendToUkId: Number(sendtoNabilId),
        sendToUk: sendtoNabil
    }));

export const normalizeSendToBuyers = ({ data: sendToBuyers }) =>
    sendToBuyers.map(({ sendtoNewlookId, sendtoNewlook }) => ({
        sendToBuyerId: Number(sendtoNewlookId),
        sendToBuyer: sendtoNewlook
    }));

export const normalizeSendToLicensors = ({ data: sendToLicensors }) =>
    sendToLicensors.map(({ sendtoLicenceID, sendtoLicence }) => ({
        sendToLicensorId: Number(sendtoLicenceID),
        sendToLicensor: sendtoLicence
    }));

