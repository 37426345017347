import React from 'react';

import { withStyles, TableCell as MuiCell } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';

const styles = ({ spacing, palette }) => ({
    root: {
        borderBotttomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 1
    },
    head: {
        backgroundColor: palette.background.light,
        color: palette.secondary.main
    },
    body: {
        fontSize: spacing.unit * 1.5,
        lineHeight: 1.5
    }
});

const TableCell = withStyles(styles)(
    ({ classes, children, styles, ...rest }) => {
        const c = composeClsx({ classes, styles });

        return (
            <MuiCell classes={c} {...rest}>
                {children}
            </MuiCell>
        );
    }
);

export { TableCell as default, TableCell };
