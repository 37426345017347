import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { Image as PDFImage } from '@libComponents/ReactPDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    url: PropTypes.string,
    label: PropTypes.string
};

const styles = ({ spacing, palette }) => ({
    imageContainer: {
        width: '100%',
        height: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,

        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    },
    label: {
        marginTop: spacing.unit * 0.5,
        fontSize: spacing.unit * 1.5,
        textAlign: 'center'
    }
});

const Image = withPDFStyles(styles)(({ classes: c, url, label, styles }) => {
    return (
        <View style={styles}>
            <View style={c.imageContainer}>
                {url && <PDFImage style={c.image} src={url} />}
            </View>
            <Text style={c.label}>{label}</Text>
        </View>
    );
});

Image.propTypes = propTypes;

export default Image;
