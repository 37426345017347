import { useCallback, useMemo } from 'react';
import {
    VERSION_LABEL_FONT_SIZE,
    VERSION_LABEL_HEIGHT_UNITS,
    VERSIONS_PADDING_UNITS,
    VERSIONS_PER_ROW
} from './constants';
import { FULL_WIDTH_UNITS } from '../constants';
import { HEADER_HEIGHT_UNITS } from './constants';
import { useVersionsState } from './useVersions';

export const useContainerStyles = ({
    classes: c,
    theme: { spacing },
    versionsHeight,
    versionsPaddingUnits
}) => {
    const container = useMemo(() => {
        return { ...c.container, height: versionsHeight };
    }, [c, versionsHeight]);

    const grid = useMemo(() => {
        const height =
            versionsHeight -
            (HEADER_HEIGHT_UNITS + versionsPaddingUnits * 2) * spacing.unit;

        const width =
            spacing.unit * (FULL_WIDTH_UNITS - versionsPaddingUnits * 2);

        const margin = spacing.unit * versionsPaddingUnits;

        return { ...c.grid, height, width, margin };
    }, [c, versionsHeight, versionsPaddingUnits]);

    return { ...c, container, grid };
};

export const useVersionStyles = ({ classes: c, theme: { spacing } }) => {
    const {
        versionsPerRow,
        versionsPaddingUnits,
        versionLabelHeightUnits,
        versionLabelFontSize
    } = useVersionsState();

    const versionWidthUnits = useMemo(
        () =>
            (FULL_WIDTH_UNITS -
                (1 + versionsPerRow) * 2 * versionsPaddingUnits) /
            versionsPerRow,
        [versionsPerRow, versionsPaddingUnits]
    );

    const container = useMemo(() => {
        const width = spacing.unit * versionWidthUnits;
        const height =
            spacing.unit *
            (versionWidthUnits * Math.sqrt(2) + versionLabelHeightUnits);

        const margin = spacing.unit * versionsPaddingUnits;

        return {
            ...c.container,
            width,
            height,
            marginTop: margin,
            marginLeft: margin,
            marginRight: margin
        };
    }, [
        versionsPerRow,
        versionsPaddingUnits,
        versionLabelHeightUnits,
        versionWidthUnits
    ]);

    const imageContainer = useMemo(() => {
        const height = spacing.unit * versionWidthUnits * Math.sqrt(2);

        return { ...c.imageContainer, height };
    }, []);

    const labelContainer = useMemo(() => {
        const height = spacing.unit * versionLabelHeightUnits;

        return { ...c.labelContainer, height };
    }, [versionLabelHeightUnits]);

    const label = useMemo(
        () => ({ ...c.label, fontSize: versionLabelFontSize }),
        [versionLabelFontSize]
    );

    return { ...c, container, imageContainer, labelContainer, label };
};

export const useInitializeStyles = ({
    maxHeight,
    versionCount,
    theme: { spacing }
}) => {
    const calculateVersionHeight = useCallback(
        countPerRow => {
            const scale = VERSIONS_PER_ROW / countPerRow;

            const versionsPaddingUnits = VERSIONS_PADDING_UNITS * scale;
            const versionLabelHeightUnits = VERSION_LABEL_HEIGHT_UNITS * scale;

            const versionWidthUnits =
                (FULL_WIDTH_UNITS -
                    (1 + countPerRow) * 2 * versionsPaddingUnits) /
                countPerRow;

            // Add Image Height + Label Height + MarginTop
            const rowHeight =
                (versionWidthUnits * Math.sqrt(2) +
                    versionLabelHeightUnits +
                    versionsPaddingUnits) *
                spacing.unit;

            const rows = Math.ceil(versionCount / countPerRow);

            // Add total row height + Header height + Padding height x 3 (top + bottom + extra bottom)
            return (
                rowHeight * rows +
                (HEADER_HEIGHT_UNITS + versionsPaddingUnits * 3) * spacing.unit
            );
        },
        [versionCount, spacing]
    );

    const calculateCountPerRow = useCallback(
        countPerRow => {
            const versionHeight = calculateVersionHeight(countPerRow);
            const canFit = versionHeight <= maxHeight;

            if (canFit) return countPerRow;
            else return calculateCountPerRow(countPerRow + 1);
        },
        [calculateVersionHeight, maxHeight]
    );

    return useMemo(() => {
        const countPerRow = calculateCountPerRow(VERSIONS_PER_ROW);
        const scale = VERSIONS_PER_ROW / countPerRow;

        const versionsPaddingUnits = VERSIONS_PADDING_UNITS * scale;
        const versionsHeight = calculateVersionHeight(countPerRow);

        return {
            versionsHeight: versionsHeight,
            versionsPerRow: countPerRow,
            versionLabelHeightUnits: VERSION_LABEL_HEIGHT_UNITS * scale,
            versionLabelFontSize: VERSION_LABEL_FONT_SIZE * scale,
            versionsPaddingUnits
        };
    }, [calculateVersionHeight, calculateCountPerRow]);
};
