import React from 'react';

import { IndeterminateCheckbox } from 'gridView/components';
import useGridView from 'gridView/useGridView';

const Adornment = ({ id, itemIndex }) => {
    const [
        {
            selectedItems: { originalIds, rowIds }
        },
        gridViewDispatch
    ] = useGridView();

    const handleChange = e => {
        let payload = {};
        if (e.currentTarget.checked) {
            payload = {
                originalIds: [...originalIds, id],
                rowIds: { ...rowIds, [itemIndex]: true }
            };
        } else {
            const filteredIds = originalIds.filter(
                originalId => originalId !== id
            );
            payload = {
                originalIds: filteredIds,
                rowIds: { ...rowIds, [itemIndex]: false }
            };
        }

        gridViewDispatch({
            type: 'set-selectedRows',
            payload
        });
    };

    const props = {
        checked: rowIds[itemIndex] || false,
        onChange: handleChange
    };
    return <IndeterminateCheckbox {...props} />;
};

export default Adornment;
