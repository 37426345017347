import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { styles } from './style';

function MultiStep({ ...props }) {
    const {
        classes,
        classes: c,
        steps,
        active = 0,
        complete = 0,
        onClick = () => {}
    } = props;
    const connector = (
        <StepConnector
            classes={{
                active: classes.connectorActive,
                completed: classes.connectorCompleted, // Change to disabled
                disabled: classes.connectorDisabled,
                line: classes.connectorLine
            }}
        />
    );
    const stepperSteps = ['', ...steps];
    return (
        <Stepper
            alternativeLabel
            activeStep={active}
            className={classes.root}
            connector={connector}
        >
            {stepperSteps.map((label, index) => (
                <Step
                    key={label}
                    className={
                        index === 0 // check index
                            ? c.firstStep
                            : index === complete
                            ? c.visitedStep
                            : ''
                    }
                >
                    <StepLabel
                        icon={index}
                        onClick={onClick(index)}
                        classes={{
                            labelContainer: classes.stepLabelContainer
                        }}
                        StepIconProps={{
                            //completed: index === complete,
                            classes: {
                                root:
                                    index === complete
                                        ? classes.stepCompleted
                                        : classes.stepRoot,
                                active: classes.stepActive,
                                completed: classes.stepCompleted
                            }
                        }}
                        //className={classes.multiLabel}
                        className={
                            index < complete // check index
                                ? classNames(
                                      classes.multiLabel,
                                      classes.multiLabelVisited
                                  )
                                : c.multiLabel
                        }
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
const _MultiStep = withStyles(styles)(MultiStep);

export { _MultiStep as default, _MultiStep as MultiStepper };
