import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

import { isSeasaltSelector } from 'sampling/redux.selectors';
import { getHeaders } from './common';

const mapState = state => ({
    isSeasalt: isSeasaltSelector(state)
});

const styles = ({ spacing, palette }) => ({
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

        backgroundColor: palette.background.light,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    cell: {
        flex: 1,
        fontSize: spacing.unit * 1.75,
        fontWeight: 600,
        padding: spacing.unit,
        overflowWrap: 'break-word',
        overflow: 'hidden',

        '&:not(:last-child)': {
            borderRightStyle: 'solid',
            borderRightWidth: 1,
            borderRightColor: palette.background.dark
        }
    }
});

const Header = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, isSeasalt, className }) => (
    <div className={clsx(c.header, className)}>
        {getHeaders(isSeasalt).map(({ name, label }) => (
            <Typography key={name} className={c.cell}>
                {label}
            </Typography>
        ))}
    </div>
));

export default Header;
