import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';
import Setup from '../Setup';
import RefAndDetails from '../RefAndDetails';
import { TabularView, Frame, Title } from 'components/PDF';
import Others from '../Others';

const styles = ({ palette }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    },
    frame: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 0
    },
    watermarkContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',

        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    watermarkText: {
        position: 'relative',
        transform: 'rotate(-45deg)',
        textTransform: 'uppercase',
        color: palette.error.main,
        opacity: 0.6,
        fontSize: 78
    },
    pendingPrintWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10
    },
    pendingPrintText: {
        color: 'red',
        fontSize: 16
    }
});

const Content = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        setup,
        refAndDetails,
        fabricDetails: { data = [], count = 0 } = {},
        samples,
        others,
        boughtDetails: { data: bdata = [], count: bcount } = {},
        trimDetails: { data: tdata = [], count: tcount } = {},
        docTitle,
        pageTitle
    }) => {
        const secondPageContent =
            (!!samples && !!samples.length) || !!bcount || !!tcount;
        const renderWatermark = () => (
            <View style={c.watermarkContainer}>
                <Text style={c.watermarkText}>incomplete</Text>
            </View>
        );
        return (
            <Document title={docTitle}>
                <Page size='A4' style={c.page}>
                    <Title
                        text={pageTitle}
                        style={{ fontSize: 20, borderBottom: 1 }}
                    />
                    <Setup {...setup} />
                    <RefAndDetails {...refAndDetails} />
                    {count > 0 && (
                        <TabularView data={data} title={'Fabric Details'} />
                    )}
                    {renderWatermark()}
                </Page>
                {secondPageContent && (
                    <Page size='A4' style={c.page}>
                        <View style={c.pageWatermark}>
                            <Text style={c.watermark}>Incomplete</Text>
                        </View>
                        {!!samples && !!samples.length && (
                            <View style={c.frame}>
                                {samples.map(({ url }, idx) => (
                                    <Frame
                                        url={url}
                                        label={idx === 0 ? 'Front' : 'Back'}
                                        key={idx}
                                    />
                                ))}
                            </View>
                        )}
                        <Others {...others} />
                        {bcount > 0 && (
                            <TabularView
                                data={bdata}
                                title={'Bought Details'}
                            />
                        )}
                        {tcount > 0 && (
                            <TabularView data={tdata} title={'Trim Details'} />
                        )}
                        {!!refAndDetails.pendingPrint && (
                            <View style={c.pendingPrintWrapper}>
                                <Text style={c.pendingPrintText}>
                                    N.B: Please prepare panels only. Style
                                    waiting artwork
                                </Text>
                            </View>
                        )}
                        {renderWatermark()}
                    </Page>
                )}
            </Document>
        );
    }
);

export default Content;
