import PropTypes from 'prop-types';

export const renderedComponentPropType = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node
]);

export const functionalComponentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
]);

/*
 * Remove props that are present in proptypes
 * @param Object incoming props
 * @param Object expected propTypes
 */
export const cleanProps = (props, propTypes) => {
    if (!props || !propTypes) return Object.assign({}, props);

    return Object.keys(props).reduce((acc, propName) => {
        if (!propTypes[propName]) {
            acc[propName] = props[propName];
        }
        return acc;
    }, {});
};

/*
 * Extract props that are present in proptypes
 * @param Object incoming props
 * @param Object expected propTypes
 */

export const extractProps = (props, propTypes) => {
    if (!props || !propTypes) return {};

    return Object.keys(props).reduce((acc, propName) => {
        if (propTypes[propName]) {
            acc[propName] = props[propName];
        }
        return acc;
    }, {});
};
