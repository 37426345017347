import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { Image } from '@libComponents/ReactPDF';
import { withPDFStyles } from '@libComponents/ReactPDF';
import { useVersionStyles } from './hooks';

const styles = ({ palette }) => ({
    container: {},
    imageContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    },
    labelContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    label: {}
});

const Version = withPDFStyles(styles, { withTheme: true })(
    ({ classes, theme, version, index }) => {
        const c = useVersionStyles({ classes, theme });

        return (
            <View style={c.container}>
                <View style={c.imageContainer}>
                    <Image style={c.image} src={version} />
                </View>
                <View style={c.labelContainer}>
                    <Text style={c.label}>{`V${index + 1}`}</Text>
                </View>
            </View>
        );
    }
);

export default Version;
