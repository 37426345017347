import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { Paper, Typography, withStyles, Grid } from '@material-ui/core';

import Button from '@libComponents/Button';

import { FORM_NAME } from '../common';
import { routeToShape, routeToNewOrder } from '../../redux.actions';
import { useShapeState } from '../useShape';

const styles = ({ palette, shadows, spacing, typography }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        boxShadow: shadows[0]
    },
    congratulations: {
        marginTop: spacing.unit * 5.5,
        fontWeight: typography.fontWeightBold,
        fontSize: '3.7vw',
        color: palette.common.black
    },
    uploadedContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: spacing.unit,
        marginBottom: spacing.unit * 3,
        paddingLeft: spacing.unit * 30,
        paddingRight: spacing.unit * 30
    },
    uploaded: {
        fontWeight: typography.fontWeightLight,
        fontSize: '1.8vw',
        whiteSpace: 'nowrap',
        paddingRight: spacing.unit // Space
    },
    uploadedName: {
        color: palette.success.main,
        fontWeight: typography.fontWeightHeavy,
        fontSize: '1.8vw',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: spacing.unit
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 10
    },
    button: {
        height: spacing.unit * 5.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        paddingLeft: spacing.unit * 3.5,
        paddingRight: spacing.unit * 3.5,
        marginRight: spacing.unit,
        marginTop: spacing.unit * 1.5
    }
});

const mapState = state => ({
    sampleReference: formValueSelector(FORM_NAME)(state, 'sampleReference')
});

const mapDispatch = (dispatch, { mode }) => ({
    routeToNew: () => dispatch(routeToShape(mode)),
    routeToNewOrder: () => dispatch(routeToNewOrder(FORM_NAME))
});

const Complete = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withStyles(styles)
)(({ classes: c, sampleReference, routeToNew, routeToNewOrder }) => {
    const { reset } = useShapeState();

    const handleRouteToNew = useCallback(() => {
        reset();
        routeToNew();
    }, [routeToNew, reset]);

    return (
        <Paper className={c.container}>
            <Typography className={c.congratulations} variant='h2'>
                CONGRATULATIONS!
            </Typography>
            <div className={c.uploadedContainer}>
                <Typography variant='h4' className={c.uploaded}>
                    You have successfully uploaded a sample request; email to be
                    sent soon:
                </Typography>
                <Typography variant='h4' className={c.uploadedName}>
                    {sampleReference}
                </Typography>
            </div>
            <div className={c.actionsContainer}>
                <Typography variant='subtitle1'>
                    What would you like to do next?
                </Typography>
                <Grid container>
                    <Grid item>
                        <Button onClick={handleRouteToNew} className={c.button}>
                            Send another
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color='success'
                            onClick={() => routeToNewOrder()}
                            className={c.button}
                        >
                            Order This Sample
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
});

export default Complete;
