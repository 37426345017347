import makeContext from '@libs/makeContext';

import { normalizePermissionsMenus } from '../../../../redux.normalizers';

const initialState = {
    permissions: {
        menus: [],
        retailers: [],
        sampleFactories: [],
        orderFactories: []
    },
    data: {
        menus: [],
        retailers: [],
        sampleFactories: [],
        orderFactories: []
    },
    expandedRows: [],
    menuAllSelected: false,
    retailerAllSelected: false,
    sampleFactoryAllSelected: false,
    orderFactoryAllSelected: false
};

const initializeUserPermission = (permissions, data) => {
    permissions = permissions || initialState.permissions;
    data = Object.keys(data).length > 0 ? data : initialState.data;

    const menuAllSelected =
        permissions.menus.length > 0 &&
        permissions.menus.length === data.menus.length;
    const retailerAllSelected =
        permissions.retailers.length > 0 &&
        permissions.retailers.length === data.retailers.length;
    const sampleFactoryAllSelected =
        permissions.sampleFactories.length > 0 &&
        permissions.sampleFactories.length === data.sampleFactories.length;
    const orderFactoryAllSelected =
        permissions.orderFactories.length > 0 &&
        permissions.orderFactories.length === data.orderFactories.length;

    return {
        permissions,
        data,
        menuAllSelected,
        retailerAllSelected,
        sampleFactoryAllSelected,
        orderFactoryAllSelected
    };
};

const toggleSelection = (state, payload, permissionsKey, allSelectedKey) => {
    const {
        [permissionsKey]: newPermissions,
        [allSelectedKey]: allSelected
    } = payload;

    return {
        ...state,
        [allSelectedKey]: allSelected,
        permissions: {
            ...state.permissions,
            [permissionsKey]: newPermissions
        }
    };
};

const rolePermissionsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case 'TOGGLE_EXPAND_ROW': {
            const { expandedRows } = state;
            if (expandedRows.includes(payload)) {
                return {
                    ...state,
                    expandedRows: expandedRows.filter(id => id !== payload)
                };
            } else {
                return { ...state, expandedRows: [...expandedRows, payload] };
            }
        }

        case 'TOGGLE_MENU_SELECTION': {
            return toggleSelection(state, payload, 'menus', 'menuAllSelected');
        }

        case 'TOGGLE_RETAILER_SELECTION': {
            return toggleSelection(
                state,
                payload,
                'retailers',
                'retailerAllSelected'
            );
        }

        case 'TOGGLE_SAMPLE_FACTORY_SELECTION': {
            return toggleSelection(
                state,
                payload,
                'sampleFactories',
                'sampleFactoryAllSelected'
            );
        }

        case 'TOGGLE_ORDER_FACTORY_SELECTION': {
            return toggleSelection(
                state,
                payload,
                'orderFactories',
                'orderFactoryAllSelected'
            );
        }

        default:
            return state;
    }
};

const togglePermission = (
    state,
    action,
    permissionKey,
    permissionSingluarKey,
    idKey,
    reduxFormOnChange
) => {
    const { data, permissions } = state;
    const { payload } = action;
    let newPermissions;

    if (payload === 'ALL') {
        if (permissions[permissionKey].length === data[permissionKey].length) {
            newPermissions = [];
        } else {
            newPermissions = data[permissionKey].map(item => item[idKey]);
        }
    } else {
        const hasPermission = permissions[permissionKey].includes(payload);
        if (hasPermission) {
            newPermissions = permissions[permissionKey].filter(
                item => item !== payload
            );
        } else {
            const { [idKey]: itemId } = data[permissionKey].find(
                item => item[idKey] === payload
            );
            newPermissions = [...permissions[permissionKey], itemId];
        }
    }

    reduxFormOnChange({
        ...permissions,
        [permissionKey]: newPermissions
    });

    return {
        ...action,
        payload: {
            [permissionKey]: newPermissions,
            [`${permissionSingluarKey}AllSelected`]:
                newPermissions.length === data[permissionKey].length
        }
    };
};

function findMenuItemWithParents(menuItems, menuId) {
    function findRecursively(menuId, items) {
        for (const item of items) {
            if (item.menuId === menuId) {
                return [
                    menuId,
                    ...(item.menuId === item.parentMenuId
                        ? []
                        : findRecursively(item.parentMenuId, items))
                ];
            }
        }
        return [];
    }

    return findRecursively(menuId, menuItems);
}

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'TOGGLE_MENU_SELECTION': {
            const { data, permissions } = state;
            const { payload } = action;
            let newMenus;

            if (payload === 'ALL') {
                if (permissions.menus.length === data.menus.length) {
                    newMenus = [];
                } else {
                    newMenus = normalizePermissionsMenus(data.menus);
                }
            } else {
                if (permissions.menus.includes(payload)) {
                    newMenus = permissions.menus.filter(
                        menuId => menuId !== payload
                    );
                } else {
                    const menuWithParents = findMenuItemWithParents(
                        data.menus,
                        action.payload
                    );
                    newMenus = [
                        ...new Set([...permissions.menus, ...menuWithParents])
                    ];
                }
            }
            reduxFormOnChange({ ...permissions, menus: newMenus });

            return {
                ...action,
                payload: {
                    menus: newMenus,
                    menuAllSelected: newMenus.length === data.menus.length
                }
            };
        }

        case 'TOGGLE_RETAILER_SELECTION': {
            return togglePermission(
                state,
                action,
                'retailers',
                'retailer',
                'retailerId',
                reduxFormOnChange
            );
        }

        case 'TOGGLE_SAMPLE_FACTORY_SELECTION': {
            return togglePermission(
                state,
                action,
                'sampleFactories',
                'sampleFactory',
                'sampleFactoryId',
                reduxFormOnChange
            );
        }

        case 'TOGGLE_ORDER_FACTORY_SELECTION': {
            return togglePermission(
                state,
                action,
                'orderFactories',
                'orderFactory',
                'orderFactoryId',
                reduxFormOnChange
            );
        }
        default:
            return action;
    }
};

const [
    RolePermissionsProvider,
    useRolePermissionsState,
    useRolePermissionsDispatch,
    useRolePermissions
] = makeContext(rolePermissionsReducer, initialState, {
    name: 'rolePermissions',
    middleware
});

export {
    useRolePermissions as default,
    RolePermissionsProvider,
    useRolePermissionsState,
    useRolePermissionsDispatch,
    initializeUserPermission
};
