import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';

// Material
import { withStyles } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';
import { ContentContainer } from '@libComponents/ContentContainer';
import { makeSideEffectCallback } from '@libs/makeContext';
import { useDimensionsObserver } from '@libHooks/useDimensions';

import FormHelperText from '../FormHelperText';
import Footer from './Footer';
import { Adornment } from './Adornment';
import { ImageDnDProvider } from './useImageDnD';
import { Content } from './Content';

const propTypes = {};

const defaultProps = {};

const styles = ({ palette, spacing, shadows }) => ({
    root: {
        width: '100%',
        minHeight: spacing.unit * 20,
        boxShadow: shadows[0],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRadius: 0,
        marginBottom: 0
    },
    header: {
        height: spacing.unit * 5.5
    },
    content: {
        width: '100%',
        height: '100%'
    }
});

const ImageDnD = withStyles(styles, { withTheme: true })(
    ({
        classes,
        styles,
        theme: { spacing },
        label,
        input: { onChange, value, onBlur },
        meta: { touched, error, form },
        canDelete,
        fieldName,
        required
    }) => {
        const c = composeClsx({ classes, styles });
        const [contentRef, { height }] = useDimensionsObserver();
        const inputRef = useRef(null);
        const [key, setKey] = useState(new Date().getTime());
        const id = `${form}-${fieldName}`;
        const hasError = touched && !!error;

        const handleClick = useCallback(() => {
            inputRef.current.click();
        }, [inputRef]);

        const initialize = useCallback(
            state => ({
                ...state,
                file: value,
                onBlur
            }),
            [value, onBlur]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const [action, dispatch] = useState({});

        const handleFile = useCallback(
            ({ target: { files: [file] } = {} }) => {
                dispatch({ type: 'addFile', payload: file });
                setKey(new Date().getTime());
            },
            [value]
        );

        const handleCancel = useCallback(() => {
            dispatch({ type: 'removeFile' });
        }, [dispatch]);

        const name = useMemo(() => (value ? value.filename : 'Upload'), [
            value
        ]);

        const contentStyle = useMemo(
            () => ({
                '--imageHeight': `${height - spacing.unit * 5}px`
            }),
            [height, spacing]
        );
        return (
            <ImageDnDProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title={label}
                    shouldBorderRed={hasError}
                    styles={{ container: c.root, header: c.header }}
                    required={required}
                    AdornmentComponent={
                        canDelete && !!value ? (
                            <Adornment onClick={handleCancel} />
                        ) : (
                            <Fragment />
                        )
                    }
                >
                    <div
                        className={c.content}
                        style={contentStyle}
                        ref={contentRef}
                        id={id}
                    >
                        <Content onClick={handleClick} />
                        <Footer onClick={handleClick} label={name} />
                        <input
                            key={key}
                            accept='image/*'
                            type='file'
                            hidden
                            ref={inputRef}
                            onChange={handleFile}
                        />
                    </div>
                </ContentContainer>
                {hasError && <FormHelperText error>{error}</FormHelperText>}
            </ImageDnDProvider>
        );
    }
);

ImageDnD.propTypes = propTypes;
ImageDnD.defaultProps = defaultProps;

export { ImageDnD as default, ImageDnD };
