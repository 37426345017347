export const normalizeCompositions = ({ data: compositions }) =>
    compositions.map(({ fabricComId, fabricCom }) => ({
        compositionId: fabricComId,
        composition: fabricCom
    }));

export const normalizeFinishedGsms = ({ data: finishedGsms }) =>
    finishedGsms.map(({ gsmid, gsm }) => ({
        finishedGsmId: gsmid,
        finishedGsm: gsm
    }));
