import reduceReducer from 'reduce-reducers';

// Local
import fabricReducer from './fabric';
import referenceReducer from './reference';
import requestReducer from './request';

const samplingReducer = reduceReducer(
    {},
    fabricReducer,
    referenceReducer,
    requestReducer
);

export { samplingReducer as default, samplingReducer };
