import { MODULE_NAME } from './common';

// Each grid view searchable columns
const searchableColumns = [
    {
        value: 'sampleRef',
        label: 'Echo Ref'
    },
    {
        value: 'specRef',
        label: 'Spec Ref'
    },
    {
        value: 'specDesc',
        label: 'Spec Desc'
    }
];

// Each grid view specific reports info
const reportsInfo = {
    xlsx: { show: false, columns: [] },
    pdf: { show: false, columns: [] },
    sampleOrderedXL: { show: true, columns: [], title: 'XLSX' }
};

export const config = {
    searchableColumns,
    moduleName: MODULE_NAME,
    reportsInfo,
    disableCheckbox: true,
    disableEditIcon: true
};
