import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME } from '../common';

const propTypes = {
    name: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    domain: DOMAIN_NAME
};

const SamplePaginatedSelect = props => <PaginatedSelect {...props} />;

SamplePaginatedSelect.propTypes = propTypes;
SamplePaginatedSelect.defaultProps = defaultProps;

export { SamplePaginatedSelect as default, SamplePaginatedSelect };
