import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Local
import SelectModel from '@formComponents/SelectModel';
import {
    DOMAIN_NAME,
    DISPATCH_DETAILS_FORM_NAME
} from 'sampling/SampleDispatch/common';
import { DISPATCH_SAMPLES_BY_ORDER_NO } from 'sampling/redux.datasets';

// Datasets
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';

const dataSelector = createCachedSelector(entitySelector, data =>
    data.map(({ sampleRef }) => ({
        label: sampleRef,
        value: sampleRef
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sampleRef',
    label: 'Sample Reference',
    domain: DOMAIN_NAME,
    entity: DISPATCH_SAMPLES_BY_ORDER_NO,
    disabled: false,
    required: true
};

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    orderNo: formValueSelector(DISPATCH_DETAILS_FORM_NAME)(state, 'orderNo')
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectSampleReferenceByOrder = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, orderNo, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, [orderNo]);

    return <SelectModel {...props} />;
});

SelectSampleReferenceByOrder.propTypes = propTypes;
SelectSampleReferenceByOrder.defaultProps = defaultProps;

export default SelectSampleReferenceByOrder;
