import { DOMAIN_NAME } from '../common';

const FORM_NAME = 'sealSample';

const LABELS = {
    setup: 'New Style Introduction',
    details: 'New Style Details',
    other: 'Other Information',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    qcVersion: 1,
    fabrics: [],
    attachments: [],
    bulkProductionUnit: {}
};

const SEAL_SAMPLE_FORM_FIELDS = {
    0: [
        { name: 'jwpNo', type: 'select' },
        { name: 'qcStyle', type: 'select' },
        { name: 'qcColor', type: 'select' },
        { name: 'qcDate', type: 'date' },
        { name: 'sealDate', type: 'date' },
        { name: 'factory', type: 'input' },
        { name: 'quantity', type: 'input' },
        { name: 'styleDesc', type: 'input' },
        { name: 'finishWash', type: 'select' },
        { name: 'fabricBatch', type: 'input' },
        { name: 'prodUnit', type: 'input' },
        { name: 'panelStatus', type: 'select' },
        { name: 'bulkProductionUnit', type: 'array' }
    ],
    1: [
        { name: 'qcReference' },
        { name: 'qcVersion' },
        { name: 'bodyFabricStatus', type: 'select' },
        { name: 'sampleColorStatus', type: 'select' },
        { name: 'trimFabricStatus', type: 'select' },
        { name: 'accAndTrimStatus', type: 'input' },
        { name: 'missingTrims', type: 'input' },
        { name: 'embellishmentStatus', type: 'select' },
        { name: 'washStatus', type: 'select' },
        { name: 'stichingStatus', type: 'select' },
        { name: 'washShrinkage', type: 'input' },
        { name: 'printShrinkage', type: 'input' },
        { name: 'spirality', type: 'input' },
        { name: 'foundGSM', type: 'input' },
        { name: 'patternStatus', type: 'select' },
        { name: 'fitStatus', type: 'select' },
        { name: 'typeOfSample', type: 'select' },
        { name: 'frontImage', type: 'image' },
        { name: 'backImage', type: 'image' },
        { name: 'commentsFromBuyer' },
        { name: 'generalInstructions' },
        { name: 'sealOrTrimCardVerification' },
        { name: 'workmanshipComments' },
        { name: 'measurementComments' },
        { name: 'preventiveCorrective' },
        { name: 'fabricCTQ' },
        { name: 'embellishmentCTQ' },
        { name: 'accTrimsCTQ' },
        { name: 'CPRTWashCTQ' },
        { name: 'cuttingCTQ' },
        { name: 'finishingPackingCTQ' },
        { name: 'sewingCTQ' },
        { name: 'bulkProductionComplicacy' }
    ],
    2: [
        { name: 'sampleQTY', type: 'input' },
        { name: 'sampleReviewStatus', type: 'select' },
        { name: 'rejectQTY', type: 'input' },
        { name: 'dhu', type: 'input' },
        { name: 'tdr', type: 'input' },
        { name: 'remarks', type: 'input' }
    ]
};

const NEW_SEAL_SAMPLE_ROUTE_ID = 610;
const INCOMPLETE_SEAL_SAMPLE_ROUTE_ID = 611;
const AMEND_SEAL_SAMPLE_ROUTE_ID = 612;

export {
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_SEAL_SAMPLE_ROUTE_ID,
    INCOMPLETE_SEAL_SAMPLE_ROUTE_ID,
    AMEND_SEAL_SAMPLE_ROUTE_ID,
    DOMAIN_NAME,
    SEAL_SAMPLE_FORM_FIELDS
};
