import React from 'react';

import Order from './Order';
import OrderCP from './OrderCP';
import { ORDER_CP_ROUTE_ID } from './OrderCP/common';

import {
    NEW_ORDER_ROUTE_ID,
    INCOMPLETE_ORDER_ROUTE_ID,
    AMEND_ORDER_ROUTE_ID
} from './Order/common';

export default {
    [NEW_ORDER_ROUTE_ID]: () => <Order mode='new' />,
    [INCOMPLETE_ORDER_ROUTE_ID]: () => <Order mode='incomplete' />,
    [AMEND_ORDER_ROUTE_ID]: () => <Order mode='amend' />,
    [ORDER_CP_ROUTE_ID]: () => <OrderCP />
};
