function sanitizeHTML(html) {
    // Define the allowed HTML tags and attributes
    const allowedTags = [
        'b',
        'i',
        'u',
        'a',
        'p',
        'h1',
        'h2',
        'h3',
        'br',
        'ul',
        'ol',
        'li',
        'strong',
        'em'
    ];
    const allowedAttributes = {
        a: ['href']
    };

    // Create a temporary element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Traverse through all elements and attributes, remove any not allowed
    tempElement.querySelectorAll('*').forEach(node => {
        if (!allowedTags.includes(node.tagName.toLowerCase())) {
            node.parentNode.removeChild(node);
            return;
        }
        const allowedAttrs = allowedAttributes[node.tagName.toLowerCase()];
        if (allowedAttrs) {
            Array.from(node.attributes).forEach(attr => {
                if (!allowedAttrs.includes(attr.name.toLowerCase())) {
                    node.removeAttribute(attr.name);
                }
            });
        }
    });

    // Return the sanitized HTML
    return tempElement.innerHTML;
}

export default sanitizeHTML;
