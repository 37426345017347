import {
    SET_INK_DETAILS,
    EDIT_INK_DETAIL,
    REMOVE_INK_DETAIL,
    REMOVE_INK_IMAGE,
    DELETE_INK_IMAGE_REQUEST
} from 'store/actionTypes';

// New
export const setInkDetails = payload => ({
    type: SET_INK_DETAILS,
    payload
});

export const editInkDetail = payload => ({
    type: EDIT_INK_DETAIL,
    payload
});

export const removeInkDetail = payload => ({
    type: REMOVE_INK_DETAIL,
    payload
});

// Todo: Needs update, dont remove yet
export const removeInkImage = payload => ({
    type: REMOVE_INK_IMAGE,
    payload
});

// WTF
export function deleteInkImg(payload) {
    return { type: DELETE_INK_IMAGE_REQUEST, payload };
}
