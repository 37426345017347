export default ({ breakpoints, spacing }) => ({
	drawerPaper: {
		backgroundColor: '#232323',
		position: 'relative',
		whiteSpace: 'nowrap',
		width: spacing.unit * 30,
		overflowX: 'hidden'
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		width: spacing.unit * 7,
		[breakpoints.up('sm')]: {
			width: spacing.unit * 9
		}
	},
	drawerPaperRight: {
		whiteSpace: 'nowrap',
		width: spacing.unit * 30 + 40
	}
});
