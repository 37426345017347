import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import {
    textFilterOperators,
    numberFilterOperators,
    defaultFilterOperatorValues,
    selectFilterOperators,
    dateFilterOperators,
    boolFilterOperators,
    booleanOptions
} from './filterTypesAndOperators';
import FilterWrapper from './FilterWrapper';
import InputFilter from './InputFilter';
import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';

const styles = ({ spacing, palette }) => ({
    gridFilterHeaderCell: {
        width: '100%',
        height: spacing.unit * 5.5,
        padding: spacing.unit * 0.5,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1
    }
});

// Define the FilterEditor component to render the appropriate filter based on the type
const FilterEditor = ({
    column: { type, name, domain, entity },
    filterValue,
    onFilterValueChange,
    filterOperatorValue,
    datasetSelector,
    datasetLoadingSelector,
    ...props
}) => {
    const filterWrapperProps = {
        ...props,
        filterOperatorValue
    };
    switch (type) {
        case 'number':
        case 'text': {
            return (
                <FilterWrapper
                    FilterComponent={
                        <InputFilter
                            type={type}
                            filterValue={filterValue}
                            filterOperatorValue={filterOperatorValue}
                            onFilterValueChange={onFilterValueChange}
                        />
                    }
                    operatorOptions={
                        type === 'number'
                            ? numberFilterOperators
                            : textFilterOperators
                    }
                    {...filterWrapperProps}
                />
            );
        }
        case 'select': {
            return (
                <FilterWrapper
                    FilterComponent={
                        <SelectFilter
                            name={name}
                            filterValue={filterValue}
                            onFilterValueChange={onFilterValueChange}
                            data={datasetSelector(domain, entity)}
                            loading={datasetLoadingSelector(domain, entity)}
                        />
                    }
                    operatorOptions={selectFilterOperators}
                    {...filterWrapperProps}
                />
            );
        }
        case 'date': {
            return (
                <FilterWrapper
                    FilterComponent={
                        <DateFilter
                            name={name}
                            filterValue={filterValue}
                            filterOperatorValue={filterOperatorValue}
                            onFilterValueChange={onFilterValueChange}
                        />
                    }
                    operatorOptions={dateFilterOperators}
                    {...filterWrapperProps}
                />
            );
        }
        case 'boolean': {
            return (
                <FilterWrapper
                    FilterComponent={
                        <SelectFilter
                            name={name}
                            filterValue={filterValue}
                            onFilterValueChange={onFilterValueChange}
                            data={booleanOptions}
                            loading={false}
                        />
                    }
                    operatorOptions={boolFilterOperators}
                    {...filterWrapperProps}
                />
            );
        }
        default: {
            return null;
        }
    }
};

const FilterHeader = memo(
    withStyles(styles)(
        ({
            classes: c,
            column,
            filterValues,
            handleFilterChange,
            removeFilter,
            removeAllFilters,
            ...rest
        }) => {
            const { name, type, filterable } = column;

            const filterValue = filterValues[name] && filterValues[name].value;

            const filterOperator =
                (filterValues[name] && filterValues[name].operator) ||
                defaultFilterOperatorValues[type];

            const onFilterValueChange = useCallback(
                value => handleFilterChange(name, value, type, filterOperator),
                [name, filterOperator, type, handleFilterChange]
            );

            const onFilterOperatorChange = useCallback(
                operator => {
                    if (operator !== filterOperator) {
                        handleFilterChange(name, filterValue, type, operator);
                    }
                },
                [filterOperator, filterValue, name, type, handleFilterChange]
            );
            const hasAnyFilterApplied = useMemo(
                () => Object.values(filterValues).some(filter => filter.value),
                [filterValues]
            );

            const handleClearFilter = useCallback(() => {
                removeFilter(name);
            }, [name, removeFilter]);

            return (
                <div className={c.gridFilterHeaderCell}>
                    {filterable ? (
                        <FilterEditor
                            column={column}
                            filterValue={filterValue}
                            filterOperatorValue={filterOperator}
                            onFilterValueChange={onFilterValueChange}
                            onFilterOperatorChange={onFilterOperatorChange}
                            handleClearFilter={handleClearFilter}
                            handleClearAllFilter={removeAllFilters}
                            hasAnyFilterApplied={hasAnyFilterApplied}
                            hasFilterApplied={!!filterValue}
                            {...rest}
                        />
                    ) : null}
                </div>
            );
        }
    )
);

FilterHeader.propTypes = {
    column: PropTypes.object.isRequired,
    filterValues: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    removeAllFilters: PropTypes.func.isRequired
};

export default FilterHeader;
