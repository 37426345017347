import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import SplitButton from '@libComponents/SplitButton';
import { CancelDialog } from '@formComponents/CancelDialog';
import { withModalProvider, withModal } from '@libComponents/Modal';

// App
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Domain
// import { SelectAmendCads, SelectIncompleteCads } from '../../components';
import { useShapeState } from '../useShape';
import { DOMAIN_NAME, FORM_NAME } from '../common';
import { SelectShapeAmendSamples } from '../../components';
import { PaginatedSelect } from '@formComponents/PaginatedSelect';
import { SHAPE_INCOMPLETE_SAMPLES } from '../../redux.datasets';
import {
    destroyShapeForm,
    getIncompleteShapeData,
    getAmendShapeData,
    shapeReferenceGenerate
} from '../../redux.actions';
import Preview from '../components/Handover/Preview';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    dropdownButton: {
        height: spacing.unit * 4.5
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    incompleteReference: ({ payload }) => getIncompleteShapeData(payload),
    amendReference: ({ payload }) => getAmendShapeData(payload)
};

const mapStateToProps = state => ({
    sampleReference: formValueSelector(FORM_NAME)(state, 'sampleReference'),
    sampleReferenceId: formValueSelector(FORM_NAME)(state, 'id')
});

const mapDispatch = {
    destroyShapeForm,
    shapeReferenceGenerate
};

const HeaderAdornment = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        destroyShapeForm,
        handleModal: handleHandoverModal,
        shapeReferenceGenerate,
        sampleReference,
        sampleReferenceId
    }) => {
        const {
            shouldFetchData,
            shouldCancel,
            shouldPublish,
            shouldSave,
            canSave,
            canPublish,
            handleSave,
            handleHandover,
            handlePublish,
            mode,
            reset,
            shouldPreview,
            handlePreviewModal,
            handleReduxFormSubmit,
            sampleRefLoading
        } = useShapeState();

        const [open, setOpen] = useState(false);

        const handleCancel = useCallback(() => setOpen(true), []);

        const handleClose = useCallback(() => setOpen(false), []);

        const handleConfirm = useCallback(() => {
            destroyShapeForm();
            handleClose();
            reset();
        }, [handleClose, destroyShapeForm]);

        const handlePublishWithValidation = useCallback(() => {
            if (!canPublish) {
                handleReduxFormSubmit();
                return;
            }
            handlePublish();
        }, [canPublish]);

        const paginatedSelectProps = useMemo(
            () => ({
                styles: {
                    container: c.selectContainer,
                    control: c.selectControl
                },
                label: null
            }),
            []
        );

        const handleHandoverWithPreview = useMemo(
            () => event => {
                event.persist();
                if (!sampleReference && !sampleReferenceId) {
                    shapeReferenceGenerate();
                }
                handleHandoverModal({
                    handleHandover,
                    mode
                });
            },
            [handleHandover, handleHandoverModal]
        );

        const saveOptions = useMemo(
            () => [
                { label: 'Save Draft', callback: handleSave },
                { label: 'Handover', callback: handleHandoverWithPreview }
            ],
            [handleHandoverWithPreview]
        );

        const publishOptions = useMemo(
            () => [
                { label: 'Publish', callback: handlePublishWithValidation },
                { label: 'Publish with email', callback: handlePublish }
            ],
            []
        );

        return (
            <div className={c.container}>
                {shouldFetchData && (
                    <div className={c.selectWrapper}>
                        <Typography
                            variant='subtitle1'
                            className={c.selectLabel}
                        >
                            Select sample ref:
                        </Typography>
                        <div className={c.selectContainerWrapper}>
                            {mode === 'amend' ? (
                                <SelectShapeAmendSamples
                                    {...paginatedSelectProps}
                                />
                            ) : (
                                <PaginatedSelect
                                    name='incompleteReference'
                                    entity={SHAPE_INCOMPLETE_SAMPLES}
                                    domain={DOMAIN_NAME}
                                    {...paginatedSelectProps}
                                />
                            )}
                        </div>
                    </div>
                )}
                {shouldCancel && (
                    <Button
                        className={c.button}
                        color='error'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                )}
                {shouldSave && (
                    <SplitButton
                        className={c.button}
                        color='success'
                        options={saveOptions}
                        disabled={!canSave || sampleRefLoading}
                    />
                )}
                {shouldPreview && (
                    <Button
                        className={c.button}
                        onClick={() => handlePreviewModal()}
                    >
                        {'Preview'}
                    </Button>
                )}

                <CancelDialog
                    open={open}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                />
            </div>
        );
    }
);

export default HeaderAdornment;
