import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

import Editor from './Editor';
import Image from './Image';
import GridCellContent from '../../../../GridCellContent';
import CustomCellRenderer from './CustomCellRenderer';

const styles = ({ spacing }) => ({
    gridBodyCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: `${spacing.unit * 0.5}px ${spacing.unit}px`
    },
    gridBodyCheckboxCell: {
        padding: spacing.unit * 0.5
    }
});

const isDateTypeColumn = column => column.type === 'date';
const isSelectTypeColumn = column => column.type === 'select';

const Cell = ({
    classes: c,
    column,
    row,
    isColumnCheckbox,
    toggleRowSelection,
    isRowSelected,
    syncGridDataWithServerAsync,
    updateRowsInGrid,
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    ...props
}) => {
    const [cellEditMode, setCellEditMode] = useState(false);

    const handleCellDoubleClick = useCallback(() => {
        if (column.editable) {
            setCellEditMode(true);
        }
    }, [column.editable]);

    const handleCellEditModeOff = useCallback(() => {
        setCellEditMode(false);
    }, []);

    const renderContent = () => {
        if (isColumnCheckbox) {
            const checkboxProps = {
                handleChange: () => toggleRowSelection(row.id),
                checked: isRowSelected,
                styles: { root: c.gridBodyCheckboxCell }
            };
            return column.renderCheckbox(checkboxProps);
        }

        if (column.type === 'image') {
            return <Image column={column} row={row} />;
        }

        if (column.editable && column.isCellEditable(row) && cellEditMode) {
            return (
                <Editor
                    column={column}
                    row={row}
                    datasetSelector={datasetSelector}
                    datasetLoadingSelector={datasetLoadingSelector}
                    updateRowsInGrid={updateRowsInGrid}
                    handleCellEditModeOff={handleCellEditModeOff}
                    syncGridDataWithServerAsync={syncGridDataWithServerAsync}
                />
            );
        }

        if (column.render) {
            return <CustomCellRenderer row={row} column={column} />;
        }

        if (isDateTypeColumn(column)) {
            return (
                <GridCellContent
                    content={formatDateToShort(row[column.name])}
                />
            );
        }

        if (isSelectTypeColumn(column)) {
            return (
                <GridCellContent
                    content={valueSelector(
                        column.domain,
                        column.entity,
                        row[column.name]
                    )}
                />
            );
        }

        return <GridCellContent content={row[column.name]} />;
    };

    return (
        <div
            className={c.gridBodyCell}
            onDoubleClick={handleCellDoubleClick}
            {...props}
        >
            {renderContent()}
        </div>
    );
};

export default withStyles(styles)(React.memo(Cell));
