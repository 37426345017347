import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Lib
import { GridItem, Input } from '@formComponents';

// Local
import CommentContainer from './CommentContainer';

const styles = ({ spacing }) => ({
    commentContainer: {
        padding: `${spacing.unit * 1}px ${spacing.unit * 1}px`
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 8
    }
});

const PreProductionEvaluation = withStyles(styles)(({ classes: c }) => {
    return (
        <CommentContainer title={'Pre Production Sample Evaluation'}>
            <Grid container className={c.commentContainer}>
                <GridItem md={6}>
                    <Input
                        name='workmanshipComments'
                        label='Workmanship comments'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='measurementComments'
                        label='Measurement comments'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='preventiveCorrective'
                        label='Preventive & corrective action'
                        placeholder='Write comments here'
                        multiline
                        rowsMax={4}
                        styles={{ root: c.inputRoot }}
                    />
                </GridItem>
            </Grid>
        </CommentContainer>
    );
});

export default PreProductionEvaluation;
