import React from 'react';
import { string, bool } from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from 'sampling/common';
import { GARMENTS_TYPE } from 'sampling/redux.datasets';

const propTypes = {
    name: string,
    label: string,
    placeholder: string,
    disabled: bool,
    required: bool
};

const defaultProps = {
    name: 'type',
    label: 'Product Type',
    placeholder: 'Select type',
    disabled: false,
    required: false
};

const SelectGarmentsType = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={GARMENTS_TYPE} />
);

SelectGarmentsType.propTypes = propTypes;
SelectGarmentsType.defaultProps = defaultProps;

export default SelectGarmentsType;
