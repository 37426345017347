import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

import { withStyles, Divider, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Checkbox, Input } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Other Domain
import { SelectRetailers } from 'sampling/components';
import { SelectArtworkSources } from 'cadUpload/components';

import { FORM_NAME, LABELS } from '../../common';
import { useFabricDevelopmentState } from '../../useFabricDevelopment';
import { getCopyFabricData } from '../../../redux.actions';
import HeaderAdornment from '../HeaderAdornment';
import SelectReferenceForCopyData from './SelectReferenceForCopyData';
import asyncValidate from './asyncValidate';

const styles = ({ spacing, typography }) => ({
    container: {
        paddingBottom: spacing.unit * 6
    },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    },
    copyReferenceContainer: {
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 2
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const mapInputs = {
    copyReference: ({ payload }) => getCopyFabricData(payload),
    hasCopyReference: () => {
        return change(FORM_NAME, 'copyReference', '');
    },
    hasManualReference: () => {
        return change(FORM_NAME, 'manualReference', '');
    },
    manualReference: ({ payload }) => {
        if (payload) return change(FORM_NAME, 'manualReferenceAsync', '');
    }
};

const mapState = state => ({
    ...formValueSelector(FORM_NAME)(
        state,
        'hasCopyReference',
        'hasManualReference'
    )
});

const Setup = compose(
    connect(
        mapState,
        null
    ),
    withStyles(styles),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['manualReference']
    }),
    formListener(mapInputs)
)(({ classes: c, hasCopyReference, hasManualReference }) => {
    const { setupLocked } = useFabricDevelopmentState();

    return (
        <ContentContainer
            title={LABELS.setup}
            AdornmentComponent={<HeaderAdornment />}
        >
            <GridContainer className={c.container}>
                <Grid container className={c.copyReferenceContainer}>
                    <GridItem md={6}>
                        <Checkbox
                            name='hasCopyReference'
                            label='Copy data from another fabric request'
                            styles={{
                                label: c.checkboxLabel
                            }}
                            disabled={setupLocked}
                        />
                    </GridItem>
                    <GridItem md={3} />
                    <GridItem md={3} />
                    {hasCopyReference && (
                        <GridItem md={6}>
                            <SelectReferenceForCopyData
                                disabled={setupLocked}
                            />
                        </GridItem>
                    )}
                </Grid>
                <GridItem md={6}>
                    <SelectRetailers
                        label='Fabric Development For'
                        required
                        disabled={setupLocked}
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectArtworkSources
                        name='artworkSource'
                        label='Where is the FD from'
                        placeholder='Select Location'
                        disabled={setupLocked}
                        required
                    />
                </GridItem>
                <Divider className={c.divider} />
                <GridItem md={6}>
                    <Checkbox
                        name='hasManualReference'
                        label='I want to give this a manual reference'
                        styles={{
                            label: c.checkboxLabel
                        }}
                        disabled={setupLocked}
                    />
                </GridItem>
                <GridItem md={3} />
                <GridItem md={3} />
                {hasManualReference && (
                    <GridItem md={6}>
                        <Input
                            name='manualReference'
                            placeholder='Enter manual reference'
                            required
                            disabled={!!setupLocked}
                        />
                    </GridItem>
                )}
            </GridContainer>
        </ContentContainer>
    );
});

export default Setup;
