import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { number, string, func, oneOfType } from 'prop-types';
// Materials
import { Typography, withStyles } from '@material-ui/core';

// Libs
import { valueSelector } from '@libs/datasets';

// Local
import { DOMAIN_NAME } from '../../../../common';
import {
    PO_SHIP_MODE,
    PO_SHIP_DESTINATION,
    PO_TYPE,
    SIZE_SETS,
    PAYMENT_TERMS,
    SETTLEMENT_DISCOUNT
} from '../../../../../redux.datasets';
import {
    isNinetyPercentSelector,
    discountSelector
} from 'orders/redux.selectors';
import ActionsAdornment from './ActionsAdornment';
import BasicContainer from './BasicContainer';
import DipsTable from './DipsTable';
import SizeBreakdown from '../SizeBreakdown';

const propTypes = {
    shipMode: oneOfType([number, string]),
    shipDestination: oneOfType([number, string]),
    orderType: oneOfType([number, string]),
    sizeSet: oneOfType([number.isRequired, string.isRequired]),

    handleDuplicate: func.isRequired,
    handleEdit: func.isRequired,
    handleDelete: func.isRequired
};

const mapState = (
    state,
    {
        shipMode,
        shipDestination,
        paymentTerms,
        settlementDiscount,
        orderType,
        sizeSet,
        discount,
        dips,
        orderQuantity,
        packType,
        totalRatio
    }
) => {
    const isNinetyPercent = isNinetyPercentSelector(state);
    return {
        ...(isNinetyPercent
            ? {}
            : {
                  shipMode: valueSelector(
                      state,
                      DOMAIN_NAME,
                      PO_SHIP_MODE,
                      shipMode
                  ),
                  shipDestination: valueSelector(
                      state,
                      DOMAIN_NAME,
                      PO_SHIP_DESTINATION,
                      shipDestination
                  ),
                  orderType: valueSelector(
                      state,
                      DOMAIN_NAME,
                      PO_TYPE,
                      orderType
                  )
              }),
        paymentTerms: valueSelector(
            state,
            DOMAIN_NAME,
            PAYMENT_TERMS,
            paymentTerms
        ),
        settlementDiscount: valueSelector(
            state,
            DOMAIN_NAME,
            SETTLEMENT_DISCOUNT,
            settlementDiscount
        ),
        sizeSet: valueSelector(state, DOMAIN_NAME, SIZE_SETS, sizeSet),
        ...discountSelector(dips, discount),
        ...(packType === 'Ratio'
            ? { packQuantity: orderQuantity / totalRatio }
            : {})
    };
};

const styles = ({ spacing, palette, typography, shape }) => ({
    container: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,

        borderRadius: shape.borderRadius,

        '&:not(:last-child)': {
            marginBottom: spacing.unit * 1
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: '100%',
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,

        backgroundColor: palette.background.light,

        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderTopRightRadius: shape.borderRadius,
        borderTopLeftRadius: shape.borderRadius
    },
    title: {
        ...typography.h6,
        fontWeight: typography.fontWeightBold,
        whiteSpace: 'nowrap',
        minWidth: spacing.unit * 10,
        textAlign: 'center'
    },
    spaceAround: {
        padding: spacing.unit,
        '&:last-child': {
            paddingTop: 0
        }
    }
});

const Card = compose(
    withStyles(styles),
    connect(mapState)
)(
    ({
        classes: c,
        poNo,
        handleDuplicate,
        handleEdit,
        handleDelete,
        dips,
        sizeDetails,
        discount,
        forwardRef,
        isFirstCard,
        ...restCardProps
    }) => {
        return (
            <div className={c.container}>
                <div className={c.header}>
                    <Typography className={c.title}>{poNo}</Typography>
                    <BasicContainer basicInfo={restCardProps} />
                    <ActionsAdornment
                        handleDuplicate={handleDuplicate}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                </div>
                <div className={c.spaceAround}>
                    <DipsTable dips={dips} />
                </div>
                <div className={c.spaceAround}>
                    <SizeBreakdown data={sizeDetails} tableLocked={true} />
                </div>
            </div>
        );
    }
);

Card.propTypes = propTypes;

export { Card as default, Card };
