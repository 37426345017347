import { useEffect, useState } from 'react';

export default (toggle, timeout) => {
    const [transitionExpand, setTransitionExpand] = useState(toggle);

    useEffect(() => {
        let transitionTimeout = void 0;
        if (toggle) {
            setTransitionExpand(toggle);
        } else {
            transitionTimeout = setTimeout(
                () => setTransitionExpand(toggle),
                timeout
            );
        }
        return () => clearTimeout(transitionTimeout);
    }, [toggle]);
    return transitionExpand;
};
