import {
    FETCH_COPY_SAMPLE_REF_SUCCESS,
    REQUEST_FORM_SUBMIT,
    REQUEST_FORM_SUBMIT_SUCCEEDED
} from 'actionTypes';

const requestReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_COPY_SAMPLE_REF_SUCCESS: {
            return { ...state, copySampleReferences: payload };
        }

        case REQUEST_FORM_SUBMIT: {
            return { ...state, requestLoading: true };
        }

        case REQUEST_FORM_SUBMIT_SUCCEEDED: {
            return { ...state, requestLoading: false };
        }

        default: {
            return state;
        }
    }
};

export { requestReducer as default, requestReducer };
