import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, withStyles } from '@material-ui/core';

const propTypes = {
    value: PropTypes.string.isRequired
};

const styles = () => ({
    root: { display: 'flex', alignItems: 'center', height: '100%' }
});

const Title = withStyles(styles)(({ classes: c, value }) => {
    return (
        <div className={c.root}>
            <Typography>{value}</Typography>
        </div>
    );
});

Title.propTypes = propTypes;

export default Title;
