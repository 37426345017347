import React from 'react';
import { string } from 'prop-types';

const propTypes = {
    url: string.isRequired,
    filename: string
};

const Image = ({ url, filename, ...rest }) => (
    <img src={url} alt={filename} {...rest} />
);

Image.propTypes = propTypes;

export default Image;
