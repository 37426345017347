import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { withStyles, Grid, Typography } from '@material-ui/core';
import { ReorderOutlined } from '@material-ui/icons';

import Button from '@libComponents/Button';
import { GridItem, Input } from '@formComponents';
import { required, validateStringWithSpaces } from '@libs/validationRules';

const styles = ({ spacing, typography, palette }) => ({
    container: {
        padding: spacing.unit,
        '&:hover': {
            cursor: 'grab'
        }
    },
    icon: {
        marginTop: spacing.unit * 1.25,
        marginLeft: spacing.unit * 1.5,
        color: palette.grey[400]
    },
    serial: {
        fontSize: typography.subtitle1.fontSize,
        margin: spacing.unit * 1,
        fontWeight: typography.fontWeightHeavy
    },
    draggedOver: {
        cursor: 'grabbing',
        backgroundColor: palette.grey[500]
    }
});

const uniqueSizeName = (value, { sizes }, _, fieldName) => {
    // Extract the index from the field name using regular expression
    const regex = /\[(\d+)\]/;
    const match = fieldName.match(regex);
    const currentIndex = match ? parseInt(match[1]) : -1;

    // Check if the entered size (value) exists in the sizes array
    // excluding the current element
    const isDuplicate = sizes.some(
        (size, index) => size && size.name === value && index !== currentIndex
    );

    if (isDuplicate) {
        return 'Size already exists in the size set';
    }

    return undefined; // No error if size is unique
};

const validationRules = [required, validateStringWithSpaces, uniqueSizeName];

const propTypes = {
    size: PropTypes.string,
    index: PropTypes.number,
    fields: PropTypes.object
};

const SizeInput = withStyles(styles)(
    ({ size, index, fields, classes, dragAndDropProps }) => {
        const {
            handleDragStart,
            handleDragOver,
            handleDragLeave,
            handleDragDrop,
            handleDragEnd,
            draggedOverIndex,
            draggedIndex
        } = dragAndDropProps;

        const isDraggedOver =
            draggedOverIndex === index && draggedIndex !== draggedOverIndex;

        const item = fields.get(index);
        const autoFocus = fields.length - 1 === index && !item.name; // Auto focus on last item
        const isDisabled = !!item.id; // If id is present, it means the item cannot be edited.

        const handleRemove = () => {
            fields.remove(index);
        };

        return (
            <Grid
                container
                draggable
                className={clsx(
                    classes.container,
                    isDraggedOver && classes.draggedOver
                )}
                onDragStart={() => handleDragStart(index)}
                onDragOver={e => handleDragOver(e, index)}
                onDragLeave={handleDragLeave}
                onDrop={() => handleDragDrop(index)}
                onDragEnd={handleDragEnd}
            >
                <GridItem md={1}>
                    <ReorderOutlined className={classes.icon} />
                </GridItem>
                <GridItem md={11}>
                    <Grid container>
                        <GridItem md={1}>
                            <Typography className={classes.serial}>
                                {index + 1}.
                            </Typography>
                        </GridItem>
                        <GridItem md={8}>
                            <Input
                                name={`${size}.name`}
                                placeholder='Enter Size'
                                label=''
                                validate={validationRules}
                                autoFocus={autoFocus}
                                disabled={isDisabled}
                            />
                        </GridItem>
                        <GridItem md={3}>
                            <Button
                                variant='outlined'
                                color='error'
                                onClick={handleRemove}
                                disabled={isDisabled}
                            >
                                Remove
                            </Button>
                        </GridItem>
                    </Grid>
                </GridItem>
            </Grid>
        );
    }
);

SizeInput.propTypes = propTypes;

export default SizeInput;
