import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';

import ContentContainer from '@libComponents/ContentContainer';
import { setSnack } from 'utilities/redux.actions';
import { withField } from '@libs/reduxForm';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { makeSideEffectCallback } from '@libs/makeContext';
import { Iframe } from 'common/components';
import { MailFormProvider, useStoreEmailFormData } from '@libHooks/useMailForm';

import MailModal from './MailModal';
import ActionsAdornment from './ActionsAdornment';
import {
    PDFPropsSelector,
    referenceGenerateLoadingSelector
} from '../../../redux.selectors';
import { useMakePDF } from './hooks';

const styles = ({ spacing }) => ({
    container: {
        width: '80vw',
        minHeight: '90vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    loadingText: {
        position: 'absolute',
        top: '50%',
        fontSize: spacing.unit * 2.5
    }
});

const propTypes = {
    name: string
};

const defaultProps = {
    name: 'mail'
};

const mapStateToProps = (state, { mode }) => ({
    PDFProps: PDFPropsSelector(state, mode),
    referenceLoading: referenceGenerateLoadingSelector(state)
});

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Preview = compose(
    withModalProvider,
    withModal({
        handleModal: MailModal
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handlePublish,
        setSnack,
        handleModal,
        input,
        meta: { form },
        watermark,
        PDFProps,
        referenceLoading,
        mode
    }) => {
        const PDFHandlerInvokedRef = useRef(false);
        const [PDFLoading, setPDFLoading] = useState(true);
        const [PDFBlobURLHandler, PDF, removePDFBlobURLHandler] = useMakePDF({
            props: {
                ...PDFProps,
                watermark
            },
            fileName: 'seal_sample.pdf',
            setSnack
        });

        const { value, onChange } = input;
        const [action, setAction] = useState({});

        const initialize = useCallback(
            state => ({
                ...state,
                mail: value || {}
            }),
            [value]
        );
        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const handlePDFAdd = useCallback(pdfInfo => {
            setAction({ type: 'addPDF', payload: pdfInfo });
        }, []);
        const handlePreviewModalClose = useCallback(() => {
            removePDFBlobURLHandler();
            setAction({ type: 'removePDF' });
            setTimeout(() => onClose(), 0);
        }, [onClose]);
        const handleMailModal = useMemo(
            () => props =>
                handleModal({
                    ...props,
                    formName: form,
                    handlePublish,
                    closePreviewModal: onClose,
                    mode,
                    handleDocRemove: removePDFBlobURLHandler
                }),
            [handleModal, form, mode, removePDFBlobURLHandler]
        );
        const handlePublishWithMail = useStoreEmailFormData({
            handleModal: handleMailModal,
            setAction,
            formName: form
        });

        useEffect(() => {
            if (
                !referenceLoading &&
                !!Object.keys(PDFProps.setup).length &&
                PDFHandlerInvokedRef.current === false
            ) {
                PDFHandlerInvokedRef.current = true;
                PDFBlobURLHandler();
            }
        }, [PDFProps, referenceLoading]);

        useEffect(() => {
            if (PDF && PDF.url) {
                setPDFLoading(false);
                handlePDFAdd({ pdf: PDF });
            }
        }, [PDF]);

        return (
            <MailFormProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Preview'
                    styles={{ container: c.container }}
                    AdornmentComponent={
                        <ActionsAdornment
                            closePreviewModal={handlePreviewModalClose}
                            handlePublishWithMail={handlePublishWithMail}
                            handlePublish={handlePublish}
                            PDFLoading={PDFLoading}
                            isPublished={!watermark}
                        />
                    }
                >
                    {!PDFLoading ? (
                        <Iframe src={PDF.url} />
                    ) : (
                        <Typography className={c.loadingText}>
                            Loading document...
                        </Typography>
                    )}
                </ContentContainer>
            </MailFormProvider>
        );
    }
);

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
