// Entities
import {
    designTypes,
    productTypes,
    COUNTRIES,
    DESIGN_TYPES,
    DEPARTMENTS,
    FACTORIES,
    PRODUCT_TYPES,
    JOINED_RETAILER_FACTORIES,
    RETAILERS,
    COPY_SAMPLE_REFERENCES,
} from 'libs';

export default {
    [COUNTRIES]: [],
    [DESIGN_TYPES]: designTypes,
    [DEPARTMENTS]: [],
    [FACTORIES]: [],
    [PRODUCT_TYPES]: productTypes,
    [JOINED_RETAILER_FACTORIES]: [],
    [RETAILERS]: [],
    [COPY_SAMPLE_REFERENCES]: { nextPage: false, data: [] },
};
