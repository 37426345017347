import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { formValueSelector, getFormValues } from 'redux-form';

import { valueSelector } from '@libs/datasets';
import { deserializeFilename } from '@libs/filenames';
import { normalizeDate } from '@baseComponents/Date/normalizeDate';

import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import {
    FABRIC_TYPES,
    COMPOSITIONS,
    SPECIAL_FINISHES,
    FINISHED_GSMS,
    RETAILERS,
    DEPARTMENTS,
    SAMPLE_FACTORY
} from 'sampling/redux.datasets';

import { DOMAIN_NAME, FORM_NAME } from './FabricDevelopment/common';
import {
    DEVELOPMENT_TYPE_SENT,
    REQUEST_BY,
    HANGER_TYPE,
    YARN_COUNT,
    YARN_SUPPLIER,
    PILLING_METHOD,
    DRY_PROCESS,
    APPROVAL_STATUS,
    APPROVED_BY
} from './redux.datasets';

const fabricSelector = state => state[DOMAIN_NAME];

const fabricDevelopmentFormSelector = formValueSelector(FORM_NAME);
const fabricDevelopmentFormAllValuesSelector = state =>
    getFormValues(FORM_NAME)(state);

const fabricDevelopmentReferenceLoadingSelector = createSelector(
    fabricSelector,
    ({ fabricDevelopmentReferenceLoading }) => fabricDevelopmentReferenceLoading
);

const fabricDevelopmentPDFPropsSelector = createSelector(
    state => fabricDevelopmentFormAllValuesSelector(state),
    state => state,
    (_, mode) => mode,
    (values, state, mode) => {
        const {
            versionNo,
            fabricReference,
            developmentType,
            originalFabric,
            requiredFabric,
            specialFinish,
            requestBy,
            retailer,
            department,
            hangerType,
            factory,
            developedFabric = {},
            yarnCount,
            yarnSupplier,
            pillingMethod,
            dryProcess,
            approvalStatus,
            approvedBy,
            ...rest
        } = values;

        const versionName =
            mode === 'amend' ? `V${versionNo + 1}` : `V${versionNo}`;

        return {
            values: {
                ...rest,
                fabricReference,
                versionName,
                developmentType: valueSelector(
                    state,
                    DOMAIN_NAME,
                    DEVELOPMENT_TYPE_SENT,
                    developmentType
                ),
                originalFabric: {
                    fabricType: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FABRIC_TYPES,
                        originalFabric.fabricType
                    ),
                    composition: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        COMPOSITIONS,
                        originalFabric.composition
                    ),
                    gsm: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FINISHED_GSMS,
                        originalFabric.gsm
                    )
                },
                requiredFabric: {
                    fabricType: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FABRIC_TYPES,
                        requiredFabric.fabricType
                    ),
                    composition: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        COMPOSITIONS,
                        requiredFabric.composition
                    ),
                    gsm: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FINISHED_GSMS,
                        requiredFabric.gsm
                    )
                },
                specialFinish: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    SPECIAL_FINISHES,
                    specialFinish
                ),
                requestBy: valueSelector(
                    state,
                    DOMAIN_NAME,
                    REQUEST_BY,
                    requestBy
                ),
                retailer: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    RETAILERS,
                    retailer
                ),
                department: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    DEPARTMENTS,
                    department
                ),
                hangerType: valueSelector(
                    state,
                    DOMAIN_NAME,
                    HANGER_TYPE,
                    hangerType
                ),
                factory: valueSelector(
                    state,
                    SAMPLING_DOMAIN_NAME,
                    SAMPLE_FACTORY,
                    factory
                ),
                developedFabric: {
                    fabricType: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FABRIC_TYPES,
                        developedFabric.fabricType
                    ),
                    composition: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        COMPOSITIONS,
                        developedFabric.composition
                    ),
                    gsm: valueSelector(
                        state,
                        SAMPLING_DOMAIN_NAME,
                        FINISHED_GSMS,
                        developedFabric.gsm
                    )
                },
                yarnCount: valueSelector(
                    state,
                    DOMAIN_NAME,
                    YARN_COUNT,
                    yarnCount
                ),
                yarnSupplier: valueSelector(
                    state,
                    DOMAIN_NAME,
                    YARN_SUPPLIER,
                    yarnSupplier
                ),
                pillingMethod: valueSelector(
                    state,
                    DOMAIN_NAME,
                    PILLING_METHOD,
                    pillingMethod
                ),
                dryProcess: valueSelector(
                    state,
                    DOMAIN_NAME,
                    DRY_PROCESS,
                    dryProcess
                ),
                approvalStatus: valueSelector(
                    state,
                    DOMAIN_NAME,
                    APPROVAL_STATUS,
                    approvalStatus
                ),
                approvedBy: valueSelector(
                    state,
                    DOMAIN_NAME,
                    APPROVED_BY,
                    approvedBy
                )
            },
            docTitle: 'Fabric Development Report',
            pageTitle: `Fabric Development [${fabricReference}]`,
            isDevelopedFabric: mode === 'developedFabric'
        };
    }
);

const fabricDevelopmentMailSubject = createSelector(
    state =>
        fabricDevelopmentFormSelector(state, 'fabricReference', 'versionNo'),
    ({ fabricReference, versionNo }) =>
        `FABRIC DEVELOPMENTS [${fabricReference}] V${versionNo}`
);

const fabricDevelopmentGridPreviewDataSelector = createCachedSelector(
    data => data,
    (data, fabricReference) => fabricReference,
    (data, fabricReference) => {
        if (
            !data ||
            Object.entries(data).length === 0 ||
            data.fabricReference !== fabricReference
        ) {
            return {};
        }

        const {
            originalFabric = {},
            requiredFabric = {},
            developedFabric = {}
        } = data;

        const versionNo = `V${data.versionNo}`;
        const previewTitle = `Fabric Development [${fabricReference}]`;
        const emailSubject = `${previewTitle} ${versionNo}`;
        const retailer = data.retailerId;

        if (data.reportLink) {
            return {
                emailSubject,
                storedPDFData: {
                    url: `${data.reportLink}?timestamp=${Date.now()}`,
                    filename: deserializeFilename(data.reportLink)
                },
                previewTitle,
                versionNo,
                retailer
            };
        }

        return {
            values: {
                ...data,
                fabricReference,
                versionName: versionNo,
                developmentType: data.fabricDevTypeSent,
                boughtReference: data.fabricBoughtSampleReference,
                hasMatchWashAndFinish: data.isMatchWash,
                hasMatchColor: data.isMatchFabricColor,
                specialFinish: data.fabricSpecialFinish,
                color: data.fabricColorName,
                colorReference: data.fabricColorReference,
                comments: data.fabricComments,
                requestBy: data.fabricRequestedBy,
                buyer: data.fabricBuyerContactPerson,
                hasSpeed: data.isSpeed,
                hasAvailability: data.isAvailability,
                hasSustainability: data.isSustainability,
                dateRequested: normalizeDate(data.dateRequested),
                dateRequired: normalizeDate(data.dateRequired),
                hangerType: data.fabricHangerType,
                factory: data.fabricFactory,
                batchNo: data.yarnBatchNo,
                recipeNo: data.receipeNo,
                yarnCount: data.fabricYarnCount,
                yarnLot: data.yarnLotNo,
                pillingMethod: data.fabricPillingMethod,
                originalFabricImage: { url: data.originalImage },
                washedImage: { url: data.washImage },
                finalImage: { url: data.finalImage },
                dryProcess: data.fabricDryProcess,
                approvalStatus: data.fabricApprovalStatus,
                approvedBy: data.fabricApprovalBy,
                dateOfApproved: normalizeDate(data.dateApproved),
                approvalComments: data.fabricApprovalComments,
                originalFabric: {
                    ...originalFabric,
                    fabricType: originalFabric.type
                },
                requiredFabric: {
                    ...requiredFabric,
                    fabricType: requiredFabric.type
                },
                developedFabric: {
                    ...developedFabric,
                    fabricType: developedFabric.type
                }
            },
            versionNo: data.versionNo,
            emailSubject,
            retailer,
            docTitle: 'Fabric Development Report',
            pageTitle: previewTitle
        };
    }
)(
    (data, fabricReference) =>
        `fabric-development-grid-preview-${fabricReference}`
);

const fabricExportXLDataLoadingSelector = createSelector(
    fabricSelector,
    ({ fabricExportXLDataLoading }) => fabricExportXLDataLoading
);

export {
    fabricDevelopmentReferenceLoadingSelector,
    fabricDevelopmentFormSelector,
    fabricDevelopmentPDFPropsSelector,
    fabricDevelopmentMailSubject,
    fabricDevelopmentGridPreviewDataSelector,
    fabricExportXLDataLoadingSelector
};
