import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import { GridCellContent } from 'dataGrid/components';

import { routeToAmendUser } from '../../redux.actions';

const propTypes = {
    reference: PropTypes.string.isRequired
};

const styles = ({ palette, typography }) => ({
    gridLinkCell: {
        cursor: 'pointer',
        color: palette.primary.main,
        fontWeight: typography.fontWeightSemi,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const mapDispatch = dispatch => ({
    routeToAmendUser: email => dispatch(routeToAmendUser(email))
});

const RedirectToAmendUser = compose(
    connect(
        null,
        mapDispatch
    ),
    withStyles(styles)
)(({ classes, reference, routeToAmendUser }) => {
    const handleRedirect = useCallback(() => routeToAmendUser(reference), [
        reference
    ]);

    return (
        <GridCellContent
            content={reference}
            onClick={handleRedirect}
            className={classes.gridLinkCell}
        />
    );
});

RedirectToAmendUser.propTypes = propTypes;

export default RedirectToAmendUser;
