export default ({ palette, spacing, typography }) => ({
    root: {
        height: spacing.unit * 5,
        '&:hover': {
            backgroundColor: palette.primary.light
        }
    },
    shaded: {
        backgroundColor: palette.grey[100]
    },
    cell: {
        maxWidth: spacing.unit * 25,
        wordWrap: 'break-word',
        color: palette.grey[800],
        fontSize: typography.subtitle2.fontSize
    }
});
