import React, { useCallback } from 'react';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { withModalProvider, withModal } from '@libComponents/Modal';

import Modal from './Modal';

const styles = ({ spacing, typography }) => ({
    button: {
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const HeaderAdornment = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    withStyles(styles)
)(({ classes: c, handleModal }) => {
    const handleClick = useCallback(
        e => {
            e.persist();
            handleModal();
        },
        [handleModal]
    );
    return (
        <Button className={c.button} color='success' onClick={handleClick}>
            Add Status
        </Button>
    );
});

export default HeaderAdornment;
