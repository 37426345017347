import React, { Fragment, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, Typography, withStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/ExpandMore';

const propTypes = {
    url: (props, propName, componentName) => {
        if (!props.url && !props.loading) {
            return new Error(
                `The prop '${propName}' is required in '${componentName}'.`
            );
        }
    },
    name: PropTypes.string.isRequired,
    showIcon: PropTypes.bool,
    loading: PropTypes.bool
};

const defaultProps = {
    loading: false
};

const styles = ({ palette, spacing, shape }) => ({
    root: {
        display: 'flex',
        height: spacing.unit * 2.5,
        color: palette.success.main,
        textTransform: 'none',
        padding: 0,
        paddingRight: spacing.unit * 0.5,
        borderRadius: shape.borderRadius * 0.5,

        '&:hover': {
            color: palette.success.dark,
            cursor: 'pointer'
        }
    },
    anchor: {
        display: 'none'
    },
    icon: {
        color: 'inherit',
        fontSize: spacing.unit * 2.5
    },
    label: {
        height: '100%',
        lineHeight: '100%',
        color: 'inherit'
    }
});

const DownloadLink = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme, url, name, loading, showIcon = true, ...props }) => {
        const anchorRef = useRef(null);

        const handleClick = useCallback(async () => {
            const anchor = document.createElement('a');

            anchor.href = url;
            anchor.download = name;

            anchor.click();
        }, [anchorRef.current, url, name]);

        return (
            <Fragment>
                <Button
                    className={c.root}
                    {...props}
                    onClick={handleClick}
                    disabled={loading}
                >
                    {showIcon && <DownloadIcon className={c.icon} />}
                    <Typography variant='caption' className={c.label}>
                        Download
                    </Typography>
                </Button>
            </Fragment>
        );
    }
);

DownloadLink.propTypes = propTypes;
DownloadLink.defaultProps = defaultProps;

export { DownloadLink as default, DownloadLink };
