import { all, call, put, takeLatest } from 'redux-saga/effects';
import { startSubmit, setSubmitSucceeded, stopSubmit } from 'redux-form';
import { eraseCookie, setCookie } from 'helpers/auth';
// Local
import storage from 'redux-persist/lib/storage';
import { apiRequest } from 'helpers';
import { MENU_LIST, MENU_GROUPS } from 'libs';
import { FETCH_DATASETS, SET_NOTIFICATION } from 'actionTypes';

// Action Types
import { SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT } from 'actionTypes';
// Workers
function* signInWorker({ payload }) {
    try {
        // yield put(startSubmit('AuthenticationForm'));
        console.log('calling sign in');
        const { data: signInData } = yield call(apiRequest, {
            url: '/api/Logins',
            method: 'post',
            data: { username: 'admin', password: 'echo@logyx2' }
            // data: payload
        });
        // yield put(stopSubmit('AuthenticationForm'));
        // yield put(setSubmitSucceeded('AuthenticationForm'));

        // Legacy
        // yield put({
        //     type: FETCH_DATASETS,
        //     meta: { datasets: [MENU_LIST] }
        // });
        //
        // yield put({
        //     type: SIGN_IN_SUCCESS,
        //     payload: signInData
        // });

        // New

        // Enable later
        console.log('finished login call');

        const [
            {
                data: { data: menus }
            },
            {
                data: { data: menuGroups }
            }
        ] = yield all([
            call(apiRequest, {
                url: '/api/Menus',
                method: 'get'
            }),
            call(apiRequest, {
                url: '/api/Logins/GetMenuGroups',
                method: 'get'
            })
        ]);

        const { userid, username, emailAddress, token } = signInData;

        // Enable later
        // const { value, validTo } = token;
        // setCookie(value, validTo);

        const authReducer = {
            userId: userid,
            username,
            email: emailAddress,
            routes: []
        };

        console.log('menus', menus);
        console.log('menuGroups', menuGroups);
        console.log('authReducer', authReducer);
    } catch (err) {
        yield put(stopSubmit('AuthenticationForm'));
        // if (err && err.response)
        // 	yield put({ type: SET_NOTIFICATION, response: err.response });
    }
}

function signOutWorker() {
    storage.removeItem(`persist:root`);
    eraseCookie();
}

// Watchers
const commonWatchers = function*() {
    yield takeLatest(SIGN_IN_REQUEST, signInWorker);
    yield takeLatest(SIGN_OUT, signOutWorker);
};

export { commonWatchers as default, commonWatchers };
