import { useEffect, useState } from 'react';

const useLoadedImage = url => {
    const [loadedImage, setLoadedImage] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        if (url) {
            const refImage = new Image();
            refImage.src = url;

            refImage.onload = () => {
                if (isSubscribed) {
                    setLoadedImage(url);
                }
            };
        }

        return () => {
            isSubscribed = false;
        };
    }, [url]);

    return loadedImage;
};

export default useLoadedImage;
