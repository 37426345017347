import React from 'react';
import { array } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import ShippedReport from './ShippedReport';
import WorkInProgressReport from './WorkInProgressReport';

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    shippedQuantityReport: array.isRequired,
    wipQuantityReport: array.isRequired
};

const FinanceReport = withStyles(styles)(
    ({ classes: c, shippedQuantityReport, wipQuantityReport }) => {
        return (
            <ContentContainer
                title='Finance Report'
                styles={{ container: c.container }}
            >
                <ShippedReport shippedQuantityReport={shippedQuantityReport} />
                <WorkInProgressReport wipQuantityReport={wipQuantityReport} />
            </ContentContainer>
        );
    }
);

FinanceReport.propTypes = propTypes;

export { FinanceReport as default, FinanceReport };
