export default ({ palette, spacing, shape }) => ({
    root: {
        border: `1px solid`,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: spacing.unit * 25,
        paddingRight: spacing.unit + 1,
        borderRight: 'none',

        '&:hover': {
            borderRight: '1px solid',
            borderColor: palette.grey[400],
            paddingRight: spacing.unit,
            zIndex: 4000
        },
    },
    input: {
        height: '100%'
    }
});
