import React, { useMemo } from 'react';

import { COUNTRIES } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Country = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Country', canSort: true, sortKey: 'name' },
            { label: 'Country Code', canSort: true, sortKey: 'shortName' },
            { label: 'Sample Country', canSort: true, sortKey: 'isSample' },
            { label: 'Dispatch Country', canSort: true, sortKey: 'isDispatch' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'COUNTRY SETUP',
        domain: DOMAIN_NAME,
        entity: COUNTRIES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Country as default, Country };
