import { fade } from '@material-ui/core/styles/colorManipulator';

export default ({ spacing, palette }) => {
    const color = palette.grey[600];
    const backgroundColor = fade(palette.grey[600], 0.3);
    return {
        girdContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        grid: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            // view height - grid actions wrapper height - grid footer height - app footer height
            height: `calc(100vh - var(--gridActionsWrapperHeight) - var(--gridFooterHeight) - 48px)`,
            margin: `${spacing.unit * 1.25}px 0px`,
            fontFamily: 'Museo',

            backgroundColor: '#fff',

            borderStyle: 'solid',
            borderColor: palette.background.dark,
            borderWidth: 1
        },
        gridOverlayScroll: {
            width: '100%',
            height: `calc(100% - var(--gridFooterHeight))`
        },
        scroll: {
            '& .gm-scrollbar.-vertical': {
                marginTop: spacing.unit * 11,
                marginBottom: spacing.unit * 2
            },
            /* Horizontal Scroll Gutter */
            '& .gm-scrollbar.-horizontal': {
                height: 'calc(var(--scrollSize) * 3)',
                marginLeft: 'var(--scrollSize)',
                marginRight: spacing.unit * 2,
                background: `linear-gradient(
                                  to bottom, 
                                  ${palette.common.transparent} 0%, 
                                  ${palette.common.transparent} 33%, 
                                  ${backgroundColor} 33%, 
                                  ${backgroundColor} 66%, 
                                  ${palette.common.transparent} 66%, 
                                  ${palette.common.transparent} 100%)`,
                /* Horizontal Scroll handle */
                '& .thumb': {
                    height: 'calc(var(--scrollSize) * 3)',
                    left: 'calc(var(--scrollSize) * -1)',
                    borderStyle: 'solid',
                    borderColor: palette.common.transparent,
                    borderWidth: 'var(--scrollSize)',
                    boxShadow: 'inset 0 0 0 var(--scrollSize)',
                    backgroundColor: palette.common.transparent,
                    color
                }
            }
        },
        noDataMessage: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(50%)'
        }
    };
};
