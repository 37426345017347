import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

import { withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import ContentLoader from '@libComponents/ContentLoader';
import { formListener } from 'app/ReduxGate/store/middleware';
import { pieChartSettingsSelector } from 'personalizationSettings/redux.selectors';
import { setPieChartSettings } from 'personalizationSettings/redux.actions';

import SampleOverviewChart from './SampleOverviewChart';
import DateRangeFilter from './DateRangeFilter';
import SampleDetailsBreakdownChart from './SampleDetailsBreakdownChart';
import { FORM_NAME, INITIAL_VALUES, DOMAIN_NAME } from './common';
import { fetchSamplingReportData } from '../redux.actions';
import {
    samplingReportDataLoadingSelector,
    samplingReportDataSelector
} from '../redux.selectors';

const styles = ({ spacing }) => ({
    contentContainer: {
        position: 'relative',
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 3
    }
});

const mapInputs = {
    startDate: () => change(FORM_NAME, 'endDate', '')
};

const mapStateToProps = state => {
    const { startDate, endDate, retailer } = formValueSelector(FORM_NAME)(
        state,
        'startDate',
        'endDate',
        'retailer'
    );
    return {
        loading: samplingReportDataLoadingSelector(state),
        data: samplingReportDataSelector(state),
        chartSettings: pieChartSettingsSelector(state, DOMAIN_NAME),
        startDate,
        endDate,
        retailer
    };
};

const mapDispatchToProps = {
    fetchData: fetchSamplingReportData,
    storeChartViewSettings: setPieChartSettings(DOMAIN_NAME)
};

const SamplingReport = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    reduxForm({
        form: FORM_NAME,
        initialValues: INITIAL_VALUES,
        enableReinitialize: true
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes,
        loading,
        data,
        chartSettings,
        startDate,
        endDate,
        retailer,
        fetchData,
        storeChartViewSettings
    }) => {
        useEffect(() => {
            if (startDate && endDate) {
                fetchData({ startDate, endDate });
            }
        }, [startDate, endDate]);

        return (
            <ContentContainer
                title='Overall Sampling'
                AdornmentComponent={<DateRangeFilter />}
                styles={{ container: classes.contentContainer }}
            >
                {loading && <ContentLoader />}

                <SampleOverviewChart data={data} />
                <SampleDetailsBreakdownChart
                    data={data}
                    retailer={retailer}
                    storeChartViewSettings={storeChartViewSettings}
                    chartSettings={chartSettings}
                    startDate={startDate}
                    endDate={endDate}
                />
            </ContentContainer>
        );
    }
);

export default SamplingReport;
