import createCachedSelector from 're-reselect';

// Selectors
import { selectPaginatedDataset } from 'selectors';

// Normalizers
import { normalizePaginatedData } from 'normalizers';

const composeUrl = (baseUrl, queries) => {
    const queriesString = Object.entries(queries).reduce(
        (acm, [key, value], index) => {
            const ampersand = index === 0 ? '' : '&';
            if (value) return `${acm}${ampersand}${key}=${value}`;
            else return acm;
        },
        '?'
    );
    return `${baseUrl}/${queriesString}`;
};

const composePaginatedEntity = (
    entity,
    fields,
    composeDefaultQueries = () => ({})
) => ({
    selector: createCachedSelector(
        (state) => selectPaginatedDataset(state, entity),
        ({ data = [] } = {}) => data
    )(() => 'placeholder'),
    normalizer: normalizePaginatedData,
    fields,
    queryBuilder: (
        paginationQueries = {
            pageNumber: 1,
            pageSize: 10,
        }
    ) => (state) => ({
        ...composeDefaultQueries(state),
        ...paginationQueries,
    }),
});

export { composeUrl, composePaginatedEntity };
