import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, Button, Typography, Toolbar } from '@material-ui/core';

// Local
import styles from './styles';
import {
    Card,
    CardBody,
    CheckTickIcon,
    GridContainer,
    GridItem,
    withModal
} from 'components';
import SamplingFabricsHeader from './SamplingFabricsHeader';
import SamplingFabricsCard from './SamplingFabricsCard';
import { FabricsModal } from 'views/Sampling/modals';

class SamplingFabricsContainer extends Component {
    render() {
        const { props } = this;

        const { classes: c, handleFabricsModal, fabrics } = props;

        return (
            <Fragment>
                <SamplingFabricsHeader
                    fabricExists={!!fabrics.length}
                    modalHandler={() => handleFabricsModal()}
                />
                {!fabrics.length && (
                    <Button
                        variant='contained'
                        className={classNames(c.button, c.centerAlign)}
                        onClick={() => handleFabricsModal()}
                    >
                        Add fabric details
                    </Button>
                )}

                {!!fabrics.length && (
                    <GridContainer justify='flex-start' spacing={24}>
                        {fabrics.map((fabric, index) => (
                            <GridItem xs={12} sm={6} md={3} key={index}>
                                <SamplingFabricsCard
                                    data={fabric}
                                    handleFabricsModal={handleFabricsModal}
                                    fabricIndex={index}
                                />
                            </GridItem>
                        ))}
                    </GridContainer>
                )}
            </Fragment>
        );
    }
}

const mapModals = {
    handleFabricsModal: FabricsModal
};

const _SamplingFabricsContainer = compose(
    withStyles(styles),
    withModal(mapModals)
)(SamplingFabricsContainer);

export {
    _SamplingFabricsContainer as default,
    _SamplingFabricsContainer as SamplingFabricsContainer
};
