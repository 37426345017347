import React, { useCallback, useMemo } from 'react';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { makeSideEffectCallback } from '@libs/makeContext';

// Local
import { UploadProvider } from './useUpload';
import { useUploadStyle } from './hooks';
import Actions from './Actions';
import Control from './Control';

const propTypes = {};
const defaultProps = {};

const styles = ({ palette, spacing, typography, shadows }) => ({
    container: {
        width: '100%'
    },
    root: {
        display: 'flex',
        height: spacing.unitHeight,
        width: '100%'
    },
    label: {
        width: '80%',
        height: '100%',
        justifyContent: 'start',
        textTransform: 'none',
        color: 'var(--textColor)',

        borderWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,

        '&:hover': {
            backgroundColor: palette.common.white
        }
    },
    Upload: {
        width: '20%',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        height: '100%',
        color: palette.common.white,
        fontSize: typography.caption.fontSize,
        fontWeight: typography.fontWeightHeavy,
        borderRadius: 0,
        textTransform: 'none',
        boxShadow: shadows[0],
        background: `linear-gradient(to bottom, ${palette.success.main}, ${palette.success.dark})`,

        '&:hover': {
            background: `linear-gradient(to bottom, ${palette.success.dark}, ${palette.success.dark})`
        }
    }
});

const Upload = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme,
        placeholder,
        uploadConstraints,
        input: { value, onChange, onBlur },
        meta: { touched }
    }) => {
        const url = value.hasOwnProperty('url') ? value.url : '';
        const initialize = useCallback(
            state => ({
                ...state,
                touched,
                uploadConstraints,
                handleBlur: onBlur,
                value: url,
                filename: value.hasOwnProperty('filename')
                    ? value.filename
                    : placeholder
            }),
            [value, placeholder, touched, onBlur, uploadConstraints]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        return (
            <div
                className={c.container}
                style={useUploadStyle({ theme, value })}
            >
                <UploadProvider
                    initialize={initialize}
                    middlewareProps={middlewareProps}
                >
                    <Control />
                    <Actions />
                </UploadProvider>
            </div>
        );
    }
);

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
