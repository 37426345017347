import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { withStyles, Divider } from '@material-ui/core';

// Libs
import { fetchDatasets } from '@libs/datasets';
import ContentContainer from '@libComponents/ContentContainer';
import { Input, GridContainer, GridItem } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware';
import isBlobUrl from '@libs/isBlobUrl';
import { requestedDateAfterNewImplementation } from '@baseComponents/Date';

// Out of domain
import { setSnack } from 'utilities/redux.actions';
import {
    SelectShapeDesigners,
    SelectGenders,
    SelectAges,
    SelectCategories,
    SelectStyles,
    SelectDesignDepartments
} from 'common/components';
import { SelectDepartments, SelectRetailers } from 'sampling/components';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import { developmentNameSelector } from 'common/redux.selectors';

// Local
import { FORM_NAME } from '../../common';
import {
    SelectArtworkSources,
    SelectDesigners,
    SelectLicensors,
    SelectProperties,
    VersionsViewer
} from '../../components';
import HeaderAdornment from './HeaderAdornment';
import { useImageResolver } from '../../hooks';
import { RESET_LICENSOR_PROPERTY } from '../../redux.actionTypes';
import {
    setCadRetailerChildren,
    setCadPropertyChildren,
    setArtworkSource
} from '../../redux.actions';
import { TYPES } from '../../redux.datasets';
import {
    isLicensedSelector,
    typeSelector,
    versionsSelector,
    cadSizePathsSelector,
    developmentTypeSelector,
    isPrimarkSelector
} from '../../redux.selectors';

const styles = ({ spacing, typography }) => ({
    contentContainer: {
        paddingBottom: spacing.unit * 6,
        marginBottom: spacing.unit * 6
    },
    container: {
        paddingBottom: spacing.unit * 6
    },
    fieldContainer: {
        paddingBottom: 0
    },
    button: {
        height: '100%',
        fontSize: typography.subtitle1.fontSize
    },
    divider: {
        width: '100%',
        marginBottom: spacing.unit * 5
    }
});

const mapState = state => {
    const developmentType = developmentTypeSelector(state);

    return {
        isLicensed: isLicensedSelector(state),
        type: typeSelector(state),
        developmentName: developmentNameSelector(state, 'CAD', developmentType),
        versions: versionsSelector(state),
        sizePaths: cadSizePathsSelector(state),
        requestedDate: formValueSelector(FORM_NAME)(state, 'requestedDate'),
        isPrimark: isPrimarkSelector(state)
    };
};

const mapDispatch = {
    fetchDatasets,
    setSnack
};

const mapInputs = {
    type: () => ({ type: RESET_LICENSOR_PROPERTY }),
    retailer: setCadRetailerChildren,
    licensor: setCadPropertyChildren,
    designer: setArtworkSource
};

const UploadCad = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        fetchDatasets,
        setSnack,
        isLicensed,
        mode,
        type,
        isShaped,
        developmentName,
        versions,
        sizePaths,
        requestedDate,
        isPrimark
    }) => {
        const isNewFieldsDisabled =
            mode !== 'new' &&
            requestedDateAfterNewImplementation(requestedDate);
        const disabledVersions = useMemo(() => {
            if (mode !== 'new') {
                return versions
                    .filter(version => !isBlobUrl(version.url))
                    .map(version => version.url);
            } else return false;
        }, [versions, mode]);

        const imageResolver = useImageResolver(sizePaths);

        const errorResolver = useCallback(
            error => setSnack({ message: error, type: 'error' }),
            [setSnack]
        );
        useEffect(() => {
            fetchDatasets([DEVELOPMENT_TYPES, TYPES]);
        }, []);

        return (
            <ContentContainer
                title='Upload CAD Images'
                styles={{ container: c.contentContainer }}
                AdornmentComponent={<HeaderAdornment />}
            >
                <GridContainer className={c.container}>
                    <GridItem md={4}>
                        <Input
                            name='cadReference'
                            label='CAD Reference'
                            disabled
                            required
                            styles={{ container: c.fieldContainer }}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='buyerReference'
                            label='Buyer Reference'
                            styles={{ container: c.fieldContainer }}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='@view-type'
                            label='Type'
                            disabled
                            staticValue={type}
                            styles={{ container: c.fieldContainer }}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='@view-developmentName'
                            label='Development version'
                            disabled
                            staticValue={developmentName}
                            styles={{ container: c.fieldContainer }}
                        />
                    </GridItem>
                    <GridItem md={8}>
                        <Input
                            name='description'
                            label='Description'
                            required
                            styles={{ container: c.fieldContainer }}
                        />
                    </GridItem>
                    {isLicensed && (
                        <Fragment>
                            <GridItem md={4}>
                                <SelectLicensors
                                    styles={{ container: c.fieldContainer }}
                                    disabled={mode !== 'new'}
                                    required
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SelectProperties
                                    styles={{ container: c.fieldContainer }}
                                    formName={FORM_NAME}
                                    required
                                />
                            </GridItem>
                        </Fragment>
                    )}
                    <GridItem md={4}>
                        <SelectArtworkSources
                            styles={{ container: c.fieldContainer }}
                            disabled={mode === 'amend'}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectDesigners
                            styles={{ container: c.fieldContainer }}
                            required
                            disabled
                        />
                    </GridItem>
                    {!isShaped && (
                        <GridItem md={4}>
                            <SelectShapeDesigners
                                styles={{ container: c.fieldContainer }}
                                disabled={mode === 'amend'}
                                required
                            />
                        </GridItem>
                    )}
                    <GridItem md={4}>
                        <SelectRetailers disabled={mode !== 'new'} required />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectGenders
                            disabled={isNewFieldsDisabled}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectAges disabled={isNewFieldsDisabled} required />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectCategories
                            disabled={isNewFieldsDisabled}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectDepartments
                            disabled={isNewFieldsDisabled}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectStyles disabled={isNewFieldsDisabled} required />
                    </GridItem>
                    {isPrimark && (
                        <GridItem md={4}>
                            <SelectDesignDepartments
                                formName={FORM_NAME}
                                required
                            />
                        </GridItem>
                    )}
                </GridContainer>

                <Divider className={c.divider} />
                <VersionsViewer
                    disableDrag={disabledVersions}
                    disableRemove={disabledVersions}
                    imageResolver={imageResolver}
                    errorResolver={errorResolver}
                    required
                />
            </ContentContainer>
        );
    }
);

export default UploadCad;
