import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import { makeSideEffectCallback } from '@libs/makeContext';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { withModalProvider, withModal } from '@libComponents/Modal';
import ContentContainer from '@libComponents/ContentContainer';

// Local
import { watchPORemoved } from 'orders/redux.actions';
import {
    PO_SHIP_MODE,
    PO_SHIP_DESTINATION,
    PO_TYPE,
    PO_PACK_TYPE,
    SIZE_SETS,
    NP_COLORS,
    ORDER_SUSTAINABILITY
} from 'orders/redux.datasets';
import {
    COMPOSITIONS,
    FABRIC_TYPES,
    FINISHED_GSMS
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import {
    productsLoadingSelector,
    isNewlookSelector,
    isPrimarkSelector,
    sampleRefChangeDataLoadingSelector,
    labDipsSelector
} from 'orders/redux.selectors';
import { PurchaseProvider } from './usePurchaseOrders';
import { useHandleAdd } from './hooks';
import { DOMAIN_NAME, FORM_NAME } from '../../../common';
import { NINETY_PERCENT_ID } from 'orders/common';
import Modal from './Modal';
import Adornments from './Adornments';
import Content from './Content';
import validation from './validation';

const propTypes = {
    name: PropTypes.string,
    mode: PropTypes.string.isRequired
};

const defaultProps = {
    name: 'purchaseOrders',
    required: false,
    validate: validation
};

const datasets = [
    { entity: PO_SHIP_MODE, domain: DOMAIN_NAME },
    { entity: PO_SHIP_DESTINATION, domain: DOMAIN_NAME },
    { entity: PO_TYPE, domain: DOMAIN_NAME },
    { entity: ORDER_SUSTAINABILITY, domain: DOMAIN_NAME },
    { entity: PO_PACK_TYPE, domain: DOMAIN_NAME },
    { entity: SIZE_SETS, domain: DOMAIN_NAME },
    { entity: COMPOSITIONS, domain: SAMPLING_DOMAIN_NAME },
    { entity: FABRIC_TYPES, domain: SAMPLING_DOMAIN_NAME },
    { entity: FINISHED_GSMS, domain: SAMPLING_DOMAIN_NAME }
];

const mapState = state => {
    const sampleRefChangeDataLoading = sampleRefChangeDataLoadingSelector(
        state
    );
    const { retailer, products, factoryOrigin } = formValueSelector(FORM_NAME)(
        state,
        'factoryOrigin',
        'retailer',
        'products'
    );

    const realDatasets = [
        ...datasets,
        ...(Number(retailer) === NINETY_PERCENT_ID
            ? [{ entity: NP_COLORS, domain: DOMAIN_NAME }]
            : [])
    ];

    return {
        loading:
            sampleRefChangeDataLoading ||
            realDatasets.some(({ entity, domain }) =>
                datasetLoadingSelector(state, domain, entity)
            ),
        productsLoading:
            sampleRefChangeDataLoading || productsLoadingSelector(state),
        isNewlook: isNewlookSelector(state),
        isPrimark: isPrimarkSelector(state),
        retailer,
        factoryOrigin,
        realDatasets,
        initialDipValues: labDipsSelector(products, retailer, factoryOrigin)
    };
};

const mapDispatch = (dispatch, { mode }) => ({
    fetchDatasets: (...args) => dispatch(fetchDatasets.apply(null, args)),
    watchPORemoved: index => dispatch(watchPORemoved(mode, index))
});

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const PurchaseOrdersContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    withField(),
    connect(
        mapState,
        mapDispatch
    ),

    withStyles(styles)
)(
    ({
        classes: c,
        input: { value, onChange, onBlur },
        meta: { form, touched, error },
        fieldName,
        required,
        handleModal,
        fetchDatasets,
        loading,
        productsLoading,
        factoryOrigin,
        isNewlook,
        retailer,
        isPrimark,
        realDatasets,
        watchPORemoved,
        initialDipValues
    }) => {
        const [action, setAction] = useState({});
        const poInitialValue = useMemo(() => value || [], [value]);
        const poFieldId = `${form}-${fieldName}`;

        const initialize = useCallback(
            state => ({
                ...state,
                purchaseOrders: poInitialValue,
                isNewlook,
                isPrimark
            }),
            [poInitialValue, isNewlook, isPrimark]
        );

        const handlePOValueChange = useCallback(
            value => {
                onChange(value);
                onBlur();
            },
            [onChange, onBlur]
        );

        const poNoTaken = useMemo(
            () =>
                poInitialValue.map(({ poNo, poPackId }) => ({
                    poNo,
                    poPackId
                })),
            [poInitialValue]
        );

        const poSizeSetTaken = useMemo(
            () =>
                poInitialValue.map(({ poNo, poPackId, sizeSet }) => ({
                    poNo,
                    poPackId,
                    sizeSet
                })),
            [poInitialValue]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(handlePOValueChange)
            }),
            [handlePOValueChange]
        );

        const formHandleModal = useMemo(
            () => ({
                initialValues: {
                    poNo,
                    poNoAsync,
                    poPackId,
                    packIdAsync,
                    sizeSet,
                    ...initialValues
                },
                ...props
            }) =>
                handleModal({
                    ...props,
                    initialValues: {
                        ...initialValues,
                        poNo,
                        poPackId,
                        sizeSet,
                        poNoTaken: poNoTaken.filter(
                            po => po.poNo !== poNo || po.poPackId !== poPackId
                        ),
                        poSizeSetTaken: poSizeSetTaken.filter(
                            po => po.poNo !== poNo || po.poPackId !== poPackId
                        ),
                        retailer
                    },
                    formName: form,
                    retailer
                }),
            [handleModal, form, retailer, poNoTaken, poSizeSetTaken]
        );

        const handleAdd = useHandleAdd({
            handleModal: formHandleModal,
            setAction,
            formName: form,
            factoryOrigin,
            retailer,
            initialDipValues
        });

        useEffect(() => {
            fetchDatasets(realDatasets.map(({ entity }) => entity));
        }, []);

        return (
            <PurchaseProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='PO details'
                    required={required}
                    error={touched && error ? error : ''}
                    AdornmentComponent={
                        <Adornments
                            handleAdd={handleAdd}
                            loading={!(value && value.length) && loading}
                            form={form}
                            disabled={productsLoading}
                        />
                    }
                    styles={{ container: c.container }}
                    id={poFieldId}
                >
                    <Content
                        handleModal={formHandleModal}
                        loading={loading}
                        watchPORemoved={watchPORemoved}
                    />
                </ContentContainer>
            </PurchaseProvider>
        );
    }
);

PurchaseOrdersContainer.propTypes = propTypes;
PurchaseOrdersContainer.defaultProps = defaultProps;

export { PurchaseOrdersContainer as default, PurchaseOrdersContainer };
