import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import {
    Select as MuiSelect,
    MenuItem,
    TextField,
    FormControl,
    LinearProgress
} from '@material-ui/core';

// Local
import LabelBase from '../../Label';
import FormHelperText from '../../FormHelperText';
import { composeClsx } from '@libs/materialUI';
import SelectCancellableIcon from './SelectCancellableIcon';
import SelectDropdownIcon from './SelectDropdownIcon';
import SelectPlaceholder from './SelectPlaceholder';

// Todo: First click after load is eaten up, fix
/*
 * Domain: --
 * Page: Select
 * Component: Base
 * Type: --
 * SelectBase
 */
class Select extends Component {
    // Set prop types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        isCancellable: PropTypes.bool,
        displayFirst: PropTypes.bool,
        placeholder: PropTypes.string
    };

    // Set default props
    static defaultProps = {
        styles: {},
        displayFirst: false,
        isCancellable: false,
        placeholder: null
    };

    state = {
        data: this.props.data,
        initialData: this.props.data,
        queryValue: '',
        selectFocus: false,
        selectStyle: null,
        currentIndex: -1,
        placeholderStyle: null,
        showPlaceholder: Boolean(this.props.placeholder),
        isDisabled: this.props.disabled || this.props.loading,
        valueIndex: -1,
        timedQuery: null
    };

    componentDidMount() {
        const {
            input: { onChange },
            data,
            displayFirst
        } = this.props;

        if (displayFirst) onChange(data[0]);
        this.setValueIndex();
    }

    componentDidUpdate(prevProps) {
        const {
            props: { input }
        } = this;
        if (prevProps.input.value !== input.value) {
            this.setValueIndex();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { queryValue } = state;

        const {
            classes: { select, selectCancellable, selectQuery },
            isCancellable,
            styles,
            meta: { dirty, initial },
            placeholder
        } = props;

        const nextState = {
            selectStyle: clsx(select, styles.select),
            showPlaceholder: Boolean(placeholder)
        };

        if (isCancellable && (dirty || initial)) {
            nextState.selectStyle = clsx(
                nextState.selectStyle,
                selectCancellable
            );
        }

        // There's no other way <-- Efficient though
        if (queryValue) {
            if (dirty) {
                nextState.selectStyle = clsx(
                    nextState.selectStyle,
                    selectQuery
                );
            }
            if (placeholder) {
                nextState.showPlaceholder = false;
            }
        }

        return nextState;
    }

    setValueIndex = () => {
        const {
            input: { value },
            data
        } = this.props;
        const valueIndex = data
            .map(({ value }) => value)
            .indexOf(Number(value));
        this.setState({ valueIndex });
    };

    onQuery = ({ target: { value: queryValue } }) => {
        const { state } = this;
        const { initialData, timedQuery } = state;
        this.setState({ queryValue });
        clearTimeout(timedQuery);
        const localTimer = setTimeout(() => {
            const data = initialData.filter(({ label }) => {
                return (
                    queryValue === '' ||
                    label
                        .toString()
                        .toLowerCase()
                        .includes(queryValue.toLowerCase())
                );
            });
            this.setState({ data, selectOpen: true });
        }, 250);
        this.setState({ timedQuery: localTimer });
    };

    onBlur = () => {
        this.setState({ selectFocus: false });
        this.props.input.onBlur();
    };

    onFocus = () => {
        this.setState({ selectFocus: true });
        this.props.input.onFocus();
    };

    onChange = value => {
        // Todo: handle input focus
        this.setState({ queryValue: '', data: this.state.initialData });
        this.props.input.onChange(value);
    };

    renderDropdownIcon = () => {
        const { state, props, onFocus } = this;
        const { styles = {} } = props;
        const { isDisabled } = state;
        const propStyles = Object.splice(styles, [
            'dropdownRoot',
            'dropdownIcon'
        ]);
        return (
            <SelectDropdownIcon
                styles={propStyles}
                onClick={onFocus}
                disabled={isDisabled}
            />
        );
    };

    keyDown = e => {
        const { keyCode } = e;
        // Down
        if (keyCode === 40) {
            const { currentIndex, data } = this.state;
            const newIndex =
                currentIndex >= data.length - 1 ? 0 : currentIndex + 1;
            this.setState({
                currentIndex: newIndex
            });
            this.onFocus();
        }

        // Down
        if (keyCode === 38) {
            const { currentIndex, data } = this.state;
            const newIndex =
                currentIndex <= 0 ? data.length - 1 : currentIndex - 1;
            this.setState({
                currentIndex: newIndex
            });
            this.onFocus();
        }

        // Enter
        if (keyCode === 13) {
            const { currentIndex, data } = this.state;
            const selection = data[currentIndex];

            if (selection) {
                this.onChange(selection.value);
                this.onBlur();
            }
        }
    };

    render() {
        const {
            state,
            props,
            renderDropdownIcon,
            onQuery,
            onBlur,
            onFocus,
            onChange
        } = this;

        const {
            queryValue,
            data,
            selectFocus,
            selectStyle,
            showPlaceholder,
            currentIndex,
            isDisabled,
            valueIndex
        } = state;

        const {
            classes,
            styles,
            input,
            meta: { form, touched, error },
            isCancellable,
            placeholder: placeholderValue,
            className,
            label,
            required,
            disabled,
            displayFirst, // Take out of restProps
            showSuccess = true,
            loading,
            domain,
            entity,
            onChangeHover = () => {},
            data: rawData,
            ...restProps
        } = props;

        const c = composeClsx({
            classes: { ...classes, select: selectStyle },
            styles
        });

        const { name, value } = input;
        const {
            onFocus: noFocus, // naming conflict
            onBlur: noBlur, // naming conflict
            onChange: noChange, // naming conflict
            ...inputProps
        } = input;

        const selectProps = {
            MenuProps: {
                classes: { paper: c.list },
                MenuListProps: {
                    disablePadding: true,
                    className: c.listItem
                },
                getContentAnchorEl: null,
                disableAutoFocusItem: true,
                disableAutoFocus: true,
                ModalClasses: { root: c.modal }
            },
            classes: {
                ...Object.splice(c, ['root', 'select', 'icon']),
                ...(isDisabled ? { disabled: c.selectDisabled } : {})
            },
            IconComponent: renderDropdownIcon,
            disableUnderline: true,
            ...inputProps,
            ...restProps,
            inputProps: {
                ...inputProps,
                value
            },
            open: !isDisabled && selectFocus,
            onClose: onBlur,
            onOpen: onFocus,
            onChange,
            disabled: isDisabled
        };
        const id = `${form}-${name}`;
        const valid = isNaN(value) ? !!value : !!Number(value);
        const success = !isDisabled && valid;
        const hasError = touched && error;

        const renderFloor =
            queryValue || valueIndex < 20 ? 0 : Number(valueIndex);
        const renderCeiling =
            queryValue || valueIndex < 20 ? 20 : renderFloor + 20;

        const composeMenuStyle = index =>
            currentIndex === index
                ? {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                  }
                : {};

        return (
            <FormControl className={c.container} id={id}>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        success={success}
                    />
                )}
                <FormControl
                    className={clsx(
                        c.control,
                        className,
                        showSuccess && success && c.success,
                        isDisabled && c.disabled,
                        hasError && c.error
                    )}
                >
                    {/*Todo: Convert to InputBase element */}

                    <TextField
                        classes={{ root: c.inputRoot }}
                        inputProps={{ className: c.input }}
                        value={queryValue}
                        onChange={onQuery}
                        onClick={onFocus}
                        disabled={isDisabled}
                        onKeyDown={this.keyDown}
                    />

                    {showPlaceholder && !success && !isDisabled && (
                        <SelectPlaceholder
                            styles={Object.splice(c, ['placeholder'])}
                            placeholder={placeholderValue}
                        />
                    )}
                    {isCancellable && success && (
                        <SelectCancellableIcon
                            styles={Object.splice(styles, [
                                'cancellableRoot',
                                'cancellableIcon'
                            ])}
                            onClick={() => {
                                onChange('');
                                onBlur();
                            }}
                        />
                    )}
                    <MuiSelect {...selectProps} id={id}>
                        {data.length > 0 ? (
                            data.map(
                                ({ value, label }, index) =>
                                    index >= renderFloor &&
                                    index < renderCeiling && (
                                        <MenuItem
                                            key={id + index}
                                            value={value}
                                            style={composeMenuStyle(index)}
                                            onMouseEnter={() =>
                                                onChangeHover(value)
                                            }
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                            )
                        ) : (
                            <MenuItem disabled>No options available</MenuItem>
                        )}
                    </MuiSelect>
                    {loading && (
                        <LinearProgress
                            classes={{
                                root: c.progress,
                                barColorPrimary: c.progressPrimary
                            }}
                        />
                    )}
                </FormControl>
                {hasError && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

export { Select as default, Select as SelectBase };
