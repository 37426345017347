import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import GeminiScrollbar from 'react-gemini-scrollbar';
import 'gemini-scrollbar/gemini-scrollbar.css';

import { fade } from '@material-ui/core/styles/colorManipulator';

import { withStylesAndRef } from '@libs/materialUI';

const propTypes = {
    size: PropTypes.number,
    autoshow: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    size: 5,
    autoshow: true,
    styles: {}
};

const styles = ({ palette }) => {
    const color = palette.grey[600];
    const backgroundColor = fade(palette.grey[600], 0.3);

    return {
        root: {
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        scroll: {
            /* Scroll Gutter
            Width is dynamic (x3)
            Margins prevent it from hitting the top/bottom
            Background uses sharp gradient for 1/3 sections
         */
            '& .gm-scrollbar.-vertical': {
                width: 'calc(var(--scrollSize) * 3)',
                marginBottom: 'var(--scrollSize)',
                marginTop: 'var(--scrollSize)',

                background: `linear-gradient(
                        to right, 
                        ${palette.common.transparent} 0%, 
                        ${palette.common.transparent} 33%, 
                        ${backgroundColor} 33%, 
                        ${backgroundColor} 66%, 
                        ${palette.common.transparent} 66%, 
                        ${palette.common.transparent} 100%)`,

                /* Scroll handle
                Negative 'top' to compensate for gutter's marginBottom
            */
                '& .thumb': {
                    width: 'calc(var(--scrollSize) * 3)',
                    top: 'calc(var(--scrollSize) * -1)',

                    borderStyle: 'solid',
                    borderColor: palette.common.transparent,
                    borderWidth: 'var(--scrollSize)',

                    boxShadow: 'inset 0 0 0 var(--scrollSize)',

                    backgroundColor: palette.common.transparent,
                    color
                }
            }
        }
    };
};

const OverlayScrollContainer = withStylesAndRef(styles)(
    ({
        classes: c,
        className,
        children,
        size,
        styles,
        forwardRef,
        ...props
    }) => {
        const style = {
            '--scrollSize': `${size}px`
        };

        return (
            <div className={clsx(c.root, styles.root)}>
                <GeminiScrollbar
                    className={clsx(c.scroll, styles.scroll)}
                    style={style}
                    ref={forwardRef}
                    forceGemini={true}
                    {...props}
                >
                    {children}
                </GeminiScrollbar>
            </div>
        );
    }
);

OverlayScrollContainer.propTypes = propTypes;
OverlayScrollContainer.defaultProps = defaultProps;

export default OverlayScrollContainer;
