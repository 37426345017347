import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import DateBase from '@baseComponents/Date';
import {
    normalizeDate,
    deserializeDate
} from '@baseComponents/Date/normalizeDate';

import { useGridViewDispatch } from 'gridView/useGridView';

const propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    disabled: false,
    required: false
};

const styles = ({ spacing }) => ({
    container: {
        width: '100%'
    },
    root: {
        height: spacing.unit * 3.5,
        borderColor: '#e0e0e0 !important',
        '&:hover': {
            borderColor: '#bdbdbd !important'
        }
    }
});

const DateInput = withStyles(styles)(
    ({ name, id, rowIndex, value, classes, ...props }) => {
        const gridViewDispatch = useGridViewDispatch();

        const handleChange = value => {
            gridViewDispatch({
                type: 'update-grid-cell',
                payload: {
                    rowIndex,
                    id,
                    name,
                    value: deserializeDate(normalizeDate(value)),
                    type: 'date'
                }
            });
        };

        const datePickerProps = {
            input: {
                onFocus: () => {},
                onBlur: () => {},
                onChange: handleChange,
                value: normalizeDate(value) || ''
            },
            styles: classes,
            name: 'void0',
            ...props
        };

        return <DateBase {...datePickerProps} />;
    }
);

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export { DateInput as default, DateInput };
