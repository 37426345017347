export default ({ palette, spacing }) => ({
    toolbar: {
        marginBottom: spacing.unit * 2.5,
        marginTop: spacing.unit * 5,
    },
    icon: {
        marginLeft: spacing.unit * 2,
        marginRight: spacing.unit * 2,
        marginBottom: spacing.unit * 0.5
    },
    button: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    }
});
