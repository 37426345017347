const LICENSOR_ROUTE_ID = 241;
const LICENSOR_PROPERTY_ROUTE_ID = 631;
const CAD_TYPE_ROUTE_ID = 629;
const ARTWORK_SOURCE_ROUTE_ID = 632;

export {
    LICENSOR_ROUTE_ID,
    LICENSOR_PROPERTY_ROUTE_ID,
    CAD_TYPE_ROUTE_ID,
    ARTWORK_SOURCE_ROUTE_ID
};
