export const FETCH_USER_PERMISSIONS_DATA =
    'administrator@fetch-user-permissions-data';
export const SET_USER_PERMISSIONS_DATA_LOADING =
    'administrator@set-user-permissions-data-loading';
export const SET_USER_PERMISSIONS_DATA =
    'administrator@set-user-permissions-data';
export const SET_COPY_USER_GRANTED_PERMISSIONS =
    'administrator@set-copy-user-granted-permissions';
export const SET_COPY_USER_GRANTED_PERMISSIONS_LOADING =
    'administrator@set-copy-user-granted-permissions-loading';
export const USER_PUBLISH = 'administrator@user-publish';
export const GET_AMEND_USER_DATA = 'administrator@get-amend-user-data';
export const ROUTE_TO_AMEND_USER = 'administrator@route-to-amend-user';
export const EXPORT_USER_XL_DATA = 'administrator@export-user-xl-data';
export const SET_EXPORT_USER_XL_DATA_LOADING =
    'administrator@set-export-user-xl-data-loading';
