import React from 'react';
import { string, object } from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

import { FONT_FAMILY_BOLD } from 'common/constants';

const propTypes = {
    text: string.isRequired,
    style: object
};

const styles = StyleSheet.create({
    title: {
        width: '100%',
        borderBottom: 2,
        borderBottomColor: 'black',
        fontSize: 16,
        fontFamily: FONT_FAMILY_BOLD,
        margin: `4px 0px`
    }
});

const Title = ({ text, style, ...rest }) => {
    return (
        <Text style={{ ...styles.title, ...style }} {...rest}>
            {text}
        </Text>
    );
};

Title.propTypes = propTypes;

export { Title as default, Title };
