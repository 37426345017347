import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Local
import SelectModel from '@formComponents/SelectModel';
import {
    DOMAIN_NAME,
    DISPATCH_DETAILS_FORM_NAME
} from 'sampling/SampleDispatch/common';
import { DISPATCH_SAMPLES_BY_ORDER_NO } from 'sampling/redux.datasets';

// Datasets
import { datasetLoadingSelector, entitySelector } from '@libs/datasets';

const colourDataSelector = createCachedSelector(
    entitySelector,
    (state, _, __, sampleRef) => sampleRef,
    (data, sampleRef) => {
        const { colours = [] } =
            data.find(datum => datum.sampleRef === sampleRef) || {};
        return colours.map(({ colour }) => ({
            label: colour,
            value: colour
        }));
    }
)(
    (state, domain, entity, sampleRef) =>
        `@data-${domain}-${entity}-${sampleRef}`
);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'colour',
    label: 'Colour',
    domain: DOMAIN_NAME,
    entity: DISPATCH_SAMPLES_BY_ORDER_NO,
    disabled: false,
    required: true
};

const mapState = (state, { domain, entity }) => {
    const sampleRef = formValueSelector(DISPATCH_DETAILS_FORM_NAME)(
        state,
        'sampleRef'
    );
    return {
        data: colourDataSelector(state, domain, entity, sampleRef),
        loading: datasetLoadingSelector(state, domain, entity),
        disabled: !sampleRef
    };
};

const SelectColourByOrderSample = connect(mapState)(props => {
    return <SelectModel {...props} />;
});

SelectColourByOrderSample.propTypes = propTypes;
SelectColourByOrderSample.defaultProps = defaultProps;

export { SelectColourByOrderSample as default, colourDataSelector };
