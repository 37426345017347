import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

import useResizingContext from '../useResizingContext';

// pre fatch additional rows of items in both direction, improving user experience when scrolling
const OVER_SCAN = 2;

const useRowVirtualization = (totalItems, rowHeight) => {
    const { gridScrollbarRef, gridBodyHeight } = useResizingContext();

    const [visibleRange, setVisibleRange] = useState({
        start: 0,
        end: Math.min(10, totalItems)
    });

    const calculateVisibleRange = useCallback(
        debounce(() => {
            if (gridScrollbarRef.current) {
                const container =
                    gridScrollbarRef.current.scrollbar._viewElement;
                const scrollTop = container.scrollTop;
                const start = Math.floor(scrollTop / rowHeight);
                const end = Math.min(
                    start + Math.ceil(gridBodyHeight / rowHeight) + 1,
                    totalItems
                );

                setVisibleRange({
                    start: Math.max(0, start - OVER_SCAN),
                    end: Math.min(totalItems, end + OVER_SCAN)
                });
            }
        }, 200),
        [gridBodyHeight, totalItems]
    );

    useEffect(() => {
        if (gridScrollbarRef.current) {
            const container = gridScrollbarRef.current.scrollbar._viewElement;
            const handleScroll = () => {
                calculateVisibleRange();
            };
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [calculateVisibleRange]);

    useEffect(() => {
        let isSubscribed = true;
        const itemsCanFit = Math.ceil(gridBodyHeight / rowHeight);

        if (isSubscribed) {
            setVisibleRange({
                start: 0,
                end: Math.min(itemsCanFit, totalItems)
            });

            if (gridScrollbarRef.current) {
                const container =
                    gridScrollbarRef.current.scrollbar._viewElement;
                container.scrollTop = 0;
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [gridBodyHeight, totalItems]);

    return visibleRange;
};

export { useRowVirtualization };
