import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import GridInput from '../../../../../GridInput';

const propTypes = {
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    handleGridDataUpdate: PropTypes.func.isRequired
};

const styles = ({ shape, spacing }) => ({
    gridInputCellEditor: {
        height: spacing.unit * 4.5,
        backgroundColor: '#fff',
        borderRadius: shape.borderRadius,
        padding: spacing.unit * 1
    }
});

const InputCell = withStyles(styles)(
    ({ classes: c, column, row, handleGridDataUpdate }) => {
        const currentValue = row[column.name] || '';
        const [value, setValue] = useState(currentValue);

        const handleChange = useCallback(event => {
            setValue(event.target.value);
        }, []);

        const handleBlur = useCallback(() => {
            const newRow = { ...row, [column.name]: value };
            handleGridDataUpdate(newRow);
        }, [row, column, value, handleGridDataUpdate]);

        const handleKeyPress = useCallback(
            ({ key }) => {
                if (key === 'Enter') {
                    handleBlur();
                }
            },
            [handleBlur]
        );

        return (
            <GridInput
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                type={column.type}
                styles={{ root: c.gridInputCellEditor }}
                autoFocus
            />
        );
    }
);

InputCell.propTypes = propTypes;

export default InputCell;
