import React from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableCell } from '@libComponents/Table';
import { useFabricTableContent } from './hooks';

const propTypes = {
    content: PropTypes.object
};

const Content = ({ content }) => {
    const cells = useFabricTableContent(content);
    return (
        <TableRow>
            {cells.map((cell, idx) => (
                <TableCell key={`fabric-table-cell-${idx}`}>{cell}</TableCell>
            ))}
        </TableRow>
    );
};

Content.propTypes = propTypes;

export default Content;
