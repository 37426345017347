import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import GridDate from '../../../../../GridDate';

const propTypes = {
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    handleGridDataUpdate: PropTypes.func.isRequired
};

const styles = ({ spacing }) => ({
    gridDateCellContainer: {
        height: spacing.unit * 4.5
    },
    gridDateCellEditor: {
        backgroundColor: '#fff'
    }
});

const DateCell = withStyles(styles)(
    ({ classes: c, column, row, handleGridDataUpdate, ...restProps }) => {
        const currentValue = row[column.name] || '';
        const columnName = `${column.name}-${row.id}`;

        const handleChange = useCallback(
            value => {
                const newRow = { ...row, [column.name]: value };
                handleGridDataUpdate(newRow);
            },
            [row, column, handleGridDataUpdate]
        );

        const props = useMemo(
            () => ({
                styles: {
                    root: c.gridDateCellEditor,
                    container: c.gridDateCellContainer
                },
                handleOnChange: handleChange,
                value: currentValue,
                name: columnName,
                ...restProps
            }),
            [handleChange, currentValue, columnName, restProps]
        );
        return <GridDate {...props} />;
    }
);

DateCell.propTypes = propTypes;

export default DateCell;
