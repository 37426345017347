export const FETCH_GRAPHIC_REF_REQUEST = 'get_graphic_ref';
export const FETCH_GRAPHIC_REF_SUCCESS = 'get_graphic_ref_success';
export const FETCH_GRAPHIC_REF_FAILURE = 'get_graphic_ref_failure';

export const NEW_CAD_OTHERS = 'post_new_cad_others_create';

export const SET_GRAPHIC_DESCRIPTION = 'set_graphic_description';
export const SET_CAD_IMAGE = 'set_cad_image';

export const UPLOAD_CAD_IMAGE_REQUEST = 'upload_cad_image_request';
export const UPLOAD_CAD_IMAGE_SUCCESS = 'upload_cad_image_success';