import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

// Local
import { useUrl } from './hooks';

const propTypes = {
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired
};

const styles = ({ palette, spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 6,
        marginRight: spacing.unit * 1.5,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        backgroundColor: palette.background.light
    },
    extension: {
        color: palette.background.dark,
        textTransform: 'uppercase',
        marginLeft: spacing.unit * -0.25 // Accounts for italics
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
});

const Preview = withStyles(styles)(({ classes: c, url, filename }) => {
    const { extension, canPreview } = useUrl(filename);

    return (
        <div className={c.root}>
            {canPreview ? (
                <img className={c.image} src={url} alt={extension} />
            ) : (
                <Typography component='i' variant='h5' className={c.extension}>
                    {extension}
                </Typography>
            )}
        </div>
    );
});

Preview.propTypes = propTypes;

export default Preview;
