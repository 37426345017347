import React, { useMemo } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';
// App
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

import { PaginatedSelect } from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME, FORM_NAME } from 'orders/Order/common';
import { ORDER_BASIC_TRIMS } from 'orders/redux.datasets';
import { setOrderBasicTrimsChildren } from 'orders/redux.actions';

const mapInputs = {
    orderBasicTrims: ({ payload }) => setOrderBasicTrimsChildren(payload)
};

const styles = ({ spacing, palette }) => ({
    selectContainerWrapper: {
        width: spacing.unit * 27
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const Adornment = compose(
    withStyles(styles),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs)
)(({ classes: c }) => {
    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null,
            placeholder: 'Select..'
        }),
        []
    );
    return (
        <div className={c.selectContainerWrapper}>
            <PaginatedSelect
                name='orderBasicTrims'
                entity={ORDER_BASIC_TRIMS}
                domain={DOMAIN_NAME}
                {...paginatedSelectProps}
            />
        </div>
    );
});

export default Adornment;
