import { useState, useCallback, useEffect } from 'react';

import getDataUrl from '@libs/getDataUrl';
import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDFContent from './PDFContent';

export const loadProps = async ({
    other: { brands, ...restOther },
    products,
    ...restProps
}) => {
    const productImageUrls = await Promise.all(
        products.map(async ({ frontImage, ...restProducts }) => {
            return {
                frontImage: !!frontImage
                    ? await getDataUrl(frontImage)
                    : undefined,
                ...restProducts
            };
        })
    );

    const trimImageUrls = await Promise.all(
        brands.map(async ({ image, ...restInfo }) => {
            return {
                image: !!image ? await getDataUrl(image) : undefined,
                ...restInfo
            };
        })
    );

    return {
        other: {
            brands: trimImageUrls,
            ...restOther
        },
        products: productImageUrls,
        ...restProps
    };
};

export const useMakePDF = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleClick, loading, disabled, PDF, handleRemove] = usePDF({
        document: PDFContent,
        fileName,
        props,
        loadProps,
        errorResolver: error => setPDFError(error),
        withDownload: false,
        blobUrl: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleClick
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleClick, PDF, handleRemove];
};

export const useMailForm = ({ handleModal, setAction, formName }) => {
    const handleSave = useCallback(values => {
        setAction({ type: 'saveMailData', payload: values });
    }, []);

    return useCallback(() => {
        handleModal({ handleSave, formName });
    }, [handleModal, handleSave, formName]);
};

// Use only for testing pdf viewer.
export const usePDFProps = props => {
    const [propsReady, setPropsReady] = useState(false);
    const [loadedProps, setLoadedProps] = useState(null);
    const handleLoadProps = useCallback(async () => {
        try {
            const loadedProps = await loadProps(props);
            setLoadedProps(loadedProps);
            setPropsReady(true);
        } catch (e) {
            console.error(e);
        }
    }, [props]);
    useEffect(() => {
        if (props !== null) handleLoadProps();
    }, [props]);
    return [propsReady, loadedProps];
};
