import {
    LOGIN,
    LOGOUT,
    FORGOT_PASSWORD_EMAIL_SEND,
    CHANGE_PASSWORD,
    RESEND_OTP,
    VERIFY_OTP,
    SEND_OTP_LOADING
} from './redux.actionTypes';

export const login = ({ name, password }) => ({
    type: LOGIN,
    payload: { name, password }
});

export const sendResetLink = ({ email, url }) => ({
    type: FORGOT_PASSWORD_EMAIL_SEND,
    payload: { email, url }
});
export const changePassword = () => ({
    type: CHANGE_PASSWORD
});

export const resendOTP = () => ({
    type: RESEND_OTP
});

export const sendOTPLoading = loading => ({
    type: SEND_OTP_LOADING,
    payload: loading
});

export const verifyOTP = otp => ({
    type: VERIFY_OTP,
    payload: otp
});

export const logout = () => ({ type: LOGOUT });
