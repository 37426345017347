import { change } from 'redux-form';

import { apiClient } from '@libs/apiRequest';
import { PO_FORM_NAME } from 'orders/Order/common';
import { PRIMARK_ID } from 'orders/common';
import { setPoOrPackCheckLoading } from 'orders/redux.actions';

export default async (
    { poNo = '', poTBC, poPackId, packTBC, retailer, id, poNoTaken },
    dispatch,
    props,
    field
) => {
    const isPrimark = Number(retailer) === PRIMARK_ID;
    let params = { poNo, id, retailerId: retailer };

    if (!isPrimark) {
        if (field !== 'poNo' || !poNo) return;

        if (poTBC) {
            dispatch(change(PO_FORM_NAME, 'poNoAsync', 'Valid'));
            return;
        }

        const locallyExist = poNoTaken.some(po => poNo === po.poNo);
        if (locallyExist) {
            throw { poNo: 'This PO No already exists' };
        }
    } else {
        if (field !== 'poPackId' || !poPackId) return;

        if (poTBC && packTBC) {
            dispatch(change(PO_FORM_NAME, 'poNoAsync', 'Valid'));
            dispatch(change(PO_FORM_NAME, 'packIdAsync', 'Valid'));
            return;
        }

        const locallyExist = poNoTaken.some(
            po => po.poNo === poNo && po.poPackId === poPackId
        );

        if (locallyExist) {
            throw { poPackId: 'This Pack ID already exists' };
        }

        params = { ...params, poPackId };
    }

    // Responds with 404 on fail
    dispatch(setPoOrPackCheckLoading(true));

    const checkReference = new Promise(async resolve => {
        const referenceExists = await apiClient({
            url: 'PoDetails/GetPoDetailsByPO',
            params
        })
            .then(() => true)
            .catch(() => false);
        resolve(referenceExists);
    });

    dispatch(setPoOrPackCheckLoading(false));
    if (await checkReference) {
        throw {
            [isPrimark ? 'poPackId' : 'poNo']: `This ${
                isPrimark ? 'Pack ID' : 'PO NO'
            } already exists`
        };
    } else {
        dispatch(change(PO_FORM_NAME, 'poNoAsync', 'Valid'));
        if (isPrimark) {
            dispatch(change(PO_FORM_NAME, 'packIdAsync', 'Valid'));
        }
    }
};
