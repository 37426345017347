import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

import getHeaders from './common';

const styles = ({ spacing, palette }) => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    others: {
        width: '100%',
        textAlign: 'center',
        fontSize: spacing.unit * 1,
        padding: spacing.unit * 0.5,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    lastRow: {
        borderBottom: 'none'
    },
    rowCell: {
        fontSize: spacing.unit * 1,
        padding: spacing.unit * 0.5,
        overflowWrap: 'break-word',
        overflow: 'hidden',

        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark,

        lineHeight: 1.2
    },
    rowLastCell: {
        borderRight: 'none'
    },
    imageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark
    },
    image: {
        width: spacing.unit * 5,
        height: 'auto',
        objectFit: 'contain'
    },
    boldLabel: {
        fontFamily: FONT_FAMILY_BOLD
    }
});

const Row = withPDFStyles(styles)(
    ({
        classes: c,
        isLast,
        gender,
        department,
        po,
        remarks,
        isSeasalt,
        ...props
    }) => {
        const headers = getHeaders(isSeasalt);
        const rowLength = headers.length;

        return (
            <View>
                <View style={c.row}>
                    {headers.map(({ name, width }, idx) => {
                        const propertyValue = props[name];
                        if (name === 'sampleImage' && propertyValue) {
                            return (
                                <View
                                    style={{ ...c.imageContainer, width }}
                                    key={name}
                                >
                                    <Image
                                        style={c.image}
                                        src={propertyValue}
                                    />
                                </View>
                            );
                        }
                        return (
                            <Text
                                key={name}
                                style={{
                                    ...c.rowCell,
                                    width,
                                    ...(rowLength - 1 === idx
                                        ? c.rowLastCell
                                        : {})
                                }}
                            >
                                {propertyValue ? propertyValue : 'N/A'}
                            </Text>
                        );
                    })}
                </View>
                <Text style={{ ...c.others, ...(isLast ? c.lastRow : {}) }}>
                    <Text style={c.boldLabel}>Gender:</Text> {gender || 'N/A'}
                    {', '}
                    <Text style={c.boldLabel}>Department:</Text>
                    {department || 'N/A'} {po || remarks ? ', ' : ''}
                    {po ? (
                        <Text>
                            <Text style={c.boldLabel}>PO:</Text> {po}
                        </Text>
                    ) : remarks ? (
                        <Text>
                            <Text style={c.boldLabel}>Remarks:</Text> {remarks}
                        </Text>
                    ) : (
                        ''
                    )}
                </Text>
            </View>
        );
    }
);

export default Row;
