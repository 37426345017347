import { takeLatest, call, put } from 'redux-saga/effects';

// Action Types
import {
    DELETE_INK_IMAGE_REQUEST,
    DELETE_INK_IMAGE_SUCCESS
} from 'store/actionTypes';

//api calling

//workers
function* inkDeleteImgEffectSaga({ payload }) {
    try {
        yield put({
            type: DELETE_INK_IMAGE_SUCCESS,
            payload
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const inksWatchers = function*() {
    yield takeLatest(DELETE_INK_IMAGE_REQUEST, inkDeleteImgEffectSaga);
};

export { inksWatchers as default, inksWatchers };
