import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { fade } from '@material-ui/core/styles/colorManipulator';

import useVersionsViewer from '../../../useVersionsViewer';

const propTypes = {
    index: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired
};

const styles = ({ palette, spacing, transitions }) => ({
    overlay: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        padding: spacing.unit,
        color: 'var(--color)',
        '&:hover': {
            backgroundColor: 'var(--backgroundColor)'
        },
        borderRadius: 0,
        cursor: 'grab',
        zIndex: 30,
        transition: transitions.create(['color', 'background'], {
            easing: transitions.easing.easeInOut
        })
    },
    clearIcon: {
        position: 'absolute',
        right: 0,
        width: spacing.unit * 4,
        height: spacing.unit * 4,
        color: 'var(--color)',
        zIndex: 30,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: fade(palette.secondary.main, 0.7),
            color: palette.common.white
        },
        transition: transitions.create(['color', 'background'], {
            easing: transitions.easing.easeInOut
        })
    }
});

const VersionOverlay = withStyles(styles, { withTheme: true })(
    ({ classes: c, index, image, handleMouseEnter, handleMouseLeave }) => {
        const [{ readOnly, disableRemove }, dispatch] = useVersionsViewer();

        const handleActive = useCallback(
            () => dispatch({ type: 'setActiveIndex', payload: index }),
            [dispatch, index]
        );

        const handleRemove = useCallback(
            () => dispatch({ type: 'removeImage', payload: index }),
            [dispatch, index]
        );

        const canRemove = useMemo(() => {
            if (typeof disableRemove === 'boolean') return !disableRemove;
            else return !disableRemove.includes(image);
        }, [disableRemove, image]);

        return (
            <Fragment>
                <Button
                    className={c.overlay}
                    onClick={handleActive}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div />
                </Button>
                {!readOnly && canRemove && (
                    <IconButton className={c.clearIcon} onClick={handleRemove}>
                        <ClearIcon color='inherit' />
                    </IconButton>
                )}
            </Fragment>
        );
    }
);

VersionOverlay.propTypes = propTypes;

export default VersionOverlay;
