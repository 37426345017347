import React from 'react';
import PropTypes from 'prop-types';

// libs
import Button from '@libComponents/Button';

const propTypes = {
    handleSKUGenerate: PropTypes.func.isRequired,
    canGenerateSKU: PropTypes.bool.isRequired
};

const Adornment = ({ canGenerateSKU, handleSKUGenerate }) => {
    return (
        <Button
            color='success'
            onClick={handleSKUGenerate}
            disabled={!canGenerateSKU}
        >
            SKU Sequence
        </Button>
    );
};

Adornment.propTypes = propTypes;

export default Adornment;
