import React from 'react';

import { withStyles } from '@material-ui/core';

const styles = ({ palette, spacing }) => ({
    wrapper: {
        width: spacing.unit * 5.2,
        height: spacing.unit * 4.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #C8CED4',
        borderRadius: 2,
        backgroundColor: '#fff',
        marginLeft: spacing.unit * 0.8,
        '&:hover': {
            cursor: 'pointer',
            '& $icon': {
                fill: palette.grey[700]
            }
        }
    },
    icon: {}
});
const FilterIcon = withStyles(styles)(
    ({ color: fill = '#a5aab3', classes: c, ...restProps }) => {
        return (
            <div className={c.wrapper} {...restProps}>
                <svg
                    id='filter-results-button'
                    xmlns='http://www.w3.org/2000/svg'
                    width='18.594'
                    height='12.396'
                    viewBox='0 0 18.594 12.396'
                    className={c.icon}
                    fill={fill}
                >
                    <g id='filter'>
                        <path
                            id='Path_911'
                            data-name='Path 911'
                            d='M7.231,88.9h4.132V86.83H7.231ZM0,76.5v2.066H18.594V76.5Zm3.1,7.231h12.4V81.665H3.1Z'
                            transform='translate(0 -76.5)'
                        />
                    </g>
                </svg>
            </div>
        );
    }
);

export { FilterIcon as default, FilterIcon };
