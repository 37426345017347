import React from 'react';
import PropTypes from 'prop-types';

import { PaginatedSelect } from '@formComponents';
import { DOMAIN_NAME } from '../common';
import { PRINT_AMEND_SAMPLES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'amendReference',
    label: 'Print Amend Reference',
    placeholder: 'Select sample reference',
    disabled: false,
    required: false
};

const SelectPrintAmendSamples = props => (
    <PaginatedSelect {...props} domain={DOMAIN_NAME} entity={PRINT_AMEND_SAMPLES} />
);

SelectPrintAmendSamples.propTypes = propTypes;
SelectPrintAmendSamples.defaultProps = defaultProps;

export { SelectPrintAmendSamples as default, SelectPrintAmendSamples };
