import React from 'react';
import { array } from 'prop-types';

// Material
import {
    ExpansionPanel as MuiExpansionPanel,
    ExpansionPanelSummary as MuiExpansionPanelSummary,
    ExpansionPanelDetails as MuiExpansionPanelDetails,
    withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Local
import ExpansionPanelSummary from './ExpansionPanelSummary';
import ExpansionPanelDetails from './ExpansionPanelDetails/';

const propTypes = {
    data: array.isRequired
};

const styles = ({ spacing, palette, shape }) => ({
    panelRoot: {
        boxShadow: 'none',

        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        '&:not(:last-child)': {
            marginBottom: spacing.unit * 1.5
        },
        '&::before': {
            content: "''",
            height: '0px'
        }
    },
    summaryRoot: {
        backgroundColor: palette.background.light
    },
    detailsRoot: {
        padding: spacing.unit
    }
});

const ExpansionPanel = withStyles(styles)(({ classes: c, data }) => {
    return data.map(({ fabrics, cads, ...summary }, idx) => (
        <MuiExpansionPanel
            key={`expansion-panel-${idx}`}
            classes={{ root: c.panelRoot }}
        >
            <MuiExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: c.summaryRoot }}
            >
                <ExpansionPanelSummary {...summary} />
            </MuiExpansionPanelSummary>
            <MuiExpansionPanelDetails classes={{ root: c.detailsRoot }}>
                <ExpansionPanelDetails fabrics={fabrics} cads={cads} />
            </MuiExpansionPanelDetails>
        </MuiExpansionPanel>
    ));
});

ExpansionPanel.propTypes = propTypes;

export default ExpansionPanel;
