import React from 'react';
import classNames from 'classnames';

// Material
import { withStyles, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// Local
import styles from './styles';

const PaginatedDialogToolbarSpotlight = ({
    classes: { icon, iconHighlight, ...c },
    highlight,
    ...restProps
}) => {
    return (
        <InputAdornment classes={c} position='end' {...restProps}>
            <SearchIcon
                className={classNames(icon, highlight && iconHighlight)}
            />
        </InputAdornment>
    );
};

export default withStyles(styles)(PaginatedDialogToolbarSpotlight);
