import makeContext from '@libs/makeContext';

const initialState = {
    dispatchDetails: []
};

const dispatchDetailsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addDispatchDetails': {
            const { dispatchDetails } = state;
            const { payload } = action;

            reduxFormOnChange([...dispatchDetails, { ...payload, id: 0 }]);

            return action;
        }

        case 'editDispatchDetails': {
            const { dispatchDetails } = state;
            const { index, values } = action.payload;

            const dispatchDetailsAfterEdit = dispatchDetails.map(
                ({ id, ...dispatchDetails }, dispatchDetailsIndex) => ({
                    id,
                    ...(index === dispatchDetailsIndex
                        ? values
                        : dispatchDetails)
                })
            );

            reduxFormOnChange(dispatchDetailsAfterEdit);

            return action;
        }

        case 'deleteDispatchDetails': {
            const { dispatchDetails } = state;
            const { payload } = action;

            const dispatchDetailsAfterDelete = dispatchDetails.filter(
                (_, index) => index !== payload
            );

            reduxFormOnChange(dispatchDetailsAfterDelete);

            return action;
        }
        default:
            return action;
    }
};

const [
    DispatchDetailsProvider,
    useDispatchDetailsState,
    useDispatchDetailsDispatch,
    useDispatchDetails
] = makeContext(dispatchDetailsReducer, initialState, {
    name: 'dispatchDetails',
    middleware
});

export {
    useDispatchDetails as default,
    DispatchDetailsProvider,
    useDispatchDetailsState,
    useDispatchDetailsDispatch
};
