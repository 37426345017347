import React from 'react';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';

import { usePagination, DOTS } from './hooks';
import { NextIcon, PreviousIcon } from '@assets/Icons';

const styles = ({ transitions, spacing }) => ({
    pagination: {
        display: 'flex',
        listStyleType: 'none',
        margin: '0px',
        padding: '0px'
    },
    item: {
        color: '#333333',
        padding: `${spacing.unit}px ${spacing.unit * 2}px`,
        textDecoration: 'none',
        transition: transitions.create(['background-color']),
        transitionDuration: '.3s',
        border: '1px solid #C8CED4',
        backgroundColor: '#fff',
        '&:not(:last-child)': {
            borderRight: 'none'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer'
        }
    },
    active: {
        pointerEvents: 'none',
        backgroundColor: '#234C72',
        color: '#FFFFFF',
        border: 'none',
        fontWeight: 700
    },
    dots: {
        pointerEvents: 'none',
        padding: `${spacing.unit}px ${spacing.unit * 1.25}px`,
        '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'default'
        }
    },
    disabled: {
        pointerEvents: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'default'
        }
    }
});

const _Pagination = withStyles(styles)(
    ({
        totalPages,
        currentPage,
        pageSize,
        siblingCount,
        handlePageChange,
        classes: c
    }) => {
        const paginationRange = usePagination({
            currentPage,
            totalPages,
            siblingCount,
            pageSize
        });
        if (currentPage === 0 || paginationRange.length < 2) {
            return null;
        }

        const onNext = () => {
            handlePageChange(currentPage + 1);
        };

        const onPrevious = () => {
            handlePageChange(currentPage - 1);
        };

        const lastPage = paginationRange[paginationRange.length - 1];
        return (
            <ul className={c.pagination}>
                <li
                    className={classnames(
                        c.item,
                        currentPage === 1 ? c.disabled : ''
                    )}
                    onClick={onPrevious}
                >
                    <PreviousIcon />
                </li>

                {paginationRange.map((pageNumber, idx) => {
                    if (pageNumber === DOTS) {
                        return (
                            <li
                                className={classnames(c.item, c.dots)}
                                key={`${pageNumber}-${idx}`}
                            >
                                &#8230;
                            </li>
                        );
                    }

                    return (
                        <li
                            className={classnames(
                                c.item,
                                pageNumber === currentPage ? c.active : ''
                            )}
                            onClick={() => handlePageChange(pageNumber)}
                            key={`${pageNumber}-${idx}`}
                        >
                            {pageNumber}
                        </li>
                    );
                })}

                <li
                    className={classnames(
                        c.item,
                        currentPage === lastPage ? c.disabled : ''
                    )}
                    onClick={onNext}
                >
                    <NextIcon />
                </li>
            </ul>
        );
    }
);

export default _Pagination;
