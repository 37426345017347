import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';
import { userIdSelector } from 'auth/redux.selectors';

import { sampleDispatchFormSelector } from '../../../redux.selectors';

export default function*() {
    const [
        { dispatchDetails = [], actualSendDate, eta, ...values },
        userId
    ] = yield all([
        select(
            sampleDispatchFormSelector,
            'retailer',
            'country',
            'dispatchReference',
            'id',
            'trackingReference',
            'fao',
            'actualSendDate',
            'eta',
            'currier',
            'dispatchDetails'
        ),
        yield select(userIdSelector)
    ]);

    return renameProperties(
        {
            ...values,
            userId,
            actSentDate: deserializeDate(actualSendDate),
            eta: deserializeDate(eta),
            dispatchDetails: dispatchDetails.map(
                ({ PONumbers, ...details }) => {
                    const pos = PONumbers.map(poNo => ({ poNo }));
                    return {
                        ...renameProperties(
                            { ...details, pos },
                            {
                                dispatchType: 'typeId',
                                size: 'sizeId',
                                factory: 'factoryId',
                                season: 'seasonId'
                            }
                        )
                    };
                }
            )
        },
        {
            dispatchReference: 'dispatchRef',
            retailer: 'retailerId',
            country: 'countryId',
            fao: 'notifyFAO',
            currier: 'currierId'
        }
    );
}
