import { startSubmit } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

import { FORM_NAME } from '../../Fabric/FabricGroup/common';
import { fabricGroupSchema } from './schemas';
import { DOMAIN_NAME } from 'setup/common';
import { FABRIC_GROUP } from 'setup/redux.datasets';
import { FABRIC_TYPES } from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

// Saga that handles adding or updating fabric group
export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        // Start the submit process for the form
        yield put(startSubmit(FORM_NAME));

        // Compose the payload to be sent to the API
        const publishData = composeApiPayload(payload, fabricGroupSchema);

        // Call the API to add or update the fabric group
        const { data } = yield call(apiRequest, {
            url: 'FabricGroup',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        // Update the fabric group dataset
        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: FABRIC_GROUP,
            isNew: id === 0,
            data
        });

        // If updating an existing fabric group, update the dependent dataset
        if (id > 0) {
            const dependentDataset = yield select(
                entitySelector,
                SAMPLING_DOMAIN_NAME,
                FABRIC_TYPES
            );
            // Update the fabric types dataset with the updated fabric group data
            const dependentDatasetAfterEdit = dependentDataset.map(
                ({ fabricGroupId, ...prevData }) => ({
                    ...(fabricGroupId === id
                        ? { ...prevData, fabricGroup: data.fabricGroup }
                        : prevData),
                    fabricGroupId
                })
            );
            yield put(
                setDataset({
                    entity: FABRIC_TYPES,
                    domain: SAMPLING_DOMAIN_NAME,
                    dataset: dependentDatasetAfterEdit
                })
            );
        }

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Fabric group',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
