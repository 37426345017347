import { call, put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { apiRequest } from '@libs/apiRequest';
import { setReportLoading } from '../../redux.actions';
import downloadWorker from './downloadWorker';

export default function*(action) {
    const {
        payload: { data, fileName },
        meta: { module, reportType }
    } = action;
    try {
        yield put(setReportLoading({ [reportType]: true }));
        const payload = {
            sampleIds: data.join(','),
            moduleType: module,
            reportType
        };
        const { data: blob } = yield call(apiRequest, {
            method: 'get',
            url: 'Sample/GetSamplePageReport',
            params: { ...payload },
            responseType: 'blob'
        });
        yield call(downloadWorker, {
            blob,
            fileName: `${fileName}.pdf`
        });
        yield put(setReportLoading({ [reportType]: false }));
    } catch (error) {
        yield put(setReportLoading({ [reportType]: false }));
        yield put(
            setSnack({
                message: 'Failed to download',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
