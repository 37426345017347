import { mapFromLabel } from './select';

export const mapToCard = ({ amends = [], ...details }) => detailsMap => {
    const isAmended = propName => amends.includes(propName);

    const serializeSelect = (label, propName, selector) => ({
        label,
        value: mapFromLabel(selector, details[propName]),
        ...(isAmended(propName) ? { amended: true } : {})
    });

    const serializeBoolean = (label, propName) => ({
        label,
        value: !!details[propName],
        ...(isAmended(propName) ? { amended: true } : {})
    });

    const serializeText = (label, propName) => ({
        label,
        value: details[propName],
        ...(isAmended(propName) ? { amended: true } : {})
    });

    return detailsMap.map(([label, propName, type = undefined]) => {
        switch (typeof type) {
            case 'object': {
                return serializeSelect(label, propName, type);
            }
            case 'boolean': {
                return serializeBoolean(label, propName);
            }
            default:
                return serializeText(label, propName);
        }
    });
};
