import React, { isValidElement } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import clsx from 'classnames';

import { useGridViewState } from 'gridView/useGridView';
import { expandedDataSelector } from 'gridView/customSelectors';

const styles = ({ spacing, typography, palette }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,25%)',
        gridTemplateRows: 'auto',
        gridAutoFlow: 'row',
        '& > $column:nth-child(8n + 5),& > $column:nth-child(8n + 6),& > $column:nth-child(8n + 7),& > $column:nth-child(8n + 8)': {
            backgroundColor: '#fcfcfc'
        },
        border: '1px solid #000'
    },
    column: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRight: '1px solid #c8ced4',
        borderBottom: '1px solid #c8ced4',
        padding: `${spacing.unit * 1}px ${spacing.unit * 2}px`,
        '&:nth-child(4n+1):nth-last-child(-n+4),&:nth-child(4n+1):nth-last-child(-n+4) ~ &': {
            borderBottom: 'none'
        }
    },
    cell: {
        fontSize: spacing.unit * 1.4,
        fontFamily: typography.fontFamily,
        color: palette.grey[600]
    },
    header: {
        marginRight: spacing.unit * 2,
        flexBasis: '40%'
    },
    body: {
        flexBasis: '60%',
        wordBreak: 'break-word'
    },
    taRight: {
        textAlign: 'right',
        lineHeight: '18px'
    }
});

const ExpandedView = withStyles(styles)(({ rowId, classes: c }) => {
    const { data } = useGridViewState();
    const expandData = expandedDataSelector(data, rowId);
    return (
        <div className={c.container}>
            {expandData.map(({ header, value }, idx) => {
                return (
                    <div className={c.column} key={`${header}-${idx}`}>
                        <Typography className={clsx(c.cell, c.header)}>
                            {header}
                        </Typography>
                        <div className={c.body}>
                            {isValidElement(value) ? (
                                value
                            ) : (
                                <Typography className={clsx(c.cell, c.taRight)}>
                                    {value}
                                </Typography>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default ExpandedView;
