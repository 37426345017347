import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Input } from '@formComponents';
import { TooltipAction } from '@formComponents';

//Assets
import { CopyIcon } from '@assets/Icons';
import { useEffect } from 'react';

const styles = ({ spacing }) => ({
    wrapper: {
        position: 'relative'
    },
    inputStyle: {
        marginRight: spacing.unit * 2.5
    },
    copyButton: {
        position: 'absolute',
        top: '50%',
        right: '-25px'
    }
});

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'artworkLinks',
    label: 'Artwork Link',
    placeholder: 'Enter artwork link'
};

const mapState = (state, { formname }) => ({
    artworkLink: formValueSelector(formname)(state, 'artworkLinks')
});

const ArtworkLink = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, artworkLink, ...props }) => {
    const [title, setTitle] = useState('');
    const handleCopy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(artworkLink);
            setTitle('Copied');
        } catch (error) {
            setTitle('Failed to copy');
        }
    }, [artworkLink]);

    useEffect(() => {
        setTitle('Copy');
    }, [artworkLink]);

    return (
        <div className={c.wrapper}>
            <Input {...props} />
            {artworkLink && (
                <TooltipAction
                    onClick={handleCopy}
                    className={c.copyButton}
                    IconComponent={CopyIcon}
                    title={title}
                />
            )}
        </div>
    );
});

ArtworkLink.propTypes = propTypes;
ArtworkLink.defaultProps = defaultProps;

export { ArtworkLink as default, ArtworkLink };
