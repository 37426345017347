export const FETCH_GRID_DATA = 'gridView@fetchGridData';
export const SET_GRID_DATA = 'gridView@setGridData';
export const SET_GRID_LOADING = 'gridView@setGridLoading';

export const GRID_DATA_UPDATE = 'gridView@gridUpdate';
export const GRID_ROW_UPDATE_LOADING = 'gridView@gridRowUpdateLoading';

export const GRID_EXCEL_EXPORTING = 'gridView@gridExcelExporting';
export const GRID_ALL_COLUMNS_EXCEL_EXPORTING =
    'gridView@gridAllColumnsExcelExporting';
export const GRID_ALL_ROWS_EXCEL_EXPORTING =
    'gridView@gridAllRowsExcelExporting';
export const GRID_PDF_PAGE_EXPORTING = 'gridView@gridPdfPageExporting';

export const SET_GRID_REPORT_LOADING = 'gridView@setGridReportLoading';

export const FETCH_ACCUMULATED_DATA = 'gridView@fetchAccumulatedData';
export const SET_ACCUMULATED_DATA = 'gridView@setAccumulatedData';

export const FETCH_PREVIEW_DATA = 'gridView@fetchPreviewData';
export const SET_PREVIEW_DATA = 'gridView@setPreviewData';
export const SET_PREVIEW_LOADING = 'gridView@setPreviewLoading';
export const GRID_EDIT_HANDLE = 'gridView@gridEditHandle';

// fitlers form in gridview
export const SET_GRID_FILTER_RETAILER_CHILDREN =
    'gridView@setGridFilterRetailerChildren';
export const SET_GRID_FILTER_PROPERTY_VALUE =
    'gridView@setGridFilterPropertyValue';
