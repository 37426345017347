import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import { makeSideEffectCallback } from '@libs/makeContext';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { withModalProvider, withModal } from '@libComponents/Modal';
import ContentContainer from '@libComponents/ContentContainer';

// Local
import {
    FABRIC_FORS,
    FABRIC_TYPES,
    COMPOSITIONS,
    DYE_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from '../../redux.datasets';
import { fabricsLoadingSelector } from '../../redux.selectors';
import { FabricsProvider } from './useFabrics';
import Content from './Content';
import Modal from './Modal';
import { useHandleAdd } from './hooks';
import { DOMAIN_NAME } from '../../common';
import Adornment from './Adornment';

const propTypes = {
    name: PropTypes.string,
    includeTc: PropTypes.bool,
    entity: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'fabrics',
    includeTc: false,
    required: false
};

const datasets = [
    FABRIC_FORS,
    FABRIC_TYPES,
    COMPOSITIONS,
    DYE_TYPES,
    FINISHED_GSMS,
    SPECIAL_FINISHES
];

const mapState = state => {
    const fabricsLoading = fabricsLoadingSelector(state);
    const datasetsLoading = datasets.reduce(
        (acm, entity) =>
            acm || datasetLoadingSelector(state, DOMAIN_NAME, entity),
        false
    );

    return {
        loading: datasetsLoading || fabricsLoading
    };
};

const mapDispatch = { fetchDatasets };

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const FabricsContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        input: { value, onChange },
        meta: { form, touched },
        handleModal,
        fetchDatasets,
        loading,
        includeTc,
        entity,
        required
    }) => {
        const [action, setAction] = useState({});

        useEffect(() => {
            fetchDatasets(datasets);
        }, []);

        const initialize = useCallback(
            state => ({
                ...state,
                includeTc,
                fabrics: value || []
            }),
            [value]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const formHandleModal = useMemo(
            () => props => handleModal({ ...props, formName: form, includeTc }),
            [handleModal, form]
        );

        const handleAdd = useHandleAdd({
            handleModal: formHandleModal,
            setAction,
            formName: form
        });

        const validation = useMemo(() => {
            if (touched) {
                if (value.length === 0) {
                    return 'Fabric Details required!';
                }
                const mainBodyFabric = value.some(
                    ({ fabricFor }) => fabricFor === 1
                );
                if (!mainBodyFabric) {
                    return 'Main body fabric required!';
                }
            }
        }, [touched, value]);
        return (
            <FabricsProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title={`Add fabric details`}
                    AdornmentComponent={
                        <Adornment
                            handleAdd={handleAdd}
                            loading={!(value && value.length) && loading}
                            form={form}
                            entity={entity}
                        />
                    }
                    styles={{ container: c.container }}
                    required={required}
                >
                    <Content
                        handleModal={formHandleModal}
                        loading={loading}
                        validationMsg={validation}
                    />
                </ContentContainer>
            </FabricsProvider>
        );
    }
);

FabricsContainer.propTypes = propTypes;
FabricsContainer.defaultProps = defaultProps;

export { FabricsContainer as default, FabricsContainer };
