import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isInvalid, formValueSelector } from 'redux-form';

import {
    withStyles,
    DialogActions as MuiDialogActions
} from '@material-ui/core';

import { Button } from '@libComponents/Button';

import { FORM_NAME } from '../common';
import { useCallback } from 'react';

const propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

const mapState = state => ({
    inValid: isInvalid(FORM_NAME)(state),
    rowIds: formValueSelector(FORM_NAME)(state, 'rowIds')
});

const styles = ({ spacing, typography }) => ({
    button: {
        width: spacing.unit * 12,
        height: spacing.unit * 4,
        fontSize: typography.subtitle1.fontSize
    }
});

const DialogActions = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, onClose, loading, onConfirm, inValid, rowIds }) => {
    const handleConfirm = useCallback(() => {
        onConfirm(rowIds);
    }, [rowIds]);

    return (
        <MuiDialogActions>
            <Button
                className={c.button}
                color='error'
                onClick={onClose}
                disabled={loading}
            >
                Deny
            </Button>
            <Button
                className={c.button}
                onClick={handleConfirm}
                loading={loading}
                disabled={inValid}
            >
                Confirm
            </Button>
        </MuiDialogActions>
    );
});

DialogActions.propTypes = propTypes;

export default DialogActions;
