import React from 'react';
import { string, number, bool } from 'prop-types';
import clsx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles, Typography } from '@material-ui/core';
//libs
import { datasetLoadingSelector } from '@libs/datasets';
import PaginatedSelectBase from '@baseComponents/PaginatedSelect';
import { composeClsx } from '@libs/materialUI';
import { Checkbox } from '@formComponents';

//local
import { DOMAIN_NAME } from 'orders/common';

const propTypes = {
    serial: number.isRequired,
    domain: string.isRequired,
    entity: string.isRequired,
    shouldRenderSameStyleCheckbox: bool.isRequired,
    name: string,
    label: string,
    placeholder: string,
    disabled: bool,
    required: bool
};

const defaultProps = {
    serial: 1,
    name: 'sample',
    label: '',
    placeholder: 'Select Sample Reference',
    disabled: false,
    required: true,
    domain: DOMAIN_NAME
};

const styles = ({ spacing, palette, typography }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: `${spacing.unit * 20}px auto`,
        minHeight: spacing.unit * 8
    },
    serial: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5cd3b4',
        color: '#fff',
        fontSize: spacing.unit * 2.5,

        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6
    },
    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: `${spacing.unit}px ${spacing.unit * 2.5}px`,

        border: '1px solid',
        borderColor: palette.grey[300],
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6
    },
    paginatedcontainer: {
        paddingBottom: 0
    },
    control: {
        height: spacing.unitHeight,
        width: '100%'
    },
    addContainer: {
        width: '80% !important'
    },
    cancellableRoot: {
        right: spacing.unit * 4
    },
    dropdownRoot: {
        '&:before': {
            display: 'none'
        }
    },
    placeholder: {
        paddingRight: spacing.unit * 6
    },
    amendControl: {
        backgroundColor: palette.amend.main
    },
    amendDisabled: {
        backgroundColor: `${palette.amend.dark} !important`
    },
    amendPlaceholder: {
        color: palette.text.secondary
    },
    checkboxWrapper: {
        marginLeft: 'auto'
    },
    checkboxLabel: {
        fontSize: typography.caption.fontSize,
        color: palette.grey[600]
    },
    checkboxIcon: { width: spacing.unit * 2.5, height: spacing.unit * 2.5 }
});

const mapState = (state, { domain, entity }) => ({
    loading: datasetLoadingSelector(state, domain, entity)
    // amended: selectAmendedField(state, name)
});

const OverlaySelectSample = compose(
    connect(mapState),
    withStyles(styles)
)(
    ({
        serial: serialNo,
        classes: {
            container,
            serial,
            selectContainer,
            paginatedcontainer,
            control,
            addContainer,
            placeholder: placeholderClass,
            amendControl,
            amendDisabled,
            amendPlaceholder,
            checkboxLabel,
            checkboxIcon,
            checkboxWrapper,
            ...restClasses
        },
        amended,
        shouldRenderSameStyleCheckbox,
        ...restProps
    }) => {
        const props = {
            isCancellable: true,
            styles: composeClsx({
                classes: {
                    ...restClasses,
                    container: paginatedcontainer,
                    control: clsx(control, amended && amendControl),
                    disabled: amended && amendDisabled,
                    placeholder: clsx(
                        placeholderClass,
                        amended && amendPlaceholder
                    )
                },
                styles
            }),
            ...restProps
        };
        return (
            <div className={container}>
                <Typography className={serial}>{serialNo}</Typography>
                <div className={selectContainer}>
                    <PaginatedSelectBase {...props} />
                </div>
                <div></div>
                {shouldRenderSameStyleCheckbox && (
                    <div className={checkboxWrapper}>
                        <Checkbox
                            name='hasSameStyle'
                            label='I want same style in all packs'
                            styles={{
                                label: checkboxLabel,
                                icon: checkboxIcon
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
);

OverlaySelectSample.propTypes = propTypes;
OverlaySelectSample.defaultProps = defaultProps;

export default OverlaySelectSample;
