import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import { makeSideEffectCallback } from '@libs/makeContext';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';
import { withModalProvider, withModal } from '@libComponents/Modal';
import ContentContainer from '@libComponents/ContentContainer';

// Local
import {
    DISPATCH_SIZE,
    DISPATCH_TYPE,
    SAMPLE_FACTORY,
    SEASONS
} from '../../../../redux.datasets';
import { isSeasaltSelector } from '../../../../redux.selectors';
import { DispatchDetailsProvider } from './useDispatchDetails';
import { useHandleAdd } from './hooks';
import { DOMAIN_NAME, FORM_NAME } from '../../../common';
import required from './validation';
import Adornment from './Adornment';
import DispatchGrid from './DispatchGrid';
import Modal from './Modal';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'dispatchDetails',
    required: true,
    validate: required
};

const datasets = [
    { entity: DISPATCH_TYPE, domain: DOMAIN_NAME },
    { entity: DISPATCH_SIZE, domain: DOMAIN_NAME },
    { entity: SAMPLE_FACTORY, domain: DOMAIN_NAME },
    { entity: SEASONS, domain: DOMAIN_NAME }
];

const mapState = state => {
    const retailer = formValueSelector(FORM_NAME)(state, 'retailer');
    const datasetsLoading = datasets.reduce(
        (acm, { domain, entity }) =>
            acm || datasetLoadingSelector(state, domain, entity),
        false
    );

    return {
        loading: datasetsLoading,
        isSeasalt: isSeasaltSelector(state),
        retailer
    };
};

const mapDispatch = { fetchDatasets };

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: spacing.unit * 5
    }
});

const SampleDispatchDetailsContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Modal
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        input: { value, onChange },
        meta: { form, touched, error },
        handleModal,
        fetchDatasets,
        loading,
        isSeasalt,
        required,
        retailer
    }) => {
        const [action, setAction] = useState({});

        const initialize = useCallback(
            state => ({
                ...state,
                dispatchDetails: value || []
            }),
            [value]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const formHandleModal = useMemo(
            () => props => handleModal({ ...props, formName: form }),
            [handleModal, form]
        );

        const handleAdd = useHandleAdd({
            handleModal: formHandleModal,
            setAction,
            formName: form
        });

        useEffect(() => {
            fetchDatasets(datasets.map(({ entity }) => entity));
        }, [retailer]);

        return (
            <DispatchDetailsProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title={`Add dispatch details`}
                    required={required}
                    error={touched && error ? error : ''}
                    AdornmentComponent={
                        <Adornment
                            handleAdd={handleAdd}
                            loading={loading}
                            form={form}
                        />
                    }
                    styles={{ container: c.container }}
                >
                    <DispatchGrid
                        handleModal={formHandleModal}
                        loading={loading}
                        isSeasalt={isSeasalt}
                    />
                </ContentContainer>
            </DispatchDetailsProvider>
        );
    }
);

SampleDispatchDetailsContainer.propTypes = propTypes;
SampleDispatchDetailsContainer.defaultProps = defaultProps;

export default SampleDispatchDetailsContainer;
