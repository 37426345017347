import React from 'react';

const NextIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10.979'
            height='12.009'
            viewBox='0 0 10.979 12.009'
        >
            <g
                id='Group_210'
                data-name='Group 210'
                transform='translate(-144.021 -10.998)'
            >
                <path
                    id='Forma_1_copy_29'
                    data-name='Forma 1 copy 29'
                    d='M906.123,618.884a.446.446,0,0,1,0-.6l4.931-5.276-4.931-5.286a.446.446,0,0,1,0-.6.377.377,0,0,1,.56,0l5.2,5.576a.43.43,0,0,1,.116.3.448.448,0,0,1-.116.3l-5.2,5.576A.37.37,0,0,1,906.123,618.884Z'
                    transform='translate(-757 -596)'
                    opacity='0.702'
                />
                <path
                    id='Forma_1_copy_29-2'
                    data-name='Forma 1 copy 29'
                    d='M901.137,618.884a.446.446,0,0,1,0-.6l4.931-5.276-4.931-5.286a.447.447,0,0,1,0-.6.378.378,0,0,1,.56,0l5.2,5.576a.43.43,0,0,1,.116.3.448.448,0,0,1-.116.3l-5.2,5.576A.37.37,0,0,1,901.137,618.884Z'
                    transform='translate(-757 -596)'
                    opacity='0.702'
                />
            </g>
        </svg>
    );
};

export { NextIcon as default, NextIcon };
