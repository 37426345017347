import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Libs
import SelectModel from '@formComponents/SelectModel';
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { DOMAIN_NAME } from '../common';
import { DESIGN_DEPARTMENT } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    formName: PropTypes.string
};

const defaultProps = {
    name: 'designDepartment',
    label: 'Design Dept',
    placeholder: 'Select Design Dept',
    domain: DOMAIN_NAME,
    entity: DESIGN_DEPARTMENT,
    disabled: false,
    required: false
};

const dataSelector = createCachedSelector(
    (state, domain, entity) => entitySelector(state, domain, entity),
    (state, domain, entity, gender) => gender,
    (departments, gender) => {
        const transformDepartment = ({ id, designDepartment }) => ({
            value: id,
            label: designDepartment
        });

        return gender
            ? departments
                  .filter(department => department.genderId === gender)
                  .map(transformDepartment)
            : departments.map(transformDepartment);
    }
)(
    (state, domain, entity, gender) =>
        `gender-wise-designDept-${entity}-${gender}`
);

const mapState = (state, { domain, entity, formName }) => {
    const gender = formValueSelector(formName)(state, 'gender');
    return {
        data: dataSelector(state, domain, entity, gender),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectDesignDepartments = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, formName, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectDesignDepartments.propTypes = propTypes;
SelectDesignDepartments.defaultProps = defaultProps;

export { SelectDesignDepartments as default, SelectDesignDepartments };
