import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import { StepperProvider } from './StepContext';
import StepperContainer from './StepperContainer';
import { makeSideEffectCallback } from '../../@libs/makeContext';

const componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

const propTypes = {
    content: PropTypes.objectOf(componentPropType).isRequired,
    ContainerComponent: componentPropType,
    ContainerProps: PropTypes.object,
    initialState: PropTypes.object,
    storeState: PropTypes.func
};

const defaultProps = {
    ContainerComponent: Fragment,
    ContainerProps: {},
    initialState: {},
    storeState: () => {}
};

const styles = ({ palette, shadows }) => ({
    root: {
        position: 'relative',
        backgroundColor: palette.common.transparent,
        boxShadow: shadows[0]
    }
});

const Stepper = withStyles(styles)(
    ({
        content,
        ContainerComponent,
        ContainerProps,
        initialState,
        storeState
    }) => {
        const [action, dispatch] = useState({});

        useEffect(() => {
            dispatch({ type: 'initialize', payload: initialState });
        }, [content]);

        const contents = useMemo(
            () =>
                Object.entries(content).reduce(
                    (acm, [label, content], idx) => [
                        ...acm,
                        {
                            label,
                            content,
                            touched: idx === 0,
                            valid: false,
                            locked: false
                        }
                    ],
                    []
                ),
            [content]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                contents
            }),
            [contents]
        );

        const middlewareProps = useMemo(
            () => ({
                storeState: makeSideEffectCallback(storeState)
            }),
            [storeState]
        );

        return (
            <StepperProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <StepperContainer
                    {...{ ContainerComponent, ...ContainerProps }}
                />
            </StepperProvider>
        );
    }
);

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
