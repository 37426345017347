import fabricRoutes from './Fabric/routes';
import sampleRoutes from './Sample/routes';
import cadRoutes from './Cads/routes';
import commonSetupRoutes from './CommonSetup/routes';
import orderSetupRoutes from './Order/routes';

export default {
    ...fabricRoutes,
    ...sampleRoutes,
    ...cadRoutes,
    ...commonSetupRoutes,
    ...orderSetupRoutes
};
