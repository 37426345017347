import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';
import Setup from '../Setup';
import RefAndDetails from '../RefAndDetails';
import { TabularView, Frame, Title } from 'components/PDF';
import Others from '../Others';

const styles = () => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    },
    frame: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pendingPrintWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10
    },
    pendingPrintText: {
        color: 'red',
        fontSize: 16
    }
});

const Content = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        setup,
        refAndDetails,
        fabricDetails: { data = [], count = 0 } = {},
        samples,
        others,
        boughtDetails: { data: bdata = [], count: bcount } = {},
        trimDetails: { data: tdata = [], count: tcount } = {},
        docTitle,
        pageTitle
    }) => {
        return (
            <Document title={docTitle}>
                <Page size='A4' style={c.page}>
                    <Title
                        text={pageTitle}
                        style={{ fontSize: 20, borderBottom: 1 }}
                    />
                    <Setup {...setup} />
                    <RefAndDetails {...refAndDetails} />
                    {count > 0 && (
                        <TabularView data={data} title={'Fabric Details'} />
                    )}
                    {!!samples && !!samples.length && (
                        <View style={c.frame}>
                            {samples.map(({ url }, idx) => (
                                <Frame
                                    url={url}
                                    label={idx === 0 ? 'Front' : 'Back'}
                                    key={idx}
                                />
                            ))}
                        </View>
                    )}
                    <Others {...others} />
                    {bcount > 0 && (
                        <TabularView data={bdata} title={'Bought Details'} />
                    )}
                    {tcount > 0 && (
                        <TabularView data={tdata} title={'Trim Details'} />
                    )}
                    {!!refAndDetails.pendingPrint && (
                        <View style={c.pendingPrintWrapper}>
                            <Text style={c.pendingPrintText}>
                                N.B: Please prepare panels only. Style waiting
                                artwork
                            </Text>
                        </View>
                    )}
                </Page>
            </Document>
        );
    }
);

export default Content;
