import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';
import { DOMAIN_NAME } from '../common';
import { INCOMPLETE_CADS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'incompleteReference',
    label: 'Select Incomplete Cads',
    placeholder: 'Select reference',
    disabled: false,
    required: false
};

const SelectIncompleteCads = props => (
    <PaginatedSelect {...props} domain={DOMAIN_NAME} entity={INCOMPLETE_CADS} />
);

SelectIncompleteCads.propTypes = propTypes;
SelectIncompleteCads.defaultProps = defaultProps;

export { SelectIncompleteCads as default, SelectIncompleteCads };
