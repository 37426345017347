import { API_BASE_URL, apiClient } from 'store/config';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit, setSubmitSucceeded } from 'redux-form';
import history from 'helpers/routes/history';
import {
    backneckPublishHelper,
    trimPublishHelper,
    boughtPublishHelper,
    inksPublishHelper,
    inksImagesPublishHelper,
    attachmentsPublishHelper
} from 'helpers';
// import { startSetReportsData } from 'store/actions';
import { selectDevelopmentTypes, selectFabrics } from 'selectors';

// Action Types
import {
    PUBLISH_TO_DATABASE,
    PUBLISH_TO_DATABASE_DRAFT,
    PUBLISH_TO_DATABASE_DRAFT_SUCCESS,
    PUBLISH_TO_DATABASE_HANDOVER,
    RESET_FABRIC_DATA,
    RESET_SAMPLING_DATA
} from 'actionTypes';

// Actions
import { setReportsData } from 'actions';

async function publishToDbOps({ data, isDiscontinued, isHandover }) {
    let xtype = '';

    if (!isDiscontinued && isHandover) {
        xtype = PUBLISH_TO_DATABASE_HANDOVER;
    } else if (!isDiscontinued && !isHandover) {
        xtype = PUBLISH_TO_DATABASE_DRAFT;
    } else if (isDiscontinued && !isHandover) {
        xtype = PUBLISH_TO_DATABASE;
    }

    apiClient.defaults.headers.common['xtype'] = xtype;
    return await apiClient.put(
        API_BASE_URL + 'api/SampleRequestMains/PutSampleAllSave',
        data
    );
}

//workers
function* publishDBEffectSaga({
    payload: {
        formData,
        formData: { graphicReference, retailerId, developmentId },
        reqType
    },
    payload
}) {
    try {
        // handover: isDiscontinued=false, handover=true
        // draft: isDiscontinued=false, handover=false
        // publish: isDiscontinued=true, handover=false
        const isDiscontinued = reqType === 'publish' ? true : false;
        const isHandover = reqType === 'handover' ? true : false;
        const state = yield select();
        const {
            auth: { userId, userName },
            samplingPrint: { pageType, cadImage, inkDetails, inkImages },
            initSamplingPrint: { attachments, backnecks, boughts, trims }
        } = state;

        const devText = selectDevelopmentTypes(state).filter(
            item => item.value === Number(developmentId)
        )[0];
        const fabricsData = yield select(selectFabrics);
        const payloadData = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
            if (!!value) payloadData.append(`SamplePrintSaves.${key}`, value);
        });

        payloadData.append(
            'SamplePrintSaves.DevelopmentText',
            devText['label']
        );
        payloadData.append('SamplePrintSaves.RetailerId', retailerId);
        payloadData.append('SamplePrintSaves.SaveType', pageType.toUpperCase());
        payloadData.append('SamplePrintSaves.isDiscontinued', isDiscontinued);
        payloadData.append('SamplePrintSaves.isHandOver', isHandover);
        payloadData.append('SamplePrintSaves.UserId', userId);
        payloadData.append('SamplePrintSaves.UserName', userName);

        if (!!graphicReference)
            payloadData.append(
                'SamplePrintSaves.cadRefference',
                graphicReference
            );
        if (!!cadImage && cadImage.name !== void 0) {
            payloadData.append('SamplePrintSaves.CadImage', cadImage);
        }

        // AllFabricSaves
        if (fabricsData.length) {
            fabricsData.map((fabric, index) => {
                // Helper won't be needed, publish directly
                //const mappedFabric = fabricPublishHelper(item);
                return Object.keys(fabric).forEach(key => {
                    payloadData.append(
                        `AllFabricSaves[${index}].${key}`,
                        fabric[key]
                    );
                });
            });
        }
        // AllBackNeckSaves
        if (backnecks.length) {
            backnecks.map((item, index) => {
                const mappedBackneck = backneckPublishHelper(item);
                return Object.keys(mappedBackneck).forEach(key => {
                    payloadData.append(
                        `AllBackNeckSaves[${index}].${key}`,
                        mappedBackneck[key]
                    );
                });
            });
        }

        // AllBoughtSaves
        if (boughts.length) {
            boughts.map((item, index) => {
                const mappedBought = boughtPublishHelper(item);
                return Object.keys(mappedBought).forEach(key => {
                    payloadData.append(
                        `AllBoughtSaves[${index}].${key}`,
                        mappedBought[key]
                    );
                });
            });
        }

        // AllTrimsSaves
        if (trims.length) {
            trims.map((item, index) => {
                const mappedTrim = trimPublishHelper(item);
                return Object.keys(mappedTrim).forEach(key => {
                    payloadData.append(
                        `AllTrimsSaves[${index}].${key}`,
                        mappedTrim[key]
                    );
                });
            });
        }

        // AllInkSaves
        if (inkDetails.length) {
            inkDetails.map((item, index) => {
                const mappedInks = inksPublishHelper(item);
                return Object.keys(mappedInks).forEach(key => {
                    payloadData.append(
                        `AllInkSaves[${index}].${key}`,
                        mappedInks[key]
                    );
                });
            });
        }

        // AllInkImageSaves
        if (inkImages.length) {
            const { inkId, files } = inksImagesPublishHelper(inkImages);

            inkId.map(item => {
                return payloadData.append('AllInkImageSaves.InkId[]', item);
            });
            files.map(item => {
                return payloadData.append('AllInkImageSaves.Files', item);
            });
        }

        // AllSamplePrintAttachmentSaves
        if (attachments.length) {
            const {
                attachmentId,
                attachments: files
            } = attachmentsPublishHelper(attachments);

            attachmentId.map(item => {
                return payloadData.append(
                    'AllSamplePrintAttachmentSaves.AttachmentId[]',
                    item
                );
            });
            files.map(item => {
                return payloadData.append(
                    'AllSamplePrintAttachmentSaves.attachments',
                    item
                );
            });
        }

        yield put(startSubmit('SamplingPrintForm'));

        const res = yield call(publishToDbOps, {
            data: payloadData,
            isDiscontinued,
            isHandover
        });

        if (res.status === 200 || res.status === 204) {
            yield put(stopSubmit('SamplingPrintForm'));
            yield put(setSubmitSucceeded('SamplingPrintForm'));
            let message = 'Successful!';
            if (reqType === 'handover') {
                message = 'Handover successful!';
                yield put({
                    type: RESET_SAMPLING_DATA
                });
            } else if (reqType === 'publish') {
                message = 'Published successfully!';
                yield put({
                    type: RESET_SAMPLING_DATA
                });
            } else if (reqType === 'draft') {
                console.log('its draft');
                message = 'Draft saved successfully!';
                yield put({
                    type: PUBLISH_TO_DATABASE_DRAFT_SUCCESS,
                    payload: res.data
                });
            }

            // REPORT
            // ======
            if (reqType !== 'draft') {
                const {
                    data: {
                        data: { pdf, xls, attachments: sentAttachments }
                    }
                } = res;

                const attachments = sentAttachments.map(
                    ({ attachmentId, imageName }) => ({
                        id: attachmentId,
                        fileName: imageName
                    })
                );

                const reportsData = {
                    module: 'sample',
                    moduleRef: payload.formData.sampleReference,
                    pdfUrl: pdf[0].pdfUrl,
                    pdfReportId: pdf[0].reportId,
                    xlsxUrl: xls[0].xlsUrl,
                    xlsxReportId: xls[0].reportId,
                    attachments
                };

                yield put(setReportsData(reportsData));
                history.push('/sampling/print/reports');
            }
        }
    } catch (err) {
        yield put(stopSubmit('SamplingPrintForm'));
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* resetFabricEffectSaga() {
    try {
        yield put({
            type: RESET_FABRIC_DATA
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const commonWatchers = function*() {
    yield takeLatest(PUBLISH_TO_DATABASE, publishDBEffectSaga);
    yield takeLatest(RESET_FABRIC_DATA, resetFabricEffectSaga);
};

export { commonWatchers as default, commonWatchers };
