export default ({ palette, spacing, typography }) => ({
    head: {
        backgroundColor: palette.grey[400]
    },
    row: {
        height: spacing.unit * 5
    },
    cell: {
        color: palette.common.black,
        fontSize: typography.subtitle1.fontSize,
        fontWeight: typography.fontWeightBold
    },
    emptyData: {
        width: '100%',
        textAlign: 'center',
        margin: spacing.unit,
        color: palette.grey[400],
        borderBottom: 'none'
    }
});
