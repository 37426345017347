import React from 'react';

import GridCellContent from '../../../../GridCellContent';

const CustomCellRenderer = ({ column, row }) => {
    const customCell = column.render({ column, row });
    if (typeof customCell === 'string') {
        return <GridCellContent content={customCell} />;
    }
    return customCell;
};

export default React.memo(CustomCellRenderer);
