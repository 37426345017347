import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SinglePageIcon = props => {
    const fill = props.fill || '#3D3D3D';
    return (
        <SvgIcon
            {...props}
            x='0px'
            y='0px'
            viewBox='0 0 539.000000 648.000000'
        >
            <g
                transform='translate(0.000000,648.000000) scale(0.100000,-0.100000)'
                fill={fill}
                stroke='none'
            >
                <path
                    d='M0 3240 l0 -3240 2695 0 2695 0 0 3240 0 3240 -2695 0 -2695 0 0
-3240z m3930 1510 l0 -110 -1385 0 -1385 0 0 110 0 110 1385 0 1385 0 0 -110z
m-640 -1005 l0 -105 -1065 0 -1065 0 0 105 0 105 1065 0 1065 0 0 -105z m0
-1005 l0 -110 -1065 0 -1065 0 0 110 0 110 1065 0 1065 0 0 -110z m640 -1010
l0 -110 -1385 0 -1385 0 0 110 0 110 1385 0 1385 0 0 -110z'
                />
            </g>
        </SvgIcon>
    );
};
export { SinglePageIcon as default, SinglePageIcon };
