import React from 'react';
import PropTypes from 'prop-types';

import { SelectModel } from '@formComponents';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'batchConfirmation',
    label: 'Batch Confirmation',
    placeholder: 'Select Batch Confirmation',
    disabled: false,
    required: false
};

const SelectBatchConfirmation = props => <SelectModel {...props} />;

SelectBatchConfirmation.propTypes = propTypes;
SelectBatchConfirmation.defaultProps = defaultProps;

export default SelectBatchConfirmation;
