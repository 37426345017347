import React from 'react';
import { string, number, bool, func, oneOfType, object } from 'prop-types';
import clsx from 'classnames';

import { withStyles, InputBase, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const propTypes = {
    value: oneOfType([string, number]),
    type: string.isRequired,
    onChange: func.isRequired,
    isCancellable: bool,
    handleClearValue: function(props, propName, componentName) {
        if (props.isCancellable) {
            if (!props[propName] || typeof props[propName] !== 'function') {
                return new Error(
                    `The prop '${propName}' is required and must be a funciton when 'isCancellable' is true in '${componentName}'.`
                );
            }
        }
    },
    styles: object,
    placeholder: string
};

const defaultProps = {
    isCancellable: false
};

const styles = ({ palette, shape, typography }) => ({
    gridInputRoot: {
        width: '100%',
        height: '100%',

        backgroundColor: 'inherit',
        border: `1px solid`,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        '&:hover': {
            borderColor: palette.grey[400]
        },
        fontSize: typography.caption.fontSize
    },
    gridInput: {
        '-moz-appearance': 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    gridInputSuccess: {
        border: `1px solid !important`,
        borderColor: `${palette.secondary.main} !important`,
        '&:hover': {
            borderColor: `#18191a !important`
        }
    },
    gridInputFocused: {
        border: `1px solid`,
        borderColor: `${palette.grey[500]} !important`,
        '&:hover': {
            borderColor: `${palette.grey[700]} !important`
        }
    },
    gridInputIconRoot: {
        width: '100%',
        color: palette.grey[700],
        cursor: 'pointer',
        '&:hover': {
            color: palette.error.main
        }
    }
});

const GridInput = withStyles(styles)(
    ({
        classes: c,
        value,
        type,
        isCancellable,
        handleClearValue,
        styles: { root } = {},
        ...props
    }) => {
        return (
            <InputBase
                classes={{
                    root: clsx(
                        c.gridInputRoot,
                        root,
                        value && c.gridInputSuccess
                    ),
                    focused: c.gridInputFocused,
                    input: c.gridInput
                }}
                value={value}
                type={type}
                endAdornment={
                    isCancellable && (
                        <InputAdornment position='end'>
                            <CloseIcon
                                classes={{
                                    root: c.gridInputIconRoot
                                }}
                                onClick={handleClearValue}
                            />
                        </InputAdornment>
                    )
                }
                {...props}
            />
        );
    }
);

GridInput.propTypes = propTypes;
GridInput.defaultProps = defaultProps;

export { GridInput as default, GridInput };
