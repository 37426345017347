import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const FabricDevelopmentReducer = state => state;

const [
    FabricDevelopmentProvider,
    useFabricDevelopmentState,
    useFabricDevelopmentDispatch,
    useSampleSend
] = makeContext(FabricDevelopmentReducer, initialState, {
    name: 'FabricDevelopment'
});

export {
    useSampleSend as default,
    FabricDevelopmentProvider,
    useFabricDevelopmentState,
    useFabricDevelopmentDispatch
};
