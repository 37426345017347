import { useCallback, useMemo } from 'react';
import { LABELS } from './common';
import Upload from './content/Upload';
import Complete from './content/Complete';

import useStepperHandle from '@libHooks/useStepperHandle';

const useContent = () => {
    return useMemo(() => {
        return {
            [LABELS.upload]: Upload,
            [LABELS.complete]: Complete
        };
    }, []);
};

const useHandles = ({ dispatch, activeStep, publish }) => {
    const amountOfSteps = 2;

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, [dispatch]);

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        activeStep,
        publish
    });

    const handleNext = useCallback(() => {
        handleStepper();
    }, [handleStepper]);

    return [handleNext, reset];
};

export { useContent, useHandles };
