import GreenTick from 'components/Global/assets/img/green-tick.svg';

export default theme => ({
    loader: {
        color: '#ffffff',
        position: 'absolute'
    },
    gridContainer: {
        padding: 20
    },
    disabledCheckboxLabel: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    }
});
