import { formValueSelector, change } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import { renameProperties } from '@libs/object';
import composeApiPayload from '@libs/composeApiPayload';
import { normalizeDate } from '@baseComponents/Date';

import { userIdSelector } from 'auth/redux.selectors';

// Domain
import { FORM_NAME } from 'orders/Order/common';

// Local
import { generateReferenceSchema } from './orderPublishWorker/schemas';
import { setOrderReferenceLoading } from 'orders/redux.actions';

export default function*() {
    try {
        const [userId, { noOfPacks, ...values }] = yield all([
            select(userIdSelector),
            select(
                formValueSelector(FORM_NAME),
                'type',
                'isTBC',
                'retailer',
                'noOfPacks',
                'sampleRefs'
            )
        ]);

        const payload = renameProperties(
            {
                userId,
                noOfPacks,
                ...values
            },
            {
                type: 'garmentsTypeId',
                isTBC: 'isTbcSample',
                retailer: 'retailerId',
                noOfPacks: 'orderPack',
                sampleRefs: 'sampleRef'
            }
        );

        yield put(setOrderReferenceLoading(true));
        const {
            data: {
                departmentId,
                orderSupplierRef,
                orderVersionNo,
                dateOrderAmended,
                dateOrderPlaced,
                id
            }
        } = yield call(apiRequest, {
            method: 'post',
            url: 'OrderMains/GenerateOrder',
            data: composeApiPayload(payload, generateReferenceSchema)
        });
        yield put(setOrderReferenceLoading(false));
        yield put(change(FORM_NAME, 'id', id));
        yield put(change(FORM_NAME, 'department', departmentId));
        yield put(change(FORM_NAME, 'echoOrderNo', orderSupplierRef));
        yield put(change(FORM_NAME, 'versionNo', Number(orderVersionNo)));
        yield put(
            change(
                FORM_NAME,
                'orderAmendedDate',
                normalizeDate(dateOrderAmended)
            )
        );
        yield put(
            change(FORM_NAME, 'orderPlacedDate', normalizeDate(dateOrderPlaced))
        );
    } catch (error) {
        yield put(setOrderReferenceLoading(false));
        console.log('Error while generating reference', error);
    }
}
