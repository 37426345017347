import React from 'react';
import { withStyles } from '@material-ui/core';

import { CloseIcon } from '@assets/Icons';

const styles = () => ({
    container: { display: 'flex', alignItems: 'center' },
    icon: {
        border: 'none',
        backgroundColor: 'inherit'
    }
});

const ActionsAdornment = withStyles(styles)(({ classes: c, closeModal }) => {
    return (
        <div className={c.container}>
            <CloseIcon className={c.icon} onClick={closeModal} />
        </div>
    );
});

export default ActionsAdornment;
