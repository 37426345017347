import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Libs
import { GridItem } from '@formComponents';

import BreakdownChart from './BreakdownChart';
import NoDataMessage from '../../NoDataMessage';
import { useResponsivePieChartSize } from '../../hooks';
import { useDetailsBreakdownChartState } from '../useDetailsBreakdownChart';

const radiuses = {
    '2x1': {
        xs: {
            outerRadius: 80,
            innerRadius: 20
        },
        sm: {
            outerRadius: 110,
            innerRadius: 30
        },
        md: {
            outerRadius: 150,
            innerRadius: 40
        },
        lg: {
            outerRadius: 200,
            innerRadius: 60
        }
    },
    '1x1': {
        sm: {
            outerRadius: 180,
            innerRadius: 50
        },
        md: {
            outerRadius: 220,
            innerRadius: 65
        },
        lg: {
            outerRadius: 260,
            innerRadius: 80
        }
    }
};

const styles = ({ spacing, transitions }) => ({
    container: {
        width: '100%',
        minHeight: spacing.unit * 20
    },
    gridItem: {
        padding: spacing.unit * 1.5,
        transition: transitions.create('flex-basis', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.longer
        })
    }
});

const Content = withStyles(styles)(({ classes: c }) => {
    const { data, chartSettings } = useDetailsBreakdownChartState();
    const [screen, ref] = useResponsivePieChartSize();
    const view = chartSettings.view;

    return (
        <div ref={ref} className={c.container}>
            {data && Object.keys(data).length > 0 ? (
                <Grid container>
                    {Object.entries(data).map(
                        ([key, value]) =>
                            chartSettings[key] !== false && (
                                <GridItem
                                    key={key}
                                    md={view === '2x1' ? 6 : 12}
                                    xs={12}
                                    className={c.gridItem}
                                >
                                    <BreakdownChart
                                        chartName={key}
                                        data={value}
                                        screen={view === '1x1' ? 'lg' : screen}
                                        cellSettings={
                                            chartSettings.cellSettings[key] ||
                                            {}
                                        }
                                        {...radiuses[view][screen]}
                                    />
                                </GridItem>
                            )
                    )}
                </Grid>
            ) : (
                <NoDataMessage />
            )}
        </div>
    );
});

export default Content;
