import React from 'react';
import { string } from 'prop-types';
import { View } from '@react-pdf/renderer';

import { Field } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    fabricReference: string.isRequired,
    versionName: string.isRequired,
    developmentType: string.isRequired,
    boughtReference: string
};

const styles = () => ({
    container: {
        flexDirection: 'row'
    }
});

const FabricReferencePDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        fabricReference,
        versionName,
        developmentType,
        boughtReference
    }) => {
        return (
            <View style={c.container}>
                <Field
                    label='Fabric Reference'
                    value={fabricReference}
                    style={{ width: '25%' }}
                />
                <Field
                    label='Version No'
                    value={versionName}
                    style={{ width: '15%' }}
                />
                <Field
                    label='Development Type Sent'
                    value={developmentType}
                    style={{ width: '30%' }}
                />
                <Field
                    label='Bought Reference'
                    value={boughtReference}
                    style={{ width: '25%' }}
                    required={false}
                />
            </View>
        );
    }
);

FabricReferencePDFContent.propTypes = propTypes;

export default FabricReferencePDFContent;
