import { change } from 'redux-form';
import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import {FORM_NAME, INCOMPLETE_PRINT_ROUTE_ID} from '../../../Print/common';
import {getIncompletePrintData} from "../../../redux.actions";

export default function*({
    mode,
    sampleReference
}) {
    if (mode !== 'incomplete') {
        yield put(setRedirect(true));
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(
        setSnack({
            message: `Saved draft: ${sampleReference}`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(INCOMPLETE_PRINT_ROUTE_ID)); // Route key changed
    yield put(getIncompletePrintData(sampleReference));
}
