export const PDF_WIDTH = 960;
export const PDF_HEIGHT = 550;
export const PAGE_PADDING_TOP = 24;
export const PAGE_PADDING_BOTTOM = 24;
export const PAGE_PADDING_LEFT = 30;
export const PAGE_PADDING_RIGHT = 30;
export const A3 = {
    PORTRAIT: {
        WIDTH: 842,
        HEIGHT: 1191
    },
    LANDSCAPE: {
        WIDTH: 1191,
        HEIGHT: 842
    }
};
export const FONT_FAMILY_NORMAL = 'Times-Roman';
export const FONT_FAMILY_BOLD = 'Times-Bold';
export const FONT_FAMILY_ITALIC = 'Times-Italic';
