import React from 'react';
import { string } from 'prop-types';
import { withStyles } from '@material-ui/core';

import { useProductSummary } from './hooks';
import Row from './Row';
import Image from './Image';

const styles = ({ spacing }) => ({
    summary: {
        width: `calc(100% - ${spacing.unit * 2}px)`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});

const propTypes = {
    sampleRef: string.isRequired,
    developmentNo: string,
    description: string,
    specRef: string,
    fitStatus: string,
    frontImage: string
};

const ExpansionPanelSummary = withStyles(styles)(
    ({ classes: c, ...summary }) => {
        const sortedSummary = useProductSummary(summary);
        return (
            <div className={c.summary}>
                {sortedSummary.map(({ label, value }, idx) =>
                    label ? (
                        <Row
                            label={label}
                            key={`summary-header-${idx}`}
                            value={value}
                        />
                    ) : (
                        <Image url={value} key={`summary-header-${idx}`} />
                    )
                )}
            </div>
        );
    }
);

ExpansionPanelSummary.propTypes = propTypes;

export default ExpansionPanelSummary;
