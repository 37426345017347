import { formValueSelector } from 'redux-form';
import { select, call } from 'redux-saga/effects';

// Domain
import { FORM_NAME } from '../../../SealSample/common';
import generatePreproductionReferenceWorker from '../generateSealSampleReferenceWorker';

export default function*({ mode, handleLoading }) {
    const { qcReference, id } = yield select(
        formValueSelector(FORM_NAME),
        'qcReference',
        'id'
    );
    if (mode === 'new' && !qcReference) {
        const { qcReference, id } = yield call(
            generatePreproductionReferenceWorker
        );
        yield call(handleLoading);
        return { qcReference, id };
    } else {
        yield call(handleLoading);
        return { qcReference, id };
    }
}
