import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid, withStyles } from '@material-ui/core';

const propTypes = {
    image: PropTypes.string
};

const defaultProps = {};

const styles = ({ palette, spacing }) => ({
    root: {
        height: spacing.unit * 14,
        paddingTop: spacing.unit * 1.5,
        paddingBottom: spacing.unit * 1.5
    },
    imageContainer: {
        height: '100%',
        paddingRight: spacing.unit * 0.75,
        paddingLeft: spacing.unit * 0.75
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        backgroundColor: palette.background.main,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    }
});

const ImageRow = withStyles(styles)(({ classes: c, image, ...props }) => {
    return (
        <Grid container className={c.root}>
            {image && (
                <Grid className={c.imageContainer} item md={4}>
                    <img className={c.image} src={image} alt={`Bought`} />
                </Grid>
            )}
        </Grid>
    );
});

ImageRow.propTypes = propTypes;
ImageRow.defaultProps = defaultProps;

export default ImageRow;
