import { select, call } from 'redux-saga/effects';

// Libs
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { composeLoadingWorker } from '@libs/publishWorkers';
import { publishFailedWorker } from '@libs/publishWorkers';

import getPublishPayloadWorker from './getPublishPayloadWorker';
import savedDraftWorker from './savedDraftWorker';
import reportUploadAndMailSendWorker from './reportUploadAndMailSendWorker';
import { publishSchema } from './schema';
import { sampleDispatchFormSelector } from '../../../redux.selectors';

// Workers
export default function*(action) {
    const {
        meta: { handleComplete = () => {}, saveDraft = false },
        payload: {
            mode,
            closePreviewModal = () => {},
            closeMailModal = () => {}
        }
    } = action;

    const handleLoading = yield call(composeLoadingWorker, {
        assets: [],
        mode
    });
    const { dispatchReference, versionNo = 1 } = yield select(
        sampleDispatchFormSelector,
        'dispatchReference',
        'versionNo'
    );

    try {
        const payload = yield call(getPublishPayloadWorker);
        const version = mode === 'amend' ? versionNo + 1 : versionNo;

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinued: !saveDraft,
                versionNo: version
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'SampleDispatches/PublishSampleDispatch',
            data: publishData
        });

        /*
            --- After publish
         */

        if (!saveDraft) {
            yield call(reportUploadAndMailSendWorker, {
                dispatchRef: dispatchReference,
                versionNo: version,
                mode,
                handleLoading
            });
        }

        yield call(handleLoading, true);

        if (saveDraft) {
            yield call(savedDraftWorker, {
                mode,
                dispatchReference
            });
        }
        closeMailModal();
        closePreviewModal();
        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to ';

        if (saveDraft) actionErrorMessage += 'save';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
