import { SET_AUTH_DATA, SEND_OTP_LOADING, LOGOUT } from './redux.actionTypes';

const initialState = {
    userId: null,
    OTPLoading: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_AUTH_DATA: {
            return { ...state, ...payload };
        }
        case SEND_OTP_LOADING: {
            return { ...state, OTPLoading: payload };
        }
        case LOGOUT: {
            return {
                ...initialState,
                browserId: state.browserId
            };
        }

        default: {
            return state;
        }
    }
};
