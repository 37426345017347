import React, { useMemo } from 'react';
import ImagesRow from './ImagesRow';

const useBoughtDetails = details =>
    useMemo(
        () =>
            Object.entries(details)
                .map(([label, value]) => {
                    switch (label) {
                        case 'images':
                            return {
                                type: 'custom',
                                value: <ImagesRow images={value} />,
                                order: 0
                            };
                        case 'boughtSampleRef':
                            return {
                                label: 'Bought Sample Ref',
                                value,
                                order: 1
                            };
                        case 'boughtBy':
                            return value
                                ? { label: 'Bought By', value, order: 2 }
                                : null;
                        case 'boughtLocation':
                            return value
                                ? { label: 'Bought Location', value, order: 3 }
                                : null;
                        case 'tripReference':
                            return value
                                ? {
                                      label: 'Trip Reference',
                                      value,
                                      order: 4
                                  }
                                : null;
                        case 'boughtDate':
                            return value
                                ? { label: 'Bought Date', value, order: 5 }
                                : null;
                        default:
                            return null;
                    }
                })
                .filter(details => !!details)
                .sort((a, b) => a.order - b.order)
                .map(({ order, ...detail }) => detail),
        [details]
    );

export { useBoughtDetails };
