export default theme => {
    const transform = 'rotate(180deg)';
    return {
        transform: {
            WebkitTransform: transform,
            MozTransform: transform,
            MsTransform: transform,
            OTransform: transform,
            transform,
        },
    };
};
