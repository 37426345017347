import React from 'react';

const PreviousIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11.01'
            height='12.009'
            viewBox='0 0 11.01 12.009'
        >
            <g
                id='Group_211'
                data-name='Group 211'
                transform='translate(-12 -11.993)'
            >
                <path
                    id='Forma_1_copy_29'
                    data-name='Forma 1 copy 29'
                    d='M774.894,608.116a.446.446,0,0,1,0,.6l-4.945,5.276,4.945,5.286a.446.446,0,0,1,0,.6.379.379,0,0,1-.561,0l-5.216-5.576A.429.429,0,0,1,769,614a.447.447,0,0,1,.116-.3l5.216-5.576A.372.372,0,0,1,774.894,608.116Z'
                    transform='translate(-757 -596)'
                    opacity='0.702'
                />
                <path
                    id='Forma_1_copy_29-2'
                    data-name='Forma 1 copy 29'
                    d='M779.894,608.116a.446.446,0,0,1,0,.6l-4.946,5.276,4.946,5.286a.446.446,0,0,1,0,.6.379.379,0,0,1-.561,0l-5.216-5.576A.43.43,0,0,1,774,614a.448.448,0,0,1,.116-.3l5.216-5.576A.372.372,0,0,1,779.894,608.116Z'
                    transform='translate(-757 -596)'
                    opacity='0.702'
                />
            </g>
        </svg>
    );
};

export { PreviousIcon as default, PreviousIcon };
