import React from 'react';
import { View } from '@react-pdf/renderer';
import { string, bool, number } from 'prop-types';

import { Field, Checkbox } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = () => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    type: string.isRequired,
    isTBC: bool,
    designSourceName: string,
    retailer: string.isRequired,
    batchConfirmation: string,
    noOfPacks: number.isRequired
};

const defaultProps = {
    isTBC: false
};

const Setup = withPDFStyles(styles)(
    ({ classes: c, type, isTBC, retailer, batchConfirmation, noOfPacks }) => {
        return (
            <View style={c.container}>
                <Checkbox label={'TBC'} checked={!!isTBC} />
                <Field label={'Gaments Type'} value={type} />
                <Field label={'Retailer'} value={retailer} />
                {!!batchConfirmation && (
                    <Field
                        label={'Batch Confirmation'}
                        value={batchConfirmation}
                    />
                )}
                <Field label={'No Of Packs'} value={noOfPacks} />
            </View>
        );
    }
);

Setup.propTypes = propTypes;
Setup.defaultProps = defaultProps;

export default Setup;
