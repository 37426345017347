import { useContext, useLayoutEffect, useState } from 'react';

import { useStepperState } from '../StepContext';

const useStepPadding = () => {
    const { contents } = useStepperState();
    const [padding, setPadding] = useState(0);

    useLayoutEffect(() => {
        const amountOfSteps = contents.length || 1;

        // Calculates slice of div based on number of steps
        const stepPercentage = 100 / amountOfSteps;

        /*
            Calculates padding percentage
            1/2 due to centering into the step section
            1/2 due to accounting for the steps' internal padding
            Total: 1/4
         */
        setPadding(stepPercentage / 4);
    }, [contents]);
    return { '--padding': `${padding}%` };
};

export { useStepPadding };
