import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import { GridItem, Input, Select } from '@formComponents';
import { testCuttingRules, commonNumberFieldRules } from '../useSchema';

import { DOMAIN_NAME } from '../../common';
import { SEAL_SAMPLE_STATUS } from '../../../redux.datasets';

const styles = () => ({
    container: {
        width: '80%',
        margin: 'auto'
    }
});

const SampleReviewSummary = withStyles(styles)(({ classes: c }) => {
    return (
        <Grid container className={c.container}>
            <GridItem md={4}>
                <Input
                    name='sampleQTY'
                    label='Test Cutting QTY'
                    type='number'
                    required
                    validate={testCuttingRules}
                />
            </GridItem>
            <GridItem md={4}>
                <Select
                    name='sampleReviewStatus'
                    label='Select Sample Status'
                    domain={DOMAIN_NAME}
                    entity={SEAL_SAMPLE_STATUS}
                    required
                />
            </GridItem>
            <GridItem md={4}>
                <Input
                    name='rejectQTY'
                    label='Reject QTY'
                    type='number'
                    validate={commonNumberFieldRules}
                />
            </GridItem>
            <GridItem md={4}>
                <Input
                    name='dhu'
                    label='DHU'
                    type='number'
                    adornment='%'
                    validate={commonNumberFieldRules}
                />
            </GridItem>
            <GridItem md={4}>
                <Input
                    name='tdr'
                    label='TDR'
                    type='number'
                    adornment='%'
                    validate={commonNumberFieldRules}
                />
            </GridItem>
        </Grid>
    );
});

export default SampleReviewSummary;
