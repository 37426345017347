import React from 'react';

import {
    LICENSOR_ROUTE_ID,
    LICENSOR_PROPERTY_ROUTE_ID,
    CAD_TYPE_ROUTE_ID,
    ARTWORK_SOURCE_ROUTE_ID
} from './common';

import Licensor from './Licensor';
import LicensorProperty from './LicensorProperty';
import CadType from './CadType';
import ArtworkSource from './ArtworkSource';

export default {
    [LICENSOR_ROUTE_ID]: () => <Licensor />,
    [LICENSOR_PROPERTY_ROUTE_ID]: () => <LicensorProperty />,
    [CAD_TYPE_ROUTE_ID]: () => <CadType />,
    [ARTWORK_SOURCE_ROUTE_ID]: () => <ArtworkSource />
};
