import { takeLatest, call, put, select } from 'redux-saga/effects';

// Local
import { saveTrimPost } from 'store/selectors';
import { apiRequest } from 'helpers';

// Action Types
import {
    POST_NEW_TRIM_STEP_FIRST,
    POST_NEW_TRIM_STEP_FIRST_SUCCESS
} from 'store/actionTypes';

// Workers
function* postNewTrimCardWorker() {
    const payload = yield select(saveTrimPost);
    const { res } = yield call(apiRequest, {
        url: 'api/TrimCards/PostTrimCardNew',
        method: 'post',
        data: payload,
        transformData: true,
        type: POST_NEW_TRIM_STEP_FIRST
    });

    yield put({
        type: POST_NEW_TRIM_STEP_FIRST_SUCCESS,
        payload: res.data
    });
}

// Watchers
const trimsWatchers = function*() {
    yield takeLatest(POST_NEW_TRIM_STEP_FIRST, postNewTrimCardWorker);
};

export { trimsWatchers as default, trimsWatchers };
