const validateCadVersionSizeAndExtension = versions => {
    if (!versions || !Array.isArray(versions)) {
        return null;
    }

    const MAX_SIZE = 10 * 1024 * 1024; // 10 MB
    const allowedExtensions = ['jpg', 'jpeg', 'png'];

    for (let index = 0; index < versions.length; index++) {
        const version = versions[index];
        const imageNumber = index + 1;

        if (!version.filename || !version.size) {
            continue;
        }

        if (version.size > MAX_SIZE) {
            return `The file "${version.filename}" (image number ${imageNumber}) exceeds the maximum file size of 10 MB.`;
        }

        const fileExtension = version.filename
            .toLowerCase()
            .slice(((version.filename.lastIndexOf('.') - 1) >>> 0) + 2);

        if (!allowedExtensions.includes(fileExtension)) {
            const supportedExtensions = allowedExtensions.join(', ');
            return `The file "${version.filename}" (image number ${imageNumber}) has an unsupported file type. Supported extensions are: ${supportedExtensions}.`;
        }
    }

    return null;
};

export default validateCadVersionSizeAndExtension;
