import React, { Fragment } from 'react';

import ModalContentColumns from './ModalContentColumns';
import ModalContentToolbar from './ModalContentToolbar';

const ManageColumnsModalContent = () => {
    return (
        <Fragment>
            <ModalContentToolbar />
            <ModalContentColumns />
        </Fragment>
    );
};

export default ManageColumnsModalContent;
