import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';

// libs
import Button from '@libComponents/Button';

const propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

const defaultProps = {
    disabled: true
};

const styles = ({ spacing, breakpoints }) => ({
    button: {
        width: 'auto',
        marginRight: spacing.unit,
        [breakpoints.down(770)]: {
            display: 'none'
        }
    },
    outlinedButton: {
        width: 'auto',
        [breakpoints.up(770)]: {
            display: 'none'
        }
    }
});

const DownloadButton = withStyles(styles)(({ classes: c, ...restProps }) => {
    return (
        <>
            <Button color='success' className={c.button} {...restProps}>
                Export PDF
            </Button>
            <Button
                variant='outlined'
                color='success'
                className={c.outlinedButton}
                {...restProps}
            >
                <ArrowDownward />
            </Button>
        </>
    );
});

DownloadButton.propTypes = propTypes;
DownloadButton.defaultProps = defaultProps;

export default DownloadButton;
