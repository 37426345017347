import makeContext from '@libs/makeContext';

const initialState = {
    purchaseOrders: [],
    isNewlook: false,
    queryValue: ''
};

const PurchaseOrdersReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case 'set-po-search': {
            return { ...state, queryValue: payload };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addPurchaseOrder': {
            const { purchaseOrders } = state;
            const { payload } = action;

            reduxFormOnChange([...purchaseOrders, { ...payload, id: 0 }]);

            return action;
        }

        case 'duplicatePurchaseOrder': {
            const { purchaseOrders } = state;
            const { payload: { duplicate, ...restPayload } = {} } = action;

            reduxFormOnChange([...purchaseOrders, { ...restPayload, id: 0 }]);

            return action;
        }
        case 'editPurchaseOrder': {
            const { purchaseOrders } = state;
            const { poNo, values, poPackId } = action.payload;

            const purchaseOrdersAfterEdit = purchaseOrders.map(
                ({ id, ...purchaseOrder }) => ({
                    id,
                    ...(poNo === purchaseOrder.poNo &&
                    poPackId === purchaseOrder.poPackId
                        ? values
                        : purchaseOrder)
                })
            );

            reduxFormOnChange(purchaseOrdersAfterEdit);

            return action;
        }

        case 'deletePurchaseOrder': {
            const { payload } = action;

            reduxFormOnChange(payload);

            return action;
        }

        default:
            return action;
    }
};

const [
    PurchaseProvider,
    usePurchaseOrdersState,
    usePurchaseOrdersDispatch,
    usePurchaseOrders
] = makeContext(PurchaseOrdersReducer, initialState, {
    name: 'purchaseOrders',
    middleware
});

export {
    usePurchaseOrders as default,
    PurchaseProvider,
    usePurchaseOrdersState,
    usePurchaseOrdersDispatch
};
