import reduceReducer from 'reduce-reducers';

// Local
import initialState from './initialState';

// Reducers
import commonReducer from './common';

// Action Types
import { SIGN_OUT } from 'actionTypes';

const stemReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SIGN_OUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

const authReducer = reduceReducer(initialState, stemReducer, commonReducer);

export { authReducer as default, authReducer };
