import {
    SET_FABRICS,
    DUPLICATE_FABRIC,
    EDIT_FABRIC,
    REMOVE_FABRIC
} from 'store/actionTypes';

export const setFabrics = fabrics => ({
    type: SET_FABRICS,
    payload: {
        fabrics: Array.isArray(fabrics) ? fabrics : [fabrics]
    }
});

export const duplicateFabric = fabricIndex => ({
    type: DUPLICATE_FABRIC,
    meta: { fabricIndex }
});

export const editFabric = (fabricIndex, fabricDetails) => ({
    type: EDIT_FABRIC,
    meta: { fabricIndex },
    payload: { fabricDetails }
});

export const removeFabric = fabricIndex => ({
    type: REMOVE_FABRIC,
    meta: { fabricIndex }
});
