import { useCallback, useMemo, useState } from 'react';

import useStepperHandle from '@libHooks/useStepperHandle';

import { LABELS, BASIC_FIELDS, PERMISSION_FIELDS } from './common';
import BasicInformation from './content/BasicInfo';
import Permissions from './content/Permissions';
import Complete from './content/Complete';

const useContent = mode => {
    return useMemo(() => {
        return {
            [LABELS.basicInformation]: BasicInformation,
            [LABELS.permissions]: Permissions,
            [LABELS.complete]: Complete
        };
    }, []);
};

const useHandles = ({ dispatch, mode, activeStep, publish }) => {
    const amountOfSteps = 3;

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, []);

    const handlePublish = useCallback(
        dispatchProps => {
            return useStepperHandle({
                amountOfSteps,
                dispatch,
                mode,
                publish,
                activeStep: amountOfSteps - 2, // Forces the handle to think it's about to publish,
                dispatchProps
            });
        },
        [dispatch]
    );

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        activeStep,
        publish
    });

    const handleNext = useCallback(() => {
        handleStepper();
    }, [mode, activeStep, dispatch, amountOfSteps]);

    return [handleNext, handlePublish, reset];
};

const useContentFields = activeStep => {
    return useMemo(() => {
        return activeStep === 0 ? BASIC_FIELDS : PERMISSION_FIELDS;
    }, [activeStep]);
};

export { useContent, useHandles, useContentFields };
