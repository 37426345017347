import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { withStyles, FormControl } from '@material-ui/core';

import { fieldRequired } from '@libs/reduxForm';

// Local
import containerStyles from './styles';
import selectStyles from './SelectBase/styles';
import SelectBase from './SelectBase';

/*
 * Domain: --
 * Page: --
 * Component: Select
 * Type:
 * Select
 */
const Select = withStyles(containerStyles)(
    class extends Component {
        state = {
            unitHeight: null,
            unitWidth: null,
            iconRight: null
        };

        componentDidMount() {
            const node = node => ReactDOM.findDOMNode(node);
            this.setState({
                unitWidth: node(this.containerRef).offsetWidth,
                unitHeight: node(this.containerRef).offsetHeight,
                iconRight: window
                    .getComputedStyle(node(this.iconRef))
                    .right.slice(0, -2)
            });
        }

        renderSelect = () => {
            const { state, props } = this;
            const { unitHeight, unitWidth, iconRight } = state;
            const {
                classes,
                listMaxNumber = 5,
                validate,
                required,
                ...selectProps
            } = props;
            const SelectComponent = withStyles(
                selectStyles({
                    listMaxNumber,
                    unitHeight,
                    unitWidth,
                    iconRight
                })
            )(SelectBase);

            return (
                unitHeight && (
                    <Field
                        {...selectProps}
                        component={SelectComponent}
                        required={required}
                        validate={
                            !!validate
                                ? validate
                                : required
                                ? fieldRequired
                                : undefined
                        }
                    />
                )
            );
        };

        render() {
            const { state, props, renderSelect } = this;
            const { iconRight, unitHeight } = state;
            const {
                classes: { container, cancellableRoot },
                className,
                styles = {}
            } = props;

            const containerClassName = classNames(
                container,
                styles.control || className
            );

            const iconClassName = classNames(
                cancellableRoot,
                styles.cancellableRoot
            );

            return (
                <Fragment>
                    {Boolean(iconRight) || (
                        <div
                            className={iconClassName}
                            ref={ref => (this.iconRef = ref)}
                        />
                    )}
                    {Boolean(unitHeight) || (
                        <FormControl
                            className={containerClassName}
                            ref={ref => (this.containerRef = ref)}
                        />
                    )}

                    {renderSelect()}
                </Fragment>
            );
        }
    }
);

Select.propTypes = {
    data: PropTypes.array.isRequired
};

export default Select;
