import React from 'react';
import { bool, func } from 'prop-types';

import Checkbox from '@baseComponents/Checkbox';
import { CheckboxPartiallyFilledIcon } from '@assets/Icons';

const propTypes = {
    checked: bool,
    partiallyChecked: bool,
    handleChange: func.isRequired
};

const defaultProps = {
    partiallyChecked: false,
    checked: false,
    handleChange: f => f
};

const GridCheckbox = React.memo(
    ({ checked, partiallyChecked, handleChange, ...rest }) => {
        return (
            <Checkbox
                checked={checked || partiallyChecked}
                onChange={handleChange}
                FilledIcon={
                    partiallyChecked && !checked
                        ? CheckboxPartiallyFilledIcon
                        : undefined
                }
                {...rest}
            />
        );
    }
);

GridCheckbox.propTypes = propTypes;

GridCheckbox.defaultProps = defaultProps;

export default GridCheckbox;
