import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import { useDimensionsObserver } from '@libHooks/useDimensions';
import { withField } from '@libs/reduxForm';
import { makeSideEffectCallback } from '@libs/makeContext';
import { required } from '@libs/validationRules';

import Preview from './Preview';
import Viewer from './Viewer';
import validateCadVersionSizeAndExtension from './validateCadVersionSizeAndExtension';
import { VersionsViewerProvider } from './useVersionsViewer';
import { useVersionsViewerStyle } from './hooks';

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    readOnly: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    disableDrag: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    imageResolver: PropTypes.func,
    errorResolver: PropTypes.func
};

const defaultProps = {
    name: 'versions',
    title: 'Versions Viewer',
    readOnly: false,
    disableRemove: false,
    disableDrag: false,
    imageResolver: image => image,
    errorResolver: error => console.log('Versions viewer error: ', error),
    required: false,
    validate: [required, validateCadVersionSizeAndExtension]
};

const styles = ({ spacing }) => ({
    root: {
        width: '100%',
        maxWidth: spacing.unit * 200,
        display: 'flex',
        paddingLeft: spacing.unit * 3,
        paddingRight: spacing.unit * 3,
        paddingBottom: spacing.unit * 6
    }
});

const VersionsViewer = compose(
    withField(),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: c,
        theme,
        children,
        title,
        readOnly,
        disableRemove,
        disableDrag,
        imageResolver,
        errorResolver,
        input: { value, onChange, onBlur },
        meta: { touched, error },
        required
    }) => {
        const hasError = touched && !!error;
        const [ref, { height }] = useDimensionsObserver({ throttle: 50 });
        const style = useVersionsViewerStyle({ height, theme });

        const initialize = useCallback(
            state => ({
                ...state,
                versions: value,
                readOnly,
                disableRemove,
                disableDrag,
                imageResolver,
                errorResolver,
                hasError,
                error,
                required
            }),
            [
                value,
                readOnly,
                disableRemove,
                disableDrag,
                imageResolver,
                errorResolver,
                hasError,
                error,
                required
            ]
        );

        const handleOnChange = useCallback(
            versions => {
                onBlur();
                onChange(versions);
            },
            [onChange, onBlur]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(handleOnChange)
            }),
            [handleOnChange]
        );

        return (
            <div className={c.root} style={style}>
                <VersionsViewerProvider
                    initialize={initialize}
                    middlewareProps={middlewareProps}
                >
                    <Preview ref={ref} />
                    <Viewer title={title}>{children}</Viewer>
                </VersionsViewerProvider>
            </div>
        );
    }
);

VersionsViewer.propTypes = propTypes;
VersionsViewer.defaultProps = defaultProps;

export { VersionsViewer };
