import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import { ARMHOLES, HEMLINES, LABEL_TYPES, NECKLINES } from 'libs';

// Armholes
export const selectArmholesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[ARMHOLES]
)(() => 'placeholder');

// Hemlines
export const selectHemlinesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[HEMLINES]
)(() => 'placeholder');

// Label Types
export const selectLabelTypesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[LABEL_TYPES]
)(() => 'placeholder');

// Necklines
export const selectNecklinesData = createCachedSelector(
    selectDatasets,
    datasets => datasets[NECKLINES]
)(() => 'placeholder');
