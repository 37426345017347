import React from 'react';
import classnames from 'classnames';
import { withStyles, Typography } from '@material-ui/core';
import { func, string, bool } from 'prop-types';

const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&$disabled': {
            pointerEvents: 'none',
            cursor: 'none'
        }
    },
    title: {
        fontSize: spacing.unit * 1.5,
        color: '#000',
        marginLeft: spacing.unit * 1,
        '&$disabled': {
            color: palette.grey[500]
        }
    },
    disabled: {}
});

const propTypes = {
    icon: func.isRequired,
    title: string.isRequired,
    shouldDisable: bool
};

const Item = withStyles(styles)(
    ({ icon: Icon, title, shouldDisable, classes: c, ...restProps }) => {
        return (
            <div
                className={classnames(c.root, shouldDisable ? c.disabled : '')}
                {...restProps}
            >
                <Icon color={`${shouldDisable ? '#9e9e9e' : ''}`} />
                <Typography
                    className={classnames(
                        c.title,
                        shouldDisable ? c.disabled : ''
                    )}
                >
                    {title}
                </Typography>
            </div>
        );
    }
);

Item.propTypes = propTypes;

export default Item;
