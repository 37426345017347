import React from 'react';

import { PieChart } from '@libComponents/Charts';

import Legend from './Legend';
import useRetailerAggregatedData from './hooks';
import useDynamicColors, { useResponsivePieChartSize } from '../hooks';
import NoDataMessage from '../NoDataMessage';

const radiuses = {
    sm: {
        outerRadius: 150,
        innerRadius: 40
    },
    md: {
        outerRadius: 180,
        innerRadius: 55
    },
    lg: {
        outerRadius: 220,
        innerRadius: 65
    }
};

const SampleOverviewChart = ({ data }) => {
    const retailerAggregatedData = useRetailerAggregatedData(data);
    const colors = useDynamicColors(retailerAggregatedData);
    const [screen, elementRef] = useResponsivePieChartSize();

    if (data.length === 0) {
        return <NoDataMessage />;
    }
    return (
        <div ref={elementRef} style={{ width: '100%' }}>
            <PieChart
                data={retailerAggregatedData}
                colors={colors}
                legendRenderer={Legend}
                contentPadding={160}
                screen={screen}
                {...radiuses[screen]}
            />
        </div>
    );
};

export default SampleOverviewChart;
