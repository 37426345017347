import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm, change } from 'redux-form';

import { Divider, Grid, withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';

// Lib
import {
    GridContainer,
    GridItem,
    Input,
    ImageDnD,
    Checkbox
} from '@formComponents';
import { setReloadAction } from 'utilities/redux.actions';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import isBlobUrl from '@libs/isBlobUrl';

// Other Domain
import { isStyleBottomSelector } from 'common/redux.selectors';
import {
    developmentNameSelector,
    developmentTypesLoadingSelector
} from 'common/redux.selectors';
import {
    validateImageSizeAndExtension,
    validateImageDnDRequired
} from '@libs/validationRules';

// Local
import HeaderAdornment from '../HeaderAdornment';
import { FabricsContainer, SampleSelect } from '../../../components';
import { FORM_NAME, LABELS } from '../../common';
import {
    ARMHOLES,
    HEMLINES,
    LABEL_TYPES,
    NECKLINES,
    SIZE_RANGES,
    WAISTBANDS,
    DEVELOPED_FABRICS_FOR_SHAPE
} from '../../../redux.datasets';
import { useShapeState } from '../../useShape';

const frontImageRules = [
    validateImageDnDRequired,
    validateImageSizeAndExtension
];

const styles = ({ spacing }) => ({
    contentContainer: {
        paddingBottom: spacing.unit * 6
    },
    container: {
        paddingBottom: spacing.unit * 4
    },
    dateRequested: {
        paddingRight: spacing.unit * 0.5
    },
    dateRequiredBy: {
        paddingLeft: spacing.unit * 0.5
    },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    }
});

const mapInputs = {
    front: ({ payload }) => {
        const shouldPrompt =
            payload && payload.hasOwnProperty('url') && isBlobUrl(payload.url);
        const action = change(FORM_NAME, 'front', '');

        return setReloadAction({
            field: 'front',
            shouldPrompt,
            action
        });
    },
    back: ({ payload }) => {
        const shouldPrompt =
            payload.hasOwnProperty('url') && isBlobUrl(payload.url);
        const action = change(FORM_NAME, 'back', '');

        return setReloadAction({
            field: 'back',
            shouldPrompt,
            action
        });
    }
};

const mapState = state => {
    const { developmentNumber, style } = formValueSelector(FORM_NAME)(
        state,
        'developmentNumber',
        'style'
    );

    return {
        developmentTypesLoading: developmentTypesLoadingSelector(state),
        developmentName: developmentNameSelector(
            state,
            'Non Print',
            developmentNumber
        ),
        isStyleBottom: isStyleBottomSelector(state, style)
    };
};

const Details = compose(
    connect(
        mapState,
        null
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, developmentName, developmentTypesLoading, isStyleBottom }) => {
    const { sampleRefLoading } = useShapeState();

    return (
        <Fragment>
            <ContentContainer
                title={LABELS.details}
                styles={{ container: c.contentContainer }}
                AdornmentComponent={<HeaderAdornment />}
            >
                <GridContainer className={c.container}>
                    <GridItem md={12}>
                        <Grid container>
                            <GridItem md={4}>
                                <Input
                                    name='sampleReference'
                                    label='Sample reference'
                                    loading={sampleRefLoading}
                                    disabled
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Input
                                    name='developmentName'
                                    label='Revision No'
                                    staticValue={developmentName}
                                    loading={developmentTypesLoading}
                                    disabled
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Input
                                    name='styleDescription'
                                    label='Style Description'
                                    required
                                />
                            </GridItem>
                        </Grid>
                        <Divider className={c.divider} />

                        <Grid container>
                            <GridItem md={4}>
                                <Input
                                    name='specReference'
                                    label='Spec Reference'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Input
                                    name='specDescription'
                                    label='Spec Description'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Input
                                    name='departmentNumber'
                                    label='Dept No'
                                    placeholder='Enter department'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Input
                                    name='referenceFrom'
                                    label='Reference From'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SampleSelect
                                    entity={SIZE_RANGES}
                                    name='sizeRange'
                                    label='Size Range'
                                    placeholder='Select size range'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SampleSelect
                                    entity={LABEL_TYPES}
                                    name='labelType'
                                    label='Label Type'
                                    placeholder='Select label type'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SampleSelect
                                    entity={ARMHOLES}
                                    name='armhole'
                                    label='Armholes/Cuffs'
                                    placeholder='Select armhole/cuffs'
                                    required
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <SampleSelect
                                    entity={HEMLINES}
                                    name='hemline'
                                    label='Hemline'
                                    placeholder='Select hemline'
                                    required
                                />
                            </GridItem>
                            <GridItem md={4}>
                                {isStyleBottom ? (
                                    <SampleSelect
                                        entity={WAISTBANDS}
                                        name='waistband'
                                        label='Waistband'
                                        placeholder='Select waistband'
                                        required
                                    />
                                ) : (
                                    <SampleSelect
                                        entity={NECKLINES}
                                        name='neckline'
                                        label='Neckline'
                                        placeholder='Select neckline'
                                        required
                                    />
                                )}
                            </GridItem>
                            <GridItem md={8}>
                                <Checkbox
                                    name='pendingPrint'
                                    label='Pending Print'
                                />
                                <Checkbox
                                    name='photoApproval'
                                    label='Request For Photo Approval'
                                />
                            </GridItem>
                        </Grid>
                    </GridItem>
                </GridContainer>
                <FabricsContainer
                    includeTc
                    required
                    entity={DEVELOPED_FABRICS_FOR_SHAPE}
                />
                <Divider className={c.divider} />
                <GridContainer>
                    <GridItem md={6}>
                        <ImageDnD
                            name='front'
                            label='Front'
                            validate={frontImageRules}
                            required
                        />
                    </GridItem>
                    <GridItem md={6}>
                        <ImageDnD
                            name='back'
                            label='Back'
                            validate={validateImageSizeAndExtension}
                        />
                    </GridItem>
                </GridContainer>
            </ContentContainer>
        </Fragment>
    );
});

export default Details;
