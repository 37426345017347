import {
    SET_DATASET_LOADING,
    FETCH_DATASETS,
    SET_DATASET,
    FETCH_PAGINATED_DATASET,
    FETCH_GRID_DATASET,
    FETCH_GRID_PREVIEW_DATA_BY_REF,
    SET_PAGINATED_DATASET,
    SET_GRID_DATASET,
    RESET_DATASETS,
    REFRESH_DATASET
} from './libs.actionTypes';

const setDatasetLoading = ({ entity, domain, loading }) => ({
    type: domain + SET_DATASET_LOADING,
    meta: { entity },
    payload: loading
});

const setDataset = ({ entity, domain, dataset }) => ({
    type: domain + SET_DATASET,
    meta: { entity },
    payload: { dataset }
});

const fetchDatasets = datasets => ({
    type: FETCH_DATASETS,
    payload: Array.isArray(datasets) ? datasets : [datasets]
});

const createFetchPaginatedDataset = ({ domain, entity }) => ({
    pageNumber = 1,
    pageSize = 10,
    filterKey = '',
    filterCategory = '',
    shouldAppend = false
}) => ({
    type: FETCH_PAGINATED_DATASET,
    meta: { domain, entity, shouldAppend },
    payload: {
        pageNumber,
        pageSize,
        filterKey,
        filterCategory
    }
});

const createFetchGridDataset = ({ domain, entity, endpoint }) => payload => ({
    type: FETCH_GRID_DATASET,
    meta: { domain, entity, endpoint },
    payload
});

const createFetchGridPreviewData = ({
    domain,
    entity,
    endpoint
}) => payload => ({
    type: FETCH_GRID_PREVIEW_DATA_BY_REF,
    meta: { domain, entity, endpoint },
    payload
});

const setPaginatedDataset = ({
    domain,
    entity,
    shouldAppend,
    dataset,
    nextPage
}) => ({
    type: domain + SET_PAGINATED_DATASET,
    meta: { entity, shouldAppend },
    payload: { dataset, nextPage }
});

const setGridDataset = ({ domain, entity, ...payload }) => ({
    type: domain + SET_GRID_DATASET,
    meta: { entity },
    payload
});

const resetDatasets = () => ({
    type: RESET_DATASETS
});

const refreshDataset = payload => ({
    type: REFRESH_DATASET,
    payload
});

export {
    setDatasetLoading,
    fetchDatasets,
    createFetchPaginatedDataset,
    setDataset,
    setPaginatedDataset,
    resetDatasets,
    refreshDataset,
    createFetchGridDataset,
    setGridDataset,
    createFetchGridPreviewData
};
