export const generateReferenceSchema = {
    garmentsTypeId: 'string',
    retailerId: 0,
    orderPack: 0,
    sampleRef: ['string'],
    isTbcSample: false,
    userId: 0
};

export const publishSchema = {
    id: 0,
    userId: 0,
    isDiscontinued: false,
    orderVersionNo: 0,
    departmentId: 0,
    orderFactoryRef: 'string',
    factorySl: 0,
    sampleEta: '2022-03-02T09:29:45.601Z',
    styleNo: 'string',
    productionDescription: 'string',
    clientPoNo: 'string',
    orderSeason: 0,
    orderSupplierNo: 'string',
    orderFactory: 0,
    orderProductionSamples: 0,
    kimbelNo: 'string',
    kimbleColour: 'string',
    commodity: 'string',
    nlKind: 'string',
    ukSize: 0,
    buyerSize: 0,
    sysFacNo: 'string',
    isBlankOrder: false,
    isCancel: false,
    isHold: false,
    softPublish: false,
    isSampleAmend: false,
    departmentNo: 'string',
    asosDepartmentName: 'string',
    sectionDescription: 'string',
    subSectionDescription: 'string',
    licensingCompany: 'string',
    packType: 0,
    boxType: 0,
    costingDate: '2022-03-02T09:29:45.601Z',
    costBasedOn: 0,
    htsCode: 'string',
    bdCommission: {
        name: 'string',
        value: 'string'
    },
    otherCommission: {
        name: 'string',
        value: 'string'
    },
    orderComments: 'string',
    specialorderComments: 'string',
    poDetails: [
        {
            id: 0,
            cid: 'string',
            pono: 'string',
            packId: 'string',
            poTypeName: 'string',
            poModeName: 'string',
            paymentTermsId: 0,
            settlementDiscountId: 0,
            poDestinationName: 'string',
            poPackType: 'string',
            exFtyDate: '2022-03-02T09:29:45.601Z',
            fobDate: '2022-03-02T09:29:45.601Z',
            retailPrice: {
                name: 'string',
                value: 'string'
            },
            quantity: 0,
            totalPack: 0,
            discount: 0,
            fabricConsumption: 0,
            yarnCount: 'string',
            yarnPrice: {
                name: 'string',
                value: 'string'
            },
            totalFabricKg: 0,
            totalYarnPrice: 0,
            sizeSet: 'string',
            styleNo: 'string',
            labDetails: [
                {
                    id: 0,
                    sampleRef: 'string',
                    batchRef: 'string',
                    colorName: 0,
                    colorCode: 'string',
                    colorNote: 'string',
                    fobPrice: {
                        name: 'string',
                        value: 'string'
                    },
                    echoPrice: {
                        name: 'string',
                        value: 'string'
                    },
                    licensorFee: {
                        name: 'string',
                        value: 'string'
                    },
                    orderSustainabilityId: 0,
                    compositionId: 0,
                    fabricTypeId: 0,
                    gsmId: 0
                }
            ],
            sizeDetails: [
                {
                    id: 0,
                    sizeName: 'string',
                    quantity: 0,
                    packQuantity: 0,
                    ratio: 0,
                    packRatio: 0,
                    sku: 'string',
                    barCode: 'string'
                }
            ]
        }
    ],

    labelingDetails: [
        {
            id: 0,
            basicTrimId: 0
        }
    ],
    attachments: [
        {
            id: 0,
            sizeOriginal: 'string'
        }
    ]
};

export const orderEmailSchema = {
    userName: 'string',
    toAddress: 'string',
    ccAddress: 'string',
    subject: 'string',
    body: 'string',
    echoOrderNo: 'string'
};
