import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Libs
import SelectModel from '@formComponents/SelectModel';
// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { DOMAIN_NAME } from 'sampling/common';
import { SAMPLE_EMAIL_GROUP } from 'sampling/redux.datasets';

const propTypes = {
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    retailer: PropTypes.number.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'groups',
    label: '',
    placeholder: 'To..',
    disabled: false,
    required: false,
    domain: DOMAIN_NAME,
    entity: SAMPLE_EMAIL_GROUP
};

const dataSelector = createCachedSelector(
    (state, domain, entity) => entitySelector(state, domain, entity),
    (state, domain, entity, retailer) => retailer,
    (groups, retailer) =>
        groups
            .filter(
                ({ retailerId, isHandOver, isFinalPhoto }) =>
                    Number(retailerId) === retailer &&
                    !isHandOver &&
                    !isFinalPhoto
            )
            .map(({ id, emailGroupName }) => ({
                label: emailGroupName,
                value: id
            }))
)(
    (state, domain, entity, retailer) =>
        `email-publish-groups-${entity}-${retailer}`
);

const mapState = (state, { domain, entity, retailer }) => {
    return {
        data: dataSelector(state, domain, entity, retailer),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SampleEmailGroup = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, retailer, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SampleEmailGroup.propTypes = propTypes;
SampleEmailGroup.defaultProps = defaultProps;

export { SampleEmailGroup as default, SampleEmailGroup };
