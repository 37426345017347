export const FABRIC_DEVELOPMENT_REFERENCE_GENERATE =
    'fabric@fabric-development-reference-generate';
export const SET_FABRIC_DEVELOPMENT_REFERENCE_GENERATE_LOADING =
    'fabric@set-fabric-development-reference-generate-loading';
export const GET_COPY_FABRIC_DATA = 'fabric@get-copy-fabric-data';
export const COPY_ORIGINAL_FABRIC_DATA = 'fabric@copy-original-fabric-data';
export const SET_MATCH_COLOR_CHILDREN = 'fabric@set-match-color-children';
export const GET_FABRIC_DEVELOPMENT_REFERENCE_DATA =
    'fabric@get-fabric-development-reference-data';
export const FABRIC_DEVELOPMENT_PUBLISH = 'fabric@fabric-development-publish';
export const ROUTE_TO_FABRIC_DEVELOPMENT = 'fabric@route-to-fabric-development';
export const DESTROY_FABRIC_DEVELOPMENT_FORM =
    'fabric@destroy-fabric-development-form';
export const SET_FABRIC_DEVELOPMENT_MAIL_RECEIVERS =
    'fabric@set-fabric-development-mail-receivers';
export const SET_FABRIC_DEVELOPMENT_MAIL_BODY =
    'fabric@set-fabric-development-mail-body';
export const FABRIC_REPORT_MAIL_SEND = 'fabric@fabric-report-mail-send';
export const FABRIC_EXPORT_XL_DATA = 'fabric@fabric-export-xl-data';
export const SET_FABRIC_EXPORT_XL_DATA_LOADING =
    'sampling@set-fabric-export-xl-data-loading';
