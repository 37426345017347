import _ from 'lodash/array';
import { CHANGE } from 'redux-form/lib/actionTypes';

const createMiddleware = () => {
    const listeners = [];

    return {
        addListeners: newListeners => listeners.push(...newListeners),
        removeListeners: oldListeners =>
            _.remove(listeners, ({ id }) => {
                return oldListeners.includes(id);
            }),
        middleware: listener(listeners)
    };
};

// Function to compare a triggered field with a map field that may contain an asterisk wildcard
const compareFieldNameWithAsterisk = (triggeredField, mapField) => {
    // Check if the map field contains an asterisk wildcard
    if (mapField.includes('*')) {
        // Regular expression to extract the index inside square brackets
        const indexExtractRegex = /\[(.*?)\]/;
        // Match the index inside square brackets in the triggered field
        const indexRegexMatch = triggeredField.match(indexExtractRegex);

        // Check if a match is found and if the match array has more than one element
        if (indexRegexMatch && indexRegexMatch.length > 1) {
            // Extract the index from the match array
            const index = indexRegexMatch[1];
            // Replace the asterisk wildcard in the map field with the extracted index
            const escapedMapField = mapField.replace(/\*/g, index);

            // Check if the escaped map field matches the triggered field
            if (escapedMapField === triggeredField) {
                return true; // Return true if there is a match
            }
        }
    }
    return false;

    // Return false if no match is found or if the map field does not contain an asterisk wildcard
};

const listener = listeners => ({ dispatch, getState }) => next => action => {
    if (action.type === CHANGE) {
        const inListeners = listeners.filter(({ form, field }) => {
            return (
                form === action.meta.form &&
                (field === action.meta.field ||
                    compareFieldNameWithAsterisk(action.meta.field, field))
            );
        });
        if (inListeners.length) {
            const actionObject = inListeners[0].action(
                {
                    ...action,
                    meta: { ...action.meta, mode: inListeners[0].mode }
                },
                getState
            );
            // Checks if its a real action
            if (actionObject) dispatch(actionObject);
        }
    }

    return next(action);
};

export { createMiddleware as default, createMiddleware };
