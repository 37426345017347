import React, { useMemo } from 'react';

import { ORDER_STATUS } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';
import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Status = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Status', canSort: true, sortKey: 'orderStatus' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'STATUS SETUP',
        domain: DOMAIN_NAME,
        entity: ORDER_STATUS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Status as default, Status };
