import { createSelector } from 'reselect';

import {
    number,
    minValue,
    fraction,
    validateSpecialCharacters
} from '@libs/validationRules';

import { NINETY_PERCENT_ID } from 'orders/common';

// Original reducer function (used inside the selector)
const sizeDetailsReducer = (accumulator, { quantity, sku }) => {
    return {
        quantitySummary: accumulator.quantitySummary + Number(quantity),
        hasSkuNoQuantity: accumulator.hasSkuNoQuantity || (sku && !quantity)
    };
};

// Selector to calculate quantitySummary and hasSkuNoQuantity using reselect
const sizeDetailsSelector = createSelector(
    values => values, // Input selector: Selector for the 'values' array
    values => {
        // The actual calculation using reduce (same as before)
        const { quantitySummary, hasSkuNoQuantity } = values.reduce(
            sizeDetailsReducer,
            {
                quantitySummary: 0,
                hasSkuNoQuantity: false
            }
        );

        return { quantitySummary, hasSkuNoQuantity };
    }
);

export const validateSizeDetails = (
    values,
    { poNo, orderQuantity, retailer }
) => {
    if (!values || values.length === 0) {
        const error = {};
        error.sizeDetails = 'Size breakdown table is required';
        return error;
    }
    if (!poNo) return null;

    // Using the selector to get quantitySummary and hasSkuNoQuantity
    const { quantitySummary, hasSkuNoQuantity } = sizeDetailsSelector(values);

    if (NINETY_PERCENT_ID !== retailer && hasSkuNoQuantity) {
        const error = {};
        error.sizeDetails =
            'Please provide the quantity when entering the SKU value.';
        return error;
    }

    if (poNo.includes('TBC-')) return null;

    if (quantitySummary !== orderQuantity) {
        const error = {};
        error.sizeDetails = 'Ensure size wise quantity matches total quantity.';
        return error;
    }
};

export const validateSizeDetailsFields = (
    orderQuantity,
    totalRatio,
    setError,
    sizeDataSummary,
    fieldName,
    newValue,
    prevValue,
    index,
    quantity
) => {
    const fieldNameWithIndex = `sizeDetails[${index}].${fieldName}`;

    const validateNumberField = () => {
        const numberError = number(newValue);
        if (numberError) {
            setError({ [fieldNameWithIndex]: numberError });
            return false;
        }

        const minValueError = minValue(0)(newValue);
        if (minValueError) {
            setError({ [fieldNameWithIndex]: minValueError });
            return false;
        }

        const fractionError = fraction(newValue);
        if (fractionError) {
            setError({ [fieldNameWithIndex]: fractionError });
            return false;
        }

        return true;
    };

    const validateQuantityField = () => {
        if (
            sizeDataSummary.quantity + Number(newValue) - Number(prevValue) >
            orderQuantity
        ) {
            const remaining = orderQuantity - sizeDataSummary.quantity;
            setError({
                [fieldNameWithIndex]: `Quantity limit exceeded. The remaining quantity is ${remaining}`
            });
            return false;
        }
        return true;
    };

    const validateRatioField = () => {
        if (
            sizeDataSummary.ratio + Number(newValue) - Number(prevValue) >
            Number(totalRatio)
        ) {
            const remaining = Number(totalRatio) - sizeDataSummary.ratio;
            setError({
                [fieldNameWithIndex]: `Ratio limit exceeded. The remaining ratio is ${remaining}`
            });
            return false;
        }
        return true;
    };

    const validateSpecialChars = () => {
        const alphaError = validateSpecialCharacters(newValue);
        if (alphaError) {
            setError({ [fieldNameWithIndex]: alphaError });
            return false;
        }
        return true;
    };

    const quantityRequiredForSKU = () => {
        if (!quantity && newValue) {
            setError({
                [fieldNameWithIndex]: 'Quantity is required for SKU value'
            });
            return false;
        }
        return true;
    };

    if (fieldName === 'quantity' || fieldName === 'ratio') {
        if (!validateNumberField()) {
            return;
        }

        if (fieldName === 'quantity' && !validateQuantityField()) {
            return;
        }

        if (fieldName === 'ratio' && !validateRatioField()) {
            return;
        }
    } else {
        if (!validateSpecialChars()) {
            return;
        }
        if (!quantityRequiredForSKU()) {
            return;
        }
    }
    setError({});
};
