import React from 'react';
import PropTypes from 'prop-types';

import InputGroupSelect from '@baseComponents/InputGroupSelect';
import { number, mustBeGreaterThanZero } from '@libs/validationRules';

import { currencyData } from '../../../../../../customData';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'licenseFee',
    label: 'License Price',
    placeholder: 'Lic. Price',
    disabled: false,
    required: false,
    validate: [number, mustBeGreaterThanZero],
    inputProps: { type: 'number' }
};

const LicenseFee = props => (
    <InputGroupSelect {...props} selectData={currencyData} />
);

LicenseFee.propTypes = propTypes;
LicenseFee.defaultProps = defaultProps;

export default LicenseFee;
