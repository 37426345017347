import {
    FETCH_EMAIL_DATA_REQUEST,
    FETCH_EMAIL_DATA_SUCCESS,
    POST_EMAIL_DATA_REQUEST,
    // POST_EMAIL_DATA_SUCCESS,
    // POST_EMAIL_DATA_FAILURE,
} from 'store/actionTypes';

export const fetchEmailDataRequest = ({ reportId, module }) => ({
    type: FETCH_EMAIL_DATA_REQUEST,
    payload: {
        reportId,
        module,
    },
});

export const fetchEmailDataSuccess = ({
    mailGroupDataset,
    templateDataset,
}) => ({
    type: FETCH_EMAIL_DATA_SUCCESS,
    payload: {
        mailGroupDataset,
        templateDataset,
    },
});

export const postEmailDataRequest = ({ to, cc, subject, mail }) => ({
    type: POST_EMAIL_DATA_REQUEST,
    payload: {
        to,
        cc,
        subject,
        mail,
    },
});
