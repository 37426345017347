import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field } from 'redux-form';

// Local
import { PaginatedSelect } from 'components';
import { fetchSampleReferences } from 'store/actions';

const SamplingReferenceSelect = ({
    input: { value, onChange },
    ...restProps
}) => {
    return (
        <PaginatedSelect
            type='copySampleRef'
            success={!!value}
            value={value}
            action={onChange}
            {...restProps}
        />
    );
};

const normalize = ({ value }) => {
    if (typeof value === 'object') {
        const {
            value: {
                fullData: { sampleReference }
            }
        } = value;
        return sampleReference;
    }
    return value;
};

const mapDispatch = dispatch => ({
    loadMoreItems: (searchKey = '', pageNumber = 1) =>
        dispatch(fetchSampleReferences({ searchKey, pageNumber }))
});

const _SamplingReferenceSelect = compose(
    connect(
        undefined,
        mapDispatch
    )
)(SamplingReferenceSelect);

const __SamplingReferenceSelect = props => (
    <Field
        component={_SamplingReferenceSelect}
        {...props}
        fieldName={props.name}
        normalize={normalize}
    />
);

export {
    __SamplingReferenceSelect as default,
    __SamplingReferenceSelect as SamplingReferenceSelect
};
