import React from 'react';
import { withStyles } from '@material-ui/core';

import Card from './Card';

const styles = ({ spacing }) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridGap: `${spacing.unit * 2.5}px ${spacing.unit * 1.25}px`,
        padding: spacing.unit * 1.25
    }
});

const TileView = withStyles(styles)(({ data, classes: c }) => {
    return (
        <div className={c.container}>
            {data.map((item, idx) => (
                <Card item={item} itemIndex={idx} key={`tile-item-${idx}`} />
            ))}
        </div>
    );
});

export { TileView as default, TileView };
