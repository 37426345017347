import React from 'react';
import { array, string, bool } from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

import { Error } from 'components/Message';
// Local
import ExpansionPanel from './ExpansionPanel';

const propTypes = {
    products: array.isRequired,
    loading: bool
};

const defaultProps = {};

const styles = ({ spacing }) => ({
    content: {
        width: '100%',
        padding: spacing.unit * 2
    }
});

const Content = withStyles(styles)(({ classes: c, products, loading }) => {
    return (
        <div className={c.content}>
            {!loading && !!products.length && (
                <ExpansionPanel data={products} />
            )}
        </div>
    );
});

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
