import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { string, bool, number } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ palette, spacing, typography }) => ({
    tableBody: {
        width: 'auto',

        flexDirection: 'row',
        justifyContent: 'space-between',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,

        borderTop: 'none'
    },
    value: {
        width: '8%',
        fontSize: spacing.unit * 1,
        fontWeight: typography.fontWeightBold,
        //textAlign: 'center',
        padding: spacing.unit * 0.75,

        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1,
        lineHeight: 1.2,

        opacity: 0.75
    }
});

const propTypes = {
    fabricFor: string,
    fabricType: string,
    composition: string,
    gsm: number,
    dyeType: string,
    specialFabricRef: string,
    specialFinish: string,
    fabricColour: string,
    eld: string,
    isYds: bool,
    istc: bool,
    fabFrom: string,
    isLast: bool
};

const FabricBody = withPDFStyles(styles)(
    ({
        fabricFor,
        fabricType,
        composition,
        gsm,
        dyeType,
        specialFabricRef,
        specialFinish,
        fabricColour,
        eld,
        isYds,
        istc,
        fabFrom,
        classes: c
    }) => {
        return (
            <View style={c.tableBody}>
                <Text style={{ ...c.value, width: '9%' }}>{fabricFor} </Text>
                <Text style={c.value}>{fabricType} </Text>
                <Text style={{ ...c.value, width: '10%' }}>{composition} </Text>
                <Text style={{ ...c.value, width: '6%' }}>{gsm} </Text>
                <Text style={c.value}>{dyeType} </Text>
                <Text style={{ ...c.value, width: '10%' }}>
                    {specialFabricRef}{' '}
                </Text>
                <Text style={{ ...c.value, width: '9%' }}>
                    {specialFinish}{' '}
                </Text>
                <Text style={{ ...c.value, width: '9%' }}>{fabricColour} </Text>
                <Text style={{ ...c.value, width: '6%' }}>{eld} </Text>
                <Text style={{ ...c.value, width: '6%' }}>{isYds} </Text>
                <Text style={{ ...c.value, width: '6%' }}>{istc} </Text>
                <Text style={{ ...c.value, width: '9%', borderRight: 'none' }}>
                    {fabFrom}
                </Text>
            </View>
        );
    }
);

FabricBody.propTypes = propTypes;

export default FabricBody;
