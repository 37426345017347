export const TABLE_MAP = {
    cadReference: { label: 'CAD reference', required: true },
    description: { label: 'Description', required: true },
    licensor: { label: 'Licensor', required: true },
    property: { label: 'Property', required: true },
    artworkSource: { label: 'Artwork Source', required: true },
    designer: { label: 'Designer', required: true },
    type: { label: 'Type', required: true },
    developmentVersion: { label: 'Development version', required: true },
    gender: { label: 'Gender', required: false },
    style: { label: 'Style', required: false },
    sleeveType: { label: 'Sleeve type', required: false }
};

export const CHARACTERS_PER_LINE = 28;
export const LINE_HEIGHT = 19;
export const ROW_HEIGHT = 28;

// Spacing Units
export const FULL_WIDTH_UNITS = 90;
export const FULL_HEIGHT_UNITS = 135;
export const TITLE_HEIGHT_UNITS = 8;
export const IMAGE_CONTAINER_WIDTH_UNITS = FULL_WIDTH_UNITS / 2 - 4; // 4 is paddingRight
export const TABLE_MARGIN_UNITS = 4;
export const LINE_SPACING_UNITS = 3.75;
