import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Card } from 'components';
import { FabricsModal } from 'views/Sampling/modals';

// Actions
import { removeFabric, duplicateFabric } from 'store/actions';

// Selectors
import { selectSerializedFabricDetails } from 'selectors';

const SamplingFabricsCard = ({
    removeFabric,
    duplicateFabric,
    fabricDetails: [{ value: fabricFor }, ...fabricDetails],
    fabricIndex,
    handleFabricsModal
}) => (
    <Card
        data={fabricDetails}
        title={'Fabric for: ' + fabricFor}
        duplicateAction={() => duplicateFabric(fabricIndex)}
        editAction={() => handleFabricsModal({ fabricIndex })}
        deleteAction={() => removeFabric(fabricIndex)}
    />
);

const mapState = (state, { data }) => ({
    fabricDetails: selectSerializedFabricDetails(state, data)
});

const mapDispatch = {
    removeFabric,
    duplicateFabric
};

const _SamplingFabricsCard = compose(
    connect(
        mapState,
        mapDispatch
    )
)(SamplingFabricsCard);

export {
    _SamplingFabricsCard as default,
    _SamplingFabricsCard as SamplingFabricsCard
};
