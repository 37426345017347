import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

// Lib
import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Input } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Local
import { SelectRetailers } from 'sampling/components';
import { FORM_NAME, LABELS, HAND_CARRIED_COURIER_ID } from '../../common';
import { useSampleSendState } from '../../useSampleSend';
import { SET_ACTUAL_SEND_DATE_CHILDREN } from '../../../redux.actionTypes';
import HeaderAdornment from '../HeaderAdornment';
import ActualSendDate from './ActualSendDate';
import ETADate from './ETADate';
import SelectCurrier from './SelectCurrier';
import SampleDispatchDetailsContainer from './SampleDispatchDetailsContainer';
import SelectDispatchCountry from '../SelectDispatchCountry';

const mapInputs = {
    actualSendDate: ({ payload }) => ({
        type: SET_ACTUAL_SEND_DATE_CHILDREN,
        payload
    })
};

const mapState = state => {
    const currier = formValueSelector(FORM_NAME)(state, 'currier');

    return {
        isHandCarriedCourier: HAND_CARRIED_COURIER_ID === currier
    };
};

const styles = ({ spacing }) => ({
    container: {
        marginBottom: spacing.unit * 2
    }
});

const Details = compose(
    connect(mapState),
    withStyles(styles),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs)
)(({ classes: c, isHandCarriedCourier }) => {
    const { sampleDispatchReferenceLoading } = useSampleSendState();
    return (
        <ContentContainer
            title={LABELS.details}
            AdornmentComponent={<HeaderAdornment />}
        >
            <GridContainer className={c.container}>
                <GridItem md={4}>
                    <SelectRetailers required disabled />
                </GridItem>
                <GridItem md={4}>
                    <SelectDispatchCountry
                        name='originCountry'
                        label='Source Country'
                        placeholder='Select source country'
                        disabled
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectDispatchCountry
                        name='destinationCountry'
                        label='Destination Country'
                        placeholder='Select destination country'
                        disabled
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='dispatchReference'
                        label='Dispatch Reference'
                        disabled
                        loading={sampleDispatchReferenceLoading}
                        required
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input name='fao' label='FAO' />
                </GridItem>
                <GridItem md={4}>
                    <SelectCurrier />
                </GridItem>
                <GridItem md={4}>
                    <ActualSendDate />
                </GridItem>
                <GridItem md={4}>
                    <ETADate />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='trackingReference'
                        label='Tracking Reference'
                        required={!isHandCarriedCourier}
                    />
                </GridItem>
            </GridContainer>
            <SampleDispatchDetailsContainer />
        </ContentContainer>
    );
});

export default Details;
