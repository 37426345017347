import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components';

import styles from './styles';

import { ReportsSamplingAppbar } from './Appbars';
import ReportsToolbar from './ReportsToolbar';
import ReportsDocument from './ReportsDocument';
import ReportsAttachments from './ReportsAttachments';

class Reports extends Component {
    render() {
        const {
            classes: { root, container }
        } = this.props;
        return (
            <GridContainer className={root}>
                <GridItem item md={8} className={container}>
                    <ReportsSamplingAppbar />
                    <ReportsToolbar />
                    <ReportsDocument />
                    <ReportsAttachments />
                </GridItem>
            </GridContainer>
        );
    }
}

const _Reports = withStyles(styles)(Reports);

export { _Reports as default, _Reports as Reports };
