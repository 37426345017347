import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { array } from 'prop-types';

import { Title, AttachmentDetails, Comments } from 'components/PDF';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    othersContainer: {
        marginTop: 5
    }
});

const propTypes = {
    attachments: array
};

const Others = ({ attachments = [], additionalComments }) => {
    const hasOthersData = !!attachments.length || !!additionalComments;

    return (
        <View>
            {hasOthersData && <Title text={'Other Information'} />}
            {/* {!!attachments.length && (
                <View
                    style={{ ...styles.container, ...styles.othersContainer }}
                >
                    <AttachmentDetails
                        attachments={attachments}
                        style={{ width: '100%' }}
                    />
                </View>
            )} */}
            {!!additionalComments && (
                <View
                    style={{ ...styles.container, ...styles.othersContainer }}
                >
                    <Comments
                        comments={additionalComments}
                        style={{ width: '100%' }}
                    />
                </View>
            )}
        </View>
    );
};

Others.propTypes = propTypes;

export default Others;
