export const selectMailData = ({
    auth: { userId, userEmail, userName },
    reports: { pdfReportId, xlsxReportId, module },
}) => ({
    userId,
    userEmail,
    userName,
    pdfReportId,
    xlsxReportId,
    module
});
