import {
    FETCH_GRAPHIC_REF_REQUEST,
    SET_GRAPHIC_DESCRIPTION,
    SET_CAD_IMAGE
} from 'store/actionTypes';

export const setGraphicDescription = reference => ({
    type: SET_GRAPHIC_DESCRIPTION,
    payload: { reference }
});

export function fetchGraphicRef(payload) {
    return { type: FETCH_GRAPHIC_REF_REQUEST, payload };
}

export const setCadImage = cadImage => ({
    type: SET_CAD_IMAGE,
    payload: { cadImage }
});
