import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Attachment from './Attachment';
import OverlayScrollContainer from '@baseComponents/OverlayScrollContainer';
import useAttachmentDetails from '../useAttachmentDetails';
import DropZone from '../DropZone';

const propTypes = {
    onClick: PropTypes.func.isRequired
};

const styles = ({ spacing }) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: spacing.unit * 40.5
    }
});

const Attachments = withStyles(styles)(({ classes: c, onClick }) => {
    const [{ attachments }, dispatch] = useAttachmentDetails();

    const makeHandleDelete = useCallback(
        index => () => {
            dispatch({ type: 'removeAttachment', payload: index });
        },
        [dispatch]
    );
    const handleDragEnter = useCallback(
        () => dispatch({ type: 'setDragging', payload: true }),
        [dispatch]
    );

    const Wrapper = attachments.length > 4 ? OverlayScrollContainer : Fragment;

    return (
        <div className={c.root} onDragEnter={handleDragEnter}>
            <DropZone handleClick={onClick} />
            <Wrapper>
                {attachments.map(({ filename, ...attachment }, index) => (
                    <Attachment
                        key={`${filename}-${index}`}
                        filename={filename}
                        handleDelete={makeHandleDelete(index)}
                        {...attachment}
                    />
                ))}
            </Wrapper>
        </div>
    );
});

Attachments.propTypes = propTypes;

export default Attachments;
