import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';

import { setSnack } from 'utilities/redux.actions';
import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { makeSideEffectCallback } from '@libs/makeContext';
import { Iframe } from 'common/components';
import { MailFormProvider, useStoreEmailFormData } from '@libHooks/useMailForm';

import { reportPropsSelector } from 'orders/redux.selectors';
import { useMakePDF } from '../hooks';
import { useWatermarkAndFileName } from './hooks';
import ActionsAdornment from './ActionsAdornment';
import MailModal from './MailModal';

const styles = ({ breakpoints }) => ({
    container: {
        width: '85vw',
        minHeight: '90vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const propTypes = {
    name: string
};

const defaultProps = {
    name: 'mailData'
};

const mapStateToProps = (state, { mode }) => ({
    orderReportProps: reportPropsSelector(state, mode)
});

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Preview = compose(
    withModalProvider,
    withModal({
        handleModal: MailModal
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withField(),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handlePublish,
        orderReportProps,
        setSnack,
        handleModal,
        input,
        meta: { form },
        mode,
        statusAction
    }) => {
        const [pdfLoading, setPdfloading] = useState(true);
        const propsLoadedRef = useRef(0);
        const {
            fileName,
            statusActionName,
            ...watermark
        } = useWatermarkAndFileName(statusAction);

        const [makePDFURL, PDF, handleDocRemove] = useMakePDF({
            fileName,
            props: {
                ...orderReportProps,
                ...watermark
            },
            setSnack
        });

        const { value, onChange } = input;
        const [action, setAction] = useState({});

        const initialize = useCallback(
            state => ({
                ...state,
                mail: value || {}
            }),
            [value]
        );

        const middlewareProps = useMemo(
            () => ({
                reduxFormOnChange: makeSideEffectCallback(onChange)
            }),
            [onChange]
        );

        const handlePreviewModalClose = useCallback(() => {
            handleDocRemove(); // re initialize PDF obj
            setAction({ type: 'removePDF' });
            setTimeout(() => onClose(), 0);
        }, [onClose, handleDocRemove, setAction]);

        const handleEmailModal = useMemo(
            () => props =>
                handleModal({
                    ...props,
                    formName: form,
                    handlePublish,
                    closePreviewModal: handlePreviewModalClose,
                    mode,
                    statusActionName
                }),
            [
                handleModal,
                form,
                mode,
                handlePublish,
                statusActionName,
                handlePreviewModalClose
            ]
        );

        const handleMailForm = useStoreEmailFormData({
            handleModal: handleEmailModal,
            setAction,
            formName: form
        });

        const handlePDFAdd = useCallback(value => {
            setAction({ type: 'addPDF', payload: value });
        }, []);

        useEffect(() => {
            if (
                orderReportProps &&
                !!Object.keys(orderReportProps.setup).length &&
                propsLoadedRef.current === 0
            ) {
                propsLoadedRef.current = 1;
                makePDFURL();
            }
        }, [orderReportProps]);

        useEffect(() => {
            if (PDF && PDF.url) {
                setPdfloading(false);
                handlePDFAdd({ pdf: PDF });
            }
        }, [PDF]);

        return (
            <MailFormProvider
                initialize={initialize}
                initialAction={action}
                middlewareProps={middlewareProps}
            >
                <ContentContainer
                    title='Preview'
                    styles={{ container: c.container }}
                    AdornmentComponent={
                        <ActionsAdornment
                            handlePreviewModalClose={handlePreviewModalClose}
                            handleMailForm={handleMailForm}
                            loading={pdfLoading}
                            isPublishPreview={!statusAction}
                        />
                    }
                >
                    {!pdfLoading ? (
                        <Iframe src={PDF.url} />
                    ) : (
                        <CircularProgress
                            size='50px'
                            style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                position: 'absolute',
                                top: '50%'
                            }}
                        />
                    )}
                </ContentContainer>
            </MailFormProvider>
        );
    }
);

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
