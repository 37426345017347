import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { withModalProvider, withModalCarouselProvider } from 'components';
import Header from './Header';
import Footer from './Footer';
import { Menus, RightMenu } from './Menus';
import styles from './styles';
// Actions
import { selectAuthMenus } from 'selectors';

class PrivateContainer extends PureComponent {
    state = {
        menuDrawer: true,
        rightDrawer: false,
        hoverEnable: false
    };

    //Left and right menu
    toggleDrawer = type => {
        const currentVal = this.state[type];
        this.setState({ [type]: !currentVal, hoverEnable: currentVal });
    };

    //Left menu only
    toggleMenuDrawerOnHover = () => {
        const { menuDrawer, hoverEnable } = this.state;
        if (hoverEnable) this.setState({ menuDrawer: !menuDrawer });
    };

    render() {
        const { state, props, toggleDrawer, toggleMenuDrawerOnHover } = this;
        const { classes, children, menus } = props;
        const { menuDrawer, rightDrawer } = state;

        return (
            <div className={classes.root}>
                {/* Left menu list */}
                <Menus
                    isOpen={menuDrawer}
                    hoverAction={toggleMenuDrawerOnHover}
                    menus={menus}
                />

                {/* Right drawer menu */}
                <RightMenu isOpen={rightDrawer} action={toggleDrawer} />

                <main className={classes.content}>
                    {/* Top tool bar */}
                    <Header
                        isOpen={menuDrawer}
                        handleDrawer={() => toggleDrawer('menuDrawer')}
                        handleRightDrawer={() => toggleDrawer('rightDrawer')}
                    />
                    {/* Child content */}
                    <div className={classes.contentWrapper}>{children}</div>

                    {/* Footer */}
                    <Footer />
                </main>
            </div>
        );
    }
}

const mapState = state => {
    return {
        menus: selectAuthMenus(state)
    };
};

PrivateContainer.propTypes = {
    classes: PropTypes.object.isRequired
};

const _PrivateContainer = compose(
    connect(mapState),
    withStyles(styles),
    withModalCarouselProvider,
    withModalProvider
)(PrivateContainer);

export { _PrivateContainer as default, _PrivateContainer as PrivateContainer };
