import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    isSubmitting,
    initialize as formInitialize
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import { useValidate, useNext } from '@libHooks/useValidate';
import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { userLabelSelector } from 'auth/redux.selectors';
import { formErrorsSelector } from 'common/redux.selectors';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';

import { SealSampleProvider } from './useSealSample';
import { useHandles } from './hooks';
import { FORM_NAME, INITIAL_VALUES, SEAL_SAMPLE_FORM_FIELDS } from './common';
import useSchema from './content/useSchema';
import {
    sealSampleSaveDraft,
    sealSamplePublish,
    sealSampleRefGenerate,
    sealSampleHandover,
    destroySealSampleForm
} from 'qualityControl/redux.actions';
import Preview from './content/Preview';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => {
    return {
        formValues: getFormValues(FORM_NAME)(state),
        formErrors: formErrorsSelector(state, FORM_NAME),
        formSubmitting: isSubmitting(FORM_NAME)(state),
        userLabel: userLabelSelector(state)
    };
};

const mapDispatch = {
    saveDraft: sealSampleSaveDraft,
    handOver: sealSampleHandover,
    publish: sealSamplePublish,
    generateReference: sealSampleRefGenerate,
    formInitialize,
    formDestroy: destroySealSampleForm
};

const SealSampleContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        saveDraft,
        handOver,
        publish,
        generateReference,
        formInitialize,
        formDestroy,
        mode,
        handleReduxFormSubmit,
        handleModal,
        userLabel
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const [sealSampleFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            SEAL_SAMPLE_FORM_FIELDS[activeStep],
            FORM_NAME
        );

        const handleSealSampleFormSubmit = useCallback(() => {
            handleReduxFormSubmit();
            handleScroll();
        }, [handleReduxFormSubmit, handleScroll]);

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const isQA = userLabel === 'QA';
        const { qcReference } = formValues;

        const schema = useSchema(mode, formValues, isQA);
        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);
        const canPublish = areValid.publish;

        const canResetForm = useMemo(() => {
            return !!Object.keys(formValues).length;
        }, [formValues]);

        const shouldReferenceGenerate = useMemo(
            () => activeStep === 1 && mode === 'new' && !qcReference,
            [activeStep, mode, qcReference]
        );
        const shouldFetchData = useMemo(
            () => mode !== 'new' && activeStep === 0,
            [mode, activeStep]
        );
        const shouldSave = useMemo(() => mode !== 'amend', [mode]);
        const shouldPreview = useMemo(() => {
            return activeStep === contents.length - 2;
        }, [activeStep, contents.length]);

        const [handleNext, handlePublish, setupLocked, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish
        });
        const handleSaveDraft = useCallback(() => saveDraft({ mode }), [
            mode,
            saveDraft
        ]);
        const handleHandOver = useCallback(
            dispatchProps => () =>
                handOver({
                    mode,
                    handleComplete: reset,
                    ...dispatchProps
                }),
            [dispatch]
        );
        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleSealSampleFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep, handleSealSampleFormSubmit]);

        const handlePreview = useCallback(
            props => {
                if (!canPublish) {
                    handleSealSampleFormSubmit();
                    return;
                }
                handleModal({ ...props, handlePublish, mode });
            },
            [handleModal, canPublish, handleSealSampleFormSubmit]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                mode,
                canSave: areValid.saveDraft,
                canPublish,
                handlePublish,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleSaveDraft,
                reset,
                handleHandOver,
                shouldPreview,
                handlePreview,
                isQA,
                canResetForm
            }),
            [
                mode,
                areValid.saveDraft,
                canPublish,
                handlePublish,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleSaveDraft,
                reset,
                handleHandOver,
                shouldPreview,
                handlePreview,
                isQA,
                canResetForm
            ]
        );

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => formDestroy();
        }, [mode, formDestroy]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        useEffect(() => {
            shouldReferenceGenerate && generateReference();
        }, [shouldReferenceGenerate]);

        return (
            <Paper className={c.container}>
                <SealSampleProvider initialize={initialize}>
                    <div ref={sealSampleFormRef}>{children}</div>
                </SealSampleProvider>
                {!complete &&
                    (shouldPreview ? (
                        <Button className={c.button} onClick={handlePreview}>
                            Preview
                        </Button>
                    ) : (
                        <Button
                            className={c.button}
                            onClick={handleNextWithSubmit}
                            loading={formSubmitting}
                        >
                            {proceedText}
                        </Button>
                    ))}
            </Paper>
        );
    }
);

export default SealSampleContainer;
