import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import isLoading from 'helpers/isLoading';
import { MultiStepper, Button } from 'components';
import { SamplingBar } from '../appBar';
import { getSteps } from './_getSteps';
import { getStepContent } from './_getStepContent';
import { publishToDatabase } from 'actions';
import { printNewStepValidate, printAmendStepValidate } from '../validator';

import style from './style';

import { PUBLISH_TO_DATABASE_DRAFT } from 'actionTypes';
import { selectPageType } from 'selectors';

function mapStateToProps(state) {
    return {
        global: state.global,
        stepData: state.initSamplingPrint,
        form: state.form,
        isNewPage: selectPageType(state, 'new'),
        isIncompletePage: selectPageType(state, 'incomplete'),
        isAmendPage: selectPageType(state, 'amend'),
        hasSampleRef: !!formValueSelector('SamplingPrintForm')(
            state,
            'sampleReference'
        )
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            publishToDatabase
        },
        dispatch
    );
};

class StepsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            completedStep: 1
        };
    }

    // draft, publish handler
    submitForm = reqType => {
        const {
            publishToDatabase,
            form: { SamplingPrintForm }
        } = this.props;
        publishToDatabase({ reqType, formData: SamplingPrintForm.values });
    };

    // allowed max step
    samplingMaxStep = () => (this.props.isNewPage ? 5 : 4);

    // iterative step validator
    allStepValidated = step => {
        const { stepData } = this.props;
        let result = true;
        if (step >= 1) {
            for (let i = 1; i < step; i++) {
                if (!printNewStepValidate(i, stepData)) {
                    result = false;
                    break;
                }
            }
        }
        return result;
    };

    // onClick multiStepper bar handler
    handleStep = step => () => {
        const { completedStep } = this.state;
        if (step < completedStep) {
            this.setState({ activeStep: step });
        }
    };

    // move to next step
    forwardNext = () => {
        const { activeStep, completedStep } = this.state;
        this.setState({
            activeStep:
                activeStep < this.samplingMaxStep()
                    ? activeStep + 1
                    : activeStep,
            completedStep:
                completedStep < this.samplingMaxStep() &&
                activeStep === completedStep
                    ? completedStep + 1
                    : completedStep
        });
    };

    // move to next validator
    validateNext = () => {
        const { activeStep } = this.state;
        const {
            stepData,
            pageType: { newPage }
        } = this.props;
        let isValid = false;

        if (newPage) {
            isValid = printNewStepValidate(activeStep, stepData);
        } else {
            isValid = printAmendStepValidate(activeStep, stepData);
        }

        return isValid;
    };

    //check loading
    isStepLoading = key => isLoading(this.props.global, key);

    //draft, publish, next buttons
    isButtonDisabled = type => {
        let isDisabled = true;
        const { completedStep } = this.state;
        const {
            isNewPage,
            isAmendPage,
            isIncompletePage,
            hasSampleRef,
            form: { SamplingRequestForm }
        } = this.props;

        switch (type) {
            case 'handover':
            case 'draft':
                if (isNewPage) {
                    isDisabled =
                        completedStep === 1 &&
                        SamplingRequestForm &&
                        !SamplingRequestForm.submitSucceeded;
                } else {
                    isDisabled = false;
                }
                break;
            case 'publish':
                if (isNewPage) {
                    isDisabled = completedStep < 5;
                } else if (isAmendPage && hasSampleRef) {
                    isDisabled = false;
                } else if (isIncompletePage) {
                    isDisabled = completedStep < 4;
                }
                break;
            default:
                isDisabled = true;
                break;
        }

        return isDisabled;
    };

    render() {
        const {
            state,
            props,
            isButtonDisabled,
            isStepLoading,
            submitForm,
            forwardNext
        } = this;

        const {
            classes,
            title,
            isNewPage,
            isAmendPage,
            pageType: { parent }
        } = props;

        const { activeStep, completedStep } = state;

        const steps = getSteps(isNewPage, parent);

        const stepTitle = steps[activeStep - 1]; //allow 0 index of array

        const StepContent = getStepContent(
            activeStep,
            isNewPage,
            isAmendPage,
            parent
        );

        return (
            <Fragment>
                {/*Handover, Publish button*/}
                <SamplingBar
                    title={title}
                    completedStep={completedStep}
                    isHandoverDisabled={isButtonDisabled('handover')}
                    isPublishDisabled={isButtonDisabled('publish')}
                />

                <Typography component='div'>
                    {/*Step - number bar*/}
                    <MultiStepper
                        steps={steps}
                        active={activeStep}
                        complete={completedStep}
                        onClick={this.handleStep}
                    />

                    {/*Step - content*/}
                    <Paper className={classes.paperRoot} elevation={1}>
                        <Paper
                            component='div'
                            className={classes.headerPaperRoot}
                        >
                            <Typography className={classes.paperTitle}>
                                {stepTitle}
                            </Typography>
                            {!isAmendPage ? (
                                <Button
                                    disabled={isButtonDisabled('draft')}
                                    onClick={() => submitForm('draft')}
                                    category='secondary'
                                    isLoading={isStepLoading(
                                        PUBLISH_TO_DATABASE_DRAFT
                                    )}
                                />
                            ) : null}
                        </Paper>

                        <Paper
                            component='div'
                            className={classes.gridContainer}
                        >
                            <StepContent onSubmit={forwardNext} />
                        </Paper>
                    </Paper>
                </Typography>
            </Fragment>
        );
    }
}

StepsContainer.propTypes = {
    classes: PropTypes.object
};

const SampleStepsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(StepsContainer));
export { SampleStepsContainer as default, SampleStepsContainer };
