import React from 'react';
import { compose } from 'redux';
import { array, string, func, bool } from 'prop-types';
import { withStyles } from '@material-ui/core';
import clsx from 'classnames';

import { SelectBase } from 'components';

const propTypes = {
    data: array.isRequired,
    value: string,
    handleChange: func.isRequired,
    error: bool
};

const styles = ({ spacing, palette }) => ({
    root: { fontSize: spacing.unit * 1.8 },
    container: { width: '70% !important' },
    control: {
        height: spacing.unitHeight,
        width: '100%',
        borderTopRightRadius: `0 !important`,
        borderBottomRightRadius: `0 !important`,
        borderRight: 'none !important',
        '&:hover': {
            borderRight: '1px solid !important',
            borderColor: `${palette.grey[400]} !important`
        }
    },

    placeholder: {
        paddingRight: spacing.unit * 6
    },
    success: {
        '&:hover': {
            borderRight: 'none !important'
        }
    },
    error: {
        '&:hover': {
            borderRight: 'none !important'
        }
    },
    dropdownIcon: {
        transform: 'scale(1.1)'
    }
});

const Select = compose(withStyles(styles))(
    ({
        data,
        value,
        handleChange,
        classes: { success: successClass, error: errorClass, control, ...c },
        success = false,
        error = false,
        isDisabled
    }) => {
        const controlClass = clsx(
            control,
            success && successClass,
            error && errorClass
        );
        const selectProps = {
            name: 'void0',
            data,
            input: {
                onFocus: () => {},
                onBlur: () => {},
                onChange: handleChange,
                value
            },
            showSuccess: success,
            styles: { ...c, control: controlClass },
            disabled: isDisabled,
            error
        };

        return <SelectBase {...selectProps} />;
    }
);

Select.propTypes = propTypes;

export default Select;
