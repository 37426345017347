import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import prototypeEnhancer from 'helpers/prototypeEnhancers';
import { store, persistor } from './helpers/createStore'; // Todo: Legacy store
import App from './app';

// Enhances prototypes
prototypeEnhancer();

ReactDOM.render(<App />, document.getElementById('echo'));
