import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { DESIGNERS_BY_USER_ID } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'designer',
    label: 'Designer',
    placeholder: 'Select designer',
    disabled: false,
    required: false
};

const SelectDesigners = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={DESIGNERS_BY_USER_ID} />
);

SelectDesigners.propTypes = propTypes;
SelectDesigners.defaultProps = defaultProps;

export { SelectDesigners as default, SelectDesigners };
