import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { CancelDialog } from '@formComponents/CancelDialog';

// App
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Domain
import { SelectAmendCads, SelectIncompleteCads } from '../../components';
import { useCadUploadState } from '../../useCadUpload';
import { FORM_NAME } from '../../common';
import {
    destroyForm,
    getIncompleteData,
    getAmendData
} from '../../redux.actions';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    incompleteReference: ({ payload }) => getIncompleteData(payload),
    amendReference: ({ payload }) => getAmendData(payload)
};

const HeaderAdornment = compose(
    connect(
        void 0,
        { destroyForm }
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, destroyForm }) => {
    const {
        canSave,
        canPublish,
        handleSave,
        handlePublish,
        mode,
        handleReduxFormSubmit
    } = useCadUploadState();

    const handlePublishWithValidation = () => {
        if (!canPublish) {
            handleReduxFormSubmit();
            return;
        }
        handlePublish();
    };

    const [open, setOpen] = useState(false);

    const isIncompleteOrAmend = useMemo(() => mode !== 'new', [mode]);

    const shouldSaveDraft = useMemo(() => mode !== 'amend', [mode]);

    const handleCancel = useCallback(() => setOpen(true), []);

    const handleClose = useCallback(() => setOpen(false), []);

    const handleConfirm = useCallback(() => {
        destroyForm();
        handleClose();
    }, [handleClose, destroyForm]);

    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null
        }),
        []
    );

    return (
        <div className={c.container}>
            {isIncompleteOrAmend && (
                <Fragment>
                    <div className={c.selectWrapper}>
                        <Typography
                            variant='subtitle1'
                            className={c.selectLabel}
                        >
                            Select graphic ref:
                        </Typography>
                        <div className={c.selectContainerWrapper}>
                            {mode === 'amend' ? (
                                <SelectAmendCads {...paginatedSelectProps} />
                            ) : (
                                <SelectIncompleteCads
                                    {...paginatedSelectProps}
                                />
                            )}
                        </div>
                    </div>
                    <Button
                        className={c.button}
                        color='error'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Fragment>
            )}
            {shouldSaveDraft && (
                <Button
                    className={c.button}
                    color='success'
                    disabled={!canSave}
                    onClick={handleSave}
                >
                    Save Draft
                </Button>
            )}
            <Button
                className={c.button}
                //disabled={!canPublish}
                onClick={handlePublishWithValidation}
            >
                Publish to Database
            </Button>
            <CancelDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </div>
    );
});

export default HeaderAdornment;
