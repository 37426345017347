import { startSubmit } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { entitySelector } from '@libs/datasets';
import { setDataset } from '@libs/datasets/libs.actions';

import { DOMAIN_NAME } from 'setup/common';
import { TRIM_LABEL_TYPE, TRIM_LABEL } from 'setup/redux.datasets';
import { FORM_NAME } from '../../Order/TrimLabelType/common';
import { trimLabelTypeSchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

function* updateDependentDataset(id, trimType) {
    const dependentDataset = yield select(
        entitySelector,
        DOMAIN_NAME,
        TRIM_LABEL
    );

    const dependentDatasetAfterEdit = dependentDataset.map(
        ({ trimTypeId, ...prevData }) => ({
            ...(trimTypeId === id ? { ...prevData, trimType } : prevData),
            trimTypeId
        })
    );

    yield put(
        setDataset({
            entity: TRIM_LABEL,
            domain: DOMAIN_NAME,
            dataset: dependentDatasetAfterEdit
        })
    );
}

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            isActive: id ? payload.isActive : true
        };

        const publishData = composeApiPayload(
            preparedPayload,
            trimLabelTypeSchema
        );

        const { data } = yield call(apiRequest, {
            url: 'OrderBasicTrimTypes',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: TRIM_LABEL_TYPE,
            isNew: id === 0,
            data
        });

        if (id > 0) {
            yield updateDependentDataset(id, payload.trimType);
        }

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Trim label type',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
