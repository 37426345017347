import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import createCachedSelector from 're-reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Material
import { withStyles } from '@material-ui/core';

// libs
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import { extractProps } from '@libs/propTypes';
import SelectModel from '@formComponents/SelectModel';

import { DOMAIN_NAME, FORM_NAME } from 'orders/Order/common';
import { ORDER_NOTES } from 'orders/redux.datasets';
import { setOrderNoteChildren } from 'orders/redux.actions';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: ORDER_NOTES,
    name: 'orderNoteName',
    label: '',
    placeholder: 'Select Order Note',
    disabled: false,
    required: false
};

const dataSelector = createCachedSelector(
    entitySelector,
    (state, domain, entity, retailer) => retailer,
    (data, retailer) =>
        data
            .filter(({ retailerId }) => Number(retailerId) === retailer)
            .map(({ id, orderNoteName }) => ({
                label: orderNoteName,
                value: id
            }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => {
    const retailer = formValueSelector(FORM_NAME)(state, 'retailer');
    return {
        data: dataSelector(state, domain, entity, retailer),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const mapInputs = {
    orderNoteName: ({ payload }) => setOrderNoteChildren(payload)
};

const styles = ({ spacing, palette }) => ({
    selectContainerWrapper: {
        width: spacing.unit * 27
    },
    fieldContainer: {
        paddingBottom: 0
    },
    selectControl: {
        backgroundColor: palette.common.white
    }
});

const Adornment = compose(
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles),
    formListener(mapInputs)
)(({ classes: c, fetchDataset, data, loading, ...rest }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return (
        <div className={c.selectContainerWrapper}>
            <SelectModel
                styles={{
                    container: c.fieldContainer,
                    control: c.selectControl
                }}
                data={data}
                loading={loading}
                {...extractProps(rest, propTypes)}
            />
        </div>
    );
});

Adornment.propTypes = propTypes;
Adornment.defaultProps = defaultProps;

export default Adornment;
