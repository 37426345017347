export default ({ palette, spacing, shape }) => ({
    root: {
        width: '100%',
        borderBottom: `1px solid`,
        borderBottomColor: palette.grey[300],

        '&:hover': {
            zIndex: 4000,
            borderBottomColor: palette.grey[500]
        }
    },
    input: {
        height: '100%'
    }
});
