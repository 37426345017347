import React, { useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, initialize } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { PaginatedSelect, CancelDialog } from '@formComponents';
import Button from '@libComponents/Button';

import { useSampleSendState } from '../useSampleSend';
import { FORM_NAME, DOMAIN_NAME } from '../common';
import {
    DISPATCH_INCOMPLETE_SAMPLES,
    DISPATCH_AMEND_SAMPLES
} from '../../redux.datasets';
import { getSampleDispatchData } from '../../redux.actions';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    dropdownButton: {
        height: spacing.unit * 4.5
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    referenceNo: ({ payload }) => getSampleDispatchData(payload)
};

const mapStateToProps = state => ({
    dispatchReference: formValueSelector(FORM_NAME)(state, 'dispatchReference')
});

const mapDispatch = dispatch => ({
    resetDispatchForm: () => dispatch(initialize(FORM_NAME, {}))
});

const HeaderAdornment = compose(
    connect(
        mapStateToProps,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, resetDispatchForm }) => {
    const {
        mode,
        canSave,
        shouldFetchData,
        shouldSave,
        handleSaveDraft,
        shouldPreview,
        handlePreview,
        reset,
        canResetForm
    } = useSampleSendState();
    const [open, setOpen] = useState(false);

    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null
        }),
        []
    );

    const handleResetForm = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleConfirm = useCallback(() => {
        resetDispatchForm();
        handleClose();
        reset();
    }, [resetDispatchForm]);

    return (
        <div className={c.container}>
            {shouldFetchData && (
                <div className={c.selectWrapper}>
                    <Typography variant='subtitle1' className={c.selectLabel}>
                        Select dispatch ref:
                    </Typography>
                    <div className={c.selectContainerWrapper}>
                        {mode === 'amend' ? (
                            <PaginatedSelect
                                name='referenceNo'
                                entity={DISPATCH_AMEND_SAMPLES}
                                domain={DOMAIN_NAME}
                                {...paginatedSelectProps}
                            />
                        ) : (
                            <PaginatedSelect
                                name='referenceNo'
                                entity={DISPATCH_INCOMPLETE_SAMPLES}
                                domain={DOMAIN_NAME}
                                {...paginatedSelectProps}
                            />
                        )}
                    </div>
                </div>
            )}
            <Button
                className={c.button}
                color='error'
                onClick={handleResetForm}
                disabled={!canResetForm}
            >
                Reset
            </Button>
            {shouldSave && (
                <Button
                    className={c.button}
                    color='success'
                    disabled={!canSave}
                    onClick={handleSaveDraft}
                >
                    Save Draft
                </Button>
            )}
            {shouldPreview && (
                <Button className={c.button} onClick={() => handlePreview()}>
                    Preview
                </Button>
            )}
            <CancelDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </div>
    );
});

export default HeaderAdornment;
