const style = theme => ({
    loader: {
        color: '#ffffff',
        marginTop: 3,
    },
    paperRoot: {
        boxShadow: '0px 3px 21px 0px rgba(0, 4, 52, 0.11)',
        border: '1px solid #e4e4e4',
        marginBottom: 150,
    },
    headerPaperRoot: {
        backgroundColor: '#f4f4f4',
        padding: '10px 10px 10px 20px',
        borderBottom: '1px solid #e4e4e4',
        display: 'flex',
        boxShadow: 'none',
        borderRadius: 0,
    },
    gridContainer: {
        padding: '40px 20px 15px',
        boxShadow: 'none',
    },
    paperTitle: {
        fontSize: '18px',
        lineHeight: '35px',
        color: 'black',
        fontWeight: 'bold',
        flexGrow: 1,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

export { style as default, style };
