import { fork } from 'redux-saga/effects';

// Local
import commonWatchers from './common';

const datasetsSaga = function*() {
    yield fork(commonWatchers);
};

export { datasetsSaga as default, datasetsSaga };
