import { initializeDatasets } from '@libs/datasets';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';
import { LOGOUT } from 'auth/redux.actionTypes';

import datasets from './redux.datasets';
import {
    SET_USER_PERMISSIONS_DATA_LOADING,
    SET_USER_PERMISSIONS_DATA,
    SET_COPY_USER_GRANTED_PERMISSIONS_LOADING,
    SET_EXPORT_USER_XL_DATA_LOADING
} from './redux.actionTypes';

const initialState = {
    userPermissionsLoading: false,
    userPermissions: {},
    copyUserPermissionsLoading: false,
    exportUserExcelDataLoading: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }

        case SET_USER_PERMISSIONS_DATA_LOADING: {
            return { ...state, userPermissionsLoading: payload };
        }

        case SET_USER_PERMISSIONS_DATA: {
            return { ...state, userPermissions: payload };
        }

        case SET_COPY_USER_GRANTED_PERMISSIONS_LOADING: {
            return { ...state, copyUserPermissionsLoading: payload };
        }

        case SET_EXPORT_USER_XL_DATA_LOADING: {
            return { ...state, exportUserExcelDataLoading: payload };
        }

        default: {
            return state;
        }
    }
};
