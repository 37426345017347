import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';

import { DOMAIN_NAME } from 'sampling/common';
import { DISPATCH_SAMPLE_REFERENCES } from 'sampling/redux.datasets';

const propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sampleRef',
    label: 'Sample Reference',
    domain: DOMAIN_NAME,
    entity: DISPATCH_SAMPLE_REFERENCES,
    required: true
};

const SelectPaginatedSampleReferences = props => <PaginatedSelect {...props} />;

SelectPaginatedSampleReferences.propTypes = propTypes;
SelectPaginatedSampleReferences.defaultProps = defaultProps;

export default SelectPaginatedSampleReferences;
