import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Attachment from './Attachment';
import { useHasAttachments } from './hooks';

const propTypes = {
    attachments: PropTypes.array.isRequired
};

const styles = ({ palette, spacing }) => ({
    root: {
        backgroundColor: '#525659',
        width: '100%',
        maxHeight: spacing.unit * 20,
        overflow: 'auto'
    },
    wrapper: {
        width: spacing.unit * 99.5,
        margin: '8px auto',
        backgroundColor: '#fff',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    }
});

const DownloadAttachments = withStyles(styles)(
    ({ attachments, classes: c }) => {
        const hasAttachment = useHasAttachments(attachments);
        return (
            hasAttachment && (
                <div className={c.root}>
                    <div className={c.wrapper}>
                        {attachments.map(({ sizeOriginal }, index) => (
                            <Attachment key={index} url={sizeOriginal} />
                        ))}
                    </div>
                </div>
            )
        );
    }
);

DownloadAttachments.propTypes = propTypes;

export default DownloadAttachments;
