import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, LinearProgress } from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../PaginatedDialogContext';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogProgress
 */
const PaginatedDialogProgress = ({ classes: c, dialogState: { loading } }) => {
    return loading ? (
        <LinearProgress classes={c} />
        // <div className={c.root} style={{ backgroundColor: 'blue'}}/>
    ) : (
        <div className={c.root} />
    );
};

const _PaginatedDialogProgress = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogProgress);

export {
    _PaginatedDialogProgress as default,
    _PaginatedDialogProgress as PaginatedDialogProgress,
};
