import { useCallback, useMemo, useState, useEffect } from 'react';
import { LABELS } from './common';
import Setup from './content/Setup';
import Details from './content/Details';
import Other from './content/Other';
import Complete from './content/Complete';

import useStepperHandle from '@libHooks/useStepperHandle';

const useContent = mode => {
    return useMemo(() => {
        return {
            [LABELS.setup]: Setup,
            [LABELS.details]: Details,
            [LABELS.other]: Other,
            [LABELS.complete]: Complete
        };
    }, [mode]);
};

const useHandles = ({ dispatch, mode, activeStep, publish }) => {
    const amountOfSteps = 4;
    const [setupLocked, setSetupLocked] = useState(mode !== 'new');

    const reset = useCallback(() => {
        mode === 'new' && setSetupLocked(false);
        dispatch({ type: 'reset' });
    }, [dispatch, mode]);

    const handlePublish = useCallback(
        dispatchProps => {
            return useStepperHandle({
                amountOfSteps,
                dispatch,
                mode,
                publish,
                activeStep: amountOfSteps - 2, // Forces the handle to think it's about to publish,
                dispatchProps
            });
        },
        [dispatch]
    );

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        activeStep,
        publish
    });

    const handleNext = useCallback(() => {
        if (activeStep === 0) setSetupLocked(true);

        handleStepper();
    }, [mode, activeStep, dispatch, amountOfSteps]);
    return [handleNext, handlePublish, setupLocked, reset];
};

export { useContent, useHandles };
