import {
	POST_NEW_TRIM_STEP_FIRST,
	UPDATE_NEW_TRIM_FINAL_STEP
} from 'store/actionTypes';

export function postNewTrimCard(payload) {
	return { type: POST_NEW_TRIM_STEP_FIRST, payload };
}

// export function updateNewTrimCartStepFinal(payload) {
//     return { type: UPDATE_NEW_TRIM_FINAL_STEP, payload };
// }
