import { formValueSelector } from 'redux-form';

import { userIdSelector } from 'auth/redux.selectors';

import { FORM_NAME } from './SealSample/common';

export const SEAL_SAMPLE_STYLES = '@sealSampleStyles';
export const SEAL_SAMPLE_COLORS = '@sealSampleColors';
export const STOCKPILE_STATUS = '@stockpileStatus';
export const CONSENT_STATUS = '@consentStatus';
export const LAUNDRY_STATUS = '@laundryStatus';
export const SEAL_SAMPLE_TYPES = '@sealSampleTypes';
export const SEAL_SAMPLE_STATUS = '@sealSampleStatus';
export const ORDERS_LIST = '@ordersList';

export const AMEND_SEAL_SAMPLE = '@amendSealSample';
export const INCOMPLETE_SEAL_SAMPLE = '@incompleteSealSample';

export const SEAL_SAMPLE_MAIL_GROUP = '@sealSampleMailGroup';
export const SEAL_SAMPLE_MAIL_TEMPLATE = '@sealSampleMailTemplate';

export default {
    [ORDERS_LIST]: {
        url: 'OrderMains/GetDropdownOrders',
        params: state => ({
            userId: userIdSelector(state),
            isAmend: true
        }),
        fields: {
            factoryRef: 'Factory Ref',
            echoOrderNo: 'Order No',
            styleNo: 'Style',
            retailer: 'Retailer'
        },
        selector: () => false
    },
    [SEAL_SAMPLE_STYLES]: {
        url: 'QcPreSealSamples/GetQcStyles',
        params: state => ({
            jwpNo: formValueSelector(FORM_NAME)(state, 'jwpNo')
        }),
        selector: () => false
    },
    [SEAL_SAMPLE_COLORS]: {
        url: 'QcPreSealSamples/GetQcColors',
        params: state => {
            const { jwpNo, qcStyle } = formValueSelector(FORM_NAME)(
                state,
                'jwpNo',
                'qcStyle'
            );
            return {
                jwpNo,
                styleRef: qcStyle
            };
        },
        selector: () => false
    },
    [STOCKPILE_STATUS]: {
        url: 'StockpileStatus'
    },
    [CONSENT_STATUS]: {
        url: 'ConsentStatus'
    },
    [LAUNDRY_STATUS]: {
        url: 'LaundryStatus'
    },
    [INCOMPLETE_SEAL_SAMPLE]: {
        url: 'QcPreSealSamples/GetIncompleteAndAmendQcPreSealSamples',
        params: state => ({
            userId: userIdSelector(state),
            isAmend: false
        }),
        fields: {
            qcRef: 'QC Ref',
            jwpNo: 'JWP No',
            styleRef: 'Style',
            colour: 'Colour'
        },
        selector: () => false
    },
    [AMEND_SEAL_SAMPLE]: {
        url: 'QcPreSealSamples/GetIncompleteAndAmendQcPreSealSamples',
        params: state => ({
            userId: userIdSelector(state),
            isAmend: true
        }),
        fields: {
            qcRef: 'QC Ref',
            jwpNo: 'JWP No',
            styleRef: 'Style',
            colour: 'Colour'
        },
        selector: () => false
    },
    [SEAL_SAMPLE_MAIL_GROUP]: {
        url: 'PreSealQcEmailGroup'
    },
    [SEAL_SAMPLE_MAIL_TEMPLATE]: {
        url: 'PreSealQcEmailBody'
    },
    [SEAL_SAMPLE_TYPES]: {
        url: 'QcTypesOfSample'
    },
    [SEAL_SAMPLE_STATUS]: {
        url: 'QcStatus'
    }
};
