export default ({ palette, spacing }) => ({
    root: {
        borderTop: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: 0,
        height: spacing.unit * 55,
    },
    lastPage: {
        height: spacing.unit * 60,
    },
    scroll: {
        overflowY: 'scroll',
    },
});
