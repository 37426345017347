import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing, palette, typography }) => ({
    success: {
        marginTop: spacing.unit * 1.25,
        color: palette.success.dark,
        fontSize: spacing.unit * 1.75
    }
});

const propTypes = {
    message: PropTypes.string.isRequired
};

const Success = withStyles(styles)(({ classes: c, message, styles }) => {
    return (
        <Typography className={c.success} style={styles}>
            {message}
        </Typography>
    );
});

Success.propTypes = propTypes;

export { Success as default, Success };
