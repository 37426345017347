import React from 'react';
import { View } from '@react-pdf/renderer';
import { string, element } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Image from './Image';

const styles = ({ spacing, palette }) => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    contentContainer: {
        width: '80%',
        minHeight: spacing.unit * 12
    },
    imageContainer: {
        width: '18%',
        height: '100%',
        minHeight: spacing.unit * 12,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: spacing.unit * 0.75,

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    }
});

const propTypes = {
    children: element.isRequired,
    front: string,
    back: string
};

const BasicContentContainer = withPDFStyles(styles)(
    ({ classes: c, children, front, back }) => {
        return (
            <View style={c.container}>
                <View style={c.contentContainer}>{children}</View>
                <View style={c.imageContainer}>
                    <Image url={front} name='Front' />
                    <Image url={back} name='Back' />
                </View>
            </View>
        );
    }
);

BasicContentContainer.propTypes = propTypes;

export default BasicContentContainer;
