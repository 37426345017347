import {
    FETCH_CAD_GRAPHIC_REF_AMEND_SUCCESS,
    FETCH_CAD_GRAPHIC_REF_INCOMPLETE_SUCCESS
} from 'actionTypes';

const cadReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case FETCH_CAD_GRAPHIC_REF_AMEND_SUCCESS: {
            return { ...state, amendGraphicReferences: payload };
        }

        case FETCH_CAD_GRAPHIC_REF_INCOMPLETE_SUCCESS: {
            return { ...state, incompleteGraphicReferences: payload };
        }

        default: {
            return state;
        }
    }
};

export { cadReducer as default, cadReducer };
