import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import cloneDeep from 'lodash/cloneDeep';

import { DOMAIN_NAME } from './common';
import { FORM_NAME, GENERAL_USER_ROLE_ID } from './User/common';

const administratorSelector = state => state[DOMAIN_NAME];
const userFormSelector = formValueSelector(FORM_NAME);

const isGeneralUserRoleSelector = createSelector(
    state => userFormSelector(state, 'role'),
    id => id === GENERAL_USER_ROLE_ID
);

const userPermissionsLoadingSelector = createSelector(
    administratorSelector,
    ({ userPermissionsLoading }) => userPermissionsLoading
);

const userPermissionsDataSelector = createSelector(
    administratorSelector,
    ({ userPermissions }) => userPermissions
);

const userMenuPermissionsDataSelector = createSelector(
    userPermissionsDataSelector,
    ({ menus = [] }) => {
        const copyMenus = cloneDeep(menus);
        const menuMap = new Map();
        const rootMenu = [];

        // Create a menuMap for quick access using menuId as the key
        copyMenus.forEach(item => {
            item.children = [];
            menuMap.set(item.menuId, item);
        });

        // Build the tree structure and rename keys
        copyMenus.forEach(item => {
            if (item.menuId === item.parentMenuId) {
                // If menuId and parentMenuId are the same, add to the root
                rootMenu.push({
                    id: item.menuId,
                    name: item.menu,
                    parentId: item.parentMenuId,
                    children: item.children
                });
            } else {
                // If not the same, add it to its parent's children array and rename keys
                if (menuMap.has(item.parentMenuId)) {
                    menuMap.get(item.parentMenuId).children.push({
                        id: item.menuId,
                        name: item.menu,
                        parentId: item.parentMenuId,
                        children: item.children
                    });
                }
            }
        });

        return rootMenu;
    }
);

const userRetailerPermissionsDataSelector = createSelector(
    userPermissionsDataSelector,
    ({ retailers = [] }) =>
        retailers.map(({ retailerId, retailer }) => ({
            id: retailerId,
            name: retailer
        }))
);

const userSampleFactoryPermissionsDataSelector = createSelector(
    userPermissionsDataSelector,
    ({ sampleFactories = [] }) =>
        sampleFactories.map(({ sampleFactoryId, sampleFactory }) => ({
            id: sampleFactoryId,
            name: sampleFactory
        }))
);

const userOrderFactoryPermissionsDataSelector = createSelector(
    userPermissionsDataSelector,
    ({ orderFactories = [] }) =>
        orderFactories.map(({ orderFactoryId, orderFactory }) => ({
            id: orderFactoryId,
            name: orderFactory
        }))
);

const copyUserPermissionsDataLoadingSelector = createSelector(
    administratorSelector,
    ({ copyUserPermissionsLoading }) => copyUserPermissionsLoading
);

const exportUserExcelDataLoadingSelector = createSelector(
    administratorSelector,
    ({ exportUserExcelDataLoading }) => exportUserExcelDataLoading
);

export {
    userFormSelector,
    isGeneralUserRoleSelector,
    userPermissionsLoadingSelector,
    userPermissionsDataSelector,
    userMenuPermissionsDataSelector,
    userRetailerPermissionsDataSelector,
    userSampleFactoryPermissionsDataSelector,
    userOrderFactoryPermissionsDataSelector,
    copyUserPermissionsDataLoadingSelector,
    exportUserExcelDataLoadingSelector
};
