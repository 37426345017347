import React, { useMemo, useState, useCallback } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import SplitButton from '@libComponents/SplitButton';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { CancelDialog as ResetFormConfirmDialog } from '@formComponents/CancelDialog';

// App
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

// Domain
import { useOrderState } from '../useOrder';
import { DOMAIN_NAME, FORM_NAME } from '../common';
import { PaginatedSelect } from '@formComponents/PaginatedSelect';
import { INCOMPLETE_ORDERS, AMEND_ORDERS } from '../../redux.datasets';
import {
    getAmendOrderData,
    getIncompleteOrderData,
    resetOrderForm
} from '../../redux.actions';
import Preview from '../components/Report/Preview';

const styles = ({ palette, spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },

    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    },
    dropdownButton: {
        height: spacing.unit * 4.5
    },
    // ----- Paginated Select
    selectWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabel: {
        fontWeight: typography.fontWeightHeavy,
        marginRight: spacing.unit
    },
    selectContainerWrapper: {
        width: spacing.unit * 31
    },
    selectContainer: {
        paddingBottom: 0
    },
    selectControl: {
        height: spacing.unit * 4.5,
        backgroundColor: palette.common.white
    }
});

const mapInputs = {
    incompleteOrder: ({ payload }) => getIncompleteOrderData(payload),
    amendOrder: ({ payload }) => getAmendOrderData(payload)
};

const mapDispatch = {
    resetOrderForm
};

const HeaderAdornment = compose(
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        null,
        mapDispatch
    ),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, handleModal, resetOrderForm }) => {
    const {
        shouldFetchData,
        shouldSave,
        canSave,
        handleSave,
        mode,
        shouldPreview,
        handlePreviewModal,
        shouldStatusChange,
        canStatusChange,
        handleHold,
        handleCancel,
        canSoftPublish,
        handleSoftPublish,
        shouldSoftPublish,
        reset,
        canResetForm
    } = useOrderState();

    const [open, setOpen] = useState(false);

    const handleResetDialogOpen = useCallback(() => setOpen(true), []);

    const handleResetDialogClose = useCallback(() => setOpen(false), []);

    const handleResetConfirm = useCallback(() => {
        resetOrderForm();
        reset();
        handleResetDialogClose();
    }, [handleResetDialogClose, resetOrderForm, reset]);

    const handleCancelWithPreview = useMemo(
        () => event => {
            event.persist();
            handleModal({
                handlePublish: handleCancel,
                mode,
                statusAction: 1
            });
        },
        [handleCancel, handleModal, mode]
    );

    const handleHoldWithPreview = useMemo(
        () => event => {
            event.persist();
            handleModal({
                handlePublish: handleHold,
                mode,
                statusAction: 2
            });
        },
        [handleHold, handleModal, mode]
    );

    const paginatedSelectProps = useMemo(
        () => ({
            styles: {
                container: c.selectContainer,
                control: c.selectControl
            },
            label: null
        }),
        []
    );

    const statusOptions = useMemo(
        () => [
            { label: 'Cancel Order', callback: handleCancelWithPreview },
            { label: 'Hold Order', callback: handleHoldWithPreview }
        ],
        [handleHoldWithPreview, handleCancelWithPreview]
    );

    return (
        <div className={c.container}>
            {shouldFetchData && (
                <div className={c.selectWrapper}>
                    <Typography variant='subtitle1' className={c.selectLabel}>
                        Select order no:
                    </Typography>
                    <div className={c.selectContainerWrapper}>
                        {mode === 'amend' ? (
                            <PaginatedSelect
                                name='amendOrder'
                                entity={AMEND_ORDERS}
                                domain={DOMAIN_NAME}
                                {...paginatedSelectProps}
                            />
                        ) : (
                            <PaginatedSelect
                                name='incompleteOrder'
                                entity={INCOMPLETE_ORDERS}
                                domain={DOMAIN_NAME}
                                {...paginatedSelectProps}
                            />
                        )}
                    </div>
                </div>
            )}

            <Button
                className={c.button}
                color='error'
                onClick={handleResetDialogOpen}
                disabled={!canResetForm}
            >
                Reset Form
            </Button>

            {shouldSave && (
                <Button
                    className={c.button}
                    color='success'
                    onClick={handleSave}
                    disabled={!canSave}
                >
                    Save Draft
                </Button>
            )}
            {shouldSoftPublish && (
                <Button
                    className={c.button}
                    onClick={handleSoftPublish}
                    color='success'
                    disabled={!canSoftPublish}
                >
                    Soft Publish
                </Button>
            )}
            {shouldPreview && (
                <Button
                    className={c.button}
                    onClick={() => handlePreviewModal()}
                >
                    Preview
                </Button>
            )}
            {shouldStatusChange && (
                <SplitButton
                    className={c.button}
                    color='error'
                    options={statusOptions}
                    disabled={!canStatusChange}
                />
            )}
            <ResetFormConfirmDialog
                open={open}
                onClose={handleResetDialogClose}
                onConfirm={handleResetConfirm}
            />
        </div>
    );
});

export default HeaderAdornment;
