import { useMemo } from 'react';
import { denormalizeDate } from '@baseComponents/Date/normalizeDate';

const useMinDate = from => {
    return useMemo(() => {
        const daysBuffer = 1;
        const timeFrom = new Date(denormalizeDate(from)).getTime();
        const timeBuffer = 1000 * 60 * 60 * 24 * daysBuffer;
        const timeMin = timeFrom + timeBuffer;
        return new Date(timeMin);
    }, [from]);
};
export default useMinDate;
