import React from 'react';
import { withStyles } from '@material-ui/core';

import Image from './Image';
import ImageLoader from './ImageLoader';
import { useLoadedImage } from '../../hooks';

const styles = () => ({
    image: {
        margin: '8px 0px',
        width: '66px',
        height: '52px',
        objectFit: 'cover'
    }
});

const NoImage = withStyles(styles)(({ url, classes: c }) => {
    const loadedImage = useLoadedImage({ image: url });

    return loadedImage ? (
        <Image url={url} filename={'no image'} className={c.image} />
    ) : (
        <ImageLoader />
    );
});

export { NoImage as default, NoImage };
