import { select, all } from 'redux-saga/effects';

import { formValueSelector } from 'redux-form';
import { renameProperties } from '@libs/object';
import { composeDevelopmentNumber } from '@libs/publishWorkers';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';

import { userIdSelector } from 'auth/redux.selectors';

import { developmentTypesSelector } from 'common/redux.selectors';
import { FORM_NAME } from '../../../Shape/common';

export default function*({ mode, id, sampleReference }) {
    const [
        userId,
        {
            developmentNumber,
            fabrics = [],
            boughtDetails = [],
            trimDetails = [],
            dateRequested,
            dateRequiredBy,
            ...values
        },
        developmentTypes
    ] = yield all([
        select(userIdSelector),
        select(
            formValueSelector(FORM_NAME),
            'gender',
            'age',
            'category',
            'department',
            'style',
            'country',
            'designDepartment',
            'developmentNumber',
            'dateRequested',
            'dateRequiredBy',
            'sendToEcho',
            'sendToBuyer',
            'buyer',
            'category',
            'season',
            'fitOption',
            'styleDescription',
            'departmentNumber',
            'referenceFrom',
            'sizeRange',
            'neckline',
            'waistband',
            'armhole',
            'hemline',
            'labelType',
            'specReference',
            'specDescription',
            'additionalComments',
            'photoApproval',
            'pendingPrint',
            'fabrics',
            'boughtDetails',
            'trimDetails'
        ),
        select(developmentTypesSelector, 'Non Print')
    ]);

    const developmentNo = composeDevelopmentNumber(
        developmentTypes,
        developmentNumber,
        mode
    );

    return renameProperties(
        {
            ...values,
            userId,
            id,
            sampleReference,
            developmentNo,
            requestedDate: deserializeDate(dateRequested),
            sampleRequiredDate: deserializeDate(dateRequiredBy),
            fabrics: fabrics.map(fabric =>
                renameProperties(fabric, {
                    fabricFor: 'fabricForId',
                    fabricType: 'fabricTypeId',
                    composition: 'compositionId',
                    finishedGsm: 'gsmId',
                    dyeType: 'dyeTypeId',
                    specialFinish: 'specialFinishId',
                    isTc: 'istc'
                })
            ),
            boughts: boughtDetails.map(({ id, boughtSampleRef }) => ({
                id,
                boughtRef: boughtSampleRef
            })),
            trims: trimDetails.map(
                ({ id, trimCardReference, colour, quantity }) => ({
                    id,
                    trimCardRef: trimCardReference,
                    colour,
                    quantity
                })
            )
        },
        {
            sampleReference: 'sampleRef',
            gender: 'genderId',
            age: 'ageId',
            category: 'categoryId',
            department: 'departmentId',
            style: 'styleId',
            country: 'countryId',
            designDepartment: 'designDepartmentId',
            sendToEcho: 'ukSizeId',
            sendToBuyer: 'buyerSizeId',
            buyer: 'departmentContactPerson',
            season: 'seasonId',
            fitOption: 'fitId',
            referenceFrom: 'specRefFrom',
            sizeRange: 'sizeRangeId',
            neckline: 'necklineId',
            waistband: 'waistBandId',
            armhole: 'armholesCuffsId',
            hemline: 'hemlineId',
            labelType: 'labelTypeId',
            specReference: 'specRef',
            styleDescription: 'garmentDescription', // came from ines and marion
            specDescription: 'styleDescription', // came from ines and marion
            additionalComments: 'specComments',
            photoApproval: 'isSampleRequired',
            pendingPrint: 'isPendingPrint'
        }
    );
}
