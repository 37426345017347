import { useState, useEffect } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';

import PDF from './PDF';

export const usePDFReport = ({ fileName, props, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);

    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document: PDF,
        fileName,
        props,
        loadProps: props => props,
        errorResolver: error => setPDFError(error),
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    return [handleExport, exportLoading, exportDisabled];
};
