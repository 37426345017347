import { userIdSelector } from 'auth/redux.selectors';

export const INCOMPLETE_FABRICS = '@incompleteFabrics';
export const AMEND_FABRICS = '@amendFabrics';
export const DEVELOPMENT_TYPE_SENT = '@developmentTypeSent';
export const REQUEST_BY = '@requestBy';
export const HANGER_TYPE = '@hangerType';
export const YARN_COUNT = '@yarnCount';
export const YARN_SUPPLIER = '@yarnSupplier';
export const PILLING_METHOD = '@pillingMethod';
export const DRY_PROCESS = '@dryProcess';
export const APPROVAL_STATUS = '@approvalStatus';
export const APPROVED_BY = '@approvedBy';

// Fabric development data grid datasets
export const FD_GRID_DATA = '@FDGridData';
export const FD_GRID_PREVIEW_DATA = '@FDGridPreviewData';

const getFabrics = (amend = false) => ({
    url: 'FabricDevelopment/GetDropdownFabricDevelopments',
    params: state => ({
        userId: userIdSelector(state),
        isAmend: amend
    }),
    fields: {
        fabricReference: 'Fabric Ref',
        retailer: 'Retailer'
    },
    selector: () => false
});

export default {
    [INCOMPLETE_FABRICS]: getFabrics(),
    [AMEND_FABRICS]: getFabrics(true),
    [DEVELOPMENT_TYPE_SENT]: {
        url: 'TypeSent'
    },
    [REQUEST_BY]: {
        url: 'RequestBy'
    },
    [HANGER_TYPE]: {
        url: 'HangerType'
    },
    [YARN_COUNT]: {
        url: 'YarnCount'
    },
    [YARN_SUPPLIER]: {
        url: 'YarnSupplier'
    },
    [PILLING_METHOD]: {
        url: 'PillingMethod'
    },
    [DRY_PROCESS]: {
        url: 'DryProcess'
    },
    [APPROVAL_STATUS]: {
        url: 'FabricDevelopmentApproval'
    },
    [APPROVED_BY]: {
        url: 'FabricDevelopmentApproved'
    }
};
