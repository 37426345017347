import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, FormControl } from '@material-ui/core';

// Local
import Label from '@baseComponents/Label';
import { composeClsx } from '@libs/materialUI';
import EditorBase from '@baseComponents/Editor';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    staticValue: PropTypes.string
};

const defaultProps = {
    required: false,
    staticValue: '',
    disabled: false
};

const styles = ({ palette }) => ({
    container: {
        width: '100%'
    },
    editor: {
        '& > .ql-toolbar': {
            backgroundColor: palette.background.light,
            '&.ql-snow': {
                borderColor: 'var(--editorBorderColor)'
            }
        },
        '& > .ql-container': {
            '&.ql-snow': {
                borderColor: 'var(--editorBorderColor)'
            }
        }
    }
});

const Editor = withStyles(styles, { withTheme: true })(
    ({
        classes,
        styles,
        className,
        disabled,
        label,
        loading,
        staticValue,
        ...restProps
    }) => {
        const c = composeClsx({ classes, styles });

        return (
            <FormControl className={c.container}>
                {label && (
                    <Label
                        label={label}
                        disabled={disabled || loading}
                        required={restProps.required}
                    />
                )}
                <EditorBase
                    className={c.editor}
                    readOnly={disabled}
                    {...restProps}
                />
            </FormControl>
        );
    }
);

Editor.propTypes = propTypes;
Editor.defaultProps = defaultProps;

export { Editor as default, Editor };
