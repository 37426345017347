import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';
import { userIdSelector } from 'auth/redux.selectors';

import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../SealSample/common';

export default function*({ id, qcReference }) {
    const [userId, { qcDate, sealDate, ...values }] = yield all([
        select(userIdSelector),
        select(
            formValueSelector(FORM_NAME),
            'jwpNo',
            'qcStyle',
            'qcColor',
            'qcDate',
            'sealDate',
            'finishWash',
            'fabricBatch',
            'prodUnit',
            'panelStatus',
            'bulkProductionUnit',
            'bodyFabricStatus',
            'sampleColorStatus',
            'trimFabricStatus',
            'accAndTrimStatus',
            'missingTrims',
            'embellishmentStatus',
            'washStatus',
            'stichingStatus',
            'washShrinkage',
            'printShrinkage',
            'spirality',
            'foundGSM',
            'patternStatus',
            'fitStatus',
            'typeOfSample',
            'commentsFromBuyer',
            'generalInstructions',
            'sealOrTrimCardVerification',
            'patternFitComments',
            'specialInstructions',
            'workmanshipComments',
            'measurementComments',
            'preventiveCorrective',
            'fabricCTQ',
            'embellishmentCTQ',
            'accTrimsCTQ',
            'CPRTWashCTQ',
            'cuttingCTQ',
            'finishingPackingCTQ',
            'sewingCTQ',
            'bulkProductionComplicacy',
            'sampleQTY',
            'sampleReviewStatus',
            'rejectQTY',
            'dhu',
            'tdr',
            'remarks'
        )
    ]);

    return renameProperties(
        {
            ...values,
            userId,
            id,
            qcReference,
            qcDate: deserializeDate(qcDate),
            sealByDate: deserializeDate(sealDate)
        },
        {
            qcReference: 'qcRef',
            qcStyle: 'styleRef',
            qcColor: 'colorId',
            finishWash: 'finishWashId',
            prodUnit: 'ftyPrdUnit',
            panelStatus: 'prewashPanel',
            bodyFabricStatus: 'bodyFabric',
            sampleColorStatus: 'sampleColorStatusId',
            trimFabricStatus: 'trimFabric',
            accAndTrimStatus: 'accTrim',
            embellishmentStatus: 'emblishment',
            stichingStatus: 'styling',
            washStatus: 'colorShade',
            spirality: 'spiralityPercent',
            foundGSM: 'foundGsm',
            patternStatus: 'pattern',
            typeOfSample: 'typesOfSample',
            commentsFromBuyer: 'clarificationFromBuyer',
            generalInstructions: 'generalInstruction',
            sealOrTrimCardVerification: 'sealVerification',
            patternFitComments: 'patternFit',
            specialInstructions: 'specialComments',
            preventiveCorrective: 'preventiveAction',
            embellishmentCTQ: 'emblishmentCTQ',
            accTrimsCTQ: 'accTrimCTQ',
            CPRTWashCTQ: 'cprtwashCTQ',
            sampleQTY: 'sampleQty',
            rejectQTY: 'rejectQty',
            tdr: 'thDefectRate'
        }
    );
}
