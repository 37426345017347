import { startSubmit } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { userIdSelector } from 'auth/redux.selectors';
import { setDataset } from '@libs/datasets/libs.actions';

import { DOMAIN_NAME } from 'setup/common';
import { PO_SIZE_SET } from 'setup/redux.datasets';

import { FORM_NAME } from '../../Order/POSizeSet/common';
import { POSizeSetSchema } from './schemas';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { isEdit = false, sizes, ...payload }
    } = action;

    const resolveId = isEdit ? 1 : 0; // Since this worker expect the id to be 0 or real id

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            sizes: sizes.map((size, idx) => ({
                ...size,
                slNo: idx + 1
            })),
            retailerId: payload.retailer,
            isActive: resolveId ? payload.isActive : true,
            userId: yield select(userIdSelector)
        };

        const publishData = composeApiPayload(preparedPayload, POSizeSetSchema);

        const { data } = yield call(apiRequest, {
            url: 'PoSizes/AddPoSize',
            method: 'put',
            data: publishData
        });

        yield put(
            setDataset({
                entity: PO_SIZE_SET,
                domain: DOMAIN_NAME,
                dataset: data
            })
        );

        yield* setupFormHandleSuccessWorker(
            resolveId,
            handleCloseModal,
            'PO size set',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, resolveId, FORM_NAME);
    }
}
