import React, { Fragment, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import { userMenuPermissionsDataSelector } from 'administrator/redux.selectors';
import PermissionTabsTableHeader from './PermissionTabsTableHeader';
import PermissionTabsTableRow from './PermissionTabsTableRow';
import useRolePermissions from '../useRolePermissions';

const styles = () => ({});

const mapState = state => ({
    menus: userMenuPermissionsDataSelector(state)
});

const MenuPermissionTab = compose(
    connect(mapState),
    withStyles(styles)
)(({ menus }) => {
    const [
        { expandedRows, menuAllSelected, permissions },
        dispatch
    ] = useRolePermissions();

    const toggleExpandRowHandler = useCallback(rowId => {
        dispatch({ type: 'TOGGLE_EXPAND_ROW', payload: rowId });
    }, []);

    const toggleSelectionHandler = useCallback(rowId => {
        dispatch({ type: 'TOGGLE_MENU_SELECTION', payload: rowId });
    }, []);

    const toggleAllSelectionHandler = useCallback(() => {
        dispatch({ type: 'TOGGLE_MENU_SELECTION', payload: 'ALL' });
    }, []);

    const isRowSelectedHandler = useCallback(
        rowId => permissions.menus.includes(rowId),
        [permissions.menus]
    );

    return (
        <Fragment>
            <PermissionTabsTableHeader
                selectAll={menuAllSelected}
                onToggleAllSelection={toggleAllSelectionHandler}
            />
            {menus.map(row => {
                return (
                    <PermissionTabsTableRow
                        row={row}
                        key={row.id}
                        isRowSelected={isRowSelectedHandler}
                        expandedRows={expandedRows}
                        onToggleExpandRow={toggleExpandRowHandler}
                        onToggleSelection={toggleSelectionHandler}
                    />
                );
            })}
        </Fragment>
    );
});

export default MenuPermissionTab;
