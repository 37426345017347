// const baseURL = 'https://18.130.139.199/echoapi';
// const baseURL = 'https://18.130.139.199/echoapi';
const baseURL = 'http://185.38.37.19/echoapi';

const baseConfig = {
    baseURL,
    header: { 'Content-Type': 'application/json' },
    timeout: 30000
};

export { baseConfig as default, baseURL };
