import { formValueSelector, change } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import { renameProperties } from '@libs/object';
import composeApiPayload from '@libs/composeApiPayload';

import { userIdSelector } from 'auth/redux.selectors';

// Domain
import { FORM_NAME } from 'sampling/Print/common';

// Local
import { generateReferenceSchema } from './printPublishWorker/schemas';
import { setSampleReferenceLoading } from 'sampling/redux.actions';

export default function*({ meta: { loader = false } = {} } = {}) {
    try {
        const [userId, values] = yield all([
            select(userIdSelector),
            select(
                formValueSelector(FORM_NAME),
                'cad',
                'version',
                'cadDescription',
                'designSource',
                'retailer',
                'gender',
                'age',
                'category',
                'department',
                'style',
                'country',
                'designDepartment',
                'copyReference',
                'manualReference'
            )
        ]);

        const payload = renameProperties(
            {
                userId,
                ...values
            },
            {
                cad: 'cadRef',
                version: 'cadVersion',
                cadDescription: 'graphicDescription',
                designSource: 'designerFromId',
                retailer: 'retailerId',
                gender: 'genderId',
                age: 'ageId',
                category: 'categoryId',
                department: 'departmentId',
                style: 'styleId',
                designDepartment: 'designDepartmentId',
                country: 'countryId',
                copyReference: 'copySampleRef',
                manualReference: 'manualRef'
            }
        );

        if (loader) {
            yield put(setSampleReferenceLoading(true));
        }
        const {
            data: { sampleRef, id }
        } = yield call(apiRequest, {
            method: 'post',
            url: 'Sample/GeneratePrintSampleReference',
            data: composeApiPayload(payload, generateReferenceSchema)
        });
        if (loader) {
            yield put(setSampleReferenceLoading(false));
        }
        yield put(change(FORM_NAME, 'sampleReference', sampleRef));
        yield put(change(FORM_NAME, 'id', id));
        return { sampleReference: sampleRef, id };
    } catch (error) {
        if (loader) {
            yield put(setSampleReferenceLoading(false));
        }
        console.log('Error while generating print reference');
    }
}
