import React from 'react';
import classNames from 'classnames';

// Material
import { withStyles, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Local
import styles from './styles';

const AddHandlerContainer = ({ classes: c, children, ...restProps }) => {
    return (
        <div className={c.container}>
            <div className={c.select}>{children}</div>
            <div className={c.buttonContainer}>
                <Button
                    className={c.button}
                    variant={'outlined'}
                    {...restProps}
                >
                    <AddIcon className={c.icon} />
                </Button>
            </div>
        </div>
    );
};

const _AddHandlerContainer = withStyles(styles)(AddHandlerContainer);

export {
    _AddHandlerContainer as default,
    _AddHandlerContainer as AddHandlerContainer,
};
