import React, { memo } from 'react';
import { withStyles, Typography } from '@material-ui/core';

const styles = ({ spacing }) => ({
    gridPageInformation: {
        fontSize: spacing.unit * 1.63
    }
});

const PageInformation = memo(
    ({ classes, currentPage, totalPages, totalCount }) => {
        const itemCountText = totalCount > 1 ? 'items' : 'item';
        return (
            <Typography className={classes.gridPageInformation}>
                {`Page ${currentPage} of ${totalPages} (${totalCount} ${itemCountText})`}
            </Typography>
        );
    }
);

export default withStyles(styles)(PageInformation);
