const DOMAIN_NAME = 'cadUpload';
const FORM_NAME = 'cadUpload';

const LABELS = {
    basicInformation: 'Basic Information',
    uploadCad: 'Upload CAD',
    editCad: 'Edit CAD',
    amendCad: 'Amend CAD',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    developmentType: 204,
    cadReference: 'NEW'
};

const BUYERID = 48;
const NEW_CAD_ROUTE_ID = 414;
const INCOMPLETE_CAD_ROUTE_ID = 270;
const AMEND_CAD_ROUTE_ID = 271;

export {
    DOMAIN_NAME,
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    BUYERID,
    NEW_CAD_ROUTE_ID,
    INCOMPLETE_CAD_ROUTE_ID,
    AMEND_CAD_ROUTE_ID
};
