import {arrowGenerator} from 'helpers';

export default theme => ({
    loader: {
        color: '#ffffff',
        position: 'absolute',
    },
    textField: {
        marginBottom: theme.spacing.unit * 2.5,
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative',
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important',
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px',
    },
    labelFileField: {
        '& + div': {
            display: 'none',
        },
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        borderRadius: 0,
        height: 43,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))',
    },
    browseButtonRoot: {
        border: '1px solid #e6e6e6',
        borderRadius: 4,
        height: 45,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex',
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 45,
        flexGrow: 1,
        lineHeight: '45px',
        paddingLeft: 15,
        '& span:not(:first-child):before': {
            content: '", "',
        },
    },
    attachemntTable: {
        backgroundColor: '#fcfcfc',
        border: '1px solid #e4e4e4',
        marginTop: 10,
        '& thead': {
            '& tr': {
                height: 40,
                backgroundColor: '#f4f4f4',
                '& th': {
                    '&:first-child': {
                        width: 100,
                    },
                    color: 'black',
                    paddingRight: 0,
                    paddingLeft: 13,
                    fontSize: '0.875rem',
                    '&:last-child': {
                        paddingRight: '13px !important',
                        width: 300,
                    },
                },
            },
        },
        '& tbody': {
            '& tr': {
                height: 28,
                '& td': {
                    padding: 13,
                    '&:first-child > img': {
                        maxHeight: 40,
                    },
                    '&:nth-child(2)': {
                        verticalAlign: 'top',
                        color: '#387ff5',
                        '& > p': {
                            height: 40,
                            lineHeight: '20px',
                            overflow: 'hidden',
                        },
                    },
                },
            },
        },
    },
    attachemntTableHeaderButton: {
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        marginLeft: 10,
        opacity: 0.5,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            boxShadow: 'none',
            opacity: 1,
        },
    },
    attachemntTableHeaderButtonIcon: {
        marginRight: 5,
    },
    customSvgIcon: {
        padding: 6,
    },
    tooltipTriggerIcon: {
        marginLeft: 10,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1,
        },
    },
    arrowPopper: arrowGenerator('white'),
    arrow: {
        position: 'absolute',
        border: '1px solid rgab(0, 0, 0, .1)',
        fontSize: 6,
        boxShadow: '0px 3px 21px 0px rgba(0, 4, 52, 0.11)',
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    arrowLabel: {
        boxShadow: '0px 0px 7px 0px rgba(0, 2, 53, 0.29)',
        color: 'black',
        borderRadius: 0,
        backgroundColor: 'white',
        padding: '6px 10px',
        fontSize: '.9rem',
    },
    dialogPaper: {
        boxShadow: '0px 0px 21px 0px rgba(0, 4, 52, 0.27)',
    },
    dialogContentRoot: {
        margin: 0,
        padding: '20px 20px 0',
    },
    dialogActionsRoot: {
        padding: 10,
    },
    blackButton: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000',
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000',
        },
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1',
        },
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important',
    },
    editorLabel: {
        height: '35px',
        fontWeight: 'bold',
        lineHeight: '35px',
        marginBottom: '10px',
    },
});
