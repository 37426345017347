import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@libComponents/Stepper';
//import { Stepper } from '@formComponents/Stepper';
import ShapeContainer from './ShapeContainer';
import { useContent } from './hooks';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from './common';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'incomplete', 'amend'])
};
const defaultProps = {
    mode: 'new'
};

const Shape = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ mode, handleSubmit: handleReduxFormSubmit }) => {
    return (
        <Stepper
            content={useContent(mode)}
            ContainerComponent={ShapeContainer}
            ContainerProps={{ mode, handleReduxFormSubmit }}
        />
    );
});

Shape.propTypes = propTypes;
Shape.defaultProps = defaultProps;

export { Shape as default, Shape };
