import React from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    Paper,
    Typography,
    DialogContent as MuiDialogContent
} from '@material-ui/core';

import SelectMultipleRows from './SelectMultipleRows';

const propTypes = {
    updatingRowId: PropTypes.string.isRequired,
    mutationText: PropTypes.string.isRequired
};

const styles = ({ spacing, shadows, palette }) => ({
    contentContainer: {
        borderTopWidth: 1,
        borderTopColor: palette.background.dark,
        borderTopStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,
        borderBottomStyle: 'solid'
    },
    confirmText: {
        fontSize: spacing.unit * 1.75,
        fontWeight: 400,
        color: palette.grey[800],
        lineHeight: 1.5,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2
    },
    formRoot: {
        boxShadow: shadows[0]
    },
    fieldLabel: {
        color: palette.grey[800],
        marginBottom: spacing.unit * 0.75
    },
    formControl: {
        minHeight: `${spacing.unit * 5}px !important`,
        maxHeight: spacing.unit * 15,
        overflow: 'auto'
    }
});

const DialogContent = withStyles(styles)(
    ({ classes: c, updatingRowId, mutationText }) => {
        return (
            <MuiDialogContent className={c.contentContainer}>
                <Typography className={c.confirmText}>
                    {`Please confirm you are changing the ${mutationText}`}
                </Typography>

                <Paper className={c.formRoot}>
                    <Typography className={c.fieldLabel}>
                        Apply the same value to other POs?
                    </Typography>
                    <SelectMultipleRows
                        defaultValues={[updatingRowId]}
                        styles={{ control: c.formControl }}
                    />
                </Paper>
            </MuiDialogContent>
        );
    }
);

DialogContent.propTypes = propTypes;

export default DialogContent;
