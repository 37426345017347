import React, { useMemo } from 'react';
import { Page, Document, View, Text } from '@react-pdf/renderer';
import { string, object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import {
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT,
    FONT_FAMILY_NORMAL,
    FONT_FAMILY_BOLD
} from 'common/constants';
import { Title } from 'components/PDF';

import DevContent from './DevContent';
import QCContent from './QCContent';

const propTypes = {
    pageTitle: string.isRequired,
    docTitle: string.isRequired,
    setup: object.isRequired,
    details: object.isRequired,
    others: object.isRequired,
    watermark: string
};

const defaultProps = {
    docTitle: 'Document',
    pageTitle: 'PDF Page'
};

const styles = ({ palette, spacing }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2,
        fontFamily: FONT_FAMILY_NORMAL
    },
    pageTitle: { fontSize: 14, fontFamily: FONT_FAMILY_BOLD, borderBottom: 1 },

    watermarkContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',

        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    watermarkText: {
        position: 'relative',
        transform: 'rotate(-45deg)',
        textTransform: 'uppercase',
        color: palette.error.main,
        opacity: 0.6,
        fontSize: spacing.unit * 8
    }
});

const PDFContent = withPDFStyles(styles)(
    ({ classes: c, pageTitle, docTitle, watermark, isQA, ...props }) => {
        const renderWatermark = () => (
            <View style={c.watermarkContainer}>
                <Text style={c.watermarkText}>{watermark}</Text>
            </View>
        );

        const pdfPageProps = useMemo(
            () => ({
                size: 'A4',
                orientation: 'landscape',
                style: c.page
            }),
            [c]
        );

        return (
            <Document title={docTitle}>
                <Page {...pdfPageProps}>
                    <View>
                        <Title text={pageTitle} style={c.pageTitle} />
                        {isQA ? (
                            <QCContent {...props} />
                        ) : (
                            <DevContent {...props} />
                        )}
                    </View>
                    {!!watermark && renderWatermark()}
                </Page>
            </Document>
        );
    }
);

PDFContent.propTypes = propTypes;
PDFContent.defaultProps = defaultProps;

export default PDFContent;
