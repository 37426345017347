import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

import { SelectBase } from 'components';

const styles = ({ spacing }) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        backgroundColor: '#fff',
        width: 'auto !important'
    },
    control: {
        height: spacing.unitHeight,
        minWidth: spacing.unit * 12,
        borderTopLeftRadius: `0 !important`,
        borderBottomLeftRadius: `0 !important`
    },
    text: {
        color: '#333333',
        fontSize: spacing.unit * 1.63,
        marginRight: spacing.unit * 1.25
    },
    placeholder: {
        paddingRight: spacing.unit * 2
    }
});

const SelectPageSize = withStyles(styles)(
    ({ pageSize, handlePageSize, classes }) => {
        const { wrapper, text, ...c } = classes;
        const selectProps = {
            name: 'void0',
            data: [
                { value: 10, label: 10 },
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 250, label: 250 }
            ],
            input: {
                onFocus: () => {},
                onBlur: () => {},
                onChange: e => handlePageSize(e.target.value),
                value: pageSize
            },
            showSuccess: false,
            styles: c,
            listMaxNumber: 3
        };
        return (
            <div className={wrapper}>
                <Typography className={text}>Page Size</Typography>
                <SelectBase {...selectProps} />
            </div>
        );
    }
);

export default SelectPageSize;
