import React from 'react';
import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';

const styles = ({ spacing, typography }) => ({
    container: { display: 'flex', alignItems: 'center' },
    button: {
        height: spacing.unit * 4.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        marginLeft: spacing.unit
    }
});

const ActionsAdornment = withStyles(styles)(
    React.memo(
        ({ classes: c, loading, handleMailModal, handlePreviewModalClose }) => {
            return (
                <div className={c.container}>
                    <Button
                        className={c.button}
                        onClick={handleMailModal}
                        disabled={loading}
                    >
                        Email
                    </Button>
                    <Button
                        color='error'
                        className={c.button}
                        onClick={handlePreviewModalClose}
                    >
                        Close
                    </Button>
                </div>
            );
        }
    )
);

export default ActionsAdornment;
