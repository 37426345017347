import React, { memo } from 'react';
import clsx from 'classnames';
import { withStyles, List, ListItem, ListItemText } from '@material-ui/core';

const styles = ({ palette, spacing, transitions }) => ({
    gridPaginationNavContainer: {
        display: 'flex',
        background: '#fff',
        padding: 0,

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    gridPaginationItemGutters: {
        padding: 0,
        '&:not(:last-child)': {
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: palette.background.dark
        }
    },
    gridPaginationItemButton: {
        transition: transitions.create(['background-color']),
        transitionDuration: '.3s',
        '&:hover': {
            backgroundColor: palette.primary.light
        },
        '&:focus': {
            backgroundColor: 'inherit',
            '&:hover': {
                backgroundColor: palette.primary.light
            }
        }
    },

    gridPaginationItemSelected: {
        backgroundColor: `${palette.primary.main}!important`
    },

    gridPaginationItemText: {
        fontSize: spacing.unit * 1.63,
        padding: `${spacing.unit}px ${spacing.unit * 2}px`,
        '&:first-child': {
            paddingLeft: spacing.unit * 2
        }
    },
    gridPaginationItemTextPrimary: {
        fontSize: spacing.unit * 1.75
    },
    gridPaginationItemTextPrimarySelected: {
        fontWeight: 600,
        color: '#fff'
    }
});

const Pagination = memo(
    ({
        classes: c,
        currentPage,
        goToPage,
        goToFirstPage,
        goToLastPage,
        goToPrevPage,
        goToNextPage,
        paginationRange,
        totalPages,
        DOTS
    }) => {
        if (totalPages === 0 || !paginationRange) return null;

        return (
            <List component='nav' className={c.gridPaginationNavContainer}>
                <ListItem
                    button
                    disableRipple
                    onClick={goToFirstPage}
                    disabled={currentPage === 1}
                    classes={{
                        button: c.gridPaginationItemButton,
                        gutters: c.gridPaginationItemGutters
                    }}
                >
                    <ListItemText
                        primary='First'
                        classes={{
                            root: c.gridPaginationItemText,
                            primary: c.gridPaginationItemTextPrimary
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    disableRipple
                    onClick={goToPrevPage}
                    disabled={currentPage === 1}
                    classes={{
                        button: c.gridPaginationItemButton,
                        gutters: c.gridPaginationItemGutters
                    }}
                >
                    <ListItemText
                        primary='Prev'
                        classes={{
                            root: c.gridPaginationItemText,
                            primary: c.gridPaginationItemTextPrimary
                        }}
                    />
                </ListItem>
                {paginationRange.map((page, idx) => (
                    <ListItem
                        key={`page-${page}-${idx}`}
                        button
                        disableRipple
                        selected={currentPage === page}
                        onClick={() => goToPage(page)}
                        classes={{
                            button: c.gridPaginationItemButton,
                            gutters: c.gridPaginationItemGutters,
                            selected: c.gridPaginationItemSelected
                        }}
                        disabled={DOTS === page}
                    >
                        <ListItemText
                            primary={page}
                            classes={{
                                root: c.gridPaginationItemText,
                                primary: clsx(
                                    c.gridPaginationItemTextPrimary,
                                    currentPage === page &&
                                        c.gridPaginationItemTextPrimarySelected
                                )
                            }}
                        />
                    </ListItem>
                ))}
                <ListItem
                    button
                    disableRipple
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    classes={{
                        button: c.gridPaginationItemButton,
                        gutters: c.gridPaginationItemGutters
                    }}
                >
                    <ListItemText
                        primary='Next'
                        classes={{
                            root: c.gridPaginationItemText,
                            primary: c.gridPaginationItemTextPrimary
                        }}
                    />
                </ListItem>
                <ListItem
                    button
                    disableRipple
                    onClick={goToLastPage}
                    disabled={currentPage === totalPages}
                    classes={{
                        button: c.gridPaginationItemButton,
                        gutters: c.gridPaginationItemGutters
                    }}
                >
                    <ListItemText
                        primary='Last'
                        classes={{
                            root: c.gridPaginationItemText,
                            primary: c.gridPaginationItemTextPrimary
                        }}
                    />
                </ListItem>
            </List>
        );
    }
);

export default withStyles(styles)(Pagination);
