import React from 'react';
import PropTypes from 'prop-types';

import Select from '@formComponents/Select';
import { DOMAIN_NAME } from '../common';
import { GENDERS } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'gender',
    label: 'Gender',
    placeholder: 'Select gender',
    disabled: false,
    required: false
};

const SelectGenders = props => (
    <Select {...props} domain={DOMAIN_NAME} entity={GENDERS} />
);

SelectGenders.propTypes = propTypes;
SelectGenders.defaultProps = defaultProps;

export { SelectGenders as default, SelectGenders };
