import React from 'react';
import PropTypes from 'prop-types';

import PaginatedSelect from '@formComponents/PaginatedSelect';

import { DOMAIN_NAME } from 'orders/common';
import { PAGINATED_COLORS } from 'orders/redux.datasets';

const propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'color',
    label: 'Select Color',
    placeholder: 'Select Color',
    domain: DOMAIN_NAME,
    entity: PAGINATED_COLORS,
    displayField: 'Color Name'
};

const SelectPaginatedColor = props => <PaginatedSelect {...props} />;

SelectPaginatedColor.propTypes = propTypes;
SelectPaginatedColor.defaultProps = defaultProps;

export { SelectPaginatedColor as default, SelectPaginatedColor };
