import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

import styles from './styles';

const MenuWrapper = props => {
    const { classes, children, target, onClose, ...restProps } = props;

    return (
        <Menu
            classes={{
                paper: classes.profileDropdownMenu
            }}
            anchorEl={target}
            open={Boolean(target)}
            onClose={onClose}
            {...restProps}
        >
            {children}
        </Menu>
    );
};

MenuWrapper.propTypes = {
    classes: PropTypes.object,
    onClose: () => {}
};

MenuWrapper.propTypes = {
    classes: PropTypes.object,
    target: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onClose: PropTypes.func
};

const _MenuWrapper = withStyles(styles)(MenuWrapper);

export { _MenuWrapper as default, _MenuWrapper as Menu };
