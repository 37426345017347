import {
	FETCH_BACKNECK_DETAILS_REQUEST,
	POST_BACKNECK_DATA_REQUEST,
	UPDATE_BACKNECK_DETAILS_REQUEST,
	DELETE_BACKNECK_DETAILS_SUCCESS
} from 'store/actionTypes';

export function startLoadBackneckDetails(payload) {
	return { type: FETCH_BACKNECK_DETAILS_REQUEST, payload };
}

export function addBackneckData(payload) {
	return { type: POST_BACKNECK_DATA_REQUEST, payload };
}

export function updateBackneckData(payload) {
	return { type: UPDATE_BACKNECK_DETAILS_REQUEST, payload };
}

export function deleteBackneckDetails(payload) {
	return { type: DELETE_BACKNECK_DETAILS_SUCCESS, payload };
}
