import { call, put, select } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import { setAccumulatedData } from 'gridView/redux.actions';
import { userIdSelector } from 'auth/redux.selectors';

export function* fetchAccumulatedData(moduleName) {
    const userId = yield select(userIdSelector);
    const { data: { accumulation } = {} } = yield call(apiRequest, {
        url: 'Accumulation',
        params: { moduleName, userId }
    });
    return accumulation;
}

export default function*(action) {
    const { payload: moduleName, type } = action;
    try {
        const accumulation = yield call(fetchAccumulatedData, moduleName);
        yield put(setAccumulatedData({ moduleName, accumulation }));
    } catch (error) {
        console.log(`Error while fetching ${type}`);
    }
}
