import React from 'react';
import { Document, Page } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Item from './Item';

const styles = ({ spacing }) => ({
    page: {
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 4
    }
});

const PrintSamplesDocument = withPDFStyles(styles)(({ pages, classes: c }) => {
    return (
        <Document>
            {pages.map((items, idx) => (
                <Page size='A3' style={c.page} key={`pdf-page-${idx}`}>
                    {items.map((item, idx) => (
                        <Item
                            key={`pdf-row-${idx}`}
                            item={item}
                            isLast={items.length - 1 === idx}
                        />
                    ))}
                </Page>
            ))}
        </Document>
    );
});

export default PrintSamplesDocument;
