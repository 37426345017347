import React, { useCallback } from 'react';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core';

import { CHECKBOX_COLUMN_ID } from '../../../../dataGridDefaultValues';

import useResizingContext from '../../useResizingContext';
import GridCellResizing from '../../GridCellResizing';
import useRowStyles from './useRowStyles';
import RowLocked from './RowLocked';
import Cell from './Cell';

const styles = ({ palette }) => ({
    gridBodyRowContainer: {
        position: 'relative',
        display: 'flex',
        width: 'max-content',
        height: 'var(--row-height)',
        backgroundColor: 'var(--bg-color)',
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
            backgroundColor: 'var(--bg-hover-color)'
        }
    },
    gridBodyRowContainerLocked: {
        '&:hover': {
            backgroundColor: 'var(--bg-color)',
            pointerEvents: 'none'
        }
    },
    gridBodyRow: {
        display: 'flex'
    },
    gridBodyRowStickyCells: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'inherit'
    },
    gridBodyCellContainer: {
        position: 'relative',
        width: 'var(--columnWidth)',
        maxWidth: 'var(--columnWidth)',
        minWidth: 'var(--columnWidth)',

        flexGrow: 0,
        flexShrink: 0,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,

        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: palette.background.dark
    }
});

const GridBodyRow = withStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { palette },
        row,
        idx,
        hasFrozenColumns,
        gridFrozenColumnsWithCheckbox,
        gridColumnsWithCheckbox,
        gridRegularColumns,
        rowStyle,
        isColumnFrozen,
        isRowSelected,
        disableColumnResize,
        isRowEditable,
        rowHeight,
        ...rest
    }) => {
        const { columnWidths, startResize } = useResizingContext();
        const customRowColor = rowStyle(row);
        const rowStyles = useRowStyles({
            rowIdx: idx,
            palette,
            isRowSelected,
            customRowColor,
            rowHeight
        });

        const handleStartResize = useCallback(
            columnName => clientX => {
                startResize({ [columnName]: clientX });
            },
            [startResize]
        );

        const renderCells = (columns, row, isFrozen) => {
            return columns.map(column => {
                const isColumnCheckbox = column.name === CHECKBOX_COLUMN_ID;
                const columnWidth = isColumnCheckbox
                    ? column.width
                    : columnWidths[column.name].width;
                const isThisColumnFrozen = isColumnFrozen(column.name);

                if (isThisColumnFrozen === isFrozen || isColumnCheckbox) {
                    return (
                        <div
                            key={column.name}
                            className={c.gridBodyCellContainer}
                            style={{
                                '--columnWidth': `${columnWidth}px`
                            }}
                            role='gridcell'
                        >
                            <Cell
                                column={column}
                                row={row}
                                isColumnCheckbox={isColumnCheckbox}
                                isRowSelected={isRowSelected}
                                {...rest}
                            />
                            {column.resizable && !disableColumnResize && (
                                <GridCellResizing
                                    onStartResize={handleStartResize(
                                        column.name
                                    )}
                                />
                            )}
                        </div>
                    );
                }

                return null;
            });
        };

        return (
            <div
                className={clsx(
                    c.gridBodyRowContainer
                    /* !isRowEditable(row) && c.gridBodyRowContainerLocked */
                )}
                style={rowStyles}
                role='row'
            >
                {/* {!isRowEditable(row) && <RowLocked />} */}
                {/* Render frozen columns if they exist */}
                {hasFrozenColumns && (
                    <div
                        className={clsx(
                            c.gridBodyRow,
                            c.gridBodyRowStickyCells
                        )}
                    >
                        {renderCells(gridFrozenColumnsWithCheckbox, row, true)}
                    </div>
                )}
                {/* Render regular columns */}
                <div className={c.gridBodyRow}>
                    {renderCells(
                        !hasFrozenColumns
                            ? gridColumnsWithCheckbox
                            : gridRegularColumns,
                        row,
                        false
                    )}
                </div>
            </div>
        );
    }
);

export default GridBodyRow;
