import React, { useEffect, useState } from 'react';
import { Image as PDFImage } from '@react-pdf/renderer';

const propTypes = PDFImage.propTypes;

const Image = props => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let readyTimeout = null;
        readyTimeout = setTimeout(() => setReady(true), 1);

        return () => clearTimeout(readyTimeout);
    }, []);

    return ready && <PDFImage {...props} />;
};

Image.propTypes = propTypes;

export { Image as default, Image };
