import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

// Local
import persistReducer from './persistReducer';
import middleware from './middleware';
import saga from './saga';
import devTools from './devTools';

const sagaMiddleware = createSagaMiddleware();
const enhancers = compose(
    applyMiddleware(...middleware, sagaMiddleware),
    devTools
);

const store = createStore(persistReducer, {}, enhancers);

sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { store as default, persistor };
