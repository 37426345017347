import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

//import { Stepper } from '@formComponents/Stepper';
import Stepper from '@libComponents/Stepper';

import SampleDispatchContainer from './SampleDispatchContainer';
import { useContent } from './hooks';
import { FORM_NAME } from './common';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'incomplete', 'amend'])
};
const defaultProps = {
    mode: 'new'
};

const SampleDispatch = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ mode, handleSubmit: handleReduxFormSubmit }) => (
    <Stepper
        content={useContent(mode)}
        ContainerComponent={SampleDispatchContainer}
        ContainerProps={{ mode, handleReduxFormSubmit }}
    />
));

SampleDispatch.propTypes = propTypes;
SampleDispatch.defaultProps = defaultProps;

export { SampleDispatch as default, SampleDispatch };
