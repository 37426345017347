export default ({ palette }) => ({
    footerBar: {
        background: palette.grey[100],
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: palette.grey['A700'],
        boxShadow: '0px 0px 18px 0px rgba(184, 184, 184, 0.39)',
        '& span': {
            color: palette.primary.main
        },
        padding: '25px 24px'
    }
});
