import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

// Material
import { withStyles } from '@material-ui/core/styles';
import { Card, Paper } from '@material-ui/core';

// Actions
import { setReportsNumPages } from 'store/actions';

// Local
import styles from './styles';

/*
 * Domain: Reports
 * Page: --
 * Component: Document
 * Type: --
 * ReportsDocument
 */
class ReportsDocument extends Component {
    state = {
        documentReady: false,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.props.setReportsNumPages(numPages);
        this.setState({ documentReady: true });
    };

    renderPage = () => {
        const {
            classes: { page },
            pageNumber,
            numPages,
            viewMode,
        } = this.props;

        const renderPage = [];
        switch (viewMode) {
            case 'single': {
                for (let numPage = 1; numPage <= numPages; numPage++) {
                    renderPage.push(
                        <Page
                            key={`page${numPage}`}
                            pageNumber={numPage}
                            className={page}
                            scale={2}
                        />
                    );
                }
                break;
            }

            case 'multi': {
                renderPage.push(
                    <Page
                        key={`page${pageNumber}`}
                        pageNumber={pageNumber}
                        className={page}
                        scale={2}
                    />
                );
                break;
            }
            default: // N/A
        }

        return renderPage;
    };

    render() {
        const {
            classes: { root, container, document },
            file,
        } = this.props;


        return (
            <Fragment>
                {file && (
                    <Paper className={root}>
                        <Card className={container}>
                            <Document
                                className={document}
                                file={file}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                {this.renderPage()}
                            </Document>
                        </Card>
                    </Paper>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ reports: { pagination, viewMode, pdfUrl } }) => ({
    ...pagination,
    viewMode,
    file: pdfUrl,
});

const mapDispatchToProps = dispatch => ({
    setReportsNumPages: numPages => dispatch(setReportsNumPages({ numPages })),
});

const _ReportsDocument = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(ReportsDocument);

export { _ReportsDocument as default, _ReportsDocument as ReportsDocument };
