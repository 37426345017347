import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { string, bool } from 'prop-types';

import { Field, Checkbox } from 'components/PDF';
import { useCustomDate } from 'sampling/hooks';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    checkboxWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    retailer: string,
    gender: string,
    age: string,
    category: string,
    department: string,
    style: string,
    designSourceName: string,
    country: string,
    shapeDesigner: string,
    hasCopyReference: bool,
    copyReference: string,
    hasManualReference: bool,
    manualReference: string,
    dateRequested: string,
    dateRequiredBy: string,
    sendToEcho: string,
    sendToBuyer: string,
    season: string
};

const defaultProps = {
    hasCopyReference: false,
    hasManualReference: false
};

const Setup = ({
    classes: c,
    retailer,
    gender,
    age,
    category,
    department,
    style,
    designSourceName,
    country,
    shapeDesigner,
    hasCopyReference,
    copyReference,
    hasManualReference,
    manualReference,
    dateRequested,
    dateRequiredBy,
    sendToEcho,
    sendToBuyer,
    buyer,
    season
}) => {
    return (
        <View style={styles.container}>
            <Field label={'Retailer'} value={retailer} />
            <Field label={'Gender'} value={gender} />
            <Field label={'Age'} value={age} />
            <Field label={'Category'} value={category} />
            <Field label={'Department'} value={department} />
            <Field label={'Style'} value={style} />
            <Field label={'Design from'} value={designSourceName} />
            <Field label={'Country'} value={country} />
            {shapeDesigner !== '' && (
                <Field label={'Shape Designer'} value={shapeDesigner} />
            )}
            {(!!hasCopyReference || !!manualReference) && (
                <View style={styles.checkboxWrapper}>
                    <Checkbox
                        label={'Copy data from another sample request'}
                        checked={!!hasCopyReference}
                        style={{ width: '46%' }}
                    />
                    <Checkbox
                        label={'I want to give this a manual reference'}
                        checked={!!hasManualReference}
                        style={{ width: '46%' }}
                    />
                    <Field
                        value={!!copyReference ? copyReference : ''}
                        style={{ width: '44%' }}
                    />
                    <Field
                        value={!!manualReference ? manualReference : ''}
                        style={{ width: '44%' }}
                    />
                </View>
            )}
            <Field
                label={'Date Requested'}
                value={useCustomDate(dateRequested)}
                required={false}
            />
            <Field
                label={'Sample Required by'}
                value={useCustomDate(dateRequiredBy)}
                required={false}
            />
            <Field label={'Sent to UK'} value={sendToEcho} required={false} />
            <Field
                label={'Sent to Buyer'}
                value={sendToBuyer}
                required={false}
            />
            <Field label={'Buyer Name'} value={buyer} required={false} />
            <Field label={'Season'} value={season} required={false} />
        </View>
    );
};

Setup.propTypes = propTypes;
Setup.defaultProps = defaultProps;

export default Setup;
