import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Lib
import ContentContainer from '@libComponents/ContentContainer';
import {
    GridContainer,
    GridItem,
    Input,
    ImageDnD,
    Select
} from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { validateImageSizeAndExtension } from '@libs/validationRules';
import { setReloadAction } from 'utilities/redux.actions';
import isBlobUrl from '@libs/isBlobUrl';

// Local
import { referenceGenerateLoadingSelector } from '../../../redux.selectors';
import { FORM_NAME, LABELS, DOMAIN_NAME } from '../../common';
import { SEAL_SAMPLE_TYPES } from '../../../redux.datasets';
import { useSealSampleState } from '../../useSealSample';
import { commonNumberFieldRules, foundGSMRules } from '../useSchema';
import HeaderAdornment from '../HeaderAdornment';
import SelectStockpileStatus from './SelectStockpileStatus';
import SelectConsentStatus from './SelectConsentStatus';
import FabricsContainer from './FabricsContainer';
import SealSampleEvaluation from './SealSampleEvaluation';
import PreProductionEvaluation from './PreProductionEvaluation';
import ProductRiskAnalysis from './ProductRiskAnalysis';

const styles = ({ spacing }) => ({
    contentContainer: {
        marginBottom: spacing.unit * 6,
        paddingBottom: spacing.unit * 3
    },
    container: {
        marginBottom: spacing.unit * 2
    },
    imageContainer: {
        padding: spacing.unit * 3
    }
});

const mapState = state => {
    const qcVersion = formValueSelector(FORM_NAME)(state, 'qcVersion');
    return {
        isReferenceLoading: referenceGenerateLoadingSelector(state),
        version: `V${qcVersion}`
    };
};

const mapInputs = {
    frontImage: ({ payload }) => {
        const shouldPrompt =
            payload.hasOwnProperty('url') && isBlobUrl(payload.url);
        const action = change(FORM_NAME, 'frontImage', '');

        return setReloadAction({
            field: 'frontImage',
            shouldPrompt,
            action
        });
    },
    backImage: ({ payload }) => {
        const shouldPrompt =
            payload.hasOwnProperty('url') && isBlobUrl(payload.url);
        const action = change(FORM_NAME, 'backImage', '');

        return setReloadAction({
            field: 'backImage',
            shouldPrompt,
            action
        });
    }
};

const Details = compose(
    connect(
        mapState,
        null
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withStyles(styles),
    formListener(mapInputs)
)(({ isReferenceLoading, version, classes: c }) => {
    const { isQA } = useSealSampleState();
    return (
        <ContentContainer
            title={LABELS.details}
            AdornmentComponent={<HeaderAdornment />}
            styles={{ container: c.contentContainer }}
        >
            <GridContainer className={c.container}>
                <GridItem md={4}>
                    <Input
                        name='qcReference'
                        label='QC Reference'
                        disabled
                        required
                        loading={isReferenceLoading}
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='qcVersion'
                        label='Version NO'
                        disabled
                        required
                        loading={isReferenceLoading}
                        staticValue={version}
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectStockpileStatus
                        name='bodyFabricStatus'
                        label='Body Fabric Status'
                    />
                </GridItem>
                {!isQA && (
                    <GridItem md={4}>
                        <SelectStockpileStatus
                            name='sampleColorStatus'
                            label='Sample Color Status'
                        />
                    </GridItem>
                )}
                <GridItem md={4}>
                    <SelectStockpileStatus
                        name='trimFabricStatus'
                        label='Trim Fabric Status'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input name='accAndTrimStatus' label='Acc & Trim Status' />
                </GridItem>
                <GridItem md={4}>
                    <Input name='missingTrims' label='Missing Trims' />
                </GridItem>
                <GridItem md={4}>
                    <SelectStockpileStatus
                        name='embellishmentStatus'
                        label='Embellishment Status'
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectStockpileStatus
                        name='washStatus'
                        label='Wash/Color Status'
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectConsentStatus
                        name='stichingStatus'
                        label='Styling & Stitching Status'
                    />
                </GridItem>
                {isQA && (
                    <>
                        <GridItem md={4}>
                            <Input
                                name='washShrinkage.length'
                                label='Wash Shrinkage Length'
                                type='number'
                                adornment='%'
                                validate={commonNumberFieldRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='washShrinkage.width'
                                label='Wash Shrinkage Width'
                                type='number'
                                adornment='%'
                                validate={commonNumberFieldRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='printShrinkage.length'
                                label='Print Shrinkage Length'
                                type='number'
                                adornment='%'
                                validate={commonNumberFieldRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='printShrinkage.width'
                                label='Print Shrinkage Width'
                                type='number'
                                adornment='%'
                                validate={commonNumberFieldRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='spirality'
                                label='Spirality'
                                type='number'
                                adornment='%'
                                validate={commonNumberFieldRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='foundGSM'
                                label='Found GSM'
                                type='number'
                                validate={foundGSMRules}
                            />
                        </GridItem>
                    </>
                )}

                <GridItem md={4}>
                    <SelectConsentStatus
                        name='patternStatus'
                        label='Pattern Status'
                    />
                </GridItem>
                <GridItem md={4}>
                    <SelectConsentStatus name='fitStatus' label='Fit Status' />
                </GridItem>
                <GridItem md={4}>
                    <Select
                        name='typeOfSample'
                        label='Select Sample Type'
                        domain={DOMAIN_NAME}
                        entity={SEAL_SAMPLE_TYPES}
                    />
                </GridItem>
            </GridContainer>
            <FabricsContainer />

            {/* Images Block */}

            <Grid container className={c.imageContainer}>
                <GridItem md={6}>
                    <ImageDnD
                        name='frontImage'
                        label='Front Image'
                        canDelete={false}
                        validate={validateImageSizeAndExtension}
                    />
                </GridItem>
                <GridItem md={6}>
                    <ImageDnD
                        name='backImage'
                        label='Back Image'
                        canDelete={false}
                        validate={validateImageSizeAndExtension}
                    />
                </GridItem>
            </Grid>

            {/* Comments Block */}

            <SealSampleEvaluation isQA={isQA} />
            {isQA && <PreProductionEvaluation />}

            {isQA && <ProductRiskAnalysis />}
        </ContentContainer>
    );
});

export default Details;
