import React, { Component } from 'react';
import { withStyles, TextField } from '@material-ui/core';

import SpotlightAdornment from './SpotlightAdornment';

const styles = ({ palette, spacing, shape }) => ({
    root: {
        border: `1px solid`,
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: spacing.unit * 35,
        paddingRight: spacing.unit + 1,
        borderRight: 'none',

        '&:hover': {
            borderRight: '1px solid',
            borderColor: palette.grey[400],
            paddingRight: spacing.unit,
            zIndex: 4000
        }
    },
    input: {
        height: '100%'
    }
});

class SpotlightSearch extends Component {
    state = {
        timedSearch: void 0,
        previousSearch: '',
        previousCategory: 0
    };

    handleSearch = () => {
        const { state, props } = this;
        const { timedSearch, previousSearch, previousCategory } = state;
        const {
            gridViewDispatch,
            gridViewState: { search, category }
        } = props;

        clearTimeout(timedSearch);
        if (previousSearch !== search || previousCategory !== category) {
            gridViewDispatch({ type: 'query' });
            this.setState({
                previousSearch: search,
                previousCategory: category
            });
        }
    };

    handleChange = ({ target: { value = '' } }) => {
        const { state, props, handleSearch } = this;

        props.gridViewDispatch({
            type: 'set-search',
            payload: value
        });

        clearTimeout(state.timedSearch);
        const timedSearch = setTimeout(handleSearch, 1000);
        this.setState({ timedSearch });
    };

    handleKeyPress = ({ key }) => key === 'Enter' && this.handleSearch();

    render() {
        const { props, handleChange, handleKeyPress, handleSearch } = this;

        const {
            gridViewState: { search },
            classes: { input: inputClass, ...c }
        } = props;

        const adornmentProps = {
            onClick: handleSearch
        };

        return (
            <TextField
                classes={c}
                InputProps={{
                    disableUnderline: true,
                    className: inputClass,
                    startAdornment: <SpotlightAdornment {...adornmentProps} />
                }}
                value={search}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder='Search..'
            />
        );
    }
}

export default withStyles(styles)(SpotlightSearch);
