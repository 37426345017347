import { getSteps } from './_getSteps';

// Local
import {
    SamplingPrintRequest,
    SamplingPrintReference,
    SamplingPrintArtwork,
    SamplingPrintSpec,
    SamplingPrintOther
} from 'views/Sampling/containers';

function getStepContent(
    activeStep,
    newPage = false,
    amendPage = false,
    parent = 'print' //print, shape/non-print
) {
    let component = null;
    const steps = getSteps(newPage, parent);
    const componentIndex = steps.indexOf(steps[activeStep - 1]); //-1 to allow index 0 of arrays

    /*const printNewComponents = [
        <SampleNewRequest />,
        <SampleSampleDetails newPage={newPage} amendPage={amendPage} />,
        <SampleArtworkDetails amendPage={amendPage} />,
        <SampleSpecDetails amendPage={amendPage} />,
        <SampleOthersInfo amendPage={amendPage} />,
    ];*/

    const printNewComponents = [
        SamplingPrintRequest,
        SamplingPrintReference,
        SamplingPrintArtwork,
        SamplingPrintSpec,
        SamplingPrintOther
    ];

    const printOldComponents = [
        //SamplingPrintRequest,
        SamplingPrintReference,
        SamplingPrintArtwork,
        SamplingPrintSpec,
        SamplingPrintOther
    ];

    if (parent === 'print') {
        if (newPage) {
            component = printNewComponents[componentIndex];
        } else {
            component = printOldComponents[componentIndex];
        }
    }

    return component;
}

export { getStepContent as default, getStepContent };
