import React from 'react';

import { withStylesAndRef } from '@libs/materialUI';

import PaginationContainer from './PaginationContainer';
import ExportContainer from './ExportContainer';
import useGridContext from '../../../useGridContext';

const styles = ({ palette }) => ({
    gridFooterContainer: {
        borderTopStyle: 'solid',
        borderTopColor: palette.background.dark,
        borderTopWidth: 1
    }
});

const GridFooter = withStylesAndRef(styles)(({ classes, forwardRef }) => {
    const {
        paginationProps,
        selectedRows,
        RenderDataGridExportOptions,
        gridColumns,
        filterValues,
        frozenColumns,
        sortedData,
        pageSizes
    } = useGridContext();

    return (
        <div ref={forwardRef} className={classes.gridFooterContainer}>
            <PaginationContainer
                paginationProps={paginationProps}
                pageSizes={pageSizes}
            />
            <ExportContainer
                selectedRows={selectedRows}
                RenderDataGridExportOptions={RenderDataGridExportOptions}
                gridColumns={gridColumns}
                totalCount={paginationProps.totalCount}
                filters={filterValues}
                frozenColumns={frozenColumns}
                data={sortedData}
            />
        </div>
    );
});

export default GridFooter;
