import React from 'react';
import PropTypes from 'prop-types';

import InputGroupSelect from '@baseComponents/InputGroupSelect';
import { number, absoluteNumber } from '@libs/validationRules';

import { commissionData } from '../../customData';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'BDCommission',
    label: 'BD Commission',
    placeholder: 'BD Commis.',
    disabled: false,
    required: false,
    validate: [number, absoluteNumber],
    inputProps: { type: 'number' }
};

const BDCommision = props => (
    <InputGroupSelect {...props} selectData={commissionData} />
);

BDCommision.propTypes = propTypes;
BDCommision.defaultProps = defaultProps;

export { BDCommision as default, BDCommision };
