import React from 'react';

const ShippedIcon = () => (
    <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_116_1236)'>
            <path
                d='M15.366 19.9215H8.94141'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.4429 8.51233H19.4644L22.1967 13.5339V19.9216H12.8921'
                fill='white'
            />
            <path
                d='M14.4429 8.51233H19.4644L22.1967 13.5339V19.9216H12.8921'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M21.7539 19.9215H2.11084V4.85693H14.4431V15.3431'
                fill='white'
            />
            <path
                d='M21.7539 19.9215H2.11084V4.85693H14.4431V15.3431'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.6554 22.2108C18.9197 22.2108 19.9447 21.1859 19.9447 19.9216C19.9447 18.6572 18.9197 17.6323 17.6554 17.6323C16.3911 17.6323 15.3662 18.6572 15.3662 19.9216C15.3662 21.1859 16.3911 22.2108 17.6554 22.2108Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.65251 22.2108C7.91682 22.2108 8.94174 21.1859 8.94174 19.9216C8.94174 18.6572 7.91682 17.6323 6.65251 17.6323C5.3882 17.6323 4.36328 18.6572 4.36328 19.9216C4.36328 21.1859 5.3882 22.2108 6.65251 22.2108Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.4647 13.5338H16.7324V10.8015H17.8401L19.4647 13.5338Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.6554 20.3277C17.8593 20.3277 18.0246 20.1624 18.0246 19.9585C18.0246 19.7545 17.8593 19.5892 17.6554 19.5892C17.4514 19.5892 17.2861 19.7545 17.2861 19.9585C17.2861 20.1624 17.4514 20.3277 17.6554 20.3277Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.65243 20.3277C6.85635 20.3277 7.02166 20.1624 7.02166 19.9585C7.02166 19.7545 6.85635 19.5892 6.65243 19.5892C6.44851 19.5892 6.2832 19.7545 6.2832 19.9585C6.2832 20.1624 6.44851 20.3277 6.65243 20.3277Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.4431 4.85693H2.11084V15.5277H14.4431V4.85693Z'
                fill='#B6FBE9'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.27696 10.0999C10.724 10.0999 12.7077 8.11622 12.7077 5.66917C12.7077 3.22213 10.724 1.2384 8.27696 1.2384C5.82991 1.2384 3.84619 3.22213 3.84619 5.66917C3.84619 8.11622 5.82991 10.0999 8.27696 10.0999Z'
                fill='white'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4924 4.27673L7.64404 7.12509'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.25146 5.73242L7.64399 7.12495'
                stroke='#5CD3B4'
                strokeWidth='0.738462'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_116_1236'>
                <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.153809 0.5)'
                />
            </clipPath>
        </defs>
    </svg>
);

export { ShippedIcon as default, ShippedIcon };
