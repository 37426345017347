import { useMemo } from 'react';

import { DESIGN_SOURCE_IN_HOUSE_ID, LABELS } from '../common';
import { MAIN_BODY_FABRIC_ID } from '../../common';

const useSchema = (
    mode,
    { hasManualReference, hasCopyReference, designSource },
    isStyleBottom,
    isSealed,
    isPrimark
) => {
    const dateRequiredBySchema = useMemo(
        () => (isSealed ? {} : { dateRequiredBy: true }),
        [isSealed]
    );

    const setupSchema = useMemo(
        () => ({
            retailer: true,
            gender: true,
            age: true,
            category: true,
            department: true,
            style: true,
            country: true,
            shapeDesigner: Number(designSource) === DESIGN_SOURCE_IN_HOUSE_ID,
            designSource: true,
            manualReference: hasManualReference,
            manualReferenceAsync: hasManualReference,
            copyReference: hasCopyReference,
            dateRequested: true,
            designDepartment: isPrimark,
            ...dateRequiredBySchema
        }),
        [hasManualReference, hasCopyReference, designSource, mode, isPrimark]
    );

    const neckOrWaistSchema = useMemo(
        () => (isStyleBottom ? { waistband: true } : { neckline: true }),
        [isStyleBottom]
    );

    const detailsSchema = useMemo(
        () => ({
            styleDescription: true,
            ...neckOrWaistSchema,
            armhole: true,
            hemline: true,
            front: true,
            back: (_, fieldError) => !fieldError,
            fabrics: (fabrics = []) =>
                fabrics.some(
                    ({ fabricFor }) => fabricFor === MAIN_BODY_FABRIC_ID
                )
        }),
        [neckOrWaistSchema]
    );

    const otherSchema = useMemo(
        () => ({
            attachments: (_, fieldError) => !fieldError
        }),
        []
    );

    // Combines
    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema,
            ...otherSchema
        }),
        [setupSchema, detailsSchema, otherSchema]
    );

    return useMemo(
        () => ({
            [LABELS.setup]: setupSchema,
            [LABELS.details]: detailsSchema,
            [LABELS.other]: otherSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: setupSchema
        }),
        [mode, setupSchema, detailsSchema, otherSchema, publishSchema]
    );
};

export default useSchema;
