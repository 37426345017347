import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createShadow } from '@libs/materialUI';

import { useMouseOverStyle } from './hooks';

const propTypes = {
    handleClick: PropTypes.func.isRequired
};

const styles = ({ palette, spacing, transitions }) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'var(--versionWidth)',
        height: 'var(--versionHeight)',
        margin: spacing.unit,
        marginBottom: spacing.unit * 3,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: 'var(--versionHandleColor)',
        cursor: 'pointer',
        transition: transitions.create('border', {
            easing: transitions.easing.easeInOut
        })
    },
    button: {
        width: '100%',
        height: '100%',
        transition: transitions.create(['background', 'boxShadow'], {
            easing: transitions.easing.easeInOut
        }),
        '&:hover': {
            backgroundColor: fade(palette.secondary.main, 0.1),
            boxShadow: createShadow({
                blur: 4,
                spread: 4,
                color: fade(palette.secondary.main, 0.1),
                inset: true
            })
        }
    },
    add: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(var(--versionWidth) * 0.4)',
        height: 2,
        backgroundColor: 'var(--versionHandleColor)',
        transition: transitions.create('background', {
            easing: transitions.easing.easeInOut
        }),

        '&:before': {
            display: 'block',
            content: "''",
            width: 2,
            height: 'calc(var(--versionWidth) * 0.4)',
            backgroundColor: 'inherit'
        }
    }
});

const VersionHandle = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme, handleClick }) => {
        const [handleMouseEnter, handleMouseLeave, style] = useMouseOverStyle(
            theme
        );
        return (
            <Grid
                item
                className={c.root}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={style}
            >
                <Button
                    variant='text'
                    className={c.button}
                    onClick={handleClick}
                >
                    <div className={c.add} />
                </Button>
            </Grid>
        );
    }
);

VersionHandle.propTypes = propTypes;

export default VersionHandle;
