import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import createCachedSelector from 're-reselect';
import { withStyles } from '@material-ui/core';

import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import { SelectBase } from 'components';
import { useGridViewDispatch } from 'gridView/useGridView';

const propTypes = {
    name: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string.isRequired
};

const defaultProps = {
    disabled: false,
    required: false,
    placeholder: 'Select ..'
};

const styles = ({ spacing }) => ({
    control: {
        height: spacing.unit * 3.5,
        borderRadius: `2px !important`
    },

    placeholder: {
        paddingRight: spacing.unit * 6,
        fontSize: spacing.unit * 1.8
    },
    dropdownIcon: {
        transform: 'scale(1.1) !important'
    },
    root: {
        fontSize: `${spacing.unit * 1.4}px !important`
    }
});

const dataSelector = createCachedSelector(entitySelector, (data = []) =>
    data.map(({ id, ...datum }) => ({
        label: Object.values(datum)[0],
        value: id
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => {
    const loading = datasetLoadingSelector(state, domain, entity);
    return {
        data: dataSelector(state, domain, entity),
        loading: loading,
        disabled: loading
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const Select = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(({ fetchDataset, name, id, rowIndex, value, classes, ...props }) => {
    const gridViewDispatch = useGridViewDispatch();

    useEffect(() => {
        fetchDataset();
    }, []);

    const handleChange = e => {
        gridViewDispatch({
            type: 'update-grid-cell',
            payload: {
                rowIndex,
                id,
                name,
                value: e.target.value
            }
        });
    };

    const selectProps = {
        input: {
            onFocus: () => {},
            onBlur: () => {},
            onChange: handleChange,
            value: value || ''
        },
        showSuccess: false,
        styles: classes,
        name: 'void0',
        ...props
    };

    return <SelectBase {...selectProps} />;
});

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export { Select as default, Select };
