import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import { MAIL_FORM_NAME } from 'common/common';
import { validateEmailForm } from '@libs/validationRules';
import MailForm from 'common/components/MailForm';

import { samplingReportMailSend } from 'sampling/redux.actions';
import {
    SET_SAMPLE_EMAIL_RECEIVERS,
    SET_SAMPLE_EMAIL_BODY
} from 'sampling/redux.actionTypes';
import { SampleEmailGroup, SampleEmailTemplate } from 'sampling/components';

const mapDispatch = dispatch => ({
    formInitialize: (...args) => dispatch(initialize(...args)),
    sendReportMail: (...args) => dispatch(samplingReportMailSend(...args))
});

const mapInputs = {
    groups: ({ payload }) => ({ type: SET_SAMPLE_EMAIL_RECEIVERS, payload }),
    templates: ({ payload }) => ({ type: SET_SAMPLE_EMAIL_BODY, payload })
};

const PreviewMailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        validate: validateEmailForm,
        onSubmit: v => v
    }),
    connect(
        null,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose: closeMailModal,
        valid,
        emailSubject,
        retailer,
        PDFData,
        reference,
        handlePreviewModalClose,
        developmentName,
        developmentNumber,
        formInitialize,
        sendReportMail
    }) => {
        const handleMailModalClose = useCallback(() => {
            closeMailModal();
            setTimeout(() => handlePreviewModalClose(), 0);
        }, []);

        const handleSendMail = useCallback(() => {
            sendReportMail({
                sampleReference: reference,
                developmentName,
                developmentNumber,
                closeModals: handleMailModalClose
            });
        }, [developmentName, developmentNumber, reference]);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, {
                subject: emailSubject,
                report: PDFData
            });
        }, []);

        const mailProps = {
            MailGroupComponent: <SampleEmailGroup retailer={retailer} />,
            MailTemplatesComponent: <SampleEmailTemplate retailer={retailer} />,
            handleModalClose: closeMailModal,
            handleSubmit: handleSendMail,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export default PreviewMailModal;
