import React from 'react';
import { array } from 'prop-types';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    wrapper: {
        width: '49%',
        border: 1,
        borderColor: '#e0e0e0',
        marginBottom: 10
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: 1,
        borderBottomColor: '#e0e0e0',
        padding: 4,
        width: '100%'
    },
    label: {
        width: '48%',
        fontSize: 12,
        marginRight: 10
    },
    value: {
        fontSize: 10,
        width: '48%',
        textAlign: 'right'
    },
    shaded: {
        backgroundColor: '#f5f5f5'
    }
});

const propTypes = {
    data: array.isRequired
};

const Table = ({ data }) => {
    return (
        <View style={styles.wrapper}>
            {data.map((value, idx) => {
                const shaded = idx % 2 !== 0 ? styles.shaded : {};
                return (
                    <View
                        key={`row-${idx}`}
                        style={{ ...styles.row, ...shaded }}
                    >
                        <Text style={styles.label}>{value.label}</Text>
                        <Text style={styles.value}>{value.value}</Text>
                    </View>
                );
            })}
        </View>
    );
};

Table.propTypes = propTypes;

export { Table as default, Table };
