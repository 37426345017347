import { reducer } from 'redux-form';

// Local
import apply from 'helpers/prototypeEnhancers/object/apply';

// Action Types
import {
    UPDATE_SAMPLING_PRINT_FORM,
    UPDATE_SAMPLING_REQUEST_FORM
} from 'store/actionTypes';

// Todo: SRO
const composeFormPlugins = (formPlugins = {}) => {
    return apply(formPlugins, updateAction => {
        return (state, action) => {
            switch (action.type) {
                case updateAction: {
                    const { values } = action.payload;
                    return {
                        ...state,
                        values: {
                            ...state.values,
                            ...values
                        }
                    };
                }
                default:
                    return state;
            }
        };
    });
};

const form = reducer.plugin(
    composeFormPlugins({
        SamplingPrintForm: UPDATE_SAMPLING_PRINT_FORM,
        SamplingRequestForm: UPDATE_SAMPLING_REQUEST_FORM
    })
);

export { form as default, form };
