export default ({ menus, retailers, sampleFactories } = {}) => {
    if (!menus || menus.length === 0) {
        return 'At least one menu item must be selected to proceed.';
    } else if (!retailers || retailers.length === 0) {
        return 'At least one retailer must be selected to proceed.';
    } else if (!sampleFactories || sampleFactories.length === 0) {
        return 'At least one sample factory must be selected to proceed.';
    }

    return null;
};
