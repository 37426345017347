import { startSubmit } from 'redux-form';
import { put, call } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';

import { DOMAIN_NAME } from 'sampling/common';
import { GET_SAMPLE_SIZES } from 'sampling/redux.datasets';
import { normalizeSampleSizes } from 'sampling/redux.normalizers';

import { FORM_NAME } from '../../Sample/SampleSize/common';
import { sampleSizeSchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            sampleSize: payload.sizeName
        };

        const publishData = composeApiPayload(
            preparedPayload,
            sampleSizeSchema
        );

        const {
            data: { sampleSizeId, ...data }
        } = yield call(apiRequest, {
            url: 'SampleSize',
            method: id ? 'put' : 'post',
            data: { ...publishData, sampleSizeId: id }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: GET_SAMPLE_SIZES,
            isNew: id === 0,
            data: {
                id: sampleSizeId,
                ...data
            }
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Sample size',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
