import React from 'react';
import { withStyles, Paper } from '@material-ui/core';

import Spotlight from './SpotlightSearch';
import CategorySelect from './SearchBarCategories';
import useGridView from 'gridView/useGridView';

const styles = () => ({
    root: {
        display: 'flex'
    }
});

const SearchBar = withStyles(styles)(({ classes }) => {
    const [state, dispatch] = useGridView();
    return (
        <Paper classes={classes} elevation={0}>
            <Spotlight gridViewState={state} gridViewDispatch={dispatch} />
            <CategorySelect gridViewState={state} gridViewDispatch={dispatch} />
        </Paper>
    );
});

export default SearchBar;
