import React from 'react';

const EditIcon = ({ color: fill = 'rgba(0, 0, 0, 0.87)', ...restProps }) => {
    return (
        <svg {...restProps} x='0px' y='0px' viewBox='0 0 11.94 11.94'>
            <path
                style={{
                    fill,
                    fillRule: 'evenodd'
                }}
                d='M1418.87,1141.41l-1.11,1.11-2.26-2.26-0.86.86,2.26,2.26-5.54,5.54-2.26-2.26-0.86.86,2.26,2.26-0.55.55-0.01-.01a0.447,0.447,0,0,1-.28.2l-2.11.47a0.277,0.277,0,0,1-.09.01,0.4,0.4,0,0,1-.31-0.13,0.427,0.427,0,0,1-.12-0.4l0.47-2.11a0.414,0.414,0,0,1,.2-0.28l-0.01-.01,8.92-8.92a0.332,0.332,0,0,1,.47,0l1.79,1.79A0.332,0.332,0,0,1,1418.87,1141.41Z'
                transform='translate(-1407.03 -1139.06)'
            />
        </svg>
    );
};

export { EditIcon as default, EditIcon };
