import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
    getFormAsyncErrors,
    getFormSubmitErrors,
    getFormSyncErrors
} from 'redux-form';

import { datasetLoadingSelector } from '@libs/datasets';
import { entitySelector } from '@libs/datasets';

import { DEVELOPMENT_TYPES, SIZE_PATHS, STYLES } from './redux.datasets';
import { DOMAIN_NAME } from './common';

const commonSelector = state => state[DOMAIN_NAME];

const developmentTypesDatasetSelector = createSelector(
    commonSelector,
    common => common[DEVELOPMENT_TYPES]
);

const developmentTypesLoadingSelector = state =>
    datasetLoadingSelector(state, DOMAIN_NAME, DEVELOPMENT_TYPES);

const developmentTypesSelector = createCachedSelector(
    developmentTypesDatasetSelector,
    (state, module) => module,
    (developmentTypes, module) =>
        developmentTypes.filter(
            ({ developmentModuleType }) =>
                developmentModuleType.toUpperCase() === module.toUpperCase()
        )
)((state, module) => `moduleDevelopmentTypes-${module.toUpperCase()}`);

const developmentNameSelector = createCachedSelector(
    developmentTypesSelector,
    (state, module, developmentTypeId) => developmentTypeId,
    (developmentTypes, developmentTypeId) => {
        const { developmentName = '' } =
            developmentTypes.find(
                ({ id }) => id === Number(developmentTypeId)
            ) || {};
        return developmentName;
    }
)(
    (state, module, developmentTypeId) =>
        `developmentNameSelector-${module.toUpperCase()}-${developmentTypeId}`
);

const sizePathsDatasetSelector = createSelector(
    commonSelector,
    common => common[SIZE_PATHS]
);

const sizePathsSelector = createCachedSelector(
    sizePathsDatasetSelector,
    (state, module) => module,
    (sizePaths, module) => sizePaths[module] || {}
)((state, module) => `sizePaths-${module}`);

const sizePathSelector = createCachedSelector(
    sizePathsSelector,
    (state, module, size) => size,
    (sizePaths, size) => sizePaths[size]
)((state, module, size) => `sizePath-${module}-${size}`);

const stylesSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, STYLES),
    (state, category) => category,
    (styles, category) =>
        styles
            .filter(({ categoryId }) => categoryId === category)
            .map(({ id, subCategory }) => ({ label: subCategory, value: id }))
)((state, category) => `styles-${category}`);

const isStyleBottomSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, STYLES),
    (state, styleId) => styleId,
    (styles, styleId) => {
        const { style } = styles.find(({ id }) => id === styleId) || {};
        return style === 'BOTTOM';
    }
)((state, styleId) => `styles-${styleId}`);

const formSubmitErrorsSelector = createSelector(
    (state, formName) => getFormSubmitErrors(formName)(state),
    errors => errors
);

const formSyncErrorsSelector = createSelector(
    (state, formName) => getFormSyncErrors(formName)(state),
    errors => errors
);

const formAsyncErrorsSelector = createSelector(
    (state, formName) => getFormAsyncErrors(formName)(state),
    errors => errors
);

const formErrorsSelector = createCachedSelector(
    (state, formName) => formSubmitErrorsSelector(state, formName),
    (state, formName) => formSyncErrorsSelector(state, formName),
    (state, formName) => formAsyncErrorsSelector(state, formName),
    (submitErrors, syncErrors, asyncErrors) => ({
        ...submitErrors,
        ...asyncErrors,
        ...syncErrors
    })
)((state, formName) => `form-errors-${formName}`);

export {
    developmentTypesSelector,
    developmentTypesLoadingSelector,
    developmentNameSelector,
    sizePathsDatasetSelector,
    sizePathsSelector,
    sizePathSelector,
    stylesSelector,
    isStyleBottomSelector,
    formErrorsSelector
};
