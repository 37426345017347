import React, { useCallback } from 'react';

// Material
import { withStyles, List, Typography } from '@material-ui/core';

import useManageColumnsContext from '../../useManageColumnsContext';
import ListItem from './ListItem';

const styles = ({ spacing }) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: `${spacing.unit}px`,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2
    },
    noColumn: {
        textAlign: 'center',
        margin: spacing.unit * 1.25
    }
});

const ModalContentColumnsList = withStyles(styles)(({ classes: c }) => {
    const { state, dispatch } = useManageColumnsContext();
    const { columns, visibleColumns } = state;

    const handleClick = useCallback(
        columnName => () => {
            dispatch({ type: 'TOGGLE_COLUMN_VISIBILITY', payload: columnName });
        },
        [dispatch]
    );

    if (columns.length === 0) {
        return (
            <Typography
                variant='body1'
                color='textSecondary'
                className={c.noColumn}
            >
                No column found.
            </Typography>
        );
    }

    return (
        <List className={c.root}>
            {columns.map(({ name, header }) => {
                return (
                    <ListItem
                        key={name}
                        name={name}
                        header={header}
                        isChecked={visibleColumns.includes(name)}
                        onClick={handleClick(name)}
                    />
                );
            })}
        </List>
    );
});

export default ModalContentColumnsList;
