import {
    SET_DATASET,
    SET_PAGINATED_DATASET,
    SET_DATASET_LOADING,
    SET_GRID_DATASET
} from './libs.actionTypes';

const datasetReducer = (state, { type, payload = {}, meta = {} }, domain) => {
    switch (type) {
        case domain + SET_DATASET_LOADING: {
            const { entity } = meta;

            return {
                ...state,
                loading: {
                    ...state.loading,
                    [entity]: payload
                }
            };
        }

        case domain + SET_DATASET: {
            const { entity } = meta;
            const { dataset } = payload;

            return { ...state, [entity]: dataset };
        }

        case domain + SET_PAGINATED_DATASET: {
            const { entity, shouldAppend } = meta;
            const { dataset, nextPage } = payload;

            const data = shouldAppend
                ? [...state[entity].data, ...dataset]
                : dataset;

            return { ...state, [entity]: { data, nextPage } };
        }

        case domain + SET_GRID_DATASET: {
            const { entity } = meta;
            return { ...state, [entity]: payload };
        }

        default:
            return state;
    }
};

export default (reducers = {}) =>
    Object.entries(reducers).reduce((acm, [domain, reducer]) => {
        const injectedReducer = (state, action) => {
            // Inject setDataset action interceptor
            const injectedState = datasetReducer(state, action, domain);
            return reducer(injectedState, action);
        };

        return {
            ...acm,
            [domain]: injectedReducer
        };
    }, {});
