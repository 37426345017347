import reduceReducer from 'reduce-reducers';

// Local
import initialState from './initialState';

// Reducers
import commonReducer from './common';
import mailReducer from './mail';
import paginationReducer from './pagination';

const reportsReducer = reduceReducer(
    initialState,
    commonReducer,
    mailReducer,
    paginationReducer
);

export { reportsReducer as default, reportsReducer };
