import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, FormControl } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/InsertPhotoOutlined';

import Label from '@baseComponents/Label';

const propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool
};

const styles = ({ palette, spacing }) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    image: {
        width: '100%',
        height: '100%',
        maxHeight: spacing.unit * 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        backgroundColor: palette.background.light
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: spacing.unit * 8,
        height: spacing.unit * 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: palette.background.dark
    },
    icon: {
        color: palette.background.dark,
        fontSize: spacing.unit * 4
    }
});

const PreviewImage = withStyles(styles)(
    ({ classes: c, url, alt, label, required }) => {
        return (
            <FormControl className={c.root}>
                {label && <Label label={label} required={required} />}
                {url ? (
                    <img src={url} alt={alt} className={c.image} />
                ) : (
                    <div className={c.image}>
                        <div className={c.empty}>
                            <ImageIcon className={c.icon} />
                        </div>
                    </div>
                )}
            </FormControl>
        );
    }
);

PreviewImage.propTypes = propTypes;

export default PreviewImage;
