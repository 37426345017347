import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// Material
import { withStyles, Button } from '@material-ui/core';

import styles from './styles';
import { baseFormatData, formalizeForLocalEdit } from 'helpers/fabricDetails';

import { setFabrics, editFabric } from 'actions';
import {
    selectFabricInfoForEditList,
    selectFabricFors,
    selectSpecialFinishes,
    selectFinishedGsms,
    selectCompositions,
    selectDyeTypes,
    selectFabricTypes,
    selectFabricDetails,
    selectFilteredFabricFor
} from 'selectors';

import {
    GridContainer,
    GridItem,
    ModalHeader,
    ModalContent,
    ModalFooter,
    Select,
    Input,
    Checkbox
} from 'components';

// Actions
import { setAmends, removeAmends } from 'actions';

// validator
const validateRequired = (values, required) => {
    const valid = Object.values(Object.splice(values, required)).filter(
        value => value !== ''
    ).length;
    if (valid < required.length) return { [required[0]]: 'error' };
};

const validate = values => {
    const required = ['fabricFor', 'fabricType', 'finishedGsm', 'composition'];

    return { ...validateRequired(values, required) };
};

const fieldNameMap = {
    fabricColor: 'fabricColour',
    fabricComposition: 'composition',
    fabricDischarge: 'isDischargeable',
    fabricDye: 'dyeType',
    fabricEld: 'eld',
    fabricEngineer: 'isEngineeredStripe',
    fabricFinish: 'specialFinish',
    fabricFor: 'fabricFor',
    fabricGsm: 'finishedGsm',
    fabricSpecialRef: 'specialFabricRef',
    fabricType: 'fabricType',
    fabricYds: 'isYds'
};

class FabricsModal extends Component {
    componentDidMount() {
        const { initialValues: { amends = [] } = {}, setAmends } = this.props;

        if (amends.length) setAmends(amends);
    }

    componentWillUnmount() {
        const {
            initialValues: { amends = [] } = {},
            removeAmends
        } = this.props;
        if (amends.length) removeAmends(amends);
    }

    onSubmit = values => {
        const {
            initialValues,
            onClose,
            setFabrics,
            fabricIndex,
            editFabric
        } = this.props;

        if (!!initialValues) {
            editFabric(fabricIndex, values);
        } else {
            setFabrics(values);
        }

        onClose();
    };

    render() {
        const { props } = this;

        const {
            classes,
            initialValues,
            onClose,
            fabricFors,
            specialFinishes,
            finishedGsms,
            compositions,
            dyeTypes,
            fabricTypes,
            invalid,
            handleSubmit
        } = props;

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {`${!!initialValues ? 'Update' : 'Add'} fabric details`}
                </ModalHeader>
                <ModalContent>
                    <GridContainer
                        spacing={40}
                        className={classes.containerStyle}
                    >
                        <GridItem md={6}>
                            <Select
                                name='fabricFor'
                                label='Fabric For'
                                data={fabricFors}
                                required
                            />
                            <Select
                                name='composition'
                                label='Composition'
                                data={compositions}
                                required
                            />
                            <Select
                                name='specialFinish'
                                label='Finish/Wash'
                                data={specialFinishes}
                                normalize={value => value || 0}
                            />
                            <Input name='eld' label='ELD' />
                            <Input
                                name='specialFabricRef'
                                label='Special Fabric Ref'
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <Select
                                name='fabricType'
                                label='Fabric/Yarn Type'
                                data={fabricTypes}
                                required
                            />
                            <Select
                                name='finishedGsm'
                                label='Finish GSM/Gauge'
                                data={finishedGsms}
                                required
                            />
                            <Select
                                name='dyeType'
                                label='Dye Type'
                                data={dyeTypes}
                                normalize={value => value || 0}
                            />
                            <Input
                                name='fabricColour'
                                label='Fabric/Yarn Color'
                            />
                            <GridContainer>
                                <GridItem md={5}>
                                    <Checkbox
                                        name='isDischargeable'
                                        label='Dischargeable'
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Checkbox
                                        name='isEngineeredStripe'
                                        label='E/Stripe'
                                    />
                                </GridItem>
                                <GridItem md={3} style={{ paddingLeft: 0 }}>
                                    <Checkbox name='isYds' label='YDS' />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer
                        spacing={0}
                        justify='flex-end'
                        className={classes.footer}
                    >
                        <GridItem md={6}>
                            <Button
                                onClick={handleSubmit(this.onSubmit)}
                                variant='contained'
                                disabled={invalid}
                                classes={{
                                    root: classes.saveButton,
                                    disabled: classes.saveButtonDisabled
                                }}
                            >
                                {!!initialValues ? 'Update' : 'Add'}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

const mapState = (state, { fabricIndex = null }) => {
    return {
        fabricFors: selectFilteredFabricFor(state, fabricIndex),
        specialFinishes: selectSpecialFinishes(state),
        finishedGsms: selectFinishedGsms(state),
        compositions: selectCompositions(state),
        dyeTypes: selectDyeTypes(state),
        fabricTypes: selectFabricTypes(state),
        initialValues: selectFabricDetails(state, fabricIndex)
    };
};

const mapDispatch = {
    setFabrics,
    editFabric,
    setAmends,
    removeAmends
};

FabricsModal.propTypes = {
    classes: PropTypes.object
};

const _FabricsModal = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: 'FabricsForm',
        validate
    }),
    withStyles(styles)
)(FabricsModal);

export { _FabricsModal as default, _FabricsModal as FabricsModal };
