import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const wrapperStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 'calc(100vh - 144px)',
	fontSize: '2em'
};

class Dashboard extends PureComponent {
	render() {
		return <div style={wrapperStyle}>WELCOME TO ERP DASHBOARD</div>;
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object
};

export { Dashboard as default, Dashboard };
