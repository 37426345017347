import { call, put, select } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { apiRequest } from '@libs/apiRequest';
import { setReportLoading } from '../../redux.actions';
import downloadWorker from './downloadWorker';
import { userIdSelector } from 'auth/redux.selectors';

export default function*(action) {
    const {
        payload: { rowIds, ...payload },
        meta: { reportType, fileName }
    } = action;
    try {
        const userId = yield select(userIdSelector);
        yield put(setReportLoading({ [reportType]: true }));
        const { data: blob } = yield call(apiRequest, {
            method: 'post',
            url: 'FileUpload/ExportToExcelAllColumn',
            data: { ...payload, ids: rowIds.join(','), userId },
            responseType: 'blob'
        });
        yield call(downloadWorker, {
            blob,
            fileName: `${fileName}.xlsx`
        });
        yield put(setReportLoading({ [reportType]: false }));
    } catch (error) {
        yield put(setReportLoading({ [reportType]: false }));
        yield put(
            setSnack({
                message: 'Failed to download',
                type: 'error',
                duration: 15000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}
