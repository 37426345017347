import { change } from 'redux-form';
import { put, select, all, call } from 'redux-saga/effects';

// Libs
import { apiRequest } from '@libs/apiRequest';

import { FORM_NAME } from '../../common';
import { cadSizePathsSelector, versionsSelector } from '../../redux.selectors';

import { composeRelativeUrls } from '@libs/sagaWorkers';

import getVersionBlobsWorker from './getVersionBlobsWorker';
import composeLoadingWorker from './composeLoadingWorker';
import getCadReferenceWorker from './getCadReferenceWorker';
import uploadVersionsWorker from './uploadVersionsWorker';
import getPublishPayloadWorker from './getPublishPayloadWorker';
import amendPublishedWorker from './amendPublishedWorker';
import savedDraftWorker from './savedDraftWorker';
import { publishFailedWorker } from '@libs/publishWorkers';
import composeApiPayload from '../../../@libs/composeApiPayload';
import publishSchema from './schema';

// Workers
export default function*(action) {
    const {
        meta: { handleComplete = () => {}, saveDraft = false },
        payload: { mode }
    } = action;

    const versions = yield select(versionsSelector);
    const handleLoading = yield call(composeLoadingWorker, { versions, mode });

    try {
        const [{ cadReference, id }, versionBlobs, sizePaths] = yield all([
            call(getCadReferenceWorker, { mode, handleLoading }),
            call(getVersionBlobsWorker, { versions, mode, handleLoading }),
            select(cadSizePathsSelector)
        ]);

        const { sizeOriginal } = sizePaths;
        const folderPath = `${sizeOriginal}/${cadReference}`;

        const versionPayloads = yield call(uploadVersionsWorker, {
            versionBlobs,
            folderPath,
            cadReference,
            sizePaths,
            handleLoading
        });

        const payload = yield call(getPublishPayloadWorker, {
            mode,
            id,
            cadReference
        });

        const publishData = composeApiPayload(
            {
                ...payload,
                isDiscontinue: !saveDraft,
                versions: versionPayloads.map(
                    ({ imageVersion, imageUrls }) => ({
                        imageVersion,
                        ...composeRelativeUrls(imageUrls)
                    })
                )
            },
            publishSchema
        );

        yield call(apiRequest, {
            method: 'put',
            url: 'Cad/PublishCad',
            data: publishData
        });

        /*
            --- After publish
         */
        const newVersions = versionPayloads.map(
            ({ imageUrls: { sizeOriginal } }) => ({ url: sizeOriginal })
        );

        // When amends
        if (mode === 'amend')
            yield call(amendPublishedWorker, { mode, versions, newVersions });

        // When new
        if (mode !== 'amend') {
            yield put(change(FORM_NAME, 'versions', newVersions));
            yield put(change(FORM_NAME, 'cadReference', cadReference));
            yield put(change(FORM_NAME, 'id', id));
        }

        yield call(handleLoading, true);

        if (saveDraft) yield call(savedDraftWorker, { mode, cadReference });

        return handleComplete();
    } catch (error) {
        console.log(error);
        let actionErrorMessage = 'Failed to ';

        if (saveDraft) actionErrorMessage += 'save';
        else actionErrorMessage += 'publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
