import React, { useMemo } from 'react';

import useGridContext from '../../../useGridContext';
import Row from './Row';
import { dataGridDefaultCheckboxProps } from '../../../dataGridDefaultValues';
import { useRowVirtualization } from './hooks';

const GridBody = () => {
    const {
        gridColumns,
        sortedData,
        gridFrozenColumns,
        isColumnFrozen,
        disableCheckboxColumn,
        disableColumnResize,
        toggleRowSelection,
        isRowSelected,
        rowStyle,
        syncGridDataWithServerAsync,
        updateRowsInGrid,
        datasetSelector,
        datasetLoadingSelector,
        valueSelector,
        isRowEditable,
        rowHeight
    } = useGridContext();
    const totalItems = sortedData.length;
    const { start, end } = useRowVirtualization(totalItems, rowHeight);

    const hasFrozenColumns = gridFrozenColumns.length > 0;

    const gridColumnsWithCheckbox = useMemo(
        () =>
            disableCheckboxColumn
                ? gridColumns
                : [dataGridDefaultCheckboxProps, ...gridColumns],
        [gridColumns, disableCheckboxColumn]
    );

    const gridFrozenColumnsWithCheckbox = useMemo(
        () =>
            disableCheckboxColumn
                ? gridFrozenColumns
                : [dataGridDefaultCheckboxProps, ...gridFrozenColumns],
        [gridFrozenColumns, disableCheckboxColumn]
    );

    const rowVirtualizationStyles = useMemo(
        () => ({
            paddingTop: `${start * rowHeight}px`,
            minHeight: `${totalItems * rowHeight}px`
        }),
        [start, totalItems]
    );

    const visibleRange = useMemo(() => sortedData.slice(start, end), [
        sortedData,
        start,
        end
    ]);

    const mapRowToComponent = (row, idx) => (
        <Row
            key={`grid-body-row-${row.id}-${idx}`}
            row={row}
            idx={idx}
            hasFrozenColumns={hasFrozenColumns}
            gridFrozenColumnsWithCheckbox={gridFrozenColumnsWithCheckbox}
            gridColumnsWithCheckbox={gridColumnsWithCheckbox}
            gridRegularColumns={gridColumns}
            syncGridDataWithServerAsync={syncGridDataWithServerAsync}
            updateRowsInGrid={updateRowsInGrid}
            toggleRowSelection={toggleRowSelection}
            rowStyle={rowStyle}
            isColumnFrozen={isColumnFrozen}
            isRowSelected={isRowSelected(row.id)}
            disableColumnResize={disableColumnResize}
            datasetSelector={datasetSelector}
            datasetLoadingSelector={datasetLoadingSelector}
            valueSelector={valueSelector}
            isRowEditable={isRowEditable}
            rowHeight={rowHeight}
        />
    );

    return (
        <div style={rowVirtualizationStyles}>
            {visibleRange.map(mapRowToComponent)}
        </div>
    );
};

export default GridBody;
