import { useMemo } from 'react';

const useVersionsViewerStyle = ({ height, theme: { spacing } }) => {
    return useMemo(() => {
        const imagesPerRow = 5;
        const imageRatio = Math.sqrt(2);
        const previewToViewerRatio = 2; // 66.66% : 33.33 % = 2
        const previewPadding = spacing.unit * 3;
        const aggregatedBorderWidth = 6; // x6 - Container, preview, viewer
        const viewerPadding = spacing.unit * 2; // Both left & right = x2
        const imageMargin = spacing.unit * 2; // Both left & right = x2
        const headerFooterHeight = spacing.unit * 5.5;

        const previewWidth = height / imageRatio;
        const viewerWidth =
            previewWidth * previewToViewerRatio -
            previewPadding -
            aggregatedBorderWidth;
        const versionWidth =
            (viewerWidth - viewerPadding) / imagesPerRow - imageMargin;
        const versionHeight = versionWidth * imageRatio;

        return {
            '--viewerHeight': `${height}px`,
            '--versionWidth': `${versionWidth}px`,
            '--versionHeight': `${versionHeight}px`,
            '--headerFooterHeight': `${headerFooterHeight}px`
        };
    }, [height, spacing]);
};

export { useVersionsViewerStyle };
