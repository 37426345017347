import React from 'react';

import { ARTWORK_SOURCES } from 'cadUpload/redux.datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import {
    RETAILERS,
    DEPARTMENTS,
    SAMPLE_FACTORY,
    FABRIC_TYPES,
    COMPOSITIONS,
    FINISHED_GSMS,
    SPECIAL_FINISHES
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import {
    DEVELOPMENT_TYPE_SENT,
    REQUEST_BY,
    HANGER_TYPE,
    YARN_COUNT,
    YARN_SUPPLIER,
    DRY_PROCESS,
    APPROVAL_STATUS,
    APPROVED_BY,
    PILLING_METHOD
} from 'fabric/redux.datasets';
import { DOMAIN_NAME as FABRIC_DOMAIN_NAME } from 'fabric/common';

import ReferenceLinkModal from './components/ReferenceLinkModal';

export const DATASETS = [
    RETAILERS,
    DEPARTMENTS,
    SAMPLE_FACTORY,
    DEVELOPMENT_TYPE_SENT,
    FABRIC_TYPES,
    COMPOSITIONS,
    FINISHED_GSMS,
    SPECIAL_FINISHES,
    REQUEST_BY,
    HANGER_TYPE,
    YARN_COUNT,
    YARN_SUPPLIER,
    DRY_PROCESS,
    APPROVAL_STATUS,
    APPROVED_BY,
    PILLING_METHOD,
    ARTWORK_SOURCES
];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number'
        },
        {
            name: 'fabricReference',
            header: 'Fabric Ref',
            type: 'text',
            render: ({ column, row }) => (
                <ReferenceLinkModal reference={row[column.name]} />
            ),
            filterable: true
        },
        {
            name: 'versionNo',
            header: 'Version No',
            type: 'text'
        },
        {
            name: 'typeSentId',
            header: 'Development Type Sent',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: DEVELOPMENT_TYPE_SENT,
            filterable: true
        },
        {
            name: 'boughtRef',
            header: 'Bought Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'fabricTypeOriginalId',
            header: 'Original Fabric Type',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: FABRIC_TYPES,
            filterable: true
        },
        {
            name: 'fabricComOriginalId',
            header: 'Original Composition',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: COMPOSITIONS,
            filterable: true
        },
        {
            name: 'fabricGsmOriginalId',
            header: 'Original GSM',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: FINISHED_GSMS,
            filterable: true
        },
        {
            name: 'originalImage',
            header: 'Original Image',
            type: 'image'
        },
        {
            name: 'fabricTypeRequiredId',
            header: 'Required Fabric Type',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: FABRIC_TYPES,
            filterable: true
        },
        {
            name: 'fabricComRequiredId',
            header: 'Required Composition',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: COMPOSITIONS,
            filterable: true
        },
        {
            name: 'fabricGsmRequiredId',
            header: 'Required GSM',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: FINISHED_GSMS,
            filterable: true
        },
        {
            name: 'fabricFinishId',
            header: 'Finish/Wash',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: SPECIAL_FINISHES,
            filterable: true
        },
        {
            name: 'washImage',
            header: 'Image',
            type: 'image'
        },
        {
            name: 'colour',
            header: 'Colour',
            type: 'text',
            filterable: true
        },
        {
            name: 'colourReference',
            header: 'Color Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'requestedById',
            header: 'Request By',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: REQUEST_BY,
            filterable: true
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: RETAILERS,
            filterable: true
        },
        {
            name: 'artworkSourceId',
            header: 'FD From',
            type: 'select',
            domain: CAD_DOMAIN_NAME,
            entity: ARTWORK_SOURCES,
            filterable: true
        },
        {
            name: 'departmentId',
            header: 'Department',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: DEPARTMENTS,
            filterable: true
        },
        {
            name: 'buyerContactPerson',
            header: 'Buyer',
            type: 'text'
        },
        {
            name: 'isSpeed',
            header: 'Speed Priority in Development?',
            type: 'text',
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'isAvailability',
            header: 'Availability Priority in Development?',
            type: 'text',
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'isSustainability',
            header: 'Sustainability Priority in Development?',
            type: 'text',
            render: ({ column, row }) =>
                row[column.name] === 'True' ? 'Yes' : 'No'
        },
        {
            name: 'dateRequested',
            header: 'Date Requested',
            type: 'date',
            filterable: true
        },
        {
            name: 'dateRequired',
            header: 'Date Required',
            type: 'date',
            filterable: true
        },
        {
            name: 'hangerTypeId',
            header: 'Hanger Type',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: HANGER_TYPE,
            filterable: true
        },
        {
            name: 'factoryId',
            header: 'Factory',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: SAMPLE_FACTORY,
            filterable: true
        },
        {
            name: 'batchNo',
            header: 'Batch No',
            type: 'text',
            filterable: true
        },
        {
            name: 'fabricComYarnId',
            header: 'Yarn Composition',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: COMPOSITIONS,
            filterable: true
        },
        {
            name: 'fabricCountYarnId',
            header: 'Yarn Count',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: YARN_COUNT,
            filterable: true
        },
        {
            name: 'fabricGsmYarnId',
            header: 'Yarn GSM',
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: FINISHED_GSMS,
            filterable: true
        },
        {
            name: 'lotNo',
            header: 'Yarn Lot No',
            type: 'text',
            filterable: true
        },
        {
            name: 'supplierId',
            header: 'Supplier',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: YARN_SUPPLIER,
            filterable: true
        },
        {
            name: 'shrinkageLength',
            header: 'Shrinkage Length%',
            type: 'number'
        },
        {
            name: 'shrinkageWidth',
            header: 'Shrinkage Width%',
            type: 'number'
        },
        {
            name: 'shrinkageSpiral',
            header: 'Shrinkage Spiral%',
            type: 'number'
        },
        {
            name: 'fabricDryProcessId',
            header: 'Fabric Dry Process',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: DRY_PROCESS,
            filterable: true
        },
        {
            name: 'fabricApprovalStatusId',
            header: 'Approval Status',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: APPROVAL_STATUS,
            filterable: true
        },
        {
            name: 'fabricApproveById',
            header: 'Approved By',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: APPROVED_BY,
            filterable: true
        },
        {
            name: 'fabricApproveDate',
            header: 'Approve Date',
            type: 'date',
            filterable: true
        },
        {
            name: 'receipeNo',
            header: 'Recipe No',
            type: 'text',
            filterable: true
        },
        {
            name: 'pillingLength',
            header: 'Pilling Length%',
            type: 'number'
        },
        {
            name: 'pillingWidth',
            header: 'Pilling Width%',
            type: 'number'
        },
        {
            name: 'pillingMethodId',
            header: 'Pilling Method',
            type: 'select',
            domain: FABRIC_DOMAIN_NAME,
            entity: PILLING_METHOD,
            filterable: true
        },
        {
            name: 'elastaneUnrecoveryLength',
            header: 'Elastane Unrecovery Length%',
            type: 'number'
        },
        {
            name: 'elastaneUnrecoveryWidth',
            header: 'Elastane Unrecovery Width%',
            type: 'number'
        },
        {
            name: 'finalImage',
            header: 'Final Image',
            type: 'image'
        }
    ];
};
