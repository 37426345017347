import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';

import { ManageColumnsContextProvider } from './useManageColumnsContext';
import ManageColumnsModalContent from './ManageColumnsModalContent';
import ManageColumnsModalFooter from './ManageColumnsModalFooter';

const propTypes = {
    onClose: PropTypes.func.isRequired,
    columnMap: PropTypes.object.isRequired,
    visibleColumns: PropTypes.array.isRequired,
    orderedColumns: PropTypes.array.isRequired
};

const styles = ({ breakpoints }) => ({
    content: { width: breakpoints.values.sm }
});

const ManageColumnsModal = withStyles(styles)(({ classes: c, ...props }) => {
    return (
        <ManageColumnsContextProvider {...props}>
            <ModalHeader onClose={props.onClose}>Manage Columns</ModalHeader>
            <ModalContent className={c.content}>
                <ManageColumnsModalContent />
            </ModalContent>
            <ModalFooter>
                <ManageColumnsModalFooter />
            </ModalFooter>
        </ManageColumnsContextProvider>
    );
});

ManageColumnsModal.propTypes = propTypes;

export default ManageColumnsModal;
