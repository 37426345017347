import React, { createContext, useContext } from 'react';

import useDataGrid from './hooks';

const initialState = {};

const GridContext = createContext(initialState);

const GridContextProvider = ({ children, ...props }) => {
    const dataGridProps = useDataGrid(props);

    return (
        <GridContext.Provider value={dataGridProps}>
            {children}
        </GridContext.Provider>
    );
};

const useGridContext = () => {
    const context = useContext(GridContext);
    if (context === undefined) {
        throw new Error(
            'useGridContext must be used within a GridContextProvider'
        );
    }
    return context;
};

export { useGridContext as default, GridContextProvider };
