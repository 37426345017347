import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ palette, spacing, typography }) => ({
    tableHeader: {
        width: 'auto',

        flexDirection: 'row',
        justifyContent: 'space-between',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    value: {
        width: '8%',
        fontSize: spacing.unit * 1.25,
        fontWeight: typography.fontWeightBold,
        //textAlign: 'center',
        padding: spacing.unit * 0.75,

        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1
    }
});

const FabricHeader = withPDFStyles(styles)(({ classes: c }) => {
    return (
        <View style={c.tableHeader}>
            <Text style={{ ...c.value, width: '9%' }}>Fabric For </Text>
            <Text style={c.value}>Fabric Type </Text>
            <Text style={{ ...c.value, width: '10%' }}>Composition </Text>
            <Text style={{ ...c.value, width: '6%' }}>GSM </Text>
            <Text style={c.value}>Dye Type </Text>
            <Text style={{ ...c.value, width: '10%' }}>Special Fabric Ref</Text>
            <Text style={{ ...c.value, width: '9%' }}>Special Finish </Text>
            <Text style={{ ...c.value, width: '9%' }}>Fabric Colour </Text>
            <Text style={{ ...c.value, width: '6%' }}>ELD </Text>
            <Text style={{ ...c.value, width: '6%' }}>Is YDS </Text>
            <Text style={{ ...c.value, width: '6%' }}>Is TC </Text>
            <Text style={{ ...c.value, width: '9%', borderRight: 'none' }}>
                Fab From
            </Text>
        </View>
    );
});

export default FabricHeader;
