// Entities
import {
    ARMHOLES,
    HEMLINES,
    LABEL_TYPES,
    NECKLINES,
    SPEC_REFERENCES,
} from 'libs';

export default {
    [ARMHOLES]: [],
    [HEMLINES]: [],
    [LABEL_TYPES]: [],
    [NECKLINES]: [],
    [SPEC_REFERENCES]: { nextPage: false, data: [] },
};
