import React from 'react';

import { withStyles, Paper } from '@material-ui/core';

import ColumnSearch from './ModalContentToolbarSearch';
import ToggleAllColumns from './ModalContentToolbarToggleAll';
import Clear from './ModalContentToolbarClear';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        padding: `${spacing.unit * 1.5}px ${spacing.unit * 2}px`
    },
    toggleRoot: {
        marginLeft: 0
    }
});

const ModalContentToolbar = withStyles(styles)(
    ({ classes: { toggleRoot, ...c } }) => {
        return (
            <Paper classes={c} elevation={0}>
                <ToggleAllColumns className={toggleRoot} />
                <ColumnSearch />
                <Clear />
            </Paper>
        );
    }
);

export default ModalContentToolbar;
