import React from 'react';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ spacing, palette }) => ({
    divider: {
        width: '100%',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark,

        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 3
    }
});

const Divider = withPDFStyles(styles)(({ classes }) => {
    return <View style={classes.divider} />;
});

export default Divider;
