import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormAsyncErrors,
    getFormSubmitErrors,
    getFormSyncErrors,
    getFormValues,
    isSubmitting,
    initialize as formInitialize
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import { useValidate, useNext } from '@libHooks/useValidate';
import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';

import { FinalPhotoUploadProvider } from './useFinalPhotoUpload';
import { useHandles } from './hooks';
import { FORM_NAME } from './common';
import useSchema from './content/useSchema';
import {
    publishFinalPhoto,
    destroyFinalPhotoForm
} from 'sampling/redux.actions';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => {
    const submitErrors = getFormSubmitErrors(FORM_NAME)(state);
    const asyncErrors = getFormAsyncErrors(FORM_NAME)(state);
    const syncErrors = getFormSyncErrors(FORM_NAME)(state);

    return {
        formValues: getFormValues(FORM_NAME)(state),
        formErrors: { ...submitErrors, ...asyncErrors, ...syncErrors },
        formSubmitting: isSubmitting(FORM_NAME)(state)
    };
};

const mapDispatch = {
    publish: publishFinalPhoto,
    destroyForm: destroyFinalPhotoForm,
    formInitialize
};

const FinalPhotoUploadContainer = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        publish,
        destroyForm,
        formInitialize,
        handleReduxFormSubmit
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );

        const schema = useSchema(formValues);
        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);

        const [handleNext, reset] = useHandles({
            dispatch,
            activeStep,
            publish
        });
        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleReduxFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, handleNext]);

        const initialize = useCallback(
            state => ({
                ...state,
                reset
            }),
            [reset]
        );

        useEffect(() => {
            formInitialize(FORM_NAME, {});
            return () => destroyForm();
        }, [destroyForm]);

        return (
            <Paper className={c.container}>
                <FinalPhotoUploadProvider initialize={initialize}>
                    {children}
                </FinalPhotoUploadProvider>
                {!complete && (
                    <Button
                        className={c.button}
                        onClick={handleNextWithSubmit}
                        loading={formSubmitting}
                        disabled={!areValid.publish}
                    >
                        {proceedText}
                    </Button>
                )}
            </Paper>
        );
    }
);

export default FinalPhotoUploadContainer;
