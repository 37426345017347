import { useMemo } from 'react';

const useFabricDetails = ({ includeTc, ...details }) =>
    useMemo(
        () =>
            Object.entries(details)
                .map(([label, value]) => {
                    switch (label) {
                        case 'fabricType':
                            return { label: 'Fabric Type', value, order: 0 };
                        case 'composition':
                            return { label: 'Composition', value, order: 1 };
                        case 'finishedGsm':
                            return { label: 'Finished GSM', value, order: 2 };
                        case 'dyeType':
                            return value
                                ? { label: 'Dye Type', value, order: 3 }
                                : null;
                        case 'specialFabricRef':
                            return {
                                label: 'Special Fabric Ref',
                                value,
                                order: 4
                            };
                        case 'specialFinish':
                            return value
                                ? { label: 'Special Finish', value, order: 5 }
                                : null;
                        case 'fabricColour':
                            return { label: 'Fabric Colour', value, order: 6 };
                        case 'eld':
                            return { label: 'ELD', value, order: 7 };
                        case 'tcText':
                            return includeTc
                                ? { label: 'TC Text', value, order: 8 }
                                : null;
                        case 'isDischargeable':
                            return {
                                label: 'Is Dischargeable',
                                value,
                                type: 'boolean',
                                order: 9
                            };
                        case 'isYds':
                            return {
                                label: 'Is YDS',
                                value,
                                type: 'boolean',
                                order: 10
                            };
                        case 'isEngineeredStripe':
                            return {
                                label: 'Is Engineered Stripe',
                                value,
                                type: 'boolean',
                                order: 11
                            };
                        case 'isTc':
                            return includeTc
                                ? {
                                      label: 'Is TC',
                                      value,
                                      type: 'boolean',
                                      order: 12
                                  }
                                : null;
                        case 'fabFrom':
                            return { label: 'Fab From', value, order: 11 };
                        default:
                            return null;
                    }
                })
                .filter(details => !!details)
                .sort((a, b) => a.order - b.order)
                .map(({ order, ...detail }) => detail),
        [details]
    );

export { useFabricDetails };
