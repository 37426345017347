import createCachedSelector from 're-reselect';

// Local
import { selectDatasets } from '../common';

// Entities
import {
    COLLECTIONS,
    LICENSORS
} from 'libs';

// Artwork Locations
export const selectCollectionsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[COLLECTIONS]
)(() => 'placeholder');

// Artwork Scales
export const selectLicensorsData = createCachedSelector(
    selectDatasets,
    datasets => datasets[LICENSORS]
)(() => 'placeholder');


