import React from 'react';

import User from './User';
import UserListView from './UserListView';

import { NEW_USER_ROUTE_ID, AMEND_USER_ROUTE_ID } from './User/common';
import { USER_LIST_ROUTE_ID } from './UserListView/common';

export default {
    [NEW_USER_ROUTE_ID]: () => <User />,
    [AMEND_USER_ROUTE_ID]: () => <User mode='amend' />,
    [USER_LIST_ROUTE_ID]: () => <UserListView />
};
