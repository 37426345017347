import { useCallback, useMemo } from 'react';
import BasicInformation from './content/BasicInformation';
import UploadCad from './content/UploadCad';
import Complete from './content/Complete';

import { LABELS } from './common';
import useStepperHandle from '@libHooks/useStepperHandle';
import isBlobUrl from '@libs/isBlobUrl';

const useUploadCadLabel = mode =>
    useMemo(() => {
        switch (mode) {
            case 'incomplete':
                return LABELS.editCad;
            case 'amend':
                return LABELS.amendCad;
            default:
                return LABELS.uploadCad;
        }
    }, [mode]);

const useContent = mode => {
    const uploadCadLabel = useUploadCadLabel(mode);
    return useMemo(() => {
        const basicInformation =
            mode === 'new'
                ? { [LABELS.basicInformation]: BasicInformation }
                : {};

        return {
            ...basicInformation,
            [uploadCadLabel]: UploadCad,
            [LABELS.complete]: Complete
        };
    }, [mode]);
};

const useHandleNext = ({ mode, ...args }) => {
    const amountOfSteps = mode === 'new' ? 3 : 2;
    return useStepperHandle({ amountOfSteps, mode, ...args });
};

const useImageResolver = sizePaths =>
    useCallback(
        ({ url = '' } = {}, size) => {
            if (isBlobUrl(url)) return url;

            const { sizeOriginal, size800, size350 } = sizePaths;
            if (size === 'big') return url.replace(sizeOriginal, size800);
            else if (size === 'small')
                return url.replace(sizeOriginal, size350);
            else return url;
        },
        [sizePaths]
    );

export { useContent, useUploadCadLabel, useHandleNext, useImageResolver };
