function printNewStepValidate(stepNo, data) {
    let isValid = false;
    switch (stepNo) {
        case 1:
            if (
                data.selectedRetailer &&
                data.selectedDepartment &&
                data.productType &&
                data.designFrom &&
                data.factoryLocation &&
                ((data.copySampleRefChecked && !!data.copySampleRef) || true) &&
                ((data.manualSampleRefChecked && data.sampleRef.length > 3) ||
                    true)
            ) {
                isValid = true;
            }
            break;

        case 2:
            if (
                data.requestType &&
                data.sendToBuyer &&
                // Magic
                ((data.designFrom && ['L', 'DL'].indexOf(data.designFrom.value) &&
                    !!data.sendToLicensor) ||
                    true)
            ) {
                isValid = true;
            }
            break;

        case 3:
            if (
                data.graphicref &&
                data.graphicdesc &&
                data.artWorkFileLocation &&
                data.artWorkScale &&
                data.printType
            ) {
                isValid = true;
            }
            break;

        case 4:
            if (data.neckline && data.specref && data.specdesc) {
                isValid = true;
            }
            break;

        case 5:
            isValid = true;
            break;

        default:
            break;
    }
    return isValid;
}

function printAmendStepValidate(stepNo, data) {
    let isValid = false;
    switch (stepNo) {
        case 1:
            if (
                data.requestType &&
                data.sendToBuyer &&
                (data.designFrom &&
                (data.designFrom.value === 'L' ||
                    data.designFrom.value === 'DL')
                    ? data.sendToLicensor
                        ? true
                        : false
                    : true)
            ) {
                isValid = true;
            }
            break;

        case 2:
            if (
                data.graphicref &&
                data.graphicdesc &&
                data.artWorkFileLocation &&
                data.artWorkScale &&
                data.printType
            ) {
                isValid = true;
            }
            break;

        case 3:
            if (data.neckline && data.specref && data.specdesc) {
                isValid = true;
            }
            break;

        case 4:
            isValid = true;
            break;

        default:
            break;
    }
    return isValid;
}

export { printNewStepValidate, printAmendStepValidate };
