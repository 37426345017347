// Action Types
import {
    SET_FABRICS,
    EDIT_FABRIC,
    REMOVE_FABRIC,
    PUBLISH_TO_DATABASE_DRAFT_SUCCESS
} from 'actionTypes';

// Selectors
import { selectNextFabricFor } from 'selectors';

const fabricsReducer = (state, { type, payload, meta, ...action }) => {
    switch (type) {
        case SET_FABRICS: {
            const { fabrics = [] } = payload;

            return {
                ...state,
                fabrics: [...state.fabrics, ...fabrics]
            };
        }

        case EDIT_FABRIC: {
            const { fabricIndex } = meta;
            const { fabricDetails } = payload;

            return {
                ...state,
                fabrics: state.fabrics.map((fabric, index) => {
                    if (fabricIndex === index) {
                        return { ...fabric, ...fabricDetails };
                    } else return fabric;
                })
            };
        }

        case REMOVE_FABRIC: {
            // const { fabricId } = payload;
            const { fabricIndex } = meta;

            return {
                ...state,
                fabrics: state.fabrics.filter(
                    (fabric, index) => index !== fabricIndex
                )
            };
        }

        case PUBLISH_TO_DATABASE_DRAFT_SUCCESS: {
            const {
                sta: {
                    value: {
                        data: { fabrics }
                    }
                }
            } = payload;

            const newFabrics = state.fabrics.map((fabric, index) => {
                return { ...fabric, fabricId: fabrics[index] };
            });

            return {
                ...state,
                fabrics: newFabrics
            };
        }

        default: {
            return state;
        }
    }
};

export { fabricsReducer as default, fabricsReducer };
