import React, { Fragment } from 'react';
import { object, func } from 'prop-types';

import { withStyles, Paper } from '@material-ui/core';

import OverlayScrollContainer from '@baseComponents/OverlayScrollContainer';

import Chip from './Chip';

const styles = ({ spacing }) => ({
    root: {
        position: 'relative',
        display: 'flex',
        padding: spacing.unit * 0.25,
        margin: spacing.unit * 0.5,
        boxShadow: 'none',
        maxWidth: spacing.unit * 48,
        height: spacing.unit * 5
    }
});

const propTypes = {
    chips: object.isRequired,
    handleClearFilter: func.isRequired
};

const FilteredChips = withStyles(styles)(
    ({ classes: c, chips, handleClearFilter }) => {
        const Wrapper =
            Object.values(chips).length > 3 ? OverlayScrollContainer : Fragment;

        return (
            <Paper className={c.root}>
                <Wrapper>
                    {Object.entries(chips).map(([key, value]) => {
                        return (
                            <Chip
                                key={key}
                                filterKey={key}
                                label={value}
                                onDelete={() => handleClearFilter(key)}
                            />
                        );
                    })}
                </Wrapper>
            </Paper>
        );
    }
);

FilteredChips.propTypes = propTypes;

export default FilteredChips;
