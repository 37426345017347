import React from 'react';
import { View, StyleSheet, Page } from '@react-pdf/renderer';
import { string, bool } from 'prop-types';

import { Field, Checkbox, Title } from 'components/PDF';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    frame: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

const propTypes = {
    sampleReference: string,
    developmentName: string,
    fitOption: string,
    specReference: string,
    specDescription: string,
    departmentNumber: string,
    referenceFrom: string,
    sizeRange: string,
    neckline: string,
    armhole: string,
    hemline: string,
    labelType: string,
    pendingPrint: bool,
    photoApproval: bool
};

const defaultProps = {
    photoApproval: false
};

const RefAndDetails = ({
    sampleReference,
    developmentName,
    fitOption,
    styleDescription,
    specReference,
    specDescription,
    departmentNumber,
    referenceFrom,
    sizeRange,
    neckline,
    waistband,
    armhole,
    hemline,
    labelType,
    pendingPrint,
    photoApproval
}) => (
    <View style={styles.container}>
        <Title text={'Sample Reference & Spec Details'} />
        <Field
            label={'Sample Reference'}
            value={sampleReference}
            required={false}
        />
        <Field
            label={'Development No'}
            value={developmentName}
            required={false}
        />
        <Field label={'Fit Options'} value={fitOption} required={false} />
        <Field
            label={'Style Description'}
            value={styleDescription}
            required={false}
        />
        <Field
            label={'Spec Reference'}
            value={specReference}
            required={false}
        />
        <Field
            label={'Spec Description'}
            value={specDescription}
            required={false}
        />
        <Field label={'Dept No'} value={departmentNumber} required={false} />
        <Field
            label={'Reference From'}
            value={referenceFrom}
            required={false}
        />
        <Field label={'Size Range'} value={sizeRange} required={false} />
        {!!neckline && <Field label={'Neckline'} value={neckline} />}
        {!!waistband && <Field label={'Waistband'} value={waistband} />}
        <Field label={'Armholes/Cuffs'} value={armhole} />
        <Field label={'Hemline'} value={hemline} />
        <Field label={'Label Type'} value={labelType} required={false} />
        <Checkbox label={'Pending Print'} checked={!!pendingPrint} />
        <Checkbox
            label={'Request For Photo Approval'}
            checked={!!photoApproval}
        />
    </View>
);

RefAndDetails.propTypes = propTypes;
RefAndDetails.defaultProps = defaultProps;

export default RefAndDetails;
