import { SET_DATASET, SET_PAGINATION_DATASET } from 'actionTypes';

const commonReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SET_DATASET: {
            const { entity } = action.meta;
            const { dataset } = payload;

            return { ...state, [entity]: dataset };
        }
        case SET_PAGINATION_DATASET: {
            const { entity, shouldAppend } = action.meta;
            const { data: rawData, nextPage } = payload;

            const data = shouldAppend
                ? [...state[entity].data, ...rawData]
                : rawData;

            // return state
            return { ...state, [entity]: { data, nextPage } };
        }

        default: {
            return state;
        }
    }
};

export { commonReducer as default, commonReducer };
