import { required, validateStringWithSpaces } from '@libs/validationRules';

export const validationRules = [required, validateStringWithSpaces];

export const PO_SIZE_SET_ROUTE_ID = 222;
export const ORDER_EMAIL_BODY_ROUTE_ID = 383;
export const ORDER_EMAIL_GROUP_ROUTE_ID = 384;
export const ORDER_FACTORY_ROUTE_ID = 212;
export const ORDER_RETAILER_FACTORY_ROUTE_ID = 369;
export const TRIM_LABEL_TYPE_ROUTE_ID = 215;
export const TRIM_LABEL_ROUTE_ID = 216;
export const PACKING_PACK_TYPE_ROUTE_ID = 371;
export const SEASON_ROUTE_ID = 373;
export const COLOUR_ROUTE_ID = 210;
export const PO_SHIP_MODE_ROUTE_ID = 220;
export const PO_SHIP_DESTINATION_ROUTE_ID = 211;
export const PO_TYPE_ROUTE_ID = 218;
export const PACKING_BOX_TYPE_ROUTE_ID = 209;
export const ORDER_NOTE_ROUTE_ID = 374;
export const ORDER_STATUS_ROUTE_ID = 390;
