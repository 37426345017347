import { useMemo } from 'react';

import { required, number, absoluteNumber } from '@libs/validationRules';

import { LABELS } from '../common';

const commonNumberFieldRules = [number, absoluteNumber];
const foundGSMRules = [number];
const testCuttingRules = [required, ...commonNumberFieldRules];

const useSchema = (mode, values, isQA) => {
    const setupSchema = useMemo(
        () => ({
            jwpNo: true,
            qcStyle: true,
            qcColor: true,
            ...(isQA ? { qcDate: true } : { sealDate: true })
        }),
        [isQA]
    );

    const nonMandatorySchemaStep2 = useMemo(
        () => ({
            frontImage: (_, fieldError) => !fieldError,
            backImage: (_, fieldError) => !fieldError,
            ...(isQA
                ? {
                      washShrinkage: (_, fieldError) => !fieldError,
                      printShrinkage: (_, fieldError) => !fieldError,
                      spirality: (_, fieldError) => !fieldError,
                      foundGSM: (_, fieldError) => !fieldError
                  }
                : {})
        }),
        [isQA]
    );

    const nonMandatorySchemaStep3 = useMemo(
        () => ({
            attachments: (_, fieldError) => !fieldError,
            ...(isQA
                ? {
                      sampleQTY: (_, fieldError) => !fieldError,
                      rejectQTY: (_, fieldError) => !fieldError,
                      dhu: (_, fieldError) => !fieldError,
                      tdr: (_, fieldError) => !fieldError
                  }
                : {})
        }),
        [isQA]
    );

    const detailsSchema = useMemo(
        () => ({
            qcReference: true,
            ...nonMandatorySchemaStep2
        }),
        [nonMandatorySchemaStep2]
    );
    const othersSchema = useMemo(
        () => ({
            ...nonMandatorySchemaStep3,
            ...(isQA
                ? {
                      sampleReviewStatus: true
                  }
                : {})
        }),
        [isQA, nonMandatorySchemaStep3]
    );

    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema,
            ...othersSchema
        }),
        [setupSchema, detailsSchema, othersSchema]
    );

    return useMemo(
        () => ({
            [LABELS.setup]: setupSchema,
            [LABELS.details]: detailsSchema,
            [LABELS.other]: othersSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: {
                ...setupSchema,
                ...nonMandatorySchemaStep2,
                ...nonMandatorySchemaStep3
            }
        }),
        [
            setupSchema,
            detailsSchema,
            othersSchema,
            publishSchema,
            nonMandatorySchemaStep2,
            nonMandatorySchemaStep3
        ]
    );
};

export {
    useSchema as default,
    commonNumberFieldRules,
    foundGSMRules,
    testCuttingRules
};
