export const LOGIN = 'auth@login';
export const SET_AUTH_DATA = 'auth@set-auth-data';
export const LOGOUT = 'auth@logout';

export const FORGOT_PASSWORD_EMAIL_SEND = 'auth@forgot-password-email-send';
export const CHANGE_PASSWORD = 'auth@change-password';

export const RESEND_OTP = 'auth@resend-otp';
export const SEND_OTP_LOADING = 'auth@send-otp-loading';
export const VERIFY_OTP = 'auth@verify-otp';
