import { formValueSelector } from 'redux-form';
import { select, call, all, fork } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';

import { sizePathsSelector } from 'common/redux.selectors';
import { userNameSelector } from 'auth/redux.selectors';
import { getFileNameFromURL } from '@libs/filenames';
import { syncReportToDatabase } from 'common/redux.sagas';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';

import { FORM_NAME } from 'qualityControl/SealSample/common';
import { QCEmailSchema } from './schemas';

export default function*({
    qcReference,
    qcVersionNo,
    mode,
    hasEmail,
    handleLoading
}) {
    try {
        const {
            pdf,
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body
        } = yield select(formValueSelector(FORM_NAME), 'mail');

        const [userName, PDFBlobs, reportSizePaths] = yield all([
            select(userNameSelector),
            call(getAssetBlobsWorker, {
                assets: pdf,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'reportPath')
        ]);

        const [PDFPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: PDFBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/${qcReference}`,
            reference: qcReference,
            sizePaths: reportSizePaths,
            handleLoading,
            developmentName: `V${qcVersionNo}`
        });

        const originalPath = PDFPayload ? PDFPayload.sizeOriginal : '';

        const absoluteURL = yield call(syncReportToDatabase, {
            reportReference: qcReference,
            reportUrl: originalPath,
            referenceDevNo: qcVersionNo
        });

        if (hasEmail) {
            const fileName = yield call(getFileNameFromURL, absoluteURL);

            let composedBody = `
                    ${body}
                    <br/>
                    <a style="color: #234c72;margin-right: 10px;font-size: 16px;" href="${absoluteURL}">${fileName}</a>`;

            const emailPayload = composeApiPayload(
                {
                    userName,
                    toAddress: toAddress.replace(/\s/g, ''),
                    ccAddress,
                    subject,
                    body: composedBody,
                    preSealQcRef: qcReference
                },
                QCEmailSchema
            );

            yield fork(apiRequest, {
                method: 'post',
                url: 'EmailSend/PreSealQcEmailSend',
                data: emailPayload
            });
        }
    } catch (err) {
        console.log('error in report worker', err);
    }
}
