import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Divider } from 'components';
import DrawerDate from './DrawerDate';
import DrawerSettings from './DrawerSettings';

const RightMenu = memo(function RightMenu({ ...props }) {
    const { isOpen, action } = props;
    return (
        <Drawer
            open={isOpen}
            anchor='right'
            onClose={() => action('rightDrawer')}
        >
            <div tabIndex={0} role='button'>
                <DrawerDate /> {/*TODO: Temp, required design and API change */}
                <Divider />
                <DrawerSettings />{' '}
                {/*TODO: Temp, required design and API change */}
            </div>
        </Drawer>
    );
});

RightMenu.defaultProps = {
    isOpen: false,
    action: () => {}
};

RightMenu.propTypes = {
    isOpen: PropTypes.bool,
    action: PropTypes.func
};

export { RightMenu as default, RightMenu };
