import { fork } from 'redux-saga/effects';

// Proper
import authSaga from './auth';
import datasetsSaga from './datasets';
import reportsSaga from './reports';
import samplingSaga from './sampling';
import utilsSaga from './utils';

function* rootSaga() {
    yield fork(authSaga);
    yield fork(datasetsSaga);
    yield fork(reportsSaga);
    yield fork(samplingSaga);
    yield fork(utilsSaga);
}

export { rootSaga as default, rootSaga };
