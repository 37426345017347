import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';

// Local
import MultiSelectModel from './MultiSelectModel';

// Selectors

// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    defaultValues: PropTypes.array
};

const defaultProps = {
    styles: {},
    defaultValues: []
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data.map(({ id, ...datum }) => ({
        label: Object.values(datum)[0],
        value: id
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { name, domain, entity }) => ({
    // amended: selectAmendedField(state, name),
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const MultiSelect = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...restProps }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return <MultiSelectModel {...restProps} />;
});

MultiSelect.propTypes = propTypes;
MultiSelect.defaultProps = defaultProps;

export { MultiSelect as default, MultiSelect };
