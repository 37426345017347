import React from 'react';

const DeleteIcon = ({ color: fill = 'rgba(0, 0, 0, 0.87)', ...restProps }) => {
    return (
        <svg {...restProps} x='0px' y='0px' viewBox='0 0 10 12'>
            <path
                style={{
                    fill,
                    fillRule: 'evenodd'
                }}
                d='M651.578,982.381h-2.949a0.422,0.422,0,0,0-.42-0.381H645.79a0.422,0.422,0,0,0-.419.381h-2.949a0.423,0.423,0,0,0-.422.424v0.8a0.423,0.423,0,0,0,.422.424h9.156a0.423,0.423,0,0,0,.422-0.424v-0.8A0.423,0.423,0,0,0,651.578,982.381Zm-8.693,2.465v8.73a0.423,0.423,0,0,0,.422.424h7.386a0.423,0.423,0,0,0,.422-0.424v-8.73h-8.23Zm3.089,6.553a0.466,0.466,0,1,1-.931,0V986.77a0.466,0.466,0,1,1,.931,0V991.4Zm2.983,0a0.466,0.466,0,1,1-.931,0V986.77a0.466,0.466,0,1,1,.931,0V991.4Z'
                transform='translate(-642 -982)'
            />
        </svg>
    );
};

export { DeleteIcon as default, DeleteIcon };
