import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Libs
import BaseCard from '@baseComponents/Card';
import { valueSelector } from '@libs/datasets';

// Bought
import { DOMAIN_NAME as TRIM_DOMAIN_NAME } from 'trim/common';
import { TRIM_TYPES, TRIM_SUPPLIERS } from 'trim/redux.datasets';

// Local
import Title from './Title';
import ActionsAdornment from './ActionsAdornment';
import { useBoughtDetails } from './hooks';
import { sizePathsSelector } from 'common/redux.selectors';
import { useTrimDetailsDispatch } from '../useTrim';

const propTypes = {
    title: PropTypes.string.isRequired,
    trimCardReference: PropTypes.string.isRequired,
    image: PropTypes.string,
    trimType: PropTypes.number,
    trimSupplier: PropTypes.number,
    description: PropTypes.string,
    size: PropTypes.string,
    composition: PropTypes.string,
    application: PropTypes.string,
    position: PropTypes.string,
    supplierRef: PropTypes.string,
    colour: PropTypes.string,
    quantity: PropTypes.number,

    index: PropTypes.number.isRequired,
    handleDelete: PropTypes.func.isRequired
};

const defaultProps = {
    colour: '',
    quantity: 0
};

const mapState = (state, { trimType, trimSupplier }) => ({
    trimType: valueSelector(state, TRIM_DOMAIN_NAME, TRIM_TYPES, trimType),
    trimSupplier: valueSelector(
        state,
        TRIM_DOMAIN_NAME,
        TRIM_SUPPLIERS,
        trimSupplier
    ),
    trimCardSizePaths: sizePathsSelector(state, 'trimCard')
});

const Card = connect(mapState)(
    ({ title, index, handleDelete, trimCardSizePaths, ...props }) => {
        const dispatch = useTrimDetailsDispatch();

        const imageResolver = useCallback(
            url => {
                if (url) {
                    const { sizeOriginal, size100 } = trimCardSizePaths;

                    return url.replace(sizeOriginal, size100);
                } else return url;
            },
            [trimCardSizePaths]
        );

        const details = useBoughtDetails(props, imageResolver, dispatch, index);

        return (
            <BaseCard
                title={<Title value={title} />}
                ActionsAdornment={
                    <ActionsAdornment handleDelete={handleDelete} />
                }
                details={details}
                minAmountShown={7}
            />
        );
    }
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export { Card as default, Card };
