import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core';

// Libs
import { withField } from '@libs/reduxForm';
import ContentContainer from '@libComponents/ContentContainer';

// Local
import {
    productsLoadingSelector,
    sampleRefChangeDataLoadingSelector,
    distinctProductsSelector
} from 'orders/redux.selectors';
import Content from './Content';
import Adornment from './Adornment';
import validation from './validation';

const propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'products',
    required: false,
    validate: validation
};

const mapState = (state, { input: { value } }) => ({
    loading:
        productsLoadingSelector(state) ||
        sampleRefChangeDataLoadingSelector(state),
    products: distinctProductsSelector(value)
});

const styles = () => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    }
});

const ProductsContainer = compose(
    withField(),
    connect(
        mapState,
        null
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        products,
        meta: { touched, error, form },
        fieldName,
        loading,
        required
    }) => {
        const id = `${form}-${fieldName}`;
        return (
            <ContentContainer
                title={`Product details`}
                required={required}
                error={touched && error ? error : ''}
                AdornmentComponent={<Adornment loading={loading} />}
                styles={{ container: c.container }}
                id={id}
            >
                <Content products={products} loading={loading} />
            </ContentContainer>
        );
    }
);

ProductsContainer.propTypes = propTypes;
ProductsContainer.defaultProps = defaultProps;

export { ProductsContainer as default, ProductsContainer };
