import { END, eventChannel } from 'redux-saga';
import { call, fork, take, put } from 'redux-saga/effects';

// Local
import { setLoader, deleteLoader, progressLoader } from 'store/actions';

const progressChannel = () => {
    let emit;

    const channel = () =>
        eventChannel(emitter => {
            emit = emitter;
            return () => {};
        });

    const progress = ({ loaded, total }) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };
    return { channel, progress };
};

function* progressWorker({ emissions, token }) {
    yield put(setLoader(token));
    while (true) {
        const progress = yield take(emissions);
        yield put(progressLoader({ progress, loader: token }));
        if (progress === 100) yield put(deleteLoader(token));
    }
}

function* uploadProgress(token) {
    const { channel, progress } = progressChannel();
    const emissions = yield call(channel);
    yield fork(progressWorker, { emissions, token });
    return progress;
}

export { uploadProgress as default, uploadProgress };
