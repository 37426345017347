import { SET_REPORTS_VIEW_MODE, SET_REPORTS_DATA } from 'store/actionTypes';

export const setReportsViewMode = payload => ({
    type: SET_REPORTS_VIEW_MODE,
    payload
});

export const setReportsData = payload => ({
    type: SET_REPORTS_DATA,
    payload
});
