import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { string, func, oneOfType, number } from 'prop-types';

import { withStyles, Chip as MuiChip } from '@material-ui/core';

import { chipValueSelector } from 'gridView/redux.selectors';

const styles = ({ spacing, palette }) => ({
    chip: {
        fontSize: spacing.unit * 1.5,
        margin: spacing.unit * 0.25,
        backgroundColor: palette.background.light,
        '&:focus': {
            backgroundColor: palette.background.dark
        }
    },
    label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: spacing.unit * 10
    },
    deleteIcon: {
        width: spacing.unit * 2.5
    }
});

const propTypes = {
    filterKey: string.isRequired,
    label: oneOfType([string.isRequired, number.isRequired]),
    onDelete: func.isRequired
};

const mapStateToProps = (state, { filterKey, label }) => ({
    ...chipValueSelector(state, filterKey, label)
});

const Chip = compose(
    connect(
        mapStateToProps,
        null
    ),
    withStyles(styles)
)(({ classes: c, filterKey, label, onDelete }) => {
    return (
        <MuiChip
            label={<div className={c.label}>{label}</div>}
            className={c.chip}
            classes={{ deleteIcon: c.deleteIcon }}
            onDelete={onDelete}
        />
    );
});

Chip.propTypes = propTypes;

export default Chip;
