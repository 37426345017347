import { takeLatest, call, put, select } from 'redux-saga/effects';
import { API_BASE_URL, apiClient } from 'store/config';
import { formValueSelector } from 'redux-form';
import {
    apiRequest,
    backneckBaseFormatData,
    deserializeBackneckData
} from 'helpers';

// Action Types
import {
    FETCH_TRIMS_INFO,
    FETCH_TRIMS_INFO_SUCCESS,
    FETCH_BOUGHT_DETAILS_TYPES,
    FETCH_BOUGHT_DETAILS_TYPES_SUCCESS,
    GET_SINGLE_TRIM_INFO_REQUEST,
    GET_SINGLE_TRIM_INFO_SUCCESS,
    FETCH_BACKNECK_DETAILS_REQUEST,
    LOAD_BACKNECK_DATA,
    GET_BOUGHT_DETAILS_REQUEST,
    GET_BOUGHT_DETAILS_SUCCESS,
    POST_BACKNECK_DATA_REQUEST,
    POST_BACKNECK_DATA_SUCCESS,
    RESET_BACKNECK_DATA_REQUEST,
    UPDATE_BACKNECK_DETAILS_REQUEST,
    UPDATE_BACKNECK_DETAILS_SUCCESS,
    UPLOAD_ATTACHMENTS,
    UPLOAD_ATTACHMENTS_SUCCESS
} from 'store/actionTypes';

// Selectors
import { selectAttachmentsData } from 'store/selectors';

//API calling
async function getTrims(
    { userId, retailerId, searchKey, pageNo },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_TRIMS_INFO;
    return await apiClient.get(
        API_BASE_URL +
            `api/TrimCards/GetTrimsReferenceForSample?userId=${userId}&retailerId=${retailerId}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}`
    );
}

async function getBoughtDetails(
    { userId, retailerId, searchKey, pageNo },
    pageSize = 10
) {
    apiClient.defaults.headers.common['xtype'] = FETCH_BOUGHT_DETAILS_TYPES;
    return await apiClient.get(
        API_BASE_URL +
            `api/BoughtMains/GetBoughtForSample?userId=${userId}&retailerId=${retailerId}&pageNumber=${pageNo}&pageSize=${pageSize}&filterKey=${searchKey}`
    );
}

async function getSingleTrimInfo({ trimCardRef }) {
    apiClient.defaults.headers.common['xtype'] = GET_SINGLE_TRIM_INFO_REQUEST;
    return await apiClient.get(API_BASE_URL + `api/TrimCards/${trimCardRef}`);
}

async function getSingleBoughtInfo({ boughtRef }) {
    apiClient.defaults.headers.common['xtype'] = GET_BOUGHT_DETAILS_REQUEST;
    return await apiClient.get(API_BASE_URL + `api/BoughtMains/${boughtRef}`);
}

//workers
function* fetchTrimsEffectSaga({ payload }) {
    try {
        const state = yield select();
        const selector = formValueSelector('SamplingPrintForm');
        const retailerId = selector(state, 'retailerId');
        const userId = state.auth.userId;

        const res = yield call(getTrims, {
            ...payload,
            retailerId,
            userId
        });
        yield put({
            type: FETCH_TRIMS_INFO_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* fetchBoughtDetailsEffectSaga({ payload }) {
    try {
        const state = yield select();
        const selector = formValueSelector('SamplingPrintForm');
        const retailerId = selector(state, 'retailerId');
        const userId = state.auth.userId;

        const res = yield call(getBoughtDetails, {
            ...payload,
            retailerId,
            userId
        });
        yield put({
            type: FETCH_BOUGHT_DETAILS_TYPES_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* fetchBackneckEffectSaga({ payload }) {
    try {
        const { backnecks } = yield select(
            ({ initSamplingPrint: { backnecks } }) => ({
                backnecks
            })
        );

        const backneckDetails = backnecks.filter(
            (item, index) => index === payload.backneckEditIndex
        )[0]; // Convert into object

        const formattedDetails = deserializeBackneckData(backneckDetails);
        yield put({
            type: LOAD_BACKNECK_DATA,
            payload: formattedDetails
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* postBackneckEffectSaga() {
    try {
        const stateData = yield select(
            ({
                initSamplingPrint: {
                    backneckGraphicRef,
                    backneckFabricPatchH,
                    backneckFabricPatchW,
                    backneckPrintH,
                    backneckPrintW,
                    backneckFabricDetails,
                    backneckInc1Details,
                    backneckInc2Details,
                    backneckImg
                }
            }) => ({
                graphicRef: backneckGraphicRef,
                fabricPatchHeight: backneckFabricPatchH,
                fabricPatchWidth: backneckFabricPatchW,
                backneckPrintHeight: backneckPrintH,
                backneckPrintWidth: backneckPrintW,
                fabricDetails: backneckFabricDetails,
                ink1: backneckInc1Details,
                ink2: backneckInc2Details,
                images: backneckImg
            })
        );

        const payload = [backneckBaseFormatData(stateData)];
        yield put({
            type: POST_BACKNECK_DATA_SUCCESS,
            payload
        });
        yield put({
            type: RESET_BACKNECK_DATA_REQUEST
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* updateBackneckEffectSaga({ payload }) {
    try {
        const stateData = yield select(
            ({
                initSamplingPrint: {
                    backneckGraphicRef,
                    backneckFabricPatchH,
                    backneckFabricPatchW,
                    backneckPrintH,
                    backneckPrintW,
                    backneckFabricDetails,
                    backneckInc1Details,
                    backneckInc2Details,
                    backneckImg
                }
            }) => ({
                graphicRef: backneckGraphicRef,
                fabricPatchHeight: backneckFabricPatchH,
                fabricPatchWidth: backneckFabricPatchW,
                backneckPrintHeight: backneckPrintH,
                backneckPrintWidth: backneckPrintW,
                fabricDetails: backneckFabricDetails,
                ink1: backneckInc1Details,
                ink2: backneckInc2Details,
                images: backneckImg
            })
        );
        const formattedData = { data: backneckBaseFormatData(stateData) };
        formattedData['backneckEditIndex'] = payload.backneckEditIndex;

        yield put({
            type: UPDATE_BACKNECK_DETAILS_SUCCESS,
            payload: formattedData
        });

        yield put({
            type: RESET_BACKNECK_DATA_REQUEST
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* getSingleTrimInfoEffectSaga() {
    try {
        const state = yield select();
        const {
            initSamplingPrint: {
                selectedTrim: {
                    value: {
                        fullData: { trimCardRef }
                    }
                }
            }
        } = state;
        const res = yield call(getSingleTrimInfo, { trimCardRef });
        yield put({
            type: GET_SINGLE_TRIM_INFO_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

function* getSingleBoughtInfoEffectSaga() {
    try {
        const state = yield select();
        const {
            initSamplingPrint: {
                selectedBought: {
                    value: {
                        fullData: { boughtRef }
                    }
                }
            }
        } = state;
        const res = yield call(getSingleBoughtInfo, { boughtRef });
        //201
        yield put({
            type: GET_BOUGHT_DETAILS_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // if (err && err.response) flashMessage(err.response, true);
    }
}

// Watchers
const otherWatchers = function*() {
    yield takeLatest(FETCH_TRIMS_INFO, fetchTrimsEffectSaga);
    yield takeLatest(FETCH_BOUGHT_DETAILS_TYPES, fetchBoughtDetailsEffectSaga);
    yield takeLatest(FETCH_BACKNECK_DETAILS_REQUEST, fetchBackneckEffectSaga);
    yield takeLatest(POST_BACKNECK_DATA_REQUEST, postBackneckEffectSaga);
    yield takeLatest(UPDATE_BACKNECK_DETAILS_REQUEST, updateBackneckEffectSaga);
    yield takeLatest(GET_SINGLE_TRIM_INFO_REQUEST, getSingleTrimInfoEffectSaga);
    yield takeLatest(GET_BOUGHT_DETAILS_REQUEST, getSingleBoughtInfoEffectSaga);
};

export { otherWatchers as default, otherWatchers };
