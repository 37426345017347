import React, { createContext, useContext } from 'react';

const initialState = {};

const ResizingContext = createContext(initialState);

const ResizingContextProvider = ({ children, ...props }) => {
    return (
        <ResizingContext.Provider value={props}>
            {children}
        </ResizingContext.Provider>
    );
};

const useResizingContext = () => {
    const context = useContext(ResizingContext);
    if (context === undefined) {
        throw new Error(
            'useResizingContext must be used within a ResizingContextProvider'
        );
    }
    return context;
};

export { useResizingContext as default, ResizingContextProvider };
