import {
	FETCH_TRIMS_INFO,
	GET_SINGLE_TRIM_INFO_REQUEST,
	DELETE_TRIM_ITEM_SUCCESS,
	DELETE_ATTACHMENT_SUCCESS,
	FETCH_BOUGHT_DETAILS_TYPES,
	GET_BOUGHT_DETAILS_REQUEST,
	DELETE_BOUGHT_ITEM_SUCCESS,
	UPLOAD_ATTACHMENTS_SUCCESS
} from 'store/actionTypes';

export function uploadAttachments(payload) {
	return { type: UPLOAD_ATTACHMENTS_SUCCESS, payload };
}

export function deleteAttachment(payload) {
	return { type: DELETE_ATTACHMENT_SUCCESS, payload };
}

// trims
export function fetchTrimsInfo(payload) {
	return { type: FETCH_TRIMS_INFO, payload };
}

export function postSingleTrimInfo() {
	return { type: GET_SINGLE_TRIM_INFO_REQUEST };
}

export function deleteSingleTrimInfo(payload) {
	return { type: DELETE_TRIM_ITEM_SUCCESS, payload };
}

// bought details
export function fetchBoughtDetails(payload) {
	return { type: FETCH_BOUGHT_DETAILS_TYPES, payload };
}

export function postSingleBoughtValue() {
	return { type: GET_BOUGHT_DETAILS_REQUEST };
}

export function deleteSingleBoughtValue(payload) {
	return { type: DELETE_BOUGHT_ITEM_SUCCESS, payload };
}
