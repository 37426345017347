// Entities
import {
    CURRENCIES,
    TRIM_SUPPLIERS,
    TRIM_TYPES,
    TRIM_DETAILS,
} from 'libs/datasets';

// Selectors
import {
    selectCurrenciesData,
    selectTrimSuppliersData,
    selectTrimTypesData,
    selectUserId,
} from 'selectors';

// Normalizers
import {
    normalizeCurrencies,
    normalizeTrimSuppliers,
    normalizeTrimTypes,
} from 'normalizers';
import { composePaginatedEntity, composeUrl } from '../../../common';
import { formValueSelector } from 'redux-form';

// References
export default [
    {
        entity: CURRENCIES,
        url: '/api/CurrencySymbols',
        selector: selectCurrenciesData,
        normalizer: normalizeCurrencies, // check existing
    },

    {
        entity: TRIM_SUPPLIERS,
        url: '/api/TrimSuppliers',
        selector: selectTrimSuppliersData,
        normalizer: normalizeTrimSuppliers,
    },

    {
        entity: TRIM_TYPES,
        url: '/api/TrimTypes',
        selector: selectTrimTypesData,
        normalizer: normalizeTrimTypes,
    },

    {
        entity: TRIM_DETAILS,
        url: (state, queryBuilder) =>
            composeUrl(
                'api/TrimCards/GetTrimsReferenceForSample',
                queryBuilder(state)
            ),
        ...composePaginatedEntity(
            TRIM_DETAILS,
            { sampleReference: 'Reference', specDescription: 'Description' },
            (state) => ({
                userId: selectUserId(state),
                retailerId: formValueSelector('SamplingPrintForm')(
                    state,
                    'retailerId'
                ),
            })
        ),
    },
];
