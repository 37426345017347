import makeContext from '@libs/makeContext';

const initialState = {
    canPublish: false,
    reset: () => {},
    handlePublish: () => {}
};

const FinalPhotoUploadReducer = state => state;

const [
    FinalPhotoUploadProvider,
    useFinalPhotoUploadState,
    useFinalPhotoUploadDispatch,
    useFinalPhotoUpload
] = makeContext(FinalPhotoUploadReducer, initialState, {
    name: 'FinalPhotoUpload'
});

export {
    useFinalPhotoUpload as default,
    FinalPhotoUploadProvider,
    useFinalPhotoUploadState,
    useFinalPhotoUploadDispatch
};
