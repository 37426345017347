import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
    change,
    destroy,
    formValueSelector,
    initialize,
    startSubmit,
    stopSubmit
} from 'redux-form';
import _ from 'lodash';

import { isRedirectedSelector } from 'navigation/redux.selectors';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import {
    DESTROY_SHAPE_FORM,
    GET_TRIM_DETAIL_DATA,
    ROUTE_TO_SHAPE,
    SET_SHAPE_RETAILER_CHILDREN,
    SHAPE_PUBLISH,
    GET_COPY_SHAPE_DATA,
    GET_INCOMPLETE_SHAPE_DATA,
    GET_AMEND_SHAPE_DATA,
    SHAPE_REFERENCE_GENERATE
} from '../../redux.actionTypes';

import { FORM_NAME } from '../../Shape/common';
import shapePublishWorker from './shapePublishWorker';
import { destroyShapeForm, setTrimDetailsLoading } from '../../redux.actions';
import apiRequest from '@libs/apiRequest';
import { normalizeTrimDetails } from '../../redux.normalizers';
import isBlobUrl from '@libs/isBlobUrl';
import {
    INITIAL_VALUES,
    NEW_SHAPE_ROUTE_ID,
    INCOMPLETE_SHAPE_ROUTE_ID,
    AMEND_SHAPE_ROUTE_ID
} from '../../Shape/common';
import getReferenceDataWorker from './getReferenceDataWorker';
import { setLoading } from 'utilities/redux.actions';
import generateShapeReferenceWorker from './generateShapeReferenceWorker';

// Workers
export function* routeToShapeWorker({ payload: { mode } }) {
    const routeID =
        mode === 'new'
            ? NEW_SHAPE_ROUTE_ID
            : mode === 'amend'
            ? AMEND_SHAPE_ROUTE_ID
            : INCOMPLETE_SHAPE_ROUTE_ID;

    yield put(destroyShapeForm()); // Not redundant -- takes care of object URLS

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    } else {
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(redirectToRoute(routeID));
}

export function* destroyShapeFormWorker() {
    const isRedirected = yield select(isRedirectedSelector);

    if (!isRedirected) {
        // URL blob clean up
        const { attachments, front, back } = yield select(
            formValueSelector(FORM_NAME),
            'attachments',
            'front',
            'back'
        );

        if (attachments)
            attachments.forEach(
                attachment =>
                    attachment &&
                    isBlobUrl(attachment.url) &&
                    URL.revokeObjectURL(attachment.url)
            );
        if (front && isBlobUrl(front.url)) URL.revokeObjectURL(front.url);
        if (back && isBlobUrl(back.url)) URL.revokeObjectURL(back.url);

        yield put(destroy(FORM_NAME));
    }

    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    yield put(setRedirect(false));
}

function* setShapeRetailerChildrenWorker() {
    yield put(change(FORM_NAME, 'department', ''));
    yield put(change(FORM_NAME, 'country', ''));
    yield put(change(FORM_NAME, 'season', ''));
    yield put(change(FORM_NAME, 'category', ''));
}

function* getTrimDetailDataWorker({ payload: trimReference }) {
    if (trimReference) {
        yield put(startSubmit(trimReference));
        yield put(setTrimDetailsLoading(true));

        const trimDetails = yield select(
            formValueSelector(FORM_NAME),
            'trimDetails'
        );

        // API call goes here
        const {
            data: { data }
        } = yield call(apiRequest, {
            url: 'Trims/GetTrimByRef',
            params: { trimCardRef: trimReference }
        });

        const newTrimDetails = normalizeTrimDetails(data, true);

        yield put(
            change(FORM_NAME, 'trimDetails', [
                ...trimDetails,
                ...newTrimDetails
            ])
        );

        yield put(stopSubmit(FORM_NAME));
        yield put(setTrimDetailsLoading(false));
    }
}
function* getCopyShapeDataWorker({ payload: sampleReference }) {
    const hasFabricOption = yield select(
        formValueSelector(FORM_NAME),
        'hasFabricOption'
    );

    if (!hasFabricOption) {
        yield put(change(FORM_NAME, 'hasManualReference', false));
    }

    if (sampleReference) {
        yield put(setLoading(true));

        // Perform the API call
        yield call(
            getReferenceDataWorker,
            sampleReference,
            true,
            hasFabricOption
        );

        if (hasFabricOption) {
            const manualReferenceValue = `${sampleReference}-`;
            yield put(
                change(FORM_NAME, 'manualReference', manualReferenceValue)
            );
        }

        yield put(setLoading(false));
    }
}
function* getIncompleteShapeDataWorker({ payload: sampleReference }) {
    if (sampleReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, sampleReference);

        yield put(setLoading(false));
    }
}
function* getAmendShapeDataWorker({ payload: sampleReference }) {
    if (sampleReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, sampleReference);

        yield put(setLoading(false));
    }
}

// Watchers
export default function*() {
    yield takeLatest(ROUTE_TO_SHAPE, routeToShapeWorker);
    yield takeLatest(DESTROY_SHAPE_FORM, destroyShapeFormWorker);
    yield takeLatest(
        SET_SHAPE_RETAILER_CHILDREN,
        setShapeRetailerChildrenWorker
    );
    yield takeLatest(GET_TRIM_DETAIL_DATA, getTrimDetailDataWorker);
    yield takeLatest(SHAPE_PUBLISH, shapePublishWorker);
    yield takeLatest(GET_COPY_SHAPE_DATA, getCopyShapeDataWorker);
    yield takeLatest(GET_INCOMPLETE_SHAPE_DATA, getIncompleteShapeDataWorker);
    yield takeLatest(GET_AMEND_SHAPE_DATA, getAmendShapeDataWorker);
    yield takeLatest(SHAPE_REFERENCE_GENERATE, generateShapeReferenceWorker);
}
