import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { object, number } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';

const styles = ({ spacing, palette }) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: palette.grey[200]
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,

        borderTop: 'none'
    },
    tableDataWrapper: {
        flex: 1,

        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1
    },
    tableData: {
        textAlign: 'center',
        fontSize: spacing.unit * 1,
        padding: spacing.unit * 0.25
    },
    titleWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        borderLeftStyle: 'solid',
        borderLeftColor: palette.background.dark,
        borderLeftWidth: 1,

        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 1
    },
    title: {
        fontSize: spacing.unit * 1.5,
        padding: spacing.unit * 0.25
    }
});

const propTypes = {
    grandTotal: object.isRequired,
    poColumnSize: number.isRequired
};

const GrandTotal = withPDFStyles(styles)(
    ({ classes: c, grandTotal: { values }, poColumnSize }) => {
        const totalLength = values.length;
        const sizesContainerWidth = totalLength * poColumnSize;
        const columnSize = `${sizesContainerWidth / totalLength}%`;
        return (
            <View style={c.container} wrap={false}>
                <View
                    style={{
                        ...c.titleWrapper,
                        width: `${100 - sizesContainerWidth}%`
                    }}
                >
                    <Text style={c.title}>Grand Total</Text>
                </View>

                <View
                    style={{ ...c.wrapper, width: `${sizesContainerWidth}%` }}
                >
                    {values.map((value, idx) => (
                        <View
                            key={`grand-total-value-${idx}`}
                            style={{
                                ...c.tableDataWrapper,
                                width: columnSize,
                                ...(totalLength - 1 === idx
                                    ? { borderRight: 'none' }
                                    : {})
                            }}
                        >
                            <Text style={c.tableData}>{value}</Text>
                        </View>
                    ))}
                </View>
            </View>
        );
    }
);

GrandTotal.propTypes = propTypes;

export default GrandTotal;
