import { DOMAIN_NAME } from './common';

/* Fabric setup form action types */

export const ADD_COMPOSITION = `${DOMAIN_NAME}@add-composition`;
export const ADD_GSM = `${DOMAIN_NAME}@add-gsm`;
export const ADD_DYE_TYPES = `${DOMAIN_NAME}@add-dye-types`;
export const ADD_FABRIC_FORS = `${DOMAIN_NAME}@add-fabric-fors`;
export const ADD_FABRIC_TYPES = `${DOMAIN_NAME}@add-fabric-types`;
export const ADD_FINISH_WASH = `${DOMAIN_NAME}@add-finish-wash`;
export const ADD_FABRIC_GROUP = `${DOMAIN_NAME}@add-fabric-group`;
export const ADD_SUSTAINABILITY = `${DOMAIN_NAME}@add-sustainability`;

/* Sample setup form action types */
export const ADD_ARMHOLE_CUFF = `${DOMAIN_NAME}@add-armhole-cuff`;
export const ADD_HEMLINE = `${DOMAIN_NAME}@add-hemline`;
export const ADD_NECKLINE = `${DOMAIN_NAME}@add-neckline`;
export const ADD_FIT_TYPE = `${DOMAIN_NAME}@add-fit-type`;
export const ADD_REQUEST_TYPE = `${DOMAIN_NAME}@add-request-type`;
export const ADD_SIZE_RANGE = `${DOMAIN_NAME}@add-size-range`;
export const ADD_LABEL_TYPE = `${DOMAIN_NAME}@add-label-type`;
export const ADD_SAMPLE_EMAIL_GROUP = `${DOMAIN_NAME}@add-sample-email-group`;
export const ADD_SAMPLE_EMAIL_BODY = `${DOMAIN_NAME}@add-sample-email-body`;
export const ADD_SAMPLE_FACTORY = `${DOMAIN_NAME}@add-sample-factory`;
export const ADD_SAMPLE_STATUS = `${DOMAIN_NAME}@add-sample-status`;
export const ADD_SAMPLE_ACTION = `${DOMAIN_NAME}@add-sample-action`;
export const ADD_SAMPLE_SIZE = `${DOMAIN_NAME}@add-sample-size`;

/* Dispatch setup form action types */
export const ADD_CURRIER = `${DOMAIN_NAME}@add-currier`;
export const ADD_DISPATCH_TYPE = `${DOMAIN_NAME}@add-dispatch-type`;
export const ADD_DISPATCH_SIZE = `${DOMAIN_NAME}@add-dispatch-size`;
export const ADD_DISPATCH_EMAIL_GROUP = `${DOMAIN_NAME}@add-dispatch-email-group`;
export const ADD_DISPATCH_EMAIL_BODY = `${DOMAIN_NAME}@add-dispatch-email-body`;

/* Cads setup form action types */
export const ADD_LICENSOR = `${DOMAIN_NAME}@add-licensor`;
export const ADD_LICENSOR_PROPERTY = `${DOMAIN_NAME}@add-licensor-property`;
export const ADD_CAD_TYPE = `${DOMAIN_NAME}@add-cad-type`;
export const ADD_ARTWORK_SOURCE = `${DOMAIN_NAME}@add-artwork-source`;

/* Common setup form action types */
export const ADD_RETAILER = `${DOMAIN_NAME}@add-retailer`;
export const ADD_DEPARTMENT = `${DOMAIN_NAME}@add-department`;
export const ADD_STYLE = `${DOMAIN_NAME}@add-style`;
export const ADD_GENDER = `${DOMAIN_NAME}@add-gender`;
export const ADD_CATEGORY = `${DOMAIN_NAME}@add-category`;
export const ADD_COUNTRY = `${DOMAIN_NAME}@add-country`;
export const ADD_AGE = `${DOMAIN_NAME}@add-age`;
export const ADD_DESIGN_DEPARTMENT = `${DOMAIN_NAME}@add-design-department`;

/* Order setup form action types */
export const ADD_PO_SIZE_SET = `${DOMAIN_NAME}@add-po-size-set`;
export const ADD_ORDER_EMAIL_GROUP = `${DOMAIN_NAME}@order-email-group`;
export const ADD_ORDER_EMAIL_BODY = `${DOMAIN_NAME}@order-email-body`;
export const ADD_ORDER_FACTORY = `${DOMAIN_NAME}@order-factory`;
export const ADD_ORDER_RETAILER_FACTORY = `${DOMAIN_NAME}@order-retailer-factory`;
export const ADD_TRIM_LABEL_TYPE = `${DOMAIN_NAME}@add-trim-label-type`;
export const ADD_TRIM_LABEL = `${DOMAIN_NAME}@add-trim-label`;
export const ADD_PACKING_PACK_TYPE = `${DOMAIN_NAME}@add-packing-pack-type`;
export const ADD_SEASON = `${DOMAIN_NAME}@add-season`;
export const ADD_COLOUR = `${DOMAIN_NAME}@add-colour`;
export const ADD_PO_SHIP_MODE = `${DOMAIN_NAME}@add-po-ship-mode`;
export const ADD_PO_SHIP_DESTINATION = `${DOMAIN_NAME}@add-po-ship-destination`;
export const ADD_PO_TYPE = `${DOMAIN_NAME}@add-po-type`;
export const ADD_PACKING_BOX_TYPE = `${DOMAIN_NAME}@add-box-type`;
export const ADD_ORDER_NOTE = `${DOMAIN_NAME}@add-order-note`;
export const ADD_ORDER_STATUS = `${DOMAIN_NAME}@add-order-status`;
