import React from 'react';
import { array } from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import Row from './Row';

const propTypes = {
    cadStatistics: array.isRequired,
    quantityReport: array.isRequired,
    shippedQuantityReport: array.isRequired,
    wipQuantityReport: array.isRequired
};

const styles = ({ palette, spacing, typography }) => ({
    container: {
        marginTop: spacing.unit * 0.5,
        width: '100%',
        flexDirection: 'column'
    },
    titleContainer: {
        width: '100%',
        flexDirection: 'row',

        backgroundColor: palette.grey[300]
    },
    titleText: {
        fontSize: 10,
        fontWeight: typography.fontWeightHeavy,
        textTransform: 'uppercase',
        padding: spacing.unit * 0.5
    },
    title: {
        width: '80%'
    },
    quantity: {
        width: '20%'
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,
        height: 'auto'
    }
});

const Description = withPDFStyles(styles)(
    ({
        classes: c,
        cadStatistics,
        quantityReport,
        shippedQuantityReport,
        wipQuantityReport
    }) => {
        return (
            <View style={c.container}>
                <View style={c.titleContainer}>
                    <View style={c.title}>
                        <Text style={c.titleText}>Description</Text>
                    </View>
                    <View style={c.quantity}>
                        <Text style={c.titleText}>quantity</Text>
                    </View>
                </View>
                <View style={{ ...c.titleContainer, borderWidth: 0 }}>
                    <Text style={c.titleText}>Sampling and Cads</Text>
                </View>
                <View style={c.table}>
                    {cadStatistics.map(({ title, value }, index) => (
                        <Row
                            key={`key-cadStatistics-table-${index}`}
                            label={title}
                            value={value}
                            isLast={index === cadStatistics.length - 1}
                        />
                    ))}
                </View>
                <View style={{ ...c.titleContainer, borderWidth: 0 }}>
                    <Text style={c.titleText}>Quantity Report</Text>
                </View>
                <View style={c.table}>
                    {quantityReport.map(({ title, value }, index) => (
                        <Row
                            key={`key-quantityReport-table-${index}`}
                            label={title}
                            value={value}
                            isLast={index === quantityReport.length - 1}
                            threeColumn={index % 3 > 0}
                        />
                    ))}
                </View>
                <View style={{ ...c.titleContainer, borderWidth: 0 }}>
                    <Text style={c.titleText}>Finance Report</Text>
                </View>
                <View style={{ ...c.titleContainer, borderWidth: 0 }}>
                    <Text style={c.titleText}>Shipped Quantity Report</Text>
                </View>
                <View style={c.table}>
                    {shippedQuantityReport.map(({ title, value }, index) => (
                        <Row
                            key={`key-shippedQuantityReport-table-${index}`}
                            label={title}
                            value={value}
                            isLast={index === shippedQuantityReport.length - 1}
                            threeColumn={index % 4 > 0}
                        />
                    ))}
                </View>
                <View style={{ ...c.titleContainer, borderWidth: 0 }}>
                    <Text style={c.titleText}>
                        Work In Progress Quantity Report
                    </Text>
                </View>
                <View style={c.table}>
                    {wipQuantityReport.map(({ title, value }, index) => (
                        <Row
                            key={`key-wipQuantityReport-table-${index}`}
                            label={title}
                            value={value}
                            isLast={index === wipQuantityReport.length - 1}
                            threeColumn={index % 4 > 0}
                        />
                    ))}
                </View>
            </View>
        );
    }
);

Description.propTypes = propTypes;

export default Description;
