import React from 'react';
import { string, bool } from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

const propTypes = {
    label: string.isRequired,
    value: string.isRequired,
    isLast: bool.isRequired
};

const styles = ({ spacing, palette, typography }) => ({
    tableRow: {
        flexDirection: 'row',
        width: '100%',
        height: 'auto',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderBottomWidth: 1
    },
    tableHeader: {
        width: '20%',
        flexDirection: 'row',
        padding: spacing.unit * 0.5,
        alignItems: 'center'
    },
    tableHeaderCell: {
        fontWeight: typography.fontWeightHeavy,
        fontSize: 9
    },
    tableCellText: { fontSize: 9 },
    tableCell: {
        width: '80%',
        minHeight: 0,
        paddingLeft: spacing.unit * 1.75,
        paddingRight: spacing.unit * 1.75,
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderLeftWidth: 1,
        lineHeight: 1.2
    }
});

const Row = withPDFStyles(styles)(({ classes: c, label, value, isLast }) => {
    return (
        <View
            style={{
                ...c.tableRow,
                ...(isLast ? { borderBottomWidth: 0 } : {})
            }}
        >
            <View style={c.tableHeader}>
                <Text style={c.tableHeaderCell}>{label}</Text>
            </View>
            <View style={c.tableCell}>
                <Text style={c.tableCellText}>{value}</Text>
            </View>
        </View>
    );
});

Row.propTypes = propTypes;

export default Row;
