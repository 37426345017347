import { RESET_AMENDS, SET_AMENDS, REMOVE_AMENDS } from 'actionTypes';
import initialState from '../initialState';

const amendsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case RESET_AMENDS: {
            return { ...state, amends: initialState.amends };
        }

        case SET_AMENDS: {
            const { amends } = payload;
            return { ...state, amends: [...state.amends, ...amends] };
        }

        case REMOVE_AMENDS: {
            const { removedAmends } = payload;
            const amends = state.amends.filter(
                amendedField => !removedAmends.includes(amendedField)
            );
            return { ...state, amends };
        }

        default: {
            return state;
        }
    }
};

export { amendsReducer as default, amendsReducer };
