import {
    RESET_LOADING,
    SET_LOADING,
    SET_LOADING_PROGRESS,
    RESET_SNACK,
    SET_SNACK,
    SET_RELOAD_ACTION,
    RESET_RELOAD_ACTIONS
} from './redux.actionTypes';

export const resetLoading = () => ({
    type: RESET_LOADING
});

/*
    @loading    bool
 */
export const setLoading = loading => ({
    type: SET_LOADING,
    payload: loading
});

/*
    @loading    number      [1-100]
 */
export const setLoadingProgress = loading => ({
    type: SET_LOADING_PROGRESS,
    payload: loading
});

export const resetSnack = () => ({
    type: RESET_SNACK
});

export const setSnack = ({
    message,
    type = 'info',
    duration = 8000,
    action: { label, handle } = {}
}) => ({
    type: SET_SNACK,
    payload: { message, type, duration, action: { label, handle } }
});

export const setReloadAction = ({ field, shouldPrompt, action }) => ({
    type: SET_RELOAD_ACTION,
    payload: { field, shouldPrompt, fieldAction: action }
});

export const resetReloadActions = () => ({
    type: RESET_RELOAD_ACTIONS
});
