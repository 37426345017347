import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import GridSelect from '../../../../../GridSelect';

const propTypes = {
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    handleGridDataUpdate: PropTypes.func.isRequired
};

const defaultProps = {
    data: [],
    loading: false
};

const styles = ({ spacing }) => ({
    gridSelectCellEditor: {
        backgroundColor: '#fff',
        height: spacing.unit * 4.5
    }
});

const SelectCell = ({
    classes,
    column,
    row,
    handleGridDataUpdate,
    ...restProps
}) => {
    const currentValue = row[column.name] || '';

    const handleChange = useCallback(
        value => {
            const newRow = { ...row, [column.name]: value };
            handleGridDataUpdate(newRow);
        },
        [row, column.name, handleGridDataUpdate]
    );

    const selectCellProps = useMemo(
        () => ({
            styles: {
                control: classes.gridSelectCellEditor
            },
            handleOnChange: handleChange,
            value: currentValue,
            name: column.name,
            ...restProps
        }),
        [handleChange, currentValue, column.name, restProps]
    );

    return <GridSelect {...selectCellProps} />;
};

SelectCell.propTypes = propTypes;
SelectCell.defaultProps = defaultProps;

export default withStyles(styles)(React.memo(SelectCell));
