import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import styles from './styles';
import {
    GridContainer,
    GridItem,
    TextInput,
    ModalHeader,
    ModalContent
} from 'components';

import { createNewCollection } from 'store/actions';

function mapStateToProps(state) {
    return {
        store: state
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            createNewCollection
        },
        dispatch
    );
};

class AddCollectionModal extends PureComponent {
    state = {
        collectionText: ''
    };

    handletext = (e, type) => {
        this.setState({ [type]: e.target.value });
    };

    render() {
        const { state, props } = this;
        const { classes, onClose, createNewCollection } = props;
        const { collectionText } = state;

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>New Collection :</ModalHeader>
                <ModalContent>
                    <GridContainer
                        justify='center'
                        spacing={0}
                        className={classes.gridContainer}
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                labelText='Collection name: '
                                placeholderText='New collection'
                                value={collectionText}
                                handleText={e =>
                                    this.handletext(e, 'collectionText')
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Button
                                variant='contained'
                                disabled={!!!collectionText.length}
                                onClick={() =>
                                    createNewCollection({
                                        name: collectionText
                                    })
                                }
                                classes={{
                                    root: classNames(
                                        classes.saveButton,
                                        classes.saveCollectionBtn
                                    ),
                                    disabled: classes.saveButtonDisabled
                                }}
                            >
                                SAVE
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Button
                                variant='contained'
                                disabled={false}
                                onClick={() => onClose()}
                                classes={{
                                    root: classes.cancelButton
                                }}
                            >
                                CANCEL
                            </Button>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
            </Fragment>
        );
    }
}

AddCollectionModal.propTypes = {
    classes: PropTypes.object
};

const _AddCollectionModal = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(AddCollectionModal);

export {
    _AddCollectionModal as default,
    _AddCollectionModal as AddCollectionModal
};
