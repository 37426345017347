import React, { useCallback } from 'react';
import { compose } from 'redux';

import { SettingsOutlined } from '@material-ui/icons';

import IconLabelButton from '@libComponents/IconLabelButton';
import { withModalProvider, withModal } from '@libComponents/Modal';

import ChartsVisibilityModal from './ChartsVisibilityModal';

const ManageChartsVisibility = compose(
    withModalProvider,
    withModal({
        handleModal: ChartsVisibilityModal
    })
)(({ className, handleModal, retailer, chartName, chartCellName }) => {
    const handleManageChartsClick = useCallback(
        () => handleModal({ retailer, chartName, chartCellName }),
        [handleModal]
    );
    return (
        <IconLabelButton
            label='Manage charts'
            IconComponent={SettingsOutlined}
            className={className}
            onClick={handleManageChartsClick}
        />
    );
});

export default ManageChartsVisibility;
