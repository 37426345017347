import { arrowGenerator } from 'helpers';
import GreyDot from 'components/Global/assets/img/grey-dot.svg';

export default theme => ({
    sidebyside: {
        display: 'flex'
    },
    leftSide: {
        flexGrow: 1
    },
    rightSide: {
        width: 47,
        height: 47
    },
    plusButton: {
        marginLeft: 10,
        height: 45,
        padding: 0,
        minWidth: 45,
        border: '1px solid #e6e6e6'
    },
    textField: {
        marginBottom: theme.spacing.unit * 2.5
    },
    loader: {
        color: '#ffffff',
        position: 'absolute'
    },
    inputTextField: {
        height: '32px',
        fontSize: '.875rem',
        padding: '6px 15px 7px 20px',
        border: '1px solid  #e6e6e6',
        borderRadius: 4
    },
    inputLabelRoot: {
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    },
    inputLabelFocused: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    },
    inputLabelShrank: {
        fontSize: '.875rem',
        transform: 'translate(0, 0px) scale(1.0)',
        lineHeight: '20px'
    },
    labelFileField: {
        '& + div': {
            display: 'none'
        }
    },
    browseButtonRoot: {
        border: '1px solid #e6e6e6',
        borderRadius: 4,
        height: 45,
        cursor: 'pointer',
        marginTop: -10,
        display: 'flex'
    },
    fileListLabel: {
        color: '#aeaeae',
        width: 'calc(100% - 95px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        height: 45,
        lineHeight: '45px',
        paddingLeft: 15
    },
    browseButton: {
        width: '80px',
        textTransform: 'capitalize',
        marginLeft: 15,
        borderLeft: '1px solid #e6e6e6',
        borderRadius: 0,
        height: 43,
        background:
            'linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, .07))'
    },
    inputHasSuccess: {
        borderColor: '#8fc255'
    },
    inputHasError: {
        borderColor: '#eb5151'
    },
    expandIconStyles: {
        left: '8px',
        right: 'auto'
    },
    checkIcon: {
        margin: '7px 15px 0'
    },
    addBackneckButton: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    },
    cardTable: {
        backgroundColor: '#fcfcfc',
        border: '1px solid #e4e4e4',
        '& tbody': {
            '& tr': {
                height: 28,
                '& th': {
                    backgroundImage: `url(${GreyDot})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '10px center',
                    backgroundSize: 10,
                    paddingRight: 0,
                    paddingLeft: 28,
                    maxHeight: 20
                },
                '& td': {
                    paddingRight: '13px !important'
                }
            }
        },
        '& thead': {
            '& tr': {
                height: 40,
                backgroundColor: '#f4f4f4',
                '& th': {
                    color: 'black',
                    paddingRight: 0,
                    paddingLeft: 13,
                    '&:last-child': {
                        paddingRight: '13px !important'
                    }
                }
            }
        }
    },
    cardDetailsTable: {
        '& tbody': {
            '& tr.image-box': {
                '& th': {
                    backgroundImage: 'none !important',
                    borderBottom: 'none'
                },
                '& td': {
                    borderBottom: 'none'
                }
            }
        }
    },
    imageRow: {
        '& > th': {
            background: 'transparent !important',
            paddingTop: 15,
            borderBottom: 0,
            '& > img': {
                marginRight: 15
            },
            '& > p': {
                display: 'inline-block',
                verticalAlign: 'top'
            }
        },
        '& > td': {
            borderBottom: 0
        }
    },
    centerAlign: {
        display: 'block',
        margin: '0 auto'
    },
    tooltipTriggerIcon: {
        marginLeft: 10,
        cursor: 'pointer',
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    arrowPopper: arrowGenerator('white'),
    arrow: {
        position: 'absolute',
        border: '1px solid rgab(0, 0, 0, .1)',
        fontSize: 6,
        boxShadow: '0px 3px 21px 0px rgba(0, 4, 52, 0.11)',
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    arrowLabel: {
        boxShadow: '0px 0px 7px 0px rgba(0, 2, 53, 0.29)',
        color: 'black',
        borderRadius: 0,
        backgroundColor: 'white',
        padding: '6px 10px',
        fontSize: '.9rem'
    },
    actionsGridContainer: {
        margin: 0
    },
    saveButton: {
        boxShadow: 'none',
        width: '100%',
        height: '45px',
        color: 'white',
        backgroundColor: '#8fc255',
        borderColor: '#8fc255',
        '&:hover': {
            backgroundColor: '#8fc255',
            borderColor: '#8fc255'
        },
        '&.active': {
            backgroundColor: '#8fc255',
            borderColor: '#8fc255',
            opacity: '1'
        }
    },
    saveButtonDisabled: {
        color: '#ffffff !important',
        backgroundColor: '#c1dba4 !important'
    },
    paddingTopNone: {
        paddingTop: '0 !important'
    },
    singleCardBox: {
        paddingRight: '10px'
    },
    trimDialogueActionGrid: {
        marginTop: '-30px'
    },
    disabledTextField: {
        height: '45px',
        fontSize: '.875rem',
        padding: '6px 0 7px 20px;',
        border: '1px solid  #e6e6e6',
        borderRadius: 4,
        marginTop: '10px !important',
        backgroundColor: '#f5f5f5'
    },
    blackButton: {
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000'
        }
    },
    trimExportBtn: {
        fontWeight: 'normal'
    },
    cardViewMore: {
        '& td': {
            cursor: 'pointer',
            color: '#387ff5',
            paddingBottom: '10px',
            '& svg': {
                marginBottom: '-7px',
                fontSize: '22px'
            }
        }
    },
    cardItemMaxRow: {
        '& th': {
            borderBottom: 'none'
        },
        '& td': {
            borderBottom: 'none'
        }
    },
    textArea: {
        border: '1px solid #e6e6e6',
        borderRadius: '4px',
        padding: '6px 15px 7px 20px',
        marginTop: -12,
        minHeight: 129,
        marginBottom: 13,
        fontSize: '.875rem'
    },
    titleLabel: {
        display: 'block',
        fontWeight: 'bold',
        lineHeight: '22px',
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    titleLabelBottomMargin: {
        marginBottom: '7px'
    },
    textAreaLessHeight: {
        maxHeight: '50px',
        minHeight: '50px'
    },
    trimCostCurrencyMarginTop: {
        marginTop: '8px'
    },
    cardInfoColor: {
        color: 'gray'
    },
    nextButton: {
        boxShadow: 'none',
        height: '45px',
        color: 'white',
        margin: '0px auto',
        display: 'block',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        },
        '&:active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5'
        }
    },
    disabledNextButton: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important'
    }
});

