import React, { useMemo } from 'react';

import { ORDER_USER_RETAILER_WISE_FACTORIES } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const OrderFactory = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Factory Name', canSort: true, sortKey: 'factory' },
            { label: 'Retailer Name', canSort: true, sortKey: 'retailer' },
            {
                label: 'Supplier No',
                canSort: true,
                sortKey: 'supplierNo'
            },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'ORDER RETAILER FACTORY SETUP',
        domain: DOMAIN_NAME,
        entity: ORDER_USER_RETAILER_WISE_FACTORIES,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { OrderFactory as default, OrderFactory };
