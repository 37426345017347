import { createSelector } from 'reselect';
import { MENU_GROUPS, MENUS } from './redux.datasets';
import routeIcons from './routeIcons';
import routes from './routes';
import createCachedSelector from 're-reselect';
import { id } from 'date-fns/locale';

const navigationSelector = state => state.navigation;
const routeIconsSelector = () => routeIcons;
const routesSelector = () => routes;
const frequentlyUsedModulesSelector = state =>
    state.navigation.frequentlyUsedModules;

const menusSelector = createSelector(
    navigationSelector,
    navigation => navigation[MENUS]
);

const menuGroupsSelector = createSelector(
    navigationSelector,
    navigation => navigation[MENU_GROUPS]
);

const nestedRoutesSelector = createSelector(
    menusSelector,
    routesSelector,
    (menus, routes) => {
        const deserializeMenus = (parent, parentRoute = null) =>
            menus
                .filter(({ id, parentId }) =>
                    parent
                        ? id !== parent && parentId === parent
                        : id === parentId
                )
                .sort((a, b) => a.serial - b.serial)
                .map(({ id, groupId, parentId, serial, name, ...menu }) => {
                    const rawRoute =
                        id !== 1
                            ? name
                                  .split(' ')
                                  .map(word => word.toLowerCase())
                                  .join('-')
                            : '';

                    const route = parentRoute
                        ? `${parentRoute}/${rawRoute}`
                        : `/${rawRoute}`;

                    const normalizedMenu = parentRoute
                        ? { ...menu }
                        : { ...menu, groupId };

                    return {
                        ...normalizedMenu,
                        id,
                        name,
                        route,
                        Component: routes[id],
                        children: deserializeMenus(id, route)
                    };
                });

        return deserializeMenus();
    }
);

const iconedRoutesSelector = createSelector(
    nestedRoutesSelector,
    routeIconsSelector,
    (routes, routeIcons) =>
        routes.map(route => ({
            ...route,
            ...routeIcons[route.id]
        }))
);

const navigationRoutesSelector = createSelector(
    iconedRoutesSelector,
    menuGroupsSelector,
    (routes, menuGroups) => {
        const groups = menuGroups.reduce(
            (acm, { id, ...menuGroup }) => {
                return { ...acm, [id]: { ...menuGroup, routes: [] } };
            },
            { null: { groupName: null, serial: 0, routes: [] } }
        );

        routes.forEach(({ groupId, parentId, serial, ...route }) => {
            if (groups.hasOwnProperty(groupId))
                groups[groupId].routes.push(route);
            else groups['null'].routes.push(route);
        });

        /*  --- For debugging ---
            Don't delete, only comment out when not using
         / // <- Just get rid of the * to comment out
        console.log(
            'routes',
            Object.values(groups)
                .sort((a, b) => a.serial - b.serial)
                .map(({ serial, ...group }) => group)
        );
        // */

        return Object.values(groups)
            .sort((a, b) => a.serial - b.serial)
            .filter(({ routes }) => !!routes.length)
            .map(({ serial, ...group }) => group);
    }
);

const routeSelector = createCachedSelector(
    nestedRoutesSelector,
    (state, routeId) => routeId,
    (nestedRoutes, routeId) => {
        const findRoute = nestedRoutes =>
            nestedRoutes.reduce((acm, { id, route, children }) => {
                if (acm) return acm;
                else if (id === routeId) {
                    return route;
                } else if (!!children.length) {
                    return findRoute(children);
                } else return acm;
            }, '');

        return findRoute(nestedRoutes);
    }
)((state, routeId) => `@@routeSelector-${routeId}`);

const frequentlyUsedRoutesSelector = createSelector(
    nestedRoutesSelector,
    frequentlyUsedModulesSelector,
    (routes, visitedRoutes) => {
        // Sorting the visiting routes and extracting the top 5
        const sortedRoutes = Object.entries(visitedRoutes)
            .sort((a, b) => b[1] - a[1])
            .filter(([key]) => key !== '/')
            .slice(0, 5)
            .map(([key]) => key);

        // Create a result array to store matching routes
        const result = [];

        // Create a map of route.route to the route for easy lookup
        const routeMap = new Map();
        function traverse(routes) {
            for (let route of routes) {
                routeMap.set(route.route, route);
                if (route.children && route.children.length > 0) {
                    traverse(route.children);
                }
            }
        }

        traverse(routes);

        // Now, sort the result array based on sortedRoutes
        sortedRoutes.forEach(routeKey => {
            if (routeMap.has(routeKey)) {
                result.push({
                    name: routeMap.get(routeKey).name,
                    label: routeKey,
                    id: routeMap.get(routeKey).id
                });
            }
        });

        return result;
    }
);

const isRedirectedSelector = createSelector(
    navigationSelector,
    ({ isRedirected }) => isRedirected
);
export {
    isRedirectedSelector,
    navigationRoutesSelector,
    routeSelector,
    frequentlyUsedRoutesSelector
};
