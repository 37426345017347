export default ({ spacing, palette, shape }) => ({
    root: {
        position: 'relative',
        marginTop: spacing.unit * 4,
        marginBottom: spacing.unit * 4,
        borderTop: '1px solid',
        borderColor: palette.grey[500]
    },
    input: {
        width: '100%',
        marginTop: spacing.unit / 2
    },
    select: {
        height: spacing.unit * 5,
        width: spacing.unit * 40,
        borderRadius: shape.borderRadius / 2,
        position: 'absolute',
        right: 0,
        top: spacing.unit * 0.75
    },

    // For testing purposes
    inputBase: {
        height: spacing.unit * 5,
    },
});