import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
    AccountCircle as AccountsCircleIcon,
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
    List as RightMenuIcon
} from '@material-ui/icons';

import Search from 'views/Containers/PrivatePages/Search';
import { Breadcrumb } from 'views/Containers/PrivatePages/Breadcrumb';

import { AppBar, Badge, IconButton, Menu, MenuItem, Toolbar } from 'components';
import { signOut } from 'actions';
import history from 'helpers/routes/history';

import styles from './styles';

function mapStateToProps(state) {
    return {
        auth: state.auth,
        utils: state.utils
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            signOut: signOut
        },
        dispatch
    );
};

const Header = props => {
    const { classes, handleDrawer, signOut, handleRightDrawer } = props;
    const [profileDropdown, setDropdown] = useState(null);
    const menuItems = [
        { title: 'Profile' },
        { title: 'My account' },
        { title: 'Logout', action: signOut }
    ];

    function handleClick(event) {
        setDropdown(event.currentTarget);
    }

    function handleClose() {
        setDropdown(null);
    }

    const MenuSectionWrapper = ({ children }) => {
        return <div className={classes.flexBox}>{children}</div>;
    };

    const SectionItemWrapper = ({ children }) => {
        return (
            <div className={classes.rightSectionItemWrapper}>{children}</div>
        );
    };

    const UserMenu = () => {
        return (
            <Fragment>
                <IconButton onClick={handleClick}>
                    <AccountsCircleIcon />
                </IconButton>
                <Menu target={profileDropdown} onClose={handleClose}>
                    {menuItems.map((item, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={
                                Object.prototype.hasOwnProperty.call(
                                    item,
                                    'action'
                                )
                                    ? item.action
                                    : handleClose
                            }
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </Menu>
            </Fragment>
        );
    };

    return (
        <AppBar>
            <Toolbar classes={{ root: classes.toolbar }}>
                <MenuSectionWrapper>
                    <IconButton onClick={handleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Search className={classes.searchBox} />
                </MenuSectionWrapper>
                <MenuSectionWrapper>
                    <SectionItemWrapper>
                        <IconButton>
                            <Badge content={4}>
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </SectionItemWrapper>

                    {/*MENU*/}
                    <SectionItemWrapper>
                        <UserMenu />
                    </SectionItemWrapper>

                    <SectionItemWrapper>
                        <IconButton onClick={handleRightDrawer}>
                            <RightMenuIcon />
                        </IconButton>
                    </SectionItemWrapper>
                </MenuSectionWrapper>
            </Toolbar>

            {/*Breadcrumb*/}
            <Breadcrumb path={history ? history.location.pathname : null} />
        </AppBar>
    );
};

Header.propTypes = {
    classes: PropTypes.object,
    handleDrawer: PropTypes.func,
    signOut: PropTypes.func,
    handleRightDrawer: PropTypes.func
};

const _Header = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(Header);

export { _Header as default, _Header as Header };
