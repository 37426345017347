import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'orders/common';
import { ORDER_EMAIL_BODY } from 'orders/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const OrderEmailBody = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Body Name', canSort: true, sortKey: 'emailBodyName' },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            {
                label: 'Body',
                canSort: false
            },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'ORDER EMAIL BODY SETUP',
        domain: DOMAIN_NAME,
        entity: ORDER_EMAIL_BODY,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { OrderEmailBody as default, OrderEmailBody };
