import artworkState from './artwork/initialState';
import backnecksState from './backnecks/initialState';
import cadState from './cad/initialState';
import commonState from './common/initialState';
import fabricsState from './fabrics/initialState';
import inksState from './inks/initialState';
import otherState from './other/initialState';
import referenceState from './reference/initialState';
import requestState from './request/initialState';
import specState from './spec/initialState';
import trimsState from './trims/initialState';

export default {
    ...artworkState,
    ...backnecksState,
    ...cadState,
    ...commonState,
    ...fabricsState,
    ...inksState,
    ...otherState,
    ...referenceState,
    ...requestState,
    ...specState,
    ...trimsState
};
