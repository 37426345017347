import React, { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { withStyles } from '@material-ui/core';

import Tooltip from '@libComponents/Tooltip';
import { isOverflown } from '@libs/domUtils';

const propTypes = {
    label: PropTypes.string.isRequired
};

const styles = ({ spacing }) => ({
    gridHeaderTitle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 600,
        fontSize: spacing.unit * 1.75,
        marginRight: spacing.unit * 0.5
    }
});

const Title = withStyles(styles)(
    memo(({ classes, label, className }) => {
        const [tooltip, setTooltip] = useState(false);
        const titleRef = useRef(null);

        const handleMouseOver = () => {
            if (titleRef.current) {
                const isOver = isOverflown(titleRef.current);
                setTooltip(isOver ? label : '');
            }
        };

        const renderTitle = (
            <div
                className={clsx(classes.gridHeaderTitle, className)}
                onMouseOver={handleMouseOver}
                ref={titleRef}
                aria-label='headertitle'
            >
                {label}
            </div>
        );

        return tooltip ? (
            <Tooltip title={tooltip}>{renderTitle}</Tooltip>
        ) : (
            renderTitle
        );
    })
);

Title.propTypes = propTypes;

export default Title;
