import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography, withStyles, CircularProgress } from '@material-ui/core';

// Local
import useDispatchDetails from '../useDispatchDetails';
import Header from './Header';
import Row from './Row';

const propTypes = {
    handleModal: PropTypes.func,
    loading: PropTypes.bool.isRequired
};

const styles = ({ palette, spacing }) => ({
    wrapper: {
        width: '100%',
        padding: spacing.unit
    },
    grid: {
        '--scrollbar-track-color': '#f1f1f1',
        '--scrollbar-thumb-color': palette.grey[500],
        '--scrollbar-thumb-hover-color': palette.grey[600],
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,
        maxHeight: spacing.unit * 37.5,
        overflowY: 'hidden',
        transition: 'overflow-y 0.3s ease',
        '&:hover': {
            overflowY: 'auto'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,0.3)',
            borderRadius: spacing.unit * 0.75,
            backgroundColor: 'var(--scrollbar-track-color)' // Use CSS variable
        },
        '&::-webkit-scrollbar': {
            width: spacing.unit * 1,
            backgroundColor: 'var(--scrollbar-track-color)' // Use CSS variable
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: spacing.unit * 0.75,
            '-webkit-box-shadow': 'inset 0 0 4px rgba(0,0,0,.3)',
            backgroundColor: 'var(--scrollbar-thumb-color)' // Use CSS variable
        }
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.unit * 10
    },
    emptyContent: {
        color: palette.secondary.light
    }
});

const DispatchGrid = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing }, handleModal, loading }) => {
        const [{ dispatchDetails }, dispatch] = useDispatchDetails();

        const makeHandleDelete = useCallback(
            index => () => {
                dispatch({ type: 'deleteDispatchDetails', payload: index });
            },
            []
        );

        const handleEdit = useCallback(
            index => values => {
                dispatch({
                    type: 'editDispatchDetails',
                    payload: { index, values }
                });
            },
            []
        );

        const makeHandleEdit = useCallback(
            index => () =>
                handleModal({
                    handleSave: handleEdit(index),
                    initialValues: dispatchDetails[index]
                }),
            [handleModal, dispatchDetails]
        );

        if (dispatchDetails.length === 0) {
            return (
                <div className={c.emptyContainer}>
                    <Typography className={c.emptyContent}>
                        No dispatch details added yet!
                    </Typography>
                </div>
            );
        }

        return (
            <div className={c.wrapper}>
                {loading ? (
                    <div className={c.loader}>
                        <CircularProgress size={spacing.unit * 5.5} />
                    </div>
                ) : (
                    <div className={c.grid}>
                        <Header className={c.stickyHeader} />
                        {dispatchDetails.map((detail, idx) => {
                            return (
                                <Row
                                    {...detail}
                                    key={`dispatch-details-card-${detail.colour}-${idx}`}
                                    handleEdit={makeHandleEdit(idx)}
                                    handleDelete={makeHandleDelete(idx)}
                                    isLast={idx === dispatchDetails.length - 1}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
);

DispatchGrid.propTypes = propTypes;

export default DispatchGrid;
