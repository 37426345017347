import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

import useBlobURL from '@libHooks/useBlobURL';
import { DownloadLink } from 'components';
import { deserializeFilename } from '@libs/filenames';

const propTypes = {
    url: PropTypes.string
};

const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: spacing.unit * 2,

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    }
});

const Attachment = withStyles(styles)(({ classes: c, url }) => {
    if (!url) return null;
    const [blobUrl, blobLoading] = useBlobURL(url);
    const filename = deserializeFilename(url);
    return (
        <div className={c.root}>
            <Typography className={c.filename}>{filename}</Typography>
            <DownloadLink url={blobUrl} name={filename} loading={blobLoading} />
        </div>
    );
});

Attachment.propTypes = propTypes;

export default Attachment;
