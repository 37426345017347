export const serializeData = (
    existingData,
    newData,
    formatData,
    forceFormat = undefined
) => {
    const formatKeys = Object.keys(formatData);
    const formatValues = Object.values(formatData);

    const serializedData = formatValues.map((formatValue, index) => {
        const formatKey = formatKeys[index];
        const existingProperty = existingData[formatKey];
        const newProperty = newData[formatValue];
        const serializedProperty = [formatKey];

        let serializeType;

        // If force exists for this key
        if (forceFormat && forceFormat[formatKey] !== undefined) {
            serializeType = forceFormat[formatKey];
        } else {
            if (typeof existingProperty === 'object') {
                const { id, text } = existingProperty;

                if (id === 'Check') {
                    serializeType = 'boolean';
                } else {
                    if (id === text) {
                        serializeType = 'string';
                    } else {
                        serializeType = 'select';
                    }
                }
            } else {
                serializeType = 'direct';
            }
        }

        switch (serializeType) {
            case 'boolean':
                serializedProperty.push({
                    ...existingProperty,
                    text: newProperty
                });
                break;
            case 'string':
                serializedProperty.push({
                    ...existingProperty,
                    id: newProperty,
                    text: newProperty
                });
                break;
            case 'select':
                if (newProperty) {
                    serializedProperty.push({
                        ...existingProperty,
                        id: newProperty.value,
                        text: newProperty.label
                    });
                } else {
                    serializedProperty.push({
                        ...existingProperty,
                        id: null,
                        text: null
                    });
                }
                break;
            default:
                serializedProperty.push(newData[formatValue]);
        }

        return serializedProperty;
    });

    // Makes the 2D array into a map to create an object with key [0] and value [1] pairs
    return Object.fromEntries(new Map(serializedData));
};
