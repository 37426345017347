export const armholeCuffSchema = {
    armholes: 'string',
    id: 0
};

export const hemlineSchema = {
    hemline: 'string',
    id: 0
};

export const necklineSchema = {
    neckline: 'string',
    id: 0
};

export const fitTypeSchema = {
    fitType: 'string',
    id: 0
};

export const requestTypeSchema = {
    requestType: 'string',
    id: 0
};

export const sizeRangeSchema = {
    sizeRange: 'string',
    id: 0
};

export const labelTypeSchema = {
    labelType: 'string',
    id: 0
};

export const sampleEmailGroupSchema = {
    id: 0,
    emailGroupName: 'string',
    emailGroup: 'string',
    retailerId: 0,
    isFinalPhoto: false,
    isHandOver: false,
    isFD: false
};

export const sampleEmailBodySchema = {
    id: 0,
    emailBodyName: 'string',
    emailBody: 'string',
    retailerId: 0,
    isFinalPhoto: true,
    isHandOver: true,
    isFD: true
};

export const sampleFactorySchema = {
    id: 0,
    factoryName: 'string',
    factoryCode: 'string',
    factoryNo: 'string',
    countryId: 0,
    isActive: true
};

export const sampleStatusSchema = {
    id: 0,
    sampleStatus: 'string'
};

export const sampleSizeSchema = {
    sampleSizeId: 0,
    sampleSize: 'string'
};

export const sampleActionSchema = {
    id: 0,
    sampleActionName: 'string'
};

export const currierSchema = {
    id: 0,
    currier: 'string'
};

export const dispatchTypeSchema = {
    id: 0,
    type: 'string',
    isManual: true,
    isOrder: true
};

export const dispatchSizeSchema = {
    id: 0,
    size: 'string'
};

export const dispatchEmailGroupSchema = {
    id: 0,
    emailGroupName: 'string',
    emailGroup: 'string',
    retailerId: 0
};

export const dispatchEmailBodySchema = {
    id: 0,
    emailBodyName: 'string',
    emailBody: 'string',
    retailerId: 0
};
