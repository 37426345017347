import React from 'react';

import { withStyles } from '@material-ui/core';

const styles = ({ transitions }) => ({
    icon: {
        transition: transitions.create('transform', {
            duration: transitions.duration.shortest
        }),
        '&:hover': {
            transform: 'scale(1.2)'
        }
    }
});

const ExpandIcon = withStyles(styles)(({ classes: { icon } }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19'
        height='19'
        viewBox='0 0 19 19'
        className={icon}
    >
        <g id='Group_21' data-name='Group 21' transform='translate(-1752 -346)'>
            <rect
                id='Shape_1462'
                data-name='Shape 1462'
                width='1'
                height='19'
                transform='translate(1761 346)'
                fill='#0e6bba'
            />
            <rect
                id='Shape_1462_copy'
                data-name='Shape 1462 copy'
                width='19'
                height='1'
                transform='translate(1752 355)'
                fill='#0e6bba'
            />
        </g>
    </svg>
));

export { ExpandIcon as default, ExpandIcon };
