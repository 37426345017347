import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles, TableBody} from '@material-ui/core';

// Local
import styles from './styles';
import withDialogContext from '../../PaginatedDialogContext';
import PaginatedTableRow from './PaginatedDialogTableRow';

/*
 * Domain: --
 * Page: Select
 * Component: Cancellable
 * Type: Icon
 * PaginatedDialogTableBody
 */
class PaginatedDialogTableBody extends Component {
    render() {
        const { props } = this;
        const {
            dialogState: { data = [] },
        } = props;

        return (
            <TableBody>
                {data.map((rowData, index) => (
                <PaginatedTableRow
                    key={`paginatedTableRow${index}`}
                    data={rowData}
                    shaded={index % 2 !== 0}
                />
            ))}
            </TableBody>
        );
    }
}

const _PaginatedDialogTableBody = compose(
    withStyles(styles),
    withDialogContext
)(PaginatedDialogTableBody);

export {
    _PaginatedDialogTableBody as default,
    _PaginatedDialogTableBody as PaginatedDialogTableBody,
};
