import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const ShapeReducer = state => state;

const [
    ShapeProvider,
    useShapeState,
    useShapeDispatch,
    useShape
] = makeContext(ShapeReducer, initialState, { name: 'Shape' });

export {
    useShape as default,
    ShapeProvider,
    useShapeState,
    useShapeDispatch
};
