import { useState, useCallback, useEffect, useRef } from 'react';

const useColumnReorder = (
    columns,
    storedDataGridSettings,
    setDataGridSettings
) => {
    const orderedColumnsSettingsKey = `orderedColumns`;
    const [orderedColumns, setOrderedColumns] = useState(() => {
        const storedOrderedColumns =
            storedDataGridSettings[orderedColumnsSettingsKey];
        if (storedOrderedColumns) {
            const columnNames = columns.map(column => column.name);

            // Find the columns that have been added since the stored ordered columns.
            const addedColumns = columnNames.filter(
                column => !storedOrderedColumns.includes(column)
            );

            // Filter the stored ordered columns to keep only those that exist in the current 'columns' array.
            const columnsAfterDelete = storedOrderedColumns.filter(column =>
                columnNames.includes(column)
            );

            // Return a new array as initial state that combines the filtered 'columnsAfterDelete' and 'addedColumns'.
            return [...columnsAfterDelete, ...addedColumns];
        }

        // - Extract the names of columns from the 'columns' array and return them as the initial state for 'orderedColumns'.
        return columns.map(column => column.name);
    });
    const orderedColumnsRef = useRef(orderedColumns);
    const [draggedColumn, setDraggedColumn] = useState('');

    const handleDragStart = useCallback(columnName => {
        setDraggedColumn(columnName);
    }, []);

    const handleOnDrop = useCallback(
        columnName => {
            if (draggedColumn === null || columnName === draggedColumn) {
                return;
            }
            const draggedStartIndex = orderedColumns.findIndex(
                column => column === draggedColumn
            );
            const draggedOverIndex = orderedColumns.findIndex(
                column => column === columnName
            );

            const reorderedColumns = [...orderedColumns];
            const [removed] = reorderedColumns.splice(draggedStartIndex, 1);
            reorderedColumns.splice(draggedOverIndex, 0, removed);
            setOrderedColumns(reorderedColumns);
            orderedColumnsRef.current = reorderedColumns;
            setDraggedColumn(null);
        },
        [draggedColumn, orderedColumns]
    );

    const handleDragEnd = useCallback(() => {
        setDraggedColumn(null);
    }, []);

    const clearColumnReorderSettings = useCallback(() => {
        setOrderedColumns(columns.map(column => column.name));
    }, [columns]);

    useEffect(() => {
        setDataGridSettings({ [orderedColumnsSettingsKey]: orderedColumns });
    }, [orderedColumns, setDataGridSettings]);

    return {
        orderedColumns,
        handleDragStart,
        handleOnDrop,
        handleDragEnd,
        clearColumnReorderSettings,
        draggedColumn
    };
};

export default useColumnReorder;
