import React from 'react';

const GridViewIcon = props => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20.914'
            height='20.914'
            viewBox='0 0 20.914 20.914'
            fill='#b9b9b9'
            {...props}
        >
            <path
                id='iconfinder_293637_grid_icon'
                d='M4,4H9.228V9.228H4Zm7.843,0h5.228V9.228H11.843Zm7.843,0h5.228V9.228H19.685ZM4,11.843H9.228v5.228H4Zm7.843,0h5.228v5.228H11.843Zm7.843,0h5.228v5.228H19.685ZM4,19.685H9.228v5.228H4Zm7.843,0h5.228v5.228H11.843Zm7.843,0h5.228v5.228H19.685Z'
                transform='translate(-4 -4)'
            />
        </svg>
    );
};

export { GridViewIcon as default, GridViewIcon };
