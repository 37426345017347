import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { withStyles, Grid } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Editor } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { AttachmentDetails } from 'components';

import { FORM_NAME, LABELS } from '../../common';
import { BoughtContainer } from '../../../components';
import { getBoughtDetailData, getTrimDetailData } from '../../../redux.actions';
import TrimContainer from './TrimContainer';
import HeaderAdornment from '../HeaderAdornment';

const styles = ({ spacing }) => ({
    wrapper: {
        marginBottom: spacing.unit * 4
    },
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 4
    },
    editor: {
        '& > .ql-container > .ql-editor': {
            minHeight: spacing.unit * 44.5
        }
    },
    dateRequested: {
        paddingRight: spacing.unit * 0.5
    },
    dateRequiredBy: {
        paddingLeft: spacing.unit * 0.5
    },
    divider: {
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    }
});
const mapInputs = {
    boughtReference: ({ payload }) =>
        getBoughtDetailData({ boughtReference: payload, formName: FORM_NAME }),
    trimReference: ({ payload }) => getTrimDetailData(payload)
};

const Other = compose(
    withStyles(styles),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs)
)(({ classes: c }) => {
    return (
        <div className={c.wrapper}>
            <ContentContainer
                title={LABELS.other}
                AdornmentComponent={<HeaderAdornment />}
            >
                <Grid container className={c.container}>
                    <GridItem md={6}>
                        <AttachmentDetails />
                    </GridItem>
                    <GridItem md={6}>
                        <Editor
                            name='additionalComments'
                            label='Additional Comments'
                            placeholder='Write comments here'
                            styles={{ editor: c.editor }}
                        />
                    </GridItem>
                </Grid>
            </ContentContainer>
            <BoughtContainer />
            <TrimContainer />
        </div>
    );
});

export default Other;
