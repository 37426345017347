import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import styles from './styles';

function _AppBar({ ...props }) {
    const { classes, children, color, position } = props;

    return (
        <AppBar
            color={color}
            position={position}
            className={classes.appBar}
            classes={{
                root: classes.appBarCss
            }}
        >
            {children}
        </AppBar>
    );
}

_AppBar.defaultProps = {
    color: 'inherit',
    position: 'absolute'
};

_AppBar.propTypes = {
    classes: PropTypes.object,
    color: PropTypes.string,
    position: PropTypes.string
};

const __AppBar = withStyles(styles)(_AppBar);

export { __AppBar as default, __AppBar as AppBar };
