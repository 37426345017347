import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const SampleSendReducer = state => state;

const [
    SampleSendProvider,
    useSampleSendState,
    useSampleSendDispatch,
    useSampleSend
] = makeContext(SampleSendReducer, initialState, { name: 'SampleSend' });

export {
    useSampleSend as default,
    SampleSendProvider,
    useSampleSendState,
    useSampleSendDispatch
};
