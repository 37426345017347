import React, { useMemo } from 'react';

import { GENDERS } from 'common/redux.datasets';
import { DOMAIN_NAME } from 'common/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Gender = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Gender', canSort: true, sortKey: 'gender' },
            { label: 'Code', canSort: true, sortKey: 'code' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'GENDER SETUP',
        domain: DOMAIN_NAME,
        entity: GENDERS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Gender as default, Gender };
