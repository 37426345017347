import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { string, bool } from 'prop-types';

import { Field, Checkbox } from 'components/PDF';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    checkboxWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    }
});

const propTypes = {
    cad: string,
    version: string,
    designSourceName: string,
    retailer: string,
    gender: string,
    age: string,
    category: string,
    department: string,
    style: string,
    country: string,
    hasCopyReference: bool,
    copyReference: string,
    hasManualReference: bool,
    manualReference: string
};

const defaultProps = {
    hasCopyReference: false,
    hasManualReference: false
};

const Setup = ({
    classes: c,
    cad,
    version,
    cadDescription,
    designSourceName,
    retailer,
    gender,
    age,
    category,
    department,
    style,
    country,
    hasCopyReference,
    copyReference,
    hasManualReference,
    manualReference
}) => {
    return (
        <View style={styles.container}>
            <Field label={'Select CAD'} value={cad} />
            <Field label={'Version no'} value={version} />
            <Field label={'Graphic Description'} value={cadDescription} />
            <Field label={'Design from'} value={designSourceName} />
            <Field label={'Retailer'} value={retailer} />
            <Field label={'Gender'} value={gender} />
            <Field label={'Age'} value={age} />
            <Field label={'Category'} value={category} />
            <Field label={'Department'} value={department} />
            <Field label={'Style'} value={style} />
            <Field label={'Country'} value={country} />
            {!!copyReference ||
                (!!manualReference && (
                    <View style={styles.checkboxWrapper}>
                        <Checkbox
                            label={'Copy data from another sample request'}
                            checked={!!hasCopyReference}
                            style={{ width: '46%' }}
                        />
                        <Checkbox
                            label={'I want to give this a manual reference'}
                            checked={!!hasManualReference}
                            style={{ width: '46%' }}
                        />
                        <Field value={copyReference} style={{ width: '44%' }} />
                        <Field
                            value={manualReference}
                            style={{ width: '44%' }}
                        />
                    </View>
                ))}
        </View>
    );
};

Setup.propTypes = propTypes;
Setup.defaultProps = defaultProps;

export default Setup;
