import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, Typography } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import isBlobUrl from '@libs/isBlobUrl';
import { withModalProvider, withModal } from '@libComponents/Modal';
import {
    datasetLoadingSelector,
    createFetchGridPreviewData,
    gridPreviewDataSelector
} from '@libs/datasets';
import { setSnack } from 'utilities/redux.actions';
import { Iframe } from 'common/components';

import ActionsAdornment from './ActionsAdornment';
import PreviewMailModal from './PreviewMailModal';
import { FD_GRID_PREVIEW_DATA } from 'fabric/redux.datasets';
import { DOMAIN_NAME } from 'fabric/common';
import { fabricDevelopmentGridPreviewDataSelector } from 'fabric/redux.selectors';
import { useMakePDF } from 'fabric/FabricDevelopment/content/Preview/hooks';

const styles = ({ spacing }) => ({
    container: {
        width: '80vw',
        minHeight: '90vh',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    loadingText: {
        position: 'absolute',
        top: '50%',
        fontSize: spacing.unit * 2.5
    }
});

const mapStateToProps = (state, { reference }) => {
    const data = gridPreviewDataSelector(
        state,
        DOMAIN_NAME,
        FD_GRID_PREVIEW_DATA
    );
    const {
        emailSubject,
        attachments,
        storedPDFData,
        previewTitle,
        versionNo,
        retailer,
        ...PDFProps
    } = fabricDevelopmentGridPreviewDataSelector(data, reference);
    return {
        retailer,
        emailSubject,
        storedPDFData,
        previewTitle,
        versionNo,
        PDFProps,
        loading: datasetLoadingSelector(
            state,
            DOMAIN_NAME,
            FD_GRID_PREVIEW_DATA
        )
    };
};

const mapDispatchToProps = (dispatch, { reference }) => ({
    fetchGridPreviewData: () =>
        dispatch(
            createFetchGridPreviewData({
                endpoint:
                    'FabricDevelopment/GetFabricDevelopmentViewDetailsByRef',
                domain: DOMAIN_NAME,
                entity: FD_GRID_PREVIEW_DATA
            })({ fabricReference: reference })
        ),
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const PDFPreview = compose(
    withModalProvider,
    withModal({
        handleModal: PreviewMailModal
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(
    ({
        classes,
        onClose,
        reference,
        emailSubject,
        storedPDFData,
        previewTitle,
        versionNo,
        retailer,
        PDFProps,
        loading,
        fetchGridPreviewData,
        setSnack,
        handleModal
    }) => {
        const [PDFData, setPDFData] = useState({});

        const [
            PDFBlobURLGenerator,
            PDFBlobURLObject,
            handlePDFBlobURLRemove
        ] = useMakePDF({
            props: PDFProps,
            setSnack
        });

        const handlePreviewModalClose = useCallback(() => {
            handlePDFBlobURLRemove(); // re initialize report obj
            if (isBlobUrl(PDFData.url)) {
                // clear from memory
                URL.revokeObjectURL(PDFData.url);
            }
            setTimeout(() => onClose(), 0);
        }, [PDFData]);

        const handleMailModal = useCallback(() => {
            return handleModal({
                emailSubject,
                retailer,
                PDFData,
                reference,
                handlePreviewModalClose,
                versionNo
            });
        }, [PDFData]);

        useEffect(() => {
            if (!loading && !PDFData.url && !!Object.keys(PDFProps).length) {
                PDFBlobURLGenerator();
            }
        }, [PDFProps, loading, PDFData]);

        useEffect(() => {
            if (!loading && storedPDFData) {
                setPDFData(storedPDFData);
            }
        }, [storedPDFData, loading]);

        useEffect(() => {
            if (PDFBlobURLObject && PDFBlobURLObject.url) {
                setPDFData(PDFBlobURLObject);
            }
        }, [PDFBlobURLObject]);

        useEffect(() => {
            fetchGridPreviewData();
        }, []);

        return (
            <ContentContainer
                title={'Preview'}
                AdornmentComponent={
                    <ActionsAdornment
                        loading={loading || !PDFData.url}
                        handleMailModal={handleMailModal}
                        handlePreviewModalClose={handlePreviewModalClose}
                    />
                }
                styles={{ container: classes.container }}
            >
                {!loading && PDFData.url ? (
                    <Iframe src={PDFData.url} title={previewTitle} />
                ) : (
                    <Typography className={classes.loadingText}>
                        Loading document...
                    </Typography>
                )}
            </ContentContainer>
        );
    }
);

export default PDFPreview;
