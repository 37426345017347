import React, { useCallback, useMemo } from 'react';

import { AttachmentDetailsProvider } from './useAttachmentDetails';

import Container from './Container';
import { makeSideEffectCallback } from '@libs/makeContext';

const Control = ({ input: { value, onChange, onBlur }, hasError }) => {
    const initialize = useCallback(
        state => ({
            ...state,
            attachments: value || []
        }),
        [value]
    );

    const handleOnChange = useCallback(
        value => {
            onBlur();
            onChange(value);
        },
        [onChange, onBlur]
    );

    const middlewareProps = useMemo(
        () => ({
            reduxFormOnChange: makeSideEffectCallback(handleOnChange)
        }),
        [handleOnChange]
    );

    return (
        <AttachmentDetailsProvider
            initialize={initialize}
            middlewareProps={middlewareProps}
        >
            <Container hasError={hasError} />
        </AttachmentDetailsProvider>
    );
};

export default Control;
