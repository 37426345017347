import {
    call,
    fork,
    put,
    take,
    takeEvery,
    select,
    takeLatest
} from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { apiRequest } from '@libs/apiRequest';
import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';
import { userIdSelector } from 'auth/redux.selectors';

import composeEntities from './composeEntities';
import {
    FETCH_DATASETS,
    FETCH_PAGINATED_DATASET,
    FETCH_GRID_DATASET,
    FETCH_GRID_PREVIEW_DATA_BY_REF,
    REFRESH_DATASET
} from './libs.actionTypes';
import {
    setDatasetLoading,
    setDataset,
    setPaginatedDataset,
    fetchDatasets,
    setGridDataset
} from './libs.actions';

const fetchPaginationDatasetFactory = entities =>
    function*({
        meta: { domain, entity, shouldAppend },
        payload: { filterCategory = '', filterKey = '', ...paginationInfo }
    }) {
        const { url, normalizer, params, fields } = entities[entity];

        const isParamsFn = typeof params === 'function';

        // If normalizer takes more than one argument (more than just 'data')
        const isStoreNeeded =
            isParamsFn || (!!normalizer && normalizer.length > 1);

        const store = isStoreNeeded ? yield select() : void 0;

        const rootParams = isParamsFn ? params(store) : params;

        // Flips through fields to find the correct name
        const category = filterCategory
            ? Object.entries(fields).reduce((acm, [rawLabel, label]) => {
                  if (label === filterCategory) return rawLabel;
                  else return acm;
              }, '')
            : '';

        // Fixes naming conventions
        const normalizedPageInfo = {
            ...paginationInfo,
            filter: filterKey,
            category
        };

        try {
            const response = yield call(apiRequest, {
                url,
                params: { ...rootParams, ...normalizedPageInfo }
            });

            const { data: dataset, nextPage } = normalizer(response);

            yield put(
                setPaginatedDataset({
                    domain,
                    entity,
                    shouldAppend,
                    dataset,
                    nextPage
                })
            );
        } catch {
            console.log(`Error while fetching ${entity} resources`);
        }
    };

function* fetchDatasetWorker(datasetsChannel) {
    while (true) {
        const {
            entity,
            url,
            domain,
            selector,
            normalizer,
            params = {}
        } = yield take(datasetsChannel);
        const dataExists = yield select(selector);

        if (!dataExists) {
            const isParamsFn = typeof params === 'function';

            // If normalizer takes more than one argument (more than just 'data')
            const isStoreNeeded =
                isParamsFn || (!!normalizer && normalizer.length > 1);

            const store = isStoreNeeded ? yield select() : void 0;

            yield put(setDatasetLoading({ entity, domain, loading: true }));

            try {
                const response = yield call(apiRequest, {
                    url,
                    params: isParamsFn ? params(store) : params
                });

                const dataset = normalizer(response, store);
                yield put(setDataset({ entity, domain, dataset }));
                yield put(
                    setDatasetLoading({ entity, domain, loading: false })
                );
            } catch {
                console.log(`Error while fetching ${entity} resources`);
            }
        }
    }
}
// Channels
const fetchDatasetsChannelFactory = entities =>
    function* fetchDatasetsChannel({ payload: datasets }) {
        const datasetsChannel = yield call(channel);

        // create 6 worker 'threads'
        for (let i = 0; i < 6; i++) {
            yield fork(fetchDatasetWorker, datasetsChannel);
        }

        for (const dataset of datasets) {
            yield put(datasetsChannel, entities[dataset]);
        }
    };

function* refreshDatasetWorker({ payload: { entity, domain } }) {
    yield put(setDataset({ entity, domain, dataset: [] }));
    yield put(fetchDatasets(entity));
}

function* fetchGridDatasetWorker(action) {
    const {
        meta: { domain, entity, endpoint },
        payload
    } = action;
    const userId = yield select(userIdSelector);

    yield put(setDatasetLoading({ entity, domain, loading: true }));

    try {
        const { data: { currentPage, pageSize, ...data } = {} } = yield call(
            apiRequest,
            {
                url: endpoint,
                params: { userId, ...payload }
            }
        );

        yield put(setGridDataset({ domain, entity, ...data }));
        yield put(setDatasetLoading({ entity, domain, loading: false }));
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(setDatasetLoading({ entity, domain, loading: false }));
        yield put(
            setSnack({
                message: `Failed to retrieve data ${serverMessage}`,
                type: 'error',
                duration: 6000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}

function* fetchGridPreviewDataWorker(action) {
    const {
        meta: { domain, entity, endpoint },
        payload
    } = action;

    yield put(setDatasetLoading({ entity, domain, loading: true }));

    try {
        const { data: { data = [] } = {} } = yield call(apiRequest, {
            url: endpoint,
            params: payload
        });
        yield put(setGridDataset({ domain, entity, ...data[0] }));
        yield put(setDatasetLoading({ entity, domain, loading: false }));
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(setDatasetLoading({ entity, domain, loading: false }));
        yield put(
            setSnack({
                message: `Failed to retrieve data ${serverMessage}`,
                type: 'error',
                duration: 6000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
    }
}

export default datasets => {
    const entities = composeEntities(datasets);

    const fetchDatasetsChannel = fetchDatasetsChannelFactory(entities);
    const fetchPaginationDatasetWorker = fetchPaginationDatasetFactory(
        entities
    );

    return function*() {
        yield takeEvery(FETCH_DATASETS, fetchDatasetsChannel);
        yield takeEvery(FETCH_PAGINATED_DATASET, fetchPaginationDatasetWorker);
        yield takeEvery(REFRESH_DATASET, refreshDatasetWorker);
        yield takeLatest(FETCH_GRID_DATASET, fetchGridDatasetWorker);
        yield takeLatest(
            FETCH_GRID_PREVIEW_DATA_BY_REF,
            fetchGridPreviewDataWorker
        );
    };
};
