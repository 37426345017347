import React from 'react';
import { string, number } from 'prop-types';
import { View } from '@react-pdf/renderer';

import { useCustomDate } from 'sampling/hooks';
import { Field, Title, RichComments } from 'components/PDF';
import { withPDFStyles } from '@libComponents/ReactPDF';

import Image from './Image';

const propTypes = {
    shrinkageLength: number,
    shrinkageWidth: number,
    shrinkageSpirality: number,
    pillingLength: number,
    pillingWidth: number,
    pillingMethod: string,
    elastaneUnrecoveryLength: number,
    elastaneUnrecoveryWidth: number,
    dryProcess: string,
    approvalStatus: string,
    approvedBy: string,
    dateOfApproved: string,
    approvalComments: string,
    finalImage: string
};

const styles = ({ spacing }) => ({
    title: { fontSize: spacing.unit * 1.75, borderBottom: 1 },
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldWidth: {
        width: '23%'
    },
    comments: {
        width: '58%',
        marginTop: spacing.unit * 2.5,
        marginLeft: spacing.unit * 0.5,
        marginRight: spacing.unit * 0.5
    }
});

const PhysicalTestResultsPDFContent = withPDFStyles(styles)(
    ({
        classes: c,
        shrinkageLength,
        shrinkageWidth,
        shrinkageSpirality,
        pillingLength,
        pillingWidth,
        pillingMethod,
        elastaneUnrecoveryLength,
        elastaneUnrecoveryWidth,
        dryProcess,
        approvalStatus,
        approvedBy,
        dateOfApproved,
        approvalComments,
        finalImage
    }) => {
        return (
            <View>
                <Title text='Physical Test Result' style={c.title} />
                <View style={c.container}>
                    <Field
                        label='Shrinkage L%'
                        value={shrinkageLength}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Shrinkage W%'
                        value={shrinkageWidth}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Spirality(%)'
                        value={shrinkageSpirality}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Pilling L%'
                        value={pillingLength}
                        style={c.fieldWidth}
                        required={false}
                    />
                    <Field
                        label='Pilling W%'
                        value={pillingWidth}
                        style={{ width: '20%' }}
                        required={false}
                    />
                    <Field
                        label='Pilling Method'
                        value={pillingMethod}
                        style={{ width: '20%' }}
                        required={false}
                    />
                    <Field
                        label='Elastane Unrecovery L%'
                        value={elastaneUnrecoveryLength}
                        style={{ width: '26%' }}
                        required={false}
                    />
                    <Field
                        label='Elastane Unrecovery W%'
                        value={elastaneUnrecoveryWidth}
                        style={{ width: '26%' }}
                        required={false}
                    />
                    <Field
                        label='Dry Process'
                        value={dryProcess}
                        required={false}
                    />
                </View>
                <Title text='Approval Status' style={c.title} />
                <View style={c.container}>
                    <Field
                        label='Approval Status'
                        value={approvalStatus}
                        required={false}
                    />
                    <Field
                        label='Approved By'
                        value={approvedBy}
                        required={false}
                    />
                    <Field
                        label='Date Approved'
                        value={useCustomDate(dateOfApproved)}
                        required={false}
                    />
                    <View style={c.container}>
                        <RichComments
                            label='FD Approval Comments'
                            comments={approvalComments}
                            styles={c.comments}
                        />
                        <Image
                            url={finalImage}
                            title='Final Image'
                            styles={{
                                width: '40%'
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    }
);

PhysicalTestResultsPDFContent.propTypes = propTypes;

export {
    PhysicalTestResultsPDFContent as default,
    propTypes as PhysicalTestResultsPDFContentPropTypes
};
