import { put } from 'redux-saga/effects';

// Libs
import isBlobUrl from '@libs/isBlobUrl';
import { makeLoadingWorker } from '@libs/publishWorkers';

// Utilities
import { setLoadingProgress } from 'utilities/redux.actions';

export default function*({ versions, mode }) {
    const defaultIncrements = mode === 'new' ? 2 : 1;
    const increments = versions.reduce((acm, version) => {
        if (isBlobUrl(version.url)) return acm + 1;
        else if (mode === 'amend') return acm;
        else return acm + 2;
    }, defaultIncrements); // Initial reference and Final publish

    yield put(setLoadingProgress(0.1));

    return makeLoadingWorker(increments);
}
