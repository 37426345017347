import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl } from '@material-ui/core';

// Actions
import { setReportsPage } from 'store/actions';

// Local
import { ReportsToolbarIcon } from 'views/Reports/components';
import {
    FirstPageIcon,
    PreviousPageIcon,
    NextPageIcon,
    LastPageIcon,
} from 'assets/Reports';

import styles from './styles';


/*
 * Domain: Reports
 * Page: Toolbar
 * Component: Pagination
 * Type: --
 * ReportsToolbarPagination
 */
const ReportsToolbarPagination = ({
    classes: {
        root,
        finalPageIcon,
        immediatePageIcon,
    },
    numPages,
    pageNumber,
    viewMode,
    setReportsPage,
    className
}) => {
    const disabled = viewMode === 'single' || numPages === 1;

    const iconData = [
        {
            title: 'First Page',
            Icon: FirstPageIcon,
            className: finalPageIcon,
            onClick: () => setReportsPage(1),
            disabled: disabled || pageNumber === 1
        },
        {
            title: 'Previous  Page',
            Icon: PreviousPageIcon,
            className: immediatePageIcon,
            onClick: () => setReportsPage(pageNumber - 1),
            disabled: disabled || pageNumber === 1
        },
        {
            title: 'Next Page',
            Icon: NextPageIcon,
            className: immediatePageIcon,
            onClick: () => setReportsPage(pageNumber + 1),
            disabled: disabled || pageNumber === numPages
        },
        {
            title: 'Last Page',
            Icon: LastPageIcon,
            className: finalPageIcon,
            onClick: () => setReportsPage(numPages),
            disabled: disabled || pageNumber === numPages
        },
    ];

    const renderPageSelect = () => {
        const renderPages = [];
        for (let page = 1; page <= numPages; page++) {
             renderPages.push((
                <MenuItem
                    key={`page${page}`}
                    value={page}
                >
                    {`${page} of ${numPages}`}
                </MenuItem>
            ));
        }

        return renderPages;
    };

    return (
        <div className={classNames(root, className)}>
            {iconData.map(
                (iconData, index) =>
                    index < 2 && (
                        <ReportsToolbarIcon
                            key={iconData.title}
                            {...iconData}
                        />
                    )
            )}
            <FormControl
                disabled={disabled}
            >
                <Select
                    value={pageNumber}
                    onClick={({ target: { value } }) => value && setReportsPage(value)}>
                    {renderPageSelect()}
                </Select>
            </FormControl>
            {iconData.map(
                (iconData, index) =>
                    index >= 2 && (
                        <ReportsToolbarIcon
                            key={iconData.title}
                            {...iconData}
                        />
                    )
            )}
        </div>
    );
};

const mapStateToProps = ({ reports: { pagination, viewMode } }) => ({
    ...pagination,
    viewMode
});

const mapDispatchToProps = dispatch => ({
    setReportsPage: pageNumber => dispatch(setReportsPage({ pageNumber })),
});

const _ReportsToolbarPagination = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ReportsToolbarPagination);

export {
    _ReportsToolbarPagination as default,
    _ReportsToolbarPagination as ReportsToolbarPagination,
};
