import Museo300 from '@assets/fonts/Museo-300.ttf';
import Museo500 from '@assets/fonts/Museo-500.otf';
import Museo700 from '@assets/fonts/Museo-700.ttf';

const museo300 = {
    fontFamily: 'Museo',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `local('Museo'), url(${Museo300}) format('truetype')`
};

const museo500 = {
    fontFamily: 'Museo',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `local('Museo'), url(${Museo500}) format('opentype')`
};
const museo700 = {
    fontFamily: 'Museo',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `local('Museo'), url(${Museo700}) format('truetype')`
};

export default {
    MuiCssBaseline: {
        '@global': {
            '@font-face': [museo300, museo500, museo700],
        },
    },
    // LEGACY
    MuiFormLabel: {
        asterisk: {
            color: '#db3131',
            '&$error': {
                color: '#db3131'
            }
        }
    },
    MuiInputBase: {
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color, color'
            }
        }
    }
};
