import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
    text: {
        color: '#333333',
        fontSize: spacing.unit * 1.63
    }
});
const PageCount = withStyles(styles)(
    ({ currentPage, items, totalPages, classes: c }) => (
        <Typography className={c.text}>
            {`Page ${currentPage} of ${totalPages} (${items} items)`}{' '}
        </Typography>
    )
);

export default PageCount;
