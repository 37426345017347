import makeContext from '@libs/makeContext';

const initialState = {
    trimDetails: []
};

const trimDetailsReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'deleteTrimDetail': {
            const { trimDetails } = state;
            const { payload } = action;

            const trimDetailsAfterDelete = trimDetails.filter(
                (trim, index) => index !== payload
            );

            reduxFormOnChange(trimDetailsAfterDelete);

            return action;
        }

        case 'updateTrimDetail': {
            const { trimDetails } = state;
            const {
                payload: { field, value, index }
            } = action;

            const trimDetailsAfterUpdate = trimDetails.map(
                (trimDetail, trimDetailIndex) => ({
                    ...trimDetail,
                    ...(trimDetailIndex === index ? { [field]: value } : {})
                })
            );

            reduxFormOnChange(trimDetailsAfterUpdate);

            return action;
        }
        default:
            return action;
    }
};

const [
    TrimDetailsProvider,
    useTrimDetailsState,
    useTrimDetailsDispatch,
    useTrimDetails
] = makeContext(trimDetailsReducer, initialState, {
    name: 'trimDetails',
    middleware
});

export {
    useTrimDetails as default,
    TrimDetailsProvider,
    useTrimDetailsState,
    useTrimDetailsDispatch
};
