import makeContext from '@libs/makeContext';
import isBlobUrl from '@libs/isBlobUrl';

const initialState = {
    attachments: [],
    isDragging: false
};

const reducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case 'setDragging': {
            return {
                ...state,
                isDragging: payload
            };
        }

        default:
            return state;
    }
};

const middleware = (state, action, { reduxFormOnChange }) => {
    switch (action.type) {
        case 'addAttachments': {
            const { attachments } = state;
            const { payload } = action;

            const newAttachments = [];

            [...payload].forEach(file => {
                newAttachments.push({
                    id: 0,
                    url: URL.createObjectURL(file),
                    filename: file.name,
                    size: file.size
                });
            });

            reduxFormOnChange([...attachments, ...newAttachments]);
            return { type: 'setDragging', payload: false };
        }

        case 'removeAttachment': {
            const { attachments } = state;
            const { payload } = action;

            const attachmentsAfterRemoval = attachments.filter(
                ({ url }, index) => {
                    if (index === payload && isBlobUrl(url)) {
                        URL.revokeObjectURL(url);
                    }
                    return index !== payload;
                }
            );

            reduxFormOnChange(attachmentsAfterRemoval);

            return action;
        }

        case 'removeAll': {
            reduxFormOnChange([]);
            return action;
        }

        default:
            return action;
    }
};

const [
    AttachmentDetailsProvider,
    useAttachmentDetailsState,
    useAttachmentDetailsDispatch,
    useAttachmentDetails
] = makeContext(reducer, initialState, {
    name: 'AttachmentDetails',
    middleware
});

export {
    useAttachmentDetails as default,
    AttachmentDetailsProvider,
    useAttachmentDetailsState,
    useAttachmentDetailsDispatch
};
