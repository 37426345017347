import React from 'react';
import { connect } from 'react-redux';

import { setSnack } from 'utilities/redux.actions';

import {
    useSamplingReport,
    useFilteredData,
    useAggregatedValue
} from './hooks';
import { useDetailsBreakdownChartState } from '../../../useDetailsBreakdownChart';
import ExportButton from '../ExportButton';

const mapDispatch = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const ExportPDF = connect(
    null,
    mapDispatch
)(({ setSnack }) => {
    const {
        data,
        chartSettings,
        retailer,
        startDate,
        endDate
    } = useDetailsBreakdownChartState();
    const reportName = `${retailer} ${startDate}-${endDate}.pdf`;
    const filteredData = useFilteredData(data, chartSettings);
    const total = useAggregatedValue(filteredData);

    const [handleExport, exportLoading, exportDisabled] = useSamplingReport({
        fileName: reportName,
        props: {
            data: filteredData,
            retailer,
            startDate,
            endDate,
            total,
            view: chartSettings.view,
            cellSettings: chartSettings.cellSettings
        },
        setSnack
    });

    return (
        <ExportButton
            disabled={exportDisabled}
            loading={exportLoading}
            onClick={handleExport}
        />
    );
});

export default ExportPDF;
