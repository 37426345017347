import React, { useRef, useMemo } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { object } from 'prop-types';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Title } from 'components/PDF';

import GrandTotal from './GrandTotal';

const styles = ({ palette, spacing, typography }) => ({
    container: {
        width: '100%',

        flexDirection: 'row',
        justifyContent: 'space-between',

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1,

        backgroundColor: palette.grey[300]
    },

    tableHeader: {
        fontSize: spacing.unit * 0.75,
        fontWeight: typography.fontWeightBold,
        padding: spacing.unit * 0.25,
        textAlign: 'center',

        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1
    },

    tableBody: {
        borderTop: 'none',
        backgroundColor: '#fff',

        flexDirection: 'column',
        justifyContent: 'center'
    },

    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',

        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 2
    },

    tableDataContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        borderRightStyle: 'solid',
        borderRightColor: palette.background.dark,
        borderRightWidth: 1
    },

    tableSubRowContainer: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',

        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 1
    },

    borderRightNone: {
        borderRight: 'none'
    },
    borderBottomNone: {
        borderBottom: 'none'
    },
    tableData: {
        fontSize: spacing.unit * 0.65,
        padding: spacing.unit * 0.25,
        lineHeight: 1.4,

        opacity: 0.9
    },
    yellowBG: {
        backgroundColor: 'yellow'
    }
});

const propTypes = {
    poTable: object.isRequired,
    grandTotal: object.isRequired
};

const PODetails = withPDFStyles(styles)(
    ({ classes: c, poTable: { keys = [], values = [] } = {}, grandTotal }) => {
        const totalPO = values.length;
        const noOfSubRowsRef = useRef(0);
        const subRowHeightRef = useRef({});
        const defaultSubRowHeight = 20;
        const tableLength = keys.length;
        const columnSize = `${100 / tableLength}%`;
        const CHARACTERS_PER_LINE = useMemo(() => {
            return tableLength <= 25 ? 14 : tableLength < 30 ? 12 : 10;
        }, [tableLength]);

        const isEmpty = value =>
            value === '' || value === null || value === undefined;

        const isNumber = value => typeof value === 'number';

        const shouldAddLine = value => value.length > CHARACTERS_PER_LINE;

        const handleSubRowHeight = (value, rowIdx) => {
            const EACH_LINE_HEIGHT = 10.5;
            const valueLength = value.length;
            const height =
                Math.ceil(valueLength / CHARACTERS_PER_LINE) * EACH_LINE_HEIGHT;
            const defaultValue =
                subRowHeightRef.current[rowIdx] || defaultSubRowHeight;
            if (rowIdx !== undefined && height > defaultValue) {
                subRowHeightRef.current[rowIdx] = height;
            }
            return value;
        };

        const composeValue = (value, rowIdx) => {
            if (isEmpty(value)) return 'N/A';
            if (isNumber(value)) return value;
            handleSubRowHeight(value, rowIdx);
            if (shouldAddLine(value)) {
                let result = '';
                while (value.length > 0) {
                    result += value.substring(0, CHARACTERS_PER_LINE) + '\n';
                    value = value.substring(CHARACTERS_PER_LINE);
                }
                return result;
            }
            return value;
        };

        return (
            <View>
                <Title text={'PO Details'} wrap={false} />
                <View style={c.container} wrap={false}>
                    {keys.map((header, idx) => (
                        <Text
                            style={{
                                ...c.tableHeader,
                                width: columnSize,
                                ...(tableLength - 1 === idx
                                    ? { borderRight: 'none' }
                                    : {})
                            }}
                            key={`po-header-${idx}`}
                        >
                            {header}
                        </Text>
                    ))}
                </View>
                <View
                    style={{
                        ...c.container,
                        ...c.tableBody
                    }}
                >
                    {values.map((row, rowIdx) => {
                        return (
                            <View
                                style={{
                                    ...c.tableRow,
                                    ...(totalPO - 1 === rowIdx
                                        ? c.borderBottomNone
                                        : {})
                                }}
                                key={`po-table-body-row-${rowIdx}`}
                                wrap={false}
                            >
                                {row.map((column, columnIdx) => {
                                    const columnLength =
                                        (column && column.length) || 0;
                                    noOfSubRowsRef.current = Math.max(
                                        columnLength,
                                        noOfSubRowsRef.current
                                    );
                                    const isColumnAmend =
                                        !Array.isArray(column) &&
                                        column.isAmend;
                                    return (
                                        <View
                                            style={{
                                                ...c.tableDataContainer,
                                                ...(tableLength - 1 ===
                                                columnIdx
                                                    ? c.borderRightNone
                                                    : {}),
                                                width: columnSize,
                                                ...(isColumnAmend
                                                    ? c.yellowBG
                                                    : {})
                                            }}
                                            key={`po-table-row-column-${rowIdx}-${columnIdx}`}
                                        >
                                            {Array.isArray(column) ? (
                                                <View style={{ width: '100%' }}>
                                                    {column.map(
                                                        (subRow, subRowIdx) => (
                                                            <View
                                                                style={{
                                                                    ...c.tableSubRowContainer,
                                                                    ...(column.length -
                                                                        1 ===
                                                                    subRowIdx
                                                                        ? c.borderBottomNone
                                                                        : {}),
                                                                    height:
                                                                        columnIdx <
                                                                        12
                                                                            ? `${subRowHeightRef
                                                                                  .current[
                                                                                  rowIdx
                                                                              ] ||
                                                                                  defaultSubRowHeight}px`
                                                                            : `${defaultSubRowHeight}px`,
                                                                    ...(subRow.isAmend
                                                                        ? c.yellowBG
                                                                        : {})
                                                                }}
                                                                key={`po-table-row-column-subRow-${rowIdx}-${columnIdx}-${subRowIdx}`}
                                                            >
                                                                <Text
                                                                    style={
                                                                        c.tableData
                                                                    }
                                                                >
                                                                    {composeValue(
                                                                        subRow.value,
                                                                        rowIdx
                                                                    )}
                                                                </Text>
                                                            </View>
                                                        )
                                                    )}
                                                </View>
                                            ) : (
                                                <Text style={c.tableData}>
                                                    {composeValue(column.value)}
                                                </Text>
                                            )}
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    })}
                </View>
                <GrandTotal
                    poColumnSize={100 / tableLength}
                    grandTotal={grandTotal}
                />
            </View>
        );
    }
);

PODetails.propTypes = propTypes;

export default PODetails;
