import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';
import { withFieldArray } from '@libs/reduxForm';

import SizeInput from './SizeInput';
import { useFieldsArrayItemMove } from '../../../../hooks';

const propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    initialSizes: PropTypes.array
};

const defaultProps = {
    name: 'sizes',
    required: true
};

const styles = ({ spacing, typography, palette }) => ({
    container: {
        width: '100%',
        marginTop: spacing.unit * 2
    },
    message: {
        textAlign: 'center',
        color: palette.error.light,
        margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px`
    },

    button: {
        margin: spacing.unit * 2,
        width: spacing.unit * 19
    }
});

const AddNewItemToSizeSet = compose(
    withFieldArray(),
    withStyles(styles)
)(({ classes: c, fields }) => {
    const dragAndDropProps = useFieldsArrayItemMove(fields);
    const handleAddSize = () => {
        fields.push({ slNo: fields.length + 1, id: 0 });
    };

    return (
        <div className={c.container}>
            {fields.map((size, index) => (
                <SizeInput
                    key={index}
                    size={size}
                    index={index}
                    fields={fields}
                    dragAndDropProps={dragAndDropProps}
                />
            ))}
            <Button
                variant='outlined'
                color='primary'
                onClick={handleAddSize}
                className={c.button}
            >
                Add Size to Size Set
            </Button>
        </div>
    );
});

AddNewItemToSizeSet.propTypes = propTypes;
AddNewItemToSizeSet.defaultProps = defaultProps;

export { AddNewItemToSizeSet as default, AddNewItemToSizeSet };
