import React from 'react';
import { string, number, oneOfType } from 'prop-types';

// Material
import { withStyles, Typography } from '@material-ui/core';

const propTypes = {
    label: string.isRequired,
    value: oneOfType([string, number])
};

const styles = ({ palette, spacing, shape }) => ({
    label: {
        fontSize: spacing.unit * 1.75
    },
    columnValue: {
        minHeight: spacing.unit * 3.25,
        backgroundColor: palette.background.light,
        padding: `${spacing.unit * 0.5}px ${spacing.unit * 1}px`,
        fontSize: spacing.unit * 1.5,

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderRadius: shape.borderRadius,
        flex: 1
    }
});

const BasicColumn = withStyles(styles)(({ classes: c, label, value }) => {
    return (
        <div>
            <Typography className={c.label}>{label}</Typography>
            <Typography className={c.columnValue}>{value}</Typography>
        </div>
    );
});

BasicColumn.propTypes = propTypes;

export default BasicColumn;
