import { SET_SAMPLING_PAGE_TYPE } from 'actionTypes';

const specReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        default: {
            return state;
        }
    }
};

export { specReducer as default, specReducer };
