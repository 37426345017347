import { takeLatest, put, call } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { gridXLFileDownload } from '@libs/sagaWorkers';
import { apiRequest } from '@libs/apiRequest';
import downloadBlob from '@libs/downloadBlob';
import { valueSelector } from '@libs/datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';
import { ARTWORK_SOURCES } from 'cadUpload/redux.datasets';
import store from 'app/ReduxGate/store';

import {
    DOWNLOAD_SAMPLES_AS_EXCEL,
    DOWNLOAD_SAMPLES_AS_MULTI_PAGE,
    DOWNLOAD_SAMPLES_AS_SINGLE_PAGE,
    DOWNLOAD_SAMPLES_AS_COST_FORM,
    SAMPLE_REPORT_MAIL_SEND
} from '../../redux.actionTypes';
import {
    setDownloadSamplesAsExcelLoading,
    setDownloadSamplesAsMultiPageLoading,
    setDownloadSamplesAsSinglePageLoading,
    setDownloadSamplesAsCostFormLoading
} from '../../redux.actions';
import { getDataForSelectedRowsWithSamplesSelector } from '../../redux.selectors';
import sampleReportUploadAndMailSendWorker from './sampleReportUploadAndMailSendWorker';

function prepareCostFormRows(data, sampleType) {
    const costFields = [
        { header: 'ECHO COST', name: 'echoCost', type: 'text' },
        { header: 'ADDITONAL FEES', name: 'additionalFees', type: 'text' },
        { header: 'FINAL PRICE', name: 'finalPrice', type: 'text' },
        { header: 'HOD', name: 'hod', type: 'text' }
    ];
    const printCostFormHeaderMap = [
        { header: 'ECHO REF.', name: 'sampleRef', type: 'text' },
        {
            header: 'IMAGE',
            name: 'frontImage',
            type: 'image|100',
            render: image => {
                return image[0].size100;
            }
        },
        { header: 'VALIDATION', name: 'validation', type: 'text' },
        {
            header: 'DESIGN FROM',
            name: 'designFromId',
            type: 'text',
            render: designFromId => {
                return valueSelector(
                    store.getState(),
                    CAD_DOMAIN_NAME,
                    ARTWORK_SOURCES,
                    designFromId
                );
            }
        },
        { header: 'GRAPHIC DESC', name: 'graphicDescription', type: 'text' },
        { header: 'FABRIC', name: 'fabrication', type: 'text' },
        ...costFields
    ];
    const shapeCostFormHeaderMap = [
        { header: 'ECHO REF.', name: 'sampleRef', type: 'text' },
        {
            header: 'FRONT IMAGE',
            name: 'frontImage',
            type: 'image|100',
            render: image => {
                return image[0].size100;
            }
        },
        {
            header: 'BACK IMAGE',
            name: 'backImage',
            type: 'image|100',
            render: image => {
                return image[0].size100;
            }
        },
        {
            header: 'DESIGN FROM',
            name: 'designFromId',
            type: 'text',
            render: designFromId => {
                return valueSelector(
                    store.getState(),
                    CAD_DOMAIN_NAME,
                    ARTWORK_SOURCES,
                    designFromId
                );
            }
        },
        { header: 'STYLE DESC', name: 'styleDescription', type: 'text' },
        { header: 'FABRIC', name: 'fabrication', type: 'text' },
        ...costFields
    ];

    return data.reduce((acm, row) => {
        let newRow;
        const costFormReducer = (acm, { name, render, ...col }) => {
            return [
                ...acm,
                {
                    ...col,
                    value: render ? render(row[name]) : row[name]
                }
            ];
        };
        if (sampleType === 'P') {
            newRow = printCostFormHeaderMap.reduce(costFormReducer, []);
        } else {
            newRow = shapeCostFormHeaderMap.reduce(costFormReducer, []);
        }
        return [...acm, newRow];
    }, []);
}

function* downloadSamplesAsExcelWorker({ payload }) {
    const ROW_DOWNLOAD_LIMIT = 500;
    const { totalCount, selectedRows } = payload || {};

    if (
        totalCount <= ROW_DOWNLOAD_LIMIT ||
        (!!selectedRows.length && selectedRows.length <= ROW_DOWNLOAD_LIMIT)
    ) {
        try {
            yield put(setDownloadSamplesAsExcelLoading(true));
            yield call(gridXLFileDownload, {
                payload,
                fileName: 'samples.xlsx',
                endpoint: 'FileUpload/ExportToExcelAllSamplesColumn'
            });
            yield put(setDownloadSamplesAsExcelLoading(false));
        } catch (error) {
            yield put(setDownloadSamplesAsExcelLoading(false));
            yield put(
                setSnack({
                    message: 'Failed to download the Excel file.',
                    type: 'error',
                    duration: 6000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Row count exceeds ${ROW_DOWNLOAD_LIMIT}. Please narrow your search.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

function* downloadSamplesAsMultiPageWorker({ payload }) {
    const { selectedRows, data } = payload || {};

    if (!!selectedRows.length) {
        const {
            sampleType,
            hasBoth
        } = getDataForSelectedRowsWithSamplesSelector(data, selectedRows);
        if (!hasBoth) {
            try {
                const requestParams = {
                    sampleIds: selectedRows.join(','),
                    moduleType:
                        sampleType === 'P' ? 'printGridView' : 'shapeGridView',
                    reportType: 'multi'
                };
                yield put(setDownloadSamplesAsMultiPageLoading(true));
                const { data: blob } = yield call(apiRequest, {
                    method: 'get',
                    url: 'Sample/GetSamplePageReport',
                    params: requestParams,
                    responseType: 'blob'
                });
                yield call(downloadBlob, { blob, fileName: 'multi_page.pdf' });
                yield put(setDownloadSamplesAsMultiPageLoading(false));
            } catch (error) {
                yield put(setDownloadSamplesAsMultiPageLoading(false));
                yield put(
                    setSnack({
                        message: 'Failed to download the multi page file.',
                        type: 'error',
                        duration: 6000
                    })
                );
            }
        } else {
            yield put(
                setSnack({
                    message:
                        'You cannot download both PRINT and SHAPE types together.',
                    type: 'error',
                    duration: 8000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Please select row to download.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

function* downloadSamplesAsSinglePageWorker({ payload }) {
    const { selectedRows, data } = payload || {};

    if (!!selectedRows.length) {
        const {
            sampleType,
            hasBoth
        } = getDataForSelectedRowsWithSamplesSelector(data, selectedRows);
        if (!hasBoth) {
            try {
                const requestParams = {
                    sampleIds: selectedRows.join(','),
                    moduleType:
                        sampleType === 'P' ? 'printGridView' : 'shapeGridView',
                    reportType: 'single'
                };
                yield put(setDownloadSamplesAsSinglePageLoading(true));
                const { data: blob } = yield call(apiRequest, {
                    method: 'get',
                    url: 'Sample/GetSamplePageReport',
                    params: requestParams,
                    responseType: 'blob'
                });
                yield call(downloadBlob, { blob, fileName: 'single_page.pdf' });
                yield put(setDownloadSamplesAsSinglePageLoading(false));
            } catch (error) {
                yield put(setDownloadSamplesAsSinglePageLoading(false));
                yield put(
                    setSnack({
                        message: 'Failed to download the single page file.',
                        type: 'error',
                        duration: 6000
                    })
                );
            }
        } else {
            yield put(
                setSnack({
                    message:
                        'You cannot download both PRINT and SHAPE types together.',
                    type: 'error',
                    duration: 8000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Please select row to download.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

function* downloadSamplesACostFormWorker({ payload }) {
    const { selectedRows, data } = payload || {};

    if (!!selectedRows.length) {
        const {
            sampleType,
            hasBoth,
            selectedSamples
        } = getDataForSelectedRowsWithSamplesSelector(data, selectedRows);
        if (!hasBoth) {
            const preparedRows = prepareCostFormRows(
                selectedSamples,
                sampleType
            );

            try {
                yield put(setDownloadSamplesAsCostFormLoading(true));
                const { data: blob } = yield call(apiRequest, {
                    method: 'post',
                    url: 'FileUpload/ExportToExcel',
                    data: {
                        data: preparedRows,
                        fileName: 'cost_form.xlsx'
                    },
                    responseType: 'blob'
                });
                yield call(downloadBlob, { blob, fileName: 'cost_form.xlsx' });
                yield put(setDownloadSamplesAsCostFormLoading(false));
            } catch (error) {
                yield put(setDownloadSamplesAsCostFormLoading(false));
                yield put(
                    setSnack({
                        message: 'Failed to download the cost form.',
                        type: 'error',
                        duration: 6000
                    })
                );
            }
        } else {
            yield put(
                setSnack({
                    message:
                        'You cannot download both PRINT and SHAPE types together.',
                    type: 'error',
                    duration: 8000
                })
            );
        }
    } else {
        yield put(
            setSnack({
                message: `Please select row to download.`,
                type: 'error',
                duration: 6000
            })
        );
    }
}

// Watchers
export default function*() {
    yield takeLatest(DOWNLOAD_SAMPLES_AS_EXCEL, downloadSamplesAsExcelWorker);
    yield takeLatest(
        DOWNLOAD_SAMPLES_AS_MULTI_PAGE,
        downloadSamplesAsMultiPageWorker
    );
    yield takeLatest(
        DOWNLOAD_SAMPLES_AS_SINGLE_PAGE,
        downloadSamplesAsSinglePageWorker
    );
    yield takeLatest(
        DOWNLOAD_SAMPLES_AS_COST_FORM,
        downloadSamplesACostFormWorker
    );
    yield takeLatest(
        SAMPLE_REPORT_MAIL_SEND,
        sampleReportUploadAndMailSendWorker
    );
}
