import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing, palette }) => ({
    overlayChartContainer: {
        '--max-height': `${spacing.unit * 12.5}px`,
        maxHeight: 'var(--max-height)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            transition: 'width 0.3s ease'
        },
        '&:hover::-webkit-scrollbar': {
            width: spacing.unit * 0.75
        },
        '&::-webkit-scrollbar-track': {
            background: palette.grey[300]
        },
        '&::-webkit-scrollbar-thumb': {
            background: palette.grey[500],
            borderRadius: spacing.unit * 0.75,
            transition: 'background 0.3s ease'
        },
        '&:hover::-webkit-scrollbar-thumb': {
            background: palette.grey[600]
        }
    }
});

const OverlayChartContainer = ({ classes, children, style }) => {
    return (
        <div className={classes.overlayChartContainer} style={style}>
            {children}
        </div>
    );
};

export default withStyles(styles)(OverlayChartContainer);
