import { useMemo } from 'react';
import { STATUS_ARRAY, STATUS_PAST_ARRAY } from './constants';

export const useWatermarkAndFileName = action => {
    return useMemo(() => {
        const actionName = STATUS_ARRAY[action];
        const fileName = actionName
            ? `order_report_${actionName.toLowerCase()}.pdf`
            : 'order_report.pdf';
        const watermarkObj = action
            ? { hasWatermark: true, watermarkText: STATUS_PAST_ARRAY[action] }
            : {};
        return {
            fileName,
            ...watermarkObj,
            statusActionName: actionName
        };
    }, [action]);
};
