import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = ({ palette }) => ({
    loader: {
        margin: '8px 0px',
        width: '66px',
        height: '52px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: fade(palette.secondary.main, 0.3),
        color: palette.primary.light
    }
});

const ImageLoader = withStyles(styles)(({ classes: c }) => {
    return (
        <div className={c.loader}>
            <CircularProgress color='inherit' size={30} thickness={2} />
        </div>
    );
});

export default ImageLoader;
