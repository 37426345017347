import { change } from 'redux-form';
import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import { FORM_NAME, INCOMPLETE_SHAPE_ROUTE_ID } from '../../../Shape/common';
import { getIncompleteShapeData } from '../../../redux.actions';

export default function*({ mode, sampleReference }) {
    if (mode !== 'incomplete') {
        yield put(setRedirect(true));
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(
        setSnack({
            message: `Saved draft: ${sampleReference}`,
            type: 'success'
        })
    );
    yield put(redirectToRoute(INCOMPLETE_SHAPE_ROUTE_ID)); // Route key changed
    yield put(getIncompleteShapeData(sampleReference));
}
