import { change } from 'redux-form';

import { apiClient } from '@libs/apiRequest';
import { FORM_NAME } from 'orders/Order/common';
import { setClientPONoCheckLoading } from 'orders/redux.actions';

export default async ({ clientPONo = '', clientPOTBC, id }, dispatch) => {
    if (clientPOTBC) {
        dispatch(change(FORM_NAME, 'clientPONoAsync', 'Valid'));
        return;
    }
    // Responds with 404 on fail
    dispatch(setClientPONoCheckLoading(true));
    const checkReference = new Promise(
        async resolve =>
            await apiClient({
                url: 'OrderMains/GetClientPoDuplicateCheck',
                params: {
                    clientPONo,
                    id
                }
            })
                .then(() => resolve(true))
                .catch(() => resolve(false))
    );
    const referenceExists = await checkReference;
    dispatch(setClientPONoCheckLoading(false));
    if (referenceExists) {
        throw { clientPONo: 'This Client PO NO already exists' };
    } else {
        dispatch(change(FORM_NAME, 'clientPONoAsync', 'Valid'));
    }
};
