import React from 'react';

import {
    COMPOSITION_ROUTE_ID,
    GSM_ROUTE_ID,
    DYE_TYPE_ROUTE_ID,
    FABRIC_TYPES_ROUTE_ID,
    FABRIC_FORS_ROUTE_ID,
    FINISH_WASH_ROUTE_ID,
    FABRIC_GROUP_ROUTE_ID,
    FABRIC_SUSTAINABILITY_ROUTE_ID
} from './common';
import Composition from './Composition';
import GSM from './GSM';
import DyeTypes from './DyeTypes';
import FabricTypes from './FabricTypes';
import FabricFors from './FabricFors';
import FinishWash from './FinishWash';
import FabricGroup from './FabricGroup';
import Sustainability from './Sustainability';

export default {
    [COMPOSITION_ROUTE_ID]: () => <Composition />,
    [GSM_ROUTE_ID]: () => <GSM />,
    [DYE_TYPE_ROUTE_ID]: () => <DyeTypes />,
    [FABRIC_TYPES_ROUTE_ID]: () => <FabricTypes />,
    [FABRIC_FORS_ROUTE_ID]: () => <FabricFors />,
    [FINISH_WASH_ROUTE_ID]: () => <FinishWash />,
    [FABRIC_GROUP_ROUTE_ID]: () => <FabricGroup />,
    [FABRIC_SUSTAINABILITY_ROUTE_ID]: () => <Sustainability />
};
