import React, { PureComponent } from 'react';
import { SampleStepsContainer } from './steps';

class Home extends PureComponent {
    render() {
        const {
            parent = 'print', //print, shape/non-print
            newPage = false,
            incompletePage = false,
            amendPage = false,
            title = ''
        } = this.props;
        return (
            <SampleStepsContainer
                title={title}
                pageType={{
                    parent,
                    newPage,
                    incompletePage,
                    amendPage
                }}
            />
        );
    }
}

export default Home;
