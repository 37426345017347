import createCachedSelector from 're-reselect';

import reportIcons from './reportIcons';

import { DOMAIN_NAME, DUMMY_DATASETS } from './common';

const cadDesignerReport = state => state[DOMAIN_NAME];
const reportIconsSelector = () => reportIcons;
const reportGroupAndTitleSelector = () => ({
    totalNumberofCads: {
        title: 'Total Number of Cads Produced',
        group: 'cadStatistics',
        serial: 1
    },
    totalCadConvToSampleByCad: {
        title: 'Total Cads Converted to Samples',
        group: 'cadStatistics',
        serial: 2
    },
    totalOrderConvByCad: {
        title: 'Number of Samples Converted to Orders',
        group: 'cadStatistics',
        serial: 3
    },
    totalNumberofsrfs: {
        title: 'Total Sample Requests done by Selected Designer',
        group: 'cadStatistics',
        serial: 4
    },
    cadVsSample: {
        title: 'Cads vs Samples Ratio',
        group: 'cadStatistics',
        serial: 5
    },
    cadVsOrder: {
        title: 'Cads vs Order Ratio',
        group: 'cadStatistics',
        serial: 6
    },
    totalOrderQtyByCad: {
        title: 'Total Order Quantity',
        group: 'quantityReport',
        serial: 7
    },
    totalOrderQtyBdByCad: {
        title: 'Order Quantity Bangladesh',
        group: 'quantityReport',
        serial: 8
    },
    totalOrderQtyTkyByCad: {
        title: 'Order Quantity Turkey',
        group: 'quantityReport',
        serial: 9
    },
    totalOrderShippedQtyByCad: {
        title: 'Total Order Shipped to Date',
        group: 'quantityReport',
        serial: 10
    },
    totalOrderShippedQtyBdByCad: {
        title: 'Order Shipped to Date Bangladesh',
        group: 'quantityReport',
        serial: 11
    },
    totalOrderShippedQtyTkyByCad: {
        title: 'Order Shipped to Date Turkey',
        group: 'quantityReport',
        serial: 12
    },
    totalWorkInQtyByCad: {
        title: 'Total Work in Progress Quantity',
        group: 'quantityReport',
        serial: 13
    },
    totalWorkInQtyBDByCad: {
        title: 'Work in Progress Quantity Bangladesh',
        group: 'quantityReport',
        serial: 14
    },
    totalWorkInQtyTkyByCad: {
        title: 'Work in Progress Quantiry Turkey',
        group: 'quantityReport',
        serial: 15
    },
    totalGlobalRevenuePound: {
        title: 'Total Global Revenue Pound',
        group: 'shippedQuantityReport',
        serial: 16
    },
    totalRevenuePound: {
        title: 'Total Revenue Pound',
        group: 'shippedQuantityReport',
        serial: 17
    },
    totalRevenueDollar: {
        title: 'Total Revenue Dollar',
        group: 'shippedQuantityReport',
        serial: 18
    },
    totalRevenueEuro: {
        title: 'Total Revenue Euro',
        group: 'shippedQuantityReport',
        serial: 19
    },
    totalGlobalMarginPound: {
        title: 'Total Global Margin Pound',
        group: 'shippedQuantityReport',
        serial: 20
    },
    totalMarginPound: {
        title: 'Total Margin Pound',
        group: 'shippedQuantityReport',
        serial: 21
    },
    totalMarginDollar: {
        title: 'Total Margin Dollar',
        group: 'shippedQuantityReport',
        serial: 22
    },
    totalMarginEuro: {
        title: 'Total Margin Euro',
        group: 'shippedQuantityReport',
        serial: 23
    },
    globalWorkInRevenuePound: {
        title: 'Total Global Revenue Pound',
        group: 'wipQuantityReport',
        serial: 24
    },
    totalWorkInRevenuePound: {
        title: 'Total Revenue Pound',
        group: 'wipQuantityReport',
        serial: 25
    },
    totalWorkInRevenueDollar: {
        title: 'Total Revenue Dollar',
        group: 'wipQuantityReport',
        serial: 26
    },
    totalWorkInRevenueEuro: {
        title: 'Total Revenue Euro',
        group: 'wipQuantityReport',
        serial: 27
    },
    globalWorkInMarginPound: {
        title: 'Total Global Margin Pound',
        group: 'wipQuantityReport',
        serial: 28
    },
    totalWorkInMarginPound: {
        title: 'Total Margin Pound',
        group: 'wipQuantityReport',
        serial: 29
    },
    totalWorkInMarginDollar: {
        title: 'Total Margin Dollar',
        group: 'wipQuantityReport',
        serial: 30
    },
    totalWorkInMarginEuro: {
        title: 'Total Margin Euro',
        group: 'wipQuantityReport',
        serial: 31
    },
    cadTypeName: {
        title: 'CAD TYPE',
        group: 'summary',
        serial: 31
    },
    buyerName: {
        title: 'RETAILER',
        group: 'summary',
        serial: 32
    },
    departmentName: {
        title: 'DEPARTMENT',
        group: 'summary',
        serial: 33
    },
    startDate: {
        title: 'START DATE',
        group: 'summary',
        serial: 34
    },
    endDate: {
        title: 'END DATE',
        group: 'summary',
        serial: 35
    },
    designerName: {
        title: 'DESIGNER',
        group: 'summary',
        serial: 36
    }
});

const reportData = createCachedSelector(cadDesignerReport, domain => {
    return (
        (!!Object.values(domain.reportData).length && domain.reportData) ||
        DUMMY_DATASETS
    );
})(state => `@report-data`);

const cadDesignerServerDataExist = createCachedSelector(
    cadDesignerReport,
    domain => !!Object.values(domain.reportData).length
)(state => `@cad-designer-server-data-exist`);

const cadDesignerReportData = createCachedSelector(
    reportData,
    reportIconsSelector,
    reportGroupAndTitleSelector,
    (data, icons, groups) => {
        const groupData = Object.entries(data).reduce((accu, [key, value]) => {
            if (!!groups[key]) {
                const groupItems = !!accu[groups[key].group]
                    ? accu[groups[key].group]
                    : [];
                return {
                    ...accu,
                    [groups[key].group]: [
                        ...groupItems,
                        {
                            key,
                            value,
                            ...groups[key],
                            ...icons[key]
                        }
                    ]
                };
            }
            return accu;
        }, {});

        return Object.entries(groupData).reduce((accu, [key, values]) => {
            return {
                ...accu,
                [key]: values
                    .sort((a, b) => a.serial - b.serial)
                    .map(({ serial, group, ...rest }) => rest)
            };
        }, {});

        //return groupData;
    }
)(state => `@cad-designer-report-data`);

const cadDesignerReportDataLoading = createCachedSelector(
    cadDesignerReport,
    cadDesignerReport => cadDesignerReport.reportDataLoading
)(state => `@cad-designer-report-data-loading`);

const cadDesignerReportPDFData = createCachedSelector(
    reportData,
    reportGroupAndTitleSelector,
    (data, groups) => {
        const groupData = Object.entries(data).reduce((accu, [key, value]) => {
            if (
                !!groups[key] &&
                key !== 'cadVsSample' &&
                key !== 'cadVsOrder'
            ) {
                const groupItems = !!accu[groups[key].group]
                    ? accu[groups[key].group]
                    : [];
                return {
                    ...accu,
                    [groups[key].group]: [
                        ...groupItems,
                        {
                            key,
                            value,
                            ...groups[key]
                        }
                    ]
                };
            }
            return accu;
        }, {});
        const { summary, ...rest } = Object.entries(groupData).reduce(
            (accu, [key, values]) => {
                return {
                    ...accu,
                    [key]: values
                        .sort((a, b) => a.serial - b.serial)
                        .map(({ serial, group, ...rest }) => rest)
                };
            },
            {}
        );
        const dates = summary.splice(3, 2);
        const rangeObj = {
            key: 'dateRange',
            value: `${dates[0].value} to ${dates[1].value}`,
            title: 'DATE RANGE'
        };
        summary.splice(3, 0, rangeObj);
        return { ...rest, summary };
    }
)(state => `@cad-designer-pdf-data`);

export {
    cadDesignerReportData,
    cadDesignerReportDataLoading,
    cadDesignerReportPDFData,
    cadDesignerServerDataExist
};
