import 'date-fns';
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    defaultTopPadding: {
        padding: '10px 16px 0px',
    },
    defaultContentPadding: {
        padding: '30px 0px 10px',
    },
    titleStyle: { color: 'rgba(0, 0, 0, 0.54)' },
    fontStyle: {
        fontSize: 32,
        lineHeight: '48px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

class drawerDate extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Grid
                    container
                    justify='center'
                    spacing={0}
                    className={classes.defaultTopPadding}
                >
                    <Grid item md={12}>
                        <Typography className={classes.titleStyle}>
                            Today
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={classes.defaultContentPadding}
                    >
                        <Typography className={classes.fontStyle}>
                            Monday
                        </Typography>
                        <Typography className={classes.fontStyle}>
                            18th February
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(drawerDate);
