import { fork } from 'redux-saga/effects';

// Local
import notificationWatchers from './notification';

const utilsSaga = function*() {
	yield fork(notificationWatchers);
};

export { utilsSaga as default, utilsSaga };
