import {
    ATTACHMENTS_UPLOAD_LOADING,
    BACKNECK_IMG_UPLOAD_LOADING
} from '../actionTypes/index';

const initialData = {
    cadUploadLoader: 0,
    attachmentsLoader: 0,
    addFabricLoader: 0
};

export default (state = initialData, action) => {
    let newObj = {};

    switch (action.type) {
        // case UPLOAD_CAD_IMAGE_LOADING:
        //     newObj['cadUploadLoader'] = Number(action.percentage);
        //     return Object.assign({}, state, newObj);

        case ATTACHMENTS_UPLOAD_LOADING:
            newObj['attachmentsLoader'] = Number(action.percentage);
            return Object.assign({}, state, newObj);

        case BACKNECK_IMG_UPLOAD_LOADING:
            newObj['addFabricLoader'] = Number(action.percentage);
            return Object.assign({}, state, newObj);

        default:
            return state;
    }
};
