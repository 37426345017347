import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

// Material
import { withStyles, Typography, Grid } from '@material-ui/core';

//Libs
import { GridItem, Input, Select } from '@formComponents';

import {
    COMPOSITIONS,
    FABRIC_TYPES,
    FINISHED_GSMS
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

//Local
import {
    colorsDataSelector,
    labDipColorLoadingSelector,
    isAsosSelector,
    isNinetyPercentSelector,
    batchRefsDataSelector,
    isNewlookSelector,
    isPrimarkSelector
} from 'orders/redux.selectors';
import { FORM_NAME, PO_FORM_NAME } from 'orders/Order/common';

import SelectColor from './SelectColor';
import FOBPrice from './FOBPrice';
import EchoPrice from './EchoPrice';
import LicenseFee from './LicenseFee';
import SelectPaginatedColor from './SelectPaginatedColor';
import SelectBatchConfirmation from './SelectBatchConfirmation';
import SelectOrderSustainability from './SelectOrderSustainability';
import isTBCPO from '../../../isTBCPO';

const mapState = (state, { dip, sampleRef, batchConfirmation }) => {
    const retailer = formValueSelector(FORM_NAME)(state, 'retailer');
    const poNo = formValueSelector(PO_FORM_NAME)(state, 'poNo');
    return {
        priceNotRequired: !poNo || isTBCPO(poNo),
        batchRefs: batchRefsDataSelector(state, sampleRef),
        colors: colorsDataSelector(
            state,
            retailer,
            sampleRef,
            batchConfirmation
        ),
        colorLoading: labDipColorLoadingSelector(state, `${dip}.color-loading`),
        isAsos: isAsosSelector(state),
        isNewlook: isNewlookSelector(state),
        isNinetyPercent: isNinetyPercentSelector(state),
        isPrimark: isPrimarkSelector(state),
        batchConfirmation,
        sampleRef
    };
};

const styles = ({ palette, spacing, shape }) => ({
    container: {
        border: '1px solid',
        borderColor: palette.grey[200],
        borderRadius: shape.borderRadius,
        margin: spacing.unit * 2
    },
    header: {
        display: 'flex',
        padding: `${spacing.unit * 2}px ${spacing.unit}px`,
        backgroundColor: palette.background.light,
        borderTopRightRadius: shape.borderRadius,
        borderTopLeftRadius: shape.borderRadius
    },
    headerText: {
        fontSize: spacing.unit * 1.75,
        '&:not(:last-child)': {
            marginRight: spacing.unit * 2
        }
    },
    content: {
        padding: `${spacing.unit}px 0px ${spacing.unit * 1.5}px`
    },
    spaceAround: {
        paddingLeft: spacing.unit,
        paddingRight: spacing.unit
    }
});

const DipComponent = compose(
    connect(mapState),
    withStyles(styles)
)(
    ({
        classes: c,
        batchRefs,
        colors,
        dip,
        colorLoading,
        isNinetyPercent,
        isNewlook,
        isPrimark,
        isAsos,
        batchConfirmation,
        sampleRef,
        priceNotRequired
    }) => {
        return (
            <div className={c.container}>
                <div className={c.header}>
                    <Typography className={c.headerText}>
                        {sampleRef}
                    </Typography>
                </div>
                <Grid container className={c.content}>
                    {isNinetyPercent && (
                        <GridItem md={2} className={c.spaceAround}>
                            <SelectBatchConfirmation
                                name={`${dip}.batchConfirmation`}
                                required
                                data={batchRefs}
                            />
                        </GridItem>
                    )}
                    <GridItem md={isNewlook ? 3 : 2} className={c.spaceAround}>
                        {isNinetyPercent ? (
                            <SelectColor
                                name={`${dip}.color`}
                                data={colors}
                                required
                                disabled={!batchConfirmation}
                            />
                        ) : (
                            <SelectPaginatedColor
                                name={`${dip}.color`}
                                required
                            />
                        )}
                    </GridItem>
                    <GridItem md={isNewlook ? 3 : 2} className={c.spaceAround}>
                        <Input
                            name={`${dip}.colorCode`}
                            label='Code'
                            loading={colorLoading}
                        />
                    </GridItem>
                    <GridItem md={2} className={c.spaceAround}>
                        <Input
                            name={`${dip}.colorRecipe`}
                            loading={colorLoading}
                            label={isNewlook ? 'Lab Dip' : 'Recipe'}
                            required={!(isNewlook || isPrimark)}
                        />
                    </GridItem>
                    <GridItem md={2} className={c.spaceAround}>
                        <FOBPrice
                            name={`${dip}.fobPrice`}
                            required={!priceNotRequired}
                        />
                    </GridItem>
                    {!isNewlook && (
                        <GridItem md={2} className={c.spaceAround}>
                            <LicenseFee name={`${dip}.licenseFee`} />
                        </GridItem>
                    )}

                    <GridItem md={2} className={c.spaceAround}>
                        <EchoPrice
                            name={`${dip}.echoPrice`}
                            required={!priceNotRequired}
                        />
                    </GridItem>
                    {isAsos && (
                        <>
                            <GridItem md={2} className={c.spaceAround}>
                                <SelectOrderSustainability
                                    name={`${dip}.sustainability`}
                                    required
                                />
                            </GridItem>
                            <GridItem md={2} className={c.spaceAround}>
                                <Select
                                    name={`${dip}.composition`}
                                    label='Composition'
                                    domain={SAMPLING_DOMAIN_NAME}
                                    entity={COMPOSITIONS}
                                    required
                                />
                            </GridItem>
                            <GridItem md={2} className={c.spaceAround}>
                                <Select
                                    name={`${dip}.fabricType`}
                                    label='Fabric Type'
                                    domain={SAMPLING_DOMAIN_NAME}
                                    entity={FABRIC_TYPES}
                                    required
                                />
                            </GridItem>
                            <GridItem md={2} className={c.spaceAround}>
                                <Select
                                    name={`${dip}.gsm`}
                                    label='GSM'
                                    domain={SAMPLING_DOMAIN_NAME}
                                    entity={FINISHED_GSMS}
                                    required
                                />
                            </GridItem>
                        </>
                    )}
                </Grid>
            </div>
        );
    }
);

export default DipComponent;
