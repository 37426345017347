import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { redirectToRoute } from 'navigation/redux.actions';
import { INCOMPLETE_SEAL_SAMPLE_ROUTE_ID } from '../../../SealSample/common';
import { getPreproductionQCData } from '../../../redux.actions';

export default function*({ mode, qcReference, handover }) {
    const message = handover ? 'Handed over' : 'Saved draft';
    yield put(
        setSnack({
            message: `${message}: ${qcReference}`,
            type: 'success'
        })
    );
    if (mode === 'new') {
        yield put(redirectToRoute(INCOMPLETE_SEAL_SAMPLE_ROUTE_ID)); // Route key changed
        yield put(getPreproductionQCData(qcReference));
    }
}
