import React from 'react';
import { CssBaseline } from '@material-ui/core';

import Utilities from '../utilities';

// Local
import Theme from './Theme';
import Router, { history } from './Router';
import ReduxGate from './ReduxGate';
import ErrorBoundary from './ErrorBoundary';

import Auth from '../auth';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export { history };
export default () => (
    <ErrorBoundary>
        <Theme>
            <DndProvider backend={HTML5Backend}>
                <Router>
                    <ReduxGate>
                        <CssBaseline />
                        <Utilities />
                        <Auth />
                    </ReduxGate>
                </Router>
            </DndProvider>
        </Theme>
    </ErrorBoundary>
);
