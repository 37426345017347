import { takeLatest } from 'redux-saga/effects';

import {
    ADD_ARMHOLE_CUFF,
    ADD_HEMLINE,
    ADD_NECKLINE,
    ADD_FIT_TYPE,
    ADD_REQUEST_TYPE,
    ADD_SIZE_RANGE,
    ADD_LABEL_TYPE,
    ADD_SAMPLE_EMAIL_GROUP,
    ADD_SAMPLE_EMAIL_BODY,
    ADD_SAMPLE_FACTORY,
    ADD_SAMPLE_STATUS,
    ADD_SAMPLE_ACTION,
    ADD_SAMPLE_SIZE,
    ADD_CURRIER,
    ADD_DISPATCH_TYPE,
    ADD_DISPATCH_SIZE,
    ADD_DISPATCH_EMAIL_GROUP,
    ADD_DISPATCH_EMAIL_BODY
} from 'setup/redux.actionTypes';

import armholeCuffWorker from './armholeCuffWorker.js';
import hemlineWorker from './hemlineWorker.js';
import necklineWorker from './necklineWorker.js';
import fitTypeWorker from './fitTypeWorker.js';
import requestTypeWorker from './requestTypeWorker.js';
import sizeRangeWorker from './sizeRangeWorker.js';
import labelTypeWorker from './labelTypeWorker.js';
import sampleEmailGroupWorker from './sampleEmailGroupWorker.js';
import sampleEmailBodyWorker from './sampleEmailBodyWorker.js';
import sampleFactoryWorker from './sampleFactoryWorker.js';
import sampleStatusWorker from './sampleStatusWorker.js';
import sampleActionWorker from './sampleActionWorker.js';
import sampleSizeWorker from './sampleSizeWorker.js';

import currierWorker from './currierWorker.js';
import dispatchTypeWorker from './dispatchTypeWorker.js';
import dispatchSizeWorker from './dispatchSizeWorker.js';
import dispatchEmailGroupWorker from './dispatchEmailGroupWorker.js';
import dispatchEmailBodyWorker from './dispatchEmailBodyWorker.js';

// Watchers
export default function*() {
    yield takeLatest(ADD_ARMHOLE_CUFF, armholeCuffWorker);
    yield takeLatest(ADD_HEMLINE, hemlineWorker);
    yield takeLatest(ADD_NECKLINE, necklineWorker);
    yield takeLatest(ADD_FIT_TYPE, fitTypeWorker);
    yield takeLatest(ADD_REQUEST_TYPE, requestTypeWorker);
    yield takeLatest(ADD_SIZE_RANGE, sizeRangeWorker);
    yield takeLatest(ADD_LABEL_TYPE, labelTypeWorker);
    yield takeLatest(ADD_SAMPLE_EMAIL_GROUP, sampleEmailGroupWorker);
    yield takeLatest(ADD_SAMPLE_EMAIL_BODY, sampleEmailBodyWorker);
    yield takeLatest(ADD_SAMPLE_FACTORY, sampleFactoryWorker);
    yield takeLatest(ADD_SAMPLE_STATUS, sampleStatusWorker);
    yield takeLatest(ADD_SAMPLE_ACTION, sampleActionWorker);
    yield takeLatest(ADD_SAMPLE_SIZE, sampleSizeWorker);

    yield takeLatest(ADD_CURRIER, currierWorker);
    yield takeLatest(ADD_DISPATCH_TYPE, dispatchTypeWorker);
    yield takeLatest(ADD_DISPATCH_SIZE, dispatchSizeWorker);
    yield takeLatest(ADD_DISPATCH_EMAIL_GROUP, dispatchEmailGroupWorker);
    yield takeLatest(ADD_DISPATCH_EMAIL_BODY, dispatchEmailBodyWorker);
}
