import { formValueSelector, startSubmit, stopSubmit, change } from 'redux-form';
import { put, call, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';

import { setFabricsLoading } from '../redux.actions';
import { fabricForMainBodyExistsSelector } from '../redux.selectors';
import { normalizeSpecFabrics } from '../redux.normalizers';

export default function*({ payload: { reference, formName } }) {
    const fabrics = yield select(formValueSelector(formName), 'fabrics');
    // Do api call to get fabrics
    if (reference) {
        yield put(startSubmit(formName));
        yield put(setFabricsLoading(true));

        try {
            const { data: { data = [] } = {} } = yield call(apiRequest, {
                url: 'FabricDevelopment/GetFabricByReference',
                params: { fabricReference: reference }
            });

            const mainBodyExists = yield select(
                fabricForMainBodyExistsSelector,
                formName
            );

            const newFabrics = normalizeSpecFabrics(
                data,
                reference,
                mainBodyExists
            );

            yield put(change(formName, 'fabrics', [...fabrics, ...newFabrics]));
        } catch (error) {
            console.log(error);
        }
        yield put(stopSubmit(formName));
        yield put(setFabricsLoading(false));
    } else {
        // Remove the fabrics that have any Fab From
        const filteredFabrics = fabrics.filter(({ fabFrom }) => !fabFrom);
        yield put(change(formName, 'fabrics', filteredFabrics));
    }
}
