import React, { useMemo } from 'react';

import { DESIGN_DEPARTMENT } from 'common/redux.datasets';
import { DOMAIN_NAME } from 'common/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const DesignDepartment = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            {
                label: 'Design Dept',
                canSort: true,
                sortKey: 'designDepartment'
            },
            { label: 'Gender', canSort: true, sortKey: 'gender' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'DESIGN DEPT SETUP',
        domain: DOMAIN_NAME,
        entity: DESIGN_DEPARTMENT,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { DesignDepartment as default, DesignDepartment };
