// Complete
export const ROUTE_TO_NEW = 'cadUpload@route-to-new';
export const ROUTE_TO_AMEND = 'cadUpload@route-to-amend';
export const ROUTE_TO_NEW_SAMPLE = 'cadUpload@route-to-new-sample';

export const SET_CAD_PROPERTY_CHILDREN = 'cadUpload@set-property-children';
export const RESET_LICENSOR_PROPERTY = 'cadUpload@reset-licensor-property';
export const DESTROY_FORM = 'cadUpload@destroy-form';
export const PUBLISH = 'cadUpload@publish';

// Get Data
export const GET_INCOMPLETE_DATA = 'cadUpload@get-incomplete-data';
export const GET_AMEND_DATA = 'cadUpload@get-amend-data';

export const SET_ARTWORK_SOURCE = 'cadUpload@set-artwork-source';

export const SET_CAD_RETAILER_CHILDREN = 'sampling@set-cad-retailer-children';
