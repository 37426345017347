import { useCallback, useMemo, useState } from 'react';

import useStepperHandle from '@libHooks/useStepperHandle';

import Setup from './content/Setup';
import Details from './content/Details';
import Complete from './content/Complete';
import { LABELS, SETUP_FIELDS, INFO_FIELDS } from './common';

const useSampleDispatchDetailsLabel = mode =>
    useMemo(() => {
        switch (mode) {
            case 'incomplete':
                return LABELS.editSampleDispatch;
            case 'amend':
                return LABELS.amendSampleDispatch;
            default:
                return LABELS.details;
        }
    }, [mode]);

const useContent = mode => {
    const sampleDispatchDetails = useSampleDispatchDetailsLabel(mode);
    return useMemo(() => {
        const setup = mode === 'new' ? { [LABELS.setup]: Setup } : {};
        return {
            ...setup,
            [sampleDispatchDetails]: Details,
            [LABELS.complete]: Complete
        };
    }, [mode, sampleDispatchDetails]);
};

const useContentFields = (mode, activeStep) => {
    return useMemo(() => {
        if (mode === 'incomplete' || mode === 'amend') {
            return INFO_FIELDS;
        } else {
            return activeStep === 0 ? SETUP_FIELDS : INFO_FIELDS;
        }
    }, [mode, activeStep]);
};

const useHandles = ({ dispatch, mode, activeStep, publish }) => {
    const amountOfSteps = mode === 'new' ? 3 : 2;
    const [setupLocked, setSetupLocked] = useState(mode !== 'new');

    const reset = useCallback(() => {
        mode === 'new' && setSetupLocked(false);
        dispatch({ type: 'reset' });
    }, [dispatch, mode]);

    const handlePublish = useCallback(
        dispatchProps => {
            return useStepperHandle({
                amountOfSteps,
                dispatch,
                mode,
                publish,
                activeStep: amountOfSteps - 2, // Forces the handle to think it's about to publish,
                dispatchProps
            });
        },
        [dispatch]
    );

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        activeStep,
        publish
    });

    const handleNext = useCallback(() => {
        if (activeStep === 0) setSetupLocked(true);

        handleStepper();
    }, [mode, activeStep, dispatch, amountOfSteps]);
    return [handleNext, handlePublish, setupLocked, reset];
};

export {
    useContent,
    useHandles,
    useContentFields,
    useSampleDispatchDetailsLabel
};
