import { useState, useCallback } from 'react';

const useFieldFilter = () => {
    const [filterValues, setFilterValues] = useState({});

    const handleFilterChange = useCallback(
        (columnKey, value, type, operator) => {
            const columnFilter = filterValues[columnKey];
            const isColumnOperatorInRange =
                columnFilter &&
                ['inrange', 'notinrange'].includes(columnFilter.operator);
            const isNewOperatorInRange = ['inrange', 'notinrange'].includes(
                operator
            );
            const newValue =
                (isNewOperatorInRange && !isColumnOperatorInRange) ||
                (!isNewOperatorInRange && isColumnOperatorInRange)
                    ? ''
                    : value;

            const newValuesAfterFilter = {
                ...filterValues,
                [columnKey]: {
                    value: newValue,
                    type,
                    operator
                }
            };

            setFilterValues(newValuesAfterFilter);
        },
        [filterValues]
    );

    const removeFilter = useCallback(columnKey => {
        setFilterValues(prevFilterValues => {
            const newValuesAfterFilter = { ...prevFilterValues };
            delete newValuesAfterFilter[columnKey];
            return newValuesAfterFilter;
        });
    }, []);

    const removeAllFilters = useCallback(() => {
        setFilterValues({});
    }, []);

    return {
        filterValues,
        handleFilterChange,
        removeFilter,
        removeAllFilters
    };
};

export default useFieldFilter;
