import React, { useMemo } from 'react';

import { DOMAIN_NAME } from 'sampling/common';
import { SAMPLE_EMAIL_BODY } from 'sampling/redux.datasets';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const SampleEmailBody = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Body Name', canSort: true, sortKey: 'emailBodyName' },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            {
                label: 'Body',
                canSort: false
            },
            { label: 'Is Final Photo', canSort: true, sortKey: 'isFinalPhoto' },
            { label: 'Is Hand Over', canSort: true, sortKey: 'isHandOver' },
            { label: 'Is FD', canSort: true, sortKey: 'isFD' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'SAMPLE EMAIL BODY SETUP',
        domain: DOMAIN_NAME,
        entity: SAMPLE_EMAIL_BODY,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { SampleEmailBody as default, SampleEmailBody };
