import { useMemo } from 'react';

const useValidate = (values, schemas) =>
    useMemo(
        () =>
            // Returns an object with the same property names as schema
            Object.entries(schemas).reduce((acm, [label, schema]) => {
                /*
                    Checks all the fields inside a specific schema and validates into
                    an array of booleans pertaining to the field indices
                */
                const allValid = Object.entries(schema).reduce(
                    (acm, [field, validator]) => {
                        // Base use case, simply checks if the field is filled
                        if (typeof validator === 'boolean')
                            return [
                                ...acm,
                                values.hasOwnProperty(field) === validator,
                            ];
                        // When a custom validator if provided
                        else if (typeof validator === 'function')
                            return [...acm, validator(values[field])];
                        // Defaults to invalid
                        return [...acm, false];
                    },
                    []
                );

                // Checks if all fields in this section is valid
                const isValid = !allValid.some((valid) => !valid);

                return { ...acm, [label]: isValid };
            }, {}),
        [values]
    );

const useNext = ({ activeStep, contents }, areValid) =>
    useMemo(() => {
        const { touched: nextTouched = false } = contents[activeStep + 1] || {};

        return (
            // Checks if has already been to the next step
            nextTouched ||
            // If not, checks if all previous steps are still valid
            Object.values(areValid)
                .slice(0, activeStep + 1)
                .every((valid) => !!valid)
        );
    }, [contents, areValid]);

export { useValidate as default, useValidate, useNext };
