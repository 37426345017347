import { takeLatest, put, call } from 'redux-saga/effects';
import { change } from 'redux-form';

import { apiRequest } from '@libs/apiRequest';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';
import { setSnack } from 'utilities/redux.actions';
import { isoDate } from '@libs/date';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';

import { FETCH_SAMPLING_REPORT_DATA } from 'sampling/redux.actionTypes';
import {
    setSamplingReportData,
    setSamplingReportDataLoading
} from 'sampling/redux.actions';
import { FORM_NAME } from 'sampling/SamplingReport/common';

// Workers

function* fetchSamplingReportDataWorker(action) {
    const { payload: { startDate, endDate } = {} } = action;

    try {
        // prepare payload
        const payload = {
            startDt: isoDate(deserializeDate(startDate)),
            endDt: isoDate(deserializeDate(endDate), false)
        };
        yield put(setSamplingReportDataLoading(true));
        const { data } = yield call(apiRequest, {
            method: 'get',
            url: 'Sample/GetSampleReport',
            params: payload
        });

        yield put(setSamplingReportData(data));
        yield put(change(FORM_NAME, 'retailer', ''));
        yield put(setSamplingReportDataLoading(false));
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(
            setSnack({
                message: `Failed to retrieve data ${serverMessage}`,
                type: 'error',
                duration: 6000,
                action: {
                    label: 'Retry',
                    handle: action
                }
            })
        );
        yield put(setSamplingReportDataLoading(false));
    }
}

// Watchers
export default function*() {
    yield takeLatest(FETCH_SAMPLING_REPORT_DATA, fetchSamplingReportDataWorker);
}
