import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { useRowStyle } from './hooks';

const propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    lines: PropTypes.number.isRequired,
    isLast: PropTypes.bool.isRequired
};

const styles = ({ spacing, palette }) => ({
    tableRow: {
        flexDirection: 'row',
        width: '100%',
        height: spacing.unit * 3.75,

        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderBottomWidth: 1
    },
    tableHeader: {
        width: '41%',
        height: spacing.unit * 3.75,
        flexDirection: 'row',
        paddingLeft: spacing.unit * 1.75,
        alignItems: 'center'
    },
    tableCellText: { fontSize: 12 },
    tableCellRequired: {
        color: palette.error.main,
        marginLeft: spacing.unit * 0.5
    },
    tableCell: {
        width: '59%',
        minHeight: 0,
        paddingLeft: spacing.unit * 1.75,
        paddingRight: spacing.unit * 1.75,
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderLeftWidth: 1
    }
});

const ReportTableRow = withPDFStyles(styles, { withTheme: true })(
    ({ classes, theme, isLast, label, value, required, lines }) => {
        const c = useRowStyle({ classes, theme, isLast, lines });

        return (
            <View style={c.tableRow}>
                <View style={c.tableHeader}>
                    <Text style={c.tableCellText}>{label}</Text>
                    {required && <Text style={c.tableCellRequired}>*</Text>}
                </View>
                <View style={c.tableCell}>
                    <Text style={c.tableCellText}>{value}</Text>
                </View>
            </View>
        );
    }
);

ReportTableRow.propTypes = propTypes;

export default ReportTableRow;
