import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    isSubmitting,
    initialize as formInitialize,
    isDirty
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import { useValidate, useNext } from '@libHooks/useValidate';
import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import { withModalProvider, withModal } from '@libComponents/Modal';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';
import { formErrorsSelector } from 'common/redux.selectors';

import { OrderProvider } from './useOrder';
import { useHandles } from './hooks';
import { FORM_NAME, INITIAL_VALUES, ORDER_FORM_FIELDS } from './common';
import useSchema from './content/useSchema';
import {
    orderSaveDraft,
    orderPublish,
    destroyOrderForm,
    orderStatusChange,
    orderSoftPublish
} from '../redux.actions';
import Preview from './components/Report/Preview';
import { sampleRefChangeDataLoadingSelector } from '../redux.selectors';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => {
    const formErrors = formErrorsSelector(state, FORM_NAME);
    const formValues = getFormValues(FORM_NAME)(state) || {};
    return {
        formSubmitting: isSubmitting(FORM_NAME)(state),
        isFormDirty: isDirty(FORM_NAME)(state),
        sampleRefChangeDataLoading: sampleRefChangeDataLoadingSelector(state),
        formValues,
        formErrors
    };
};

const mapDispatch = {
    publish: orderPublish,
    saveDraft: orderSaveDraft,
    statusChange: orderStatusChange,
    destroyForm: destroyOrderForm,
    formInitialize,
    softPublish: orderSoftPublish
};

const OrderContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        mode,
        destroyForm,
        publish,
        saveDraft,
        statusChange,
        handleModal,
        handleReduxFormSubmit,
        formInitialize,
        softPublish,
        isFormDirty,
        sampleRefChangeDataLoading
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const [orderFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            ORDER_FORM_FIELDS[activeStep],
            FORM_NAME
        );

        const handleOrderFormSubmit = useCallback(() => {
            handleReduxFormSubmit();
            handleScroll();
        }, [handleReduxFormSubmit, handleScroll]);

        const {
            echoOrderNo,
            tbcOrder,
            isPORemoved = false,
            isCanceledOrHeld
        } = formValues;

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const schema = useSchema(mode, formValues);
        const [handleNext, handlePublish, setupLocked, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish
        });

        const areValid = useValidate(formValues, schema, formErrors);

        const canProceed = useNext(state, areValid);

        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleOrderFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep, handleOrderFormSubmit]);

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => {
                destroyForm();
            };
        }, [mode]);

        const shouldFetchData = useMemo(
            () => mode !== 'new' && activeStep === 0,
            [mode, activeStep]
        );
        const shouldCancel = useMemo(() => mode !== 'new', [mode]);
        const shouldPublish = useMemo(
            () => !(mode === 'new' && [0, 1].includes(activeStep)),
            [mode, activeStep]
        );
        const shouldSave = useMemo(() => mode !== 'amend' && activeStep !== 0, [
            mode,
            activeStep
        ]);
        const shouldSoftPublish =
            mode === 'amend' && activeStep !== 0 && !isCanceledOrHeld;
        const shouldStatusChange =
            mode === 'amend' && activeStep !== 0 && !isCanceledOrHeld;

        const shouldPreview = useMemo(() => {
            return activeStep === contents.length - 2;
        }, [activeStep, contents.length]);

        const canSoftPublish =
            areValid.publish && !isPORemoved && !sampleRefChangeDataLoading;

        const canStatusChange =
            areValid.publish &&
            !!echoOrderNo &&
            !tbcOrder &&
            !sampleRefChangeDataLoading;

        const canResetForm = useMemo(() => {
            return !!Object.keys(formValues).length;
        }, [formValues]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        const handleSave = useCallback(() => saveDraft({ mode }), [
            mode,
            saveDraft
        ]);

        const handleSoftPublish = useCallback(() => softPublish({ mode }), [
            mode,
            softPublish
        ]);

        const publishHandlerInPreview = useCallback(
            props => {
                if (!canProceed || !areValid.saveDraft) {
                    handleOrderFormSubmit();
                    return;
                }
                handleModal({ ...props, handlePublish, mode });
            },
            [handleModal, canProceed, handleOrderFormSubmit, areValid.saveDraft]
        );

        const handleCancel = useCallback(
            dispatchProps => {
                return () =>
                    statusChange({
                        mode,
                        handleComplete: () => reset(),
                        isCancel: true,
                        ...dispatchProps
                    });
            },
            [dispatch]
        );

        const handleHold = useCallback(
            dispatchProps => {
                return () =>
                    statusChange({
                        mode,
                        handleComplete: () => reset(),
                        isHold: true,
                        ...dispatchProps
                    });
            },
            [dispatch]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                mode,
                reset,
                handleSave,
                handlePublish,
                shouldCancel,
                shouldPublish,
                shouldSave,
                shouldFetchData,
                setupLocked,
                shouldPreview,
                handlePreviewModal: publishHandlerInPreview,
                handleOrderFormSubmit,
                shouldStatusChange,
                canStatusChange,
                handleHold,
                handleCancel,
                canSoftPublish,
                handleSoftPublish,
                shouldSoftPublish,
                canResetForm
            }),
            [
                areValid.saveDraft,
                areValid.publish,
                mode,
                shouldCancel,
                shouldPublish,
                shouldSave,
                shouldPreview,
                shouldFetchData,
                shouldStatusChange,
                canStatusChange,
                handleHold,
                handleCancel,
                setupLocked,
                handleSoftPublish,
                canSoftPublish,
                shouldSoftPublish,
                reset,
                publishHandlerInPreview,
                isFormDirty,
                handleOrderFormSubmit,
                canResetForm
            ]
        );

        return (
            <Paper className={c.container}>
                <OrderProvider initialize={initialize}>
                    <div ref={orderFormRef}>{children}</div>
                </OrderProvider>
                {!complete &&
                    (shouldPreview ? (
                        <Button
                            className={c.button}
                            onClick={publishHandlerInPreview}
                        >
                            Preview
                        </Button>
                    ) : (
                        <Button
                            className={c.button}
                            onClick={handleNextWithSubmit}
                            loading={formSubmitting}
                        >
                            {proceedText}
                        </Button>
                    ))}
            </Paper>
        );
    }
);

export default OrderContainer;
