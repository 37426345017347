import React, {
    useState,
    useEffect,
    useRef,
    Fragment,
    useCallback,
    useMemo
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.css';
import { FormHelperText } from '@material-ui/core';

import { withField } from '@libs/reduxForm';

const blankHTML = '<p><br></p>';

const removeImageTags = html => {
    return html && html.replace(/<img\b[^>]*>/gi, '');
};

const Editor = withField({
    parse: value => {
        if (value === blankHTML) return '';
        return removeImageTags(value);
    }
})(
    ({
        input: { value, onChange, onBlur } = {},
        meta: { touched, error, form },
        theme: { palette },
        readOnly,
        fieldName,
        ...restProps
    }) => {
        const hasError = touched && error;
        const prevValue = useRef(value);
        const [text, setText] = useState(value || '');
        const [timer, setTimer] = useState(void 0);
        const id = `${form}-${fieldName}`;

        const handleChange = useCallback(
            text => {
                setText(text);
                clearTimeout(timer);
                const localTimer = setTimeout(() => {
                    prevValue.current = text;
                    onChange(text);
                    onBlur();
                }, 250);
                setTimer(localTimer);
            },
            [timer]
        );

        const stylesVariable = useMemo(
            () => ({
                '--editorBorderColor': hasError
                    ? palette.error.main
                    : palette.background.dark,
                '--editorBGColor': readOnly
                    ? palette.background.light
                    : 'inherit',
                '--editorCursor': readOnly ? 'not-allowed' : 'auto'
            }),
            [hasError, readOnly, palette]
        );

        useEffect(() => {
            if (prevValue.current !== value) {
                setText(value);
            }
        }, [value]);
        return (
            <Fragment>
                <ReactQuill
                    style={stylesVariable}
                    value={text}
                    onChange={handleChange}
                    readOnly={readOnly}
                    id={id}
                    {...restProps}
                />
                {hasError && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                )}
            </Fragment>
        );
    }
);

export { Editor as default, Editor };
