import createMiddleware from './middleware';
import formListener from './formListener';

const middleware = createMiddleware();

const formMiddleware = middleware.middleware;
const addListeners = middleware.addListeners;
const removeListeners = middleware.removeListeners;

export {
    formMiddleware as default,
    formMiddleware,
    addListeners,
    removeListeners,
    formListener
};
