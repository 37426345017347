import React from 'react';
import { string } from 'prop-types';
import { View, Image as PDFImage, Text } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { FONT_FAMILY_BOLD } from 'common/constants';

const propTypes = {
    url: string,
    title: string.isRequired
};

const styles = ({ spacing, palette }) => ({
    container: {
        width: '32%',
        marginTop: spacing.unit * 0.5
    },
    title: {
        color: palette.secondary.main,
        fontSize: spacing.unit * 1.5,
        marginBottom: spacing.unit * 0.5,
        fontFamily: FONT_FAMILY_BOLD
    },
    imageWrapper: {
        width: '100%',
        height: spacing.unit * 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        borderColor: palette.grey[300]
    },
    finalImageWrapper: {
        height: spacing.unit * 20
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
});

const Image = withPDFStyles(styles)(
    ({ classes: c, url, title, styles = {} }) => {
        return (
            <View style={{ ...c.container, ...styles }}>
                <Text style={c.title}>{title}:</Text>
                <View
                    style={{
                        ...c.imageWrapper,
                        ...(title === 'Final Image' ? c.finalImageWrapper : {})
                    }}
                >
                    {url && <PDFImage src={url} style={c.image} alt={title} />}
                </View>
            </View>
        );
    }
);

Image.propTypes = propTypes;

export default Image;
