export default ({ spacing, palette, shape }) => ({
    root: {
        position: 'relative',
        marginTop: spacing.unit * 4,
        marginBottom: 0,
    },
    select: {
        height: spacing.unit * 5,
        width: spacing.unit * 30,
        borderRadius: shape.borderRadius / 2,
    },
    editor: {
        // Toolbar
        '& > .ql-toolbar': {
            backgroundColor: palette.grey[200],
            height: spacing.unit * 6
        },

        // Editor
        '& > .ql-container': {
            height: spacing.unit * 40
        }
    },

});