import React from 'react';

// Material
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// Local
import palette from './palette';
import overrides from './overrides';
import spacing from './spacing';
import transitions from './transitions';
import typography from './typography';

// TODO: Revise 'LEGACY'
export const theme = createMuiTheme({
    palette,
    overrides,
    spacing,
    transitions,
    typography
});

export default ({ children }) => (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
