import { useMemo, useCallback } from 'react';
import {
    denormalizeDate,
    deserializeDate,
    normalizeDate
} from '@baseComponents/Date/normalizeDate';

const useDateRangeNavigation = (startDate, endDate, changeDate) => {
    const endDateMax = useMemo(() => {
        const date = new Date(deserializeDate(startDate));
        date.setDate(date.getDate() + 90);
        return date;
    }, [startDate]);

    const navigateDates = useCallback(
        direction => {
            const start = new Date(denormalizeDate(startDate));
            const end = new Date(denormalizeDate(endDate));
            const diff = end - start;

            const newStartDate = new Date(
                start.setDate(
                    start.getDate() + direction * (diff / (1000 * 60 * 60 * 24))
                )
            );
            const newEndDate = new Date(
                end.setDate(
                    end.getDate() + direction * (diff / (1000 * 60 * 60 * 24))
                )
            );

            changeDate(normalizeDate(newStartDate), normalizeDate(newEndDate));
        },
        [startDate, endDate, changeDate]
    );

    const isNextDisabled = useMemo(() => {
        const currentDate = new Date();
        const parsedEndDate = new Date(deserializeDate(endDate));

        // Set time to 00:00:00 to compare only date part
        parsedEndDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        return parsedEndDate >= currentDate;
    }, [endDate]);

    return { endDateMax, navigateDates, isNextDisabled };
};

export default useDateRangeNavigation;
