export default theme => ({
    root: {
        width: '100%',
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: 0
    },
    button: {
        marginRight: theme.spacing
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing,
        marginBottom: theme.spacing
    },
    connectorLine: {
        transition: theme.transitions.create('border-color')
    },
    connectorActive: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#8fc255',
            borderTopWidth: '4px'
        }
    },
    connectorCompleted: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#8fc255',
            borderTopWidth: '4px'
        }
    },
    connectorDisabled: {
        left: 0,
        right: 0,
        top: 10,
        '& $connectorLine': {
            borderColor: '#cccccc',
            borderTopWidth: '4px'
        }
    },
    firstStep: {
        display: 'none'
    },
    visitedStep: {
        '& $connectorLine': {
            borderColor: '#8fc255' //#c2dca7
        }
    },
    stepRoot: {
        color: '#cccccc'
    },
    stepActive: {
        color: '#8fc255 !important'
    },
    stepCompleted: {
        color: '#8fc255 !important',
        backgroundColor: '#f5f5f5'
    },
    stepLabelContainer: {
        maxWidth: '130px'
    },
    multiLabel: {
        position: 'relative',
        zIndex: 2
    },
    multiLabelVisited: {
        cursor: 'pointer !important',
        '& svg': {
            color: '#8fc255 !important'
        }
    }
});
