import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';

import { theme } from 'app/Theme';

const withStylesFactory = theme => (
    styles = () => ({}),
    { withTheme = false } = {}
) => Component => props => {
    const hocProps = {
        classes: StyleSheet.create(styles(theme)),
        ...(withTheme ? { theme } : {})
    };
    return <Component {...props} {...hocProps} />;
};

const withPDFStyles = withStylesFactory(theme);

export { withStylesFactory as default, withStylesFactory, withPDFStyles };
