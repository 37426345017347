import React, { Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridItem, Input } from '@formComponents';
import { withField } from '@libs/reduxForm';
import { Error } from 'components/Message';

const propTypes = {
    name: PropTypes.string
};

const defaultProps = {
    name: 'bulkProductionUnit'
};

const styles = ({ spacing, palette }) => ({
    contentContainer: {
        width: '64%',
        marginBottom: spacing.unit * 5
    },
    container: {
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2
    },
    error: {
        borderColor: palette.error.main
    }
});

const BulkProductionContainer = compose(
    withField(),
    withStyles(styles)
)(({ classes: c, meta: { touched, error } }) => {
    const hasError = touched && error;
    return (
        <div className={c.contentContainer}>
            <ContentContainer
                title={`Bulk Production Process Flow`}
                AdornmentComponent={<Fragment />}
                styles={{ ...(hasError ? { container: c.error } : {}) }}
            >
                <Grid container className={c.container}>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.one'
                            label=''
                            adornment='1'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.two'
                            label=''
                            adornment='2'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.three'
                            label=''
                            adornment='3'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.four'
                            label=''
                            adornment='4'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.five'
                            label=''
                            adornment='5'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.six'
                            label=''
                            adornment='6'
                            position='start'
                        />
                    </GridItem>
                    <GridItem md={3}>
                        <Input
                            name='bulkProductionUnit.seven'
                            label=''
                            adornment='7'
                            position='start'
                        />
                    </GridItem>
                </Grid>
            </ContentContainer>
            {hasError && <Error message={error} />}
        </div>
    );
});

BulkProductionContainer.propTypes = propTypes;
BulkProductionContainer.defaultProps = defaultProps;

export { BulkProductionContainer as default, BulkProductionContainer };
