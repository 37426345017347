import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import {
    withStyles,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper
} from '@material-ui/core';

import { frequentlyUsedRoutesSelector } from 'navigation/redux.selectors';

const styles = ({ spacing, palette }) => ({
    root: {
        padding: spacing.unit * 3,
        marginTop: spacing.unit * 3
    },
    paper: {
        padding: spacing.unit * 2,
        marginTop: spacing.unit * 2
    },
    link: {
        textDecoration: 'none',
        width: '100%',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    linkText: {
        color: palette.primary.main,
        '&:hover': {
            color: palette.secondary.main
        }
    }
});

const mapStateToProps = state => ({
    routes: frequentlyUsedRoutesSelector(state)
});

const Home = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(({ classes: c, routes }) => {
    return (
        <div className={c.root}>
            <Typography variant='h4' gutterBottom>
                Welcome to the Echosys
            </Typography>

            {routes.length > 0 && (
                <Paper className={c.paper}>
                    <Typography variant='h6' gutterBottom>
                        Quick Access Links
                    </Typography>

                    <List>
                        {routes.map(route => (
                            <ListItem key={route.id}>
                                <Link to={route.label} className={c.link}>
                                    <ListItemText
                                        primary={
                                            <Typography className={c.linkText}>
                                                {route.name}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </div>
    );
});

export default Home;
