export { DOMAIN_NAME } from '../common';
const FORM_NAME = 'finalPhoto';

const LABELS = {
    upload: 'Upload Photo',
    complete: 'Complete!'
};

const INITIAL_VALUES = {
    sampleRef: 'PET-P-0135',
    developmentNo: 'New',
    department: 'Mens',
    buyer: 'Primark'
};

const FINAL_PHOTO_UPLOAD_ROUTE_ID = 617;

export { FORM_NAME, LABELS, INITIAL_VALUES, FINAL_PHOTO_UPLOAD_ROUTE_ID };
