import { useState, useEffect } from 'react';
import axios from 'axios';

import isBlobUrl from '@libs/isBlobUrl';

export default url => {
    const canDownload = url && !isBlobUrl(url);

    const [blobUrl, setBlobUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let subscribed = true;
        if (canDownload)
            (async () => {
                const { data } = await axios.get(url, {
                    responseType: 'blob',
                    headers: {
                        'Cache-Control': 'no-cache',
                        Authorization: ''
                    }
                });
                if (subscribed) {
                    setBlobUrl(URL.createObjectURL(data));
                    setLoading(false);
                }
            })();
        else setBlobUrl(url);

        return () => {
            subscribed = false;
        };
    }, [canDownload, url]);

    useEffect(() => {
        return () => {
            canDownload && URL.revokeObjectURL(blobUrl);
        };
    }, [blobUrl]);

    return [blobUrl, loading, canDownload];
};
