import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Material
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button } from '@material-ui/core';

// Actions
import { fetchEmailDataRequest } from 'store/actions';

// Local
import styles from './styles';
import Checkbox from './Checkbox/index';
import SelectFields from './SelectFields';
import { withModal } from 'components';
import ReportsMailForm from 'views/Reports/ReportsMailForm';

class ReportsSamplingAppbar extends Component {
    render() {
        const {
            classes: {
                queryContainer,
                queryBar,
                sampleRefLabel,
                sampleRefSelect,
                checkbox,
                checkboxLabel,
                mail,
                mailButton
            },
            sampleRef,
            pdfReportId,
            handleReportsMailForm,
            fetchEmailData
        } = this.props;
        return (
            <Paper square className={queryContainer}>
                <Grid
                    container
                    spacing={8}
                    className={queryBar}
                    alignItems='center'
                >
                    <Grid item>
                        <Typography
                            className={sampleRefLabel}
                            variant='subtitle1'
                            color='inherit'
                        >
                            Sample Ref:
                        </Typography>
                    </Grid>
                    <Grid item md={4} className={sampleRefSelect}>
                        <SelectFields
                            placeholder={
                                sampleRef || 'Please select sample ref'
                            }
                            data={['arstarstrasats', 'arstarstrsatarstarsta']}
                            value={''}
                        />
                    </Grid>
                    <Grid item md={3} className={checkbox}>
                        <Checkbox
                            label='Add Spec Documents'
                            labelStyle={checkboxLabel}
                        />
                    </Grid>
                    <Grid item md={2} className={mail}>
                        <Button
                            color='primary'
                            variant='contained'
                            size='large'
                            classes={{ root: mailButton }}
                            onClick={() => {
                                fetchEmailData(pdfReportId);
                                handleReportsMailForm();
                            }}
                        >
                            Mail
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const mapStateToProps = ({ reports: { moduleRef, pdfReportId } }) => ({
    sampleRef: moduleRef,
    pdfReportId
});

const mapDispatchToProps = dispatch => ({
    fetchEmailData: reportId =>
        dispatch(fetchEmailDataRequest({ reportId, module: 'sample' }))
});

const mapModalsToProps = {
    handleReportsMailForm: ReportsMailForm
};

const _ReportsSamplingAppbar = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withModal(mapModalsToProps)
)(ReportsSamplingAppbar);

export {
    _ReportsSamplingAppbar as default,
    _ReportsSamplingAppbar as ReportsSamplingAppbar
};
