import { call } from 'redux-saga/effects';

// Libs
import { apiRequest } from '@libs/apiRequest';
import {
    publishFailedWorker,
    composeLoadingWorker
} from '@libs/publishWorkers';
import composeApiPayload from '@libs/composeApiPayload';

import getPublishPayloadWorker from './getPublishPayloadWorker';
import welcomeMailSendWorker from './welcomeMailSendWorker';
import { publishSchema } from './schemas';

// Workers
export default function*(action) {
    const {
        meta: { handleComplete = () => {} },
        payload: { mode }
    } = action;

    const handleLoading = yield call(composeLoadingWorker, {
        mode,
        assets: []
    });

    try {
        const payload = yield call(getPublishPayloadWorker, mode);

        const publishData = composeApiPayload(payload, publishSchema);

        const { data: { name, email, token } = {} } = yield call(apiRequest, {
            method: 'post',
            url: 'User/UserCreation',
            data: publishData
        });

        yield call(handleLoading);

        /*
            --- After publish
         */

        if (mode === 'new') {
            yield call(welcomeMailSendWorker, { name, email, token });
        }

        yield call(handleLoading, true);

        return handleComplete();
    } catch (error) {
        let actionErrorMessage = 'Failed to publish';

        yield call(publishFailedWorker, {
            action,
            handleLoading,
            actionErrorMessage,
            error
        });
    }
}
