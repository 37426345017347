export default ({ breakpoints, spacing }) => ({
    header: {
      paddingLeft: spacing.unit * 4
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4,
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 4
    },
    send: {
        width: spacing.unit * 18,
        height: spacing.unit * 6
    },
    // --------------------
    mailForm: {
        width: breakpoints.values.md,
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4
    }
});