import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { Image as PDFImage } from '@libComponents/ReactPDF';
import { withPDFStyles } from '@libComponents/ReactPDF';
import { useImageStyles } from './hooks';

const styles = ({ palette }) => ({
    container: {},
    imageContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    image: {
        width: 'auto',
        objectFit: 'contain'
    },
    labelContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    label: {}
});

const Image = withPDFStyles(styles, { withTheme: true })(
    ({ classes, theme, url, header }) => {
        const c = useImageStyles({ classes, theme });
        return (
            <View style={c.container}>
                <View style={c.imageContainer}>
                    {url && <PDFImage style={c.image} src={url} />}
                </View>
                <View style={c.labelContainer}>
                    <Text style={c.label}>{header}</Text>
                </View>
            </View>
        );
    }
);

export default Image;
