import _ from 'lodash/array';
import { CHANGE } from 'redux-form/lib/actionTypes';

const createMiddleware = () => {
    const listeners = [];

    return {
        addListeners: newListeners => listeners.push(...newListeners),
        removeListeners: oldListeners =>
            _.remove(listeners, ({ id }) => {
                return oldListeners.includes(id);
            }),
        middleware: listener(listeners)
    };
};

const listener = listeners => ({ dispatch }) => next => action => {
    if (action.type === CHANGE) {
        const inListeners = listeners.filter(({ form, field }) => {
            return form === action.meta.form && field === action.meta.field;
        });

        if (inListeners.length) {
            const actionObject = inListeners[0].action(action);
            // Checks if its a real action
            if (actionObject) dispatch(actionObject)
        }
    }

    return next(action);
};

export { createMiddleware as default, createMiddleware };
