import { formValueSelector } from 'redux-form';
import { select, call, all, fork } from 'redux-saga/effects';

import { uploadAssetsWorker, getAssetBlobsWorker } from '@libs/publishWorkers';

import { sizePathsSelector } from 'common/redux.selectors';
import { userNameSelector, userEmailSelector } from 'auth/redux.selectors';
import { syncReportToDatabase } from 'common/redux.sagas';
import composeApiPayload from '@libs/composeApiPayload';
import { apiRequest } from '@libs/apiRequest';
import { updateCcAddress } from '@libs/mailer';

import { FORM_NAME } from 'sampling/SampleDispatch/common';
import { dispatchEmailSchema } from './schema';

export default function*({ dispatchRef, versionNo, mode, handleLoading }) {
    try {
        const {
            pdf,
            receivers: toAddress,
            cc: ccAddress,
            subject,
            body
        } = yield select(formValueSelector(FORM_NAME), 'mailData');

        const [userEmail, userName, reportBlobs, reportSizePaths] = yield all([
            select(userEmailSelector),
            select(userNameSelector),
            call(getAssetBlobsWorker, {
                assets: pdf,
                mode,
                handleLoading
            }),
            select(sizePathsSelector, 'reportPath')
        ]);

        /* Upload to s3 bucket */
        const [reportPayload] = yield call(uploadAssetsWorker, {
            assetBlobs: reportBlobs,
            folderPath: `${reportSizePaths.sizeOriginal}/${dispatchRef}`,
            reference: dispatchRef,
            sizePaths: reportSizePaths,
            handleLoading,
            developmentName: `V${versionNo}`
        });

        const originalPath = reportPayload ? reportPayload.sizeOriginal : '';

        const reportUrl = yield call(syncReportToDatabase, {
            reportReference: dispatchRef,
            reportUrl: originalPath,
            referenceDevNo: versionNo
        });

        const recipients = toAddress.replace(/\s/g, '');

        const updatedCcAddress = updateCcAddress(
            ccAddress,
            userEmail,
            recipients
        );

        const emailPayload = composeApiPayload(
            {
                userName,
                toAddress: recipients,
                ccAddress: updatedCcAddress,
                subject,
                body,
                dispatchRef,
                attachment: reportUrl
            },
            dispatchEmailSchema
        );

        yield fork(apiRequest, {
            method: 'post',
            url: 'EmailSend/DispatchEmailSend',
            data: emailPayload
        });
    } catch (err) {
        console.log('error in report worker', err);
    }
}
