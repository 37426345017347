import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets
} from '@libs/datasets';
import { Input } from '@formComponents';

import { DOMAIN_NAME } from 'sampling/common';
import { DESIGN_SOURCES } from '../redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'designSource',
    label: 'Where is the design from',
    placeholder: ' ',
    disabled: true,
    required: true
};

const mapState = (state, { form }) => {
    const designSource = formValueSelector(form)(state, 'designSource');
    return {
        staticValue: valueSelector(
            state,
            DOMAIN_NAME,
            DESIGN_SOURCES,
            designSource
        ),
        loading: datasetLoadingSelector(state, DOMAIN_NAME, DESIGN_SOURCES)
    };
};

const mapDispatch = {
    fetchDatasets
};

const DesignSource = connect(
    mapState,
    mapDispatch
)(({ fetchDatasets, ...props }) => {
    useEffect(() => {
        fetchDatasets(DESIGN_SOURCES);
    }, []);

    return <Input {...props} />;
});

DesignSource.propTypes = propTypes;
DesignSource.defaultProps = defaultProps;

export { DesignSource as default, DesignSource };
