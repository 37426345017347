// Action Types
import {
    SET_SAMPLE_REFERENCES // Todo: undo later when making multicolumn
} from 'store/actionTypes';

const referenceReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SET_SAMPLE_REFERENCES: {
            const { sampleReferences } = payload;
            return { ...state, sampleReferences };
        }

        default: {
            return state;
        }
    }
};

export { referenceReducer as default, referenceReducer };
