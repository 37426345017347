import React, { useCallback } from 'react';

import Search from '@libComponents/Search';

import useManageColumnsContext from '../../../useManageColumnsContext';

const ModalContentColumnSearch = () => {
    const { state, dispatch } = useManageColumnsContext();

    const handleSearch = useCallback(query => {
        dispatch({ type: 'SEARCH_COLUMN', payload: query });
    }, []);

    return <Search value={state.searchQuery || ''} makeSearch={handleSearch} />;
};

export default ModalContentColumnSearch;
