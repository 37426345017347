import React, { useMemo } from 'react';

import { DISPATCH_TYPE } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const DispatchType = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Type', canSort: true, sortKey: 'type' },
            { label: 'Is Manual', canSort: true, sortKey: 'isManual' },
            { label: 'Is Order', canSort: true, sortKey: 'IsOrder' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'DISPATCH TYPE SETUP',
        domain: DOMAIN_NAME,
        entity: DISPATCH_TYPE,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { DispatchType as default, DispatchType };
