import { useCallback, useEffect, useState } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';
import getDataUrl from '@libs/getDataUrl';

import PDFDocument from './PDFDocument';

const loadProps = async rows => {
    const loadedProps = await Promise.all(
        rows.map(async ({ cad, ...row }) => {
            return {
                ...row,
                cadImage:
                    cad[0] && cad[0].size350
                        ? await getDataUrl(cad[0].size350)
                        : null
            };
        })
    );

    // Each pdf page there will have 3 items.
    const pages = [...Array(Math.ceil(loadedProps.length / 3))];
    const pageItems = pages.map((page, idx) =>
        loadedProps.slice(idx * 3, idx * 3 + 3)
    );

    return {
        pages: pageItems
    };
};

export const useCadPDF = ({ data, setSnack }) => {
    const [pdfError, setPDFError] = useState(null);
    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document: PDFDocument,
        fileName: 'cads.pdf',
        props: data,
        loadProps,
        errorResolver: error => setPDFError(error),
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 6000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    const handlePDFExport = useCallback(() => {
        if (data.length === 0) {
            setSnack({
                message: `Please select a row to download.`,
                type: 'error',
                duration: 6000
            });
            return;
        }

        handleExport();
    }, [data, setSnack, handleExport]);

    return [handlePDFExport, exportLoading, exportDisabled];
};
