import React, { useMemo } from 'react';

import {
    visibleColumnHeadersSelector,
    tableColumnsSelector,
    rowsSelector
} from 'gridView/customSelectors';
import DataTable from './DataTable';
import useGridView from 'gridView/useGridView';

const ListView = () => {
    const [
        {
            data,
            selectedItems: { rowIds },
            expanded,
            dataLinkable,
            moduleName,
            disableCheckbox,
            disableEditIcon
        },
        dispatch
    ] = useGridView();
    const isExpandable = useMemo(() => Object.entries(data[0]).length > 12, [
        data
    ]);
    const headers = visibleColumnHeadersSelector(data[0]);
    const columns = tableColumnsSelector(headers, {
        isExpandable,
        moduleName,
        disableCheckbox,
        disableEditIcon
    });
    const rows = rowsSelector(data, dataLinkable);

    return (
        <DataTable
            data={rows}
            columns={columns}
            selectedRowIds={rowIds}
            dispatch={dispatch}
            expandedRow={expanded}
        />
    );
};

export { ListView as default, ListView };
