import { put } from 'redux-saga/effects';

import { setSnack } from 'utilities/redux.actions';
import { routeToPrint } from '../../../redux.actions';

export default function*({ mode, sampleReference }) {
    yield put(routeToPrint(mode));
    yield put(
        setSnack({
            message: `Handed over: ${sampleReference}`,
            type: 'success'
        })
    );
}
