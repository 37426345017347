import React, { Fragment } from 'react';
import clsx from 'classnames';

// Material
import { withStyles, FormLabel } from '@material-ui/core';

const styles = ({ spacing, palette, typography }) => ({
    root: {
        paddingTop: spacing.unitPadding,
        marginBottom: spacing.unit,
        height: spacing.unitPadding * 2,
        fontSize: typography.subtitle2.fontSize,
        color: palette.secondary.main,
        '&$disabled': {
            color: palette.secondary.main
        }
    },
    focused: {
        color: `${palette.grey[800]} !important`
    },
    disabled: {},
    icon: {
        position: 'absolute',
        transform: `translateX(${spacing.unit * 4}px)`,
        height: spacing.unit * 1.5
    }
});

const LabelBase = ({ classes: c, className, label, success, ...restProps }) => (
    <Fragment>
        <FormLabel
            classes={{
                root: clsx(c.root, className),
                ...Object.splice(c, ['focused', 'disabled'])
            }}
            {...restProps}
        >
            {`${label}:`}
        </FormLabel>
    </Fragment>
);

const _LabelBase = withStyles(styles)(LabelBase);

export { _LabelBase as default, _LabelBase as LabelBase };
