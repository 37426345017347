import React, { useMemo } from 'react';
import { withStyles, Grid } from '@material-ui/core';
// Lib
import { GridItem } from '@formComponents';
import SelectedRows from './SelectedRows';
import Reports from './Reports';
import { useGridViewState } from 'gridView/useGridView';

const styles = () => ({
    container: {
        padding: '10px 0px'
    }
});

const FooterBottom = withStyles(styles)(({ classes: c }) => {
    const {
        data,
        selectedItems: { originalIds },
        moduleName,
        reportsInfo,
        viewType,
        tileView: { data: tileData } = {},
        filters,
        search,
        disableCheckbox
    } = useGridViewState();

    return (
        <Grid container className={c.container}>
            <GridItem md={4}>
                {!disableCheckbox && <SelectedRows originalIds={originalIds} />}
            </GridItem>
            <GridItem md={8}>
                <Reports
                    moduleName={moduleName}
                    reportsInfo={reportsInfo}
                    viewType={viewType}
                    tileData={tileData}
                    originalIds={originalIds}
                    data={data}
                    filters={{ filter: search, ...filters }}
                />
            </GridItem>
        </Grid>
    );
});

export default FooterBottom;
