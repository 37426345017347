import React from 'react';

import { withStyles } from '@material-ui/core';

import Checkbox from './Checkbox';

const styles = () => ({
    container: {
        '&:hover': {
            backgroundColor: 'inherit',
            borderRadius: 'unset'
        }
    }
});

const Header = withStyles(styles)(({ ...rest }) => {
    return <Checkbox label='Select All' {...rest} />;
});

export default Header;
