import React from 'react';

import FooterTop from './FooterTop';
import FooterBottom from './FooterBottom';

const Footer = props => {
    return (
        <>
            <FooterTop {...props} />
            <FooterBottom />
        </>
    );
};

export { Footer as default, Footer };
