import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
    useVersionsViewerDispatch,
    useVersionsViewerState
} from '../../../useVersionsViewer';
import { useDrag, useDrop } from 'react-dnd';
import { VERSION_TYPE } from '../common';

export const useVersionMouseOverStyle = ({
    index,
    image,
    shouldRender,
    theme: { palette }
}) => {
    const { activeIndex, dragIndex, disableDrag } = useVersionsViewerState();

    const [isHovered, setHovered] = useState(false);
    const [previewOpacity, setPreviewOpacity] = useState(0);

    useEffect(() => {
        if (dragIndex === index) {
            setPreviewOpacity(1);
            setTimeout(() => setPreviewOpacity(0), 0);
        }
    }, [dragIndex, index]);

    const handleMouseEnter = useCallback(() => setHovered(true), []);
    const handleMouseLeave = useCallback(() => setHovered(false), []);

    const style = useMemo(() => {
        const isDragging = dragIndex >= 0;
        const canDrop = Array.isArray(disableDrag)
            ? !disableDrag.includes(image)
            : !disableDrag;

        const color =
            !isDragging && isHovered
                ? palette.common.white
                : palette.common.transparent;
        const backgroundColor =
            !isDragging && isHovered
                ? fade(palette.secondary.main, 0.7)
                : palette.common.transparent;

        const borderColor =
            !isDragging && activeIndex === index
                ? palette.success.main
                : palette.background.dark;

        const opacity = shouldRender ? (isDragging && !canDrop ? 0.4 : 1) : 0.5;

        return {
            '--color': color,
            '--backgroundColor': backgroundColor,
            '--borderColor': borderColor,
            '--opacity': opacity,
            '--previewOpacity': previewOpacity
        };
    }, [
        isHovered,
        index,
        activeIndex,
        dragIndex,
        previewOpacity,
        shouldRender,
        disableDrag
    ]);

    return [handleMouseEnter, handleMouseLeave, style];
};

/*
    --------- Drag and Drop ---------
 */
const useDragRef = ({ index, image }) => {
    const dispatch = useVersionsViewerDispatch();

    const previewRef = useRef(null);

    const handleVersionDragging = useCallback(
        dragIndex => dispatch({ type: 'setDragIndex', payload: dragIndex }),
        [dispatch]
    );

    const handleVersionDrop = useCallback(
        () => dispatch({ type: 'dropVersion' }),
        [dispatch, index]
    );
    const [, dragRef, preview] = useDrag({
        item: { type: VERSION_TYPE, id: index, image },
        begin: () => {
            handleVersionDragging(index);
        },
        end: () => {
            handleVersionDragging(-1);
            handleVersionDrop();
        }
    });

    useEffect(() => {
        preview(previewRef, { captureDraggingState: true });
    }, []);

    return [dragRef, previewRef];
};

const useDropRef = ({ index }) => {
    const dispatch = useVersionsViewerDispatch();

    const [{ isOver, dragImage }, dropRef] = useDrop({
        accept: VERSION_TYPE,
        collect: monitor => {
            const { image = void 0 } = monitor.getItem() || {};
            return {
                dragImage: image,
                isOver: monitor.isOver()
            };
        }
    });

    const handleHover = useCallback(
        () =>
            dispatch({
                type: 'setHoverVersions',
                payload: { dragImage, hoverIndex: index }
            }),
        [dispatch, index, dragImage]
    );

    useEffect(() => {
        if (isOver) handleHover();
    }, [isOver]);

    return dropRef;
};

export const useDndRefs = ({ index, image }) => {
    const { readOnly, disableDrag } = useVersionsViewerState();
    const [dragRef, previewRef] = useDragRef({ index, image });
    const dropRef = useDropRef({ index });

    const [, hoverRef] = useDrop({ accept: VERSION_TYPE });

    const canDrag = useMemo(() => {
        if (typeof disableDrag === 'boolean') return !disableDrag;
        else return !disableDrag.includes(image);
    }, [disableDrag, image]);

    return readOnly || !canDrag
        ? [null, null, null, null]
        : [dragRef, dropRef, hoverRef, previewRef];
};

export const useDropStyle = ({ index }) => {
    const { dragIndex, hoverIndex } = useVersionsViewerState();

    return useMemo(() => {
        if (dragIndex !== -1) {
            // To account for rows
            const indexMod = index % 5;

            if (hoverIndex - 1 === index && indexMod !== 4) {
                return { '--width': '85%' };
            }
            if (hoverIndex + 1 === index && indexMod !== 0) {
                return { '--width': '85%', '--right': 0 };
            }

            return { '--width': '100%' };
        } else {
            return { '--width': 0 };
        }
    }, [dragIndex, hoverIndex, index]);
};

// Lazy loading
export const useLoadedImage = ({ image, index }) => {
    const { imageResolver, errorResolver } = useVersionsViewerState();
    const [loadedImage, setLoadedImage] = useState(null);

    useEffect(() => {
        let isSubscribed = true;
        const loadImage = async () =>
            await new Promise((resolve, reject) => {
                let refImage = new Image();
                const smallImage = imageResolver(image, 'small');
                refImage.src = smallImage;
                refImage.onload = () => resolve(smallImage);

                refImage.onerror = () =>
                    reject(`Version ${index + 1} not reachable`);
            });

        if (!!image) {
            setLoadedImage(null);
            loadImage()
                .then(src => isSubscribed && setLoadedImage(src))
                .catch(error => isSubscribed && errorResolver(error));
        }

        return () => {
            isSubscribed = false;
        };
    }, [image]);

    return loadedImage;
};
