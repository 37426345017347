import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { withStyles, Typography } from '@material-ui/core';

import { ModalHeader, ModalContent } from '@libComponents/Modal';

import { samplingReportDataSelector } from 'sampling/redux.selectors';
import { FORM_NAME } from '../../../../../common';
import useInfiniteScroll from './hooks';

const detailedDataSelector = createSelector(
    data => data,
    (_, columnName) => columnName,
    (_, __, value) => value,
    (_, __, ___, retailer) => retailer,
    (data, columnName, value, retailer) => {
        // Here we are filtering the data based on the column name and value and retailer if provided.
        // N/A is default for null values and also database column value for some columns.
        // Due to this, we need to check if the value is null for N/A
        const condition = item => {
            return (
                item[columnName] === value ||
                (value === 'N/A' && item[columnName] === null)
            );
        };

        if (retailer) {
            return data.filter(
                item => condition(item) && item.retailer === retailer
            );
        }
        return data.filter(condition);
    }
);

const propTypes = {
    onClose: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    columnName: PropTypes.string.isRequired
};

const styles = ({ breakpoints, spacing, palette }) => ({
    content: {
        width: breakpoints.values.md,
        maxHeight: '60vh',
        overflowY: 'auto',
        paddingBottom: spacing.unit,
        [breakpoints.down(770)]: {
            width: '90%'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderBottomColor: palette.grey[600],
        borderBottomStyle: 'solid',
        paddingBottom: spacing.unit
    },
    title: {
        fontSize: spacing.unit * 2,
        paddingLeft: spacing.unit * 2,
        paddingRight: spacing.unit * 2,
        paddingTop: spacing.unit * 2
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: spacing.unit * 4,
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 2,
        borderBottom: '2px solid #000',
        fontWeight: 'bold',
        [breakpoints.down(770)]: {
            paddingLeft: spacing.unit,
            paddingRight: spacing.unit
        }
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: spacing.unit,
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4,
        borderBottom: '1px solid #ddd',
        '&:hover': {
            backgroundColor: '#f5f5f5'
        },
        '&:last-child': {
            borderBottom: 'none'
        },
        [breakpoints.down(770)]: {
            paddingLeft: spacing.unit,
            paddingRight: spacing.unit
        }
    }
});

const mapStateToProps = (state, { label: value, columnName }) => {
    const samplingReportData = samplingReportDataSelector(state);
    const retailer = formValueSelector(FORM_NAME)(state, 'retailer');
    return {
        data: detailedDataSelector(
            samplingReportData,
            columnName,
            value,
            retailer
        ),
        retailer
    };
};

const DetailViewModal = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(({ classes: c, onClose, label, quantity, data, retailer }) => {
    const [visibleData, lastItemRef, hasMore] = useInfiniteScroll(data);

    return (
        <>
            <ModalHeader onClose={onClose}>{`${
                retailer ? `${retailer} - ` : ''
            } ${label}(${quantity})`}</ModalHeader>
            <ModalContent className={c.content}>
                <div
                    role='table'
                    aria-label={`${retailer ? retailer : 'TOTAL'} - ${label}`}
                >
                    <div role='row' className={c.header}>
                        <div role='columnheader'>Sample Ref</div>
                        <div role='columnheader'>Requested Date</div>
                    </div>
                    <div role='rowgroup'>
                        {visibleData.map((item, index) => (
                            <div
                                role='row'
                                key={item.sampleRef}
                                className={c.listItem}
                                ref={
                                    visibleData.length === index + 1 && hasMore
                                        ? lastItemRef
                                        : null
                                }
                            >
                                <div role='cell'>{item.sampleRef}</div>
                                <div role='cell'>{item.requestedDate}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalContent>
        </>
    );
});

DetailViewModal.propTypes = propTypes;

export default DetailViewModal;
