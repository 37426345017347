import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@libComponents/Stepper';
//import { Stepper } from '@formComponents/Stepper';
import OrderContainer from './OrderContainer';
import { useContent } from './hooks';
import { reduxForm } from 'redux-form';
import { FORM_NAME } from './common';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'incomplete', 'amend'])
};
const defaultProps = {
    mode: 'new'
};

const Order = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ mode, handleSubmit: handleReduxFormSubmit }) => (
    <Stepper
        content={useContent()}
        ContainerComponent={OrderContainer}
        ContainerProps={{ mode, handleReduxFormSubmit }}
    />
));

Order.propTypes = propTypes;
Order.defaultProps = defaultProps;

export { Order as default, Order };
