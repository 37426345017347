import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

// Material
import { reloadActionsSelector } from './redux.selectors';
import { resetReloadActions } from './redux.actions';

const mapState = state => ({
    reloadActions: reloadActionsSelector(state)
});

const mapDispatch = dispatch => ({
    dispatch
});

const ReloadPrompt = connect(
    mapState,
    mapDispatch
)(({ classes: c, dispatch, reloadActions }) => {
    const shouldPrompt = useMemo(() => !!Object.keys(reloadActions).length, [
        reloadActions
    ]);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, []);

    useEffect(() => {
        if (mounted) {
            const confirmActions = Object.values(reloadActions);
            if (!!confirmActions.length) {
                confirmActions.forEach(confirmAction => {
                    dispatch(confirmAction);
                });
                dispatch(resetReloadActions());
            }

            setMounted(false);
        }
    }, [reloadActions, mounted]);

    useEffect(() => {
        window.onbeforeunload = event => {
            if (typeof event !== 'undefined' && event && shouldPrompt) {
                const message = 'Are you sure you want to leave?';
                event.returnValue = message;
                return message;
            }
        };
    }, [shouldPrompt]);

    return <Fragment />;
});

export default ReloadPrompt;
