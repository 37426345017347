import { SET_REPORTS_DATA } from 'actionTypes';

const commonReducer = (state, { type, payload, ...action }) => {
    switch (type) {
        case SET_REPORTS_DATA: {
            return {
                ...state,
                ...payload
            };
        }

        default: {
            return state;
        }
    }
};

export { commonReducer as default, commonReducer };
