const COMPOSITION_ROUTE_ID = 614;
const GSM_ROUTE_ID = 615;
const DYE_TYPE_ROUTE_ID = 159;
const FABRIC_TYPES_ROUTE_ID = 153;
const FABRIC_FORS_ROUTE_ID = 167;
const FINISH_WASH_ROUTE_ID = 158;
const FABRIC_GROUP_ROUTE_ID = 574;
const FABRIC_SUSTAINABILITY_ROUTE_ID = 580;

export {
    COMPOSITION_ROUTE_ID,
    GSM_ROUTE_ID,
    DYE_TYPE_ROUTE_ID,
    FABRIC_TYPES_ROUTE_ID,
    FABRIC_FORS_ROUTE_ID,
    FINISH_WASH_ROUTE_ID,
    FABRIC_GROUP_ROUTE_ID,
    FABRIC_SUSTAINABILITY_ROUTE_ID
};
