export default ({ palette, spacing, shape }) => ({
    container: {},
    control: {
        height: spacing.unitHeight,
        minWidth: spacing.unit * 25,
        borderTopLeftRadius: `0 !important`,
        borderBottomLeftRadius: `0 !important`,
    },
    dropdownRoot: {
        '&:before': {
            content: '""',
            backgroundColor: palette.grey[500],
            width: 2,
            height: '70%',
            position: 'absolute',
            marginLeft: -spacing.unit, // Either fatten the width or move this
        },
    },
    placeholder: {
        paddingRight: spacing.unit * 6,
    },
});
