import { useMemo } from 'react';

export const samplingReportBreakdownKeys = [
    { name: 'sampleType', label: 'Sample Type' },
    { name: 'designFrom', label: 'Design From' },
    { name: 'gender', label: 'Gender' },
    { name: 'category', label: 'Category' },
    { name: 'age', label: 'Age' },
    { name: 'country', label: 'Country' },
    { name: 'designDepartment', label: 'Design Department' },
    { name: 'orderStatus', label: 'Order Status' }
];

const useDetailsBreakdown = (data, retailer = null) => {
    const detailsBreakdown = useMemo(() => {
        const breakdownMap = {};

        data.forEach(item => {
            samplingReportBreakdownKeys.forEach(({ name, label }) => {
                const keyValue = item[name] || 'N/A';
                if (!breakdownMap[label]) {
                    breakdownMap[label] = {};
                }

                if (!breakdownMap[label][keyValue]) {
                    breakdownMap[label][keyValue] = {
                        name: keyValue,
                        value: 0,
                        key: name
                    };
                }
                if (retailer) {
                    breakdownMap[label][keyValue].value +=
                        item.retailer === retailer ? 1 : 0;
                } else {
                    breakdownMap[label][keyValue].value += 1;
                }
            });
        });

        // Sort breakdown items
        Object.keys(breakdownMap).forEach(label => {
            breakdownMap[label] = Object.values(breakdownMap[label])
                .filter(({ value }) => value)
                .sort((a, b) => b.value - a.value);
        });

        return breakdownMap;
    }, [data, retailer]);

    return detailsBreakdown;
};

export default useDetailsBreakdown;
