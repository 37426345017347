export default ({ unitHeight, unitWidth, listMaxNumber, iconRight }) => ({
    spacing,
    palette,
    shape,
    typography
}) => {
    const menuItemHeight = unitHeight / 2;
    const listItemNumber = listMaxNumber;

    const menuItemPadding = menuItemHeight / 2;
    const listItemHeight = menuItemHeight * 2;
    const listHeight = listItemHeight * listItemNumber;

    // Padding = 2 + 2 ------ ScrollBar = 3 |||| spacing.units
    const listItemWidth = unitWidth - spacing.unit * 7;

    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: spacing.unit,
            height: 'inherit',
            color: palette.grey[700],
            '&:hover': {
                color: palette.text.primary
            },
            fontSize: typography.subtitle2.fontSize
        },
        select: {
            display: 'inline-block',
            lineHeight: `${menuItemHeight * 2}px`,
            marginRight: spacing.unit * -4,
            width: '100%',
            height: 'inherit',
            paddingLeft: spacing.unit * 2,
            paddingTop: 0,
            paddingBottom: 0,
            '&:hover': {
                border: '1px solid',
                borderColor: palette.primary.main,
                borderRadius: shape.borderRadius
            }
        },
        selectCancellable: {
            paddingRight: Number(iconRight) + spacing.unit * 3 // Icon margin + font size
        },
        icon: {
            position: 'relative',
            top: 0
        },
        list: {
            marginTop: menuItemHeight * 2 + 2,
            maxHeight: listHeight,
            maxWidth: 0,
            borderTopLeftRadius: 'unset',
            borderTopRightRadius: 'unset'
        },
        listItem: {
            '& > li': {
                height: 'auto',
                //width: listItemWidth,
                width: `calc(100% - ${spacing.unit * 4}px)`,
                //width: 'calc(100% - 32px);',
                lineHeight: `${menuItemHeight}px`,
                display: 'inline-block',
                whiteSpace: 'break-spaces',
                paddingTop: menuItemPadding,
                paddingBottom: menuItemPadding,
                borderBottom: '1px solid',
                borderBottomColor: palette.grey[300]
            }
        },
        placeholder: {
            lineHeight: `${menuItemHeight * 2}px`,
            width: unitWidth
        },

        inputRoot: {
            width: '100%',
            position: 'absolute',
            //left: Number(unitWidth) + spacing.unit,
            // InputBase
            '& > div': {
                height: 'inherit',

                '&:before': {
                    display: 'none'
                },
                '&::after': {
                    display: 'none'
                }
            }
        },

        input: {
            height: 'inherit',
            paddingLeft: spacing.unit * 2,
            paddingRight: Number(iconRight) + spacing.unit * 3,
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 1,
            fontSize: typography.subtitle2.fontSize,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },

        modal: {
            width: 0
        },
        selectQuery: {
            color: palette.common.transparent
        },
        placeholderQuery: {
            display: 'none'
        },
        control: {
            width: unitWidth ? null : '100%',
            height: unitHeight ? null : '100%',
            border: '1px solid',
            borderColor: palette.grey[300],
            borderRadius: shape.borderRadius,
            // InputBase
            '& > div': {
                marginTop: 0,
                height: 'inherit'
            },
            '&:hover': {
                borderColor: palette.grey[400]
            }
        },

        success: {
            // borderColor: `${palette.success.primary} !important`, // Focus override
            // '&:hover': {
            //     borderColor: `${palette.success.hover} !important`, // Focus override
            // }

            borderColor: `#212121 !important`, // Focus override
            '&:hover': {
                borderColor: `#18191a !important` // Focus override
            }
        },
        disabled: {
            backgroundColor: palette.grey[100],
            color: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[300]
            }
        },
        container: {
            position: 'unset',
            width: '100%'
        },
        progress: {
            backgroundColor: palette.secondary.light,
            height: spacing.unit * 0.5,
            marginTop: `${spacing.unit * -0.75}px !important`,
            borderBottomLeftRadius: spacing.unit * 0.25 + 1,
            borderBottomRightRadius: spacing.unit * 0.25 + 1
        },
        progressPrimary: {
            backgroundColor: palette.secondary.main,
            borderBottomLeftRadius: spacing.unit * 0.25 + 1,
            borderBottomRightRadius: spacing.unit * 0.25 + 1
        }
    };
};
