import { NINETY_PERCENT_ID } from 'orders/common';
import { normalizeDate } from '@baseComponents/Date';
import { normalizeInputGroup } from 'common/redux.normalizers';

const normalizeSizeDetails = (
    tableData = [],
    packType = 'Normal',
    isNinetyPercent = false
) => {
    const isRatio = packType === 'Ratio';

    return tableData.map(
        ({
            sizeName,
            sku,
            barCode,
            quantity,
            ratio,
            packQuantity,
            packRatio
        }) => {
            const sizeDetails = {
                sizeName,
                quantity: quantity || 0,
                packQuantity: packQuantity || 0,
                sku: sku || '',
                locked: {
                    sizeName: true,
                    quantity: isRatio || (isNinetyPercent && !sku && !barCode),
                    packQuantity: true,
                    sku: isNinetyPercent
                }
            };

            if (isRatio) {
                sizeDetails.ratio = ratio || 0;
                sizeDetails.packRatio = packRatio || 0;
                sizeDetails.locked.ratio = isNinetyPercent && !sku && !barCode;
                sizeDetails.locked.packRatio = true;
            }

            if (isNinetyPercent) {
                sizeDetails.barCode = barCode;
                sizeDetails.locked.barCode = true;
            }

            return sizeDetails;
        }
    );
};

const normalizeOrderBasicTrims = ({
    data: { data = [], nextPage = false } = {}
} = {}) => {
    const normalizedData = data.reduce(
        (acm, { id, labelRef, type, color, description }) => {
            return [
                ...acm,
                {
                    'Label Ref': `${labelRef}-${id}`,
                    Type: type,
                    Color: color,
                    Description: description
                }
            ];
        },
        []
    );
    return { data: normalizedData, nextPage };
};
const normalizePaginatedColor = ({
    data: { data = [], nextPage = false } = {}
} = {}) => {
    const normalizedData = data.reduce((acm, { id, colorName }) => {
        return [
            ...acm,
            {
                Color: `${colorName}-${id}`
            }
        ];
    }, []);
    return { data: normalizedData, nextPage };
};

const normalizeOrderSamples = samples =>
    samples.map(({ sampleRef }) => sampleRef);

const normalizePODetails = (poDetails, retailerId) => {
    const isNinetyPercent = NINETY_PERCENT_ID === Number(retailerId);
    return poDetails.map(
        ({
            cid,
            pono,
            packId,
            poTypeName,
            poModeName,
            paymentTermsId,
            settlementDiscountId,
            poDestinationName,
            poPackType,
            exFtyDate,
            fobDate,
            retailPrice,
            quantity,
            yarnPrice,
            totalFabricKg,
            totalPack,
            sizeSet,
            styleNo,
            labDetails,
            sizeDetails,
            ...rest
        }) => ({
            ...rest,
            cidNO: cid,
            poNo: pono,
            poPackId: packId,
            orderType: isNinetyPercent ? poTypeName : Number(poTypeName),
            shipMode: isNinetyPercent ? poModeName : Number(poModeName),
            paymentTerms: paymentTermsId,
            settlementDiscount: settlementDiscountId,
            shipDestination: isNinetyPercent
                ? poDestinationName
                : Number(poDestinationName),
            packType: poPackType,
            estimateFactoryDate: normalizeDate(exFtyDate),
            freeOnBoardDate: normalizeDate(fobDate),
            retailPrice: normalizeInputGroup(retailPrice),
            orderQuantity: quantity,
            yarnPrice: normalizeInputGroup(yarnPrice),
            totalFabric: totalFabricKg,
            totalRatio: totalPack,
            sizeSet: Number(sizeSet),
            orderStyle: styleNo,
            dips: labDetails.map(
                ({
                    id,
                    sampleRef,
                    composition,
                    batchRef,
                    colors: { id: colorId, value: colorName },
                    colorCode,
                    colorNote,
                    fobPrice,
                    echoPrice,
                    licensorFee,
                    orderSustainabilityId,
                    compositionId,
                    fabricTypeId,
                    gsmId
                }) => ({
                    id,
                    sampleRef,
                    batchConfirmation: batchRef,
                    color: isNinetyPercent
                        ? colorId
                        : `${colorName}-${colorId}`,
                    colorCode,
                    colorRecipe: colorNote,
                    fobPrice: normalizeInputGroup(fobPrice),
                    echoPrice: normalizeInputGroup(echoPrice),
                    licenseFee: normalizeInputGroup(licensorFee),
                    sustainability: orderSustainabilityId,
                    composition: compositionId,
                    fabricType: fabricTypeId,
                    gsm: gsmId
                })
            ),
            sizeDetails: normalizeSizeDetails(
                sizeDetails,
                poPackType,
                isNinetyPercent
            )
        })
    );
};

const normalizeLicensor = samples => {
    const { cads: { licensor } = {} } = samples.find(({ cads }) => cads) || {};
    return licensor;
};

export {
    normalizeSizeDetails,
    normalizeOrderBasicTrims,
    normalizeOrderSamples,
    normalizePODetails,
    normalizePaginatedColor,
    normalizeLicensor
};
