import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createCachedSelector from 're-reselect';
import { formValueSelector } from 'redux-form';

// Datasets
import {
    entitySelector,
    datasetLoadingSelector,
    fetchDatasets
} from '@libs/datasets';

import { SelectModel } from '@formComponents';
import { SEAL_SAMPLE_STYLES } from 'qualityControl/redux.datasets';
import { DOMAIN_NAME, FORM_NAME } from 'qualityControl/SealSample/common';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object
};

const defaultProps = {
    name: 'qcStyle',
    label: 'Select Style',
    domain: DOMAIN_NAME,
    entity: SEAL_SAMPLE_STYLES,
    required: false,
    disabled: false
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data.map(({ styleRef }) => ({
        label: styleRef,
        value: styleRef
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    jwpNo: formValueSelector(FORM_NAME)(state, 'jwpNo')
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectQCStyles = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, jwpNo, ...restProps }) => {
    useEffect(() => {
        jwpNo && fetchDataset();
    }, [jwpNo]);

    return <SelectModel {...restProps} />;
});

SelectQCStyles.propTypes = propTypes;
SelectQCStyles.defaultProps = defaultProps;

export { SelectQCStyles as default, SelectQCStyles };
