import getDataUrl from '@libs/getDataUrl';

export const loadProps = async ({ samples, others, ...restProps }) => {
    const { attachments, ...rest } = others;
    const samplesURLs = await Promise.all(
        samples
            .filter(sample => sample)
            .map(async ({ url, ...restInfo }) => {
                const dataURL = url && (await getDataUrl(url));
                return { url: dataURL, ...restInfo };
            })
    );
    /* const attachmentUrls = await Promise.all(
        attachments.map(async ({ url, ...restInfo }) => {
            const dataURL = await getDataUrl(url);
            return { url: !!dataURL ? dataURL : undefined, ...restInfo };
        })
    ); */
    return {
        samples: samplesURLs,
        others: { attachments: [], ...rest },
        ...restProps
    };
};
