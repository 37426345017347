import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles, MenuItem, MenuList } from '@material-ui/core';

// Local
import { useListStyle } from './hooks';
import { optionShape } from './common';

const propTypes = {
    options: PropTypes.arrayOf(PropTypes.exact(optionShape)).isRequired,
    color: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired
};

const styles = ({ palette, typography, shape }) => ({
    list: {
        padding: 0,
        backgroundColor: 'var(--hoverColor)',
        borderBottomLeftRadius: shape.borderRadius,
        borderBottomRightRadius: shape.borderRadius
    },
    listItem: {
        height: 'var(--height)',
        width: 'var(--width)',
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '80%',
        fontWeight: typography.fontWeightRegular,
        borderRadius: 4,

        borderTopColor: 'var(--hoverColor)',
        borderTopStyle: 'solid',
        borderTopWidth: 1,

        color: palette.primary.contrastText,
        backgroundColor: 'var(--menuColor)',
        '&:hover': {
            backgroundColor: 'var(--menuHoverColor)'
        }
    }
});

const Options = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme, color, options, width, height, handleClose }) => {
        const listStyle = useListStyle({ theme, color, width, height });
        return (
            <MenuList className={c.list} style={listStyle}>
                {options.map(({ label, callback, disabled }, index) => (
                    <MenuItem
                        key={`${label}--${index}`}
                        disabled={disabled}
                        onClick={event => {
                            callback(event);
                            handleClose(event);
                        }}
                        className={c.listItem}
                    >
                        {label}
                    </MenuItem>
                ))}
            </MenuList>
        );
    }
);

Options.propTypes = propTypes;

export default Options;
