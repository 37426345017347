import axios from 'axios';
import { call, all } from 'redux-saga/effects';

import isBlobUrl from '@libs/isBlobUrl';

export default function*({ versions, mode, handleLoading }) {
    const versionBlobRequests = versions.reduce((acm, version) => {
        // If on amend, and is already on the server
        if (mode === 'amend' && !isBlobUrl(version.url)) return [...acm, null];
        else
            return [
                ...acm,
                call(function*() {
                    const { data: file } = yield call(axios, {
                        url: version.url,
                        responseType: 'blob',
                        headers: {
                            Authorization: ''
                        }
                    });

                    if (!isBlobUrl(version.url)) yield call(handleLoading);

                    const type = file.type.split('/').slice(-1);

                    return { file, type };
                })
            ];
    }, []);

    return yield all(versionBlobRequests);
}
