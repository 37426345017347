import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { withStyles, Grid } from '@material-ui/core';

import { Date as DateInput } from '@formComponents';
import { GridItem } from '@formComponents';
import useMinDate from '@libHooks/useMinDate';

import { FORM_NAME } from 'cadDesignerReport/common';
import { SelectTypes } from 'cadUpload/components';
import { SelectDesigners } from 'cadDesignerReport/components';
import { SelectDepartments, SelectRetailers } from 'sampling/components';

import Export from './Export';

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        background: '#fff',
        padding: spacing.unit * 3,
        margin: `${spacing.unit * 2}px 0px`
    }
});

const mapState = state => ({
    dateFrom: formValueSelector(FORM_NAME)(state, 'from')
});

const FilteredItems = compose(
    connect(
        mapState,
        null
    ),
    withStyles(styles)
)(({ classes: c, dateFrom, canExport }) => {
    const multiselectProps = useMemo(() => ({ multiple: true, label: '' }), []);
    return (
        <Grid container className={c.container}>
            <GridItem md={3}>
                <SelectTypes {...multiselectProps} />
            </GridItem>
            <GridItem md={3}>
                <SelectRetailers {...multiselectProps} />
            </GridItem>
            <GridItem md={3}>
                <SelectDepartments {...multiselectProps} formName={FORM_NAME} />
            </GridItem>
            <GridItem md={3}>
                <SelectDesigners {...multiselectProps} />
            </GridItem>
            <GridItem md={3}>
                <DateInput
                    name='from'
                    label=''
                    placeholder='From'
                    maxDate={new Date()}
                    minDate={new Date('2015-01-01')}
                />
            </GridItem>
            <GridItem md={3}>
                <DateInput
                    name='to'
                    label=''
                    placeholder='To'
                    minDate={useMinDate(dateFrom)}
                />
            </GridItem>
            <GridItem md={3}>
                <Export canExport={canExport} />
            </GridItem>
        </Grid>
    );
});

export { FilteredItems as default, FilteredItems };
