import { useMemo } from 'react';

import { usePurchaseOrdersState } from '../../usePurchaseOrders';

const useDipsTableHeader = (headers = {}) => {
    const { isNewlook } = usePurchaseOrdersState();
    return useMemo(() => {
        const headersArray = Object.entries(headers).map(([label, value]) => {
            switch (label) {
                case 'sampleRef':
                    return { value: 'Sample Ref.', order: 0 };
                case 'batchConfirmation':
                    return !!value ? { value: 'Batch Ref.', order: 1 } : null;
                case 'color':
                    return { value: 'Color Name', order: 2 };
                case 'colorCode':
                    return { value: 'Color Code', order: 3 };
                case 'colorRecipe':
                    return {
                        value: isNewlook ? 'Lab Dip' : 'Color Recipe',
                        order: 4
                    };
                case 'echoPrice':
                    return { value: 'Echo Price', order: 6 };
                case 'fobPrice':
                    return { value: 'Fob Price in PC', order: 7 };
                case 'licenseFee':
                    return isNewlook
                        ? null
                        : { value: 'License Fee', order: 8 };
                case 'sustainability':
                    return !!value
                        ? { value: 'Sustainability', order: 9 }
                        : null;
                case 'composition':
                    return !!value ? { value: 'Fabrication', order: 10 } : null;
                default:
                    return null;
            }
        });
        return headersArray
            .filter(header => !!header)
            .sort((a, b) => a.order - b.order)
            .map(({ value }) => value);
    }, [headers, isNewlook]);
};

const useDipsTableBody = row => {
    const { isNewlook } = usePurchaseOrdersState();
    return useMemo(
        () =>
            Object.entries(row)
                .map(([label, value]) => {
                    switch (label) {
                        case 'sampleRef':
                            return { value, order: 0 };
                        case 'batchConfirmation':
                            return !!value ? { value, order: 1 } : null;
                        case 'color':
                            return { value, order: 2 };
                        case 'colorCode':
                            return { value: !!value ? value : 'N/A', order: 3 };
                        case 'colorRecipe':
                            return {
                                value: !!value ? value : 'N/A',
                                order: 4
                            };
                        case 'echoPrice': {
                            const { inputValue, selectedValue } = value || {};
                            return {
                                value: !!inputValue
                                    ? `${selectedValue}${inputValue}`
                                    : 'N/A',
                                order: 6
                            };
                        }
                        case 'fobPrice': {
                            const { inputValue, selectedValue } = value || {};
                            return {
                                value: !!inputValue
                                    ? `${selectedValue}${inputValue}`
                                    : 'N/A',
                                order: 7
                            };
                        }
                        case 'licenseFee': {
                            if (isNewlook) return null;
                            const { inputValue, selectedValue } = value || {};
                            return !!inputValue
                                ? {
                                      value: `${selectedValue}${inputValue}`,
                                      order: 8
                                  }
                                : { value: 'N/A', order: 8 };
                        }
                        case 'sustainability':
                            return !!value
                                ? {
                                      value,
                                      order: 9
                                  }
                                : null;
                        case 'fabrication':
                            return !!value ? { value, order: 10 } : null;

                        default:
                            return null;
                    }
                })
                .filter(value => !!value)
                .sort((a, b) => a.order - b.order)
                .map(({ value }) => value),
        [row, isNewlook]
    );
};

export { useDipsTableHeader, useDipsTableBody };
