import React, { useState, useEffect, useRef, useCallback } from 'react';

import { withStyles, TextField } from '@material-ui/core';

import useDebounce from '@libHooks/useDebounce';

import styles from './styles';
import SpotlightAdornment from './MultiSelectDialogSpotlightAdornment';

const MultiSelectDialogSpotlight = ({
    classes: { input, ...c },
    handleFilter
}) => {
    const [search, setSearch] = useState('');
    const previousSearch = useRef('');
    const debouncedValue = useDebounce(search, 500);

    const handleChange = useCallback(({ target: { value = '' } }) => {
        setSearch(value);
    }, []);

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === 'Enter' && search !== previousSearch.current) {
                handleFilter(search);
                previousSearch.current = search;
            }
        },
        [search]
    );

    const adornmentProps = {
        onClick: () => {
            if (search !== previousSearch.current) {
                handleFilter(search);
                previousSearch.current = search;
            }
        }
    };

    useEffect(() => {
        if (debouncedValue !== previousSearch.current) {
            handleFilter(debouncedValue);
            previousSearch.current = debouncedValue;
        }
    }, [debouncedValue]);

    return (
        <TextField
            classes={c}
            InputProps={{
                disableUnderline: true,
                className: input,
                endAdornment: <SpotlightAdornment {...adornmentProps} />
            }}
            value={search}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder='Search'
            fullWidth
            autoFocus
        />
    );
};

export default withStyles(styles)(MultiSelectDialogSpotlight);
