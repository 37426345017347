import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Material
import { Grid, withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import { GridItem, Input, Select } from '@formComponents';
import {
    required,
    number,
    mustBeGreaterThanZero,
    fraction
} from '@libs/validationRules';

// Local
import {
    dispatchTypeCategorySelector,
    isSeasaltSelector
} from 'sampling/redux.selectors';
import {
    DOMAIN_NAME,
    DISPATCH_DETAILS_FORM_NAME
} from 'sampling/SampleDispatch/common';
import {
    SET_DISPATCH_DETAILS_TYPE_CHILDREN,
    SET_DISPATCH_DETAILS_ORDER_CHILDREN,
    SET_DISPATCH_DETAILS_SAMPLE_REF_CHILDREN,
    SET_DISPATCH_DETAILS_COLOUR_CHILDREN
} from 'sampling/redux.actionTypes';
import { SelectSeasons } from 'sampling/components';
import { DISPATCH_TYPE, DISPATCH_SIZE } from 'sampling/redux.datasets';
import SelectPaginatedSampleReferences from './SelectPaginatedSampleReferences';
import SelectPaginatedOrderNo from './SelectPaginatedOrderNo';
import SelectSampleReferenceByOrder from './SelectSampleReferenceByOrder';
import SelectColourByOrderSample from './SelectColourByOrderSample';
import SelectPOByOrderColour from './SelectPOByOrderColour';
import SelectSampleFactory from './SelectSampleFactory';

const quantityValidationRules = [
    required,
    number,
    mustBeGreaterThanZero,
    fraction
];

const propTypes = {
    handleSave: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    const { dispatchType, orderNo } = formValueSelector(
        DISPATCH_DETAILS_FORM_NAME
    )(state, 'dispatchType', 'orderNo');
    return {
        dispatchTypeCategory: dispatchTypeCategorySelector(state, dispatchType),
        isSeasalt: isSeasaltSelector(state),
        orderNo
    };
};

const mapInputs = {
    dispatchType: () => ({ type: SET_DISPATCH_DETAILS_TYPE_CHILDREN }),
    orderNo: () => ({ type: SET_DISPATCH_DETAILS_ORDER_CHILDREN }),
    sampleRef: ({ payload }) => ({
        type: SET_DISPATCH_DETAILS_SAMPLE_REF_CHILDREN,
        payload
    }),
    colour: ({ payload }) => ({
        type: SET_DISPATCH_DETAILS_COLOUR_CHILDREN,
        payload
    })
};

const styles = ({ breakpoints, spacing, typography }) => ({
    content: { width: breakpoints.values.md },
    button: {
        width: '60%',
        margin: `${spacing.unit * 2}px auto`
    },
    checkboxLabel: {
        fontSize: typography.caption.fontSize
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 4.5
    }
});

const Modal = compose(
    connect(
        mapStateToProps,
        null
    ),
    reduxForm({
        form: DISPATCH_DETAILS_FORM_NAME,
        onSubmit: (values, dispatch, { handleSave, onClose }) => {
            handleSave(values);
            onClose();
        }
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        onClose,
        handleSubmit,
        initialized,
        dispatchTypeCategory,
        isSeasalt,
        orderNo
    }) => {
        const isManualDispatchType = dispatchTypeCategory === 'manual';

        const renderGenderAndDepartmentFields = (
            <>
                <GridItem md={4}>
                    <Input name='gender' label='Gender' />
                </GridItem>
                <GridItem md={4}>
                    <Input name='department' label='Department' />
                </GridItem>
            </>
        );

        const renderOrderFields = (
            <>
                <GridItem md={4}>
                    <SelectPaginatedOrderNo />
                </GridItem>
                {orderNo && (
                    <>
                        <GridItem md={4}>
                            <SelectSampleReferenceByOrder />
                        </GridItem>
                        {renderGenderAndDepartmentFields}
                        <GridItem md={4}>
                            <SelectColourByOrderSample />
                        </GridItem>
                        <GridItem md={8}>
                            <SelectPOByOrderColour />
                        </GridItem>
                    </>
                )}
            </>
        );

        const renderManualFieds = (
            <>
                <GridItem md={4}>
                    <Input name='sampleRef' label='Sample Reference' />
                </GridItem>
                {renderGenderAndDepartmentFields}
                <GridItem md={4}>
                    <Input
                        name='colour'
                        label='Colour'
                        required={dispatchTypeCategory !== 'manual'}
                    />
                </GridItem>
            </>
        );

        const renderSampleFields = (
            <>
                <GridItem md={4}>
                    <SelectPaginatedSampleReferences />
                </GridItem>
                {renderGenderAndDepartmentFields}
                <GridItem md={4}>
                    <Input name='colour' label='Colour' required />
                </GridItem>
            </>
        );

        const renderSeasaltFields = (
            <>
                <GridItem md={4}>
                    <SelectSeasons />
                </GridItem>
                <GridItem md={4}>
                    <Input name='designer' label='Designer' />
                </GridItem>
                <GridItem md={4}>
                    <Input name='fabricGroup' label='Fabric Group' />
                </GridItem>
            </>
        );
        return (
            <Fragment>
                <ModalHeader onClose={onClose}>
                    {`${initialized ? 'Update' : 'Add'} dispatch details`}
                </ModalHeader>
                <ModalContent className={c.content}>
                    <Grid container>
                        <GridItem md={4}>
                            <Select
                                name='dispatchType'
                                label='Dispatch Type'
                                entity={DISPATCH_TYPE}
                                domain={DOMAIN_NAME}
                                required
                            />
                        </GridItem>
                        {dispatchTypeCategory === 'sample' &&
                            renderSampleFields}
                        {dispatchTypeCategory === 'manual' && renderManualFieds}
                        {dispatchTypeCategory === 'order' && renderOrderFields}
                        <GridItem md={4}>
                            <Select
                                name='size'
                                label='Size'
                                entity={DISPATCH_SIZE}
                                domain={DOMAIN_NAME}
                                required={!isManualDispatchType}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <Input
                                name='quantity'
                                label='QTY'
                                type='number'
                                required={true}
                                validate={quantityValidationRules}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <SelectSampleFactory
                                required={!isManualDispatchType}
                            />
                        </GridItem>
                        {isSeasalt && renderSeasaltFields}
                        <GridItem md={8}>
                            <Input
                                name='remarks'
                                label='Remarks'
                                placeholder='Write comments here'
                                multiline
                                rowsMax={4}
                                styles={{ root: c.inputRoot }}
                            />
                        </GridItem>
                    </Grid>
                </ModalContent>
                <ModalFooter>
                    <Button className={c.button} onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </Fragment>
        );
    }
);

Modal.propTypes = propTypes;

export default Modal;
