import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Libs
import BaseCard from '@baseComponents/Card';
import { valueSelector } from '@libs/datasets';

// Bought
import { DOMAIN_NAME as BOUGHT_DOMAIN_NAME } from 'bought/common';
import {
    BOUGHT_BYS,
    BOUGHT_LOCATIONS
} from 'bought/redux.datasets';

// Local
import Title from './Title';
import ActionsAdornment from './ActionsAdornment';
import { useBoughtDetails } from './hooks';

const propTypes = {
    title: PropTypes.string.isRequired,
    boughtSampleRef: PropTypes.string.isRequired,
    boughtBy: PropTypes.number,
    boughtLocation: PropTypes.number,
    tripReference: PropTypes.string,
    boughtDate: PropTypes.string,

    handleDelete: PropTypes.func.isRequired
};

const mapState = (state, { boughtBy, boughtLocation }) => ({
    boughtBy: valueSelector(state, BOUGHT_DOMAIN_NAME, BOUGHT_BYS, boughtBy),
    boughtLocation: valueSelector(
        state,
        BOUGHT_DOMAIN_NAME,
        BOUGHT_LOCATIONS,
        boughtLocation
    )
});

const Card = connect(mapState)(({ title, handleDelete, ...props }) => {
    const details = useBoughtDetails(props);

    return (
        <BaseCard
            title={<Title value={title} />}
            ActionsAdornment={<ActionsAdornment handleDelete={handleDelete} />}
            details={details}
            minAmountShown={7}
        />
    );
});

Card.propTypes = propTypes;

export { Card as default, Card };
