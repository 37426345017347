import { DOMAIN_NAME } from '../common';

const FORM_NAME = `fabricDevelopment`;

const LABELS = {
    setup: 'New Fabric Development',
    details: 'Fabric Development Details',
    others: 'Others',
    complete: 'Complete!',
    editFabricDevelopment: 'Edit Fabric Development',
    amendFabricDevelopment: 'Amend Fabric Development'
};

const FD_SETUP_FIELDS = [
    { name: 'developFor', type: 'select' },
    { name: 'sourceOfFD', type: 'select' },
    { name: 'hasManualReference', type: 'checkbox' },
    { name: 'manualReference', type: 'input' }
];

const FD_DETAILS_FIELDS = [
    { name: 'fabircReference', type: 'input' },
    { name: 'versionNo', type: 'input' },
    { name: 'developmentType', type: 'select' },
    { name: 'boughtReference', type: 'select' },
    { name: 'originalFabric', type: 'object' },
    { name: 'originalFabricImage', type: 'image' },
    { name: 'requiredFabric', type: 'object' },
    { name: 'hasSameAsOriginalFabric', type: 'checkbox' },
    { name: 'requiredFabricType', type: 'select' },
    { name: 'requiredFabricComposition', type: 'select' },
    { name: 'requiredFabricGsm', type: 'select' },
    { name: 'hasMatchWashAndFinish', type: 'checkbox' },
    { name: 'specialFinish', type: 'select' },
    { name: 'hasMatchColor', type: 'checkbox' },
    { name: 'color', type: 'input' },
    { name: 'colorReference', type: 'input' },
    { name: 'washedImage', type: 'image' },
    { name: 'comments', type: 'textarea' }
];

const FD_OTHERS_FIELDS = [
    { name: 'requestBy', type: 'select' },
    { name: 'developFor', type: 'select' },
    { name: 'department', type: 'select' },
    { name: 'buyer', type: 'input' },
    { name: 'dateRequested', type: 'date' },
    { name: 'dateRequired', type: 'date' },
    { name: 'requiredHanger', type: 'select' },
    { name: 'factory', type: 'select' },
    { name: 'hasSpeed', type: 'checkbox' },
    { name: 'hasAvailability', type: 'checkbox' },
    { name: 'hasSustainability', type: 'checkbox' },
    { name: 'developedFabric', type: 'object' },
    { name: 'batchNo', type: 'input' },
    { name: 'recipeNo', type: 'input' },
    { name: 'yarnCount', type: 'select' },
    { name: 'yarnLot', type: 'input' },
    { name: 'yarnSupplier', type: 'select' },
    { name: 'shrinkageLength', type: 'input' },
    { name: 'shrinkageWidth', type: 'input' },
    { name: 'shrinkageSpirality', type: 'input' },
    { name: 'pillingLength', type: 'input' },
    { name: 'pillingWidth', type: 'input' },
    { name: 'pillingMethod', type: 'select' },
    { name: 'elastaneUnrecoveryLength', type: 'input' },
    { name: 'elastaneUnrecoveryWidth', type: 'input' },
    { name: 'dryProcess', type: 'select' },
    { name: 'approvalStatus', type: 'select' },
    { name: 'approvedBy', type: 'select' },
    { name: 'dateOfApproved', type: 'date' },
    { name: 'approvalComments', type: 'textarea' },
    { name: 'finalImage', type: 'image' }
];

const INITIAL_VALUES = { versionNo: 1 };

const NEW_FABRIC_DEVELOPMENT_ROUTE_ID = 339;
const INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID = 340;
const AMEND_FABRIC_DEVELOPMENT_ROUTE_ID = 341;
const DEVELOPED_FABRIC_ROUTE_ID = 482;

export {
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_FABRIC_DEVELOPMENT_ROUTE_ID,
    INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID,
    AMEND_FABRIC_DEVELOPMENT_ROUTE_ID,
    DEVELOPED_FABRIC_ROUTE_ID,
    DOMAIN_NAME,
    FD_SETUP_FIELDS,
    FD_DETAILS_FIELDS,
    FD_OTHERS_FIELDS
};
