import React, { useMemo } from 'react';

import { COMPOSITIONS } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const Composition = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Composition', canSort: true, sortKey: 'composition' },
            {
                label: 'Sustainability',
                canSort: true,
                sortKey: 'sustainability'
            },
            { label: 'Retailer', canSort: true, sortKey: 'retailer' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'FABRIC COMPOSITION SETUP',
        domain: DOMAIN_NAME,
        entity: COMPOSITIONS,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { Composition as default, Composition };
