import React from 'react';
import PropTypes from 'prop-types';
// Material
import useStepper from './StepContext';
import { Paper, withStyles } from '@material-ui/core';
import StepperBar from './StepperBar';

const propTypes = {
    ContainerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
        .isRequired
};

const styles = ({ palette, shadows }) => ({
    root: {
        position: 'relative',
        backgroundColor: palette.common.transparent,
        boxShadow: shadows[0]
    }
});

const StepperContainer = withStyles(styles)(
    ({ classes: c, ContainerComponent, ...props }) => {
        const [state, dispatch] = useStepper();

        const { contents, activeStep } = state;

        const { content: Content } = contents[activeStep] || {};

        return (
            <ContainerComponent
                {...{ stepReducer: [() => state, dispatch], ...props }}
            >
                <Paper classes={c}>
                    <StepperBar />
                    {contents.length && <Content {...props} />}
                </Paper>
            </ContainerComponent>
        );
    }
);

StepperContainer.propTypes = propTypes;

export default StepperContainer;
