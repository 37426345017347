import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';

import OverlayScrollContainer from '@baseComponents/OverlayScrollContainer';
import { withStylesAndRef } from '@libs/materialUI';
import { useDimensionsObserver } from '@libHooks/useDimensions';
import ContentLoader from '@libComponents/ContentLoader';

import { ResizingContextProvider } from './useResizingContext';
import { useScrollWhileColumnDragging, useColumnResizing } from './hooks';
import useGridContext from '../../useGridContext';
import DataGridHeaderActions from './DataGridHeaderActions';
import GridHeader from './GridHeader';
import GridBody from './GridBody';
import GridFooter from './GridFooter';
import styles from './styles';

const GridRoot = withStylesAndRef(styles)(({ classes: c, forwardRef }) => {
    const {
        dataGridLoading,
        sortedData,
        draggedColumn,
        columns
    } = useGridContext();

    const { resizeColumn, stopResize, ...resizingProps } = useColumnResizing(
        columns
    );

    const { scrollbarRef, handleDrag } = useScrollWhileColumnDragging(
        draggedColumn,
        resizingProps.columnWidths
    );

    const [gridRef, { height: gridHeight }] = useDimensionsObserver();

    const [footerRef, { height: gridFooterHeight }] = useDimensionsObserver();

    const [
        gridActionsWrapperRef,
        { height: gridActionsWrapperHeight }
    ] = useDimensionsObserver();

    const resizeFrame = e => {
        resizeColumn(e.clientX);
    };

    const gridFooterHeightPX = `${gridFooterHeight}px`;
    const gridActionsWrapperHeightPX = `${gridActionsWrapperHeight}px`;

    const rootStyles = {
        '--gridFooterHeight': gridFooterHeightPX,
        '--gridActionsWrapperHeight': gridActionsWrapperHeightPX
    };

    const gridProps = {
        className: c.grid,
        role: 'grid',
        onMouseMove: resizeFrame,
        onMouseUp: stopResize,
        onDrag: handleDrag,
        ref: gridRef
    };

    const overlayScrollProps = useMemo(
        () => ({
            styles: { root: c.gridOverlayScroll, scroll: c.scroll },
            ref: scrollbarRef
        }),
        [scrollbarRef]
    );

    const noDataMessageProps = useMemo(
        () => ({
            className: c.noDataMessage,
            variant: 'body1',
            color: 'textSecondary'
        }),
        []
    );

    const gridBodyHeight = gridHeight - gridFooterHeight;

    const extendedResizingProps = useMemo(
        () => ({
            ...resizingProps,
            gridScrollbarRef: scrollbarRef,
            gridBodyHeight
        }),
        [resizingProps, scrollbarRef, gridBodyHeight]
    );

    return (
        <div ref={forwardRef} className={c.girdContainer} style={rootStyles}>
            {dataGridLoading && <ContentLoader />}
            <DataGridHeaderActions ref={gridActionsWrapperRef} />
            <div {...gridProps}>
                <OverlayScrollContainer {...overlayScrollProps}>
                    <ResizingContextProvider {...extendedResizingProps}>
                        <GridHeader />
                        <GridBody />
                    </ResizingContextProvider>
                </OverlayScrollContainer>

                {!dataGridLoading && sortedData.length === 0 && (
                    <Typography {...noDataMessageProps}>
                        No data found.
                    </Typography>
                )}

                <GridFooter ref={footerRef} />
            </div>
        </div>
    );
});

export { GridRoot as default, GridRoot };
