import {
    REDIRECT_TO_ROUTE,
    SET_REDIRECT,
    SET_MODULE_FREQUENTLY_USED
} from './redux.actionTypes';

export const redirectToRoute = routeId => ({
    type: REDIRECT_TO_ROUTE,
    payload: { routeId }
});

export const setRedirect = redirect => ({
    type: SET_REDIRECT,
    payload: redirect
});

export const setModuleFrequentlyUsed = pathname => ({
    type: SET_MODULE_FREQUENTLY_USED,
    payload: pathname
});
