import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';

import {
    datasetLoadingSelector,
    fetchDatasets,
    entitySelector
} from '@libs/datasets';
import SelectModel from '@formComponents/SelectModel';

import { DOMAIN_NAME } from 'setup/common';
import { TRIM_LABEL_TYPE } from 'setup/redux.datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    domain: PropTypes.string,
    entity: PropTypes.string
};

const defaultProps = {
    domain: DOMAIN_NAME,
    entity: TRIM_LABEL_TYPE,
    name: 'trimType',
    label: 'Trim Label Type',
    placeholder: 'Select Type',
    disabled: false,
    required: false
};

const dataSelector = createCachedSelector(entitySelector, data =>
    data
        .filter(({ isActive }) => isActive)
        .map(({ id, trimType }) => ({
            label: trimType,
            value: id
        }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectTrimLabelType = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} />;
});

SelectTrimLabelType.propTypes = propTypes;
SelectTrimLabelType.defaultProps = defaultProps;

export default SelectTrimLabelType;
