import { formValueSelector, change } from 'redux-form';
import { select, call, all, put } from 'redux-saga/effects';

import { apiRequest } from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { renameProperties } from '@libs/object';

import { userIdSelector } from 'auth/redux.selectors';

// Domain
import { FORM_NAME } from '../../FabricDevelopment/common';

// Local
import { generateReferenceSchema } from './publishWorker/schema';

export default function*() {
    try {
        const [userId, values] = yield all([
            select(userIdSelector),
            select(
                formValueSelector(FORM_NAME),
                'retailer',
                'artworkSource',
                'manualReference'
            )
        ]);

        const payload = renameProperties(
            {
                userId,
                ...values
            },
            {
                retailer: 'retailerId',
                artworkSource: 'artworkSourceId',
                manualReference: 'manualRef'
            }
        );

        const {
            data: { fabricReference, id }
        } = yield call(apiRequest, {
            method: 'post',
            url: 'FabricDevelopment/GenerateFabricDevelopment',
            data: composeApiPayload(payload, generateReferenceSchema)
        });
        yield put(change(FORM_NAME, 'fabricReference', fabricReference));
        yield put(change(FORM_NAME, 'id', id));
        yield put(change(FORM_NAME, 'versionNo', 1));
        return { fabricReference, id };
    } catch {
        console.log('Error while generating reference');
    }
}
