const httpType = code => {
	/* 
    TYPE = 'info', 'success', 'error', 'warning', 
    Ref: https://www.w3.org/Protocols/HTTP/HTRESP.html
    1xx (Informational): The request was received, continuing process
	2xx (Successful): The request was successfully received, understood and accepted
	3xx (Redirection): Further action needs to be taken in order to complete the request
	4xx (Client Error): The request contains bad syntax or cannot be fulfilled
	5xx (Server Error): The server failed to fulfill an apparently valid request
    */

	let type = 'info';
	const strCode = String(code).charAt(0);

	if (strCode === '1') type = 'info';
	if (strCode === '2') type = 'success';
	if (strCode === '4' || strCode === '5') type = 'error';

	return type;
};

export { httpType as default, httpType };
