import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, withStyles } from '@material-ui/core';

import OverlayScrollContainer from '@baseComponents/OverlayScrollContainer';
import useVersionsViewer from '../../useVersionsViewer';
import Version from './Version';
import VersionHandle from './VersionHandle';

const propTypes = {
    handleClick: PropTypes.func.isRequired
};

const styles = ({ palette, spacing }) => ({
    overlayScroll: {
        width: '100%',
        // 100% - header + (readOnly ? 0 : footer)
        height: `calc(100% - (
                    var(--headerFooterHeight) + (
                        var(--headerFooterHeight) * var(--handleBarCount)
                    )
                )
            )`,
        backgroundColor: palette.background.main
    },
    viewer: {
        padding: spacing.unit
    }
});

const Versions = withStyles(styles)(({ classes: c, handleClick }) => {
    const [{ versions, readOnly, dragIndex }, dispatch] = useVersionsViewer();

    const handleDragEnter = useCallback(() => {
        if (dragIndex === -1) dispatch({ type: 'setDragging', payload: true });
    }, [dispatch, dragIndex]);
    return (
        <OverlayScrollContainer
            styles={{ root: c.overlayScroll }}
            onDragEnter={handleDragEnter}
        >
            <Grid container className={c.viewer} onDragEnter={handleDragEnter}>
                {!!versions.length &&
                    versions.map((versionImage, index) => (
                        <Version
                            key={`versionViewerImage-${index}`}
                            image={versionImage}
                            index={index}
                        />
                    ))}
                {!readOnly && !!versions.length && (
                    <VersionHandle handleClick={handleClick} />
                )}
            </Grid>
        </OverlayScrollContainer>
    );
});

Versions.propTypes = propTypes;

export default Versions;
