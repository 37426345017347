import React, { useState } from 'react';
import { array } from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

import Image from './Image';
import Slider from './Slider';

const styles = ({ spacing }) => ({
    layer: {
        position: 'relative',
        width: spacing.unit * 9,
        height: spacing.unit * 9,
        margin: '4px 0px 8px'
    },
    size100: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        cursor: 'pointer',
        borderRadius: 2,
        border: '1px solid #d9d7d7',
        backgroundColor: '#fff'
    },
    top: {
        zIndex: 1
    },
    bottom: {
        zIndex: 0,
        top: 4,
        left: 8
    },

    image: { width: '100%' }
});

const propTypes = {
    image: array.isRequired
};

const GridImage = withStyles(styles)(({ image, classes: c }) => {
    const layerImages = image.slice(0, 2);
    const [show, setShow] = useState(false);

    return (
        <>
            <div className={c.layer}>
                {layerImages.map(({ size100, imageVersion }, idx) => {
                    return (
                        <Image
                            key={`gird-image-${idx}`}
                            url={size100}
                            filename={imageVersion}
                            className={classNames(
                                c.size100,
                                idx === 0 ? c.top : c.bottom
                            )}
                            onClick={() => setShow(true)}
                        />
                    );
                })}
            </div>
            {show && (
                <Slider sliderData={image} onClose={() => setShow(false)} />
            )}
        </>
    );
});

GridImage.propTypes = propTypes;

export { GridImage as default, GridImage };
