const DIVIDER = '__';

export const deserializeFilename = url => {
    if (!url) return '';
    // Just in case
    const [fullFilename] = url.split('/').slice(-1);
    const [filename] = fullFilename.split(DIVIDER).slice(-1);
    return filename;
};

export const serializeFilename = ({
    reference,
    developmentName,
    filename,
    prefix = '',
    serial = null
}) => {
    const serialText = serial ? `_${serial}` : '';
    const prefixText = prefix ? `_${prefix}` : '';
    const slug = `${reference}_${developmentName}${serialText}${prefixText}`;

    const serializedFilename = filename.split(' ').join('-');

    return `${slug}${DIVIDER}${serializedFilename}`;
};

export const getFileNameFromURL = url => {
    if (!url) return null;
    return url.split('/').slice(-1)[0];
};
