import {
    FETCH_SPEC_REF_REQUEST,
    SET_SPEC_REF_DATA
} from 'store/actionTypes';


export const setSpecRefData = (sampleReference) => ({
    type: SET_SPEC_REF_DATA,
    payload: { sampleReference }
});


export function fetchSpecRefs(payload) {
    return { type: FETCH_SPEC_REF_REQUEST, payload };
}