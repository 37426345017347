import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import GridView from 'gridView';
import { FORM_NAME } from './common';
import Filters from './components/Filters';
import { config } from './config';

const CadGridListView = compose(
    reduxForm({
        form: FORM_NAME
    })
)(() => {
    return (
        <GridView
            entity={{ name: '@cadLists', endpoint: 'Cad/GetCadListViewData' }}
            FiltersComponent={Filters}
            config={config}
        />
    );
});

export { CadGridListView, CadGridListView as default };
