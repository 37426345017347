import React from 'react';
import { reduxForm } from 'redux-form';

import Stepper from '@libComponents/Stepper';
//import { Stepper } from '@formComponents/Stepper';

import FinalPhotoUploadContainer from './FinalPhotoUploadContainer';
import { useContent } from './hooks';
import { FORM_NAME } from './common';

const FinalPhotoUpload = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: v => v
})(({ handleSubmit: handleReduxFormSubmit }) => (
    <Stepper
        content={useContent()}
        ContainerComponent={FinalPhotoUploadContainer}
        ContainerProps={{ handleReduxFormSubmit }}
    />
));

export { FinalPhotoUpload as default, FinalPhotoUpload };
