export const normalizeInkDetails = (payload = []) => {
	const SingleInkMapper = singleInk => {
		const inksMap = {
			colour: 'colour',
			colourRef: 'colourRef',
			inkAutoId: 'inkAutoId',
			inkType: 'InkType'
		};

		let keyChanged = Object.splice(singleInk, inksMap);

		Object.keys(keyChanged).forEach(function(ink) {
			if (ink && typeof keyChanged[ink] === 'object')
				keyChanged[ink] = keyChanged[ink].id || '';
		});

		return keyChanged;
	};

	const mappedPayload = payload.map(inkObj => SingleInkMapper(inkObj));

	return mappedPayload;
};

export const normalizeInkImages = (payload = []) => {
	const SingleImgMapper = singleInk => {
		const inksMap = {
			inkImageRefId: 'id',
			imageUrl50: 'file'
		};

		return Object.splice(singleInk, inksMap);
	};

	const mappedPayload = payload.map(imgObj => SingleImgMapper(imgObj));

	return mappedPayload;
};
