import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Material
import { withStyles, Paper, Popover } from '@material-ui/core';

// Local
import styles from './styles';
import { composeClasses } from 'helpers';
import { createFetchPaginatedDataset, entitySelector } from '@libs/datasets';
import PaginatedDialogToolbar from './PaginatedDialogToolbar';
import PaginatedDialogProgress from './PaginatedDialogProgress';
import PaginatedDialogTable from './PaginatedDialogTable';
import PaginatedDialogFooter from './PaginatedDialogFooter';
import {
    PaginatedSelectContext,
    dialogReducer,
    initialState
} from './PaginatedDialogContext';

/*
 * Domain: --
 * Page: PaginatedSelect
 * Component: PaginatedDialog
 * Type: --
 * PaginatedDialog
 */
class PaginatedDialog extends Component {
    state = {
        dialogState: initialState,
        data: [],
        dialogWidth: 0,
        dialogHeight: 0
    };

    componentDidMount() {
        const {
            props: { data: { data = [] } = {} },
            dispatch
        } = this;
        const dataExists = !!data.length;
        dispatch({ type: dataExists ? 'initialize' : 'query' });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data: { data: prevData = [] } = {} } = prevProps;
        const { data: { data = [] } = {} } = this.props;

        if (prevData !== data) {
            const initialized = !!this.state.dialogState.categories.length;
            this.dispatch({ type: initialized ? 'set-data' : 'initialize' });
        }
    }

    handleRef = ref => {
        try {
            if (!this.state.unitWidth) {
                const { offsetWidth, offsetHeight } = ReactDOM.findDOMNode(ref);

                this.setState({
                    dialogWidth: offsetWidth,
                    dialogHeight: offsetHeight
                });
            }
        } catch {
            this.setState({
                dialogWidth: 0,
                dialogHeight: 0
            });
        }
    };

    query = (
        dialogState,
        { pageNumber = 1, pageSize = 10, filter = false, shouldAppend = false }
    ) => {
        const { fetchPaginatedDataset } = this.props;
        const { search, categories, categoryValue } = dialogState;

        const payload = {
            pageNumber,
            pageSize,
            filterKey: filter ? search : '',
            filterCategory: filter ? categories[categoryValue] : '',
            shouldAppend
        };

        fetchPaginatedDataset(payload);
    };

    dialogMiddleware = (state, action) => {
        switch (action.type) {
            case 'query': {
                const { fetchPaginatedDataset } = this.props;
                const { search, categories, categoryValue } = state;

                fetchPaginatedDataset({
                    pageNumber: 1,
                    pageSize: 10,
                    filterKey: search,
                    filterCategory: categories[categoryValue]
                });

                return action;
            }

            case 'load-more': {
                const { fetchPaginatedDataset } = this.props;
                const { search, categories, categoryValue } = state;

                fetchPaginatedDataset({
                    pageNumber: 2,
                    pageSize: state.data.length,
                    filterKey: search,
                    filterCategory: categories[categoryValue],
                    shouldAppend: true
                });

                return action;
            }

            case 'clear': {
                const {
                    fetchPaginatedDataset,
                    entity,
                    handleChange
                } = this.props;

                handleChange('');

                fetchPaginatedDataset(entity, {
                    pageNumber: 1,
                    pageSize: 10
                });

                return action;
            }

            case 'initialize':
            case 'set-data': {
                return { ...action, payload: this.props.data };
            }

            case 'select-item': {
                const { handleChange, hideDialog } = this.props;
                handleChange(action.payload[0]);
                hideDialog();
                return action;
            }
            default: {
                return action;
            }
        }
    };

    dispatch = action => {
        const {
            state: { dialogState: contextState },
            dialogMiddleware
        } = this;
        const dialogState = dialogReducer(
            contextState,
            dialogMiddleware,
            action
        );
        this.setState({ dialogState });
    };

    render() {
        const { props, state, dispatch, handleRef } = this;
        const { dialogState, dialogWidth, dialogHeight } = state;
        const { classes, styles, hideDialog, anchor } = props;

        const c = composeClasses({ classes, styles });

        const contextPayload = {
            state: dialogState,
            dispatch
        };

        return (
            <Popover
                open={!!anchor}
                onClose={hideDialog}
                anchorEl={anchor}
                PaperProps={{
                    style: { width: dialogWidth, height: dialogHeight }
                }}
            >
                <Paper classes={c} ref={handleRef}>
                    <PaginatedSelectContext.Provider value={contextPayload}>
                        <PaginatedDialogToolbar />
                        <PaginatedDialogProgress />
                        <PaginatedDialogTable />
                        <PaginatedDialogFooter />
                    </PaginatedSelectContext.Provider>
                </Paper>
            </Popover>
        );
    }
}

const mapState = (state, { domain, entity }) => ({
    data: entitySelector(state, domain, entity)
});

const mapDispatch = (dispatch, { domain, entity }) => ({
    fetchPaginatedDataset: payload =>
        dispatch(createFetchPaginatedDataset({ domain, entity })(payload))
});

const _PaginatedDialog = compose(
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(PaginatedDialog);

export { _PaginatedDialog as default, _PaginatedDialog as PaginatedDialog };
