import { takeLatest } from 'redux-saga/effects';

import {
    ADD_COMPOSITION,
    ADD_GSM,
    ADD_DYE_TYPES,
    ADD_FABRIC_FORS,
    ADD_FABRIC_TYPES,
    ADD_FINISH_WASH,
    ADD_FABRIC_GROUP,
    ADD_SUSTAINABILITY
} from 'setup/redux.actionTypes';

import compositionWorker from './compositionWorker.js';
import GSMWorker from './GSMWorker.js';
import dyeTypeWorker from './dyeTypeWorker.js';
import fabricForsWorker from './fabricForsWorker.js';
import fabricTypesWorker from './fabricTypesWorker.js';
import finishWashWorker from './finishWashWorker.js';
import fabricGroupWorker from './fabricGroupWorker.js';
import sustainabilityWorker from './sustainabilityWorker.js';

// Watchers
export default function*() {
    yield takeLatest(ADD_COMPOSITION, compositionWorker);
    yield takeLatest(ADD_GSM, GSMWorker);
    yield takeLatest(ADD_DYE_TYPES, dyeTypeWorker);
    yield takeLatest(ADD_FABRIC_FORS, fabricForsWorker);
    yield takeLatest(ADD_FABRIC_TYPES, fabricTypesWorker);
    yield takeLatest(ADD_FINISH_WASH, finishWashWorker);
    yield takeLatest(ADD_FABRIC_GROUP, fabricGroupWorker);
    yield takeLatest(ADD_SUSTAINABILITY, sustainabilityWorker);
}
