import { deserializeFilename } from '@libs/filenames';

const normalizeAttachments = (attachments, forceNew = false) =>
    attachments.map(({ id, sizeOriginal }) => ({
        id: forceNew || !id ? 0 : id,
        filename: deserializeFilename(sizeOriginal),
        url: sizeOriginal
    }));

const normalizeInputGroup = object => {
    const [[key, value], [key1, value1]] = Object.entries(object);

    return value && value1
        ? { selectedValue: value.trim(), inputValue: value1.trim() }
        : {};
};

const normalizeServerFailedResponse = error => {
    const { response: { data, status } = {}, code } = error || {};
    let serverMessage;

    if (data) {
        const { ErrorMessage } =
            typeof data === 'object' && data !== null
                ? data
                : { ErrorMessage: data };
        serverMessage = `due to ${ErrorMessage}`;
    } else if (code === 'ECONNABORTED') {
        serverMessage =
            '. Looks like the server is taking to long to respond, please try again in sometime';
    } else if (!data && !status) {
        serverMessage = `. Looks like you have an unstable network at the moment, please try again when network stabilizes.`;
    }

    return serverMessage;
};

export {
    normalizeAttachments,
    normalizeInputGroup,
    normalizeServerFailedResponse
};
