import { startSubmit } from 'redux-form';
import { put, call } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';

import { FORM_NAME } from '../../Sample/ArmholeCuff/common';
import { armholeCuffSchema } from './schemas';
import { DOMAIN_NAME } from 'sampling/common';
import { ARMHOLES } from 'sampling/redux.datasets';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const publishData = composeApiPayload(payload, armholeCuffSchema);

        const { data } = yield call(apiRequest, {
            url: 'ArmholeCuff',
            method: id ? 'put' : 'post',
            data: {
                ...publishData,
                id
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: ARMHOLES,
            isNew: id === 0,
            data
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Armhole cuff',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
