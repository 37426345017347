export const normalizeArtworkLocations = ({ data: artworkLocations }) =>
    artworkLocations.map(({ artWorkLocationId, artWorkLocation }) => ({
        artworkLocationId: Number(artWorkLocationId),
        artworkLocation: artWorkLocation
    }));

export const normalizeDesigners = ({ data: designers }) =>
    designers.map(({ designerId, designerName }) => ({
        designerId: Number(designerId),
        designer: designerName
    }));

