import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { Grid, withStyles, Typography } from '@material-ui/core';

import Button from '@libComponents/Button';
import { withFieldArray } from '@libs/reduxForm';
import { GridItem, Input } from '@formComponents';
import { required, email } from '@libs/validationRules';

const uniqueEmailAddress = (value, { emails }, _, fieldName) => {
    // Extract the index from the field name using regular expression
    const regex = /\[(\d+)\]/;
    const match = fieldName.match(regex);
    const currentIndex = match ? parseInt(match[1]) : -1;

    // Check if the entered email (value) exists in the emails array
    // excluding the current element
    const isDuplicate = emails.some(
        (email, index) => email === value && index !== currentIndex
    );

    if (isDuplicate) {
        return 'Email address already exists in the group';
    }

    return undefined; // No error if email is unique
};

const emailValidationRules = [required, email, uniqueEmailAddress];

const propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'emails',
    required: true
};

const styles = ({ spacing, typography, palette }) => ({
    container: {
        width: '100%',
        marginTop: spacing.unit * 2
    },
    message: {
        textAlign: 'center',
        color: palette.error.light,
        margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px`
    },
    serial: {
        fontSize: typography.subtitle1.fontSize,
        margin: spacing.unit * 1,
        fontWeight: typography.fontWeightHeavy
    },
    button: {
        margin: spacing.unit * 2
    }
});

const AddEmailAddress = compose(
    withFieldArray(),
    withStyles(styles)
)(({ classes: c, fields }) => {
    return (
        <div className={c.container}>
            <Typography className={c.message}>
                ***You can include a maximum of 40 email addresses in the group.
            </Typography>
            {fields.map((emailAddress, index) => {
                return (
                    <Grid container key={index}>
                        <GridItem md={1}>
                            <Typography className={c.serial}>
                                {index + 1}.
                            </Typography>
                        </GridItem>
                        <GridItem md={8}>
                            <Input
                                name={emailAddress}
                                placeholder='Enter Email'
                                label=''
                                validate={emailValidationRules}
                                autoFocus={
                                    fields.length - 1 === index &&
                                    !fields.get(index)
                                }
                            />
                        </GridItem>
                        <GridItem md={3}>
                            <Button
                                variant='outlined'
                                color='error'
                                onClick={() => fields.remove(index)}
                            >
                                Remove
                            </Button>
                        </GridItem>
                    </Grid>
                );
            })}
            {fields.length < 40 && (
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => fields.push()}
                    className={c.button}
                >
                    Add Email
                </Button>
            )}
        </div>
    );
});

AddEmailAddress.propTypes = propTypes;
AddEmailAddress.defaultProps = defaultProps;

export { AddEmailAddress as default, AddEmailAddress };
