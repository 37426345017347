import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { userIdSelector } from 'auth/redux.selectors';
import {
    normalizeOrderBasicTrims,
    normalizePaginatedColor
} from './redux.normalizers';
import { entitySelector } from '@libs/datasets';

import { FORM_NAME as ORDER_FORM_NAME, DOMAIN_NAME } from './Order/common';

export const BATCH_CONFIRMATION = '@batch-confirmation';
export const ALL_ORDER_SAMPLES = '@all-order-samples';
export const ORDER_USER_RETAILER_WISE_FACTORIES =
    '@order-user-retailer-wise-factories';
export const PRODUCTION_SAMPLE_TYPE = '@production-sample-type';
export const PO_SHIP_MODE = '@po-ship-mode';
export const PO_SHIP_DESTINATION = '@po-ship-distination';
export const PO_TYPE = '@po-type';
export const NP_COLORS = '@np-colors';
export const PAGINATED_COLORS = '@paginated-colors';
export const ORDER_SUSTAINABILITY = '@order-sustainability';
export const PO_PACK_TYPE = '@po-pack-type';
export const SIZE_SETS = '@size-sets';
export const ORDER_BASIC_TRIMS = '@order-basic-trims';
export const PACKING_PACK_TYPE = '@packing-pack-type';
export const PACKING_BOX_TYPE = '@packing-box-type';
export const ORDER_NOTES = '@order-notes';
export const AMEND_ORDERS = '@amend-orders';
export const INCOMPLETE_ORDERS = '@incomplete-orders';

export const ORDER_EMAIL_GROUP = '@order-email-group';
export const ORDER_EMAIL_BODY = '@order-email-body';
export const COLUMN_GROUP = '@order-column-group';
export const ORDER_DATE_FILTER_FIELD = '@order-date-filter-field';
export const ORDER_BASE_FACTORIES = '@order-base-factories';
export const PAYMENT_TERMS = '@order-payment-terms';
export const SETTLEMENT_DISCOUNT = '@order-settlement-discount';

// Order CP datasets
export const CP_GRID_DATA = '@cp-grid-data';
export const CP_GRID_PREVIEW_DATA = '@cp-grid-preview-data';
export const ORDER_STATUS = '@order-status';
export const CP_SHIPMENT_DELAY_REASON = '@cp-shipment-delay-reason';

export default {
    [BATCH_CONFIRMATION]: {
        url: 'ConfirmationMains',
        fields: {
            confirmRef: 'Confirm Reference'
        }
    },
    [ALL_ORDER_SAMPLES]: {
        url: 'Sample/GetOrderSamples',
        params: state => {
            const { retailer, isTBC, type } = formValueSelector(
                ORDER_FORM_NAME
            )(state, 'retailer', 'isTBC', 'type');
            return {
                userId: userIdSelector(state),
                retailerId: retailer,
                isTbc: !!isTBC,
                garmentsType: type
            };
        },
        fields: {
            sampleRef: 'Sample Reference',
            department: 'Department',
            garmentsType: 'Product Type',
            sampleDescription: 'Description'
        },
        selector: () => false
    },
    [ORDER_USER_RETAILER_WISE_FACTORIES]: {
        url: 'FactoryRetailers',
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    },
    [PRODUCTION_SAMPLE_TYPE]: {
        url: 'ProductionSampleTypes'
    },
    [PO_SHIP_MODE]: {
        url: 'ShipModeRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [PO_SHIP_DESTINATION]: {
        url: 'DestinationRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [PO_TYPE]: {
        url: 'PoTypeRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [NP_COLORS]: {
        url: 'Colours/GetColoursByRetailer',
        params: state => ({
            retailerId: formValueSelector(ORDER_FORM_NAME)(state, 'retailer')
        }),
        selector: createSelector(
            state => entitySelector(state, DOMAIN_NAME, NP_COLORS),
            state => formValueSelector(ORDER_FORM_NAME)(state, 'retailer'),
            (data, retailer) => data[0] && data[0].retailerId === retailer
        )
    },
    [PAGINATED_COLORS]: {
        url: 'Colours/GetPaginatedColours',
        fields: {
            colorName: 'Color'
        },
        normalizer: normalizePaginatedColor,
        selector: () => false
    },
    [ORDER_SUSTAINABILITY]: {
        url: 'OrderSustainabilities'
    },
    [PO_PACK_TYPE]: {
        url: 'PoPack'
    },
    [SIZE_SETS]: {
        url: 'PoSizes/GetPoSizeSetByRetailerId',
        params: state => ({
            retailerId: formValueSelector(ORDER_FORM_NAME)(state, 'retailer')
        }),
        selector: createSelector(
            state => entitySelector(state, DOMAIN_NAME, SIZE_SETS),
            state => formValueSelector(ORDER_FORM_NAME)(state, 'retailer'),
            (data, retailer) => data[0] && data[0].retailerId === retailer
        )
    },
    [ORDER_BASIC_TRIMS]: {
        url: 'OrderBasicTrims/GetOrderBasicTrimsByRetailerId',
        params: state => ({
            retailerId: formValueSelector(ORDER_FORM_NAME)(state, 'retailer')
        }),
        fields: {
            labelRef: 'Label Ref',
            type: 'Type',
            color: 'Color',
            description: 'Description'
        },
        normalizer: normalizeOrderBasicTrims,
        selector: () => false
    },
    [PACKING_PACK_TYPE]: {
        url: 'PackTypeRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [PACKING_BOX_TYPE]: {
        url: 'BoxTypeRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [ORDER_NOTES]: {
        url: 'OrderNoteRetailers',
        params: state => ({
            userId: userIdSelector(state)
        })
    },
    [INCOMPLETE_ORDERS]: {
        url: 'OrderMains/GetDropdownOrders',
        params: state => ({
            userId: userIdSelector(state),
            isAmend: false
        }),
        fields: {
            echoOrderNo: 'Order No',
            factoryRef: 'Factory Ref',
            styleNo: 'Style',
            retailer: 'Retailer'
        },
        selector: () => false
    },
    [AMEND_ORDERS]: {
        url: 'OrderMains/GetDropdownOrders',
        params: state => ({
            userId: userIdSelector(state),
            isAmend: true
        }),
        fields: {
            echoOrderNo: 'Order No',
            factoryRef: 'Factory Ref',
            styleNo: 'Style',
            retailer: 'Retailer',
            orderStatus: 'Status'
        },
        selector: () => false
    },
    [ORDER_EMAIL_GROUP]: {
        url: 'OrderEmailGroup',
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    },
    [ORDER_EMAIL_BODY]: {
        url: 'OrderEmailBody',
        params: state => ({
            userId: userIdSelector(state)
        }),
        selector: () => false
    },
    [COLUMN_GROUP]: {
        url: 'OrderCpGroupLogs'
    },
    [ORDER_BASE_FACTORIES]: {
        url: 'OrderFactories'
    },
    [PAYMENT_TERMS]: {
        url: 'paymentTerms'
    },
    [SETTLEMENT_DISCOUNT]: {
        url: 'settlementDiscount'
    },
    [ORDER_DATE_FILTER_FIELD]: {
        url: 'OrderDateFilter'
    },

    // New CP related api endpoints
    [ORDER_STATUS]: {
        url: 'orderStatus'
    },
    [CP_SHIPMENT_DELAY_REASON]: {
        url: 'shipmentDelayStatus'
    }
};
