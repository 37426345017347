export default ({ typography, palette, spacing }) => ({
    container: {
        width: '100%',
        display: 'flex',
    },
    select: {
        width: '100%',
    },
    buttonContainer: {
        width: spacing.unit * 7,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingBottom: spacing.unitPadding,
    },
    button: {
        minWidth: 'unset',
        width: spacing.unit * 6,
        height: spacing.unit * 6,
        marginLeft: spacing.unit,
        fontSize: typography.h4.fontSize,
        borderColor: palette.grey[300],
        '&:hover': {
            borderColor: palette.grey[400],
        },
        lineHeight: 1.1,
    },
    icon: {
        color: palette.grey[800],
    },
});
