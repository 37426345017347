import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

import { withStyles, Grid, Divider } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { formListener } from 'app/ReduxGate/store/middleware';
import {
    GridContainer,
    GridItem,
    PaginatedSelect,
    Input,
    Date as DateInput,
    Select,
    Checkbox
} from '@formComponents';

import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { SPECIAL_FINISHES } from 'sampling/redux.datasets';

import { FORM_NAME, LABELS, DOMAIN_NAME } from '../../common';
import { SET_SEAL_SAMPLE_COLOR_CHILDREN } from '../../../redux.actionTypes';
import { getCopyPreproductionQCData } from '../../../redux.actions';
import { colorChildrenLoadingSelector } from '../../../redux.selectors';
import { useSealSampleState } from '../../useSealSample';
import { LAUNDRY_STATUS } from 'qualityControl/redux.datasets';
import { AMEND_SEAL_SAMPLE, ORDERS_LIST } from '../../../redux.datasets';
import HeaderAdornment from '../HeaderAdornment';
import SelectQCStyles from './SelectQCStyles';
import SelectQCColors from './SelectQCColors';
import BulkProductionContainer from './BulkProductionContainer';

const styles = ({ spacing, typography }) => ({
    container: {
        paddingBottom: spacing.unit * 4
    },
    checkBoxes: {
        marginTop: spacing.unit * 2
    },
    divider: {
        width: '100%',
        marginTop: spacing.unit * 1.5,
        marginBottom: spacing.unit * 3
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const mapState = state => ({
    isColorChildrenLoading: colorChildrenLoadingSelector(state),
    ...formValueSelector(FORM_NAME)(
        state,
        'jwpNo',
        'qcStyle',
        'hasCopyReference'
    )
});

const mapInputs = {
    hasCopyReference: ({ payload }) => {
        if (!payload) return change(FORM_NAME, 'copyReference', '');
    },
    copyReference: ({ payload }) => getCopyPreproductionQCData(payload),
    jwpNo: () => change(FORM_NAME, 'qcStyle', ''),
    qcStyle: () => change(FORM_NAME, 'qcColor', ''),
    qcColor: ({ payload }) => ({
        type: SET_SEAL_SAMPLE_COLOR_CHILDREN,
        payload
    })
};

const Setup = compose(
    connect(mapState),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        isColorChildrenLoading,
        jwpNo,
        qcStyle,
        hasCopyReference
    }) => {
        const { setupLocked, mode, isQA } = useSealSampleState();

        return (
            <ContentContainer
                title={LABELS.setup}
                AdornmentComponent={<HeaderAdornment />}
            >
                <GridContainer className={c.container}>
                    {mode === 'new' && (
                        <Fragment>
                            <Grid container className={c.checkBoxes}>
                                <GridItem md={4}>
                                    <Checkbox
                                        name='hasCopyReference'
                                        label='Copy data from another'
                                        styles={{
                                            label: c.checkboxLabel
                                        }}
                                        disabled={setupLocked}
                                    />
                                </GridItem>

                                <GridItem md={4} />
                                <GridItem md={4} />
                                <GridItem md={4}>
                                    {hasCopyReference &&
                                        (setupLocked ? (
                                            <Input
                                                name='copyReference'
                                                placeholder=' '
                                                required
                                                disabled
                                            />
                                        ) : (
                                            <PaginatedSelect
                                                name='copyReference'
                                                entity={AMEND_SEAL_SAMPLE}
                                                domain={DOMAIN_NAME}
                                            />
                                        ))}
                                </GridItem>
                            </Grid>
                            <Divider className={c.divider} />
                        </Fragment>
                    )}
                    <GridItem md={4}>
                        <PaginatedSelect
                            name='jwpNo'
                            entity={ORDERS_LIST}
                            domain={DOMAIN_NAME}
                            label='Select JWP'
                            placeholder='Select JWP'
                            required
                            disabled={setupLocked}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectQCStyles
                            disabled={setupLocked || !jwpNo}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectQCColors
                            disabled={setupLocked || !qcStyle}
                            required
                        />
                    </GridItem>
                    {isQA ? (
                        <>
                            <GridItem md={4}>
                                <DateInput
                                    name='qcDate'
                                    label='QC Date'
                                    required
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <DateInput
                                    name='sealDate'
                                    label='Seal by Date'
                                    disabled
                                />
                            </GridItem>
                        </>
                    ) : (
                        <GridItem md={4}>
                            <DateInput
                                name='sealDate'
                                label='Seal by Date'
                                required
                            />
                        </GridItem>
                    )}

                    <GridItem md={4}>
                        <Input
                            name='factory'
                            label='Factory'
                            disabled
                            loading={isColorChildrenLoading}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='quantity'
                            label='Total Qty'
                            placeholder='Quantity'
                            disabled
                            loading={isColorChildrenLoading}
                        />
                    </GridItem>
                    <GridItem md={8}>
                        <Input
                            name='styleDesc'
                            label='Style Description'
                            disabled
                            loading={isColorChildrenLoading}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Select
                            name='finishWash'
                            label='Finish Wash'
                            domain={SAMPLING_DOMAIN_NAME}
                            entity={SPECIAL_FINISHES}
                            loading={isColorChildrenLoading}
                            disabled={isQA}
                        />
                    </GridItem>
                    {isQA && (
                        <>
                            <GridItem md={4}>
                                <Input
                                    name='fabricBatch'
                                    label='Fabric Batch'
                                />
                            </GridItem>

                            <GridItem md={4}>
                                <Input
                                    name='prodUnit'
                                    label='Factory Prod Unit'
                                />
                            </GridItem>
                            <GridItem md={4}>
                                <Select
                                    name='panelStatus'
                                    label='Prewash Panel Status'
                                    placeholder='Prewash Status'
                                    domain={DOMAIN_NAME}
                                    entity={LAUNDRY_STATUS}
                                />
                            </GridItem>
                        </>
                    )}
                </GridContainer>
                {isQA && <BulkProductionContainer />}
            </ContentContainer>
        );
    }
);

export default Setup;
