import { fork } from 'redux-saga/effects';

// Local
import artworkWatchers from './artwork';
//import backnecksWatchers from './backnecks';
import cadWatchers from './cad';
import commonWatchers from './common';
import fabricsWatchers from './fabrics';
import inksWatchers from './inks';
import otherWatchers from './other';
import referenceWatchers from './reference';
import requestWatchers from './request';
import specWatchers from './spec';
import trimsWatchers from './trims';

const samplingSaga = function*() {
    yield fork(artworkWatchers);
    // yield fork( backnecksWatchers);
    yield fork(cadWatchers);
    yield fork(commonWatchers);
    yield fork(fabricsWatchers);
    yield fork(inksWatchers);
    yield fork(otherWatchers);
    yield fork(referenceWatchers);
    yield fork(requestWatchers);
    yield fork(specWatchers);
    yield fork(trimsWatchers);
};

export { samplingSaga as default, samplingSaga };
