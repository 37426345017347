import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { Grid, withStyles } from '@material-ui/core';
// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { Checkbox, GridItem } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware';
import { fetchDatasets } from '@libs/datasets';
// Local
import {
    resetSamplesRefs,
    setSameStyleInAllPacks,
    setSampleRefsAmend
} from 'orders/redux.actions';
import { FORM_NAME, LABELS } from '../../common';
import { useOrderState } from '../../useOrder';
import { GET_SAMPLE_SIZES } from 'sampling/redux.datasets';
import { SelectRetailers } from 'sampling/components';
import HeaderAdornment from '../HeaderAdornment';
import SelectGarmentsType from './SelectGarmentsType';
import SamplesContainer from './SamplesContainer';
import NumberOfPacks from './NumberOfPacks';

const styles = ({ spacing, typography, palette }) => ({
    container: {
        paddingTop: spacing.unit * 4,
        paddingBottom: spacing.unit * 6
    },
    button: {
        height: '100%',
        fontSize: typography.subtitle1.fontSize
    },
    filterContainer: {
        width: '100%',
        padding: `${spacing.unit * 3}px ${spacing.unit * 1}px`,
        //backgroundColor: 'rgba(0,0,0,0.05)',

        borderRadius: 6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark
    },
    tbcWrapper: {
        position: 'relative'
    },
    tbc: {
        position: 'absolute',
        right: 0,
        top: -2
    },
    checkboxLabel: { fontSize: typography.caption.fontSize },
    checkboxIcon: { width: spacing.unit * 2.5, height: spacing.unit * 2.5 }
});

const mapDispatch = { fetchDatasets };

const mapInputs = {
    type: ({ meta: { field } }) => resetSamplesRefs(field),
    isTBC: ({ meta: { field } }) => resetSamplesRefs(field),
    retailer: ({ meta: { field } }) => resetSamplesRefs(field),
    noOfPacks: ({ meta: { field } }) => resetSamplesRefs(field),
    'sampleRefs[*]': ({ meta: { mode } }) =>
        mode !== 'new' && setSampleRefsAmend(),
    hasSameStyle: ({ payload }) => setSameStyleInAllPacks(payload)
};

const Setup = compose(
    connect(
        null,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, fetchDatasets }) => {
    const { setupLocked, mode } = useOrderState();
    const orderSamplesLocked = mode === 'new' && setupLocked;

    useEffect(() => {
        fetchDatasets(GET_SAMPLE_SIZES);
    }, []);

    return (
        <ContentContainer
            title={LABELS.setup}
            AdornmentComponent={<HeaderAdornment />}
        >
            <>
                <Grid container className={c.container}>
                    <GridItem md={4}>
                        <Grid container className={c.filterContainer}>
                            <GridItem md={12} className={c.tbcWrapper}>
                                <SelectGarmentsType
                                    required
                                    disabled={setupLocked}
                                />
                                <div className={c.tbc}>
                                    <Checkbox
                                        name='isTBC'
                                        label='TBC Order'
                                        styles={{
                                            label: c.checkboxLabel,
                                            icon: c.checkboxIcon
                                        }}
                                        disabled={setupLocked}
                                    />
                                </div>
                            </GridItem>
                            <GridItem md={12}>
                                <SelectRetailers
                                    required
                                    disabled={setupLocked}
                                />
                            </GridItem>
                            <GridItem md={12}>
                                <NumberOfPacks disabled={setupLocked} />
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem md={7}>
                        <SamplesContainer
                            orderSamplesLocked={orderSamplesLocked}
                            mode={mode}
                        />
                    </GridItem>
                </Grid>
            </>
        </ContentContainer>
    );
});

export default Setup;
