import { useMemo, useRef, useCallback } from 'react';

const useScrollToErrorField = (formErrors, formFields, formName) => {
    const formRef = useRef(null);
    const fieldPrefix = `${formName}-`;

    const findFirstErrorFieldRecursively = useCallback((errors, fields) => {
        if (!errors || Object.keys(errors).length === 0) return {};

        for (const field of fields) {
            const errorValue = errors[field.name];
            if (field.type === 'array' && Array.isArray(errorValue)) {
                for (let i = 0; i < errorValue.length; i++) {
                    const nestedErrors = errorValue[i];
                    if (field.fields) {
                        const nestedErrorField = findFirstErrorFieldRecursively(
                            nestedErrors,
                            field.fields
                        );
                        if (Object.keys(nestedErrorField).length > 0) {
                            return {
                                name: `${field.name}[${i}].${nestedErrorField.name}`,
                                type: nestedErrorField.type
                            };
                        }
                    } else if (errorValue[i]) {
                        return { name: `${field.name}[${i}]` };
                    }
                }
            } else if (errorValue) {
                if (typeof errorValue === 'object') {
                    const errorKey = Object.keys(errorValue)[0];
                    return {
                        name: `${field.name}.${errorKey}`,
                        type: field.type
                    };
                }
                // If the field has a direct error
                return { name: field.name, type: field.type };
            }
        }

        return {}; // Return an empty object if no error field is found in the formFields
    }, []);

    const handleScrollToErrorField = useCallback(() => {
        // Find the first error field
        const {
            name: firstErrorField,
            type: firstErrorFieldType
        } = findFirstErrorFieldRecursively(formErrors, formFields);

        if (!firstErrorField) return;

        const fieldSelector = `${fieldPrefix}${firstErrorField}`;
        const escapeFieldSelector = fieldSelector.replace(
            /[-[\]{}()*+?.,\\^$|#\s]/g,
            '\\$&'
        );
        const errorFieldElement =
            formRef && formRef.current.querySelector(`#${escapeFieldSelector}`);

        if (errorFieldElement) {
            errorFieldElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
            if (firstErrorFieldType === 'input') {
                errorFieldElement.focus();
            }
        }
    }, [JSON.stringify(formErrors), formFields, fieldPrefix]);

    return useMemo(() => {
        return [formRef, handleScrollToErrorField];
    }, [handleScrollToErrorField]);
};

export default useScrollToErrorField;
