import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextLogo } from 'assets';
import styles from './styles';

class AuthContainer extends PureComponent {
    render() {
        const { props } = this;
        const { classes: c, children, title } = props;

        return (
            <div className={c.containerWrappper}>
                <div className={c.containerLeftColumn} />
                <div className={c.containerRightColumn}>
                    <div className={c.containerRightColumnContents}>
                        <img
                            src={TextLogo}
                            className={c.rightLogo}
                            alt='Right'
                        />
                        <h4 className={c.containerTitle}>{title}</h4>

                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

AuthContainer.defaultProps = {
    title: ''
};

AuthContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string
};

const _AuthContainer = withRouter(withStyles(styles)(AuthContainer));

export { _AuthContainer as default, _AuthContainer as AuthContainer };
