import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';

import { FULL_WIDTH_UNITS, FULL_HEIGHT_UNITS } from './constants';
import Summary from './Summary/';
import Description from './Description';

const propTypes = {};

const styles = ({ spacing, typography }) => ({
    page: {
        width: spacing.unit * FULL_WIDTH_UNITS,
        height: spacing.unit * FULL_HEIGHT_UNITS,
        padding: spacing.unit * 2
    },
    titleContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center'
    },
    title: {
        fontSize: 16,
        fontWeight: typography.fontWeightHeavy,
        textTransform: 'uppercase',
        marginBottom: spacing.unit * 1
    }
});

const PDF = withPDFStyles(styles, { withTheme: true })(
    ({ classes: c, summary, ...rest }) => {
        return (
            <Document>
                <Page size='A4' style={c.page}>
                    <View style={c.titleContainer}>
                        <Text style={c.title}>Designer Report</Text>
                    </View>
                    <Summary summary={summary} />
                    <Description {...rest} />
                </Page>
            </Document>
        );
    }
);

PDF.propTypes = propTypes;

export { PDF as default, PDF };
