import { useMemo } from 'react';

export const useQuantityReport = data => {
    return useMemo(() => {
        const firstColumn = data.slice(0, 3);
        const secondColumn = data.slice(3, 6);
        const thirdColumn = data.slice(6, 9);
        return { firstColumn, secondColumn, thirdColumn };
    }, [data]);
};
