import React from 'react';
import { withStyles } from '@material-ui/core';
import { string } from 'prop-types';
import { Link as RRLink } from 'react-router-dom';

const styles = ({ palette, typography }) => ({
    link: {
        textDecoration: 'none',
        color: palette.primary.main,
        fontWeight: typography.fontWeightSemi,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const propTypes = {
    param: string.isRequired,
    targetLink: string.isRequired
};

const Link = withStyles(styles)(({ param, targetLink, classes: { link } }) => {
    return (
        <RRLink to={`${targetLink}/${param}`} target='_blank' className={link}>
            {param}
        </RRLink>
    );
});

Link.propTypes = propTypes;

export { Link as default, Link };
