export default ({ typography }) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    finalPageIcon: {
        fontSize: typography.display1.fontSize,
    },
    immediatePageIcon: {
        fontSize: typography.display1.fontSize.slice(0, 3) * 0.82 + 'rem',
    },
});
