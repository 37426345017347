import React from 'react';

import FabricDevelopment from './FabricDevelopment';
import FabricDevelopmentView from './FabricDevelopmentView';

import {
    NEW_FABRIC_DEVELOPMENT_ROUTE_ID,
    INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID,
    AMEND_FABRIC_DEVELOPMENT_ROUTE_ID,
    DEVELOPED_FABRIC_ROUTE_ID
} from './FabricDevelopment/common';
import { FABRIC_DEVELOPMENT_VIEW_ROUTE_ID } from './FabricDevelopmentView/common';

export default {
    [NEW_FABRIC_DEVELOPMENT_ROUTE_ID]: () => <FabricDevelopment />,
    [INCOMPLETE_FABRIC_DEVELOPMENT_ROUTE_ID]: () => (
        <FabricDevelopment mode='incomplete' />
    ),
    [AMEND_FABRIC_DEVELOPMENT_ROUTE_ID]: () => (
        <FabricDevelopment mode='amend' />
    ),
    [DEVELOPED_FABRIC_ROUTE_ID]: () => (
        <FabricDevelopment mode='developedFabric' />
    ),
    [FABRIC_DEVELOPMENT_VIEW_ROUTE_ID]: () => <FabricDevelopmentView />
};
