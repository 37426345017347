import React, { useMemo } from 'react';

import { SAMPLE_FACTORY } from 'sampling/redux.datasets';
import { DOMAIN_NAME } from 'sampling/common';

import SetupBase from 'setup/components/SetupBase';
import HeaderAdornment from './HeaderAdornment';
import RowComponent from './RowComponent';

const SampleFactory = () => {
    const headers = useMemo(
        () => [
            { label: '#', canSort: false },
            { label: 'Factory Name', canSort: true, sortKey: 'factoryName' },
            { label: 'Factory Code', canSort: true, sortKey: 'factoryCode' },
            { label: 'Factory Number', canSort: true, sortKey: 'factoryNo' },
            { label: 'Country', canSort: true, sortKey: 'country' },
            { label: 'Is Active', canSort: true, sortKey: 'isActive' },
            { label: 'Action', canSort: false }
        ],
        []
    );

    const baseProps = {
        title: 'SAMPLE FACTORY SETUP',
        domain: DOMAIN_NAME,
        entity: SAMPLE_FACTORY,
        AdornmentComponent: HeaderAdornment,
        headers,
        RowComponent
    };

    return <SetupBase {...baseProps} />;
};

export { SampleFactory as default, SampleFactory };
