import { DOMAIN_NAME } from '../common';

const FORM_NAME = `user`;

const LABELS = {
    basicInformation: 'Basic Info',
    permissions: 'Permissions',
    complete: 'Complete!'
};

const BASIC_FIELDS = [
    { name: 'name', type: 'text' },
    { name: 'email', type: 'text' },
    { name: 'role', type: 'select' },
    { name: 'reportTo', type: 'select' }
];

const PERMISSION_FIELDS = [{ name: 'copyUser' }, { name: 'permissions' }];

const INITIAL_VALUES = { active: true };

const NEW_USER_ROUTE_ID = 7;
const AMEND_USER_ROUTE_ID = 622;
const ADMIN_ROLE_ID = 1;
const HEAD_OF_DEPT_ROLE_ID = 2;
const GENERAL_USER_ROLE_ID = 3;

export {
    DOMAIN_NAME,
    FORM_NAME,
    LABELS,
    INITIAL_VALUES,
    NEW_USER_ROUTE_ID,
    AMEND_USER_ROUTE_ID,
    BASIC_FIELDS,
    PERMISSION_FIELDS,
    ADMIN_ROLE_ID,
    HEAD_OF_DEPT_ROLE_ID,
    GENERAL_USER_ROLE_ID
};
