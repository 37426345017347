import { startSubmit } from 'redux-form';
import { put, call, all, select } from 'redux-saga/effects';

import apiRequest from '@libs/apiRequest';
import composeApiPayload from '@libs/composeApiPayload';
import { getAssetBlobsWorker, uploadAssetsWorker } from '@libs/publishWorkers';
import { sizePathsSelector } from 'common/redux.selectors';
import { normalizeImagePayload } from 'sampling/redux.normalizers';

import { DOMAIN_NAME } from 'setup/common';
import { TRIM_LABEL } from 'setup/redux.datasets';
import { FORM_NAME } from '../../Order/TrimLabel/common';
import { trimLabelSchema } from './schemas';
import updateDatasetWorker from '../updateDatasetWorker';
import setupFormHandleSuccessWorker from '../setupFormHandleSuccessWorker';
import setupFormHandleErrorWorker from '../setupFormHandleErrorWorker';

export default function*(action) {
    const {
        meta: { handleCloseModal },
        payload: { id = 0, ...payload }
    } = action;

    try {
        yield put(startSubmit(FORM_NAME));

        const preparedPayload = {
            ...payload,
            trimTypeId: payload.trimType,
            retailerId: payload.retailer
        };

        const publishData = composeApiPayload(preparedPayload, trimLabelSchema);
        let originalId = id;
        if (id === 0) {
            const { data } = yield call(apiRequest, {
                url: 'OrderBasicTrims/AddBasicTrim',
                method: 'put',
                data: {
                    ...publishData,
                    id
                }
            });

            originalId = data.id;
        }

        const handleLoading = f => f;

        const [trimLabelImageBlobs, trimLabelImageSizePaths] = yield all([
            call(getAssetBlobsWorker, {
                assets: payload.image,
                mode: id ? 'amend' : 'new',
                handleLoading
            }),
            select(sizePathsSelector, 'basicTrim')
        ]);

        const [trimLabelImagePayload = {}] = yield call(uploadAssetsWorker, {
            assetBlobs: trimLabelImageBlobs,
            folderPath: `${trimLabelImageSizePaths.sizeOriginal}`,
            reference: `${payload.trimType}-${payload.trimRef}-${originalId}`,
            sizePaths: trimLabelImageSizePaths,
            handleLoading,
            developmentName: ''
        });

        const { data } = yield call(apiRequest, {
            url: 'OrderBasicTrims/AddBasicTrim',
            method: 'put',
            data: {
                ...publishData,
                id: originalId,
                images: normalizeImagePayload(trimLabelImagePayload)
            }
        });

        yield call(updateDatasetWorker, {
            domain: DOMAIN_NAME,
            entity: TRIM_LABEL,
            isNew: id === 0,
            data
        });

        yield* setupFormHandleSuccessWorker(
            id,
            handleCloseModal,
            'Trim label',
            FORM_NAME
        );
    } catch (error) {
        yield* setupFormHandleErrorWorker(error, id, FORM_NAME);
    }
}
