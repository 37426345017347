import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles, Typography } from '@material-ui/core';

// Libs
import { valueSelector } from '@libs/datasets';

// Local
import { DOMAIN_NAME } from 'sampling/common';
import { SEASONS } from 'sampling/redux.datasets';
import {
    DISPATCH_TYPE,
    DISPATCH_SIZE,
    SAMPLE_FACTORY
} from 'sampling/redux.datasets';
import { isSeasaltSelector } from 'sampling/redux.selectors';
import { getHeaders } from '../common';
import Image from './Image';
import Actions from './Actions';

const propTypes = {
    dispatchType: PropTypes.number.isRequired,
    size: PropTypes.number,
    factory: PropTypes.number,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLast: PropTypes.bool.isRequired
};

const mapState = (state, { dispatchType, size, factory, season }) => {
    return {
        dispatchType: valueSelector(
            state,
            DOMAIN_NAME,
            DISPATCH_TYPE,
            dispatchType
        ),
        size: valueSelector(state, DOMAIN_NAME, DISPATCH_SIZE, size),
        factory: valueSelector(state, DOMAIN_NAME, SAMPLE_FACTORY, factory),
        season: valueSelector(state, DOMAIN_NAME, SEASONS, season),
        isSeasalt: isSeasaltSelector(state)
    };
};

const styles = ({ spacing, palette }) => ({
    row: {
        display: 'flex',
        justifyContent: 'space-between',

        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: palette.background.dark
    },
    cellWrapper: {
        flex: 1,
        padding: spacing.unit,
        overflowWrap: 'break-word',
        overflow: 'hidden',

        '&:not(:last-child)': {
            borderRightStyle: 'solid',
            borderRightWidth: 1,
            borderRightColor: palette.background.dark
        }
    },
    cell: {
        fontSize: spacing.unit * 1.5
    },
    borderBottomNone: {
        borderBottom: 'none'
    }
});

const Row = compose(
    connect(mapState),
    withStyles(styles)
)(({ classes: c, handleEdit, handleDelete, isLast, isSeasalt, ...props }) => {
    return (
        <div className={clsx(c.row, isLast && c.borderBottomNone)}>
            {getHeaders(isSeasalt).map(({ name }) => {
                const propertyValue = props[name];
                const prepareValue = Array.isArray(propertyValue)
                    ? propertyValue.join(', ')
                    : propertyValue;

                if (name === 'sampleImage' && prepareValue) {
                    return (
                        <div key={name} className={c.cellWrapper}>
                            <Image
                                url={prepareValue}
                                sampleRef={props.sampleRef}
                            />
                        </div>
                    );
                } else if (name === 'action') {
                    return (
                        <div key={name} className={c.cellWrapper}>
                            <Actions
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div key={name} className={c.cellWrapper}>
                            <Typography className={c.cell}>
                                {prepareValue || 'N/A'}
                            </Typography>
                        </div>
                    );
                }
            })}
        </div>
    );
});
Row.propTypes = propTypes;

export default Row;
