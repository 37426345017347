import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import {
    withStyles,
    Popover,
    List,
    ListItem,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

const propTypes = {
    operatorOptions: PropTypes.object.isRequired,
    onFilterOperatorChange: PropTypes.func.isRequired,
    handleClearFilter: PropTypes.func.isRequired,
    handleClearAllFilter: PropTypes.func.isRequired,
    filterOperatorValue: PropTypes.string.isRequired,
    hasFilterApplied: PropTypes.bool.isRequired,
    hasAnyFilterApplied: PropTypes.bool.isRequired
};

const styles = ({ spacing, palette }) => ({
    gridFilterSettingsIcon: {
        margin: spacing.unit * 0.5,
        color: palette.grey[400],
        '&:hover': {
            cursor: 'pointer',
            color: palette.grey[600]
        }
    },
    gridFilterListItem: {
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover': {
            backgroundColor: palette.grey[300],
            cursor: 'pointer'
        }
    },
    gridFilterListItemRadio: {
        padding: spacing.unit * 1
    },
    gridHeaderFilterSettingsDivider: {
        width: '100%',
        marginTop: spacing.unit * 0.5,
        marginBottom: spacing.unit * 0.5
    },
    gridFilterClearItem: {
        '&:hover': {
            backgroundColor: palette.grey[300],
            cursor: 'pointer'
        }
    },
    gridFilterClearItemDisabled: {
        pointerEvents: 'none'
    }
});

const FilterSettings = withStyles(styles)(
    ({
        classes: c,
        operatorOptions,
        onFilterOperatorChange,
        handleClearFilter,
        handleClearAllFilter,
        hasFilterApplied,
        hasAnyFilterApplied,
        filterOperatorValue
    }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleFilterSettingsClick = useCallback(event => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleFilterSettingsClose = useCallback(() => {
            setAnchorEl(null);
        }, []);

        const handleFilterOperatorChange = useCallback(
            e => {
                setAnchorEl(null);
                onFilterOperatorChange(e.target.value);
            },
            [onFilterOperatorChange]
        );

        const handleFilterClearAction = useCallback(
            action => () => {
                setAnchorEl(null);
                action();
            },
            []
        );

        return (
            <div>
                <FilterList
                    onClick={handleFilterSettingsClick}
                    className={c.gridFilterSettingsIcon}
                />
                <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleFilterSettingsClose}
                >
                    <List>
                        {Object.entries(operatorOptions).map(
                            ([value, label]) => (
                                <label key={value} htmlFor={value}>
                                    <ListItem
                                        classes={{ root: c.gridFilterListItem }}
                                    >
                                        <RadioGroup
                                            value={filterOperatorValue}
                                            onChange={
                                                handleFilterOperatorChange
                                            }
                                        >
                                            <FormControlLabel
                                                value={value}
                                                control={
                                                    <Radio
                                                        id={value}
                                                        classes={{
                                                            root:
                                                                c.gridFilterListItemRadio
                                                        }}
                                                    />
                                                }
                                                label={label}
                                            />
                                        </RadioGroup>
                                    </ListItem>
                                </label>
                            )
                        )}
                        <Divider
                            className={c.gridHeaderFilterSettingsDivider}
                        />
                        <ListItem
                            className={clsx(
                                c.gridFilterClearItem,
                                !hasFilterApplied &&
                                    c.gridFilterClearItemDisabled
                            )}
                            onClick={handleFilterClearAction(handleClearFilter)}
                            disabled={!hasFilterApplied}
                        >
                            Clear
                        </ListItem>
                        <ListItem
                            className={clsx(
                                c.gridFilterClearItem,
                                !hasAnyFilterApplied &&
                                    c.gridFilterClearItemDisabled
                            )}
                            onClick={handleFilterClearAction(
                                handleClearAllFilter
                            )}
                            disabled={!hasAnyFilterApplied}
                        >
                            Clear All
                        </ListItem>
                    </List>
                </Popover>
            </div>
        );
    }
);

FilterSettings.propTypes = propTypes;

export default FilterSettings;
