const styles = theme => ({
    stepperTitleBar: {
        display: 'flex',
    },
    stepperTitle: {
        textTransform: 'uppercase',
        fontSize: '26px',
        color: '#333333',
        marginBottom: '10px',
        fontWeight: 'bold',
        flexGrow: 1,
    },
    handoverButton: {
        marginTop: '6px',
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '100px',
        height: '35px',
        color: 'white',
        backgroundColor: '#000000',
        borderColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
            borderColor: '#000000',
        },
        '&.active': {
            backgroundColor: '#000000',
            borderColor: '#000000',
        },
    },
    publishButton: {
        marginTop: '6px',
        marginLeft: '10px',
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '170px',
        height: '35px',
        color: 'white',
        backgroundColor: '#387ff5',
        borderColor: '#387ff5',
        '&:hover': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
        },
        '&.active': {
            backgroundColor: '#387ff5',
            borderColor: '#387ff5',
            opacity: '1',
        },
    },
    disabledPublishButton: {
        color: '#ffffff !important',
        backgroundColor: '#96baf5 !important',
    },
});

export { styles as default, styles };
