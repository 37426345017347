const isLoading = (state, thisType) => {
	let loading = false;
	const types = state.xtype;
	if (types.length > 0) {
		if (types.includes(thisType)) loading = true;
	}

	return loading;
};

export { isLoading as default, isLoading };
