import React from 'react';
import { compose } from 'redux';

import {
    normalizeDate,
    getCurrentFinancialYear,
    requestedDateAfterNewImplementation
} from './normalizeDate';

import { withField } from '@libs/reduxForm';

import DateBase from './DateBase';

const DateInput = compose(withField({ normalize: normalizeDate }))(props => {
    return <DateBase {...props} />;
});

export {
    DateInput as default,
    DateInput as Date,
    normalizeDate,
    getCurrentFinancialYear,
    requestedDateAfterNewImplementation,
    DateBase
};
