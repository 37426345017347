import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

// Material
import { withStyles, CircularProgress } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';
import { withFieldArray } from '@libs/reduxForm';

// Local
import SizeBreakdown from '../../SizeBreakdown';
import Adornment from './Adornment';
import {
    poSizeSetLoadingSelector,
    labDipColorLoadingSelector,
    isNinetyPercentSelector,
    canGenerateSKUSelector
} from 'orders/redux.selectors';
import { generatePOSizeSKU } from 'orders/redux.actions';
import { validateSizeDetails, validateSizeDetailsFields } from './validation';
import { PO_FORM_NAME } from 'orders/Order/common';

const propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'sizeDetails',
    required: true,
    validate: validateSizeDetails
};

const styles = ({ spacing }) => ({
    contentContainer: {
        width: '98%',
        margin: 'auto',
        marginTop: spacing.unit
    },
    contentHeader: {
        padding: spacing.unit * 2
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    sizeTableWrapper: {
        width: '100%',
        minHeight: spacing.unit * 7.5,
        padding: spacing.unit * 2
    }
});

const mapState = state => {
    const { sizeDetails, ...restPOFormData } = formValueSelector(PO_FORM_NAME)(
        state,
        'sizeDetails',
        'orderQuantity',
        'totalRatio'
    );
    const sizeDataArray = sizeDetails !== undefined ? sizeDetails : [];
    const sizeSetLoading = poSizeSetLoadingSelector(state);
    const isNinetyPercent = isNinetyPercentSelector(state);
    const labDipFirstColorLoading = labDipColorLoadingSelector(
        state,
        'dips[0].color-loading'
    );
    return {
        loading: sizeSetLoading || (isNinetyPercent && labDipFirstColorLoading),
        canGenerateSKU: canGenerateSKUSelector(state),
        sizeDataArray,
        ...restPOFormData
    };
};

const mapDispatch = { handleSKUGenerate: generatePOSizeSKU };

const SizeDetailsContainer = compose(
    withFieldArray(),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles, { withTheme: true })
)(
    ({
        classes: c,
        theme: { spacing },
        loading,
        sizeDataArray,
        canGenerateSKU,
        orderQuantity,
        totalRatio,
        handleSKUGenerate,
        meta: {
            error: { sizeDetails: sizeDetailsError } = {},
            submitFailed,
            form
        } = {},
        fieldName
    }) => {
        const fieldId = `${form}-${fieldName}`;
        const [sizeDetailsFieldsError, setSizeDetailsFieldsError] = useState(
            {}
        );

        const fieldError = useMemo(() => {
            return Object.values(sizeDetailsFieldsError)[0];
        }, [sizeDetailsFieldsError]);

        const errorMessage =
            submitFailed || !!fieldError ? fieldError || sizeDetailsError : '';

        const handleSizeDetailsFieldsError = useCallback(
            (fieldName, index, quantity, sizeDataSummary) => (
                newValue,
                prevValue
            ) => {
                validateSizeDetailsFields(
                    orderQuantity,
                    totalRatio,
                    setSizeDetailsFieldsError,
                    sizeDataSummary,
                    fieldName,
                    newValue,
                    prevValue,
                    index,
                    quantity
                );
            },
            [
                orderQuantity,
                totalRatio,
                setSizeDetailsFieldsError,
                validateSizeDetailsFields
            ]
        );

        useEffect(() => {
            setSizeDetailsFieldsError({});
        }, [sizeDataArray]);

        return (
            <ContentContainer
                title='Size Breakdown'
                required={true}
                error={errorMessage}
                AdornmentComponent={
                    <Adornment
                        canGenerateSKU={canGenerateSKU}
                        handleSKUGenerate={handleSKUGenerate}
                    />
                }
                styles={{
                    container: c.contentContainer,
                    header: c.contentHeader
                }}
                id={fieldId}
            >
                <div className={c.sizeTableWrapper}>
                    {loading ? (
                        <div className={c.loader}>
                            <CircularProgress size={spacing.unit * 4} />
                        </div>
                    ) : (
                        <SizeBreakdown
                            data={sizeDataArray}
                            sizeDetailsFieldsError={sizeDetailsFieldsError}
                            handleSizeDetailsFieldsError={
                                handleSizeDetailsFieldsError
                            }
                        />
                    )}
                </div>
            </ContentContainer>
        );
    }
);

SizeDetailsContainer.propTypes = propTypes;
SizeDetailsContainer.defaultProps = defaultProps;

export default SizeDetailsContainer;
