import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import { formListener } from 'app/ReduxGate/store/middleware';
import MailForm from 'common/components/MailForm';
import { MAIL_FORM_NAME } from 'common/common';

import {
    samplingReportMailSend,
    setSampleEmailBody,
    setSampleEmailReceivers
} from 'sampling/redux.actions';
import SampleEmailGroup from './SampleEmailGroup';
import SampleEmailTemplate from './SampleEmailTemplate';

const mapDispatch = dispatch => ({
    reportMailSend: (...args) => dispatch(samplingReportMailSend(...args)),
    formInitialize: (...args) => dispatch(initialize(...args))
});

const mapInputs = {
    groups: ({ payload }) => setSampleEmailReceivers(payload),
    templates: ({ payload }) => setSampleEmailBody(payload)
};

const SamplePreviewEmailModal = compose(
    reduxForm({
        form: MAIL_FORM_NAME,
        onSubmit: v => v
    }),
    connect(
        null,
        mapDispatch
    ),
    formListener(mapInputs)
)(
    ({
        onClose,
        valid,
        retailer,
        reference,
        developmentName,
        developmentNumber,
        emailSubject,
        PDFData,
        handlePreviewModalClose,
        reportMailSend,
        formInitialize
    }) => {
        const handleMailModalClose = useCallback(() => {
            onClose();
            setTimeout(() => handlePreviewModalClose(), 0);
        }, []);

        const handleSend = useCallback(() => {
            reportMailSend({
                sampleReference: reference,
                developmentName,
                developmentNumber,
                closeModal: handleMailModalClose
            });
        }, []);

        useEffect(() => {
            formInitialize(MAIL_FORM_NAME, {
                subject: emailSubject,
                report: PDFData
            });
        }, []);

        const mailProps = {
            MailGroupComponent: <SampleEmailGroup retailer={retailer} />,
            MailTemplatesComponent: <SampleEmailTemplate retailer={retailer} />,
            handleModalClose: onClose,
            handleSubmit: handleSend,
            isFormValid: valid
        };

        return <MailForm {...mailProps} />;
    }
);

export { SamplePreviewEmailModal as default, SamplePreviewEmailModal };
