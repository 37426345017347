import React from 'react';

// Material
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = ({ palette }) => ({
    overlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: `${palette.background.default}8C`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10
    }
});

const ContentLoader = withStyles(styles, { withTheme: true })(
    ({ classes: c, theme: { spacing } }) => (
        <div className={c.overlay}>
            <CircularProgress size={spacing.unit * 7} color='primary' />
        </div>
    )
);

export default ContentLoader;
