export const FETCH_DESIGNER_TYPE = 'get_designer_type';
export const FETCH_DESIGNER_TYPE_SUCCESS = 'get_designer_type_success';
export const FETCH_DESIGNER_TYPE_FAILURE = 'get_designer_type_failure';

export const FETCH_CAD_LICENSOR = 'get_cad_licensor';
export const FETCH_CAD_LICENSOR_SUCCESS = 'get_cad_licensor_success';
export const FETCH_CAD_LICENSOR_FAILURE = 'get_cad_licensor_failure';

export const FETCH_CAD_GRAPHIC_REF_INCOMPLETE =
	'get_cad_graphic_ref_incomplete';
export const FETCH_CAD_GRAPHIC_REF_INCOMPLETE_SUCCESS =
	'get_cad_graphic_ref_incomplete_success';
export const FETCH_CAD_GRAPHIC_REF_INCOMPLETE_FAILURE =
	'get_cad_graphic_ref_incomplete_failure';

export const FETCH_CAD_GRAPHIC_REF_AMEND = 'get_cad_graphic_ref_amend';
export const FETCH_CAD_GRAPHIC_REF_AMEND_SUCCESS =
	'get_cad_graphic_ref_amend_success';
export const FETCH_CAD_GRAPHIC_REF_AMEND_FAILURE =
	'get_cad_graphic_ref_amend_failure';

export const FETCH_CAD_REF_INFO = 'get_cad_ref_info';
export const FETCH_CAD_REF_INFO_SUCCESS = 'get_cad_ref_info_success';
export const FETCH_CAD_REF_INFO_FAILURE = 'get_cad_ref_info_failure';

export const FETCH_NEW_CAD_COLLECTION = 'get_new_cad_collection';
export const FETCH_NEW_CAD_COLLECTION_SUCCESS =
	'get_new_cad_collection_success';
export const FETCH_NEW_CAD_COLLECTION_FAILURE =
	'get_new_cad_collection_failure';

export const POST_CAD_COLLECTION_REQUEST = 'post_cad_collection_success';
export const POST_CAD_COLLECTION_SUCCESS = 'post_cad_collection_failure';

export const POST_CAD_COLLECTION_FAILURE = 'post_cad_collection_failure';

export const FETCH_LICENSOR_REQUEST = 'get_licensor';
export const FETCH_LICENSOR_SUCCESS = 'get_licensor_success';
export const FETCH_LICENSOR_FAILURE = 'get_licensor_failure';

export const POST_NEW_CAD_REQUEST = 'post_cad_create';
export const POST_NEW_CAD_SUCCESS = 'post_cad_create_success';

export const UPDATE_NEW_CAD_REQUEST = 'put_cad_request';
export const UPDATE_NEW_CAD_SUCCESS = 'put_cad_request_success';

export const POST_CAD_COLOR_REQUEST = 'post_cad_color';
export const POST_CAD_COLOR_SUCCESS = 'post_cad_color_success';

export const CHECK_COLOR_DUPLICATE = 'get_check_color_duplicate';
export const CHECK_COLOR_DUPLICATE_SUCCESS =
	'get_check_color_duplicate_success';
