import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormValues,
    isSubmitting,
    initialize as formInitialize,
    destroy as formDestroy
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import useScrollToErrorField from '@libHooks/useScrollToErrorField';
import { useValidate, useNext } from '@libHooks/useValidate';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { formErrorsSelector } from 'common/redux.selectors';

import useSchema from './content/useSchema';
import Preview from './content/Preview';
import { SampleSendProvider } from './useSampleSend';
import { useHandles, useContentFields } from './hooks';
import { FORM_NAME, INITIAL_VALUES } from './common';
import {
    dispatchReferenceGenerate,
    dispatchSaveDraft,
    dispatchPublish
} from '../redux.actions';
import { sampleDispatchReferenceLoadingSelector } from '../redux.selectors';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => ({
    formValues: getFormValues(FORM_NAME)(state),
    formErrors: formErrorsSelector(state, FORM_NAME),
    formSubmitting: isSubmitting(FORM_NAME)(state),
    sampleDispatchReferenceLoading: sampleDispatchReferenceLoadingSelector(
        state
    )
});

const mapDispatch = {
    saveDraft: dispatchSaveDraft,
    publish: dispatchPublish,
    generateReference: dispatchReferenceGenerate,
    formInitialize,
    formDestroy
};

const SampleDispatchContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        sampleDispatchReferenceLoading,
        saveDraft,
        publish,
        generateReference,
        formInitialize,
        formDestroy,
        mode,
        handleReduxFormSubmit,
        handleModal
    }) => {
        const state = getState();
        const { activeStep, contents } = state;
        const formFields = useContentFields(mode, activeStep);

        const [dispatchFormRef, handleScroll] = useScrollToErrorField(
            formErrors,
            formFields,
            FORM_NAME
        );

        const handleDispatchFormSubmit = useCallback(() => {
            handleReduxFormSubmit();
            handleScroll();
        }, [handleReduxFormSubmit, handleScroll]);

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const { dispatchReference } = formValues;

        const schema = useSchema(mode, formValues);
        const areValid = useValidate(formValues, schema, formErrors);
        const canProceed = useNext(state, areValid);

        const canResetForm = useMemo(() => {
            return !!Object.keys(formValues).length;
        }, [formValues]);

        const shouldReferenceGenerate = useMemo(
            () => activeStep === 1 && mode === 'new' && !dispatchReference,
            [activeStep, mode, dispatchReference]
        );

        const shouldFetchData = useMemo(
            () => mode !== 'new' && activeStep === 0,
            [mode, activeStep]
        );

        const shouldSave = useMemo(
            () =>
                mode !== 'amend' &&
                activeStep === (mode === 'incomplete' ? 0 : 1),
            [mode, activeStep]
        );

        const shouldPreview = useMemo(() => {
            return activeStep === contents.length - 2;
        }, [activeStep, contents.length]);

        const [handleNext, handlePublish, setupLocked, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish
        });

        const handleSaveDraft = useCallback(() => saveDraft({ mode }), [
            mode,
            saveDraft
        ]);

        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleDispatchFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep]);

        const handlePreview = useCallback(
            props => {
                if (!canProceed) {
                    handleDispatchFormSubmit();
                    return;
                }
                handleModal({ ...props, handlePublish, mode });
            },
            [handleModal, canProceed]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                mode,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                handlePublish,
                sampleDispatchReferenceLoading,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleSaveDraft,
                reset,
                shouldPreview,
                handlePreview,
                canResetForm
            }),
            [
                mode,
                areValid.saveDraft,
                areValid.publish,
                sampleDispatchReferenceLoading,
                handlePublish,
                setupLocked,
                shouldFetchData,
                shouldSave,
                handleSaveDraft,
                reset,
                shouldPreview,
                handlePreview,
                canResetForm
            ]
        );

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => formDestroy(FORM_NAME);
        }, [mode]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        useEffect(() => {
            shouldReferenceGenerate && generateReference();
        }, [shouldReferenceGenerate]);

        return (
            <Paper className={c.container}>
                <SampleSendProvider initialize={initialize}>
                    <div ref={dispatchFormRef}>{children}</div>
                </SampleSendProvider>
                {!complete &&
                    (shouldPreview ? (
                        <Button className={c.button} onClick={handlePreview}>
                            Preview
                        </Button>
                    ) : (
                        <Button
                            className={c.button}
                            onClick={handleNextWithSubmit}
                            loading={formSubmitting}
                        >
                            {proceedText}
                        </Button>
                    ))}
            </Paper>
        );
    }
);

export default SampleDispatchContainer;
