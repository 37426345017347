import { apiClient } from '@libs/apiRequest';

export default async ({ email, id }) => {
    if (email) {
        // Responds with 404 on fail
        const checkReference = new Promise(
            async resolve =>
                await apiClient({
                    url: 'User/GetUserByEmail',
                    params: {
                        email,
                        userId: id
                    }
                })
                    .then(() => resolve(true))
                    .catch(() => resolve(false))
        );
        const referenceExists = await checkReference;

        if (referenceExists) {
            throw { email: 'This user already exists' };
        }
    }
};
