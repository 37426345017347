import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { func, bool } from 'prop-types';

import { GridItem, Input, Editor } from '@formComponents';
import { ModalHeader, ModalContent, ModalFooter } from '@libComponents/Modal';
import Button from '@libComponents/Button';
import { renderedComponentPropType } from '@libs/propTypes';

const propTypes = {
    MailGroupComponent: renderedComponentPropType.isRequired,
    MailTemplatesComponent: renderedComponentPropType.isRequired,
    handleSubmit: func.isRequired,
    handleModalClose: func.isRequired,
    isFormValid: bool
};

const styles = ({ spacing, breakpoints, palette, shape }) => ({
    content: {
        width: breakpoints.values.md,
        padding: spacing.unit * 1,
        paddingTop: spacing.unit * 2
    },
    spaceAround: {
        paddingLeft: spacing.unit * 1,
        paddingRight: spacing.unit * 1
    },
    button: {
        width: '100%',
        margin: spacing.unit * 2
    },
    customButton: {
        minWidth: '100%',
        height: spacing.unit * 4.5,
        border: '1px solid',
        borderColor: palette.grey[300],
        borderRadius: shape.borderRadius,
        color: palette.secondary.light,
        backgroundColor: '#fff',
        '&:hover': {
            color: palette.secondary.light,
            backgroundColor: 'inherit'
        }
    },
    editor: {
        '& > .ql-container > .ql-editor': {}
    },
    inputRoot: {
        height: 'auto',
        minHeight: spacing.unit * 4.5
    }
});

const MailForm = withStyles(styles)(
    ({
        classes: c,
        handleModalClose,
        handleSubmit,
        isFormValid,
        MailGroupComponent,
        MailTemplatesComponent
    }) => {
        return (
            <>
                <ModalHeader onClose={handleModalClose}>Mail Form</ModalHeader>
                <ModalContent className={c.content}>
                    <Grid container>
                        <GridItem md={3} className={c.spaceAround}>
                            {MailGroupComponent}
                        </GridItem>
                        <GridItem md={9} className={c.spaceAround}>
                            <Input
                                name='receivers'
                                required
                                placeholder='To..'
                                multiline
                                rowsMax={8}
                                styles={{ root: c.inputRoot }}
                            />
                        </GridItem>
                        <GridItem md={3} className={c.spaceAround}>
                            <Button
                                className={c.customButton}
                                variant='text'
                                disableRipple
                            >
                                Cc..
                            </Button>
                        </GridItem>
                        <GridItem md={9} className={c.spaceAround}>
                            <Input name='cc' placeholder='cc..' />
                        </GridItem>
                        <Grid container>
                            <GridItem md={3} className={c.spaceAround}>
                                {MailTemplatesComponent}
                            </GridItem>
                            <GridItem md={2} className={c.spaceAround}>
                                <Button
                                    className={c.customButton}
                                    variant='text'
                                    disableRipple
                                >
                                    Subject
                                </Button>
                            </GridItem>
                            <GridItem md={7} className={c.spaceAround}>
                                <Input name='subject' required />
                            </GridItem>
                        </Grid>

                        <GridItem md={12} className={c.spaceAround}>
                            <Editor
                                name='body'
                                styles={{ editor: c.editor }}
                                required
                                placeholder='Write your message here...'
                            />
                        </GridItem>
                    </Grid>
                </ModalContent>
                <ModalFooter>
                    <Button
                        className={c.button}
                        onClick={handleSubmit}
                        disabled={!isFormValid}
                    >
                        Send
                    </Button>
                </ModalFooter>
            </>
        );
    }
);

MailForm.propTypes = propTypes;

export { MailForm as default, MailForm };
