import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text, Link, Image } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { Image as PDFImage } from '@libComponents/ReactPDF';
import {
    PAGE_PADDING_TOP,
    PAGE_PADDING_BOTTOM,
    PAGE_PADDING_LEFT,
    PAGE_PADDING_RIGHT
} from 'common/constants';
import { Title, Field } from 'components/PDF';

const propTypes = {
    sampleRef: PropTypes.string.isRequired,
    developmentNo: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    buyer: PropTypes.string,
    images: PropTypes.array.isRequired
};

const defaultProps = {
    buyer: ''
};

const styles = ({ spacing, palette, typography }) => ({
    page: {
        paddingLeft: PAGE_PADDING_LEFT,
        paddingRight: PAGE_PADDING_RIGHT,
        paddingTop: PAGE_PADDING_TOP,
        paddingBottom: PAGE_PADDING_BOTTOM
    },
    contentContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: spacing.unit * 1.25
    },
    imageContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: spacing.unit * 0.75,
        justifyContent: 'space-between'
    },
    imageFrame: {
        width: '48%',
        margin: spacing.unit * 0.5
    },
    imageGroup: {
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        borderWidth: 1
    },
    title: {
        padding: spacing.unit * 1.5,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.background.dark,
        borderBottomWidth: 1,
        backgroundColor: palette.background.light,
        fontSize: 14
    },
    imageWrapper: {
        width: '100%',
        height: spacing.unit * 30,
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    linkWrapper: {
        flexDirection: 'row',
        marginTop: spacing.unit * 0.5,
        marginBottom: spacing.unit * 0.5
    },
    linkTitle: {
        fontSize: spacing.unit * 1.5,
        marginRight: spacing.unit * 0.5
    },
    link: {
        fontWeight: typography.fontWeightHeavy,
        fontSize: spacing.unit * 1.5,
        color: palette.orange.main,
        textDecoration: 'underline'
    }
});

const Report = withPDFStyles(styles, { withTheme: true })(
    ({
        classes: c,
        theme: { spacing },
        sampleRef,
        developmentNo,
        department,
        buyer,
        images
    }) => {
        //const year = useMemo(() => new Date().getFullYear(), []);

        return (
            <Document>
                <Page size='A4' style={c.page}>
                    <Title
                        text={'Final Photo Info'}
                        style={{
                            fontSize: spacing.unit * 2.25,
                            borderBottom: 1
                        }}
                    />
                    <View style={c.contentContainer}>
                        <Field label={'Sample Reference'} value={sampleRef} />
                        <Field label={'Development no'} value={developmentNo} />
                        <Field label={'Department'} value={department} />
                        <Field label={'Buyer'} value={buyer} />
                    </View>

                    {!!images.length && (
                        <View style={c.imageContainer}>
                            {images.map(({ url, title, link }, idx) => (
                                <View
                                    style={c.imageFrame}
                                    key={`final-photo-frame-${idx}`}
                                >
                                    <View style={c.imageGroup}>
                                        <Text style={c.title}>{title}</Text>
                                        <View style={c.imageWrapper}>
                                            {url && (
                                                <PDFImage
                                                    style={c.image}
                                                    src={url}
                                                />
                                            )}
                                        </View>
                                    </View>
                                    <View style={c.linkWrapper}>
                                        <Text style={c.linkTitle}>{title}</Text>
                                        <Link
                                            src={link}
                                            style={c.link}
                                            download
                                        >
                                            Link
                                        </Link>
                                    </View>
                                </View>
                            ))}
                        </View>
                    )}
                </Page>
            </Document>
        );
    }
);

Report.propTypes = propTypes;
Report.defaultProps = defaultProps;

export default Report;
