import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SvgIcon from '@material-ui/core/SvgIcon';
import ExpansionPanel from './ExpansionPanel';
import ExpansionPanelDetails from './ExpansionPanelDetails';
import {
    ExpansionPanelSummary,
    ReverseExpansionPanelSummary
} from './ExpansionPanelSummary';
import { AccordionConsumer } from './context';
import styles from './styles';

const ExpandIcon = () => (
    <SvgIcon fontSize='small' color='primary'>
        <NavigateNextIcon />
    </SvgIcon>
);

class AccordionPanel extends Component {
    render() {
        const {
            classes,
            tag,
            title,
            icon,
            depth,
            reverse,
            children,
            ...restProps
        } = this.props;

        const expandId = `id-${tag}`;
        const expandAria = `content-${tag}`;

        return (
            <AccordionConsumer>
                {({ expandedId, handleChange }) => (
                    <ExpansionPanel
                        square
                        expanded={expandedId === tag}
                        onChange={() => handleChange(tag)}
                        {...restProps}
                    >
                        {reverse ? (
                            <ReverseExpansionPanelSummary
                                aria-controls={expandAria}
                                id={expandId}
                                expandIcon={<ExpandIcon />}
                                style={{ paddingLeft: `${depth * 16}px` }}
                            >
                                {icon ? (
                                    <img
                                        className={classes.image}
                                        src={icon}
                                        alt={title}
                                    />
                                ) : null}
                                {title}
                            </ReverseExpansionPanelSummary>
                        ) : (
                            <ExpansionPanelSummary
                                aria-controls={expandAria}
                                id={expandId}
                                expandIcon={<ExpandIcon />}
                                style={{ paddingLeft: `${depth * 16}px` }}
                            >
                                {icon ? (
                                    <img
                                        className={classes.image}
                                        src={icon}
                                        alt={title}
                                    />
                                ) : null}
                                {title}
                            </ExpansionPanelSummary>
                        )}
                        <ExpansionPanelDetails>
                            {children}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </AccordionConsumer>
        );
    }
}

AccordionPanel.defaultProps = {
    tag: '',
    title: '',
    children: '',
    icon: null,
    depth: 1
};

AccordionPanel.propTypes = {
    depth: PropTypes.number,
    title: PropTypes.string,
    icon: PropTypes.string
};

const _AccordionPanel = withStyles(styles)(AccordionPanel);

export { _AccordionPanel as default, _AccordionPanel as AccordionPanel };
