import React, { PureComponent, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { formValueSelector } from 'redux-form';

import styles from './styles';

import {
    imgTypeAllowed,
    imgTypes,
    fileSize,
    priceValidator,
    isLoading
} from 'helpers';

import {
    GridContainer,
    GridItem,
    TextInput,
    SelectFields,
    NativeEditor,
    CircularLoader,
    ModalHeader,
    ModalContent,
    ModalFooter
} from 'components';

import { POST_NEW_TRIM_STEP_FIRST } from 'store/actionTypes';

import { selectCurrencies, selectTrimSuppliers } from 'store/selectors';

import { setStepData, postNewTrimCard } from 'store/actions';

import { selectRetailers } from 'selectors';

function mapStateToProps(state) {
    const selector = formValueSelector('SamplingPrintForm');
    const retailerId = selector(state, 'retailerId');
    const [selectedRetailer] = selectRetailers(state).filter(
        ({ value }) => value == retailerId
    );
    return {
        store: state,
        currencies: selectCurrencies(state),
        suppliers: selectTrimSuppliers(state),
        selectedRetailer
    };
}

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            setDataForSteps: setStepData,
            postNewTrimCard
        },
        dispatch
    );
};

class TrimDetailsModal extends PureComponent {
    componentDidMount() {
        const {
            currencies,
            suppliers,
            fetchCurrencies,
            fetchSuppliers
        } = this.props;
        if (!currencies.length) fetchCurrencies();
        if (!suppliers.length) fetchSuppliers();
    }

    state = {
        attachmentUploadError: '',
        cadUploadError: ''
    };

    isValidToSubmit = () => {
        const {
            store: {
                initSamplingPrint: { trimSupplier }
            }
        } = this.props;

        let isValid = false;

        if (trimSupplier) isValid = true;

        return isValid;
    };

    changeText = (e, type) =>
        this.setValueToState({ type: type, value: e.target.value });

    handleComment = (e, type) => this.setValueToState({ type: type, value: e });

    setValueToState = param => {
        let stepKey = param.type;
        let stepData = param.value;

        this.props.setDataForSteps(stepKey, stepData);
    };

    handleAttachments = event => {
        // this.setState({ attachmentUploadError: '' });
        // if (event.target.files.length > 0) {
        //     const newImg = [];
        //     [...event.target.files].map((file, index) => {
        //         if (file.size <= fileSize.bytes) {
        //             newImg.push(file);
        //         } else {
        //             this.showUploadError(
        //                 'Attachment file size limit exceeded.',
        //                 1
        //             );
        //         }
        //         return true;
        //     });
        //     if (newImg.length > 0)
        //         this.props.setDataForSteps('trimAttachments', [
        //             ...this.props.stepData.trimAttachments,
        //             ...newImg
        //         ]);
        // }
    };

    handleCadImage = event => {
        this.setState({ cadUploadError: '' });
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if (
                file.size <= fileSize.bytes &&
                imgTypeAllowed.includes(file.type)
            ) {
                this.props.setDataForSteps('trimCadImg', file);
            } else if (!imgTypeAllowed.includes(file.type)) {
                this.showUploadError('Cad image file type not allowed.', 2);
            } else {
                this.showUploadError('Cad image file size limit exceeded.', 2);
            }
        }
    };

    showUploadError = (msg, type) => {
        //type = 1: attachments, 2: cad image
        const THIS = this;
        if (type === 1) {
            this.setState({ attachmentUploadError: msg });
            setTimeout(function() {
                THIS.setState({ attachmentUploadError: '' });
            }, 3000);
        } else if (type === 2) {
            this.setState({ cadUploadError: msg });
            setTimeout(function() {
                THIS.setState({ cadUploadError: '' });
            }, 3000);
        }
    };

    saveFinalData = () => {
        const { onClose, postNewTrimCard } = this.props;
        postNewTrimCard();
        // onClose();
    };

    isLoadingStatus = key => isLoading(this.props.store.global, key);

    render() {
        const {
            state,
            props,
            isLoadingStatus,
            changeText,
            handleComment
        } = this;
        const {
            classes,
            currencies,
            suppliers,
            onClose,
            selectedRetailer,
            store: {
                initSamplingPrint: {
                    trimType,
                    trimDescription,
                    trimSupplier,
                    trimSupplierRef,
                    trimComments,
                    trimSize,
                    trimComposition,
                    trimCost,
                    trimCurrency,
                    trimUnit,
                    trimAttachments,
                    trimCadImg
                }
            }
        } = props;
        const { attachmentUploadError, cadUploadError } = state;

        return (
            <Fragment>
                <ModalHeader onClose={onClose}>New trim card</ModalHeader>
                <ModalContent>
                    <GridContainer
                        justify='center'
                        spacing={40}
                        className={classes.gridContainer}
                    >
                        <GridItem xs={12} sm={6} md={6}>
                            <TextInput
                                isDisabled={true}
                                labelText='Retailer: '
                                placeholderText='Retailer'
                                value={
                                    selectedRetailer
                                        ? selectedRetailer.label
                                        : ''
                                }
                            />
                            <TextInput
                                multiline={true}
                                rows='4'
                                textArea={true}
                                labelText='Trim Description: '
                                placeholderText='Please write description'
                                value={trimDescription}
                                handleText={e =>
                                    changeText(e, 'trimDescription')
                                }
                            />
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={6} md={6}>
                                    <SelectFields
                                        label='Supplier: '
                                        required={true}
                                        success={!!trimSupplier}
                                        data={suppliers}
                                        value={trimSupplier}
                                        action={this.setValueToState}
                                        placeholder='Supplier'
                                        type='trimSupplier'
                                        disableCondition={!suppliers.length}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextInput
                                        labelText='Supplier Ref: '
                                        placeholderText='Supplier ref.'
                                        value={trimSupplierRef}
                                        handleText={e =>
                                            changeText(e, 'trimSupplierRef')
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                            <label>
                                <Typography
                                    component='div'
                                    className={classes.editorLabel}
                                >
                                    Comments:
                                </Typography>
                            </label>
                            <NativeEditor
                                key='commentEditor'
                                placeholder='Write comments'
                                value={trimComments}
                                onChange={e => handleComment(e, 'trimComments')}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <TextInput
                                isDisabled={true}
                                labelText='Trim Type: '
                                placeholderText='Trim Type'
                                value={trimType ? trimType.label : ''}
                            />
                            <TextInput
                                labelText='Trim Size: '
                                placeholderText='Trim Size'
                                value={trimSize}
                                handleText={e => changeText(e, 'trimSize')}
                            />
                            <TextInput
                                labelText='Trim composition: '
                                placeholderText='Trim composition'
                                value={trimComposition}
                                handleText={e =>
                                    changeText(e, 'trimComposition')
                                }
                            />
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={4} md={4}>
                                    <TextInput
                                        labelText='Cost: '
                                        placeholderText='0.00'
                                        value={trimCost}
                                        handleText={e => {
                                            let valid = true;
                                            let value = e.target.value;
                                            if (!priceValidator(value)) {
                                                valid = false;
                                            }
                                            if (valid) {
                                                changeText(e, 'trimCost');
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <SelectFields
                                        label='Currency: '
                                        success={!!trimCurrency}
                                        data={currencies}
                                        value={trimCurrency}
                                        action={this.setValueToState}
                                        placeholder='Select'
                                        type='trimCurrency'
                                        disableCondition={!currencies.length}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <TextInput
                                        labelText='Units: '
                                        placeholderText='Set unit'
                                        value={trimUnit}
                                        handleText={e =>
                                            changeText(e, 'trimUnit')
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                            <TextField
                                fullWidth
                                label='Add attachment: '
                                InputProps={{
                                    onChange: e => this.handleAttachments(e),
                                    disableUnderline: true,
                                    inputProps: {
                                        type: 'file',
                                        multiple: true,
                                        accept: '*',
                                        size: fileSize.bytes,
                                        id: 'attachment-upload-trim'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.inputLabelRoot,
                                        focused: classes.inputLabelFocused,
                                        shrink: classNames(
                                            classes.inputLabelShrank,
                                            classes.labelFileField
                                        )
                                    }
                                }}
                                className={classes.textField}
                            />
                            <label
                                htmlFor='attachment-upload-trim'
                                className={classes.browseButtonRoot}
                            >
                                <Typography className={classes.fileListLabel}>
                                    {trimAttachments.map((file, index) => (
                                        <span key={index}>{file.name}</span>
                                    ))}
                                </Typography>
                                <Button
                                    component='span'
                                    className={classes.browseButton}
                                    disableRipple
                                >
                                    Browse
                                </Button>
                            </label>
                            <Typography
                                style={{
                                    color: 'red',
                                    marginTop: 15,
                                    textAlign: 'center'
                                }}
                            >
                                {attachmentUploadError}
                            </Typography>

                            <TextField
                                fullWidth
                                label='Cad: '
                                InputProps={{
                                    onChange: e => this.handleCadImage(e),
                                    disableUnderline: true,
                                    inputProps: {
                                        type: 'file',
                                        multiple: true,
                                        accept: '*',
                                        size: fileSize.bytes,
                                        id: 'cad-upload-trim'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.inputLabelRoot,
                                        focused: classes.inputLabelFocused,
                                        shrink: classNames(
                                            classes.inputLabelShrank,
                                            classes.labelFileField
                                        )
                                    }
                                }}
                                className={classes.textField}
                            />
                            <label
                                htmlFor='cad-upload-trim'
                                className={classes.browseButtonRoot}
                            >
                                <Typography className={classes.fileListLabel}>
                                    {trimCadImg ? trimCadImg.name : ''}
                                </Typography>
                                <Button
                                    component='span'
                                    className={classes.browseButton}
                                    disableRipple
                                >
                                    Browse
                                </Button>
                            </label>
                            <Typography
                                style={{
                                    color: 'red',
                                    marginTop: 15,
                                    textAlign: 'center'
                                }}
                            >
                                {cadUploadError}
                            </Typography>
                            <Typography
                                style={{ color: '#aeaeae', marginTop: 35 }}
                            >
                                * Allowed file extensions: {imgTypes.join(', ')}
                                <br />* Maximum file size: {
                                    fileSize.megabytes
                                }{' '}
                                MB
                                <br />* Maximum 1 image
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </ModalContent>
                <ModalFooter>
                    <GridContainer
                        justify='center'
                        spacing={0}
                        className={classes.footer}
                    >
                        <GridItem xs={12} sm={6} md={6} />
                        <GridItem xs={12} sm={6} md={6}>
                            <Button
                                onClick={this.saveFinalData}
                                variant='contained'
                                disabled={!this.isValidToSubmit()}
                                classes={{
                                    root: classes.saveButton,
                                    disabled: classes.saveButtonDisabled
                                }}
                            >
                                {isLoadingStatus(POST_NEW_TRIM_STEP_FIRST) ? (
                                    <CircularLoader />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </ModalFooter>
            </Fragment>
        );
    }
}

TrimDetailsModal.propTypes = {
    classes: PropTypes.object
};

const _TrimDetailsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TrimDetailsModal));

export { _TrimDetailsModal as default, _TrimDetailsModal as TrimDetailsModal };
