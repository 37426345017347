import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Local
import {
    GridContainer,
    GridItem,
    Input,
    Select,
    Checkbox,
    DateInput,
    NavigateButton,
    PaginatedSelectTest
} from 'components';
import SamplingReferenceSelect from './SamplingReferenceSelect';
import { formListener } from 'store/middlewares/formMiddleware';
import { denormalizeDate, validateRequired } from 'helpers';

// Actions
import {
    fetchSampleReferences,
    fetchSampleData,
    fetchDatasets,
    setSamplingPageType
} from 'store/actions';

// Selectors

import { selectSampleReferencesList } from 'store/selectors/datasets/sampling/reference';
import { selectPageType } from 'store/selectors/sampling/common';
import {
    selectRequestTypes,
    selectSendToBuyers,
    selectSendToLicensors,
    selectSendToUks,
    selectLicenseType,
    selectFactoryNames,
    selectDevelopmentTypes,
    selectRequestFormLoading // Todo: Remove when fixing multistepper
} from 'selectors';
import { selectDepartments } from 'store/selectors/sampling/newRequest';

// Entities
import {
    COUNTRIES,
    DEPARTMENTS,
    DEVELOPMENT_TYPES,
    FACTORIES,
    REQUEST_TYPES,
    SEND_TO_BUYERS,
    SEND_TO_LICENSORS,
    SEND_TO_UKS
} from 'libs';

const validate = (values, { isLicensed }) => {
    const required = ['requestTypeId', 'sendToBuyerId', 'factoryId'];

    if (isLicensed) required.push('sendToLicensorId');

    return { ...validateRequired(values, required) };
};

class SamplingPrintReference extends Component {
    componentDidMount() {
        const { isNewPage, fetchSampleReferences, fetchDatasets } = this.props;
        if (!isNewPage) fetchSampleReferences();

        fetchDatasets([
            COUNTRIES,
            DEPARTMENTS,
            DEVELOPMENT_TYPES,
            FACTORIES,
            REQUEST_TYPES,
            SEND_TO_BUYERS,
            SEND_TO_LICENSORS,
            SEND_TO_UKS
        ]);
    }

    getForwardedDate = days => {
        const { dateRequested } = this.props;
        const date = dateRequested ? new Date(dateRequested) : new Date();
        return date.setDate(date.getDate() + days);
    };

    render() {
        const { props, getForwardedDate } = this;
        const {
            onSubmit,
            invalid,
            isNewPage,
            factoryNamesData,
            sendToUks,
            requestTypes,
            sendToBuyers,
            sendToLicensors,
            developmentTypesData,
            sampleReferencesData,
            departmentsData,
            isLicensed,
            requestLoading
        } = props;

        console.log('requestLoading', requestLoading);

        return (
            <Fragment>
                <GridContainer>
                    <GridItem>
                        {isNewPage ? (
                            <Input
                                name='sampleReference'
                                label='Sample Reference'
                                disabled
                            />
                        ) : (
                            <SamplingReferenceSelect
                                name='sampleReference'
                                label='Sample Reference'
                                data={sampleReferencesData}
                                disabled={requestLoading}
                            />
                        )}
                        <DateInput
                            name='dateRequested'
                            label='Date Requested'
                            maxDate={new Date()}
                            loading={requestLoading}
                        />
                        <DateInput
                            name='sampleRequiredDate'
                            label='Sample Requested By'
                            minDate={getForwardedDate(1)}
                            loading={requestLoading}
                        />
                        <Select
                            name='factoryId'
                            label='Factory Name'
                            placeholder='Please select factory'
                            data={factoryNamesData}
                            required
                            isCancellable={false}
                            disabled={requestLoading}
                        />
                    </GridItem>
                    <GridItem>
                        {!isNewPage && (
                            <Select
                                name='departmentId'
                                label='Department'
                                data={departmentsData}
                                disabled
                            />
                        )}
                        <Select
                            name='developmentId'
                            label='Development Number'
                            disabled
                            data={developmentTypesData}
                        />
                        <Select
                            name='sendToUkId'
                            label='Send to Echo UK'
                            data={sendToUks}
                            disabled={requestLoading}
                        />
                        <Input
                            name='buyerName'
                            label='Buyer'
                            loading={requestLoading}
                        />
                    </GridItem>
                    <GridItem>
                        <Select
                            name='requestTypeId'
                            label='Request Type'
                            required
                            data={requestTypes}
                            disabled={requestLoading}
                        />
                        <Select
                            name='sendToBuyerId'
                            label='Send to Buyer'
                            required
                            data={sendToBuyers}
                            disabled={requestLoading}
                        />
                        {isLicensed && (
                            <Select
                                name='sendToLicensorId'
                                label='Send to Licensor'
                                required
                                data={sendToLicensors}
                                disabled={requestLoading}
                            />
                        )}
                        <Checkbox
                            name='photoApprovalCheck'
                            label='Requested photo for approval'
                            disabled={requestLoading}
                        />
                    </GridItem>
                </GridContainer>
                <NavigateButton
                    onClick={onSubmit}
                    disabled={invalid || requestLoading}
                >
                    Next
                </NavigateButton>
            </Fragment>
        );
    }
}

const mapState = state => {
    const selector = formValueSelector('SamplingPrintForm');
    const retailerId = selector(state, 'retailerId');
    const dateRequested = selector(state, 'dateRequested');
    return {
        isLicensed: selectLicenseType(state),
        isNewPage: selectPageType(state, 'new'),
        dateRequested: dateRequested ? denormalizeDate(dateRequested) : null,
        factoryNamesData: selectFactoryNames(state),
        sendToUks: selectSendToUks(state),
        requestTypes: selectRequestTypes(state),
        sendToBuyers: selectSendToBuyers(state),
        sendToLicensors: selectSendToLicensors(state),
        developmentTypesData: selectDevelopmentTypes(state),
        sampleReferencesData: selectSampleReferencesList(state),
        departmentsData: selectDepartments(state, retailerId),
        requestLoading: selectRequestFormLoading(state)
    };
};

const mapDispatch = {
    fetchSampleReferences,
    fetchDatasets,
    setSamplingPageType
};

const mapInputs = {
    sampleReference: ({ payload }) => fetchSampleData(payload)
};

const _SamplingPrintReference = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: 'SamplingPrintForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        validate
    }),
    formListener(mapInputs)
)(SamplingPrintReference);

export {
    _SamplingPrintReference as default,
    _SamplingPrintReference as SamplingPrintReference
};
