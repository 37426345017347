import { useCallback } from 'react';

const useRowColor = () => {
    const colors = ['#fff', '#f7fcff', '#fff7f7'];

    const getColor = useCallback(level => {
        const maxColorIndex = colors.length - 1;
        const colorIndex = level % (maxColorIndex + 1);
        return {
            '--permissionTableRowBackgroundColor': colors[colorIndex]
        };
    }, []);

    return getColor;
};

export default useRowColor;
