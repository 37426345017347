import { put } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

export default function*(error, id, formName) {
    const { response: { status, data: { ErrorMessage } = {} } = {} } =
        error || {};
    let message = `Failed to ${id === 0 ? 'save' : 'update'} ${
        ErrorMessage ? `due to '${ErrorMessage}'` : ''
    }`;
    if (status === 404) {
        message = 'Requested item not found!';
    }
    yield put(
        stopSubmit(formName, {
            _error: message
        })
    );
}
